<mat-card class="mat-card-custom p-0 h-100" [formGroup]="formGrp">
    <mat-card-header class="align-items-center gap-3 change-mat-header justify-content-between last-card">
        <mat-card-title class="order-info">
            <span class="top-heading">Order Information</span>
        </mat-card-title>
        <div class="order-info">Order Information</div>
        <div class="history-btn" (click)="showHistoryFlyOut()">
            View History
        </div>
    </mat-card-header>
    <mat-card-content class="mat-card-pd mt-2">
        <div class="row mt-1">
            <div class="col-6 mb-4 right-padding">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Order Status</mat-label>
                    <mat-select class="select-arrow-change-icon"
                        formControlName="status"
                        (selectionChange)="onStatusChange($event.value)">
                        <mat-option *ngFor="let status of statuslist"
                            [value]="status.statusId"
                            [disabled]="status.statusId === 19 || status.statusId === 24 || status.statusId === 25 || status.statusId === 5">
                            {{status.statusName}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-6 mb-4 left-padding">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Account Number</mat-label>
                    <input matInput type="text" formControlName="accNo"
                        placeholder="Account Number">
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-6 mb-4 right-padding">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Order Number</mat-label>
                    <input matInput type="text" formControlName="poNumber"
                        placeholder="This will be auto generated.">
                </mat-form-field>
            </div>
            <div class="col-6 mb-3 left-padding">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Choose a date</mat-label>
                    <input matInput [matDatepicker]="pickerA"
                        formControlName="orderDate" placeholder="Order Date">
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="pickerA">
                    </mat-datepicker-toggle>
                    <mat-datepicker #pickerA></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
        <div class="row">
            <div class="col-6 right-padding">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Confirmation Number</mat-label>
                    <input matInput type="text" formControlName="confirmationNo"
                        placeholder="Confirmation Number">
                </mat-form-field>
            </div>
            <div class="col-6 left-padding">
                <mat-form-field appearance="outline" class="w-100">
                    <mat-label>Choose a date</mat-label>
                    <input matInput [matDatepicker]="picker1"
                        formControlName="confirmationDate"
                        placeholder="Confirmation Date">
                    <mat-hint>MM/DD/YYYY</mat-hint>
                    <mat-datepicker-toggle matIconSuffix [for]="picker1">
                    </mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
            </div>
        </div>
    </mat-card-content>
</mat-card>
