// import { customPartDetails } from "../custom-part/CustomPart";

export class ProductModel {
  ProductName: number;
  ProductDescription: number;
  category: Category[];
}
export class Category {
  id: number;
  productParts: ProductParts[];
}
export class ProductParts {
  id: number;
  qty: number;
  cost: string;
  hours: string;
  partQuestions: PartQuestions[];
}
export class PartQuestions {
  answerId: number;
  questionTitle: string;
  questionid: number;
  typeKey: string;
  typeName: string;
  answer: any;
  productPartId: number;
}

export class OrderProductPartsArray {
  orderPartQuestions: OrderPartQuestions[];
}

export class OrderProductParts {
  id: number;
  qty: number = 1;
  cost: string;
  hours: string;
  partQuestions: OrderPartQuestions[];

  partNo: string;
  name: string;
  vendorPartName: string;
  vendorPartNumber: string;
  vendorName: string;
  isCustomPart: boolean;
  vendorPartCoat: number;
  laborHrsExt: number;
  laborPriceExt: number;
  laborCostExt: number;
  soTotalCostExt: number;
  soTotalPriceExt: number;
  VendorId : number;
  customPartList : CustomPartList[];
  customerDiscount:CustomerDiscount[];
  categoryId : number
  customerAdditionalDiscount : any;
  partTracking : number;
  oemDiscount : number;
  height : any;
  width : any;
  length : any;
  weight : number;
  ptype : any;
  dtype : any;
}

export class OrderPartQuestions {
  answerId: number;
  questionTitle: string;
  questionid: number;
  typeKey: string;
  typeName: string;
  answerName: any;
}

export class CustomPartList {
  vendorpartname: string;
  vendorpartnumber: string;
  ptype : any;
  dtype : any;
}

export class CustomerDiscount {
  Id: number;
  PartId: number;
  Qty: number;
  Discount:string;
  CustomerCost:string;
  isActive:boolean
}

