import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PickListService {

  baseUrl = environment.baseUrl;
  constructor(private _http: HttpClient) { }

  PickListType(orderId: any, companyId: any, BatchType: any, Count: number, userId: string) {
    return this._http.get(this.baseUrl + 'api/PickList/PickListType?orderId=' + orderId + '&companyId=' + companyId + '&VariationType=' + BatchType + '&Count=' + Count + '&userId=' + userId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  PickListTypeByOrderIds(orderId: any, companyId: any, BatchType: any, Count: number, userId: string) {
    return this._http.get(this.baseUrl + 'api/PickList/GetPickListTypeByOrderIds?orderId=' + orderId + '&companyId=' + companyId + '&VariationType=' + BatchType + '&Count=' + Count + '&userId=' + userId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetSinglePickList(orderId: any, companyId: number) {
    return this._http.get(this.baseUrl + 'api/PickList/GetSinglePickList?orderId=' + orderId + '&companyId=' + companyId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetPickListType(companyId: any, type: any, order: any, BatchType: any) {
    return this._http.get(this.baseUrl + 'api/PickList/GetPickListType?companyId=' + companyId + '&type=' + type + '&orderNo=' + order + '&VariationType=' + BatchType, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetPickListTypeNew(companyId: any, type: any, order: any, BatchType: any) {
    return this._http.get(this.baseUrl + 'api/PickList/GetPickListTypeNew?companyId=' + companyId + '&type=' + type + '&orderNo=' + order + '&VariationType=' + BatchType, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetReadyToPickList(companyId: any, BatchType: any) {
    return this._http.get(this.baseUrl + 'api/PickList/GetReadyToPickList?companyId=' + companyId + '&VariationType=' + BatchType, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetPickedData(companyId: any) {
    return this._http.get(this.baseUrl + 'api/PickList/GetPickedData?companyId=' + companyId, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetProcessingData(companyId: any) {
    return this._http.get(this.baseUrl + 'api/PickList/GetProcessingData?companyId=' + companyId, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  CountItemsAndOrdersForPickList(OrderId: any) {
    return this._http.get(this.baseUrl + 'api/PickList/GetCountItemsAndOrdersForPickList?OrderId=' + OrderId, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetPickList(CompanyId: number, OrderPartId: any) {
    return this._http.post(this.baseUrl + 'api/PickList/GetPickList?CompanyId=' + CompanyId, OrderPartId, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetPickListNew(CompanyId: number, OrderPartId: any) {
    return this._http.post(this.baseUrl + 'api/PickList/GetPickListNew?CompanyId=' + CompanyId, OrderPartId, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  InsUpdPicklist(uspPickList: any) {
    return this._http.post(this.baseUrl + 'api/PickList/InsUpdPicklist', uspPickList, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  InsUpdPicklistNew(uspPickList: any, CurrentPickCount: number) {
    return this._http.post(this.baseUrl + 'api/PickList/AddUpdPicklistNew?CurrentPickCount=' + CurrentPickCount, uspPickList, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  ToteUpdate(Id: number, toteId: string) {
    return this._http.post(this.baseUrl + 'api/PickList/AddToteUpdate?Id=' + Id + '&toteId=' + toteId, '', {
      headers: new HttpHeaders()
        .set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader'))
    })
      .pipe(catchError(this.errorHandler));
  }
  GetPartImages(PartId: number) {
    return this._http.get(this.baseUrl + 'api/PickList/GetPartImages?PartId=' + PartId, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  MoveReadyToPick(orderId: number) {
    return this._http.post(this.baseUrl + 'api/PickList/DeletePickingById?orderId=' + orderId, '', { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  //Error Handler Method
  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || "server error.");
  }
}