import { Component, ElementRef, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SharedService } from 'src/app/services/shared.service';
import { hierarchy } from './hierarchy';
import * as _ from 'lodash';

import { ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ConstantsService } from 'src/app/constants.service';
import { DomSanitizer } from '@angular/platform-browser';
import { CompanyService } from 'src/app/services/company/company.service';
@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css']
})
export class CompanyComponent implements OnInit {
  @ViewChild('closeBtn') closeBtn: ElementRef;
  form: FormGroup;
  validation: boolean = false;
  data: any;
  selectedStateValue = '';
  selectedStateId: any;
  taxable: any;  
  taxId: any;
  stateData
  countryData: any;
  stateId: any;
  hierarchyList = [];
  hierarchyListSingle = [];
  childHierarchyList = [];
  addresssLine1: any;
  addresssLine2: any;
  city: any;
  color: any;
  hierarchyName: any;
  zipCode: any;
  selectedhierarchyValue = '';
  selectedhierarchyId;
  isAddHierarchy = false;
  companyName: string;
  phoneNumber: any;
  contactEmail: any;
  fax: any;
  userId: any;
  hierarchyAddresssLine1: any;
  hierarchyAddresssLine2: any;
  hierarchyCity: any;
  selectedHierarchyStateValue = '';
  selectedHierarchyStateId: any;
  hierarchyZipCode: any;
  hierarchyColor: any;
  hierarchyPhoneNumber: any;
  hierarchyEmail: any;
  parentId: any;
  companyData: any;
  companyDetails;
  companyHierarchyDetails: any;
  id: any;
  childHierarchyListPopulate = [];
  childHierarchyListPopulateDetails: any;
  @ViewChild('one') d1: ElementRef;
  hierarchyListChild: any[];
  hierarchyListOfCompany: any[];
  level: number
  EditHierarchyCompany: any[];
  isEditHierarchy = false;
  hierarchyid: any;
  hierarchyParentId: any;
  hierarchyLevelData: any;
  deleteDetail: string;
  deletedItem: any;
  hierarchyLevelDataList: any;
  buttonValue: string;
  isHierarchyData = false;
  arrowCompany = true;
  hierarchyIndex: any;
  buttonStatus: string;
  isShownButton: boolean = true;
  deleteType: any;
  isDeletedChild: boolean;
  rolePermissionsList: any;
  roleId: any;
  hierarchyaddresssLine2: any;
  userList: any;
  recordAlreadyExist = false;
  settingDetailCSS: any;
  settingLogoImage: any;
  companyId: any;
  permissionMessage = '';
  loader: boolean = false;
  clearbutton: boolean = true;
  countrList: any;
  countrySelected = '231';
  createddate: any;
  colorData: any;
  logoName: any;
  laborCost: any;
  notes: any;
  purchaseOrderNo: any;
  prefix: any;
  isPo: any
  isOperational: any
  salesOrderNo: any
  salesOrderPrefix: any
  saleTax: any;  RestoreMultipleImage = [];
  selectedLink: string = "taxable";
  MultipleImage = [];
  IsNonTaxable: Boolean = false;
  imageUrl: any = "/assets/image/add-part.png";
  SalesTaxName: any;
  salePdf: boolean = false;
  ImageType: string; fileToUpload: File;
  DefaultLogoPDF: any;
  @ViewChild('Image') myInputVariable: ElementRef;
  deletedItemImage: any;
  constructor(
    private route: ActivatedRoute,
    public sharedService: SharedService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private constants: ConstantsService,private _sanitizer: DomSanitizer,
    private router: Router,
    private companyService: CompanyService
  ) { }

  ngOnInit(): void {
    $('.dropdowncompanyher').hide();
    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);
    } else {
      this.rolePermissionsList = JSON.parse(localStorage.getItem('rolePermissionsList'));
      this.rolePermissionsList = this.rolePermissionsList.filter(x => x.pageId == 2);
      this.permissionMessage = '';
      if (this.rolePermissionsList != null && this.rolePermissionsList != undefined) {
        if (!this.rolePermissionsList[0].isView) {
          this.permissionMessage += 'view';
        } if (!this.rolePermissionsList[0].isInsert) {
          if (this.permissionMessage != '') {
            this.permissionMessage += ', ';
          }
          this.permissionMessage += 'add';
        } if (!this.rolePermissionsList[0].isUpdate) {
          if (this.permissionMessage != '') {
            this.permissionMessage += ', ';
          }
          this.permissionMessage += 'edit';
        } if (!this.rolePermissionsList[0].isDelete) {
          if (this.permissionMessage != '') {
            this.permissionMessage += ', ';
          }
          this.permissionMessage += 'delete';
        }
      }
      if (this.permissionMessage != '') {
        this.permissionMessage = 'You do not have access to ' + this.permissionMessage + '. Please contact to administrator!';
      }
      this.buttonValue = this.constants.Save;
      this.userId = localStorage.getItem(this.constants.userId);
      this.companyId = localStorage.getItem(this.constants.companyId);

      this.getCompanyHierarchyLevel();
      if (this.route.snapshot.params['id'] != undefined && !Number.isNaN(this.route.snapshot.params['id'])) {

        this.id = Number(this.route.snapshot.params['id']);
        this.buttonValue = this.constants.Update;
        this.userId = localStorage.getItem(this.constants.userId);
        this.GetAllCompanyById();
        this.GetAllCompany();
        this.GetAllCompanyHierarchyByCompanyId();
        this.clearbutton = false;

      }
    }
    this.form = new FormGroup({
      'companyName': new FormControl('', Validators.compose([Validators.required, Validators.minLength(1)])),
      'addressLine1': new FormControl(),
      'addressLine2': new FormControl(),
      'cityName': new FormControl(),
      'stateName': new FormControl('', Validators.compose([Validators.required, Validators.minLength(1)])),
      'zipCode': new FormControl(),
      'contactEmail': new FormControl(),
      'phoneNumber': new FormControl(),
      'hierarchyName': new FormControl(),
      'hierarchyAddresssLine1': new FormControl(),
      'hierarchyAddresssLine2': new FormControl(),
      'hierarchyCity': new FormControl(),
      'hierarchyZipCode': new FormControl(),
      'hierarchyState': new FormControl(),
      'hierarchyColor': new FormControl(),
      'selectedhierarchyValue': new FormControl(),
      'hierarchyEmail': new FormControl(),
      'hierarchyPhoneNumber': new FormControl(),
      //'countrList':new FormControl(),
      'countrySelected': new FormControl(),
      'taxable' : new FormControl(),
      'taxId' : new FormControl()

    });

    this.GetAllCountryDetails();
    this.getAllState(this.countrySelected);
    $(".openpart").on("click", function () {
      var service = this.id;
      var service_id = '.' + service + '-form';
      $(".openpart").toggle();
      $(this).find('i').toggleClass('fa-minus-circle fa-plus-circle')

      $(service_id).next('.table-partdetail').hide();
      $(service_id).find('i').addClass('fa-minus').removeClass('fa-plus');
    });
    this.DefaultLogoPDF = "/assets/image/PDFFile1.png";
  }


  getAllUsers() {
    this.sharedService.getAllUsers().subscribe(
      data => {
        this.loader = true;
        this.data = data;

        this.userList = this.data.dataList.filter(x => x.userId == this.userId);;
        if (this.userList.length == 1) {
          this.roleId = this.userList[0].roleId;
          this.getAllRolePermissions();
          this.loader = false;
        } else {
          this.loader = true;
          this.roleId = '034eef3b-cf41-4a73-9a95-45543fe997ef';
          this.getAllRolePermissions();
          this.loader = false;
        }
      });
  }


  getAllRolePermissions() {
    this.sharedService.GetAllRolePermissionByUserId(this.roleId).subscribe(
      data => {
        this.data = data;
        if (this.data.dataList != undefined && this.data.dataList != null) {
          this.rolePermissionsList = this.data.dataList.filter(x => x.pageName == 'Company');
        }
      });
  }

  changeCountry(event) {

    this.countrySelected = event.target.value;
    this.getAllState(event.target.value);
    this.selectedStateValue = '';
    this.selectedHierarchyStateValue = '';
  }

  validateZipcode(field, type) {
    if (type == 'hierarchy') {
      let zipDigits = this.hierarchyZipCode;
      this.isValidFlg = (zipDigits?.length == 0 || zipDigits?.length == 10);
      if (zipDigits?.length >= 5)
        field.value = zipDigits.substring(0, 5) + "-" + zipDigits.substring(6, 10);;
      this.hierarchyZipCode = field.value;
    } else {
      let zipDigits = this.zipCode;
      this.isValidFlg = (zipDigits?.length == 0 || zipDigits?.length == 10);
      if (zipDigits?.length >= 5)
        field.value = zipDigits.substring(0, 5) + "-" + zipDigits.substring(6, 10);;
      this.zipCode = field.value;
    }

  }
  closeModal(): void {
    this.closeBtn.nativeElement.click();
    this.validation = false;
    this.hierarchyName = '';
    this.hierarchyAddresssLine1 = '';
    this.hierarchyAddresssLine2 = '';
    this.hierarchyCity = '';
    this.selectedHierarchyStateId = '';
    this.selectedHierarchyStateValue = '';
    this.hierarchyZipCode = '';
    this.fax = '';
    this.hierarchyParentId = '';
    this.selectedhierarchyId = '';
    this.selectedhierarchyValue = '';
    this.hierarchyEmail = '';
    this.hierarchyPhoneNumber = '';
  }
  changeState(event) {
    this.selectedStateId = event;
  }
  changeHierarchy(event) {
    this.selectedhierarchyId = event;
  }
  changeHierarchyState(event) {
    this.selectedHierarchyStateId = event;
  }


  GetAllCompanyHierarchyByCompanyId() {
    this.loader = true;
    this.companyService.GetAllCompanyHierarchyByCompanyId(this.id).subscribe(
      data => {

        this.data = data;
        this.hierarchyListOfCompany = this.data.dataList;
        this.companyHierarchyDetails = this.data.dataList.filter(x => x.parentId == this.id);
        for (let i = 0; i < this.hierarchyListOfCompany.length; i++) {
          this.hierarchyList[i] = this.companyHierarchyDetails[i];
        }

      }
    )
    this.loader = false;
  }


  GetAllCompanyById() {
    this.companyService.GetAllCompanyById(this.id== undefined ? 0 : this.id).subscribe(
      data => {
        this.data = data;
        if (this.data.data != undefined || this.data.data != null) {
          this.companyDetails = this.data.data;
          this.companyName = this.companyDetails.companyName;
          this.addresssLine1 = this.companyDetails.addressLine1
          this.addresssLine2 = this.companyDetails.addressLine2;
          this.city = this.companyDetails.city;
          this.selectedStateValue = this.companyDetails.stateId;
          this.zipCode = this.companyDetails.zipCode;
          this.contactEmail = this.companyDetails.contactEmail;
          this.phoneNumber = this.companyDetails.phoneNumber;
          this.countrySelected = this.companyDetails.countryId;
          this.createddate = this.companyDetails.createdDate;
          //
          this.colorData = this.companyDetails.color;
          this.logoName = this.companyDetails.getLogoName;
          this.laborCost = this.companyDetails.laborCost;
          this.notes = this.companyDetails.notes;
          this.purchaseOrderNo = this.companyDetails.purchaseOrderNo;
          this.prefix = this.companyDetails.prefix;
          this.isPo = this.companyDetails.isPo;
          this.isOperational = this.companyDetails.isOperational;
          this.salesOrderNo = this.companyDetails.salesOrderNo;
          this.salesOrderPrefix = this.companyDetails.salesOrderPrefix;
          this.saleTax = this.companyDetails.saleTax;
          this.taxable = this.companyDetails.taxable;
          this.selectedLink = this.companyDetails.taxable;
          this.taxId = this.companyDetails.taxId;
          if (this.companyDetails.salesCertificate != "") {
            this.imageUrl = this.companyDetails.salesCertificate;
            let ojtmultiplepart1 = {
              imageUrl: this._sanitizer.bypassSecurityTrustUrl(this.imageUrl),
              imageName: this.companyDetails.salesCertificateName
            }

            if (this.imageUrl != null) {
              this.MultipleImage.push(ojtmultiplepart1);
            }
          }
          if (this.taxable  == "nontaxable") {
            if (this.RestoreMultipleImage.length != 0) {
              this.MultipleImage = this.RestoreMultipleImage;
            }
            if (this.MultipleImage.length == 0) {
              this.IsNonTaxable = true;
            }
            else {
              this.IsNonTaxable = false;
            }
          }
          else {
            this.IsNonTaxable = false;
            this.RestoreMultipleImage = this.MultipleImage;
            this.MultipleImage = [];
          }
          this.salePdf = false;
          //
          this.getAllState(this.companyDetails.countryId);
        }
      }
    )
  }


  GetAllCompany() {
    this.companyService.GetAllCompany(this.companyId).subscribe(
      data => {

        this.data = data;
        if (this.data.dataList != null && this.data.dataList != undefined) {
          this.childHierarchyListPopulateDetails = this.data.dataList.filter(x => x.id == 1 || x.parentId == 1);
          for (let i = 0; i < this.childHierarchyListPopulateDetails.length; i++) {
            this.childHierarchyListPopulate[i] = this.childHierarchyListPopulateDetails[i];

          }
        }

      }
    )
  }

  getAllState(countryId) {
    this.sharedService.getAllState(countryId).subscribe(
      data => {
        this.data = data;
        this.stateData = this.data.dataList;
      });
  }

  getCompanyHierarchyLevel() {
    this.companyService.getCompanyHierarchyLevel().subscribe(
      data => {
        this.data = data;
        this.hierarchyLevelData = this.data.dataList;
      });
  }

  getAllCountry() {
    this.sharedService.getAllCountry().subscribe(
      data => {
        this.data = data;
        this.countryData = this.data.dataList
      });
  }

  public addNewCompamy(): void {

    this.clearbutton = true;
    this.loader = true;
    this.validation = true;
    if (this.form.controls.companyName.status === this.constants.Invalid
      || this.form.controls.contactEmail.status === this.constants.Invalid
      || this.form.controls.phoneNumber.status === this.constants.Invalid
      || this.form.controls.addressLine1.status === this.constants.Invalid
      || this.form.controls.cityName.status === this.constants.Invalid
      || this.form.controls.stateName.value === this.constants.Invalid
      || this.form.controls.zipCode.status === this.constants.Invalid
      || this.selectedStateValue === ""

    ) {
      this.loader = false;
      return;
    }
    const obj = {};
    obj['CompanyName'] = this.companyName;
    obj['ContactEmail'] = this.contactEmail;
    obj['PhoneNumber'] = this.phoneNumber;
    obj['Fax'] = this.fax;
    obj['AddressLine1'] = this.addresssLine1;
    obj['AddressLine2'] = this.addresssLine2;
    obj['City'] = this.city;
    obj['StateId'] = this.selectedStateId;
    obj['ZipCode'] = this.zipCode;
    obj["CountryId"] = parseInt(this.countrySelected);
    obj['TaxId'] = this.taxId == undefined ? 0 : Number(this.taxId);
    obj['Taxable'] = this.selectedLink;
    for (var j = 0; j < this.MultipleImage.length; j++) {
      if (j == 0) {
        obj['SalesCertificate'] = this.MultipleImage[j].imageUrl.changingThisBreaksApplicationSecurity;
        obj['SalesCertificateName'] = this.MultipleImage[j].imageName;
        obj['SalePdf'] = this.salePdf;
      }
    }

    if (this.id != undefined && !Number.isNaN(this.id)) {
      obj['Id'] = this.id;
      obj['ModifiedBy'] = this.userId;
      obj['CreatedBy'] = this.userId;
      obj['CreatedDate'] = this.createddate;
      //
      obj['Color'] = this.colorData;
      obj['LogoName'] = this.logoName;
      obj['LaborCost'] = this.laborCost;
      obj['Notes'] = this.notes;
      obj['PurchaseOrderNo'] = this.purchaseOrderNo;
      obj['Prefix'] = this.prefix;
      obj['IsPo'] = this.isPo;
      obj['IsOperational'] = this.isOperational;
      obj['SalesOrderNo'] = this.salesOrderNo;
      obj['SalesOrderPrefix'] = this.salesOrderPrefix;
      obj['SaleTax'] = this.saleTax;
      
      this.companyService.updateCompany(obj)
        .subscribe(
          data => {
            this.data = data;
            this.loader = true;
            switch (data.statusCode) {

              case 200:
                this.closeModal();
                this.GetAllCompanyById();
                this.GetAllCompanyHierarchyByCompanyId();
                $("#add_update").show();
                this.taxable = "";
                this.imageUrl = "";
                this.MultipleImage = [];
                this.RestoreMultipleImage = [];
                this.clearbutton = false;
                break;
              case 400:
                $("#wrong_msg").show();
                break;
              default:
                $("#wrong_msg").show();
            }
            this.loader = false;
          }
        );
    } else {
      obj['CreatedBy'] = this.userId;
      this.companyService.addNewCompany(obj)
        .subscribe(
          data => {
            this.data = data;
            this.loader = true;
            switch (data.statusCode) {
              case 200:
                this.id = Number(data.data);
                this.GetAllCompanyById();
                this.GetAllCompanyHierarchyByCompanyId();
                $("#add_success").show();
                this.recordAlreadyExist = false;
                this.taxable = "";
                this.imageUrl = "";
                this.MultipleImage = [];
                this.RestoreMultipleImage = [];
                break;
              case 400:
                if (data.errorMessage === 'Record Already Exist') {
                  this.recordAlreadyExist = true;
                } else {
                  $("#wrong_msg").show();
                }
                break;
              default:
                $("#wrong_msg").show();
            }
            this.loader = false;
          }
        );
    }
  }
  openCompanyHierarchy() {
    this.validation = false;
    this.isAddHierarchy = true;
    this.id = Number(this.route.snapshot.params['id']);
  }

  resetCompanyData() {
    this.companyName = '';
    this.selectedhierarchyValue = '';
    this.city = '';
    this.zipCode = '';
    this.color = '';
    this.selectedStateValue = '';
    this.addresssLine1 = '';
    this.addresssLine2 = '';
    this.isAddHierarchy = false;
    this.contactEmail = '';
    this.phoneNumber = '';
    this.validation = false;
    this.recordAlreadyExist = false;
    this.hierarchyZipCode = '';
    this.hierarchyAddresssLine2 = '';
    this.hierarchyAddresssLine1 = '';
    this.hierarchyEmail = '';
    this.hierarchyName = '';
    this.hierarchyPhoneNumber = '';
    this.hierarchyCity = '';
    this.selectedHierarchyStateValue = '';
  }

  deleteCompanyHierarchy(item: any, type: string) {
    this.isShownButton = false;
    if (type == 'parent') {
      var parentRecords = [];
      parentRecords = this.companyDetails;
      if (parentRecords.length > 0) {
        var updateChilds = [];
        updateChilds = this.hierarchyListOfCompany.filter(x => x.parentId == item.id);
        for (let i = 0; i < updateChilds.length; i++) {
          updateChilds[i].parentId = this.companyDetails.id;
          this.updateParenBeforeDelete(updateChilds[i]);
        }
      }
    } else {
      var parentRecords = [];
      parentRecords = this.hierarchyListOfCompany.filter(x => x.id == item.parentId);
      if (this.hierarchyListOfCompany.filter(x => x.parentId == item.id).length > 0) {
        var updateChilds = [];
        updateChilds = this.hierarchyListOfCompany.filter(x => x.parentId == item.id);
        for (let i = 0; i < updateChilds.length; i++) {
          updateChilds[i].parentId = parentRecords[0].id;
          this.updateParenBeforeDelete(updateChilds[i]);
        }

      }

    }

    this.companyService.deleteCompanyHierarchy(item).subscribe(
      data => {
        this.data = data;
        switch (data.statusCode) {
          case 200:
            this.GetAllCompanyHierarchyByCompanyId();
            $("#delete_success").show();
            break;
          case 400:
            $("#wrong_msg").show();
            break;
          default:
            $("#wrong_msg").show();

        }
      });
  }


  addNewCompanyHierarchy(): void {

    this.validation = true;
    if (this.form.controls.hierarchyName.status === this.constants.Invalid
      || this.form.controls.selectedhierarchyValue.status === this.constants.Invalid
      || this.form.controls.hierarchyEmail.status === this.constants.Invalid
      || this.form.controls.hierarchyPhoneNumber.status === this.constants.Invalid
      || this.form.controls.hierarchyAddresssLine1.status === this.constants.Invalid
      || this.form.controls.hierarchyCity.value === ''
      // || this.form.controls.hierarchyState.status === ''
      || this.form.controls.hierarchyZipCode.status === this.constants.Invalid
      || this.selectedHierarchyStateValue == ''

    ) {
      return;
    }
    const obj = {};
    obj['CompanyName'] = this.hierarchyName;
    obj['AddressLine1'] = this.hierarchyAddresssLine1;
    obj['AddressLine2'] = this.hierarchyAddresssLine2;
    obj['City'] = this.hierarchyCity;
    obj['StateId'] = this.selectedHierarchyStateId;
    obj['ZipCode'] = this.hierarchyZipCode;
    obj['ContactEmail'] = this.contactEmail;
    obj['PhoneNumber'] = this.phoneNumber;
    obj['Fax'] = this.fax;
    obj['CompanyHierarchyId'] = this.selectedhierarchyId;
    obj['ContactEmail'] = this.hierarchyEmail;
    obj['PhoneNumber'] = this.hierarchyPhoneNumber;
    obj["CountryId"] = parseInt(this.countrySelected);
    if (!this.isEditHierarchy) {
      obj['ParentId'] = this.id;
      obj['CreatedBy'] = this.userId;

      this.companyService.addNewCompanyHierarchy(obj)
        .subscribe(
          data => {
            this.data = data;
            this.loader = true;
            switch (data.statusCode) {
              case 200:
                this.id = Number(this.route.snapshot.params['id']);
                this.recordAlreadyExist = false;

                this.closeModal();
                this.GetAllCompanyHierarchyByCompanyId();
                $("#add_success").show();

                this.recordAlreadyExist = false;
                break;
              case 400:
                if (data.errorMessage === 'Record Already Exist') {
                  this.recordAlreadyExist = true;
                } else {
                  $("#wrong_msg").show();

                }
              case 0:
                this.toastr.error('Record Already Exist');
                break;
              default:
                $("#wrong_msg").show();

            }
            this.loader = false;
          }
        );
    } else {
      
      obj['ModifiedBy'] = this.userId;
      obj['Id'] = this.hierarchyid;
      obj['ParentId'] = this.hierarchyParentId;
      obj['CreatedBy'] = this.userId;
      obj['CreatedDate'] = this.createddate;
      //
      obj['Color'] = this.colorData;
      obj['LogoName'] = this.logoName;
      obj['LaborCost'] = this.laborCost;
      obj['Notes'] = this.notes;
      obj['PurchaseOrderNo'] = this.purchaseOrderNo;
      obj['Prefix'] = this.prefix;
      obj['IsPo'] = this.isPo;
      obj['IsOperational'] = this.isOperational;
      obj['SalesOrderNo'] = this.salesOrderNo;
      obj['SalesOrderPrefix'] = this.salesOrderPrefix;
      obj['SaleTax'] = this.saleTax;
      //
      this.companyService.updateCompanyHierarchy(obj)
        .subscribe(
          data => {
            this.data = data;
            this.loader = true;
            switch (data.statusCode) {
              case 200:
                this.id = Number(this.route.snapshot.params['id']);
                this.closeModal();
                this.GetAllCompanyHierarchyByCompanyId();
                $("#add_update").show();

                this.isEditHierarchy = false;
                break;
              case 400:
                $("#wrong_msg").show();

                break;
              default:
                $("#wrong_msg").show();

            }
            this.loader = false;
          }
        );

    }
  }

  callHierarchy(hierarchyItem: any) {

    this.isShownButton = true;
    this.buttonValue = this.constants.Save;
    this.buttonStatus = this.constants.AddCompanyHierarchy;
    this.validation = false;
    this.isEditHierarchy = false;
    this.id = hierarchyItem.id;
    this.hierarchyLevelDataList = this.hierarchyLevelData.filter(x => x.id == hierarchyItem.companyHierarchyId);
    this.hierarchyLevelDataList = this.hierarchyLevelData.filter(x => x.ordering! > this.hierarchyLevelDataList[0].ordering);
  }

  openEditHierarchy(hierarchyList: any) {

    this.isShownButton = true;
    this.buttonValue = this.constants.Update;
    this.buttonStatus = this.constants.UpdateCompanyHierarchy;
    this.hierarchyLevelDataList = '';
    this.hierarchyLevelDataList = this.hierarchyLevelData.filter(x => x.id == hierarchyList.companyHierarchyId);
    this.hierarchyLevelDataList = this.hierarchyLevelData.filter(x => x.ordering! >= this.hierarchyLevelDataList[0].ordering);
    this.isEditHierarchy = true;
    this.hierarchyName = hierarchyList.companyName.split("(")[0];
    this.hierarchyAddresssLine1 = hierarchyList.addressLine1;
    this.hierarchyAddresssLine2 = hierarchyList.addressLine2;
    this.hierarchyCity = hierarchyList.city;
    this.selectedHierarchyStateId = hierarchyList.stateId;
    this.selectedHierarchyStateValue = hierarchyList.stateId;
    this.hierarchyZipCode = hierarchyList.zipCode;
    this.fax = hierarchyList.fax;
    this.hierarchyid = hierarchyList.id;
    this.hierarchyParentId = hierarchyList.parentId;
    this.selectedhierarchyId = hierarchyList.companyHierarchyId;
    this.selectedhierarchyValue = hierarchyList.companyHierarchyId;
    this.hierarchyEmail = hierarchyList.contactEmail;
    this.hierarchyPhoneNumber = hierarchyList.phoneNumber;
    this.countrySelected = hierarchyList.countryId;

    this.colorData = this.companyDetails.color;
    this.logoName = this.companyDetails.getLogoName;
    this.laborCost = this.companyDetails.laborCost;
    this.notes = this.companyDetails.notes;
    this.purchaseOrderNo = this.companyDetails.purchaseOrderNo;
    this.prefix = this.companyDetails.prefix;
    this.isPo = this.companyDetails.isPo;
    this.isOperational = this.companyDetails.isOperational;
    this.salesOrderNo = this.companyDetails.salesOrderNo;
    this.salesOrderPrefix = this.companyDetails.salesOrderPrefix;
    this.saleTax = this.companyDetails.saleTax;


    this.getAllState(hierarchyList.countryId)
  }


  viewHierarchy(hierarchyList: any) {

    this.isShownButton = false;
    this.buttonValue = this.constants.Update;
    this.buttonStatus = this.constants.ViewCompanyHierarchy;
    this.hierarchyLevelDataList = '';
    this.hierarchyLevelDataList = this.hierarchyLevelData.filter(x => x.id == hierarchyList.companyHierarchyId);
    this.hierarchyLevelDataList = this.hierarchyLevelData.filter(x => x.ordering! >= this.hierarchyLevelDataList[0].ordering);
    this.isEditHierarchy = true;
    this.hierarchyName = hierarchyList.companyName.split("(")[0];
    this.hierarchyAddresssLine1 = hierarchyList.addressLine1;
    this.hierarchyAddresssLine2 = hierarchyList.addressLine2;
    this.hierarchyCity = hierarchyList.city;
    this.selectedHierarchyStateId = hierarchyList.stateId;
    this.selectedHierarchyStateValue = hierarchyList.stateId;
    this.hierarchyZipCode = hierarchyList.zipCode;
    this.fax = hierarchyList.fax;
    this.selectedhierarchyId = hierarchyList.companyHierarchyId;
    this.selectedhierarchyValue = hierarchyList.companyHierarchyId;
    this.hierarchyEmail = hierarchyList.contactEmail;
    this.hierarchyPhoneNumber = hierarchyList.phoneNumber;
  }

  ngAfterViewInit() {

    this.settingDetailCSS = localStorage.getItem("settingDetailCSS");
    this.settingLogoImage = localStorage.getItem("settingImageLogo");
    this.SetSettingCSS(this.settingDetailCSS, this.settingLogoImage);

  }

  deletePopup(item, content, type, grandParentData,) {
    var childItems = [];
    childItems = this.hierarchyListOfCompany.filter(x => x.parentId == item.id)
    if (childItems.length > 0) {
      this.isDeletedChild = false;
      this.modalService.open(content, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
      this.deleteDetail = 'Please delete childs of ' + item.companyName + '?';
    } else {
      this.isDeletedChild = true;
      this.deletedItem = '';
      this.modalService.open(content, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
      if (grandParentData != undefined) {
        this.deleteDetail = 'Do you want to delete ' + grandParentData.companyName + '->' + item.companyName + '?';
      } else {
        this.deleteDetail = 'Do you want to delete ' + item.companyName + '?';
      }
      this.deletedItem = item;
      this.deleteType = type;
    }

  }

  toggleHierarchy(index: any) {
    this.hierarchyIndex = index;
    this.isHierarchyData = !this.isHierarchyData;
    this.arrowCompany = !this.arrowCompany;
  }

  updateParenBeforeDelete(item: any) {
    const obj = item;
    obj['ModifiedBy'] = this.userId;
    this.companyService.updateCompanyHierarchy(obj)
      .subscribe(
        data => {
          this.data = data;
          this.loader = true;
          switch (data.statusCode) {
            case 200:
              this.id = Number(this.route.snapshot.params['id']);
              this.closeModal();
              this.GetAllCompanyHierarchyByCompanyId();
              break;
            case 400:
              $("#wrong_msg").show();

              break;
            default:
              $("#wrong_msg").show();

          }
          this.loader = false;
        }
      );
  }

  public isValidFlg: boolean = true;
  validatePhoneNo(field) {
    var phoneNumDigits = field.value.replace(/\D/g, '');
    this.isValidFlg = (phoneNumDigits.length == 0 || phoneNumDigits.length == 10);
    var formattedNumber = phoneNumDigits;
    if (phoneNumDigits.length >= 6)
      formattedNumber = '(' + phoneNumDigits.substring(0, 3) + ') ' + phoneNumDigits.substring(3, 6) + '-' + phoneNumDigits.substring(6, 10);
    else if (phoneNumDigits.length >= 3)
      formattedNumber = '(' + phoneNumDigits.substring(0, 3) + ') ' + phoneNumDigits.substring(3);
    field.value = formattedNumber;
  }


  SetSettingCSS(settingCSS, logoImagePath) {
    if (settingCSS != null) {
      let headerCss = document.getElementsByClassName(this.constants.CssHealerClass) as HTMLCollectionOf<HTMLElement>;
      if (headerCss.length != 0) {
        headerCss[0].style.backgroundColor = settingCSS.split(',')[0];
      }
      let saveButtonCss = document.getElementsByClassName(this.constants.CssSaveBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (saveButtonCss.length != 0) {
        saveButtonCss[0].style.backgroundColor = settingCSS.split(',')[1];
        $(".blue-bg").css("background", settingCSS.split(',')[1]);
      }
      let cencelButtonCss = document.getElementsByClassName(this.constants.CssCancelBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (cencelButtonCss.length != 0) {
        cencelButtonCss[0].style.backgroundColor = settingCSS.split(',')[2];
        $(".darkred-bg").css("background", settingCSS.split(',')[2]);
      }
    }


    if (logoImagePath != null && logoImagePath != "") {
      (document.getElementById('hrdlogoImage') as HTMLImageElement).src = logoImagePath;
    }

  }

  GetAllCountryDetails() {

    this.sharedService.GetCountry().subscribe(
      data => {

        this.data = data;
        this.countrList = this.data.dataList;
      }
    )
  }
  OpenpartCompany(id) {
    var service = id;
    var service_id = '.openpartCompany-' + service + '-herirchylavel2';
    $(service_id).toggle();
    $("#openpartCompany-" + id).find('i').toggleClass('fa-plus-circle fa-minus-circle')
    $(service_id).next('.table-partdetail').hide();
  }
  Openpart(id) {
    var service = id;
    var service_id = '.openpart-' + service + '-herirchylavel3';
    $(service_id).toggle();
    $("#openpart-" + id).find('i').toggleClass('fa-plus-circle fa-minus-circle');
    $(service_id).next('.table-partdetail').hide();
    $(service_id).find('i').addClass('fa-minus').removeClass('fa-plus');
  }

  addsuccess() {
    $("#add_success").hide();
    this.router.navigate(['/company', this.id]);
  }
  updateitem() {
    $("#add_update").hide();
  }
  deleteitem() {
    $("#delete_success").hide();
  }
  msgitem() {
    $("#wrong_msg").hide();
  }

  changeTaxable(event: any) {
    this.selectedLink = event.target.value;
    if (event.target.value == "nontaxable") {
      if (this.RestoreMultipleImage.length != 0) {
        this.MultipleImage = this.RestoreMultipleImage;
      }
      if (this.MultipleImage.length == 0) {
        this.IsNonTaxable = true;
      }
      else {
        this.IsNonTaxable = false;
      }
    }
    else {
      this.IsNonTaxable = false;
      this.RestoreMultipleImage = this.MultipleImage;
      this.MultipleImage = [];
    }
  }
  setOnlyNumber(event) {
    var k;
    k = event.charCode;  //         k = event.keyCode;  (Both can be used)
    return ((k > 64 && k < 91) || (k > 96 && k < 123) || k == 8 || k == 32 || (k >= 48 && k <= 57));
  }
  AddImage() {
    if (this.imageUrl != undefined && this.imageUrl != "" && this.imageUrl != "/assets/image/add-part.png") {
      let ojtmultiplepart = {
        imageUrl: this._sanitizer.bypassSecurityTrustHtml(this.imageUrl),
        imageName: this.SalesTaxName
      }
      if (this.MultipleImage.length < 1) {
        this.salePdf = true;
        this.MultipleImage.push(ojtmultiplepart);
        this.imageUrl = '';
        this.SalesTaxName = '';
        this.myInputVariable.nativeElement.value = "";
        if (this.selectedLink == "nontaxable") {
          this.IsNonTaxable = false;
        }
      }
      else {
        $("#Imagevlaidation").show();
        this.ImageType = "Sales Tax Certificate";
        this.imageUrl = '';
        this.SalesTaxName = '';
        this.myInputVariable.nativeElement.value = "";
      }
    }
  }
  ChngSettingImage(files: FileList) {
    this.fileToUpload = files.item(0);
    var reader = new FileReader()
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
      this.SalesTaxName = this.fileToUpload.name;
      const SalesTax = this.SalesTaxName.split(".pdf");
      this.SalesTaxName = SalesTax[0];
    }
    reader.readAsDataURL(this.fileToUpload);
  }
  DeleteImageModalpopup(i, content) {
    this.modalService.open(content, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
    this.deleteDetail = 'Are you sure, you want to delete?';
    this.deletedItemImage = i;
  }
  DeleteImage() {
    let index = this.deletedItemImage;
    if (index != null) {
      this.MultipleImage.splice(index, 1);
      if (this.selectedLink == "nontaxable") {
        this.IsNonTaxable = true;
      }

    }
    else {

    }
  }
  viewImage(value) {
    let value1 = "";
    value = value.changingThisBreaksApplicationSecurity;
    value1 = value.split(",");
    var blob = this.b64toBlob(value1[1], "application/pdf");
    let a = document.createElement("a");
    document.body.appendChild(a);
    var url = window.URL.createObjectURL(blob);
    a.href = url;
    // let pdfWindow = window.open("")
    // pdfWindow.document.write("<iframe width='100%' height='100%' src='" + encodeURI(url) + "'></iframe>");
    a.download = String("PDF.pdf");
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
  }
  public b64toBlob(b64Data, contentType) {
    contentType = contentType || '';
    let sliceSize = 512;

    var byteCharacters = window.atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
}
