import { IfStmt } from '@angular/compiler';
import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
// import { TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { Subject } from 'rxjs';
import { ConstantsService } from 'src/app/constants.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { GlobalChangeService } from 'src/app/services/global-change.service';
import { PickListService } from 'src/app/services/pickList/pick-list.service';
import { SharedService } from 'src/app/services/shared.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-picklist',
  templateUrl: './picklist.component.html',
  styleUrls: ['./picklist.component.css']
})
export class PicklistComponent implements OnInit {
  /*Datatable Property*/
  @ViewChildren(DataTableDirective)
  dtElement: QueryList<DataTableDirective>;
  dtOptions: DataTables.Settings = {};
  dtOptions1: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  dtTrigger1: Subject<any> = new Subject<any>();
  formSearch: FormGroup;
  userGroupObjects_search = new Array();
  companyList_search: any;
  data: any;
  value_search: number;
  userId: any;
  pickListFor: any = '';
  ListAngle: any;
  loader: boolean = false;
  SearchValidation: any;
  // config = TreeviewConfig.create({
  //   hasAllCheckBox: true,
  //   hasFilter: true,
  //   hasCollapseExpand: true,
  //   decoupleChildFromParent: false,
  //   maxHeight: 400
  // });
  rolePermissionsList: any;
  permissionMessage: any;
  companyLoginId: any;
  PickListForList = [];
  orderNo: any;
  ListShow: boolean = false;
  ListDiv: boolean = false;
  pickList = [];
  PickItem: string;
  PickDetails = [];
  UpdatingMsg: string;
  IsUpdating: boolean;
  newPickList = [];
  ShowPickList: boolean = false;
  SaveIndex: any;
  partListCustomPart = [];
  SpecialPartList = [];
  partListWithoutCustomPart = [];
  srNo1: number;
  custom: any;
  qtyLabel = [];
  openId: any;
  openTracking: any;
  openPartId: any;
  openCompanyId: any;
  pickingProp: boolean = false;
  customVal: any;
  constructor(
    public sharedService: SharedService,
    public pickListService: PickListService,
    private router: Router,
    private constants: ConstantsService,
    private globalChangeService: GlobalChangeService,
    private companyService: CompanyService
  ) { }

  ngOnInit(): void {
    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);
    } else {
      this.userId = localStorage.getItem(this.constants.userId);

    }
    this.formSearch = new FormGroup({
      'pickListFor': new FormControl(),
      'orderNo': new FormControl(),
    });
    this.rolePermissionsList = JSON.parse(localStorage.getItem('rolePermissionsList'));
    this.rolePermissionsList = this.rolePermissionsList.filter(x => x.pageId == 13);
    this.permissionMessage = '';
    if (this.rolePermissionsList != null && this.rolePermissionsList != undefined) {
      if (!this.rolePermissionsList[0].isView) {
        this.permissionMessage += 'view';
      } if (!this.rolePermissionsList[0].isInsert) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'add';
      } if (!this.rolePermissionsList[0].isUpdate) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'edit';
      } if (!this.rolePermissionsList[0].isDelete) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'delete';
      }
    }
    if (this.permissionMessage != '') {
      this.permissionMessage = 'You do not have access to ' + this.permissionMessage + '. Please contact to administrator!';
    }
    this.companyLoginId = localStorage.getItem(this.constants.companyId);
    //this.getAllCompany_search(this.companyLoginId);
    this.PickListForList = this.constants.PickListFor;

    this.dtOptions = {
      pagingType: this.constants.full_numbers,
      pageLength: 10,
      ordering: false
    };
    this.dtOptions1 = {
      pagingType: this.constants.full_numbers,
      pageLength: 10,
      ordering: false
    };

    //ankit implementaion
    // get company by default set value
    var company = this.globalChangeService.getGlobalCompany();
    if (company > 0) {
      this.value_search = company;
      this.getAllCompany_search(company);
    }
    else {
      this.ErrorModal('Error', 'Please select company.');
    }

    //on change function 
    this.globalChangeService.dropdownChange$.subscribe((data) => {
      //this.onValueChange_search(data);
      this.getAllCompany_search(data);
    })
  }

  getAllCompany_search(companyId) {
    this.companyService.GetDropdownCompanyHierarchyModel(this.companyLoginId).subscribe(
      data => {
        this.data = data;
        this.companyList_search = this.data.dataList;
        // this.companyList_search.forEach((x) => {
        //   this.userGroupObjects_search.push(new TreeviewItem(x, false));
        // });
        this.value_search = Number(companyId);
      });
  }
  ChangePick(e) {
    if (e != "Individual SO") {
      this.orderNo = undefined;
      this.SearchValidation = false;
    }

  }
  searchPickList() {
    this.loader = true;
    if (this.pickListFor == "") {
      this.SearchValidation = true;
      this.loader = false;
      this.pickList = [];
      return;
    } else {
      if (this.pickListFor != "Individual SO") {
        this.ShowPickList = false;
        this.newPickList = [];
        this.partListWithoutCustomPart = [];
        this.partListCustomPart = [];
        this.SpecialPartList = [];
        if (!this.pickListFor || this.value_search == 0) {
          this.SearchValidation = true;
          return;
        }
        this.orderNo = this.orderNo == "" ? undefined : this.orderNo;
        this.SearchValidation = false;
        this.ListShow = true;
        this.ListDiv = true;
        this.ListAngle = this.constants.UpArrow;
        $('#datatable').DataTable().destroy();
        this.dtTrigger.next('');
        this.pickList = [];
        this.pickListService.GetPickListType(this.value_search, this.pickListFor, this.orderNo, '').subscribe(
          data => {
            this.data = data;
            if (this.orderNo != undefined) {
              this.pickList = this.data.dataList.filter(x => x.soNo == this.orderNo && (x.pickCount != 0 || x.partialShipment == true));
            }
            else {
              this.pickList = this.data.dataList.filter(x => x.pickCount != 0 || x.partialShipment == true);
            }
            this.loader = false;
            this.rerender("one");
          }
        )
      }
      else {
        this.pickList = [];
        this.partListWithoutCustomPart = [];
        this.partListCustomPart = [];
        this.SpecialPartList = [];
        if (this.orderNo != undefined) {
          this.orderNo = this.orderNo.trim();
        }
        else {
          this.SearchValidation = true;
          this.loader = false;
          this.pickList = [];
          return;
        }
        this.ShowPickList = false;
        this.newPickList = [];
        if (!this.pickListFor || this.value_search == 0 || !this.orderNo) {
          this.SearchValidation = true;
          this.pickList = [];
          return;
        }

        this.ListShow = true;
        this.ListDiv = true;
        this.ListAngle = this.constants.UpArrow;
        $('#datatable').DataTable().destroy();
        this.dtTrigger.next('');
        this.pickList = [];
        this.pickListService.GetPickListType(this.value_search, this.pickListFor, this.orderNo, '').subscribe(
          data => {
            this.data = data;
            const pickList = this.data.dataList.filter(x => (x.pickCount != 0));
            this.pickList = pickList;
            this.loader = false;
            this.rerender("one");
          }
        )
      }
    }


  }
  ListDivShowHide() {
    if (this.ListDiv == true) {
      this.ListDiv = false;
      this.ListAngle = this.constants.DownArrow;
    }
    else if (this.ListDiv == false) {
      this.ListDiv = true;
      this.ListAngle = this.constants.UpArrow;
    }
  }

  rerender(type): void {
    if (type == 'one') {
      this.dtElement.first.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next('');
      });
    }
    else {
      this.dtElement.last.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger1.next('');
      });
    }
  }


  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this.dtTrigger1.unsubscribe();
  }

  SelectedPickIcon(id) {
    this.pickList[id].scolor = !this.pickList[id].scolor;
    this.ShowPickList = false;
    this.newPickList = [];
  }

  PickItems() {
    if (this.pickList.length == 0) {
      this.PickItem = "There is no item for pick";
      $('#pickModel').show();
    }
    else {
      var count = 0;
      this.pickList.forEach(element => {
        if (element.scolor == true) {
          count = count + 1;
        }
      });

      if (count == 0) {
        this.PickItem = "There is no item for picklist, please select";
        $('#pickModel').show();
      }
      else {
        this.CreateNewPickList();
      }
    }
  }
  CreateNewPickList() {
    this.srNo1 = 1;
    this.partListWithoutCustomPart = [];
    this.partListCustomPart = [];
    this.loader = true;
    let OrderIds = '';
    this.ShowPickList = true;
    const pickList = this.pickList.filter(x => x.scolor == true);
    pickList.forEach(orderLst => {
      OrderIds += orderLst.orderId + ',';
    });
    // $('#datatable1').DataTable().destroy();
    // this.dtTrigger1.next('');
    this.sharedService.GetPartPickListType(OrderIds, this.value_search, this.pickListFor, '', 0).subscribe(
      data => {
        this.loader = false;
        this.data = data;
        this.newPickList = this.data.dataList;
        this.newPickList.forEach(element => {
          element.scolor = false;
        });
        this.partListWithoutCustomPart = this.newPickList.filter(x => (x.virtual == false || x.virtual == null) && x.customPartId == null);
        this.partListWithoutCustomPart.forEach(partLst => {
          partLst.srNo = this.srNo1;
          this.srNo1 += 1;

        });
        this.partListCustomPart = this.newPickList.filter(x => x.virtual == true);
        this.partListCustomPart.forEach(partLst => {
          if (this.newPickList.filter(x => x.customPartId == partLst.partId).length > 0) {
            partLst.srNo = this.srNo1;
            if (partLst.orderTypeId == 2) {
              partLst.PartList = this.newPickList.filter(x => x.customPartId == partLst.partId && x.orderId == partLst.orderId);
              partLst.srNo = this.srNo1;
            }
            else {
              partLst.PartList = this.newPickList.filter(x => x.customPartId == partLst.partId && x.orderId == partLst.orderId);
              partLst.srNo = this.srNo1;
            }
            this.srNo1 += 1;
          }
        });
        this.SpecialPartList = [];
        this.SpecialPartList.push(...this.partListWithoutCustomPart, ...this.partListCustomPart);
        this.SpecialPartList = this.SpecialPartList.filter(x => x.readytoShip != 0);
        this.SpecialPartList = this.SpecialPartList.sort((a, b) => (a.orderId > b.orderId ? 1 : -1));
      }
    )
  }

  OpenSerialModel(id, cid, pid, Tracking: any) {
    if (Tracking != "3" && Tracking != "0") {

      this.loader = true;
      this.pickingProp = false;
      this.sharedService.GetTrackingDetails(id, cid, pid).subscribe(
        data => {
          this.loader = false;
          this.data = data;
          this.qtyLabel = [];
          this.data.dataList.forEach((element, index) => {
            var obj = {
              id: element.id, part: element.part,
              serialNo: element.serialNo, disabled: false
            }
            this.qtyLabel.push(obj);
          });
          this.loader = false;
          this.openId = id;
          this.openTracking = Tracking;
          this.openPartId = pid;
          this.openCompanyId = cid;
          $('#SerialModel').show();
        }
      )
    }

  }
  OpenSerialModels(id, cid, pid, Tracking: any, custom: any) {
    if (Tracking != "3" && Tracking != "0") {
      this.pickingProp = true;
      this.customVal = custom;
      this.loader = true;
      this.sharedService.GetTrackingDetails(id, cid, pid).subscribe(
        data => {
          this.loader = false;
          this.data = data;
          this.qtyLabel = [];
          this.data.dataList.forEach((element, index) => {
            var obj = {
              id: element.id, part: element.part,
              serialNo: element.serialNo, disabled: false
            }
            this.qtyLabel.push(obj);
          });
          this.loader = false;
          this.openId = id;
          this.openTracking = Tracking;
          this.openPartId = pid;
          this.openCompanyId = cid;
          $('#SerialModel').show();
        }
      )
    }
    else {
      this.CheckPickList(id, custom);
    }

  }
  CloseSerialModel() {

    $('#SerialModel').hide();
  }

  EditDetail(id: any, Tracking: any, pid: any, cid: any) {
    const valuel = this.qtyLabel;
    let count = 0; let messageCount = 0; let sameCount = 0;
    const sameSerial = [];
    const filterElement = valuel;
    if (filterElement[0].number == '') {
      filterElement[0].message = '';
    }
    else {
      filterElement[0].message = '';
    }
    valuel.forEach(element => {
      if (element.serialNo == '') {
        element.valid = true;
        count++;
      }
      else {
        element.valid = false;
      }
    });
    if (count == 0) {
      let serial = ''; let ids = ''; let serialNext = [];
      valuel.forEach(partLst => {
        let obj = {};
        sameSerial.push(partLst.serialNo);
        var ss = partLst.id == undefined ? '0' : partLst.id;
        serial += partLst.serialNo + ':' + ss + ',';
        obj['SerialNo'] = partLst.serialNo;
        obj['id'] = partLst.id;
        serialNext.push(obj);
      });

      let findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) != index);
      const duplicate = [...new Set(findDuplicates(sameSerial))];
      if (duplicate.length == 0) {
        if (serial) {
          serial = serial.slice(0, -1);
        }
        this.loader = true;
        this.sharedService.GetSerialExist(serial, pid == undefined ? 0 : pid, Tracking).subscribe(
          data => {
            this.data = data;
            const list = this.data.dataList;
            this.loader = false;
            if (list.length > 0) {
              list.forEach(element => {
                const getSerial = valuel.filter(x => x.serialNo == element.serialNo);
                getSerial[0].message = Tracking == "1" ? 'Serial No already exists for this part' : 'Lot # already exists for this part';
                messageCount = messageCount + 1;
              });
            }
            if (messageCount == 0) {
              this.UpdateSerial(id, cid, serialNext, Tracking);
            }
            else {

            }
          });
      }
      else {
        if (duplicate.length > 0) {
          duplicate.forEach(element => {
            const getSerial = valuel.filter(x => x.serialNo == element);
            getSerial.forEach(element => {
              element.message = Tracking == "1" ? 'Serial No should be unique on this part' : 'Lot # should be unique on this part';
            });

          });
        }
      }

    }
    else {
      // $('#ExitModel').show();
      return;
    }
  }
  changeValidation(id) {
    const filterElement = this.qtyLabel.filter(x => x.id == id);
    if (filterElement[0].number == '') {
      filterElement[0].valid = true;
      filterElement[0].message = '';
    }
    else {
      filterElement[0].valid = false;
      filterElement[0].message = '';
    }
  }
  UpdateSerial(id: any, cid: any, list: any, traking: any) {
    this.loader = true;
    let obj = {};
    obj['Id'] = id;
    obj['companyId'] = cid;
    obj['PartSerial'] = list;
    this.sharedService.UpdateSerial(obj).subscribe(
      data => {
        this.loader = false;
        this.data = data;
        if (this.data.statusCode == 200) {
          $('#SerialModel').show();
          $('#Update_Success').show();
          this.openTracking = traking;
        }
        else {
          $('#SerialModel').hide();
          $('#wrong_msg').show();
        }
      }
    )
  }
  msgitem() {
    $('#wrong_msg').hide();
  }
  Update_SuccessClose() {
    $('#Update_Success').hide();
  }

  CheckPickList(id, custom) {
    this.SaveIndex = id;
    this.custom = custom;
    $('#CheckPick').show();
  }

  SavePickList(id) {
    $('#CheckPick').hide();
    const custom = this.custom;
    let data = []; let data1 = []; let count = 0;
    if (custom == 1) {
      data = this.SpecialPartList.filter(x => x.partInventoryId == id);
    }
    else {
      for (var j = 0; j < this.SpecialPartList.length; j++) {
        const a = this.SpecialPartList[j].PartList;
        if (a != undefined) {
          data = a.filter(x => x.partInventoryId == id);
          if (data.length == 0) {

          }
          else {
            data1 = this.SpecialPartList.filter(x => x.partId == data[0].customPartId);
            count = this.SpecialPartList[j].PartList.length;
            break;
          }
        }


      }
    }
    data[0].scolor = !data[0].scolor;
    if (data1.length > 0) {
      data1[0].scolor = !data1[0].scolor;
    }
    const data2 = data1.push(...data);

    let obj = {};
    this.PickDetails = [];
    let invArr = data1;
    let ToLocationOneCount = 0;


    for (var j = 0; j < invArr.length; j++) {

      let invObj = {};
      invObj["CompanyId"] = Number(this.value_search);
      invObj["OrderTypeId"] = invArr[j].orderTypeId;
      invObj["SoNo"] = invArr[j].soNo;
      invObj["CustomerId"] = invArr[j].customerId;
      invObj["PartId"] = invArr[j].partId;
      invObj["Quantity"] = invArr[j].quantity;
      invObj["FromLocation"] = invArr[j].fromLocation;
      invObj["ToLocation"] = invArr[j].orderTypeId == "2" ? invArr[j].toLocation : invArr[j].toLocation1;
      invObj["OrderId"] = invArr[j].orderId;
      invObj["Id"] = invArr[j].id;
      invObj["tracking"] = parseInt(invArr[j].partTracking);
      invObj["customPartId"] = parseInt(invArr[j].customPartId);
      invObj["virtual"] = invArr[j].virtual;
      invObj["isCustomPart"] = invArr[j].isCustomPart;
      invObj["PartInventoryId"] = invArr[j].partInventoryId;
      invObj["FromLocationName"] = parseInt(invArr[j].locationName);
      invObj["RecvQty"] = 0;
      if (invArr[j].orderTypeId == "2") {
        invObj["ToLocationName"] = parseInt(invArr[j].toLocationShipId);
      }
      else {
        invObj["ToLocationName"] = parseInt(invArr[j].toLocationProdId);
      }
      if (invArr[j].orderTypeId == "1") {
        if (invArr[j].isCustomPart == false) {
          if (invArr[j].toLocation1 == undefined || invArr[j].toLocation1 == null) {
            ToLocationOneCount = ToLocationOneCount + 1;
            this.PickItem = "Part Item can not be picked becuase there is no To Location Exist";
            invArr[j].scolor = false;
          }
          else {
            if (invArr[j].fromLocation == undefined || invArr[j].fromLocation == null) {
              ToLocationOneCount = ToLocationOneCount + 1;
              this.PickItem = "Part Item can not be picked becuase there is no From Location";
              invArr[j].scolor = false;
            }
          }

        }
      }

      if (invArr[j].orderTypeId == "2") {
        if (invArr[j].isCustomPart == false) {
          if (invArr[j].toLocation == undefined || invArr[j].toLocation == null) {
            ToLocationOneCount = ToLocationOneCount + 1;
            this.PickItem = "Part Item can not be picked becuase there is no To Location Exist";
            invArr[j].scolor = false;
          }
          else {
            if (invArr[j].fromLocation == undefined || invArr[j].fromLocation == null) {
              ToLocationOneCount = ToLocationOneCount + 1;
              this.PickItem = "Part Item can not be picked becuase there is no From Location";
              invArr[j].scolor = false;
            }
          }

        }
      }

      this.PickDetails.push(invObj);
    }

    if (ToLocationOneCount == 0) {
      obj["Counts"] = count;
      obj["UpdatePickList"] = this.PickDetails;
      this.IsUpdating = true;
      this.loader = true;
      this.UpdatingMsg = "Create Picklist...";
      this.sharedService.UpdateCreatePicklist(obj)
        .subscribe(
          data => {
            this.loader = false;
            switch (data.statusCode) {
              case 200:
                $('#SuccessMsg').show();
                $('#SerialModel').hide();
                this.IsUpdating = false;
                this.UpdatingMsg = "";
                // this.searchPickList();
                this.CreateNewPickList();
                break;
              case 400:
                $("#wrong_msg").show();
                break;
              default:
                $("#wrong_msg").show();
            }
          }
        );

    }
    else {

      $('#pickModel').show();
    }

  }
  ClosepickModel() {
    $('#pickModel').hide();
  }
  CloseSuccessMsg() {
    $('#SuccessMsg').hide();
  }
  CloseCheckPick() {
    $('#CheckPick').hide();
  }
  SortString(name) {
    var newstr = name.substring(0, 24);
    var newStr1 = newstr + "....";
    return newStr1;

  }

  //ankit implemenation
  ErrorModal(title: string, message: string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }
}
