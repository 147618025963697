import { Injectable } from '@angular/core';
import { Observable, throwError, of, BehaviorSubject, Subject } from 'rxjs';
import { tap, delay, catchError } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TitleCasePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class VendorService {
  baseUrl = environment.baseUrl;
  // IntegrationbaseUrl = environment.IntegrationbaseUrl;

  constructor(private _http: HttpClient) { }

  getAllTerms() {
    return this._http.get(this.baseUrl + 'api/Vendor/GetAllTermsVendor',
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  getAllShippings() {
    return this._http.get(this.baseUrl + 'api/Vendor/GetAllShippingVendor',
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  getAllCarrier() {
    return this._http.get(this.baseUrl + 'api/Vendor/GetAllCarrierVendor',
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  // TestRateAPI(Id) {
  //   return this._http.post(this.IntegrationbaseUrl + 'api/ShippingRate?companyId='+Id, '',
  //     { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
  //     .pipe(catchError(this.errorHandler));
  // }
  getAllShippingTypeById(OrderId: number) {
    return this._http.get(this.baseUrl + 'api/Vendor/getAllShippingTypeById?OrderId=' + OrderId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetVendorById(id: any) {
    return this._http.get(this.baseUrl + 'api/Vendor/GetVendor?vendorId=' + id, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetAllVendor(companyId: any, searchTerm: any) {
    return this._http.get(this.baseUrl + 'api/Vendor/GetAllVendor?companyId=' + companyId + '&searchTerm=' + searchTerm,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }


  addvendor(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Vendor/AddVendor', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }


  updateVendor(item: any): Observable<any> {
    return this._http.put(this.baseUrl + 'api/Vendor/UpdateVendor', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  deleteVendor(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Vendor/DeleteVendor?vendorIds=' + item, {},
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  uploadVendor(formData: any, pram1: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Vendor/UploadVendor?companyId=' + pram1, formData,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    // this.loader = false;
    return throwError(error.message || "server error.");
  }
}
