
import { Component, OnInit, QueryList, ViewChild } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
// import { TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { Subject } from 'rxjs';
import { ConstantsService } from 'src/app/constants.service';
import { SharedService } from 'src/app/services/shared.service';
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { GlobalChangeService } from 'src/app/services/global-change.service';
import Swal from 'sweetalert2';
import { CompanyService } from 'src/app/services/company/company.service';
import { PartService } from 'src/app/services/part/part.service';
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-purchaseorderreport',
  templateUrl: './purchaseorderreport.component.html',
  styleUrls: ['./purchaseorderreport.component.css']
})
export class PurchaseorderreportComponent implements OnInit {
  data: any;
  companyList: any;
  userGroupObjects: any;
  value: number;
  contactTypeDataList: any;
  // dtOptions: DataTables.Settings = {};
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();
  validation: boolean;
  name: any;
  id: any;
  userId: any;
  deletedItem: string;
  deleteDetail: string;
  loader: boolean = false;
  form: FormGroup;
  contactType: string;
  userRoleList: any;
  buttonValue: any;
  settingDetailCSS: any;
  recordAlreadyExist = false;
  settingLogoImage: any;
  clearbutton: boolean = true;
  rolePermissionsList: any;
  permissionMessage: any;
  TCreateUpdate: any = 1;
  ProductSearch: any = "";
  SearchValidation: any;
  SearchControl: boolean = false;
  ListShow: boolean = false;
  ListDiv: boolean = false;
  formSearch: FormGroup;
  reportFormforSO: FormGroup;
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  // config = TreeviewConfig.create({
  //   hasAllCheckBox: true,
  //   hasFilter: true,
  //   hasCollapseExpand: true,
  //   decoupleChildFromParent: false,
  //   maxHeight: 400
  // });
  // config1 = TreeviewConfig.create({
  //   hasAllCheckBox: true,
  //   hasFilter: true,
  //   hasCollapseExpand: true,
  //   decoupleChildFromParent: false,
  //   maxHeight: 400
  // });

  value1 = 0;
  companyLoginId: any;
  userSearch: any;
  ListAngle: any;
  SearchType: any;
  value_search: number;
  userGroupObjects_search = new Array();
  companyList_search: any;
  // items_search: TreeviewItem[];
  createdDateEdit: any;
  messageModalImage: any;
  messageModalHeading: any;
  messageModalDescription: any;
  ShippingStatus: any;
  IsStatuschage: boolean = false;
  statusDropdownList: any;
  statusIdVal: any = 0;
  vendorVal:any=0;
  purchageorderList: any;
  dtElements: QueryList<DataTableDirective>;
  dtTrigger1: Subject<any> = new Subject<any>();
  dtTrigger2: Subject<any> = new Subject<any>();
  ddlPartList: any;
  isBackgroudImage: any;
  companyIdVal: any;
  fromDate: any;
  toDate: any;
  buttons: any[];
  totalAmountAll: any = 0;
  RecOrderListShow = false;
  loginUserName: any;
  loginFirstName: any;
  customerDetails: any;
  datepipe: any;
  companyDetails: any;
  revisionNumbers: any;
  orderNumber: any;
  partList: any;
  CurrencyPipe: any;
  laborHrsCost: any;
  MorderList: any = [];
  IsDateRange: boolean = false;
  vendorlist: any;
  POTypeList: any;
  POstatusList: any;
  totalrows: any;
  items_search: any;

  constructor(
    private route: ActivatedRoute,
    public sharedService: SharedService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private constants: ConstantsService,
    private router: Router,
    private globalChangeService: GlobalChangeService,
    private companyService: CompanyService,
    private partService: PartService
  ) { }


  ngOnInit(): void {
    
    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);
    } else {
      this.userId = localStorage.getItem(this.constants.userId);
      this.buttonValue = this.constants.Save; 

      //ankit implementaion
      // get company by default set value
      var company = this.globalChangeService.getGlobalCompany();
      if(company>0){
        this.value = company;
        this.value_search = company;

        this.Getvendordropdownlist(this.value_search);
      }
      else{
        this.ErrorModal('Error', 'Please select company.');
      }

      //on change function 
      this.globalChangeService.dropdownChange$.subscribe((data)=>{
        this.onValueChange(data);
        this.onValueChange_search(data);
      })
    }

    this.reportFormforSO = new FormGroup
      ({
        'statusIdVal': new FormControl(),
        'vendorVal':new FormControl(),
        'fromDate': new FormControl(),
        'toDate': new FormControl(),
        'allDateRange':new FormControl('', Validators.required)
       
      });

    this.rolePermissionsList = JSON.parse(localStorage.getItem('rolePermissionsList'));
    this.rolePermissionsList = this.rolePermissionsList.filter(x => x.pageId == 1044);
    this.permissionMessage = '';
    if (this.rolePermissionsList != null && this.rolePermissionsList != undefined) {
      if (!this.rolePermissionsList[0].isView) {
        this.permissionMessage += 'view';
      } if (!this.rolePermissionsList[0].isInsert) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'add';
      } if (!this.rolePermissionsList[0].isUpdate) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'edit';
      } if (!this.rolePermissionsList[0].isDelete) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'delete';
      }
    }
    if (this.permissionMessage != '') {
      this.permissionMessage = 'You do not have access to ' + this.permissionMessage + '. Please contact to administrator!';
    }
    this.companyLoginId = localStorage.getItem(this.constants.companyId);
    //this.getAllCompany(this.companyLoginId);
    this.getAllCompany_search(this.companyLoginId);
    this.GetShippingStatus();
    this.POStatusDropdownList();
   


  }

  changeAllDateOrder(e)
  {
    
    let valueChange=e.target.value;
    if(valueChange=="allDateRange")
    {
      this.IsDateRange=true;
    }
    else
    {
      this.IsDateRange=false;
    }
  }
 
 get range(){
    return this.form.controls;
  }

  getAllCompany_search(companyId) {
    this.companyService.GetDropdownCompanyHierarchyModel(this.companyLoginId).subscribe(
      data => {

        this.data = data;
        this.items_search = this.data.dataList;
        this.companyList_search = this.data.dataList;
        // this.companyList_search.forEach((x) => {
        //   this.userGroupObjects_search.push(new TreeviewItem(x, false));
        // });
        this.value_search = Number(companyId);
      });
  }

  onValueChange(value: any): void {
    this.value = value;

  }
  onValueChange_search(value: any): void {
    
    this.value_search = value;
    this.Getvendordropdownlist(this.value_search);
  }

  statusvalues(event) {
    this.statusDropdownList = event.target.value;
    this.IsStatuschage = true;
  }
  vendorvalues(e)
  {
    //this.statusDropdownList = event.target.value;
    //this.IsStatuschage = true;
  }
  GetShippingStatus() {
    this.sharedService.GetShippingStatusdropdownlist().subscribe(
      data => {
        this.data = data;
        this.ShippingStatus = this.data.dataList;
      }
    )
  }

  SortTableHeader(item: any) {
    if (this.purchageorderList != null) {
      var ret = new Array;
      for (var i = this.purchageorderList.length - 1; i >= 0; i--) {
        ret.push(this.purchageorderList[i]);
      }

      this.purchageorderList = ret;
      var Keys = Object.keys(this.purchageorderList[0]);

      if (Keys.includes(item)) {
        if (this.isBackgroudImage) {
          this.isBackgroudImage = false;
          var element = document.getElementById(item);
          if (element.className === 'sorting1') {
            element.classList.replace('sorting1', 'sorting_asc');
          }
          else if (element.className === 'sorting_asc') {
            element.classList.replace('sorting_asc', 'sorting_desc');
          }
          else {
            element.classList.replace('sorting_desc', 'sorting_asc');
          }
        }
        else {
          this.isBackgroudImage = true;
          var element = document.getElementById(item);
          if (element.className === 'sorting1') {
            element.classList.replace('sorting1', 'sorting_asc');
          }
          else if (element.className === 'sorting_asc') {
            element.classList.replace('sorting_asc', 'sorting_desc');
          }
          else {
            element.classList.replace('sorting_desc', 'sorting_asc');
          }
        }
      }

      const result = Keys.filter(key => key != item);

      if (result) {
        result.forEach(data => {
          var element = document.getElementById(data);
          if (element != null) {
            if (element.className === 'sorting1') {
              element.classList.replace('sorting1', 'sorting1');
            }
            else if (element.className === 'sorting_asc') {
              element.classList.replace('sorting_asc', 'sorting1');
            }
            else {
              element.classList.replace('sorting_desc', 'sorting1');
            }
          }
        });
      }
    }
  }

  
  Getvendordropdownlist(companyId) {
    
    if (companyId == undefined) { companyId = 0; }
    this.partService.Getvendordropdownlist(companyId).subscribe(
      
      data => {
        this.data = data;
        if (companyId > 0) {
          this.vendorlist = this.data.dataList;
        }
       
      }
    )
  }

  
  POStatusDropdownList() {
    this.sharedService.GetPOStatusDropdownList().subscribe(
      data => {
        this.data = data;
        this.POstatusList = this.data.dataList;  
      }
    )
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next('');

    });
  }

  GetPurchaseOrderReportData() {
    var mapped;
    var mapped11;
    var crrArr = [];
    this.companyIdVal = this.value_search;
    if (this.companyIdVal == undefined) { this.companyIdVal = 0; }
    if (this.fromDate == undefined) { this.fromDate = '' }
    if (this.toDate == undefined) { this.toDate = '' }
    this.loader = true;
    $('#datatableSOReport').DataTable().destroy();
    this.dtTrigger.next('');
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      ordering: false,
      searching: false,
      
      ajax: (dataTablesParameters: any, callback) => {     
        crrArr = [];
        this.sharedService.GetPurchaseOrdeReportAllDataWithPagging(this.companyIdVal, Number(this.statusIdVal),Number(this.vendorVal),dataTablesParameters.start, dataTablesParameters.length, this.fromDate, this.toDate)
          .subscribe(resp => {
            this.loader = false;
            let totalRows = null;
            this.data = resp;           
            this.purchageorderList = this.data.dataList;
            if (this.data.dataList.length > 0) {
              this.purchageorderList = this.data.dataList;
              mapped11 = this.purchageorderList;
              totalRows = [...new Set(this.purchageorderList.map(item => item.totalRows))];
              if (this.purchageorderList.length > 0) {
                this.purchageorderList.forEach(itemValues => {
                  this.totalAmountAll = itemValues.totalAmountAll
                  this.totalrows=itemValues.totalRows;
                 
                })

              }
              this.MorderList = [];
              this.sharedService.GetPurchaseOrdeReportAllData(this.companyIdVal, this.statusIdVal,this.vendorVal, this.fromDate, this.toDate)
                .subscribe(resp => {
                  this.loader = false;
                  this.data = resp;
                  this.MorderList = this.data.dataList;
                  if (this.data.dataList.length > 0) {
                    this.MorderList = this.data.dataList;
                    let spaceArr=['','','','','','',''];
                     let addTotal = ['','Total:','','','','',
                       this.totalAmountAll==0?this.totalAmountAll:'$'+ this.totalAmountAll
                    ];
                    
                    mapped = this.MorderList;
                    for (var j = 0; j < mapped.length; j++) {
                      crrArr[j] = [mapped[j].po,mapped[j].purchaseOrderPrefix, mapped[j].poStatus, mapped[j].vendorName,mapped[j].dateOrder,mapped[j].dateSchedule,'$'+mapped[j].totalAmount
                        
                      ];

                    }
                    crrArr.push(spaceArr);
                    crrArr.push(addTotal);
                    
                  }
                });

            }
            else {
              this.purchageorderList = null;
              totalRows = 0;
              this.totalAmountAll = '';
            }

            callback({

              recordsTotal: totalRows,
              recordsFiltered: totalRows,
              data: []
            });


          });
      },

      dom: 'Bfrtip',
     
      buttons: [
       {

          extend: 'pdf',
          alignment: "center",
          className: 'green glyphicon glyphicon-file',
          title: 'Purchase Order Report',
          filename: 'purchaseorderReport',
          exportOptions: {
            customizeData: function (d) {
              if (crrArr.length > 0) {
                crrArr.forEach(element => {
                  d.body.push(element);
                  //d.content[1].table.body[element][6].alignment = 'right';
                });
               // d.content[1].table.body[6][6].alignment = 'right';
              }
            }, 
           columns: ':visible:not(:first-child)',  
                
          }
          

        },
        {
          extend: 'excel',
          className: 'green glyphicon glyphicon-file',
          title: 'Purchase Order Report',
          filename: 'purchaseorderReport',
          exportOptions: {
            customizeData: function (d) {
              if (crrArr.length > 0) {
                crrArr.forEach(element => {
                  d.body.push(element);
                });
              }
            },
            columns: ':visible:not(:first-child)',       
          }
        },
        {
          extend: 'print',
          className: 'green glyphicon glyphicon-file',
          title: 'Purchase Order Report',
          filename: 'purchaseorderReport',
          exportOptions: {
            customizeData: function (d) {
              if (crrArr.length > 0) {
                crrArr.forEach(element => {
                  d.body.push(element);
                });
              }
            },
            columns: ':visible:not(:first-child)',  
             
          }
        }
      ]

    };
   
    this.rerender();
  }

  //ankit implemenation
  ErrorModal(title:string, message:string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }
}
