<section class="content-section pt-2 ws-nobreak1">
    <div class="loading" *ngIf="loader">Loading&#8230;</div>

    <div col-sm-12>
        <div class="row">
            <div class="col-6 mb-2 d-flex align-items-baseline text-xs-left">
                <div class="page-title">
                    <div class="bread-crumb">
                        <a [routerLink]="['/adminDashboard/']">Home</a>
                        <span class="mx-1">-</span>
                        <span class="active">View Inventory</span>
                        <div *ngIf="permissionMessage!=''" class='btn-danger text-center'>{{permissionMessage}} </div>
                    </div>
                </div>
            </div>
            <div class="col-6 mb-2 text-right align-items-baseline">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="white-box1">
                <div class="customHeading py-1">
                    View Inventory
                </div>
                <div class="card-desc1">
                    <form [formGroup]="formSearch">
                        <div class="row mt-2">
                            <!-- <div class="col-lg-3 col-md-12 mt-lg-0 mt-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp required">
                                        <label class="customLabel">Company</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10 border-none"
                                        [ngClass]="((value_search==0) && (SearchValidation)) ? 'validation_border': ''">
                                        <ngx-dropdown-treeview-select [config]="config"
                                            class="dropdown-company customInput-dropdown"
                                            [items]="userGroupObjects_search" [(value)]="value_search"
                                            (valueChange)="onValueChange_search($event)">
                                        </ngx-dropdown-treeview-select>
                                    </div>
                                </div>
                            </div> -->
                            <div class="col-lg-3 col-md-12 mt-lg-0 mt-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Vendor</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <select [(ngModel)]="vendorSearch" class="customInput"
                                            formControlName='vendorSearchDDL'>
                                            <option value='0'>All</option>
                                            <option *ngFor="let item of vendorListDDL" [value]="item.id">
                                                {{item.vendorName}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-2 col-md-12 mt-lg-0 mt-1">
                                <div class="row">
                                   
                                    <select class="customInput pl-1px"
                                        (change)="ChangefieldToSearch($event.target.value)"
                                        [(ngModel)]="fieldToSearch" id="fieldToSearch"
                                        formControlName="fieldToSearch">
                                        <option value='2'>List or Vendor Part Name</option>
                                        <option value='3'>List or Vendor Part #</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-12 mt-lg-0 mt-1">
                                <div class="row">
                                    
                                    <div class="col-lg-11 col-md-10">
                                        <input type="text" placeholder="Search Part Name" class="customInput"
                                            [(ngModel)]="partSearch"  placeholder="{{fieldToSearchPlaceHolder}}" formControlName="partSearch" autocomplete="off" (keyup.enter)="searchInvPartList()"> 
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-1 col-md-6 mt-lg-0 mt-1">
                                <div class="row">
                                    <div class="col-lg-12 col-md-2 trp">
                                        <i class="fas fa-search cpointer" (click)="searchInvPartList()"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <hr>
            <div class="white-box1" *ngIf="rolePermissionsList && rolePermissionsList[0].isView"
                [style.display]="ListShow==true ? 'block' : 'none'">
                <div class="white-box-title1">
                    <div class="customHeading">
                        Part List<i class="fa fa-angle-{{ListAngle}} cpointer ml-2" aria-hidden="true"
                            (click)="ListDivShowHide()"></i>
                    </div>
                </div>
                <!------------------------------------- company list table ------------------------------>
                <div class="card-desc1 mt-1" [style.display]="ListDiv==true ? 'block' : 'none'">
                    <table id="datatable" class="display table clearfix" class="row-border hover" datatable
                        [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                        <thead>
                            <tr>
                                <th class="coloumn-hide"></th>
                                <th>Company</th>
                                <th>List Part Name</th>
                                <th>List Part Number</th>
                                <th>Vendor Name</th>
                                <!-- <th>Category</th> -->
                                <th>Part Price</th>
                                <th class="width-75">Labor Hrs</th>
                                <!-- <th>Part ID</th> -->
                                <th data-orderable="false">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of partList">
                                <td class="coloumn-hide">{{data.id}}</td>
                                <td>{{data.companyName}}</td>
                                <td  title={{data.name}}>{{data.name.length <= 25 ? data.name : SortString(data.name)}}
                                <!-- <td>{{data.name}}</td> -->
                                <td>{{data.partNo}}</td>
                                <td>{{data.vendorName}}</td>

                                <!-- <td>{{data.partCategoryName}}</td> -->
                                <td>{{data.cost|currency}}</td>
                                <td>{{data.hours|number: '1.2-2'}}</td>
                               
                                <td>
                                    <ng-container *ngIf="data.nonInventory == false;then  public; else user">
                                    </ng-container>
                 
                                    <ng-template #user>
                                        <span style="font-weight:600">Non-Invetory</span>
                                    </ng-template>
                                    <ng-template #public>
                                        <a class="green-text"
                                        *ngIf="rolePermissionsList && rolePermissionsList[0].isUpdate">
                                        <i class="fas fa-edit" (click)="searchInvF(data)"></i>
                                       
                                    </a>
                                    </ng-template>
                                   
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>

            <div class="white-box1 mt-2" [style.display]="invDiv==true ? 'block' : 'none'">

                <div class="card-desc1">
                    <form [formGroup]="form">
                        <div class="part-questions">

                            <div class="row mt-1 d-flex align-items-center">
                                <div class="col-lg-12 col-md-12 pr-lg-0">

                                    <span class="position-relative">
                                        <label class="customLabelPartInfo mb-0">List Part Name : {{displayPartName}}&nbsp;<span
                                                class="tooltipWrap tooltip-icon"><i
                                                    class="fa fa-info-circle"></i></span>
                                            <span class="tooltips">
                                                Vendor Part Name : {{displayVendorPartName ? displayVendorPartName :
                                                'N/A'}}<br>
                                                Vendor Part Number : {{displayVendorPartNo ? displayVendorPartNo :
                                                'N/A'}}
                                            </span>
                                        </label>
                                    </span>
                                    <br>
                                    <label class="customLabelPartInfo mb-0">List Part Number : {{displayPartNo}}</label>
                                    <br>
                                    <label class="customLabelPartInfo mb-0">Vendor Name : {{displayVendorName}}</label>
                                    <br>
                                    <label class="customLabelPartInfo mb-0">Minimum Inventory :
                                        {{displayMinInventory}}</label>
                                    <br>
                                    <label class="customLabelPartInfo mb-0">Reorder Quantity : {{displayReorderQty}}</label> 
                                    <br>
                                    <label class="customLabelPartInfo mb-0">Part Tracking Details : <span style="color:red; font-weight:600">{{displayPartTracking == "1" ? "Serial #" : displayPartTracking == "2" ? "Lot #" : displayPartTracking == "3" ? "None"  : 'No Tracking Selected' }}</span></label>
                                </div>
                            </div>
                            <hr class="mt-1">
                            <div class="row mt-1 d-flex align-items-center">
                                <div class="col-lg-1 col-md-6 pr-lg-0">
                                    <div class="d-flex align-items-center">
                                        <label class="customLabel mr-2">Qty<span style="color:red">*</span></label>

                                        <input type="text" class="customInput custom-width"
                                            [ngClass]="((!form.controls.InvQty.valid) && (InvValidation)) ? 'form-control1 NumberOnly validation_border': 'form-control1 NumberOnly'"
                                            placeholder="Qty" formControlName='InvQty' [(ngModel)]="InvQty"
                                            maxlength="5" min="1" max="99"
                                            [ngClass]="((!form.controls.InvQty.valid) && (InvValidation)) ? 'validation_border': ''">

                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-6 mt-lg-0 mt-1">
                                    <div class="d-flex align-items-center">
                                        <label class="customLabel mr-2">Date<span style="color:red">*</span></label>
                                        <input type="date" class="customInput" min="{{MinDate}}"
                                            [ngClass]="((!form.controls.InvDate.valid) && (InvValidation)) ? 'form-control1 validation_border': 'form-control1'"
                                            placeholder="Date" formControlName='InvDate' [(ngModel)]="InvDate"
                                            [ngClass]="((!form.controls.InvDate.valid) && (InvValidation)) ? 'validation_border': ''">

                                    </div>
                                </div>
                                <div class="col-lg-1 col-md-6 p-lg-0 mt-lg-0 mt-1">
                                    <div class="d-flex align-items-center">
                                        <label class="customLabel mr-2 ws-nobreak">$ Cost<span
                                                style="color:red">*</span></label>
                                        <input type="text" class="customInput"
                                            [ngClass]="((!form.controls.InvCost.valid) && (InvValidation)) ? 'form-control1 CostQty validation_border': 'form-control1 CostQty'"
                                            placeholder="$ Cost" formControlName='InvCost' [(ngModel)]="InvCost"
                                            maxlength="8"
                                            [ngClass]="((!form.controls.InvCost.valid) && (InvValidation)) ? 'validation_border': ''">

                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-6 mt-lg-0 mt-1">
                                    <div class="d-flex align-items-center">
                                        <label class="customLabel mr-2">Type<span style="color:red">*</span></label>
                                        <select class="customInput"
                                            [ngClass]="((!form.controls.InvType.valid) && (InvValidation)) ? 'validation_border': ''"
                                            formControlName='InvType' [(ngModel)]="InvType">
                                            <option value=''>Select Type</option>
                                            <option *ngFor="let item of InvTypeList" [value]="item.id">
                                                {{item.name}}
                                            </option>
                                        </select>

                                    </div>
                                </div>

                                <ng-container *ngIf='InvType == "5"'>
                                    <div class="d-flex align-items-center">
                                        <label class="customLabel mr-2">Number</label>
                                        <input type="text" class="customInput"
                                            [ngClass]="((!form.controls.InvServNo.valid) && (InvValidation)) ? 'validation_border': ''"
                                            placeholder="Service #" formControlName='InvServNo' [(ngModel)]="InvServNo">

                                    </div>
                                </ng-container>
                                <ng-container *ngIf='InvType == "6"'>

                                    <div class="d-flex align-items-center">
                                        <label class="customLabel mr-2">Number</label>
                                        <select class="customInput" formControlName='InvPartOrder'
                                            [ngClass]="((!form.controls.InvPartOrder.valid) && (InvValidation)) ? 'validation_border': ''"
                                            [(ngModel)]="InvPartOrder">
                                            <option value=''>Select Part SO</option>
                                            <ng-container *ngFor="let item of InvPartOrderDDL">
                                                <option *ngIf="item.qtyToFulfill!=0" [value]="item.id">
                                                    {{item.name}}
                                                </option>
                                            </ng-container>
                                            <ng-container *ngFor="let item of InvPartOrderDDL">
                                                <option *ngIf="item.qtyToFulfill==0" [value]="item.id"
                                                    [disabled]="true">
                                                    {{item.name}} - Fulfilled 
                                                </option>
                                            </ng-container>

                                            <!-- <option *ngFor="let item of InvPartOrderDDL" [value]="item.id">
                                                {{item.name}}
                                            </option> -->
                                        </select>

                                    </div>
                                </ng-container>
                                <ng-container *ngIf='InvType == "7"'>

                                    <div class="d-flex align-items-center">
                                        <label class="customLabel mr-2">Number</label>
                                        <select class="customInput" formControlName='InvProductOrder'
                                            [ngClass]="((!form.controls.InvProductOrder.valid) && (InvValidation)) ? 'validation_border': ''"
                                            [(ngModel)]="InvProductOrder">
                                            <option value=''>Select Product SO</option>
                                            <ng-container *ngFor="let item of InvProductOrderDDL">
                                                <option *ngIf="item.qtyToFulfill!=0" [value]="item.id">
                                                    {{item.name}}
                                                </option>
                                            </ng-container>
                                            <ng-container *ngFor="let item of InvProductOrderDDL">
                                                <option *ngIf="item.qtyToFulfill==0" [value]="item.id"
                                                    [disabled]="true">
                                                    {{item.name}} - Fulfilled 
                                                </option>
                                            </ng-container>
                                            <!-- <option *ngFor="let item of InvProductOrderDDL" [value]="item.id">
                                                {{item.name}}
                                            </option> -->
                                        </select>

                                    </div>
                                </ng-container>


                                <div class="col-lg-2 col-md-12 pr-lg-0 mt-lg-0 mt-1">
                                    <div class="d-flex align-items-center">
                                        <label class="customLabel mr-2 ws-nobreak">Vendor<span style="color:red">*</span></label>
                                        <select class="customInput" formControlName='InvVendor'
                                            [ngClass]="((!form.controls.InvVendor.valid) && (InvValidation)) ? 'validation_border': ''"
                                            [(ngModel)]="InvVendor">
                                            <option value=''>Select Vendor</option>
                                            <option *ngFor="let item of partVendorsList" [value]="item.id">
                                                {{item.name}}
                                            </option>
                                        </select>

                                    </div>
                                </div>

                              

                                <div class="col-lg-2 col-md-12 pr-lg-0 mt-lg-0 mt-1">
                                    <div class="d-flex align-items-center">
                                        <label class="customLabel mr-2 ws-nobreak">Location Name<span
                                                style="color:red">*</span></label>
                                        <select class="customInput" formControlName='InvLocName'
                                            [ngClass]="((!form.controls.InvLocName.valid) && (InvValidation)) ? 'validation_border': ''"
                                            [(ngModel)]="InvLocName" (change)="changeLocationNew($event)">
                                            <option value=''>Location Name</option>
                                            <option *ngFor="let item of locationNameList" [value]="item.id">
                                                {{item.locationName}}
                                            </option>
                                        </select>

                                    </div>
                                </div>

                                <div class="col-lg-2 col-md-12 pr-lg-0 mt-lg-0 mt-2">
                                    <div class="d-flex align-items-center">
                                        <label class="customLabel mr-2 ws-nobreak">Location<span
                                                style="color:red">*</span></label>
                                        <select class="customInput cwidth" formControlName='InvLocName1'
                                            [ngClass]="((!form.controls.InvLocName1.valid) && (InvValidation)) ? 'validation_border': ''"
                                            [(ngModel)]="InvLocName1">
                                            <option value=''>Select Location</option>
                                            <option *ngFor="let item of invlocationLevelList" [value]="item.levelsName">
                                                {{item.levelsName}}
                                            </option>
                                        </select>

                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-12 pr-lg-0 mt-lg-0 mt-2">
                                    <div class="d-flex align-items-center mt-1">
                                        <label class="customLabel mr-2 ws-nobreak">Tracking<span
                                                style="color:red">*</span></label>
                                        <select class="customInput" formControlName='InvPartTracking'
                                            [ngClass]="((!form.controls.InvPartTracking.valid) && (InvValidation)) ? 'validation_border': ''"
                                            [(ngModel)]="InvPartTracking" (change)="SetTracking($event)">
                                            <option value=''>Select Tracking Details</option>
                                            <option *ngFor="let item of PartTrackingList" [value]="item.id">
                                                {{item.name}}
                                            </option>
                                        </select>

                                    </div>
                                 
                                </div>
                                <div class="col-lg-2 col-md-12 pr-lg-0 mt-lg-0 mt-1">
                                    <div class="d-flex align-items-center mt-2">
                                        <!-- <label class="customLabel mr-2 ws-nobreak">Location</label>
                                        <input type="text" class="customInput" formControlName='InvPartLocation'
                                            [(ngModel)]="InvPartLocation" maxlength="200"> -->
                                            <button class="btn blue-bg round-btn add-btn-res" (click)="AddInv()"><i
                                                class="fas fa-plus-circle" aria-hidden="true"></i>
                                            Add
                                        </button>
                                    </div>
                                     
                                </div>

                            </div>
                            <div class="col-sm-12 p-0">
                                <table class="display table-bordered table clearfix order-detail mb-0 mt-4">
                                    <thead>
                                        <tr>
                                            <th style="font-weight:bold; width: 5%;"></th>
                                            <th style="font-weight:bold; width: 6%;">Avlb. Qty</th>
                                            <th style="font-weight:bold; width: 10%;">Date</th>
                                            <th style="font-weight:bold; width: 10%;">Cost</th>
                                            <th style="font-weight:bold; width: 12%;">Type</th>
                                            <th style="font-weight:bold; width: 12%;">Number</th>
                                            <th style="font-weight:bold; width: 12%;">Vendor</th>
                                            <th style="font-weight:bold; width: 10%;">Loc Name</th>
                                            <th style="font-weight:bold; width: 10%;">Location</th>
                                            <th style="font-weight:bold; width: 12%;">Tracking Details</th>
                                            <th style="font-weight:bold; width: 10%;">Manual/PO </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let InvItem of InvList; let i = index">
                                            <td>

                                                <a ngbTooltip="Move Inventory"><span
                                                        class="fa fa-exchange partEdit mr-1"
                                                        (click)="MoveInvRow(InvItem)"></span></a>
                                                <a ngbTooltip="Adjust Quantity"
                                                    *ngIf="InvItem.InvType!=5 && InvItem.InvType!=6 && InvItem.InvType!=7"><span
                                                        class="fa fa-indent partEdit ml-1"
                                                        (click)="AdjustInvRow(InvItem)"></span></a>

                                                <!-- <span class="dropdown" #ddlMoveAdjust>
                                                    <span class="fas fa-gear ml-1 mr-1" data-toggle="dropdown"
                                                        aria-haspopup="true" aria-expanded="false"
                                                        (click)="openDrop(i)"></span>
                                                    <div class="dropdown-menu openShow" [ngClass]="'openedDrop'+i">
                                                        <a class="dropdown-item" (click)="MoveInvRow(InvItem)">Move
                                                            Inventory</a>
                                                        <a class="dropdown-item" (click)="AdjustInvRow(InvItem)">Adjust
                                                            Quantity</a>
                                                    </div>
                                                </span> -->
                                            </td>
                                            <td>
                                                <div *ngIf="normalShow==true || RowEdit==false">{{InvItem.InvQty}}</div>
                                                <div
                                                    *ngIf="InvItem.InvSno==EditInvSno && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                    <input class="customInput"
                                                        [ngClass]="((!form.controls.UpdateInvQty.valid) && (InvValidationUpdate)) ? 'form-control1 text-center NumberOnly validation_border': 'form-control1 text-center NumberOnly'"
                                                        formControlName='UpdateInvQty' maxlength="5" type="text"
                                                        [(ngModel)]="UpdateInvQty" min="1" max="99" />
                                                </div>
                                            </td>
                                            <td>
                                                <div *ngIf="normalShow==true || RowEdit==false">{{InvItem.InvDate |
                                                    date:
                                                    'MMM-dd-yyyy'}}</div>
                                                <div
                                                    *ngIf="InvItem.InvSno==EditInvSno && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                    <input formControlName='UpdateInvDate' class="customInput"
                                                        [ngClass]="((!form.controls.UpdateInvDate.valid) && (InvValidationUpdate)) ? 'form-control1 text-center validation_border': 'form-control1 text-center'"
                                                        type="date" min="{{MinDate}}" [(ngModel)]="UpdateInvDate" />
                                                </div>
                                            </td>
                                            <td>
                                                <div *ngIf="normalShow==true || RowEdit==false">${{InvItem.InvCost}}
                                                </div>
                                                <div
                                                    *ngIf="InvItem.InvSno==EditInvSno && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                    <input class="customInput Cost"
                                                        [ngClass]="((!form.controls.UpdateInvCost.valid) && (InvValidationUpdate)) ? 'form-control1 text-center validation_border': 'form-control1 text-center'"
                                                        formControlName='UpdateInvCost' type="text"
                                                        [(ngModel)]="UpdateInvCost" maxlength="8" />
                                                </div>
                                            </td>
                                            <td>
                                                <div *ngIf="normalShow==true || RowEdit==false">
                                                    {{GetInvTypeDDLText(InvItem.InvType)}}</div>
                                                <div
                                                    *ngIf="InvItem.InvSno==EditInvSno && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                    <select formControlName='UpdateInvType' class="customInput"
                                                        [ngClass]="((!form.controls.UpdateInvType.valid) && (InvValidationUpdate)) ? 'form-control1 validation_border': 'form-control1'"
                                                        [(ngModel)]="UpdateInvType">
                                                        <option value=''>Select Type</option>
                                                        <option *ngFor="let item of InvTypeList" [value]="item.id">
                                                            {{item.name}}
                                                        </option>
                                                    </select>
                                                </div>
                                            </td>
                                            <td>
                                                <div *ngIf="normalShow==true || RowEdit==false">
                                                    {{GetInvType2DDLText(InvItem.InvType,
                                                    InvItem.InvType2)}}</div>
                                                <div
                                                    *ngIf="InvItem.InvSno==EditInvSno && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                    <ng-container *ngIf='UpdateInvType == "5"'>
                                                        <input class="customInput"
                                                            [ngClass]="((!form.controls.UpdateInvServNo.valid) && (InvValidationUpdate)) ? 'form-control1 text-center validation_border': 'form-control1 text-center'"
                                                            formControlName='UpdateInvServNo' type="text"
                                                            [(ngModel)]="UpdateInvServNo" />
                                                    </ng-container>
                                                    <ng-container *ngIf='UpdateInvType == "6"'>
                                                        <select formControlName='UpdateInvPartOrder' class="customInput"
                                                            [ngClass]="((!form.controls.UpdateInvPartOrder.valid) && (InvValidationUpdate)) ? 'form-control1 validation_border': 'form-control1'"
                                                            [(ngModel)]="UpdateInvPartOrder">
                                                            <option value=''>Select Part SO Order</option>
                                                            <option *ngFor="let item of InvPartOrderDDL"
                                                                [value]="item.id">
                                                                {{item.name}}
                                                            </option>
                                                        </select>
                                                    </ng-container>
                                                    <ng-container *ngIf='UpdateInvType == "7"'>
                                                        <select formControlName='UpdateInvProductOrder'
                                                            class="customInput"
                                                            [ngClass]="((!form.controls.UpdateInvProductOrder.valid) && (InvValidationUpdate)) ? 'form-control1 validation_border': 'form-control1'"
                                                            [(ngModel)]="UpdateInvProductOrder">
                                                            <option value=''>Select Product SO Order</option>
                                                            <option *ngFor="let item of InvProductOrderDDL"
                                                                [value]="item.id">
                                                                {{item.name}}
                                                            </option>
                                                        </select>
                                                    </ng-container>
                                                </div>
                                            </td>

                                            <td>
                                                <div *ngIf="normalShow==true || RowEdit==false">
                                                    {{GetVendorNameById(InvItem.InvVendor)}}
                                                </div>
                                                <div
                                                    *ngIf="InvItem.InvSno==EditInvSno && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                    <select class="customInput" formControlName='UpdateInvVendor'
                                                        [ngClass]="((!form.controls.UpdateInvVendor.valid) && (InvValidation)) ? 'validation_border': ''"
                                                        [(ngModel)]="UpdateInvVendor">
                                                        <option value=''>Select Vendor</option>
                                                        <option *ngFor="let item of partVendorsList" [value]="item.id">
                                                            {{item.name}}
                                                        </option>
                                                    </select>
                                                </div>
                                            </td>
                                            <td>
                                                <div *ngIf="normalShow==true || RowEdit==false">
                                                    {{GetLocName(InvItem.InvLoc)}}
                                                </div>
                                                <div
                                                    *ngIf="InvItem.InvSno==EditInvSno && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                    <input class="customInput text-center"
                                                        formControlName='UpdateInvPartLocation' type="text"
                                                        [(ngModel)]="UpdateInvPartLocation" max="200" />
                                                </div>
                                            </td>
                                            <td>
                                                <div *ngIf="normalShow==true || RowEdit==false">
                                                    {{InvItem.InvLoc1 == "null" ? "" : InvItem.InvLoc1 }}
                                                </div>
                                            </td>
                                            <td>
                                                <div class="tackingtooltip"
                                                    (click)="OpenSerialModel(InvItem.obj1, InvItem.InvType, InvItem.id == undefined ? InvItem.InvSno : InvItem.id, InvItem.InvTracking, InvItem.InvQty)">
                                                    <label class="customLabel"><span class="tooltipWrap tooltip-icon"
                                                            style="display:inline;"><i
                                                                class="fa fa-info-circle"></i></span>
                                                                <span class="tooltipsSerial">
                                                                    <ng-container *ngIf="InvItem.obj1.length > 0 else elseBlock">
                                                                        <span
                                                                        *ngFor="let dynamic of filterActiveRecords(InvItem.obj1); let i = index; let isLast=last;"
                                                                        style="color:white">
                                                                        <ng-container *ngIf="dynamic.isActive == true">
                                                                            {{dynamic.serialNo == "" ? '-' :
                                                                            dynamic.serialNo}}{{dynamic.serialNo != "" ? isLast
                                                                            ? '' : ', ' : ''}}
                                                                        </ng-container>
        
                                                                    </span>
                                                                    </ng-container>
                                                                    <ng-template #elseBlock>{{InvItem.InvTracking == "3" ? 'None' : "No Tracking" }}</ng-template>
                                                                </span>
                                                      
                                                    </label>
                                                </div>
                                            </td>
                                            <!-- <td>
                                                <div *ngIf="normalShow==true || RowEdit==false">
                                                    {{InvItem.InvPartLocation}}
                                                </div>
                                                <div
                                                    *ngIf="InvItem.InvSno==EditInvSno && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                    <input class="customInput text-center"
                                                        formControlName='UpdateInvPartLocation' type="text"
                                                        [(ngModel)]="UpdateInvPartLocation" maxlength="200" />
                                                </div>
                                            </td> -->
                                            <td>
                                                <div>{{InvItem.purchaseOrderId==null?"Manual":"PO"}}</div>
                                            </td>

                                        </tr>
                                        <tr *ngIf="InvList.length==0">
                                            <td colspan="8" class="text-center">Inventory Item is not Available</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <hr class="mb-1 mt-2">
                        <h3 class="qty_hd"><strong>Total Qty : {{totalQty}}</strong></h3>
                        <div class="submit-from text-right">
                            <button *ngIf="rolePermissionsList && rolePermissionsList[0].isInsert"
                                class="btn blue-bg round-btn" (click)="SaveConfirm(confirmSave)"
                                [disabled]="isPartval"><i class="fas fa-save"
                                    aria-hidden="true"></i>{{buttonValue}}</button>
                            <button class="btn darkred-bg round-btn" (click)="Exit(discardItem)"><i
                                    class="fas fa-sign-out-alt" aria-hidden="true"></i>
                                Exit </button>

                        </div>

                    </form>
                </div>
            </div>

        </div>
    </div>

    <div class="row" [style.display]="isInvFind==true ? 'block' : 'none'">
        <div class="col-md-12 p-lg-0">
            <button class="btn blue-bg round-btn part-category" (click)="viewLogFun()"><i class="fas fa-down-circle"
                    aria-hidden="true"></i>View Log<i class="ml-1 fas fa-angle-down" aria-hidden="true"></i>
            </button>
        </div>
    </div>
    <div class="inventory-table table-responsive" [style.display]="viewLog==true ? 'block' : 'none'">

        <table class="table table-bordered">
            <thead>
                <tr>
                    <th width="10%" scope="col">Action</th>
                    <th width="10%" scope="col">Date</th>
                    <th width="10%" scope="col">Type</th>
                    <th width="10%" scope="col">Number</th>
                    <th width="10%" scope="col">In Qty</th>
                    <th width="10%" scope="col">Out Qty</th>
                    <th width="10%" scope="col">Loc Name</th>
                    <th width="10%" scope="col">Location</th>
                    <th width="10%" scope="col">Tracking Details</th>
                    <th width="25%" scope="col">Remark</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of logList; let i = index">
                    <td [style.background-color]="item.bcolor">{{item.action}}</td>
                    <td [style.background-color]="item.bcolor">{{item.effectDate}}</td>
                    <td [style.background-color]="item.bcolor">{{item.type}}</td>
                    <td [style.background-color]="item.bcolor">{{item.typeValue}}</td>
                    <td [style.background-color]="item.bcolor">{{item.inQty}}</td>
                    <td [style.background-color]="item.bcolor">{{item.outQty}}</td>
                    <td [style.background-color]="item.bcolor">{{item.location}}</td>
                    <td [style.background-color]="item.bcolor">{{item.locationName}}</td>
                    <td [style.background-color]="item.bcolor">
                        <div  class="tackingtooltip breakword">
                        <label class="customLabel">
                        <span class="tooltipWrap tooltip-icon" style="display:inline;"><i
                                class="fa fa-info-circle"></i></span>
                        <span class="tooltipsSerials">
                            <span style="color: white;">
                                {{ item.tracking == "0" ? 'No Tracking' : item.tracking == "3" ? 'None' : item.serials == "" ? 'None' : item.serials }}

                            </span>
                        </span>
                        </label>
                        </div>
                    </td>
                    <td [style.background-color]="item.bcolor">{{item.remark}}</td>
                </tr>
                <!-- <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td><b>Total In : {{TotalIn}}</b></td>
                    <td><b>Total Out : {{TotalOut}}</b></td>
                    <td></td>
                    <td></td>
                </tr> -->
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="2" align="text-center"><b><b>Total Available : {{totalQtyLog}}</b></b></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    </div>

    <ng-template #itemModel let-c="close" let-d="dismiss">
        <h4 class="modal-title darkred-bg alert_heading">Move Inventory - Move part number {{displayPartNo}} from
            {{displayOldLocation}} to New Location:</h4>
        <button class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-body">
            <form [formGroup]="formPopup">

                <div class="row mt-2">
                    <div class="col-lg-3 col-md-12 mt-lg-0 mt-1">
                        <div class="row">
                            <div class="col-lg-4 col-md-2 trp required">
                                <label class="customLabel">Current Qty</label>
                            </div>
                            <div class="col-lg-8 col-md-10">
                                <input type="text" class="customInput NumberOnly" formControlName='mInvQty'
                                    [(ngModel)]="mInvQty" maxlength="5" min="1" max="99" disabled>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-12 mt-lg-0 mt-1">
                        <div class="row">
                            <div class="col-lg-4 col-md-2 trp required">
                                <label class="customLabel">Date</label>
                            </div>
                            <div class="col-lg-8 col-md-10">
                                <input type="date" class="customInput" min="{{MinDate}}" placeholder="Date"
                                    formControlName='mInvDate' [(ngModel)]="mInvDate"
                                    [ngClass]="((!formPopup.controls.mInvDate.valid) && (mInvValidation)) ? 'validation_border': ''"
                                    disabled>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-12 mt-lg-0 mt-1">
                        <div class="row">
                            <div class="col-lg-4 col-md-2 trp required">
                                <label class="customLabel">Type</label>
                            </div>
                            <div class="col-lg-8 col-md-10">
                                <select class="customInput" formControlName='mInvType' [(ngModel)]="mInvType"
                                    [ngClass]="((!formPopup.controls.mInvType.valid) && (mInvValidation)) ? 'validation_border': ''">
                                    <option value=''>Select Type</option>
                                    <option *ngFor="let item of mInvTypeList" [value]="item.id">
                                        {{item.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <ng-container *ngIf='mInvType == "5"'>
                        <div class="col-lg-3 col-md-12 mt-lg-0 mt-1">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp required">
                                    <label class="customLabel">Number</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <input type="text" class="customInput" placeholder="Service #"
                                        formControlName='mInvServNo' [(ngModel)]="mInvServNo"
                                        [ngClass]="((!formPopup.controls.mInvServNo.valid) && (mInvValidation)) ? 'validation_border': ''">
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf='mInvType == "6"'>
                        <div class="col-lg-3 col-md-12 mt-lg-0 mt-1">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp required">
                                    <label class="customLabel">Number</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <select class="customInput" formControlName='mInvPartOrder' [(ngModel)]="mInvPartOrder"
                                    [ngClass]="((!formPopup.controls.mInvPartOrder.valid) && (mInvValidation)) ? 'validation_border': ''"
                                    (change)="ChangeOrderDropdown($event.target.value)">
                                    <option value=''>Select Part SO Order</option>
                                    <ng-container *ngFor="let item of mInvPartOrderDDL">
                                        <option *ngIf="item.qtyToFulfill!=0" [value]="item.id">
                                            {{item.name}}
                                        </option>
                                    </ng-container>
                                    <ng-container *ngFor="let item of mInvPartOrderDDL">
                                        <option *ngIf="item.qtyToFulfill==0" [value]="item.id" [disabled]="true">
                                            {{item.name}} - Fulfilled
                                        </option>
                                    </ng-container>
                                    <!-- <option *ngFor="let item of mInvPartOrderDDL" [value]="item.id">
                                        {{item.name}}
                                    </option> -->
                                </select>
                                    <!-- <select class="customInput" formControlName='mInvPartOrder'
                                        [(ngModel)]="mInvPartOrder"
                                        [ngClass]="((!formPopup.controls.mInvPartOrder.valid) && (mInvValidation)) ? 'validation_border': ''"
                                        (change)="ChangeOrderDropdown($event.target.value)">
                                        <option value=''>Select Part SO Order</option>
                                        <option *ngFor="let item of mInvPartOrderDDL" [value]="item.id">
                                            {{item.name}}
                                        </option>
                                    </select> -->
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf='mInvType == "7"'>
                        <div class="col-lg-3 col-md-12 mt-lg-0 mt-1">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp required">
                                    <label class="customLabel">Number</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <select class="customInput" formControlName='mInvProductOrder'
                                    [(ngModel)]="mInvProductOrder"
                                    [ngClass]="((!formPopup.controls.mInvProductOrder.valid) && (mInvValidation)) ? 'validation_border': ''"
                                    (change)="ChangeOrderDropdown($event.target.value)">
                                    <option value=''>Select Product SO Order</option>
                                    <ng-container *ngFor="let item of mInvProductOrderDDL">
                                        <option *ngIf="item.qtyToFulfill!=0" [value]="item.id">
                                            {{item.name}}
                                        </option>
                                    </ng-container>
                                    <ng-container *ngFor="let item of mInvProductOrderDDL">
                                        <option *ngIf="item.qtyToFulfill==0" [value]="item.id" [disabled]="true">
                                            {{item.name}} - Fulfilled
                                        </option>
                                    </ng-container>
                                    <!-- <option *ngFor="let item of mInvProductOrderDDL" [value]="item.id">
                                        {{item.name}}
                                    </option> -->
                                </select>
                                    <!-- <select class="customInput" formControlName='mInvProductOrder'
                                        [(ngModel)]="mInvProductOrder"
                                        [ngClass]="((!formPopup.controls.mInvProductOrder.valid) && (mInvValidation)) ? 'validation_border': ''"
                                        (change)="ChangeOrderDropdown($event.target.value)">
                                        <option value=''>Select Product SO Order</option>
                                        <option *ngFor="let item of mInvProductOrderDDL" [value]="item.id">
                                            {{item.name}}
                                        </option>
                                    </select> -->
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <div class="row mt-2">
                    <div class="col-lg-3 col-md-12 mt-lg-0 mt-1">
                        <div class="row">
                            <div class="col-lg-4 col-md-2 trp required">
                                <label class="customLabel">Qty to Move</label>
                            </div>
                            <div class="col-lg-8 col-md-10">
                                <input type="text" class="customInput NumberOnly" formControlName='mInvQtyToMove'
                                    [(ngModel)]="mInvQtyToMove" maxlength="5" min="1" max="99"
                                    oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"
                                    [ngClass]="((!formPopup.controls.mInvQtyToMove.valid || mInvQtyValidation) && (mInvValidation)) ? 'validation_border': ''">
                            </div>
                        </div>
                    </div>
                    <!-- <div class="col-lg-3 col-md-12 mt-lg-0 mt-1">
                        <div class="row">
                            <div class="col-lg-4 col-md-2 trp">
                                <label class="customLabel">Location</label>
                            </div>
                            <div class="col-lg-8 col-md-10">
                                <input type="text" class="customInput" placeholder="Enter Location"
                                    formControlName='mInvLocation' [(ngModel)]="mInvLocation">
                            </div>
                        </div>
                    </div> -->
                    <div class="col-lg-3 col-md-12 mt-lg-0 mt-1">
                        <div class="row">
                            <div class="col-lg-4 col-md-2 trp">
                                <label class="customLabel">Location Name</label>
                            </div>
                            <div class="col-lg-8 col-md-10">
                                <select class="customInput" formControlName='mInvLocation2' [(ngModel)]="mInvLocation2"
                                    [ngClass]="((!formPopup.controls.mInvLocation2.valid) && (mInvValidation)) ? 'validation_border': ''"
                                    (change)="changeLocationMove($event)">
                                    <option value=''>Location Name</option>
                                    <option *ngFor="let item of locationNameMoveList" [value]="item.id">
                                        {{item.locationName}}
                                    </option>
                                </select>
    
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-12 mt-lg-0 mt-1">
                        <div class="row">
                            <div class="col-lg-4 col-md-2 trp">
                                <label class="customLabel">Location</label>
                            </div>
                            <div class="col-lg-8 col-md-10">
                                <select class="customInput" formControlName='mInvLocation1' [(ngModel)]="mInvLocation1"
                                    [ngClass]="((!formPopup.controls.mInvLocation1.valid) && (mInvValidation)) ? 'validation_border': ''">
                                    <option value=''>Location</option>
                                    <option *ngFor="let item of locationLevelMoveList" [value]="item.levelsName">
                                        {{item.levelsName}}
                                    </option>
                                </select>
    
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12 mt-lg-0 mt-1">
                        <div class="row">
                            <div class="col-lg-1 col-md-2 trp">
                                <label class="customLabel">Reason</label>
                            </div>
                            <div class="col-lg-8 col-md-10">
                                <input type="text" class="customInput" placeholder="Enter Reason"
                                    formControlName='mInvReason' [(ngModel)]="mInvReason">
                            </div>
    
                        </div>
                    </div>
                </div>
                <div class="alert alert-danger fade show mt-3" role="alert" style="padding: 0.35rem 1.25rem;"
                    *ngIf="mQtyMoveMsg==true">
                    Quantity can't be move more than {{mValidateQtyToFulfill}} for this sales order.
                    <button type="button" class="close" (click)="closenoti()">
                        <strong aria-hidden="true" class="text-danger">&times;</strong>
                    </button>
                </div>
            </form>
        </div>
        <div class="modal-footer text-center">
            <button class="btn blue-bg round-btn add-btn-res" (click)="MoveInv()"><i class="fa fa-arrow-right"
                    aria-hidden="true"></i>
                Move
            </button>
            <button type="button" class="btn red-bg round-btn m-0" (click)="c('Close click')">Exit</button>
        </div>
    </ng-template>

    <ng-template #itemModel2 let-c="close" let-d="dismiss">
        <h4 class="modal-title darkred-bg alert_heading">Adjust Quantity - {{displayPartName}}</h4>
        <button class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-body">
            <form [formGroup]="formPopupAdjustQty">

                <div class="row mt-2">
                    <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                        <div class="row">
                            <div class="col-lg-4 col-md-2 trp required">
                                <label class="customLabel">Current Qty</label>
                            </div>
                            <div class="col-lg-8 col-md-10">
                                <input type="text" class="customInput NumberOnly" formControlName='adjInvQty'
                                    [(ngModel)]="adjInvQty" maxlength="5" min="1" max="99" disabled>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                        <div class="row">
                            <div class="col-lg-4 col-md-2 trp required">
                                <label class="customLabel">Adjust Qty To</label>
                            </div>
                            <div class="col-lg-8 col-md-10">
                                <input type="text" class="customInput NumberOnly" formControlName='adjInvQtyToAdjust'
                                    [(ngModel)]="adjInvQtyToAdjust" maxlength="5" min="1" max="99"
                                    [ngClass]="((!formPopupAdjustQty.controls.adjInvQtyToAdjust.valid || (formPopupAdjustQty.controls.adjInvQtyToAdjust.value == formPopupAdjustQty.controls.adjInvQty.value)) && (adjInvValidation)) ? 'validation_border': ''">
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                        <div class="row">
                            <div class="col-lg-4 col-md-2 trp">
                                <label class="customLabel">Reason</label>
                            </div>
                            <div class="col-lg-8 col-md-10">
                                <select class="customInput" [(ngModel)]="adjInvReason" formControlName="adjInvReason">
                                    <option value="">Select</option>
                                    <option *ngFor="let item of adjInvReasonList" [value]="item.name">
                                        {{item.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
        <div class="modal-footer text-center">
            <button class="btn blue-bg round-btn add-btn-res ml-0" (click)="AdjustInv()">
                Save
            </button>
            <button type="button" class="btn red-bg round-btn m-0" (click)="c('Close click')">Exit</button>
        </div>
    </ng-template>

    <ng-template #QtyMoveMsgModel let-c="close" let-d="dismiss">
        <h4 class="modal-title darkred-bg sweet-heading"></h4>
        <button class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-body">
            <div class="thank-you-pop">
                <img src="{{mMsgClass}}" alt="">
                <p class="mb-0">{{mMsg}}</p>
            </div>
        </div>
    </ng-template>

    <ng-template #contentExit let-c="close" let-d="dismiss">
        <h4 class="modal-title darkred-bg sweet-heading"></h4>
        <button class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-body">
            <div class="thank-you-pop">
                <img src="./assets/image/popup-alert.png" alt="">
                <h1>Confirmation</h1>
                <p class="mb-0">Are you sure you want to exit without saving?</p>
            </div>
        </div>
        <div class="modal-footer sweet-footer text-center">
            <button type="button" class="btn green-bg round-btn m-0 mr-1"
                (click)="goDashboard(); c('Close click')">Yes</button>
            <button type="button" class="btn red-bg round-btn m-0" (click)="c('Close click')">No</button>
        </div>
    </ng-template>

    <ng-template #confirmSave let-c="close" let-d="dismiss">
        <h4 class="modal-title darkred-bg sweet-heading"></h4>
        <button class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-body">
            <div class="thank-you-pop">
                <img src="./assets/image/popup-alert.png" alt="">
                <h1>Confirmation</h1>
                <p class="mb-0">Are you sure you want to save?</p>
            </div>
        </div>
        <div class="modal-footer sweet-footer text-center">
            <button type="button" class="btn green-bg round-btn m-0 mr-1"
                (click)="SaveInv(); c('Close click')">Yes</button>
            <button type="button" class="btn red-bg round-btn m-0" (click)="c('Close click')">No</button>
        </div>
    </ng-template>

    <div class="modal" id="add_update">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header1 darkred-bg sweet-heading p-0">
                    <h4 class="modal-title sweet-heading"></h4>
                    <button type="button" class="close btn alert_modal" (click)="updateitem()"
                        data-dismiss="modal">&times;</button>
                </div>
                <div class="modal-body p-1">
                    <div class="thank-you-pop">
                        <img src="./assets/image/Green-Round-Tick.png" alt="">
                        <h1>Thank You!</h1>
                        <p>Successfully Saved.</p>
                    </div>
                </div>
                <div class="modal-footer sweet-footer p-1">
                    <button type="button" class="btn red-bg round-btn" (click)="updateitem()"
                        data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
    <ng-template #discardItem let-c="close" let-d="dismiss">
        <h4 class="modal-title darkred-bg alert_heading sweet-heading"></h4>
        <button class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>

        <div class="modal-body p-1">
            <div class="thank-you-pop">
                <img src="./assets/image/popup-alert.png" alt="">
                <h1>Confirmation</h1>
                <p class="mt-1">{{deleteDetail}}</p>
            </div>
        </div>
        <div class="modal-footer sweet-footer text-center p-1">
            <button type="button" class="btn green-bg round-btn"
                (click)="gotoDashBoard(); c('Close click')">Yes</button>
            <button type="button" class="btn red-bg round-btn" (click)="c('Close click')">No</button>
        </div>
    </ng-template>
</section>

<div class="modal" id="InvExist">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="InvExist()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p style="margin-bottom: 0">Part is Non Inventory! You can not added the Inventory on this Part</p>
                  
                   
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
             
                <button type="button" class="btn red-bg round-btn" (click)="InvExist()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="InvExist1">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="InvExist1()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p style="margin-bottom: 0">{{InvProp}}</p>
                  
                   
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
             
                <button type="button" class="btn red-bg round-btn" (click)="InvExist1()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="SerialModel">
    <div class="modal-dialog modal-dialog-scrollable" [ngClass]="(this.qtyLabel.length > 15) ? 'heightClass1': 'heightClass'">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"><span *ngIf='exitProp == false' style="color:white">Add </span><span style="color:white" *ngIf='exitProp == true'>Update </span>{{Tracking == "1" ? "Serial #" : "Lot #"}} to {{inventoryQty}}
                    Part(s)</h4>
                <button type="button" class="close btn alert_modal" (click)="CloseModel()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">

                <div class="thank-you-pop">
                    <div class="row mt-1" *ngFor="let data of qtyLabel; let i=index;">
                        <ng-container *ngIf="data.isActive == true">
                            <div class="col-md-4">
                                <span class="customLabel">Part {{i + 1}} :</span>
                            </div>
                            <div class="col-md-7">
                                <input type="text" class="customInput" [disabled]="IsSerialDisabled == true"
                                    [(ngModel)]="data.serialNo" (blur)="changeValidation(data.id)"
                                    [ngClass]="(data.valid == true) ? 'validation_border': ''" />

                            </div>
                            <div class="col-md-4">

                            </div>
                            <div class="col-md-7" style="color:red; 
                                text-align: left;">{{data.message}}</div>
                        </ng-container>

                    </div>
                   
                </div>
            </div>
            <div class="modal-footer text-center">
                <button type="button" *ngIf='exitProp == false' class="btn blue-bg round-btn add-btn-res ml-0 padding"
                    (click)="SaveDetail()"><i class="fas fa-save" aria-hidden="true"></i>Save</button>
                <button type="button" *ngIf='exitProp == true' class="btn blue-bg round-btn ml-0 padding"
                    (click)="EditDetail(this.openId)"><i class="fas fa-save" aria-hidden="true"></i>Update</button>
                <button type="button" class="btn red-bg round-btn m-0 padding" data-dismiss="modal"
                    (click)="CloseModel()">Exit</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="Update_Success">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="Update_SuccessClose()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You</h1>
                    <p style="margin-bottom: 0">{{openTracking == "1" ? "Serial #" : "Lot #"}} Update Successfully</p>


                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">

                <button type="button" class="btn red-bg round-btn" (click)="Update_SuccessClose()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="DeleteSerials" style="z-index: 1080">
    <div class="modal-dialog modal-dialog-scrollable" [ngClass]="(this.AddSerials.length > 10 || this.DeleteSerialArr.length > 10 ) ? 'heightClass1': 'heightClass'">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading">{{AddSerial == true ? "Add " + serialNumDiff : "Remove " +
                    serialNumDiff}} {{DSerialTracking == "1" ? "Serial#" : "Lot#"}} Number</h4>
                <button type="button" class="close btn alert_modal" (click)="CloseDSerials()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <div class="row mt-1" *ngFor="let data of DeleteSerialArr; let i =index;">
                        <ng-container>
                            <div class="col-md-4">
                                <span class="customLabel">Part {{i+1}} :</span>
                            </div>
                            <div class="col-md-7">
                                <input type="text" class="customInput" [disabled]="data.IsSerialDisabled == false"
                                    [(ngModel)]="data.serialNo" (blur)="changeValidation1(data.id)"
                                    [ngClass]="(data.isActive == false) ?  'addClass' : ''" />

                            </div>
                            <div class="col-md-1" *ngIf="AddSerial == false">
                                <span class="minusbutton" *ngIf="data.isActive == true">
                                    <i class="fas fa-minus-circle red-text"
                                        (click)="deleteSerialNum(data.id, DSerialTracking)"></i>
                                </span>
                                <span class="minusbutton" *ngIf="data.isActive == false">
                                    <i class="fas fa-plus-circle green-text"
                                        (click)="addSerialNum(data.id, DSerialTracking)"></i>
                                </span>
                            </div>
                            <div class="col-md-4">

                            </div>
                            <div class="col-md-7" style="color:red; 
                            text-align: left;">{{data.message}}</div>
                           

                        </ng-container>


                    </div>
                    <div class="row mt-1" *ngFor="let data of AddSerials; let j =index;">
                        <div class="col-md-4">
                            <span class="customLabel">Part {{data.ids}} :</span>
                        </div>
                        <div class="col-md-7">
                            <input type="text" class="customInput" [disabled]="data.IsSerialDisabled == true"
                                [(ngModel)]="data.serialNo" (blur)="changeValidation2(data.ids)"
                                [ngClass]="(data.valid == true) ? 'validation_border': ''" />

                        </div>
                        <div class="col-md-4">

                        </div>
                        <div class="col-md-7" style="color:red; 
                        text-align: left;">{{data.message}}</div>
                    </div>
                </div>
            </div>
            <div class="modal-footer text-center">
                <button type="button" class="btn blue-bg round-btn add-btn-res ml-0"
                    (click)="SaveDSerial(DSerialTracking, AddSerial)">Save</button>
                <button type="button" class="btn red-bg round-btn m-0" (click)="CloseDSerials()"
                    data-dismiss="modal">Exit</button>
            </div>
        </div>
    </div>
</div>


<div class="modal" id="GeneralModel" style="z-index: 1085">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="CloseGeneralModel()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>{{SerialComment}}</p>
                </div>
            </div>
            <div class="modal-footer text-center">
                <button type="button" class="btn red-bg round-btn m-0" data-dismiss="modal"
                    (click)="CloseGeneralModel()">Exit</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="MoveSerials" style="z-index: 1080">
    <div class="modal-dialog modal-dialog-scrollable" [ngClass]="(this.MoveSerialArr.length > 10) ? 'heightClass1': 'heightClass'">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"> Move {{ moveDiff}} {{DSerialTracking == "1" ? "Serial#" : "Lot#"}} Number</h4>
                <button type="button" class="close btn alert_modal" (click)="CloseMoveSerials()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <div class="row mt-1" *ngFor="let data of MoveSerialArr; let i =index;">
                        <ng-container>
                            <div class="col-md-4">
                                <span class="customLabel">Part {{i+1}} :</span>
                            </div>
                            <div class="col-md-7">
                                <input type="text" class="customInput" [disabled]="data.IsSerialDisabled == false"
                                    [(ngModel)]="data.serialNo" (blur)="changeValidation1(data.id)"
                                    [ngClass]="(data.isActive == false) ?  'movebutton' : ''" />

                            </div>
                            <div class="col-md-1">
                                <span class="minusbutton">
                                    <i class="fa fa-arrow-right" *ngIf="data.isActive == true"
                aria-hidden="true"  (click)="MoveSerialNum(data.id, DSerialTracking)"></i>
                                </span>
                            </div>
                            <div class="col-md-4">

                            </div>
                            <div class="col-md-7" style="color:red; 
                            text-align: left;">{{data.message}}</div>
                           

                        </ng-container>


                    </div>
                   
                    
                </div>
            </div>
            <div class="modal-footer text-center">
                <button type="button" class="btn blue-bg round-btn add-btn-res ml-0"
                    (click)="MoveSerial(DSerialTracking)">Save</button>
                <button type="button" class="btn red-bg round-btn m-0" (click)="CloseMoveSerials()"
                    data-dismiss="modal">Exit</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="SetTracking" style="z-index: 1080">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="CloseTracking()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>{{SerialComment}}</p>
                </div>
            </div>
            <div class="modal-footer text-center">
                <button type="button" class="btn red-bg round-btn m-0" data-dismiss="modal"
                    (click)="CloseTracking()">Exit</button>
            </div>
        </div>
    </div>
</div>