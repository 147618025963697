<mat-dialog-content>
    <form [formGroup]="locationForm" class="replacement-form">
        <div class="create-replenishment">
            <div class="create-replenishment-section">
                <div class="row">
                    <div class="col-12">
                        <h2 class="replacement-heading">Create Replenishment</h2>
                    </div>
                    <div class="col-md-10 col-8">
                        <h3 class="replacement-para">Please scan or search for the Part Number you would like to replenish.</h3>
                    </div>
                    <div class="col-md-2 col-4 p-0">
                        <span *ngIf="isPart" (click)="isPartChange()" class="change-part-btn">Change Part</span>
                    </div>
                </div>
            </div>
            <div class="replenishment-part my-2" *ngIf="!isPart">
                <div class="part-replenishment d-flex align-items-center gap-3">
                    <div class="part-input">
                        <mat-form-field appearance="outline">
                            <mat-label>Part Number</mat-label>
                            <input type="text" matInput [formControl]="myControl" [matAutocomplete]="auto" />
                            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete">
                                <mat-option *ngFor="let option of filteredOptions | async" [value]="option.partNo"
                                    (click)="getPartId(option.id)">
                                    <strong>{{option.name}}</strong> <br>
                                    <p> SKU: {{option.partNo}}</p>
                                </mat-option>
                            </mat-autocomplete>
                            <span class="material-icons"> search</span>
                        </mat-form-field>
                    </div>
                    <div class="scan-section">
                        <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 64 64" fill="none">
                            <g filter="url(#filter0_d_1448_76537)">
                                <rect x="2" y="2" width="60" height="60" rx="4" fill="#EEEDF1" />
                                <path
                                    d="M41.1108 10H38.8884C37.555 10 36.666 10.8889 36.666 12.2223C36.666 13.5556 37.555 14.4445 38.8884 14.4445H41.1108C43.5554 14.4445 45.5555 16.4446 45.5555 18.889V21.3335C45.5555 22.6669 46.4444 23.5558 47.7779 23.5558C49.1113 23.5558 50.0002 22.6669 50.0002 21.3335V18.889C50.0002 14.0001 46 10 41.1108 10Z"
                                    fill="#43474E" />
                                <path
                                    d="M47.7779 36.666C46.4444 36.666 45.5555 37.5549 45.5555 38.8883V41.1105C45.5555 43.555 43.5554 45.5551 41.1108 45.5551H38.8884C37.555 45.5551 36.666 46.444 36.666 47.7773C36.666 49.1107 37.555 49.9996 38.8884 49.9996H41.1108C46 49.9996 50.0002 45.9995 50.0002 41.1105V38.8883C50.0002 37.5549 49.1113 36.666 47.7779 36.666Z"
                                    fill="#43474E" />
                                <path
                                    d="M21.1118 45.5551H18.8895C16.4449 45.5551 14.4447 43.555 14.4447 41.1105V38.8883C14.4447 37.5549 13.5558 36.666 12.2224 36.666C10.8889 36.666 10 37.5549 10 38.8883V41.1105C10 45.9995 14.0003 49.9996 18.8895 49.9996H21.1118C22.4453 49.9996 23.3342 49.1107 23.3342 47.7773C23.3342 46.444 22.4453 45.5551 21.1118 45.5551Z"
                                    fill="#43474E" />
                                <path
                                    d="M12.2224 23.3336C13.5558 23.3336 14.4447 22.4447 14.4447 21.1113V18.889C14.4447 16.4446 16.4449 14.4445 18.8895 14.4445H21.1118C22.4453 14.4445 23.3342 13.5556 23.3342 12.2223C23.3342 10.8889 22.4453 10 21.1118 10H18.8895C14.0003 10 10 14.0001 10 18.889V21.1113C10 22.2224 10.8889 23.3336 12.2224 23.3336Z"
                                    fill="#43474E" />
                                <path
                                    d="M29.9983 21.1105C29.9983 18.6661 27.9981 16.666 25.5535 16.666H21.1088C18.6642 16.666 16.6641 18.6661 16.6641 21.1105V25.5551C16.6641 27.9995 18.6642 29.9996 21.1088 29.9996H25.5535C27.9981 29.9996 29.9983 27.9995 29.9983 25.5551V21.1105ZM21.1088 25.5551V21.1105H25.5535V25.5551H21.1088Z"
                                    fill="#43474E" />
                                <path
                                    d="M43.3342 38.889V34.4445C43.3342 32 41.3341 30 38.8895 30H34.4447C32.0001 30 30 32 30 34.4445V38.889C30 41.3335 32.0001 43.3336 34.4447 43.3336H38.8895C41.3341 43.3336 43.3342 41.3335 43.3342 38.889ZM34.4447 34.4445H38.8895V38.889H34.4447V34.4445Z"
                                    fill="#43474E" />
                                <path
                                    d="M34.4431 27.7773H36.6654C37.9989 27.7773 38.8878 26.8884 38.8878 25.5551V21.1105H41.1102C42.4436 21.1105 43.3325 20.2216 43.3325 18.8883C43.3325 17.5549 42.4436 16.666 41.1102 16.666H36.6654C35.332 16.666 34.4431 17.5549 34.4431 18.8883V23.3328C33.1097 23.3328 32.2207 24.2217 32.2207 25.5551C32.2207 26.8884 33.1097 27.7773 34.4431 27.7773Z"
                                    fill="#43474E" />
                                <path
                                    d="M25.5535 32.2227H18.8864C17.553 32.2227 16.6641 33.1116 16.6641 34.4449C16.6641 35.7783 17.553 36.6672 18.8864 36.6672H23.3312V41.1117C23.3312 42.4451 24.2201 43.334 25.5535 43.334C26.887 43.334 27.7759 42.4451 27.7759 41.1117V34.4449C27.7759 33.1116 26.887 32.2227 25.5535 32.2227Z"
                                    fill="#43474E" />
                            </g>
                            <defs>
                                <filter id="filter0_d_1448_76537" x="0" y="0" width="64" height="64"
                                    filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                    <feColorMatrix in="SourceAlpha" type="matrix"
                                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                    <feOffset dx="2" dy="2" />
                                    <feGaussianBlur stdDeviation="2" />
                                    <feComposite in2="hardAlpha" operator="out" />
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
                                    <feBlend mode="normal" in2="BackgroundImageFix"
                                        result="effect1_dropShadow_1448_76537" />
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1448_76537"
                                        result="shape" />
                                </filter>
                            </defs>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
        <div class="image-section image-section-2">
            <div *ngIf="locationForm.value.imgPath != '' && locationForm.value.imgPath != null && locationForm.value.imgPath != undefined">
                <div class="card card-image">
                    <img class="PickListColImgBorder" src="{{ConstImgURL}}{{locationForm.value.imgPath}}">
                </div>
            </div>
            <div class="" *ngIf="locationForm.value.imgPath == '' && locationForm.value.imgPath != null && locationForm.value.imgPath != undefined">
                <div class="card card-image">
                    <img src="./assets/image/No-Image.png">
                </div>
            </div>
        </div>
        <div class="about-section mt-2">
            <h4 class="sku-heading text-center">SKU:{{locationForm.value.SKU}}</h4>
            <h3 class="put-away-heading text-center">There are <span class="location-text">{{locationForm.value.replenishmentItem}} </span> <span class="items-name">items</span> to Replenish.
            </h3>
        </div>
        <div class="default-location d-flex justify-content-between align-items-center">
            <div class="left-side mb-2">
                <h4>Destination Location</h4>
            </div>
            <div class="right-side mb-2">
                <h4 *ngIf="isDestination" (click)="locationChange()">{{isDestinationChange ? 'Cancel' : 'Change'}}</h4>
            </div>
        </div>

        <div class="repleinsment-secion mt-2" *ngIf="isDestination && isDestinationChange">
            <div class="d-flex flex-wrap">
                <div class="col-6 first-card">
                    <mat-form-field class="forShipping mt-2 p-0 w-100" appearance="outline">
                        <mat-label>Warehouse</mat-label>
                        <mat-select formControlName="wareHouseId" placeholder="Warehouse">
                            <mat-option *ngFor="let item of CompanyWareHouseList"
                                [value]="item.id">{{item.name}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-6 fourth-card">
                    <mat-form-field class="forShipping mt-2 p-0 w-100" appearance="outline">
                        <mat-label>Location Name</mat-label>
                        <mat-select formControlName="locationId" (selectionChange)="locNameChange($event.value)">
                            <mat-option *ngFor="let location of RecLocationList" [value]="location.id">
                                {{ location.locationName }}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="d-flex flex-wrap">
                <div class="col-md-3 col-6 first-card">
                    <mat-form-field class="forShipping m-0 mt-2 p-0 w-100" appearance="outline">
                        <mat-label>Aisle</mat-label>
                        <mat-select formControlName="aisle" placeholder="Aisle"
                            (selectionChange)="onloclevelChange(1,$event.value)">
                            <mat-option *ngFor="let item of level1List" [value]="item">{{item}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-3 col-6 second-card">
                    <mat-form-field class="forShipping m-0 mt-2 p-0 w-100" appearance="outline">
                        <mat-label>Section</mat-label>
                        <mat-select formControlName="section" placeholder="Section"
                            (selectionChange)="onloclevelChange(2,$event.value)">
                            <mat-option *ngFor="let item of level2List" [value]="item">{{item}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-3 col-6 third-card">
                    <mat-form-field class="forShipping m-0 mt-2 p-0 w-100" appearance="outline">
                        <mat-label>Shelf</mat-label>
                        <mat-select formControlName="shelf" placeholder="Shelf"
                            (selectionChange)="onloclevelChange(3,$event.value)">
                            <mat-option *ngFor="let item of level3List" [value]="item">{{item}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="col-md-3 col-6 fourth-card">
                    <mat-form-field class="forShipping m-0 mt-2 p-0 w-100" appearance="outline">
                        <mat-label>Bin</mat-label>
                        <mat-select formControlName="bin" placeholder="Bin"
                            (selectionChange)="onloclevelChange(4,$event.value)">
                            <mat-option *ngFor="let item of level4List" [value]="item">{{item}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="d-flex flex-wrap">
                <div class="col-6 first-card">
                    <mat-form-field class="forShipping mt-2 p-0 w-100" appearance="outline">
                        <mat-label>On Hand</mat-label>
                        <input matInput type="text" maxlength="100" minlength="3" formControlName="onHand">
                    </mat-form-field>
                </div>
                <div class="col-6 fourth-card">
                    <mat-form-field class="forShipping mt-2 p-0 w-100" appearance="outline">
                        <mat-label>Location Capacity</mat-label>
                        <input matInput type="text" maxlength="100" minlength="3" formControlName="locationCapacity">
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div *ngIf="!isDestination">
            <span>TBD</span>
        </div>
        <div *ngIf="isDestination && !isDestinationChange" class="address-section mt-2">
            <div class="main-address">
                    <span>Warehouse:{{locationForm.value.wareHouse}},
                    Location:{{locationForm.value.locationName}},
                    Address:{{locationForm.value.aisle}}-{{locationForm.value.section}}-{{locationForm.value.shelf}}-{{locationForm.value.bin}}
                </span>

            </div>
            <div class="on-hand-address">
                On Hand:{{locationForm.value.onHand}},
                </div>
                <div class="location-capacity">
                    Location Capacity:{{locationForm.value.locationCapacity}}
                </div>
            </div> 

        <div class="row justify-content-center mt-3">
            <button class="btnMinus" (click)="Counter('sub')"><b>-</b></button>
            <input matInput class="put-away-input w-25"  formControlName="moveQty"
                (input)="moveQtyChange($event.target.value)">
            <button class="btnPlus" (click)="Counter('add')"><b>+</b></button>
        </div>
        <div class="d-flex align-items-center">
            <div class="col-1">
                <span><a type="button" (click)="moveQtyChange(0)">0</a></span>
            </div>
            <div class="col-md-10 col-9">
                <mat-slider min="0" max="{{locationForm.value.replenishmentItem}}" step="1" class="custom-mat-slider w-100 ">
                    <input type="range" [value]="locationForm.value.moveQty"
                        (change)="moveQtyChange($event.target.value)" matSliderThumb>
                </mat-slider>
            </div>
            <div class="col-md-1 col-2">
                <span><a type="button" (click)="moveQtyChange(locationForm.value.replenishmentItem)">{{locationForm.value.replenishmentItem}}</a></span>
            </div>
        </div>
            <div class="default-location">
                <div class="left-side mb-2">
                    <h4 class="m-0">Source Location</h4>
                </div>
            </div>
            <mat-form-field class="forShipping m-0 mt-4 p-0 w-100 select-arrow-change-icon" appearance="outline">
                <mat-label>Select a Source Location</mat-label>
                <mat-select formControlName="sourceLocation" (selectionChange)="sourceLocChange($event.value)">
                    <mat-option *ngFor="let item of sourceLoc" [value]="item">
                        {{GetWareHouseValue(item.wareHouseId)}} {{GetLocationValue(item.locationName)}}
                        {{item.location}} Available:{{item.balance}}
                    </mat-option>
                </mat-select>
            </mat-form-field>

       <div class="footer-btn d-flex justify-content-end gap-3 mt-4 mb-2">
        <button  mat-dialog-close class="btn cancel-btn update-btn">Cancel</button>
        <button  mat-dialog-close class="btn save-btn update-btn" (click)="Save()" [disabled]="!locationForm.valid">Save</button>
       </div>
    </form>
</mat-dialog-content>