<!-- <p >System admin works!</p>
<p >{{userName}} logged in</p> -->

<div class="main-section">

    <header>
        <div class="header-left">
            <img src="/assets/image/logo.webp" class="hrdLogoSize">
        </div>
        <div class="header-right">
            <div class="row align-items-center">
                <div class="col-6">
                    <ul class="nav navbar-nav">
                        <li class="nav-item"><i class="fas fa-bars"></i></li>
                        <li class="nav-item nav-search"><i class="fas fa-search"></i>
                            <div class="search-input">
                                <input class="input" type="text" placeholder="Search...">
                                <div class="search-input-close"><i class="fas fa-times"></i></div>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="col-6 text-right">
                    <ul class="nav navbar-nav">
                        <li class="nav-item"><i class="far fa-bell"></i></li>
                        <li class="dropdown dropdown-user nav-item">
                            <a class="dropdown-toggle dropdown-user-link" href="#" data-toggle="dropdown"
                                aria-expanded="true">
                                <span class="mr-1 user-name text-bold-700">John Doe</span><span
                                    class="avatar avatar-online"><img src="/assets/image/profile.png"
                                        alt="avatar"><i></i></span>
                            </a>
                            <div class="dropdown-menu dropdown-menu-right">
                                <a class="dropdown-item" href="user-profile.html"><i class="ft-user"></i> Edit
                                    Profile</a>
                                <div class="dropdown-divider"></div><a class="dropdown-item" href="#"><i
                                        class="ft-power" (click)="LogOut()"></i> Logout</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </header>

    <section class="sidebar">
        <ul class="navigation navigation-main" id="main-menu-navigation">
            <li class="nav-item"><a routerLink="./" routerLinkActive="active"
                    [routerLinkActiveOptions]="{ exact: true }">
                    <i class="fas fa-home"></i><span class="menu-title">Dashboard</span></a>
            </li>
            <li class="nav-item"><a routerLink="./company" routerLinkActive="active">
                    <i class="fas fa-building"></i><span class="menu-title">Company</span></a>
            </li>
            <li class="nav-item"><a href="#">
                    <i class="fas fa-users"></i><span class="menu-title">Users</span></a>
            </li>
            <li class="nav-item"><a href="#">
                    <img src="/assets/image/customer.svg"><span class="menu-title">Customers</span></a>
            </li>
            <li class="nav-item"><a href="#">
                    <img src="/assets/image/market-store.svg"><span class="menu-title">Vendors</span></a>
            </li>
            <li class="nav-item"><a href="#">
                    <i class="fas fa-box-open"></i><span class="menu-title">Products</span></a>
            </li>
            <li class="nav-item"><a href="#">
                    <img src="/assets/image/question.svg"><span class="menu-title">Part Questions</span></a>
            </li>
            <li class="nav-item"><a href="#">
                    <img src="/assets/image/steering-wheel.svg"><span class="menu-title">Parts</span></a>
            </li>
            <li class="nav-item"><a href="#">
                    <i class="fas fa-luggage-cart"></i><span class="menu-title">Order</span></a>
            </li>
        </ul>
    </section>

    <section class="content-section">

        <div class="page-title mb-4">
            <h2>Company</h2>
        </div>

        <div class="row">
            <div class="col-sm-12">
                <div class="white-box">
                    <div class="white-box-title">
                        <h5>Company Details</h5>
                    </div>
                    <div class="card-desc">
                        <form>
                            <div class="row">
                                <div class="col-sm-12">
                                    <input type="text" class="form-control" placeholder="Enter Company Name">
                                </div>
                                <div class="col-sm-12">
                                    <input type="text" class="form-control" placeholder="Enter Address Line 1">
                                </div>
                                <div class="col-sm-12">
                                    <input type="text" class="form-control" placeholder="Enter Address Line 2">
                                </div>
                                <div class="col-sm-12">
                                    <div class="row">
                                        <div class="col-sm-5">
                                            <input type="text" class="form-control" placeholder="Enter City">
                                            <input type="text" class="form-control" placeholder="Enter State">
                                        </div>
                                        <div class="col-sm-5">
                                            <input type="text" class="form-control" placeholder="Enter Zip">
                                            <input type="text" class="form-control" placeholder="Enter Color">
                                        </div>
                                        <div class="col-sm-2">
                                            <input type="file" class="form-control choose-file-hidden">
                                            <img src="/assets/image/add-file.png" class="add-file">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!------------------------------------------------->

                            <div class="whitebox-subtitle mb-2 mt-2 pt-4 bt-1">
                                <div class="row">
                                    <div class="col-sm-6">
                                        <h5>Add Company Hierarchy </h5>
                                    </div>
                                    <div class="col-6 text-right">
                                        <a href="#">View Company Herirachy</a>
                                    </div>
                                </div>
                            </div>

                            <div class="row h_second_l">
                                <div class="col-sm-6">
                                    <select class="form-control">
                                        <option>Select Company Level</option>
                                        <option>Division</option>
                                        <option>Reseller</option>
                                        <option>Region</option>
                                        <option>Branch</option>
                                    </select>
                                </div>
                                <div class="col-sm-6">
                                    <input type="text" class="form-control" placeholder="Enter Name">
                                </div>
                                <div class="col-sm-12">
                                    <input type="text" class="form-control" placeholder="Enter Address Line 1">
                                </div>
                                <div class="col-sm-12">
                                    <input type="text" class="form-control" placeholder="Enter Address Line 2">
                                </div>
                                <div class="col-sm-12">
                                    <div class="row">
                                        <div class="col-sm-5">
                                            <input type="text" class="form-control" placeholder="Enter City">
                                            <input type="text" class="form-control" placeholder="Enter State">
                                        </div>
                                        <div class="col-sm-5">
                                            <input type="text" class="form-control" placeholder="Enter Zip">
                                            <input type="text" class="form-control" placeholder="Enter Color">
                                        </div>
                                        <div class="col-sm-2">
                                            <input type="file" class="form-control choose-file-hidden">
                                            <img src="/assets/image/add-file.png" class="add-file">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-sm-12 text-right mt-2 mb-2">
                                    <button class="btn blue-bg round-btn"><i class="fas fa-save"></i> Add Company
                                        Hierarchy </button>
                                </div>
                            </div>


                            <div class="grey-bg mt-4">
                                <div class="grey-sec-title">
                                    <div class="row align-items-center">
                                        <div class="col-sm-6">
                                            <h5>Region 1</h5>
                                        </div>
                                        <div class="col-sm-6 text-right">
                                            <button class="btn btn-sm green-bg sq-btn"> Add Company Hierarchy </button>
                                            <button class="btn btn-sm red-bg sq-btn"> <i class="fas fa-trash-alt"></i>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div class="grey-sec-desc">
                                    <div class="row h_second_1">

                                        <div class="col-sm-12 mb-4">
                                            <div class="row align-items-center">
                                                <div class="col-sm-6">
                                                    <h6>Add Company Hierarchy </h6>
                                                </div>
                                                <div class="col-sm-6 text-right">
                                                    <button class="btn btn-sm green-bg sq-btn"> <i
                                                            class="fas fa-plus"></i> </button>
                                                    <button class="btn btn-sm red-bg sq-btn"> <i
                                                            class="fas fa-trash-alt"></i> </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-sm-6">
                                            <select class="form-control">
                                                <option>Select Company Level</option>
                                                <option>Division</option>
                                                <option>Reseller</option>
                                                <option>Region</option>
                                                <option>Branch</option>
                                            </select>
                                        </div>
                                        <div class="col-sm-6">
                                            <input type="text" class="form-control" placeholder="Enter Name">
                                        </div>
                                        <div class="col-sm-12">
                                            <input type="text" class="form-control" placeholder="Enter Address Line 1">
                                        </div>
                                        <div class="col-sm-12">
                                            <input type="text" class="form-control" placeholder="Enter Address Line 2">
                                        </div>
                                        <div class="col-sm-12">
                                            <div class="row">
                                                <div class="col-sm-5">
                                                    <input type="text" class="form-control" placeholder="Enter City">
                                                    <input type="text" class="form-control" placeholder="Enter State">
                                                </div>
                                                <div class="col-sm-5">
                                                    <input type="text" class="form-control" placeholder="Enter Zip">
                                                    <input type="text" class="form-control" placeholder="Enter Color">
                                                </div>
                                                <div class="col-sm-2">
                                                    <input type="file" class="form-control choose-file-hidden">
                                                    <img src="/assets/image/add-file.png" class="add-file">
                                                </div>
                                            </div>
                                        </div>


                                        <!------------------------------------------------------------------>
                                        <div class="col-sm-12 h_second_2">
                                            <div class="row">

                                                <div class="col-sm-12 mb-4 bt-dark-1 pt-4 mt-2">
                                                    <div class="row align-items-center">
                                                        <div class="col-sm-6">
                                                            <h6>Add Company Hierarchy </h6>
                                                        </div>
                                                        <div class="col-sm-6 text-right">
                                                            <button class="btn btn-sm green-bg sq-btn"> <i
                                                                    class="fas fa-plus"></i> </button>
                                                            <button class="btn btn-sm red-bg sq-btn"> <i
                                                                    class="fas fa-trash-alt"></i> </button>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-sm-6">
                                                    <select class="form-control">
                                                        <option>Select Company Level</option>
                                                        <option>Division</option>
                                                        <option>Reseller</option>
                                                        <option>Region</option>
                                                        <option>Branch</option>
                                                    </select>
                                                </div>
                                                <div class="col-sm-6">
                                                    <input type="text" class="form-control" placeholder="Enter Name">
                                                </div>
                                                <div class="col-sm-12">
                                                    <input type="text" class="form-control"
                                                        placeholder="Enter Address Line 1">
                                                </div>
                                                <div class="col-sm-12">
                                                    <input type="text" class="form-control"
                                                        placeholder="Enter Address Line 2">
                                                </div>
                                                <div class="col-sm-12">
                                                    <div class="row">
                                                        <div class="col-sm-5">
                                                            <input type="text" class="form-control"
                                                                placeholder="Enter City">
                                                            <input type="text" class="form-control"
                                                                placeholder="Enter State">
                                                        </div>
                                                        <div class="col-sm-4">
                                                            <input type="text" class="form-control"
                                                                placeholder="Enter Zip">
                                                            <input type="text" class="form-control"
                                                                placeholder="Enter Color">
                                                        </div>
                                                        <div class="col-sm-3">
                                                            <input type="file" class="form-control choose-file-hidden">
                                                            <img src="/assets/image/add-file.png" class="add-file">
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-sm-12 h_second_2">
                                                <div class="row">

                                                    <div class="col-sm-12 mb-4 bt-dark-1 pt-4 mt-2">
                                                        <div class="row align-items-center">
                                                            <div class="col-sm-6">
                                                                <h6>Add Company Hierarchy </h6>
                                                            </div>
                                                            <div class="col-sm-6 text-right">
                                                                <button class="btn btn-sm green-bg sq-btn"> <i
                                                                        class="fas fa-plus"></i> </button>
                                                                <button class="btn btn-sm red-bg sq-btn"> <i
                                                                        class="fas fa-trash-alt"></i> </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <select class="form-control">
                                                            <option>Select Company Level</option>
                                                            <option>Division</option>
                                                            <option>Reseller</option>
                                                            <option>Region</option>
                                                            <option>Branch</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-sm-6">
                                                        <input type="text" class="form-control"
                                                            placeholder="Enter Name">
                                                    </div>
                                                    <div class="col-sm-12">
                                                        <input type="text" class="form-control"
                                                            placeholder="Enter Address Line 1">
                                                    </div>
                                                    <div class="col-sm-12">
                                                        <input type="text" class="form-control"
                                                            placeholder="Enter Address Line 2">
                                                    </div>
                                                    <div class="col-sm-12">
                                                        <div class="row">
                                                            <div class="col-sm-5">
                                                                <input type="text" class="form-control"
                                                                    placeholder="Enter City">
                                                                <input type="text" class="form-control"
                                                                    placeholder="Enter State">
                                                            </div>
                                                            <div class="col-sm-4">
                                                                <input type="text" class="form-control"
                                                                    placeholder="Enter Zip">
                                                                <input type="text" class="form-control"
                                                                    placeholder="Enter Color">
                                                            </div>
                                                            <div class="col-sm-3">
                                                                <input type="file"
                                                                    class="form-control choose-file-hidden">
                                                                <img src="/assets/image/add-file.png" class="add-file">
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <!------------------------------------------------------------------>

                                    </div>
                                </div>
                            </div>
                            <div class="submit-from text-right">
                                <button class="btn blue-bg round-btn"><i class="fas fa-save"></i> Save </button>
                                <button class="btn darkred-bg round-btn"><i class="fas fa-redo"></i> Clear </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>

    </section>

</div>
<router-outlet></router-outlet>