import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ConstantsService } from 'src/app/constants.service';
import { SharedService } from 'src/app/services/shared.service';
// import { TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { DomSanitizer } from "@angular/platform-browser";
import { GlobalChangeService } from '../services/global-change.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-email-template',
  templateUrl: './email-template.component.html',
  styleUrls: ['./email-template.component.css']
})
export class EmailTemplateComponent implements OnInit {

  name = "ng2-ckeditor";
  ckeConfig: any;
  templateBody: any;
  @ViewChild("myckeditor") ckeditor: any;

  /*Search Property*/
  formSearch: FormGroup;
  searchTemplate: any;
  TCreateUpdate: any = 1;
  SearchValidation: any;
  SearchControl: boolean = false;
  SearchType: any = false;
  ListShow: boolean = false;
  ListDiv: boolean = false;
  ListAngle: any;
  value_search = 0;
  // config_search = TreeviewConfig.create({
  //   hasAllCheckBox: true,
  //   hasFilter: true,
  //   hasCollapseExpand: true,
  //   decoupleChildFromParent: false,
  //   maxHeight: 400
  // });
  userGroupObjects_search = new Array();
  companyList_search: any;
  // items_search: TreeviewItem[];
  tempList: any;

  /* Form Property */
  form: FormGroup;
  validation: boolean = false;
  ddlTempType = '';
  txtSubject: any;
  txtTempName: any;
  value = 0;
  // config = TreeviewConfig.create({
  //   hasAllCheckBox: true,
  //   hasFilter: true,
  //   hasCollapseExpand: true,
  //   decoupleChildFromParent: false,
  //   maxHeight: 400
  // });
  userGroupObjects = new Array();
  companyList: any;
  dictionary = this.constants.Dictionary;

  /*Datatable Property*/
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  /*Others*/
  loader: boolean = false;
  clearbutton: boolean = true;
  rolePermissionsList: any;
  permissionMessage = '';
  id: number;
  userId: string;
  companyLoginId: any;
  data: any;
  buttonValue: string;
  settingDetailCSS: any;
  settingLogoImage: any;
  createdBy: any;
  createdDate: any;
  deletedItem: string;
  deleteDetail: string;

  /* Message Modal Properties */
  messageModalImage: any;
  messageModalHeading: any;
  messageModalDescription: any;
  @ViewChild('messageModal', { static: false }) private messageModal;

  constructor(public sharedService: SharedService,
    private constants: ConstantsService,
    private _sanitizer: DomSanitizer,
    private router: Router, 
    private modalService: NgbModal,
    private globalChangeService: GlobalChangeService
    ) {

  }

  ngOnInit(): void {

    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);
    }
    this.rolePermissionsList = JSON.parse(localStorage.getItem('rolePermissionsList'));
    this.rolePermissionsList = this.rolePermissionsList.filter(x => x.pageId == 1021);
    this.permissionMessage = '';
    if (this.rolePermissionsList != null && this.rolePermissionsList != undefined) {
      if (!this.rolePermissionsList[0].isView) {
        this.permissionMessage += 'view';
      } if (!this.rolePermissionsList[0].isInsert) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'add';
      } if (!this.rolePermissionsList[0].isUpdate) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'edit';
      } if (!this.rolePermissionsList[0].isDelete) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'delete';
      }
    }
    if (this.permissionMessage != '') {
      this.permissionMessage = 'You do not have access to ' + this.permissionMessage + '. Please contact to administrator!';
    }

    this.ckeConfig = {
      extraPlugins:
        "dialogui,dialog,a11yhelp,basicstyles,bidi,blockquote,clipboard," +
        "button,panelbutton,panel,floatpanel,colorbutton,colordialog,menu," +
        "contextmenu,dialogadvtab,div,elementspath,enterkey,entities,popup," +
        "filebrowser,find,fakeobjects,floatingspace,listblock,richcombo," +
        "font,format,forms,horizontalrule,htmlwriter,iframe,indent," +
        "indentblock,indentlist,justify,link,list,liststyle,magicline," +
        "maximize,newpage,pagebreak,pastefromword,pastetext,preview,print," +
        "removeformat,resize,menubutton,scayt,selectall,showblocks," +
        "showborders,smiley,sourcearea,specialchar,stylescombo,tab,table," +
        "tabletools,templates,toolbar,undo,wysiwygarea",
      removeButtons: "About",
      removePlugins : 'elementspath'
    };


    this.buttonValue = this.constants.Save;
    this.userId = localStorage.getItem(this.constants.userId);
    this.companyLoginId = localStorage.getItem(this.constants.companyId);
    // this.getAllCompany(this.companyLoginId);
    // this.getAllCompany_search(this.companyLoginId);
    this.form = new FormGroup({
      'ddlTempType': new FormControl(),
      'txtSubject': new FormControl(),
      'txtTempName': new FormControl(),
      'templateBody': new FormControl(),
    });

    this.formSearch = new FormGroup({
      'TCreateUpdate': new FormControl(),
      'searchTemplate': new FormControl(),
    });

    //ankit implementaion
    // get company by default set value
    var company = this.globalChangeService.getGlobalCompany();
    if(company>0){
      this.value = company;
      this.value_search = company;
    }
    else{
      this.ErrorModal('Error', 'Please select company.');
    }

    //on change function 
    this.globalChangeService.dropdownChange$.subscribe((data)=>{
      this.onValueChange(data);
      this.onValueChange_search(data);
    })

    this.dtOptions = {
      pagingType: this.constants.full_numbers,
      pageLength: 10
    };
  }

  ChangeTCreateUpdate(event) {
    this.TCreateUpdate = event.target.value;
    if (this.TCreateUpdate == "1") {
      this.SearchControl = false;
      this.ListShow = false;
      this.ListDiv = false;
      this.SearchValidation = false;
      this.clearbutton = true;
      this.buttonValue = this.constants.Save;
      this.resetTemplateData();
    }
    else if (this.TCreateUpdate == "0") {
      this.SearchControl = true;
      this.searchTemplate = "";
    }
  }

  onValueChange_search(value: any): void {
    this.value_search = value;
  }

  onValueChange(value: any): void {
    this.value = value;
  }

  getDictionaryData(data) {
    this.templateBody += data;
  }

  copyText(val: string) {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  ListDivShowHide() {
    if (this.ListDiv == true) {
      this.ListDiv = false;
      this.ListAngle = this.constants.DownArrow;
    }
    else if (this.ListDiv == false) {
      this.ListDiv = true;
      this.ListAngle = this.constants.UpArrow;
    }
  }

  searchTemplateF() {
    this.SearchType = false;
    if (!this.searchTemplate || this.value_search == 0) {
      this.SearchValidation = true;
      return;
    }
    this.GetAllTemplate(this.value_search, this.searchTemplate, 0);
  }

  AllTemplate() {
    this.SearchType = true;
    if (this.value_search == 0) {
      this.SearchValidation = true;
      return;
    }
    this.GetAllTemplate(this.value_search, '', 0);
  }

  GetAllTemplate(companySearch, searchTerm, tempType) {
    this.loader = true;
    this.sharedService.GetAllEmailTemplate(companySearch, searchTerm, tempType).subscribe(
      data => {
        this.ListShow = true;
        this.ListDiv = true;
        this.ListAngle = this.constants.UpArrow;
        this.data = data;
        this.tempList = this.data.dataList;
        this.loader = false;
        this.rerender();
      }
    )
  }

  // getAllCompany_search(companyId) {
  //   this.sharedService.GetDropdownCompanyHierarchyModel(this.companyLoginId).subscribe(
  //     data => {
  //       this.data = data;
  //       this.items_search = this.data.dataList;
  //       this.companyList_search = this.data.dataList;
  //       this.companyList_search.forEach((x) => {
  //         this.userGroupObjects_search.push(new TreeviewItem(x, false));
  //       });
  //       this.value_search = Number(companyId);
  //     });
  // }

  // getAllCompany(companyId) {
  //   this.sharedService.GetDropdownCompanyHierarchyModel(companyId).subscribe(
  //     data => {
  //       this.data = data;
  //       if (this.data.dataList != null && this.data.dataList != undefined) {
  //         this.companyList = this.data.dataList;
  //         this.companyList.forEach((x) => {
  //           this.userGroupObjects.push(new TreeviewItem(x, false));
  //         });
  //       }
  //       this.value = Number(companyId);
  //     });
  // }

  toggleEdit(item: any) {
    this.ListDiv = false;
    this.ListAngle = this.constants.DownArrow;
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.buttonValue = this.constants.Update;
    this.clearbutton = false;
    this.sharedService.GetEmailTemplate(item.id).subscribe(
      (data: any) => {
        this.id = item.id;
        this.value = data.data.companyId;
        this.ddlTempType = data.data.tempType;
        this.txtTempName = data.data.tempName;
        this.txtSubject = data.data.subject;
        this.templateBody = data.data.tempText;
      });
  }

  addTemplate() {

    this.loader = true;
    this.validation = true;
    this.clearbutton = true;
    if (
      this.value === 0 ||
      this.form.controls.ddlTempType.status === this.constants.Invalid ||
      this.form.controls.txtTempName.status === this.constants.Invalid
    ) {
      return;
    }

    const obj = {};
    obj['TempType'] = Number(this.ddlTempType);
    obj['CompanyId'] = Number(this.value);
    obj['TempName'] = this.txtTempName;
    obj['Subject'] = this.txtSubject;
    obj['TempText'] = this.templateBody;
    obj['IsActive'] = true;

    if (this.id != undefined && !Number.isNaN(this.id) && this.id != 0 && this.id != null) {

      obj['Id'] = this.id;
      obj['ModifiedBy'] = this.userId;
      obj['ModifiedDate'] = new Date();
      this.sharedService.UpdateEmailTemplate(obj)
        .subscribe(
          data => {
            switch (data.statusCode) {
              case 200:
                this.TCreateUpdate = 1;
                this.SearchControl = false;
                this.ListShow = false;
                this.ListDiv = false;
                this.resetTemplateData();
                this.rerender();
                this.modalSuccess(this.constants.RecordUpdateSuccessfully);
                break;
              case 400:
                this.modalError(this.constants.SomethingWentWrong);
                break;
              default:
                this.modalError(this.constants.SomethingWentWrong);
            }
            this.loader = false;
          }
        );
    }
    else {
      obj['CreatedBy'] = this.userId;
      obj['CreatedDate'] = new Date();
      this.sharedService.AddEmailTemplate(obj)
        .subscribe(
          data => {
            switch (data.statusCode) {
              case 200:
                this.TCreateUpdate = 1;
                this.SearchControl = false;
                this.ListShow = false;
                this.ListDiv = false;
                this.resetTemplateData();
                this.rerender();
                this.modalSuccess(this.constants.RecordAddedSuccessfully);
                break;
              case 400:
                this.modalError(this.constants.SomethingWentWrong);
                break;
              case 208:
                this.modalError(this.constants.EmailTemplateExist);
                break;
              default:
                this.modalError(this.constants.SomethingWentWrong);
            }
            this.loader = false;
          }
        );
    }
  }

  DeleteConfirm(item, content) {
    this.deletedItem = '';
    this.modalService.open(content, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
    this.deleteDetail = this.constants.DeletePopup;
    this.deletedItem = item;
  }

  DeleteTemplate(item: any) {
    this.sharedService.deleteEmailTemplate(item.id).subscribe(
      data => {
        this.data = data;
        switch (data.statusCode) {
          case 200:
            this.modalSuccess(this.constants.RecordDeletedSuccessfully);
            this.GetAllTemplate(this.value_search, this.searchTemplate == "" ? "" : this.searchTemplate, 0);
            this.rerender();
            break;
          case 400:
            this.modalError(this.constants.SomethingWentWrong);
            break;
          default:
            this.modalError(this.constants.SomethingWentWrong);
        }
      });
  }

  resetTemplateData() {
    this.id = null;
    this.buttonValue = this.constants.Save;
    this.txtSubject = '';
    this.ddlTempType = '';
    this.templateBody = '';
    this.validation = false;
  }

  templateBack() {
    this.clearbutton = true;
    this.id = null;
    this.buttonValue = this.constants.Save;
    this.TCreateUpdate = 1;
    this.SearchControl = false;
    this.ListShow = false;
    this.ListDiv = false;
    this.resetTemplateData();
  }

  modalSuccess(msg) {
    this.messageModalImage = this.constants.SuccessPng;
    this.messageModalDescription = msg;
    this.modalService.dismissAll();
    this.modalService.open(this.messageModal, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
  }

  modalError(msg) {
    this.messageModalImage = this.constants.ClosePng;
    this.messageModalDescription = msg;
    this.modalService.dismissAll();
    this.modalService.open(this.messageModal, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
  }

  SetSettingCSS(settingCSS, logoImagePath) {
    if (settingCSS != null) {
      let headerCss = document.getElementsByClassName(this.constants.CssHealerClass) as HTMLCollectionOf<HTMLElement>;
      if (headerCss.length != 0) {
        headerCss[0].style.backgroundColor = settingCSS.split(',')[0];
      }
      let saveButtonCss = document.getElementsByClassName(this.constants.CssSaveBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (saveButtonCss.length != 0) {
        saveButtonCss[0].style.backgroundColor = settingCSS.split(',')[1];
        $(".blue-bg").css("background", settingCSS.split(',')[1]);
      }
      let cencelButtonCss = document.getElementsByClassName(this.constants.CssCancelBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (cencelButtonCss.length != 0) {
        cencelButtonCss[0].style.backgroundColor = settingCSS.split(',')[2];
        $(".darkred-bg").css("background", settingCSS.split(',')[2]);
      }
    }

    if (logoImagePath != null && logoImagePath != "") {
      (document.getElementById('hrdlogoImage') as HTMLImageElement).src = logoImagePath;
    }
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next('');
    this.settingDetailCSS = localStorage.getItem("settingDetailCSS");
    this.settingLogoImage = localStorage.getItem("settingImageLogo");
    this.SetSettingCSS(this.settingDetailCSS, this.settingLogoImage);
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next('');
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  //ankit implemenation
  ErrorModal(title:string, message:string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }
}
