<section class="content-section-2" id="new-layout">
    <form [formGroup]="OrderPartForm">
        <div class="loading lodingtext" *ngIf="loader">
            <!-- <p>{{lodingtext}}</p> -->
        </div>
        <!-- --------------bredcumps start------- -->
        <div class="top-bredcumps">
            <div class="cstmbreadcum paddingmng-right">
                <div class="page_title_cstms">
                    <ul class="link_current">
                        <li *ngIf="IsSalesOrderList">
                            <a (click)="BackToList()" class="backbtns" *ngIf="IsSalesOrderList">
                                <mat-icon>keyboard_arrow_left</mat-icon>
                                <span>Back to List</span>
                            </a>
                        </li>
                        <li>
                            <span *ngIf="!IsSalesOrderList">Sales Order List</span>
                            <span *ngIf="IsSalesOrderList">Sales Order Details</span>
                        </li>
                    </ul>
                </div>

                <div class="top-button d-flex justify-content-between align-items-center gap-3"
                    *ngIf="IsSalesOrderList">
                    <button class="btn cancel-btn update-btn" (click)="Cancel()">
                        Cancel
                    </button>
                    <button class="save-btn btn update-btn" [matMenuTriggerFor]="Sharemenu"
                        [disabled]="OrderPartForm.value.orderNumber==''" #SharemenuTrigger="matMenuTrigger"
                        (click)="SharemenuTrigger.openMenu()">
                        Share
                    </button>
                    <mat-menu #Sharemenu="matMenu">
                        <span (click)="SharemenuTrigger.closeMenu()">
                            <span>
                                <button mat-menu-item (click)="DownloadPDF(false,2)">
                                    Download PDF
                                </button>
                                <button mat-menu-item (click)="DownloadPDF(false,1)">
                                    Email with PDF
                                </button>
                                <button mat-menu-item (click)="DownloadPDF(true,2)">
                                    Print
                                </button>
                            </span>
                        </span>
                    </mat-menu>
                    <button class="btn save-btn update-btn" (click)="validateForm()">
                        <span>Save</span>
                    </button>
                </div>
            </div>
        </div>
        <!-- --------------bredcumps end------- -->

        <!-- ------------------------sales order home page start here----------- -->
        <div class="content-section-1" *ngIf="!IsSalesOrderList">
            <div class="container-fluid">
                <div class="main-section">
                    <!-- -----------------------top search section on home page  -->
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-4 col-12 mb-3">
                            <div class="cstm-radiosearch select-arrow-change-icon">
                                <mat-form-field class="forShipping m-0 mt-12 p-0 status-select" appearance="outline">
                                    <mat-label>Status Filter</mat-label>
                                    <mat-select multiple [(ngModel)]="selectedFilter"
                                        [ngModelOptions]="{standalone: true}" (selectionChange)="ChangeStatu($event)">
                                        <mat-option *ngFor="let statusList of StatusList" [value]="statusList.statusId"
                                            [disabled]="statusList.statusId==17">
                                            {{statusList.statusName}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-8 col-12 mb-3">
                            <div class="search_filter-alltype select-arrow-change-icon">
                                <mat-select #select multiple class="customInput">
                                    <mat-checkbox [(ngModel)]="allSelected" [ngModelOptions]="{standalone: true}"
                                        (change)="toggleAllSelection()">Select All</mat-checkbox>
                                    <mat-option (click)="ChangeSearch()" *ngFor="let c of constants.SearchList"
                                        [value]="c">
                                        {{c}}
                                    </mat-option>
                                </mat-select>
                                <input type="text" class="searchalltype"
                                    (input)="GetOrderList($event.target.value,false,false)" #input
                                    placeholder="{{selectedSearchText}}">
                                <button class="btnsearch_alltype" type="button">
                                    <mat-icon>search</mat-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                    <!-- -----------------------top search section on home page  -->
                    <!-- --------------------sales-order-table section start here---------- -->
                    <div class="sales-order-table">
                        <div class="heading-section-1">
                            <mat-table [dataSource]="SalesOrderListDS" (scroll)="SalesOrderListScroll($event)"
                                class="table-material table-responsive">
                                <ng-container matColumnDef="menu">
                                    <mat-header-cell *matHeaderCellDef> Menu </mat-header-cell>
                                    <mat-cell *matCellDef="let element; let i = index;" data-label="Menu"> <mat-icon
                                            class="icon_fix">more_vert</mat-icon> </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="orderDate">
                                    <mat-header-cell *matHeaderCellDef> Order Date </mat-header-cell>
                                    <mat-cell *matCellDef="let element" data-label="Order Date"> {{element.orderDate |
                                        date:
                                        "MMM-dd-yyyy"}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="customerponumber">
                                    <mat-header-cell *matHeaderCellDef> Order Number </mat-header-cell>
                                    <mat-cell *matCellDef="let element" data-label="Order Number">
                                        {{element.orderNumber}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="companyName">
                                    <mat-header-cell *matHeaderCellDef> Company Name </mat-header-cell>
                                    <mat-cell *matCellDef="let element" data-label="Company Name">
                                        {{element.companyName}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="customername">
                                    <mat-header-cell *matHeaderCellDef> Customer Name </mat-header-cell>
                                    <mat-cell *matCellDef="let element" data-label="Customer Name">
                                        {{element.customerName}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="contactName">
                                    <mat-header-cell *matHeaderCellDef> Contact Name </mat-header-cell>
                                    <mat-cell *matCellDef="let element" data-label="Contact Name">
                                        {{element.customerFirstName}} </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="status">
                                    <mat-header-cell *matHeaderCellDef> Status </mat-header-cell>
                                    <mat-cell *matCellDef="let element" data-label="Status"> {{element.statusName}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="source">
                                    <mat-header-cell *matHeaderCellDef> Source </mat-header-cell>
                                    <mat-cell *matCellDef="let element" data-label="Source"> {{element.source == null
                                        ?'Internal' :element.source}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="sourceID">
                                    <mat-header-cell *matHeaderCellDef> Source ID </mat-header-cell>
                                    <mat-cell *matCellDef="let element" data-label="Source"> {{element.eOrderNo}}
                                    </mat-cell>
                                </ng-container>
                                <ng-container matColumnDef="shipAs">
                                    <mat-header-cell *matHeaderCellDef> Ship As </mat-header-cell>
                                    <mat-cell *matCellDef="let element" data-label="Ship As"> {{element.partialShipment?
                                        'Partial' :'Complete'}} </mat-cell>
                                </ng-container>
                                <mat-header-row *matHeaderRowDef="constants.SalesOrderListDSColumn sticky: true"
                                    class="header-style table-responsive-header"></mat-header-row>
                                <mat-row *matRowDef="let row; columns: constants.SalesOrderListDSColumn;let i= index;"
                                    (click)="selectedRow(row.id,row.customerId)"></mat-row>
                            </mat-table>
                        </div>
                    </div>
                    <!-- --------------------sales-order-table section end here---------- -->
                </div>
            </div>
        </div>
        <!-- ------------------------sales order home page end here----------- -->

        <!-- ---------saleds order details page start here------------------------------- -->
        <div class="content-section-1" *ngIf="IsSalesOrderList">
            <div class="container-fluid">
                <div class="main-section">
                    <!-- -------------first row section------------- -->
                    <div class="row top-section">
                        <div class="col-xl-4 col-md-6 col-12 mb-4 first-card column-width custom-height">
                            <mat-card class="mat-card-custom p-0">
                                <mat-card-header class="align-items-center gap-3 change-mat-header">
                                    <div class="main-heading">Bill to</div>
                                    <div class="form-fields-custom">
                                        <mat-form-field appearance="outline"
                                            [ngClass]="IsBillToItem? 'height-change' : 'height-change height-changeErr'">
                                            <mat-icon matSuffix class="p-2">search</mat-icon>
                                            <input matInput placeholder="Search Customer" #autoCompleteInput
                                                (input)="SearchCustomer($event)" [matAutocomplete]="auto">
                                            <mat-autocomplete #auto="matAutocomplete"
                                                (optionSelected)="ChangeCustomer($event)">
                                                <mat-option *ngFor="let cust of customerList"
                                                    [value]="cust.customerName">
                                                    {{cust.customerName}}
                                                </mat-option>
                                            </mat-autocomplete>
                                        </mat-form-field>
                                    </div>
                                    <div class="new-btn">
                                        <button mat-stroked-button (click)="AddBillToAddres()">
                                            <mat-icon class="add-btn">add</mat-icon>
                                            New</button>
                                    </div>
                                </mat-card-header>
                                <mat-card-content class="mt-2">
                                    <div class="product-name mb-2">
                                        <span>{{BillToForm.value.name}}</span>
                                    </div>
                                    <div class="address">
                                        <span>{{BillToForm.value.addressLine1}}</span>
                                        <span> {{BillToForm.value.city}} &nbsp;
                                            {{BillToForm.value.name!=''?
                                            this.GetStateCode(BillToForm.value.stateId):''}}
                                            &nbsp;{{BillToForm.value.zip}}</span>
                                        <span>{{BillToForm.value.cName}} &nbsp; {{BillToForm.value.phone}}</span>
                                        <span>{{BillToForm.value.email}}</span>
                                    </div>
                                </mat-card-content>
                                <div *ngIf="BillToForm.value.name ==''" class="p-2 empty-text">
                                    Select a current customer or create a new customer to start.
                                </div>
                            </mat-card>
                        </div>
                        <div class="col-xl-4 col-md-6 col-12 mb-4 second-card column-width custom-height">
                            <mat-card class="mat-card-custom p-0">
                                <mat-card-header class="align-items-center gap-3 change-mat-header">
                                    <div class="main-heading">Ship to</div>
                                    <div class="form-fields-custom select-add-field select-arrow-change-icon">
                                        <mat-form-field appearance="outline"
                                            [ngClass]="IsShipToItem? 'height-change' : 'height-change height-changeErr'">
                                            <mat-select (selectionChange)="ChangeShipTo($event.value)"
                                                [(ngModel)]="shipToVal" [ngModelOptions]="{standalone: true}"
                                                placeholder="Select Address" [disabled]="BillToForm.value.name ==''">
                                                <mat-option [value]="1" class="mat-option-value">
                                                    <div class="drop-ship-name">
                                                        <span class="option-name">
                                                            Drop Ship
                                                        </span>
                                                        <span>You can enter a one time ship to address</span>
                                                    </div>
                                                </mat-option>
                                                <mat-option [value]="2" *ngFor="let w of CompanyWareHouseList"
                                                    (click)="ChangeWareHouse(w)" class="mat-option-value">
                                                    <span class="option-name">
                                                        Will Call
                                                    </span>
                                                    <span>{{w.name}} <br />{{w.address1}} <br />{{w.city}},
                                                        {{GetStateCode(w.stateId)}}
                                                        {{w.zipCode}}</span>
                                                </mat-option>
                                                <mat-option [value]="3" class="mat-option-value"
                                                    *ngFor="let c of CustomerWithChildList"
                                                    (click)="ChangeCustomerDetails(c)">
                                                    <span class="option-name">
                                                        Ship To
                                                    </span>
                                                    <div class="ship-to-content">
                                                        <span>{{c.shipName}}</span>
                                                        <span> {{c.shipAddressLine1}}</span>
                                                        <span>{{c.shipCity}} {{GetStateCode(c.shipState)}}
                                                            {{c.shipZipCode}}</span>
                                                    </div>
                                                </mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="new-btn">
                                        <button mat-stroked-button (click)="AddShipToAddres()"
                                            [disabled]="BillToForm.value.name ==''">
                                            <mat-icon class="add-btn">add</mat-icon> New</button>
                                    </div>
                                </mat-card-header>
                                <mat-card-content class="mat-card-pd mt-2">
                                    <div *ngIf="shipToVal==3" class="product-name mb-2 addres-section">
                                        <span>{{ShipToForm.value.name}}</span>
                                        <div appearance="outline" class="example-full-width">
                                            <input matInput placeholder="Enter Customer"
                                                value="{{ShipToForm.value.attention}}"
                                                (input)="ShipToAtten($event.target.value)"
                                                class="w-100 dropship-input mb-2">
                                        </div>
                                        <span>{{ShipToForm.value.addressLine1}}</span>
                                        <span>{{ShipToForm.value.city}} {{GetStateCode(this.ShipToForm.value.stateId)}}
                                            {{ShipToForm.value.zip}}</span>
                                    </div>
                                    <div class="addres-section" *ngIf="shipToVal==2" class="card-input">
                                        <span>{{ShipToForm.value.name}}</span>
                                        <span>{{ShipToForm.value.addressLine1}}</span>
                                        <span>{{ShipToForm.value.city}} {{GetStateCode(this.ShipToForm.value.stateId)}}
                                            {{ShipToForm.value.zip}}</span>
                                    </div>
                                    <div class="addres-section"
                                        *ngIf="SalesOrderById != null && SalesOrderById != undefined && shipToVal ==0">
                                        <span>{{ShipToForm.value.name}}</span>
                                        <span>{{ShipToForm.value.addressLine1}}</span>
                                        <span> {{ShipToForm.value.city}} {{GetStateCode(this.ShipToForm.value.stateId)}}
                                            {{ShipToForm.value.zip}}</span>
                                        <span>{{ShipToForm.value.email}}</span> &nbsp;
                                        <span>{{ShipToForm.value.phone}}</span>
                                    </div>

                                    <!-- --------------------------------------------------------------------------------------------- -->
                                    <div *ngIf="shipToVal==1">
                                        <form [formGroup]="ShipToForm">
                                            <div class="row">
                                                <div class="col-12 mb-2">
                                                    <div class="forShipping">
                                                        <input matInput type="text" placeholder="Customer Name"
                                                            formControlName="name" class="dropship-input w-100">
                                                    </div>
                                                </div>
                                                <div class="col-12 mb-2">
                                                    <div class="forShipping">
                                                        <input matInput type="text" placeholder="Attention"
                                                            formControlName="attention" class="dropship-input w-100">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12 mb-2">
                                                    <div class="forShipping">
                                                        <input matInput type="text" placeholder="Street Address"
                                                            formControlName="addressLine1" class="dropship-input w-100">
                                                    </div>
                                                    <mat-error class="section-valid"
                                                        *ngIf="(ShipToFormError.addressLine1.touched || submitted) && ShipToFormError.addressLine1.errors?.required">
                                                        Enter Street Address
                                                    </mat-error>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-12 mb-2">
                                                    <div class="forShipping">
                                                        <input matInput type="text" placeholder="Appt, Suit, Unit"
                                                            formControlName="addressLine2" class="dropship-input w-100">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-xl-6 mb-2 first-card custom-card">
                                                    <div class="forShipping">
                                                        <input matInput type="text" placeholder="City"
                                                            formControlName="city" class="dropship-input w-100">
                                                    </div>
                                                    <mat-error class="section-valid"
                                                        *ngIf="(ShipToFormError.city.touched || submitted) && ShipToFormError.city.errors?.required">
                                                        Enter City
                                                    </mat-error>
                                                </div>
                                                <div
                                                    class="col-xl-3 col-lg-6 custom-field second-card mb-2 select-arrow-change-icon">
                                                    <mat-form-field class="forShipping" appearance="outline">
                                                        <mat-select formControlName='stateId' placeholder="State">
                                                            <mat-option selected disabled>Select State</mat-option>
                                                            <mat-option *ngFor="let s of StateListByCountryId"
                                                                [value]="s.id">
                                                                <span
                                                                    *ngIf="s.abbreviation.length != 0">{{s.abbreviation}}</span>
                                                                <span
                                                                    *ngIf="s.abbreviation.length == 0">{{s.name}}</span>
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                    <mat-error class="section-valid"
                                                        *ngIf="(ShipToFormError.stateId.touched || submitted) && ShipToFormError.stateId.errors?.required">
                                                        Enter State
                                                    </mat-error>
                                                </div>
                                                <div class="col-xl-3 col-lg-6 mb-2 custom-field fourth-card">
                                                    <mat-form-field class="forShipping m-0 mt-12 p-0 w-100"
                                                        appearance="outline">
                                                        <input matInput type="text" mask="00000-0000" placeholder="Zip"
                                                            formControlName="zip" (input)="RemoveMaskValidation()">
                                                    </mat-form-field>
                                                    <mat-error class="section-valid"
                                                        *ngIf="(ShipToFormError.zip.touched || submitted) && ShipToFormError.zip.errors?.required">
                                                        Enter a valid Zip
                                                    </mat-error>
                                                </div>
                                            </div>
                                            <div class="row align-items-center select-arrow-change-icon">
                                                <div class="col-6 custom-field first-card">
                                                    <mat-form-field class="forShipping m-0 mt-12 p-0 w-100"
                                                        appearance="outline">
                                                        <mat-select formControlName='countryId'
                                                            (selectionChange)="GetStateByCountryId($event.value)"
                                                            placeholder="Zip">
                                                            <mat-option disabled selected>Select State</mat-option>
                                                            <mat-option *ngFor="let c of CountryList" [value]="c.id">
                                                                {{c.name}}
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                    <mat-error class="section-valid"
                                                        *ngIf="(ShipToFormError.countryId.touched || submitted) && ShipToFormError.countryId.errors?.required">
                                                        Country is required
                                                    </mat-error>
                                                </div>
                                            </div>
                                        </form>
                                    </div>

                                </mat-card-content>
                                <div *ngIf="shipToVal==0" class="p-2 empty-text">
                                    Please select a shipping address from the menu above.
                                </div>
                                <!-- ------------- -->
                                <div class="validate-section mt-2 justify-content-end" *ngIf="shipToVal != 0">
                                    <div class=" text-end" *ngIf="!OrderPartForm.value.isAddressValidate"><i
                                            class="fa fa-times-circle-o" style="color: red;"></i></div>
                                    <div class="text-end" *ngIf="OrderPartForm.value.isAddressValidate"><img
                                            class="tik-image" src="./assets/image/Green-Round-Tick.png">
                                    </div>
                                    <div class=" btn-link p-0 validate-btn" (click)="ShippingAddressValidationApi()">
                                        Validate
                                        Address
                                    </div>
                                </div>
                            </mat-card>

                        </div>
                        <div
                            class="col-xl-4 col-md-12 col-12 mb-4 fourth-card mat-header-change column-width-full order-info-card custom-height">
                            <mat-card class=" mat-card-custom p-0">
                                <mat-card-header
                                    class="align-items-center gap-3 change-mat-header justify-content-between">
                                    <div class="order-info">Order Information</div>
                                    <div *ngIf="SalesOrderById != undefined" class="div btn-link history-btn"
                                        (click)="AddHistoryFlyOut()">View History</div>
                                </mat-card-header>
                                <mat-card-content class="mat-card-pd mt-2">
                                    <div class="row mt-1">
                                        <div
                                            class="col-6 mb-xl-2 mb-lg-0 mb-md-0 mb-sm-2 right-padding select-arrow-change-icon">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Order Status</mat-label>
                                                <mat-select formControlName="statusId"
                                                    (selectionChange)="ChangeOrderInfoStatus($event.value)">
                                                    <mat-option *ngFor="let statusList of StatusList"
                                                        [disabled]="statusList.statusId == 17 || (OrderPartForm.value.orderNumber!='' && statusList.statusId == 10)"
                                                        [value]="statusList.statusId">
                                                        {{statusList.statusName}}
                                                    </mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-6 mb-2 left-padding select-arrow-change-icon">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Fulfillment Type</mat-label>
                                                <mat-select formControlName="fulfilmentType">
                                                    <mat-option value="Auto">Auto </mat-option>
                                                    <mat-option value="Manual">Manual</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6 right-padding">
                                            <mat-form-field appearance="outline" class="example-full-width">
                                                <mat-label>Order Number</mat-label>
                                                <input matInput readonly formControlName="orderNumber" disabled>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-6 left-padding">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Order Date</mat-label>
                                                <input matInput
                                                    value="{{OrderPartForm.value.orderDate | date: 'MMM-dd-yyyy'}}"
                                                    readonly>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="OrderPartForm.value.statusId ==8">
                                        <div
                                            class="col-6 mb-lg-0 mb-md-0 mb-sm-2 mt-2 right-padding select-arrow-change-icon">
                                            <mat-form-field appearance="outline">
                                                <mat-label>Hold Reason</mat-label>
                                                <mat-select formControlName="holdReason">
                                                    <mat-option value="" disabled>Select One</mat-option>
                                                    <mat-option value="CreditHold">Credit Hold</mat-option>
                                                    <mat-option value="PaymentHold">Payment Hold</mat-option>
                                                    <mat-option value="DelayedShipment">Delayed Shipment</mat-option>
                                                    <mat-option value="Other">Other</mat-option>
                                                </mat-select>
                                            </mat-form-field>
                                        </div>
                                        <div class="col-6 mb-lg-0 mb-md-0 mb-sm-2 mt-2 left-padding">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Hold Until Date</mat-label>
                                                <input matInput [matDatepicker]="picker" [min]="todayDate" readonly
                                                    formControlName="holdUntilDate">
                                                <mat-hint>MM/DD/YYYY</mat-hint>
                                                <mat-datepicker-toggle matIconSuffix
                                                    [for]="picker"></mat-datepicker-toggle>
                                                <mat-datepicker #picker></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                    <div class="row" *ngIf="OrderPartForm.value.statusId ==11">
                                        <div class="col-12 mt-2">
                                            <mat-form-field appearance="outline" class="w-100">
                                                <mat-label>Quote Expiration Date</mat-label>
                                                <input matInput [matDatepicker]="Quotepicker" [min]="todayDate" readonly
                                                    formControlName="defQuoteDays">
                                                <mat-hint>MM/DD/YYYY</mat-hint>
                                                <mat-datepicker-toggle matIconSuffix
                                                    [for]="Quotepicker"></mat-datepicker-toggle>
                                                <mat-datepicker #Quotepicker></mat-datepicker>
                                            </mat-form-field>
                                        </div>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </div>
                    <!-- ---------------second row section------------------ -->
                    <div class="section-2 details-section">
                        <div class="top-heading d-flex align-items-center gap-3 mb-3">
                            <h1>Other Information</h1>
                            <h4 class="ecommerce">{{OrderPartForm.value.source}} <span
                                    *ngIf="!ISNullOrEmpty(OrderPartForm.value.eOrderNo)">({{OrderPartForm.value.eOrderNo}})</span>
                            </h4>
                        </div>
                        <div class="row cards-padding">
                            <div class="col-xl-6">
                                <div class="row">
                                    <div class="col-md-3 col-6 first-card mb-sm-3">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Customer Role</mat-label>
                                            <input matInput value="{{OrderPartForm.value.customerRole}}" disabled>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-3 col-6 second-card mb-sm-3">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Business Channel</mat-label>
                                            <input matInput value="{{OrderPartForm.value.businessChannel}}" disabled>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-3 col-6 second-card mb-sm-3 select-arrow-change-icon">
                                        <mat-form-field class="w-100" appearance="outline">
                                            <mat-label>Payment Terms</mat-label>
                                            <mat-select formControlName="netTerms">
                                                <mat-option *ngFor="let nt of NetTermsList" [value]="nt.id"
                                                    [disabled]="nt.IsDisable">{{ (nt.id == 0) ? nt.name : 'Net
                                                    '+nt.name}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-3 col-6 second-card mb-sm-3">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Available Credit</mat-label>
                                            <input matInput formControlName="availableCredit">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div class="row">
                                    <div class="col-md-3 col-6 first-card mb-sm-3 select-arrow-change-icon">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Sales Tax</mat-label>
                                            <mat-select formControlName="taxable"
                                                (selectionChange)="ChangeTaxable($event.value)">
                                                <mat-option value="NonTaxable">Non-Taxable</mat-option>
                                                <mat-option value="Taxable">Taxable</mat-option>
                                                <mat-option value="Exempt">Exempt</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-3 col-6 second-card mb-sm-3">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Customer PO#</mat-label>
                                            <input matInput formControlName="purchaseOrder">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-3 col-6 second-card mb-sm-3 select-arrow-change-icon">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Ship As</mat-label>
                                            <mat-select formControlName="isPartialShipment">
                                                <mat-option [value]=true>Partial</mat-option>
                                                <mat-option [value]=false>Complete</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-md-3 col-6 fourth-card mb-sm-3 select-arrow-change-icon">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Collect Shipping</mat-label>
                                            <mat-select formControlName="collectAccount"
                                                (selectionChange)="ChangeCollectAccount($event.value)">
                                                <mat-option value="">None</mat-option>
                                                <mat-option value="Fedex">Fedex -
                                                    {{OrderPartForm.value.collectAmount}}</mat-option>
                                                <mat-option value="UPS">UPS -
                                                    {{OrderPartForm.value.collectAmount}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div class="row">
                                    <div class="col-12 first-card md-mb-3">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Notes for Warehouse</mat-label>
                                            <input matInput formControlName="wareHouseNotes">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="col-xl-6">
                                <div class="row">
                                    <div class="col-12 first-card-1">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Notes for Customer</mat-label>
                                            <input matInput formControlName="customerNotes">
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- ---------------third  section------------------ -->
                    <div class="third-section mb-3">
                        <div class="tabs-section d-flex justify-content-between">
                            <div [ngClass]="IsValidItem ? 'add-btn' : 'add-btn-danger'">
                                <button mat-stroked-button color="primary" (click)="AddPartsFlyOut()"
                                    class="add-item-btn update-btn">
                                    <mat-icon matSuffix class="">add</mat-icon>Add Item</button>
                            </div>
                            <div class="tab-section">
                                <div class="cstm-radiosearch">
                                    <div class="filter_cstm_radio" (change)="ChangePartsStatusList($event)">
                                        <input type="radio" name="status_filter" id="Summary" checked>
                                        <label for="Summary">
                                            <mat-icon>check</mat-icon>
                                            <span>Summary</span>
                                        </label>
                                        <input type="radio" name="status_filter" id="Detail">
                                        <label for="Detail">
                                            <mat-icon>check</mat-icon>
                                            <span>Detail</span>
                                        </label>
                                        <input type="radio" name="status_filter" id="Profitability">
                                        <label for="Profitability">
                                            <mat-icon>check</mat-icon>
                                            <span>Profitability</span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="collapse-section d-flex align-items-center">
                                <div class="button-1 btn link" (click)="IsCollapseAll(false)">Collapse All</div>
                                <div class="button-2 btn link" (click)="IsCollapseAll(true)">Expand All</div>
                            </div>
                        </div>
                    </div>
                    <!-- -----------------OrderPart List section Start--------------- -->
                    <div class="card-desc example-container cstm_scrollbar_theme company-table">
                        <ul class="heading-section">
                            <li class="p-li">Menu</li>
                            <li class="item-section p-li">Item</li>
                            <li class="p-li arrow-header"></li>
                            <li class="part-number p-li">Part Number</li>
                            <li class="wd-45 p-li text-start">Description</li>
                            <!-- Summary Start -->
                            <ng-container *ngIf="targetVal != 'Detail' && targetVal != 'Profitability'" class="d-flex">
                                <li class="w-8 p-li">Taxable</li>
                                <li class="w-8 p-li">Quantity</li>
                                <li class="w-8 p-li">UOM</li>
                                <li class="w-8 p-li">Unit Price</li>
                                <li class="w-8 p-li text-end">Extension</li>
                            </ng-container>
                            <!-- Summary End -->

                            <!-- Sales Details Start -->
                            <ng-container *ngIf="targetVal == 'Detail'" class="d-flex">
                                <li class="w-5 p-li">Parts Quantity</li>
                                <li class="w-8 p-li">Parts Price</li>
                                <li class="w-6 p-li">Total Parts</li>
                                <li class="w-6 p-li">Labor Hours</li>
                                <li class="w-4 p-li">Shop Rate</li>
                                <li class="w-4 p-li">Total Labor</li>
                                <li class="w-8 p-li text-end">Extension</li>
                            </ng-container>
                            <!-- Sales Details End -->

                            <!-- Profitability Start -->
                            <ng-container *ngIf="targetVal == 'Profitability'" class="d-flex">
                                <li class="w-8 p-li">Parts Quantity</li>
                                <li class="w-6 p-li">Unit Cost</li>
                                <li class="w-6 p-li">Unit Price</li>
                                <li class="w-6 p-li">Parts Margin</li>
                                <li class="w-6 p-li">Labor Hours</li>
                                <li class="w-6 p-li">Labor Cost</li>
                                <li class="w-6 p-li">Labor Price</li>
                                <li class="w-6 p-li">Labor Margin</li>
                                <li class="w-8 p-li text-end">Extension</li>
                            </ng-container>
                            <!-- Profitability End -->

                        </ul>
                        <div formArrayName="OrderPart">
                            <div *ngFor="let d of OrderPart.controls; let i = index;" [formGroupName]="i">
                                <ul class="value-section" [ngClass]="{ 'bgColor_part': OrderPart.value[i].PartId == 0}">
                                    <li class="p-li d-order-2" data-label="Menu">
                                        <button mat-icon-button [matMenuTriggerFor]="OrderPartmenu"
                                            #OrerPartTrigger="matMenuTrigger" (click)="OrerPartTrigger.openMenu()">
                                            <mat-icon class="icon_fix">more_vert</mat-icon>
                                        </button>
                                        <mat-menu #OrderPartmenu="matMenu">
                                            <span (mouseleave)="OrerPartTrigger.closeMenu()">
                                                <span>
                                                    <button [disabled]="OrderPart.value[i].IsNotes" mat-menu-item
                                                        (click)="AddNotesRow(i)" class="p-0">
                                                        Add Note
                                                    </button>
                                                    <button mat-menu-item
                                                        (click)="DeleteOrderPart(OrderPart.value[i].PartId)">
                                                        Delete Item
                                                    </button>
                                                </span>
                                            </span>
                                        </mat-menu>
                                    </li>
                                    <li class="item-section p-li d-order-1" data-label="Item">{{i+1}}
                                    </li>
                                    <li class="p-li arrow-div d-order-9">
                                        <span *ngIf="Question(i).length >0">
                                            <mat-icon class="mat-icon-rtl-mirror" *ngIf="Question(i).value[0].IsShow"
                                                (click)="AddQuestionAnsRow(i,false)">
                                                expand_more
                                            </mat-icon>
                                            <mat-icon class="mat-icon-rtl-mirror" *ngIf="!Question(i).value[0].IsShow"
                                                (click)="AddQuestionAnsRow(i,true)">
                                                keyboard_arrow_right
                                            </mat-icon>
                                        </span>
                                    </li>
                                    <li class="part-number p-li d-order-3" data-label="Part Number">
                                        {{OrderPart.value[i].PartNumber}} <br>
                                        <span *ngIf="OrderPart.value[i].PartId == 0" style="color: red;">Part not
                                            found.</span>
                                    </li>
                                    <li class="wd-45 p-li d-order-4 text-start" data-label="Description">
                                        {{OrderPart.value[i].PartName}}</li>
                                    <!-- Summary Start -->
                                    <ng-container *ngIf="targetVal != 'Detail' && targetVal != 'Profitability'"
                                        class="d-flex">
                                        <li class="w-8 p-li d-order-5" data-label="Taxable">
                                            <input type="checkbox" formControlName="IsTaxable"
                                                (change)="changeTax($event.target.checked)" />
                                        </li>
                                        <li class="w-8 p-li d-order-6 quantity-div" data-label="Quantity">
                                            <mat-form-field appearance="outline" style="width: 100%;">
                                                <input matInput type="number" formControlName="Quantity"
                                                    (input)="SubTotal()">
                                            </mat-form-field>
                                        </li>
                                        <li class="w-8 p-li d-order-7" data-label="UOM">
                                            {{GetUOM(OrderPart.value[i].DimentionType)}}
                                        </li>
                                        <li class="w-8 p-li btn-link d-order-8 " data-label="Unit Price">
                                            <span (click)="SalesDiscountModal(i)" class="unit-price-currency">
                                                <span
                                                    *ngIf="OrderPart.value[i].Cost == OrderPart.value[i].CustDiscountCost || OrderPart.value[i].CustDiscountCost == 0">{{OrderPart.value[i].Cost
                                                    | currency }} List</span>
                                                <span
                                                    *ngIf="OrderPart.value[i].Cost != OrderPart.value[i].CustDiscountCost && OrderPart.value[i].CustDiscountCost != 0"><s>{{OrderPart.value[i].Cost
                                                        | currency }}</s> List</span>
                                                <span
                                                    *ngIf="OrderPart.value[i].Cost != OrderPart.value[i].CustDiscountCost && OrderPart.value[i].CustDiscountCost != 0">
                                                    {{OrderPart.value[i].CustDiscountCost | currency : 'USD' : 'symbol'
                                                    :'1.2-2'}} ({{OrderPart.value[i].CustDiscountType}})</span>
                                            </span>
                                        </li>
                                        <li class="w-8 p-li d-order-10 " data-label="Extension">
                                            {{OrderPart.value[i].SExtension
                                            |currency}}</li>
                                    </ng-container>
                                    <!-- Summary End -->

                                    <!-- Sales Details Start -->
                                    <ng-container *ngIf="targetVal == 'Detail'" class="d-flex">
                                        <li class="w-5 p-li d-order-5" data-label="Parts Quantity">
                                            {{OrderPart.value[i].Quantity}}
                                        </li>
                                        <li class="w-8 p-li d-order-6 quantity-div" data-label="Parts Price">
                                            <mat-form-field appearance="outline" style="width: 80%;text-align: center;">
                                                <input matInput
                                                    [formControlName]="OrderPart.value[i].Cost != OrderPart.value[i].CustDiscountCost && OrderPart.value[i].CustDiscountCost != 0 ? 'CustDiscountCost':'Cost' "
                                                    (input)="SubTotal()">
                                                <span matPrefix>$</span>
                                            </mat-form-field>
                                        </li>
                                        <li class="w-6 p-li d-order-7" data-label="Total Parts">
                                            {{OrderPart.value[i].SExtension
                                            |currency }}</li>
                                        <li class="w-6 p-li quantity-div" data-label="Labour Hours">
                                            <mat-form-field appearance="outline" data-label="Labour">
                                                <input matInput type="number" formControlName="Hours"
                                                    (input)="SubTotal()">
                                            </mat-form-field>
                                        </li>
                                        <li class="w-4 p-li d-order-9" data-label="Shop Rate">
                                            {{OrderPart.value[i].LaborCost |
                                            currency }}</li>
                                        <li class="w-4 p-li d-order-10" data-label="Total Labor">
                                            {{OrderPart.value[i].TotalLobor |
                                            currency }}</li>
                                        <li class="w-8 p-li d-order-11" data-label="Extension">
                                            {{OrderPart.value[i].DExtension |
                                            currency}}
                                        </li>
                                    </ng-container>
                                    <!-- Sales Details End -->

                                    <!-- Profitability Start -->
                                    <ng-container *ngIf="targetVal == 'Profitability'" class="d-flex">
                                        <li class="w-8 p-li d-order-5" data-label="Parts Quantity">
                                            {{OrderPart.value[i].Quantity}}
                                        </li>
                                        <li class="w-6 p-li d-order-6" data-label="Unit Cost">
                                            {{OrderPart.value[i].UnitCost
                                            |
                                            currency}}
                                        </li>
                                        <!-- <li class="w-4 p-li">{{OrderPart.value[i].UnitCost | currency}}
                                </li> -->
                                        <li class="w-6 p-li d-order-7" data-label="Unit Price">
                                            {{OrderPart.value[i].Cost|
                                            currency }}</li>
                                        <li class="w-6 p-li d-order-8" data-label="Parts Margin">
                                            {{OrderPart.value[i].PartsMargin}}%
                                        </li>
                                        <li class="w-6 p-li d-order-9" data-label=" Labour Hours">
                                            {{OrderPart.value[i].Hours}}</li>
                                        <li class="w-6 p-li d-order-10" data-label="Labor Cost">
                                            {{OrderPart.value[i].LaborCost |
                                            currency }}</li>
                                        <li class="w-6 p-li d-order-11" data-label="Labor Price">
                                            {{OrderPart.value[i].LaborLoadedCost |
                                            currency }}</li>
                                        <li class="w-6 p-li d-order-12" data-label="Labor Margin">
                                            {{OrderPart.value[i].LaborMargin}}%</li>
                                        <li class="w-8 p-li d-order-13" data-label="Extension">
                                            {{OrderPart.value[i].PExtension}}%</li>
                                    </ng-container>
                                    <!-- Profitability End -->
                                </ul>
                                <!-- Questions Section Start -->
                                <ng-container formArrayName="Question"
                                    *ngFor="let q of Question(i).controls; let j = index;">
                                    <ul class="value-section" [formGroupName]="j" *ngIf="Question(i).value[j].IsShow">
                                        <ng-container>
                                            <li class="col-4 p-li">
                                                <span *ngIf="Question(i).value[j].IsRequired"
                                                    style="color: red;">*</span>
                                                {{Question(i).value[j].QuestionTitle}}
                                            </li>
                                            <li class="col-8 p-li">
                                                <mat-form-field appearance="outline" class="w-100"
                                                    *ngIf="Question(i).value[j].AnswerTypeId ==1">
                                                    <mat-label>Enter answer here</mat-label>
                                                    <input matInput formControlName="Answer">
                                                </mat-form-field>
                                                <mat-form-field appearance="outline" class="w-100"
                                                    *ngIf="Question(i).value[j].AnswerTypeId !=1">
                                                    <mat-label>Select One</mat-label>
                                                    <mat-select class="w-100" formControlName="Answer">
                                                        <mat-option value='true'>Yes</mat-option>
                                                        <mat-option value='false'>No</mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </li>
                                        </ng-container>
                                    </ul>
                                </ng-container>
                                <!-- Questions Section End -->
                                <!-- Notes Section Start -->
                                <ul class="value-section" *ngIf="OrderPart.value[i].IsNotes">
                                    <li class="w-8 p-li">
                                        <button mat-icon-button [matMenuTriggerFor]="Notemenu"
                                            #NoteTrigger="matMenuTrigger" (click)="NoteTrigger.openMenu()">
                                            <mat-icon class="icon_fix">more_vert</mat-icon>
                                        </button>
                                        <mat-menu #Notemenu="matMenu">
                                            <span (mouseleave)="NoteTrigger.closeMenu()">
                                                <span>
                                                    <button mat-menu-item (click)="DelNotesRow(i)">
                                                        Delete Note
                                                    </button>
                                                </span>
                                            </span>
                                        </mat-menu>
                                    </li>
                                    <!-- <li class="w-8 p-li">{{i+1}}.{{i+1}}</li> -->
                                    <mat-form-field appearance="outline" class="w-100">
                                        <mat-label>Enter notes for this item here</mat-label>
                                        <input matInput formControlName="Notes">
                                    </mat-form-field>
                                </ul>
                                <!-- Notes Section End -->
                            </div>
                        </div>
                    </div>
                    <!-- -----------------OrderPart List section End--------------- -->
                    <div class="row d-flex justify-content-end mb-5">
                        <div class="col-xl-6 col-12">
                            <div class="card bg-change border-0">
                                <div class="subtotal-heading d-flex gap-3 pr-13" *ngIf="targetVal =='Profitability'">
                                    <div class="total-heading">SubTotal:</div>
                                    <div class="total-value w-53">{{OrderPartForm.value.subTotalPartsMargin}}%</div>
                                    <div class="total-value w-30">{{OrderPartForm.value.subTotalLaborMargin}}%</div>
                                    <div class="total-value">{{OrderPartForm.value.subTotalPartsExt}}%</div>
                                </div>
                                <div class="subtotal-heading d-flex justify-content-between"
                                    *ngIf="targetVal =='Detail'">
                                    <div class="total-heading">SubTotal:</div>
                                    <div class="total-value">{{OrderPartForm.value.subTotalDetExt |currency}}</div>
                                </div>
                                <div class="subtotal-heading d-flex justify-content-between"
                                    *ngIf="targetVal =='Summary'">
                                    <div class="total-heading">SubTotal:</div>
                                    <div class="total-value">{{OrderPartForm.value.subTotalSummaryExt |currency}}</div>
                                </div>
                                <div class="accordian-section">
                                    <div class="middle-section" *ngIf="targetVal != 'Profitability'">
                                        <mat-accordion [togglePosition]="'before'" multi="true">
                                            <mat-expansion-panel>
                                                <mat-expansion-panel-header class="p-0" #PShipping
                                                    (click)="PShipping._toggle()">
                                                    <mat-panel-description class="m-0 justify-content-between">
                                                        <div class="custom-col-1 p-0">
                                                            <mat-icon class="mat-icon-rtl-mirror"
                                                                (click)="PShipping._toggle();setPShipping(_PShipping)">
                                                                {{_PShipping? 'expand_more' :
                                                                'chevron_right'}}
                                                            </mat-icon>
                                                        </div>
                                                        <div class="col-6 p-0"><b>Shipping:</b>
                                                            {{OrderPartForm.value.shippingType}}
                                                            <span
                                                                *ngIf="GetPackageType(OrderPartForm.value.rate_id)!= undefined && shiprateListnew != undefined && shiprateListnew.length>0">
                                                                -
                                                                ({{GetPackageType(OrderPartForm.value.rate_id).carrier_delivery_days}}
                                                                Days Delivery)
                                                                {{GetPackageType(OrderPartForm.value.rate_id).package_type}}
                                                                ({{OrderPartForm.value.quotedShippingPackage}})
                                                            </span>
                                                        </div>
                                                        <div class="col-3 mobile-padding">
                                                            <div
                                                                [ngClass]="(OrderPartForm.value.isCollectAmount ?'Collect_Shipping_disabled': 'btn-link')">
                                                                <span *ngIf="!OrderPartForm.value.isCollectAmount"
                                                                    (click)="changeShippingServices(OrderPartForm.value.shippingOption)">
                                                                    Update Shipping
                                                                </span>
                                                                <span *ngIf="OrderPartForm.value.isCollectAmount">
                                                                    Collect Shipping
                                                                </span>
                                                            </div>
                                                        </div>
                                                        <div class="col-2 d-flex justify-content-end">
                                                            <b>{{OrderPartForm.value.shippingAmount | currency}}</b>
                                                        </div>
                                                    </mat-panel-description>
                                                </mat-expansion-panel-header>
                                                <div class="row mb-3">
                                                    <div class="col-10">
                                                        <mat-form-field appearance="outline" class="w-100">
                                                            <mat-label>Shipping Services</mat-label>
                                                            <mat-select class="w-100"
                                                                (selectionChange)="changeShippingServices($event.value)"
                                                                formControlName="shippingOption">
                                                                <mat-option *ngFor="let sl of constants.ShippingType"
                                                                    [disabled]="sl.id ==0 || sl.id==7"
                                                                    [value]="sl.id">{{sl.name}}</mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div class="row justify-content-between"
                                                    *ngFor="let rate of shiprateListnew">
                                                    <div class="col-1 mobile-padding">
                                                        <input type="radio" class="radio-type" formControlName="rate_id"
                                                            value="{{rate.rate_id}}" (change)="getshipValue(rate)">
                                                    </div>
                                                    <div class="col-8 mb-2 mobile-padding">
                                                        <span class="fedex-main"> {{ rate.service_type }}( {{
                                                            rate.carrier_delivery_days }}
                                                            Days Delivery)</span>
                                                        <span class="fedex-second">{{ rate.package_type}}</span>
                                                    </div>
                                                    <div class="col-2 rate-amount mobile-padding">{{ rate.amount |
                                                        currency
                                                        }}</div>
                                                </div>
                                            </mat-expansion-panel>
                                            <mat-expansion-panel>
                                                <mat-expansion-panel-header class="p-0" #PSales
                                                    (click)="PSales._toggle()">
                                                    <mat-panel-description class="m-0 justify-content-between">
                                                        <div class="custom-col-1 p-0">
                                                            <mat-icon class="mat-icon-rtl-mirror"
                                                                (click)="PSales._toggle();setPSales(_PSales)">
                                                                {{_PSales? 'expand_more' :
                                                                'chevron_right'}}
                                                            </mat-icon>
                                                        </div>
                                                        <div class="col-9 p-0"><b>Sales Tax:</b>
                                                            <span *ngIf="OrderPartForm.value.TaxType == '1'">
                                                                Rate:</span>
                                                            <span *ngIf="OrderPartForm.value.TaxType == '2'">
                                                                Exempt</span>
                                                            <span *ngIf="OrderPartForm.value.TaxType == '3'"> Out Of
                                                                State</span>
                                                            <span
                                                                *ngIf="OrderPartForm.value.isTaxPart && OrderPartForm.value.taxType == '1'">P({{OrderPartForm.getRawValue().partTaxRate}})%</span>
                                                            <span
                                                                *ngIf="OrderPartForm.value.isTaxLabor && OrderPartForm.value.taxType == '1'">,L({{OrderPartForm.getRawValue().laborTaxRate}})%</span>
                                                            <span
                                                                *ngIf="OrderPartForm.value.isTaxShipping && OrderPartForm.value.taxType == '1'">,S({{OrderPartForm.getRawValue().shippingTaxRate}})%</span>
                                                        </div>
                                                        <div class="col-2 d-flex justify-content-end">
                                                            <b>{{(OrderPartForm.value.partTaxRateAmt+OrderPartForm.value.laborTaxRateAmt+OrderPartForm.value.shippingTaxRateAmt)
                                                                | currency}}</b>
                                                        </div>
                                                    </mat-panel-description>
                                                </mat-expansion-panel-header>
                                                <div class="row mb-3">
                                                    <div class="col-10">
                                                        <mat-form-field appearance="outline" class="w-100">
                                                            <mat-label>Sales Tax Type</mat-label>
                                                            <mat-select class="w-100" formControlName="taxType"
                                                                (selectionChange)="ChangeTaxType($event.value)">
                                                                <mat-option
                                                                    [disabled]="OrderPartForm.value.taxable!='Exempt'"
                                                                    [value]="2">Exempt</mat-option>
                                                                <mat-option [value]="3">Out Of State</mat-option>
                                                                <mat-option [value]="1">Taxable</mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                </div>
                                                <div class="row" *ngIf="OrderPartForm.value.taxType ==1">
                                                    <div class="col-12">
                                                        <div class="row mb-3 align-items-center">
                                                            <div class="col-1 mobile-padding"><input type="checkbox"
                                                                    formControlName="isTaxPart"
                                                                    (change)="IsTaxRateChecked('Parts')">
                                                            </div>
                                                            <div class="col-2 mobile-padding">Parts</div>
                                                            <div class="col-7 mobile-padding">
                                                                <mat-form-field appearance="outline">
                                                                    <span matSuffix>%</span>
                                                                    <input matInput type="number"
                                                                        formControlName="partTaxRate"
                                                                        (input)="CalculateTaxRate('Parts')">
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col-2 text-end mobile-padding">
                                                                {{(OrderPartForm.value.partTaxRateAmt) |
                                                                currency}}</div>
                                                        </div>
                                                        <div class="row mb-3 align-items-center">
                                                            <div class="col-1 mobile-padding"><input type="checkbox"
                                                                    formControlName="isTaxLabor"
                                                                    (change)="IsTaxRateChecked('Labor')">
                                                            </div>
                                                            <div class="col-2 mobile-padding">Labor</div>
                                                            <div class="col-7 mobile-padding">
                                                                <mat-form-field appearance="outline">
                                                                    <span matSuffix>%</span>
                                                                    <input matInput type="number"
                                                                        formControlName="laborTaxRate"
                                                                        (input)="CalculateTaxRate('Labor')">
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col-2 text-end mobile-padding">
                                                                {{OrderPartForm.value.laborTaxRateAmt |
                                                                currency}}</div>
                                                        </div>
                                                        <div class="row mb-3 align-items-center">
                                                            <div class="col-1 mobile-padding"><input type="checkbox"
                                                                    formControlName="isTaxShipping"
                                                                    (change)="IsTaxRateChecked('Shipping')"></div>
                                                            <div class="col-2 mobile-padding">Shipping</div>
                                                            <div class="col-7 mobile-padding">
                                                                <mat-form-field appearance="outline">
                                                                    <span matSuffix>%</span>
                                                                    <input matInput type="number"
                                                                        formControlName="shippingTaxRate"
                                                                        (input)="CalculateTaxRate('Shipping')">
                                                                </mat-form-field>
                                                            </div>
                                                            <div class="col-2 text-end mobile-padding">
                                                                {{OrderPartForm.value.shippingTaxRateAmt |
                                                                currency}}</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </mat-expansion-panel>
                                            <mat-expansion-panel>
                                                <mat-expansion-panel-header class="p-0" #PPayment
                                                    (click)="PPayment._toggle()">
                                                    <mat-panel-description class="m-0 justify-content-between">
                                                        <div class="custom-col-1  p-0"> <mat-icon
                                                                class="mat-icon-rtl-mirror"
                                                                (click)="PPayment._toggle();setPPayment(_PPayment)">
                                                                {{_PPayment? 'expand_more' :
                                                                'chevron_right'}}
                                                            </mat-icon></div>
                                                        <div class="col-6 p-0" (opened)="setStep(2)"><b>Payments:</b>
                                                            {{OrderPartForm.value.paymentType}}
                                                            {{OrderPartForm.value.paymentStatus}}</div>
                                                        <div class="col-3">
                                                            <button class="btn pay-now-btn" (click)="PaymentModal()"
                                                                [disabled]="IsPayNow()">
                                                                <span>Pay Now</span>
                                                            </button>
                                                        </div>
                                                        <div class="col-2 d-flex justify-content-end">
                                                            <b>{{OrderPartForm.value.paymentAmt| currency}}</b>
                                                        </div>
                                                    </mat-panel-description>
                                                </mat-expansion-panel-header>
                                                <div class="row align-items-center">
                                                    <div class="col-md-10 col-8">
                                                        <mat-form-field appearance="outline" class="w-100">
                                                            <mat-label>Payment Type</mat-label>
                                                            <mat-select class="w-100" formControlName="paymentType">
                                                                <mat-option value="Credit Card">Credit Card</mat-option>
                                                                <mat-option value="PayPal">PayPal</mat-option>
                                                                <!-- <mat-option value="Helcim">Helcim</mat-option> -->
                                                                <mat-option
                                                                    value="NetTerms">{{GetNetTermsVal()}}</mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                    </div>
                                                    <div class="col-md-2 col-4 update-link text-end p-0"
                                                        (click)="PaymentLog()">
                                                        Payment Log
                                                    </div>
                                                </div>
                                                <div *ngIf="PaymentLogList.length >0">
                                                    <div class="row" *ngFor="let t of PaymentLogList">
                                                        <div class="col-6" *ngIf="t.status != 'CANCELED'">
                                                            Transaction : &nbsp; {{t.status}} &nbsp; {{t.createdDate |
                                                            date:'MM/dd/yy'}} <br>
                                                        </div>
                                                        <div class="col-3" *ngIf="t.status != 'CANCELED'">
                                                            {{t.amountMoney/100 | currency}}
                                                        </div>
                                                    </div>
                                                </div>
                                            </mat-expansion-panel>
                                        </mat-accordion>
                                    </div>
                                    <div class="d-flex justify-content-between subtotal-heading">
                                        <div class="total-heading">Balance Due:</div>
                                        <div class="total-value">{{((OrderPartForm.value.subTotalSummaryExt +
                                            (OrderPartForm.value.partTaxRateAmt+OrderPartForm.value.laborTaxRateAmt+OrderPartForm.value.shippingTaxRateAmt)
                                            + OrderPartForm.value.shippingAmount)-OrderPartForm.value.paymentAmt)|
                                            currency}}</div>
                                        <!-- <div class="col-2">{{GetBalanceDue()| currency}}</div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- -----------------OrderPart List section End--------------- -->
                </div>
            </div>
        </div>
        <!-- -- Parts Section flyout Start-->
        <diV *ngIf="IsAddPartFlyOut"> <app-order-part-fly-out [pageName]="'SalesOrder'"
                (IsSalesOrderFlyOut)="PartFlyOutClose($event)" [CompanyId]="companyId"
                [partIds]="OrderPart.value"></app-order-part-fly-out>
        </diV>
        <!-- -- Parts Section flyout End-->

        <!-- --------------------------------------------------- Order History flyout Start--------------------------------------------------->
        <div class="overcstfixedsidebar active" *ngIf="IsHistoryFlyOut"></div>
        <div class="sidebar_trackingnum active left-sidebar" *ngIf="IsHistoryFlyOut">
            <!-- -=------------------------------- title of order history --------------- -->
            <div class="titlecstmsidebar">
                <h2 class="top-heading">Order History</h2>
                <h4>Scroll to view the Sales Order History.</h4>
            </div>
            <!-- -=------------------------------- title of order history --------------- -->
            <div class="table-order-history">
                <table class="table-material border-0">
                    <thead class="table-responsive-header">
                        <tr>
                            <th>Document</th>
                            <th>Order Number</th>
                            <th>Order Status</th>
                            <th>Expiration</th>
                            <th>Hold Reason</th>
                            <th>Hold Until Date</th>
                            <th>Modified Date</th>
                            <th>Changed By</th>
                        </tr>
                    </thead>
                    <tbody class="table-responsive-body">
                        <tr *ngFor="let h of OrderHistoryList; let i = index;">
                            <td data-label="Document">
                                <span *ngIf="h.status != 'Draft'">
                                    <mat-icon
                                        (click)=" BarcodeGenerate(h.document,h.orderNumber,true,false,h.status,false,2)">
                                        insert_drive_file</mat-icon>
                                </span>
                            </td>
                            <td data-label="Order Number">{{h.orderNumber}}</td>
                            <td data-label="Order Status">{{h.status}}</td>
                            <td data-label="Expiration">{{h.expiration | date :'M/d/yy, h:mm a'}}</td>
                            <td data-label="Hold Reason">{{h.holdReason}}</td>
                            <td data-label="Hold Until Date">{{h.holdUntilDate | date :'M/d/yy, h:mm a'}}</td>
                            <td data-label="Modified Date">{{h.createdDate | date :'M/d/yy, h:mm a'}}</td>
                            <td data-label="Changed By">{{h.createdBy}}</td>
                    </tbody>
                </table>
            </div>
            <div class="footer-btn">
                <button type="button" class="save-btn btn update-btn" (click)="CloseHistoryFlyOut()">Close</button>
            </div>
        </div>
        <!-- -- -------------------------------------------------Order History flyout End-------------------------------------------------->

        <!-- -- PaymentLog flyout Start-->
        <div class="overcstfixedsidebar active" *ngIf="IsPaymentLogFlyOut"></div>
        <div class="sidebar_trackingnum active" *ngIf="IsPaymentLogFlyOut">
            <div class="titlecstmsidebar">
                <h2>Payment Log</h2>
                <h4>Please review the payment transactions for the Sales Order.</h4>
                <hr />
            </div>
            <div class="card-desc example-container cstm_scrollbar_theme company-table">
                <ul class="heading-section">
                    <li class="w-8 p-li">Item</li>
                    <li class="w-12 p-li">Payment Type</li>
                    <li class="w-15 p-li">Payment Amount</li>
                    <li class="w-15 p-li">Status</li>
                    <li class="w-8 p-li">Card</li>
                    <li class="wd-25 p-li">Transaction Id</li>
                    <li class="w-12 p-li">Last 4 of Card</li>
                    <li class="w-12 p-li">Expiration Date</li>
                </ul>
                <div *ngIf="PaymentLogList!= undefined && PaymentLogList.length >0">
                    <div *ngFor="let paymentlog of PaymentLogList; let i = index;">
                        <ul class="value-section" *ngIf="paymentlog.status != 'CANCELED'">
                            <li class="w-8 p-li">{{i+1}}</li>
                            <li class="w-12 p-li"> {{paymentlog.sourceType}}</li>
                            <li class="w-15 p-li">{{paymentlog.amountMoney/100 | currency}}</li>
                            <li class="w-15 p-li">{{paymentlog.status}}</li>
                            <li class="w-8 p-li">{{paymentlog.cardBrand}}</li>
                            <li class="wd-25 p-li">{{paymentlog.transactionId}}</li>
                            <li class="w-12 p-li">{{paymentlog.ccLast4}}</li>
                            <li class="w-12 p-li">{{paymentlog.ccExpDate | slice:0:3}}{{paymentlog.ccExpDate | slice:5}}
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div>
                <button type="button" class="btn btn-primary" (click)="ClosePaymentLogFlyOut()">Close</button>
            </div>
        </div>
        <!-- -- PaymentLog flyout End-->
    </form>
</section>

<!-- modal work Start-->
<!-- Bill To Address Modal Start -->
<div class="modal" id="BillToAddressModal">
    <div class="modal-dialog">
        <div class="modal-content change-modal-bg">
            <!-- Modal Header -->
            <div class="modal-header">
                <h2 class="">Enter a new Bill to location for this customer.</h2>
            </div>
            <div class="modal-body modal-height">
                <form [formGroup]="CustomerForm">
                    <div class="row">
                        <div class="col-12 mb-3 no-padding-card">
                            <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                                <mat-label>Customer Name</mat-label>
                                <input matInput type="text" formControlName="name" placeholder="Customer Name">
                            </mat-form-field>
                            <mat-error class="text-danger"
                                *ngIf="(CustomerFormError.name.touched || submitted) && CustomerFormError.name.errors?.required">
                                Customer Name is required
                            </mat-error>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 mb-3 no-padding-card">
                            <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                                <mat-label>Address Line 1</mat-label>
                                <input matInput type="text" formControlName="addressLine1" placeholder="Address Line 1">
                            </mat-form-field>
                            <mat-error class="text-danger"
                                *ngIf="(CustomerFormError.addressLine1.touched || submitted) && CustomerFormError.addressLine1.errors?.required">
                                Address Line 1 is required
                            </mat-error>
                        </div>
                    </div>
                    <div class="row select-arrow-change-icon">
                        <div class="col-md-6 col-12 mb-3 first-card">
                            <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                                <mat-label>Address Line 2</mat-label>
                                <input matInput type="text" formControlName="addressLine2" placeholder="Address Line 2">
                            </mat-form-field>
                        </div>
                        <div class="col-md-6 col-12 mb-3 fourth-card">
                            <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                                <mat-label>Country</mat-label>
                                <mat-select formControlName='countryId'
                                    (selectionChange)="GetStateByCountryId($event.value)">
                                    <mat-option disabled selected>Select Country</mat-option>
                                    <mat-option *ngFor="let c of CountryList" [value]="c.id">
                                        {{c.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-error class="text-danger"
                                *ngIf="(CustomerFormError.countryId.touched || submitted) && CustomerFormError.countryId.errors?.required">
                                Country is required
                            </mat-error>
                        </div>
                    </div>
                    <div class="row select-arrow-change-icon">
                        <div class="col-xl-6 mb-lg-0 mb-3 first-card">
                            <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                                <mat-label>City or Town</mat-label>
                                <input matInput type="text" formControlName="city" placeholder="City or Town">
                            </mat-form-field>
                            <mat-error class="text-danger"
                                *ngIf="(CustomerFormError.city.touched || submitted) && CustomerFormError.city.errors?.required">
                                City is required
                            </mat-error>
                        </div>
                        <div class="col-xl-3 col-md-6 mb-3 second-card">
                            <mat-form-field class="forShipping m-0 mt-12 p-0 state-select" appearance="outline">
                                <mat-label>State</mat-label>
                                <mat-select formControlName='stateId'>
                                    <mat-option disabled selected>Select State</mat-option>
                                    <mat-option *ngFor="let s of StateListByCountryId" [value]="s.id">
                                        <span *ngIf="s.abbreviation.length != 0">{{s.abbreviation}}</span>
                                        <span *ngIf="s.abbreviation.length == 0">{{s.name}}</span>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-error class="text-danger"
                                *ngIf="(CustomerFormError.stateId.touched || submitted) && CustomerFormError.stateId.errors?.required">
                                State is required
                            </mat-error>
                        </div>
                        <div class="col-xl-3 col-md-6 mb-3 fourth-card">
                            <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                                <mat-label>Zip</mat-label>
                                <input matInput type="text" formControlName="zip" placeholder="xxxxx-xxxx">
                            </mat-form-field>
                            <mat-error class="text-danger validation-error"
                                *ngIf="CustomerFormError.zip.errors?.minlength">
                                Enter a valid Zip Code
                            </mat-error>
                            <mat-error class="text-danger"
                                *ngIf="(CustomerFormError.zip.touched || submitted) && CustomerFormError.zip.errors?.required">
                                Zip is required
                            </mat-error>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 col-12 mb-3 first-card">
                            <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                                <mat-label>Email</mat-label>
                                <input matInput type="text" formControlName="email" placeholder="Email">
                            </mat-form-field>
                            <mat-error class="text-danger"
                                *ngIf="CustomerFormError.email.errors?.email || CustomerFormError.email.errors?.pattern">
                                Enter a valid email address
                            </mat-error>
                        </div>
                        <div class="col-md-6 col-12 mb-3     fourth-card">
                            <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                                <mat-label>Contact Number</mat-label>
                                <input matInput type="text" formControlName="phone" placeholder="(xxx) xxx-xxxx">
                            </mat-form-field>
                            <!-- <mat-error class="text-danger validation-error"
                                *ngIf="CustomerFormError.phone.errors?.minlength">
                                Enter a valid Phone Number
                            </mat-error> -->
                        </div>
                    </div>
                </form>
            </div>
            <!-- Modal footer -->
            <div class="modal-footer">
                <button type="button" class="btn cancel-btn update-btn" (click)="CloseBillToAddres()">Cancel</button>
                <button type="button" class="save-btn btn update-btn" (click)="AddCustomer('Bill')">Save Bill to
                    Information</button>
            </div>

        </div>
    </div>
</div>
<!-- Bill To Address Modal End -->
<!-- Ship To Address Modal Start -->
<div class="modal" id="ShipToAddressModal" style="color: #74777F;">
    <div class="modal-dialog">
        <form [formGroup]="CustomerForm">
            <div class="modal-content change-modal-bg">
                <!-- Modal Header -->
                <div class="modal-header">
                    <h2 class="">Enter a new Ship to location for this customer.</h2>
                </div>
                <div class="modal-body modal-height">
                    <div class="row">
                        <div class="col-12 mb-3 no-padding-card">
                            <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                                <mat-label>Ship Name</mat-label>
                                <input matInput type="text" placeholder="Ship Name" formControlName="name">
                            </mat-form-field>
                            <mat-error class="text-danger"
                                *ngIf="(CustomerFormError.name.touched || submitted) && CustomerFormError.name.errors?.required">
                                Ship Name is required
                            </mat-error>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 mb-3 no-padding-card">
                            <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                                <mat-label>Street Address</mat-label>
                                <input matInput type="text" placeholder="Street Address" formControlName="addressLine1">
                            </mat-form-field>
                            <mat-error class="text-danger"
                                *ngIf="(CustomerFormError.addressLine1.touched || submitted) && CustomerFormError.addressLine1.errors?.required">
                                Street is required
                            </mat-error>
                        </div>
                    </div>
                    <div class="row select-arrow-change-icon">
                        <div class="col-md-6 col-12 mb-3 first-card">
                            <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                                <mat-label>Address 2 (optional)</mat-label>
                                <input matInput type="text" placeholder="Address 2 (optional)"
                                    formControlName="addressLine2">
                            </mat-form-field>
                        </div>
                        <div class="col-md-6 col-12 mb-3 fourth-card">
                            <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                                <mat-label>Country</mat-label>
                                <mat-select formControlName='countryId'
                                    (selectionChange)="GetStateByCountryId($event.value)">
                                    <mat-option disabled selected>Select Country</mat-option>
                                    <mat-option *ngFor="let c of CountryList" [value]="c.id">
                                        {{c.name}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-error class="text-danger"
                                *ngIf="(CustomerFormError.countryId.touched || submitted) && CustomerFormError.countryId.errors?.required">
                                Country is required
                            </mat-error>
                        </div>
                    </div>
                    <div class="row select-arrow-change-icon">
                        <div class="col-lg-6 col-12 mb-3 first-card">
                            <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                                <mat-label>City or Town</mat-label>
                                <input matInput type="text" placeholder="City or Town" formControlName="city">
                            </mat-form-field>
                            <mat-error class="text-danger"
                                *ngIf="(CustomerFormError.city.touched || submitted) && CustomerFormError.city.errors?.required">
                                City is required
                            </mat-error>
                        </div>
                        <div class="col-lg-3 col-12 mb-3 second-card">
                            <mat-form-field class="forShipping m-0 mt-12 p-0 state-select" appearance="outline">
                                <mat-label>State</mat-label>
                                <mat-select formControlName='stateId'>
                                    <mat-option disabled selected>Select State</mat-option>
                                    <mat-option *ngFor="let s of StateListByCountryId" [value]="s.id">
                                        <span *ngIf="s.abbreviation.length != 0">{{s.abbreviation}}</span>
                                        <span *ngIf="s.abbreviation.length == 0">{{s.name}}</span>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <mat-error class="text-danger"
                                *ngIf="(CustomerFormError.stateId.touched || submitted) && CustomerFormError.stateId.errors?.required">
                                State is required
                            </mat-error>
                        </div>
                        <div class="col-lg-3 col-12 mb-3 fourth-card">
                            <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                                <mat-label>Zip</mat-label>
                                <input matInput type="text" placeholder="xxxxxxxxx" formControlName="zip">
                            </mat-form-field>
                            <mat-error class="text-danger validation-error"
                                *ngIf="CustomerFormError.zip.errors?.minlength">
                                Enter a valid Zip Code
                            </mat-error>
                            <mat-error class="text-danger"
                                *ngIf="(CustomerFormError.zip.touched || submitted) && CustomerFormError.zip.errors?.required">
                                Zip Code is required
                            </mat-error>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-6 col-12 mb-3 first-card">
                            <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                                <mat-label>Email</mat-label>
                                <input matInput type="text" placeholder="Email" formControlName="email">
                            </mat-form-field>
                            <mat-error class="text-danger"
                                *ngIf="CustomerFormError.email.errors?.email || CustomerFormError.email.errors?.pattern">
                                Enter a valid email address
                            </mat-error>
                        </div>
                        <div class="col-md-6 col-12 mb-3 fourth-card">
                            <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                                <mat-label>Contact Number</mat-label>
                                <input matInput type="text" placeholder="(xxx) xxx-xxxx" formControlName="phone">
                            </mat-form-field>
                            <!-- <mat-error class="text-danger validation-error"
                                *ngIf="CustomerFormError.phone.errors?.minlength">
                                Enter a valid Phone Number
                            </mat-error> -->
                        </div>
                    </div>
                </div>
                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn cancel-btn update-btn"
                        (click)="CloseAddShipToAddres()">Cancel</button>
                    <button type="button" class="save-btn btn update-btn" (click)="AddCustomer('Ship')">Save Ship to
                        Information</button>
                </div>

            </div>
        </form>
    </div>
</div>
<!-- Ship To Address Modal End -->
<!-- Payment Infor Modal Start -->
<div class="modal" id="PaymentInfoModal" style="color: #74777F;">
    <div class="modal-dialog">
        <form [formGroup]="CCardPaymentForm" id="helcimForm">
            <div class="modal-content">
                <!-- Modal Header -->
                <div class="modal-header">
                    <div>
                        <h2 class="">{{OrderPartForm.value.paymentType}} Payment Information.</h2>
                        <h6>Please complete the {{OrderPartForm.value.paymentType}} information below.</h6>
                    </div>
                </div>
                <div class="modal-body">
                    <div class="row mt-2">
                        <div class="col-12">
                            <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                                <mat-label>Payment Amount</mat-label>
                                <input matInput type="text" id="amount" (input)="AcceptNumberOnly($event,'CCAmount')"
                                    placeholder="Enter Creadit Card Number" formControlName="CCAmount">
                            </mat-form-field>
                            <mat-error class="text-danger" *ngIf="CCardPaymentForm.get('CCAmount').hasError('Exceed')">
                                Payment Can not exceed by Balance Due
                            </mat-error>
                        </div>
                    </div>
                    <div class="row mt-2" [hidden]="OrderPartForm.value.paymentType != 'Credit Card'">
                        <div class="col-12" id="card-container"></div>
                    </div>
                    <div *ngIf="OrderPartForm.value.paymentType == 'Helcim'">
                        <input type="hidden" id="token" value="04532a54d6f9b9b8436a54">
                        <input type="hidden" id="language" value="en">
                        <div class="row mt-2">
                            <div class="col-xl-6">
                                <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                                    <mat-label>Credit Card Number</mat-label>
                                    <input matInput type="text" id="cardNumber" placeholder="Enter Helcim Card Number"
                                        maxlength="18">
                                </mat-form-field>
                            </div>
                            <div class="col-3">
                                <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                                    <mat-label>Expiration Date</mat-label>
                                    <input matInput type="text" id="cardExpiryMonth" mask='00/00' placeholder="MM/YY">
                                </mat-form-field>
                            </div>
                            <div class="col-3">
                                <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                                    <mat-label>CVV Code</mat-label>
                                    <input matInput type="text" placeholder="###" maxlength="4" min="3">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-12">
                                <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                                    <mat-label>Name on Card</mat-label>
                                    <input matInput type="text" id="cardHolderName" placeholder="Name on Card">
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-xl-8">
                                <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                                    <mat-label>Billing Street Address</mat-label>
                                    <input matInput type="text" id="cardHolderAddress"
                                        placeholder="Billing Street Address">
                                </mat-form-field>
                            </div>
                            <div class="col-4">
                                <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                                    <mat-label>Billing Zip Code</mat-label>
                                    <input matInput type="text" id="cardHolderPostalCode"
                                        placeholder="Billing Zip Code">
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" (click)="ClosePaymentInfoModal()">Cancel</button>
                    <!-- <button type="button" class="btn btn-primary" [disabled]="OrderPartForm.value.paymentStatus=='APPROVED'"  (click)="Pay(false)">Authorize</button> -->
                    <button type="button" class="btn btn-primary"
                        [disabled]="OrderPartForm.value.paymentStatus=='APPROVED'"
                        (click)="Save(true, true, true, false)">Authorize</button>
                    <button type="button" class="btn btn-primary" [disabled]="CCardPaymentForm.invalid"
                        (click)="Save(true,true,false,true)">Complete
                        Payment</button>
                </div>

            </div>
        </form>
    </div>
</div>
<!-- Payment Infor Modal End -->
<!-- PayPal Modal Start -->
<div class="modal" id="PaymentModal" style="color: #74777F;">
    <div class="modal-dialog">
        <form [formGroup]="PaymentForm">
            <div class="modal-content">
                <!-- Modal Header -->
                <div class="modal-header">
                    <div>
                        <h2 class="">{{OrderPartForm.value.paymentType}} Payment Information.</h2>
                        <h4>Please enter the payment amount below.</h4>
                    </div>
                </div>
                <div class="modal-body">
                    <div class="row mt-2">
                        <div class="col-12">
                            <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                                <mat-label>Payment Amount</mat-label>
                                <input matInput type="text" formControlName="PaymentAmount"
                                    (input)="AcceptNumberOnly($event,'PaymentAmount')"
                                    placeholder="Enter Creadit Card Number">
                            </mat-form-field>
                            <mat-error class="text-danger" *ngIf="PaymentForm.get('PaymentAmount').hasError('Exceed')">
                                Payment Can not exceed by Balance Due
                            </mat-error>
                        </div>
                    </div>
                </div>
                <!-- Modal footer -->
                <div class="modal-footer">
                    <button type="button" class="btn btn-primary" (click)="ClosePaymentModal()">Cancel</button>
                    <button type="button" class="btn btn-primary" [disabled]="PaymentForm.invalid"
                        (click)="Save(false,true,false,true)">Complete Payment</button>
                </div>
            </div>
        </form>
    </div>
</div>
<!-- PayPal Infor Modal End -->
<!-- Sales Discount Modal Start -->
<div class="modal" id="SalesDiscountModal" style="color: #74777F;">
    <div class="modal-dialog">
        <div class="modal-content change-backg">
            <!-- Modal Header -->
            <div class="modal-header border-0 flex-column">
                <h2 class="sales-heading">Sales Discounts</h2>
                <span class="sales-discount-para">The discounts available for this product are displayed in the tables
                    below.</span>
            </div>
            <div class="modal-body border-0 pt-0">
                <div class="row mt-2" *ngIf="DiscountIndex > -1">
                    <div class="col-md-6 first-column-padding">
                        <h6 class="table-top-heading text-center">Volume Pricing Discounts</h6>
                        <table class="sales-discount-table">
                            <thead>
                                <tr>
                                    <th>Min Qty</th>
                                    <th>Max Qty</th>
                                    <th>Unit Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let r of OrderPart.value[DiscountIndex]._CustomerDiscount">
                                    <td>{{r.minQty}}</td>
                                    <td>{{r.qty != 0 ? r.qty : '+'}}</td>
                                    <td>{{r.customerCost | currency}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div class="col-md-6 second-column-padding">
                        <h6 class="table-top-heading text-center">Role Based Pricing</h6>
                        <table class="sales-discount-table">
                            <thead>
                                <tr>
                                    <th>Role</th>
                                    <th>Unit Price</th>
                                </tr>
                            </thead>
                            <tbody *ngFor="let c of OrderPart.value[DiscountIndex].RoleBasePricingDiscount">
                                <tr>
                                    <td>{{GetCustomerTypeForRolePricing(c.customerValue)}}</td>
                                    <td>{{c.cost | currency}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>
            </div>
            <!-- Modal footer -->
            <div class="modal-footer border-0">
                <button type="button" class="btn close-btn" (click)="CloseSalesDiscountModal()">Close</button>
            </div>

        </div>
    </div>
</div>
<!-- Sales Discount Modal End -->
<!-- Address Validation Modal Start -->
<div class="modal" id="validAddress">
    <div class="modal-dialog">
        <div class="modal-content change-backg">
            <div class="modal-body p-1">
                <div class="row p-3">
                    <div class="col-lg-12">
                        <h2 class="valid-heading">Address Validation</h2>
                    </div>
                    <div class="col-lg-12">
                        <h6 class="valid-para">
                            Please click on the preferred address and click the Confirm button to update the Ship To
                            address.
                        </h6>
                    </div>
                    <div class="col-lg-12">
                        <div class="row">
                            <div class="col-md-6 first-card">
                                <div class="form-group" (click)="DefaultAddress(false)">
                                    <fieldset class="the-fieldset"
                                        [ngClass]="!OrderPartForm.value.isAddressValidate ? 'defaultAddress': ''">
                                        <legend class="the-legend">Original Address</legend>
                                        <div>
                                            <ul class="list-unstyled">
                                                <li>{{ShipToForm.value.addressLine1}}</li>
                                                <li>{{ShipToForm.value.city}}</li>
                                                <li>{{ShipToForm.value.zip}}</li>
                                                <li>{{GetStateCode(ShipToForm.value.stateId)}}</li>
                                            </ul>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                            <div class="col-md-6 fourth-card">
                                <div class="form-group" (click)="DefaultAddress(true)">
                                    <fieldset class="the-fieldset"
                                        [ngClass]="OrderPartForm.value.isAddressValidate ? 'defaultAddress': ''">
                                        <legend class="the-legend">Suggested Address</legend>
                                        <div>
                                            <ul class="list-unstyled" *ngIf="AddressValidatedAddress != undefined">
                                                <li>{{AddressValidatedAddress.address_line1}}</li>
                                                <li>{{AddressValidatedAddress.city_locality}}</li>
                                                <li>{{AddressValidatedAddress.postal_code}}</li>
                                                <li>{{AddressValidatedAddress.state_province}}</li>
                                            </ul>
                                        </div>
                                    </fieldset>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 d-flex justify-content-end gap-3">
                        <button class="btn cancel-btn update-btn" (click)="closevalidaddress()">Cancel</button>
                        <button class="btn save-btn update-btn" (click)="SuggestedShipToAddress()">Confirm</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- Address Validation Modal End -->

<!-- -------------------------------------update shipping dialogue start --------------------------->
<div class="modal" id="updateshipping">
    <div class="modal-dialog">
        <div class="modal-content change-modal-bg">
            <!-- Modal Header -->
            <div class="modal-header border-0">
                <h2 class="">Update Shipping</h2>
            </div>
            <div class="modal-body modal-height pt-0">
                <span class="update-content">The Sales Order has changed but the shipping cost has not been updated.
                    Please click the Update Shipping button to update the shipping cost without Saving or Skip and Save
                    to Save without updating the shipping cost.
                </span>
            </div>
            <!-- Modal footer -->
            <div class="modal-footer p-4 border-0">
                <button type="button" class="cancel-btn btn update-btn"
                    (click)="GetShippingCharge(this.OrderPartForm.value.shippingOption)">Update Shipping</button>
                <button type="button" class="save-btn btn update-btn" (click)="Save(false, false, true,false)">Skip and
                    Save</button>
            </div>

        </div>
    </div>
</div>
<!-- -------------------------------------update shipping dialogue start------------------------- -->
<!--Validation Modal start for Vendor & Ship to data-->
<div class="modal" id="SOFormValidationModal" style="color: #74777F;">
    <div class="modal-dialog">
        <div class="modal-content change-backg">
            <!-- Modal Header -->
            <div class="modal-header border-0 flex-column">
                <h2 class="sales-heading">Missing Information</h2>
                <span class="sales-discount-para mb-3">To save this order you must save as Draft or complete the
                    following information:</span>
                <span class="disc-list" [innerHtml]="messageList">
                </span>
            </div>
            <!-- Modal footer -->
            <div class="modal-footer border-0">
                <button class="btn cancel-btn update-btn" (click)="closeValidationModal()">Cancel</button>
                <button class="btn save-btn update-btn" (click)="SaveAsdraft()">Save as Draft</button>
            </div>
        </div>
    </div>
</div>
<!--Validation Modal End -->
<!-- BarCode work -->
<div hidden>
    <svg #barcode id="barcode"></svg>
</div>