import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
// import { DownlineTreeviewItem, DropdownTreeviewComponent, TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstantsService } from 'src/app/constants.service';
import { SharedService } from 'src/app/services/shared.service';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { ConfirmationDialogService } from 'src/app/ConfirmationDialog/confirmation-dialog.service'
import Swal from 'sweetalert2';
import { GlobalChangeService } from 'src/app/services/global-change.service';
import { VendorService } from 'src/app/services/vendor/vendor.service';
import { PartService } from 'src/app/services/part/part.service';
import { CompanyService } from 'src/app/services/company/company.service';

@Component({
  selector: 'app-vendors',
  templateUrl: './vendors.component.html',
  styleUrls: ['./vendors.component.css']
})
export class VendorsComponent implements AfterViewInit, OnDestroy, OnInit {
  stateData;
  termsData;
  shippingData;
  carrierData;
  // @ViewChild(DropdownTreeviewComponent, { static: false }) dropdownTreeviewComponent: DropdownTreeviewComponent;
  data: any = {};
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  vendorList: any;
  form: FormGroup;
  formSearch: FormGroup;
  id: number;
  firstName: string;
  lastName: string;
  vendorName: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  stateId: any;
  website;
  account;
  zipCode: string;
  phoneNumber: string;
  email: string;
  poEmail: string;
  descriptions: string;
  userGroupObjects = new Array();
  userGroupObjects_search = new Array();
  userId: string;
  value: number;
  value_search: number;
  deletedItem: string;
  deleteDetail: string;
  companyList: any;
  companyList_search: any;
  companyId: any;
  selectedStateValue = '';
  selectedtermsValue = '';
  selectedCarrierValue = '';
  selectedshippingValue = '';
  countrySelected = '231';
  selectedStateId: any;
  buttonValue: string;
  createdBy: any;
  createdDate: any;
  recordAlreadyExist = false;
  settingDetailCSS: any;
  loader: boolean = false;
  VendorNotes: any;
  AdditionalDiscount : string;
  FreeShipping : string; 
  settingLogoImage: any; selectedHierarchyStateValue = '';
  // config = TreeviewConfig.create({
  //   hasAllCheckBox: true,
  //   hasFilter: true,
  //   hasCollapseExpand: true,
  //   decoupleChildFromParent: false,
  //   maxHeight: 400

  // });

  // items: TreeviewItem[];
  // items_search: TreeviewItem[];
  validation: boolean = false;
  rolePermissionsList: any;
  userList: any;
  roleId: any;
  permissionMessage = '';
  clearbutton: boolean = true;
  VendorHash: any;
  Fax: any
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  termsId: any;
  carrierId: any;
  shippingId: any;
  extension: any;
  FobPoint: any;
  selectedFobPointValue: any = '';
  countrList: any;
  selectedCountryValue1 = '';
  TCreateUpdate: any = 1;
  vendorSearch: any;
  SearchValidation: any;
  SearchType: any = false;
  SearchControl: boolean = false;
  ListShow: boolean = false;
  ListDiv: boolean = false;
  ListAngle: any;
  isSaveOrUpdate:boolean=false;
  items_search: any;
  items: any;
  constructor(public sharedService: SharedService,
    private toastr: ToastrService,
    public vendorService: VendorService,
    private modalService: NgbModal,
    private constants: ConstantsService,
    private router: Router, private route: ActivatedRoute,
    public confirmation: ConfirmationDialogService,
    private globalChangeService:GlobalChangeService,
    private partService: PartService,
    private companyService: CompanyService
  ) {}

  ngOnInit(): void {
 
    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);
    }
    this.rolePermissionsList = JSON.parse(localStorage.getItem('rolePermissionsList'));
    this.rolePermissionsList = this.rolePermissionsList.filter(x => x.pageId == 6);
    this.permissionMessage = '';
    if (this.rolePermissionsList != null && this.rolePermissionsList != undefined) {
      if (!this.rolePermissionsList[0].isView) {
        this.permissionMessage += 'view';
      } if (!this.rolePermissionsList[0].isInsert) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'add';
      } if (!this.rolePermissionsList[0].isUpdate) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'edit';
      } if (!this.rolePermissionsList[0].isDelete) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'delete';
      }
    }
    if (this.permissionMessage != '') {
      this.permissionMessage = 'You do not have access to ' + this.permissionMessage + '. Please contact to administrator!';
    }
    this.buttonValue = this.constants.Save;
    this.userId = localStorage.getItem(this.constants.userId);
    this.companyId = localStorage.getItem(this.constants.companyId);

    //ankit commented
    // this.getAllCompany(this.companyId);
    // this.getAllCompany_search(this.companyId);
    
    this.GetAllCountryDetails();
    this.dtOptions = {
      pagingType: this.constants.full_numbers,
      pageLength: 10,
      ordering: true,
      order : [[1, 'asc']],
      scrollX: true
     
    };

    this.form = new FormGroup({
      'firstName': new FormControl(),
      'lastName': new FormControl(),
      'vendorName': new FormControl(),
      'addressLine1': new FormControl(),
      'addressLine2': new FormControl(),
      'city': new FormControl(),
      'stateId': new FormControl('', Validators.compose([Validators.required, Validators.minLength(1)])),
      'companyId': new FormControl(),
      'termsId': new FormControl(),
      'carrierId': new FormControl(),
      'shippingId': new FormControl(),
      'website': new FormControl(),
      'AdditionalDiscount' : new FormControl(),
      'extension': new FormControl(),
      'account': new FormControl(),
      'zipCode': new FormControl(),
      'phoneNumber': new FormControl(),
      'email': new FormControl(),
      'descriptions': new FormControl(),
      'VendorNotes': new FormControl(),
      'VendorHash': new FormControl({ value : '', disabled : true}),
      'Fax': new FormControl(),
      'FobPointId': new FormControl(),
      'countrySelected': new FormControl(),
      'poEmail': new FormControl(),
      'FreeShipping' : new FormControl()

    });


    this.formSearch = new FormGroup({
      'TCreateUpdate': new FormControl(),
      'vendorSearch': new FormControl(),
    });
    if (this.route.snapshot.params['id']) {
      const Id = this.route.snapshot.params['id'];
      this.GetValue(Id);
    }
    else {
      // this.router.navigate(['/vendors/' + 0], { skipLocationChange: true });
   
    }

    //ankit implementaion
    this.getCompanyValue();

    //on change function 
    this.globalChangeService.dropdownChange$.subscribe((data)=>{
      this.onValueChange(data);
      this.onValueChange_search(data);
    })
    this.getAllState(this.countrySelected);
  }

  getCompanyValue(){
        // get company by default set value

        var company = this.globalChangeService.getGlobalCompany();
        if(company>0){
          this.value = company;
          this.value_search = company;
        }else{
          this.ErrorModal('Error', 'Please select company.');
        }
  }

  async canDeactivate(): Promise<Promise<boolean> | boolean> {
   
    if (!this.form.pristine && !this.isSaveOrUpdate) {
      const confirmResult = await this.confirmation.confirm('Confirmation','Are you sure you want to leave this page ?','Cancel','OK','lg');
      if (confirmResult === true) {
        return true;
      } else {
        return false;
      }
    }
    else {
      return true;
    }
  }

  GetValue(Id) {
    this.loader = true;
    this.ListDiv = false;
    this.ListAngle = "down";
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.buttonValue = this.constants.Update;
    this.clearbutton = false;
    this.vendorService.GetVendorById(Id).subscribe(
      (data: any) => {
        this.loader = false;
        if (data.statusCode == 200) {
          this.TCreateUpdate = 0;
          this.SearchControl = true;
          this.id = Id;
          this.firstName = (data.data.firstName == null || data.data.firstName == undefined) ? '' : data.data.firstName;
          this.lastName = (data.data.lastName == null || data.data.lastName == undefined) ? '' : data.data.lastName;
          this.vendorName = data.data.vendorName;
          this.phoneNumber = (data.data.phoneNumber == null || data.data.phoneNumber == undefined) ? '' : data.data.phoneNumber;
          this.email = (data.data.email == null || data.data.email == undefined) ? '' : data.data.email;
          this.addressLine1 = (data.data.addressLine1 == null || data.data.addressLine1 == undefined) ? '' : data.data.addressLine1;
          this.addressLine2 = (data.data.addressLine2 == null || data.data.addressLine2 == undefined) ? '' : data.data.addressLine2;
          this.city = (data.data.city == null || data.data.city == undefined) ? '' : data.data.city;
          this.zipCode = (data.data.zipCode == null || data.data.zipCode == undefined) ? '' : data.data.zipCode;
          this.descriptions = (data.data.descriptions == null || data.data.descriptions == undefined) ? '' : data.data.descriptions;
          this.value = Number(data.data.companyId);
          this.value_search =  Number(data.data.companyId);
         // this.vendorSearch = data.data.vendorName;
          this.selectedStateValue = data.data.stateId == null ? '' : data.data.stateId;
          this.createdBy = (data.data.createdBy == null || data.data.createdBy == undefined) ? '' : data.data.createdBy;
          this.createdDate = (data.data.createdDate == null || data.data.createdDate == undefined) ? '' : data.data.createdDate;
          this.VendorNotes = (data.data.vendorNotes == null || data.data.vendorNotes == undefined) ? '' : data.data.vendorNotes;
          this.VendorHash = (data.data.vendorHash == null || data.data.vendorHash == undefined) ? '' : data.data.vendorHash;
          this.Fax = (data.data.fax == null || data.data.fax == undefined) ? '' : data.data.fax;
          this.website = (data.data.website == null || data.data.website == undefined) ? '' : data.data.website;
          this.extension = (data.data.extension == null || data.data.extension == undefined) ? '' : data.data.extension;
          this.account = (data.data.account == null || data.data.account == undefined) ? '' : data.data.account;
          this.AdditionalDiscount = (data.data.discount == null || data.data.discount == undefined) ? '' : data.data.discount;
          this.selectedCarrierValue = data.data.carrierId == null ? '' : data.data.carrierId;
          this.selectedtermsValue = data.data.termsId == null ? '' : data.data.termsId;
          this.selectedshippingValue = data.data.shippingId == null ? '' : data.data.shippingId;
          this.selectedFobPointValue = data.data.fobPointId == null ? '' : data.data.fobPointId;
          this.countrySelected = data.data.countryId == null ? '' : data.data.countryId;
          this.poEmail = data.data.poEmail;
          //this.getAllState(data.data.countryId);
          this.stateData.filter( x => x.countryId == data.data.countryId);
        }
        else {
          this.TCreateUpdate = 1;
          this.SearchControl = false;
          this.router.navigate(['/vendors/' + 0], { skipLocationChange: true });
          this.resetvendorData();
        }
      });
  }

  ChangeTCreateUpdate(event) {
    this.TCreateUpdate = event.target.value;
    if (this.TCreateUpdate == "1") {
      this.SearchControl = false;
      this.ListShow = false;
      this.ListDiv = false;
      this.clearbutton = true;
      this.buttonValue = this.constants.Save;
      this.resetvendorData();
    }
    else if (this.TCreateUpdate == "0") {
      this.SearchControl = true;
      this.vendorSearch = "";
    }
  }

  changeState(event) {
    this.stateId = event;
  }

  changeTerms(event) {
    this.termsId = event;
  }
  changeShipping(event) {
    this.shippingId = event;
  }
  changeCarrier(event) {
    this.carrierId = event;
  }

  ListDivShowHide() {
    if (this.ListDiv == true) {
      this.ListDiv = false;
      this.ListAngle = "down";
    }
    else if (this.ListDiv == false) {
      this.ListDiv = true;
      this.ListAngle = "up";
    }
  }

  searchVendor() {
    this.SearchType = false;
    if (!this.vendorSearch || this.value_search == 0) {
      this.SearchValidation = true;
      return;
    }
    this.GetAllVendor(this.value_search, this.vendorSearch);
  }

  AllVendor() {
    this.SearchType = true;
    if (this.value_search == 0) {
      this.SearchValidation = true;
      return;
    }
    // this.SearchValidation = false;
    this.GetAllVendor(this.value_search, this.vendorSearch == undefined ? "" : this.vendorSearch);
  }

  GetAllVendor(companySearch, searchTerm) {
    if(companySearch>0){
      this.loader = true;
      this.vendorService.GetAllVendor(companySearch, searchTerm).subscribe(
        data => {
          this.ListShow = true;
          this.ListDiv = true;
          this.ListAngle = "up";
          this.data = data;
          this.vendorList = this.data.dataList;
          this.loader = false;
          this.rerender();
        }
      )
    }
    else{
      this.ErrorModal('Error', 'Please select company.')
    }
  }

  GetAllCountryDetails() {

    this.sharedService.GetCountry().subscribe(
      data => {
        this.data = data;
        this.countrList = this.data.dataList;
        this.getAllTerms();
      }
    )
  }
  getAllState(countryId) {
    if (countryId == undefined || countryId == null) {
      countryId = 0;
    }
    this.sharedService.getAllState(countryId).subscribe(
      data => {
        this.data = data;
        this.stateData = this.data.dataList;


      });
  }
  getAllTerms() {
    this.vendorService.getAllTerms().subscribe(
      data => {
        this.data = data;
        this.termsData = this.data.dataList;
        this.getAllShippings();
      });
  }
  getAllShippings() {
    this.vendorService.getAllShippings().subscribe(
      data => {
        this.data = data;
        this.shippingData = this.data.dataList;
        this.getAllCarrier();
      });
  }
  getAllCarrier() {
    this.vendorService.getAllCarrier().subscribe(
      data => {
        this.data = data;
        this.carrierData = this.data.dataList;
        // this.GetAllVendor(this.companyId);
        this.FobPointDropdownList();
        //this.getAllState(this.countrySelected);
        this.stateData.filter( x => x.countryId == this.countrySelected);
      });
  }

  addNewUser() {
    this.validation = true;
    this.clearbutton = true;
    this.getCompanyValue();
    if (
      this.form.controls.vendorName.status === this.constants.Invalid ||
      this.form.controls.email.status === this.constants.Invalid ||
      //this.form.controls.stateId.status === this.constants.Invalid ||
      this.value === 0
    ) {
      return;
    }

    const obj = {};
    obj['firstName'] = this.firstName;
    obj['lastName'] = this.lastName;
    obj['vendorName'] = this.vendorName;
    obj['addressLine1'] = this.addressLine1;
    obj['addressLine2'] = this.addressLine2;
    obj['city'] = this.city;
    obj['zipCode'] = this.zipCode;
    obj['phoneNumber'] = this.phoneNumber;
    obj['email'] = this.email;
    obj['descriptions'] = this.descriptions;
    obj['stateId'] = parseInt(this.stateId);
    obj['carrierId'] = parseInt(this.carrierId);
    obj['termsId'] = parseInt(this.termsId);
    obj['shippingId'] = parseInt(this.shippingId);
    obj['account'] = this.account;
    obj['Website'] = this.website;
    obj['extension'] = this.extension;
    obj['companyId'] = this.value;
    obj['vendorNotes'] = this.VendorNotes;
    obj['createdBy'] = this.createdBy == "" ? null : this.createdBy
    obj['createdDate'] = this.createdDate;
    obj['VendorHash'] = this.VendorHash;
    obj['Fax'] = this.Fax;
    obj['discount'] = Number(this.AdditionalDiscount);
    obj['FobPointId'] = parseInt(this.selectedFobPointValue);
    obj["CountryId"] = parseInt(this.countrySelected);
    obj["POEmail"] = this.poEmail;
    obj["FreeShipping"] = parseFloat(this.FreeShipping);
    this.loader = true;
    if (this.id != undefined && !Number.isNaN(this.id) && this.id != 0) {
      obj['Id'] = this.id;
      obj['ModifiedBy'] = this.userId;
      obj['CreatedDate'] = this.createdDate;
      this.vendorService.updateVendor(obj)
        .subscribe(
          data => {
            switch (data.statusCode) {
              case 200:
                this.buttonValue = this.constants.Save;
                this.validation = false;
                $("#add_update").show();
                this.isSaveOrUpdate=true;
                // this.toastr.success(this.constants.RecordUpdateSuccessfully);
                this.id = null;
                // this.GetAllVendor(this.companyId);
                this.TCreateUpdate = 1;
                this.SearchControl = false;
                this.ListShow = true;
                this.ListDiv = true;
                this.resetvendorData();
                this.rerender();
                break;
              case 400:
                $("#wrong_msg").show();
                // this.toastr.error(this.constants.SomethingWentWrong);
                break;
              case 208:
                $("#modalExist").show();
                // this.toastr.error(this.constants.SomethingWentWrong);
                break;
              default:
                $("#wrong_msg").show();
              // this.toastr.error(this.constants.SomethingWentWrong);
            }
            this.loader = false;
          }
        );
    }
    else {
      obj['CreatedBy'] = this.userId;
      this.vendorService.addvendor(obj)
        .subscribe(
          data => {
            switch (data.statusCode) {
              case 200:
                $("#add_success").show();
                // this.toastr.success(this.constants.RecordAddedSuccessfully);
                this.validation = false;
                this.isSaveOrUpdate=true;
                this.TCreateUpdate = 1;
                this.SearchControl = false;
                this.ListShow = false;
                this.ListDiv = false;
                this.resetvendorData();
                // this.GetAllVendor(this.companyId);
                this.rerender();

                break;
              case 400:
                $("#wrong_msg").show();
                // this.toastr.error(this.constants.SomethingWentWrong);
                break;
              case 208:
                $("#modalExist").show();
                // this.toastr.error(this.constants.SomethingWentWrong);
                break;
              default:
                $("#wrong_msg").show();
              // this.toastr.error(this.constants.SomethingWentWrong);
            }
            this.loader = false;
          }
        );
    }
  }

  keyPressNumbers(event) {
    var character = String.fromCharCode(event.keyCode);
    if(this.AdditionalDiscount == undefined){
      this.AdditionalDiscount = "";
    }
    var newValue = this.AdditionalDiscount + character;
    if(newValue != "."){
      if (isNaN(Number(newValue)) || this.hasDecimalPlace(newValue, 3)) {
        event.preventDefault();
        return false;
      }
    }
 
  } 

  hasDecimalPlace(value, x) {
    var pointIndex = value.indexOf('.');
    return  pointIndex >= 0 && pointIndex < value.length - x;
  }
  viewUserDetails(item: any) {
    this.vendorService.GetVendorById(item.id).subscribe(
      (data: any) => {
        this.data = data.data;
      });
  }

  deletePopup(item, content) {
    this.modalService.open(content, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
    this.deleteDetail = 'Are you sure, you want to delete?';
    this.deletedItem = item;
  }


  deleteVendor(item: any) {

    this.loader = true;
    this.vendorService.deleteVendor(item.id).subscribe(
      data => {
        this.data = data;
        switch (data.statusCode) {
          case 200:
            $("#delete_success").show();
            // this.toastr.success(this.constants.RecordDeletedSuccessfully);
            this.GetAllVendor(this.value_search, this.vendorSearch == "" ? "" : this.vendorSearch);
            this.rerender();
            break;
          case 400:
            $("#wrong_msg").show();
            // this.toastr.error(this.constants.SomethingWentWrong);
            break;
          default:
            $("#wrong_msg").show();
          // this.toastr.error(this.constants.SomethingWentWrong);
        }
        this.loader = false;
      });
  }

  getAllCompany(companyId) {
    this.companyService.GetDropdownCompanyHierarchyModel(this.companyId).subscribe(
      data => {
        this.data = data;
        this.items = this.data.dataList;
        this.companyList = this.data.dataList;
        // this.companyList.forEach((x) => {
        //   this.userGroupObjects.push(new TreeviewItem(x, false));
        // });
        this.value = Number(companyId);
        //this.getAllState();
      });
  }

  getAllCompany_search(companyId) {
    this.companyService.GetDropdownCompanyHierarchyModel(this.companyId).subscribe(
      data => {
        this.data = data;
        this.items_search = this.data.dataList;
        this.companyList_search = this.data.dataList;
        // this.companyList_search.forEach((x) => {
        //   this.userGroupObjects_search.push(new TreeviewItem(x, false));
        // });
        this.value_search = Number(companyId);
        this.sharedService.VendorLoadedComponent.subscribe( value => {
          if (value != 0 && value != undefined)
          {   
 
           this.route.params.subscribe(
             (params) => {
               if (params.id){
                 this.GetValue(value);
               }
             }
           );
           // if (this.route.snapshot.params['id']){
           //   this.GetValue(value);
           // }  
          
          }
 
          });
        //this.getAllState();
      });
  }

  toggleEdit(item: any) {
    this.ListDiv = false;
    this.ListAngle = "down";
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.buttonValue = this.constants.Update;
    this.clearbutton = false;
    this.vendorService.GetVendorById(item.id).subscribe(
      (data: any) => {
        this.id = item.id;
        this.firstName = (data.data.firstName == null || data.data.firstName == undefined) ? '' : data.data.firstName;
        this.lastName = (data.data.lastName == null || data.data.lastName == undefined) ? '' : data.data.lastName;
        this.vendorName = data.data.vendorName;
        this.phoneNumber = (data.data.phoneNumber == null || data.data.phoneNumber == undefined) ? '' : data.data.phoneNumber;
        this.email = (data.data.email == null || data.data.email == undefined) ? '' : data.data.email;
        this.addressLine1 = (data.data.addressLine1 == null || data.data.addressLine1 == undefined) ? '' : data.data.addressLine1;
        this.addressLine2 = (data.data.addressLine2 == null || data.data.addressLine2 == undefined) ? '' : data.data.addressLine2;
        this.city = (data.data.city == null || data.data.city == undefined) ? '' : data.data.city;
        this.zipCode = (data.data.zipCode == null || data.data.zipCode == undefined) ? '' : data.data.zipCode;
        this.descriptions = (data.data.descriptions == null || data.data.descriptions == undefined) ? '' : data.data.descriptions;
        this.value = data.data.companyId;
        this.selectedStateValue = data.data.stateId == null ? '' : data.data.stateId;
        this.createdBy = (data.data.createdBy == null || data.data.createdBy == undefined) ? '' : data.data.createdBy;
        this.createdDate = (data.data.createdDate == null || data.data.createdDate == undefined) ? '' : data.data.createdDate;
        this.VendorNotes = (data.data.vendorNotes == null || data.data.vendorNotes == undefined) ? '' : data.data.vendorNotes;
        this.VendorHash = (data.data.vendorHash == null || data.data.vendorHash == undefined) ? '' : data.data.vendorHash;
        this.Fax = (data.data.fax == null || data.data.fax == undefined) ? '' : data.data.fax;
        this.website = (data.data.website == null || data.data.website == undefined) ? '' : data.data.website;
        this.extension = (data.data.extension == null || data.data.extension == undefined) ? '' : data.data.extension;
        this.account = (data.data.account == null || data.data.account == undefined) ? '' : data.data.account;
        this.AdditionalDiscount = (data.data.discount == null || data.data.discount == undefined) ? '' : data.data.discount;
        this.selectedCarrierValue = data.data.carrierId == null ? '' : data.data.carrierId;
        this.selectedtermsValue = data.data.termsId == null ? '' : data.data.termsId;
        this.selectedshippingValue = data.data.shippingId == null ? '' : data.data.shippingId;
        this.selectedFobPointValue = data.data.fobPointId == null ? '' : data.data.fobPointId;
        this.countrySelected = data.data.countryId == null ? '' : data.data.countryId;
        this.poEmail = data.data.poEmail;
        this.FreeShipping = data.data.freeShipping;
        //this.getAllState(data.data.countryId);
        this.stateData.filter( x => x.countryId == data.data.countryId);
      });
  }


  resetvendorData() {
    this.FreeShipping = '';
    this.buttonValue = this.constants.Save;
    this.firstName = '';
    this.lastName = '';
    this.vendorName = '';
    this.phoneNumber = '';
    this.email = '';
    this.addressLine1 = '';
    this.addressLine2 = '';
    this.city = '';
    this.zipCode = '';
    this.descriptions = '';
    this.selectedStateValue = '';
    this.value = 0;
    this.id = 0;
    this.VendorNotes = '';
    this.VendorHash = '';
    this.Fax = '';
    this.website = '';
    this.account = '';
    this.selectedCarrierValue = '';
    this.selectedtermsValue = '';
    this.selectedshippingValue = '';
    this.extension = '';
    if (this.companyId > 0) {
      this.value = Number(this.companyId);
    }
    else {
      this.value = 0;
    }
    this.poEmail = '';
    this.AdditionalDiscount = ''
  }

  onValueChange(value: any): void {
    this.value = value;
  }

  onValueChange_search(value: any): void {
    this.value_search = value;
  }

  public isValidFlg: boolean = true;

  validatePhoneNo(field) {
    var phoneNumDigits = field.value.replace(/\D/g, '');
    this.isValidFlg = (phoneNumDigits.length == 0 || phoneNumDigits.length == 10);
    var formattedNumber = phoneNumDigits;
    if (phoneNumDigits.length >= 6)
      formattedNumber = '(' + phoneNumDigits.substring(0, 3) + ') ' + phoneNumDigits.substring(3, 6) + '-' + phoneNumDigits.substring(6, 10);
    else if (phoneNumDigits.length >= 3)
      formattedNumber = '(' + phoneNumDigits.substring(0, 3) + ') ' + phoneNumDigits.substring(3);
    field.value = formattedNumber;
    this.phoneNumber = field.value;
  }
 
  validateFax(field) {
    var phoneNumDigits = field.value.replace(/\D/g, '');
    this.isValidFlg = (phoneNumDigits.length == 0 || phoneNumDigits.length == 10);
    var formattedNumber = phoneNumDigits;
    if (phoneNumDigits.length >= 6)
      formattedNumber = '(' + phoneNumDigits.substring(0, 3) + ') ' + phoneNumDigits.substring(3, 6) + '-' + phoneNumDigits.substring(6, 10);
    else if (phoneNumDigits.length >= 3)
      formattedNumber = '(' + phoneNumDigits.substring(0, 3) + ') ' + phoneNumDigits.substring(3);
    field.value = formattedNumber;
    this.Fax = field.value;
  }
  validateZipcode(field) {
    var zipDigits = this.zipCode;
    this.isValidFlg = (zipDigits?.length == 0 || zipDigits?.length == 10);
    if (zipDigits?.length >= 5)
      field.value = zipDigits.substring(0, 5) + "-" + zipDigits.substring(6, 10);;

    this.zipCode = field.value;
  }


  SetSettingCSS(settingCSS, logoImagePath) {
    if (settingCSS != null) {
      let headerCss = document.getElementsByClassName(this.constants.CssHealerClass) as HTMLCollectionOf<HTMLElement>;
      if (headerCss.length != 0) {
        headerCss[0].style.backgroundColor = settingCSS.split(',')[0];
      }
      let saveButtonCss = document.getElementsByClassName(this.constants.CssSaveBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (saveButtonCss.length != 0) {
        saveButtonCss[0].style.backgroundColor = settingCSS.split(',')[1];
        $(".blue-bg").css("background", settingCSS.split(',')[1]);
      }
      let cencelButtonCss = document.getElementsByClassName(this.constants.CssCancelBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (cencelButtonCss.length != 0) {
        cencelButtonCss[0].style.backgroundColor = settingCSS.split(',')[2];
        $(".darkred-bg").css("background", settingCSS.split(',')[2]);
      }
    }


    if (logoImagePath != null && logoImagePath != "") {
      (document.getElementById('hrdlogoImage') as HTMLImageElement).src = logoImagePath;
    }
  }

  Vendorback() {
    this.clearbutton = true;
    this.id = null;
    this.buttonValue = this.constants.Save;
    this.TCreateUpdate = 1;
    this.SearchControl = false;
    this.ListShow = false;
    this.ListDiv = false;
    this.resetvendorData();
  }

  FobPointDropdownList() {

    this.partService.FobPointsDropdownList().subscribe(
      data => {
        this.data = data;
        this.FobPoint = this.data.dataList;
      }
    )
  }

  changeCountry(event) {
    this.countrySelected = event.target.value;
    //this.getAllState(event.target.value);
    this.stateData.filter( x => x.countryId == event.target.value);
    this.selectedStateValue = '';
  }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next('');
    });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next('');
    this.settingDetailCSS = localStorage.getItem("settingDetailCSS");
    this.settingLogoImage = localStorage.getItem("settingImageLogo");
    this.SetSettingCSS(this.settingDetailCSS, this.settingLogoImage);
  }


  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  addsuccess() {
    $("#add_success").hide();
  }
  updateitem() {
    $("#add_update").hide();
  }
  deleteitem() {
    $("#delete_success").hide();
  }
  msgitem() {
    $("#wrong_msg").hide();
  }
  closeExist() {
    $("#modalExist").hide();
  }

  //ankit implemenation
  ErrorModal(title:string, message:string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }
}

