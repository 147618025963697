<!-- <a [href]="href" class="mt-5">
    Pay Now
</a> -->

<div class="container p-0">
    <div class="card px-4">
        <p class="h8 py-3">Payment Details</p>
        <form name="helcimForm" id="helcimForm">
            <div class="row gx-3">
                <div>
                    <div id="helcimResults"></div>

                    <input type="hidden" id="token" value="04532a54d6f9b9b8436a54">
                    <input type="hidden" id="language" value="en">
                    <!-- <input type="hidden" id="test" value="1"> -->
                </div>
                <div class="col-12">
                    <div class="d-flex flex-column">
                        <input id="amount" class="form-control mb-3" type="text" placeholder="Amount" [disabled]="true">
                        <!-- <input type="hidden" id="amountHash" value="c0fbd50bf08ced29ea91930296107be8590294009919f92aa9f0e52eafc14bfc"> -->
                    </div>
                    
                </div>
                <div class="col-12">
                    <div class="d-flex flex-column">
                        <input id="cardHolderName" class="form-control mb-3" type="text" placeholder="Name">
                    </div>
                </div>
                <div class="col-8">
                    <div class="d-flex flex-column">
                        <input id="cardHolderAddress" class="form-control mb-3" type="text"
                            placeholder="Street Address">
                    </div>
                </div>
                <div class="col-4">
                    <div class="d-flex flex-column">
                        <input id="cardHolderPostalCode" class="form-control mb-3" type="text"
                            placeholder="Postal Code">
                    </div>
                </div>
                <div class="col-12">
                    <div class="d-flex flex-column">
                        <input id="cardNumber" class="form-control mb-3" type="text" placeholder="1234 5678 435678" maxlength="18">
                    </div>
                </div>
                <div class="col-3">
                    <div class="d-flex flex-column">
                        <input id="cardExpiryMonth" class="form-control mb-3" type="text" maxlength="2" placeholder="MM">
                    </div>
                </div>
                <div class="col-3">
                    <div class="d-flex flex-column">
                        <input id="cardExpiryYear" class="form-control mb-3" type="text" maxlength="2" placeholder="YY">
                    </div>
                </div>
                <div class="col-6">
                    <div class="d-flex flex-column">
                        <input id="cardCVV" class="form-control mb-3 pt-2 " type="text" maxlength="4" placeholder="123">
                    </div>
                </div>
                <div class="col-12">
                    <div class="btn btn-primary mb-3" onclick="javascript:helcimProcess();">
                        <span class="text-center">Process Payment</span>
                        <!-- <span class="fas fa-arrow-right"></span> -->
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>