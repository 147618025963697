<section class="content-section pt-2">
    <div class="loading" *ngIf="loader">Loading&#8230;</div>
    <div col-sm-12>
        <div class="row">
            <div class="col-6 mb-2 d-flex align-items-baseline text-xs-left">
                <div class="page-title">
                    <div class="bread-crumb">
                        <a [routerLink]="['/adminDashboard/']">Home</a>
                        <span class="mx-1">-</span>
                        <span class="active">User Management</span>
                        <div *ngIf="permissionMessage!=''" class='btn-danger text-center'>{{permissionMessage}} </div>
                    </div>
                </div>
            </div>
            <div class="col-6 mb-2 text-right align-items-baseline">

            </div>
        </div>
    </div>


    <div class="row">
        <div class="col-md-12">

            <div class="white-box1">
                <div class="card-desc1">
                    <form [formGroup]="formSearch">
                        <div class="row detailSection">
                            <div class="col-md-12 container">
                                <div class="row mt-3">
                                    <div class="col-lg-2 col-md-6">
                                        <div class="d-flex d-flex align-items-center">
                                            <label class="customLabel mr-1" for="Type">Type</label>
                                            <select class="customInput pl-1px" (change)="ChangeTCreateUpdate($event)"
                                                [(ngModel)]="TCreateUpdate" id="TCreateUpdate"
                                                formControlName="TCreateUpdate">
                                                <option value="1">Create</option>
                                                <option value="0">Update</option>
                                            </select>
                                        </div>
                                    </div>

                                    <!-- <div class="col-lg-3 col-md-6 mt-md-0 mt-1"
                                        [style.display]="SearchControl==true ? 'block' : 'none'">
                                        <div class="d-flex align-items-center required borderNone"
                                            [ngClass]="((value_search==0) && (SearchValidation)) ? 'validation_border': ''">
                                            <label class="customLabel mr-1" for="Type">Company</label>
                                            <ngx-dropdown-treeview-select [config]="config"
                                                class="dropdown-company customInput-dropdown"
                                                [items]="userGroupObjects_search" [(value)]="value_search"
                                                (valueChange)="onValueChange_search($event)">
                                            </ngx-dropdown-treeview-select>
                                        </div>
                                    </div> -->
                                    <div class="col-lg-5 col-md-12 mt-lg-0 mt-1 mb-1"
                                        [style.display]="SearchControl==true ? 'block' : 'none'">

                                        <div class="d-flex align-items-center">
                                            <label class="customLabel mr-1 ws-nobreak" for="User Name">User Name</label>
                                            <input type="text" placeholder="Search User Name" class="customInput"
                                                [(ngModel)]="userSearch" formControlName="userSearch" autocomplete="off"
                                                required
                                                [ngClass]="((!userSearch && SearchType==false) && (SearchValidation)) ? 'validation_border': ''" (keyup.enter)="searchUser()">
                                            <!-- <div *ngIf="((!userSearch && SearchType==false) && (SearchValidation))"
                                                class="text-danger input-error err-fontSize header_error"
                                                style="">
                                                Enter User Name.</div> -->
                                            <i class="fas fa-search cpointer ml-3 mr-4 cpointer"
                                                (click)="searchUser()"></i>
                                            <button type="button" class="btn blue-bg round-btn m-0" (click)="AllUser()">All
                                                Users</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <ng-container *ngIf="rolePermissionsList && rolePermissionsList[0].isView">
                <div class="white-box1" [style.display]="ListShow==true ? 'block' : 'none'">
                    <div class="white-box-title1">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="customHeading mt-3">
                                    User List<i class="fa fa-angle-{{ListAngle}} cpointer ml-2" aria-hidden="true"
                                        (click)="ListDivShowHide()"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-desc1 mt-2" [style.display]="ListDiv==true ? 'block' : 'none'">
                        <div class="table-responsive">
                            <table class="row-border hover table-responsive" datatable [dtOptions]="dtOptions"
                                [dtTrigger]="dtTrigger">
                                <thead>
                                    <tr>
                                        <th style="display:none"></th>
                                        <th>User Name</th>
                                        <th>User Role</th>
                                        <th>Company Name</th>
                                        <th>Phone No</th>
                                        <th>Email</th>
                                        <th data-orderable="false">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let data of userList">
                                        <td style="display:none">{{data.id}}</td>
                                        <td>{{data.firstName}} {{data.lastName}} </td>
                                        <td>{{data.roleName}}</td>
                                        <td>{{data.companyName}}</td>
                                        <td>{{data.phoneNumber}}</td>
                                        <td>{{data.email}}</td>
                                        <td>
                                            <a class="blue-text"
                                                *ngIf="rolePermissionsList && rolePermissionsList[0].isView"><i
                                                    class="fas fa-eye" data-toggle="modal" data-target="#addhierarchy"
                                                    (click)="viewUserDetails(data)"></i></a>
                                            <a class="green-text"
                                                *ngIf="rolePermissionsList && rolePermissionsList[0].isUpdate"><i
                                                    class="fas fa-edit" (click)="toggleEdit(data)"></i></a>
                                            <a class="red-text"
                                                *ngIf="rolePermissionsList && rolePermissionsList[0].isDelete"><i
                                                    class="fas fa-trash-alt"
                                                    (click)="deletePopup(data,content)"></i></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </ng-container>
            <div class="white-box1">
                <div class="customHeading mt-3">
                    User Details
                </div>
                <div class="card-desc1">
                    <form [formGroup]="form">
                        <div class="row mt-2">
                                <!-- <div class="col-lg-4 col-md-12">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-2 trp required">
                                            <label class="customLabel">Company</label>
                                        </div>
                                        <div class="col-lg-8 col-md-10 borderNone"
                                            [ngClass]="((value==0) && (validation)) ? 'validation_border': ''">
                                            <ngx-dropdown-treeview-select class="dropdown-company customInput-dropdown"
                                                [config]="config" [items]="userGroupObjects" [(value)]="value"
                                                (valueChange)="onValueChange($event)">
                                            </ngx-dropdown-treeview-select>
                                        </div>
                                    </div>
                                </div> -->
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp required">
                                        <label class="customLabel">Role</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <select class="customInput pl-1px" [(ngModel)]="selectedRoleValue"
                                            (ngModelChange)="changeUserRole($event)"
                                            formControlName='userRole' required
                                            [ngClass]="((!form.controls.userRole.valid) && (validation)) ? 'validation_border': ''">
                                            <option value=''>Select Role</option>
                                            <option *ngFor="let item of userRoleList" [value]="item.id">{{item.name}}
                                            </option>
                                        </select>
                                        <!-- <div *ngIf="((!form.controls.userRole.valid) && (validation))"
                                            class="text-danger input-error">
                                            Select Role.</div> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp required">
                                        <label class="customLabel">Skill Level Required</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <select class="customInput pl-1px" [(ngModel)]="skillLevel" required
                                            formControlName='skillLevel'>
                                            <option value='0'>No Skill Required</option>
                                            <option *ngFor="let item of skillLevelList" [value]="item.id">
                                                {{item.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-lg-4 col-md-12">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp required">
                                        <label class="customLabel">First Name </label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput CharacterWithSpace"
                                            [(ngModel)]="firstName" required placeholder="Enter First Name" name="fname"
                                            formControlName='firstName' autocomplete="none|false|no" required
                                            maxlength="50"
                                            [ngClass]="((!form.controls.firstName.valid) && (validation)) ? 'validation_border': ''">
                                        <!-- <div *ngIf="((!form.controls.firstName.valid) && (validation))"
                                            class="text-danger input-error">
                                            Enter First Name.</div> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp required">
                                        <label class="customLabel">Last Name</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput Character" [(ngModel)]="lastName" required
                                            placeholder="Enter Last Name" name="Lname" formControlName='lastName'
                                            autocomplete="none|false|no" required maxlength="50"
                                            [ngClass]="((!form.controls.lastName.valid) && (validation)) ? 'validation_border': ''">
                                        <!-- <div *ngIf="((!form.controls.lastName.valid) && (validation))"
                                            class="text-danger input-error">
                                            Enter Last Name.</div> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp required">
                                        <label class="customLabel">Phone Number</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput PhoneValidation"
                                            (keypress)="validatePhoneNo($event.target)" [(ngModel)]="phoneNumber"
                                            placeholder="Enter Phone Number (xxx) xxx-xxxx" name="phoneNumber"
                                            formControlName='phoneNumber' required autocomplete="none|false|no"
                                            maxlength="14" pattern="^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$"
                                            [ngClass]="((!form.controls.phoneNumber.valid) && (validation)) ? 'validation_border': ''">
                                        <!-- <div *ngIf="((!form.controls.phoneNumber.valid) && (validation))"
                                            class="text-danger input-error">
                                            Enter Valid Phone Number.eg- (xxx) xxx-xxxx</div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-lg-4 col-md-12">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp required">
                                        <label class="customLabel">Email</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput EmailValidation"
                                            [(ngModel)]="contactEmail" placeholder="Enter Email" name="contactEmail"
                                            formControlName='contactEmail' required autocomplete="off"
                                            pattern="[0-9a-zA-Z._%+-]+@[0-9a-zA-Z.-]+\.[a-zA-Z]{2,3}$" maxlength="50"
                                            (keypress)="RemovedEmailMessage($event.target)"
                                            [ngClass]="((!form.controls.contactEmail.valid) && (validation)) ? 'validation_border': ''">
                                        <!-- <div *ngIf="((!form.controls.contactEmail.valid) && (validation))"
                                                class="text-danger input-error">
                                                Enter Valid Email.</div> -->
                                        <div *ngIf="((form.controls.contactEmail.valid) && (recordAlreadyExist))"
                                            class="text-danger input-error">
                                            Email Already Exist.
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-1" *ngIf="updateMode">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp required">
                                        <label class="customLabel">Password</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="password" class="customInput" [(ngModel)]="password"
                                            formControlName='password' required autocomplete="off" maxlength="40"
                                            placeholder="Enter Password"
                                            [ngClass]="((form.controls.password.value==''||form.controls.password.value==undefined) && (validation)) ? 'validation_border': ''">
                                        <!-- <div *ngIf="((form.controls.password.value==''||form.controls.password.value==undefined) && (validation))"
                                            class="text-danger input-error">
                                            Enter password</div> -->
                                        <div class="text-danger input-error"
                                            *ngIf="form.get('password').invalid && (form.get('password').touched || form.get('password').dirty)">
                                            <div *ngIf="form.get('password').errors.minlength">
                                                Password must be at least 8 characters
                                            </div>
                                            <div *ngIf="form.get('password').hasError('passwordStrength')">
                                                {{form.get('password').errors['passwordStrength']}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-1" *ngIf="updateMode">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp required">
                                        <label class="customLabel">Confirm Password</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="password" class="customInput" [(ngModel)]="passworConfirm"
                                            formControlName='passworConfirm' required autocomplete="new-password"
                                            placeholder="Enter Confirm Password" maxlength="40"
                                            [ngClass]="((form.controls.passworConfirm.value==''||form.controls.passworConfirm.value==undefined) && (validation)) ? 'validation_border': ''">
                                        <div class="text-danger input-error">
                                            <!-- <div class="text-danger"
                                                *ngIf="((form.controls.passworConfirm.value==''||form.controls.passworConfirm.value==undefined) && (validation))">
                                                Enter Confirm Password</div> -->
                                            <div *ngIf="((password!= passworConfirm) && (!form.controls.passworConfirm.value =='' || !form.controls.passworConfirm.value == undefined
                                                || !form.controls.passworConfirm.value == null))">
                                                Password not match.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="submit-from text-right mt-1">
                            <button type="button" class="btn blue-bg round-btn" id="saveID"
                                *ngIf="rolePermissionsList && rolePermissionsList[0].isInsert" (click)="addNewUser()"><i
                                    class="fas fa-save" aria-hidden="true"></i> {{buttonValue}}
                            </button>
                            <button type="button" class="btn darkred-bg round-btn" *ngIf="clearbutton==true"
                                (click)="clearDetails()"><i class="fas fa-redo" aria-hidden="true"></i>
                                Clear </button>
                            <button type="button" class="btn darkred-bg round-btn" *ngIf="clearbutton==false" (click)="userBack()"><i
                                    class="fas fa" aria-hidden="true"></i>
                                Exit </button>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-1" *ngIf="!updateMode">
                                <div class="row required">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Password</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="password" class="customInput" [(ngModel)]="password"
                                            formControlName='password' required autocomplete="off" maxlength="40"
                                            placeholder="Enter Password*">
                                        <div *ngIf="((form.controls.password.value==''||form.controls.password.value==undefined) && (validation))"
                                            class="text-danger input-error">
                                            Enter password</div>
                                        <div class="text-danger input-error"
                                            *ngIf="form.get('password').invalid && (form.get('password').touched || form.get('password').dirty)">
                                            <div *ngIf="form.get('password').errors.minlength">
                                                Password must be at least 8 characters
                                            </div>
                                            <div *ngIf="form.get('password').hasError('passwordStrength')">
                                                {{form.get('password').errors['passwordStrength']}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-1" *ngIf="!updateMode">
                                <div class="row required">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Confirm Password</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="password" class="customInput" [(ngModel)]="passworConfirm"
                                            formControlName='passworConfirm' required autocomplete="new-password"
                                            placeholder="Enter Confirm Password*" maxlength="40">
                                        <div class="text-danger input-error">
                                            <div class="text-danger"
                                                *ngIf="((form.controls.passworConfirm.value==''||form.controls.passworConfirm.value==undefined) && (validation))">
                                                Enter Confirm Password</div>
                                            <div *ngIf="((password!= passworConfirm) && (!form.controls.passworConfirm.value =='' || !form.controls.passworConfirm.value == undefined
                                            || !form.controls.passworConfirm.value == null))">
                                                Password not match.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="submit-from text-right" *ngIf="!updateMode">
                                <button type="button" class="btn blue-bg round-btn" (click)="changePassword()"><i class="fas fa-save"
                                        aria-hidden="true"></i> Change Password
                                </button>
                                <button type="button" class="btn darkred-bg round-btn" (click)="clearDetails()"><i class="fas fa-redo"
                                        aria-hidden="true"></i>
                                    Clear </button>
                            </div>
                        </div>

                    </form>
                </div>
            </div>

        </div>
    </div>

</section>
<ng-template #content let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg sweet-heading"></h4>
    <button type="button" class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body">
        <div class="thank-you-pop">
            <img src="./assets/image/popup-alert.png" alt="">
            <h1>Confirmation</h1>
            <p class="mt-1">{{deleteDetail}}</p>
        </div>
    </div>
    <div class="modal-footer sweet-footer text-center">
        <button type="button" class="btn roundBtn green-bg"
            (click)="deleteUser(deletedItem); c('Close click')">Yes</button>
        <button type="button" class="btn roundBtn red-bg" (click)="c('Close click')">No</button>
    </div>
</ng-template>


<div class="modal fade" id="addhierarchy" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog  modal-dialog-centered modal-lg" role="document">
        <div class="modal-content border-none">
            <div class="modal-header info-popup-header p-2">
                <h5 class="modal-title info-popup-title" id="exampleModalLabel">User Details</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="card-desc1">
                    <div class="modal-body bread-crumb">
                        <form [formGroup]="form">
                            <div class="row">
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <label for="" class="customLabel">Company Name</label>
                                        </div>
                                        <div class="col-md-8">
                                            <span>{{value}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <label for="" class="customLabel">Role</label>
                                        </div>
                                        <div class="col-md-8">
                                            <span>{{selectedRoleValue}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-1">
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <label for="" class="customLabel">First Name</label>
                                        </div>
                                        <div class="col-md-8">
                                            <span>{{firstName}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <label for="" class="customLabel">Last Name</label>
                                        </div>
                                        <div class="col-md-8">
                                            <span>{{lastName}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-1">
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <label for="" class="customLabel">Email</label>
                                        </div>
                                        <div class="col-md-8">
                                            <span>{{contactEmail}}</span>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <label for="" class="customLabel">Phone Number</label>
                                        </div>
                                        <div class="col-md-8">
                                            <span>{{phoneNumber}}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            <div class="modal-footer p-1">
                <button type="button" class="btn darkred-bg round-btn" data-dismiss="modal"
                    (click)="closeModal()">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="add_success">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="addsuccess()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Added Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="addsuccess()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="password_strength">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="addPasswordStrength()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Thank You!</h1>
                    <p>{{passwordLength}}</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="addPasswordStrength()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="add_update">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="updateitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Update Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="updateitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="delete_success">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="deleteitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Deleted Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="deleteitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="pwd_success">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="updatepwd()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Password Change Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="updatepwd()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="wrong_msg">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="msgitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>Something went wrong</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="msgitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>