<section class="content-section pt-2" id="new-layout">
    <div class="loading" *ngIf="loader">Loading&#8230;</div>
    <div col-sm-12>
        <div class="row">
            <div class="col-6 mb-2 d-flex align-items-baseline text-xs-left">
                <div class="page-title">
                    <div class="bread-crumb">
                        <a [routerLink]="['/adminDashboard/']">Home</a>
                        <span class="mx-1">-</span>
                        <span class="active">{{listHeading}}</span>
                         <div *ngIf="permissionMessage!=''" class='btn-danger text-center'>{{permissionMessage}} </div>
                    </div>
                </div>
            </div>
            <div class="col-6 mb-2 text-right align-items-baseline">

            </div>
        </div>
    </div>



    <div class="row">
        <div class="col-md-12">
            <div class="white-box1">
                <div class="white-box-title1">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="customHeading mt-3">
                                {{listHeading}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-desc1 mt-2" *ngIf="isPoList==true">
                    <div class="table-responsive">
                        <table class="row-border hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                            <thead>
                                <tr>
                                    <!-- <th data-orderable="false" class="coloumn-hide"></th> -->
                                    <th>PO #</th>
                                    <th>Status</th>
                                    <th>Account #</th>
                                    <th>Vendor</th>
                                    <th>Vendor Phone #</th>
                                    <th>Vendor Ext</th>
                                    <th>Date Shipped</th>
                                    <th>Payment Terms</th>
                                    <th>FOB Point</th>
                                    <th>Shipping Terms</th>
                                    <th>Total Cost</th>
                                    <th data-orderable="false"> Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of tableList">
                                    <td>{{data.poNumber}}</td>
                                    <td>{{data.status}}</td>
                                    <td>{{data.accountNo}}</td>
                                    <td>{{data.vendorName}}</td>
                                    <td>{{data.vendorPhone}}</td>
                                    <td>{{data.vendorExt}}</td>
                                    <td>{{data.dateShipped}}</td>
                                    <td>{{data.paymentTerms}}</td>
                                    <td>{{data.fobPoint}}</td>
                                    <td>{{data.shippingTerms}}</td>
                                    <td>{{data.poTotal | currency}}</td>
                                    <td data-orderable="false"><a (click)="redirect(data.poNumber)"> <i
                                                class="fas fa-edit" aria-hidden="true"></i></a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-desc1 mt-2" *ngIf="isPoList==true">
                    <div class="customHeading mt-3">
                        Totals
                    </div>
                    <div class="table-responsive">
                        <table class="display table-bordered table clearfix order-detail mb-0 mt-2">
                            <thead>
                                <tr>
                                    <th style="width: 90%;"></th>
                                    <th>Total Cost</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td><strong>{{poGTotal | currency}}</strong></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-desc1 mt-2" *ngIf="isSoList==true">
                    <div class="table-responsive">
                        <table class="row-border hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                            <thead>
                                <tr>
                                    <th class="coloumn-hide"></th>
                                    <th>SO #</th>
                                    <th>Status</th>
                                    <th>eComm</th>
                                    <th>Contact Name</th>
                                    <th>Customer</th>
                                    <th>Product Name</th>
                                    <th>Order Date</th>
                                    <th>Total Cost</th>
                                    <!-- <th>Quantity</th>
                                    <th>Part Cost</th>
                                    <th>Part Price</th>
                                    <th>Part Price Ext.</th>
                                    <th>Labor Hrs.</th>
                                    <th>Labor Ext.</th>
                                    <th>Part Discount</th>
                                    <th>Labor Discount</th>
                                    <th>Part Sales Tax</th>
                                    <th>Labor Sales Tax</th>
                                    <th>Total</th> -->
                                    <th data-orderable="false"> Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of tableList">
                                    <td class="coloumn-hide">{{data.id}}</td>
                                    <td>{{data.soNo}}</td>
                                    <td>{{data.stat}}</td>
                                    <td>{{data.eOrderNo}}</td>
                                    <td>{{data.contactName}}</td>
                                    <td>{{data.customerName}}</td>
                                    <td>{{data.name == null ? 'Custom Parts' : data.name}}</td>
                                    <td>{{data.orderDate | date: "MMM-dd-yyyy"}}</td>
                                    <td>{{data.totalAmount | currency}}</td>
                                    <!-- <td>{{data.quantity}}</td>
                                    <td>{{data.vendorPartCost | currency}}</td>
                                    <td>{{data.cost | currency}}</td>
                                    <td>{{data.partPriceExt | currency}}</td>
                                    <td>{{data.hours | number}}</td>
                                    <td>{{data.laborExt | currency}}</td>
                                    <td>{{!data.partDisc ? '$0.00' : data.partDisc | currency }}</td>
                                    <td>{{!data.laborDisc ? '$0.00' : data.laborDisc | currency }}</td>
                                    <td>{{!data.partSalesTax ? '$0.00' : data.partSalesTax | currency }}</td>
                                    <td>{{!data.laborSalesTax ? '$0.00' : data.laborSalesTax | currency }}</td>
                                    <td>{{data.total | currency}}</td> -->
                                    <!-- <td data-orderable="false"><a (click)="redirectPartSo(data.soNo, data.orderTypeId)"> <i
                                        class="fas fa-edit" aria-hidden="true"></i></a></td> -->

                                        <td data-orderable="false"><a (click)="redirectPartSo(data.id, data.orderTypeId)"> <i
                                            class="fas fa-edit" aria-hidden="true"></i></a></td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
                <div class="card-desc1 mt-2" *ngIf="isSoList==true">
                    <div class="customHeading mt-3">
                        Totals
                    </div>
                    <div class="table-responsive">
                        <table class="display table-bordered table clearfix order-detail mb-0 mt-2">
                            <thead>
                                <tr>
                                    <!-- <th>Parts Cost</th>
                                    <th>Parts Price</th>
                                    <th>Parts Price Ext</th>
                                    <th>Labor Hrs</th>
                                    <th>Labor Ext</th>
                                    <th>Part Discount</th>
                                    <th>Labor Discount</th>
                                    <th>Parts Sales Tax</th>
                                    <th>Labor Sales Tax</th> -->
                                    <th style="width: 90%;"></th>
                                    <th>Total Cost</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <!-- <td>{{totalPartCost | currency}}</td>
                                    <td>{{totalPartPrice | currency}}</td>
                                    <td>{{totalPartPriceExt | currency}}</td>
                                    <td>{{totalLaborHrs | number}}</td>
                                    <td>{{totalLaborExt | currency}}</td>
                                    <td>{{totalPartDisc | currency }}</td>
                                    <td>{{totalLaborDisc | currency }}</td>
                                    <td>{{totalPartSaleTax | currency }}</td>
                                    <td>{{totalLaborSaleTax | currency }}</td> -->
                                    <td>&nbsp;</td>
                                    <td><strong>{{gTotal | currency}}</strong></td>
                                </tr>
                                <!-- <tr>
                                    <td colspan="8"></td>
                                    <td><b>Parts Margin</b></td>
                                    <td><b>{{partsMargin | currency }}</b></td>
                                </tr>
                                <tr>
                                    <td colspan="8"></td>
                                    <td><b>Discount Part</b></td>
                                    <td><b>{{discParts | currency }}</b></td>
                                </tr>
                                <tr>
                                    <td colspan="8"></td>
                                    <td><b>Discount Labor</b></td>
                                    <td><b>{{discLabor | currency }}</b></td>
                                </tr> -->
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="submit-from text-right mt-1 p-0">
        <button class="btn darkred-bg round-btn" (click)="back()">
            <i class="fas fa-sign-out-alt" aria-hidden="true"></i>
            Back </button>
    </div>
</section>