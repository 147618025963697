<header>
  <div class="header-main">
    <div class="header-left d-flex align-items-center ">
      <ul class="nav navbar-nav flex-row ml-15">
        <li class="nav-item d-block">
          <a class="menu-toggle" (click)="tog()"
            ><i class="fas fa-bars text-dark"></i
          ></a>
        </li>
        <li class="nav-item flex-grow-1 site-logo text-left">
           <!-- <img
            src="/assets/image/logo.jpg"
            class="hrdLogoSize"
            id="hrdlogoImage"
          />  -->
          <!-- <span>Logo</span> -->
        </li>
       
      </ul>
    </div>
    <div class="header-right d-block">
      <div class="row align-items-center justify-content-between">
        <div class="toggle-btn">
            <div class="">
             
              <ul class="nav navbar-nav mt-1 logo-nav">
                <!-- <li class="hidden-xs1">
                  <a class="menu-toggle down-sidebar" (click)="tog()"
                    ><i class="fas fa-bars text-light"></i
                  ></a>
                </li> -->
                <li class="nav-item">
                  <img
                   src="/assets/image/logo.png"
                   class="hrdLogoSize"
                   id="hrdlogoImage"
                 /> 
                 <!-- <span class="between-logo">Logo</span> -->
               </li>
                
              </ul>
            </div>
            </div>

            <div class="search-section d-flex mgr-5">
              <form [formGroup]="form" class="order_2">
                <div class="input-group pmd-input-group-filled header-search">
                  <div class="input-group-prepend new-input">
                    <span class="input-group-text p-0 all-dropdown-span" id="filled-ig1">
                      <select
                      class="customInput custom h-100 hidden-xs1"
                      required
                      formControlName="dbType"
                      (change)="changevalue($event)"
                    >
                      <option value="1">All</option>
                      <option
                        *ngFor="let item of dbTypeList"
                        [value]="
                          item.databaseFieldName +
                          '-' +
                          item.labelName +
                          '-' +
                          item.defaultUI +
                          '-' +
                          item.companyId
                        "
                      >
                        {{ item.labelName }}
                      </option>
                    </select>
                    </span>
                  </div>
                  <div class="example-form h-40">
                    <ng-autocomplete
                        #myNameElem
                        [data]="dataAutoComp"
                        placeHolder="“What are you looking for today?”"
                        [searchKeyword]="keyword"
                        (selected)="selectEvent($event)"
                        (inputChanged)="getServerResponse($event)"
                        (inputFocused)="onFocused($event)"
                        [itemTemplate]="itemTemplate"
                        (inputCleared)="searchCleared()"
                        [notFoundTemplate]="notFoundTemplate"
                        [debounceTime]="600"
                        [isLoading]="isLoadingResult"
                        [minQueryLength]="0"
                      >
                      </ng-autocomplete>
                      <ng-template #itemTemplate let-item>
                        <a class="bolds">
                          <div class="row">
                            <div class="col-10">
                              <span
                                [innerHTML]="item.purchaseOrderPrefix"
                                style="
                                  color: #363434;
                                  font-size: 11px;
                                  font-family: 'Roboto', sans-serif !important;
                                "
                              ></span>
                            </div>
                          </div>
                        </a>
                      </ng-template>

                      <ng-template #notFoundTemplate let-notFound>
                        <span
                          style="
                            color: #363434;
                            font-size: 11px;
                            font-family: 'Roboto', sans-serif !important;
                          "
                          >{{ errorMsg }}</span
                        >
                      </ng-template>
                    </div>
                  <div class="input-group-prepend bg-search align-items-center justify-content-center wd-50 border-search ">
                    <span class="material-icons text-dark fw-5">
                      search
                      </span>
                  </div>
                </div>
           

                <div class="form-row hader-search input-group">
                </div>
              </form>
              <!-- Added BY Navneet -->
              <button mat-raised (click)="doEvent()" class="quick-bg d-flex align-items-center quick-ord-btn fs-15 text-light order_1">
                <mat-icon class="mr-1 text-light">add</mat-icon>
              <span class="text-light quick-order-text">  Quick Order</span>
              </button>
              <!-- ----- -->
              <div class="user-profile-section order_3">
                <ul class="nav navbar-nav">
             <li class="dropdown dropdown-user nav-item">
               <a
                 class="dropdown-toggle dropdown-user-link link1 text-right"
                 data-toggle="dropdown"
                 aria-expanded="true"
               >
                 <span class=" user-name text-bold-700 text-light"
                   >{{ userName }}
                   <br />
                   <span class="fs-11 text-light" *ngIf="companyName != null">
                     ({{ companyName }})
                   </span> </span
                 ><span class="avatar avatar-online">
                   <img [src]="this.profileLogo" id="profileLogo" alt="avatar" />
                   <i></i
                 ></span>
               </a>
               <div class="dropdown-menu dropdown-menu-right drpdown1">
                 <a class="dropdown-item" [routerLink]="['/user-management/']"
                   ><i class="ft-user"></i> Edit Profile</a
                 >
                 <div class="dropdown-divider"></div>
                 <a class="dropdown-item" (click)="LogOut()"
                   ><i class="ft-power"></i> Logout</a
                 >
               </div>
             </li>
           </ul>
             </div>
            </div>

          
            <!-- <div class="user-profile-section">
               <ul class="nav navbar-nav">
            <li class="dropdown dropdown-user nav-item">
              <a
                class="dropdown-toggle dropdown-user-link link1 text-right"
                data-toggle="dropdown"
                aria-expanded="true"
              >
                <span class=" user-name text-bold-700 text-light"
                  >{{ userName }}
                  <br />
                  <span class="fs-11 text-light" *ngIf="companyName != null">
                    ({{ companyName }})
                  </span> </span
                ><span class="avatar avatar-online">
                  <img [src]="this.profileLogo" id="profileLogo" alt="avatar" />
                  <i></i
                ></span>
              </a>
              <div class="dropdown-menu dropdown-menu-right drpdown1">
                <a class="dropdown-item" [routerLink]="['/user-management/']"
                  ><i class="ft-user"></i> Edit Profile</a
                >
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" (click)="LogOut()"
                  ><i class="ft-power"></i> Logout</a
                >
              </div>
            </li>
          </ul>
            </div> -->
        </div>

        <div class="col-lg-3 text-right">
         
        </div>
      </div>
    </div>
</header>
