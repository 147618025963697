import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-po-orderdetials-card',
  templateUrl: './po-orderdetials-card.component.html',
  styleUrls: ['./po-orderdetials-card.component.css', '../purchaseorder-details/po-detail/po-detail.component.css']
})
export class PoOrderdetialsCardComponent implements OnInit {
  @Input() formGrp: FormGroup;
  @Input() companyId: number;
  @Input() statuslist: any;
  @Output() historyEvent = new EventEmitter<boolean>();
  @Output() statusEvent = new EventEmitter<any>();

  constructor(){
  }

  ngOnInit(): void {
  }

  showHistoryFlyOut(){
    this.historyEvent.emit();
  }

  onStatusChange(value){
    this.statusEvent.emit(value);
  }
}
