<section class="content-section pt-2">
    <div class="loading" *ngIf="loader">Loading&#8230;</div>

    <div col-sm-12>
        <div class="row">
            <div class="col-6 mb-2 d-flex align-items-baseline text-xs-left">
                <div class="page-title">
                    <div class="bread-crumb">
                        <a [routerLink]="['/adminDashboard/']">Home</a>
                        <span class="mx-1">-</span>
                        <span class="active">Customers</span>
                        <div *ngIf="permissionMessage!=''" class='btn-danger text-center'>{{permissionMessage}} </div>
                    </div>
                </div>
            </div>
            <div class="col-6 mb-2 text-right align-items-baseline">
            </div>
        </div>
    </div>

    <div class="">
        <div class="white-box1 no_bg1">
            <div class="card-desc1">
                <form [formGroup]="formSearch">
                    <div class="row detailSection">
                        <div class="col-md-12 container">
                            <div class="row mt-3">
                                <div class="col-lg-2 col-md-6">
                                    <div class="d-flex align-items-center">
                                        <label class="customLabel mr-1" for="Type">Type</label>
                                        <select class="customInput" (change)="ChangeTCreateUpdate($event)"
                                            [(ngModel)]="TCreateUpdate" id="TCreateUpdate"
                                            formControlName="TCreateUpdate">
                                            <option value="1">Create</option>
                                            <option value="0">Update</option>
                                        </select>
                                    </div>
                                </div>
                                <!-- <div class="col-lg-3 col-md-6 mt-md-0 mt-1"
                                    [style.display]="SearchControl==true ? 'block' : 'none'">
                                    <div class="d-flex align-items-center borderNone">
                                        <label class="customLabel mr-1" for="Type">Company</label>
                                        <ngx-dropdown-treeview-select [config]="config"
                                            class="dropdown-company customInput-dropdown"
                                            [items]="userGroupObjects_search" [(value)]="value_search"
                                            (valueChange)="onValueChange_search($event)">
                                        </ngx-dropdown-treeview-select>
                                    </div>
                                </div> -->
                                <div class="col-lg-5 col-md-12 mt-lg-0 mt-1"
                                    [style.display]="SearchControl==true ? 'block' : 'none'">
                                    <div class="d-flex align-items-center">
                                        <label class="customLabel mr-1 ws-nobreak" for="Customer Name">Customer
                                            Name</label>
                                        <input type="text" placeholder="Search Customer Name" class="customInput"
                                            [(ngModel)]="customerSearch" formControlName="customerSearch"
                                            autocomplete="off" required
                                            [ngClass]="((!customerSearch && SearchType==false) && (SearchValidation)) ? 'validation_border': ''"
                                            (keyup.enter)="searchCustomer()">

                                        <i class="fas fa-search cpointer ml-2 mr-2 cpointer"
                                            (click)="searchCustomer()"></i>
                                        <button type="button" class="btn blue-bg round-btn m-0"
                                            (click)="AllCustomer()">All
                                            Customers</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <ng-container *ngIf="rolePermissionsList && rolePermissionsList[0].isView">
            <div class="white-box1" [style.display]="ListShow==true ? 'block' : 'none'">
                <div class="white-box-title1">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="customHeading mt-3">
                                Customer List<i class="fa fa-angle-{{ListAngle}} cpointer ml-2" aria-hidden="true"
                                    (click)="ListDivShowHide()"></i>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-desc1 mt-2" [style.display]="ListDiv==true ? 'block' : 'none'">

                    <table class="row-border hover table-responsive" id="datatable" datatable [dtOptions]="dtOptions"
                        [dtTrigger]="dtTrigger" style="width: 100%;">
                        <thead>
                            <tr>
                                <th style="display: none;">
                                    Id
                                </th>
                                <th>
                                    Company Name
                                </th>
                                <th>
                                    Customer Name
                                </th>
                                <th>
                                    Parent Customer
                                </th>
                                <th>
                                    Contact Person
                                </th>

                                <th>
                                    Phone No.
                                </th>
                                <th>
                                    User Type
                                </th>

                                <th data-orderable="false">
                                    Action
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of customerList">
                                <td style="display: none;">{{data.id}}</td>
                                <td>{{data.companyName}}</td>
                                <td>{{data.customerName}}</td>
                                <td>{{data.parentCustomer}}</td>
                                <td>{{data.firstname}}</td>
                                <td>{{data.phone}}</td>
                                <td>{{data.customerType}}</td>
                                <td>
                                    <a *ngIf="rolePermissionsList && rolePermissionsList[0].isView" class="blue-text"><i
                                            class="fas fa-eye" data-toggle="modal" data-target="#addhierarchy"
                                            (click)="viewCustomerDetails(data)"></i></a>

                                    <a *ngIf="rolePermissionsList && rolePermissionsList[0].isUpdate" class="green-text"
                                        (click)="GetValue(data.id)"><i class="fas fa-edit"></i></a>
                                    <a *ngIf="rolePermissionsList && rolePermissionsList[0].isDelete"
                                        class="red-text"><i class="fas fa-trash-alt"
                                            (click)="deletePopup(data,content)"></i></a>

                                </td>
                            </tr>

                        </tbody>

                    </table>

                </div>
            </div>
        </ng-container>

        <form [formGroup]="form">

            <!--Customer-->
            <div class="white-box1 no_bg1">
                <div class="customHeading mt-3">
                    Customer Details <span class="ml-3 Guest">
                        <!-- <input type="checkbox" id="isGuests" name="isGuests" [checked]="isGuest"
                            formControlName="isGuest" [(ngModel)]="isGuest" (change)="changeGuestCustomer($event)">
                        <label class="customLabel customH2" for="isGuests">Guest</label> -->

                    </span>
                </div>
                <div class="card-desc1">

                    <div class="row mt-2">

                        <div class="col-lg-4 col-md-12 mt-md-1">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp required">
                                    <label class="customLabel Character">Customer Name</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <input type="text" class="customInput" [(ngModel)]="customerName"
                                        placeholder="Enter Customer Name" name="customerName"
                                        formControlName="customerName" autocomplete="none|false|no" maxlength="50"
                                        required
                                        [ngClass]="((!form.controls.customerName.valid) && (validation)) ? 'validation_border': ''">

                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12 mt-md-1">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp required">
                                    <label class="customLabel">Customer #</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <input type="text" class="customInput" [(ngModel)]="CustomerHash"
                                        placeholder="Enter Customer #" name="CustomerHash"
                                        formControlName="CustomerHash" readonly autocomplete="none|false|no"
                                        maxlength="100" required
                                        [ngClass]="((!form.controls.CustomerHash.valid) && (validation)) ? 'validation_border': ''">
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12 mt-md-1">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp">
                                    <label class="customLabel">Wordpress Id</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <input type="text" class="customInput text-success"
                                        [(ngModel)]="CustomerWordPressId" placeholder="Wordpress Id"
                                        name="CustomerWordPressId" formControlName="CustomerWordPressId" readonly>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp">
                                    <label class="customLabel">Parent Customer</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <app-customer-dropdown [_selectedCustomer]="selectedCustomer"
                                        (_newItemEvent)="onParentCustomerChange($event)"></app-customer-dropdown>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12 mt-md-1">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp">
                                    <label class="customLabel">Phone Number</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <input type="text" class="customInput PhoneValidation"
                                        (keypress)="validatePhoneNo($event.target)" [(ngModel)]="phone"
                                        placeholder="Enter Phone Number (xxx)xxx-xxx" name="phone"
                                        formControlName="phone" autocomplete="disabled" maxlength="14"
                                        pattern="/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/">
                                    <!-- <div *ngIf="((!form.controls.phone.valid) && (validation))"
                                    class="text-danger input-error">
                                    Enter Phone Number.eg-(xxx)xxx-xxx.</div> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12 mt-md-1">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp">
                                    <label class="customLabel">Extension #</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <input type="text" class="customInput" [(ngModel)]="extension"
                                        placeholder="Enter Extension" name="extension" formControlName="extension"
                                        autocomplete="disabled" maxlength="14">
                                    <!-- <div *ngIf="((!form.controls.phone.valid) && (validation))"
                                    class="text-danger input-error">
                                    Enter Phone Number.eg-(xxx)xxx-xxx.</div> -->
                                </div>
                            </div>
                        </div>

                        <!-- <div class="col-lg-4 col-md-12">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp">
                                    <label class="customLabel">Email</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <input type="text" class="customInput EmailValidation" [(ngModel)]="email"
                                        placeholder="Enter Email" name="email" formControlName="email"
                                        autocomplete="off" required  [ngClass]="((!form.controls.email.valid) && (validation)) ? 'validation_border': ''"
                                        pattern="[0-9a-zA-Z._%+-]+@[0-9a-zA-Z.-]+\.[a-zA-Z]{2,3}$" maxlength="50">
                                        <div *ngIf="((form.controls.email.valid) && (isEmailExist))"
                                        class="text-danger input-error">
                                        Email Already Exist</div>
                                </div>
                            </div>
                        </div> -->

                        <div class="col-lg-4 col-md-12 mt-md-1">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp">
                                    <label class="customLabel">Fax #</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <input type="text" class="customInput" (keypress)="validatePhoneNo($event.target)"
                                        [(ngModel)]="Fax" placeholder="Enter Fax (xxx)xxx-xxx" name="Fax"
                                        formControlName="Fax" autocomplete="disabled" required maxlength="14"
                                        pattern="/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/">
                                    <!-- <div *ngIf="((!form.controls.phone.valid) && (validation))"
                                    class="text-danger input-error">
                                    Enter Phone Number.eg-(xxx)xxx-xxx.</div> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12 mt-md-1">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp">
                                    <label class="customLabel">Address Line 1</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <input type="text" class="customInput" [(ngModel)]="addressLine1"
                                        placeholder="Enter Address Line 1" maxlength="100" name="addressLine1"
                                        formControlName="addressLine1" autocomplete="on">
                                    <!-- <div *ngIf="((!form.controls.addressLine1.valid) && (validation))"
                                    class="text-danger input-error">
                                    Enter Address Line 1. </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12 mt-md-1">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp">
                                    <label class="customLabel">Address Line 2</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <input type="text" class="customInput" [(ngModel)]="addressLine2"
                                        placeholder="Enter Address Line 2" maxlength="100" name="addressLine2"
                                        formControlName="addressLine2" autocomplete="disabled">
                                    <!-- <div *ngIf="((!form.controls.addressLine2.valid) && (validation))"
                                    class="text-danger input-error">
                                    Enter Address Line 2. </div> -->
                                </div>
                            </div>
                        </div>


                        <div class="col-lg-4 col-md-12 mt-md-1">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp">
                                    <label class="customLabel">Zip</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <div *ngIf="(countrySelected==231)">
                                        <input type="text" class="customInput NumberOnly" [(ngModel)]="zipCode"
                                            placeholder="Enter Zip xxxxx-xxxx" name="zipCode" pattern="\d{5}-?(\d{4})?"
                                            (keypress)="validateZipcode($event.target)" formControlName="zipCode"
                                            autocomplete="disabled" maxlength="10">
                                        <!-- 
                                        <div *ngIf="((!form.controls.zipCode.value) && (validation))"
                                            class="text-danger input-error">
                                            Enter Zip.eg-xxxxx-xxxx</div> -->
                                        <div *ngIf="((!form.controls.zipCode.valid && form.controls.zipCode.value!='' && form.controls.zipCode.value!=undefined) && (validation))"
                                            class="text-danger input-error">Enter Valid Zip.eg-xxxxx-xxxx</div>
                                    </div>
                                    <div *ngIf="(countrySelected!=231)">
                                        <input type="text" class="customInput" [(ngModel)]="shipZip"
                                            placeholder="Enter Zip xxxxx-xxxx" name="shipZip" formControlName="shipZip"
                                            autocomplete="disabled" maxlength="10" />
                                    </div>


                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12 mt-md-1">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp">
                                    <label class="customLabel">City</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <input type="text" class="customInput" [(ngModel)]="city" placeholder="Enter City"
                                        name="city" formControlName="city" autocomplete="disabled" maxlength="50">
                                    <!-- <div *ngIf="((!form.controls.city.valid) && (validation))"
                                    class="text-danger input-error">
                                    Enter City.</div> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12 mt-md-1">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp">
                                    <label class="customLabel">Country</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <select class="customInput pl-1px" formControlName='countrySelected'
                                        (change)="changeCountry($event)" [(ngModel)]="countrySelected">
                                        <option value=''>Select Country</option>
                                        <option *ngFor="let item of countrList" [value]="item.id">
                                            {{item.name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>


                        <div class="col-lg-4 col-md-12 mt-md-1">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp">
                                    <label class="customLabel">State</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <select class="customInput pl-1px"  (ngModelChange)="changeState($event)" formControlName="stateName" required
                                        [(ngModel)]="selectedStateValue">
                                        <option  value=''> Select State</option>
                                        <option *ngFor="let item of stateData" [value]="item.id">{{item.name}}
                                        </option>
                                    </select>
                                    <!-- <div *ngIf="((!form.controls.stateName.valid) && (validation))"
                                        class="text-danger input-error">
                                        Select State.</div> -->
                                </div>
                                <!-- {{selectedStateValue}} -->
                            </div>
                        </div>
                    </div>

                    <div class="row mt-md-1 mt-0">
                        <div class="col-lg-2 col-md-2 trp">
                            <label class="customLabel padding-r">Notes</label>
                        </div>
                        <div class="col-lg-10 col-md-10">
                            <textarea [(ngModel)]="customerNotes" rows="3" cols="5"
                                class="customInput customerTextarea customerTextarea2" placeholder="Enter Notes"
                                formControlName="customerNotes"></textarea>
                        </div>
                    </div>

                    <div class="row mt-md-1 mt-0">

                        <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp">
                                    <label class="customLabel">Addt'l Discount</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <input type="text" class="customInput Dtextbox" [(ngModel)]="AdditionalDiscount"
                                        name="AdditionalDiscount" formControlName='AdditionalDiscount'
                                        autocomplete="off" (keypress)="keyPressNumbers($event)" maxlength="6">
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-12 mt-lg-0 mt-md-1">
                            <div class="row">
                                <div class="col-lg-12 col-md-12 trp">
                                    <input type="checkbox" id="PartialShipment" name="PartialShipment"
                                        [checked]="PartialShipment" formControlName="PartialShipment"
                                        [(ngModel)]="PartialShipment" class="top">

                                    <label class="customLabel left" for="PartialShipment">Partial Shipments</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6 col-md-12">
                            <div class="row">
                                <div class="col-lg-2 col-md-2 trp">
                                    <label class="customLabel">Customer Role</label>
                                </div>
                                <div class="col-lg-6 col-md-8 d-flex">
                                    <!-- <a type="button" class="text-primary customLabel d-block" (click)="openCustomerRoleModel()">Edit&nbsp;List</a>&nbsp; -->
                                    <!-- <select class="customInput pl-1px" formControlName="customerType" required
                                        [(ngModel)]="selectedCustomerType" (ngModelChange)="changeCustomerType($event)">
                                        <option value=''>Select Customer Role</option>
                                        <option *ngFor="let item of CustomerTypeList" [value]="item.id">
                                            {{item.customerType}}
                                        </option>
                                    </select> -->
                                    <!-- <select class="customInput pl-1px" formControlName="CustomerType" required
                                            [(ngModel)]="selectedCustomerType" (ngModelChange)="changeCustomerType($event)">
                                        <option value=''>Select Image Type</option>
                                        <option *ngFor="let item of CustomerTypeList" [value]="item.id">
                                            {{item.name}}
                                        </option>
                                    </select> -->


                                    <select class="customInput pl-1px" (ngModelChange)="changeRoles($event)"
                                        [(ngModel)]="_CustomerRole" [ngModelOptions]="{standalone: true}">
                                        <option value=null disabled>Select Customer Role</option>
                                        <option *ngFor="let r of RolesList" [value]="r.rolesName">
                                            {{r.rolesName}}
                                        </option>
                                    </select>
                                </div>

                            </div>
                        </div>
                        <!-- For Model to choose the different role class="card-desc1" -->

                        <div class="modal" id="customerRoleModel">
                            <div class="modal-dialog  modal-dialog-centered modal-lg bigmodals" role="document">
                                <div class="modal-content" style="width: 65%; margin: auto;">

                                    <h4 class="modal-title info-popup-title p-1 text-center">Edit Customer Roles
                                    </h4>

                                    <button type="button" class="close btn alert_modal" aria-label="Close"
                                        (click)="userRoleclose()">
                                        <span aria-hidden="true">&times;</span>
                                    </button>

                                    <div class="modal-body">
                                        <div class="card-desc1 mt-1  table-responsive">
                                            <table class="table table-bordered" style="width: 100%;"
                                                id="roleModelTable">
                                                <thead>
                                                    <tr>
                                                        <th>Role Name</th>
                                                        <th>Active (Yes/No)</th>
                                                        <th>Edit Icon</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <ng-container>
                                                        <tr *ngFor="let item of customerEditableData; index as i"
                                                            style="line-height: 2;">
                                                            <td>
                                                                <span *ngIf="!item.enabled">{{item.customerType}}</span>
                                                                <input type="text" class="form-control"
                                                                    formControlName="customerTypeName"
                                                                    [(ngModel)]="customerNameModal"
                                                                    *ngIf="item.enabled" />
                                                            </td>
                                                            <td>
                                                                <span *ngIf="!item.enabled">{{item.isActive == true ?
                                                                    'Yes' : 'No'}}</span>
                                                                <select *ngIf="item.enabled" class="customInput pl-1px"
                                                                    formControlName="CustomerTypeIsActive" required>
                                                                    <option [value]="1"
                                                                        [selected]="item.isActive == true ? true : null">
                                                                        Yes
                                                                    </option>
                                                                    <option [value]="2"
                                                                        [selected]="item.isActive == false ? true : null">
                                                                        No
                                                                    </option>
                                                                </select>
                                                            </td>

                                                            <td><a class="green-text" *ngIf="!item.enabled"
                                                                    (click)="enableEditMethod(item);"><i
                                                                        class="fas fa-edit"></i></a>

                                                                <a class="green-text mx-1" *ngIf="item.enabled"
                                                                    (click)="customerTypeModalSaveBtnClicked(item);"><i
                                                                        class="fas fa-save text-success"
                                                                        aria-hidden="true"></i>
                                                                </a>


                                                                <a class="fw-bold mx-1" *ngIf="item.enabled"
                                                                    (click)="customerTypeModalCancelBtnClicked();"><i
                                                                        class="fa fa-remove text-danger"
                                                                        aria-hidden="true"></i>
                                                                </a>
                                                            </td>
                                                        </tr>
                                                        <tr *ngIf="showCustomerType" style="line-height: 2;">
                                                            <td>
                                                                <input type="text" class="customInput"
                                                                    [(ngModel)]="addCustomerTypeName"
                                                                    placeholder="Enter Role Name" name="extension"
                                                                    formControlName="addCustomerTypeName"
                                                                    autocomplete="disabled"
                                                                    [ngClass]="((!form.controls.addCustomerTypeName.valid) && (validation)) ? 'validation_border': ''">
                                                            </td>

                                                            <td>
                                                                <select class="customInput pl-1px"
                                                                    formControlName="addCustomerTypeIsActive"
                                                                    [(ngModel)]="selectedValueStatus"
                                                                    [ngClass]="((!form.controls.addCustomerTypeIsActive.valid) && (validation)) ? 'validation_border': ''">
                                                                    <option value=''>Select Active</option>
                                                                    <option [value]="1">
                                                                        Yes
                                                                    </option>
                                                                    <option [value]="2">
                                                                        No
                                                                    </option>
                                                                </select>
                                                            </td>

                                                            <td>
                                                                <button class="btn btn-primary btn-customer btn-sm"
                                                                    (click)="addCustomerTypeCancelBtnClicked()">Cancel</button>
                                                                <button class="btn btn-primary btn-customer btn-sm"
                                                                    (click)="addCustomerTypeSaveBtnClicked()">Save</button>
                                                            </td>
                                                        </tr>
                                                    </ng-container>
                                                </tbody>
                                                <tbody>
                                                    <!-- <tr class="c1">
                                                                <td colspan="9" class="no-data-available">No
                                                                    data available in table</td>
                                                            </tr> -->
                                                </tbody>
                                            </table>
                                            <div class="row">
                                                <div class="col-md-12 mb-5">
                                                    <div class="submit-from text-right mt-1">
                                                        <button type="button" class="btn blue-bg round-btn"
                                                            *ngIf="enableCustomerRoleAddBtn"
                                                            (click)="customerModelType()">
                                                            + Add Customer Role
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>



                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <!-- <div class="col-lg-4 col-md-12">
                            <div class="row">
                                <div class="col-lg-4 col-md-2">
                                    <label class="customLabel">Collect Account</label>
                                </div>
                                <div class="col-lg-4 col-md-10">
                                    <input type="text" class="customInput Dtextbox" [(ngModel)]="CollectAmount"
                                        name="CollectAmount" formControlName='CollectAmount' autocomplete="off">
                                </div>


                            </div>
                        </div> -->
                </div>
            </div>
            <!--Customer-->

            <!-- contact information -->
            <div class="white-box1 no_bg1">
                <div class="customHeading mt-3">
                    <div class="row">
                        <div class="col-lg-12 col-md-12">
                            <button class="btn round-btn upload_btn add_img mt-lg-0 mb-2" (click)="openCustomerModal();"
                                [disabled]="isGuestType == true">+ Add Contacts

                            </button>
                        </div>
                    </div>


                    <!-- Contact Information -->
                </div>
                <div class="card-desc1">
                    <div [style.display]="isGuestType == true ? 'block' : 'none'">
                        <div class="row mt-2">
                            <div class="col-lg-4 col-md-12">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Contact First Name</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput" [(ngModel)]="firstName"
                                            placeholder="Enter Contact First Name" name="firstName"
                                            formControlName="firstName" autocomplete="disabled" maxlength="50"
                                            [ngClass]="((!form.controls.firstName.valid) && (validation)) ? 'validation_border': ''">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel Character">Contact Last Name</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput" [(ngModel)]="lastName"
                                            placeholder="Enter Contact Last Name" name="lastName"
                                            formControlName="lastName" autocomplete="none|false|no" maxlength="50"
                                            required
                                            [ngClass]="((!form.controls.lastName.valid) && (validation)) ? 'validation_border': ''">
                                        <!-- <div *ngIf="((!form.controls.lastName.valid) && (validation))"
                                        class="text-danger input-error">
                                        Enter Last Name.</div> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Email</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput EmailValidation"
                                            [(ngModel)]="contactEmail" placeholder="Enter Email" name="contactEmail"
                                            formControlName="contactEmail" autocomplete="off" required
                                            pattern="^[0-9a-zA-Z._%+-]+@[0-9a-zA-Z.-]+\.[a-zA-Z]{2,3}$" maxlength="50"
                                            [ngClass]="((!form.controls.contactEmail.valid) && (validation)) ? 'validation_border': ''">
                                        <div *ngIf="((form.controls.contactEmail.valid) && (isEmailExist))"
                                            class="text-danger input-error">
                                            Guest Email Already Exist</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-lg-4 col-md-12">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Phone Number</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput PhoneValidation"
                                            (keypress)="validatePhoneNo($event.target)" [(ngModel)]="contactPhone"
                                            placeholder="Enter Phone Number (xxx)xxx-xxx" name="contactPhone"
                                            formControlName="contactPhone" autocomplete="disabled" maxlength="14"
                                            pattern="/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/">
                                        <!-- <div *ngIf="((!form.controls.phone.valid) && (validation))"
                                        class="text-danger input-error">
                                        Enter Phone Number.eg-(xxx)xxx-xxx.</div> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Extension #</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput" [(ngModel)]="contactExtension"
                                            placeholder="Enter Extension" name="contactExtension"
                                            formControlName="contactExtension" autocomplete="disabled" maxlength="14">
                                        <!-- <div *ngIf="((!form.controls.phone.valid) && (validation))"
                                        class="text-danger input-error">
                                        Enter Phone Number.eg-(xxx)xxx-xxx.</div> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Fax #</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput"
                                            (keypress)="validatePhoneNo($event.target)" [(ngModel)]="contactFax"
                                            placeholder="Enter Fax (xxx)xxx-xxx" name="contactFax"
                                            formControlName="contactFax" autocomplete="disabled" required maxlength="14"
                                            pattern="/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/">
                                        <!-- <div *ngIf="((!form.controls.phone.valid) && (validation))"
                                        class="text-danger input-error">
                                        Enter Phone Number.eg-(xxx)xxx-xxx.</div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-lg-4 col-md-12">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Address Line 1</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput" [(ngModel)]="contactAddressLine1"
                                            placeholder="Enter Address Line 1 " maxlength="100"
                                            name="contactAddressLine1" formControlName="contactAddressLine1"
                                            autocomplete="disabled">
                                        <!-- <div *ngIf="((!form.controls.addressLine1.valid) && (validation))"
                                        class="text-danger input-error">
                                        Enter Address Line 1. </div> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Address Line 2</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput" [(ngModel)]="contactAddressLine2"
                                            placeholder="Enter Address Line 2 " maxlength="100"
                                            name="contactAddressLine2" formControlName="contactAddressLine2"
                                            autocomplete="disabled">
                                        <!-- <div *ngIf="((!form.controls.addressLine2.valid) && (validation))"
                                        class="text-danger input-error">
                                        Enter Address Line 2. </div> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Zip</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <div *ngIf="(selectedContactCountry==231)">
                                            <input type="text" class="customInput NumberOnly" [(ngModel)]="contactZip"
                                                placeholder="Enter Zip xxxxx-xxxx" name="contactZip"
                                                pattern="\d{5}-?(\d{4})?" (keypress)="validateZipcode($event.target)"
                                                formControlName="contactZip" autocomplete="disabled" maxlength="10">
                                            <div *ngIf="((!form.controls.contactZip.valid && form.controls.contactZip.value!='' && form.controls.contactZip.value!=undefined) && (validation))"
                                                class="text-danger input-error">Enter Valid Zip.eg-xxxxx-xxxx</div>
                                        </div>
                                        <div *ngIf="(selectedContactCountry!=231)">
                                            <input type="text" class="customInput" [(ngModel)]="shipZip"
                                                placeholder="Enter Zip xxxxx-xxxx" name="shipZip"
                                                formControlName="shipZip" autocomplete="disabled" maxlength="10" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-lg-4 col-md-12">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">City</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput" [(ngModel)]="contactCity"
                                            placeholder="Enter City" name="contactCity" formControlName="contactCity"
                                            autocomplete="disabled" maxlength="50">
                                        <!-- <div *ngIf="((!form.controls.city.valid) && (validation))"
                                        class="text-danger input-error">
                                        Enter City.</div> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Country</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <select class="customInput pl-1px" formControlName='contactCountry'
                                            (change)="changeCountryContact($event)"
                                            [(ngModel)]="selectedContactCountry">
                                            <option value=''>Select Country</option>
                                            <option *ngFor="let item of countryDataContact" [value]="item.id">
                                                {{item.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">State</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <select class="customInput pl-1px" formControlName="contactState" required
                                            [(ngModel)]="selectedContactState"
                                            (ngModelChange)="changeStateContact($event)">
                                            <option value=''> Select State</option>
                                            <option *ngFor="let item of stateDataContact" [value]="item.id">
                                                {{item.name}}
                                            </option>
                                        </select>
                                        <!-- <div *ngIf="((!form.controls.stateName.valid) && (validation))"
                                        class="text-danger input-error">
                                        Select State.</div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div [style.display]="showCount == true ? 'block' : 'none'" class="showCount">
                        <div class="card-desc1 mt-1  table-responsive">
                            <div style=" overflow:scroll">
                                <table class="table table-bordered" style="width: 100%;" id="datatable1">

                                    <thead>
                                        <tr>
                                            <th>Contact First Name</th>
                                            <th>Contact Last Name</th>
                                            <th>Email</th>
                                            <th>Phone Number</th>
                                            <th>Customer Role(s)</th>
                                            <!-- <th>Contact Type</th> -->
                                            <th>SO Contact</th>
                                            <th>IsEcommerce</th>
                                            <th data-orderable="false">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <ng-container *ngFor="let data of multiUserList">
                                            <tr *ngIf="data.IsActive == true">
                                                <td>{{data.firstname}}</td>
                                                <td>{{data.lastname}}</td>
                                                <td>{{data.email}}</td>
                                                <td>{{data.phone}}</td>
                                                <!-- <td>{{data.customerTypeVal}}</td>  CustomerTypeList-->
                                                <td>
                                                    <!-- <span
                                                        *ngFor="let data1 of data.MultiContactUserRoles; let isLast=last">
                                                        {{data1.customerType}}{{isLast ? '' : ', '}}
                                                    </span> -->
                                                    {{data._MultiContactUserRoles}}
                                                </td>
                                                <!-- <td>{{data.Issocontact}}</td>   -->
                                                <td><span *ngIf="data.Issocontact==true"><i
                                                            class="fa fa-check text-success"
                                                            aria-hidden="true"></i></span></td>
                                                <td><span *ngIf="data.IsEcoomerce==true"><i
                                                            class="fa fa-check text-success"
                                                            aria-hidden="true"></i></span></td>
                                                <td>
                                                    <a class="green-text"
                                                        *ngIf="rolePermissionsList && rolePermissionsList[0].isUpdate"><i
                                                            class="fas fa-edit" (click)="multiUserEdit(data)"></i></a>
                                                    <a class="red-text"
                                                        *ngIf="rolePermissionsList && rolePermissionsList[0].isDelete"><i
                                                            class="fas fa-trash-alt"
                                                            (click)="deleteUserPopup(data)"></i></a>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </tbody>
                                    <tbody *ngIf="multiUserList==null">
                                        <tr class="c1">
                                            <td colspan="9" class="no-data-available">No data available in table</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>


                    </div>

                </div>
            </div>
            <!-- contact information -->

            <!-- Bill to -->
            <div class="white-box1 no_bg1">
                <div class="customHeading mt-3">
                    Bill To
                </div>
                <div class="card-desc1">

                    <div class="row mt-2">
                        <div class="col-lg-4 col-md-12">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp">
                                    <label class="customLabel">Name</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <input type="text" class="customInput" [(ngModel)]="billName"
                                        placeholder="Enter Name" name="billName" formControlName="billName"
                                        autocomplete="disabled" maxlength="50">
                                    <!-- <div *ngIf="((!form.controls.firstName.valid) && (validation))"
                                    class="text-danger input-error">
                                    Enter First Name.</div> -->
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp">
                                    <label class="customLabel">Email</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <input type="text" class="customInput EmailValidation" [(ngModel)]="billEmail"
                                        placeholder="Enter Email" name="billEmail" formControlName="billEmail"
                                        autocomplete="off"
                                        pattern="^[0-9a-zA-Z._%+-]+@[0-9a-zA-Z.-]+\.[a-zA-Z]{2,3}$" maxlength="50"
                                        [ngClass]="((!form.controls.billEmail.valid) && (validation)) ? 'validation_border': ''">

                                    <div *ngIf="((!form.controls.billEmail.valid) && (validation))"
                                        class="text-danger input-error">
                                        Please Enter Valid Email.</div>
                                    <!-- <div *ngIf="((form.controls.email.valid) && (recordAlreadyExist))"
                                    class="text-danger input-error">
                                    Email Already Exist.</div> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp">
                                    <label class="customLabel">Phone Number</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <input type="text" class="customInput PhoneValidation"
                                        (keypress)="validatePhoneNo($event.target)" [(ngModel)]="billPhone"
                                        placeholder="Enter Phone Number (xxx)xxx-xxx" name="billPhone"
                                        formControlName="billPhone" autocomplete="disabled" maxlength="14"
                                        pattern="/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/">
                                    <!-- <div *ngIf="((!form.controls.phone.valid) && (validation))"
                                    class="text-danger input-error">
                                    Enter Phone Number.eg-(xxx)xxx-xxx.</div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-lg-4 col-md-12">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp">
                                    <label class="customLabel">Extension #</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <input type="text" class="customInput" [(ngModel)]="billExtension"
                                        placeholder="Enter Extension " name="billExtension"
                                        formControlName="billExtension" autocomplete="disabled" maxlength="14">
                                    <!-- <div *ngIf="((!form.controls.phone.valid) && (validation))"
                                    class="text-danger input-error">
                                    Enter Phone Number.eg-(xxx)xxx-xxx.</div> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp">
                                    <label class="customLabel">Fax #</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <input type="text" class="customInput" (keypress)="validatePhoneNo($event.target)"
                                        [(ngModel)]="billFax" placeholder="Enter Fax (xxx)xxx-xxx" name="billFax"
                                        formControlName="billFax" autocomplete="disabled" required maxlength="14"
                                        pattern="/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/">
                                    <!-- <div *ngIf="((!form.controls.phone.valid) && (validation))"
                                    class="text-danger input-error">
                                    Enter Phone Number.eg-(xxx)xxx-xxx.</div> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp">
                                    <label class="customLabel">Address Line 1</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <input type="text" class="customInput" [(ngModel)]="billAddressLine1"
                                        placeholder="Enter Address Line 1 " maxlength="100" name="billAddressLine1"
                                        formControlName="billAddressLine1" autocomplete="disabled">
                                    <!-- <div *ngIf="((!form.controls.addressLine1.valid) && (validation))"
                                    class="text-danger input-error">
                                    Enter Address Line 1. </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-lg-4 col-md-12">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp">
                                    <label class="customLabel">Address Line 2</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <input type="text" class="customInput" [(ngModel)]="billAddressLine2"
                                        placeholder="Enter Address Line 2 " maxlength="100" name="billAddressLine2"
                                        formControlName="billAddressLine2" autocomplete="disabled">
                                    <!-- <div *ngIf="((!form.controls.addressLine2.valid) && (validation))"
                                    class="text-danger input-error">
                                    Enter Address Line 2. </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp">
                                    <label class="customLabel">Zip</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <div *ngIf="(selectedBillCountry==231)">
                                        <input type="text" class="customInput NumberOnly" [(ngModel)]="billZip"
                                            placeholder="Enter Zip xxxxx-xxxx" name="billZip" pattern="\d{5}-?(\d{4})?"
                                            (keypress)="validateZipcode($event.target)" formControlName="billZip"
                                            autocomplete="disabled" maxlength="10">
                                        <div *ngIf="((!form.controls.billZip.valid && form.controls.billZip.value!='' && form.controls.billZip.value!=undefined) && (validation))"
                                            class="text-danger input-error">Enter Valid Zip.eg-xxxxx-xxxx</div>
                                    </div>
                                    <div *ngIf="(selectedBillCountry!=231)">
                                        <input type="text" class="customInput" [(ngModel)]="billZip"
                                            placeholder="Enter Zip xxxxx-xxxx" name="billZip" formControlName="billZip"
                                            autocomplete="disabled" maxlength="10" />
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp">
                                    <label class="customLabel">City</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <input type="text" class="customInput" [(ngModel)]="billCity"
                                        placeholder="Enter City" name="billCity" formControlName="billCity"
                                        autocomplete="disabled" maxlength="50">
                                    <!-- <div *ngIf="((!form.controls.city.valid) && (validation))"
                                    class="text-danger input-error">
                                    Enter City.</div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-lg-4 col-md-12">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp">
                                    <label class="customLabel">Country</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <select class="customInput pl-1px" formControlName='billCountry'
                                        (change)="changeCountryBill($event)" [(ngModel)]="selectedBillCountry">
                                        <option value=''>Select Country</option>
                                        <option *ngFor="let item of countryDataBill" [value]="item.id">
                                            {{item.name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp">
                                    <label class="customLabel">State</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <select class="customInput pl-1px" formControlName="billState" required
                                        [(ngModel)]="selectedBillState" 
                                        (ngModelChange)="changeStateBill($event)">
                                        <option value=''> Select State</option>
                                        <option *ngFor="let item of stateDataBill" [value]="item.id">{{item.name}}
                                        </option>
                                    </select>
                                    <!-- <div *ngIf="((!form.controls.stateName.valid) && (validation))"
                                    class="text-danger input-error">
                                    Select State.</div> -->
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp">
                                    <label class="customLabel">Attention</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <input type="text" class="customInput" [(ngModel)]="billAtten"
                                        placeholder="Enter Attention" name="billAtten" formControlName="billAtten"
                                        autocomplete="disabled" maxlength="500">
                                    <!-- <div *ngIf="((!form.controls.stateName.valid) && (validation))"
                                    class="text-danger input-error">
                                    Select State.</div> -->
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-1">
                        <div class="col-lg-4 col-md-12">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp">
                                    <label class="customLabel">Net Terms</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <select class="customInput pl-1px" formControlName='NetTerms'
                                        [(ngModel)]="NetTerms">
                                        <option value=''>Select Net Terms</option>
                                        <option *ngFor="let item of NetTermsList" [value]="item.name">
                                            {{item.name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp">
                                    <label class="customLabel">Credit Limit</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <input type="text" class="customInput" [(ngModel)]="CreditCard"
                                        placeholder="Enter Credit Limit Amount $" maxlength="100" name="CreditCard"
                                        formControlName="CreditCard" autocomplete="off" (change)="ShowDoller()">

                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp">
                                    <label class="customLabel">Purchase Order #</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <select class="customInput pl-1px" formControlName="PurchaseOrder" required
                                        [(ngModel)]="PurchaseOrder">
                                        <option value=''> Select Required or Optional</option>
                                        <option *ngFor="let item of PurchaseOptionList" [value]="item.name">
                                            {{item.name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-1">

                        <div class="col-lg-4 col-md-12">
                            <div class="row mt-3">

                                <div class="col-lg-12 col-md-12">
                                    <div class="billInfo">
                                        <input type="checkbox" id="billinfo" name="billinfo" [checked]="billinfo"
                                            formControlName="billinfo" (change)="copyShipInfo($event)"
                                            [(ngModel)]="billinfo">

                                        <label for="billinfo" class="customLabel ml-2">Copy these details to my Ship To
                                            Information</label>
                                    </div>

                                </div>

                            </div>

                        </div>

                    </div>
                </div>


            </div>

            <!-- Bill to -->

            <!--Tax Information-->
            <div class="white-box1 no_bg1" [style.display]="isGuestType == true ? 'none' : 'block'">
                <div class="customHeading mt-3">
                    Tax Information
                </div>
                <div class="card-desc1">

                    <div class="row mt-2">

                        <div class="col-lg-8 col-md-12">
                            <div class="row">
                                <div class="col-lg-2 col-md-2 trp">
                                    <label class="customLabel"></label>
                                </div>
                                <div class="col-lg-10 col-md-12">
                                    <input type="radio" [checked]='true' value="taxable" id="taxable" name="taxable"
                                        [ngModel]="taxable" formControlName="taxable" (change)="changeTaxable($event)">
                                    <label for="taxable" class="customLabel">Taxable</label>
                                    <input type="radio" value="nontaxable" id="nontaxable" name="taxable"
                                        [ngModel]="taxable" formControlName="taxable" (change)="changeTaxable($event)">
                                    <label for="nontaxable" class="customLabel">Non-Taxable</label>

                                    <div class="taxids" style="display: inline-flex;">
                                        <label class="customLabel">Tax ID #</label>

                                        <input type="text" class="customInput ml-2 textwidth Cost"
                                            style="padding-left:4px;" [(ngModel)]="taxId" name="taxId"
                                            formControlName="taxId" autocomplete="off"
                                            (keypress)="omit_special_char($event)" maxlength="50">
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-3">
                                <div class="col-lg-2 col-md-12 trp">
                                    <label class="customLabel">Application Document</label>
                                </div>
                                <div class="col-lg-4 col-md-10">
                                    <div class="cstyle">
                                        <input type="file" class="customInput" #Image accept="application/pdf"
                                            (change)="ChngSettingImage($event.target.files)" id="file1"
                                            [disabled]="IsNonTaxable==false">
                                    </div>

                                </div>
                                <div class="col-lg-4 col-md-4 addimageandpdf mt-4 mt-md-0">
                                    <button type="button"
                                        class="btn round-btn upload_btn add_img ml-0 mt-1 mt-lg-0 btns"
                                        (click)="AddImage();">+ Add image</button>
                                    <div class="pdffield" *ngFor="let image of MultipleImage;let i = index;">
                                        <div class="particular_img application">
                                            <img [src]="DefaultLogoPDF" class="pdfimage"
                                                (click)="viewImage(image.imageUrl)">

                                            <button type="button" class="close"
                                                (click)="DeleteImageModalpopup(i,contentImage, 1)" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                            <span class="imgtypes">
                                                {{image.imageName}}
                                            </span>
                                        </div>
                                    </div>
                                </div>


                            </div>

                            <div class="row mt-3">
                                <div class="col-lg-2 col-md-12 trp">
                                    <label class="customLabel">Sales Tax Certificate</label>
                                </div>
                                <div class="col-lg-4 col-md-10">
                                    <div class="cstyle">
                                        <input type="file" class=" customInput" #Image1 accept="
                                        application/pdf" (change)="ChngSettingImage1($event.target.files)" id="file"
                                            [disabled]="IsNonTaxable1==false">
                                    </div>

                                </div>
                                <div class="col-lg-4 col-md-2 addimageandpdf mt-4 mt-md-0">
                                    <button type="button"
                                        class="btn round-btn upload_btn add_img ml-0 mt-1 mt-lg-0 btns"
                                        (click)="AddImage1();">+ Add image</button>
                                    <div class="pdffield" *ngFor="let image of MultipleImage1;let i = index;">
                                        <div class="particular_img tax">
                                            <img [src]="DefaultLogoPDF" class="pdfimage"
                                                (click)="viewImage(image.imageUrl1)">

                                            <button type="button" class="close"
                                                (click)="DeleteImageModalpopup(i,contentImage, 2)" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                            <span class="imgtypes">
                                                {{image.imageName1}}
                                            </span>
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>


                    </div>




                </div>
            </div>
            <!--Tax Information-->

            <!-- ship to -->
            <div class="white-box1 no_bg1">
                <div class="customHeading mt-3">
                    Ship To
                </div>
                <div class="card-desc1">

                    <div class="row mt-2">
                        <div class="col-lg-4 col-md-12">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp">
                                    <label class="customLabel">Name</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <input type="text" class="customInput" [(ngModel)]="shipName"
                                        placeholder="Enter Name" name="shipName" formControlName="shipName"
                                        autocomplete="disabled" maxlength="50">
                                    <!-- <div *ngIf="((!form.controls.firstName.valid) && (validation))"
                                    class="text-danger input-error">
                                    Enter First Name.</div> -->
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp">
                                    <label class="customLabel">Email</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <input type="text" class="customInput EmailValidation" [(ngModel)]="shipEmail"
                                        placeholder="Enter Email" name="shipEmail" formControlName="shipEmail"
                                        autocomplete="off"
                                        pattern="^[0-9a-zA-Z._%+-]+@[0-9a-zA-Z.-]+\.[a-zA-Z]{2,3}$" maxlength="50"
                                        [ngClass]="((!form.controls.shipEmail.valid) && (validation)) ? 'validation_border': ''">

                                    <div *ngIf="((!form.controls.shipEmail.valid) && (validation))"
                                        class="text-danger input-error">
                                        Please Enter Valid Email.</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp">
                                    <label class="customLabel">Phone Number</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <input type="text" class="customInput PhoneValidation"
                                        (keypress)="validatePhoneNo($event.target)" [(ngModel)]="shipPhone"
                                        placeholder="Enter Phone Number (xxx)xxx-xxx" name="shipPhone"
                                        formControlName="shipPhone" autocomplete="disabled" maxlength="14"
                                        pattern="/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/">
                                    <!-- <div *ngIf="((!form.controls.phone.valid) && (validation))"
                                    class="text-danger input-error">
                                    Enter Phone Number.eg-(xxx)xxx-xxx.</div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-lg-4 col-md-12">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp">
                                    <label class="customLabel">Extension #</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <input type="text" class="customInput" [(ngModel)]="shipExtension"
                                        placeholder="Enter Extension " name="shipExtension"
                                        formControlName="shipExtension" autocomplete="disabled" maxlength="14">
                                    <!-- <div *ngIf="((!form.controls.phone.valid) && (validation))"
                                    class="text-danger input-error">
                                    Enter Phone Number.eg-(xxx)xxx-xxx.</div> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp">
                                    <label class="customLabel">Fax #</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <input type="text" class="customInput" (keypress)="validatePhoneNo($event.target)"
                                        [(ngModel)]="shipFax" placeholder="Enter Fax (xxx)xxx-xxx" name="shipFax"
                                        formControlName="shipFax" autocomplete="disabled" required maxlength="14"
                                        pattern="/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/">
                                    <!-- <div *ngIf="((!form.controls.phone.valid) && (validation))"
                                    class="text-danger input-error">
                                    Enter Phone Number.eg-(xxx)xxx-xxx.</div> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp">
                                    <label class="customLabel">Address Line 1</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <input type="text" class="customInput" [(ngModel)]="shipAddressLine1"
                                        placeholder="Enter Address Line 1 " maxlength="100" name="shipAddressLine1"
                                        formControlName="shipAddressLine1" autocomplete="disabled">
                                    <!-- <div *ngIf="((!form.controls.addressLine1.valid) && (validation))"
                                    class="text-danger input-error">
                                    Enter Address Line 1. </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-lg-4 col-md-12">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp">
                                    <label class="customLabel">Address Line 2</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <input type="text" class="customInput" [(ngModel)]="shipAddressLine2"
                                        placeholder="Enter Address Line 2 " maxlength="100" name="shipAddressLine2"
                                        formControlName="shipAddressLine2" autocomplete="disabled">
                                    <!-- <div *ngIf="((!form.controls.addressLine2.valid) && (validation))"
                                    class="text-danger input-error">
                                    Enter Address Line 2. </div> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp">
                                    <label class="customLabel">Zip</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <div *ngIf="(selectedShipCountry==231)">
                                        <input type="text" class="customInput NumberOnly" [(ngModel)]="shipZip"
                                            placeholder="Enter Zip xxxxx-xxxx" name="shipZip" pattern="\d{5}-?(\d{4})?"
                                            (keypress)="validateZipcode($event.target)" formControlName="shipZip"
                                            autocomplete="disabled" maxlength="10">
                                        <div *ngIf="((!form.controls.shipZip.valid && form.controls.shipZip.value!='' && form.controls.shipZip.value!=undefined) && (validation))"
                                            class="text-danger input-error">Enter Valid Zip.eg-xxxxx-xxxx</div>
                                    </div>
                                    <div *ngIf="(selectedShipCountry!=231)">
                                        <input type="text" class="customInput" [(ngModel)]="shipZip"
                                            placeholder="Enter Zip xxxxx-xxxx" name="shipZip" formControlName="shipZip"
                                            autocomplete="disabled" maxlength="10" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp">
                                    <label class="customLabel">City</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <input type="text" class="customInput" [(ngModel)]="shipCity"
                                        placeholder="Enter City" name="shipCity" formControlName="shipCity"
                                        autocomplete="disabled" maxlength="50">
                                    <!-- <div *ngIf="((!form.controls.city.valid) && (validation))"
                                    class="text-danger input-error">
                                    Enter City.</div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-lg-4 col-md-12">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp">
                                    <label class="customLabel">Country</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <select class="customInput pl-1px" formControlName='shipCountry'
                                        (change)="changeCountryShip($event)" [(ngModel)]="selectedShipCountry">
                                        <option value=''>Select Country</option>
                                        <option *ngFor="let item of countryDataShip" [value]="item.id">
                                            <!-- <option *ngFor="let item of countryDataShip"[value]="item.id +'-'+ item.countryCode"> -->

                                            {{item.name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp">
                                    <label class="customLabel">State</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <select class="customInput pl-1px" formControlName="shipState" required
                                        [(ngModel)]="selectedShipState" (ngModelChange)="changeStateShip($event)">
                                        <option value=''> Select State</option>
                                        <option *ngFor="let item of stateDataShip" [value]="item.id">{{item.name}}
                                        </option>
                                    </select>
                                    <!-- <div *ngIf="((!form.controls.stateName.valid) && (validation))"
                                    class="text-danger input-error">
                                    Select State.</div> -->
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp">
                                    <label class="customLabel">Attention</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <input type="text" class="customInput" [(ngModel)]="shipAtten"
                                        placeholder="Enter Attention" name="shipAtten" formControlName="shipAtten"
                                        autocomplete="disabled" maxlength="500">
                                    <!-- <div *ngIf="((!form.controls.stateName.valid) && (validation))"
                                    class="text-danger input-error">
                                    Select State.</div> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                            <div class="row">
                                <div class="col-md-12 mt-lg-0 mt-md-1">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-2 trp d-flex justify-content-between pe-0">
                                            <label class="customLabel">Fedex Collect Acct</label>
                                            <div>
                                                <label class="customLabel"
                                                    [style.display]="IsCollectFedex == true ? 'inline-block':'none'">(Default)</label>
                                                <input type="checkbox" value="Fedex" [checked]="IsCollectFedex"
                                                    (change)="OnCollectAmount($event)"
                                                    [disabled]="disabledCollectFedex" />
                                            </div>
                                        </div>
                                        <div class="col-lg-8 col-md-10">
                                            <input type="text" class="customInput" [(ngModel)]="FedexCollect"
                                                placeholder="Fedex Collect(9 Digits- Numeric)" name="FedexCollect"
                                                formControlName="FedexCollect" autocomplete="disabled" maxlength="9"
                                                (keyup)="keyupFedexCollect($event)">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 mt-lg-0 mt-md-1 mt-1">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-2 trp d-flex justify-content-between pe-0">
                                            <label class="customLabel">UPS Collect Acct</label>
                                            <div>
                                                <label class="customLabel"
                                                    [style.display]="IsCollectUPS == true ? 'inline-block':'none'">(Default)</label>
                                                <input type="checkbox" value="UPS" [checked]="IsCollectUPS"
                                                    (change)="OnCollectAmount($event)"
                                                    [disabled]="disabledCollectUPS" />
                                            </div>
                                        </div>
                                        <div class="col-lg-8 col-md-10">
                                            <input type="text" class="customInput" [(ngModel)]="UPSCollect"
                                                placeholder="UPS Collect(6 Digits- Alphanumeric)" name="UPSCollect"
                                                formControlName="UPSCollect" autocomplete="disabled" maxlength="6"
                                                (keyup)="keyupUPSCollect($event)">
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                        <!-- <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp">
                                    <label class="customLabel"></label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <input id="IsCollectAmount" [(ngModel)]="IsCollectAmount"
                                        formControlName="IsCollectAmount" (change)="OnCollectAmount($event)"
                                        class="custom_checkbox mr-2 mt-auto mb-auto" type="checkbox"
                                        ng-reflect-model="false" />
                                    <label for="IsCollectAmount" class="customLabel">Collect Account</label>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp">
                                    <label class="customLabel">Fedex Collect</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <input type="text" class="customInput" [(ngModel)]="FedexCollect"
                                        placeholder="Fedex Collect(9 Digits- Numeric)" name="FedexCollect"
                                        formControlName="FedexCollect" autocomplete="disabled" maxlength="9">
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp">
                                    <label class="customLabel">UPS Collect</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <input type="text" class="customInput" [(ngModel)]="UPSCollect"
                                        placeholder="UPS Collect(6 Digits- Alphanumeric)" name="UPSCollect"
                                        formControlName="UPSCollect" autocomplete="disabled" maxlength="6">
                                </div>
                            </div>
                        </div> -->
                    </div>

                </div>
            </div>
            <!-- ship to -->
            <div class="row">
                <div class="col-md-12 mb-5">
                    <div class="submit-from text-right mt-1">
                        <button type="button" class="btn blue-bg round-btn"
                            *ngIf="rolePermissionsList && rolePermissionsList[0].isInsert" (click)="addCustomer()"><i
                                class="fas fa-save" aria-hidden="true"></i>
                            {{buttonValue}}
                        </button>
                        <button type="button" class="btn darkred-bg round-btn" *ngIf="clearbutton==true"
                            (click)="clearDetails()"><i class="fas fa-redo" aria-hidden="true"></i>
                            Clear </button>
                        <button type="button" class="btn darkred-bg round-btn" *ngIf="clearbutton==false"
                            (click)="CustomerExit()"><i class="fas fa" aria-hidden="true"></i>
                            Exit </button>
                    </div>
                </div>
            </div>
        </form>

    </div>


</section>


<ng-template #content let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg alert_heading sweet-heading"></h4>
    <button type="button" class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body">
        <div class="thank-you-pop">
            <img src="./assets/image/popup-alert.png" alt="">
            <h1>Confirmation</h1>
            <p class="mt-1">{{deleteDetail}}</p>
        </div>
    </div>
    <div class="modal-footer sweet-footer text-center p-1">
        <button type="button" class="btn round-btn green-bg m-1"
            (click)="deleteCustomer(deletedItem); c('Close click')">Yes</button>
        <button type="button" class="btn round-btn red-bg m-1" (click)="c('Close click')">No</button>
    </div>
</ng-template>

<ng-template #content1 let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg alert_heading sweet-heading"></h4>
    <button type="button" class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body">
        <div class="thank-you-pop">
            <img src="./assets/image/popup-alert.png" alt="">
            <h1>Confirmation</h1>
            <p class="mt-1">{{userdeleteDetail}}</p>
        </div>
    </div>
    <div class="modal-footer sweet-footer text-center p-1">
        <button type="button" class="btn round-btn green-bg m-1"
            (click)="multiUserDelete(userdeletedItem); c('Close click')">Yes</button>
        <button type="button" class="btn round-btn red-bg m-1" (click)="c('Close click')">No</button>
    </div>
</ng-template>

<div class="modal fade" id="addhierarchy" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content border-none">
            <div class="modal-header info-popup-header p-2">
                <h5 class="modal-title info-popup-title" id="exampleModalLabel">Customer Details: <strong
                        class="text-light">{{customerName}}
                    </strong></h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="card-desc1">
                    <div class="modal-body bread-crumb">
                        <div class="row">
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label for="" class="customLabel">Company Name :</label>
                                    </div>
                                    <div class="col-md-8">
                                        <span>{{value}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label for="" class="customLabel">Phone No :</label>
                                    </div>
                                    <div class="col-md-8">
                                        <span>{{phone}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label for="" class="customLabel">Customer Name :</label>
                                    </div>
                                    <div class="col-md-8">
                                        <span>{{customerName}}</span>
                                    </div>
                                </div>
                            </div>
                            <!-- <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label for="" class="customLabel">Email :</label>
                                    </div>
                                    <div class="col-md-8">
                                        <span>{{email}}</span>
                                    </div>
                                </div>
                            </div> -->
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label for="" class="customLabel">Address Line 1 :</label>
                                    </div>
                                    <div class="col-md-8">
                                        <span>{{addressLine1}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-1">

                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label for="" class="customLabel">Address Line 2 :</label>
                                    </div>
                                    <div class="col-md-8">
                                        <span>{{addressLine2}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label for="" class="customLabel">State :</label>
                                    </div>
                                    <div class="col-md-8">
                                        <span>{{stateName}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-1">

                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label for="" class="customLabel">City :</label>
                                    </div>
                                    <div class="col-md-8">
                                        <span>{{city}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label for="" class="customLabel">Zip Code :</label>
                                    </div>
                                    <div class="col-md-8">
                                        <span>{{zipCode}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-1">

                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label for="" class="customLabel">Customer # :</label>
                                    </div>
                                    <div class="col-md-8">
                                        <span>{{CustomerHash}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6">
                                <div class="row">
                                    <div class="col-md-4">
                                        <label for="" class="customLabel"> Fax # :</label>
                                    </div>
                                    <div class="col-md-8">
                                        <span>{{Fax}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-1">

                        </div>
                        <div class="row mt-1">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-md-2">
                                        <label for="" class="customLabel">Notes :</label>
                                    </div>
                                    <div class="col-md-10">
                                        <span>{{customerNotes}}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="modal-footer p-1">
                <button type="button" class="btn darkred-bg round-btn" data-dismiss="modal"
                    (click)="closeModal()">Close</button>

            </div>
        </div>
    </div>
</div>
<div class="modal" id="add_success">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="addsuccess()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Added Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="addsuccess()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="add_update">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="updateitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Updated Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="updateitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="delete_success">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="deleteitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Deleted Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="deleteitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="wrong_msg">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="msgitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>Something went wrong</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="msgitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="validAddress">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-body p-1">
                <div class="row p-3">
                    <div class="col-lg-12">
                        <p class="h5">
                            We are unable to verify your address. Please, update the suggested address.
                        </p>
                    </div>
                    <div class="col-lg-12">
                        <div class="row">
                            <div class="col-md-6 p-3">
                                <div class="mb-3">
                                    <h5 class="text-info">You Entered</h5>
                                </div>
                                <div>
                                    <ul class="list-unstyled">
                                        <li>{{shipAddressLine1}}</li>
                                        <li>{{shipCity}}</li>
                                        <li>{{validState}}</li>
                                        <li>{{shipZip}}</li>
                                        <li>{{validCountry}}</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="col-md-6 p-3">
                                <div class="mb-3">
                                    <h5 class="text-info">Suggested Address</h5>
                                </div>
                                <div>
                                    <ul class="list-unstyled">
                                        <li>{{address_line1}}</li>
                                        <li>{{city_locality}}</li>
                                        <li>{{postal_code}}</li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12">
                        <button (click)="autofillAddress()" type="button"
                            class="h6 text-light bg-primary w-100 p-2 rounded">USE SUGGESTED</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="invalidAddress">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="invalidAddress()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>ShipTo Address is Invalid</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="invalidAddress()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="modalExist">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="closeExist()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>Customer # already exist.</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="closeExist()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="Imagevlaidation">
    <div class="modal-dialog">
        <div class="modal-content">
            <h4 class="modal-title darkred-bg sweet-heading"></h4>
            <button type="button" class="close btn alert_modal" aria-label="Close" (click)="hidemodalpopup1()">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
                <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="">
                    <h1>Alert</h1>
                    <p class="mb-0">Only 1 {{ImageType}} can be uploaded</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer">
                <button type="button" class="btn red-bg round-btn m-0" (click)="hidemodalpopup1()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="customerInvalidModel">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="customerInvalidModel()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>{{customerProp}}</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="customerInvalidModel()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<ng-template #contentImage let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg sweet-heading"></h4>
    <button type="button" class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body">
        <div class="thank-you-pop">
            <img src="./assets/image/popup-alert.png" alt="">
            <h1>Confirmation</h1>
            <p class="mb-0">{{deleteDetail}}</p>
        </div>
    </div>
    <div class="modal-footer sweet-footer text-center">
        <button type="button" class="btn green-bg round-btn m-0 mr-1"
            (click)="DeleteImage(); c('Close click')">Yes</button>
        <button type="button" class="btn red-bg round-btn m-0" (click)="c('Close click')">No</button>
    </div>
</ng-template>

<div class="modal" id="customerModel">
    <div class="modal-dialog  modal-dialog-centered modal-lg bigmodals" role="document">
        <div class="modal-content">
            <h4 class="modal-title info-popup-title p-1 text-center">Multiple Contacts for {{CustomerPartName}}
            </h4>
            <button type="button" class="close btn alert_modal" aria-label="Close" (click)="userclose()">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
                <!------------------------------------- Multiple Contacts table ------------------------------>
                <div class="card-desc1">
                    <form [formGroup]="userForm">
                        <div class="row mt-2">
                            <div class="col-lg-4 col-md-12">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Contact First Name</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput" [(ngModel)]="userfirstName"
                                            placeholder="Enter First Name" name="userfirstName"
                                            formControlName="userfirstName" autocomplete="off" maxlength="50"
                                            (keypress)="omit_special_char($event)"
                                            [ngClass]="((!userForm.controls.userfirstName.valid) && (uservalidation)) ? 'validation_border': ''">

                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel Character">Contact Last Name</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput" required [(ngModel)]="userlastName"
                                            placeholder="Enter Last Name" name="userlastName"
                                            formControlName="userlastName" autocomplete="none|false|no" maxlength="50"
                                            (keypress)="omit_special_char($event)"
                                            [ngClass]="((!userForm.controls.userlastName.valid) && (uservalidation)) ? 'validation_border': ''">

                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Email</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput EmailValidation" [(ngModel)]="useremail"
                                            placeholder="Enter Email" name="useremail" formControlName="useremail"
                                            autocomplete="off" required
                                            pattern="^[0-9a-zA-Z._%+-]+@[0-9a-zA-Z.-]+\.[a-zA-Z]{2,3}$" maxlength="50"
                                            [ngClass]="((!userForm.controls.useremail.valid) && (uservalidation)) ? 'validation_border': ''">

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-1">
                            <div class="col-lg-4 col-md-12">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Phone Number</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput PhoneValidation"
                                            (keypress)="validatePhoneNo($event.target)" [(ngModel)]="userphone"
                                            placeholder="Enter Phone Number (xxx)xxx-xxx" name="userphone" required
                                            formControlName="userphone" autocomplete="disabled" maxlength="14"
                                            pattern="/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/"
                                            [ngClass]="((!userForm.controls.userphone.valid) && (uservalidation)) ? 'validation_border': ''">

                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Extension #</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput" [(ngModel)]="userextention"
                                            placeholder="Enter Extension" name="userextention"
                                            formControlName="userextention" autocomplete="disabled" maxlength="14">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Fax #</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput"
                                            (keypress)="validatePhoneNo($event.target)" [(ngModel)]="userfax"
                                            placeholder="Enter Fax (xxx)xxx-xxx" name="userfax"
                                            formControlName="userfax" autocomplete="disabled" maxlength="14"
                                            pattern="/^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$/">
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="row mt-1">
                            <div class="col-lg-4 col-md-12">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Address Line 1</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput" [(ngModel)]="useraddress"
                                            placeholder="Enter Address Line 1 " maxlength="100" name="useraddress"
                                            formControlName="useraddress" autocomplete="disabled">

                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Address Line 2</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput" [(ngModel)]="useraddress1"
                                            placeholder="Enter Address Line 2 " maxlength="100" name="useraddress1"
                                            formControlName="useraddress1" autocomplete="disabled">

                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Zip</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <div *ngIf="(selectedContactCountry==231)">
                                            <input type="text" class="customInput NumberOnly" [(ngModel)]="userzip"
                                                placeholder="Enter Zip xxxxx-xxxx" name="userzip"
                                                pattern="\d{5}-?(\d{4})?" (keypress)="validateZipcode($event.target)"
                                                formControlName="userzip" autocomplete="disabled" maxlength="10">
                                            <div *ngIf="((!userForm.controls.userzip.valid && userForm.controls.userzip.value!='' && userForm.controls.userzip.value!=undefined) && (uservalidation))"
                                                class="text-danger input-error">Enter Valid Zip.eg-xxxxx-xxxx</div>
                                        </div>
                                        <div *ngIf="(selectedContactCountry!=231)">
                                            <input type="text" class="customInput" [(ngModel)]="userzip"
                                                placeholder="Enter Zip xxxxx-xxxx" name="userzip"
                                                formControlName="userzip" autocomplete="disabled" maxlength="10" />
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="row mt-1">
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">City</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput" [(ngModel)]="usercity"
                                            placeholder="Enter City" name="usercity" formControlName="usercity"
                                            autocomplete="off"
                                            [ngClass]="((!userForm.controls.usercity.valid) && (uservalidation)) ? 'validation_border': ''">

                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Country</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <select class="customInput pl-1px" formControlName='usercountry'
                                            (change)="changeUserCountryContact($event)"
                                            [(ngModel)]="selectedContactCountry">
                                            <option value=''>Select Country</option>
                                            <option *ngFor="let item of countryDataContact" [value]="item.id">
                                                {{item.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">State</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <select class="customInput pl-1px" formControlName="userstate"
                                            [(ngModel)]="selectedUserValue"
                                            [ngClass]="((!userForm.controls.userstate.valid) && (uservalidation)) ? 'validation_border': ''">
                                            <option value=''> Select State</option>
                                            <option *ngFor="let item of stateUserData" [value]="item.id">{{item.name}}
                                            </option>
                                        </select>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-1">
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Contact Type</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <select class="customInput pl-1px" formControlName="selectedCustomerContactType"
                                            [(ngModel)]="selectedCustomerContactType"
                                            [ngClass]="((!userForm.controls.selectedCustomerContactType.valid) && (uservalidation)) ? 'validation_border': ''">
                                            <option value=''> Select Contact Type</option>
                                            <option *ngFor="let item of CustomerContacttypeId" [value]="item.id">
                                                {{item.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel" for="Issocontact">So Contact</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="checkbox" id="Issocontact" name="Issocontact"
                                            [checked]="Issocontact" formControlName="Issocontact"
                                            [(ngModel)]="Issocontact" />

                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">

                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row  mt-1">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel" for="selectedItems">Customer Role</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <!-- <ng-multiselect-dropdown-angular7
                                        formControlName="selectedItems"
                                        [placeholder]="'Select Customer Roles'"
                                        [data]="dropdownList"
                                        [(ngModel)]="selectedItems"
                                        [settings]="dropdownSettings"
                                        (onSelect)="onItemSelect($event)"
                                        (onDeSelect)="onItemDeSelect($event)"
                                        >
                                        </ng-multiselect-dropdown-angular7> -->

                                        <!-- <mat-form-field appearance="outline">
                                            <mat-select placeholder="Select Customer Roles"
                                                formControlName="selectedItems" [(value)]="selectedItems" multiple>
                                                <mat-option *ngFor="let item of dropdownList"
                                                    [value]="item.id">{{item.customerType}}</mat-option>
                                            </mat-select>
                                        </mat-form-field> -->
                                        <mat-form-field appearance="outline">
                                            <mat-select placeholder="Select Customer Roles"
                                                formControlName="selectedItems" [(value)]="selectedItems" multiple>
                                                <mat-option *ngFor="let item of RolesList"
                                                    [value]="item.rolesName">{{item.rolesName}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-1"
                                *ngIf="isUserEdit==false && isEcoomUser == true">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp required">
                                        <label class="customLabel">Password</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="password" class="customInput" [(ngModel)]="userpassword"
                                            formControlName='userpassword' required autocomplete="off" maxlength="40"
                                            placeholder="Enter Password"
                                            [ngClass]="((userForm.controls.userpassword.value==''||userForm.controls.userpassword.value==undefined) && (uservalidation)) ? 'validation_border': ''">

                                        <div class="text-danger input-error"
                                            *ngIf="userForm.get('userpassword').invalid && (userForm.get('userpassword').touched || userForm.get('userpassword').dirty)">
                                            <div *ngIf="userForm.get('userpassword').errors.minlength">
                                                Password must be at least 8 characters
                                            </div>
                                            <div *ngIf="userForm.get('userpassword').hasError('passwordStrength')">
                                                {{userForm.get('userpassword').errors['passwordStrength']}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-1"
                                *ngIf="isUserEdit==false && isEcoomUser == true">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp required">
                                        <label class="customLabel">Confirm Password</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="password" class="customInput" [(ngModel)]="userpassworConfirm"
                                            formControlName='userpassworConfirm' required autocomplete="new-password"
                                            placeholder="Enter Confirm Password" maxlength="40"
                                            [ngClass]="((userForm.controls.userpassworConfirm.value==''||userForm.controls.userpassworConfirm.value==undefined) && (uservalidation)) ? 'validation_border': ''">
                                        <div class="text-danger input-error">

                                            <div *ngIf="((userpassword != userpassworConfirm) && (userForm.controls.userpassworConfirm.value !='') && (userForm.controls.userpassworConfirm.value != undefined)
                                                && (userForm.controls.userpassworConfirm.value != null))">
                                                Password not match.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel" for="isEcoomUser">Ecommerce User</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="checkbox" id="isEcoomUser" name="isEcoomUser"
                                            [checked]="isEcoomUser" formControlName="isEcoomUser"
                                            [(ngModel)]="isEcoomUser" (change)="changeEUser($event)">
                                    </div>
                                </div>
                            </div>



                        </div>

                        <div class="row mt-1">
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1"></div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1"></div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">

                                <button type="button" class="btn darkred-bg round-btn" (click)="userclose()"
                                    style="float: right">Exit</button>
                                <button type="button" class="btn blue-bg round-btn" *ngIf="IsUsertSave"
                                    (click)="AddMultiUser()" style="float: right">Save</button>

                                <button type="button" class="btn blue-bg round-btn" *ngIf="IsUserUpdate"
                                    (click)="UpdateMultiUser()" style="float: right">Update</button>

                            </div>
                        </div>

                        <div class="row mt-2" *ngIf="isUserEdit==true && isEcoomUser == true">
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp required">
                                        <label class="customLabel">Password</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="password" class="customInput" [(ngModel)]="changepassword"
                                            formControlName='changepassword' required autocomplete="off" maxlength="40"
                                            placeholder="Enter Password"
                                            [ngClass]="((userForm.controls.changepassword.value==''||userForm.controls.changepassword.value==undefined) && (userPasswordvalidation)) ? 'validation_border': ''">

                                        <div class="text-danger input-error"
                                            *ngIf="userForm.get('changepassword').invalid && (userForm.get('changepassword').touched || userForm.get('changepassword').dirty)">
                                            <div *ngIf="userForm.get('changepassword').errors.minlength">
                                                Password must be at least 8 characters
                                            </div>
                                            <div *ngIf="userForm.get('changepassword').hasError('passwordStrength')">
                                                {{userForm.get('changepassword').errors['passwordStrength']}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-2">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp required">
                                        <label class="customLabel">Confirm Password</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="password" class="customInput" [(ngModel)]="changepassworConfirm"
                                            formControlName='changepassworConfirm' required autocomplete="new-password"
                                            placeholder="Enter Confirm Password" maxlength="40"
                                            [ngClass]="((userForm.controls.changepassworConfirm.value==''||userForm.controls.changepassworConfirm.value==undefined) && (userPasswordvalidation)) ? 'validation_border': ''">
                                        <div class="text-danger input-error">

                                            <div *ngIf="((changepassword != changepassworConfirm) && (userForm.controls.changepassworConfirm.value !='') && (userForm.controls.changepassworConfirm.value != undefined)
                                                && (userForm.controls.changepassworConfirm.value != null))">
                                                Password not match.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <button type="button" class="btn blue-bg round-btn" (click)="ChangePassword()"
                                        style="float: right">Change Password</button>
                                    <button type="button" class="btn darkred-bg round-btn"
                                        (click)="clearPasswordDetails()"><i class="fas fa-redo" aria-hidden="true"></i>
                                        Clear</button>
                                </div>
                            </div>
                        </div>

                    </form>

                    <hr style="margin-bottom: 0;" />

                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="SaveModel" style="z-index: 1080">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="SavePopModel()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>{{this.SaveModel}}</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="SavePopModel()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="AlreadyUserExist" style="z-index: 1080">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="AlreadyUserExist()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>{{this.AlreadyEmail}}</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="AlreadyUserExist()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="DeletePopModel" style="z-index: 1080">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="DeletePopModel()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body">
                <div class="thank-you-pop">
                    <div *ngIf="this.userdeletedItem != 0">
                        <img src="./assets/image/popup-alert.png" alt="">
                        <h1>Confirmation</h1>
                    </div>
                    <div *ngIf="this.userdeletedItem == 0">
                        <img src="./assets/image/close.png" alt="">
                        <h1>Alert</h1>
                    </div>
                    <p class="mb-0">{{userdeleteDetail}}</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn green-bg round-btn m-0 mr-1"
                    (click)="multiUserDelete(this.userdeletedItem)" *ngIf="this.userdeletedItem != 0">Yes</button>
                <button type="button" class="btn red-bg round-btn" (click)="DeletePopModel()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="ContactModel">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="CloseContactModel()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>Add atleast one contacts for this Customer</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="CloseContactModel()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="ChangePassword">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="CloseChangePassword()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Password Change Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="CloseChangePassword()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="notGuest">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="ClosenotGuest()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="">
                    <h1>Alert</h1>
                    <p>Registered User can not be converted as Guest User</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="ClosenotGuest()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="ReturnModel" style="z-index: 1080">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="CloseReturnModel()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="">
                    <h1>Alert</h1>
                    <p>Only one SO Contact could be selected</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="CloseReturnModel()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>