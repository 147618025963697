<link rel="stylesheet" href="https://unpkg.com/ngx-bootstrap/datepicker/bs-datepicker.css">
<section class="r_report content-section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-2">
                <form [formGroup]="reportFormforSO">
                    <!-- <div class="left-img">
                        <img src="http://localhost:4200/assets/image/logo.jpg" alt=""> 
                        <h5 class="mt-2">mark@911vehicle.com</h5>
                        <h5>911vehicle.com</h5>
                    </div> -->
                    <div class="p-2 blue-txt text-center shadow">
                        <h4 style="margin:0px !important">Total #PO's {{this.totalrows}}</h4>
                    </div>
                    <div class="mt-2">
                        <mat-card class="example-card map-box-custom" appearance="outlined" style="height: 154px;">
                            <mat-card-content style="padding: 0px 5px;">
                                <div class="form-group dflex">
                                    <input class="form-control-input" type="radio" name="allDateRange" checked="checked"
                                        formControlName="allDateRange" (change)="changeAllDateOrder($event)"
                                        id=dateRange1 value="">
                                    <label class="form-control-label" for="dateRange1">All Order</label>
                                </div>
                                <div class="form-group dflex mb-0">
                                    <input class="form-control-input" type="radio" name="allDateRange"
                                        formControlName="allDateRange" (change)="changeAllDateOrder($event)"
                                        id=dateRange value="allDateRange">
                                    <label class="form-control-label" for="dateRange">Order Date Range</label>
                                </div>
                                <!-- <label for="">Order Date</label> -->
                                <div class="d-flex mt-2 flex-column" *ngIf="IsDateRange==true">
                                    <input type="date" style="margin: 4px;" matInput class="customInput mr-1"
                                        [(ngModel)]="fromDate" formControlName="fromDate">
                                    <input type="date" style="margin: 4px;" matInput class="customInput mt-1"
                                        [(ngModel)]="toDate" formControlName="toDate">
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <mat-card class="example-card" style="height: 72px;padding-top: 7px;">
                        <mat-card-content style="padding: 0 12px">
                            <mat-form-field appearance="outline">
                                <mat-label>Vendor</mat-label>
                                <mat-select name='vendorVal' id="vendorVal" [(ngModel)]="vendorVal"
                                    formControlName="vendorVal" (change)="vendorvalues($event)">
                                    <mat-option [value]='0'>All</mat-option>
                                    <mat-option *ngFor="let item of vendorlist" [value]="item.id">
                                        {{item.vendorName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </mat-card-content>
                    </mat-card>


                    <mat-card class="example-card" style="height: 72px; padding-top: 7px;">
                        <mat-card-content style="padding: 0 10px">
                            <mat-form-field appearance="outline">
                                <mat-label>Order Status</mat-label>
                                <mat-select name='statusId' id="statusvalues" [(ngModel)]="statusIdVal"
                                    formControlName="statusIdVal" (change)="statusvalues($event)">
                                    <mat-option [value]='0'>All</mat-option>
                                    <mat-option *ngFor="let item of POstatusList" [value]="item.statusId">
                                        {{item.statusName}}
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                        </mat-card-content>
                    </mat-card>

                    <mat-card class="example-card map-box-custom shadow" appearance="outlined"
                        style="height: 57px; padding-top: 8px;">
                        <mat-card-content style="padding: 0;">
                            <button class="form-control" (click)="GetPurchaseOrderReportData()">Search <span> <i
                                        class="fas fa-search cpointer"></i></span> </button>
                        </mat-card-content>
                    </mat-card>
                </form>
            </div>

            <div class="col-md-10 right-blk">
                <div class="row three-map-blk">
                    <div class="col-md-4">
                        <mat-card class="example-card map-box-custom shadow" appearance="outlined">
                            <mat-card-header>
                                <mat-card-title>Labor Revenue and Part Revenue</mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <div class="col-md-4">
                        <mat-card class="example-card map-box-custom shadow" appearance="outlined">
                            <mat-card-header>
                                <mat-card-title>GrossProfit</mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                            </mat-card-content>
                        </mat-card>
                    </div>
                    <div class="col-md-4">
                        <mat-card class="example-card map-box-custom shadow" appearance="outlined">
                            <mat-card-header>
                                <mat-card-title>Count Of</mat-card-title>
                            </mat-card-header>
                            <mat-card-content>
                            </mat-card-content>
                        </mat-card>
                    </div>

                    <label style="margin-left: 500px;">Purchase Order Report</label>
                    <div class="col-12 example-container company-table">
                        <table mat-table [dataSource]="dataSource" class="cstm_scrollbar_theme">

                            <!-- PO Column -->
                            <ng-container matColumnDef="po">
                                <th mat-header-cell *matHeaderCellDef> PO </th>
                                <td mat-cell *matCellDef="let element"> {{element.po}} </td>
                            </ng-container>

                            <!-- Prefix Column -->
                            <ng-container matColumnDef="poprefix">
                                <th mat-header-cell *matHeaderCellDef> PO Prefix </th>
                                <td mat-cell *matCellDef="let element"> {{element.purchaseOrderPrefix}} </td>
                            </ng-container>

                            <!-- Status Column -->
                            <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef> Status </th>
                                <td mat-cell *matCellDef="let element"> {{element.poStatus}} </td>
                            </ng-container>

                            <!-- Vendor Column -->
                            <ng-container matColumnDef="vendor">
                                <th mat-header-cell *matHeaderCellDef> Vendor </th>
                                <td mat-cell *matCellDef="let element"> {{element.vendorName}} </td>
                            </ng-container>

                            <!-- Order Date Column -->
                            <ng-container matColumnDef="orderDate">
                                <th mat-header-cell *matHeaderCellDef> Order Date </th>
                                <td mat-cell *matCellDef="let element"> {{element.dateOrder}} </td>
                            </ng-container>

                            <!-- Ship Date Column -->
                            <ng-container matColumnDef="shipDate">
                                <th mat-header-cell *matHeaderCellDef> Ship Date </th>
                                <td mat-cell *matCellDef="let element"> {{element.dateSchedule}} </td>
                            </ng-container>

                            <!-- Total Amount Column -->
                            <ng-container matColumnDef="total">
                                <th mat-header-cell *matHeaderCellDef> Total </th>
                                <td mat-cell *matCellDef="let element"> {{element.totalAmount|currency}} </td>
                            </ng-container>

                            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>


                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="10" class="error text-center" style="height: 47px">
                                    No data found !
                                </td>
                            </tr>
                            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                        </table>
                    </div>
                    <div *ngIf="purchageorderList!=null">
                        <tr>
                            <td><span style="margin-left: 22px; margin-top: 4px;">Total:</span></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td class="text-right"><span style="margin-left: 1095px;">{{totalAmountAll== 0 ? '':totalAmountAll|currency}}</span> </td>
                        </tr>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>