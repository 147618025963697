import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { ConstantsService } from 'src/app/constants.service';
import { SharedService } from 'src/app/services/shared.service';
// import { TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import Swal from 'sweetalert2';
import { GlobalChangeService } from 'src/app/services/global-change.service';
import { CompanyService } from 'src/app/services/company/company.service';

@Component({
  selector: 'app-searchable-fields',
  templateUrl: './searchable-fields.component.html',
  styleUrls: ['./searchable-fields.component.css']
})
export class SearchableFieldsComponent implements OnInit {
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  loader: boolean = false;
  SearchControl: boolean = false;
  SearchValidation: any;
  ListShow: boolean = false;
  ListDiv: boolean = false;
  clearbutton: boolean = true;
  buttonValue: any;
  companyLoginId: any;
  userSearch: any;
  rolePermissionsList: any;
  permissionMessage: any;
  deletedItem: string;
  deleteDetail: string;
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  // config = TreeviewConfig.create({
  //   hasAllCheckBox: true,
  //   hasFilter: true,
  //   hasCollapseExpand: true,
  //   decoupleChildFromParent: false,
  //   maxHeight: 400
  // });
  // config1 = TreeviewConfig.create({
  //   hasAllCheckBox: true,
  //   hasFilter: true,
  //   hasCollapseExpand: true,
  //   decoupleChildFromParent: false,
  //   maxHeight: 400
  // });
  userGroupObjects_search = new Array();
  data: any;
  // items_search: TreeviewItem[];
  companyList_search: any;
  value_search: number;
  companyList: any;
  userGroupObjects = new Array();
  userGroupObjects1 = new Array();
  value = 0;
  value1 = 0;
  userId: string;
  id: any;
  seachableDataList: any;
  createdDateEdit: any;
  ListAngle: string;
  SearchType: any;
  settingDetailCSS: string;
  settingLogoImage: string;
  form: FormGroup;
  dbNameList: any;
  dbName: any = '';
  labelName: any = '';
  validation: boolean;
  dbTypeList: any;
  items_search: any;
  constructor(
    private route: ActivatedRoute,
    public sharedService: SharedService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private constants: ConstantsService,
    private router: Router,
    private globalChangeService: GlobalChangeService,
    private companyService: CompanyService
  ) { }

  TCreateUpdate: any = 1;
  formSearch: FormGroup;

  /* Message Modal Properties */
  messageModalImage: any;
  messageModalHeading: any;
  messageModalDescription: any;
  @ViewChild('messageModal', { static: false }) private messageModal;
  ngOnInit(): void {
    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);
    } else {
      this.userId = localStorage.getItem(this.constants.userId);
      this.buttonValue = this.constants.Save;
      this.dtOptions = {
        pagingType: this.constants.full_numbers,
        pageLength: 10,
      };

      
    }
    this.formSearch = new FormGroup({
      'TCreateUpdate': new FormControl(),
      'userSearch': new FormControl(),
    });
    this.form = new FormGroup({
      'dbName': new FormControl(),
      'labelName': new FormControl()

    });
    this.rolePermissionsList = JSON.parse(localStorage.getItem('rolePermissionsList'));
    this.rolePermissionsList = this.rolePermissionsList.filter(x => x.pageId == 1034);
    this.permissionMessage = '';
    if (this.rolePermissionsList != null && this.rolePermissionsList != undefined) {
      if (!this.rolePermissionsList[0].isView) {
        this.permissionMessage += 'view';
      } if (!this.rolePermissionsList[0].isInsert) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'add';
      } if (!this.rolePermissionsList[0].isUpdate) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'edit';
      } if (!this.rolePermissionsList[0].isDelete) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'delete';
      }
    }
    if (this.permissionMessage != '') {
      this.permissionMessage = 'You do not have access to ' + this.permissionMessage + '. Please contact to administrator!';
    }
    this.companyLoginId = localStorage.getItem(this.constants.companyId);
    // this.getAllCompany(this.companyLoginId);
    // this.getAllCompany_search(this.companyLoginId);
    this.dbNameList = this.constants.dbNameList;
    this.dbName = '';

    //ankit implementaion
    // get company by default set value
    var company = this.globalChangeService.getGlobalCompany();
    if(company>0){
      this.value = company;
      this.value_search = company;
    }
    else{
      this.ErrorModal('Error', 'Please select company.');
    }

    //on change function 
    this.globalChangeService.dropdownChange$.subscribe((data)=>{
      this.onValueChange_search(data);
    })
  }
  ChangeTCreateUpdate(event) {
    this.TCreateUpdate = event.target.value;
    if (this.TCreateUpdate == "1") {
      this.SearchControl = false;
      this.ListShow = false;
      this.ListDiv = false;
      this.clearbutton = true;
      this.buttonValue = this.constants.Save;
      // this.resetSearchableField();

    }
    else if (this.TCreateUpdate == "0") {
      this.SearchControl = true;
      this.userSearch = "";

    }
  }
  getAllCompany_search(companyId) {
    this.companyService.GetDropdownCompanyHierarchyModel(this.companyLoginId).subscribe(
      data => {
        this.data = data;
        this.items_search = this.data.dataList;
        this.companyList_search = this.data.dataList;
        // this.companyList_search.forEach((x) => {
        //   this.userGroupObjects_search.push(new TreeviewItem(x, false));
        // });
        this.value_search = Number(companyId);

      });
  }
  onValueChange_search(value: any): void {
    this.value_search = value;
  }
  getAllCompany(companyId) {
    this.companyService.GetDropdownCompanyHierarchyModel(companyId).subscribe(
      data => {
        this.data = data;
        if (this.data.dataList != null && this.data.dataList != undefined) {
          this.companyList = this.data.dataList;
          // this.companyList.forEach((x) => {
          //   this.userGroupObjects.push(new TreeviewItem(x, false));
          // });
        }
        this.value = Number(companyId);
      });
  }
  getSearchableFields(companyId, SearchItem) {
    this.sharedService.getAllSearchableField(companyId, SearchItem).subscribe(
      data => {
        this.loader = true;
        this.data = data;
        this.seachableDataList = this.data.dataList;
        this.loader = false;
        this.rerender();
        this.ListShow = true;
        this.ListDiv = true;
        this.ListAngle = this.constants.UpArrow;
      });
  }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next('');
    });
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  AllSearchableFields() {
    this.SearchType = true;
    if (this.value_search == 0) {
      this.SearchValidation = true;
      return;
    }
    this.getSearchableFields(this.value_search, this.userSearch);
  }
  ngAfterViewInit() {
    this.settingDetailCSS = localStorage.getItem("settingDetailCSS");
    this.settingLogoImage = localStorage.getItem("settingImageLogo");
    this.SetSettingCSS(this.settingDetailCSS, this.settingLogoImage);
    this.dtTrigger.next('');
  }
  SetSettingCSS(settingCSS, logoImagePath) {
    if (settingCSS != null) {
      let headerCss = document.getElementsByClassName(this.constants.CssHealerClass) as HTMLCollectionOf<HTMLElement>;
      if (headerCss.length != 0) {
        headerCss[0].style.backgroundColor = settingCSS.split(',')[0];
      }
      let saveButtonCss = document.getElementsByClassName(this.constants.CssSaveBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (saveButtonCss.length != 0) {
        saveButtonCss[0].style.backgroundColor = settingCSS.split(',')[1];
      }
      let cencelButtonCss = document.getElementsByClassName(this.constants.CssCancelBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (cencelButtonCss.length != 0) {
        cencelButtonCss[0].style.backgroundColor = settingCSS.split(',')[2];
      }
    }

    if (logoImagePath != null && logoImagePath != "") {
      (document.getElementById('hrdlogoImage') as HTMLImageElement).src = logoImagePath;
    }

  }
  ListDivShowHide() {
    if (this.ListDiv == true) {
      this.ListDiv = false;
      this.ListAngle = this.constants.DownArrow;
    }
    else if (this.ListDiv == false) {
      this.ListDiv = true;
      this.ListAngle = this.constants.UpArrow;
    }
  }
  addSearchableField() {
    this.validation = true;
    this.clearbutton = true;
    if (this.form.controls.dbName.status === this.constants.Invalid || this.form.controls.labelName.status === this.constants.Invalid
    ) {
      return;
    }

    this.loader = true;
    const obj = {};
    obj['databaseFieldName'] = this.dbName;
    if (this.dbName == "PO") {
      obj['defaultUI'] = 'purchasorder';
      obj['dbName'] = 'PO#';
    }
    else if (this.dbName == 'SO') {
      obj['defaultUI'] = 'order';
      obj['dbName'] = 'SO#';
    }
    else if (this.dbName == 'Company Part Name' || this.dbName == 'Company Part Number' || this.dbName == 'Vendor Part Name' || this.dbName == 'Vendor Part Number') {
      obj['defaultUI'] = 'parts';
      switch (this.dbName) {
        case 'Company Part Name':
          obj['dbName'] = 'Company_Part_Name';
          break;
        case 'Company Part Number':
          obj['dbName'] = 'Company_Part_Number';
          break;
        case 'Vendor Part Name':
          obj['dbName'] = 'Vendor_Part_Name';
          break;
          case 'Vendor Part Number':
            obj['dbName'] = 'Vendor_Part_Number';
            break;
        default:
          obj['dbName'] = '';
          break;
      }
    }
    else if (this.dbName == 'Vendor Name') {
      obj['defaultUI'] = 'vendors';
      obj['dbName'] = 'Vendor_Name';
    }
    else if (this.dbName == 'Customer Name') {
      obj['defaultUI'] = 'customers';
      obj['dbName'] = 'Customer_Name';
    }
    else {

    }
    obj['labelName'] = this.labelName;

    obj['CompanyId'] = this.value;
    obj['CreatedBy'] = this.userId;
    if (this.id != undefined && this.id != null && this.id != '') {
      obj['Id'] = Number(this.id);
      obj['ModifiedBy'] = this.userId;
      obj['CreatedDate'] = this.createdDateEdit;
      this.sharedService.updateSearchableField(obj)
        .subscribe(
          data => {
            switch (data.statusCode) {
              case 200:
                this.loader = false;
                this.buttonValue = this.constants.Save;
                this.dbName = '';
                this.labelName = '';
                // this.TCreateUpdate = 1;
                // this.SearchControl = false;
                // this.ListShow = false;
                // this.ListDiv = false;
                this.validation = false;
                this.id = '';
                this.resetSearchableField();
                this.sharedService.isChangeDbValue.next(true);
                this.modalSuccess(this.constants.RecordUpdateSuccessfully);
                this.AllSearchableFields();
                break;
              case 208:
                this.loader = false;
                this.modalError(this.constants.RecordAlreadyExist);
                break;
              case 400:
                this.loader = false;
                this.modalError(this.constants.SomethingWentWrong);
                break;

              default:
                this.loader = false;
                this.modalError(this.constants.SomethingWentWrong);
            }
          }
        );
    } else {
      this.sharedService.AddSearchableField(obj)
        .subscribe(
          data => {
            switch (data.statusCode) {
              case 200:
                this.loader = false;
                this.validation = false;
                this.resetSearchableField();
                this.sharedService.isChangeDbValue.next(true);
                this.modalSuccess(this.constants.RecordAddedSuccessfully);
                this.AllSearchableFields();
                break;
              case 400:
                this.loader = false;
                this.modalError(this.constants.SomethingWentWrong);
                break;
              case 208:
                this.loader = false;
                this.modalError(this.constants.RecordAlreadyExist);
                break;
              default:
                this.loader = false;
                this.modalError(this.constants.SomethingWentWrong);
            }
          }
        );
    }
  }
  modalSuccess(msg) {
    this.messageModalImage = this.constants.SuccessPng;
    this.messageModalDescription = msg;
    this.modalService.dismissAll();
    this.modalService.open(this.messageModal, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
  }
  modalError(msg) {
    this.messageModalImage = this.constants.ClosePng;
    this.messageModalDescription = msg;
    this.modalService.dismissAll();
    this.modalService.open(this.messageModal, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
  }

  resetSearchableField() {
    this.id = 0;
    if (this.companyLoginId > 0) {
      this.value = Number(this.companyLoginId)
    }
    else {
      this.value = 0;
    }
    this.dbName = '';
    this.labelName = '';
  }
  toggleEdit(item: any) {
    this.ListDiv = false;
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.clearbutton = false;
    this.buttonValue = this.constants.Update;
    this.id = item.id;
    this.value = item.companyId;
    this.labelName = item.labelName;
    this.dbName = item.databaseFieldName;
    this.createdDateEdit = item.createdDate == null ? '' : item.createdDate == undefined ? '' : item.createdDate;
  }
  SearchableFieldBack() {
    this.clearbutton = true;
    this.id = null;
    this.buttonValue = this.constants.Save;
    this.resetSearchableField();
  }
  searchFields() {
    this.SearchType = false;
    if (!this.userSearch || this.value_search == 0) {
      this.SearchValidation = true;
      return;
    }
    this.getSearchableFields(this.value_search, this.userSearch);
  }
  deletePopup(item, content) {
    this.deletedItem = '';
    this.modalService.open(content, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
    this.deleteDetail = this.constants.DeletePopup;
    this.deletedItem = item.id;
  }
  deleteSearchableField(item: any) {
    this.sharedService.deleteSearchableField(item).subscribe(
      data => {

        this.data = data;
        this.loader = true;
        switch (data.statusCode) {
          case 200:
            this.loader = false;
            this.getSearchableFields(this.value_search, this.userSearch == "" ? "" : this.userSearch);
            this.rerender();
            this.sharedService.isChangeDbValue.next(true);
            this.modalSuccess(this.constants.RecordDeletedSuccessfully);
            break;
          case 400:
            this.loader = false;
            this.modalError(this.constants.SomethingWentWrong);
            break;
          default:
            this.loader = false;
            this.modalError(this.constants.SomethingWentWrong);
        }
      });
  }

  //ankit implemenation
  ErrorModal(title:string, message:string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }
}
