import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PurchaseOrderService } from 'src/app/services/purchaseOrder/purchase-order.service';

@Component({
  selector: 'app-po-history',
  templateUrl: './po-history.component.html',
  styleUrls: ['./po-history.component.css']
})
export class PoHistoryComponent implements OnInit{
  PurchaseOrderPDF: any = [];
  @Input() logList: any;
  @Input() IsHistoryFlyOut: boolean;
  @Output() HistoryFlyOut:EventEmitter<any> = new EventEmitter();
  @Output() GeneratPdfEmit:EventEmitter<any> = new EventEmitter()
  statuslist: any = [];

  constructor(private POService: PurchaseOrderService){}
  ngOnInit(): void {
  }

  CloseHistoryFlyOut() {
    this.IsHistoryFlyOut = false;
    this.HistoryFlyOut.emit(this.IsHistoryFlyOut);
  }

  POStatusList() {
    this.POService.GetPOStatus().subscribe(
      (data: any) => {
        this.statuslist = data.filter(x => x.isPurchaseOrder == true).sort((a, b) => a.poOrdering - b.poOrdering);
      }
    )
  }

  GetStatusKey(id: any) {
    if (this.statuslist.length > 0 && id > 0) {
      const list = this.statuslist.filter(x => x.statusId == Number(id));
      if (list.length > 0) {
        return list[0].statusName;
      }
    }
    return '';
  }

  GeneratePOPdf(snap, poOrderNumber){
    const data = {
      snap: snap,
      poOrderNumber: poOrderNumber
    }
    this.GeneratPdfEmit.emit(data);
  }
}
