import { Injectable, ViewChild } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs';
// import { CanLeave } from '@angular/router';
export interface CanExit {
  canDeactivate: () =>  Observable<boolean>|Promise<boolean> |boolean;
} 
  
@Injectable()  
export class FormGuardService implements CanDeactivate<CanExit>  {  
  constructor() {  
  
  }  
  
  canDeactivate(component: CanExit) {
    
if (component.canDeactivate != null) {

 var test = component.canDeactivate();

return test;
}
return true;
}

// canLeave(): boolean | Observable<boolean> | Promise<boolean> {
//   // Your logic to prevent the user from leaving the component
//   if (component.canDeactivate) {

//     var test = component.canDeactivate();
   
//    return test;
//    }
//   return true;
// }
}  



// @Injectable({
//   providedIn: 'root'
// })

// export class FormGuardService implements CanDeactivate<CustomPartComponent>{

//   constructor(public confirmationService:ConfirmationDialogService) { }

//   canDeactivate(component: CustomPartComponent): Promise<boolean> {
//     if (component.form.dirty) {
//       return this.confirmationService.confirm('Confirmation','Are you sure, you want to exit without saving ?','Leave','Cancle','lg');
//     }
//   }
  
// }
