<link rel="stylesheet" href="https://unpkg.com/ngx-bootstrap/datepicker/bs-datepicker.css">
<section class="r_report content-section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-2">
                <form [formGroup]="reportFormforSO">
                    <div class="left-img">
                        <!-- <img src="http://localhost:4200/assets/image/logo.jpg" alt=""> -->
                        <h5 class="mt-2">mark@911vehicle.com</h5>
                        <h5>911vehicle.com</h5>
                    </div>
                    <div class="p-2 blue-txt text-center shadow">
                        <h4 style="font-weight: 20px;">Total #PO's</h4>
                    <h4>{{this.totalrows}}</h4>
                    </div>
                    <!-- <div class="left-gray-box mt-2 shadow">
                        <div class="form-group mb-0">
                            <label for="">Company Name</label>
                            <div class="form-control"
                                [ngClass]="((value_search==0) && (SearchValidation)) ? 'validation_border': ''">
                                <ngx-dropdown-treeview-select [config]="config"
                                    class="dropdown-company customInput-dropdown" [items]="userGroupObjects_search"
                                    [(value)]="value_search" (valueChange)="onValueChange_search($event)">
                                </ngx-dropdown-treeview-select>
                            </div>

                        </div>
                    </div> -->

                    <div class="left-gray-box mt-2 shadow">
                        <div class="mb-0">
                            <div class="form-group dflex">
                                <input class="form-control-input"  type="radio" name="allDateRange"
                                    checked="checked" formControlName="allDateRange"
                                    (change)="changeAllDateOrder($event)" id=dateRange1 value="">
                                    <label class="form-control-label" for="dateRange1">All Order</label>
                            </div>
                            <div class="form-group dflex mb-0">                 
                                <input class="form-control-input" type="radio" name="allDateRange"
                                    formControlName="allDateRange" (change)="changeAllDateOrder($event)" id=dateRange
                                    value="allDateRange">
                                    <label class="form-control-label" for="dateRange">Order Date Range</label>
                            </div>
                            <!-- <label for="">Order Date</label> -->
                            <div class="d-flex mt-2 flex-column" *ngIf="IsDateRange==true">
                                <input type="date" class="customInput mr-1" [(ngModel)]="fromDate"
                                    formControlName="fromDate">
                                <input type="date" class="customInput mt-1" [(ngModel)]="toDate"
                                    formControlName="toDate">
                            </div>
                        </div>
                    </div>
                    <div class="left-gray-box mt-2 shadow">
                        <div class="form-group mb-0">
                            <label for="">Vendor</label>
                            <select class="form-control" name='vendorVal' id="vendorVal" [(ngModel)]="vendorVal"
                                formControlName="vendorVal" (change)="vendorvalues($event)">
                                <option value=0>All</option>
                                <option *ngFor="let item of vendorlist" [value]="item.id">
                                    {{item.vendorName}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="left-gray-box mt-2 shadow">
                        <div class="form-group mb-0">
                            <label for="">Order Status</label>
                            <select class="form-control" name='statusId' id="statusvalues" [(ngModel)]="statusIdVal"
                                formControlName="statusIdVal" (change)="statusvalues($event)">
                                <option value='0'>All</option>
                                <option *ngFor="let item of POstatusList" [value]="item.id">
                                    {{item.name}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="left-gray-box mt-2 shadow">
                        <button class="form-control" (click)="GetPurchaseOrderReportData()">Search <span> <i
                                    class="fas fa-search cpointer"></i></span> </button>
                    </div>
                    <!-- <div class="left-gray-box mt-2 shadow ">
                    <div class="form-control">  
                        <button class="ml-autoBtn" >PDF</button>
                        <button  class="ml-autoBtn">Print</button> 
                        <button class="ml-autoBtn" >Excel</button> 
                    </div>
                  </div>  -->
                    <!-- <div class="left-gray-box mt-2 shadow">
                        <div class="form-group">
                            <label for="">So Prefix</label>
                            <select class="form-control" id="">
                              <option>ALL</option>
                              <option>2</option>
                              <option>3</option>
                              <option>4</option>
                              <option>5</option>
                            </select>
                          </div>
                     </div> -->
                </form>
            </div>

            <div class="col-md-10 right-blk">
                <div class="row three-map-blk">
                    <div class="col-md-4">
                        <div class="map-box-custom shadow">
                            <h4>Labor Revenue and Part Revenue</h4>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="map-box-custom shadow">
                            <h4>GrossProfit</h4>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="map-box-custom shadow">
                            <h4>Count Of</h4>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="shadow">
                            <div class="table-report" >
                                <h2 class="headingNew">Purchase Order Report</h2>
                                <table id="datatableSOReport" cellspacing="0" class="no-spacing"  class="display table table-responsive clearfix" datatable [dtOptions]="dtOptions"[dtTrigger]="dtTrigger">
                                    <thead>
                                        <tr>  
                                            <th hidden></th>
                                            <!-- <th>PO<span id="po"></span></th>-->
                                            <th (click)="SortTableHeader('po')">PO<span id="po" class="sorting1"></span></th>  
                                            <th (click)="SortTableHeader('purchaseOrderPrefix')">PO Prefix<span   id="purchaseOrderPrefix" class="sorting1"></span></th>                                    
                                            <th (click)="SortTableHeader('poStatus')">Status<span id="poStatus" class="sorting1"></span></th>
                                            <th (click)="SortTableHeader('vendorName')">Vendor<span id="vendorName" class="sorting1"></span></th>
                                            <th (click)="SortTableHeader('dateOrder')">Order Date<span id="dateOrder" class="sorting1"></span></th>
                                            <th (click)="SortTableHeader('dateSchedule')">Ship Date<span id="dateSchedule" class="sorting1"></span></th>
                                            <th class="text-right"style="width: 30px;">Total<span id="total"></span></th>

                                        </tr>
                                    </thead>
                                    <tbody *ngIf="purchageorderList?.length != 0">
                                        <tr *ngFor="let datalist of purchageorderList">
                                            <td class="double-border">{{datalist.po}}<span
                                                class="text-right"></span></td>                                         
                                            <td class="double-border">{{datalist.purchaseOrderPrefix}}<span
                                                    class="text-right"></span></td>
                                            <td class="double-border">{{datalist.poStatus}}<span
                                                    class="text-right"></span></td>
                                            <td class="double-border">{{datalist.vendorName}} <span
                                                    class="text-right"></span></td>
                                            <td class="double-border">{{datalist.dateOrder}} <span
                                                    class="text-right"></span></td>
                                            <td class="double-border">{{datalist.dateSchedule}} <span
                                                    class="text-right"></span></td>
                                            <!-- <td class="double-border">{{datalist.dateSchedule|date:"MMM-dd-yyyy"}} <span
                                                        class="text-right"></span></td> -->
                                            <td class="double-border">{{datalist.totalAmount|currency}} <span
                                                    class="text-right"></span></td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="purchageorderList!=null" >
                                        <tr>
                                            <td ><span style="font-weight:bold">Total:</span></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td></td>
                                            <td class="double-border text-right"><span style="font-weight:bold">{{totalAmountAll== 0 ? '':totalAmountAll|currency}}</span> </td>
                                        </tr>
                                    </tbody>

                                    <tbody *ngIf="purchageorderList==null">
                                        <tr class="c1">
                                            <td colspan="11" class="no-data-available">No data available in table</td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                            <!-- <div class="d-flex align-items-center">
                             <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">Showing 1 to 10 of 22 entries</div>
                             <nav aria-label="Page navigation" class="ml-auto">
                                <ul class="pagination">
                                  <li class="page-item"><a class="page-link" href="#">Previous</a></li>
                                  <li class="page-item"><a class="page-link" href="#">1</a></li>
                                  <li class="page-item"><a class="page-link" href="#">2</a></li>
                                  <li class="page-item"><a class="page-link" href="#">3</a></li>
                                  <li class="page-item"><a class="page-link" href="#">Next</a></li>
                                </ul>
                              </nav>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>