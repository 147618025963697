import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { map, Observable, startWith } from 'rxjs';
import { ConstantsService } from 'src/app/constants.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { InventoryService } from 'src/app/services/inventory/inventory.service';
import { PartService } from 'src/app/services/part/part.service';
import { PutAwayService } from 'src/app/services/put-away.service';
import { SharedService } from 'src/app/services/shared.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-replenishment',
  templateUrl: './replenishment.component.html',
  styleUrls: ['./replenishment.component.css']
})
export class ReplenishmentComponent implements OnInit {
  isPart: boolean = false;
  isDestination: boolean = false;
  isDestinationChange: boolean = false;
  loader: boolean = false;
  partList: any = [];
  CompanyWareHouseList: any = [];
  RecLocationList: any = [];
  ConstImgURL: string = '';
  wareHouseId: any;
  locationId: any;
  locationForm: FormGroup;
  tempList: any = [];
  level1List: any = [];
  level2List: any = [];
  level3List: any = [];
  level4List: any = [];
  partId: number;
  sourceLoc: any = [];
  companyId: number;
  
  myControl = new FormControl('');
  filteredOptions: Observable<any[]>;

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private paservice: PutAwayService,
    private partService: PartService,
    private companyService: CompanyService,
    private constants: ConstantsService,
    private sharedService: SharedService,
    private inventoryService: InventoryService
  ) {
    this.ConstImgURL = constants.PickListImagURL;
    this.tempList = constants.tempList;
    this.CreateLocationForm();
  }

  ngOnInit(): void {
    if(this.data!='' || this.data!=null || this.data!=undefined){
      if (this.data.partId > 0) {
        this.isPart = true;
        this.companyId = this.data.com;
        this.InitFn();
        this.getPartId(this.data.partId);
      }else{
        this.isPart = false;
        this.companyId = this.data.com;
        this.InitFn();
      }
    }
  }

  InitFn(){
    this.GetAllPartsList();
    this.filterPartData();
    this.getWareHouseWithChild(this.companyId);
    this.getLocationName(this.companyId);
  }

  locationChange() {
    this.isDestinationChange = !this.isDestinationChange;
  }

  isPartChange() {
    this.isPart = !this.isPart;
  }

  CreateLocationForm() {
    this.locationForm = new FormGroup({
      partId: new FormControl(0),
      imgPath: new FormControl(''),
      replenishmentItem: new FormControl(0),
      SKU: new FormControl(''),
      wareHouse: new FormControl(''),
      wareHouseId: new FormControl(0),
      locationName: new FormControl(''),
      locationId: new FormControl(0),
      aisle: new FormControl(''),
      section: new FormControl(''),
      shelf: new FormControl(''),
      bin: new FormControl(''),
      onHand: new FormControl(0),
      locationCapacity: new FormControl(0),
      moveQty: new FormControl(0, Validators.required),
      sourceLocation: new FormControl('', Validators.required),
    })
  }

  moveQtyChange(value: any){
    if(this.locationForm.value.sourceLocation!='' && this.locationForm.value.sourceLocation!=undefined && this.locationForm.value.sourceLocation!= null){
      if(Number(value)>Number(this.locationForm.value.sourceLocation.balance)){
        this.locationForm.patchValue({moveQty: this.locationForm.value.sourceLocation.balance});
      }else{
        this.locationForm.patchValue({moveQty: value});
      }
    }else{
      if(Number(this.locationForm.value.moveQty)>Number(this.locationForm.value.replenishmentItem)){
        this.locationForm.patchValue({moveQty: this.locationForm.value.replenishmentItem});
      }else{
       this.locationForm.patchValue({moveQty: Number(value)}); 
      }
    }
  }

  sourceLocChange(value: any){
    this.locationForm.patchValue({moveQty: value.balance});
  }

  getPartId(partId: number) {
    this.partId = partId;
    this.paservice.GetDefaultPartData(partId).subscribe((data: any) => {
      if(data.data!=null){
        this.isDestination = true;
        if(data.data.destination!=null){
          if(data.data.destination.locationId != null &&  data.data.destination.locationId != '' && data.data.destination.locationId != undefined){
            this.locNameChange(data.data.destination.locationId)
          }
          this.locationForm.patchValue({
            partId: partId,
            imgPath: data.data.destination.imgPath,
            onHand: data.data.destination.onHand,
            locationCapacity: data.data.destination.locationCapacity,
            replenishmentItem: (data.data.destination.locationCapacity) - (data.data.destination.onHand),
            moveQty: (data.data.destination.locationCapacity) - (data.data.destination.onHand),
            SKU: data.data.destination.sku,
            wareHouseId: data.data.destination.wareHouseId,
            wareHouse: this.GetWareHouseValue(data.data.destination.wareHouseId),
            locationId: data.data.destination.locationId,
            locationName: this.GetLocationValue(data.data.destination.locationId),
            aisle: data.data.destination.level1,
            section: data.data.destination.level2,
            shelf: data.data.destination.level3,
            bin: data.data.destination.level4
          });
        }
        
        if(data.data.source!=null){
          if(data.data.source.length > 0){
            this.sourceLoc = data.data.source.filter(item => typeof item.balance === 'number' && item.balance > 0);
          }else{
            this.sourceLoc = [];
          }
        }
      }
      this.myControl = new FormControl('');
      this.isPart = true;
    })
  }

  filterPartData(){
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );
  }

  _filter(value: any) {
    const filterValue = value.toLowerCase();
    return this.partList.filter(x => x.name.toLowerCase().includes(filterValue));
  }

  getWareHouseWithChild(companyId: Number) {
    this.companyService.GetWareHouseWithChild(companyId).subscribe(
      (data: any) => {
        if (data.data != null) {
          this.CompanyWareHouseList = data.data;
        }
      }
    )
  }

  GetWareHouseValue(id): String {
    if (this.CompanyWareHouseList.length > 0) {
      const wareHouseList = this.CompanyWareHouseList.filter(x => x.id == Number(id));
      if (wareHouseList.length > 0) {
        return wareHouseList[0].name;
      }
    }
    return '';
  }

  getLocationName(companyId: number) {
    this.sharedService.getLocationLevel(companyId, '').subscribe((data: any) => {
      this.RecLocationList = data.dataList;
    },
      (error) => {
        this.popupModal(this.constants.APIError, error);
      }
    )
  }

  GetLocationValue(id): String {
    if (this.RecLocationList.length > 0) {
      const locationList = this.RecLocationList.filter(x => x.id == id);
      if (locationList.length > 0) {
        return locationList[0].locationName;
      }
    }
    return 'N/A';
  }

  popupModal(title: string, message: string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }

  Counter(type: string) {
    if(this.locationForm.value.sourceLocation!='' && this.locationForm.value.sourceLocation!=undefined && this.locationForm.value.sourceLocation!= null){

    }else{
      if (type == 'add' && this.locationForm.value.moveQty<this.locationForm.value.replenishmentItem) {
        this.locationForm.patchValue({moveQty : (this.locationForm.value.moveQty+1)});
      } else if (type == 'sub' && this.locationForm.value.moveQty>0) {
        this.locationForm.patchValue({moveQty : (this.locationForm.value.moveQty-1)});
        this.locationForm.value.moveQty < 0 == true ? (this.locationForm.patchValue({moveQty : 0})) : (this.locationForm.patchValue({moveQty : Number(this.locationForm.value.moveQty)}));
      }
    }
  }

  locNameChange(value: any) {
    this.level1List = [];
    this.level2List = [];
    this.level3List = [];
    this.level4List = [];
    this.locationForm.patchValue(
      { locationId: value, 
        locationName: this.GetLocationValue(value),
        aisle: '',
        section: '',
        shelf: '',
        bin: ''
      });
    this.getLocationLevels(this.companyId, value);
  }

  getLocationLevels(companyId: number, locationId: number) {
    this.loader = true;
    this.inventoryService.GetLocationLevelsPositions(companyId, locationId)
      .subscribe({
        next: (data: any) => {
          this.loader = false;
          if (data.dataList.length > 0) {
            const Asile = data.dataList.map(function (item) { return item['aileName']; });
            const Section = data.dataList.map(function (item) { return item['sectionName']; });
            const Shelf = data.dataList.map(function (item) { return item['shelfName']; });
            const Bin = data.dataList.map(function (item) { return item['positionName']; });
            this.level1List.push(...(Asile.filter((value, index, array) => array.indexOf(value) === index)));
            this.level2List.push(...(Section.filter((value, index, array) => array.indexOf(value) === index)));
            this.level3List.push(...(Shelf.filter((value, index, array) => array.indexOf(value) === index)));
            this.level4List.push(...(Bin.filter((value, index, array) => array.indexOf(value) === index)));
          }
        },
        error: (error: any) => {
          this.loader = false;
          this.popupModal(this.constants.APIError, error);
        },
        complete: () => {

        }
      });
  }

  onloclevelChange(level: Number, value: any) {
    switch (level) {
      case 1:
        this.locationForm.patchValue({ aisle: value });
        break;
      case 2:
        this.locationForm.patchValue({ section: value });
        break;
      case 3:
        this.locationForm.patchValue({ shelf: value });
        break;
      case 4:
        this.locationForm.patchValue({ bin: value });
        break;
    }
  }

  // Method to save Replenishment on Put AwaY from Create Replenishmnet
  PopupModal(title: string, message: string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }

  Save() {
    const DestinationLocation = {
      WareHouseId: Number(this.locationForm.value.wareHouseId),
      LocationId: Number(this.locationForm.value.locationId),
      LocationAddress: (this.locationForm.value.aisle ? this.locationForm.value.aisle : '') + (this.locationForm.value.section ? '-' + this.locationForm.value.section : '') + (this.locationForm.value.shelf ? '-' + this.locationForm.value.shelf : '') + (this.locationForm.value.bin ? '-' + this.locationForm.value.bin : '') ,
      OnHand: Number(this.locationForm.value.onHand),
      LocationCapacity: Number(this.locationForm.value.locationCapacity)
    }
    const SourceLocation = {
      WareHouseId: Number(this.locationForm.value.sourceLocation.wareHouseId),
      LocationId: Number(this.locationForm.value.sourceLocation.locationName),
      LocationAddress: (this.locationForm.value.sourceLocation.location),
      Balance: Number(this.locationForm.value.sourceLocation.balance),
    }
    const reqObj = {
      PartId : Number(this.partId),
      Quantity : Number(this.locationForm.value.moveQty),
      CompanyId: Number(this.companyId),
      SourceLocation: SourceLocation,
      DestinationLocation: DestinationLocation
    };
    if(Number(this.locationForm.value.moveQty)>0){
      this.paservice.CreateReplenishment(reqObj).subscribe(
        (data) => {
          this.loader = false;
        },
        (error) => {
          this.loader = false;
          this.PopupModal(this.constants.APIError, error);
        }
      );
    }
  }

  GetAllPartsList() {
    this.loader = true;
    this.partService.GetPartsByCompanyId(this.companyId)
      .subscribe({
        next: (data: any) => {
        this.partList = data;
      },
        error: (err) => {
          this.partList = [];
          this.loader = false
        },
      });
  }
}


