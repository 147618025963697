<section class="content-section pt-2">
		
    <div class="page-title mb-4">
        <!-- <h2>Company Hierarchy</h2> -->
        <div class="bread-crumb">
            <a href="#!">Home</a>
            <span class="mx-1">-</span>
            <span class="active">Company Hierarchy</span>
        </div>
    </div>
    
    <div class="row">
        <div class="col-sm-12">
            <div class="white-box">
                <div class="my-menu">
                    <ul class="my-nav">
                      <li>
                        <a href="#">
                            <div class="company-logo d-inline-block"><img src="img/company-logo.jpg" /></div>			
                            <div class="d-inline-block company-info-main">
                                Region
                                <p class="company-info">1509 Johnson Ave - 26330-1016</p>
                            </div>						  
                        </a>
                        <ul>
                          <li>
                         <a href="#">
                            <div class="company-logo d-inline-block"><img src="img/company-logo.jpg" /></div>			
                            <div class="d-inline-block company-info-main">
                                Division
                                <p class="company-info">1509 Johnson Ave - 26330-1016</p>
                            </div>						  
                        </a>
                            <ul>
                                <li>
                                    <a href="#">
                                    <div class="company-logo d-inline-block"><img src="img/company-logo.jpg" /></div>			
                                    <div class="d-inline-block company-info-main">
                                        Division
                                        <p class="company-info">1509 Johnson Ave - 26330-1016</p>
                                    </div>						  
                                    </a>
                                </li>
                                    <li><a href="#">
                                <div class="company-logo d-inline-block"><img src="img/company-logo.jpg" /></div>			
                                <div class="d-inline-block company-info-main">
                                    Branch
                                    <p class="company-info">1509 Johnson Ave - 26330-1016</p>
                                </div>						  
                            </a></li>
                            </ul>
                          </li>
                          <li><a href="#">
                            <div class="company-logo d-inline-block"><img src="img/company-logo.jpg" /></div>			
                            <div class="d-inline-block company-info-main">
                                Branch
                                <p class="company-info">1509 Johnson Ave - 26330-1016</p>
                            </div>						  
                        </a>
                            <ul>
                                <li><a href="#">
                            <div class="company-logo d-inline-block"><img src="img/company-logo.jpg" /></div>			
                            <div class="d-inline-block company-info-main">
                                Region
                                <p class="company-info">1509 Johnson Ave - 26330-1016</p>
                            </div>						  
                        </a></li>
                                <li><a href="#">
                            <div class="company-logo d-inline-block"><img src="img/company-logo.jpg" /></div>			
                            <div class="d-inline-block company-info-main">
                                Region
                                <p class="company-info">1509 Johnson Ave - 26330-1016</p>
                            </div>						  
                        </a></li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                      <li>								  
                        <a href="#">
                            <div class="company-logo d-inline-block"><img src="img/company-logo2.jpg" /></div>			
                            <div class="d-inline-block company-info-main">
                                Division
                                <p class="company-info">1509 Johnson Ave - 26330-1016</p>
                            </div>						  
                        </a>
                        <ul>
                            <li><a href="#">
                                User 1
                                <p class="company-info">1509 Johnson Ave - 26330-1016</p>
                            </a></li>
                        </ul>
                      </li>
                      <li>
                        <a href="#">
                            <div class="company-logo d-inline-block"><img src="img/company-logo.jpg" /></div>			
                            <div class="d-inline-block company-info-main">
                                Branch
                                <p class="company-info">1509 Johnson Ave - 26330-1016</p>
                            </div>						  
                        </a>
                        <ul>
                          <li><a href="#">Region
                            <p class="company-info">1509 Johnson Ave - 26330-1016</p></a>
                            <ul>
                              <li><a href="#">Reseller
                                <p class="company-info">1509 Johnson Ave - 26330-1016</p></a>
                                <ul>
                                  <li><a href="#">Division
                                    <p class="company-info">1509 Johnson Ave - 26330-1016</p></a>
                                    <ul>
                                        <li><a href="#">
                                            User 1
                                            <p class="company-info">1509 Johnson Ave - 26330-1016</p>
                                        </a></li>
                                    </ul>
                                  </li>
                                </ul>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                    </ul>
                </div>





            </div>
        </div>
    </div>

</section>