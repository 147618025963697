import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';
import { ClientPrintJob, DefaultPrinter, FileSourceType, InstalledPrinter, JSPrintManager, PrintFilePDF, WSStatus } from 'src/assets/js/JSPrintManager';

@Component({
  selector: 'app-jsprint',
  templateUrl: './jsprint.component.html',
  styleUrls: ['./jsprint.component.css']
})
export class JSPrintComponent implements OnInit { 
  title = 'JSPrintManager in Angular';
  printers: string[];
  selectedPrinter: any;
  isDefaultPrinterSelected = false;
  

  constructor(
    public sharedService: SharedService
  ) {
  }

  ngOnInit() {
    // WebSocket settings
    JSPrintManager.auto_reconnect = true;
    JSPrintManager.start();
    JSPrintManager.WS.onStatusChanged = () => {
        if (this.jspmWSStatus()) {
            // get client installed printers
            JSPrintManager.getPrinters().then((myPrinters: string[]) => {
              this.printers = myPrinters;
              console.log(this.printers);
            });
        }
    }; 
  }
  // Check JSPM WebSocket status
  jspmWSStatus() {
    if (JSPrintManager.websocket_status === WSStatus.Open) {
        return true;
    } else if (JSPrintManager.websocket_status === WSStatus.Closed) {
        console.log('JSPrintManager (JSPM) is not installed or not running! Download JSPM Client App from https://neodynamic.com/downloads/jspm');
        return false;
    } else if (JSPrintManager.websocket_status === WSStatus.Blocked) {
        console.log('JSPM has blocked this website!');
        return false;
    }
  }

  getZPLString(){
    const obj={
      item: "https://api.shipengine.com/v1/downloads/10/Av1_R2NK3EWitC2_uwfnsQ/labelpackage-539581254.zpl"
    }
    this.sharedService.GetZplString(obj).subscribe(
      (data: any) => {
        const response: any = data;
        console.log(response)
        this.doPrintZPL(response.data)
      },
      (error) => {
        
      }
    );
  }

  // Do Zebra ZPL printing...
  doPrintZPL(cmds : string) {
    console.log(this.selectedPrinter);
    if (this.selectedPrinter !== 'undefined' && this.jspmWSStatus()) {
        // Create a ClientPrintJob
        const cpj = new ClientPrintJob();
        // Set Printer type (Refer to the help, there many of them!)
		//https://www.neodynamic.com/Products/Help/JSPrintManager4.0/articles/jsprintmanager.html#client-printer-types
        if ( this.isDefaultPrinterSelected ) {
          cpj.clientPrinter = new DefaultPrinter();
        } else {
          cpj.clientPrinter = new InstalledPrinter(this.selectedPrinter);
        }

        // Set content to print...
        //Create Zebra ZPL commands for sample label
		// var cmds =  "^XA";
		// cmds += "^FO20,30^GB750,1100,4^FS";
		// cmds += "^FO20,30^GB750,200,4^FS";
		// cmds += "^FO20,30^GB750,400,4^FS";
		// cmds += "^FO20,30^GB750,700,4^FS";
		// cmds += "^FO20,226^GB325,204,4^FS";
		// cmds += "^FO30,40^ADN,36,20^FDShip to:^FS";
		// cmds += "^FO30,260^ADN,18,10^FDPart number #^FS";
		// cmds += "^FO360,260^ADN,18,10^FDDescription:^FS";
		// cmds += "^FO30,750^ADN,36,20^FDFrom:^FS";
		// cmds += "^FO150,125^ADN,36,20^FDAcme Printing^FS";
		// cmds += "^FO60,330^ADN,36,20^FD14042^FS";
		// cmds += "^FO400,330^ADN,36,20^FDScrew^FS";
		// cmds += "^FO70,480^BY4^B3N,,200^FD12345678^FS";
		// cmds += "^FO150,800^ADN,36,20^FDMacks Fabricating^FS";
		// cmds += "^XZ";
		cpj.printerCommands = cmds;

        console.log(cmds);
        // Send print job to printer!
        cpj.sendToClient();
    }
  }
  
  
  // Do PDF printing...
  doPrintPDF() {
    console.log(this.selectedPrinter);
    if (this.selectedPrinter !== 'undefined' && this.jspmWSStatus()) {
        // Create a ClientPrintJob
        const cpj = new ClientPrintJob();
        // Set Printer type (Refer to the help, there many of them!)
		if ( this.isDefaultPrinterSelected ) {
          cpj.clientPrinter = new DefaultPrinter();
        } else {
          cpj.clientPrinter = new InstalledPrinter(this.selectedPrinter);
        }

        // Set content to print...
        //Set PDF file... for more advanced PDF settings please refer to 
		//https://www.neodynamic.com/Products/Help/JSPrintManager4.0/apiref/classes/printfilepdf.html
		var my_file = new PrintFilePDF('https://neodynamic.com/temp/LoremIpsum.pdf', FileSourceType.URL, 'MyFile.pdf', 1);
		
		cpj.files.push(my_file);

        // Send print job to printer!
        cpj.sendToClient();
    }
  }
  
}
