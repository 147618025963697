<!-- <app-header #getSoftwareVersion></app-header> -->
<section class="content-section pt-2">
    <div class="loading" *ngIf="loader">Loading&#8230;</div>

    <div col-sm-12>
        <div class="row">
            <div class="col-6 mb-2 d-flex align-items-baseline text-xs-left">
                <div class="page-title">
                    <div class="bread-crumb">
                        <a [routerLink]="['/adminDashboard/']">Home</a>
                        <span class="mx-1">-</span>
                        <span class="active">Settings</span>
                        <div *ngIf="permissionMessage!=''" class='btn-danger text-center'>{{permissionMessage}} </div>
                    </div>
                </div>
            </div>
            <div class="col-6 mb-2 text-right align-items-baseline">
            </div>
        </div>
    </div>


    <div class="row">
        <div class="col-md-12">
            <div class="white-box1">
                <div class="card-desc">
                    <!-- <div class="row">
                        <div class="col-lg-4 col-md-12">
                            <div class="row mt-2 required">
                                <div class="col-lg-4 col-md-2 trp">
                                    <label class="customLabel mr-1" for="Type">Company</label>
                                </div>
                                <div class="col-lg-8 col-md-10 borderNone" [ngClass]="((value==undefined||value==0) && (validation)) ? 'validation_border': ''">
                                    <ngx-dropdown-treeview-select class="dropdown-company customInput-dropdown"
                                        [config]="config" [items]="userGroupObjects" [(value)]="value"
                                        (valueChange)="onValueChange($event)">
                                    </ngx-dropdown-treeview-select>
                                </div>
                            </div>
                        </div>

                    </div> -->
                    <div class="row mt-3">
                        <div class="col-lg-4 col-md-12">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp">
                                    <label class="customLabel">Upload logo</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <input type="file" class="form-control choose-file-hidden upload_icon_custom" #Image
                                        accept="image/*" (change)="ChngSettingImage($event.target.files)" id="file">
                                    <img [src]="this.imageUrl" class="uplod-logo" id="uplod-img"
                                        title="Click to change">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-lg-6 col-md-12">
                            <label class="customLabel">Logo size should be 250 px (width) x 100 px (height)</label>
                        </div>

                    </div>
                    <div class="color-setting">
                        <div class="customHeading mt-4">
                            Color setting
                        </div>
                        <!-- <div class="row mt-2">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-lg-4 col-md-4 col-6">
                                        <div class="d-flex align-items-center">
                                            <label class="customLabel">Header Color</label>
                                            <input type="color" id="idHeaderColor" class="ml-4" value="#d23c45"
                                                (click)="clorPicker($event)">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-6">
                                        <div class="d-flex align-items-center">
                                            <label class="customLabel">Save Button Color</label>
                                            <input type="color" id="idSaveButtonColor" class="ml-4" value="#1e9ff2"
                                                (click)="clorPicker($event)">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-4 col-6 mt-md-0 mt-1">
                                        <div class="d-flex align-items-center">
                                            <label class="customLabel">Cancel Button Color</label>
                                            <input type="color" id="idCancelButtonColor" class="ml-4" value="#ce2026"
                                                (click)="clorPicker($event)">
                                        </div>
                                    </div>
                                  
                                </div>
                            </div>
                        </div> -->
                        <div class="row mt-2">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-lg-3 col-md-4 mt-lg-0 mt-1">
                                        <div class="d-flex align-items-center">
                                            <label class="customLabel ws-nobreak">Header Color</label>
                                            <div class="ml-4 part-cost-block">
                                              
                                                <input type="color" id="idHeaderColor" class="ml-4" value="#d23c45"
                                                (click)="clorPicker($event)">
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-3 col-md-4 mt-lg-0 mt-1">
                                        <div class="d-flex align-items-center">
                                            <label class="customLabel ws-nobreak">Save Button Color</label>
                                            <div class="ml-4 part-cost-block">
                                              
                                                <input type="color" id="idSaveButtonColor" class="ml-4" value="#1e9ff2"
                                                (click)="clorPicker($event)">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-4 mt-lg-0 mt-1">
                                        <div class="d-flex align-items-center">
                                            <label class="customLabel ws-nobreak">Cancel Button Color</label>
                                            <div class="ml-4 part-cost-block">
                                            <input type="color" id="idCancelButtonColor" class="ml-4" value="#ce2026"
                                                (click)="clorPicker($event)">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-lg-3 col-md-4 mt-lg-0 mt-1">
                                        <div class="d-flex align-items-center">
                                            <label class="customLabel ws-nobreak">Labor Hourly Rate</label>
                                            <div class="part-cost-block" style="margin-left: 18px;">
                                                <span class="dollar customLabel">$</span>
                                                <input type="text" class="customInput Cost" placeholder="0.00"
                                                    [(ngModel)]="LaborHrsCost" (blur)="formatCurrency()"
                                                    autocomplete="off" maxlength="8" />
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-3 col-md-4 mt-lg-0 mt-1">
                                        <div class="d-flex align-items-center">
                                            <label class="customLabel ws-nobreak">Labor Hourly Loaded Cost</label>
                                            <div class="ml-1 part-cost-block">
                                                <span class="dollar customLabel">$</span>
                                                <input type="text" class="customInput Cost" placeholder="0.00"
                                                    [(ngModel)]="LaborHrsLoadedCost" (blur)="formatCurrency1()"
                                                    autocomplete="off" maxlength="8" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                       

                        <div class="row mt-2">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-lg-1 col-md-2 trp">
                                        <label class="customLabel">Notes: (CS)</label>
                                    </div>
                                    <div class="col-lg-11 col-md-10 pl-1">
                                        <textarea [(ngModel)]="Notes" rows="4" cols="50"
                                            class=" part-cost-block customInput customerTextarea-setting"
                                            placeholder="Enter your notes"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- *ngIf="rolePermissionsList[0].isServices" -->
                        <div *ngIf="rolePermissionsList[0].isServices">
                            <div class="customHeading mt-3">
                                Services
                            </div>
                            <div class="row mt-1">
                                <div class="col-lg-4 col-md-6">
                                    <label class="checkbox-inline customLabel">
                                        <input type="checkbox" class="mt-3px" name="PO" [(ngModel)]="ChkPO"
                                            (click)="GetChkVal($event, 'PO')">&nbsp;&nbsp;Purchase Order,Inventory &
                                        RMA
                                        &nbsp;&nbsp;
                                    </label>
                                </div>
                                <div class="col-lg-4 col-md-6">
                                    <label class="checkbox-inline customLabel">
                                        <input type="checkbox" class="mt-3px" name="Receive" [(ngModel)]="chkReviced"
                                            (click)="GetChkVal($event, 'Receive')">&nbsp;&nbsp;Operations
                                        Management&nbsp;&nbsp;
                                    </label>
                                </div>
                            </div>
                        </div>

                        <!-- *ngIf="rolePermissionsList[0].isPurchaseOrderNumber" -->
                        <div *ngIf="rolePermissionsList[0].isPurchaseOrderNumber">
                            <div class="customHeading mt-3">
                                <!-- Purchase order number -->
                                Default and Starting Values
                            </div>
                            <div class="row mt-2">
                                <div class="col-lg-3 col-md-6 f_size_12">
                                    <label class="customLabel">Purchase order starting value</label>
                                </div>
                                <div class="col-lg-3 col-md-12 mt-lg-0 mt-1">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-2 trp">
                                            <label class="customLabel">Prefix: (CS)</label>
                                        </div>
                                        <div class="col-lg-8 col-md-10">
                                            <input type="text" class="customInput" [(ngModel)]="Prefix"
                                                autocomplete="off"  />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-12 mt-lg-0 mt-1">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-2 trp">
                                            <label class="customLabel">-Number<span style="color:red;">*</span>:</label>
                                        </div>
                                        <div class="col-lg-8 col-md-10">
                                            <input type="text" class="customInput PhoneValidation"
                                                [(ngModel)]="PurchaseNumber" autocomplete="off" [ngClass]="((PurchaseNumber==''||PurchaseNumber==null||PurchaseNumber==undefined) && (validation)) ? 'validation_border': ''">

                                            <!-- <div *ngIf="((PurchaseNumber==''||PurchaseNumber==null||PurchaseNumber==undefined) && (validation))"
                                                class="text-danger input-error" style="font-size:10px;">
                                                Enter Number
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-12 d-flex align-items-center">
                                    <div class="row">
                                        <div class="col-md-12 f_size_10">
                                            <label class="customLabel">Preview:
                                            {{this.Prefix==null?"":this.Prefix}}<span
                                                *ngIf="Prefix!=''" class="customLabel">-</span>{{this.PurchaseNumber==null?"":this.PurchaseNumber}}
                                            </label>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>

                        <div *ngIf="rolePermissionsList[0].isPurchaseOrderNumber">
                            <!-- <div class="customHeading mt-3">
                                Sales order
                            </div> -->
                            <div class="row mt-2">
                                <div class="col-lg-3 col-md-6 f_size_12">
                                    <label class="customLabel">Sales order starting  value</label>
                                </div>
                                <div class="col-lg-3 col-md-6">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <label class="customLabel">Prefix: (CS)</label>
                                        </div>
                                        <div class="col-md-8">
                                            <input type="text" class="customInput" [(ngModel)]="SPrefix"
                                                autocomplete="off" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <label class="customLabel">-Number<span style="color:red;">*</span>:</label>
                                        </div>
                                        <div class="col-md-8">
                                            <input type="text" class="customInput PhoneValidation"
                                                [(ngModel)]="SalesNumber" autocomplete="off"  [ngClass]="((SalesNumber=='' || SalesNumber==null || SalesNumber==undefined) && (validation)) ? 'validation_border': ''">

                                            <!-- <div *ngIf="((SalesNumber=='' || SalesNumber==null || SalesNumber==undefined) && (validation))"
                                                class="text-danger input-error" style="font-size:10px;">
                                                Enter Number
                                            </div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-6 d-flex align-items-center">
                                    <div class="row">
                                        <div class="col-md-12 f_size_10">
                                            <label class="customLabel">Preview:
                                            {{this.SPrefix==null?"":this.SPrefix}}<span
                                                *ngIf="SPrefix!=''" class="customLabel">-</span>{{this.SalesNumber==null?"":this.SalesNumber}}
                                            </label>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>

                        <div>
                             <div class="row mt-2">
                                <div class="col-lg-3 col-md-6 f_size_12">
                                    <label class="customLabel">Days to add to Ship Date</label>
                                </div>
                                <div class="col-lg-3 col-md-6">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <label class="customLabel"></label>
                                        </div>
                                        <div class="col-md-8">
                                            <input type="text" class="customInput NumberOnly" 
                                                maxlength="3" [(ngModel)]="DaysToAddShipDate"
                                                autocomplete="off"/>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        
                        <div>
                            <div class="customHeading mt-3">
                                Default Sales Tax Value
                            </div>
                            <div class="row mt-2">
                                <div class="col-lg-3 col-md-6 f_size_12">
                                 
                                   <label class="customLabel"> Sales Tax Value <span style="color:red;">*</span></label>
                                
                                </div>
                                <div class="col-md-1">
                                    <label class="customLabel"></label>
                                </div>
                                <div class="col-lg-3 col-md-6">
                                    <div class="row">  
                                        <div class="col-md-4">
                                            <input type="text" class="customInput Cost" (keypress)="SaleTaxkeyPress()" style="padding-left: 4px;"
                                            (blur)="SaleTaxBlur($event.target.value)" [(ngModel)]="Salestax" autocomplete="off" maxlength="5" [ngClass]="((Salestax=='' || Salestax==null || Salestax==undefined) && (validation)) ? 'validation_border': ''">
                                        </div><span class=" f_size_12 customLabel" style="margin-left: -13px; margin-top: 4px;">%</span>
                                    </div>
                                    
                                </div>
                                <div class="col-md-2 f_size_10">
                                    <label class="customLabel">Preview:
                                    {{this.Salestax==null?"":this.Salestax}}<span class="customLabel"
                                        *ngIf="Salestax!=''">%</span>
                                    </label>
                                </div>
                        
                            </div>

                        </div>
                        
                        <div>
                            <div class="customHeading mt-3">
                                Company Metrics
                            </div>
                            <div class="row mt-2">
                                <div class="col-lg-3 col-md-6 f_size_12">
                                 
                                   <label class="customLabel"> Labor Goal Percentage <span style="color:red;">*</span></label>
                                
                                </div>
                                <div class="col-md-1">
                                    <label class="customLabel"></label>
                                </div>
                                <div class="col-lg-3 col-md-6">
                                    <div class="row">  
                                        <div class="col-md-4">
                                            <input type="text" class="customInput Cost" (keypress)="LaborGoalPercentagekeyPress()" style="padding-left: 4px;"
                                            (blur)="LaborGoalPercentageBlur($event.target.value)" [(ngModel)]="LaborGoalPercentage" autocomplete="off" maxlength="5" [ngClass]="((LaborGoalPercentage=='' || LaborGoalPercentage==null || LaborGoalPercentage==undefined) && (validation)) ? 'validation_border': ''">
                                        </div><span class=" f_size_12 customLabel" style="margin-left: -13px; margin-top: 4px;">%</span>
                                    </div>
                                    
                                </div>
                                <div class="col-md-2 f_size_10">
                                    <label class="customLabel">Preview:
                                    {{this.LaborGoalPercentage==null?"":this.LaborGoalPercentage}}<span class="customLabel"
                                        *ngIf="LaborGoalPercentage!=''">%</span>
                                    </label>
                                </div>
                        
                            </div>

                        </div>

                                
                        <div>
                            <div class="customHeading mt-3">
                                General Settings
                            </div>
                            <div class="row mt-2">
                                <div class="col-lg-3 col-md-6">
                                    <div class="row">  
                                        <div class="col-md-12">
                                            <label class="customLabel">Time Zone <span style="color:red;">*</span></label>

                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-1">
                                    <label class="customLabel"></label>
                                </div>
                                <div class="col-lg-2 col-md-3">
                                    <div class="row">  
                                        <select class="customInput pl-1px ml-3" [(ngModel)]="timezone" [ngClass]="((timezone=='' || timezone==null || timezone==undefined) && (validation)) ? 'validation_border': ''">
                                        <option value="">Select Time Zone</option>
                                        <option *ngFor="let item of TimeZoneList" [value]="item.id">
                                            {{item.name}}
                                        </option>
                                    </select>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                
      
                        <div>
                            <div class="customHeading mt-3">
                                Software Version
                            </div>
                            <div class="row mt-2">
                                <div class="col-lg-3 col-md-6">
                                    <div class="row">  
                                        <div class="col-md-12">
                                            <label class="customLabel">{{SoftwareVersion}}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="submit-from text-right">
                        <button *ngIf="rolePermissionsList && rolePermissionsList[0].isInsert"
                            class="btn blue-bg round-btn" (click)="SettingLogoSubmit()"><i class="fas fa-save"
                                aria-hidden="true"></i> Save
                        </button>
                        <button class="btn darkred-bg round-btn" (click)="ClorClear()"><i class="fas fa-redo"
                                aria-hidden="true"></i>
                            Clear </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="modal" id="add_success">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="addsuccess()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Added Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="addsuccess()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="add_update">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="updateitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Update Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="updateitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="delete_success">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="deleteitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Deleted Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="deleteitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="wrong_msg">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="msgitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>Something went wrong</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="msgitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>