import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UsersRoutingModule } from './users-routing.module';
import { UsersComponent } from './users.component';
import { RoleManagementComponent } from './role-management/role-management.component';
import { UserPermissionsComponent } from './user-permissions/user-permissions.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { DataTablesModule } from 'angular-datatables';
// import { ExpandMode, NgxTreeSelectModule } from 'ngx-tree-select';
// import { TreeviewModule } from 'ngx-treeview';
// import { DropdownTreeviewSelectComponent } from './dropdown-treeview-select.component';
import { MaterialModule } from '../material/material.module';
import { BrowserModule } from '@angular/platform-browser';

@NgModule({
  declarations: [
    UsersComponent,
    RoleManagementComponent,
    UserPermissionsComponent,
    // DropdownTreeviewSelectComponent,
    UserManagementComponent],    
  imports: [
    BrowserModule,
    CommonModule,
    FormsModule,
    UsersRoutingModule,
    ReactiveFormsModule, 
    DataTablesModule,
    MaterialModule,
    ToastrModule.forRoot(),
    // TreeviewModule.forRoot(),
    // NgxTreeSelectModule.forRoot({
    //   idField: 'id',
    //   textField: 'name',
    //   expandMode: ExpandMode.Selection
    // })
  ]
})
export class UsersModule { }
declare module "@angular/core" {
  interface ModuleWithProviders<T = any> {
    ngModule: Type<T>;
    // providers?: Provider[];
  }
}