import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
 declare var $: any
@Component({
  selector: 'app-view-company',
  templateUrl: './view-company.component.html',
  styleUrls: ['./view-company.component.css']
})
export class ViewCompanyComponent implements OnInit {

  constructor(private router: Router) { }

  ngOnInit(): void {
    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);
     
    }
    $(document).ready(function () {
			$('.my-nav').mgaccordion();
			$('.my-nav2').mgaccordion({
				theme: 'tree',
				 leaveOpen: true
			});
		});
  }

}
