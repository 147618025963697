<section class="content-section pt-2">
    <div class="loading" *ngIf="loader">Loading&#8230;</div>
    <div col-sm-12>
        <div class="row">

            <div class="col-12 mb-2 d-flex align-items-baseline text-xs-left">
                <div class="page-title">
                    <!-- <h2>Company List</h2> -->
                    <div class="bread-crumb">
                        <a [routerLink]="['/adminDashboard/']">Home</a>
                        <span class="mx-1">-</span>
                        <span class="active">Permissions</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="white-box1">
                <div class="customHeading">
                    Permissions
                </div>
                <div class="card-desc1">
                    <div class="row mt-2">
                        <!-- <div class="col-lg-3 col-md-12">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp">
                                    <label class="customLabel">Company</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <ngx-dropdown-treeview-select
                                        class="newsmallddl2 customInput-dropdown dropdown-company" [config]="config"
                                        required [items]="userGroupObjects" [(value)]="value"
                                        (valueChange)="onValueChange($event)">
                                    </ngx-dropdown-treeview-select>
                                </div>
                            </div>
                        </div> -->
                        <div class="col-lg-3 col-md-12 mt-lg-0 mt-1">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp">
                                    <label class="customLabel">Role</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <select class="customInput newsmallddl pl-1px" [ngModel]="selectedRoleValue"
                                        (ngModelChange)="changeUserRole($event)">
                                        <option value=''>Select User Role</option>
                                        <option *ngFor="let item of userRoleList" [value]="item.id">{{item.name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-12 mt-lg-0 mt-1" *ngIf="IsPermissionExist">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp">
                                    <label class="customLabel">Copy From Role</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <select class="form-control newsmallddl" [ngModel]="selectedcopyrole"
                                        (ngModelChange)="CopyRoleChange($event)">
                                        <option value=''>Do Not Copy</option>
                                        <option *ngFor="let item of CopyfromRoleList" [value]="item.id">{{item.name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-6 mt-lg-0 mt-1" *ngIf="IsPermissionExist && selectedcopyrole!=''">
                            <div class="row">
                                <div class="col-lg-12 col-md-2 trp">
                                    <button class="btn blue-bg round-btn" (click)="Apply(content)"><i
                                            class="fas fa-save" aria-hidden="true"></i>
                                        Apply
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>


    <div class="row mt-1">
        <div class="col-md-12 mt-1" *ngIf="perArray>0">
            <div class="white-box px-2 align-check">
                <input type="checkbox" class="form-check-input m-0" id="AllSelect" [(ngModel)]="AllSelect"
                    (click)="AllSelectPermission($event)">
                <label class="form-check-label f_size_10 customLabel" for="exampleCheck1">Select All</label>
            </div>
        </div>

        <div class="col-sm-12">

            <div class="white-box" *ngFor="let item of rolePermissionsList; let i = index">
                <div class="white-box-title">
                    <label class="customLabel">{{item.pageName == 'Search' ? "Searchable Field" : item.pageName == 'Order' ? 'Sales Orders' : item.pageName}}</label>
                </div>
                <div class="card-desc align-check">
                    <div class="row">
                        <div class="col-sm-3 col-6">
                            <div class="form-group form-check">
                                <input type="checkbox" class="form-check-input" id="exampleCheck1"
                                    [(ngModel)]="item.isView">
                                <label class="form-check-label customLabel" for="exampleCheck1">View</label>
                            </div>
                        </div>
                        <div class="col-sm-3 col-6">
                            <div class="form-group form-check">
                                <input type="checkbox" class="form-check-input" id="exampleCheck2"
                                    [(ngModel)]="item.isInsert">
                                <label class="form-check-label customLabel" for="exampleCheck2">Add</label>
                            </div>
                        </div>
                        <div class="col-sm-3 col-6">
                            <div class="form-group form-check">
                                <input type="checkbox" class="form-check-input" id="exampleCheck3"
                                    [(ngModel)]="item.isUpdate">
                                <label class="form-check-label customLabel" for="exampleCheck3">Edit</label>
                            </div>
                        </div>
                        <div class="col-sm-3 col-6">
                            <div class="form-group form-check">
                                <input type="checkbox" class="form-check-input" id="exampleCheck4"
                                    [(ngModel)]="item.isDelete">
                                <label class="form-check-label customLabel" for="exampleCheck4">Delete</label>
                            </div>
                        </div>
                       
                        <div class="col-sm-3 col-6" *ngIf="item.pageName=='Order'">
                            <div class="form-group form-check">
                                <input type="checkbox" class="form-check-input" id="exampleCheck5"
                                    [(ngModel)]="item.isExportPdf">
                                <label class="form-check-label customLabel" for="exampleCheck5">ExportPDF</label>
                            </div>
                        </div>
                        <div class="col-sm-3 col-6" *ngIf="item.pageName=='Order'">
                            <div class="form-group form-check">
                                <input type="checkbox" class="form-check-input" id="exampleCheck6"
                                    [(ngModel)]="item.isExportWord">
                                <label class="form-check-label customLabel" for="exampleCheck6">ExportWord</label>
                            </div>
                        </div>
                        <!-- Salman -->
                        <div class="col-sm-3 col-6" *ngIf="item.pageName=='Setting'">
                            <div class="form-group form-check">
                                <input type="checkbox" class="form-check-input" id="exampleCheck7"
                                    [(ngModel)]="item.isServices"
                                    (click)="onRolePermissionChange($event,item.pageId,'Services')">
                                <label class="form-check-label customLabel" for="exampleCheck7">Services</label>
                            </div>
                        </div>
                        <div class="col-sm-3 col-6" *ngIf="item.pageName=='Setting'">
                            <div class="form-group form-check">
                                <input type="checkbox" class="form-check-input" id="exampleCheck8"
                                    [(ngModel)]="item.isPurchaseOrderNumber">
                                <label class="form-check-label customLabel"
                                    for="exampleCheck8">PurchaseOrderNumber</label>
                            </div>
                        </div>
                        <!-- Salman -->
                        <div class="col-sm-3 col-6" *ngIf="item.pageName=='Order'">
                            <div class="form-group form-check">
                                <input type="checkbox" class="form-check-input" id="exampleCheck9"
                                    [(ngModel)]="item.isProductionStatus">
                                <label class="form-check-label customLabel"
                                    for="exampleCheck9">Production Status</label>
                            </div>
                        </div>
                        <div class="col-sm-3 col-6" *ngIf="item.pageName=='Order'">
                            <div class="form-group form-check">
                                <input type="checkbox" class="form-check-input" id="exampleCheck10"
                                    [(ngModel)]="item.isLaborRateChange">
                                <label class="form-check-label customLabel"
                                    for="exampleCheck10">Change Labor Rate on SO</label>
                            </div>
                        </div>
                        <div class="col-sm-3 col-6" *ngIf="item.pageName=='Order'">
                            <div class="form-group form-check">
                                <input type="checkbox" class="form-check-input" id="exampleCheck9"
                                    [(ngModel)]="item.isSOProfitability">
                                <label class="form-check-label customLabel"
                                    for="exampleCheck9">SO Profitability View</label>
                            </div>
                        </div>

                        <div class="col-sm-3 col-6" *ngIf="item.pageName=='Order' || item.pageName == 'PurchaseOrder'">
                            <div class="form-group form-check">
                                <input type="checkbox" class="form-check-input" id="exampleCheck11"
                                    [(ngModel)]="item.isUpdateReasonPopUp">
                                <label class="form-check-label customLabel"
                                    for="exampleCheck11">Show Reason for Update popup</label>
                            </div>
                        </div>
                        <div class="col-sm-3 col-6" *ngIf="item.pageName=='Product' || item.pageName=='Order'">
                            <div class="form-group form-check">
                                <input type="checkbox" class="form-check-input" id="exampleCheck12"
                                    [(ngModel)]="item.isCopy">
                                <label class="form-check-label customLabel"
                                    for="exampleCheck12">Copy</label>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-md-12 text-right p-0" *ngIf="perArray>0">
                <button type="button" class="btn blue-bg round-btn" (click)="updateRole()" data-dismiss="modal"><i
                        aria-hidden="true" class="fas fa-save"></i>Save</button>
            </div>


        </div>

    </div>


</section>
<ng-template #content let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg sweet-heading"></h4>
    <button class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body p-1">
        <div class="thank-you-pop">
            <img src="./assets/image/popup-alert.png" alt="">
            <h1>Confirmation</h1>
            <p class="mt-1">{{confirmationMsg}}</p>
        </div>
    </div>
    <div class="modal-footer sweet-footer text-center p-1">
        <button type="button" class="btn green-bg round-btn m-0 mr-1"
            (click)="ApplyPermission(); c('Close click')">Yes</button>
        <button type="button" class="btn red-bg round-btn m-0 mr-1" (click)="c('Close click')">No</button>
    </div>
    <!-- <div class="modal-footer text-center" >
        <button type="button" class="btn green-bg round-btn m-0 mr-1" (click)="c('Close click')">Ok</button>
    </div> -->
</ng-template>
<div class="modal" id="add_success">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="addsuccess()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Added Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="addsuccess()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="add_update">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="updateitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Save Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="updateitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="delete_success">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="deleteitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Deleted Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="deleteitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>