
// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // baseUrl: 'http://localhost:53616/',
  // IntegrationbaseUrl: 'http://localhost:4500/'
  baseUrl: 'https://stagingapi.volanterp.com/'
  // baseUrl: 'https://api.volanterp.com/'
}


