
<section class="content-section purchaseOrder pt-2" id="new-layoutd">
    <div class="loading" *ngIf="loader">Loading&#8230;</div>
    <div class="page-title mb-2">
        <div class="bread-crumb">
            <a [routerLink]="['/adminDashboard/']">Home</a>
            <span class="mx-1">-</span>
            <span class="active">Shipping</span>
            <div *ngIf="permissionMessage!=''" class='btn-danger text-center'>{{permissionMessage}} </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="white-box1 cust-label">
                <div class="card-desc1">

                    <form [formGroup]="formSearch">
                        <div class="row detailSection">
                            <div class="col-md-12">
                                <div class="row">
                                    <!-- <div class="col-lg-2 col-md-6">
                                        <div class="d-flex align-items-center borderNone    "
                                            [ngClass]="((CompanyValue==0) && (SearchPOValidation || SearchDDLValidation)) ? 'validation_border': ''">
                                            <label class="customLabel mr-1" for="company">Company<span
                                                    style="color:red">*</span></label>
                                            <ngx-dropdown-treeview-select [config]="config"
                                                class="dropdown-company customInput-dropdown" [items]="userGroupObjects"
                                                [(value)]="CompanyValue" (valueChange)="companyChangeValueMain($event)">
                                            </ngx-dropdown-treeview-select>

                                        </div>
                                    </div> -->
                                    <div class="col-lg-3 col-md-6 mt-md-0 mt-1 d-flex align-items-center">
                                        <label class="customLabel ws-nobreak mr-1" for="SO#">Sales Order #</label>
                                        <input type="text" placeholder="Enter Sales Order #" class="customInput POSO"
                                            [(ngModel)]="SoSearchTxt" formControlName="SoSearchTxt" autocomplete="off"
                                            required maxlength="12"
                                            [ngClass]="((!SoSearchTxt) && (SearchPOValidation)) ? 'validation_border': ''" (keyup.enter)="SearchByPONumber()">
                                        <i class="fas fa-search cpointer ml-2" (click)="SearchByPONumber()"></i>
                                    </div>
                                    <div class="col-lg-3 col-md-6 mt-lg-0 mt-1 d-flex align-items-center">
                                        <label class="ortext mr-4">OR</label>
                                        <label class="customLabel mr-1" for="Customer">Customer</label>
                                        <select [(ngModel)]="selectedCustomerSearch" class="customInput"
                                            formControlName='CustomerSearchDDL'
                                            [ngClass]="((!selectedCustomerSearch) && (SearchDDLValidation)) ? 'validation_border': ''">
                                            <option value=''>Select Customer</option>
                                            <option value='0'>All</option>
                                            <option *ngFor="let item of customerList" [value]="item.id">
                                                {{item.customerName}}
                                            </option>
                                        </select>
                                    </div>
                                    <div class="col-lg-2 col-md-6 mt-lg-0 mt-1 d-flex align-items-center">
                                        <label class="customLabel mr-1" for="Status">Status</label>
                                        <select [(ngModel)]="selectedStatusSearch" class="customInput"
                                            formControlName='StatusSearchDDL'
                                            [ngClass]="((!selectedStatusSearch) && (SearchDDLValidation)) ? 'validation_border': ''">
                                            <option value=''>Select Status</option>
                                            <option value='0'>All</option>
                                            <option *ngFor="let item of statuslist" [value]="item.id">
                                                {{item.name}}
                                            </option>
                                        </select>
                                        <i class="fas fa-search cpointer ml-2" (click)="SearchByDropDown()"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="white-box1" [style.display]="RecOrderListShow==true ? 'block' : 'none'">
                <div class="white-box-title1">
                    <div class="row mt-2 mb-1">
                        <div class="col-md-12">
                            <div class="customHeading">
                                Sales Orders<i class="fa fa-angle-{{ListAngle}} cpointer ml-2" aria-hidden="true"
                                    (click)="ListDivShowHide()"></i>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card-desc" [style.display]="ListDiv==true ? 'block' : 'none'">
                    <div class="table-responsive">
                        <table id="datatable" class="display table clearfix" class="row-border hover" datatable
                            [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                            <thead>
                                <tr>
                                    <th style="display: none;"></th>
                                    <th>Company</th>
                                    <th>Order No</th>
                                    <th>eOrderNumber</th>
                                    <th>Status</th>                                 
                                    <th>Contact Name</th>
                                    <th>Customer Name</th>
                                    <th>Product Name</th>
                                    <th>Order Date</th>
                                    <th>Part/Product</th>
                                    <th>Action</th>
                                </tr>

                            </thead>
                            <tbody>
                                <tr *ngFor="let data of datalist">
                                    <td class="coloumn-hide">{{data.id}}</td>
                                    <td>{{data.companyName}}</td>
                                    <td>{{data.customerPONumber==null?'-------':data.customerPONumber}}</td>
                                    <td>{{data.eOrderNo==null?'':data.eOrderNo}}</td>                          
                                    <!-- <td>{{GetStatusText(data.statusId)}}</td> -->
                                    <td>{{GetStatusText(data.statusId)}}</td>
                                    <td>{{data.contactFirstName}} {{data.contactLastName}}</td>
                                    <td>{{data.customerName}}</td>
                                    <td>{{data.partProductName}}</td>
                                    <td>{{data.orderDate | date: "MMM-dd-yyyy"}}</td>
                                    <td>{{data.orderType}}</td>
                                    <td>
                                        &nbsp;&nbsp;&nbsp;<a class="green-text"
                                            *ngIf="rolePermissionsList && rolePermissionsList[0].isUpdate"><i
                                                class="fas fa-file-download"
                                                (click)="SearchBySOList(data.customerPONumber,data.id)"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>

            <div class="white-box1 purchasepage mt-2" id="PoDiv">
                <div class="card-desc1">

                    <form [formGroup]="form">
                        <div class="row detailSection">
                            <div class="col-md-12 container">
                                <span class="customLabel">{{!SalesOrderNo ? '' : 'Sales Order : ' + SalesOrderNo}}</span>
                                <div class="row">
                                    <div class="col-md-4">
                                        <label class="customLabel">Ship To Customer:</label>
                                        <div class="fetchedDetails" style="height:auto;">

                                            <ul>
                                                <li class="d-flex"><label>Name:</label>
                                                    <label>{{customerName}}</label>
                                                </li>
                                                <li class="d-flex"><label>Address:</label>
                                                    <label [innerHtml]="this.sharedService.FormatAddress(customerAdd1,
                                                    customerAdd2, customerCity, customerState,
                                                    customerZip)">
                                                    </label>
                                                </li>
                                                <li class="d-flex"><label>Country:</label>
                                                    <label>{{customerCountry}}</label>
                                                </li>
                                            </ul>
                                        </div>
                                        <div class="shippingorder-cstm">
                                            <div class="form-group row mb-0 ">
                                                <div class="col-sm-4">
                                                    <label class="customLabel">Order Status</label>
                                                </div>
                                                <div class="col-sm-8">
                                                    <select  class="customInput"
                                                         [(ngModel)]='ShippingStatusModel' name='ShippingStatusModel' formControlName='ShippingStatusModel' id='ShippingStatusModel'>
                                                        <option value=""selected>{{this.GetStatusText(this.statusid) ==null ? "Select Order Status":this.GetStatusText(this.statusid)}}</option>
                                                        <option *ngFor="let item of ShippingStatus" [value]="item.id">
                                                            {{item.name}}
                                                        </option>
                                                    </select>
                                                </div>

                                                
                                                <!-- <div class="col-sm-4">
                                                    <label class="customLabel">Payment Status </label>
                                                </div>
                                                <div class="col-sm-8">
                                                    <select class="customInput" 
                                                    [(ngModel)]='paymentstatusModel'  name='paymentstatusModel'  id='paymentstatusModel'  formControlName='paymentstatusModel'>
                                                        <option  value="" selected>{{paymentStatusVal==null? "Select Payment Status" : paymentStatusVal}}</option>
                                                        <option aria-hidden="true" *ngFor="let item of paymentstatuslist" [value]="item.name">
                                                            {{item.name}}
                                                        </option>
                                                    </select>
                                                </div> -->
                                                <div class="col-lg-4 col-md-2 trp">
                                                    <label class="customLabel">Carrier Service</label>
                                                </div>
                                                <div class="col-lg-8 col-md-10">
                                                    <select class="customInput"
                                                        [(ngModel)]='carrierModel'name='carrierModel'  id='carrierModel' formControlName='carrierModel'>
                                                        <option value="" selected>{{carrierServiceVal==null? "Select Carrier" : carrierServiceVal}}</option>                                                                                                  
                                                        <option aria-hidden="true" *ngFor="let item of carrierData" [value]="item.name">
                                                            {{item.name}}
                                                        </option>
                                                    </select>
                                                </div>
                                                <!-- <div class="col-sm-4">
                                                    <label class="customLabel">Transaction ID</label>
                                                </div>
                                                <div class="col-sm-8">
                                                <input value={{transactionID}} type="text"class="form-control1 customInput">
                                                </div> -->

                                                <!-- <div class="col-sm-4">
                                                    <label class="customLabel">Payment Date</label>
                                                </div>
                                                <div class="col-sm-8" >                                              
                                                    <input [(ngModel)]="paymentDate" formControlName='paymentDate' id="paymentDate" type="date"class="col-sm-6 form-control1 customInput inputcstmread" >
                                                    <select class="col-sm-6 form-control1 customInput" 
                                                      [(ngModel)]="paymentDateModel"  name='paymentDateModel'  id="paymentDateModel" formControlName='paymentDateModel'>
                                                        <option value="" selected>{{transactionStatus==null? "Transaction Status" : transactionStatus}}</option> 
                                                        <option aria-hidden="true" *ngFor="let item of paymentDateValueList" [value]="item.name">
                                                            {{item.name}}
                                                        </option>
                                                        
                                                    </select>
                                                </div> -->
                                                
                                                
                                            </div>
                                        </div>
                                    </div>
                                   
                                    <div class="col-md-8">
                                        <label class="customLabel"></label>
                                        <div class="fetchedDetails" style="height:auto;">
                                            <div class="col-md-12">
                                                <div class="row">
                                                    <div class="d-flex align-items-center ws-nobreak">
                                                        <label class="customLabel mr-1">Package Type</label>
                                                        <select class="customInput" (change)="ChngCartonType($event)"
                                                            formControlName='cartonType' [(ngModel)]="cartonType">
                                                            <option *ngFor="let item of cartonTypeList"
                                                                [value]="item.id">
                                                                {{item.name}}
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div class="d-flex align-items-center ws-nobreak"
                                                        *ngIf="divListPackages==true">
                                                        <label class="Ship To Customer mr-1 ml-2">List of Packages</label>
                                                        <select class="customInput">
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-md-12">
                                                <div class="row">
                                                    <span class="customLabel text-danger">{{cartonError}}</span>
                                                    <div class="table-responsive">
                                                        <table class="display table-bordered table clearfix mb-0">
                                                            <thead>
                                                                <tr>
                                                                    <th colspan="2">Length</th>
                                                                    <th colspan="2">Width</th>
                                                                    <th colspan="2">Height</th>
                                                                    <th colspan="2">Weight</th>
                                                                    <th colspan="1">Tracking</th>
                                                                    <th colspan="1">Cost($)</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td style="width: 10%;">
                                                                        <input type="text"
                                                                            class="customInput NumberOnly"
                                                                            [ngClass]="((!Length1) && (cartonValidation)) ? 'validation_border': ''"
                                                                            formControlName='Length1'
                                                                            [(ngModel)]="Length1">
                                                                    </td>
                                                                    <td style="width: 10%;">
                                                                        <select class="customInput"
                                                                            formControlName='Length2'
                                                                            [(ngModel)]="Length2">
                                                                            <option *ngFor="let item of LenghtUnit"
                                                                                [value]="item.id">
                                                                                {{item.name}}
                                                                            </option>
                                                                        </select>
                                                                    </td>
                                                                    <td style="width: 10%;">
                                                                        <input type="text"
                                                                            class="customInput NumberOnly"
                                                                            [ngClass]="((!Width1) && (cartonValidation)) ? 'validation_border': ''"
                                                                            formControlName='Width1'
                                                                            [(ngModel)]="Width1">
                                                                    </td>
                                                                    <td style="width: 10%;">
                                                                        <select class="customInput"
                                                                            formControlName='Width2'
                                                                            [(ngModel)]="Width2">
                                                                            <option *ngFor="let item of WidthUnit"
                                                                                [value]="item.id">
                                                                                {{item.name}}
                                                                            </option>
                                                                        </select>
                                                                    </td>
                                                                    <td style="width: 10%;">
                                                                        <input type="text"
                                                                            class="customInput NumberOnly"
                                                                            [ngClass]="((!Height1) && (cartonValidation)) ? 'validation_border': ''"
                                                                            formControlName='Height1'
                                                                            [(ngModel)]="Height1">
                                                                    </td>
                                                                    <td style="width: 10%;">
                                                                        <select class="customInput"
                                                                            formControlName='Height2'
                                                                            [(ngModel)]="Height2">
                                                                            <option *ngFor="let item of HeightUnit"
                                                                                [value]="item.id">
                                                                                {{item.name}}
                                                                            </option>
                                                                        </select>
                                                                    </td>
                                                                    <td style="width: 10%;">
                                                                        <input type="text"
                                                                            class="customInput NumberOnly"
                                                                            [ngClass]="((!Weight1) && (cartonValidation)) ? 'validation_border': ''"
                                                                            formControlName='Weight1'
                                                                            [(ngModel)]="Weight1">
                                                                    </td>
                                                                    <td style="width: 10%;">
                                                                        <select class="customInput"
                                                                            formControlName='Weight2'
                                                                            [(ngModel)]="Weight2">
                                                                            <option *ngFor="let item of WeightUnit"
                                                                                [value]="item.id">
                                                                                {{item.name}}
                                                                            </option>
                                                                        </select>
                                                                    </td>
                                                                    <td style="width: 10%;">
                                                                        <input type="text"
                                                                        class="customInput"
                                                                        [ngClass]="((!tracking) && (cartonValidation)) ? 'validation_border': ''"
                                                                        formControlName='tracking'
                                                                        [(ngModel)]="tracking">
                                                                    </td>
                                                                    <td>
                                                                        <input type="text"
                                                                        class="customInput"
                                                                        [ngClass]="((!cost) && (cartonValidation)) ? 'validation_border': ''"
                                                                        formControlName='cost'
                                                                        [(ngModel)]="cost">
                                                                    </td>
                                                                    <td>
                                                                        <button type="button" class="btn blue-bg round-btn m-0"
                                                                            (click)="AddCarton()"><i
                                                                                class="fas fa-plus-circle"
                                                                                aria-hidden="true"></i> Add
                                                                                Package</button>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    <div class="table-responsive"
                                                        style="max-height: 130px; overflow: scroll;">
                                                        <table class="display table-bordered table clearfix">
                                                            <thead>
                                                                <tr>
                                                                    <th>Carton No</th>
                                                                    <th>Package</th>
                                                                    <th>Length</th>
                                                                    <th>Width</th>
                                                                    <th>Height</th>
                                                                    <th>Weight</th>
                                                                    <th>Tracking</th>
                                                                    <th>Cost</th>
                                                                    <th></th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr *ngFor="let item of cartonList; let i = index">
                                                                    <td style="vertical-align: middle;">
                                                                        Package&nbsp;{{item.cartonNo}}</td>
                                                                    <td style="vertical-align: middle;">
                                                                        {{GetCartonType(item.cartonType)}}</td>
                                                                    <td style="width: 10%;">
                                                                        <span
                                                                            *ngIf="normalShow==true || RowEdit==false">
                                                                            {{item.length1}}&nbsp;{{item.length2=='1'?'in':'ft'}}
                                                                        </span>
                                                                        <br>
                                                                        <span
                                                                            *ngIf="item.RowNo==EditRowPartId && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                                            <input class="customInput"
                                                                                formControlName='UpdateLength1'
                                                                                type="text"
                                                                                [(ngModel)]="UpdateLength1" />
                                                                            <br>
                                                                            <select class="customInput"
                                                                                formControlName='UpdateLength2'
                                                                                [(ngModel)]="UpdateLength2">
                                                                                <option *ngFor="let item of LenghtUnit"
                                                                                    [value]="item.id">
                                                                                    {{item.name}}
                                                                                </option>
                                                                            </select>
                                                                        </span>
                                                                    </td>
                                                                    <td style="width: 10%;">
                                                                        <span
                                                                            *ngIf="normalShow==true || RowEdit==false">
                                                                            {{item.width1}}&nbsp;{{item.width2=='1'?'in':'ft'}}
                                                                        </span>
                                                                        <br>
                                                                        <span
                                                                            *ngIf="item.RowNo==EditRowPartId && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                                            <input class="customInput"
                                                                                formControlName='UpdateWidth1'
                                                                                type="text"
                                                                                [(ngModel)]="UpdateWidth1" />
                                                                            <br>
                                                                            <select class="customInput"
                                                                                formControlName='UpdateWidth2'
                                                                                [(ngModel)]="UpdateWidth2">
                                                                                <option *ngFor="let item of WidthUnit"
                                                                                    [value]="item.id">
                                                                                    {{item.name}}
                                                                                </option>
                                                                            </select>
                                                                        </span>
                                                                    </td>
                                                                    <td style="width: 10%;">
                                                                        <span
                                                                            *ngIf="normalShow==true || RowEdit==false">
                                                                            {{item.height1}}&nbsp;{{item.height2=='1'?'in':'ft'}}
                                                                        </span>
                                                                        <br>
                                                                        <span
                                                                            *ngIf="item.RowNo==EditRowPartId && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                                            <input class="customInput"
                                                                                formControlName='UpdateHeight1'
                                                                                type="text"
                                                                                [(ngModel)]="UpdateHeight1" />
                                                                            <br>
                                                                            <select class="customInput"
                                                                                formControlName='UpdateHeight2'
                                                                                [(ngModel)]="UpdateHeight2">
                                                                                <option *ngFor="let item of HeightUnit"
                                                                                    [value]="item.id">
                                                                                    {{item.name}}
                                                                                </option>
                                                                            </select>
                                                                        </span>
                                                                    </td>
                                                                    <td style="width: 10%;">
                                                                        <span
                                                                            *ngIf="normalShow==true || RowEdit==false">
                                                                            {{item.weight1}}&nbsp;{{item.weight2=='1'?'oz':'lbs'}}
                                                                        </span>
                                                                        <br>
                                                                        <span
                                                                            *ngIf="item.RowNo==EditRowPartId && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                                            <input class="customInput"
                                                                                formControlName='UpdateWeight1'
                                                                                type="text"
                                                                                [(ngModel)]="UpdateWeight1" />
                                                                            <br>
                                                                            <select class="customInput"
                                                                                formControlName='UpdateWeight2'
                                                                                [(ngModel)]="UpdateWeight2">
                                                                                <option *ngFor="let item of WeightUnit"
                                                                                    [value]="item.id">
                                                                                    {{item.name}}
                                                                                </option>
                                                                            </select>
                                                                        </span>
                                                                    </td>
                                                                    <td>
                                                                        <span>
                                                                            {{item.tracking}}
                                                                        </span>
                                                                        <br>
                                                                        <span *ngIf="item.RowNo==EditRowPartId && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                                            <input class="customInput"
                                                                            formControlName='tracking1'
                                                                            type="text"
                                                                            [(ngModel)]="tracking1" />
                                                                        </span>
                                                                 
                                                                    </td>
                                                                    <td>
                                                                        <span>
                                                                            {{item.cost}}
                                                                        </span>
                                                                        <br>
                                                                        <span *ngIf="item.RowNo==EditRowPartId && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                                            <input class="customInput"
                                                                            formControlName='cost1'
                                                                            type="text"
                                                                            [(ngModel)]="cost1" />
                                                                        </span>
                                                                      
                                                                    </td>
                                                                    <td style="width: 10%; vertical-align: middle;">
                                                                        <span *ngIf="btnOkShow==false">
                                                                            <span class="fas fa-edit partEdit"
                                                                                (click)="EditCarton(item.RowNo,'editRow')"></span>
                                                                            <span
                                                                                class="fas fa-trash-alt order-del ml-2"
                                                                                (click)="deleteCartonConfirm(item.RowNo, contentCarton)"></span>
                                                                        </span>
                                                                        <span
                                                                            *ngIf="item.RowNo==EditRowPartId && btnOkShow==true">
                                                                            <span><button type="button" data-toggle="modal"
                                                                                    (click)="UpdateCarton(item.RowNo)"><i
                                                                                        class="fas fa-check-circle text-success"></i>
                                                                                </button></span>
                                                                            <span><button type="button" data-toggle="modal"
                                                                                    (click)="CancleUpdateCarton()"><i
                                                                                        class="fas fa-times-circle text-danger"></i>
                                                                                </button></span>
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                                <tr *ngIf="cartonList.length==0">
                                                                    <td colspan="8" class="text-center">Package Item is
                                                                        not Available</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <table class="table table-bordered mt-2">
                            <tr>
                                <td>Item #</td>
                                <td>Part Name</td>
                                <td>Ordered Qty</td>
                                <td>Previously Shipped</td>
                                <td>Ready</td>
                                <td>Package</td>
                                <td>Company Part #</td>
                                <td>Vendor Part Name</td>
                                <td>Vendor Part #</td>
                                <td>Unit Cost</td>
                                <td>Total</td>
                                <td></td>
                            </tr>
                            <ng-container *ngFor="let parts of SpecialPartList;let i = index">
                                <tr [ngClass]="parts.virtual==true ? 'Gray' : ''">
                                    <td>{{i+1}}</td>
                                    <td>
                                        <div class="position-relative">
                                            <span class="tooltipWrap">{{parts.partName=="" || parts.partName==null ?
                                                "N/A" :
                                                parts.partName}}</span>
                                            <span class="tooltips">{{parts.partName}}</span>
                                        </div>
                                    </td>
                                    <td>{{parts.oqty}}</td>
                                    <td><span  *ngIf="parts.virtual != true">{{parts.prevQty == null ? 0 : parts.prevQty}}</span></td>
                                   
                                    <td>
                                        <span *ngIf="parts.virtual != true">
                                            {{parts.quantity - parts.prevQty}}
                                        </span>
                                      
                                    </td>
                                    <td>
                                        <span *ngIf="parts.virtual != true">
                                            {{parts.cartons=="" || parts.cartons==null ?
                                            "N/A" : "Package " + parts.cartons}}
                                        </span></td>
                                    <td>{{parts.customerPartNumber=="" ||
                                        parts.customerPartNumber==null ? "N/A" : parts.customerPartNumber}}</td>
                                    <td>
                                        <div class="position-relative">
                                            <span class="tooltipWrap">{{parts.vendorPartName=="" ||
                                                parts.vendorPartName==null ? "N/A" : parts.vendorPartName}}</span>
                                            <span class="tooltips">{{parts.vendorPartName}}</span>
                                        </div>
                                    </td>
                                    <td>{{parts.vendorPartNumber=="" ||
                                        parts.vendorPartNumber==null ? "N/A" : parts.vendorPartNumber}}</td>
                                    <td>{{parts.unitCost=="" || parts.unitCost==null
                                        ? "N/A" : parts.unitCost | currency}}</td>
                                    <!-- <td>{{parts.subTotal=="" || parts.subTotal==null
                                        ?
                                        "N/A" : parts.subTotal}}</td> -->
                                        <td>{{parts.quantity * parts.unitCost | currency}}</td>
                                    <td>
                                        <span *ngIf="(parts.virtual==false || parts.virtual==null)&& parts.customPartId==null" class="fas fa-edit partEdit"
                                            (click)="ShowItemModel(itemModel, parts.id, parts.quantity, parts.prevQty)"></span>
                                    </td>
                                </tr>
                                <ng-container *ngFor="let datasub of parts.PartList; let inner = index">
                                    <tr>
                                        <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{i +1}}.{{inner+1}}</td>
                                        <td>
                                            <div class="position-relative">
                                                <span class="tooltipWrap">{{datasub.partName=="" || datasub.partName==null ?
                                                    "N/A" :
                                                    datasub.partName}}</span>
                                                <span class="tooltips">{{datasub.partName}}</span>
                                            </div>
                                        </td>
                                        <td>{{datasub.oqty}}</td>
                                        <td>{{datasub.prevQty == null ? 0 : datasub.prevQty}}</td>
                                   
                                    <td>
                                        {{datasub.quantity - datasub.prevQty}}
                                    </td>
                                        <td>{{datasub.cartons=="" || datasub.cartons==null ?
                                            "N/A" : "Package " +datasub.cartons}}</td>
                                        <td>{{datasub.customerPartNumber=="" ||
                                            datasub.customerPartNumber==null ? "N/A" : datasub.customerPartNumber}}</td>
                                        <td>
                                            <div class="position-relative">
                                                <span class="tooltipWrap">{{datasub.vendorPartName=="" ||
                                                    datasub.vendorPartName==null ? "N/A" : datasub.vendorPartName}}</span>
                                                <span class="tooltips">{{datasub.vendorPartName}}</span>
                                            </div>
                                        </td>
                                        <td>{{datasub.vendorPartNumber=="" ||
                                            datasub.vendorPartNumber==null ? "N/A" : datasub.vendorPartNumber}}</td>
                                        <td>{{datasub.unitCost=="" || datasub.unitCost==null
                                            ? "N/A" : parts.unitCost | currency }}</td>
                                        <td>{{datasub.subTotal=="" || datasub.subTotal==null
                                            ?
                                            "N/A" : datasub.subTotal}}</td>
                                        <td>
                                            <span class="fas fa-edit partEdit"
                                                (click)="ShowItemModel(itemModel, datasub.id, datasub.quantity, datasub.prevQty)"></span>
                                        </td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                            
                        </table>

                        <div class="submit-from text-right mt-3">
                            <button type="button" class="btn blue-bg round-btn" (click)="finalSave()">
                                <i class="fas fa-save" aria-hidden="true"></i>Save</button>
                            <button type="button" class="btn darkred-bg round-btn" (click)="Exit(discardItem)"><i
                                    class="fas fa-sign-out-alt" aria-hidden="true"></i>
                                Exit </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
    
    <ng-template #content let-c="close" let-d="dismiss">
        <h4 class="modal-title darkred-bg alert_heading sweet-heading"></h4>
        <button type="button" class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-body p-1">
            <div class="thank-you-pop">
                <img src="./assets/image/popup-alert.png" alt="">
                <h1>Confirmation</h1>
                <p class="mb-0">{{deleteDetail}}</p>
            </div>
        </div>
        <div class="modal-footer sweet-footer text-center p-1">
            <button type="button" class="btn green-bg round-btn m-1"
                (click)="deletePopItem(deletedItem); c('Close click')">Yes</button>
            <button type="button" class="btn red-bg round-btn m-1" (click)="c('Close click')">No</button>
        </div>
    </ng-template>

    <ng-template #contentCarton let-c="close" let-d="dismiss">
        <h4 class="modal-title darkred-bg alert_heading sweet-heading"></h4>
        <button type="button" class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-body p-1">
            <div class="thank-you-pop">
                <img src="./assets/image/popup-alert.png" alt="">
                <h1>Confirmation</h1>
                <p class="mb-0">{{deleteDetail}}</p>
            </div>
        </div>
        <div class="modal-footer sweet-footer text-center p-1">
            <button type="button" class="btn green-bg round-btn m-1"
                (click)="deleteCarton(deletedItem); c('Close click')">Yes</button>
            <button type="button" class="btn red-bg round-btn m-1" (click)="c('Close click')">No</button>
        </div>
    </ng-template>

    <ng-template #itemModel let-c="close" let-d="dismiss">
        <h4 class="modal-title darkred-bg alert_heading">{{PopPartName}}</h4>
        <div class="modal-body">
            <h5>Part Details</h5>
            <table class="table table-bordered text-left mt-1">
                <tr>
                    <th style="width: 30%;">Sales Order #</th>
                    <td style="width: 70%; font-weight: normal;">{{SalesOrderNo}}</td>
                </tr>
                <tr>
                    <th>Company Part #</th>
                    <td>{{PopCustPartNo}}</td>
                </tr>
                <tr>
                    <th>Company Part Name</th>
                    <td>{{PopPartName}}</td>
                </tr>
                <tr>
                    <th>Vendor Part #</th>
                    <td>{{PopVendorPartNo}}</td>
                </tr>
                <tr>
                    <th>Vendor Part Name</th>
                    <td>{{PopVendPartName}}</td>
                </tr>
                <tr>
                    <th>Description</th>
                    <td>{{PopDescr}}</td>
                </tr>
            </table>
            <h5>SO Details</h5>
            <table class="table table-bordered text-left mt-1">
                <tr>
                    <th style="width: 30%;">Ordered for SO#</th>
                    <td style="width: 70%; font-weight: normal;">{{PopOrderedSo}}</td>
                </tr>
                <!-- <tr>
                    <th>Received for SO#</th>
                    <td>{{PopRcvdSO}}</td>
                </tr> -->
                <tr>
                    <th>Shipped for SO#</th>
                    <td>{{PopShipped}}</td>
                </tr>
            </table>
            <!-- <h5>Inventory Details</h5> -->
            <!-- <table class="table table-bordered text-left mt-1">
                <tr>
                    <th style="width: 30%;">Total Inventory</th>
                    <td style="width: 70%; font-weight: normal;">{{PopTotalInv}}</td>
                </tr>
                <tr>
                    <th>Available Inventory</th>
                    <td>{{PopAvblInv}}</td>
                </tr>
            </table> -->

            <form [formGroup]="formPopup">
                <table class="table table-bordered text-left" style="min-width: 50%; width: 78%;">
                    <thead>
                        <tr>
                            <th style="width: 30%;">&nbsp;Date Shipped</th>
                            <th style="width: 30%;">Qty to Ship</th>
                            <th style="width: 30%;">Package</th>
                            <th style="width: 10%;"></th>
                        </tr>
                    </thead>
                    <thead>
                        <tr>
                            <td>
                                <input type="date" [(ngModel)]="TxtItemDateShipped" class="form-control"
                                    formControlName="ItemDateShipped" autocomplete="off" 
                                    [ngClass]="((!formPopup.controls.ItemDateShipped.valid) && (PopupValidation)) ? 'validation_border': ''">
                            </td>
                            <td>
                                <input type="text" [(ngModel)]="TxtItemNoShip" class="form-control NumberOnly"
                                    formControlName="ItemNoShip" autocomplete="off" maxlength="5"
                                    [ngClass]="((!formPopup.controls.ItemNoShip.valid) && (PopupValidation)) ? 'validation_border': ''">
                            </td>
                            <td>
                                <select class="form-control newsmallddl" [(ngModel)]="DDLItemCarton"
                                    formControlName="ItemCarton">
                                    <option value=''>N/A</option>
                                    <option *ngFor="let item of cartonList" [value]="item.cartonNo">
                                        Package&nbsp;{{item.cartonNo}}
                                    </option>
                                </select>
                            </td>
                            <td style="vertical-align: middle;">
                                <i class="fas fa-save text-success" (click)="SavePopup()" style="font-size: 1rem;"></i>
                                <i class="fas fa-times-circle text-danger" (click)="ClearPopup()"
                                    style="font-size: 1rem;"></i>

                            </td>
                        </tr>
                        <tr *ngFor="let item of ItemStatusList; let i = index">
                            <td>&nbsp;&nbsp;{{item.dateShipped | date: 'MMM-dd-yyyy'}}</td>
                            <td>&nbsp;&nbsp;{{item.qtyShipped}}</td>
                            <td>&nbsp;&nbsp;{{getCartonNo(item.carton)}}</td>
                            <td><i class="fas fa-edit" (click)="editPopItem(item.rowNo)"></i>&nbsp;&nbsp;<i
                                    class="fas fa-trash-alt" (click)="deletePopItemConfirm(item.rowNo,content)"></i>
                            </td>
                        </tr>
                        <tr>
                            <td>&nbsp;&nbsp;<b>Total</b></td>
                            <td>&nbsp;&nbsp;<b>{{TotalShippedQty}}</b></td>
                            <td></td>
                            <td></td>
                        </tr>
                    </thead>
                </table>
                <p class="mb-0"><strong class="text-danger">{{PopupErrorMsg}}</strong></p>
            </form>
        </div>
        <div class="modal-footer text-center">
            <button type="button" class="btn red-bg round-btn m-0" (click)="c('Close click')">Exit</button>
        </div>
    </ng-template>
</section>

<div class="modal" id="add_success">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="addsuccess()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Added Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="addsuccess()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="add_update">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="updateitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Update Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="updateitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="delete_success">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="deleteitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Deleted Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="deleteitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="no_record">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="norecorditem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>Record Not Found</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="norecorditem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="wrong_msg">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="msgitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>Something went wrong</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="msgitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="add_search">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="searchitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>You have to search first.</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="searchitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<ng-template #discardItem let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg alert_heading sweet-heading"></h4>
    <button type="button" class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>

    <div class="modal-body p-1">
        <div class="thank-you-pop">
            <img src="./assets/image/popup-alert.png" alt="">
            <h1>Confirmation</h1>
            <p class="mt-1">{{deleteDetail}}</p>
        </div>
    </div>
    <div class="modal-footer sweet-footer text-center p-1">
        <button type="button" class="btn green-bg round-btn" (click)="gotoDashBoard(); c('Close click')">Yes</button>
        <button type="button" class="btn red-bg round-btn" (click)="c('Close click')">No</button>
    </div>
</ng-template>

