import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
// import { TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { Subject } from 'rxjs';
import { ConstantsService } from 'src/app/constants.service';
import { SharedService } from '../services/shared.service';
import { CompanyService } from '../services/company/company.service';

@Component({
  selector: 'app-location-type',
  templateUrl: './location-type.component.html',
  styleUrls: ['./location-type.component.css']
})
export class LocationTypeComponent implements OnInit {

  loader: boolean = false;
  permissionMessage: any;
  rolePermissionsList: any;
  formSearch: FormGroup;
  TCreateUpdate: any = 1;
  SearchControl: boolean = false;
  userSearch: any;
  value_search: number;
  SearchValidation: any;
  userGroupObjects_search = new Array();
  SearchType: any;

  // config = TreeviewConfig.create({
  //   hasAllCheckBox: true,
  //   hasFilter: true,
  //   hasCollapseExpand: true,
  //   decoupleChildFromParent: false,
  //   maxHeight: 400
  // });

  companyLoginId: any;
  data: any;
  items_search: any;
  companyList_search: any;
  companyList: any;
  userGroupObjects = new Array();
  value: number;
  router: any;
  userId: string;
  buttonValue: string;
  ListShow: boolean = false;
  ListDiv: boolean = false;
  ListAngle: any;
  dtOptions: { pagingType: string; pageLength: number; };
  locationTypeList: any;

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  dtTrigger: Subject<any> = new Subject<any>();

  form: FormGroup;
  locationType: any;
  locationTypeDesc: any;
  isDefaultReceivingLocation: boolean = false;
  isDefaultShippingLocation: boolean = false;
  validation: boolean;
  clearbutton: boolean = true;
  id: any;
  createdDateEdit: any;
  deletedItem: string;
  deleteDetail: string;
  recordAlreadyExist: boolean = false;

  messageModalImage: any;
  messageModalHeading: any;
  messageModalDescription: any;
  @ViewChild('messageModal', { static: false }) private messageModal;

  constructor(
    private constants: ConstantsService,
    public sharedService: SharedService,
    private modalService: NgbModal,
    private companyService: CompanyService
  ) { }

  ngOnInit(): void {

    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);
    } else {
      this.userId = localStorage.getItem(this.constants.userId);
      this.buttonValue = this.constants.Save;
      this.dtOptions = {
        pagingType: this.constants.full_numbers,
        pageLength: 10,
      };

    }

    this.formSearch = new FormGroup({
      'TCreateUpdate': new FormControl(),
      'userSearch': new FormControl(),
    });

    this.form = new FormGroup({
      'locationType': new FormControl(),
      'locationTypeDesc': new FormControl(),
      'isDefaultReceivingLocation': new FormControl(),
      'isDefaultShippingLocation': new FormControl()
    });

    this.rolePermissionsList = JSON.parse(localStorage.getItem('rolePermissionsList'));
    this.rolePermissionsList = this.rolePermissionsList.filter(x => x.pageId == 1026);
    this.permissionMessage = '';
    if (this.rolePermissionsList != null && this.rolePermissionsList != undefined) {
      if (!this.rolePermissionsList[0].isView) {
        this.permissionMessage += 'view';
      } if (!this.rolePermissionsList[0].isInsert) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'add';
      } if (!this.rolePermissionsList[0].isUpdate) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'edit';
      } if (!this.rolePermissionsList[0].isDelete) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'delete';
      }
    }
    if (this.permissionMessage != '') {
      this.permissionMessage = 'You do not have access to ' + this.permissionMessage + '. Please contact to administrator!';
    }
    this.companyLoginId = localStorage.getItem(this.constants.companyId);
    this.getAllCompany_search(this.companyLoginId);
    this.getAllCompany(this.companyLoginId);
  }

  ChangeTCreateUpdate(event) {
    this.TCreateUpdate = event.target.value;
    if (this.TCreateUpdate == "1") {
      this.SearchControl = false;
      this.ListShow = false;
      this.ListDiv = false;
      this.buttonValue = this.constants.Save;
      this.resetLocationType();
    }
    else if (this.TCreateUpdate == "0") {
      this.SearchControl = true;
      this.userSearch = "";
    }
  }

  onValueChange_search(value: any): void {
    this.value_search = value;
  }

  getAllCompany_search(companyId) {
    this.companyService.GetDropdownCompanyHierarchyModel(this.companyLoginId).subscribe(
      data => {
        this.data = data;
        this.items_search = this.data.dataList;
        this.companyList_search = this.data.dataList;
        // this.companyList_search.forEach((x) => {
        //   this.userGroupObjects_search.push(new TreeviewItem(x, false));
        // });
        this.value_search = Number(companyId);

      });
  }

  getAllCompany(companyId) {

    this.companyService.GetDropdownCompanyHierarchyModel(companyId).subscribe(
      data => {
        this.data = data;
        if (this.data.dataList != null && this.data.dataList != undefined) {
          this.companyList = this.data.dataList;
          // this.companyList.forEach((x) => {
          //   this.userGroupObjects.push(new TreeviewItem(x, false));

          // });
        }
        this.value = Number(companyId);
      });
  }

  searchLocationTypeList() {

    this.SearchType = false;
    if (!this.userSearch || this.value_search == 0) {
      this.SearchValidation = true;
      return;
    }
    this.getLocationTypeList(this.value_search, this.userSearch == "" ? "" : this.userSearch);
  }

  AllLocationTypeList() {
    this.SearchType = true;
    if (this.value_search == 0) {
      this.SearchValidation = true;
      return;
    }
    this.getLocationTypeList(this.value_search, '');
  }

  getLocationTypeList(companyId, SearchItem) {

    this.sharedService.getLocationTypeList(companyId, (SearchItem == undefined || SearchItem == "" || SearchItem == null) ? SearchItem = '' : SearchItem).subscribe(
      data => {
        this.loader = true;
        this.data = data;
        this.locationTypeList = this.data.dataList;
        this.loader = false;
        this.rerender();
        this.ListShow = true;
        this.ListDiv = true;
        this.ListAngle = this.constants.UpArrow;
      });
  }

  rerender(): void {

    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next('');
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next('');
  }

  ListDivShowHide() {
    if (this.ListDiv == true) {
      this.ListDiv = false;
      this.ListAngle = this.constants.DownArrow;
    }
    else if (this.ListDiv == false) {
      this.ListDiv = true;
      this.ListAngle = this.constants.UpArrow;
    }
  }

  onValueChange(value: any): void {
    this.value = value;
  }

  changeIsDefaultReceivingLocation(event) {
    if (event.target.checked == true) {
      this.isDefaultReceivingLocation = true;
    }
    else {
      this.isDefaultReceivingLocation = false;
    }
  }

  changeIsDefaultShippingLocation(event) {
    if (event.target.checked == true) {
      this.isDefaultShippingLocation = true;
    }
    else {
      this.isDefaultShippingLocation = false;
    }
  }

  toggleEdit(item: any) {

    this.ListDiv = false;
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.buttonValue = this.constants.Update;
    this.locationType = item.locationTypeName;
    this.id = item.id;
    this.locationTypeDesc = item.locationTypeDesc;

    this.isDefaultReceivingLocation = item.isDefaultReceivingLocation;
    this.isDefaultShippingLocation = item.isDefaultShippingLocation;
    this.clearbutton = false;
    this.value = item.companyId;
    this.createdDateEdit = item.createdDate == null ? '' : item.createdDate == undefined ? '' : item.createdDate;
  }

  deletePopup(item, content) {
    this.deletedItem = '';
    this.modalService.open(content, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
    this.deleteDetail = this.constants.DeletePopup;
    this.deletedItem = item.id;
  }

  deleteLocationTypeDetails(item: any) {
    this.sharedService.deleteLocationTypeDetails(item).subscribe(
      data => {

        this.data = data;
        this.loader = true;
        switch (data.statusCode) {
          case 200:
            this.loader = false;
            this.getLocationTypeList(this.value_search, this.userSearch == "" ? "" : this.userSearch);
            this.rerender();
            this.modalSuccess(this.constants.RecordDeletedSuccessfully);
            break;
          case 208:
            this.loader = false;
            this.modalError("Location Type not deleted, It is used in Location Level.");
            break;
          case 400:
            this.loader = false;
            this.modalError(this.constants.SomethingWentWrong);
            break;
          default:
            this.loader = false;
            this.modalError(this.constants.SomethingWentWrong);
        }
      });
  }

  modalSuccess(msg) {
    this.messageModalImage = this.constants.SuccessPng;
    this.messageModalDescription = msg;
    this.modalService.dismissAll();
    this.modalService.open(this.messageModal, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
  }

  modalError(msg) {
    this.messageModalImage = this.constants.ClosePng;
    this.messageModalDescription = msg;
    this.modalService.dismissAll();
    this.modalService.open(this.messageModal, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
  }

  resetLocationType() {
    this.id = '';
    this.locationType = '';
    this.locationTypeDesc = '';
    this.isDefaultReceivingLocation = false;
    this.isDefaultShippingLocation = false;
    if (this.companyLoginId > 0) {
      this.value = Number(this.companyLoginId)
    }
    else {
      this.value = 0;

    }

  }

  locationTypeBack() {
    this.clearbutton = true;
    this.id = null;
    this.buttonValue = this.constants.Save;
    this.resetLocationType();
  }

  addUpdateLocationLevelDetails() {

    this.validation = true;
    this.clearbutton = true;
    if (this.form.controls.locationType.status === this.constants.Invalid || this.form.controls.locationTypeDesc.status === this.constants.Invalid || this.value == 0 || this.form.controls.isDefaultReceivingLocation.status == this.constants.Invalid || this.form.controls.isDefaultShippingLocation.status == this.constants.Invalid
    ) {
      return;
    }

    this.loader = true;
    const obj = {};
    obj['locationTypeName'] = this.locationType;
    obj['locationTypeDesc'] = this.locationTypeDesc;
    obj['isDefaultShippingLocation'] = this.isDefaultShippingLocation;
    obj['isDefaultReceivingLocation'] = this.isDefaultReceivingLocation;
    obj['CompanyId'] = this.value;
    obj['CreatedBy'] = this.userId;
    if (this.id != undefined && this.id != null && this.id != '') {
      obj['Id'] = this.id;
      obj['ModifiedBy'] = this.userId;
      obj['CreatedDate'] = this.createdDateEdit;
      this.sharedService.updateLocationTypeDetails(obj)
        .subscribe(
          data => {
            switch (data.statusCode) {
              case 200:
                this.loader = false;
                this.buttonValue = this.constants.Save;
                this.TCreateUpdate = 1;
                this.SearchControl = false;
                this.ListShow = false;
                this.ListDiv = false;
                this.validation = false;
                this.id = '';
                this.resetLocationType();
                this.AllLocationTypeList();
                this.modalSuccess(this.constants.RecordUpdateSuccessfully);
                break;
              case 208:
                this.loader = false;
                this.recordAlreadyExist = true;
                this.modalError(data.successMessage);
                break;
              case 400:
                this.loader = false;
                this.modalError(this.constants.SomethingWentWrong);
                break;
              default:
                this.loader = false;
                this.modalError(this.constants.SomethingWentWrong);
            }
          }
        );
    } else {
      this.sharedService.addLocationTypeDetails(obj)
        .subscribe(
          data => {
            switch (data.statusCode) {
              case 200:
                this.loader = false;
                this.validation = false;
                this.recordAlreadyExist = false;
                this.resetLocationType();
                this.AllLocationTypeList();
                this.modalSuccess(this.constants.RecordAddedSuccessfully);
                break;
              case 400:
                this.loader = false;
                this.recordAlreadyExist = true;
                this.modalError(this.constants.SomethingWentWrong);
                break;
              case 208:
                this.loader = false;
                this.recordAlreadyExist = true;
                this.modalError(data.errorMessage);
                break;
              default:
                this.loader = false;
                this.recordAlreadyExist = true;
                this.modalError(this.constants.SomethingWentWrong);
            }
          }
        );
    }

  }

}
