<section class="content-section pt-2">
    <div class="loading" *ngIf="loader">Loading&#8230;</div>

    <div col-sm-12>
        <div class="row">
            <div class="col-6 mb-2 d-flex align-items-baseline text-xs-left">
                <div class="page-title">
                    <div class="bread-crumb">
                        <a [routerLink]="['/adminDashboard/']">Home</a>
                        <span class="mx-1">-</span>
                        <span class="active">Location Details</span>
                        <div *ngIf="permissionMessage!=''" class='btn-danger text-center'>{{permissionMessage}} </div>
                    </div>
                </div>
            </div>
            <div class="col-6 mb-2 text-right align-items-baseline">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="white-box1">
                <div class="card-desc1">
                    <form [formGroup]="formSearch">
                        <div class="row detailSection">
                            <div class="col-md-12 container">
                                <div class="row mt-3">
                                    <div class="col-lg-2 col-md-6" style="margin-top: 4px;">
                                        <div class="d-flex align-items-center">
                                            <label class="customLabel mr-1" for="Type">Type</label>
                                            <select class="customInput" (change)="ChangeTCreateUpdate($event)"
                                                [(ngModel)]="TCreateUpdate" id="TCreateUpdate"
                                                formControlName="TCreateUpdate">
                                                <option value="1">Create</option>
                                                <option value="0">Update</option>
                                            </select>
                                        </div>
                                    </div>

                                    <!-- <div class="col-lg-3 col-md-6 mt-md-0 mt-1"
                                        [style.display]="SearchControl==true ? 'block' : 'none'">
                                        <div class="d-flex align-items-center required borderNone"
                                            [ngClass]="((searchValue==0) && (SearchValidation)) ? 'validation_border': ''">
                                            <label class="mr-1 customLabel" for="Company">Company</label>
                                            <ngx-dropdown-treeview-select [config]="config"
                                                class="dropdown-company customInput-dropdown" [items]="userGroupObjects"
                                                [(value)]="searchValue" (valueChange)="onSearchValueChange($event)">
                                            </ngx-dropdown-treeview-select>
                                        </div>
                                    </div> -->
                                    <div class="col-lg-5 col-md-12 mt-lg-0 mt-1"
                                        [style.display]="SearchControl==true ? 'block' : 'none'">

                                        <div class="d-flex align-items-center">
                                            <label class="customLabel mr-1 ws-nobreak" for="Location Name">
                                                Location Name</label>
                                            <input type="text" placeholder="Search Location Name" class="customInput"
                                                [(ngModel)]="userSearch" formControlName="userSearch" autocomplete="off"
                                                required
                                                [ngClass]="((!userSearch && SearchType==false) && (SearchValidation)) ? 'validation_border': ''"
                                                (keyup.enter)="searchLocationDetailsList()">
                                            <i class="fas fa-search cpointer ml-3 mr-4 cpointer"
                                                (click)="searchLocationDetailsList()"></i>
                                            <button type="button" class="btn blue-bg round-btn m-0"
                                                (click)="AllLocationLevelList()">All Location</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="white-box1" *ngIf="rolePermissionsList && rolePermissionsList[0].isView"
                [style.display]="ListShow==true ? 'block' : 'none'">
                <div class="white-box-title1">
                    <div class="customHeading mt-3 mb-2">
                        Location Type List
                        <i class="fa fa-angle-{{ListAngle}} cpointer ml-2" aria-hidden="true"
                            (click)="ListDivShowHide()"></i>
                    </div>
                </div>
                <div class="card-desc1" [style.display]="ListDiv==true ? 'block' : 'none'">
                    <table id="datatable" class="row-border hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                        <thead>
                            <tr>
                                <th>Company Name</th>
                                <th>Location Type</th>
                                <th>Location Name</th>
                                <th># Of Locations</th>
                                <th data-orderable="false">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of locationDetailsList">
                                <td>{{data.companyName}}</td>
                                <td>{{data.locationType}}</td>
                                <td>{{data.locationName}}</td>
                                <td>{{data.noOfLocations}}</td>
                                <td>
                                    <a class="green-text"
                                        *ngIf="rolePermissionsList && rolePermissionsList[0].isUpdate" (click)="toggleEdit(data)"><i
                                            class="fas fa-edit" ></i></a>
                                    <a class="red-text"
                                        *ngIf="rolePermissionsList && rolePermissionsList[0].isDelete" (click)="deletePopup(data,content)"><i
                                            class="fas fa-trash-alt"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="white-box1">
                <div class="customHeading mt-3">
                    Location Levels
                </div>
                <div class="card-desc1">
                    <form [formGroup]="form">
                        <div class="row mt-2">
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                                <div class="row required">
                                    <div class="col-lg-3 col-md-2 trp">
                                        <label class="customLabel">Location Type</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10 borderNone">
                                        <select class="customInput pl-1px" [(ngModel)]="selectedLocationValue"
                                            autocomplete="none|false|no" (ngModelChange)="changeLocationType($event)"
                                            formControlName='selectedLocationValue' required
                                            [ngClass]="((!form.controls.selectedLocationValue.valid) && (validation)) ? 'validation_border': ''">
                                            <option value=''>Select Location Type</option>
                                            <option *ngFor="let item of locationTypes" [value]="item.id">
                                                {{item.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                                <div class="row required">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Location Name</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput" [(ngModel)]="locationName"
                                            placeholder="Enter Location Name" name="locationName"
                                            formControlName="locationName" autocomplete="off" required maxlength="50"
                                            [ngClass]="((!form.controls.locationName.valid) && (validation)) ? 'validation_border': ''">
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-lg-12 col-md-12 mt-lg-0 mt-1">
                                <div class="row">
                                    <div class="col-lg-1 col-md-1 trp">
                                        <label class="customLabel">Description</label>
                                    </div>
                                    <div class="col-lg-10 col-md-10">

                                        <textarea rows="3" cols="8" type="text" style="margin-left: 0%;width: 110%;"
                                            class="customInput customerTextarea"
                                            placeholder="Enter Location Description" [(ngModel)]="locationDesc"
                                            name="locationDesc" formControlName='locationDesc'
                                            autocomplete="off"
                                            [ngClass]="((!form.controls.locationDesc.valid) && (validation)) ? 'validation_border': ''"></textarea>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                                <div class="row">
                                    <div class="col-lg-5 col-md-5 trp">
                                        <label class="customLabel">Location Details :</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-lg-1 col-md-12 mt-lg-0 mt-1">
                                
                            </div>
                            <div class="col-lg-2 col-md-12 mt-lg-0 mt-1 text-center">
                                <label class="customLabel tooltipWrap">Name Of Level
                                    <span class="tooltipWrap tooltip-icon" style="display:inline"><i
                                        class="fa fa-info-circle"></i></span>
                                <span class="tooltips">
                                    Name or specify up to 4 levels of locations. Examples can include Aisle,
                                                Section, Shelf, Position, Bay etc. Note - Leave field blank if you do
                                                not need
                                </span>
                                </label>
                                
                            </div>
                            <div class="col-lg-2 col-md-12 mt-lg-0 mt-1 text-center">
                                <label class="customLabel tooltipWrap">Number of Locations
                                    <span class="tooltipWrap tooltip-icon" style="display:inline"><i
                                        class="fa fa-info-circle"></i></span>
                                <span class="tooltips">
                                    Specify how many locations to create at that level. Note - Leave field
                                                blank if you do not need
                                </span>
                                </label>
                            </div>
                            <div class="col-lg-2 col-md-12 mt-lg-0 mt-1 text-center">
                                <label class="customLabel tooltipWrap">Starting Letter/Number
                                    <span class="tooltipWrap tooltip-icon" style="display:inline"><i
                                        class="fa fa-info-circle"></i></span>
                                <span class="tooltips">
                                    Specify the starting letter or number for each level. Note - Leave field
                                                blank if you do not need
                                </span>
                                </label>
                            </div>
                            <!-- <div class="col-lg-2 col-md-12 mt-lg-0 mt-1 text-center">
                                <label class="customLabel tooltipWrap">Special Character
                                    <span class="tooltipWrap tooltip-icon" style="display:inline"><i
                                        class="fa fa-info-circle"></i></span>
                                <span class="tooltips">
                                    Enter a special character which will be the separating mark between each
                                                level. Examples include / - . ,
                                                Note - Leave field blank if you do not need
                                </span>
                                </label>
                            </div> -->
                        </div>
                        <div class="row mt-2">
                            <div class="col-lg-1 col-md-12 mt-lg-0 mt-1">
                                <label class="customLabel tooltipWrap">Level 1</label>
                            </div>
                            <div class="col-lg-2 col-md-12 mt-lg-0 mt-1">
                                <input type="text" class="customInput" autocomplete="off" required
                                                [(ngModel)]="nameOfLevel1" name="nameOfLevel1"
                                                formControlName="nameOfLevel1" maxlength="50"
                                                [ngClass]="((!form.controls.nameOfLevel1.valid) && (validation)) ? 'validation_border': ''">
                            </div>
                            <div class="col-lg-2 col-md-12 mt-lg-0 mt-1">
                                <input type="number" class="customInput Cost" style="padding-left: 4px;" required
                                                autocomplete="off" [(ngModel)]="numbersOfLocations1"
                                                name="numbersOfLocations1" formControlName="numbersOfLocations1"
                                                maxlength="50"
                                                [ngClass]="((!form.controls.numbersOfLocations1.valid) && (validation)) ? 'validation_border': ''">
                            </div>
                            <div class="col-lg-2 col-md-12 mt-lg-0 mt-1">
                                <input type="text" class="customInput" autocomplete="off" required
                                                [(ngModel)]="startingLettersNumbers1" name="startingLettersNumbers1"
                                                formControlName="startingLettersNumbers1" maxlength="2"
                                                [ngClass]="((!form.controls.startingLettersNumbers1.valid) && (validation)) ? 'validation_border': ''">
                            </div>
                            <!-- <div class="col-lg-2 col-md-12 mt-lg-0 mt-1">
                                <input type="text" class="customInput" autocomplete="off" required
                                                [(ngModel)]="specialCharacter1" name="specialCharacter1"
                                                formControlName="specialCharacter1" maxlength="50"
                                                [ngClass]="((!form.controls.specialCharacter1.valid) && (validation)) ? 'validation_border': ''">
                            </div> -->
                        </div>
                        <div class="row mt-2">
                            <div class="col-lg-1 col-md-12 mt-lg-0 mt-1">
                                <label class="customLabel tooltipWrap">Level 2</label>
                            </div>
                            <div class="col-lg-2 col-md-12 mt-lg-0 mt-1">
                                <input type="text" class="customInput" autocomplete="off" 
                                                [(ngModel)]="nameOfLevel2" name="nameOfLevel2"
                                                formControlName="nameOfLevel2" maxlength="50"
                                                [ngClass]="((!form.controls.nameOfLevel2.valid) && (validation)) ? 'validation_border': ''">
                            </div>
                            <div class="col-lg-2 col-md-12 mt-lg-0 mt-1">
                                <input type="number" class="customInput Cost" style="padding-left: 4px;" 
                                                autocomplete="off" [(ngModel)]="numbersOfLocations2" required
                                                name="numbersOfLocations2" formControlName="numbersOfLocations2"
                                                maxlength="50"
                                                [ngClass]="((css_numbersOfLocations2) && (validation)) ? 'validation_border': ''">
                            </div>
                            <div class="col-lg-2 col-md-12 mt-lg-0 mt-1">
                                <input type="text" class="customInput" autocomplete="off"  required
                                                [(ngModel)]="startingLettersNumbers2" name="startingLettersNumbers2"
                                                formControlName="startingLettersNumbers2" maxlength="2"
                                                [ngClass]="((css_startingLettersNumbers2) && (validation)) ? 'validation_border': ''">
                            </div>
                            <!-- <div class="col-lg-2 col-md-12 mt-lg-0 mt-1">
                                <input type="text" class="customInput" autocomplete="off" 
                                                [(ngModel)]="specialCharacter2" name="specialCharacter2" required
                                                formControlName="specialCharacter2" maxlength="50"
                                                [ngClass]="((css_specialCharacter2) && (validation)) ? 'validation_border': ''">
                            </div> -->
                        </div>
                        <div class="row mt-2">
                            <div class="col-lg-1 col-md-12 mt-lg-0 mt-1">
                                <label class="customLabel tooltipWrap">Level 3</label>
                            </div>
                            <div class="col-lg-2 col-md-12 mt-lg-0 mt-1">
                                <input type="text" class="customInput" autocomplete="off" 
                                                [(ngModel)]="nameOfLevel3" name="nameOfLevel3"
                                                formControlName="nameOfLevel3" maxlength="50"
                                                [ngClass]="((!form.controls.nameOfLevel3.valid) && (validation)) ? 'validation_border': ''">
                            </div>
                            <div class="col-lg-2 col-md-12 mt-lg-0 mt-1">
                                <input type="number" class="customInput Cost" style="padding-left: 4px;" required
                                                autocomplete="off" [(ngModel)]="numbersOfLocations3"
                                                name="numbersOfLocations3" formControlName="numbersOfLocations3"
                                                maxlength="50"
                                                [ngClass]="((css_numbersOfLocations3) && (validation)) ? 'validation_border': ''">
                            </div>
                            <div class="col-lg-2 col-md-12 mt-lg-0 mt-1">
                                <input type="text" class="customInput" autocomplete="off" required
                                                [(ngModel)]="startingLettersNumbers3" name="startingLettersNumbers3"
                                                formControlName="startingLettersNumbers3" maxlength="2"
                                                [ngClass]="((css_startingLettersNumbers3) && (validation)) ? 'validation_border': ''">
                            </div>
                            <!-- <div class="col-lg-2 col-md-12 mt-lg-0 mt-1">
                                <input type="text" class="customInput" autocomplete="off" 
                                                [(ngModel)]="specialCharacter3" name="specialCharacter3" required
                                                formControlName="specialCharacter3" maxlength="50"
                                                [ngClass]="((css_specialCharacter3) && (validation)) ? 'validation_border': ''">
                            </div> -->
                        </div>
                        <div class="row mt-2">
                            <div class="col-lg-1 col-md-12 mt-lg-0 mt-1">
                                <label class="customLabel tooltipWrap">Level 4</label>
                            </div>
                            <div class="col-lg-2 col-md-12 mt-lg-0 mt-1">
                                <input type="text" class="customInput" autocomplete="off" 
                                                [(ngModel)]="nameOfLevel4" name="nameOfLevel4"
                                                formControlName="nameOfLevel4" maxlength="50"
                                                [ngClass]="((!form.controls.nameOfLevel4.valid) && (validation)) ? 'validation_border': ''">
                            </div>
                            <div class="col-lg-2 col-md-12 mt-lg-0 mt-1">
                                <input type="number" class="customInput Cost" style="padding-left: 4px;" 
                                                autocomplete="off" [(ngModel)]="numbersOfLocations4" required
                                                name="numbersOfLocations4" formControlName="numbersOfLocations4"
                                                maxlength="50"
                                                [ngClass]="((css_numbersOfLocations4) && (validation)) ? 'validation_border': ''">
                            </div>
                             <div class="col-lg-2 col-md-12 mt-lg-0 mt-1">
                                <input type="text" class="customInput" autocomplete="off" 
                                                [(ngModel)]="startingLettersNumbers4" name="startingLettersNumbers4"
                                                formControlName="startingLettersNumbers4" maxlength="2" required
                                                [ngClass]="((css_startingLettersNumbers4) && (validation)) ? 'validation_border': ''">
                            </div>
                            <!--<div class="col-lg-2 col-md-12 mt-lg-0 mt-1">
                                <input type="text" class="customInput" autocomplete="off"
                                                [(ngModel)]="specialCharacter4" name="specialCharacter4"
                                                formControlName="specialCharacter4" maxlength="50"
                                                [ngClass]="((!form.controls.specialCharacter4.valid) && (validation)) ? 'validation_border': ''">
                            </div> -->
                        </div>
                        
                        <div class="row mt-2">
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                                <div class="row">
                                    <div class="col-lg-3 col-md-5 trp">
                                        <label class="customLabel">Summary :</label>
                                    </div>
                                    <div class="col-lg-7 col-md-5 trp">
                                        <label class="customLabel">The number of locations to be created is
                                            {{this.totalLocations}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="submit-from text-right mt-1 p-0">

                            <button type="button" class="btn blue-bg round-btn"  style="height: 29px;" (click)="showLocationLevelList()"><i
                                class="fas fa" aria-hidden="true"></i>
                            View </button>
                            <button class="btn blue-bg round-btn"
                                *ngIf="rolePermissionsList && rolePermissionsList[0].isInsert"
                                (click)="addLocationLevelDetails()"><i class="fas fa-save"></i> {{buttonValue}}
                            </button>
                            <button type="button" class="btn darkred-bg round-btn" style="height: 29px;"><i
                                    class="fas fa" aria-hidden="true"></i>
                                Exit </button>
                        </div>

                        <div class="row mt-2" [hidden]="!isShowLocationLevelList">
                            <div class="col-lg-9 col-md-12 mt-lg-0 mt-1">
                                <div class="white-box1" *ngIf="rolePermissionsList && rolePermissionsList[0].isView">
                                    <div class="white-box-title1">
                                        <div class="customHeading mt-3 mb-2">
                                            <div>
                                                <div class="row">
                                                    <div class="col-lg-3 col-md-2 trp" style="font-weight: bold;">
                                                        Location :
                                                      
                                                    </div>
                                                    <!-- <div class="col-lg-5 col-md-2 trp">
                                                        <div class="row">
                                                            <div class="col-lg-12 col-md-2 trp">
                                                                <label class="customLabel" style="margin-left: 300px;">Company</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div class="col-lg-4 col-md-10 borderNone">
                                                        <div class="row">
                                                            
                                                            <div class="col-lg-12 col-md-10 borderNone"
                                                                [ngClass]="((searchValue==0) && (validation)) ? 'validation_border': ''"
                                                                style="margin-left: 3px;">
                                                                <ngx-dropdown-treeview-select
                                                                    class="dropdown-company customInput-dropdown"
                                                                    [config]="config" required
                                                                    [items]="userGroupObjects" [(value)]="searchValue"
                                                                    (valueChange)="onSearchValueChange($event)">
                                                                </ngx-dropdown-treeview-select>
                                                            </div>
                                                        </div>
                                                    </div> -->
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="card-desc1">
                                        <table id="datatable1" class="row-border hover" datatable [dtOptions]="dtOptions1"
                                            [dtTrigger]="dtTrigger1">
                                            <thead>
                                                <tr>
                                                    <th class="coloumn-hide">Company Name</th>
                                                    <th>Name</th>
                                                    <th>Description</th>
                                                    <!-- <th>Location Type</th> -->
                                                    <th>Date Created</th>
                                                    <!-- <th data-orderable="false">Action</th> -->
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let data of locationLevelsList">
                                                    <td class="coloumn-hide">{{data.companyName}}</td>
                                                    <td>{{data.name}}</td>
                                                    <td>{{data.description}}</td>
                                                    <!-- <td>{{data.locationType}}</td> -->
                                                    <td>{{data.createdDate| date: 'MM/dd/yyyy'}}</td>
                                                    <!-- <td>
                                                        <a class="green-text"
                                                            *ngIf="rolePermissionsList && rolePermissionsList[0].isUpdate" (click)="toggleEdit(data)"><i class="fas fa-edit"></i></a>
                                                        <a class="red-text"
                                                            *ngIf="rolePermissionsList && rolePermissionsList[0].isDelete" (click)="deletePopup(data,content)"><i
                                                                class="fas fa-trash-alt"></i></a>
                                                    </td> -->
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>


                    </form>
                </div>
            </div>
            
        </div>
    </div>
</section>

<ng-template #content let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg alert_heading sweet-heading"></h4>
    <button type="button" class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body p-0">
        <div class="thank-you-pop">
            <img src="./assets/image/popup-alert.png" alt="">
            <h1>Confirmation</h1>
            <p class="mt-1 modal-para">{{deleteDetail}}</p>
        </div>
    </div>
    <div class="modal-footer sweet-footer text-center p-1">
        <button type="button" class="btn green-bg round-btn m-1"
            (click)="deleteLocationLevelDetails(deletedItem); c('Close click')">Yes</button>
        <button type="button" class="btn red-bg round-btn m-1" (click)="c('Close click')">No</button>
    </div>
</ng-template>

<ng-template #messageModal let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg alert_heading sweet-heading"></h4>
    <button type="button" class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body">
        <div class="thank-you-pop">
            <img src="{{messageModalImage}}" alt="{{messageModalHeading}}">
            <!-- <h1>{{messageModalHeading}}</h1> -->
            <p class="modal-para m-1">{{messageModalDescription}}</p>
        </div>
    </div>
    <div class="modal-footer sweet-footer text-center p-1">
        <button type="button" class="btn red-bg round-btn" (click)="c('Close click')"
            data-dismiss="modal">Close</button>
    </div>
</ng-template>