<section class="content-section pt-2">
    <div class="loading" *ngIf="loader">Loading&#8230;</div>
    <div col-sm-12>
        <div class="row">
            <div class="col-6 mb-2 d-flex align-items-baseline text-xs-left">
                <div class="page-title">
                    <div class="bread-crumb">
                        <a [routerLink]="['/adminDashboard/']">Home</a>
                        <span class="mx-1">-</span>
                        <span class="active">Email Configuration</span>

                        <div *ngIf="permissionMessage!=''" class='btn-danger text-center'>{{permissionMessage}} </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="white-box1">
                <div class="card-desc1">
                    <form [formGroup]="formSearch">
                        <div class="row detailSection">
                            <div class="col-md-12 container">
                                <div class="row mt-3">
                                    <div class="col-lg-2 col-md-6">
                                        <div class="d-flex align-items-center">
                                            <label class="customLabel mr-1" for="Type">Type</label>
                                            <select class="customInput" (change)="ChangeTCreateUpdate($event)"
                                                [(ngModel)]="TCreateUpdate" id="TCreateUpdate"
                                                formControlName="TCreateUpdate">
                                                <option value="1">Create</option>
                                                <option value="0">Update</option>
                                            </select>
                                        </div>
                                    </div>

                                    <!-- <div class="col-lg-3 col-md-6 mt-md-0 mt-1"
                                        [style.display]="SearchControl==true ? 'block' : 'none'">
                                        <div class="d-flex align-items-center required borderNone"
                                            [ngClass]="((value_search==0) && (SearchValidation)) ? 'validation_border': ''">
                                            <label class="customLabel mr-1" for="Type">Company</label>
                                            <ngx-dropdown-treeview-select [config]="config_search"
                                                class="dropdown-company customInput-dropdown"
                                                [items]="userGroupObjects_search" [(value)]="value_search"
                                                (valueChange)="onValueChange_search($event)">
                                            </ngx-dropdown-treeview-select>

                                        </div>
                                    </div> -->
                                    <div class="col-lg-5 col-md-12 mt-lg-0 mt-1"
                                        [style.display]="SearchControl==true ? 'block' : 'none'">
                                        <div class="d-flex align-items-center">
                                            <label class="customLabel mr-1 ws-nobreak" for="Name">
                                                Name</label>
                                            <input type="text" placeholder="Search Configuration Name"
                                                Class="customInput" [(ngModel)]="searchTerm"
                                                formControlName="searchTerm" required
                                                [ngClass]="((!searchTerm && SearchType==false) && (SearchValidation)) ? 'validation_border': ''"
                                                autocomplete="off" required>
                                            <i class="fas fa-search cpointer ml-3 mr-4 cpointer"
                                                (click)="searchTermF()"></i>
                                            <button class="btn blue-bg round-btn m-0" (click)="AllConfig()">All</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <ng-container *ngIf="rolePermissionsList && rolePermissionsList[0].isView">
                <div class="white-box1" [style.display]="ListShow==true ? 'block' : 'none'">
                    <div class="white-box-title1">
                        <div class="row">
                            <div class="col-md-12">
                                <div class="customHeading mt-3">
                                    Configuration List<i class="fa fa-angle-{{ListAngle}} cpointer ml-2"
                                        aria-hidden="true" (click)="ListDivShowHide()"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-desc1 mt-2" [style.display]="ListDiv==true ? 'block' : 'none'">
                        <div class="table-responsive">
                            <table class="row-border hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Sender email</th>
                                        <th>Smtp server</th>
                                        <th>Port</th>
                                        <th data-orderable="false"> Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let data of configList">
                                        <td>{{data.name}}</td>
                                        <td>{{data.senderEmail}}</td>
                                        <td>{{data.smtp}}</td>
                                        <td>{{data.port}}</td>
                                        <td>
                                            <a *ngIf="rolePermissionsList && rolePermissionsList[0].isUpdate"
                                                class="green-text"><i class="fas fa-edit"
                                                    (click)="toggleEdit(data)"></i></a>
                                            <a *ngIf="rolePermissionsList && rolePermissionsList[0].isDelete"
                                                class="red-text"><i class="fas fa-trash-alt"
                                                    (click)="DeleteConfirm(data,content)"></i></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </ng-container>

            <div class="white-box1">
                <div class="white-box-title1 mb-0">
                    <div class="customHeading mt-3">
                        Add Configuration
                    </div>
                </div>
                <div class="card-desc1">
                    <form [formGroup]="form">
                        <div class="col-lg-12 col-md-12 pl-0">
                            <div class="row mt-2">
                                <!-- <div class="col-lg-4 col-md-12">
                                    <div class="row ">
                                        <div class="col-lg-4 col-md-2 trp required">
                                            <label class="customLabel">Company</label>
                                        </div>
                                        <div class="col-lg-8 col-md-10 borderNone"
                                            [ngClass]="((value==0) && (validation)) ? 'validation_border': ''">
                                            <ngx-dropdown-treeview-select class="dropdown-company customInput-dropdown"
                                                [config]="config" [items]="userGroupObjects" [(value)]="value"
                                                (valueChange)="onValueChange($event)">
                                            </ngx-dropdown-treeview-select>

                                        </div>
                                    </div>
                                </div> -->
                                <div class="col-lg-4 col-md-12">
                                    <div class="row ">
                                        <div class="col-lg-4 col-md-2 trp required">
                                            <label class="customLabel">Name</label>
                                        </div>
                                        <div class="col-lg-8 col-md-10">
                                            <input type="text" class="customInput" [(ngModel)]="txtName"
                                                formControlName="txtName" autocomplete="off" maxlength="500" required
                                                [ngClass]="((!form.controls.txtName.valid) && (validation)) ? 'validation_border': ''"
                                                aria-describedby="inputGroupPrepend2">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-12">
                                    <div class="row ">
                                        <div class="col-lg-4 col-md-2 trp required">
                                            <label class="customLabel">Sender email</label>
                                        </div>
                                        <div class="col-lg-8 col-md-10">
                                            <input type="text" class="customInput EmailValidation"
                                                placeholder="info@vehicle.com"
                                                pattern="[0-9a-zA-Z._%+-]+@[0-9a-zA-Z.-]+\.[a-zA-Z]{2,3}$"
                                                [(ngModel)]="txtEmail" formControlName="txtEmail" autocomplete="off"
                                                maxlength="100" required
                                                [ngClass]="((!form.controls.txtEmail.valid) && (validation)) ? 'validation_border': ''">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-12">
                                    <div class="row ">
                                        <div class="col-lg-4 col-md-2 trp required">
                                            <label class="customLabel">Sender email App password</label>
                                        </div>
                                        <div class="col-lg-8 col-md-10">
                                            <input type="password" class="customInput" [(ngModel)]="txtappPassword"
                                                formControlName="txtappPassword" autocomplete="off" maxlength="500"
                                                required
                                                [ngClass]="((!form.controls.txtappPassword.valid) && (validation)) ? 'validation_border': ''">
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="row mt-2">
                                <div class="col-lg-4 col-md-12">
                                    <div class="row ">
                                        <div class="col-lg-4 col-md-2 trp required">
                                            <label class="customLabel">Sender email password</label>
                                        </div>
                                        <div class="col-lg-8 col-md-10">
                                            <input type="password" class="customInput" [(ngModel)]="txtPassword"
                                                formControlName="txtPassword" autocomplete="off" maxlength="500"
                                                required
                                                [ngClass]="((!form.controls.txtPassword.valid) && (validation)) ? 'validation_border': ''">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-12">
                                    <div class="row ">
                                        <div class="col-lg-4 col-md-2 trp required">
                                            <label class="customLabel">Smtp server</label>
                                        </div>
                                        <div class="col-lg-8 col-md-10">
                                            <div class="part-cost-block">
                                                <span class="dollar customLabel" placement="bottom"
                                                    ngbTooltip="Simple Mail Transfer Protocol. It's an application used by mail servers to send, receive, and/or relay outgoing mail between email senders and receivers. ... For example, the SMTP server Gmail uses is smtp.gmail.com"><i
                                                        class="fa fa-info-circle"></i></span>
                                                <input type="text" class="customInput pl-4" [(ngModel)]="txtSmtp"
                                                    formControlName="txtSmtp" autocomplete="off" maxlength="50" required
                                                    [ngClass]="((!form.controls.txtSmtp.valid) && (validation)) ? 'validation_border': ''">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-12">
                                    <div class="row ">
                                        <div class="col-lg-4 col-md-2 trp required">
                                            <label class="customLabel">Port</label>
                                        </div>
                                        <div class="col-lg-8 col-md-10">
                                            <div class="part-cost-block">
                                                <span class="dollar customLabel" placement="bottom"
                                                    ngbTooltip="Port 587: The standard secure SMTP port
                                                    Modern email servers use port 587 for the secure submission of email for delivery."><i
                                                        class="fa fa-info-circle"></i></span>
                                                <input type="text" class="customInput NumberOnly pl-4"
                                                    [(ngModel)]="txtPort" formControlName="txtPort" autocomplete="off"
                                                    maxlength="3" required
                                                    [ngClass]="((!form.controls.txtPort.valid) && (validation)) ? 'validation_border': ''">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-lg-4 col-md-12">
                                    <div class="row ">
                                        <div class="col-lg-4 col-md-2 trp required">
                                            <label class="customLabel">Trigger</label>
                                        </div>
                                        <div class="col-lg-8 col-md-10 email-configure-height">

                                            <ng-multiselect-dropdown placeholder="Select" [settings]="dropdownSettings"
                                                [data]="dropdownList" [(ngModel)]="selectedItems"
                                                formControlName="selectedItems"
                                                [ngClass]="((!form.controls.selectedItems.valid) && (validation)) ? 'validation_border': ''"
                                                (onSelect)="onItemSelect($event)" (onDeSelect)="onItemDeSelect($event)"
                                                (onSelectAll)="onSelectAll($event)"
                                                (onDeSelectAll)="onDeSelectAll($event)">
                                            </ng-multiselect-dropdown>

                                            <!-- <ng-multiselect-dropdown-angular7 [data]="dropdownList"
                                                [(ngModel)]="selectedItems" formControlName="selectedItems"
                                                [settings]="dropdownSettings" (onSelect)="onItemSelect($event)"
                                                (onSelectAll)="onSelectAll($event)"
                                                (onDeSelectAll)="onDeSelectAll($event)"
                                                (onDeSelect)="onItemDeSelect($event)">
                                            </ng-multiselect-dropdown-angular7> -->

                                            <!-- <mat-form-field>
                                                <mat-select placeholder="Select" formControlName="selectedItems" [(value)]="selectedItems" [(ngModel)]="selectedItems" multiple>
                                                  <mat-option *ngFor="let item of dropdownList" [value]="item.item_id">{{item.item_text}}</mat-option>
                                                </mat-select>
                                              </mat-form-field> -->

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="col-lg-12 col-md-12 pr-0">
                            <div class="submit-from text-right mt-1 p-0">
                                <button class="btn darkred-bg round-btn" (click)="openModelTestMail()"
                                    [disabled]="!value || !txtName || !txtEmail || !txtPassword || !txtSmtp || !txtPort"><i
                                        class="fa fa-envelope" aria-hidden="true"></i>
                                    Send test email </button>
                                <button *ngIf="rolePermissionsList && rolePermissionsList[0].isInsert"
                                    class="btn blue-bg round-btn" (click)="addConfig()"><i class="fas fa-save"
                                        aria-hidden="true"></i>{{buttonValue}}</button>
                                <button class="btn darkred-bg round-btn" *ngIf="clearbutton==true"
                                    (click)="resetConfigData()"><i class="fas fa-redo" aria-hidden="true"></i>
                                    Clear </button>
                                <button class="btn darkred-bg round-btn" *ngIf="clearbutton==false"
                                    (click)="configBack()"><i class="fas fa-sign-out-alt" aria-hidden="true"></i>
                                    Exit </button>
                            </div>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    </div>
</section>

<ng-template #testMailModel let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg alert_heading sweet-heading">Send test email</h4>
    <button class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body">
        <form [formGroup]="testEmailForm">
            <div class="form-group row mb-0 required">
                <label for="inputEmail3" class="col-sm-2 col-form-label customLabel">To</label>
                <div class="col-sm-6 ">
                    <input class="customInput EmailValidation"
                        pattern="[0-9a-zA-Z._%+-]+@[0-9a-zA-Z.-]+\.[a-zA-Z]{2,3}$" formControlName='txtEmailSendTo'
                        type="text" [(ngModel)]="txtEmailSendTo" required
                        [ngClass]="((!testEmailForm.controls.txtEmailSendTo.valid) && (EmailTempValidate)) ? 'validation_border': ''" />

                </div>
            </div>
            <div class="form-group row mb-0">
                <label for="inputEmail3" class="col-sm-2 col-form-label customLabel">Cc</label>
                <div class="col-sm-6 ">
                    <input class="customInput EmailValidation"
                        pattern="[0-9a-zA-Z._%+-]+@[0-9a-zA-Z.-]+\.[a-zA-Z]{2,3}$" formControlName='txtEmailSendToCc'
                        type="text" [(ngModel)]="txtEmailSendToCc" />

                </div>
            </div>
            <div class="form-group row mb-0">
                <label for="inputEmail3" class="col-sm-2 col-form-label customLabel">Bcc</label>
                <div class="col-sm-6 ">
                    <input class="customInput EmailValidation"
                        pattern="[0-9a-zA-Z._%+-]+@[0-9a-zA-Z.-]+\.[a-zA-Z]{2,3}$" formControlName='txtEmailSendToBcc'
                        type="text" [(ngModel)]="txtEmailSendToBcc" />

                </div>
            </div>
            <div class="form-group row mb-0 required">
                <label for="inputEmail3" class="col-sm-2 col-form-label customLabel">Subject</label>
                <div class="col-sm-6">
                    <input class="customInput" formControlName='txtEmailSubject' type="text" required
                        [(ngModel)]="txtEmailSubject"
                        [ngClass]="((!testEmailForm.controls.txtEmailSubject.valid) && (EmailTempValidate)) ? 'validation_border': ''" />
                </div>
            </div>
            <div class="form-group row">
                <label for="inputEmail3" class="col-sm-2 col-form-label customLabel">Body</label>
                <div class="col-sm-10">
                    <ckeditor [(ngModel)]="templateBody" name="myckeditor" formControlName="templateBody"
                        [config]="ckeConfig" debounce="500">
                    </ckeditor>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer sweet-footer text-center p-1">
        <button type="button" class="btn round-btn green-bg m-1" (click)="sendMail();">Send</button>
        <button type="button" class="btn round-btn red-bg m-1" (click)="c('Close click')">No</button>
    </div>
</ng-template>

<ng-template #content let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg alert_heading sweet-heading"></h4>
    <button class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body">
        <div class="thank-you-pop">
            <img src="./assets/image/popup-alert.png" alt="">
            <h1>Confirmation</h1>
            <p class="modal-para m-1">{{deleteDetail}}</p>
        </div>
    </div>
    <div class="modal-footer sweet-footer text-center p-1">
        <button type="button" class="btn round-btn green-bg m-1"
            (click)="DeleteConfig(deletedItem); c('Close click')">Yes</button>
        <button type="button" class="btn round-btn red-bg m-1" (click)="c('Close click')">No</button>
    </div>
</ng-template>

<ng-template #messageModal let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg alert_heading sweet-heading"></h4>
    <button class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body">
        <div class="thank-you-pop">
            <img src="{{messageModalImage}}" alt="{{messageModalHeading}}">
            <p class="modal-para m-1">{{messageModalDescription}}</p>
        </div>
    </div>
    <div class="modal-footer sweet-footer text-center p-1">
        <button type="button" class="btn red-bg round-btn" (click)="c('Close click')"
            data-dismiss="modal">Close</button>
    </div>
</ng-template>