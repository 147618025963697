<div class="wrapper">
  <div class="head">
    <app-header *ngIf="(router.url !== '/' && this.resetPasordUrl !== '/forgotPassword')"
    (menuState)='burgerClicked($event)'></app-header>
  </div>
  <div class="boxcontent">
    <div id="content-scroll" class="content-scroll">
      <div class="volant-sidebar">
        <app-sidebar *ngIf="(router.url !== '/' && this.resetPasordUrl !== '/forgotPassword')" [subMenuState]="subMenuState"></app-sidebar>
      </div>
      <div class="volant-body container-fluid p-0">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>

  <div class="foot">
    <app-footer *ngIf="(router.url !== '/' && this.resetPasordUrl !== '/forgotPassword')"></app-footer>
  </div>
</div>