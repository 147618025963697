
import { Compiler, Component, HostListener, ViewChild } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';
import { slideInAnimation } from './animations';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.css'],
  animations: [slideInAnimation]
})
export class AppComponent {
  
  isSideBarActive = true;
  resetPasordUrl: string;
  

  constructor(public router: Router,
    private _compiler: Compiler,
  ) {
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.resetPasordUrl = e.url.split('?')[0];

      }
    });
  }
  getAnimationData(outlet: RouterOutlet) {
    this._compiler.clearCache();
    return outlet && outlet.activatedRouteData && outlet.activatedRouteData.animation;
  }
  subMenuState: boolean = false;
  burgerClicked(evnt) {
    this.subMenuState = evnt;
  }

  @HostListener('window:keypress', ['$event'])
  keyEvent(event: KeyboardEvent) {

    if (event.keyCode === 32 && event.srcElement['value'].trim() === '') {
      event.preventDefault();
      return false;
    } else if ((<HTMLInputElement>event.target).className.indexOf('PhoneValidation') !== -1) {
      const pattern = /[0-9\+\-\ ]/;
      const inputChar = String.fromCharCode(event.charCode);
      if (!pattern.test(inputChar) && event.charCode !== 0) {
        event.preventDefault();
        return false;
      }
    }
    else if ((<HTMLInputElement>event.target).className.indexOf('CharacterWithSpace') !== -1) {
      const pattern = /^[A-Za-z ]+$/;
      const inputChar = String.fromCharCode(event.charCode);
      if (!pattern.test(inputChar) && event.charCode !== 0) {
        event.preventDefault();
        return false;
      }
    }
    else if ((<HTMLInputElement>event.target).className.indexOf('Character') !== -1) {
      const pattern = /[a-zA-Z/s]/;
      const inputChar = String.fromCharCode(event.charCode);
      if (!pattern.test(inputChar) && event.charCode !== 0) {
        event.preventDefault();
        return false;
      }
    }
    else if ((<HTMLInputElement>event.target).className.indexOf('NumberOnly') !== -1) {
      const pattern = /[0-9]/;
      const inputChar = String.fromCharCode(event.charCode);
      if (!pattern.test(inputChar) && event.charCode !== 0) {
        event.preventDefault();
        return false;
      }
    }
    else if ((<HTMLInputElement>event.target).className.indexOf('Cost') !== -1) {
      const pattern = /[\d+(\.\d{1,2})?]/;
      const inputChar = String.fromCharCode(event.charCode);
      if (!pattern.test(inputChar) && event.charCode !== 0) {
        event.preventDefault();
        return false;
      }
    }

    else if ((<HTMLInputElement>event.target).className.indexOf('CostQty') !== -1) {
      const pattern = /[\d+(\.\d{1,2})?]/;
      const inputChar = String.fromCharCode(event.charCode);
      if (!pattern.test(inputChar) && event.charCode !== 0) {
        event.preventDefault();
        return false;
      }
    }

    else if ((<HTMLInputElement>event.target).className.indexOf('DecimalNumber') !== -1) {
      // const pattern = /[\d+(\.\d{1,2})?]/;
      const pattern = /^[A-Za-z0-9 ]+$/;
      const inputChar = String.fromCharCode(event.charCode);
      if (!pattern.test(inputChar) && event.charCode !== 0) {
        event.preventDefault();
        return false;
      }
    }
    else if ((<HTMLInputElement>event.target).className.indexOf('CostAmount') !== -1) {
      const pattern = /[\d+(\.\d{1,2})?]/;
      const inputChar = String.fromCharCode(event.charCode);
      if (!pattern.test(inputChar) && event.charCode !== 0) {
        event.preventDefault();
        return false;
      }
    }

    else if ((<HTMLInputElement>event.target).className.indexOf('POSO') !== -1) {
      const pattern = /^[0-9 -]+$/;
      const inputChar = String.fromCharCode(event.charCode);
      if (!pattern.test(inputChar) && event.charCode !== 0) {
        event.preventDefault();
        return false;
      }
    }
  }
}
