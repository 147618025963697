import { Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import { FormGroup } from '@angular/forms';
import { SharedService } from 'src/app/services/shared.service';
import { VendorService } from 'src/app/services/vendor/vendor.service';

@Component({
  selector: 'app-vendor-card',
  templateUrl: './vendor-card.component.html',
  styleUrls: ['./vendor-card.component.css', '../purchaseorder-details/po-detail/po-detail.component.css']
})
export class VendorCardComponent implements OnInit{
  @Input() formGrp: any; 
  @Input() companyId: number;
  @Input() isVendor: boolean = true;
  @Output() vendorIdEvent = new EventEmitter<number>();
  @Output() addVendorEvent = new EventEmitter<boolean>();
  vendors: any = [];
  arr: any[] = [];
  filteredVendors: any[] = [];
  stateList: any = [];
  countryList: any = [];
  addVendorForm: FormGroup;
  constructor(
    private vendorService: VendorService,
    private sharedService: SharedService,
  ) {
    this.getCountryList();
    this.getStateList();
  }

  ngOnInit(): void {
  }

  onVendorInput(value: any) {
    this.vendors = [];
    this.arr = [];
    this.filteredVendors = [];
    if(this.companyId>0 && this.companyId != null && this.companyId != undefined){
      this.vendorService.GetAllVendor(this.companyId, value).subscribe(
        (data: any) => {
            this.vendors = data.dataList;
            //old
            this.arr = this.vendors.filter(option =>
              option.vendorName.toLowerCase().includes(value.toLowerCase())
            )
            this.filteredVendors = this.arr;
        }
      )
    }
    
  }


  getVendor(value: any) {
    this.vendorIdEvent.emit(value);
  }

  addVendor(){
    this.addVendorEvent.emit();
  }

  getStateList() {
    this.sharedService.getAllState(0).subscribe((data: any) => {
      if (data.dataList.length > 0) {
        this.stateList = data.dataList;
      }
    });
  }

  GetStateName(id: any) {
    if (this.stateList.length > 0 && id > 0) {
      const list = this.stateList.filter(x => x.id == Number(id));
      if (list.length > 0) {
        if(list[0].abbreviation.trim().length>0){
          return list[0].abbreviation;
        }else{
          return list[0].name.trim();
        }
      }
    }
    return '';
  }

  getCountryList() {
    this.sharedService.GetCountry().subscribe((data: any) => {
      if (data.dataList.length > 0) {
        this.countryList = data.dataList;
      }
    });
  }

  GetCountryName(id: any) {
    if (this.countryList.length > 0 && id > 0) {
      const list = this.countryList.filter(x => x.id == Number(id));
      if (list.length > 0) {
        if(list[0].countryCode.trim().length>0){
          return list[0].countryCode.trim();
        }else{
          return list[0].name.trim();
        }
      }
    }
    return '';
  }
}
