import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { ConstantsService } from 'src/app/constants.service';
import { SharedService } from 'src/app/services/shared.service';
// import { TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { GlobalChangeService } from '../services/global-change.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-ecommerce-api-details',
  templateUrl: './ecommerce-api-details.component.html',
  styleUrls: ['./ecommerce-api-details.component.css']
})
export class EcommerceApiDetailsComponent implements AfterViewInit, OnDestroy, OnInit {
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  data: any;
  validation: boolean;
  id: any;
  userId: any;
  deletedItem: string;
  deleteDetail: string;
  loader: boolean = false;
  form: FormGroup;
  userRoleList: any;
  buttonValue: any;
  settingDetailCSS: any;
  recordAlreadyExist = false;
  settingLogoImage: any;
  clearbutton: boolean = true;
  rolePermissionsList: any;
  permissionMessage: any;
  TCreateUpdate: any = 1;
  ProductSearch: any = "";
  SearchValidation: any;
  SearchControl: boolean = false;
  ListShow: boolean = false;
  ListDiv: boolean = false;
  formSearch: FormGroup;
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  // config = TreeviewConfig.create({
  //   hasAllCheckBox: true,
  //   hasFilter: true,
  //   hasCollapseExpand: true,
  //   decoupleChildFromParent: false,
  //   maxHeight: 400
  // });
  // config1 = TreeviewConfig.create({
  //   hasAllCheckBox: true,
  //   hasFilter: true,
  //   hasCollapseExpand: true,
  //   decoupleChildFromParent: false,
  //   maxHeight: 400
  // });
  userGroupObjects = new Array();
  userGroupObjects1 = new Array();
  companyList: any;
  value = 0;
  value1 = 0;
  companyLoginId: any;
  userSearch: any;
  ListAngle: any;
  SearchType: any;
  value_search: number;
  userGroupObjects_search = new Array();

  companyList_search: any;
  // items_search: TreeviewItem[];
  createdDateEdit: any;
  apiKey: any;
  websiteName: any;
  userName: any;
  password: any;
  ecommerceDataList: any;
  websiteUrl: any;
  wordpressId: any;

  constructor(
    private route: ActivatedRoute,
    public sharedService: SharedService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private constants: ConstantsService,
    private router: Router,
    private globalChangeService: GlobalChangeService
  ) { }

  ngOnInit(): void {
    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);
    } else {
      this.userId = localStorage.getItem(this.constants.userId);
      this.buttonValue = this.constants.Save;
      this.dtOptions = {
        pagingType: this.constants.full_numbers,
        pageLength: 10,
      };
    }
    this.form = new FormGroup({
      'websiteName': new FormControl(),
      'websiteUrl': new FormControl(),
      'apiKey': new FormControl(),
      'userName': new FormControl(),
      'password': new FormControl(),
    });

    this.formSearch = new FormGroup({
      'TCreateUpdate': new FormControl(),
      'userSearch': new FormControl(),
    });

    this.rolePermissionsList = JSON.parse(localStorage.getItem('rolePermissionsList'));
    this.rolePermissionsList = this.rolePermissionsList.filter(x => x.pageId == 1033);
    this.permissionMessage = '';
    if (this.rolePermissionsList != null && this.rolePermissionsList != undefined) {
      if (!this.rolePermissionsList[0].isView) {
        this.permissionMessage += 'view';
      } if (!this.rolePermissionsList[0].isInsert) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'add';
      } if (!this.rolePermissionsList[0].isUpdate) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'edit';
      } if (!this.rolePermissionsList[0].isDelete) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'delete';
      }
    }
    if (this.permissionMessage != '') {
      this.permissionMessage = 'You do not have access to ' + this.permissionMessage + '. Please contact to administrator!';
    }
    this.companyLoginId = localStorage.getItem(this.constants.companyId);
    // this.getAllCompany(this.companyLoginId);
    // this.getAllCompany_search(this.companyLoginId);

    //ankit implementaion
    // get company by default set value
    var company = this.globalChangeService.getGlobalCompany();
    if(company>0){
      this.value = company;
      this.value_search = company;
    }
    else{
      this.ErrorModal('Error', 'Please select company.');
    }

    //on change function 
    this.globalChangeService.dropdownChange$.subscribe((data)=>{
      this.onValueChange(data);
      this.onValueChange_search(data);
    })
  }

  ngAfterViewInit() {
    this.settingDetailCSS = localStorage.getItem("settingDetailCSS");
    this.settingLogoImage = localStorage.getItem("settingImageLogo");
    this.SetSettingCSS(this.settingDetailCSS, this.settingLogoImage);
    this.dtTrigger.next('');
  }


  getAllEcommerceDetails(companyId, SearchItem) {
    if (companyId == undefined) { companyId = 0; }
    this.sharedService.GetAllEcommerceDetails(companyId, SearchItem).subscribe(
      data => {
        this.loader = true;
        this.data = data;
        this.ecommerceDataList = this.data.dataList;
        this.loader = false;
        this.rerender();
        this.ListShow = true;
        this.ListDiv = true;
        this.ListAngle = "up";

      });
  }

  addNewEcommerceApiDetails() {
    this.validation = true;
    this.clearbutton = true;
    if (this.value == 0 || this.form.controls.websiteName.status === this.constants.Invalid || this.form.controls.websiteUrl.status === this.constants.Invalid
      || this.form.controls.userName.status === this.constants.Invalid || this.form.controls.password.status === this.constants.Invalid
    ) {
      return;
    }

    this.loader = true;
    const obj = {};
    obj['WebsiteName'] = this.websiteName;
    obj['Website'] = this.websiteUrl;
    obj['ApiKey'] = this.apiKey;
    obj['UserName'] = this.userName;
    obj['Password'] = this.password;
    obj['CompanyId'] = Number(this.value);
    obj['CreatedBy'] = this.userId;
    if (this.id != undefined && this.id != null && this.id != '') {
      obj['Id'] = this.id;
      obj['ModifiedBy'] = this.userId;
      obj['CreatedDate'] = this.createdDateEdit
      this.sharedService.updateEcommerceApiDetails(obj)
        .subscribe(
          data => {
            switch (data.statusCode) {
              case 200:
                this.loader = false;
                this.buttonValue = this.constants.Save;
                $("#add_update").show();
                // this.TCreateUpdate = 1;
                // this.SearchControl = false;
                this.ListShow = false;
                this.ListDiv = false;
                this.validation = false;
                this.id = '';
                this.resetEcommerceApiDetails();
                this.AllUser();
                break;
              case 400:
                this.loader = false;
                if (data.errorMessage === 'Record Already Exist') {
                  $("#wrong_msgupdate").show();

                } else {
                  $("#wrong_msg").show();
                }
                break;
              case 208:
                this.loader = false;
                $("#wrong_msgupdate").show();
                break;
              case 501:
                this.loader = false;
                $("#notimplement").show();
                break;

              default:
                this.loader = false;
                $("#wrong_msg").show()
            }
          }
        );
    } else {
      this.sharedService.addEcommerceApiDetails(obj)
        .subscribe(
          data => {
            switch (data.statusCode) {
              case 200:
                this.loader = false;
                this.recordAlreadyExist = false;
                $("#add_success").show();
                this.validation = false;
                this.resetEcommerceApiDetails();
                this.AllUser();
                break;
              case 400:
                this.loader = false;
                if (data.errorMessage === 'Record Already Exist') {
                  $("#wrong_msgupdate").show();

                } else {
                  $("#wrong_msg").show();
                }
                break;
              case 208:
                this.loader = false;
                $("#wrong_msgupdate").show();
                break;
              case 501:
                this.loader = false;
                $("#notimplement").show();
                break;
              case 0:
                this.loader = false;
                this.recordAlreadyExist = true;
                break;
              default:
                $("#wrong_msg").show()
            }
          }
        );
    }
  }

  notimplementClose() {
    $('#notimplement').hide();
  }

  deletePopup(item, content) {
    this.deletedItem = '';
    this.modalService.open(content, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
    this.deleteDetail = 'Do you want to delete ' + item.websiteName + '?';
    this.deletedItem = item.id;
  }


  deleteUserEcommerceDetails(item: any) {
    this.sharedService.deteleEcommerceApiDetails(item).subscribe(
      data => {
        this.data = data;
        this.loader = true;
        switch (data.statusCode) {
          case 200:
            $("#delete_success").show();
            // this.toastr.success(this.constants.RecordDeletedSuccessfully);
            this.getAllEcommerceDetails(this.value_search, this.userSearch == "" ? "" : this.userSearch);
            this.rerender();
            this.loader = false;
            break;
          case 405:
            $("#wrong_msg1").show();
            this.loader = false;
            break;
          case 400:
            $("#wrong_msg").show();
            this.loader = false;

            break;
          default:
            $("#wrong_msg").show();
            this.loader = false;


        }
      });
  }
  toggleEdit(item: any) {
    this.ListDiv = false;
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.buttonValue = this.constants.Update;
    this.websiteName = item.websiteName;
    this.websiteUrl = item.website;
    this.apiKey = item.apiKey;
    this.userName = item.userName;
    this.password = item.password;
    this.id = item.id;
    this.clearbutton = false;
    this.value = item.companyId;
    this.createdDateEdit = item.createdDate == null ? '' : item.createdDate == undefined ? '' : item.createdDate;
  }


  SetSettingCSS(settingCSS, logoImagePath) {
    if (settingCSS != null) {
      let headerCss = document.getElementsByClassName(this.constants.CssHealerClass) as HTMLCollectionOf<HTMLElement>;
      if (headerCss.length != 0) {
        headerCss[0].style.backgroundColor = settingCSS.split(',')[0];
      }
      let saveButtonCss = document.getElementsByClassName(this.constants.CssSaveBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (saveButtonCss.length != 0) {
        saveButtonCss[0].style.backgroundColor = settingCSS.split(',')[1];
      }
      let cencelButtonCss = document.getElementsByClassName(this.constants.CssCancelBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (cencelButtonCss.length != 0) {
        cencelButtonCss[0].style.backgroundColor = settingCSS.split(',')[2];
      }
    }

    if (logoImagePath != null && logoImagePath != "") {
      (document.getElementById('hrdlogoImage') as HTMLImageElement).src = logoImagePath;
    }

  }
  EcommerceApiDetailsBack() {
    this.resetEcommerceApiDetails();
    this.clearbutton = true;
    this.id = null;
    this.buttonValue = this.constants.Save;
  }

  // getAllCompany(companyId) {
  //   this.sharedService.GetDropdownCompanyHierarchyModel(companyId).subscribe(
  //     data => {
  //       this.data = data;
  //       if (this.data.dataList != null && this.data.dataList != undefined) {
  //         this.companyList = this.data.dataList;
  //         this.companyList.forEach((x) => {
  //           this.userGroupObjects.push(new TreeviewItem(x, false));

  //         });
  //       }
  //       this.value = Number(companyId);
  //     });
  // }
  onValueChange(value: any): void {
    this.value = value;

  }

  ChangeTCreateUpdate(event) {
    this.TCreateUpdate = event.target.value;
    if (this.TCreateUpdate == "1") {
      this.SearchControl = false;
      this.ListShow = false;
      this.ListDiv = false;
      this.clearbutton = true;
      this.buttonValue = this.constants.Save;
      this.resetEcommerceApiDetails();
      ////this.value_search=0;
    }
    else if (this.TCreateUpdate == "0") {
      this.SearchControl = true;
      this.userSearch = "";
      //this.value_search=0;
    }
  }

  ListDivShowHide() {
    if (this.ListDiv == true) {
      this.ListDiv = false;
      this.ListAngle = "down";
    }
    else if (this.ListDiv == false) {
      this.ListDiv = true;
      this.ListAngle = "up";
    }
  }

  searchUser() {
    this.SearchType = false;
    if (!this.userSearch || this.value_search == 0) {
      this.SearchValidation = true;
      return;
    }

    this.getAllEcommerceDetails(this.value_search, this.userSearch);
  }

  AllUser() {
    this.SearchType = true;
    if (this.value_search == 0) {
      this.SearchValidation = true;
      return;
    }
    // this.SearchValidation = false;
    this.getAllEcommerceDetails(this.value_search, '');
  }

  // getAllCompany_search(companyId) {
  //   this.sharedService.GetDropdownCompanyHierarchyModel(this.companyLoginId).subscribe(
  //     data => {
  //       this.data = data;
  //       this.items_search = this.data.dataList;
  //       this.companyList_search = this.data.dataList;
  //       this.companyList_search.forEach((x) => {
  //         this.userGroupObjects_search.push(new TreeviewItem(x, false));
  //       });
  //       this.value_search = Number(companyId);
  //       //this.getAllState();
  //     });
  // }

  onValueChange_search(value: any): void {
    this.value_search = value;

  }
  addsuccess() {
    $("#add_success").hide();
  }
  updateitem() {
    $("#add_update").hide();
  }
  deleteitem() {
    $("#delete_success").hide();
  }
  msgitem() {
    $("#wrong_msg").hide();
  }
  msgitemupdate() {
    $("#wrong_msgupdate").hide();
  }

  msgitem1() {
    $("#wrong_msg1").hide();
  }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next('');
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  resetEcommerceApiDetails() {
    this.id = null;
    if (this.companyLoginId > 0) {
      this.value = Number(this.companyLoginId)
    }
    else {
      this.value = 0;
    }
    this.websiteName = '';
    this.websiteUrl = '';
    this.apiKey = '';
    this.userName = '';
    this.password = '';
  }

  
  //ankit implemenation
  ErrorModal(title:string, message:string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }

}


