import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-team-management',
  templateUrl: './team-management.component.html',
  styleUrls: ['./team-management.component.css']
})
export class TeamManagementComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
