<section class="forgetPassword">
    <div class="loading" *ngIf="loader">Loading&#8230;</div>
    

    <div class="row">

        <div class="col-md-5 m-auto">
            <div class="white-box">
                <div class="logoWrapper text-center">
                    <img class="img-fluid" src="/assets/image/logo.jpg">
                </div>
                <div class="card-desc">
                    <form [formGroup]="form">
                        <div class="color-setting">
                            <div class="white-box-title">
                                <h2 class="text-center">Password Details</h2>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group required">
                                        <label class="control-label">Password</label>
                                        <input type="password" class="form-control" [(ngModel)]="password"
                                            formControlName='password' required autocomplete="off" maxlength="40"
                                            placeholder="Enter Password*">
                                        <div *ngIf="((form.controls.password.value==''||form.controls.password.value==undefined) && (validation))"
                                            class="text-danger input-error">
                                            Enter password</div>
                                        <div class="text-danger input-error"
                                            *ngIf="form.get('password').invalid && (form.get('password').touched || form.get('password').dirty)">
                                            <div *ngIf="form.get('password').errors.minlength">
                                                Password must be at least 8 characters
                                            </div>
                                            <div *ngIf="form.get('password').hasError('passwordStrength')">
                                                {{form.get('password').errors['passwordStrength']}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="form-group required">
                                        <label class="control-label">Confirm Password</label>
                                        <input type="password" class="form-control" [(ngModel)]="passworConfirm"
                                            formControlName='passworConfirm' required autocomplete="new-password"
                                            placeholder="Enter Confirm Password*" maxlength="40">
                                        <div class="text-danger input-error">
                                            <div
                                                *ngIf="((form.controls.passworConfirm.value==''||form.controls.passworConfirm.value==undefined) && (validation))">
                                                Enter Confirm Password</div>
                                            <div *ngIf="((password!= passworConfirm) && (form.controls.passworConfirm.value != '' || form.controls.passworConfirm.value != undefined
                                                || form.controls.passworConfirm.value != null))">
                                                Password not match.</div>

                                              
                                        </div>
                                        <p style="color:red; font-size: 12px;">{{Err}}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="submit-from text-center">
                            <button class="btn blue-bg mr-3" (click)="changePassword()"><i class="fas fa-save"
                                    aria-hidden="true"></i> Change Password
                            </button>
                            <button class="btn darkred-bg" (click)="clearPasswordDetails1()"><i class="fas fa-redo"
                                    aria-hidden="true"></i>
                                Clear </button>
                        </div>
                        <div class="back-btn text-center">
                            <button class="btn btn-outline-gray" (click)="Back()"><i class="fa fa-arrow-left" aria-hidden="true"></i> Back
                            </button>
                        </div>
                    </form>
                </div>

            </div>


        </div>
    </div>

</section>

<div class="modal" id="add_update">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="updateitem()" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Update Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="updateitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="wrong_msg">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="msgitem()" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>Something went wrong</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="msgitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

