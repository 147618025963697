<div class="modal-dialog">
    <div class="modal-content">
        <div class="modal-header info-popup-header">
            <button type="button" id="dismis" class="close" (click)="dismiss()" data-dismiss="modal" aria-label="">
                <span>&times;</span></button>
        </div>
        <div class="modal-body">
            <div class="thank-you-pop">
                <img src="./assets/image/popup-alert.png" alt="">
                <h1>{{ title }}</h1>
                <p class="mt-1">{{ message }}</p>
            </div>
        </div>
        <div class="modal-footer sweet-footer p-1">
            <button type="button" class="btn green-bg round-btn m-1" (click)="accept()">{{ btnCancelText }}</button>
            <button type="button" class="btn red-bg round-btn" (click)="decline()" data-dismiss="modal">{{ btnOkText }}</button>
        </div>
    </div>
</div>