<mat-form-field class="form-field w-100" appearance="outline">
    <mat-label>Select Parent Customer</mat-label>
    <mat-select [(value)]="_selectedCustomer"
        (selectionChange)="onParentCustomerChange($event)"
        disableOptionCentering>
        <mat-option [value]="0">None</mat-option>
        <ng-container *ngTemplateOutlet="
          recursiveOptionsMulti;
          context: { $implicit: customers, depth: 1 } ">
        </ng-container>

        <ng-template #recursiveOptionsMulti let-list let-depth="depth">
            <ng-container *ngFor="let customer of list">
                <mat-option [value]="customer.value" [ngStyle]="{
              'padding-left.px': depth <= 1 ? null : 20 * depth
            }">
                    {{ customer.text }}
                </mat-option>

                <ng-container
                    *ngIf="!!customer.children && customer.children.length > 0">
                    <ng-container *ngTemplateOutlet="
                recursiveOptionsMulti;
                context: { $implicit: customer.children, depth: depth + 1 }
              ">
                    </ng-container>
                </ng-container>
            </ng-container>
        </ng-template>
    </mat-select>
</mat-form-field>
