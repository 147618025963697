<section class="content-section pt-2">
    <div class="loading" *ngIf="loader">Loading&#8230;</div>

    <div col-sm-12>
        <div class="row">
            <div class="col-6 mb-2 d-flex align-items-baseline text-xs-left">
                <div class="page-title">
                    <div class="bread-crumb">
                        <a [routerLink]="['/adminDashboard/']">Home</a>
                        <span class="mx-1">-</span>
                        <span class="active">My Production Task</span>
                        <div *ngIf="permissionMessage!=''" class='btn-danger text-center'>{{permissionMessage}} </div>
                    </div>
                </div>
            </div>
            <div class="col-6 mb-2 text-right align-items-baseline">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="white-box1">
                
                <div class="card-desc1">
                    <form [formGroup]="formSearch">
                        <div class="row detailSection">
                            <div class="col-md-12 container">
                                <div class="row my-3">
                                    <div class="col-lg-2 col-md-6">
                                        <div class="d-flex align-items-center">
                                            <label class="customLabel mr-1" for="Type">Type</label>
                                            <select class="customInput pl-1px" (change)="ChangeTCreateUpdate($event)"
                                                [(ngModel)]="TCreateUpdate" id="TCreateUpdate"
                                                formControlName="TCreateUpdate">
                                                <option value="1">Create</option>
                                                <option value="0">Update</option>
                                            </select>
                                        </div>
                                    </div>

                                    <!-- <div class="col-lg-3 col-md-6 mt-md-0 mt-1"
                                        [style.display]="SearchControl==true ? 'block' : 'none'">
                                        <div class="d-flex align-items-center required borderNone"
                                            [ngClass]="((value_search==0) && (SearchValidation)) ? 'validation_border': ''">
                                            <label class="customLabel mr-1" for="Type">Company</label>
                                            <ngx-dropdown-treeview-select [config]="config"
                                                class="dropdown-company customInput-dropdown custom_btn"
                                                [items]="userGroupObjects_search" [(value)]="value_search"
                                                (valueChange)="onValueChange_search($event)">
                                            </ngx-dropdown-treeview-select>
                                        </div>
                                    </div> -->
                                    
                                    <div class="col-lg-5 col-md-12 mt-lg-0 mt-1"
                                    [style.display]="SearchControl==true ? 'block' : 'none'">

                                        <div class="d-flex align-items-center">
                                            <label class="customLabel mr-1 ws-nobreak" for="Start Date">Start Date</label>
                                            <input type="date" placeholder="Select Start Date"
                                                class="customInput" [(ngModel)]="userSearch"
                                                formControlName="userSearch" autocomplete="off" required
                                                [ngClass]="((!userSearch && SearchType==false) && (SearchValidation)) ? 'validation_border': ''">
                                            <!-- <div *ngIf="((!userSearch && SearchType==false) && (SearchValidation))"
                                                class="text-danger input-error err-fontSize header_error" style="">
                                                Enter User Name.</div> -->
                                          
                                            <button class="btn blue-bg round-btn m-0" (click)="AllUser()">Get List</button>
                                        </div>
                                    </div>
                                </div>
                           
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="white-box1" *ngIf="rolePermissionsList && rolePermissionsList[0].isView"
                [style.display]="ListShow==true ? 'block' : 'none'">
                <div class="white-box-title1">
                    <div class="customHeading">
                        My Production Task List<i class="fa fa-angle-{{ListAngle}} cpointer ml-2" aria-hidden="true"
                            (click)="ListDivShowHide()"></i>
                    </div>
                </div>
                <!------------------------------------- company list table ------------------------------>
                <div class="card-desc1 mt-1" [style.display]="ListDiv==true ? 'block' : 'none'">
                    <table id="datatable" class="display table clearfix" class="row-border hover" datatable
                        [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                        <thead>
                            <tr>
                                <th hidden></th>
                                <th>Item</th>
                                <th>Vendor</th>
                                <th>Part Qty</th>
                                <th>Labor Hours</th>
                                <th>Workflow Step</th>
                                <th>Start Date</th>
                                <th>End Date</th>
                                <th class="width-75">Alerts</th>
                                
                            </tr>
                        </thead>
                        <tbody>
                            <tr>		
                                <td style="display: contents !important"></td>							
                                <td>1</td>
                                <td>Lori</td>
                                <td>5</td>
                                <td>2</td>
                                <td>Step 1</td>
                                <td>12/01/2020</td>
                                <td>12/01/2022</td>
                                <td>2</td>
                                
                            </tr>
                            <tr>	
                                <td style="display: contents !important"></td>							
                                <td>2</td>
                                <td>Mark</td>
                                <td>5</td>
                                <td>2</td>
                                <td>Step 2</td>
                                <td>12/01/2020</td>
                                <td>12/01/2022</td>
                                <td>2</td>
                               
                            </tr>
                            <tr>
                                <td style="display: contents !important"></td>									
                                <td>3</td>
                                <td>Jim</td>
                                <td>5</td>
                                <td>2</td>
                                <td>Step 3</td>
                                <td>12/01/2020</td>
                                <td>12/01/2022</td>
                                <td>2</td>
                               
                            </tr>
                            <tr>	
                                <td style="display: contents !important"></td>								
                                <td>4</td>
                                <td>Smith</td>
                                <td>5</td>
                                <td>2</td>
                                <td>Step 4</td>
                                <td>12/01/2020</td>
                                <td>12/01/2022</td>
                                <td>2</td>
                                
                            </tr>
                        </tbody>
                    </table>
                </div>

            </div>
        </div>
    </div> 
</section>
