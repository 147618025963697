import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { ConstantsService } from 'src/app/constants.service';
import { GlobalChangeService } from 'src/app/services/global-change.service';
import { PurchaseOrderService } from 'src/app/services/purchaseOrder/purchase-order.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-receiving-congratulations',
  templateUrl: './receiving-congratulations.component.html',
  styleUrls: ['./receiving-congratulations.component.css', '../receiving-details/receiving-details.component.css']
})
export class ReceivingCongratulationsComponent implements OnInit {
  loader: boolean = false;
  companyId: number = 0;
  masterForm: FormGroup;
  userId: string = '';
  savedObj: any;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private POService: PurchaseOrderService,
    private constants: ConstantsService,
    private globalChangeService: GlobalChangeService,
    private datepipe: DatePipe
  ) {
    this.userId = localStorage.getItem('userId');
    this.createMasterForm();
  }
  ngOnInit(): void {
    const id = this.route.snapshot.params['id'];
    this.companyId = this.globalChangeService.getGlobalCompany();
    if (this.companyId > 0) {
      if (id > 0) {
        this.getPObyId(this.companyId, Number(id));
      }
    } else {
      this.popupModal(this.constants.APIError, this.constants.SelectCompany);
    }
    //on change function
    this.globalChangeService.dropdownChange$.subscribe((data) => {
      this.companyId = data;
      this.router.navigate(['/receiving'])
    })
  }

  createMasterForm() {
    this.masterForm = this.formBuilder.group({
      id: new FormControl(0),
      companyId: new FormControl(this.companyId),
      poNumber: new FormControl(''),
      summaryItems: new FormArray([]),
      file: new FormArray([]),
      userId: this.userId
    });
  }

  get summaryItemsGet(): FormArray {
    return this.masterForm.controls.summaryItems as FormArray
  }

  getPObyId(companyId: number, id: number) {
    this.loader = true;
    this.POService.GetPObyId(companyId, id).subscribe({
      next: (data: any) => {
        this.savedObj = data;
      },
      error: (error: any) => {
        this.popupModal(this.constants.APIError, this.constants.APIErrorMsg);
      },
      complete: () => {
        this.loader = false;
        this.setPOData(this.savedObj);
      },
    });
  }

  setPOData(data: any) {
    if (data.data != null) {
      this.createMasterForm();
      this.masterForm.patchValue(data.data);

      if (data.data.file.length > 0) {
        data.data.file.forEach((element) => {
          let x = {
            id: element.id,
            poId: element.poId,
            name: element.name,
            type: element.type,
            size: element.size,
            base64: element.base64,
            docType: element.docType,
            isActive: element.isActive
          };
          this.filesGet.push(this.formBuilder.group(x));
        });
      }
      // this.masterForm.patchValue({
      //   statusOld: data.data.status,
      //   orderDate: data.data.orderDate == '' ? data.data.orderDate : new Date(data.data.orderDate),
      //   confirmationDate: data.data.confirmationDate == '' ? data.data.confirmationDate : new Date(data.data.confirmationDate),
      //   estShipDate: data.data.estShipDate == '' ? data.data.estShipDate : new Date(data.data.estShipDate),
      // })
      if (data.data.summaryItems.length > 0) {
        data.data.summaryItems.forEach((element, index) => {
          this.getItem(element, element.id);
          this.onRecieving(index);
        });
      }
    }
  }

  onRecieving(index: number) {
    const receiving = this.summaryItemsGet.at(index).get('receiving').value;
    const quantity = this.summaryItemsGet.at(index).get('quantity').value - this.summaryItemsGet.at(index).get('received').value;
    if (receiving == quantity) {
      this.summaryItemsGet.at(index).get('receiving').setValue(this.isWholeNumber(receiving) ? Number(receiving) : 0);
      this.summaryItemsGet.at(index).get('isAllRecieved').setValue(true);
    } else if (receiving > quantity) {
      this.summaryItemsGet.at(index).get('receiving').setValue(this.isWholeNumber(receiving) ? Number(quantity) : 0);
      this.summaryItemsGet.at(index).get('isAllRecieved').setValue(true);
    } else if (receiving < quantity) {
      this.summaryItemsGet.at(index).get('receiving').setValue(this.isWholeNumber(receiving) ? Number(receiving) : 0);
      this.summaryItemsGet.at(index).get('isAllRecieved').setValue(false);
    } else {
      this.summaryItemsGet.at(index).get('receiving').setValue(0);
      this.summaryItemsGet.at(index).get('isAllRecieved').setValue(false);
    }
  }

  isWholeNumber(input: any) {
    const value = Number(input);
    if (!isNaN(value) && value > 0) {
      return true;
    }
    return false;
  }

  getItem(Object: any, id: number): void {
    let summaryItem = this.masterForm.controls.summaryItems as FormArray;
    let disountList: FormArray = new FormArray([]);
    let serialLotList: FormArray = new FormArray([]);
    let receivedetailList: FormArray = new FormArray([]);
    if (Object.vendorDiscount.length > 0) {
      Object.vendorDiscount.forEach(element => {
        disountList.push(this.formBuilder.group({
          id: element.id,
          min: element.min,
          max: element.max,
          discountedCost: element.discountedCost
        }))
      });
    }
    if (Object.receivedetailList.length > 0) {
      Object.receivedetailList.forEach(element => {
        receivedetailList.push(this.formBuilder.group({
          id: new FormControl(id),
          received: new FormControl(element.received),
          tracking: new FormControl(element.tracking),
          serialLot: new FormControl(element.serialLot),
          receiver: new FormControl(element.receiver),
          receivedDate: new FormControl(element.receivedDate),
        }))
      });
    }
    if (Object.serialLotList != null) {
      if (Object.serialLotList.length > 0) {
        Object.serialLotList.forEach(element => {
          serialLotList.push(this.formBuilder.group({
            id: element.id,
            serialNo: element.serialNo,
            inventoryId: element.inventoryId,
            receivedBy: element.receivedBy,
            receivedDate: element.receivedDate,
          }))
        });
      }
    }
    summaryItem.push(this.formBuilder.group({
      id: new FormControl(id),
      isShow: false,
      PartId: Object.partId,
      vendorPartNumber: Object.vendorPartNumber,
      partNo: Object.vendorPartNumber,
      vendorPartName: Object.vendorPartName,
      unitofMeasureId: Object.unitofMeasureId,
      quantity: Object.quantity == null ? 0 : Object.quantity,
      received: Object.received == null ? 0 : Object.received,
      receiving: Object.receiving == null ? 0 : Object.receiving,
      originalCost: Object.cost,
      cost: Object.cost,
      isUpdateUnitCost: Object.isUpdateUnitCost,
      isAllRecieved: Object.isAllRecieved,
      tracking: Object.tracking,
      inventoryType: Object.inventoryType,
      status: Object.status,
      vendorDiscount: disountList,
      extension: Object.cost * (Object.quantity == null ? 0 : Object.quantity),
      serialLotList: serialLotList,
      receivedetailList: receivedetailList
    }))
  }

  backToDetails() {
    const id = this.route.snapshot.params['id'];
    this.router.navigate(['/receiving', id])
  }

  onFileChange(event: any) {
    const files = Object.keys(event.target.files).map(key => event.target.files[key]);
    let fileToUpload = files[0];
    var reader = new FileReader()
    reader.onload = (event: any) => {
      let x = {
        id: 0,
        poId: 0,
        name: fileToUpload.name,
        type: fileToUpload.type,
        size: fileToUpload.size,
        base64: event.target.result,
        docType: 'receive'
      };
      this.filesGet.push(this.formBuilder.group(x));
    }
    reader.readAsDataURL(fileToUpload);
  }

  get filesGet(): FormArray {
    return this.masterForm.controls.file as FormArray
  }

  deleteFile(index: number) {
    this.filesGet.removeAt(index);
  }

  viewImage(img) {
    if (img != null && img != '') {
      var value1 = img.base64.split(",");
      var blob = this.b64toBlob(value1[1], img.type);
      let a = document.createElement("a");
      document.body.appendChild(a);
      var url = window.URL.createObjectURL(blob);
      a.href = url;
      let pdfWindow = window.open("")
      pdfWindow.document.write("<iframe width='100%' height='100%' src='" + encodeURI(url) + "'></iframe>");
    }
  }

  public b64toBlob(b64Data, contentType) {
    contentType = contentType || '';
    let sliceSize = 512;
    var byteCharacters = window.atob(b64Data);
    var byteArrays = [];
    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);
      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      var byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  onReceiveShipment(url: string) {
    let saveObj = this.savedObj.data;
    saveObj.file = this.masterForm.value.file;
    saveObj.userId = this.userId;
    this.loader = true;
    this.POService.UpdateReceiving(saveObj, true).subscribe((data: any) => {
      this.loader = false;
      if (Number(data.data) > 0) {
        this.router.navigate(['/' + url + '']);
      } else {
        this.popupModal(this.constants.ResponseMsg, this.constants.ActionError);
      }
    },
      (error: any) => {
        this.loader = false;
        this.popupModal(this.constants.APIError, this.constants.APIErrorMsg);
      }
    );
  }

  popupModal(title: string, message: string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }
}
