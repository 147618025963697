import { Component } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgModule } from '@angular/core';
import { HelcimService } from 'src/app/services/helcim/helcim.service';

@Component({
  selector: 'app-helcim',
  templateUrl: './helcim.component.html',
  styleUrls: ['./helcim.component.css']
})
export class HelcimComponent {
  href: SafeUrl='';
  amount: any;
  cardHolderName: any;
  cardHolderAddress: any;
  cardHolderPostalCode: any;
  cardNumber: any;
  cardExpiryMonth: any;
  cardExpiryYear: any;
  cardCVV: any;

  ngOnInit() {

  }

  constructor(
    private router: Router,
    private sanitizer: DomSanitizer,
    private helcimService: HelcimService
  ) {
    this.InitPayment();
  }

  InitPayment() {
    const req = {
      "paymentType": "purchase",
      "amount": 100,
      "currency": "USD",
      "paymentMethod": "cc"
    };

    this.helcimService.CreateHelcimCheckoutSession(req).subscribe({
      next: (result) => {
        this.href = this.sanitizer.bypassSecurityTrustUrl("javascript:appendHelcimPayIframe('"+result.data.checkoutToken+"', true)");
      },
      error: (err) => {
        
      }
    });
  }

  submitted = false;

  onSubmit() {
    this.submitted = true;
  }

}

window.addEventListener('message', (event: MessageEvent<any>) => {
  const helcimPayJsIdentifierKey: string = 'helcim-pay-js-' + sessionStorage.getItem('checkoutToken');

  if (event.data.eventName === helcimPayJsIdentifierKey) {

    if (event.data.eventStatus === 'ABORTED') {
      console.error('Transaction failed!', event.data.eventMessage);
    }

    if (event.data.eventStatus === 'SUCCESS') {

      var jsonObj = JSON.parse(event.data.eventMessage);

      const payload = {
        method: 'POST',
        headers: {
          accept: 'application/json',
          'content-type': 'application/json'
        },
        body: JSON.stringify(jsonObj.data.data)
      }
      
      // axios.post('https://api.helcim.com/v2/helcim-pay/initialize', payload)
      //   .then(response => console.log(response))
      //   .catch(err => console.error(err));

    }

  }

});


