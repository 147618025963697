import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CompanyService {
  baseUrl = environment.baseUrl;

  constructor(private _http: HttpClient) { }

  getCompanyHierarchyLevel() {
    return this._http.get(this.baseUrl + 'api/CompanyHierarchy/GetCompanyHierarchyLevel',
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  addNewCompany(item: object): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Company/AddCompany', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  updateCompany(item: object): Observable<any> {
    return this._http.put(this.baseUrl + 'api/Company/UpdateCompany', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  addNewCompanyHierarchy(item: object): Observable<any> {
    return this._http.post(this.baseUrl + 'api/CompanyHierarchy/AddCompanyHierarchy', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  updateCompanyHierarchy(item: object): Observable<any> {
    return this._http.put(this.baseUrl + 'api/CompanyHierarchy/UpdateCompanyHierarchy', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  deleteCompanyHierarchy(item: object): Observable<any> {
    return this._http.post(this.baseUrl + 'api/CompanyHierarchy/DeleteCompanyHierarchy', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  InsUpdCompany(companyDetailsVM: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Company/AddInsUpdCompany', companyDetailsVM,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetAllCompanyHierarchyByCompanyId(id: any) {
    return this._http.get(this.baseUrl + 'api/CompanyHierarchy/GetCompanyHierarchy?companyId=' + id,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetAllCompanyById(id: any) {
    return this._http.get(this.baseUrl + 'api/Company/GetCompany?companyId=' + id,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetAllCompany(companyId) {
    return this._http.get(this.baseUrl + 'api/Company/GetCompanyList?companyId=' + companyId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetAllCompanyList() {
    return this._http.get(this.baseUrl + 'api/Company/GetAllCompanyList',
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetCompanyById(companyId) {
    return this._http.get(this.baseUrl + 'api/Company/GetCompanyById?companyId=' + companyId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  
  GetDropdownCompanyHierarchyModel(companyId) {
    return this._http.get(this.baseUrl + 'api/Company/GetDropdownCompanyHierarchyModel?companyId=' + companyId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetCompanyList(companyId) {
    return this._http.get(this.baseUrl + 'api/Company/GetDropdownCompanyHierarchyModel?companyId=' + companyId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  postFile(formData: any, pram1: any, pram2: any, pram3: any, pram4: any, note: any, pochk: any, opchk: any, PurchaseNumber: any, Prefix: any, salesOrderNo: any, sPrefix: any, saleTax: any, laborGoalPercentage: any, daysToAddShipDate: any, timezone: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Company/UpdateSetting?companyId=' + pram1 + '&colorStyle=' + encodeURIComponent(pram2) + '&laborHrsCost=' + pram3 + '&LaborHrsLoadedCost=' + pram4 + '&note=' + note + '&pochk=' + pochk + '&opchk=' + opchk + '&purchaseOrderNo=' + PurchaseNumber + '&Prefix=' + Prefix + '&salesOrderNo=' + salesOrderNo + '&sPrefix=' + sPrefix + '&saletax=' + saleTax + '&laborgoalpercentage=' + laborGoalPercentage + '&daysToAddShipDate=' + daysToAddShipDate + '&timezone=' + timezone, formData,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetSettingDetails(userId: any) {
    return this._http.get(this.baseUrl + 'api/Company/GetSetting?userId=' + userId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  UpdCompLogo(formData: any, companyId: number): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Company/AddUpdCompLogo?companyId=' + companyId, formData,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetWareHouseWithChild(companyId: Number){
    return this._http.get(this.baseUrl + 'api/Company/GetWareHouseWithChild?companyId=' + companyId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetCompanyPONumberExist(companyId: Number){
    return this._http.get(this.baseUrl + 'api/Company/GetCompanyPONumberExist?companyId=' + companyId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  //Error Handler Method
  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || "server error.");
  }
}
