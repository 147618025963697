<section class="content-section purchaseOrder pt-2" id="new-layout">
    <div class="loading" *ngIf="loader">Loading&#8230;</div>
    <div class="page-title mb-2">
        <div class="bread-crumb">
            <a [routerLink]="['/adminDashboard/']">Home</a>
            <span class="mx-1">-</span>
            <ng-container *ngIf="breadCrumbUrl">
                <a [routerLink]="breadCrumbUrl">{{breadCrumbName}}</a>
                <span class="mx-1">-</span>
            </ng-container>
            <span class="active">Purchase Orders</span>
            <div *ngIf="permissionMessage!=''" class='btn-danger text-center'>{{permissionMessage}} </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="white-box1 cust-label SaveDiv">
                <div class="card-desc1">
                    <form [formGroup]="formSearch">
                        <div class="row detailSection">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-lg-2 col-md-6 d-flex align-items-center">
                                        <label class="customLabel ws-nobreak" for="customer">Type</label>
                                        <select class="customInput" (change)="ChangeTypeofPO($event)"
                                            [(ngModel)]="typeOfPo" id="typeofPO" formControlName="typeofPoId">
                                            <option value="1">Create</option>
                                            <option value="0">Update</option>
                                        </select>
                                    </div>

                                    <!-- <div class="col-lg-2 col-md-6 d-flex align-items-center"
                                        *ngIf="IsSearchSection==true">
                                        <div class="row d-flex align-items-center">
                                            <div class="col-md-4 required">
                                                <label class="customLabel">Company</label>
                                            </div>
                                            <div class="col-md-8 required borderNone"
                                                [ngClass]="((SearchCompany_Value==0) && (SearchPOValidation || SearchDDLValidation)) ? 'validation_border': ''">
                                                <ngx-dropdown-treeview-select [config]="config"
                                                    class="dropdown-company customInput-dropdown"
                                                    [items]="Search_GroupObjects" [(value)]="SearchCompany_Value"
                                                    (valueChange)="SearchCompanyDropdown($event)">
                                                </ngx-dropdown-treeview-select>
                                            </div>
                                        </div>
                                    </div> -->

                                    <div class="col-lg-2 col-md-6 mt-lg-0 mt-1 d-flex align-items-center"
                                        *ngIf="IsSearchSection==true">
                                        <div class="row d-flex align-items-center">
                                            <div class="col-md-3">
                                                <label class="customLabel" for="PO#">PO#</label>
                                            </div>
                                            <div class="col-md-8 col-11 pr-md-0 pr-1">
                                                <input type="text" placeholder="Enter PO#" class="customInput POSO"
                                                    [(ngModel)]="PoSearchTxt" formControlName="PoSearchTxt"
                                                    autocomplete="off" required maxlength="12"
                                                    [ngClass]="((!PoSearchTxt) && (SearchPOValidation)) ? 'validation_border': ''"
                                                    (keyup.enter)="searchByPO()">
                                            </div>
                                            <div class="col-md-1 col-1 pl-0">
                                                <i class="fas fa-search cpointer ml-1" (click)="searchByPO()"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-md-6 mt-lg-0 mt-md-1 d-flex align-items-center"
                                        *ngIf="IsSearchSection==true">

                                        <div class="row d-flex align-items-center">
                                            <div class="col-md-4 d-flex">
                                                <span class="ortext_order mt-md-1 mt-0">OR</span>
                                                <span><label class="customLabel" for="Vendor">Vendor</label></span>
                                            </div>
                                            <div class="col-md-8">
                                                <select [(ngModel)]="selectedVendorSearch" class="customInput"
                                                    formControlName='VendorSearchDDL'
                                                    [ngClass]="((!selectedVendorSearch) && (SearchDDLValidation)) ? 'validation_border': ''">
                                                    <option value=''>Select Vendor</option>
                                                    <option value='0'>All</option>
                                                    <option *ngFor="let item of vendorlist" [value]="item.id">
                                                        {{item.vendorName}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-md-6 mt-lg-0 mt-md-1 d-flex align-items-center"
                                        *ngIf="IsSearchSection==true">
                                        <label class="customLabel" for="Status">Status</label>
                                        <select [(ngModel)]="selectedStatusSearch" class="customInput"
                                            formControlName='StatusSearchDDL'
                                            [ngClass]="((!selectedStatusSearch) && (SearchDDLValidation)) ? 'validation_border': ''">
                                            <option value=''>Select Status</option>
                                            <option value='0'>All</option>
                                            <option *ngFor="let item of POstatusList" [value]="item.id">
                                                {{item.name}}
                                            </option>
                                        </select>
                                        <i class="fas fa-search cpointer ml-2" (click)="SearchByDropDown('')"></i>
                                    </div>

                                    <div class="col-lg-2 col-md-6 mt-lg-0">
                                        <select class="customInput" (change)="ExportType($event)" id="exportpdf"
                                            *ngIf="IsEditMode==true">
                                            <option value="" selected>Select Export Type </option>
                                            <option value="1">Export to PDF</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </form>
                </div>
            </div>
            <div class="white-box1" [style.display]="RecOrderListShow==true ? 'block' : 'none'">
                <div class="white-box-title1">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="customHeading mt-3 mb-2 d-flex justify-content-between align-items-center">
                                <div>
                                    Purchase Orders<i class="fa fa-angle-{{ListAngle}} cpointer ml-2" aria-hidden="true"
                                        (click)="ListDivShowHide()"></i>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

                <div class="card-desc1" [style.display]="ListDiv==true ? 'block' : 'none'">
                    <div class="table-responsive">
                        <table id="datatable" class="display table clearfix" class="row-border hover" datatable
                            [dtOptions]="dtOptions1" [dtTrigger]="dtTrigger1">
                            <thead>
                                <tr>
                                    <th style="display: none;"></th>
                                    <th (click)="SortTableHeader('companyName')">Company<span id="companyName"
                                            class="sorting1"></span></th>
                                    <th (click)="SortTableHeader('purchaseOrderPrefix')">PO#<span
                                            id="purchaseOrderPrefix" class="sorting1"></span></th>
                                    <th (click)="SortTableHeader('poStatus')">Status<span id="poStatus"
                                            class="sorting1"></span></th>
                                    <th (click)="SortTableHeader('vendorName')">Vendor<span id="vendorName"
                                            class="sorting1"></span></th>
                                    <th (click)="SortTableHeader('dateOrder')">Order Date<span id="dateOrder"
                                            class="sorting1"></span></th>
                                    <th (click)="SortTableHeader('dateSchedule')">Ship Date<span id="dateSchedule"
                                            class="sorting1"></span></th>
                                    <th (click)="SortTableHeader('paymentTermName')">Payment Terms<span
                                            id="paymentTermName" class="sorting1"></span></th>
                                    <th class="width-75" (click)="SortTableHeader('fObPointName')">FOB Point<span
                                            id="fObPointName" class="sorting1"></span></th>
                                    <th class="width-75" (click)="SortTableHeader('shippingTerm')">Shipping Terms<span
                                            id="shippingTerm" class="sorting1"></span></th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="datalist?.length != 0">
                                <tr *ngFor="let data of datalist">
                                    <td class="coloumn-hide">{{data.id}}</td>
                                    <td>{{data.companyName}}</td>
                                    <td>{{data.statusId=="1"?'BR'+'-'+data.purchaseOrderPrefix:'PO'+'-'+data.purchaseOrderPrefix}}
                                    </td>
                                    <td>{{data.poStatus}}</td>
                                    <td>{{data.vendorName}}</td>
                                    <td>{{data.dateOrder |date: "MMM-dd-yyyy"}}</td>
                                    <td>{{data.dateSchedule |date: "MMM-dd-yyyy"}}</td>
                                    <td>{{data.paymentTermName}}</td>
                                    <td>{{data.fObPointName}}</td>
                                    <td>{{data.shippingTerm}}</td>
                                    <td>

                                        <a class="green-text"
                                            *ngIf="rolePermissionsList && rolePermissionsList[0].isUpdate"><i
                                                class="fas fa-edit"
                                                (click)="SearchByPOList(data.purchaseOrderPrefix)"></i></a>
                                        <a class="red-text"
                                            *ngIf="rolePermissionsList && rolePermissionsList[0].isDelete"><i
                                                class="fas fa-trash-alt" (click)="deletePopup(data,content)"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="datalist==null">
                                <tr class="c1">
                                    <td colspan="9" class="no-data-available">No data available in table</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <hr *ngIf="onlyView==false">
            <div class="white-box1 purchasepage" id="PoDiv">
                <div class="card-desc1 mt-3">
                    <form [formGroup]="form">
                        <div class="row detailSection">

                            <div class="col-md-12 container">
                                <div class="row">
                                    <!-- <div class="col-lg-4 col-md-12">
                                        <div class="row d-flex align-items-center required">
                                            <div class="col-lg-4 col-md-2 trp">
                                                <label class="customLabel">Company</label>
                                            </div>
                                            <div class="col-lg-8 col-md-10 borderNone"
                                                [ngClass]="((CompanyValue1==0)  && validation) ? 'validation_border': ''">
                                                <ngx-dropdown-treeview-select [config]="config"
                                                    class="dropdown-company customInput-dropdown"
                                                    [items]="userGroupObjects1" [(value)]="CompanyValue1"
                                                    (valueChange)="companyChangeValueMain($event)">
                                                </ngx-dropdown-treeview-select>
                                            </div>
                                        </div>
                                    </div> -->
                                    <div class="pl-3 mt-lg-0 mt-1 d-flex align-items-center mh_27 align-radio-margin">
                                        <label class="customLabel ws-nobreak">Tax &nbsp; &nbsp; </label>
                                        <br />

                                        <div class="d-flex align-items-center ml-1">
                                            <input class="mr-2 PoRec" type="radio" id="taxable" value="taxable"
                                                name="taxable" [ngModel]="taxable" formControlName="taxable"
                                                (change)="changeTaxable($event)">
                                            <label class="customLabel" for="taxable">Taxable</label>
                                            <input class="ml-3 mr-2 PoRec" type="radio" id="nontaxable" name="taxable"
                                                value="nontaxable" [ngModel]="taxable" formControlName="taxable" #in
                                                (change)="changeTaxable($event)">
                                            <label for="nontaxable" class="customLabel ws-nobreak">Non-Taxable
                                            </label>

                                        </div>

                                    </div>

                                </div>
                                <div class="row mt-1">
                                    <div class="col-lg-4 col-md-12">
                                        <div class="row d-flex align-items-center required">
                                            <div class="col-lg-4 col-md-2 trp">
                                                <label class="customLabel">Vendor</label>
                                            </div>
                                            <div class="col-lg-8 col-md-10">
                                                <select class="customInput" name='vendorId'
                                                    [(ngModel)]="selectedVendorValue" formControlName='vendorId'
                                                    (change)="ChangeVendorDropdow($event)"
                                                    [ngClass]="selectedVendorValue=='' && validation ? 'validation_border': ''">
                                                    <option value=''>Select Vendor</option>
                                                    <option *ngFor="let item of vendorlist" [value]="item.id">
                                                        {{item.vendorName}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="pl-3 mt-lg-0 mt-1 d-flex align-items-center mh_27 align-radio-margin">
                                        <label class="customLabel ws-nobreak">Ship To</label>
                                        <br />

                                        <div class="d-flex align-items-center ml-1">
                                            <input class="mr-2 PoRec" type="radio" id="shipcustomer" value="customer"
                                                name="ship" (change)="ShipType($event)">
                                            <label class="customLabel" for="customer">Customer</label>
                                            <input class="ml-3 mr-2 PoRec" type="radio" id="companyLocation" name="ship"
                                                value="companyLocation" (change)="ShipType($event)">
                                            <label for="companyLocation" class="customLabel ws-nobreak">Company
                                                Location</label>
                                            <label *ngIf="!shiptoradiobutton && validation"
                                                class="customLabel ws-nobreak ml-3"
                                                style="color: red !important;">Please choose ship to.</label>
                                        </div>
                                        <!-- <div *ngIf="!shiptoradiobutton && validation"
                                            class="text-danger input-error order_header_error">
                                            Please choose ship to.</div> -->
                                    </div>
                                    <div class="col-lg-3 col-md-12 mt-lg-0 mt-1 ml-3" *ngIf="Shipcustomer==true">
                                        <div class="d-flex d-width align-items-center required">
                                            <div class="row">
                                                <div class="trp">
                                                    <label class="customLabel label-right">Customer</label>
                                                </div>
                                                <div class="col-lg-8 col-md-10 pl-1 borderNone setposition">
                                                    <select class="customInput" name='customerId'
                                                        [(ngModel)]="selectedCustomerValue" formControlName='customerId'
                                                        (change)="changeDDLCustomer($event)" required
                                                        [ngClass]="selectedCustomerValue=='' && validation ? 'validation_border': ''">
                                                        <option value=''>Select Customer</option>
                                                        <option *ngFor="let item of customerList" [value]="item.id">
                                                            {{item.customerName}}
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-12 mt-lg-0 mt-1 ml-3" *ngIf="Shipcompany==true">
                                        <div class="d-flex d-width align-items-center required">
                                            <div class="row">
                                                <!-- <div class="trp">
                                                    <label class="customLabel label-right">Company</label>
                                                </div>
                                                <div class="col-lg-8 col-md-10 pl-1 borderNone setposition"
                                                    [ngClass]="CompanyValue==undefined||CompanyValue==0 && validation ? 'validation_border': ''">
                                                    <ngx-dropdown-treeview-select [config]="config"
                                                        class="dropdown-company customInput-dropdown"
                                                        [items]="userGroupObjects" [(value)]="CompanyValue"
                                                        (valueChange)="companyChangeValue($event)">
                                                    </ngx-dropdown-treeview-select>

                                                </div> -->
                                                <mat-form-field class="form-field w-100" appearance="outline">
                                                    <mat-label>Select Company</mat-label>
                                                    <mat-select [(value)]="CompanyValue"
                                                        (selectionChange)="companyChangeValue($event.value)"
                                                        disableOptionCentering>
                                                        <ng-container *ngTemplateOutlet="
                                                          recursiveOptionsMulti;
                                                          context: { $implicit: programs, depth: 1 } ">
                                                        </ng-container>

                                                        <ng-template #recursiveOptionsMulti let-list let-depth="depth">
                                                            <ng-container *ngFor="let program of list">
                                                                <mat-option [value]="program.value" [ngStyle]="{
                                                              'padding-left.px': depth <= 1 ? null : 16 * depth
                                                            }">
                                                                    {{ program.text }}
                                                                </mat-option>

                                                                <ng-container
                                                                    *ngIf="!!program.children && program.children.length > 0">
                                                                    <ng-container *ngTemplateOutlet="
                                                                recursiveOptionsMulti;
                                                                context: { $implicit: program.children, depth: depth + 1 }
                                                              ">
                                                                    </ng-container>
                                                                </ng-container>
                                                            </ng-container>
                                                        </ng-template>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="col-md-12 container">
                                <div class="row mt-1">
                                    <div class="col-md-4 mb-md-0 mb-1">
                                        <div class="fetchedDetails">
                                            <ul>
                                                <li class="d-flex"><label>Vendor
                                                        Name:</label><label>{{vendordata.vendorName}}</label>
                                                </li>
                                                <li class="d-flex"><label>Vendor Address:</label>
                                                    <label [innerHtml]="this.sharedService.FormatAddress(vendordata.addressLine1,
                                                    vendordata.addressLine2, vendordata.city, vendordata.stateName,
                                                    vendordata.zipCode)">
                                                    </label>
                                                </li>
                                                <li class="d-flex"><label>Country:</label>
                                                    <label>{{vendordata.name}}</label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-md-4" *ngIf="Shipcompany==true">
                                        <div class="fetchedDetails">
                                            <ul>
                                                <li class="d-flex"><label>Company Name:</label>
                                                    <label>{{CompanyDetails.companyName}}</label>
                                                </li>
                                                <li class="d-flex"><label>Company Address:</label>
                                                    <label [innerHtml]="this.sharedService.FormatAddress(CompanyDetails.addressLine1,
                                                    CompanyDetails.addressLine2, CompanyDetails.city, CompanyDetails.stateName,
                                                    CompanyDetails.zipCode)">
                                                    </label>
                                                </li>
                                                <li class="d-flex"><label>Country:</label>
                                                    <label>{{CompanyDetails.countryName}}</label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-md-4" *ngIf="Shipcustomer==true">
                                        <div class="fetchedDetails">
                                            <ul>
                                                <li class="d-flex"><label>Customer Name:</label>
                                                    <label>{{customerDetails.customerName}}</label>
                                                </li>
                                                <li class="d-flex"><label>Customer Address:</label>
                                                    <label [innerHtml]="this.sharedService.FormatAddress(customerDetails.addressLine1,
                                                    customerDetails.addressLine2, customerDetails.city, customerDetails.stateName,
                                                    customerDetails.zipCode)">
                                                    </label>
                                                </li>
                                                <li class="d-flex"><label>Country:</label>
                                                    <label>{{customerDetails.countryName}}</label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-md-4" *ngIf="Shipcompany==false && Shipcustomer==false">
                                        <div class="fetchedDetails">
                                            <ul>
                                                <li class="d-flex"><label>Company Name:</label> </li>
                                                <li class="d-flex"><label>Company Address:</label></li>
                                                <li class="d-flex"><label>Country:</label></li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="col-md-12 container">
                                <div class="row mt-1">
                                    <div class="col-lg-4 col-md-12">
                                        <div class="row d-flex align-items-center">
                                            <div class="col-lg-4 col-md-2 trp required">
                                                <label class="customLabel">Buyer</label>
                                            </div>
                                            <div class="col-lg-8 col-md-10">
                                                <select class="customInput" name='buyerId' [(ngModel)]="SelectedBuyer"
                                                    formControlName='buyerId' (change)="changebuyer($event)"
                                                    [ngClass]="SelectedBuyer=='' && validation ? 'validation_border': ''">
                                                    <option value=''>Select Buyer</option>
                                                    <option *ngFor="let item of buyerList" [value]="item.userId">
                                                        {{item.name}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                        <div class="row d-flex align-items-center">
                                            <div class="col-lg-4 col-md-2 trp">
                                                <label class="customLabel">{{selectedstatusvalue == 1 ? 'BR #' : 'PO #'}}</label>
                                            </div>
                                            <div class="col-lg-8 col-md-10">
                                                <span class="dollar" style="bottom:2px;"></span>
                                                <input type="text" class="customInput"
                                                    [(ngModel)]="EditPOPrefixNumber==null?'N/A':EditPOPrefixNumber"
                                                    formControlName="PO" disabled autocomplete="off" maxlength="100">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                        <div class="row d-flex align-items-center">
                                            <div class="col-lg-4 col-md-2 trp">
                                                <label class="customLabel">Order Date</label>
                                            </div>
                                            <div class="col-lg-8 col-md-10">
                                                <input type="date" class="customInput" [(ngModel)]="DateOrdered"
                                                    formControlName='DateOrdered' disabled autocomplete="off"
                                                    min="{{PopupinDate}}"
                                                    [ngClass]="DateOrdered==undefined && validation ? 'validation_border': ''">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-1">
                                    <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                        <div class="row d-flex align-items-center">
                                            <div class="col-lg-4 col-md-2 trp">
                                                <label class="customLabel">Ship Date</label>
                                            </div>
                                            <div class="col-lg-8 col-md-10">
                                                <input type="date" class="customInput" [(ngModel)]="DateScheduled"
                                                    required (change)="DateScheduledChange($event)"
                                                    formControlName='DateScheduled' min="{{PopupinDate}}"
                                                    [ngClass]="DateScheduled==undefined && validation ? 'validation_border': ''">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-12">
                                        <div class="row d-flex align-items-center">
                                            <div class="col-lg-4 col-md-2 trp required">
                                                <label class="customLabel mr-1">Status</label>
                                            </div>
                                            <div class="col-lg-8 col-md-10">
                                                <select class="customInput" formControlName='Status'
                                                    [(ngModel)]="selectedstatusvalue" id="statusId"
                                                    [ngClass]="selectedstatusvalue=='' && validation ? 'validation_border': ''">
                                                    <option value=''>Select Status</option>
                                                    <option *ngFor="let item of POstatusList" [value]="item.id">
                                                        {{item.name}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                        <div class="row d-flex align-items-center">
                                            <div class="col-lg-4 col-md-2 trp">
                                                <label class="customLabel">Payment Terms</label>
                                            </div>
                                            <div class="col-lg-8 col-md-10">
                                                <select class="customInput" formControlName='paymenttermId'
                                                    [(ngModel)]="PaymentTerms" id="paymenttermId">
                                                    <option value=''>Select Payment Terms</option>
                                                    <option *ngFor="let item of termsData" [value]="item.id">
                                                        {{item.name}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-1">
                                    <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                        <div class="row d-flex align-items-center">
                                            <div class="col-lg-4 col-md-2 trp">
                                                <label class="customLabel">FOB Point</label>
                                            </div>
                                            <div class="col-lg-8 col-md-10">
                                                <select class="customInput" formControlName='FOBPoint'
                                                    [(ngModel)]="selectedFobPointValue" id="FobPointValue">
                                                    <option value=''>Select FOB</option>
                                                    <option *ngFor="let item of FobPoint" [value]="item.id">
                                                        {{item.name}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-12">
                                        <div class="row d-flex align-items-center">
                                            <div class="col-lg-4 col-md-2 trp">
                                                <label class="customLabel">Shipping Terms</label>
                                            </div>
                                            <div class="col-lg-8 col-md-10">
                                                <select class="customInput" formControlName='ShippingTerms'
                                                    (ngModelChange)="changeShipping($event)"
                                                    [(ngModel)]="selectedshippingValue" id="ShippingTerms">
                                                    <option value=''>Select Shipping</option>
                                                    <option *ngFor="let item of shippingData" [value]="item.id">
                                                        {{item.name}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-12">
                                        <div class="row d-flex align-items-center">
                                            <div class="col-lg-4 col-md-2 trp">
                                                <label class="customLabel">Carrier Service</label>
                                            </div>
                                            <div class="col-lg-8 col-md-10">
                                                <select class="customInput" formControlName='carrier'
                                                    [(ngModel)]="carrier" id="carrier">
                                                    <option value=''>Select Carrier Service</option>
                                                    <option *ngFor="let item of carrierData" [value]="item.id">
                                                        {{item.name}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-1">
                                    <div class="col-lg-4 col-md-12">
                                        <div class="row d-flex align-items-center">
                                            <div class="col-lg-4 col-md-2 trp">
                                                <label class="customLabel">PO Email</label>
                                            </div>
                                            <div class="col-lg-8 col-md-10">
                                                <input type="text" class="customInput" [(ngModel)]="poEmail"
                                                    placeholder="Enter PO Email Address" name="poEmail"
                                                    formControlName='poEmail' autocomplete="off" maxlength="100">
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="row d-flex align-items-center mt-1">
                                    <div class="col-lg-2 col-md-2 trp">
                                        <label class="customLabel padding-r">Vendor Notes:</label>
                                    </div>
                                    <div class="col-lg-10 col-md-10">
                                        <input type="area" class="customInput customDescription customDescription2"
                                            placeholder="Enter Vendor Notes" formControlName='Notes' [(ngModel)]="Note">
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-1">
                                    <div class="col-lg-2 col-md-2 trp">
                                        <label class="customLabel padding-r">Internal Notes:</label>
                                    </div>
                                    <div class="col-lg-10 col-md-10">
                                        <input type="area" class="customInput customDescription customDescription2"
                                            placeholder="Enter Internal Notes" formControlName='internalNotes'
                                            [(ngModel)]="internalNotes">
                                    </div>
                                </div>



                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="customHeading mt-3">
                                            Vendor Documents
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-2 d-flex align-items-center">

                                    <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                        <div class="row">
                                            <div class="col-lg-4 col-md-2 trp">
                                                <label class="customLabel">Upload</label>
                                            </div>
                                            <div class="col-lg-8 col-md-10">
                                                <input type="file" class=" customInput" #Image accept=".pdf"
                                                    (change)="ChngSettingImage($event.target.files)" id="file"
                                                    [ngClass]="((PictureValue=='') && (validationImage)) ? 'validation_border': ''">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-1 col-md-12">
                                        <button type="button" class="btn round-btn upload_btn add_img ml-0 mt-1 mt-lg-0"
                                            (click)="AddMultiplePartImage();">+ Add Document</button>
                                    </div>
                                </div>
                                <div class="row mt-4">
                                    <div class="col-md-1" *ngFor="let image of MultipleImage;let i = index;">
                                        <div class="particular_img">
                                            <img [src]="DefaultLogoPDF">
                                            <div class="view_img" (click)="viewImage(image.imageUrl)">
                                                <i class="fa fa-eye" aria-hidden="true" data-toggle="modal"></i>
                                            </div>
                                            <button type="button" class="close"
                                                (click)="DeleteImageModalpopup(i,contentImage)" aria-label="Close">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                            <div class="imgtype">
                                                Document{{i+1}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-12">
                                <hr class="mb-1 mt-2" style="border: 2px solid #cccbcb;
                                margin-bottom: 10px !important;">
                            </div>

                            <div class="col-md-12 container below-table">
                                <div class="row mt-2">
                                    <div class="col-lg-3 col-md-12">
                                        <div class="row">
                                            <div class="col-lg-3 col-md-2 trp required">
                                                <label class="customLabel">Type</label>
                                            </div>
                                            <div class="col-lg-6 col-md-6">
                                                <select class="customInput" formControlName='type'
                                                    [attr.disabled]="isVendorSelected ? null : ''"
                                                    (change)="ChangePOtype($event)" [(ngModel)]="selectedvaluetype1"
                                                    [ngClass]="((!selectedvaluetype1) && (validation1)) ? 'validation_border': ''">
                                                    <option value=''>Select Type</option>
                                                    <option *ngFor="let item of POTypeList" [value]="item.id">
                                                        {{item.name}}
                                                    </option>
                                                </select>
                                            </div>
                                            <ng-container>
                                                <div class="col-lg-3 col-md-6"
                                                    *ngIf="(IsProductType==false && IsPartType==false)">
                                                    <button type="button" class="btn blue-bg upload_btn round-btn"
                                                        (click)="AddPartItemPopup()"
                                                        [disabled]="isVendorSelected==false"><i
                                                            class="fas fa-plus-circle" aria-hidden="true"></i> Add
                                                    </button>
                                                </div>
                                            </ng-container>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-12">
                                        <ng-container *ngIf="IsPartType==true">
                                            <div class="row">
                                                <div class="col-lg-4 col-md-2 trp required">
                                                    <label class="customLabel">Part So</label>
                                                </div>
                                                <div class="col-lg-8 col-md-10">
                                                    <select class="customInput" formControlName='partOrder'
                                                        [(ngModel)]='selectedPartOrder'
                                                        [ngClass]="((!selectedPartOrder) && (validation2)) ? 'validation_border': ''"
                                                        (change)="changePartorder($event)">
                                                        <!-- <option *ngFor="let item of PartOrderDropdown"
                                                            [disabled]="item.qtyToFulfill==0" [value]="item.id">
                                                            {{item.name}}<span *ngIf="item.qtyToFulfill==0"
                                                                class="ml-2"> - Fulfilled for this Vendor</span>
                                                        </option> -->
                                                        <option value=''>Select Part SO </option>
                                                        <ng-container *ngFor="let item of PartOrderDropdown">
                                                            <option *ngIf="item.qtyToFulfill!=0" [value]="item.id">
                                                                {{item.name}}
                                                            </option>
                                                        </ng-container>
                                                        <ng-container *ngFor="let item of PartOrderDropdown">
                                                            <option *ngIf="item.qtyToFulfill==0" [value]="item.id"
                                                                [disabled]="true">
                                                                {{item.name}} - Fulfilled for this Vendor
                                                            </option>
                                                        </ng-container>
                                                    </select>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="IsProductType==true">
                                            <div class="row">
                                                <div class="col-lg-4 col-md-2 trp required">
                                                    <label class="customLabel">Product SO</label>
                                                </div>
                                                <div class="col-lg-8 col-md-10">
                                                    <select class="customInput" formControlName='ProductOrder'
                                                        [(ngModel)]="selectedProductOrdervaluetype"
                                                        [ngClass]="((!selectedProductOrdervaluetype) && (validation2)) ? 'validation_border': ''"
                                                        (change)="changrPruductOrder($event)">

                                                        <option value=''>Select Product SO </option>
                                                        <ng-container *ngFor="let item of ProductOrderDropdown">
                                                            <option *ngIf="item.qtyToFulfill!=0" [value]="item.id">
                                                                {{item.name}}
                                                            </option>
                                                        </ng-container>
                                                        <ng-container *ngFor="let item of ProductOrderDropdown">
                                                            <option *ngIf="item.qtyToFulfill==0" [value]="item.id"
                                                                [disabled]="true">
                                                                {{item.name}} - Fulfilled for this Vendor
                                                            </option>
                                                        </ng-container>

                                                    </select>
                                                </div>
                                            </div>
                                        </ng-container>

                                    </div>
                                    <div class="col-lg-3 col-md-6">
                                        <ng-container *ngIf="(IsPartType==true || IsProductType==true)">
                                            <div class="col-lg-1 col-md-12">
                                                <div class="row">
                                                    <div class="col-lg-12 col-md-2">
                                                        <button type="button" class="btn blue-bg upload_btn round-btn"
                                                            (click)="pullAllParts()"
                                                            [disabled]="isVendorSelected==false"><i
                                                                class="fas fas fa-angle-double-down"
                                                                aria-hidden="true"></i>
                                                            Pull All Parts </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>

                                    <div class="col-lg-3 col-md-6">
                                        <button type="button" class="btn blue-bg upload_btn round-btn"
                                            (click)="reCalcPartCost()" [disabled]="isVendorSelected==false"
                                            style="float: right;"> Recalc Part Cost
                                        </button>
                                    </div>
                                </div>
                                <div class="modal" id="AddPartItem">
                                    <div class="modal-dialog  modal-dialog-centered modal-lg" role="document">
                                        <div class="modal-content">
                                            <h5 class="modal-title info-popup-title p-1" id="exampleModalLabel1">Add
                                                Part</h5>
                                            <button type="button" class="close btn alert_modal" aria-label="Close"
                                                (click)="PartItemExit()">
                                                <span aria-hidden="true">&times;</span>
                                            </button>
                                            <div class="modal-body">
                                                <div class="row mb-1">
                                                    <div class="col-md-12">
                                                        <div class="row">
                                                            <div class="col-md-4 col-12">
                                                                <div class="d-flex align-items-center">
                                                                    <label class="customLabel mr-2"
                                                                        for="Vendor">Vendor</label>
                                                                    <select class="customInput"
                                                                        [(ngModel)]="vendorAddPart"
                                                                        formControlName='vendorAddPart'
                                                                        (change)="ChangeVendorAddPart($event.target.value)">
                                                                        <option value='0'>All</option>
                                                                        <option *ngFor="let item of vendorlist"
                                                                            [value]="item.id">
                                                                            {{item.vendorName}}
                                                                        </option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-2 col-12">
                                                                <div class="d-flex align-items-center">
                                                                    <label class="customLabel mr-2"
                                                                        for="Vendor">Quantity</label>
                                                                    <input type="text" placeholder="Qty"
                                                                        class="customInput NumberOnly"
                                                                        [ngClass]="((!qty) && (validation1)) ? 'validation_border': ''"
                                                                        id="quantity" formControlName='qty'
                                                                        [(ngModel)]="qty" maxlength="5" min="1" max="99"
                                                                        autocomplete="off">
                                                                </div>
                                                            </div>
                                                            <!-- <div class="col-lg-2 col-md-12 mt-lg-0 mt-1">

                                                                <div class="d-flex align-items-center">
                                                                    <select class="customInput pl-1px"
                                                                        (change)="ChangefieldToSearch($event.target.value)"
                                                                        [(ngModel)]="fieldToSearch" id="fieldToSearch"  formControlName="fieldToSearch">
                                                                        <option value='1'>Search Keywords</option>
                                                                        <option value='2'>List or Vendor Part Name</option>
                                                                        <option value='3'>List or Vendor Part #</option>
                                                                    </select>
                                                                </div>
                                                            </div>
                                                            <div class="col-md-3 col-11 pr-md-2 mt-md-0 mt-1">
                                                                <div class="d-flex align-items-center">
                                                                    <input type="text" class="customInput" placeholder="{{fieldToSearchPlaceHolder}}"
                                                                        [(ngModel)]="PartName_search"  formControlName="PartName_search" autocomplete="off"
                                                                        (keyup.enter)="SearchByvendorList()">
                                                                </div>
                                                            </div>
                                                            <div class="col-md-1 col-1 text-left or_width"
                                                                style="max-width: 30px; padding:0; margin-top: 3px;">
                                                                <i class="fas fa-search cpointer" (click)="SearchByvendorList()"></i>
                                                            </div>
                                                            <div class="col-md-1 col-1 text-left or_width">
                                                                <button type="button" class="btn blue-bg m-0 centbutton"
                                                                    (click)="SearchByvendorListAll()">All Parts</button>
                                                            </div> -->
                                                            <div class="col-md-5 col-11 pr-md-2 mt-md-0 mt-1">
                                                                <div class="d-flex align-items-center">
                                                                    <label class="customLabel ws-nobreak pr-1">Part
                                                                        Name/Number</label>
                                                                    <input type="text" class="customInput"
                                                                        [(ngModel)]="PartName_search"
                                                                        formControlName="PartName_search" />

                                                                </div>
                                                            </div>
                                                            <div class="col-md-1 col-1 text-left or_width"
                                                                style="max-width: 30px; padding:0; margin-top: 3px;">
                                                                <i class="fas fa-search cpointer"
                                                                    (click)="SearchByvendorList()"></i>
                                                            </div>

                                                        </div>
                                                    </div>



                                                </div>
                                                <hr>
                                                <div class="white-box1">
                                                    <div class="white-box-title1">
                                                        <div class="customHeading">
                                                            Part List
                                                        </div>
                                                    </div>

                                                    <div class="card-desc1 mt-1">
                                                        <table id="datatable1" class="display table clearfix"
                                                            class="row-border hover table-cursor" datatable
                                                            [dtOptions]="dtOptions2" [dtTrigger]="dtTrigger2">
                                                            <thead>
                                                                <tr>
                                                                    <th style="display: none;"></th>
                                                                    <th>Company</th>
                                                                    <th>List Part Name</th>
                                                                    <th>List Part#</th>
                                                                    <th>Vendor Name</th>
                                                                    <!-- <th>Category</th> -->
                                                                    <th>Part Price</th>
                                                                    <th class="width-75">Labor Hrs</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody class="customLabel"
                                                                *ngIf="ddlPartListPopup?.length != 0">
                                                                <tr *ngFor="let data1 of ddlPartListPopup"
                                                                    (click)="AddpartDetails(data1.id);">
                                                                    <td class="coloumn-hide">{{data1.id}}</td>
                                                                    <td>{{data1.companyName}}</td>
                                                                    <td class="text-over-ellipse-td">
                                                                        <span
                                                                            class="tooltip-text"><a>{{data1.name}}</a></span>
                                                                        <span class="tooltip-td">{{data1.name}}</span>
                                                                    </td>
                                                                    <td><a>{{data1.partNo}}</a></td>
                                                                    <td><a>{{data1.vendorName}}</a></td>
                                                                    <!-- <td><a>{{data1.partCategoryName}}</a></td> -->
                                                                    <td><a>{{data1.cost|currency}}</a></td>
                                                                    <td><a>{{data1.hours|number: '1.2-2'}}</a></td>
                                                                </tr>
                                                            </tbody>
                                                            <tbody *ngIf="ddlPartListPopup==null">
                                                                <tr class="c1">
                                                                    <td colspan="7" class="no-data-available">No data
                                                                        available in table</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>

                                                </div>

                                                <div class="submit-from text-right">

                                                    <button type="button" class="btn darkred-bg round-btn"
                                                        (click)="PartItemExit();"><i aria-hidden="true"></i>
                                                        Exit </button>

                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                        <div [class]="EditRowTxtName=='editRow' ? 'table-responsive actTable' : 'table-responsive'">
                            <table class="table table-bordered mt-3">
                                <tr>
                                    <td></td>
                                    <td>Item #</td>
                                    <td>Type</td>
                                    <td style="width:50px;">Ship Date</td>
                                    <td style="width:85px;">Qty</td>
                                    <td>Rcvd</td>
                                    <!-- <td>SO Qty to Fulfill</td> -->
                                    <td class="VPName">Vendor Part Name</td>
                                    <td>Vendor SKU</td>
                                    <td>Customer SKU</td>
                                    <td>UOM</td>
                                    <td>Part SO</td>
                                    <td>Product SO</td>
                                    <td>Channel</td>
                                    <td>

                                        <div class="position-relative fix-width-tooltip1">
                                            <span class="tooltipWrapCost">Unit Cost</span>
                                            <span class="tooltips" style="visibility: hidden"></span>

                                        </div>
                                        <div class="position-relative">
                                            <span class="tooltipWrapCost tooltip-icon"><i
                                                    class="fa fa-info-circle fabnew"
                                                    [style.color]="this.discountProp == 0 ? 'black' : 'red'"></i></span>
                                            <span class="tooltips" style="top:-34px">
                                                {{this.discountProp == 0 ? 'No Additional Discount' : 'Additional
                                                Discount : ' + this.discountProp + '%'}}

                                            </span>
                                        </div>

                                    </td>

                                    <td>Total</td>

                                </tr>

                                <ng-container *ngFor="let parts of SpecialPartList; let i = index">
                                    <tr [ngClass]="parts.isCustompart==true ? 'Gray' : ''">
                                        <td><span class="d-flex1"
                                                *ngIf="btnShowvalidation && btnOkShow==false && parts.isCustompart==false">
                                                <a
                                                    (click)="deleteCustomPartPopup(parts.customArrayIndex,contentPart, '')"><i
                                                        class="fas fa-trash-alt order-del mr-2"></i></a>
                                                <a><span class="fas fa-edit partEdit"
                                                        (click)="EditPartRow(parts.customArrayIndex,'editRow')"></span></a>
                                                <span (click)="getTax(parts.partId, parts.taxable)">
                                                    <img *ngIf="parts.isCustompart==false && parts.taxable==false"
                                                        src="/assets/image/tax2.png" class="imageclass">

                                                    <img *ngIf="parts.isCustompart==false && parts.taxable==true"
                                                        src="/assets/image/tax1.png" class="imageclass">
                                                </span>

                                            </span>
                                            <span class="d-flex"
                                                *ngIf="parts.customArrayIndex==EditRowIndex && btnOkShow==true">
                                                <span><button type="button" data-toggle="modal"
                                                        (click)="UpdatePartRow(parts.customArrayIndex)"><i
                                                            class="fas fa-check-circle text-success"></i>
                                                    </button></span>
                                                <span><button type="button" data-toggle="modal"
                                                        (click)="CancleUpdatePartRow(parts.customArrayIndex)"><i
                                                            class="fas fa-times-circle text-danger"></i>
                                                    </button></span>
                                            </span>
                                        </td>
                                        <td>{{i + 1}}</td>
                                        <td>
                                            <span *ngIf="normalShow==true || RowEdit==false">
                                                {{!parts.Type ? "N/A":parts.Type}}
                                            </span>
                                            <span
                                                *ngIf="parts.customArrayIndex==EditRowIndex && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                <select class="customInput EditWidth" formControlName='type'
                                                    (change)="ChangePOtypeEdit($event)" [(ngModel)]="selectedvaluetype"
                                                    style="width: 140px;" id="test">
                                                    <option value=''>Select Type</option>
                                                    <option *ngFor="let item of POTypeList" [value]="item.id">
                                                        {{item.name}}
                                                    </option>
                                                </select>
                                            </span>
                                        </td>
                                        <td>
                                            <span style="white-space: nowrap;"
                                                *ngIf="Editlable==true||normalShow==true || RowEdit==false">
                                                {{!parts.dateschedule ? "N/A":
                                                parts.dateschedule|date:"MMM-dd-yyyy"}}</span>

                                            <span
                                                *ngIf="parts.customArrayIndex==EditRowIndex && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                <input type="date" class="customInput" [(ngModel)]="Updatedateschedule"
                                                    formControlName='Updatedateschedule' style="width: 150px;"
                                                    min="{{PopupinDate}}">
                                            </span>

                                        </td>
                                        <td>
                                            <span *ngIf="normalShow==true || RowEdit==false">{{parts.qty}}</span>
                                            <span
                                                *ngIf="parts.customArrayIndex==EditRowIndex && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                <input class="customInput odr-qty NumberOnly"
                                                    formControlName='UpdateQty' maxlength="9" type="text"
                                                    [(ngModel)]="UpdateQty" style="width: 40px;" />
                                            </span>
                                        </td>
                                        <td>
                                            <span *ngIf="normalShow==true || RowEdit==false">
                                                <span *ngIf="parts.isCustompart == false"> {{parts.recQty}}</span>

                                            </span>
                                        </td>
                                        <!-- <td>
                                            <span *ngIf="parts.isCustompart==false">
                                                {{parts.qtyToFulFill==null || parts.qtyToFulFill==undefined ? 'N/A' :
                                                parts.qtyToFulFill}}
                                            </span>

                                            <span *ngIf="parts.isCustompart==true"></span>
                                        </td> -->

                                        <td style="text-align: left;">
                                            <div class="d-flex justify-content-center">
                                                <div class="position-relative fix-width-tooltip">
                                                    <span class="tooltipWrap"
                                                        *ngIf="normalShow==true || RowEdit==false">
                                                        <img style="border-radius: inherit;"
                                                            [src]="parts.partImageUpload"
                                                            *ngIf="parts.isCustompart==false">

                                                        {{!parts.vendorPartName
                                                        ?
                                                        "N/A" : parts.vendorPartName}}</span>
                                                    <span class="tooltips">{{!parts.vendorPartName ? "N/A" :
                                                        parts.vendorPartName}}</span>

                                                </div>
                                                <div class="position-relative">
                                                    <span class="tooltipWrap tooltip-icon"
                                                        *ngIf="normalShow==true || RowEdit==false"><i
                                                            class="fa fa-info-circle"></i></span>
                                                    <span class="tooltips">Vendor Part :
                                                        {{!parts.vendorpartNumber ? "N/A" :
                                                        parts.vendorpartNumber}}<br>Description :
                                                        {{!parts.description ? "N/A" : parts.description}}<br>
                                                        VendorName :
                                                        {{!parts.vendorName ? "N/A" :
                                                        parts.vendorName}}
                                                        <br>
                                                    </span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <span *ngIf="normalShow==true || RowEdit==false">{{!parts.vendorpartNumber ?
                                                "N/A":parts.vendorpartNumber}}</span>
                                        </td>
                                        <td>

                                            <span
                                                *ngIf="normalShow==true || RowEdit==false">{{parts.custumerPartNumber}}</span>

                                        </td>
                                        <td> <span
                                                *ngIf="normalShow==true || RowEdit==false">{{!parts.unitOfMeasurementName
                                                ? "N/A" : parts.unitOfMeasurementName}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="normalShow==true || RowEdit==false">{{!parts.partOrder ?
                                                "N/A":parts.partOrder}}</span>
                                            <span
                                                *ngIf="parts.customArrayIndex==EditRowIndex && EditRowTxtName=='editRow' && CurrentEditRow=='Edit' && IsPartTypeEdit==true">
                                                <select class="customInput" formControlName='partOrder'
                                                    [(ngModel)]='selectedPartOrder' (change)="changePartorder($event)"
                                                    style="width:140px;">
                                                    <option value=''>Select Part SO Order</option>
                                                    <option *ngFor="let item of PartOrderDropdown" [value]="item.id">
                                                        {{item.name}}
                                                    </option>
                                                </select>
                                            </span>
                                        </td>
                                        <td>
                                            <span *ngIf="normalShow==true || RowEdit==false">{{!parts.productOrder ?
                                                "N/A":parts.productOrder}}</span>
                                            <span
                                                *ngIf="parts.customArrayIndex==EditRowIndex && EditRowTxtName=='editRow' && CurrentEditRow=='Edit' && IsProductTypeEdit==true">
                                                <select class="customInput" formControlName='ProductOrder'
                                                    [(ngModel)]="selectedProductOrdervaluetype"
                                                    (change)="changrPruductOrder($event)" style="width:140px;">
                                                    <option value=''>Select Product SO Order </option>
                                                    <option *ngFor="let item of ProductOrderDropdown" [value]="item.id">
                                                        {{item.name}}
                                                    </option>
                                                </select>
                                            </span>
                                        </td>
                                        <!-- <td>
                                            <span *ngIf="parts.isEcommOrder==true"><i class="fa fa-check text-success"
                                                    aria-hidden="true"></i></span>
                                        </td> -->
                                        <td>
                                            <span>{{BusinessChannel == null || BusinessChannel == undefined ? "N/A"
                                                :BusinessChannel}}</span>
                                        </td>
                                        <td>
                                            <div class="position-relative fix-width-tooltip1">
                                                <span class="tooltipWrapCost tooltip-icon"
                                                    *ngIf="normalShow==true || RowEdit==false">${{parts.vendorParCost}}
                                                </span>
                                            </div>
                                            <span
                                                *ngIf="parts.customArrayIndex==EditRowIndex && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                <input class="customInput EditWidth"
                                                    formControlName='UpdatevendorParCost' type="text"
                                                    (blur)="changeUnitCost()" [(ngModel)]="UpdatevendorParCost"
                                                    class="Cost" style="width: 140px;" maxlength="10" />
                                            </span>
                                        </td>
                                        <td>
                                            <span *ngIf="parts.isCustompart==false">

                                                {{parts.vendorParCost*parts.qty | currency}}
                                            </span>

                                        </td>

                                    </tr>
                                    <ng-container *ngFor="let datasub of parts.PartList; let inner = index">
                                        <tr>
                                            <td><span class="d-flex1" *ngIf="btnShowvalidation && btnOkShow==false">
                                                    <a
                                                        (click)="deleteCustomPartPopup(datasub.customArrayIndex,contentPart, parts.srNo)"><i
                                                            class="fas fa-trash-alt order-del mr-2"></i></a>
                                                    <a><span class="fas fa-edit partEdit"
                                                            (click)="EditPartRow(datasub.customArrayIndex,'editRow')"></span></a>
                                                    <a>
                                                        <span
                                                            (click)="getTaxs(datasub.taxable, parts.partId, datasub.partId)">
                                                            <img *ngIf="datasub.taxable==false"
                                                                src="/assets/image/tax2.png" class="imageclass">

                                                            <img *ngIf="datasub.taxable==true"
                                                                src="/assets/image/tax1.png" class="imageclass">
                                                        </span>
                                                    </a>

                                                </span>
                                                <span class="d-flex"
                                                    *ngIf="datasub.customArrayIndex==EditRowIndex && btnOkShow==true">
                                                    <span><button type="button" data-toggle="modal"
                                                            (click)="UpdatePartRow(datasub.customArrayIndex)"><i
                                                                class="fas fa-check-circle text-success"></i>
                                                        </button></span>
                                                    <span><button type="button" data-toggle="modal"
                                                            (click)="CancleUpdatePartRow(datasub.customArrayIndex)"><i
                                                                class="fas fa-times-circle text-danger"></i>
                                                        </button></span>
                                                </span>
                                            </td>
                                            <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{i +1}}.{{inner+1}}</td>
                                            <td>
                                                <span style="white-space: nowrap;"
                                                    *ngIf="Editlable==true||normalShow==true || RowEdit==false">
                                                    {{!datasub.dateschedule ? "N/A":
                                                    datasub.dateschedule|date:"MMM-dd-yyyy"}}</span>
                                                <span
                                                    *ngIf="datasub.customArrayIndex==EditRowIndex && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                    <input type="date" class="customInput"
                                                        [(ngModel)]="Updatedateschedule"
                                                        formControlName='Updatedateschedule' style="width: 150px;"
                                                        min="{{PopupinDate}}">
                                                </span>
                                            </td>
                                            <td>
                                                <span *ngIf="normalShow==true || RowEdit==false">{{datasub.qty}}</span>
                                                <span
                                                    *ngIf="datasub.customArrayIndex==EditRowIndex && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                    <input class="customInput odr-qty NumberOnly" (blur)="changeQty()"
                                                        formControlName='UpdateQty' maxlength="9" type="text"
                                                        [(ngModel)]="UpdateQty" style="width: 40px;" />
                                                </span>
                                            </td>
                                            <td>
                                                <span *ngIf="normalShow==true || RowEdit==false">
                                                    <span> {{datasub.recQty}}</span>

                                                </span>
                                            </td>
                                            <!-- <td>
                                                {{datasub.qtyToFulFill==null || datasub.qtyToFulFill==undefined ? 'N/A'
                                                :
                                                datasub.qtyToFulFill}}
                                            </td> -->

                                            <td style="text-align: left;">
                                                <div class="d-flex justify-content-center">
                                                    <div class="position-relative fix-width-tooltip">
                                                        <span class="tooltipWrap"
                                                            *ngIf="normalShow==true || RowEdit==false">
                                                            <img style="border-radius: inherit;"
                                                                [src]="datasub.partImageUpload"
                                                                *ngIf="datasub.isCustompart==false">
                                                            {{!datasub.vendorPartName
                                                            ?
                                                            "N/A" : datasub.vendorPartName}}</span>
                                                        <span class="tooltips">{{!datasub.vendorPartName ? "N/A" :
                                                            datasub.vendorPartName}}</span>

                                                    </div>
                                                    <div class="position-relative">
                                                        <span class="tooltipWrap tooltip-icon"
                                                            *ngIf="normalShow==true || RowEdit==false"><i
                                                                class="fa fa-info-circle"></i></span>
                                                        <span class="tooltips">Vendor Part :
                                                            {{!datasub.vendorpartNumber ? "N/A" :
                                                            datasub.vendorpartNumber}}<br>Description :
                                                            {{!datasub.description ? "N/A" : datasub.description}}<br>
                                                            VendorName :
                                                            {{!datasub.vendorName ? "N/A" :
                                                            datasub.vendorName}}
                                                            <br>
                                                        </span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <span
                                                    *ngIf="normalShow==true || RowEdit==false">{{!datasub.vendorpartNumber
                                                    ?
                                                    "N/A":datasub.vendorpartNumber}}</span>
                                            </td>
                                            <td>

                                                <span
                                                    *ngIf="normalShow==true || RowEdit==false">{{datasub.custumerPartNumber}}</span>

                                            </td>
                                            <td> <span
                                                    *ngIf="normalShow==true || RowEdit==false">{{!datasub.unitOfMeasurementName
                                                    ? "N/A" : datasub.unitOfMeasurementName}}</span>
                                            </td>
                                            <td>
                                                <span *ngIf="normalShow==true || RowEdit==false">
                                                    {{!datasub.Type ? "N/A":datasub.Type}}
                                                </span>
                                                <span
                                                    *ngIf="datasub.customArrayIndex==EditRowIndex && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                    <select class="customInput EditWidth" formControlName='type'
                                                        (change)="ChangePOtypeEdit($event)"
                                                        [(ngModel)]="selectedvaluetype" style="width: 140px;" id="test">
                                                        <option value=''>Select Type</option>
                                                        <option *ngFor="let item of POTypeList" [value]="item.id">
                                                            {{item.name}}
                                                        </option>
                                                    </select>
                                                </span>
                                            </td>
                                            <td>123</td>
                                            <td>

                                                <span
                                                    *ngIf="normalShow==true || RowEdit==false">{{datasub.service===""?"N/A":datasub.service}}</span>
                                                <span
                                                    *ngIf="datasub.customArrayIndex==EditRowIndex && EditRowTxtName=='editRow' && CurrentEditRow=='Edit' && IsServicesEdit==true">
                                                    <input class="customInput EditWidth" formControlName='Updateservice'
                                                        type="text" [(ngModel)]="Updateservice" style="width: 140px;" />
                                                </span>
                                            </td>
                                            <td>
                                                <span *ngIf="normalShow==true || RowEdit==false">{{!datasub.partOrder ?
                                                    "N/A":datasub.partOrder}}</span>
                                                <span
                                                    *ngIf="datasub.customArrayIndex==EditRowIndex && EditRowTxtName=='editRow' && CurrentEditRow=='Edit' && IsPartTypeEdit==true">
                                                    <select class="customInput" formControlName='partOrder'
                                                        [(ngModel)]='selectedPartOrder'
                                                        (change)="changePartorder($event)" style="width:140px;">
                                                        <option value=''>Select Part SO Order</option>
                                                        <option *ngFor="let item of PartOrderDropdown"
                                                            [value]="item.id">
                                                            {{item.name}}
                                                        </option>
                                                    </select>
                                                </span>

                                            </td>
                                            <td>
                                                <span *ngIf="normalShow==true || RowEdit==false">{{!datasub.productOrder
                                                    ?
                                                    "N/A":datasub.productOrder}}</span>
                                                <span
                                                    *ngIf="datasub.customArrayIndex==EditRowIndex && EditRowTxtName=='editRow' && CurrentEditRow=='Edit' && IsProductTypeEdit==true">
                                                    <select class="customInput" formControlName='ProductOrder'
                                                        [(ngModel)]="selectedProductOrdervaluetype"
                                                        (change)="changrPruductOrder($event)" style="width:140px;">
                                                        <option value=''>Select Product SO Order </option>
                                                        <option *ngFor="let item of ProductOrderDropdown"
                                                            [value]="item.id">
                                                            {{item.name}}
                                                        </option>
                                                    </select>
                                                </span>
                                            </td>
                                            <td><span *ngIf="datasub.isEcommOrder==true"><i
                                                        class="fa fa-check text-success" aria-hidden="true"></i></span>
                                            </td>
                                            <td>
                                                <span
                                                    *ngIf="normalShow==true || RowEdit==false">${{datasub.vendorParCost}}</span>
                                                <span
                                                    *ngIf="datasub.customArrayIndex==EditRowIndex && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                    <input class="customInput EditWidth"
                                                        formControlName='UpdatevendorParCost' type="text"
                                                        [(ngModel)]="UpdatevendorParCost" class="Cost"
                                                        style="width: 140px;" maxlength="10" />
                                                </span>

                                            </td>
                                            <td>{{datasub.vendorParCost*datasub.qty | currency}}</td>



                                        </tr>
                                    </ng-container>
                                </ng-container>
                                <tfoot>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td> </td>
                                        <td></td>
                                        <td colspan="1">
                                            <b>Sub Total</b>
                                        </td>
                                        <td>
                                            {{ SubTotalVendorCost | currency}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td colspan="1">
                                            <span style="display:flex; justify-content: center;">
                                                <input type="text" maxlength="10" class="newWidth" readonly
                                                    value="Sales Tax" style="color: #28d094 !important;">
                                                <input type="text" maxlength="10" class="newWidths" readonly
                                                    [value]="TotalSalesTax">
                                            </span>
                                        </td>
                                        <td>
                                            {{(TSubTotalVendorCost * TotalSalesTax)/ 100 | currency}}
                                        </td>
                                    </tr>
                                    <tr>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td>
                                            Free Shipping :- <span
                                                [style.color]="shipcolor=='1' ? '#28d094' : shipcolor=='2' ? 'red' : ''"
                                                style="font-weight: bold;">{{FreeShipping | currency}}</span>
                                        </td>
                                        <td colspan="1">
                                            <b>Total </b>

                                        </td>
                                        <td>
                                            {{SubTotalVendorCost + (TSubTotalVendorCost * TotalSalesTax)/ 100 |
                                            currency}}
                                        </td>
                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <div class="alert alert-danger fade show mt-1" role="alert"
                            style="padding: 0.35rem 1.25rem; font-size: 0.7rem;" *ngIf="QtytoFulfillMsg==true">
                            Qty can't be increase.
                            <button type="button" class="close" (click)="closenoti()">
                                <strong aria-hidden="true" class="text-danger">&times;</strong>
                            </button>
                        </div>
                        <div class="submit-from text-right ">
                            <button type="button"
                                *ngIf="rolePermissionsList && rolePermissionsList[0].isInsert && IsToggleEdit==false; "
                                class="btn blue-bg round-btn" (click)="SaveConfirmation(contentSavePurchase)"
                                [disabled]="isPartval"><i class="fas fa-save" aria-hidden="true"></i>Save</button>
                            <button type="button"
                                *ngIf="rolePermissionsList && rolePermissionsList[0].isInsert && IsToggleEdit==true;"
                                class="btn blue-bg round-btn" (click)="ShowreasonPopup()"><i class="fas fa-edit"
                                    aria-hidden="true"></i>Update</button>

                            <ng-container *ngIf="onlyView==false">
                                <button type="button" class="btn darkred-bg round-btn"
                                    (click)="DiscrdProduct(discardItem)"><i class="fas fa-sign-out-alt"
                                        aria-hidden="true"></i>
                                    Exit </button>
                            </ng-container>
                            <ng-container *ngIf="onlyView==true">
                                <button type="button" class="btn darkred-bg round-btn" (click)="backFromView()"><i
                                        class="fas fa-sign-out-alt" aria-hidden="true"></i>
                                    Back </button>
                            </ng-container>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    </div>
    <div class="col-md-8">
        <div class="row">

            <div class="col-lg-12 text-left col-md-7 pl-0" *ngIf="IsEditMode==true">
                <button type="button" class="btn blue-bg round-btn part-category" (click)="ViewLogDataShow()"><i
                        class="fas fa-down-circle" aria-hidden="true"></i>View Log <i
                        class="ml-1 fas fa-angle-{{updownicon}}" aria-hidden="true"></i>
                </button>
            </div>
            <div class="col-lg-12 col-md-12 pl-0" style="display: none;" id="divlog">
                <div>
                    <table class="table table-bordered mb-0 mt-0 LogContent">
                        <tr style="background-color: #dee2e6;">
                            <td style="width: 16%;"><b>Date time</b></td>
                            <td style="width: 16%;"><b>User name</b></td>
                            <td style="width: 10%;"><b>Action</b></td>
                            <td style="width: 53%;"><b>Reason</b></td>
                            <td style="width: 5%;"><b>View</b></td>
                        </tr>
                        <tr *ngFor="let logitem of PurchaseOrderLogList">
                            <td class="customLabel">{{logitem.createdDate|date: "MMM-dd-yyyy hh:mm:ss a"}}</td>
                            <td class="customLabel">{{logitem.userName}} </td>
                            <td class="customLabel">{{logitem.action}}</td>
                            <td *ngIf="logitem.reason!=null" class="customLabel">{{logitem.reason}}</td>
                            <td *ngIf="logitem.reason==null" class="customLabel">PO-{{logitem.purchaseOrderNo}}</td>
                            <td class="customLabel" (click)="LogPdfOpen(logitem.attachedPOPDF)"><span
                                    *ngIf="logitem.action=='Mail sent'"><i class="fa fa-paperclip"
                                        style="cursor: pointer;" aria-hidden="true" data-toggle="modal"></i></span><span
                                    *ngIf="logitem.action!='Mail sent'">N/A</span></td>
                        </tr>
                    </table>


                </div>
            </div>
        </div>
    </div>

</section>
<ng-template #content let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg alert_heading sweet-heading"></h4>
    <button type="button" class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body p-1">
        <div class="thank-you-pop">
            <img src="./assets/image/popup-alert.png" alt="">
            <h1>Confirmation</h1>
            <p class="mt-1">{{deleteDetail}}</p>
        </div>
    </div>
    <div class="modal-footer sweet-footer text-center p-1">
        <button type="button" class="btn green-bg round-btn m-1"
            (click)="deletePart(deletedItem); c('Close click')">Yes</button>
        <button type="button" class="btn red-bg round-btn m-1" (click)="c('Close click')">No</button>
    </div>
</ng-template>

<ng-template #contentSavePurchase let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg alert_heading sweet-heading"></h4>
    <button type="button" class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body p-1">
        <div class="thank-you-pop">
            <img src="./assets/image/popup-alert.png" alt="">
            <h1>Confirmation</h1>
            <p class="mt-1">{{deleteDetail}}</p>
        </div>
    </div>
    <div class="modal-footer sweet-footer text-center p-1">
        <button type="button" class="btn green-bg round-btn m-1"
            (click)="AddPuchaseorder(); c('Close click')">Yes</button>
        <button type="button" class="btn red-bg round-btn m-1" (click)="c('Close click')">No</button>
    </div>
</ng-template>

<ng-template #contentPart let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg alert_heading sweet-heading"></h4>
    <button type="button" class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body p-1">
        <div class="thank-you-pop">
            <img src="./assets/image/popup-alert.png" alt="">
            <h1>Confirmation</h1>
            <p class="mt-1">{{deleteDetail}}</p>
        </div>
    </div>
    <div class="modal-footer sweet-footer text-center p-1">
        <button type="button" class="btn green-bg round-btn m-1" (click)="DeletePart(); c('Close click')">Yes</button>
        <button type="button" class="btn red-bg round-btn m-1" (click)="c('Close click')">No</button>
    </div>
</ng-template>

<div class="modal" id="myModal1">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-header info-popup-header p-2">
                <h6 class="modal-title info-popup-title sweet-heading"></h6>
                <button type="button" class="close btn alert_modal" (click)="hidemodalpopup1()" data-dismiss="modal">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <!-- <div class="modal-body m-0">
                <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="">
                    <h1>Confirmation</h1>
                    <p>“Already added this part, add again?”</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn green-bg round-btn m-1" (click)="showPO()"
                    data-dismiss="modal">Yes</button>
                <button type="button" class="btn red-bg round-btn m-1" (click)="hidemodalpopup1()"
                    data-dismiss="modal">Close</button>
            </div> -->
            <div class="modal-body m-0">
                <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="">
                    <h1>Confirmation</h1>
                    <p>“Already added this part, add again?”</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn green-bg round-btn m-1" (click)="showPO()"
                    data-dismiss="modal">Yes</button>
                <button type="button" class="btn red-bg round-btn m-1" (click)="hidemodalpopup1()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>

    </div>
</div>

<div class="modal" id="modalPDFAlready">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-header info-popup-header p-2">
                <h6 class="modal-title info-popup-title sweet-heading"></h6>
                <button type="button" class="close btn alert_modal" (click)="modalPDFAlreadyClose()"
                    data-dismiss="modal">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body m-0">
                <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="">
                    <h1>Alert</h1>
                    <p>PDF file already uploaded.</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn m-0" (click)="modalPDFAlreadyClose()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="modalPDF">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-header info-popup-header p-2">
                <h6 class="modal-title info-popup-title sweet-heading"></h6>
                <button type="button" class="close btn alert_modal" (click)="modalPDFClose()" data-dismiss="modal">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body m-0">
                <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="">
                    <h1>Alert</h1>
                    <p>Please upload only PDF file.</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn m-0" (click)="modalPDFClose()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="modalNoPart">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-header info-popup-header p-2">
                <h6 class="modal-title info-popup-title sweet-heading"></h6>
                <button type="button" class="close btn alert_modal" (click)="noPart()" data-dismiss="modal">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body m-0">
                <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="">
                    <h1>Alert</h1>
                    <p>There are no part in selected vendor.</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn m-0" (click)="noPart()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="myModalVendorDocument">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header info-popup-header p-2">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="hidemodalpopupVendor()"
                    data-dismiss="modal">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="">
                    <h1>Alert</h1>
                    <p>You have not added any part in this purchase order, so please add at least one part.</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="hidemodalpopupVendor()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>


<div class="modal" id="myModalsave">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-header info-popup-header p-2">
                <button type="button" class="close" (click)="hidemodalpopupSave()" data-dismiss="modal"
                    aria-label=""><span>×</span></button>
            </div>
            <div class="modal-body">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank you!</h1>
                    <p>Your {{Stavalu | lowercase}} has been saved.</p>
                    <h3 class="cupon-pop" style="font-size:12px;">Your {{Stavalu | lowercase}} number:
                        <span>{{POGenerateNumber}}</span>
                    </h3>

                    <div class="row">
                        <div class="col-md-2"></div>
                        <div class="col-md-8">
                            <select class="customInput pl-1px" [(ngModel)]="ExportTypePdf"
                                (change)="changeWebsite($event)">
                                <option value="">Select Export PDF</option>
                                <option *ngFor="let item of ExportTypePdfDDL" [value]="item.id">
                                    {{item.name}}
                                </option>
                            </select>


                        </div>
                        <div class="col-md-2">


                        </div>
                    </div>
                    <br />
                    <div class="row">
                        <div class="col-md-2"></div>
                        <div class="col-md-4">
                            <button type="button" class="btn blue-bg round-btn" (click)="PopSendMail()"
                                style="width: 120px;margin-left: -2px;">Send Email&nbsp;&nbsp;<i
                                    class="fa fa-file-pdf-o text-white" aria-hidden="true"></i></button>
                        </div>
                        <div class="col-md-4">
                            <button type="button" class="btn blue-bg round-btn" *ngIf="SummaryPO==true"
                                style="width: 144px;margin-left: -27px;" (click)="PopSummary()">Summary PO&nbsp;&nbsp;<i
                                    class="fa fa-print text-white" aria-hidden="true"></i></button>

                            <button type="button" class="btn blue-bg round-btn" *ngIf="DetailedPO==true"
                                style="width: 144px;margin-left: -27px;" (click)="PopDetailed()">Detailed
                                PO&nbsp;&nbsp;<i class="fa fa-print text-white" aria-hidden="true"></i></button>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</div>

<div class="modal" id="add_success">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="addsuccess2()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Added Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn green-bg round-btn" data-dismiss="modal">Yes</button>
                <button type="button" class="btn red-bg round-btn" (click)="addsuccess2()"
                    data-dismiss="modal">No</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="add_update">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="updateitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Updated Successfully</p>
                    <div class="mb-0">
                        <div class="row">
                            <div class="col-md-2"></div>
                            <div class="col-md-8">
                                <select class="customInput pl-1px" [(ngModel)]="ExportTypePdf"
                                    (change)="changeWebsite($event)">
                                    <option value="">Select Export PDF</option>
                                    <option *ngFor="let item of ExportTypePdfDDL" [value]="item.id">
                                        {{item.name}}
                                    </option>
                                </select>


                            </div>
                            <div class="col-md-2">


                            </div>
                        </div>
                        <br />
                        <div class="row">
                            <div class="col-md-2"></div>
                            <div class="col-md-4">
                                <button type="button" class="btn blue-bg round-btn" (click)="PopSendMail()"
                                    style="width: 120px;margin-left: -2px;">Send Email&nbsp;&nbsp;<i
                                        class="fa fa-file-pdf-o text-white" aria-hidden="true"></i></button>
                            </div>
                            <div class="col-md-4">
                                <button type="button" class="btn blue-bg round-btn" *ngIf="SummaryPO==true"
                                    style="width: 144px;margin-left: -27px;" (click)="PopSummary()">Summary
                                    PO&nbsp;&nbsp;<i class="fa fa-print text-white" aria-hidden="true"></i></button>

                                <button type="button" class="btn blue-bg round-btn" *ngIf="DetailedPO==true"
                                    style="width: 144px;margin-left: -27px;" (click)="PopDetailed()">Detailed
                                    PO&nbsp;&nbsp;<i class="fa fa-print text-white" aria-hidden="true"></i></button>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="updateitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="delete_success">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="deleteitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Deleted Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="deleteitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="no_record">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="norecorditem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>Record Not Found</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="norecorditem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="configMsgModel">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="configMsgModelClose()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>Please configure the sender email in email configuration.</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="configMsgModelClose()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="wrong_msg">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="msgitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>Something went wrong</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="msgitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<ng-template #discardItem let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg alert_heading sweet-heading"></h4>
    <button type="button" class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>

    <div class="modal-body p-1">
        <div class="thank-you-pop">
            <img src="./assets/image/popup-alert.png" alt="">
            <h1>Confirmation</h1>
            <p class="mt-1">{{deleteDetail}}</p>
        </div>
    </div>
    <div class="modal-footer sweet-footer text-center p-1">
        <button type="button" class="btn green-bg round-btn" (click)="gotoDashBoard(); c('Close click')">Yes</button>
        <button type="button" class="btn red-bg round-btn" (click)="c('Close click')">No</button>
    </div>
</ng-template>
<div class="modal" id="Imagevlaidation">
    <div class="modal-dialog">
        <div class="modal-content">
            <h4 class="modal-title darkred-bg sweet-heading"></h4>
            <button type="button" class="close btn alert_modal" aria-label="Close" (click)="ImageClose()">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
                <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="">
                    <h1>Alert</h1>
                    <p class="mb-0">You have already added 4 vendor document into list.</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer">
                <button type="button" class="btn red-bg round-btn m-0" (click)="ImageClose()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<ng-template #contentImage let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg sweet-heading"></h4>
    <button type="button" class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body">
        <div class="thank-you-pop">
            <img src="./assets/image/popup-alert.png" alt="">
            <h1>Confirmation</h1>
            <p class="mb-0">{{deleteDetail}}</p>
        </div>
    </div>
    <div class="modal-footer sweet-footer text-center">
        <button type="button" class="btn green-bg round-btn m-0 mr-1"
            (click)="DeleteImage(); c('Close click')">Yes</button>
        <button type="button" class="btn red-bg round-btn m-0" (click)="c('Close click')">No</button>
    </div>
</ng-template>



<div style="display: none;">
    <div style="width: 100%;" id="contentpdf1" #contentpdf1 style="margin-left:20px;">
        <style type="text/css">
            .main-wrapper {
                width: 100%;
            }

            .border-PDF td {
                border: 1px solid #000;
                line-height: 1;
                font-size: 11px;
                padding: 3px;
            }

            table.strip-table tr:nth-child(odd) {
                background: #ddd;
            }

            .main-wrapper table {
                margin-top: 0;
                margin-bottom: 0;
            }
        </style>
        <div class="main-wrapper"
            style="width:570px;margin-top: 10px;font-family: 'Roboto', sans-serif;max-height: 70%;">

            <table cellpadding="0" cellspacing="0" style="width: 100%; border:none;margin-right: 10px;" id="customers">
                <tr>
                    <td>
                        <table cellpadding="0" cellspacing="0" style="width: 100%;border: none;">
                            <tr>
                                <td style="width: 65%;font-weight: 700;padding-left: 0px;">

                                    <ng-container *ngIf="PurchaseOrderSummaryPDF.cLogo">
                                        <p style="text-align: left;">
                                            <img [src]="PurchaseOrderSummaryPDF.cLogo" style=" display: inline-block; height: 80px; width:50%;border: none;border-radius:unset;max-height: unset;
                                            height: unset;">
                                        </p>
                                    </ng-container>


                                    <h1 style="margin:0px;text-align: left;font-size: 15px;font-weight: 900;">
                                        {{PurchaseOrderSummaryPDF.companyname}}
                                    </h1>
                                    <p style="margin: 0;text-align: left;font-size: 10px;line-height: 1.3;font-family: 'Montserrat', sans-serif;
                                font-weight: 300;">
                                        <span [innerHtml]="this.sharedService.FormatAddress(PurchaseOrderSummaryPDF.caddress, 
                                PurchaseOrderSummaryPDF.caddress2, PurchaseOrderSummaryPDF.cCity, PurchaseOrderSummaryPDF.cState,
                                PurchaseOrderSummaryPDF.cZipcode)"></span><br>
                                        {{PurchaseOrderSummaryPDF.cCountry | titlecase}}<br>
                                        Phone : {{PurchaseOrderSummaryPDF.cPhone}}<br>
                                        Email : {{PurchaseOrderSummaryPDF.cEmail}}
                                    </p>
                                </td>
                                <td style="width: 35%;vertical-align: top;">
                                    <h1 style="margin: 0 0 10px; font-size: 18px; text-align: right;font-weight: bolder;
                                ">{{PurchaseOrderSummaryPDF.statusId==1?'Bid Request':'Purchase Order'}}</h1>
                                    <table cellpadding="5" cellspacing="0" border="1" class="border-PDF"
                                        style="width: 100%; font-size: 14px; text-align: center; margin-bottom: 10px;">
                                        <tr style="background-color: #e9e9e9; ">
                                            <td style="width: 60%; font-size: 10px;padding: 3px !important;">
                                                <span style="font-size: 10px; font-family: 'Montserrat', sans-serif; ">
                                                    <strong>Order #</strong></span>

                                            </td>
                                            <td style="width: 40%; font-size: 10px;padding: 3px !important;">

                                                <span style="font-size: 10px; font-family: 'Montserrat', sans-serif; ">
                                                    <strong>Date</strong></span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span style="font-size: 10px; font-family: 'Montserrat', sans-serif; ">
                                                    <strong>{{PurchaseOrderSummaryPDF.poNumber}}</strong></span>
                                            </td>
                                            <td>

                                                <span style="font-size: 10px; font-family: 'Montserrat', sans-serif; ">
                                                    {{PurchaseOrderSummaryPDF.orderDate| date: 'MM/dd/yyyy'}} </span>
                                            </td>
                                        </tr>
                                    </table>
                                    <table cellpadding="5" cellspacing="0" width="100%" style="border: 0;">
                                        <tr>
                                            <td style="text-align: center;border: 0;">
                                                <img src="./assets/image/barcode.jpg" style=" display: inline-block; width:80%;border: none;border-radius:unset;max-height: unset;
                                                height: unset;">
                                            </td>
                                        </tr>
                                    </table>

                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table cellpadding="0" cellspacing="0" style="width: 100%;border: none;margin-top:0;">
                            <tr>
                                <td style="width: 50%; padding-right: 10px;">
                                    <table class="border-PDF" cellpadding="5" cellspacing="0" border="1"
                                        style="width: 100%">
                                        <tr style="background-color: #e9e9e9;">
                                            <td style="text-align: left;font-size: 10px;">
                                                <span style="font-size: 10px; font-family: 'Montserrat', sans-serif; ">
                                                    <strong>Vendor:</strong> </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                style="text-align: left;font-size: 10px;line-height: 1.3;font-weight: 300 !important; font-family: 'Montserrat', sans-serif;">
                                                <span style="font-size: 10px; font-family: 'Montserrat', sans-serif; ">
                                                    {{PurchaseOrderSummaryPDF.vendorname}}<br /></span>
                                                <span style="font-size: 10px; font-family: 'Montserrat', sans-serif; "
                                                    [innerHtml]="this.sharedService.FormatAddress(PurchaseOrderSummaryPDF.vaddress,
                                                PurchaseOrderSummaryPDF.vaddress2, PurchaseOrderSummaryPDF.vCity, PurchaseOrderSummaryPDF.vState,
                                                PurchaseOrderSummaryPDF.vZipcode)">
                                                </span>
                                                <br />
                                                <span style="font-size: 10px; font-family: 'Montserrat', sans-serif; ">
                                                    {{PurchaseOrderSummaryPDF.vCountry | titlecase}}<br /></span>
                                                <span style="font-size: 10px; font-family: 'Montserrat', sans-serif; ">
                                                    Phone:
                                                    {{!PurchaseOrderSummaryPDF.vPhone
                                                    ?'N/A':PurchaseOrderSummaryPDF.vPhone}}<br /></span>

                                                <span style="font-size: 10px; font-family: 'Montserrat', sans-serif; ">
                                                    Email:
                                                    {{!PurchaseOrderSummaryPDF.vEmail
                                                    ?'N/A':PurchaseOrderSummaryPDF.vEmail}}<br />
                                                </span>
                                                <span style="font-size: 10px; font-family: 'Montserrat', sans-serif; ">
                                                    Fax: {{!PurchaseOrderSummaryPDF.vFax
                                                    ?'N/A':PurchaseOrderSummaryPDF.vFax}}</span>
                                            </td>
                                        </tr>
                                    </table>
                                    <table class="border-PDF" cellpadding="5" cellspacing="0" border="1"
                                        style="width: 100%; margin-top:5px;">
                                        <tr>
                                            <td
                                                style="text-align: left;font-size: 10px;font-weight: 100; font-family: 'Montserrat', sans-serif;">
                                                <span
                                                    style="font-size: 10px; font-family: 'Montserrat', sans-serif;font-weight: bolder !important; ">Contact:
                                                    {{PurchaseOrderSummaryPDF.contactVendorname}} </span>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td style="width: 50%; padding-left: 10px; vertical-align: top;">
                                    <table class="border-PDF" cellpadding="5" cellspacing="0" border="1"
                                        style="width: 100%">
                                        <tr style="background-color: #e9e9e9;">
                                            <td
                                                style="text-align: left;font-size: 10px; font-family: 'Montserrat', sans-serif;">
                                                <strong>Ship To:</strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                style="text-align: left;font-size: 10px;line-height: 1.3;font-weight: 300 !important; font-family: 'Montserrat', sans-serif;">
                                                <span style="font-size: 10px; font-family: 'Montserrat', sans-serif; ">
                                                    {{!PurchaseOrderSummaryPDF.shipname
                                                    ?'N/A':PurchaseOrderSummaryPDF.shipname}}<br />
                                                </span>
                                                <span style="font-size: 10px; font-family: 'Montserrat', sans-serif; "
                                                    [innerHtml]="this.sharedService.FormatAddress(PurchaseOrderSummaryPDF.shipaddress,
                                                PurchaseOrderSummaryPDF.shipaddress2, PurchaseOrderSummaryPDF.shipCity, PurchaseOrderSummaryPDF.shipState,
                                                PurchaseOrderSummaryPDF.ShipZipcode)">
                                                </span>
                                                <br />
                                                <span style="font-size: 10px; font-family: 'Montserrat', sans-serif; ">
                                                    {{PurchaseOrderSummaryPDF.shipCountry | titlecase}}<br />
                                                </span>
                                                <span style="font-size: 10px; font-family: 'Montserrat', sans-serif; ">
                                                    Phone:
                                                    {{!PurchaseOrderSummaryPDF.shipPhone
                                                    ?'N/A':PurchaseOrderSummaryPDF.shipPhone}}<br /></span>

                                                <span style="font-size: 10px; font-family: 'Montserrat', sans-serif; ">
                                                    Email:
                                                    {{!PurchaseOrderSummaryPDF.shipEmail
                                                    ?'N/A':PurchaseOrderSummaryPDF.shipEmail}}<br />
                                                </span>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table cellpadding="0" cellspacing="0"
                            style="width: 100%;border-collapse: collapse;border: none; line-height: 12px; margin-top: 30px;margin-bottom: 20px;text-align: left;">
                            <p style="width:570px;margin-top: 50px; display: inline;line-height: 16px;
                            text-align: left;">
                                <span style="font-weight:bold">Vendor &nbsp; Notes: </span> &nbsp;
                                {{PurchaseOrderSummaryPDF.vendorNotes}}
                            </p>
                        </table>

                        <table style="width: 100%;border-collapse: collapse; margin-top: 10px;text-align: center;">
                            <tr>
                                <td style="border: 1px solid #000; background: #ddd;">
                                    <b style="font-size: 10px; font-family: 'Montserrat', sans-serif;">Buyer</b>
                                </td>
                                <td style="border: 1px solid #000; background: #ddd;">
                                    <b style="font-size: 10px; font-family: 'Montserrat', sans-serif;">Payment Terms</b>
                                </td>
                                <td style="border: 1px solid #000; background: #ddd;">
                                    <b style="font-size: 10px; font-family: 'Montserrat', sans-serif;">FOB Pont</b>
                                </td>
                                <td style="border: 1px solid #000; background: #ddd;">
                                    <b style="font-size: 10px; font-family: 'Montserrat', sans-serif;">Shipping
                                        Terms</b>
                                </td>
                                <td style="border: 1px solid #000; background: #ddd;">
                                    <b style="font-size: 10px; font-family: 'Montserrat', sans-serif;">Ship VIa</b>
                                </td>
                                <td style="border: 1px solid #000; background: #ddd;">
                                    <b style="font-size: 10px; font-family: 'Montserrat', sans-serif;">Order
                                        Date</b>
                                </td>
                            </tr>
                            <tr>
                                <td
                                    style="border: 1px solid #000; font-size: 10px; font-family: 'Montserrat', sans-serif;">
                                    <span
                                        style="font-size: 10px; font-family: 'Montserrat', sans-serif;">{{PurchaseOrderSummaryPDF.buyername}}
                                    </span>
                                </td>
                                <td
                                    style="border: 1px solid #000; font-size: 10px; font-family: 'Montserrat', sans-serif;">
                                    <span
                                        style="font-size: 10px; font-family: 'Montserrat', sans-serif;">{{!PurchaseOrderSummaryPDF.paymentTerm
                                        ?'N/A':PurchaseOrderSummaryPDF.paymentTerm}}
                                    </span>
                                </td>
                                <td
                                    style="border: 1px solid #000; font-size: 10px;font-family: 'Montserrat', sans-serif;">
                                    <span
                                        style="font-size: 10px; font-family: 'Montserrat', sans-serif;">{{!PurchaseOrderSummaryPDF.Fobpoint
                                        ?'N/A':PurchaseOrderSummaryPDF.Fobpoint}}</span>

                                </td>
                                <td
                                    style="border: 1px solid #000; font-size: 10px;font-family: 'Montserrat', sans-serif;">
                                    <span
                                        style="font-size: 10px; font-family: 'Montserrat', sans-serif;">{{!PurchaseOrderSummaryPDF.shippingTerms
                                        ?'N/A':PurchaseOrderSummaryPDF.shippingTerms}}</span>

                                </td>
                                <td
                                    style="border: 1px solid #000; font-size: 10px;font-family: 'Montserrat', sans-serif;">
                                    <span
                                        style="font-size: 10px; font-family: 'Montserrat', sans-serif;">{{!PurchaseOrderSummaryPDF.shipVia
                                        ?'N/A':PurchaseOrderSummaryPDF.shipVia}}</span>

                                </td>
                                <td
                                    style="border: 1px solid #000; font-size: 10px;font-family: 'Montserrat', sans-serif;">
                                    <span style="font-size: 10px; font-family: 'Montserrat', sans-serif;">
                                        {{PurchaseOrderSummaryPDF.orderDate| date: 'MM/dd/yyyy'}}</span>

                                </td>
                            </tr>
                        </table>
                        <table class="strip-table"
                            style="width: 100%;border: none;border-collapse: collapse; margin-top: 20px;text-align: center;max-height: 70%;">
                            <tr class="table table-striped" style="background: #ddd;">
                                <td
                                    style="border: 1px solid #fff;padding: 5px;border-bottom: 1px solid #000;font-weight: bolder !important;font-size: 10px;font-family: 'Montserrat', sans-serif;vertical-align: bottom;">

                                    <span
                                        style="font-size: 10px; font-family: 'Montserrat', sans-serif; font-weight: bolder !important;">
                                        Item #</span>
                                </td>

                                <td
                                    style="border: 1px solid #fff;padding: 5px;border-bottom: 1px solid #000;font-weight: bolder !important;font-size: 10px;font-family: 'Montserrat', sans-serif;vertical-align: bottom;">

                                    <span
                                        style="font-size: 10px; font-family: 'Montserrat', sans-serif; font-weight: bolder !important;">
                                        Vendor Part #</span>
                                </td>
                                <td
                                    style="border: 1px solid #fff;padding: 5px;border-bottom: 1px solid #000;font-weight: bolder !important;font-size: 10px;font-family: 'Montserrat', sans-serif;vertical-align: bottom;">

                                    <span
                                        style="font-size: 10px; font-family: 'Montserrat', sans-serif; font-weight: bolder !important;">Vendor
                                        Part Name </span>
                                </td>
                                <td
                                    style="border: 1px solid #fff;padding: 5px;border-bottom: 1px solid #000;font-weight: bolder !important;font-size: 10px;font-family: 'Montserrat', sans-serif;vertical-align: bottom;">

                                    <span
                                        style="font-size: 10px; font-family: 'Montserrat', sans-serif; font-weight: bolder !important;">Company
                                        Ref #</span>
                                </td>

                                <td
                                    style="border: 1px solid #fff;padding: 5px;border-bottom: 1px solid #000;font-weight: bolder !important;font-size: 10px;font-family: 'Montserrat', sans-serif;vertical-align: bottom;">

                                    <span
                                        style="font-size: 10px; font-family: 'Montserrat', sans-serif; font-weight: bolder !important;">
                                        Unit Cost </span>
                                </td>
                                <td
                                    style="border: 1px solid #fff;padding: 5px;border-bottom: 1px solid #000;font-weight: bolder !important;font-size: 10px;font-family: 'Montserrat', sans-serif;vertical-align: bottom;">

                                    <span
                                        style="font-size: 10px; font-family: 'Montserrat', sans-serif; font-weight: bolder !important;">Qty
                                        Ordered </span>
                                </td>
                                <td
                                    style="border: 1px solid #fff;padding: 5px;border-bottom: 1px solid #000;font-weight: bolder !important;font-size: 10px;font-family: 'Montserrat', sans-serif;vertical-align: bottom;">

                                    <span
                                        style="font-size: 10px; font-family: 'Montserrat', sans-serif; font-weight: bolder !important;">UOM
                                    </span>
                                </td>
                                <td
                                    style="border: 1px solid #fff;padding: 5px;border-bottom: 1px solid #000;font-weight: bolder !important;font-size: 10px;font-family: 'Montserrat', sans-serif;vertical-align: bottom;">

                                    <span
                                        style="font-size: 10px; font-family: 'Montserrat', sans-serif; font-weight: bolder !important;">Total
                                        Cost</span>
                                </td>
                            </tr>

                            <tr *ngFor="let poitem of PurchaseOrderSummaryPDF.pOpartPDF;let poitemindex = index;"
                                style="page-break-after: always">
                                <td style='border: 1px solid #fff; padding: 0px;'>
                                    <span
                                        style="font-size: 10px; font-family: 'Montserrat', sans-serif; ">{{poitemindex+1}}</span>
                                </td>

                                <td style='border: 1px solid #fff; padding: 0px;'>

                                    <span style="font-size: 10px; font-family: 'Montserrat', sans-serif; ">
                                        {{!poitem.vendorpartnumber ?"N/A":poitem.vendorpartnumber}} </span>
                                </td>
                                <td style='border: 1px solid #fff; padding: 0px;'>

                                    <span style="font-size: 10px; font-family: 'Montserrat', sans-serif; ">
                                        {{!poitem.vendorpartname ?"N/A":poitem.vendorpartname}} </span>
                                </td>

                                <td style='border: 1px solid #fff; padding: 0px;'>

                                    <span style="font-size: 10px; font-family: 'Montserrat', sans-serif; ">
                                        {{!poitem.customerpartnumber ?"N/A":poitem.customerpartnumber}} </span>
                                </td>
                                <td style='border: 1px solid #fff; padding: 0px;'>

                                    <span
                                        style="font-size: 10px; font-family: 'Montserrat', sans-serif; ">${{poitem.unitCost}}
                                    </span>
                                    <!--                         
                                    <div class="position-relative">
                                        <span class="tooltipWrap tooltip-icon"
                                            *ngIf="normalShow==true || RowEdit==false"><i
                                                class="fa fa-info-circle"></i></span>
                                        <span class="tooltips">Vendor Part :
                                            {{!datasub.vendorpartNumber ? "N/A" :
                                            datasub.vendorpartNumber}}<br>Description :
                                            {{!datasub.description ? "N/A" : datasub.description}}<br>
                                            VendorName :
                                            {{!datasub.vendorName ? "N/A" :
                                            datasub.vendorName}}
                                            <br>
                                        </span>
                                    </div> -->

                                    <!-- <span 
                                   class="tooltips" style="top:-34px"> {{this.disPartInPercentage == 0 ? 'No Part Discount' : 'Per Part Discount : ' + this.disPartInPercentage + '%'}}
                                </span> -->
                                </td>
                                <td style='border: 1px solid #fff; padding: 0px;'>
                                    <span
                                        style="font-size: 10px; font-family: 'Montserrat', sans-serif; ">{{poitem.ordered}}
                                    </span>
                                </td>
                                <td style='border: 1px solid #fff; padding: 0px;'>
                                    <span style="font-size: 10px; font-family: 'Montserrat', sans-serif; ">
                                        {{!poitem.uom ?"N/A":poitem.uom}}</span>
                                </td>
                                <td style='border: 1px solid #fff; padding: 0px;'>

                                    <span style="font-size: 10px; font-family: 'Montserrat', sans-serif; ">
                                        {{poitem.subtotalcost|currency}} </span>
                                </td>

                                <br />
                            </tr>




                            <tr style="page-break-after: always">
                                <td style='border: 1px solid #fff; padding: 0px;'>&nbsp;</td>
                                <td style='border: 1px solid #fff; padding: 0px;'></td>
                                <td style='border: 1px solid #fff; padding: 0px;'></td>
                                <td style='border: 1px solid #fff; padding: 0px;'></td>
                                <td style='border: 1px solid #fff; padding: 0px;'></td>
                                <td style='border: 1px solid #fff; padding: 0px;'></td>
                                <td style='border: 1px solid #fff; padding: 0px;'></td>
                                <td style='border: 1px solid #fff; padding: 0px;'></td>
                            </tr>

                            <tr style="page-break-after: always">
                                <td style='border: 1px solid #fff; padding: 0px;'><b>Total</b></td>
                                <td style='border: 1px solid #fff; padding: 0px;'></td>
                                <td style='border: 1px solid #fff; padding: 0px;'></td>
                                <td style='border: 1px solid #fff; padding: 0px;'></td>
                                <td style='border: 1px solid #fff; padding: 0px;'></td>
                                <td style='border: 1px solid #fff; padding: 0px;'></td>
                                <td style='border: 1px solid #fff; padding: 0px;'></td>
                                <td style='border: 1px solid #fff; padding: 0px;'>
                                    <b>{{PurchaseOrderSummaryPDF.totalAmount|currency}}</b>
                                </td>
                            </tr>
                        </table>

                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>


<div class="modal" id="PurchaseAlert">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="PurchaseAlert()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="">
                    <h1>Alert</h1>
                    <p>{{PurchaseOrderAlert}}</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="PurchaseAlert()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="ReasonModalPopup">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading">Reason for Update</h4>
                <button type="button" class="close btn alert_modal" (click)="ReasonModalPopup()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="row">
                    <div class="col-md-2">
                        Reason <span style="color:red;">*</span>
                    </div>
                    <div class="col-md-4 mt-1">

                        <textarea name="w3review" rows="4" cols="50" [(ngModel)]="Reason"
                            [ngClass]="!Reason? 'validation_border': ''" style="margin-top: 15px;">
                        </textarea>
                    </div>

                </div>

            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn green-bg round-btn" (click)="UpdatePuchaseorder()"
                    data-dismiss="modal">Save</button>
                <button type="button" class="btn red-bg round-btn" (click)="ReasonModalPopup()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="SelectPDf">
    <div class="modal-dialog">
        <div class="modal-content">
            <h4 class="modal-title darkred-bg sweet-heading"></h4>
            <button type="button" class="close btn alert_modal" aria-label="Close" (click)="SelectPDf()">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
                <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="">
                    <h1>Alert</h1>
                    <p class="mb-0">{{Chooseoption}}</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer">
                <button type="button" class="btn red-bg round-btn m-0" (click)="SelectPDf()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="sendMailPopup">
    <div class="modal-dialog" style="max-width: 60%;">
        <div class="modal-content">

            <div class="modal-header info-popup-header p-2">
                <h5 class="modal-title info-popup-title p-1" id="exampleModalLabel1">Send
                    Email</h5>
                <button type="button" class="close" (click)="sendMailPopup()" data-dismiss="modal"
                    aria-label=""><span>×</span></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formSendEmail">
                    <div class="form-group row mb-0 required">
                        <label for="inputEmail3" class="col-sm-2 col-form-label customLabel">Email Template</label>
                        <div class="col-sm-6 ">
                            <select class="customInput" [(ngModel)]="EmailTemplate" (change)="ChangeTemplate($event)"
                                formControlName='EmailTemplate'
                                [ngClass]="((!EmailTemplate) && (EmailTempValidate)) ? 'validation_border': ''">
                                <option value=''>Select Template</option>
                                <option *ngFor="let tempitem of tempList" [value]="tempitem.id">
                                    {{tempitem.tempName}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row mb-0 required">
                        <label for="inputEmail3" class="col-sm-2 col-form-label customLabel">To</label>
                        <div class="col-sm-6 ">
                            <input class="customInput" formControlName='txtEmailSendTo' type="text"
                                [(ngModel)]="txtEmailSendTo"
                                [ngClass]="((!txtEmailSendTo) && (EmailTempValidate)) ? 'validation_border': ''" />

                        </div>
                    </div>
                    <div class="form-group row mb-0">
                        <label for="inputEmail3" class="col-sm-2 col-form-label customLabel">Cc</label>
                        <div class="col-sm-6">
                            <input class="customInput" formControlName='txtEmailSendCC' type="text"
                                [(ngModel)]="txtEmailSendCC" />
                        </div>
                    </div>
                    <div class="form-group row mb-0">
                        <label for="inputEmail3" class="col-sm-2 col-form-label customLabel">Bcc</label>
                        <div class="col-sm-6">
                            <input class="customInput" formControlName='txtEmailSendBCC' type="text"
                                [(ngModel)]="txtEmailSendBCC" />
                        </div>
                    </div>
                    <div class="form-group row mb-0 required">
                        <label for="inputEmail3" class="col-sm-2 col-form-label customLabel">Subject</label>
                        <div class="col-sm-6">
                            <input class="customInput" formControlName='txtEmailSubject' type="text"
                                [(ngModel)]="txtEmailSubject" />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-2 col-form-label customLabel">Body</label>
                        <div class="col-sm-10">
                            <ckeditor [(ngModel)]="templateBody" name="myckeditor" formControlName="templateBody"
                                [config]="ckeConfig" debounce="500">
                            </ckeditor>
                        </div>
                    </div>
                </form>
                <p class="mb-0">
                    <button type="button" class="btn blue-bg round-btn float-right" (click)="SendEmailPdf()">Send
                        email</button>
                </p>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="sendMailPopupSuccess">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-header info-popup-header p-2">
                <button type="button" class="close" (click)="sendMailPopupSuccessClose()" data-dismiss="modal"
                    aria-label=""><span>×</span></button>
            </div>
            <div class="modal-body">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>{{EmailSentMessage}}</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="sendMailPopupSuccessClose()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="ExportOption">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-header info-popup-header p-2">
                <button type="button" class="close" (click)="ExportOption()" data-dismiss="modal"
                    aria-label=""><span>×</span></button>
            </div>
            <div class="modal-body">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Choose your option</p>

                    <div class="row">
                        <div class="col-md-2"></div>
                        <div class="col-md-8">
                            <select class="customInput pl-1px" [(ngModel)]="ExportTypePdf"
                                (change)="changeWebsite($event)">
                                <option value="">Select Export PDF</option>
                                <option *ngFor="let item of ExportTypePdfDDL" [value]="item.id">
                                    {{item.name}}
                                </option>
                            </select>


                        </div>
                        <div class="col-md-2">


                        </div>
                    </div>
                    <br />

                    <div class="row">
                        <div class="col-md-2"></div>
                        <div class="col-md-4">
                            <button type="button" class="btn blue-bg round-btn" (click)="PopSendMail()"
                                style="width: 120px;margin-left: -2px;">Send Email&nbsp;&nbsp;<i
                                    class="fa fa-file-pdf-o text-white" aria-hidden="true"></i></button>
                        </div>
                        <div class="col-md-4">
                            <button type="button" class="btn blue-bg round-btn" *ngIf="SummaryPO==true"
                                style="width: 144px;margin-left: -27px;" (click)="PopSummary()">Summary PO&nbsp;&nbsp;<i
                                    class="fa fa-print text-white" aria-hidden="true"></i></button>

                            <button type="button" class="btn blue-bg round-btn" *ngIf="DetailedPO==true"
                                style="width: 144px;margin-left: -27px;" (click)="PopDetailed()">Detailed
                                PO&nbsp;&nbsp;<i class="fa fa-print text-white" aria-hidden="true"></i></button>
                        </div>
                    </div>

                </div>

            </div>
        </div>
    </div>
</div>

<div class="modal" id="CustomPartitem">
    <div class="modal-dialog">
        <div class="modal-content">
            <h4 class="modal-title info-popup-header darkred-bg alert_heading sweet-heading"></h4>
            <button type="button" class="close btn alert_modal" aria-label="Close" (click)="CustomPartPopup()">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thanks</h1>

                    <p class="modal-para mb-0">Part : {{partnameDisplay}} Added</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer">
                <button type="button" class="btn red-bg round-btn m-0" (click)="CustomPartPopup()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="CalcPartModel">
    <div class="modal-dialog">
        <div class="modal-content">
            <h4 class="modal-title info-popup-header darkred-bg alert_heading sweet-heading"></h4>
            <button type="button" class="close btn alert_modal" aria-label="Close" (click)="CalcPartClose()">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
                <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="">
                    <h1>Alert</h1>
                    <p class="mt-1">You have not added any part item, so please add at least one part item</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer">
                <button type="button" class="btn red-bg round-btn m-0" (click)="CalcPartClose()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="CalcPartSuccess">
    <div class="modal-dialog">
        <div class="modal-content">
            <h4 class="modal-title info-popup-header darkred-bg alert_heading sweet-heading"></h4>
            <button type="button" class="close btn alert_modal" aria-label="Close" (click)="CalcPartSuccess()">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank you</h1>
                    <p class="mt-1">Recalculate Part Cost Successfully !</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer">
                <button type="button" class="btn red-bg round-btn m-0" (click)="CalcPartSuccess()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="AlreadyCalcPart">
    <div class="modal-dialog">
        <div class="modal-content">
            <h4 class="modal-title info-popup-header darkred-bg alert_heading sweet-heading"></h4>
            <button type="button" class="close btn alert_modal" aria-label="Close" (click)="AlreadyCalcPart()">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank you</h1>
                    <p class="mt-1">Part Cost Recalculate already!</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer">
                <button type="button" class="btn red-bg round-btn m-0" (click)="AlreadyCalcPart()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="FinalizeEdit">
    <div class="modal-dialog">
        <div class="modal-content">
            <h4 class="modal-title info-popup-header darkred-bg alert_heading sweet-heading"></h4>
            <button class="close btn alert_modal" aria-label="Close" (click)="FinalizeEdit()">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
                <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="">
                    <h1>Alert</h1>
                    <p class="mt-1">Please finalize your edit</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer">
                <button type="button" class="btn red-bg round-btn m-0" (click)="FinalizeEdit()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="UpdatePartModel">
    <div class="modal-dialog">
        <div class="modal-content">
            <h4 class="modal-title info-popup-header darkred-bg alert_heading sweet-heading"></h4>
            <button type="button" class="close btn alert_modal" aria-label="Close" (click)="UpdatePartModelClose()">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
                <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="">
                    <h1>Alert</h1>
                    <p class="mt-1">There is no part item exist, You can not update the purchase order</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer">
                <button type="button" class="btn red-bg round-btn m-0" (click)="UpdatePartModelClose()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="TypeInventory">
    <div class="modal-dialog">
        <div class="modal-content">
            <h4 class="modal-title info-popup-header darkred-bg alert_heading sweet-heading"></h4>
            <button type="button" class="close btn alert_modal" aria-label="Close" (click)="TypeInventoryClose()">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
                <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="">
                    <h1>Alert</h1>
                    <p class="mt-1">{{PartTypeInventory}}</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer">
                <button type="button" class="btn red-bg round-btn m-0" (click)="TypeInventoryClose()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>


<div style="display: none;">
    <div style="width: 100%;" id="contentpdf" #contentpdf style="margin-left:20px;">
        <style type="text/css">
            .main-wrapper {
                width: 100%;

            }

            .border-PDF td {
                border: 1px solid #000;
                line-height: 1;
                font-size: 11px;
                padding: 3px;
            }

            table.strip-table tr:nth-child(odd) {
                background: #ddd;
            }

            .main-wrapper table {
                margin-top: 0;
                margin-bottom: 0;
            }

            .main-wrapper tr {
                /* page-break-inside: avoid !important;
                page-break-after: always !important; */
                page-break-inside: avoid !important;
                page-break-before: always !important;
            }

            .main-wrapper td,
            .main-wrapper th {
                word-break: break-all !important;
                white-space: inherit !important;
            }

            .main-wrapper tr td.nowrap1,
            .main-wrapper tr th.nowrap1 {
                word-break: keep-all !important;
                white-space: nowrap !important;
            }

            @page {
                size: 500mm 50mm !important;
            }

            @media print {
                @page {
                    size: 500mm 50mm !important;
                }

                .main-wrapper tr {
                    /* page-break-after: always !important; */
                    page-break-inside: avoid !important;
                    page-break-before: always !important;
                }

                .main-wrapper {
                    background: red !important;
                }
            }
        </style>
        <div class="main-wrapper"
            style="width:570px;margin-top: 10px;font-family: 'Roboto', sans-serif;max-height: 70%; ">
            <table cellpadding="0" cellspacing="0" style="width: 100%; border:none;margin-right: 10px;" id="customers">
                <tr>

                    <td>
                        <table cellpadding="0" cellspacing="0" style="width: 100%;border: none;">
                            <tr>
                                <td style="width: 65%;font-weight: 700;padding-left: 0px;">

                                    <ng-container *ngIf="PurchaseOrderPDF.cLogo">
                                        <p style="text-align: left;">
                                            <img [src]="PurchaseOrderPDF.cLogo" style=" display: inline-block; height: 80px; width:50%;border: none;border-radius:unset;max-height: unset;
                                            height: unset;">
                                        </p>
                                    </ng-container>


                                    <h1 style="margin:0px;text-align: left;font-size: 15px;font-weight: 900;">
                                        {{PurchaseOrderPDF.companyname}}
                                    </h1>
                                    <p style="margin: 0;text-align: left;font-size: 10px;line-height: 1.3;font-family: 'Montserrat', sans-serif;
                                font-weight: 300;">
                                        <span [innerHtml]="this.sharedService.FormatAddress(PurchaseOrderPDF.caddress, 
                                PurchaseOrderPDF.caddress2, PurchaseOrderPDF.cCity, PurchaseOrderPDF.cState,
                                PurchaseOrderPDF.cZipcode)"></span><br>
                                        {{PurchaseOrderPDF.cCountry | titlecase}}<br>
                                        <span>Phone : {{PurchaseOrderPDF.cPhone}}</span><br>
                                        Email : {{PurchaseOrderPDF.cEmail}}
                                    </p>
                                </td>
                                <td style="width: 35%;vertical-align: top;">
                                    <h1 style="margin: 0 0 10px; font-size: 18px; text-align: right;font-weight: bolder;
                                ">{{PurchaseOrderPDF.statusId==1?'Bid Request':'Purchase Order'}}</h1>
                                    <table cellpadding="5" cellspacing="0" border="1" class="border-PDF"
                                        style="width: 100%; font-size: 14px; text-align: center; margin-bottom: 10px;">
                                        <tr style="background-color: #e9e9e9; ">
                                            <td style="width: 60%; font-size: 10px;padding: 3px !important;">
                                                <span style="font-size: 10px; font-family: 'Montserrat', sans-serif; ">
                                                    <strong>Order #</strong></span>

                                            </td>
                                            <td style="width: 40%; font-size: 10px;padding: 3px !important;">

                                                <span style="font-size: 10px; font-family: 'Montserrat', sans-serif; ">
                                                    <strong>Date</strong></span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <span style="font-size: 10px; font-family: 'Montserrat', sans-serif; ">
                                                    <strong>{{PurchaseOrderPDF.poNumber}}</strong></span>
                                            </td>
                                            <td>

                                                <span style="font-size: 10px; font-family: 'Montserrat', sans-serif; ">
                                                    {{PurchaseOrderPDF.orderDate| date: 'MM/dd/yyyy'}} </span>
                                            </td>
                                        </tr>
                                    </table>
                                    <table cellpadding="5" cellspacing="0" width="100%" style="border: 0;">
                                        <tr>
                                            <td style="text-align: center;border: 0;">
                                                <img src="./assets/image/barcode.jpg" style=" display: inline-block; width:80%;border: none;border-radius:unset;max-height: unset;
                                                height: unset;">
                                            </td>
                                        </tr>
                                    </table>

                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table cellpadding="0" cellspacing="0" style="width: 100%;border: none;margin-top:0;">
                            <tr>
                                <td style="width: 50%; padding-right: 10px;">
                                    <table class="border-PDF" cellpadding="5" cellspacing="0" border="1"
                                        style="width: 100%">
                                        <tr style="background-color: #e9e9e9;">
                                            <td style="text-align: left;font-size: 10px;">
                                                <span style="font-size: 10px; font-family: 'Montserrat', sans-serif; ">
                                                    <strong>Vendor:</strong> </span>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                style="text-align: left;font-size: 10px;line-height: 1.3;font-weight: 300 !important; font-family: 'Montserrat', sans-serif;">
                                                <span style="font-size: 10px; font-family: 'Montserrat', sans-serif; ">
                                                    {{PurchaseOrderPDF.vendorname}}<br /></span>
                                                <span style="font-size: 10px; font-family: 'Montserrat', sans-serif; "
                                                    [innerHtml]="this.sharedService.FormatAddress(PurchaseOrderPDF.vaddress,
                                                PurchaseOrderPDF.vaddress2, PurchaseOrderPDF.vCity, PurchaseOrderPDF.vState,
                                                PurchaseOrderPDF.vZipcode)">
                                                </span>
                                                <br />
                                                <span style="font-size: 10px; font-family: 'Montserrat', sans-serif; ">
                                                    {{PurchaseOrderPDF.vCountry | titlecase}}<br /></span>
                                                <span style="font-size: 10px; font-family: 'Montserrat', sans-serif; ">
                                                    <span>Phone:
                                                        {{!PurchaseOrderPDF.vPhone
                                                        ?'N/A':PurchaseOrderPDF.vPhone}}</span><br /></span>

                                                <span style="font-size: 10px; font-family: 'Montserrat', sans-serif; ">
                                                    Email:
                                                    {{!PurchaseOrderPDF.vEmail ?'N/A':PurchaseOrderPDF.vEmail}}<br />
                                                </span>
                                                <span style="font-size: 10px; font-family: 'Montserrat', sans-serif; ">
                                                    Fax: {{!PurchaseOrderPDF.vFax ?'N/A':PurchaseOrderPDF.vFax}}</span>
                                            </td>
                                        </tr>
                                    </table>
                                    <table class="border-PDF" cellpadding="5" cellspacing="0" border="1"
                                        style="width: 100%; margin-top:5px;">
                                        <tr>
                                            <td
                                                style="text-align: left;font-size: 10px;font-weight: 100; font-family: 'Montserrat', sans-serif;">
                                                <span
                                                    style="font-size: 10px; font-family: 'Montserrat', sans-serif;font-weight: bolder !important; ">Contact:
                                                    {{PurchaseOrderPDF.contactVendorname}} </span>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                                <td style="width: 50%; padding-left: 10px; vertical-align: top;">
                                    <table class="border-PDF" cellpadding="5" cellspacing="0" border="1"
                                        style="width: 100%">
                                        <tr style="background-color: #e9e9e9;">
                                            <td
                                                style="text-align: left;font-size: 10px; font-family: 'Montserrat', sans-serif;">
                                                <strong>Ship To:</strong>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td
                                                style="text-align: left;font-size: 10px;line-height: 1.3;font-weight: 300 !important; font-family: 'Montserrat', sans-serif;">
                                                <span style="font-size: 10px; font-family: 'Montserrat', sans-serif; ">
                                                    {{!PurchaseOrderPDF.shipname
                                                    ?'N/A':PurchaseOrderPDF.shipname}}<br />
                                                </span>
                                                <span style="font-size: 10px; font-family: 'Montserrat', sans-serif; "
                                                    [innerHtml]="this.sharedService.FormatAddress(PurchaseOrderPDF.shipaddress,
                                                PurchaseOrderPDF.shipaddress2, PurchaseOrderPDF.shipCity, PurchaseOrderPDF.shipState,
                                                PurchaseOrderPDF.ShipZipcode)">
                                                </span>
                                                <br />
                                                <span style="font-size: 10px; font-family: 'Montserrat', sans-serif; ">
                                                    {{PurchaseOrderPDF.shipCountry | titlecase}}<br />
                                                </span>
                                                <span style="font-size: 10px; font-family: 'Montserrat', sans-serif; ">
                                                    <span>Phone:
                                                        {{!PurchaseOrderPDF.shipPhone
                                                        ?'N/A':PurchaseOrderPDF.shipPhone}}</span><br /></span>

                                                <span style="font-size: 10px; font-family: 'Montserrat', sans-serif; ">
                                                    Email:
                                                    {{!PurchaseOrderPDF.shipEmail
                                                    ?'N/A':PurchaseOrderPDF.shipEmail}}<br />
                                                </span>
                                            </td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td>
                        <table cellpadding="0" cellspacing="0"
                            style="width: 100%;border-collapse: collapse;border: none; line-height: 12px; margin-top: 30px;margin-bottom: 20px;text-align: left;">
                            <p style="width:570px;margin-top: 50px; display: inline;line-height: 16px;
                            text-align: left;">
                                <span style="font-weight:bold">Vendor &nbsp; Notes: </span> &nbsp;
                                {{PurchaseOrderSummaryPDF.vendorNotes}}
                            </p>
                        </table>

                        <table style="width: 100%;border-collapse: collapse; margin-top: 10px;text-align: center; ">
                            <thead>
                                <th style="border: 1px solid #000; background: #ddd;">
                                    <b style="font-size: 10px; font-family: 'Montserrat', sans-serif;">Buyer</b>
                                </th>
                                <th style="border: 1px solid #000; background: #ddd;">
                                    <b style="font-size: 10px; font-family: 'Montserrat', sans-serif;">Payment Terms</b>
                                </th>
                                <th style="border: 1px solid #000; background: #ddd;">
                                    <b style="font-size: 10px; font-family: 'Montserrat', sans-serif;">FOB Pont</b>
                                </th>
                                <th style="border: 1px solid #000; background: #ddd;">
                                    <b style="font-size: 10px; font-family: 'Montserrat', sans-serif;">Shipping
                                        Terms</b>
                                </th>
                                <th style="border: 1px solid #000; background: #ddd;">
                                    <b style="font-size: 10px; font-family: 'Montserrat', sans-serif;">Ship VIa</b>
                                </th>
                                <th style="border: 1px solid #000; background: #ddd;">
                                    <b style="font-size: 10px; font-family: 'Montserrat', sans-serif;">Order
                                        Date</b>
                                </th>
                            </thead>
                            <tr>
                                <td
                                    style="border: 1px solid #000; font-size: 10px; font-family: 'Montserrat', sans-serif;">
                                    <span
                                        style="font-size: 10px; font-family: 'Montserrat', sans-serif;">{{PurchaseOrderPDF.buyername}}
                                    </span>
                                </td>
                                <td
                                    style="border: 1px solid #000; font-size: 10px; font-family: 'Montserrat', sans-serif;">
                                    <span
                                        style="font-size: 10px; font-family: 'Montserrat', sans-serif;">{{!PurchaseOrderPDF.paymentTerm
                                        ?'N/A':PurchaseOrderPDF.paymentTerm}}
                                    </span>
                                </td>
                                <td
                                    style="border: 1px solid #000; font-size: 10px;font-family: 'Montserrat', sans-serif;">
                                    <span
                                        style="font-size: 10px; font-family: 'Montserrat', sans-serif;">{{!PurchaseOrderPDF.Fobpoint
                                        ?'N/A':PurchaseOrderPDF.Fobpoint}}</span>

                                </td>
                                <td
                                    style="border: 1px solid #000; font-size: 10px;font-family: 'Montserrat', sans-serif;">
                                    <span
                                        style="font-size: 10px; font-family: 'Montserrat', sans-serif;">{{!PurchaseOrderPDF.shippingTerms
                                        ?'N/A':PurchaseOrderPDF.shippingTerms}}</span>

                                </td>
                                <td
                                    style="border: 1px solid #000; font-size: 10px;font-family: 'Montserrat', sans-serif;">
                                    <span
                                        style="font-size: 10px; font-family: 'Montserrat', sans-serif;">{{!PurchaseOrderPDF.shipVia
                                        ?'N/A':PurchaseOrderPDF.shipVia}}</span>

                                </td>
                                <td
                                    style="border: 1px solid #000; font-size: 10px;font-family: 'Montserrat', sans-serif;">
                                    <span style="font-size: 10px; font-family: 'Montserrat', sans-serif;">
                                        {{PurchaseOrderPDF.orderDate| date: 'MM/dd/yyyy'}}</span>

                                </td>
                            </tr>
                        </table>


                    </td>
                </tr>
            </table>
            <table class="strip-table"
                style="width: 100%;border: none;border-collapse: collapse; margin-top: 20px;text-align: center; max-height: auto;">
                <thead>
                    <tr class="table table-striped" style="background: #ddd;">
                        <td
                            style="border: 1px solid #fff;padding: 5px;border-bottom: 1px solid #000;font-weight: bolder !important;font-size: 10px;font-family: 'Montserrat', sans-serif;vertical-align: bottom;">

                            <span
                                style="font-size: 10px; font-family: 'Montserrat', sans-serif; font-weight: bolder !important;">
                                Item #</span>
                        </td>

                        <td
                            style="border: 1px solid #fff;padding: 5px;border-bottom: 1px solid #000;font-weight: bolder !important;font-size: 10px;font-family: 'Montserrat', sans-serif;vertical-align: bottom;">

                            <span
                                style="font-size: 10px; font-family: 'Montserrat', sans-serif; font-weight: bolder !important;">
                                Vendor Part #</span>
                        </td>
                        <td
                            style="border: 1px solid #fff;padding: 5px;border-bottom: 1px solid #000;font-weight: bolder !important;font-size: 10px;font-family: 'Montserrat', sans-serif;vertical-align: bottom;">

                            <span
                                style="font-size: 10px; font-family: 'Montserrat', sans-serif; font-weight: bolder !important;">Vendor
                                Part Name </span>
                        </td>
                        <td
                            style="border: 1px solid #fff;padding: 5px;border-bottom: 1px solid #000;font-weight: bolder !important;font-size: 10px;font-family: 'Montserrat', sans-serif;vertical-align: bottom;">

                            <span
                                style="font-size: 10px; font-family: 'Montserrat', sans-serif; font-weight: bolder !important;">Company
                                Ref #</span>
                        </td>

                        <td
                            style="border: 1px solid #fff;padding: 5px;border-bottom: 1px solid #000;font-weight: bolder !important;font-size: 10px;font-family: 'Montserrat', sans-serif;vertical-align: bottom;">

                            <span
                                style="font-size: 10px; font-family: 'Montserrat', sans-serif; font-weight: bolder !important;">
                                Unit Cost </span>
                        </td>
                        <td
                            style="border: 1px solid #fff;padding: 5px;border-bottom: 1px solid #000;font-weight: bolder !important;font-size: 10px;font-family: 'Montserrat', sans-serif;vertical-align: bottom;">

                            <span
                                style="font-size: 10px; font-family: 'Montserrat', sans-serif; font-weight: bolder !important;">Qty
                                Ordered </span>
                        </td>
                        <td
                            style="border: 1px solid #fff;padding: 5px;border-bottom: 1px solid #000;font-weight: bolder !important;font-size: 10px;font-family: 'Montserrat', sans-serif;vertical-align: bottom;">

                            <span
                                style="font-size: 10px; font-family: 'Montserrat', sans-serif; font-weight: bolder !important;">UOM
                            </span>
                        </td>
                        <td
                            style="border: 1px solid #fff;padding: 5px;border-bottom: 1px solid #000;font-weight: bolder !important;font-size: 10px;font-family: 'Montserrat', sans-serif;vertical-align: bottom;">

                            <span
                                style="font-size: 10px; font-family: 'Montserrat', sans-serif; font-weight: bolder !important;">Total
                                Cost</span>
                        </td>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let poitem of PurchaseOrderPDF.pOpartPDF;let poitemindex = index;"
                        style="page-break-after: always !important; page-break-inside :avoid !important; page-break-before: always !important;">
                        <td style='border: 1px solid #fff; padding: 0px;'>
                            <div style="font-size: 10px; font-family: 'Montserrat', sans-serif; ">{{poitemindex+1}}
                            </div>
                        </td>

                        <td style='border: 1px solid #fff; padding: 0px;'>

                            <div style="font-size: 10px; font-family: 'Montserrat', sans-serif; ">
                                {{!poitem.vendorpartnumber ?"N/A":poitem.vendorpartnumber}} </div>
                        </td>
                        <td style='border: 1px solid #fff; padding: 0px;'>

                            <div style="font-size: 10px; font-family: 'Montserrat', sans-serif; ">
                                {{!poitem.vendorpartname ?"N/A":poitem.vendorpartname}} </div>
                        </td>
                        <td style='border: 1px solid #fff; padding: 0px;'>

                            <div style="font-size: 10px; font-family: 'Montserrat', sans-serif; ">
                                {{!poitem.customerpartnumber ?"N/A":poitem.customerpartnumber}} </div>
                        </td>
                        <td class="nowrap1" style='border: 1px solid #fff; padding: 0px;'>

                            <div style="font-size: 10px; font-family: 'Montserrat', sans-serif; ">${{poitem.unitCost}}
                            </div>
                        </td>
                        <td style='border: 1px solid #fff; padding: 0px;'>
                            <div style="font-size: 10px; font-family: 'Montserrat', sans-serif; ">{{poitem.ordered}}
                            </div>
                        </td>
                        <td style='border: 1px solid #fff; padding: 0px;'>
                            <div style="font-size: 10px; font-family: 'Montserrat', sans-serif; ">
                                {{!poitem.uom ?"N/A":poitem.uom}}</div>
                        </td>
                        <td style='border: 1px solid #fff; padding: 0px;'>

                            <div style="font-size: 10px; font-family: 'Montserrat', sans-serif; ">
                                {{poitem.subtotalcost|currency}} </div>
                        </td>
                    </tr>
                </tbody>



                <tfoot>
                    <tr>
                        <td style='border: 1px solid #fff; padding: 0px;'></td>
                        <td style='border: 1px solid #fff; padding: 0px;'></td>
                        <td style='border: 1px solid #fff; padding: 0px;'></td>
                        <td style='border: 1px solid #fff; padding: 0px;'></td>
                        <td style='border: 1px solid #fff; padding: 0px;'></td>
                        <td style='border: 1px solid #fff; padding: 0px;'></td>
                        <td style='border: 1px solid #fff; padding: 0px;'></td>
                        <td style='border: 1px solid #fff; padding: 0px;'></td>
                    </tr>
                    <tr>
                        <td style='border: 1px solid #fff; padding: 0px;'><b>Total</b></td>
                        <td style='border: 1px solid #fff; padding: 0px;'></td>
                        <td style='border: 1px solid #fff; padding: 0px;'></td>
                        <td style='border: 1px solid #fff; padding: 0px;'></td>
                        <td style='border: 1px solid #fff; padding: 0px;'></td>
                        <td style='border: 1px solid #fff; padding: 0px;'></td>
                        <td style='border: 1px solid #fff; padding: 0px;'></td>
                        <td style='border: 1px solid #fff; padding: 0px;'>
                            <b>{{PurchaseOrderPDF.totalAmount|currency}}</b>
                        </td>
                    </tr>
                </tfoot>
            </table>
        </div>
    </div>
</div>