import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RoleManagementComponent } from './role-management/role-management.component';
import { UsersComponent } from './users.component';
import { UserPermissionsComponent } from './user-permissions/user-permissions.component';
import { UserManagementComponent } from './user-management/user-management.component';



const routes: Routes = [
  { path: 'users', 
  component: UsersComponent 
} , {
  path: 'roles',
  component: RoleManagementComponent,
},  {
  path: 'permissions',
  component: UserPermissionsComponent,
},  {
  path: 'user-management',
  component: UserManagementComponent,
},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class UsersRoutingModule { }
