import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ConstantsService } from 'src/app/constants.service';
import { GlobalChangeService } from 'src/app/services/global-change.service';
import { PartService } from 'src/app/services/part/part.service';
import { PurchaseOrderService } from 'src/app/services/purchaseOrder/purchase-order.service';
import { PurchaseOrder } from 'src/app/shared/purchaseOrder';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-receiving-list',
  templateUrl: './receiving-list.component.html',
  styleUrls: ['./receiving-list.component.css']
})
export class ReceivingListComponent implements OnInit {
  loader: boolean = false;
  companyId: number = 0;
  dataSource: MatTableDataSource<PurchaseOrder>;
  displayedColumns: string[] = ["menu", "issuedDate", "poNumber", "vendorName", "contactName", "EstArrDate", "status"];
  statuslist: any = [];
  poPartStatusList: any = [];
  userId: string = '';
  selected: string = 'All';
  searchText: string = '';
  start: number = 0;
  dataS: any = [];

  constructor(
    private POService: PurchaseOrderService,
    private constants: ConstantsService,
    private globalChangeService: GlobalChangeService,
    private partService: PartService,
    public datepipe: DatePipe,
    private router: Router
  ){
    this.getStatusList();
  }

  ngOnInit(): void{
    this.companyId = this.globalChangeService.getGlobalCompany();
    if (this.companyId > 0) {
      this.dataS =[];
      this.getRecieveList(false);
    } else {
      this.PopupModal(this.constants.APIError, this.constants.SelectCompany);
    }
    //on change function 
    this.globalChangeService.dropdownChange$.subscribe((data) => {
      this.companyId = data;
      this.dataS =[];
      this.getRecieveList(false);
    })
    this.userId = localStorage.getItem('userId');
  }

  getRecieveList(isUpdate) {
    const obj={
      companyId: this.companyId,
      statusIds: [0],
      vendorIds: [0],
      vendorPartNumber: '',
      fromDate: '',
      toDate: '',
      startIndex: isUpdate ? (this.start+25) : this.start,
      endIndex: 25,
      filterType: 5,
      searchText: this.searchText
    }
    this.POService.GetAllPurchaseOrderList(obj).subscribe((data: any) => {
      this.dataS = this.dataS.concat(data.dataList);
      this.dataS = this.removeDuplicates(this.dataS, 'id');
      this.dataSource = new MatTableDataSource(this.dataS);

      if (isUpdate) {
        this.updateIndex();
      }
    },
    error => {
      this.PopupModal(this.constants.APIError, error);
    })
  }

  removeDuplicates(myArray, Prop) {
    return myArray.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[Prop]).indexOf(obj[Prop]) === pos;
    });
  }

  updateIndex() {
    this.start = this.start + 25;
  }

  onScroll(e){
    const tableViewHeight = e.target.offsetHeight // viewport: ~500px
    const tableScrollHeight = e.target.scrollHeight // length of all table
    const scrollLocation = e.target.scrollTop; // how far user scrolled

    // If the user has scrolled within 200px of the bottom, add more data
    const buffer = 200;
    const limit = tableScrollHeight - tableViewHeight - buffer;
    if (scrollLocation > limit) {
      this.getRecieveList(true);
    }
  }

  onSearch(){
    this.start = 0;
    this.dataS = [];
    this.getRecieveList(false);
  }

  getStatusList() {
    this.POService.GetPOStatus().subscribe(
      (data: any) => {
        this.statuslist = data.filter(x=>x.isPurchaseOrder == true).sort((a, b) => a.poOrdering - b.poOrdering);
        this.poPartStatusList = data.filter(x=>x.isPOPart == true).sort((a, b) => a.poPartOrdering - b.poPartOrdering);
      }
    )
  }

  getStatusKey(id: any){
    if (this.statuslist.length > 0 && id>0) {
      const list = this.statuslist.filter(x => x.statusId == Number(id));
      if (list.length > 0) {
        return list[0].statusName;
      }
    }
    return '';
  }

  getPOById(row: any){
    this.router.navigate(['/receiving', row.id])
  }

  PopupModal(title: string, message: string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }
  
}
