<section class="content-section purchaseOrder pt-2" id="new-layout">
    <div class="loading" *ngIf="loader" style="z-index:1082">Loading&#8230;</div>
    <div class="page-title mb-2">
        <div class="bread-crumb">
            <a [routerLink]="['/adminDashboard/']">Home</a>
            <span class="mx-1">-</span>
            <span class="active">Receive Orders</span>
            <div *ngIf="permissionMessage!=''" class='btn-danger text-center'>{{permissionMessage}} </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="white-box1 cust-label">
                <div class="card-desc1">
                    <form [formGroup]="formSearch">
                        <div class="row detailSection">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-lg-2 col-md-6 mt-lg-0 mt-1">
                                        <div class="row d-flex align-items-center">
                                            <div class="col-md-3">
                                                <label class="customLabel" for="PO#">PO#</label>
                                            </div>
                                            <div class="col-md-8 col-11 pr-md-0 pr-3">
                                                <input type="text" placeholder="Enter PO#" class="customInput POSO"
                                                    [(ngModel)]="PoSearchTxt" formControlName="PoSearchTxt"
                                                    autocomplete="off" required maxlength="12"
                                                    [ngClass]="((!PoSearchTxt) && (SearchPOValidation)) ? 'validation_border': ''"
                                                    (keyup.enter)="searchByPO()">
                                            </div>
                                            <div class="col-md-1 col-1 pl-0">
                                                <i class="fas fa-search cpointer ml-1" (click)="searchByPO()"></i>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-md-6 mt-lg-0 mt-1">
                                        <div class="row d-flex align-items-center">
                                            <div class="col-md-4 d-flex align-items-center">
                                                <span class="ortext_order">OR</span>
                                                <span> <label class="customLabel" for="Vendor">Vendor</label></span>
                                            </div>
                                            <div class="col-md-8">
                                                <select [(ngModel)]="selectedVendorSearch" class="customInput"
                                                    formControlName='VendorSearchDDL'
                                                    [ngClass]="((!selectedVendorSearch) && (SearchDDLValidation)) ? 'validation_border': ''">
                                                    <option value=''>Select Vendor</option>
                                                    <option value='0'>All</option>
                                                    <option *ngFor="let item of vendorlist" [value]="item.id">
                                                        {{item.vendorName}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-md-6 mt-lg-0 mt-md-1 d-flex align-items-center">
                                        <label class="customLabel" for="Status" style="display: none">Status</label>
                                        <select [(ngModel)]="selectedStatusSearch" class="customInput"
                                            style="display: none" formControlName='StatusSearchDDL'
                                            [ngClass]="((!selectedStatusSearch) && (SearchDDLValidation)) ? 'validation_border': ''">
                                            <option value=''>Select Status</option>
                                            <option value='0'>All</option>
                                            <option *ngFor="let item of POstatusList" [value]="item.id">
                                                {{item.name}}
                                            </option>
                                        </select>
                                        <i class="fas fa-search cpointer ml-2" (click)="SearchByDropDown()"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="white-box1" [style.display]="RecOrderListShow==true ? 'block' : 'none'">
                <div class="white-box-title1">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="customHeading mt-3 mb-2">
                                Receive Orders<i class="fa fa-angle-{{ListAngle}} cpointer ml-2" aria-hidden="true"
                                    (click)="ListDivShowHide()"></i>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card-desc" [style.display]="ListDiv==true ? 'block' : 'none'">
                    <div class="table-responsive">
                        <table id="datatable" class="display table clearfix" class="row-border hover" datatable
                            [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                            <thead>
                                <tr>
                                    <th class="coloumn-hide"></th>
                                    <th (click)="SortTableHeader('companyName')">Company<span id="companyName"
                                            class="sorting1"></span></th>
                                    <th (click)="SortTableHeader('purchaseOrderPrefix')">PO#<span
                                            id="purchaseOrderPrefix" class="sorting1"></span></th>
                                    <th (click)="SortTableHeader('poStatus')">Status<span id="poStatus"
                                            class="sorting1"></span></th>
                                    <th (click)="SortTableHeader('vendorName')">Vendor<span id="vendorName"
                                            class="sorting1"></span></th>
                                    <th (click)="SortTableHeader('dateSchedule')">Date Scheduled<span id="dateSchedule"
                                            class="sorting1"></span></th>
                                    <th (click)="SortTableHeader('paymentTermName')">Payment Terms<span
                                            id="paymentTermName" class="sorting1"></span></th>
                                    <th class="width-75" (click)="SortTableHeader('fObPointName')">FOB Point<span
                                            id="fObPointName" class="sorting1"></span></th>
                                    <th class="width-75" (click)="SortTableHeader('shippingTerm')">Shipping Terms<span
                                            id="shippingTerm" class="sorting1"></span></th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody *ngIf="datalist?.length != 0">
                                <tr *ngFor="let data of datalist">
                                    <td class="coloumn-hide">{{data.id}}</td>
                                    <td>{{data.companyName}}</td>
                                    <td>{{data.statusId=="1"?'BR'+'-'+data.purchaseOrderPrefix:'PO'+'-'+data.purchaseOrderPrefix}}
                                    </td>
                                    <td>{{data.poStatus}}</td>
                                    <td>{{data.vendorName}}</td>
                                    <td>{{data.dateSchedule==""?"":data.dateSchedule | date: 'MMM-dd-yyyy'}}</td>
                                    <!-- <td>{{GetStatusText(data.statusId)}}</td> -->
                                    <td>{{data.paymentTermName}}</td>
                                    <td>{{data.fObPointName}}</td>
                                    <td>{{data.shippingTerm}}</td>
                                    <td>
                                        &nbsp;&nbsp;&nbsp;<a class="green-text"
                                            *ngIf="rolePermissionsList && rolePermissionsList[0].isUpdate"><i
                                                class="fas fa-file-download"
                                                (click)="SearchByPOList(data.purchaseOrderPrefix)"></i></a>
                                    </td>
                                </tr>
                            </tbody>
                            <tbody *ngIf="datalist==null">
                                <tr class="c1">
                                    <td colspan="10" class="no-data-available">No data available in table</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

            </div>
            <hr>
            <div class="white-box1 purchasepage" id="PoDiv">
                <div class="card-desc1">

                    <form [formGroup]="form">
                        <div class="row detailSection">

                            <div class="col-md-12 container">
                                <div class="row">
                                    <div class="col-lg-4 col-md-12">
                                        <div class="row d-flex align-items-center required">
                                            <div class="col-lg-4 col-md-2 trp">
                                                <label class="customLabel">Vendor</label>
                                            </div>
                                            <div class="col-lg-8 col-md-10">
                                                <select class="customInput" name='vendorId'
                                                    [(ngModel)]="selectedVendorValue" formControlName='vendorId'
                                                    (change)="ChangeVendorDropdow($event)"
                                                    [ngClass]="selectedVendorValue=='' && validation ? 'validation_border': ''">
                                                    <option value=''>Select Vendor</option>
                                                    <option *ngFor="let item of vendorlist" [value]="item.id">
                                                        {{item.vendorName}}
                                                    </option>
                                                </select>
                                                <!-- <div *ngIf="selectedVendorValue=='' && validation"
                                                    class="text-danger input-error" style="font-size:10px;">
                                                    Select Vendor.</div> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="pl-3 mt-lg-0 mt-1 d-flex align-items-center mh_27">
                                        <label class="customLabel ws-nobreak">Ship To</label>
                                        <br />

                                        <div class="d-flex align-items-center ml-1">
                                            <input class="mr-2 PoRec" type="radio" id="shipcustomer" name="ship"
                                                value="customer" formControlName='ship'
                                                (change)="ShipType($event.target.value)" formcont>
                                            <label class="customLabel" for="customer">Customer</label>
                                            <input class="ml-3 mr-2 PoRec" type="radio" formControlName='ship'
                                                id="companyLocation" name="ship" value="companyLocation"
                                                (change)="ShipType($event.target.value)">
                                            <label for="companyLocation" class="customLabel ws-nobreak">Company
                                                Location</label>
                                        </div>

                                    </div>
                                    <div class="col-lg-3 col-md-12 mt-lg-0 mt-1" *ngIf="Shipcustomer==true">
                                        <div class="d-flex d-width align-items-center required">
                                            <div class="row">
                                                <div class="col-lg-4 col-md-2 trp">
                                                    <label class="customLabel label-right">Customer</label>
                                                </div>
                                                <div class="col-lg-8 col-md-10 pl-1 borderNone setposition">
                                                    <select class="customInput" name='customerId'
                                                        [(ngModel)]="selectedCustomerValue" formControlName='customerId'
                                                        (change)="changeDDLCustomer($event)" required>
                                                        <option value=''>Select Customer</option>
                                                        <option *ngFor="let item of customerList" [value]="item.id">
                                                            {{item.customerName}}
                                                        </option>
                                                    </select>
                                                    <!-- <div *ngIf="selectedCustomerValue=='' && validation"
                                                        class="text-danger input-error" style="font-size:10px;">
                                                        Select Customer.</div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-3 col-md-12 mt-lg-0 mt-1" *ngIf="Shipcompany==true">
                                        <div class="d-flex d-width align-items-center required">
                                            <div class="row">
                                                <div class="col-lg-4 col-md-2 trp">
                                                    <label class="customLabel label-right">Company</label>
                                                </div>
                                                <div class="col-lg-8 col-md-10 pl-1 borderNone setposition"
                                                    [ngClass]="CompanyValue==undefined||CompanyValue==0 && validation ? 'validation_border': ''">
                                                    <ngx-dropdown-treeview-select [config]="config"
                                                        class="dropdown-company customInput-dropdown"
                                                        [items]="userGroupObjects" [(value)]="CompanyValue"
                                                        (valueChange)="companyChangeValue($event)">
                                                    </ngx-dropdown-treeview-select>
                                                    <!-- <div *ngIf="CompanyValue==undefined||CompanyValue==0 && validation"
                                                        class="text-danger input-error" style="font-size:10px;">
                                                        Select Company.</div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="col-md-12 container">
                                <div class="row mt-1">
                                    <div class="col-md-4 mb-md-0 mb-1">
                                        <div class="fetchedDetails">
                                            <ul>
                                                <li class="d-flex"><label>Vendor
                                                        Name:</label><label>{{vendordata.vendorName}}</label>
                                                </li>
                                                <li class="d-flex"><label>Vendor Address:</label>
                                                    <label [innerHtml]="this.sharedService.FormatAddress(vendordata.addressLine1,
                                                    vendordata.addressLine2, vendordata.city, vendordata.stateName,
                                                    vendordata.zipCode)">
                                                    </label>
                                                </li>
                                                <li class="d-flex"><label>Country:</label>
                                                    <label>{{vendordata.name}}</label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-md-4" *ngIf="Shipcompany==true">
                                        <div class="fetchedDetails">
                                            <ul>
                                                <li class="d-flex"><label>Company Name:</label>
                                                    <label>{{CompanyDetails.companyName}}</label>
                                                </li>
                                                <li class="d-flex"><label>Company Address:</label>
                                                    <label [innerHtml]="this.sharedService.FormatAddress(CompanyDetails.addressLine1,
                                                    CompanyDetails.addressLine2, CompanyDetails.city, CompanyDetails.stateName,
                                                    CompanyDetails.zipCode)">
                                                    </label>
                                                </li>
                                                <li class="d-flex"><label>Country:</label>
                                                    <label>{{CompanyDetails.countryName}}</label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-md-4" *ngIf="Shipcustomer==true">
                                        <div class="fetchedDetails">
                                            <ul>
                                                <li class="d-flex"><label>Customer Name:</label>
                                                    <label>{{customerDetails.customerName}}</label>
                                                </li>
                                                <li class="d-flex"><label>Customer Address:</label>
                                                    <label [innerHtml]="this.sharedService.FormatAddress(customerDetails.addressLine1,
                                                    customerDetails.addressLine2, customerDetails.city, customerDetails.stateName,
                                                    customerDetails.zipCode)">
                                                    </label>
                                                </li>
                                                <li class="d-flex"><label>Country:</label>
                                                    <label>{{customerDetails.countryName}}</label>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-md-4" *ngIf="Shipcompany==false && Shipcustomer==false">
                                        <div class="fetchedDetails">
                                            <ul>
                                                <li class="d-flex"><label>Company Name:</label> </li>
                                                <li class="d-flex"><label>Company Address:</label></li>
                                                <li class="d-flex"><label>Country:</label></li>
                                            </ul>
                                        </div>
                                    </div>

                                </div>
                            </div>

                            <div class="col-md-12 container">
                                <div class="row mt-1">
                                    <div class="col-lg-4 col-md-12">
                                        <div class="row d-flex align-items-center">
                                            <div class="col-lg-4 col-md-2 trp required">
                                                <label class="customLabel">Buyer</label>
                                            </div>
                                            <div class="col-lg-8 col-md-10">
                                                <select class="customInput" name='buyerId' [(ngModel)]="SelectedBuyer"
                                                    formControlName='buyerId' (change)="changebuyer($event)"
                                                    [ngClass]="SelectedBuyer=='' && validation ? 'validation_border': ''">
                                                    <option value=''>Select Buyer</option>
                                                    <option *ngFor="let item of buyerList" [value]="item.userId">
                                                        <!-- {{item.name}}  -->
                                                        {{item.name}}
                                                    </option>
                                                </select>
                                                <!-- <div *ngIf="SelectedBuyer=='' && validation" class="text-danger input-error"
                                                    style="font-size:10px;">
                                                    Select Buyer.</div> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                        <div class="row d-flex align-items-center">
                                            <div class="col-lg-4 col-md-2 trp">
                                                <label class="customLabel">{{selectedstatusvalue == 1 ? 'BR #' : 'PO
                                                    #'}}</label>
                                            </div>
                                            <div class="col-lg-8 col-md-10">
                                                <span class="dollar" style="bottom:2px;"></span>
                                                <input type="text" class="customInput"
                                                    [(ngModel)]="EditPOPrefixNumber==null?'N/A':EditPOPrefixNumber"
                                                    formControlName="PO" disabled autocomplete="off" maxlength="100">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                        <div class="row d-flex align-items-center">
                                            <div class="col-lg-4 col-md-2 trp">
                                                <label class="customLabel">Ship Date</label>
                                            </div>
                                            <div class="col-lg-8 col-md-10">
                                                <input type="date" class="customInput" [(ngModel)]="DateScheduled"
                                                    formControlName='DateScheduled' min="{{DSMinDate}}">
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-1">
                                    <div class="col-lg-4 col-md-12">
                                        <div class="row d-flex align-items-center">
                                            <div class="col-lg-4 col-md-2 trp required">
                                                <label class="customLabel">Status</label>
                                            </div>
                                            <div class="col-lg-8 col-md-10">
                                                <select class="customInput" formControlName='Status'
                                                    [(ngModel)]="selectedstatusvalue" id="statusId"
                                                    [ngClass]="selectedstatusvalue=='' && validation ? 'validation_border': ''">
                                                    <option value=''>Select Status</option>
                                                    <option *ngFor="let item of POstatusList" [value]="item.id">
                                                        {{item.name}}
                                                    </option>
                                                </select>
                                                <!-- <div *ngIf="selectedstatusvalue=='' && validation"
                                                    class="text-danger input-error" style="font-size:10px;">
                                                    Select Status.</div> -->
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                        <div class="row d-flex align-items-center">
                                            <div class="col-lg-4 col-md-2 trp">
                                                <label class="customLabel">Payment Terms</label>
                                            </div>
                                            <div class="col-lg-8 col-md-10">
                                                <select class="customInput" formControlName='paymenttermId'
                                                    [(ngModel)]="PaymentTerms" id="paymenttermId">
                                                    <option value=''>Select Payment Terms</option>
                                                    <option *ngFor="let item of termsData" [value]="item.id">
                                                        {{item.name}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                        <div class="row d-flex align-items-center">
                                            <div class="col-lg-4 col-md-2 trp">
                                                <label class="customLabel">FOB Point</label>
                                            </div>
                                            <div class="col-lg-8 col-md-10">
                                                <select class="customInput" formControlName='FOBPoint'
                                                    [(ngModel)]="selectedFobPointValue" id="FobPointValue">
                                                    <option value=''>Select FOB</option>
                                                    <option *ngFor="let item of FobPoint" [value]="item.id">
                                                        {{item.name}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row mt-1">
                                    <div class="col-lg-4 col-md-12">
                                        <div class="row d-flex align-items-center">
                                            <div class="col-lg-4 col-md-2 trp">
                                                <label class="customLabel">Shipping Terms</label>
                                            </div>
                                            <div class="col-lg-8 col-md-10">
                                                <select class="customInput" formControlName='ShippingTerms'
                                                    (ngModelChange)="changeShipping($event)"
                                                    [(ngModel)]="selectedshippingValue" id="ShippingTerms">
                                                    <option value=''>Select Shipping</option>
                                                    <option *ngFor="let item of shippingData" [value]="item.id">
                                                        {{item.name}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-12">
                                        <div class="row d-flex align-items-center">
                                            <div class="col-lg-4 col-md-2 trp">
                                                <label class="customLabel">Carrier</label>
                                            </div>
                                            <div class="col-lg-8 col-md-10">
                                                <select class="customInput" formControlName='carrier'
                                                    [(ngModel)]="carrier" id="carrier">
                                                    <option value=''>Select Carrier</option>
                                                    <option *ngFor="let item of carrierData" [value]="item.id">
                                                        {{item.name}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-1">
                                    <div class="col-lg-2 col-md-2 trp">
                                        <label class="customLabel padding-r">Vendor Notes:</label>
                                    </div>
                                    <div class="col-lg-10 col-md-10">
                                        <input type="area" class="customInput customDescription customDescription2"
                                            placeholder="Enter Vendor Notes" formControlName='Notes' [(ngModel)]="Note">
                                    </div>
                                </div>
                                <div class="row d-flex align-items-center mt-1">
                                    <div class="col-lg-2 col-md-2 trp">
                                        <label class="customLabel padding-r">Internal Notes:</label>
                                    </div>
                                    <div class="col-lg-10 col-md-10">
                                        <input type="area" class="customInput customDescription customDescription2"
                                            placeholder="Enter Internal Notes" formControlName='internalNotes'
                                            [(ngModel)]="internalNotes">
                                    </div>
                                </div>
                                <div class="row mt-1">
                                    <div class="col-lg-4 col-md-12">
                                        <div class="row d-flex align-items-center">
                                            <div class="col-lg-4 col-md-2 trp">
                                                <label class="customLabel">Location Name<span
                                                    style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-8 col-md-10">
                                                <select class="customInput pl-1px" required name='LocationsId'
                                                    formControlName="LocationsId" [(ngModel)]="SelectedLocation"
                                                    (change)="changeLocation($event)"
                                                    [ngClass]="SelectedLocation=='' && validation ? 'validation_border': ''">
                                                    <option value=''>Select Location Name</option>
                                                    <option *ngFor="let item of locationNameList" [value]="item.id">
                                                        {{item.locationName}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-md-12">
                                        <div class="row d-flex align-items-center">
                                            <div class="col-lg-4 col-md-2 trp">
                                                <label class="customLabel">Location<span
                                                    style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-8 col-md-10">
                                                <select class="customInput pl-1px" required name='partLocation'
                                                    formControlName="partLocation" [(ngModel)]="SelectedPartLocation"
                                                    [ngClass]="SelectedPartLocation=='' && validation ? 'validation_border': ''">
                                                    <option value=''>Select Default Part Location</option>
                                                    <option *ngFor="let item of locationLevelList"
                                                        [value]="item.levelsName">
                                                        {{item.levelsName}}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <hr class="mb-2 mt-2" style="border: 2px solid #cccbcb;">
                            </div>

                            <!-- Space For Add Part Html -->
                        </div>
                        <div [class]="EditRowTxtName=='editRow' ? 'table-responsive actTable' : 'table-responsive'">
                            <table class="table table-bordered">
                                <tr>
                                    <td>Item #</td>
                                    <td>Date Scheduled</td>
                                    <td>Qty</td>
                                    <td>Rcvd</td>
                                    <td>Company Part #</td>
                                    <td class="VPName">Vendor Part Name</td>
                                    <td>Vendor Part #</td>
                                    <td>UOM</td>
                                    <td>Type</td>
                                    <td>Service #</td>
                                    <td>Part SO</td>
                                    <td>Product SO</td>
                                    <td>eComm</td>
                                    <td>

                                        <div class="position-relative fix-width-tooltips">
                                            <span class="tooltipWrapCost">Unit Cost</span>
                                            <span class="tooltips" style="visibility: hidden"></span>

                                        </div>
                                        <div class="position-relative">
                                            <span class="tooltipWrapCost tooltip-icon"><i
                                                    class="fa fa-info-circle fabnew"
                                                    [style.color]="this.discountProp == 0 ? 'black' : 'red'"
                                                    style="right:4px"></i></span>
                                            <span class="tooltips" style="top:-34px">
                                                {{this.discountProp == 0 ? 'No Additional Discount' : 'Additional
                                                Discount : ' + this.discountProp + '%'}}


                                            </span>
                                        </div>
                                    </td>
                                    <td>Total</td>
                                    <td style="display: none;"></td>
                                </tr>

                                <ng-container *ngFor="let parts of SpecialPartList; let i = index">
                                    <tr [ngClass]="parts.isCustompart==true ? 'Gray' : ''">
                                        <td [style.background-color]="parts.isCustompart==false ? parts.bcolor : ''">
                                            {{i+1}}</td>
                                        <td>
                                            <span style="white-space: nowrap;"
                                                *ngIf="Editlable==true||normalShow==true || RowEdit==false">
                                                {{!parts.dateschedule ? "N/A":
                                                parts.dateschedule|date:"MMM-dd-yyyy"}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="parts.isCustompart == false">
                                                <span *ngIf="normalShow==true || RowEdit==false"
                                                    (click)="ShowItemModel(itemModel, parts.id, parts.tracking)"
                                                    class="cpointer clink">{{parts.qty}}</span>
                                            </span>
                                            <span *ngIf="parts.isCustompart == true">
                                                <span *ngIf="normalShow==true || RowEdit==false">{{parts.qty}}</span>
                                            </span>

                                        </td>
                                        <td>
                                            <span *ngIf="normalShow==true || RowEdit==false">
                                                <span *ngIf="parts.isCustompart == false"> {{parts.recQty}}</span>

                                            </span>
                                        </td>
                                        <td>
                                            <span
                                                *ngIf="normalShow==true || RowEdit==false">{{parts.custumerPartNumber}}</span>
                                        </td>
                                        <td>
                                            <div class="d-flex justify-content-center">
                                                <div class="position-relative fix-width-tooltip">
                                                    <span class="tooltipWrap"
                                                        *ngIf="normalShow==true || RowEdit==false">{{!parts.vendorPartName
                                                        ?
                                                        "N/A" : parts.vendorPartName}}</span>
                                                    <span class="tooltips">{{!parts.vendorPartName ? "N/A" :
                                                        parts.vendorPartName}}</span>

                                                </div>
                                                <div class="position-relative">
                                                    <span class="tooltipWrap tooltip-icon"
                                                        *ngIf="normalShow==true || RowEdit==false"><i
                                                            class="fa fa-info-circle"></i></span>
                                                    <span class="tooltips">Company Part Name :
                                                        {{!parts.custumerPartName ? "N/A" :
                                                        parts.custumerPartName}}<br>Description :
                                                        {{!parts.description ? "N/A" : parts.description}}</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <span *ngIf="normalShow==true || RowEdit==false">{{!parts.vendorpartNumber ?
                                                "N/A":parts.vendorpartNumber}}</span>

                                        </td>
                                        <td> <span
                                                *ngIf="normalShow==true || RowEdit==false">{{!parts.unitOfMeasurementName
                                                ? "N/A" : parts.unitOfMeasurementName}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="normalShow==true || RowEdit==false">
                                                {{!parts.Type ? "N/A":parts.Type}}
                                            </span>
                                        </td>
                                        <td>
                                            <span *ngIf="normalShow==true || RowEdit==false">{{!parts.service ?
                                                "N/A":parts.service}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="normalShow==true || RowEdit==false">{{!parts.partOrder ?
                                                "N/A":parts.partOrder}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="normalShow==true || RowEdit==false">{{!parts.productOrder ?
                                                "N/A":parts.productOrder}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="parts.isEcommOrder==true"><i class="fa fa-check text-success"
                                                    aria-hidden="true"></i></span>
                                        </td>
                                        <td>
                                            <span
                                                *ngIf="normalShow==true || RowEdit==false">${{parts.vendorParCost}}</span>
                                        </td>
                                        <td>
                                            <span *ngIf="parts.isCustompart == false">
                                                {{parts.vendorParCost*parts.qty | currency}}
                                            </span>
                                        </td>
                                    </tr>
                                    <ng-container *ngFor="let datasub of parts.PartList; let inner = index">
                                        <tr>
                                            <td [style.background-color]="datasub.bcolor">
                                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{i+1}}.{{inner+1}}</td>
                                            <td>
                                                <span style="white-space: nowrap;"
                                                    *ngIf="Editlable==true||normalShow==true || RowEdit==false">
                                                    {{!datasub.dateschedule ? "N/A":
                                                    datasub.dateschedule|date:"MMM-dd-yyyy"}}</span>
                                            </td>
                                            <td>
                                                <span *ngIf="normalShow==true || RowEdit==false"
                                                    (click)="ShowItemModel(itemModel, datasub.id, datasub.tracking)"
                                                    class="cpointer clink">{{datasub.qty}}</span>
                                            </td>
                                            <td>
                                                <span
                                                    *ngIf="normalShow==true || RowEdit==false">{{datasub.recQty}}</span>
                                            </td>
                                            <td>
                                                <span
                                                    *ngIf="normalShow==true || RowEdit==false">{{datasub.custumerPartNumber}}</span>
                                            </td>
                                            <td>
                                                <div class="d-flex justify-content-center">
                                                    <div class="position-relative fix-width-tooltip">
                                                        <span class="tooltipWrap"
                                                            *ngIf="normalShow==true || RowEdit==false">{{!datasub.vendorPartName
                                                            ?
                                                            "N/A" : datasub.vendorPartName}}</span>
                                                        <span class="tooltips">{{!datasub.vendorPartName ? "N/A" :
                                                            datasub.vendorPartName}}</span>

                                                    </div>
                                                    <div class="position-relative">
                                                        <span class="tooltipWrap tooltip-icon"
                                                            *ngIf="normalShow==true || RowEdit==false"><i
                                                                class="fa fa-info-circle"></i></span>
                                                        <span class="tooltips">Company Part Name :
                                                            {{!datasub.custumerPartName ? "N/A" :
                                                            datasub.custumerPartName}}<br>Description :
                                                            {{!datasub.description ? "N/A" :
                                                            datasub.description}}</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <span
                                                    *ngIf="normalShow==true || RowEdit==false">{{!datasub.vendorpartNumber
                                                    ?
                                                    "N/A":datasub.vendorpartNumber}}</span>

                                            </td>
                                            <td> <span
                                                    *ngIf="normalShow==true || RowEdit==false">{{!datasub.unitOfMeasurementName
                                                    ? "N/A" : datasub.unitOfMeasurementName}}</span>
                                            </td>
                                            <td>
                                                <span *ngIf="normalShow==true || RowEdit==false">
                                                    {{!datasub.Type ? "N/A":datasub.Type}}
                                                </span>
                                            </td>
                                            <td>
                                                <span *ngIf="normalShow==true || RowEdit==false">{{!datasub.service ?
                                                    "N/A":datasub.service}}</span>
                                            </td>
                                            <td>
                                                <span *ngIf="normalShow==true || RowEdit==false">{{!datasub.partOrder ?
                                                    "N/A":datasub.partOrder}}</span>
                                            </td>
                                            <td>
                                                <span *ngIf="normalShow==true || RowEdit==false">{{!datasub.productOrder
                                                    ?
                                                    "N/A":datasub.productOrder}}</span>
                                            </td>
                                            <td>
                                                <span *ngIf="datasub.isEcommOrder==true"><i
                                                        class="fa fa-check text-success" aria-hidden="true"></i></span>
                                            </td>
                                            <td>
                                                <span
                                                    *ngIf="normalShow==true || RowEdit==false">${{datasub.vendorParCost}}</span>
                                            </td>
                                            <td>
                                                {{datasub.vendorParCost*datasub.qty | currency}}</td>
                                        </tr>
                                    </ng-container>
                                </ng-container>
                                <tfoot>
                                    <tr>
                                        <td><b>Total</b></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td></td>
                                        <td><b>{{SubTotalVendorCost|currency}}</b></td>

                                        <td style="display: none;"></td>

                                    </tr>
                                </tfoot>
                            </table>
                        </div>
                        <div class="submit-from text-right mt-3" [style.display]="UpdateClear==true ? 'block' : 'none'">
                            <button type="button" *ngIf="rolePermissionsList && rolePermissionsList[0].isInsert"
                                class="btn blue-bg round-btn" (click)="UpdateConfirmPO()"><i class="fas fa-save"
                                    aria-hidden="true"></i>Update</button>
                            <!-- <button class="btn darkred-bg round-btn" *ngIf="clearbutton==true"
                                (click)="resetvendorData()"><i class="fas fa-redo" aria-hidden="true"></i>
                                Clear </button>
                            <button class="btn darkred-bg round-btn" *ngIf="clearbutton==false" (click)="PartBack()"><i
                                    class="fas fa" aria-hidden="true"></i>
                                Exit </button> -->
                            <button type="button" class="btn darkred-bg round-btn"
                                (click)="DiscrdProduct(discardItem)"><i class="fas fa-sign-out-alt"
                                    aria-hidden="true"></i>
                                Exit </button>

                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</section>

<div class="modal" id="modalNoPart">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-header info-popup-header p-2">
                <h6 class="modal-title info-popup-title sweet-heading"></h6>
                <button type="button" class="close btn alert_modal" (click)="noPart()" data-dismiss="modal">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body m-0">
                <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="">
                    <h1>Alert</h1>
                    <p>There are no part in selected vendor.</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn m-0" (click)="noPart()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<ng-template #completeModel let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg sweet-heading"></h4>
    <button type="button" class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body">
        <div class="thank-you-pop">
            <img src="./assets/image/popup-alert.png" alt="">
            <h1>Confirmation</h1>
            <p class="mb-0">All items have been received.<br>Do you want to complete this Purchase Order?</p>
        </div>
    </div>
    <div class="modal-footer sweet-footer text-center">
        <button type="button" class="btn green-bg round-btn m-0 mr-1" (click)="UpdateCompletePO()">Yes</button>
        <button type="button" class="btn red-bg round-btn m-0" (click)="UpdatePurchaseOrder()">No</button>
    </div>
</ng-template>

<ng-template #content let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg sweet-heading"></h4>
    <button type="button" class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body">
        <div class="thank-you-pop">
            <img src="./assets/image/popup-alert.png" alt="">
            <h1>Confirmation</h1>
            <p class="mb-0">{{deleteDetail}}</p>
        </div>
    </div>
    <div class="modal-footer sweet-footer text-center">
        <button type="button" class="btn green-bg round-btn m-0 mr-1"
            (click)="deleteItemStatus(deletedItem); c('Close click')">Yes</button>
        <button type="button" class="btn red-bg round-btn m-0" (click)="c('Close click')">No</button>
    </div>
</ng-template>

<ng-template #contentPart let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg sweet-heading"></h4>
    <button type="button" class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body">
        <div class="thank-you-pop">
            <img src="./assets/image/popup-alert.png" alt="">
            <h1>Confirmation</h1>
            <p class="mb-0">{{deleteDetail}}</p>
        </div>
    </div>
    <div class="modal-footer sweet-footer text-center">
        <button type="button" class="btn green-bg round-btn m-0 mr-1"
            (click)="DeletePart(); c('Close click')">Yes</button>
        <button type="button" class="btn red-bg round-btn m-0" (click)="c('Close click')">No</button>
    </div>
</ng-template>

<ng-template #itemModel let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg alert_heading">{{popCustPartName}}</h4>
    <!-- <button class="close btn" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button> -->
    <div class="modal-body">
        <table class="table table-bordered text-left">
            <tr>
                <th style="width: 25%;">Company Part #</th>
                <td style="width: 75%; font-weight: normal;">{{popCustPart}}</td>
            </tr>
            <tr>
                <th>Company Part Name</th>
                <td>{{popCustPartName}}</td>
            </tr>
            <tr>
                <th>Vendor Part #</th>
                <td>{{popVendorPart}}</td>
            </tr>
            <tr>
                <th>Vendor Part Name</th>
                <td>{{popVendorPartName}}</td>
            </tr>
            <tr>
                <th>Description</th>
                <td>{{popDesc}}</td>
            </tr>
            <tr>
                <th>Ordered Qty</th>
                <td>{{popOrdered}}</td>
            </tr>
        </table>
        <form [formGroup]="formPopup">
            <table class="table table-bordered text-left" style="min-width: 50%; width: 70%;">
                <thead>
                    <tr>
                        <th style="width: 45%;">&nbsp;Date Received</th>
                        <th style="width: 45%;"># Received</th>
                        <th style="width: 10%;"></th>
                        <th style="width: 20%;">Tracking</th>
                    </tr>
                </thead>
                <thead>
                    <tr>
                        <td>
                            <input type="date" [(ngModel)]="TxtItemDateReceived"
                                [ngClass]="((!formPopup.controls.ItemDateReceived.valid) && (PopupValidation)) ? 'form-control validation_border': 'form-control'"
                                formControlName="ItemDateReceived" autocomplete="off" max="{{PopupMaxDate}}">
                        </td>
                        <td>
                            <input type="text" [(ngModel)]="TxtItemReceived"
                                [ngClass]="((!formPopup.controls.ItemReceived.valid) && (PopupValidation)) ? 'form-control NumberOnly validation_border': 'form-control NumberOnly'"
                                formControlName="ItemReceived" autocomplete="off" maxlength="5">
                        </td>
                        <td style="vertical-align: middle;">
                            <i class="fas fa-save text-success" (click)="SavePopup(customPartId, uniquePartId, ItemTracking)"
                                style="font-size: 1rem;"></i>
                            <i class="fas fa-times-circle text-danger" (click)="ClearPopup()"
                                style="font-size: 1rem;"></i>
                        </td>

                    </tr>
                    <tr *ngFor="let item of ItemStatusList; let i = index">
                        <td>&nbsp;&nbsp;{{item.dateReceived | date: 'MMM-dd-yyyy'}}</td>
                        <td>&nbsp;&nbsp;{{item.qtyReceived}}</td>
                        <td><i class="fas fa-edit" (click)="editItemStatus(item.rowNo)"></i>&nbsp;&nbsp;<i
                                class="fas fa-trash-alt" (click)="deleteItemStatusConfirm(item.rowNo,content)"></i>
                        </td>
                        <td><label class="customLabel" style="margin-left: 8px;">
                            <span class="tooltipWrap tooltip-icon"
                                    style="display:inline;"><i class="fa fa-info-circle"></i></span>
                                <span class="tooltipsSerial">
                                    <ng-container *ngIf="item.receiveSerials != undefined else elseBlock1">
                                        <ng-container *ngIf="item.receiveSerials.length > 0 else elseBlock">
                                            <span *ngFor="let dynamic of item.receiveSerials; let i = index; let isLast=last;"
                                            style="color:white">
                                            {{dynamic.serialNo == "" ? '-' : dynamic.serialNo}}{{dynamic.serialNo != "" ?
                                            isLast ? '' : ', ' : ''}}
                                        </span>
                                        </ng-container>
                                        <ng-template #elseBlock>None</ng-template>
                                    </ng-container>
                                    <ng-template #elseBlock1>{{item.invTracking == 3 ? 'None' : "No Tracking" }}</ng-template>
                                </span>
                            </label></td>
                    </tr>
                    <tr>
                        <td>&nbsp;&nbsp;<b>Total</b></td>
                        <td>&nbsp;&nbsp;<b>{{TotalReceivedQty}}</b></td>
                        <td></td>
                        <td></td>
                    </tr>
                </thead>
            </table>
            <p class="mb-0"><strong class="text-danger">{{PopupErrorMsg}}</strong></p>
        </form>
    </div>
    <div class="modal-footer text-center">
        <!-- <button type="button" class="btn roundBtn green-bg" (click)="DeletePart(); c('Close click')">Save</button> -->
        <button type="button" class="btn red-bg round-btn m-0" (click)="c('Close click')">Exit</button>
    </div>
</ng-template>


<div class="modal" id="myModal1">
    <div class="modal-dialog">
        <div class="modal-content">
            <h4 class="modal-title darkred-bg sweet-heading"></h4>
            <button type="button" class="close btn alert_modal" aria-label="Close" (click)="hidemodalpopup1()">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
                <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="">
                    <h1>Message</h1>
                    <p class="mb-0">Already added this part into list.</p>
                </div>
            </div>

            <div class="modal-footer sweet-footer pb-1 pt-1">
                <button type="button" class="btn red-bg round-btn m-0" (click)="hidemodalpopup1()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="myModal2">
    <div class="modal-dialog">
        <div class="modal-content">
            <h4 class="modal-title darkred-bg sweet-heading"></h4>
            <button type="button" class="close btn alert_modal" aria-label="Close" (click)="hidemodalpopup1()">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
                <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="">
                    <h1>Message</h1>
                    <p class="mb-0">Confirm Changes</p>
                </div>
            </div>

            <div class="modal-footer sweet-footer pb-1 pt-1">
                <button type="button" class="btn red-bg round-btn m-0" (click)="hidemodalpopup1()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="add_success">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="addsuccess()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Added Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="addsuccess()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="add_update">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="updateitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Update Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="updateitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="delete_success">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="deleteitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Deleted Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="deleteitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="wrong_msg">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="msgitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>Something went wrong</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="msgitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="no_record">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="norecorditem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>Record Not Found</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="norecorditem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<ng-template #discardItem let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg alert_heading sweet-heading"></h4>
    <button type="button" class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>

    <div class="modal-body p-1">
        <div class="thank-you-pop">
            <img src="./assets/image/popup-alert.png" alt="">
            <h1>Confirmation</h1>
            <p class="mt-1">{{deleteDetail}}</p>
        </div>
    </div>
    <div class="modal-footer sweet-footer text-center p-1">
        <button type="button" class="btn green-bg round-btn" (click)="gotoDashBoard(); c('Close click')">Yes</button>
        <button type="button" class="btn red-bg round-btn" (click)="c('Close click')">No</button>
    </div>
</ng-template>

<div class="modal" id="SerialModel" style="z-index: 1080">
    <div class="modal-dialog modal-dialog-scrollable" [ngClass]="(this.qtyLabel.length > 15) ? 'heightClass1': 'heightClass'">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading">{{isEdit == false ? 'Add' : 'Update'}} {{generalTracking == "1" ? "Serial" : "Lot"}} # to
                    {{inventoryQty}} Part(s)</h4>
                <button type="button" class="close btn alert_modal" (click)="CloseSerialModel()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <div class="row mt-1" *ngFor="let data of qtyLabel">
                        <div class="col-md-4">
                            <span class="customLabel">Part {{data.id}} :</span>
                        </div>
                        <div class="col-md-7">
                            <input type="text" class="customInput" [disabled]="IsSerialDisabled == true"
                                [(ngModel)]="data.serialNo" (blur)="changeValidation(data.id)"
                                [ngClass]="(data.valid == true) ? 'validation_border': ''" />

                        </div>
                        <div class="col-md-4">

                        </div>
                        <div class="col-md-7" style="color:red; 
                        text-align: left;">{{data.message}}</div>
                    </div>
                </div>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn blue-bg round-btn" (click)="SaveDetail(serialPartId, generalTracking)" style="width: 120px;
                padding: 4px 12px;"><i class="fas fa-save"
                                        aria-hidden="true"></i>{{isEdit == false ? 'Save' : 'Update'}}</button>

                <button type="button" class="btn red-bg round-btn padding" (click)="CloseSerialModel()">Exit</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="GeneralModel" style="z-index: 1080">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="CloseGeneralModel()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>{{SerialComment}}</p>
                </div>
            </div>
            <div class="modal-footer text-center">
                <button type="button" class="btn red-bg round-btn m-0" data-dismiss="modal"
                    (click)="CloseGeneralModel()">Exit</button>
            </div>
        </div>
    </div>
</div>
