import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../auth/auth.service';
import { ConstantsService } from '../constants.service';
import { PasswordStrengthValidator } from '../users/PasswordStrengthValidator';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  form: FormGroup;
  password: string;
  passworConfirm: string;
  validation: boolean = false;
  resetToken: any;
  CurrentState: string;
  email: any;
  PasswordNotUpdated:any;
  loader: boolean = false;
  Err: any;

  constructor(private route: ActivatedRoute,
    private router: Router,
    public authService: AuthService,
    private toastr: ToastrService,
    private constants: ConstantsService) {
    route.queryParams
      .subscribe((params) => {
        this.resetToken = params.token.replace(/\s/g, "+");
        this.email = params.email;       
      });
  }

  ngOnInit(): void {
    this.route.snapshot.routeConfig.path
    this.form = new FormGroup({
      'password': new FormControl(null, Validators.compose([
        Validators.required, Validators.minLength(8), PasswordStrengthValidator])),
      'passworConfirm': new FormControl(),
    });
  }
  changePassword() {
    this.validation=true;
    if (this.form.controls.password.value === undefined ||
      this.form.controls.password.value === null ||
      this.form.controls.password.value === '' ||
      this.form.controls.passworConfirm.value === undefined ||
      this.form.controls.passworConfirm.value === null ||
      this.form.controls.passworConfirm.value === '' ||
      this.password != this.passworConfirm
    ) {
      this.loader = false;
      return;
    }
    this.loader = true;
    this.authService.Updatepassword(this.email, this.password,this.resetToken).subscribe(
      data => {
       
        switch (data.statusCode) {
          case 200:
            this.CurrentState = 'Verified';
            $("#add_update").show();
            // this.toastr.success(this.constants.PasswordUpdateSuccessfully);
            this.clearPasswordDetails();
            this.router.navigate(['']);
            this.Err = "";
            break;
          case 400:
            this.Err = data.errorMessage;
          //  this.toastr.error(data.errorMessage);
            this.clearPasswordDetails();
            this.validation = false;
            break;
          default:
            $("#wrong_msg").show();
            this.Err = "";
            // this.toastr.error(this.constants.SomethingWentWrong);
        }
        this.loader=false;


      },
    
      err => {
        this.CurrentState = 'NotVerified';
      }
    );
  }


  clearPasswordDetails() {
    this.passworConfirm='';
    this.password='';
  
  }

  
  clearPasswordDetails1() {
    this.passworConfirm='';
    this.password='';
    this.Err = "";
  
  }
  Back()
  {
    this.router.navigate(['']);
  }

 
  updateitem() {
    $("#add_update").hide();
  }
  msgitem() {
    $("#wrong_msg").hide();
  }

}
