<mat-dialog-content>
    <div class="row m-0 dialogue-width">
        <div class="loading lodingtext" *ngIf="loader">
        </div>
        <form [formGroup]="emailTemp" class="w-100">
            <h2 mat-dialog-title class="p-0">Email</h2>


            <div class="row" *ngIf="ErrorMsg.length>0">
                <div class="col-12">
                    <span class="text-danger">{{ErrorMsg}}</span>
                </div>
            </div>
            <div class="row mt-3" *ngIf="!(emailObj.type==0)">
                <div class="col-12">
                    <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                        <mat-label>Email Template</mat-label>
                        <mat-select formControlName="eTemp" (selectionChange)="ChangeTemplate($event)" required>
                            <mat-option *ngFor="let tempitem of tempList" [value]="tempitem.id">
                                {{ tempitem.tempName }}</mat-option>
                        </mat-select>
                        <mat-error *ngIf="emailTemp.get('eTemp').hasError('required')">
                            This Field is Required!
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-12">
                    <mat-form-field appearance="outline" class="forShipping m-0 mt-12 p-0 w-100">
                        <mat-label>From</mat-label>
                        <input type="text" matInput formControlName="from" required>
                        <mat-error *ngIf="emailTemp.get('from').hasError('required')">
                            This Field is Required!
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-9 col-12 mb-md-0 mb-sm-3 mb-3" *ngIf="(showCc != true) || (showBcc != true)">
                    <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                        <mat-label>Send to</mat-label>
                        <input matInput type="text" placeholder="" formControlName="sendTo" required>
                        <mat-error *ngIf="emailTemp.get('sendTo').hasError('required')">
                            This Field is Required!
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-3 col-12 mb-md-0 mb-sm-3 mb-3 d-flex align-items-center gap-3">
                    <span class="cc-text" *ngIf="showCc != true" (click)="getCcInput()">CC:</span>
                    <span class="cc-text" *ngIf="showBcc != true" (click)="getBCcInput()">BCC:</span>
                </div>
                <div class="col-12" *ngIf="(showCc == true) && (showBcc == true)">
                    <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                        <mat-label>Send to</mat-label>
                        <input matInput type="text" placeholder="" formControlName="sendTo" required>
                        <mat-error *ngIf="emailTemp.get('sendTo').hasError('required')">
                            This Field is Required!
                        </mat-error>
                        <mat-error *ngIf="emailTemp.get('sendTo').hasError('valid')">
                            Invalid Email
                        </mat-error>
                    </mat-form-field>
                </div>
            </div>
            <div class="row mt-3" *ngIf="showCc == true">
                <div class="col-12">
                    <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                        <mat-label>CC:</mat-label>
                        <input matInput type="text" placeholder="" formControlName="txtEmailSendCC">
                    </mat-form-field>
                </div>
            </div>
            <div class="row mt-3" *ngIf="showBcc == true">
                <div class="col-12">
                    <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                        <mat-label>BCC:</mat-label>
                        <input matInput type="text" placeholder="" formControlName="txtEmailSendBCC">
                    </mat-form-field>
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-9 col-12 mb-md-0 mb-sm-3 mb-3">
                    <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                        <mat-label>Subject</mat-label>
                        <input matInput type="text" placeholder="Subject" formControlName="subject" required>
                        <mat-error *ngIf="emailTemp.get('subject').hasError('required')">
                            This Field is Required!
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="col-md-3 col-12 mb-md-0 mb-sm-3 mb-3">
                    <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                        <mat-label>Priority</mat-label>
                        <mat-select formControlName="priority">
                            <mat-option value="2">High</mat-option>
                            <mat-option value="0">Normal</mat-option>
                            <mat-option value="1">Low</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-12" *ngFor="let item of filesGet.value;let i = index;">
                    <a class="mr-2" type="button" (click)="viewImage(item.base64)">
                        <i class="fa fa-file-pdf-o" aria-hidden="true"></i>
                    </a>
                    <span class="mr-2">{{item.name}}</span>
                    <span class="mr-2" (click)="deleteFile(i)">
                        <mat-icon>cancel</mat-icon>
                    </span>
                </div>
            </div>
            <div class="row mt-2 edit-section">
                <div class="col-12">
                    <!-- <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                            <textarea matInput name="Message" cols="5" rows="5" placeholder="Enter Message"
                                [innerHtml]="getInnerHTML(emailTemp.value.message)"></textarea>
                        </mat-form-field> -->
                    <editor apiKey="{{GetAPIKey()}}" formControlName='message' [init]="{
                                                          menubar: false,
                                                          plugins:'anchor 
                                                          autolink 
                                                          charmap 
                                                          codesample 
                                                          emoticons 
                                                          image 
                                                          link 
                                                          lists 
                                                          searchreplace 
                                                          table 
                                                          wordcount',
                                                          toolbar:
                                                          'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat'
                                                        }"></editor>
                </div>
            </div>
            <div class="footer-btn d-flex justify-content-end gap-3">
                <button class="btn cancel-btn update-btn" (click)="Canel()">Cancel</button>
                <button class="btn save-btn update-btn" (click)="sendMail()"
                    [disabled]="!(this.emailTemp.valid)">Send</button>
            </div>
        </form>
    </div>
</mat-dialog-content>