import { Component, OnInit, ChangeDetectorRef, AfterViewInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { FormControl, FormGroup, } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstantsService } from 'src/app/constants.service';
import { SharedService } from 'src/app/services/shared.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
// import { TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { DatePipe } from '@angular/common';
import { GlobalChangeService } from '../services/global-change.service';
import Swal from 'sweetalert2';
import { CompanyService } from '../services/company/company.service';
import { PartService } from '../services/part/part.service';
declare var $: any

@Component({
  selector: 'app-inventory-below-minimum',
  templateUrl: './inventory-below-minimum.component.html',
  styleUrls: ['./inventory-below-minimum.component.css']
})
export class InventoryBelowMinimumComponent implements AfterViewInit, OnDestroy, OnInit {
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  rolePermissionsList: any;
  permissionMessage: string;
  invDiv: boolean = false;
  userGroupObjects_search = new Array();
  value_search: number;
  loader: boolean = false;
  ListShow: boolean = false;
  ListDiv: boolean = false;
  ListAngle: any;

  form: FormGroup;
  formSearch: FormGroup;
  companyId: string;
  data: any = {};
  // items_search: TreeviewItem[];
  companyList_search: any;

  userId: string;
  buttonValue: string;
  SearchValidation: boolean;
  searchType = '1';
  searchText: any;
  partId: number;
  partSearch = '';
  totalQty: number = 0;
  InvList = [];
  InvDetails = [];
  datalist: any;
  vendorSearch = '0';
  vendorListDDL: any;
  userQuestionUpdate = new Subject<string>();
  search = ''
  @ViewChild('input') input: ElementRef;
  now: Date;
  CustomerPONumber: any;
  VendorName: any;
  vendorName: any;
  VendorPartName: any;
  UpdateVendorPartName: any;
  UpdateCustomerPONumber: any;
  UpdateName: any;
  UpdateInvQty: any;
  UpdateQuantity: any;
  UpdateVendorName: any;
  UpdateInvServNo: any;
  UpdatedAssignQty: any;
  Quantity: any;
  Id: any;
  InvValidationUpdate: boolean;
  chkIsInv: boolean = false;
  InvValidation: boolean;
  viewLog: boolean = false;
  isPartval: boolean = true;
  InvType: string;
  InvQty: string;
  UpdateInvType: string;
  InvSno: any;
  partListDDL: any;
  settingDetailCSS: any;
  settingLogoImage: any;
  disableButton: boolean = false;
  items_search: any;

  // config = TreeviewConfig.create({
  //   hasAllCheckBox: true,
  //   hasFilter: true,
  //   hasCollapseExpand: true,
  //   decoupleChildFromParent: false,
  //   maxHeight: 400

  // });
  // items: TreeviewItem[];

  constructor(public sharedService: SharedService,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private _sanitizer: DomSanitizer,
    private constants: ConstantsService,
    private modalService: NgbModal,
    private router: Router,
    public datepipe: DatePipe,
    private globalChangeService: GlobalChangeService,
    private companyServie: CompanyService,
    private partServie: PartService
  ) {
  }

  ngOnInit(): void {
    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);
    } else {
      this.rolePermissionsList = JSON.parse(localStorage.getItem('rolePermissionsList'));
      this.rolePermissionsList = this.rolePermissionsList.filter(x => x.pageId == 1032);
      this.permissionMessage = '';
      if (this.rolePermissionsList != null && this.rolePermissionsList != undefined) {
        if (!this.rolePermissionsList[0].isView) {
          this.permissionMessage += 'view';
        } if (!this.rolePermissionsList[0].isInsert) {
          if (this.permissionMessage != '') {
            this.permissionMessage += ', ';
          }
          this.permissionMessage += 'add';
        } if (!this.rolePermissionsList[0].isUpdate) {
          if (this.permissionMessage != '') {
            this.permissionMessage += ', ';
          }
          this.permissionMessage += 'edit';
        } if (!this.rolePermissionsList[0].isDelete) {
          if (this.permissionMessage != '') {
            this.permissionMessage += ', ';
          }
          this.permissionMessage += 'delete';
        }
      }
      if (this.permissionMessage != '') {
        this.permissionMessage = 'You do not have access to ' + this.permissionMessage + '. Please contact to administrator!';
      }
      this.companyId = localStorage.getItem(this.constants.companyId);
      this.userId = localStorage.getItem(this.constants.userId);
      this.buttonValue = this.constants.Save;
      // this.getAllCompany_search(this.companyId);
      // this.Getvendordropdownlist(this.companyId);

      this.dtOptions = {
        pagingType: this.constants.full_numbers,
        pageLength: 10
      };

      //ankit implementaion
      // get company by default set value
      var company = this.globalChangeService.getGlobalCompany();
      if(company>0){
        this.value_search = company;
        this.getAllCompany_search(company);
        this.Getvendordropdownlist(company);
        this.searchInv();
      }
      else{
        this.ErrorModal('Error', 'Please select company.');
      }

      //on change function 
      this.globalChangeService.dropdownChange$.subscribe((data)=>{
        this.value_search = company;
        this.onValueChange_search(data);
        this.getAllCompany_search(data);
        this.Getvendordropdownlist(data);
        this.searchInv();
      })
    }

    this.formSearch = new FormGroup({
      'vendorSearchDDL': new FormControl(),
    });

    this.form = new FormGroup({
      'UpdatedAssignQty': new FormControl(),
      'chkInv': new FormControl(),
    });
  }

  SetSettingCSS(settingCSS, logoImagePath) {
    if (settingCSS != null) {
      let headerCss = document.getElementsByClassName(this.constants.CssHealerClass) as HTMLCollectionOf<HTMLElement>;
      if (headerCss.length != 0) {
        headerCss[0].style.backgroundColor = settingCSS.split(',')[0];
      }
      let saveButtonCss = document.getElementsByClassName(this.constants.CssSaveBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (saveButtonCss.length != 0) {
        saveButtonCss[0].style.backgroundColor = settingCSS.split(',')[1];
        $(".blue-bg").css("background", settingCSS.split(',')[1]);
      }
      let cencelButtonCss = document.getElementsByClassName(this.constants.CssCancelBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (cencelButtonCss.length != 0) {
        cencelButtonCss[0].style.backgroundColor = settingCSS.split(',')[2];
        $(".darkred-bg").css("background", settingCSS.split(',')[2]);
      }
    }
    if (logoImagePath != null && logoImagePath != "") {
      (document.getElementById('hrdlogoImage') as HTMLImageElement).src = logoImagePath;
    }
  }

  getAllCompany_search(companyId) {
    this.companyServie.GetDropdownCompanyHierarchyModel(this.companyId).subscribe(
      data => {
        this.data = data;
       this.items_search = this.data.dataList;
        this.companyList_search = this.data.dataList;
        // this.companyList_search.forEach((x) => {
        //   this.userGroupObjects_search.push(new TreeviewItem(x, false));
        // });
        this.value_search = Number(companyId);
      });
  }


  onValueChange_search(value: any): void {
    this.value_search = value;
    this.Getvendordropdownlist(this.value_search);
  }

  ChangeddlVendor(value, rowIndex) {
    this.InvList[rowIndex].vendorId = Number(value);
  }

  openEditAssQty(rowIndex) {

    this.InvList.forEach(InvList => {
      InvList.rowEdit = false;
    });

    this.InvList[rowIndex].rowEdit = true;
    this.UpdatedAssignQty = this.InvList[rowIndex].reorderQty ? this.InvList[rowIndex].reorderQty : 0;
  }

  quantityBlur(rowIndex): void {
    this.InvList[rowIndex].reorderQty = Number(this.UpdatedAssignQty);
    this.InvList[rowIndex].rowEdit = false;
  }

  searchInv() {
    this.disableButton = false;
    this.ListShow = true;
    this.ListDiv = true;
    this.ListAngle = "up";
    this.invDiv = true;
    this.sharedService.GetAllBelowInventoryMinimum(this.value_search, this.vendorSearch).subscribe(
      data => {
        this.data = data;
        this.InvList = [];
        if (this.data.dataList != null && this.data.dataList != undefined) {
          if (this.data.dataList.length > 0) {
            for (var item = 0; item < this.data.dataList.length; item++) {
              let obj = {
                id: this.data.dataList[item].id,
                name: this.data.dataList[item].name,
                partNo: this.data.dataList[item].partNo,
                vendorPartName: this.data.dataList[item].vendorPartName,
                vendorPartNumber: this.data.dataList[item].vendorPartNumber,
                vendorPartCost: this.data.dataList[item].vendorPartCost,
                description: this.data.dataList[item].description,
                unitofMeasureId: this.data.dataList[item].unitofMeasureId,
                vendorId: this.data.dataList[item].vendorId,
                minInventory: this.data.dataList[item].minInventory,
                reorderQty: !this.data.dataList[item].reorderQty ? 0 : this.data.dataList[item].reorderQty,
                invQty: this.data.dataList[item].invQty,
                createdBy: this.data.dataList[item].createdBy,
                createdDate: this.data.dataList[item].createdDate,
                isChecked: true,
                rowEdit: false
              }
              this.InvList.push(obj);
            }
            this.disableButton = true;
          }
        }
        this.rerender();
      }
    )
  }

  chngIsInv(event, idd) {
    const env = this.InvList.filter(data => data.id == idd);
    if (event == true) {
      env[0].isChecked = true;
    }
    else {
      env[0].isChecked = false;
    }
  }

  SaveConfirm(confirmSave) {
    this.modalService.open(confirmSave, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
  }


  SaveAssignInv() {
    let obj = {};
    obj['PartId'] = 0;
    obj['CustomerSo'] = 'abc';
    obj['OrderedQty'] = 0;
    obj['ShipQty'] = 0;
    obj['PartOrderId'] = 0;
    obj['ProductOrderId'] = 0;
    obj['CustomerRma'] = 'fgh';
    obj['ServiceNo'] = 'ab10';
    obj['PartLocation'] = 'US';
    obj['Reason'] = 'ABC'

    let invArr = this.InvList.filter(x => x.isChecked == true);
    for (var j = 0; j < invArr.length; j++) {

      let invObj = {};
      invObj["CompanyId"] = Number(this.value_search);
      invObj["Id"] = invArr[j].id;
      invObj["Name"] = invArr[j].name;
      invObj["PartNo"] = invArr[j].partNo;
      invObj["VendorPartName"] = invArr[j].vendorPartName;
      invObj["VendorPartNumber"] = invArr[j].vendorPartNumber;
      invObj["VendorPartCost"] = invArr[j].vendorPartCost;
      invObj["Description"] = invArr[j].description;
      invObj["UnitofMeasureId"] = invArr[j].unitofMeasureId;
      invObj["VendorId"] = invArr[j].vendorId;
      invObj["ReorderQty"] = invArr[j].reorderQty;
      invObj["InvQty"] = invArr[j].invQty;
      invObj["CreatedBy"] = this.userId;
      invObj["BuyerId"] = this.userId;
      invObj["LoggedEmail"] = localStorage.getItem(this.constants.userEmail) == "" ? '' : localStorage.getItem(this.constants.userEmail);
      invObj["LoggedName"] = localStorage.getItem(this.constants.userName) == "" ? '' : localStorage.getItem(this.constants.userName);
      this.InvDetails.push(invObj);
    }

    obj["BelowInventoryMinimum"] = this.InvDetails;
    this.loader = true;
    this.sharedService.UpdateBelowInventoryMinimum(obj)
      .subscribe(
        data => {
          switch (data.statusCode) {
            case 200:
              this.buttonValue = this.constants.Save;
              this.resetvendorData();
              this.searchInv();
              this.rerender();
              $("#add_update").show();
              break;
            case 400:
              $("#wrong_msg").show();
              break;
            default:
              $("#wrong_msg").show();
          }
          this.loader = false;
        }
      );
  }

  Getvendordropdownlist(companyId) {
    this.loader = true;
    if (companyId == undefined) { companyId = 0; }
    this.partServie.Getvendordropdownlist(companyId).subscribe(
      data => {
        this.loader = false;
        this.data = data;
        if (companyId > 0) {
          this.vendorListDDL = this.data.dataList;
        }
        else {
          this.vendorSearch = '0';
        }
      }
    )
  }

  resetvendorData() {
    this.InvValidation = false;
    this.InvQty = '';
    this.InvType = '';
    this.InvList = [];
    this.InvDetails = [];
    this.invDiv = false;
    this.viewLog = false;
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next('');
    });
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
    this.dtTrigger.next('');
    this.settingDetailCSS = localStorage.getItem("settingDetailCSS");
    this.settingLogoImage = localStorage.getItem("settingImageLogo");
    this.SetSettingCSS(this.settingDetailCSS, this.settingLogoImage);
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  updateitem() {
    $("#add_update").hide();
  }

  ListDivShowHide() {
    if (this.ListDiv == true) {
      this.ListDiv = false;
      this.ListAngle = this.constants.DownArrow;
    }
    else if (this.ListDiv == false) {
      this.ListDiv = true;
      this.ListAngle = this.constants.UpArrow;
    }
  }

  //ankit implemenation
  ErrorModal(title:string, message:string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }
}




