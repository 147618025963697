import { Component, OnInit, ChangeDetectorRef, AfterViewInit, ViewChild, OnDestroy, ElementRef, HostListener, QueryList, ViewChildren } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstantsService } from 'src/app/constants.service';
import { SharedService } from 'src/app/services/shared.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
// import { TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { customPartDetails } from './CustomPart';
import { CurrencyPipe, DatePipe } from '@angular/common'
// import { FormGuardService } from 'src/app/FormGuard/form-guard.service'
import { ConfirmationDialogService } from 'src/app/ConfirmationDialog/confirmation-dialog.service'
import { OrderProductParts } from '../orders/productParts';
import Swal from 'sweetalert2';
import { GlobalChangeService } from 'src/app/services/global-change.service';
import { VendorService } from 'src/app/services/vendor/vendor.service';
import { PartService } from 'src/app/services/part/part.service';
import { CompanyService } from 'src/app/services/company/company.service';

declare var $: any


@Component({
  selector: 'app-custom-part',
  templateUrl: './custom-part.component.html',
  styleUrls: ['./custom-part.component.css']
})



export class CustomPartComponent implements AfterViewInit, OnDestroy, OnInit {
  [x: string]: any;
  AdditionalDiscountLabel: boolean = false;
  IsOpen: boolean = false;
  IsSave: boolean = true;
  IsUpdate: boolean = false;
  IsPricePart: boolean = true;
  IsPPricePart: boolean = true;
  PartName: string = '';
  PartName1: string = '';
  PartNumber1: string = '';
  ManualPartId: Number = 0;
  recordAlreadyExist = false;
  recordPartNoExist = false;
  IsMarginDisabled = true;
  IsPartMarginDisabled = true;
  settingDetailCSS: any;
  settingLogoImage: any;
  data: any = {};
  data1: any = {};
  imageUrl: any = "/assets/image/add-part.png";
  datalist: any;
  vendorlist: any;
  categorylist: any;
  Customlist: any;
  PartQuestions = [0];
  questionlist: any;
  questionlistNew: any;
  tempQuestionlist: [];
  userId: string;
  value: number;
  id: any;
  vendorId: any;
  UpdatevendorId: any;
  name: any;
  description: any;
  MarkupMargin: any;
  PartMarkupMargin: any;
  MarkupCheck: boolean = false;
  PartMarkupCheck: boolean = true;
  cost: any;
  hours: any;
  imagePath: any
  selectedVendorValue = '';
  partmodelVendorValue = '';
  partmodelvendorId: any;
  PVendorCost: any;
  PPartPrice: any;
  laborCharge: any;
  search: any;
  customPartId: any;
  customPartImagePath: any
  notes: any;
  partCategoryId: any;
  companyId: any;
  productId: any;
  isActive: any;
  createdDate: any;
  modifiedDate: any;
  createdBy: string;
  modifiedBy: string;
  partNo: any
  vendorName: any;
  partCategoryName: any;
  partname: any;
  vendorsName: any;
  UpdatevendorsName: any;
  CheckCategoryDisable: boolean = false;
  questionId: any;
  selectedPartCategoryIdValue = '';
  CustomCategory = '';
  CustompartVendor = '';
  @ViewChild('closeBtn') closeBtn: ElementRef;
  buttonValue: string;
  partid: number;
  validation: boolean = false;
  popvalidation: boolean = false;
  loader: boolean = false;
  @ViewChildren(DataTableDirective)
  dtElements: QueryList<DataTableDirective>;
  dtOptions: DataTables.Settings = {};
  dtOptions1: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  dtTrigger1: Subject<any> = new Subject<any>();
  partIds: any;
  qnsId: any;
  deleteDetail: string;
  disabled = true;
  questionidZero: any;
  fileToUpload: File = null;
  deletedItem: string;
  PartImage: any;
  clearbutton: boolean = true;
  NonInventory: boolean = false;
  weight: any;
  length: any;
  height: any;
  width: any;
  PVendorNumber: any;
  PVendorPartName: any;
  i: Number = 0;
  shortDescription: any;
  InfoName: any = "More Information";
  MatingName: any = "Mating Connectors";
  CrimpName: any = "Crimp Tools";
  RelatedName: any = "Related Items";
  rolePermissionsList: any;
  permissionMessage: string;
  btnShowvalidation: boolean = false;
  btnOkShow: boolean = false;
  MistypeValue: any;
  // config = TreeviewConfig.create({
  //   hasAllCheckBox: true,
  //   hasFilter: true,
  //   hasCollapseExpand: true,
  //   decoupleChildFromParent: false,
  //   maxHeight: 400

  // });
  // items: TreeviewItem[];
  companyList: any;
  userGroupObjects = new Array();
  CompanyValue: any;
  VendorPartCost: any;
  CategoryId: any;
  ddlPartList: any;
  ddlPartList1: any;
  ddlVendorList: any;
  ddlVendorList1: any;
  PartCategoryList: any;
  partList = [];
  partitemId: any;
  partObjectModel = new customPartDetails();
  PartId: any;
  custompartId: any;
  selectedPartValue = '';
  PartsDetails = [];
  NotActive: boolean = false;
  now: Date;
  validationcustom: boolean = false;
  deleteCustomPart: any;
  SubTotalVendorCost: any;
  SubTotalPartCost: any;
  SubTotalLaborHours: any;
  isPartval: boolean = true;
  WordpressCategoryList: any;
  WordpressCategoryCrimpToolsList: any;
  WordpressCategoryRelatedItemList: any;
  WordpressCategoryPartialList: any[] = [];
  WordpressProductPartialList: any[] = [];
  CheckCategory: boolean;
  PartHistory: boolean = false;
  CheckId: number = 0;
  CustomerPartNameList: any[] = [];
  MatingDisableList: any[] = [];
  CrimpDisableList: any[] = [];
  RelatedDisableList: any[] = [];
  CustomerPartNameCrimpList: any[] = [];
  CustomerPartNameRelatedList: any[] = [];
  WordpressAllProductList: any[] = [];
  NewArray: any[] = [];
  NewArray1: any[] = [];
  NewArray2: any[] = [];
  CrimpNewArray: any[] = [];
  CrimpNewArray1: any[] = [];
  RelatedNewArray: any[] = [];
  RelatedNewArray1: any[] = [];
  NewPartialArray = [];
  qty: any = 1;
  normalShow = true;
  UpdateQty: any = 0;
  UpdateVendorsName: any = 0;
  UpdateEachPartCost: any = 0;
  UpdateLaborHrs: any = 0;
  UpdateVendorPartCost: any = 0;
  UpdatePartName: any;
  UpdatePartNumber: any;
  RowIsMisc: any;
  misccost: any;
  mischours: any = 0.00;
  miscVendorPartCost: any = '';
  miscname: any;
  miscpartNo: any;
  miscqty: any = 1;
  MiscategortyTypeValue: any;
  unitofmeasurement: any;
  UnitofMeasureId: any;
  SelectedUnitMeasure = '';
  MainProp: string;
  InvValidation: boolean = false;
  InvValidationUpdate: boolean = false;
  InvList = [];
  SaveInvList = [];
  InvDetails = [];
  SaveLogList = [];
  InvSno = 0;
  InvSnoEdit = 100000;
  InvQty: any;
  InvDate: any;
  InvCost: any;
  InvType = '';
  InvPartOrder = '';
  InvProductOrder = '';
  InvType2: any;
  InvTypeList: any;
  InvCustNo: any;
  InvServNo: any;
  InvCustRMA: any;
  InvPartLocation: any;
  UpdateInvQty: any;
  UpdateInvDate: any;
  UpdateInvCost: any;
  UpdateInvType = '';
  UpdateInvPartOrder = '';
  UpdateInvProductOrder = '';
  UpdateInvServNo: any;
  UpdateInvCustNo: any;
  UpdateInvCustRMA: any;
  UpdateInvVendor: any;
  UpdateInvPartLocation: any;
  DelRowNo: any;
  DelInvId: any;
  InvVendor = '';
  EditInvSno: any;
  // btnShowvalidation: boolean = false;
  InvbtnOkShow: boolean = false;
  InvPartOrderDDL: any;
  InvProductOrderDDL: any;
  MinDate: any;
  TCreateUpdate: any = 1;
  fieldToSearch = '3';
  fieldToSearchPlaceHolder = 'Enter List or Vendor Part #';
  cfieldToSearchPlaceHolder = 'Enter List or Vendor Part #';
  userSearch: any;
  SearchValidation: any;
  SearchType: any;
  SearchControl: boolean = false;
  ListShow: boolean = false;
  ListDiv: boolean = false;
  ListAngle: any;
  formSearch: FormGroup;
  userGroupObjects_search = new Array();
  value_search: number;
  companyList_search: any;
  // items_search: TreeviewItem[];
  ImageTypelist: any;
  ImageTypeId: any;
  SelectedImageTypeId = '';
  MultipleImage = [];
  PartImageDefault: any;
  //PartImageArray = [];
  deletedItemImage: any;
  miscellenouseType: any;
  noproduct: string;
  PartImageArray = [];
  viewimagesshow: any;
  DefaultLogoPDF: any;
  Documentype: any;
  WordFile: any;
  @ViewChild('Image') myInputVariable: ElementRef;
  PictureValue: any = '';
  LocalId: any;
  skillLevel = '0';
  skillLevelList: any;
  minInventory: any;
  userRoleList: any;
  userRole = '';
  WorkflowSteps = '';
  WorkflowList: any;
  installationNotes: any;
  TotalAllType: any;
  TotalTypeInventory: any;
  TotalInvLog: any;
  SaveTotalAllType: any;
  SaveTotalTypeInventory: any;
  SaveTotalInvLog: any;
  reorderAmount: any;
  partLeadTime: any;
  partLocation: any;
  InvProp: string;
  partTracking : any = '';
  // Start
  VendorPartName: any;
  VendorPartNumber: any;
  AddvendorList: any[] = [];
  catdropdownList = [];
  selectedItems = [];
  CategoryArr = [];
  vendorText: any;
  partVendorsList = [];
  cValidation : Boolean = false;
  cfieldToSearch : any = '3';
  //End

  //Move Popup Properties
  formPopup: FormGroup;
  mInvValidation: boolean = false;
  mInvQtyValidation: boolean = false;
  mQtyMoveMsg: boolean = false;
  mMsg: any;
  mMsgClass: any;
  mInvQty: any;
  mInvQtyToMove: any;
  mInvDate: any;
  mInvCost: any;
  mInvType = '';
  mInvPartOrder = '';
  mInvProductOrder = '';
  mInvType2: any;
  mInvTypeList: any;
  mInvPartOrderDDL: any;
  mInvProductOrderDDL: any;
  mInvCustNo: any;
  mInvServNo: any;
  mInvCustRMA: any;
  mInvLocation: any;
  mInvReason: any;
  mValidateQtyToFulfill: any;
  partNameProperty: string;
  AlreadyVendorName: string;
  // for log
  TotalIn: number = 0;
  TotalOut: number = 0;
  viewLog: boolean = false;
  logList = [];
  searchPartId: any;
  isInvFind: boolean = false;
  PartTrackingList = [];
  //Adjust Popup Properties
  formPopupAdjustQty: FormGroup;
  adjInvValidation: boolean = false;
  adjInvQty: any;
  adjInvQtyToAdjust: any;
  adjInvReason: any;
  Pwordpressid: any;
  adjInvReasonList = this.constants.InventoryAdjustReason;
  vendorlist1: any;
  isTotalInvQty: boolean = false;
  displayOldLocation: any;
  CustomeList: any[];
  PartHistoryList: any[] = [];
  IsPartSave: boolean = true;
  IsPartUpdate: boolean = false;
  EditpartId: any;
  deleteDetailVendor: string;
  AddvendorList1: any[] = [];
  AddvendorList2: any[] = [];
  SaveModel: string;
  ADiscount: any;
  AVendor: any;
  PartdeletedItem: string;
  dropdownSettings = {};
  CheckHistoryList: boolean;
  @ViewChild('QtyMoveMsgModel', { static: false }) private QtyMoveMsgModel;
  @ViewChild('itemModel', { static: false }) private itemModel;
  @ViewChild('itemModel2', { static: false }) private itemModel2;
  MainInvRowNo: number;
  Type: any;
  customvendorId: any;
  editrow: any;
  isSaved: boolean = false;
  isBackgroudImage: boolean = false;
  form: FormGroup;
  deleteUnsuccessMsg : any;
  partObjectModels = new OrderProductParts();
  isConfirmationmodel:boolean=false;
  SelectedLocation = '';
  locationNameList : any;
  SelectedPartLocation = '';
  selectedLocationName : any;
  dynamicArray = [{ 'Qty': '', 'DiscountPrice': '', 'cost': '', 'id': '', 'isActive': false, 'CreateDate': '', 'CreatedBy': '','isReadOnly': false,'IsDReadonly': false  }];
  dynamicCustomerDiscount = [{ 'Qty': '', 'DiscountPrice': '', 'cost': '', 'id': '', 'isActive': false, 'CreateDate': '', 'CreatedBy': '' ,'isReadOnly': false,'IsDReadonly': false }];

  
  constructor(public sharedService: SharedService,
    public vendorService: VendorService,
    private cdr: ChangeDetectorRef,
    private _sanitizer: DomSanitizer,
    private constants: ConstantsService,
    private modalService: NgbModal,
    private router: Router,
    public datepipe: DatePipe, private route: ActivatedRoute,
    public confirmation: ConfirmationDialogService, 
    private globalChangeService: GlobalChangeService,
    private partService: PartService,
    private companyService: CompanyService
  ) {
    this.getTrackingList();
  }

  ngOnInit(): void {
    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);
    } else {
      this.rolePermissionsList = JSON.parse(localStorage.getItem('rolePermissionsList'));
      this.rolePermissionsList = this.rolePermissionsList.filter(x => x.pageId == 8);
      this.permissionMessage = '';
      if (this.rolePermissionsList != null && this.rolePermissionsList != undefined) {
        if (!this.rolePermissionsList[0].isView) {
          this.permissionMessage += 'view';
        } if (!this.rolePermissionsList[0].isInsert) {
          if (this.permissionMessage != '') {
            this.permissionMessage += ', ';
          }
          this.permissionMessage += 'add';
        } if (!this.rolePermissionsList[0].isUpdate) {
          if (this.permissionMessage != '') {
            this.permissionMessage += ', ';
          }
          this.permissionMessage += 'edit';
        } if (!this.rolePermissionsList[0].isDelete) {
          if (this.permissionMessage != '') {
            this.permissionMessage += ', ';
          }
          this.permissionMessage += 'delete';
        }
      }
      if (this.permissionMessage != '') {
        this.permissionMessage = 'You do not have access to ' + this.permissionMessage + '. Please contact to administrator!';
      }

      this.companyId = localStorage.getItem(this.constants.companyId);
      this.MiscategortyTypeValue = this.constants.MisceType;
      this.userId = localStorage.getItem(this.constants.userId);
      this.buttonValue = this.constants.Save;
      this.getSkillLevel(this.companyId, this.userRole);
      if (this.companyId > 0) {
        this.Getvendordropdownlist(this.companyId);
        this.Getmultivendordropdownlist(this.companyId, undefined, undefined, undefined);
      }

      //ankit commented
      // this.getAllCompany_search(this.companyId);
      // this.getAllCompany(this.companyId);

      this.UnitofMeasurementDropdownList();
      this.InvTypeDropdownList();
      if (this.companyId > 0) {
        this.InvProductOrderDropdownList(this.companyId);
        this.InvPartOrderDropdownList(this.companyId);
      }
      this.getAllProductionRole(this.companyId);
      this.getAllWorkflowSteps(this.companyId);
      this.WordpressCategoryLoad(this.companyId);
      this.DefaultLogoPDF = "/assets/image/PDFFile.png";
      this.dtOptions = {
        pagingType: this.constants.full_numbers,
        pageLength: 10,
        ordering: false,
        scrollX: true
      };

      
      //ankit implementaion
      // get company by default set value
      var company = this.globalChangeService.getGlobalCompany();
      if(company>0){
        this.CompanyValue = company;
        this.value_search = company;

        if (company == undefined) {
          company = 0;
        }
        this.Getvendordropdownlist(this.CompanyValue);
        this.Getmultivendordropdownlist(this.CompanyValue, undefined, undefined, undefined);
        this.Getquestiondropdownlist(this.CompanyValue);
        this.getAllProductionRole(this.CompanyValue);
        this.getAllWorkflowSteps(this.CompanyValue);
        this.WordpressCategoryLoad(this.CompanyValue);
        this.getSkillLevel(this.CompanyValue, this.userRole);
        this.getLocationLevel(this.CompanyValue);
        this.selectedVendorValue = "";
        this.selectedItems = [];
      }
      else{
        this.ErrorModal('Error', 'Please select company.');
      }

      //on change function 
      this.globalChangeService.dropdownChange$.subscribe((data)=>{
        this.companyChangeValue(data);
        this.onValueChange_search(data);
      })
     
    }

    let today = new Date();
    this.MinDate = this.datepipe.transform(today, 'yyyy-MM-dd');

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'partcategoryid',
      textField: 'partcategorytext',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: false
    };

    this.newform = new FormGroup({
      'chkInv': new FormControl(),
      'chkInvCrimp': new FormControl(),
      'chkInv1': new FormControl(),
      'chkInv1Crimp': new FormControl(),
      'chkInv1Related': new FormControl(),
      'chkInvRelated': new FormControl(),
      'InfoName': new FormControl(),
      'MatingName': new FormControl(),
      'CrimpName': new FormControl(),
      'RelatedName': new FormControl(),
      'shortDescription': new FormControl('', Validators.required)
    });

    this.partForm = new FormGroup({
      'partmodelVendorValue': new FormControl('', Validators.compose([Validators.required, Validators.minLength(1)])),
      'PVendorCost': new FormControl(),
      'PPartPrice': new FormControl(),
      'PartMarkupCheck': new FormControl(),
      'PartMarkupMargin': new FormControl(),
      'PVendorNumber': new FormControl(),
      'PVendorPartName': new FormControl()
    });

    this.form = new FormGroup({
      'NonInventory': new FormControl(),
      'weight': new FormControl(),
      'length': new FormControl(),
      'height': new FormControl(),
      'width': new FormControl(),
      'DimentionTypeL': new FormControl(),
      'DimentionTypeW': new FormControl(),
      'DimentionTypeH': new FormControl(),
      'WeightType': new FormControl(),
      'name': new FormControl(),
      'partNo': new FormControl(),
      'description': new FormControl(),
      'MarkupMargin': new FormControl(),
      'NotActive': new FormControl(),
      'MarkupCheck': new FormControl(),
      'hours': new FormControl(),
      'cost': new FormControl(),
      'partLocation': new FormControl(),
      'partLeadTime': new FormControl(),
      'reorderAmount': new FormControl(),
      'VendorPartCost': new FormControl(),
      'search': new FormControl(),
      'installationNotes': new FormControl(),
      'partId': new FormControl(),
      //Start
      'VendorPartName': new FormControl(),
      'VendorPartNumber': new FormControl(),
      //End
      'UpdateQty': new FormControl(),
      'UpdateVendorId': new FormControl(),
      'UpdateVendorPartNumber': new FormControl(),
      'UpdateEachPartCost': new FormControl(),
      'UpdateLaborHrs': new FormControl(),
      'UpdateVendorPartCost': new FormControl(),
      'UpdatePartName': new FormControl(),
      'UpdatePartNumber': new FormControl(),
      'misccost': new FormControl(),
      'mischours': new FormControl(),
      'miscVendorPartCost': new FormControl(),
      'miscname': new FormControl(),
      'miscpartNo': new FormControl(),
      'miscqty': new FormControl(),
      'UnitofMeasureId': new FormControl(),
      'selectedItems': new FormControl(),
      'LocationsId' : new FormControl(),
      'vendorId': new FormControl('', Validators.compose([Validators.required, Validators.minLength(1)])),
      'partCategoryId': new FormControl('', Validators.compose([Validators.required, Validators.minLength(1)])),
      'questionIds': new FormArray(
        [
          new FormGroup({
            questionId: new FormControl(0),
          }
          )
        ]
      )
      ,
      "custompartId": new FormControl(),
      'InvQty': new FormControl('', Validators.required),
      'InvDate': new FormControl('', Validators.required),
      'InvCost': new FormControl('', Validators.required),
      'InvType': new FormControl('', Validators.required),
      'InvVendor': new FormControl('', Validators.required),
      'InvCustRMA': new FormControl(),
      'InvPartLocation': new FormControl(),
      'InvServNo': new FormControl('', Validators.required),
      'InvPartOrder': new FormControl('', Validators.required),
      'InvProductOrder': new FormControl('', Validators.required),
      'UpdateInvQty': new FormControl('', Validators.required),
      'UpdateInvDate': new FormControl('', Validators.required),
      'UpdateInvCost': new FormControl('', Validators.required),
      'UpdateInvType': new FormControl('', Validators.required),
      'UpdateInvServNo': new FormControl('', Validators.required),
      'UpdateInvVendor': new FormControl(),
      'UpdateInvCustRMA': new FormControl(),
      'UpdateInvPartLocation': new FormControl(),
      'UpdateInvPartOrder': new FormControl('', Validators.required),
      'UpdateInvProductOrder': new FormControl('', Validators.required),
      //salman khan for Image type
      'ImageTypeId': new FormControl(),
      //end
      'skillLevel': new FormControl(),
      'minInventory': new FormControl(),
      'userRole': new FormControl(),
      'WorkflowSteps': new FormControl(),
      'partTracking' : new FormControl()
    });
    this.formSearch = new FormGroup({
      'TCreateUpdate': new FormControl(),
      'userSearch': new FormControl(),
      'fieldToSearch': new FormControl()
    });

    this.formPopup = new FormGroup({
      'mInvQty': new FormControl('', Validators.required),
      'mInvQtyToMove': new FormControl('', Validators.required),
      'mInvDate': new FormControl('', Validators.required),
      'mInvCost': new FormControl('', Validators.required),
      'mInvType': new FormControl('', Validators.required),
      'mInvCustRMA': new FormControl(),
      'mInvServNo': new FormControl('', Validators.required),
      'mInvPartOrder': new FormControl('', Validators.required),
      'mInvProductOrder': new FormControl('', Validators.required),
      'mInvLocation': new FormControl(),
      'mInvReason': new FormControl(),
    });

    this.formPopupAdjustQty = new FormGroup({
      'adjInvQty': new FormControl('', Validators.required),
      'adjInvQtyToAdjust': new FormControl('', Validators.required),
      'adjInvReason': new FormControl(),
    });

    this.form.controls['skillLevel'].disable();
    this.DimentionTypeList = this.constants.DimentionType;
    this.WeightTypeList = this.constants.WeightType;
    this.DimentionTypeL = 2;
    this.DimentionTypeH = 2;
    this.DimentionTypeW = 2;
    this.WeightType = 3;


    const counting = this.AddvendorList.filter(x => x.IsActive == true);
    if (counting.length > 0) {
      this.DeActiveCouting = false;
      this.ActiveCouting = true;
    }
    else {
      this.DeActiveCouting = true;
      this.ActiveCouting = false;
    }

  }

  getTrackingList(){
    this.partService.GetPartTrackingList().subscribe(
      (data: any) => {
        if(data.length>0){
          this.PartTrackingList = data;
        }
      }
    )
  }

  SortTableHeader(item: any) {
    if (this.datalist != null) {
      var ret = new Array;
      for (var i = this.datalist.length - 1; i >= 0; i--) {
        ret.push(this.datalist[i]);
      }

      this.datalist = ret;
      var Keys = Object.keys(this.datalist[0]);

      if (Keys.includes(item)) {
        if (this.isBackgroudImage) {
          this.isBackgroudImage = false;
          var element = document.getElementById(item);
          if (element.className === 'sorting1') {
            element.classList.replace('sorting1', 'sorting_asc');
          }
          else if (element.className === 'sorting_asc') {
            element.classList.replace('sorting_asc', 'sorting_desc');
          }
          else {
            element.classList.replace('sorting_desc', 'sorting_asc');
          }
        }
        else {
          this.isBackgroudImage = true;
          var element = document.getElementById(item);
          if (element.className === 'sorting1') {
            element.classList.replace('sorting1', 'sorting_asc');
          }
          else if (element.className === 'sorting_asc') {
            element.classList.replace('sorting_asc', 'sorting_desc');
          }
          else {
            element.classList.replace('sorting_desc', 'sorting_asc');
          }
        }
      }

      const result = Keys.filter(key => key != item);

      if (result) {
        result.forEach(data => {
          var element = document.getElementById(data);
          if (element != null) {
            if (element.className === 'sorting1') {
              element.classList.replace('sorting1', 'sorting1');
            }
            else if (element.className === 'sorting_asc') {
              element.classList.replace('sorting_asc', 'sorting1');
            }
            else {
              element.classList.replace('sorting_desc', 'sorting1');
            }
          }
        });
      }
    }
  }


  async canDeactivate(): Promise<Promise<boolean> | boolean> {
  
    if (!this.form.pristine && !this.isSaved){//!this.isPartval && !this.isSaved) {
      const confirmResult = await this.confirmation.confirm('Confirmation','Are you sure you want to leave this page ?','Cancel','OK','lg');
      if (confirmResult === true) {
        return true;
      } else {
        return false;
      }
    }
    else {
      return true;
    }
  }


  ChangefieldToSearch(value) {
    this.fieldToSearchPlaceHolder = value == '1' ? 'Enter Search Keywords' : value == '2' ? 'Enter List or Vendor Part Name' : value == '3' ? 'Enter List or Vendor Part #' : '';
  }

  CChangefieldToSearch(value) {
    this.cfieldToSearchPlaceHolder = value == '1' ? 'Enter Search Keywords' : value == '2' ? 'Enter List or Vendor Part Name' : value == '3' ? 'Enter List or Vendor Part #' : '';
  }

  GetValue(id) {
    //var id = this.route.snapshot.params['id'];
    this.EditByPart(id);
  }
  EditByPart(id) {
    this.viewLog = false;
    this.ListDiv = false;
    this.ListAngle = "down";
    this.LocalId = id;
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.buttonValue = this.constants.Update;
    this.clearbutton = false;
    this.partService.GetCustomPartDetailsById(id).subscribe(
      (data: any) => {
        this.FilertingData = data;
        this.TCreateUpdate = 0;
        this.SearchControl = true;
        this.companyId = data.data.companyId;
        this.userSearch = data.data.name;
        // this.value = Number(data.data.companyId);
        this.value_search = Number(data.data.companyId);
        localStorage.setItem("EditMainId", JSON.stringify(data));
        this.id = data.data.id;
        data.data.discount == null ? 0 : data.data.discount;
        this.selectedVendorValue = data.data.vendorId + '-' + data.data.discount;
        //  this.selectedVendorValue = data.data.vendorId;
        this.name = data.data.name;
        this.partNo = data.data.partNo;
        this.description = data.data.description;
        this.NonInventory = data.data.nonInventory;
        this.partCategoryId = data.data.partCategoryId;
        this.Pwordpressid = data.data.productId;
        let count = 0;
        if (data.data.multiPartCategory) {
          this.CategoryArr = data.data.multiPartCategory;
          for (let ij = 0; ij < this.CategoryArr.length; ij++) {
            this.CategoryArr[ij].partcategoryid = this.CategoryArr[ij].partcategoryid + "-" + this.CategoryArr[ij].partWordpressid;
            this.CategoryArr[ij].partcategorytext = this.CategoryArr[ij].partcategorytext;
            this.CategoryArr[ij].isActive = this.CategoryArr[ij].isActive;
            this.CategoryArr[ij].id = this.CategoryArr[ij].id;
          }
          this.selectedItems = this.CategoryArr.filter(x => x.isActive == true);
          for (let i = 0; i < data.data.multiPartCategory.length; i++) {
            if (data.data.multiPartCategory[i].partWordpressid != "0" && data.data.multiPartCategory[i].partWordpressid != null) {
              count = count + 1;
            }

          }

          if (count > 0) {
            if (data.data.isNotActive == false && data.data.nonInventory == false) {
              this.CheckCategory = true;
              this.CheckCategoryDisable = false
            }
            else {
              this.CheckCategory = false;
              this.CheckCategoryDisable = true;
            }
          }
          else {
            this.CheckCategory = false;
            this.CheckCategoryDisable = false;
          }

        }
        if (data.data.autoMarkup == true) {
          this.IsMarginDisabled = false;
          this.IsPricePart = true;
        }
        else {
          this.IsMarginDisabled = true;
          this.IsPricePart = false;
        }
        this.MarkupCheck = data.data.autoMarkup;
        this.MarkupMargin = data.data.markupMargin;
        this.NotActive = data.data.isNotActive;
        this.WeightType = data.data.weightType;
        if (data.data.dimentionType == "3") {
          this.height = String(Number(data.data.height * 1 / 12).toFixed(0)) == "0" ? "" : String(Number(data.data.height * 1 / 12).toFixed(0));
          this.width = String(Number(data.data.width * 1 / 12).toFixed(0)) == "0" ? "" : String(Number(data.data.width * 1 / 12).toFixed(0));
          this.length = String(Number(data.data.length * 1 / 12).toFixed(0)) == "0" ? "" : String(Number(data.data.length * 1 / 12).toFixed(0));
        }
        else if (data.data.dimentionType == "1") {
          this.height = String(Number(data.data.height * 2.54).toFixed(0)) == "0" ? "" : String(Number(data.data.height * 2.54).toFixed(0));
          this.width = String(Number(data.data.Width * 2.54).toFixed(0)) == "0" ? "" : String(Number(data.data.width * 2.54).toFixed(0));
          this.length = String(Number(data.data.length * 2.54).toFixed(0)) == "0" ? "" : String(Number(data.data.length * 2.54).toFixed(0));
        }
        else {
          this.height = String(data.data.height) == "0" ? "" : String(data.data.height);
          this.length = String(data.data.length) == "0" ? "" : String(data.data.length);
          this.width = String(data.data.width) == "0" ? "" : String(data.data.width);
        }

        if (this.WeightType == "2") {
          this.weight = Number(data.data.weight * 16) == 0 ? '' : Number(data.data.weight * 16).toFixed(0);

        }
        else if (this.WeightType == "1") {
          this.weight = Number(data.data.weight * 0.453592).toFixed(0);
        }
        else {
          this.weight = Number(data.data.weight) == 0 ? "" : Number(data.data.weight);
        }

        this.DimentionTypeL = data.data.dimentionType;
        this.DimentionTypeH = data.data.dimentionType;
        this.DimentionTypeW = data.data.dimentionType;
        this.selectedPartCategoryIdValue = data.data.wordpressId + "-" + data.data.partCategoryId;
        this.CheckId = data.data.id;
        this.cost = parseFloat(data.data.cost).toFixed(5);
        this.PartHistory = true;
        this.PartName = data.data.name;
        this.ManualPartId = data.data.id;
        if (data.data.partImageUpload.length > 0) {
          this.MultipleImage = [];
          for (var itemimage = 0; itemimage < data.data.partImageUpload.length; itemimage++) {

            let ojtmultiplepart = {
              partypeName: data.data.partImageUpload[itemimage].partType,
              imageUrl: data.data.partImageUpload[itemimage].documentType == "PDF" ? this._sanitizer.bypassSecurityTrustUrl(data.data.partImageUpload[itemimage].imagePath) : data.data.partImageUpload[itemimage].imagePath == null ? '' : this._sanitizer.bypassSecurityTrustUrl(data.data.partImageUpload[itemimage].imagePath),
              // imageUrl: data.data.partImageUpload[itemimage].documentType == "PDF" ? data.data.partImageUpload[itemimage].imagePath : this._sanitizer.bypassSecurityTrustUrl(data.data.partImageUpload[itemimage].imagePath == null ? '' : data.data.partImageUpload[itemimage].imagePath),
              // Id: data.data.partImageUpload[itemimage].Id,
              partId: data.data.partImageUpload[itemimage].partId,
              documenttype: data.data.partImageUpload[itemimage].documentType,
            }
            this.MultipleImage.push(ojtmultiplepart);
          }
        }
        else {
          this.MultipleImage = [];
        }
        this.AddvendorList = [];
        if (data.data.partVendorList.length > 0) {
          this.AddvendorList2 = [];
          for (var itemvendor = 0; itemvendor < data.data.partVendorList.length; itemvendor++) {

            let ojtVmultiplepart = {};
            ojtVmultiplepart['vendorPartCost'] = data.data.partVendorList[itemvendor].vendorPartCost;
            ojtVmultiplepart['cost'] = data.data.partVendorList[itemvendor].cost;
            ojtVmultiplepart['vendorPartName'] = data.data.partVendorList[itemvendor].vendorPartName;
            ojtVmultiplepart['vendorPartNumber'] = data.data.partVendorList[itemvendor].vendorPartNumber;
            ojtVmultiplepart['markupCheck'] = data.data.partVendorList[itemvendor].automarkup;
            ojtVmultiplepart['markupMargin'] = data.data.partVendorList[itemvendor].markupMargin;
            ojtVmultiplepart['companyId'] = data.data.partVendorList[itemvendor].companyId;
            ojtVmultiplepart['ModifiedBy'] = data.data.partVendorList[itemvendor].ModifiedBy;
            ojtVmultiplepart['CreatedBy'] = data.data.partVendorList[itemvendor].CreatedBy;
            ojtVmultiplepart["CreatedDate"] = data.data.partVendorList[itemvendor].CreatedDate;
            ojtVmultiplepart["ModifiedDate"] = data.data.partVendorList[itemvendor].ModifiedDate;
            ojtVmultiplepart["IsActive"] = data.data.partVendorList[itemvendor].isActive;
            ojtVmultiplepart['vendorId'] = data.data.partVendorList[itemvendor].vendorId;
            ojtVmultiplepart['vendorName'] = data.data.partVendorList[itemvendor].vendorName;
            ojtVmultiplepart['id'] = data.data.partVendorList[itemvendor].id;
            ojtVmultiplepart['Discount'] = data.data.partVendorList[itemvendor].vendorDiscount;
            this.AddvendorList.push(ojtVmultiplepart);
            this.i = ojtVmultiplepart["id"]
            this.AddvendorList = this.AddvendorList.filter(x => x.IsActive == true);
            const counting = this.AddvendorList.filter(x => x.IsActive == true);
            if (counting.length > 0) {
              this.DeActiveCouting = false;
              this.ActiveCouting = true;
            }
            else {
              this.DeActiveCouting = true;
              this.ActiveCouting = false;
            }
          }
        }
        else {
          this.AddvendorList = [];
          this.DeActiveCouting = true;
          this.ActiveCouting = false;
        }
        this.VendorDiscountList = [];
        if (data.data.vendorDiscount.length > 0) {
          for (var j = 0; j < data.data.vendorDiscount.length; j++) {
            if (j > 0) {
              this.dynamicArray.push({ 'Qty': '', 'DiscountPrice': '', 'cost': '', 'id': '0', 'isActive': false, 'CreateDate': '', 'CreatedBy': '', 'isReadOnly': false,'IsDReadonly': false });
            }
            this.dynamicArray[j].Qty = String(data.data.vendorDiscount[j].qty);
            this.dynamicArray[j].DiscountPrice = String(data.data.vendorDiscount[j].discount == null ? '' : data.data.vendorDiscount[j].discount);
            this.dynamicArray[j].cost = String(data.data.vendorDiscount[j].vendorCost == null ? '' : (data.data.vendorDiscount[j].vendorCost).toFixed(2));
            this.dynamicArray[j].id = String(data.data.vendorDiscount[j].id);
            this.dynamicArray[j].isActive = true;
            this.dynamicArray[j].CreateDate = data.data.vendorDiscount[j].createdDate;
            this.dynamicArray[j].CreatedBy = data.data.vendorDiscount[j].createdBy;

          }
        }

        if (data.data.customerDiscount.length > 0) {
          for (var j = 0; j < data.data.customerDiscount.length; j++) {
            if (j > 0) {
              this.dynamicCustomerDiscount.push({ 'Qty': '', 'DiscountPrice': '', 'cost': '', 'id': '0', 'isActive': false, 'CreateDate': '', 'CreatedBy': '','isReadOnly': false,'IsDReadonly': false });
            }
            this.dynamicCustomerDiscount[j].Qty = String(data.data.customerDiscount[j].qty);
            this.dynamicCustomerDiscount[j].DiscountPrice = String(data.data.customerDiscount[j].discount == null ? '' : data.data.customerDiscount[j].discount);
            this.dynamicCustomerDiscount[j].cost = String(data.data.customerDiscount[j].customerCost == null ? '' : (data.data.customerDiscount[j].customerCost).toFixed(2));
            this.dynamicCustomerDiscount[j].id = String(data.data.customerDiscount[j].id);
            this.dynamicCustomerDiscount[j].isActive = true;
            this.dynamicCustomerDiscount[j].CreateDate = data.data.customerDiscount[j].createdDate;
            this.dynamicCustomerDiscount[j].CreatedBy = data.data.customerDiscount[j].createdBy;
          }
        }

        this.hours = data.data.hours;
        this.PartImage = data.data.partImage;
        //  toggle edit three properties 25_02_2022
        this.VendorPartName = data.data.vendorPartName;
        this.VendorPartNumber = data.data.vendorPartNumber;
        this.partLocation = data.data.partLocation;
        this.partLeadTime = data.data.partLeadTime;
        this.reorderAmount = data.data.reorderAmount;
        this.search = data.data.search;

        this.installationNotes = data.data.installationNotes;
        this.TotalAllType = data.data.totalAllType ? data.data.totalAllType : 0;
        this.TotalTypeInventory = data.data.totalTypeInventory ? data.data.totalTypeInventory : 0;
        this.TotalInvLog = data.data.totalInvLog ? data.data.totalInvLog : 0;
        this.isTotalInvQty = true;
        this.partTracking = data.data.partTracking == 0 ? '' : data.data.partTracking;
        this.CompanyValue = data.data.companyId;
        this.VendorPartCost = parseFloat(data.data.vendorPartCost).toFixed(5);
        this.SelectedUnitMeasure = data.data.unitofMeasureId == null ? '' : data.data.unitofMeasureId;
        this.getLocationLevel(data.data.companyId);
        this.SelectedLocation = data.data.partLocationName == null ? '' : data.data.partLocationName;
        this.selectedLocationName = data.data.locationName;
        this.SelectedPartLocation = data.data.partLocation == null ? '' : data.data.partLocation;
        this.getPartLocationLevel(parseInt(data.data.partLocationName),data.data.locationName);
        this.Getvendordropdownlists(data.data.companyId, this.selectedVendorValue);
        this.AdditionalDiscountLabel = data.data.isDiscount;
        this.ADiscount = data.data.discount * 100;
        this.AVendor = data.data.vendorName;
        this.Getmultivendordropdownlist(data.data.companyId, undefined, undefined, undefined);
        this.ResetPopModelData();
        this.ResetPopModelData1();
        this.togglePopModel('ok');
        this.WordpressCategoryLoad(data.data.companyId);
        this.questionArray.controls.splice(0, this.questionArray.controls.length);
        if (data.data.customPart.length > 0) {
          this.btnShowvalidation = true;
          this.partList = data.data.customPart;
          this.SubTotalVendorCost = 0
          this.SubTotalPartCost = 0;
          this.SubTotalLaborHours = 0;
          this.isPartval = false;
          this.partList.forEach(partLst => {
            this.SubTotalVendorCost += Number(partLst.vendorParCost * partLst.quantity);
            this.SubTotalPartCost += Number(partLst.cost * partLst.quantity);
            this.SubTotalLaborHours += Number(Number(partLst.hours * partLst.quantity).toFixed(2));

          });

        }

        this.questionArray.controls.push(
          new FormGroup({
            questionId: new FormControl(0),
            partId: new FormControl(0)
          })
        );
        this.InvProductOrderDropdownList1(data.data.companyId, data.data.id);
        this.InvPartOrderDropdownList1(data.data.companyId, data.data.id);
        this.GetPartVendors(data.data.companyId, data.data.id);
        this.minInventory = data.data.minInventory;

        let companyID = data.data.companyId;
        this.sharedService.getAllProductionRole(companyID, '').subscribe(
          data1 => {
            this.data = data1;
            if (companyID > 0) {
              this.userRoleList = this.data.dataList;
            }
            else {
              this.userRole = '';
            }

            this.userRole = data.data.productionRole;
            this.changeUserRole(this.userRole);
            if (data.data.skillLevel) {
              this.skillLevel = data.data.skillLevel;
            }
            else {
              this.skillLevel = '0';
            }
          });

        this.getAllWorkflowSteps(data.data.companyId);
        this.WorkflowSteps = data.data.workflowStep ? data.data.workflowStep : '';

        for (var item = 0; item < data.data.partQuestions.length; item++) {
          this.questionArray.controls.push(
            new FormGroup({
              questionId: new FormControl(data.data.partQuestions[item].questionId),
              partId: new FormControl(data.data.partQuestions[item].partId)
            })
          )
        }
        const cid = data.data.companyId;
        const Inventory = data.data.partInventory;
        this.partService.PartInvOrderDropdownList(1, data.data.companyId).subscribe(
          (data: any) => {
            this.data = data;
            this.InvProductOrderDDL = this.data.dataList;
            this.mInvProductOrderDDL = this.data.dataList;
            this.InvPartOrderDropdownList(cid);

            if (Inventory.length > 0) {
              this.isInvFind = true;
              this.InvList = [];
              for (var item = 0; item < Inventory.length; item++) {

                let LocalInvType2 = '';
                if (Inventory[item].typeId == "5") {
                  LocalInvType2 = Inventory[item].serviceNo;
                }
                else if (Inventory[item].typeId == "6") {
                  LocalInvType2 = Inventory[item].partOrderId;
                }
                else if (Inventory[item].typeId == "7") {
                  LocalInvType2 = Inventory[item].productOrderId;
                }

                let qty: number = 0;
                let orderqty: number = 0;
                if (Inventory[item].purchaseOrderId == null || Inventory[item].purchaseOrderId == "") {
                  qty = Inventory[item].qty;
                }
                else {
                  let recQty = Inventory[item].qty == null || Inventory[item].qty == "" ? 0 : Inventory[item].qty;
                  let shipQty = Inventory[item].shipQty == null || Inventory[item].shipQty == "" ? 0 : Inventory[item].shipQty;
                  qty = parseInt(recQty) - parseInt(shipQty);
                }

                orderqty = Inventory[item].orderedQty == null || Inventory[item].orderedQty == "" ? 0 : Inventory[item].orderedQty;

                if (Number(qty) != 0) {
                  let obj = {
                    InvSno: this.InvSnoEdit,
                    id: Inventory[item].id,
                    partId: Inventory[item].partId,
                    InvQty: qty,
                    InvQtyOrdered: orderqty,
                    InvDate: this.datepipe.transform(Inventory[item].date, 'yyyy-MM-dd'),
                    InvCost: Inventory[item].cost,
                    InvType: Inventory[item].typeId,
                    InvType2: LocalInvType2,
                    InvCustNo: Inventory[item].customerSo,
                    InvCustRMA: Inventory[item].customerRma,
                    InvPartLocation: Inventory[item].partLocation,
                    purchaseOrderId: Inventory[item].purchaseOrderId,
                    shipQty: Inventory[item].shipQty,
                    InvVendor: Inventory[item].vendorId
                  }
                  this.InvList.push(obj);
                  this.InvSnoEdit += 1;
                }
              }
            }

            else {
              this.isInvFind = false;
              this.InvList = [];
            }
          });


      });

  }


  changeUserRole(event) {
    if (event) {
      const getRole = this.userRoleList.filter(x => x.id == event);
      this.getSkillLevel(this.CompanyValue, event);
      if (getRole[0].isSkillRequired == true) {
        this.form.controls['skillLevel'].enable();
      }
      else {
        this.form.controls['skillLevel'].disable();
        this.skillLevel = '0';
      }
    }
    else {
      this.skillLevel = '0';
      this.form.controls['skillLevel'].disable();
    }
    this.userRole = event;
  }

  onchangeEvent(event) {
    this.qnsId = '';
    this.qnsId = parseInt(event);
    $('#requiredmessage').html("")
  }

  onEnterVendorPartName(event: any) {
    if (!this.id) {
      this.name = event.target.value;
    }
  }

  onEnterVendorPartNumber(event: any) {
    if (!this.id) {
      this.partNo = event.target.value;
    }
  }

  SetSettingCSS(settingCSS, logoImagePath) {
    if (settingCSS != null) {
      let headerCss = document.getElementsByClassName(this.constants.CssHealerClass) as HTMLCollectionOf<HTMLElement>;
      if (headerCss.length != 0) {
        headerCss[0].style.backgroundColor = settingCSS.split(',')[0];
      }
      let saveButtonCss = document.getElementsByClassName(this.constants.CssSaveBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (saveButtonCss.length != 0) {
        saveButtonCss[0].style.backgroundColor = settingCSS.split(',')[1];
        $(".blue-bg").css("background", settingCSS.split(',')[1]);
        $(".upload_btn").css("background", settingCSS.split(',')[1]);
      }
      let cencelButtonCss = document.getElementsByClassName(this.constants.CssCancelBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (cencelButtonCss.length != 0) {
        cencelButtonCss[0].style.backgroundColor = settingCSS.split(',')[2];
        $(".darkred-bg").css("background", settingCSS.split(',')[2]);
      }
    }
    if (logoImagePath != null && logoImagePath != "") {
      (document.getElementById('hrdlogoImage') as HTMLImageElement).src = logoImagePath;
    }
  }

  get questionArray() {
    return this.form.get('questionIds') as FormArray;
  }

  closenoti() {
    this.mQtyMoveMsg = false;
  }

  AddQuestionDropdownList(i) {
    if (this.qnsId > 0) {
      this.questionArray.controls.push(
        new FormGroup({
          questionId: new FormControl(this.qnsId),
          partId: new FormControl(this.id == null ? 0 : this.id)
        })
      )
      this.qnsId = '';
      for (let index = 1; index < this.questionArray.controls.length; index++) {
        const items = this.questionlist.find(x => x.id == this.questionArray.controls[index].value.questionId);
        if (items) {
          const indx = this.questionlist.findIndex(x => x.id == this.questionArray.controls[index].value.questionId);
          this.questionlist.splice(indx, 1);
        }
      }
      $('#requiredmessage').html("")
    }
    else {
      $('#requiredmessage').html("Please Select Question")
    }
  }

  RemoveQuestionDropdownList(index) {
    const deletedItem = this.questionArray.controls.splice(index, 1);
    const arrayItem = this.questionlistNew.find(x => x.id === deletedItem[0].value.questionId);
    if (arrayItem) {
      this.questionlist.push(arrayItem);
    }
    $('#requiredmessage').html("");
  }

  getSkillLevel(id, roleId) {
    this.sharedService.getSkillLevelUser(id, roleId).subscribe(
      data => {
        this.data = data;
        if (this.data.dataList) {
          this.skillLevelList = this.data.dataList;
        }
        else {
          this.skillLevelList = '';
        }
      });
  }

  getAllWorkflowSteps(companyId) {
    this.sharedService.getAllWorkflows(companyId, '').subscribe(
      data => {
        this.data = data;
        if (companyId > 0) {
          this.WorkflowList = this.data.dataList;
        }
        else {
          this.WorkflowList = '';
        }
      });
  }

  GetAllPart(type) {
    this.value_search == undefined ? 0 : this.value_search;
    this.viewLog = false;
    this.loader = true;
    $('#datatable').DataTable().destroy();
    this.dtTrigger.next('');
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      ordering: false,
      ajax: (dataTablesParameters: any, callback) => {
        this.partService.GetAllCustomPart(this.value_search, this.userSearch ? this.userSearch : dataTablesParameters.search.value, dataTablesParameters.start, dataTablesParameters.length, type)
          .subscribe(resp => {
            this.loader = false;
            let totalRows = null;
            this.data = resp;
            if (this.data.dataList.length > 0) {
              this.datalist = this.data.dataList;
              totalRows = [...new Set(this.datalist.map(item => item.totalRows))];
            }
            else {
              this.datalist = null;
              totalRows = 0;
            }

            callback({
              recordsTotal: totalRows,
              recordsFiltered: totalRows,
              data: []
            });
          });

      },
      columns: [{ data: 'companyName' }, { data: 'name' }, { data: 'partNo' }, { data: 'vendorName' }, { data: 'vendorPartName' }, { data: 'vendorPartNumber' }, { data: 'cost' }, { data: 'hours' }],
    };
    this.rerender("one");
  }


  deletePopup(item, content) {

    this.modalService.open(content, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
    this.deleteDetail = 'Are you sure, you want to delete  ' + item.name + '?';
    this.deletedItem = item.id;
  }

  deleteCustomPartPopup(item, contentPart) {
    this.modalService.open(contentPart, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
    this.deleteDetail = 'Are you sure, you want to delete Part Item?';
    this.deleteCustomPart = item;
  }

  notdelete_success(){
    $("#notdelete_success").hide();
  }
  deletePart(item: any) {
    this.loader = true;
    this.partService.DeletePart(item).subscribe(
      data => {
        this.data = data;
        switch (data.statusCode) {
          case 200:
            if(data.data == "-1"){
              this.deleteUnsuccessMsg = "Sales Order already exist related with this custom part, this custom part can't be deleted!"
               $("#notdelete_success").show();
            }
            else if(data.data == "-2"){
              this.deleteUnsuccessMsg = "Purchase Order already exist related with this custom part, this custom part can't be deleted!"
              $("#notdelete_success").show();
            }
            else if(data.data == "-3"){
              this.deleteUnsuccessMsg = "Inventory already exist related with this custom part, this custom part can't be deleted!"
              $("#notdelete_success").show();
            }
            else{
              $("#delete_success").show();
              this.GetAllPart("1");
              this.PartBack();
        //      this.rerender();
            }
        
            break;
          case 400:
            $("#wrong_msg").show();
            break;
          default:
            $("#wrong_msg").show();
        }
        this.loader = false;
      });
  }

  UnitofMeasurementDropdownList() {

    this.partService.UnitofMeasurement().subscribe(
      data => {
        this.data = data;
        this.unitofmeasurement = this.data.dataList;
        this.Getquestiondropdownlist(this.companyId);
        this.GetAllImageTypeList();

      }
    )
  }

  Getvendordropdownlist(companyId) {
    this.partService.Getvendordropdownlist(companyId).subscribe(
      data => {
        this.data = data;
        if (companyId > 0) {
          this.vendorlist = this.data.dataList;
        }
        else {
          this.selectedVendorValue = '';
        }
        this.GetpartGategoryDropdownList(companyId);
        this.GetCustomCategory(companyId);

      }
    )
  }


  GetCustomCategory(companyId) {
    this.partService.GetpartGategoryDropdownList(companyId, '').subscribe(
      data => {
        this.data = data;
        if (companyId > 0) {
          this.Customlist = this.data.dataList;
        }
        else {
          this.CustomCategory = '';
        }
      }
    )
  }

  GetpartGategoryDropdownList(company) {
    this.partService.GetpartGategoryDropdownList(company, '').subscribe(
      data => {
        this.data = data;
        if (company > 0) {
          let categoryList = [];
          this.categorylist = this.data.dataList.filter(x => x.id != this.constants.MisceType);
          this.catdropdownList = this.categorylist;
          this.categorylist.forEach((item, index) => {
            let obj = {
              partcategoryid: item.id + '-' + item.wordpressId,
              partcategorytext: item.name,
            }
            categoryList.push(obj);

          });
          this.catdropdownList = categoryList;
        }
        else {
          this.selectedPartCategoryIdValue = '';
        }

      }
    )
  }

  InvTypeDropdownList() {
    this.sharedService.GetPOTypeDetail().subscribe(
      data => {
        this.data = data;
        this.InvTypeList = this.data.dataList;
        this.mInvTypeList = this.data.dataList;
      }
    )
  }

  InvProductOrderDropdownList(companyId) {
    // Pass 1 For Product Order
    if (companyId == undefined) { companyId = 0; }
    this.partService.PartInvOrderDropdownList(1, companyId).subscribe(
      data => {
        this.data = data;
        if (companyId > 0) {
          this.InvProductOrderDDL = this.data.dataList;
          this.mInvProductOrderDDL = this.data.dataList;
        }
        else {

        }
      }
    );
  }

  InvPartOrderDropdownList(companyId) {
    // Pass 2 For Part Order
    if (companyId == undefined) { companyId = 0; }
    this.partService.PartInvOrderDropdownList(2, companyId).subscribe(
      data => {
        this.data = data;
        if (companyId > 0) {
          this.InvPartOrderDDL = this.data.dataList;
          this.mInvPartOrderDDL = this.data.dataList;
        }
        else {

        }
      }
    );
  }

  DeleteImageModalpopup(i, content) {
    this.modalService.open(content, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
    this.deleteDetail = 'Are you sure, you want to delete?';
    this.deletedItemImage = i;
  }

  Getquestiondropdownlist(companyId) {
    this.partService.Getquestiondropdownlist(companyId).subscribe(
      data => {
        this.data = data;
        if (companyId > 0) {
          this.questionlist = this.data.dataList;
        }
        this.tempQuestionlist = this.data.dataList;
        this.questionlistNew = [...this.data.dataList];
        this.Type = 1;
        // this.GetAllPart(this.companyId, this.userSearch == "" ? "" : this.userSearch, 1);

      }
    )
  }
  AlreadyVendorExist() {
    $('#AlreadyVendorExist').hide();
    this.selectedVendorValue = '';
    this.AdditionalDiscountLabel = false;
    this.ADiscount = "";
    this.AVendor = "";
  }
  onValueChange(e) {
    var value = e.target.value;
    if (value != "") {
      const SpiltValue = value.split("-");
      const Discount = SpiltValue[1];
      const vendorValue = SpiltValue[0];

      var CheckExistPartList = this.partVendorsList.filter(x => x.Id == vendorValue && x.type == "2");
      if (CheckExistPartList.length > 0) {
        this.vendorText = CheckExistPartList[0].name;
        $('#AlreadyVendorExist').show();
        return;

      }
      else {
        var checklist = this.partVendorsList.filter(x => x.type == "1");
        if (checklist.length > 0) {
          var updateInvList = this.InvList.filter(x => x.InvVendor == checklist[0].Id);
          if (updateInvList.length > 0) {
            updateInvList.forEach(element => {
              element.InvVendor = Number(vendorValue);
            });

          }
          checklist[0].Id = Number(vendorValue);
          checklist[0].name = e.target.options[e.target.options.selectedIndex].text;
          checklist[0].type = "1";
        }
        else {
          var obj = {
            "Id": Number(vendorValue),
            "name": e.target.options[e.target.options.selectedIndex].text,
            "type": "1"
          }
          this.partVendorsList.push(obj);
        }

        if (Discount != 0) {
          this.AdditionalDiscountLabel = true;
          this.vendorService.GetVendorById(vendorValue).subscribe(
            (data: any) => {
              this.loader = false;
              this.ADiscount = data.data.discount * 100;
              this.AVendor = data.data.vendorName;
            });
        }
        else {
          this.AdditionalDiscountLabel = false;
          this.ADiscount = "";
          this.AVendor = "";
        }
      }
    }
    else {
      this.selectedVendorValue = '';
      this.AdditionalDiscountLabel = false;
      this.ADiscount = "";
      this.AVendor = "";
    }
  }

  companyChangeValue(value: any): void {
    if (value == undefined) {
      value = 0;
    }
    this.CompanyValue = value;
    this.Getvendordropdownlist(this.CompanyValue);
    this.Getmultivendordropdownlist(this.CompanyValue, undefined, undefined, undefined);
    this.Getquestiondropdownlist(this.CompanyValue);
    this.getAllProductionRole(this.CompanyValue);
    this.getAllWorkflowSteps(this.CompanyValue);
    this.WordpressCategoryLoad(this.CompanyValue);
    this.getSkillLevel(this.CompanyValue, this.userRole);
    this.getLocationLevel(this.CompanyValue);
    this.selectedVendorValue = "";
    this.selectedItems = [];
  }

  ChngSettingImage(files: FileList) {


    this.fileToUpload = files.item(0);

    var reader = new FileReader()
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
      this.Documentype = this.fileToUpload.type == 'application/pdf' ? 'PDF' : this.fileToUpload.type == "application/msword" ? "Word" : "";
    }

    reader.readAsDataURL(this.fileToUpload);
  }

  AddMultiplePartImage() {

    this.validationImage = true;
    this.PictureValue = this.myInputVariable.nativeElement.value;
    if (this.PictureValue == "" || this.SelectedImageTypeId == '') {
      return;
    }
    let ojtmultiplepart = {
      partypeName: this.SelectedImageTypeId,
      imageUrl: this.Documentype == "PDF" ? this._sanitizer.bypassSecurityTrustHtml(this.imageUrl) : this.imageUrl,
      documenttype: this.Documentype
    }

    if (this.MultipleImage.length <= 3) {
      this.MultipleImage.push(ojtmultiplepart);
      // this.DefaultLogoPDF='';
      //this.WordFile='';
      this.SelectedImageTypeId = '';
      this.imageUrl = '';
      this.validationImage = false;
      this.myInputVariable.nativeElement.value = "";
    }
    else {
      $("#myModalExist").show();
    }
  }

  ImageHide() {
    $("#myModalExist").hide();
  }

  resetvendorData() {
    this.SelectedLocation = '';
    this.SelectedPartLocation = '';
    this.recordAlreadyExist = false;
    this.vendorId = '';
    this.name = '';
    this.partNo = '';
    this.description = '';
    this.cost = '';
    this.VendorPartCost = '';
    this.hours = '';
    this.search = '';
    this.installationNotes = '';
    this.partCategoryId = '';
    this.selectedVendorValue = '';
    this.selectedPartCategoryIdValue = '';
    this.partList = [];
    this.CategoryId = '';
    this.SubTotalPartCost = '';
    this.PartsDetails = [];
    this.SubTotalVendorCost = '';
    this.CustomCategory = '';
    this.SubTotalLaborHours = '';
    this.qty = 1;
    this.SelectedUnitMeasure = '';
    this.minInventory = '';
    this.userRole = '';
    this.skillLevel = '0';
    this.partTracking = '';
    this.form.controls['skillLevel'].disable();
    this.WorkflowSteps = '';
    this.partLocation = '';
    this.partLeadTime = '';
    this.reorderAmount = '';
    this.installationNotes = '';
    this.VendorPartName = '';
    this.VendorPartNumber = '';
    this.viewLog = false;
    this.isInvFind = false;
    this.AdditionalDiscountLabel = false;
    this.weight = '';
    this.height = '';
    this.width = '';
    this.length = '';
    this.NewPartialArray = [];
    this.MarkupCheck = true;
    this.MarkupMargin = '';
    this.NotActive = false;
    this.AddvendorList = [];
    this.CategoryArr = [];
    this.selectedItems = [];
    this.DeActiveCouting = true;
    this.ActiveCouting = false;
    this.AddvendorList1 = [];
    this.AddvendorList2 = [];
    this.IsUpdate = false;
    this.IsSave = true;
    this.NonInventory = false;
    this.imageUrl = '/assets/image/add-part.png';
    this.dynamicCustomerDiscount = [];
    this.dynamicCustomerDiscount.push({ 'Qty': '', 'DiscountPrice': '', 'cost': '', 'id': '0', 'isActive': false, 'CreateDate': '', 'CreatedBy': '','isReadOnly': false,'IsDReadonly': false  });
    this.dynamicArray = [];
    this.dynamicArray.push({ 'Qty': '', 'DiscountPrice': '', 'cost': '', 'id': '0', 'isActive': false, 'CreateDate': '', 'CreatedBy': '','isReadOnly': false,'IsDReadonly': false  });
    if (this.companyId > 0) {
      this.value = this.companyId
    }
    else {
      this.value = 0;
    }


    if (this.questionArray.length > 0) {
      const deletedItem = this.questionArray.controls.splice(1, this.questionArray.length - 1);
      for (let i = 0; i < deletedItem.length; i++) {
        const arrayItem = this.questionlistNew.find(x => x.id === deletedItem[i].value.questionId);
        if (arrayItem) {
          if (this.questionlist != undefined) {
            this.questionlist.push(arrayItem);
          }

        }
      }
    }

    this.InvQty = '';
    this.InvDate = '';
    this.InvCost = '';
    this.InvType = '';
    this.InvCustNo = '';
    this.InvServNo = '';
    this.InvCustRMA = '';
    this.InvPartLocation = '';
    this.InvList = [];
    this.SaveInvList = [];
    this.SaveLogList = [];
    this.logList = [];
    this.InvDetails = [];
    this.MultipleImage = [];
    this.PartImageArray = [];
    this.PartHistory = false;
    this.miscellenouseType = '';
    this.isTotalInvQty = false;
    this.TotalAllType = '';
    this.SaveTotalAllType = '';
    this.TotalTypeInventory = '';
    this.SaveTotalTypeInventory = '';
    this.TotalInvLog = '';
    this.SaveTotalInvLog = '';
    this.ResetPopModelData();
    this.ResetPopModelData1();
    this.WordpressCategoryLoad(this.CompanyValue);
  }

  AddPartialPart() {
    this.NewPartialArray = [];
    this.popvalidation = true;
    if (this.newform.controls.shortDescription.status === this.constants.Invalid ||
      this.newform.controls.MatingName.status === this.constants.Invalid ||
      this.newform.controls.CrimpName.status === this.constants.Invalid ||
      this.newform.controls.RelatedName.status === this.constants.Invalid
    ) {
      return;
    }
    let objpartial = {};
    objpartial['MoreInformation'] = String(this.shortDescription);
    objpartial['InformationName'] = String(this.InfoName);
    objpartial['MatingName'] = String(this.MatingName);
    objpartial['CrimpName'] = String(this.CrimpName);
    objpartial['RelatedName'] = String(this.RelatedName);
    let invArr1 = this.CustomerPartNameList;
    let invObj = [];
    for (var j = 0; j < invArr1.length; j++) {
      if (invArr1[j].IsChecked == true) {
        var inv1 = {};
        inv1["ProductId"] = invArr1[j].wordpressId;
        inv1["Type"] = "Mating";
        inv1["isChecked"] = true;
        inv1["CategoryId"] = invArr1[j].partCategoryId;
        invObj.push(inv1);
      }
    }
    objpartial['ApiWordpressProduct'] = invObj;
    let invArr2 = this.CustomerPartNameCrimpList;
    for (var j = 0; j < invArr2.length; j++) {
      if (invArr2[j].IsChecked == true) {
        var inv2 = {};
        inv2["ProductId"] = invArr2[j].wordpressId;
        inv2["Type"] = "Crimp";
        inv2["isChecked"] = true;
        inv2["CategoryId"] = invArr2[j].partCategoryId;
        invObj.push(inv2);
      }
    }
    objpartial['ApiWordpressProduct'] = invObj;
    let invArr3 = this.CustomerPartNameRelatedList;

    for (var j = 0; j < invArr3.length; j++) {
      if (invArr3[j].IsChecked == true) {
        var inv3 = {};
        inv3["ProductId"] = invArr3[j].wordpressId;
        inv3["Type"] = "Related";
        inv3["isChecked"] = true;
        inv3["CategoryId"] = invArr3[j].partCategoryId;
        invObj.push(inv3);
      }
    }
    objpartial['ApiWordpressProduct'] = invObj;

    this.NewPartialArray.push(objpartial)
    $("#CheckCategoryModal").hide();
  }
  UpdatePartialPart() {
    this.popvalidation = true;
    if (this.newform.controls.shortDescription.status === this.constants.Invalid ||
      this.newform.controls.MatingName.status === this.constants.Invalid ||
      this.newform.controls.CrimpName.status === this.constants.Invalid ||
      this.newform.controls.RelatedName.status === this.constants.Invalid

    ) {
      return;
    }
    this.ResetPopModelData1();
    let objpartial = {};
    objpartial['MoreInformation'] = String(this.shortDescription);
    objpartial['InformationName'] = String(this.InfoName);
    objpartial['MatingName'] = String(this.MatingName);
    objpartial['CrimpName'] = String(this.CrimpName);
    objpartial['RelatedName'] = String(this.RelatedName);

    let invArr1 = this.CustomerPartNameList;
    let invObj = [];
    for (var j = 0; j < invArr1.length; j++) {
      if (invArr1[j].isActive == true) {
        var inv1 = {};
        inv1["ProductId"] = invArr1[j].wordpressId;
        inv1["Type"] = "Mating";
        inv1["isChecked"] = true;
        inv1["CategoryId"] = invArr1[j].partCategoryId;
        inv1["Id"] = invArr1[j].partId;
        invObj.push(inv1);
      }

      if (invArr1[j].IsChecked == true) {
        var inv1 = {};
        inv1["ProductId"] = invArr1[j].wordpressId;
        inv1["Type"] = "Mating";
        inv1["isChecked"] = true;
        inv1["CategoryId"] = invArr1[j].partCategoryId;
        invObj.push(inv1);
      }
      else if (invArr1[j].IsChecked == false) {
        var inv1 = {};
        inv1["ProductId"] = invArr1[j].wordpressId;
        inv1["Type"] = "Mating";
        inv1["isChecked"] = false;
        inv1["CategoryId"] = invArr1[j].partCategoryId;
        inv1["Id"] = invArr1[j].partId;
        invObj.push(inv1);
      }
      else { }
    }

    for (var j = 0; j < this.MatingDisableList.length; j++) {
      if (this.MatingDisableList[j].isActive == true) {
        var inv1s = {};
        inv1s["ProductId"] = this.MatingDisableList[j].wordpressId;
        inv1s["Type"] = "Mating";
        inv1s["isChecked"] = false;
        inv1s["CategoryId"] = this.MatingDisableList[j].partCategoryId;
        inv1s["Id"] = this.MatingDisableList[j].partId;
        invObj.push(inv1s);
      }
    }

    objpartial['ApiWordpressProduct'] = invObj;
    let invArr2 = this.CustomerPartNameCrimpList;
    for (var j = 0; j < invArr2.length; j++) {
      if (invArr2[j].isActive == true) {
        var inv2 = {};
        inv2["ProductId"] = invArr2[j].wordpressId;
        inv2["Type"] = "Crimp";
        inv2["isChecked"] = true;
        inv2["CategoryId"] = invArr2[j].partCategoryId;
        inv2["Id"] = invArr2[j].partId;
        invObj.push(inv2);
      }

      if (invArr2[j].IsChecked == true) {
        var inv2 = {};
        inv2["ProductId"] = invArr2[j].wordpressId;
        inv2["Type"] = "Crimp";
        inv2["isChecked"] = true;
        inv2["CategoryId"] = invArr2[j].partCategoryId;
        invObj.push(inv2);
      }
      else if (invArr2[j].IsChecked == false) {
        var inv2 = {};
        inv2["ProductId"] = invArr2[j].wordpressId;
        inv2["Type"] = "Crimp";
        inv2["isChecked"] = false;
        inv2["CategoryId"] = invArr2[j].partCategoryId;
        inv2["Id"] = invArr2[j].partId;
        invObj.push(inv2);
      }
      else { }

    }

    for (var j = 0; j < this.CrimpDisableList.length; j++) {
      if (this.CrimpDisableList[j].isActive == true) {
        var inv2s = {};
        inv2s["ProductId"] = this.CrimpDisableList[j].wordpressId;
        inv2s["Type"] = "Crimp";
        inv2s["isChecked"] = false;
        inv2s["CategoryId"] = this.CrimpDisableList[j].partCategoryId;
        inv2s["Id"] = this.CrimpDisableList[j].partId;
        invObj.push(inv2s);
      }
    }

    objpartial['ApiWordpressProduct'] = invObj;
    let invArr3 = this.CustomerPartNameRelatedList;
    for (var j = 0; j < invArr3.length; j++) {
      if (invArr3[j].isActive == true) {
        var inv3 = {};
        inv3["ProductId"] = invArr3[j].wordpressId;
        inv3["Type"] = "Related";
        inv3["isChecked"] = true;
        inv3["CategoryId"] = invArr3[j].partCategoryId;
        inv3["Id"] = invArr3[j].partId;
        invObj.push(inv3);
      }


      if (invArr3[j].IsChecked == true) {
        var inv3 = {};
        inv3["ProductId"] = invArr3[j].wordpressId;
        inv3["Type"] = "Related";
        inv3["isChecked"] = true;
        inv3["CategoryId"] = invArr3[j].partCategoryId;
        invObj.push(inv3);
      }
      else if (invArr3[j].IsChecked == false) {
        var inv3 = {};
        inv3["ProductId"] = invArr3[j].wordpressId;
        inv3["Type"] = "Related";
        inv3["isChecked"] = false;
        inv3["CategoryId"] = invArr3[j].partCategoryId;
        inv3["Id"] = invArr3[j].partId;
        invObj.push(inv3);
      }
      else { }
    }

    for (var j = 0; j < this.RelatedDisableList.length; j++) {
      if (this.RelatedDisableList[j].isActive == true) {
        var inv3s = {};
        inv3s["ProductId"] = this.RelatedDisableList[j].wordpressId;
        inv3s["Type"] = "Related";
        inv3s["isChecked"] = false;
        inv3s["CategoryId"] = this.RelatedDisableList[j].partCategoryId;
        inv3s["Id"] = this.RelatedDisableList[j].partId;
        invObj.push(inv3s);
      }
    }
    objpartial['ApiWordpressProduct'] = invObj;
    this.NewPartialArray.push(objpartial);
    $("#CheckCategoryModal").hide();
  }

  AddPart() {
    
    this.validation = true;
    this.recordAlreadyExist = false;
    this.clearbutton = true;
    if (this.form.controls.name.status === this.constants.Invalid ||
      this.form.controls.partNo.status === this.constants.Invalid ||
      this.form.controls.cost.status === this.constants.Invalid ||
      this.form.controls.hours.status === this.constants.Invalid ||
      this.form.controls.vendorId.status === this.constants.Invalid ||
      this.CompanyValue === 0 ||
      this.form.controls.minInventory.status === this.constants.Invalid
    ) {
      return;
    }
    if (this.MarkupCheck == true) {
      if (this.form.controls.MarkupMargin.status === this.constants.Invalid) {
        return;
      }

    }
    else {
      this.form.controls.MarkupMargin.status === "VALID";
    }
    if (this.selectedItems.length == 0) {
      $('#NultiCategoryModel').show();
      return;
    }


    if (this.partList.length == 0) {
      $("#myModal").show();
      return;
    }


    let obj = {};
    const Defaultimage = '/assets/image/add-part.png';
    obj['name'] = this.name;
    obj['partNo'] = this.partNo;
    obj['description'] = this.description;
    obj['cost'] = parseFloat(this.cost);
    obj['Hours'] = (this.hours).toString();
    obj['search'] = this.search;
    obj['InstallationNotes'] = this.installationNotes;
    obj['TotalAllType'] = Number(this.TotalAllType);
    obj['TotalTypeInventory'] = Number(this.TotalTypeInventory);
    obj['TotalInvLog'] = Number(this.TotalInvLog);
    obj['partImage'] = this.PartImage;
    obj['companyId'] = Number(this.CompanyValue);
    const SpiltValue = this.selectedVendorValue.split("-");
    const Discount = SpiltValue[1];
    const vendorValue = parseInt(SpiltValue[0]);
    obj['vendorId'] = vendorValue;
    // obj['vendorId'] = parseInt(this.selectedVendorValue);
    obj['partCategoryId'] = Number(0);
    obj['VendorPartCost'] = parseFloat(this.VendorPartCost);
    obj['IsCustompart'] = true;
    obj['UnitofMeasureId'] = parseInt(this.SelectedUnitMeasure);
    obj['MinInventory'] = Number(this.minInventory);
    obj['SkillLevel'] = Number(this.skillLevel);
    obj['ProductionRole'] = this.userRole;
    obj['WorkflowStep'] = Number(this.WorkflowSteps);
    // add three properties as suggested by client 28_02_2022
    obj['partLocation'] = this.SelectedPartLocation;
    obj['partLocationName'] = this.SelectedLocation;
    // obj['partLocation'] = this.partLocation;
    obj['partLeadTime'] = parseInt(this.partLeadTime);
    obj['reorderAmount'] = parseInt(this.reorderAmount);
    obj['VendorPartName'] = this.VendorPartName;
    obj['VendorPartNumber'] = this.VendorPartNumber;
    if (this.DimentionTypeH == 2) {
      obj['Height'] = String(this.height == undefined ? 0 : this.height);
      obj['DimentionType'] = String(this.DimentionTypeH);
    }
    else if (this.DimentionTypeH == 3) {
      obj['Height'] = String((this.height == undefined ? 0 : this.height) * 12);
      obj['DimentionType'] = String(this.DimentionTypeH);
    }
    else {
      obj['Height'] = String((this.height == undefined ? 0 : this.height) * 0.393701);
      obj['DimentionType'] = String(this.DimentionTypeH);
    }
    if (this.DimentionTypeL == 2) {
      obj['Length'] = String(this.length == undefined ? 0 : this.length);
      obj['DimentionType'] = String(this.DimentionTypeL);
    }
    else if (this.DimentionTypeL == 3) {
      obj['Length'] = String((this.length == undefined ? 0 : this.length) * 12);
      obj['DimentionType'] = String(this.DimentionTypeL);
    }
    else {
      obj['Length'] = String((this.length == undefined ? 0 : this.length) * 0.393701);
      obj['DimentionType'] = String(this.DimentionTypeL);
    }
    if (this.DimentionTypeW == 2) {
      obj['Width'] = String(this.width == undefined ? 0 : this.width);
      obj['DimentionType'] = String(this.DimentionTypeW);
    }
    else if (this.DimentionTypeW == 3) {
      obj['Width'] = String((this.width == undefined ? 0 : this.width) * 12);
      obj['DimentionType'] = String(this.DimentionTypeW);
    }
    else {
      obj['Width'] = String((this.width == undefined ? 0 : this.width) * 0.393701);
      obj['DimentionType'] = String(this.DimentionTypeW);
    }
    if (this.WeightType == 3) {
      obj['Weight'] = Number(this.weight ?? "");
      obj['WeightType'] = String(this.WeightType);
    }
    else if (this.WeightType == 2) {
      obj['Weight'] = Number(this.weight * 0.0625 ?? "");
      obj['WeightType'] = String(this.WeightType);
    }
    else {
      obj['Weight'] = Number(this.weight * 2.20462 ?? "");
      obj['WeightType'] = String(this.WeightType)
    }

    obj['WordpressId'] = Number(this.Pwordpressid ?? 0);
    obj['autoMarkup'] = this.MarkupCheck;
    obj['markupMargin'] = Number(this.MarkupMargin);
    obj['partTracking']  = Number(this.partTracking);
    obj['LocationName'] = this.selectedLocationName;
    this.PartsDetails = [];
    let dataPart = this.partList;
    for (var j = 0; j < dataPart.length; j++) {
      let partObj = {};
      partObj["PartId"] = parseInt(dataPart[j].partId);
      partObj["PartName"] = dataPart[j].partName;
      partObj["PartNumber"] = dataPart[j].partNumber;
      partObj["Cost"] = parseFloat(dataPart[j].cost);
      partObj["Hours"] = (dataPart[j].hours).toString();
      partObj["VendorParCost"] = parseFloat(dataPart[j].vendorParCost);
      partObj["Quantity"] = parseInt(dataPart[j].quantity);
      partObj["IsMisc"] = dataPart[j].isMisc;
      partObj["CategoryId"] = parseInt(dataPart[j].categoryId);
      partObj["vendorId"] = parseInt(dataPart[j].vendorId);
      partObj["vendorPartName"] = dataPart[j].vendorPartName;
      partObj["vendorPartNumber"] = dataPart[j].vendorPartNumber;
      partObj["Id"] = dataPart[j].id;
      partObj["CreatedDate"] = this.now = new Date();
      partObj["CreatedBy"] = this.userId;
      partObj["IsActive"] = true;
      this.PartsDetails.push(partObj);
    }
    obj["CustomPart"] = this.PartsDetails;
    ///For MultipleImages
    this.PartImageArray = [];
    let dataPartimages = this.MultipleImage;
    for (var j = 0; j < dataPartimages.length; j++) {

      let partObj = {};
      partObj["PartType"] = parseInt(dataPartimages[j].partypeName);
      partObj["DocumentType"] = dataPartimages[j].documenttype;
      partObj['CreatedBy'] = this.userId;
      partObj['IsActive'] = true;
      partObj["CreatedDate"] = this.now = new Date();
      partObj["PartId"] = this.id;
      if (dataPartimages[j].imageUrl.changingThisBreaksApplicationSecurity) {
        dataPartimages[j].imageUrl = dataPartimages[j].imageUrl.changingThisBreaksApplicationSecurity;
      }
      partObj["ImagePath"] = dataPartimages[j].imageUrl == Defaultimage ? null : dataPartimages[j].imageUrl;
      this.PartImageArray.push(partObj);
    }
    obj['isNotActive'] = Boolean(this.NotActive);
    obj["PartImageUpload"] = this.PartImageArray;
    obj['ApiWordpressDetails'] = this.NewPartialArray;
    ///End
    this.InvDetails = [];
    let invArr = this.InvList;
    for (var j = 0; j < invArr.length; j++) {
      let invObj = {};
      let LocalServNo = '';
      let LocalPartOrderId = '';
      let LocalProductOrderId = '';
      // let LocalInvDate = invArr[j].InvDate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3");

      if (invArr[j].InvType == "5") {
        LocalServNo = invArr[j].InvType2;
      }
      else if (invArr[j].InvType == "6") {
        LocalPartOrderId = invArr[j].InvType2;
      }
      else if (invArr[j].InvType == "7") {
        LocalProductOrderId = invArr[j].InvType2;
      }

      invObj["Id"] = invArr[j].id;
      // invObj["RcvdId"] = invArr[j].rcvdId;
      invObj["PartId"] = parseInt(invArr[j].partId);
      invObj["Qty"] = parseInt(invArr[j].InvQty);
      invObj["OrderedQty"] = parseInt(invArr[j].InvQtyOrdered);
      invObj["Date"] = invArr[j].InvDate;
      invObj["Cost"] = parseFloat(invArr[j].InvCost);
      invObj["TypeId"] = parseInt(invArr[j].InvType);
      invObj["CustomerSO"] = invArr[j].MovePid; //CustomerSo use for move feature
      invObj["ServiceNo"] = LocalServNo;
      invObj["PartOrderId"] = parseInt(LocalPartOrderId);
      invObj["ProductOrderId"] = parseInt(LocalProductOrderId);
      invObj["CustomerRMA"] = invArr[j].InvCustRMA;
      invObj["PartLocation"] = invArr[j].InvPartLocation;
      invObj["Reason"] = invArr[j].InvReason;
      invObj["IsActive"] = true;
      invObj["CreatedBy"] = this.userId;
      invObj["CreatedDate"] = this.now = new Date();
      invObj["PurchaseOrderId"] = invArr[j].purchaseOrderId;
      invObj["ShipQty"] = invArr[j].shipQty;
      invObj["VendorId"] = invArr[j].InvVendor;
      this.InvDetails.push(invObj);
    }
    obj["PartInventory"] = this.InvDetails;
    const questionIds = [];
    for (let index = 1; index < this.questionArray.controls.length; index++) {
      questionIds.push(this.questionArray.controls[index].value);
    }
    obj['PartQuestions'] = questionIds;
    this.AddvendorList1 = [];
    const partVendorList = this.AddvendorList;
    for (var j = 0; j < partVendorList.length; j++) {

      let partVendorObj = {};
      partVendorObj['vendorPartCost'] = parseFloat(partVendorList[j].vendorPartCost);
      partVendorObj['cost'] = parseFloat(partVendorList[j].cost);
      partVendorObj['automarkup'] = partVendorList[j].markupCheck;
      partVendorObj['markupMargin'] = Number(partVendorList[j].markupMargin);
      partVendorObj['companyId'] = partVendorList[j].companyId
      partVendorObj['ModifiedBy'] = partVendorList[j].ModifiedBy;
      partVendorObj['CreatedBy'] = partVendorList[j].CreatedBy
      partVendorObj["CreatedDate"] = partVendorList[j].CreatedDate;
      partVendorObj["ModifiedDate"] = partVendorList[j].ModifiedDate;
      partVendorObj["IsActive"] = partVendorList[j].IsActive;
      partVendorObj["vendorId"] = partVendorList[j].vendorId;
      partVendorObj["vendorName"] = partVendorList[j].vendorName;
      partVendorObj["vendorPartName"] = partVendorList[j].vendorPartName;
      partVendorObj["vendorPartNumber"] = partVendorList[j].vendorPartNumber;
      partVendorObj["PartId"] = this.id;
      if (this.id != undefined && this.id != "" && !Number.isNaN(this.id)) {
        partVendorObj["id"] = partVendorList[j].id;
      }
      partVendorObj["vendorDiscount"] = Number(partVendorList[j].Discount);
      this.AddvendorList1.push(partVendorObj);
    }

    let CategoryArr1;
    if (this.id != undefined && !Number.isNaN(this.id)) {
      CategoryArr1 = this.CategoryArr;
    }
    else {
      CategoryArr1 = this.CategoryArr.filter(x => x.isActive == true);
    }

    this.CategoryArr = [];
    for (var j = 0; j < CategoryArr1.length; j++) {
      let items = {};
      // items["Id"] = CategoryArr1[j].Id;
      var str = String(CategoryArr1[j].partcategoryid);
      var includeStr = "-";
      if (str.includes(includeStr)) {
        const categoryValue = CategoryArr1[j].partcategoryid.split('-');
        items['partcategoryid'] = Number(categoryValue[0]);
        items['partWordpressid'] = Number(categoryValue[1]);
        items['partcategorytext'] = CategoryArr1[j].partcategorytext;
      }
      else {
        items['partcategoryid'] = CategoryArr1[j].partcategoryid;
        items['partWordpressid'] = CategoryArr1[j].partWordpressid;
        items['partcategorytext'] = CategoryArr1[j].partcategorytext;
      }
      items["CreatedDate"] = this.now = new Date();
      items["ModifiedDate"] = this.now = new Date();
      items['isActive'] = CategoryArr1[j].isActive;
      items['CreatedBy'] = this.userId;
      if (this.id != undefined && !Number.isNaN(this.id)) {
        items["id"] = CategoryArr1[j].id;
      }


      items["PartId"] = this.id;

      items["CompanyId"] = Number(this.CompanyValue);
      this.CategoryArr.push(items);
    }
    if (this.id != undefined && !Number.isNaN(this.id)) {
      const listA = this.CategoryArr.filter(x => x.id != null);
      const listB = this.CategoryArr.filter(x => x.isActive == true && x.id == null);
      const listC = [...listA, ...listB];
      // listC = [...new Map(listC.map((item) => [item["partcategoryid"], item])).values()];
      obj['multiPartCategory'] = listC;
    }
    else {
      obj['multiPartCategory'] = this.CategoryArr;
    }
    if (this.id != undefined && !Number.isNaN(this.id)) {
      obj['PartVendorList'] = this.AddvendorList1;
    }
    else {
      obj['PartVendorList'] = this.AddvendorList1.filter(x => x.IsActive == true);
    }

    obj['isDiscount'] = this.AdditionalDiscountLabel;
    obj['nonInventory'] = this.NonInventory;
    
    this.VendorDiscountList = [];
    const vendorDiscountList = this.dynamicArray.filter(x => x.id != "" && x.DiscountPrice != "" && x.Qty != "");
    for (var j = 0; j < vendorDiscountList.length; j++) {
      let vendorDiscountObj = {};
      vendorDiscountObj['companyId'] = Number(this.CompanyValue);
      if (vendorDiscountList[j].id != '0') {
        vendorDiscountObj['ModifiedBy'] = this.userId;
        vendorDiscountObj["ModifiedDate"] = this.now = new Date();
        vendorDiscountObj["partId"] = this.id;
        vendorDiscountObj["Id"] = parseInt(vendorDiscountList[j].id);
        vendorDiscountObj['CreatedBy'] = vendorDiscountList[j].CreatedBy;
        vendorDiscountObj["CreatedDate"] = vendorDiscountList[j].CreateDate;
      }
      else {
        vendorDiscountObj['CreatedBy'] = this.userId;
        vendorDiscountObj["CreatedDate"] = this.now = new Date();
        vendorDiscountObj["Id"] = parseInt('0');
      }
      vendorDiscountObj["IsActive"] = vendorDiscountList[j].isActive;
      vendorDiscountObj["Qty"] = parseInt(vendorDiscountList[j].Qty);
      vendorDiscountObj["Discount"] = parseFloat(vendorDiscountList[j].DiscountPrice);
      vendorDiscountObj["VendorCost"] = parseFloat(vendorDiscountList[j].cost);
      this.VendorDiscountList.push(vendorDiscountObj);
    }
    if (this.id != undefined && !Number.isNaN(this.id)) {
      obj['VendorDiscount'] = this.VendorDiscountList;
    }
    else {
      obj['VendorDiscount'] = this.VendorDiscountList.filter(x => x.IsActive == true);
    }

    this.CustomerDiscountList = [];
    const customerDiscountList = this.dynamicCustomerDiscount.filter(x => x.id != "" && x.Qty != "");;
    for (var j = 0; j < customerDiscountList.length; j++) {
      let customerDiscountObj = {};
      customerDiscountObj['companyId'] = Number(this.CompanyValue);
      if (customerDiscountList[j].id != '0') {
        customerDiscountObj['ModifiedBy'] = this.userId;
        customerDiscountObj["ModifiedDate"] = this.now = new Date();
        customerDiscountObj["partId"] = this.id;
        customerDiscountObj["Id"] = parseInt(customerDiscountList[j].id);
        customerDiscountObj['CreatedBy'] = customerDiscountList[j].CreatedBy;
        customerDiscountObj["CreatedDate"] = customerDiscountList[j].CreateDate;
      }
      else {
        customerDiscountObj['CreatedBy'] = this.userId;
        customerDiscountObj["CreatedDate"] = this.now = new Date();
        customerDiscountObj["Id"] = parseInt('0');
      }
      customerDiscountObj["IsActive"] = customerDiscountList[j].isActive;
      customerDiscountObj["Qty"] = parseInt(customerDiscountList[j].Qty);
      customerDiscountObj["Discount"] = parseFloat(customerDiscountList[j].DiscountPrice);
      customerDiscountObj["CustomerCost"] = parseFloat(customerDiscountList[j].cost);
      this.CustomerDiscountList.push(customerDiscountObj);
    }
    if (this.id != undefined && !Number.isNaN(this.id)) {
      obj['CustomerDiscount'] = this.CustomerDiscountList;
    }
    else {
      obj['CustomerDiscount'] = this.CustomerDiscountList.filter(x => x.IsActive == true);
    }

    if (this.id != undefined && !Number.isNaN(this.id)) {
      obj['Id'] = this.id;
      obj['ModifiedBy'] = this.userId;
      obj['CreatedBy'] = this.userId;
      obj['CreatedDate'] = this.now = new Date();
      obj['ModifiedDate'] = this.now = new Date();
      this.loader = true;
      this.partService.UpdateCustomPart(obj)
        .subscribe(
          data => {
            switch (data.statusCode) {
              case 200:
                this.buttonValue = this.constants.Save;
                $("#add_update").show();
                // this.toastr.success(this.constants.RecordUpdateSuccessfully);
                this.validation = false;
                this.popvalidation = false;
                this.validationImage = false;
                this.ListDiv = true;
                this.userSearch = '';
                this.SearchValidation = false;
                this.GetAllPart("1");
                this.isSaved = true;
                this.resetvendorData();

                this.MultipleImage = [];
                this.NewPartialArray = [];
                this.WordpressCategoryList = [];
                this.WordpressCategoryCrimpToolsList = [];
                this.WordpressCategoryRelatedItemList = [];
                this.NewPartialArray = [];
                this.NewArray2 = [];
                this.NewArray = [];
                this.NewArray1 = [];
                this.CrimpNewArray = [];
                this.CrimpNewArray1 = [];
                this.RelatedNewArray = [];
                this.RelatedNewArray1 = [];
                this.CustomerPartNameList = [];
                this.CustomerPartNameCrimpList = [];
                this.CustomerPartNameRelatedList = [];
                this.shortDescription = '';
                this.id = null;
                this.CheckId = 0;
                this.IsUpdate = false;
                this.IsSave = true;
                this.CheckCategory = false;
                this.CheckCategoryDisable = false;
                this.PartHistory = false;
                this.DimentionTypeL = 2;
                this.DimentionTypeH = 2;
                this.DimentionTypeW = 2;
                this.WeightType = 3;
                this.PartsDetails = [];
                this.recordAlreadyExist = false;
                this.recordPartNoExist = false;
                this.loader = false;

                break;
              case 400:
                if (data.errorMessage === 'Record Already Exist') {
                  this.recordAlreadyExist = true;
                  this.recordPartNoExist = false;
                  this.loader = false;
                  if (this.partList.length > 0) {
                    this.isPartval = false;
                  }
                  else {
                    this.isPartval = true;
                  }

                }
                else if (data.errorMessage === 'Part No Already Exist') {
                  this.recordPartNoExist = true;
                  this.recordAlreadyExist = false;
                  this.loader = false;
                  if (this.partList.length > 0) {
                    this.isPartval = false;
                  }
                  else {
                    this.isPartval = true;
                  }
                }
                break;
              case 404:
                $("#msgModel404").show();
                this.validation = false;
                this.popvalidation = false;
                this.validationImage = false;
                this.loader = false;
                this.recordAlreadyExist = false;
                this.recordPartNoExist = false;
                break;
              default:
                $("#wrong_msg").show();
                this.validation = false;
                this.popvalidation = false;
                this.validationImage = false;
                this.recordAlreadyExist = false;
                this.recordPartNoExist = false;
                this.loader = false;
            }


          }
        );
    }
    else {

      obj['CreatedBy'] = this.userId;
      obj['CreatedDate'] = this.now = new Date();
      this.loader = true;
      this.partService.AddCustomPart(obj)
        .subscribe(
          data => {
            switch (data.statusCode) {
              case 200:
                this.recordAlreadyExist = false;
                $("#add_success").show();
                // this.toastr.success(this.constants.RecordAddedSuccessfully);
                this.validation = false;
                this.popvalidation = false;
                this.validationImage = false;
                this.userSearch = '';
                this.SearchValidation = false;
                this.resetvendorData();
                this.GetAllPart("1");
                this.isSaved = true;
                this.TCreateUpdate = 1;
                this.SearchControl = false;
                this.ListShow = false;
                this.ListDiv = false;
                this.loader = false;
                this.NewPartialArray = [];
                this.CheckCategory = false;
                this.CheckCategoryDisable = false;
                this.WordpressCategoryList = [];
                this.WordpressCategoryCrimpToolsList = [];
                this.WordpressCategoryRelatedItemList = [];
                this.NewPartialArray = [];
                this.NewArray2 = [];
                this.NewArray = [];
                this.NewArray1 = [];
                this.CrimpNewArray = [];
                this.CrimpNewArray1 = [];
                this.RelatedNewArray = [];
                this.RelatedNewArray1 = [];
                this.CustomerPartNameList = [];
                this.CustomerPartNameCrimpList = [];
                this.CustomerPartNameRelatedList = [];
                this.shortDescription = '';
                this.DimentionTypeL = 2;
                this.DimentionTypeH = 2;
                this.DimentionTypeW = 2;
                this.WeightType = 3;
                this.weight = '';
                this.recordPartNoExist = false;
                this.recordAlreadyExist = false;
                this.PartHistory = false;
                // this.GetAllPart(this.companyId);
                // this.rerender();
                break;
              case 400:
                if (data.errorMessage === 'Record Already Exist') {
                  this.recordAlreadyExist = true;
                  this.recordPartNoExist = false;
                  this.PartImageArray = [];
                  this.PartsDetails = [];
                  if (this.partList.length > 0) {
                    this.isPartval = false;
                  }
                  else {
                    this.isPartval = true;
                  }

                }
                else if (data.errorMessage === 'Part No Already Exist') {
                  this.recordPartNoExist = true;
                  this.recordAlreadyExist = false;
                  this.PartImageArray = [];
                  this.PartsDetails = [];
                  if (this.partList.length > 0) {
                    this.isPartval = false;
                  }
                  else {
                    this.isPartval = true;
                  }
                }
                else {
                  $("#wrong_msg").show();
                  this.validation = false;
                  this.popvalidation = false;
                  this.validationImage = false;
                  this.recordAlreadyExist = false;
                  this.recordPartNoExist = false;
                  this.loader = false;
                }
                break;
              case 404:
                $("#msgModel404").show();
                this.validation = false;
                this.popvalidation = false;
                this.validationImage = false;
                this.loader = false;
                this.recordAlreadyExist = false;
                this.recordPartNoExist = false;
                break;
              default:
                $("#wrong_msg").show();
                this.validation = false;
                this.popvalidation = false;
                this.validationImage = false;
                this.recordAlreadyExist = false;
                this.recordPartNoExist = false;
                this.loader = false;
            }
            this.loader = false;
          }
        );
    }
  }
  UpdatePricePart() {
    if (this.PartMarkupCheck) {
      var calcMargin = 1 + Number(this.PartMarkupMargin);
      this.PPartPrice = Number(this.PVendorCost * calcMargin).toFixed(5);
      if (this.PPartPrice == "NaN") {
        this.PPartPrice = 0;
      }
    }
  
  }

  togglePartEdit(item: any) {
    this.viewLog = false;
    this.ListDiv = false;
    this.ListAngle = "down";
    this.LocalId = item.id;
    localStorage.setItem("EditMainId", JSON.stringify(item));
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.buttonValue = this.constants.Update;
    this.clearbutton = false;
    this.CategoryArr = [];
    this.selectedItems = [];
    this.editrow = item.editrow;
    this.customvendorId = item.customId;
    this.partService.GetCustomPartDetailsById(item.id).subscribe(
      (data: any) => {
        this.FilertingData = data;
        this.id = data.data.id;
        data.data.discount == null ? 0 : data.data.discount;
        this.selectedVendorValue = data.data.vendorId + '-' + data.data.discount;
        //  this.selectedVendorValue = data.data.vendorId;
        this.name = data.data.name;
        this.partNo = data.data.partNo;
        this.description = data.data.description;
        this.NonInventory = data.data.nonInventory;
        this.partCategoryId = data.data.partCategoryId;
        this.Pwordpressid = data.data.productId;
        // this.wordpressid = data.data.wordpressId;
        let count = 0;
        if (data.data.multiPartCategory) {
          this.CategoryArr = data.data.multiPartCategory;
          for (let ij = 0; ij < this.CategoryArr.length; ij++) {
            this.CategoryArr[ij].partcategoryid = this.CategoryArr[ij].partcategoryid + "-" + this.CategoryArr[ij].partWordpressid;
            this.CategoryArr[ij].partcategorytext = this.CategoryArr[ij].partcategorytext;
            this.CategoryArr[ij].isActive = this.CategoryArr[ij].isActive;
            this.CategoryArr[ij].id = this.CategoryArr[ij].id;
          }
          this.selectedItems = this.CategoryArr.filter(x => x.isActive == true);
          // this.wordpressid = data.data.wordpressId;
          for (let i = 0; i < data.data.multiPartCategory.length; i++) {
            if (data.data.multiPartCategory[i].partWordpressid != "0" && data.data.multiPartCategory[i].partWordpressid != null) {
              count = count + 1;
            }

          }

          if (count > 0) {
            if (data.data.isNotActive == false && data.data.nonInventory == false) {
              this.CheckCategory = true;
              this.CheckCategoryDisable = false
            }
            else {
              this.CheckCategory = false;
              this.CheckCategoryDisable = true;
            }
          }
          else {
            this.CheckCategory = false;
            this.CheckCategoryDisable = false;
          }

        }
        if (data.data.autoMarkup == true) {
          this.IsMarginDisabled = false;
          this.IsPricePart = true;
        }
        else {
          this.IsMarginDisabled = true;
          this.IsPricePart = false;
        }
        this.MarkupCheck = data.data.autoMarkup;
        this.MarkupMargin = data.data.markupMargin;
        this.NotActive = data.data.isNotActive;
        this.WeightType = data.data.weightType;
        if (data.data.dimentionType == "3") {
          this.height = String(Number(data.data.height * 1 / 12).toFixed(0)) == "0" ? "" : String(Number(data.data.height * 1 / 12).toFixed(0));
          this.width = String(Number(data.data.width * 1 / 12).toFixed(0)) == "0" ? "" : String(Number(data.data.width * 1 / 12).toFixed(0));
          this.length = String(Number(data.data.length * 1 / 12).toFixed(0)) == "0" ? "" : String(Number(data.data.length * 1 / 12).toFixed(0));
        }
        else if (data.data.dimentionType == "1") {
          this.height = String(Number(data.data.height * 2.54).toFixed(0)) == "0" ? "" : String(Number(data.data.height * 2.54).toFixed(0));
          this.width = String(Number(data.data.Width * 2.54).toFixed(0)) == "0" ? "" : String(Number(data.data.width * 2.54).toFixed(0));
          this.length = String(Number(data.data.length * 2.54).toFixed(0)) == "0" ? "" : String(Number(data.data.length * 2.54).toFixed(0));
        }
        else {
          this.height = String(data.data.height) == "0" ? "" : String(data.data.height);
          this.length = String(data.data.length) == "0" ? "" : String(data.data.length);
          this.width = String(data.data.width) == "0" ? "" : String(data.data.width);
        }

        if (this.WeightType == "2") {
          this.weight = Number(data.data.weight * 16) == 0 ? '' : Number(data.data.weight * 16).toFixed(0);

        }
        else if (this.WeightType == "1") {
          this.weight = Number(data.data.weight * 0.453592).toFixed(0);
        }
        else {
          this.weight = Number(data.data.weight) == 0 ? "" : Number(data.data.weight);
        }

        this.DimentionTypeL = data.data.dimentionType;
        this.DimentionTypeH = data.data.dimentionType;
        this.DimentionTypeW = data.data.dimentionType;
        this.selectedPartCategoryIdValue = data.data.wordpressId + "-" + data.data.partCategoryId;
        this.CheckId = data.data.id;
        this.cost = parseFloat(data.data.cost).toFixed(5);
        this.PartHistory = true;
        this.PartName = data.data.name;
        this.ManualPartId = data.data.id;
        if (data.data.partImageUpload.length > 0) {
          this.MultipleImage = [];
          for (var itemimage = 0; itemimage < data.data.partImageUpload.length; itemimage++) {

            let ojtmultiplepart = {
              partypeName: data.data.partImageUpload[itemimage].partType,
              imageUrl: data.data.partImageUpload[itemimage].documentType == "PDF" ? this._sanitizer.bypassSecurityTrustUrl(data.data.partImageUpload[itemimage].imagePath) : data.data.partImageUpload[itemimage].imagePath == null ? '' : this._sanitizer.bypassSecurityTrustUrl(data.data.partImageUpload[itemimage].imagePath),
              // Id: data.data.partImageUpload[itemimage].Id,
              partId: data.data.partImageUpload[itemimage].partId,
              documenttype: data.data.partImageUpload[itemimage].documentType,
            }
            this.MultipleImage.push(ojtmultiplepart);
          }
        }
        else {
          this.MultipleImage = [];
        }
        this.AddvendorList = [];
        if (data.data.partVendorList.length > 0) {
          this.AddvendorList2 = [];
          for (var itemvendor = 0; itemvendor < data.data.partVendorList.length; itemvendor++) {

            let ojtVmultiplepart = {};
            ojtVmultiplepart['vendorPartCost'] = data.data.partVendorList[itemvendor].vendorPartCost;
            ojtVmultiplepart['cost'] = data.data.partVendorList[itemvendor].cost;
            ojtVmultiplepart['vendorPartName'] = data.data.partVendorList[itemvendor].vendorPartName;
            ojtVmultiplepart['vendorPartNumber'] = data.data.partVendorList[itemvendor].vendorPartNumber;
            ojtVmultiplepart['markupCheck'] = data.data.partVendorList[itemvendor].automarkup;
            ojtVmultiplepart['markupMargin'] = data.data.partVendorList[itemvendor].markupMargin;
            ojtVmultiplepart['companyId'] = data.data.partVendorList[itemvendor].companyId;
            ojtVmultiplepart['ModifiedBy'] = data.data.partVendorList[itemvendor].ModifiedBy;
            ojtVmultiplepart['CreatedBy'] = data.data.partVendorList[itemvendor].CreatedBy;
            ojtVmultiplepart["CreatedDate"] = data.data.partVendorList[itemvendor].CreatedDate;
            ojtVmultiplepart["ModifiedDate"] = data.data.partVendorList[itemvendor].ModifiedDate;
            ojtVmultiplepart["IsActive"] = data.data.partVendorList[itemvendor].isActive;
            ojtVmultiplepart['vendorId'] = data.data.partVendorList[itemvendor].vendorId;
            ojtVmultiplepart['vendorName'] = data.data.partVendorList[itemvendor].vendorName;
            ojtVmultiplepart['id'] = data.data.partVendorList[itemvendor].id;
            ojtVmultiplepart['Discount'] = data.data.partVendorList[itemvendor].vendorDiscount;
            this.AddvendorList.push(ojtVmultiplepart);
            this.i = ojtVmultiplepart["id"]
            this.AddvendorList = this.AddvendorList.filter(x => x.IsActive == true);
            const counting = this.AddvendorList.filter(x => x.IsActive == true);
            if (counting.length > 0) {
              this.DeActiveCouting = false;
              this.ActiveCouting = true;
            }
            else {
              this.DeActiveCouting = true;
              this.ActiveCouting = false;
            }
          }
        }
        else {
          this.AddvendorList = [];
          this.DeActiveCouting = true;
          this.ActiveCouting = false;
        }
        this.VendorDiscountList = [];
        if (data.data.vendorDiscount.length > 0) {
          for (var j = 0; j < data.data.vendorDiscount.length; j++) {
            if (j > 0) {
              this.dynamicArray.push({ 'Qty': '', 'DiscountPrice': '', 'cost': '', 'id': '0', 'isActive': false, 'CreateDate': '', 'CreatedBy': '','isReadOnly': false,'IsDReadonly': false  });
            }
            this.dynamicArray[j].Qty = String(data.data.vendorDiscount[j].qty);
            this.dynamicArray[j].DiscountPrice = String(data.data.vendorDiscount[j].discount == null ? '' : data.data.vendorDiscount[j].discount);
            this.dynamicArray[j].cost = String(data.data.vendorDiscount[j].vendorCost == null ? '' : (data.data.vendorDiscount[j].vendorCost).toFixed(2));
            this.dynamicArray[j].id = String(data.data.vendorDiscount[j].id);
            this.dynamicArray[j].isActive = true;
            this.dynamicArray[j].CreateDate = data.data.vendorDiscount[j].createdDate;
            this.dynamicArray[j].CreatedBy = data.data.vendorDiscount[j].createdBy;
          }
        }
        if (data.data.customerDiscount.length > 0) {
          for (var j = 0; j < data.data.customerDiscount.length; j++) {
            if (j > 0) {
              this.dynamicCustomerDiscount.push({ 'Qty': '', 'DiscountPrice': '', 'cost': '', 'id': '0', 'isActive': false, 'CreateDate': '', 'CreatedBy': '' ,'isReadOnly': false,'IsDReadonly': false });
            }
            this.dynamicCustomerDiscount[j].Qty = String(data.data.customerDiscount[j].qty);
            this.dynamicCustomerDiscount[j].DiscountPrice = String(data.data.customerDiscount[j].discount == null ? '' : data.data.customerDiscount[j].discount);
            this.dynamicCustomerDiscount[j].cost = String(data.data.customerDiscount[j].customerCost == null ? '' : (data.data.customerDiscount[j].customerCost).toFixed(2));
            this.dynamicCustomerDiscount[j].id = String(data.data.customerDiscount[j].id);
            this.dynamicCustomerDiscount[j].isActive = true;
            this.dynamicCustomerDiscount[j].CreateDate = data.data.customerDiscount[j].createdDate;
            this.dynamicCustomerDiscount[j].CreatedBy = data.data.customerDiscount[j].createdBy;
          }
        }

        this.hours = data.data.hours;
        this.PartImage = data.data.partImage;
        //  toggle edit three properties 25_02_2022
        this.VendorPartName = data.data.vendorPartName;
        this.VendorPartNumber = data.data.vendorPartNumber;
        this.partLocation = data.data.partLocation;
        this.partLeadTime = data.data.partLeadTime;
        this.reorderAmount = data.data.reorderAmount;
        this.search = data.data.search;
        this.partTracking = data.data.partTracking == 0 ? '' : data.data.partTracking;
        this.installationNotes = data.data.installationNotes;
        this.TotalAllType = data.data.totalAllType ? data.data.totalAllType : 0;
        this.TotalTypeInventory = data.data.totalTypeInventory ? data.data.totalTypeInventory : 0;
        this.TotalInvLog = data.data.totalInvLog ? data.data.totalInvLog : 0;
        this.isTotalInvQty = true;

        this.CompanyValue = data.data.companyId;
        this.VendorPartCost = parseFloat(data.data.vendorPartCost).toFixed(5);
        this.SelectedUnitMeasure = data.data.unitofMeasureId == null ? '' : data.data.unitofMeasureId;
        this.getLocationLevel(data.data.companyId);
        this.SelectedLocation = data.data.partLocationName == null ? '' : data.data.partLocationName;
        this.selectedLocationName = data.data.locationName;
        this.SelectedPartLocation = data.data.partLocation == null ? '' : data.data.partLocation;
        this.getPartLocationLevel(parseInt(data.data.partLocationName),data.data.locationName);
        this.Getvendordropdownlists(data.data.companyId, this.selectedVendorValue);
        this.AdditionalDiscountLabel = data.data.isDiscount;
        this.ADiscount = data.data.discount * 100;
        this.AVendor = data.data.vendorName;
        this.Getmultivendordropdownlist(data.data.companyId, this.editrow, this.customvendorId, this.AddvendorList);
        this.ResetPopModelData();
        this.ResetPopModelData1();
        this.togglePopModel('ok');
        this.WordpressCategoryLoad(data.data.companyId);
        this.questionArray.controls.splice(0, this.questionArray.controls.length);
        if (data.data.customPart.length > 0) {
          this.btnShowvalidation = true;
          this.partList = data.data.customPart;
          this.SubTotalVendorCost = 0
          this.SubTotalPartCost = 0;
          this.SubTotalLaborHours = 0;
          this.isPartval = false;
          this.partList.forEach(partLst => {
            this.SubTotalVendorCost += Number(partLst.vendorParCost * partLst.quantity);
            this.SubTotalPartCost += Number(partLst.cost * partLst.quantity);
            this.SubTotalLaborHours += Number(Number(partLst.hours * partLst.quantity).toFixed(2));
            //this.ddlPartList = this.ddlPartList.filter(x => x.id != partLst.partId);

          });

        }

        this.questionArray.controls.push(
          new FormGroup({
            questionId: new FormControl(0),
            partId: new FormControl(0)
          })
        );
        this.GetPartVendors(data.data.companyId, data.data.id);
        this.InvProductOrderDropdownList1(data.data.companyId, data.data.id);
        this.InvPartOrderDropdownList1(data.data.companyId, data.data.id);

        this.minInventory = data.data.minInventory;

        let companyID = data.data.companyId;
        this.sharedService.getAllProductionRole(companyID, '').subscribe(
          data1 => {
            this.data = data1;
            if (companyID > 0) {
              this.userRoleList = this.data.dataList;
            }
            else {
              this.userRole = '';
            }

            this.userRole = data.data.productionRole;
            this.changeUserRole(this.userRole);
            if (data.data.skillLevel) {
              this.skillLevel = data.data.skillLevel;
            }
            else {
              this.skillLevel = '0';
            }
          });

        this.getAllWorkflowSteps(data.data.companyId);
        this.WorkflowSteps = data.data.workflowStep ? data.data.workflowStep : '';

        for (var item = 0; item < data.data.partQuestions.length; item++) {
          this.questionArray.controls.push(
            new FormGroup({
              questionId: new FormControl(data.data.partQuestions[item].questionId),
              partId: new FormControl(data.data.partQuestions[item].partId)
            })
          )
        }

        if (data.data.partInventory.length > 0) {
          this.isInvFind = true;
          this.InvList = [];
          for (var item = 0; item < data.data.partInventory.length; item++) {

            let LocalInvType2 = '';
            if (data.data.partInventory[item].typeId == "5") {
              LocalInvType2 = data.data.partInventory[item].serviceNo;
            }
            else if (data.data.partInventory[item].typeId == "6") {
              LocalInvType2 = data.data.partInventory[item].partOrderId;
            }
            else if (data.data.partInventory[item].typeId == "7") {
              LocalInvType2 = data.data.partInventory[item].productOrderId;
            }

            let qty: number = 0;
            let orderqty: number = 0;
            if (data.data.partInventory[item].purchaseOrderId == null || data.data.partInventory[item].purchaseOrderId == "") {
              qty = data.data.partInventory[item].qty;
            }
            else {
              let recQty = data.data.partInventory[item].qty == null || data.data.partInventory[item].qty == "" ? 0 : data.data.partInventory[item].qty;
              let shipQty = data.data.partInventory[item].shipQty == null || data.data.partInventory[item].shipQty == "" ? 0 : data.data.partInventory[item].shipQty;
              qty = parseInt(recQty) - parseInt(shipQty);
            }

            orderqty = data.data.partInventory[item].orderedQty == null || data.data.partInventory[item].orderedQty == "" ? 0 : data.data.partInventory[item].orderedQty;

            if (Number(qty) != 0) {
              let obj = {
                InvSno: this.InvSnoEdit,
                id: data.data.partInventory[item].id,
                partId: data.data.partInventory[item].partId,
                InvQty: qty,
                InvQtyOrdered: orderqty,
                InvDate: this.datepipe.transform(data.data.partInventory[item].date, 'yyyy-MM-dd'),
                InvCost: data.data.partInventory[item].cost,
                InvType: data.data.partInventory[item].typeId,
                InvType2: LocalInvType2,
                InvCustNo: data.data.partInventory[item].customerSo,
                InvCustRMA: data.data.partInventory[item].customerRma,
                InvPartLocation: data.data.partInventory[item].partLocation,
                purchaseOrderId: data.data.partInventory[item].purchaseOrderId,
                shipQty: data.data.partInventory[item].shipQty,
                InvVendor: data.data.partInventory[item].vendorId
              }
              this.InvList.push(obj);
              this.InvSnoEdit += 1;
            }
          }
        }

        else {
          this.isInvFind = false;
          this.InvList = [];
        }
      });
  }


  public isValidFlg: boolean = true;
  validateLabourHours(field) {
    var labourHours = this.hours;
    if (labourHours == "" || labourHours == undefined || labourHours == null) {
      return;
    }
    this.isValidFlg = (labourHours.length == 0 || labourHours.length == 4);
    if (labourHours.length >= 2)
      field.value = labourHours.substring(0, 2) + "." + labourHours.substring(3, 4);;
    this.hours = field.value;
  }

  public: boolean = true;
  miscLabourHours() {
    var input_val = this.mischours;
    var original_len = input_val.length;
    if (input_val != "") {
      if (input_val.indexOf(".") >= 0) {
        var decimal_pos = input_val.indexOf(".");
        var l = input_val.substring(0, decimal_pos);
        var r = input_val.substring(decimal_pos, decimal_pos + 3);
        var right_side = input_val.substring(decimal_pos, 2);
        this.mischours = l + "" + r;
      } else {
        this.mischours = this.mischours + ".00";
      }
    }
  }

  formatCurrencyMargin() {
    
    var input_val = this.cost;
    var original_len = input_val.length;
    if (input_val != undefined) {
      if (input_val.indexOf(".") >= 0) {
        var decimal_pos = input_val.indexOf(".");
        var l = input_val.substring(0, decimal_pos);
        var r = input_val.substring(decimal_pos, decimal_pos + 6);
        var right_side = input_val.substring(decimal_pos, 2);
        this.cost = l + "" + r;
      } 
      else
       {
        //this.cost = this.cost + ".00";
        this.cost = "";
      }
      const data = this.dynamicCustomerDiscount.filter(x=>x.isActive == true && x.Qty != '' && x.DiscountPrice != '');
      if(data.length > 0){
        for (let i = 0; i < data.length; i++) {
          if (this.hasDecimalPlace(data[i].DiscountPrice, 1)) {
            const value = (parseFloat(data[i].DiscountPrice) * this.cost).toFixed(2);
            const stringvalue1 = ((parseFloat(this.cost) -parseFloat(value))).toFixed(2);
            const stringvalue = stringvalue1.toString();        
            data[i].cost = stringvalue;
            data[i].isReadOnly = true;
            data[i].IsDReadonly = false;
          }
          else {
            const disVlaue = parseFloat(data[i].DiscountPrice) / 100;
            const value = (disVlaue * this.cost).toFixed(2);
            const stringvalue1 = ((parseFloat(this.cost) - parseFloat(value))).toFixed(2);
            const stringvalue = stringvalue1.toString();
            data[i].cost = stringvalue;
            data[i].isReadOnly = true;
            data[i].IsDReadonly = false;
          }

        }
      }
    }
  }


  formatCurrency() {
    
    var input_val = this.cost;
    var original_len = input_val.length;
    if (input_val != undefined) {
      if (input_val.indexOf(".") >= 0) {
        var decimal_pos = input_val.indexOf(".");
        var l = input_val.substring(0, decimal_pos);
        var r = input_val.substring(decimal_pos, decimal_pos + 6);
        var right_side = input_val.substring(decimal_pos, 2);
        this.cost = l + "" + r;
      } else {
        this.cost = this.cost + ".00000";
      }
      const data = this.dynamicCustomerDiscount.filter(x=>x.isActive == true && x.Qty != '' && x.DiscountPrice != '');
      if(data.length > 0){
        for (let i = 0; i < data.length; i++) {
          if (this.hasDecimalPlace(data[i].DiscountPrice, 1)) {
            const value = (parseFloat(data[i].DiscountPrice) * this.cost).toFixed(2);
            const stringvalue1 = ((parseFloat(this.cost) -parseFloat(value))).toFixed(2);
            const stringvalue = stringvalue1.toString();        
            data[i].cost = stringvalue;
            data[i].isReadOnly = true;
            data[i].IsDReadonly = false;
          }
          else {
            const disVlaue = parseFloat(data[i].DiscountPrice) / 100;
            const value = (disVlaue * this.cost).toFixed(2);
            const stringvalue1 = ((parseFloat(this.cost) - parseFloat(value))).toFixed(2);
            const stringvalue = stringvalue1.toString();
            data[i].cost = stringvalue;
            data[i].isReadOnly = true;
            data[i].IsDReadonly = false;
          }

        }
      }
    }
  }

  formatVendorCostCurrency() {
    var input_val = this.VendorPartCost;
    if (input_val != undefined) {
      var original_len = input_val.length;
      var calcMargin = 1 + Number(this.MarkupMargin);
      if (this.MarkupCheck) {
        this.cost = Number(this.VendorPartCost * calcMargin).toFixed(5);
        if (this.cost == "NaN") {
          this.cost = 0;
        }
      }
      if (input_val != "") {
        if (input_val.indexOf(".") >= 0) {
          var decimal_pos = input_val.indexOf(".");
          var l = input_val.substring(0, decimal_pos);
          var r = input_val.substring(decimal_pos, decimal_pos + 6);
          var right_side = input_val.substring(decimal_pos, 2);
          this.VendorPartCost = l + "" + r;
        } else {
          this.VendorPartCost = this.VendorPartCost + ".00000";
        }

        const data = this.dynamicArray.filter(x=>x.isActive == true && x.Qty != '' && x.DiscountPrice != '');
        if(data.length > 0){
          for (let i = 0; i < data.length; i++) {
            if (this.hasDecimalPlace(data[i].DiscountPrice, 1)) {
              const value = (parseFloat(data[i].DiscountPrice) * this.VendorPartCost).toFixed(2);
              const stringvalue1 = ((parseFloat(this.VendorPartCost) - parseFloat(value))*parseFloat(data[i].Qty)).toFixed(2);;
              const stringvalue = stringvalue1.toString();
              data[i].cost = stringvalue;
              data[i].isReadOnly = true;
              data[i].IsDReadonly = false;
            }
            else {
              const disVlaue = parseFloat(data[i].DiscountPrice) / 100;
              const value = (disVlaue * this.VendorPartCost).toFixed(2);
              const stringvalue1 = ((parseFloat(this.VendorPartCost) - parseFloat(value))*parseFloat(data[i].Qty)).toFixed(2);
              const stringvalue = stringvalue1.toString();
              data[i].cost = stringvalue;
              data[i].isReadOnly = true;
              data[i].IsDReadonly = false;
            }
  
          }
        }

      }
    }

  }



  MisceVendorCost() {

    var input_val = this.miscVendorPartCost;
    var original_len = input_val.length;
    if (input_val != "") {
      if (input_val.indexOf(".") >= 0) {
        var decimal_pos = input_val.indexOf(".");
        var l = input_val.substring(0, decimal_pos);
        var r = input_val.substring(decimal_pos, decimal_pos + 3);
        var right_side = input_val.substring(decimal_pos, 2);
        this.miscVendorPartCost = l + "" + r;
      } else {
        this.miscVendorPartCost = this.miscVendorPartCost + ".00000";
      }
    }
  }

  MiscePartCost() {
    var input_val = this.misccost;
    var original_len = input_val.length;
    if (input_val != "") {
      if (input_val.indexOf(".") >= 0) {
        var decimal_pos = input_val.indexOf(".");
        var l = input_val.substring(0, decimal_pos);
        var r = input_val.substring(decimal_pos, decimal_pos + 3);
        var right_side = input_val.substring(decimal_pos, 2);
        this.misccost = l + "" + r;
      } else {
        this.misccost = this.misccost + ".00";
      }
    }
  }

  getAllCompany(companyId) {

    if (companyId == undefined) {
      companyId = 0;
    }
    this.companyService.GetDropdownCompanyHierarchyModel(companyId).subscribe(
      data => {
        this.data = data;
        this.items = this.data.dataList;
        this.companyList = this.data.dataList;
        // this.companyList.forEach((x) => {
        //   this.userGroupObjects.push(new TreeviewItem(x, false));
        // });
        this.CompanyValue = Number(companyId);
      });
  }

  PartBack() {
    this.clearbutton = true;
    this.id = null;
    this.CheckCategory = false;
    this.PartHistory = false;
    this.buttonValue = this.constants.Save;
    this.resetvendorData();
  }

  ngAfterViewInit(): void {
    //this.loader=false;
    this.cdr.detectChanges();
    this.settingDetailCSS = localStorage.getItem("settingDetailCSS");
    this.settingLogoImage = localStorage.getItem("settingImageLogo");
    this.SetSettingCSS(this.settingDetailCSS, this.settingLogoImage);
  }


 
  OnCustomVendorChangeEvent(event) {
    if (event.target.value != "" && event.target.value != undefined) {
      this.vendorId = parseInt(event.target.value);
      this.vendorsName = event.target.options[event.target.options.selectedIndex].text;
    }
    else {
      this.vendorId = "";
    }

  }

  OnCustomEditVendorChangeEvent(event) {
    this.UpdatevendorId = parseInt(event.target.value);
    this.UpdatevendorsName = event.target.options[event.target.options.selectedIndex].text;
  }
  OnCustomPartChangeEvent(event) {
    if (event != "") {
      this.PartId = parseInt(event);
      if (this.PartId != 0) {
        this.sharedService.GetPartVendors(this.CompanyValue, this.PartId).subscribe(
          data => {
            this.loader = false;
            this.data = data;
            let ddlData = this.data.dataList;
            switch (this.data.statusCode) {
              case 200:
                this.ddlVendorList = ddlData;
                this.CustompartVendor = this.ddlVendorList.filter(x => x.mainvendor != null)[0].mainvendor;
                this.vendorId = parseInt(this.CustompartVendor);
                this.vendorsName = this.ddlVendorList.filter(x => x.mainvendor != null)[0].name;
                break;
              case 400:
                this.ddlVendorList = [];
                break;
              default:
                $("#wrong_msg").show();
            }
          });
      }
    }

  }

  hidemodalpopup2() {
    $('#myModal2').hide();
  }

  AddpartDetailsNew(item) {
    if(this.CustompartVendor == ""){
      this.cValidation = true;
      return;
    }
 
    let partAlreadyExists = this.partList.filter(x => x.partId == item.id);
    if (partAlreadyExists.length > 0) {
      $("#myModal1").show();
      return;
    }
    this.VendorPartCost = 0;
    this.cost = 0;
    this.hours = 0;
    this.SubTotalVendorCost = 0
    this.SubTotalPartCost = 0;
    this.SubTotalLaborHours = 0;
    let obj = {
      partId: item.id,
      cost: item.cost,
      hours: parseFloat(item.hours).toFixed(2),
      vendorParCost: item.vendorPartCoat == null ? 0 : item.vendorPartCoat,
      partName: item.name,
      partNumber: item.partNo,
      quantity: item.qty,
      isMisc: false,
      categoryId: item.categoryId,
      vendorId: this.vendorId,
      vendorsName: this.vendorsName,
      vendorPartName: item.vendorPartName,
      vendorPartNumber: item.vendorPartNumber
    }

    this.partList.push(obj);
    if (this.partList.length > 0) {
      this.isPartval = false;
    }
    if (this.partList.length > 0) {
      this.partList.forEach(partLst => {
        this.VendorPartCost += Number(partLst.vendorParCost * partLst.quantity);
        this.SubTotalVendorCost += Number(partLst.vendorParCost * partLst.quantity);
        this.SubTotalPartCost += Number(partLst.cost * partLst.quantity);
        this.cost += Number(partLst.cost * partLst.quantity);
        this.SubTotalLaborHours += Number(Number(partLst.hours * partLst.quantity).toFixed(2));
        this.hours += Number(Number(partLst.hours * partLst.quantity).toFixed(2));
      });

      this.VendorPartCost = this.VendorPartCost.toFixed(5);
      this.cost = this.cost.toFixed(5);
      if (this.MarkupCheck == true) {
        var calcMargin = 1 + Number(this.MarkupMargin);
        this.cost = Number(this.VendorPartCost * calcMargin).toFixed(5);
        if (this.cost == "NaN") {
          this.cost = 0;
          this.cost = this.cost.toFixed(5);
        }
      }
    }

  const dataDiscount = this.dynamicArray.filter(x=>x.isActive == true && x.Qty != '' && x.DiscountPrice != '');
  if(dataDiscount.length > 0){
    for (let i = 0; i < dataDiscount.length; i++) {
      if (this.hasDecimalPlace(dataDiscount[i].DiscountPrice, 1)) {
        const value = (parseFloat(dataDiscount[i].DiscountPrice) * this.VendorPartCost).toFixed(2);
        const stringvalue1 = (parseFloat(this.VendorPartCost) - parseFloat(value)).toFixed(2);
        const stringvalue = stringvalue1.toString();
        dataDiscount[i].cost = stringvalue;
      }
      else {
        const disVlaue = parseFloat(dataDiscount[i].DiscountPrice) / 100;
        const value = (disVlaue * this.VendorPartCost).toFixed(2);
        const stringvalue1 = (parseFloat(this.VendorPartCost) - parseFloat(value)).toFixed(2);
        const stringvalue = stringvalue1.toString();
        dataDiscount[i].cost = stringvalue;
      }

    }
  }

const dataCustomer = this.dynamicCustomerDiscount.filter(x=>x.isActive == true && x.Qty != '' && x.DiscountPrice != '');
if(dataCustomer.length > 0){
  for (let i = 0; i < dataCustomer.length; i++) {
    if (this.hasDecimalPlace(dataCustomer[i].DiscountPrice, 1)) {
      const value = (parseFloat(dataCustomer[i].DiscountPrice) * this.cost).toFixed(2);
      const stringvalue1 = (parseFloat(this.cost) - parseFloat(value)).toFixed(2);
      const stringvalue = stringvalue1.toString();
      dataCustomer[i].cost = stringvalue;
    }
    else {
      const disVlaue = parseFloat(dataCustomer[i].DiscountPrice) / 100;
      const value = (disVlaue * this.cost).toFixed(2);
      const stringvalue1 = (parseFloat(this.cost) - parseFloat(value)).toFixed(2);
      const stringvalue = stringvalue1.toString();
      dataCustomer[i].cost = stringvalue;
    }

  }
}
  
    this.btnShowvalidation = true;
    this.PartId = NaN;
    $("#CustomPartitem").show();
    $("#addpartModalpopup").hide();
  }
  CustomPartPopup() {
    $("#addindividualpart").show();
    $("#CustomPartitem").hide();


  }
  AddpartDetails() {
    // isPartval
    if (isNaN(this.PartId)) {
      $("#myModal").show();
      return;
    }

    let partAlreadyExists = this.partList.filter(x => x.partId == this.selectedPartValue);
    if (partAlreadyExists.length > 0) {
      $("#myModal1").show();
      return;
    }

    if (this.vendorId == "" || this.vendorId == undefined) {

      $("#myModal2").show();
      return;
    }

    if (!isNaN(this.PartId)) {
      this.partService.GetCustomPartById(this.PartId).subscribe(
        data => {
          this.data = data;
          if (this.data != null && this.data != undefined) {
            this.VendorPartCost = 0;
            this.cost = 0;
            this.hours = 0;
            this.SubTotalVendorCost = 0
            this.SubTotalPartCost = 0;
            this.SubTotalLaborHours = 0;
            let obj = {
              partId: this.data.data.id,
              cost: this.data.data.cost,
              hours: parseFloat(this.data.data.hours).toFixed(2),
              vendorParCost: this.data.data.vendorPartCost == null ? 0 : this.data.data.vendorPartCost,
              partName: this.data.data.name,
              partNumber: this.data.data.partNo,
              quantity: this.qty,
              isMisc: false,
              categoryId: this.CustompartCategoryId,
              vendorId: this.vendorId,
              vendorsName: this.vendorsName,
              vendorPartName: this.data.data.vendorPartName,
              vendorPartNumber: this.data.data.vendorPartNumber
            }

            this.partList.push(obj);
            if (this.partList.length > 0) {
              this.isPartval = false;
            }
            if (this.partList.length > 0) {
              this.partList.forEach(partLst => {
                this.VendorPartCost += Number(partLst.vendorParCost * partLst.quantity);
                this.SubTotalVendorCost += Number(partLst.vendorParCost * partLst.quantity);
                this.SubTotalPartCost += Number(partLst.cost * partLst.quantity);
                this.cost += Number(partLst.cost * partLst.quantity);
                this.SubTotalLaborHours += Number(Number(partLst.hours * partLst.quantity).toFixed(2));
                this.hours += Number(Number(partLst.hours * partLst.quantity).toFixed(2));
              });
              if (this.MarkupCheck == true) {
                var calcMargin = 1 + Number(this.MarkupMargin);
                this.cost = Number(this.VendorPartCost * calcMargin).toFixed(5);
                if (this.cost == "NaN") {
                  this.cost = 0;
                }
              }
            }
            this.PartId = NaN;
          }
          this.vendorsName = '';
          this.vendorId = '';
        });
      this.btnShowvalidation = true;

      this.custompartreset();

    }
    else {
    }
  }

  DeletePart() {
    // this.partitemId=

    if (this.deleteCustomPart > -1) {
      this.partList.splice(this.deleteCustomPart, 1);
      this.VendorPartCost = 0;
      this.cost = 0;
      this.hours = 0;
      this.SubTotalVendorCost = 0
      this.SubTotalPartCost = 0;
      this.SubTotalLaborHours = 0;
      if (this.partList.length > 0) {
        this.partList.forEach(partLst => {
          this.VendorPartCost += Number(partLst.vendorParCost * partLst.quantity);
          this.SubTotalVendorCost += Number(partLst.vendorParCost * partLst.quantity);
          this.SubTotalPartCost += Number(partLst.cost * partLst.quantity);
          this.cost += Number(partLst.cost * partLst.quantity);
          this.SubTotalLaborHours += Number(Number(partLst.hours * partLst.quantity).toFixed(2));
          this.hours += Number(Number(partLst.hours * partLst.quantity).toFixed(2));
        });
      }
      if (this.partList.length > 0) {
        this.isPartval = false;
        const dataDiscount = this.dynamicArray.filter(x=>x.isActive == true && x.Qty != '' && x.DiscountPrice != '');
        if(dataDiscount.length > 0){
          for (let i = 0; i < dataDiscount.length; i++) {
            if (this.hasDecimalPlace(dataDiscount[i].DiscountPrice, 1)) {
              const value = (parseFloat(dataDiscount[i].DiscountPrice) * this.VendorPartCost).toFixed(2);
              const stringvalue1 = (parseFloat(this.VendorPartCost) - parseFloat(value)).toFixed(2);
              const stringvalue = stringvalue1.toString();
              dataDiscount[i].cost = stringvalue;
            }
            else {
              const disVlaue = parseFloat(dataDiscount[i].DiscountPrice) / 100;
              const value = (disVlaue * this.VendorPartCost).toFixed(2);
              const stringvalue1 = (parseFloat(this.VendorPartCost) - parseFloat(value)).toFixed(2);
              const stringvalue = stringvalue1.toString();
              dataDiscount[i].cost = stringvalue;
            }
      
          }
        }
      
      const dataCustomer = this.dynamicCustomerDiscount.filter(x=>x.isActive == true && x.Qty != '' && x.DiscountPrice != '');
      if(dataCustomer.length > 0){
        for (let i = 0; i < dataCustomer.length; i++) {
          if (this.hasDecimalPlace(dataCustomer[i].DiscountPrice, 1)) {
            const value = (parseFloat(dataCustomer[i].DiscountPrice) * this.cost).toFixed(2);
            const stringvalue1 = (parseFloat(this.cost) - parseFloat(value)).toFixed(2);
            const stringvalue = stringvalue1.toString();
            dataCustomer[i].cost = stringvalue;
          }
          else {
            const disVlaue = parseFloat(dataCustomer[i].DiscountPrice) / 100;
            const value = (disVlaue * this.cost).toFixed(2);
            const stringvalue1 = (parseFloat(this.cost) - parseFloat(value)).toFixed(2);
            const stringvalue = stringvalue1.toString();
            dataCustomer[i].cost = stringvalue;
          }
      
        }
      }
      }
      else {
        this.isPartval = true;
      }
    }
  }

  AddMiscellaneousPart() {
    this.validationcustom = true;
    this.recordAlreadyExist = false;
    var partId = Math.floor((Math.random() * 100) + 1);
    this.clearbutton = true;
    if (this.form.controls.miscname.status === this.constants.Invalid ||
      this.form.controls.misccost.status === this.constants.Invalid
    ) {
      return;
    }
    let partAlreadyExists = this.partList.filter(x => x.partName == this.miscname);
    if (partAlreadyExists.length > 0) {
      $("#myModal1").show();
      return;
    }
    this.VendorPartCost = 0;
    this.cost = 0;
    this.hours = 0;
    this.SubTotalVendorCost = 0
    this.SubTotalPartCost = 0;
    this.SubTotalLaborHours = 0;
    let ObjMiscellaneousPart = {
      partId: partId,
      cost: this.misccost,
      hours: this.mischours,
      vendorParCost: this.miscVendorPartCost == "" ? 0 : this.miscVendorPartCost,
      partName: this.miscname,
      partNumber: this.miscpartNo,
      quantity: this.miscqty,
      isMisc: true,
      categoryId: this.CustomCategory
    }

    this.partList.push(ObjMiscellaneousPart);
    if (this.partList.length > 0) {
      this.partList.forEach(partLst => {
        this.VendorPartCost += Number(partLst.vendorParCost * partLst.quantity);
        this.SubTotalVendorCost += Number(partLst.vendorParCost * partLst.quantity);
        this.SubTotalPartCost += Number(partLst.cost * partLst.quantity);
        this.cost += Number(partLst.cost * partLst.quantity);
        this.SubTotalLaborHours += Number(Number(partLst.hours * partLst.quantity).toFixed(2));
        this.hours += Number(Number(partLst.hours * partLst.quantity).toFixed(2));
      });
    }
    this.btnShowvalidation = true;
    this.MisccloseModal();
  }


  EditPartRow(partId, rowText) {
    this.normalShow = true;
    this.RowEdit = true;
    if (rowText == "editRow") {
      this.CurrentEditRow = "Edit";
      this.RowEdit = false;
      this.EditRowPartId = partId;
      this.EditRowTxtName = "editRow";
      this.btnOkShow = true;
      this.editrowdata = false;
      this.btnEditDeleteShow = false;
      this.loader = true;
      this.sharedService.GetPartVendors(this.CompanyValue, partId).subscribe(
        data => {
          this.loader = false;
          this.data = data;
          let ddlData = this.data.dataList;
          switch (this.data.statusCode) {
            case 200:
              this.ddlVendorList1 = ddlData;
              break;
            case 400:
              this.ddlVendorList1 = [];
              break;
            default:
              $("#wrong_msg").show();
          }
          const partData = this.partList.filter(item => item.partId == partId);
          if (partData.length > 0) {
            this.UpdateQty = partData[0].quantity;
            this.UpdateEachPartCost = parseFloat(partData[0].cost).toFixed(2);
            this.UpdateLaborHrs = parseFloat(partData[0].hours).toFixed(2);
            this.UpdateVendorPartCost = parseFloat(partData[0].vendorParCost).toFixed(2);
            this.UpdatePartName = partData[0].partName;
            this.UpdatePartNumber = partData[0].partNumber;
            this.RowIsMisc = partData[0].isMisc;
            this.UpdateVendorId = partData[0].vendorId;
            this.UpdatevendorsName = partData[0].vendorsName;
          }
        });
    }
  }

  UpdatePartRow(partId) {
    this.editrowdata = true;
    const partData = this.partList.filter(item => item.partId == partId);
    if (partData.length > 0 && partData != null) {
      partData[0].quantity = this.UpdateQty;
      partData[0].cost = this.UpdateEachPartCost;
      partData[0].hours = this.UpdateLaborHrs;
      partData[0].vendorParCost = this.UpdateVendorPartCost;
      partData[0].partName = this.UpdatePartName;
      partData[0].partNumber = this.UpdatePartNumber;
      if (this.UpdateVendorId == undefined || this.UpdateVendorId == "") {
        $('#myModal2').show();
        return;
      }
      partData[0].vendorId = this.UpdateVendorId;

      partData[0].vendorsName = this.UpdatevendorsName;
    }
    this.normalShow = true;
    this.RowEdit = true;
    this.CurrentEditRow = "";
    this.EditRowTxtName = "";
    this.btnEditDeleteShow = true;
    this.btnOkShow = false;
    this.VendorPartCost = 0;
    this.cost = 0;
    this.hours = 0;
    this.SubTotalVendorCost = 0;
    this.SubTotalPartCost = 0;
    this.SubTotalLaborHours = 0;
    if (this.partList.length > 0) {
      this.partList.forEach(partLst => {
        this.VendorPartCost += Number(partLst.vendorParCost * partLst.quantity);
        this.SubTotalVendorCost += Number(partLst.vendorParCost * partLst.quantity);
        this.SubTotalPartCost += Number(partLst.cost * partLst.quantity);
        this.cost += Number(partLst.cost * partLst.quantity);
        this.SubTotalLaborHours += Number(Number(partLst.hours * partLst.quantity).toFixed(2));
        this.hours += Number(Number(partLst.hours * partLst.quantity).toFixed(2));
      });
      if (this.MarkupCheck == true) {
        var calcMargin = 1 + Number(this.MarkupMargin);
        this.cost = Number(this.VendorPartCost * calcMargin);
      }

      this.cost = this.cost.toFixed(5);
      this.VendorPartCost =  this.VendorPartCost.toFixed(5);
      const dataDiscount = this.dynamicArray.filter(x=>x.isActive == true && x.Qty != '' && x.DiscountPrice != '');
      if(dataDiscount.length > 0){
        for (let i = 0; i < dataDiscount.length; i++) {
          if (this.hasDecimalPlace(dataDiscount[i].DiscountPrice, 1)) {
            const value = (parseFloat(dataDiscount[i].DiscountPrice) * this.VendorPartCost).toFixed(2);
            const stringvalue1 = (parseFloat(this.VendorPartCost) - parseFloat(value)).toFixed(2);
            const stringvalue = stringvalue1.toString();
            dataDiscount[i].cost = stringvalue;
          }
          else {
            const disVlaue = parseFloat(dataDiscount[i].DiscountPrice) / 100;
            const value = (disVlaue * this.VendorPartCost).toFixed(2);
            const stringvalue1 = (parseFloat(this.VendorPartCost) - parseFloat(value)).toFixed(2);
            const stringvalue = stringvalue1.toString();
            dataDiscount[i].cost = stringvalue;
          }
    
        }
      }
    
    const dataCustomer = this.dynamicCustomerDiscount.filter(x=>x.isActive == true && x.Qty != '' && x.DiscountPrice != '');
    if(dataCustomer.length > 0){
      for (let i = 0; i < dataCustomer.length; i++) {
        if (this.hasDecimalPlace(dataCustomer[i].DiscountPrice, 1)) {
          const value = (parseFloat(dataCustomer[i].DiscountPrice) * this.cost).toFixed(2);
          const stringvalue1 = (parseFloat(this.cost) - parseFloat(value)).toFixed(2);
          const stringvalue = stringvalue1.toString();
          dataCustomer[i].cost = stringvalue;
        }
        else {
          const disVlaue = parseFloat(dataCustomer[i].DiscountPrice) / 100;
          const value = (disVlaue * this.cost).toFixed(2);
          const stringvalue1 = (parseFloat(this.cost) - parseFloat(value)).toFixed(2);
          const stringvalue = stringvalue1.toString();
          dataCustomer[i].cost = stringvalue;
        }
    
      }
    }
    }




  }

  CancleUpdatePartRow() {
    this.btnOkShow = false;
    this.btnEditDeleteShow = true;
    this.EditRowPartId = 0;
    this.editrowdata = true;
    this.normalShow = true;
    this.RowEdit = true;
    this.CurrentEditRow = "";
    this.EditRowTxtName = "";
  }

  // Start Inventory Section


  GetInvTypeDDLText(InvTypeId) {
    if (this.InvTypeList != undefined) {
      return this.InvTypeList.find(s => s.id == InvTypeId).name;
    }

  }
  GetInvType2DDLText(InvTyepValue, InvTypeValue2) {
    if (InvTyepValue == "6") {
      if (this.InvPartOrderDDL != undefined) {
        const InvData = this.InvPartOrderDDL.filter(item => item.id == InvTypeValue2);
        if (InvData.length > 0) {
          return InvData[0].name;
        }
        else {
          return '';
        }
      }

    }
    else if (InvTyepValue == "7") {
      if (this.InvProductOrderDDL != undefined) {
        const InvData = this.InvProductOrderDDL.filter(item => item.id == InvTypeValue2);
        if (InvData.length > 0) {
          return InvData[0].name;
        }
        else {
          return '';
        }
      }

    }
    else {
      return InvTypeValue2;
    }

  }



  AddInv() {
    if (this.NonInventory == false) {
      if (this.form.controls.InvQty.status === this.constants.Invalid ||
        this.form.controls.InvDate.status === this.constants.Invalid ||
        this.form.controls.InvCost.status === this.constants.Invalid ||
        this.form.controls.InvType.status === this.constants.Invalid ||
        this.form.controls.InvVendor.status === this.constants.Invalid
      ) {
        this.InvValidation = true;
        return;
      }
      else {
        if (this.InvType == "5") {
          if (this.form.controls.InvServNo.status === this.constants.Invalid) {
            this.InvValidation = true;
            return;
          }
        }
        if (this.InvType == "6") {
          if (this.form.controls.InvPartOrder.status === this.constants.Invalid) {
            this.InvValidation = true;
            return;
          }
        }
        if (this.InvType == "7") {
          if (this.form.controls.InvProductOrder.status === this.constants.Invalid) {
            this.InvValidation = true;
            return;
          }
        }

        this.InvValidation = false;

        if (this.InvType == "5") {
          this.InvType2 = this.InvServNo;
        }
        else if (this.InvType == "6") {
          this.InvType2 = Number(this.InvPartOrder);

        }
        else if (this.InvType == "7") {
          this.InvType2 = Number(this.InvProductOrder);
        }
        else {
          this.InvType2 = '';
        }
        let obj = {
          InvSno: this.InvSno,
          partId: this.id,
          InvQty: Number(this.InvQty),
          InvQtyOrdered: Number(this.InvQty),
          InvDate: this.InvDate,
          InvCost: Number(this.InvCost),
          InvType: Number(this.InvType),
          InvType2: this.InvType2,
          InvCustRMA: this.InvCustRMA,
          InvVendor: Number(this.InvVendor),
          InvPartLocation: this.InvPartLocation,
          isActive: true,
          createdBy: this.userId,
          createdDate: this.now = new Date()
        }
        if (obj.InvType == 6 || obj.InvType == 7) {
          this.loader = true;
          this.sharedService.GetCheckInventory(obj.InvType2, obj.InvQty, obj.partId).subscribe(
            data => {
              this.data = data;
              this.loader = false;
              if (this.data.data == "0") {
                this.InvList.push(obj);
                this.InvSno += 1;
                this.InvQty = '';
                this.InvDate = '';
                this.InvCost = '';
                this.InvType = '';
                this.InvType2 = '';
                this.InvServNo = '';
                this.InvPartOrder = '';
                this.InvProductOrder = '';
                this.InvCustRMA = '';
                this.InvVendor = '';
                this.InvPartLocation = '';
              }
              else {
                this.InvProp = "You can assign only " + this.data.data + " qty on this order ";
                $('#InvExist1').show();
              }
            });
        }
        else {
          this.InvList.push(obj);
          this.InvSno += 1;
          this.InvQty = '';
          this.InvDate = '';
          this.InvCost = '';
          this.InvType = '';
          this.InvType2 = '';
          this.InvServNo = '';
          this.InvPartOrder = '';
          this.InvProductOrder = '';
          this.InvCustRMA = '';
          this.InvVendor = '';
          this.InvPartLocation = '';
        }

      }
    }
    else {
      $('#InvExist').show();
      this.InvQty = '';
      this.InvDate = '';
      this.InvCost = '';
      this.InvType = '';
      this.InvType2 = '';
      this.InvServNo = '';
      this.InvPartOrder = '';
      this.InvProductOrder = '';
      this.InvCustRMA = '';
      this.InvPartLocation = '';
      this.InvValidation = false;
      this.InvVendor = '';
    }


  }
  InvExist() {
    $('#InvExist').hide();
  }
  InvExist1() {
    $('#InvExist1').hide();
  }

  closeModal() {
    this.validationcustom = false;
    this.closeBtn.nativeElement.click();
    this.clearPartDetails();
  }

  MisccloseModal() {
    this.validationcustom = false;
    this.closeBtn.nativeElement.click();
    this.miscclearPartDetails();
  }

  clearPartDetails() {
    this.name = '';
    this.cost = '';
    this.partNo = '';
    this.hours = '';
    this.VendorPartCost = '';
    this.qty = 1;
  }

  ChangeTCreateUpdate(event) {
    this.TCreateUpdate = event.target.value;
    if (this.TCreateUpdate == "1") {
      this.SearchControl = false;
      this.ListShow = false;
      this.ListDiv = false;
      this.clearbutton = true;
      this.buttonValue = this.constants.Save;
      this.resetvendorData();
      this.CheckId = 0;
      this.CheckCategory = false;
      this.PartHistory = false;
      this.ResetPopModelData();
      this.id = null;
      this.IsUpdate = false;
      this.IsSave = true;
      if (this.CompanyValue != undefined || this.CompanyValue != 0) {
        this.WordpressCategoryLoad(this.CompanyValue);
      }
    }
    else if (this.TCreateUpdate == "0") {
      this.SearchControl = true;
      this.userSearch = "";
      this.CheckId = 0;
      this.CheckCategory = false;
      this.PartHistory = false;
      this.ResetPopModelData();
      this.id = null;
      this.CheckId = 0;
      this.IsUpdate = true;
      this.IsSave = false;
    }
  }

  ListDivShowHide() {
    if (this.ListDiv == true) {
      this.ListDiv = false;
      this.ListAngle = "down";
    }
    else if (this.ListDiv == false) {
      this.ListDiv = true;
      this.ListAngle = "up";
    }
  }
  crossitem() {
    $('#add_Items').hide();
  }

  searchUser() {
    this.datalist = [];
    this.SearchType = false;
    if (!this.userSearch || this.value_search == 0) {
      this.SearchValidation = true;
      this.GetAllPart("1");
      return;
    }

    if (this.fieldToSearch == "3") {
      this.ListShow = true;
      this.ListDiv = true;
      this.GetAllPart("2");
    }
    else if (this.fieldToSearch == "2") {
      this.ListShow = true;
      this.ListDiv = true;
      this.GetAllPart("3");
    }
    else if (this.fieldToSearch == "1") {
      this.ListShow = true;
      this.ListDiv = true;
      this.GetAllPart("4");
    }
    else {
      this.ListShow = true;
      this.ListDiv = true;
      this.Type = 1;
      this.GetAllPart("1");
    }


  }


  AllUser() {
    this.SearchType = true;
    if (this.value_search == 0) {
      this.SearchValidation = true;
      return;
    }
    // this.SearchValidation = false;
    this.ListShow = true;
    this.ListDiv = true;
    this.ListAngle = "up";
    this.GetAllPart("1");
    this.resetvendorData();
    this.CheckId = 0;
    this.CheckCategory = false;
    this.PartHistory = false;
    this.ResetPopModelData();
    this.id = null;
    this.IsUpdate = true;
    this.IsSave = false;
    this.validation = false;
    this.recordAlreadyExist = false;
    this.recordPartNoExist = false;
    this.validation = false;
    this.popvalidation = false;
    this.validationImage = false;
  }

  onValueChange_search(value: any): void {
    this.value_search = value;
    // this.InvProductOrderDropdownList(this.value_search);
    // this.InvPartOrderDropdownList(this.value_search);
  }

  miscclearPartDetails() {
    this.miscname = '';
    this.misccost = '';
    this.miscpartNo = '';
    this.mischours = 0;
    this.miscqty = 1;
    this.miscVendorPartCost = '';
  }

  custompartreset() {
    //this.qty = 1;
    this.selectedPartValue = '';
    this.CustomCategory = '';
    this.CustompartVendor = '';
    this.ddlVendorList = [];
    this.ddlVendorList1 = [];
  }

  getAllCompany_search(companyId) {
    if (companyId == undefined) {
      companyId = 0;
    }
    this.companyService.GetDropdownCompanyHierarchyModel(companyId).subscribe(
      data => {
        this.data = data;
        this.items_search = this.data.dataList;
        this.companyList_search = this.data.dataList;
        // this.companyList_search.forEach((x) => {
        //   this.userGroupObjects_search.push(new TreeviewItem(x, false));
        // });
        this.value_search = Number(companyId);
        this.sharedService.custompartLoadedComponent.subscribe(value => {
          if (value != 0 && value != undefined) {

            this.route.params.subscribe(
              (params) => {
                if (params.id) {
                  this.GetValue(value);
                }
              }
            );


          }

        });
        //this.getAllState();
      });
  }

  GetquestiondropdownlistDuplicate(companyId) {
    this.partService.Getquestiondropdownlist(companyId).subscribe(
      data => {
        this.data = data;
        if (companyId > 0) {
          this.questionlist = this.data.dataList;
          if (this.FilertingData.data.partQuestions != null && this.FilertingData.data.partQuestions.length > 0) {
            this.FilertingData.data.partQuestions.forEach(cateLst => {

              this.questionlist = this.questionlist.filter(x => x.id != cateLst.questionId);
            });

          }
        }
        else {

        }
        this.tempQuestionlist = this.data.dataList;
        this.questionlistNew = [...this.data.dataList];

      }
    )
  }

  hidemodalpopup() {
    $("#myModal").hide();
  }

  hidemodalpopup1() {
    $("#myModal1").hide();
  }

  addsuccess() {
    $("#add_success").hide();
  }

  updateitem() {
    $("#add_update").hide();
  }

  deleteitem() {
    $("#delete_success").hide();
  }

  msgitem() {
    $("#wrong_msg").hide();
  }


  DeleteImage() {
    let index = this.deletedItemImage;

    if (index != null) {
      this.MultipleImage.splice(index, 1);
    }
    else {

    }
  }

  GetAllImageTypeList() {
    this.partService.GetAllImageTypeList().subscribe(
      data => {
        this.data = data;
        this.ImageTypelist = this.data.dataList;

      }
    )
  }
  public b64toBlob(b64Data, contentType) {
    contentType = contentType || '';
    let sliceSize = 512;

    var byteCharacters = window.atob(b64Data);
    var byteArrays = [];

    for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      var slice = byteCharacters.slice(offset, offset + sliceSize);

      var byteNumbers = new Array(slice.length);
      for (var i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      var byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    var blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  viewImage(value, documenttype) {
    if (value != null && documenttype == "PDF") {
      // value = this._sanitizer.bypassSecurityTrustUrl(value); 
      // value = this._sanitizer.bypassSecurityTrustHtml(value);
      value = value.changingThisBreaksApplicationSecurity;
      var value1 = value.split(",");
      var blob = this.b64toBlob(value1[1], "application/pdf");
      let a = document.createElement("a");
      document.body.appendChild(a);
      var url = window.URL.createObjectURL(blob);
      a.href = url;
      let pdfWindow = window.open("")
      pdfWindow.document.write("<iframe width='100%' height='100%' src='" + encodeURI(url) + "'></iframe>");
      // a.download = String("PDF.pdf");
      // a.click();
      // window.URL.revokeObjectURL(url);
      // a.remove();
    }
    else {
      this.viewimagesshow = value;
      $("#myModalImage").show();
    }
  }
  DiscrdProduct(discardItem) {
    this.deletedItem = '';
    if (this.isPartval == false || this.RowEdit == true) {
      this.modalService.open(discardItem, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
      this.deleteDetail = this.constants.ExitMessage;
    }
    else {
      this.productId = 0;
      this.Exit();
    }

  }

  gotoDashBoard() {
    this.Exit();
  }

  Exit() {
    this.isPartval = true;
    this.CheckCategory = false;
    this.resetvendorData();

  }

  getAllProductionRole(companyId) {
    this.sharedService.getAllProductionRole(companyId, '').subscribe(
      data => {
        this.data = data;
        if (companyId > 0) {
          this.userRoleList = this.data.dataList;
        }
        else {
          this.userRole = '';
        }
      });
  }

  TimekeyPress() {
    var field = this.partLeadTime;
    var decimal_pos = field.indexOf(".");

    if (field.length >= 3 && decimal_pos == -1) {
      let maskingTime = '';
      maskingTime = field.substring(0, 2) + "." + field.substring(3, 4);
      this.partLeadTime = maskingTime;
    }
    else {

    }
  }

  keyPressAlphaNumericWithCharacters(event) {

    var inp = String.fromCharCode(event.keyCode);
    // Allow numbers, alpahbets, space, underscore
    if (/[a-zA-Z0-9-_ ]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }


  HideImage() {
    $("#myModalImage").hide();
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this.dtTrigger1.unsubscribe();
  }

  ChangeOrderDropdown(value) {
    if (value) {
      this.mQtyMoveMsg = false;
      this.mInvQtyValidation = false;
      this.loader = true;
      this.sharedService.GetOrderPartQtyToFulfill(value, this.id).subscribe(
        data => {
          this.loader = false;
          this.data = data;
          this.mInvQtyToMove = this.data.data;
          this.mValidateQtyToFulfill = this.data.data;
        }
      );
    }
  }

  MoveInvRow(InvItem) {
    this.MoveInvClr();
    this.MainInvRowNo = InvItem.InvSno;
    this.mInvQty = InvItem.InvQty;
    this.mInvDate = InvItem.InvDate;
    this.mInvCost = InvItem.InvCost;
    this.mInvType = InvItem.InvType;
    if (InvItem.InvType) {
      switch (InvItem.InvType) {
        case 5:
          this.mInvServNo = InvItem.InvType2;
          break;
        case 6:
          this.mInvPartOrder = InvItem.InvType2;
          break;
        case 7:
          this.mInvProductOrder = InvItem.InvType2;
          break;
      }
    }
    this.mInvCustRMA = InvItem.InvCustRMA;
    this.mInvLocation = InvItem.InvPartLocation;
    this.displayOldLocation = InvItem.InvPartLocation;
    this.modalService.dismissAll();
    this.modalService.open(this.itemModel, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false, size: 'xl' });
  }

  MoveInv() {
    if (this.formPopup.controls.mInvQty.status === this.constants.Invalid ||
      this.formPopup.controls.mInvQtyToMove.status === this.constants.Invalid ||
      this.formPopup.controls.mInvDate.status === this.constants.Invalid ||
      this.formPopup.controls.mInvType.status === this.constants.Invalid
    ) {
      this.mInvValidation = true;
      return;
    }
    else {
      if (this.mInvType == "5" && this.formPopup.controls.mInvServNo.status === this.constants.Invalid) {
        this.mInvValidation = true;
        return;
      }
      if (this.mInvType == "6" && this.formPopup.controls.mInvPartOrder.status === this.constants.Invalid) {
        this.mInvValidation = true;
        return;
      }
      if (this.mInvType == "7" && this.formPopup.controls.mInvProductOrder.status === this.constants.Invalid) {
        this.mInvValidation = true;
        return;
      }

      if ((this.mInvType == "6" || this.mInvType == "7") && Number(this.mInvQtyToMove) > Number(this.mValidateQtyToFulfill)) {
        this.mQtyMoveMsg = true;
        this.mInvQtyValidation = true;
        this.mInvValidation = true;
        return;
      }
      else {
        if (Number(this.mInvQtyToMove) > Number(this.mInvQty)) {
          this.mQtyMoveMsg = false;
          this.mInvQtyValidation = true;
          this.mInvValidation = true;
          return;
        }
      }

      this.mQtyMoveMsg = false;
      this.mInvQtyValidation = false;
      this.mInvValidation = false;

      if (this.mInvType == "5") {
        this.mInvType2 = this.mInvServNo;
      }
      else if (this.mInvType == "6") {
        this.mInvType2 = Number(this.mInvPartOrder);

      }
      else if (this.mInvType == "7") {
        this.mInvType2 = Number(this.mInvProductOrder);
      }
      else {
        this.mInvType2 = '';
      }

      const InvData = this.InvList.filter(item => item.InvSno == this.MainInvRowNo);
      if (InvData.length > 0) {

        if (Number(this.mInvQty) == Number(this.mInvQtyToMove)) {
          InvData[0].InvType = Number(this.mInvType);
          InvData[0].InvType2 = this.mInvType2;
          InvData[0].InvPartLocation = this.mInvLocation;
          InvData[0].InvReason = this.mInvReason;
        }
        else {
          let obj = {
            InvSno: this.InvSno,
            partId: this.id,
            pInvSno: this.MainInvRowNo,
            InvQty: Number(this.mInvQtyToMove),
            InvQtyOrdered: Number(this.mInvQtyToMove),
            InvDate: this.mInvDate,
            InvCost: Number(this.mInvCost),
            InvType: Number(this.mInvType),
            InvType2: this.mInvType2,
            InvCustRMA: this.mInvCustRMA,
            isActive: true,
            InvPartLocation: this.mInvLocation,
            InvReason: this.mInvReason,
            MovePid: InvData[0].id == undefined ? null : InvData[0].id.toString()
          }
          this.InvList.push(obj);
          this.InvSno += 1;
          InvData[0].InvQty = Number(InvData[0].InvQty) - Number(this.mInvQtyToMove);
          this.mInvQty = '';
          this.mInvQtyToMove = '';
          this.mInvDate = '';
          this.mInvCost = '';
          this.mInvType = '';
          this.mInvType2 = '';
          this.mInvServNo = '';
          this.mInvPartOrder = '';
          this.mInvProductOrder = '';
          this.mInvCustRMA = '';
          this.mInvLocation = '';
          this.mInvReason = '';
        }
        this.modalService.dismissAll();
        this.mMsg = this.constants.QtyMoved;
        this.mMsgClass = this.constants.SuccessPng;
        this.modalService.open(this.QtyMoveMsgModel, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
      }
    }
  }

  MoveInvClr() {
    this.mQtyMoveMsg = false;
    this.mInvQtyValidation = false;
    this.mInvValidation = false;
    this.mInvQty = '';
    this.mInvQtyToMove = '';
    this.mInvDate = '';
    this.mInvCost = '';
    this.mInvType = '';
    this.mInvType2 = '';
    this.mInvServNo = '';
    this.mInvPartOrder = '';
    this.mInvProductOrder = '';
    this.mInvCustRMA = '';
    this.mInvLocation = '';
    this.mInvReason = '';
    this.mMsg = '';
    this.mMsgClass = '';
  }

  AdjustInvRow(InvItem) {
    this.AdjustInvClr();
    this.MainInvRowNo = InvItem.InvSno;
    this.adjInvQty = InvItem.InvQty;
    this.modalService.dismissAll();
    this.modalService.open(this.itemModel2, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false, size: 'xl' });
  }

  AdjustInv() {
    if (this.formPopupAdjustQty.controls.adjInvQty.status === this.constants.Invalid ||
      this.formPopupAdjustQty.controls.adjInvQtyToAdjust.status === this.constants.Invalid
    ) {
      this.adjInvValidation = true;
      return;
    }
    else {


      if (Number(this.adjInvQty) == Number(this.adjInvQtyToAdjust)) {
        this.adjInvValidation = true;
        return;
      }

      this.adjInvValidation = false;

      const InvData = this.InvList.filter(item => item.InvSno == this.MainInvRowNo);
      if (InvData.length > 0) {
        InvData[0].InvQty = Number(this.adjInvQtyToAdjust);
        InvData[0].InvReason = this.adjInvReason;
      }

      this.modalService.dismissAll();
      this.mMsg = this.constants.QtyAdjusted;
      this.mMsgClass = this.constants.SuccessPng;
      this.modalService.open(this.QtyMoveMsgModel, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
    }
  }

  AdjustInvClr() {
    this.adjInvValidation = false;
    this.adjInvQty = '';
    this.adjInvQtyToAdjust = '';
    this.adjInvReason = '';
    this.mMsg = '';
    this.mMsgClass = '';
  }

  viewLogFun() {
    if (this.value_search == 0) {
      this.SearchValidation = true;
      return;
    }
    else {
      this.TotalIn = 0;
      this.TotalOut = 0;
      this.loader = true;
      this.sharedService.GetLog(this.value_search, this.id).subscribe(
        data => {
          this.data = data;
          if (this.data.dataList != null && this.data.dataList != undefined) {
            if (this.data.dataList.length > 0) {
              this.viewLog = !this.viewLog;
              this.logList = this.data.dataList;
              if (this.NonInventory == true) {
                const invLogListing = this.logList.filter(x => x.type == 'Part SO Order' || x.type == 'Product SO Order');
                this.logList = invLogListing;
              }

              this.logList.forEach(logLst => {
                let inQty = logLst.inQty == null || logLst.inQty == "" ? 0 : logLst.inQty;
                let outQty = logLst.outQty == null || logLst.outQty == "" ? 0 : logLst.outQty;

                if (logLst.isActive == true) {
                  this.TotalIn += inQty;
                  this.TotalOut += outQty;
                }

                logLst.id = logLst.id;
                logLst.action = logLst.action;
                logLst.type = logLst.type;
                logLst.typeValue = logLst.typeValue;
                logLst.salesOrderNo = logLst.salesOrderNo;
                logLst.inQty = inQty;
                logLst.outQty = outQty;
                logLst.effectDate = this.datepipe.transform(logLst.effectDate, 'MMM-dd-yyyy');
                logLst.remark = logLst.remark;
                logLst.location = logLst.location;
                logLst.isActive = logLst.isActive;
                if (logLst.isActive == false) {
                  logLst.bcolor = this.constants.FireBrick;
                }
                else {
                  logLst.bcolor = '';
                }
              });
            }
            else {
              this.viewLog = false;
              this.logList = [];
            }
            this.loader = false;
          }
        }
      )
    }
  }

  onValueChange1(event) {
    var a = event.target.value.split("-");
    if (a != "") {
      this.partCategoryId = a[1];
      this.wordpressid = a[0];
      this.sharedService.GetMarkup(this.CompanyValue, this.partCategoryId).subscribe(
        (data: any) => {
          if (data.dataList[0].markupMargin == null) {
            this.MarkupMargin = 0;
          }
          else {
            this.MarkupMargin = data.dataList[0].markupMargin;

          }

          if (this.MarkupCheck) {
            var calcMargin = 1 + Number(this.MarkupMargin);
            this.cost = Number(this.VendorPartCost * calcMargin).toFixed(5);
            if (this.cost == "NaN") {
              this.cost = 0;
            }
          }

          if (a[0] != "0" && a != "" && a[0] != "null") {
            if (this.NotActive == false) {
              this.CheckCategory = true;
              this.CheckCategoryDisable = false;
            }
            else {
              this.CheckCategory = false;
              this.CheckCategoryDisable = true;
            }

          }
          else {
            this.CheckCategory = false;
            this.CheckCategoryDisable = false;

          }
        });

    }
    else {
      this.MarkupMargin = '';
      this.CheckCategory = false;
    }


  }


  async openModal() {
    if (this.CheckId == 0) {
      this.loader = true;
      this.Getdata(this.CompanyValue, '');
    }
    else {
      this.loader = true;
      this.IsOpen = true;
      this.Getdata(this.CompanyValue, 'ok');

    }
  }

  Getdata(id, name) {

    if (this.CheckId == 0) {
      this.loader = false;
      $("#CheckCategoryModal").show();

    }
    else {
      $("#CheckCategoryModal").show();
      this.IsUpdate = true;
      this.IsSave = false;
      this.IsOpen = false;
      this.loader = false;
    }
  }

  togglePopModel(name) {
    this.partService.GetApiDetailsById(this.CheckId, this.CompanyValue).subscribe(
      (data: any) => {
        if (data.data.apiWordpressDetails.length > 0) {
          for (var itemimage = 0; itemimage < data.data.apiWordpressDetails.length; itemimage++) {
            if (itemimage == 0) {
              this.shortDescription = data.data.apiWordpressDetails[itemimage].moreInformation;
              this.InfoName = data.data.apiWordpressDetails[itemimage].informationName;
              this.MatingName = data.data.apiWordpressDetails[itemimage].matingName;
              this.CrimpName = data.data.apiWordpressDetails[itemimage].crimpName;
              this.RelatedName = data.data.apiWordpressDetails[itemimage].relatedName;
              this.GetCategory(data.data.apiWordpressDetails[itemimage].id, name);

            }

          }
        }
        else {
          this.loader = false;
        }


      });
  }
  GetCategory(id: any, name: string) {
    this.partService.GetCategoryDetails(id).subscribe(
      (data: any) => {
        const datas = data;

        const apiWordpress = data.dataList[0].apiWordpressProduct.filter(x => x.type == 'Mating' && x.isChecked == true);
        if (apiWordpress.length > 0) {
          apiWordpress.forEach(e => {
            if (this.WordpressCategoryList.length > 0) {
              this.WordpressCategoryList.filter(f => f.id == e.categoryId)[0].IsChecked = true;
              this.NewArray.push(e.categoryId);
              this.GetdataLoad(e.categoryId, apiWordpress, id, datas);
            }

          });
        }
        else {
          this.GetdataLoad(id, apiWordpress, id, datas);
        }

      });
    this.loader = false;
  }
  GetdataLoad(Id, data1, id1, data: any) {
    this.loader = true;
    const ab = data;
    this.partService.GetAllCustomerPartNameSelected(this.CompanyValue, Id, id1, 'ApiWordpressCategory').subscribe(
      data => {
        this.data = data;
        this.data.dataList.forEach(e => {
          this.CustomerPartNameList.push(e);

        });
        const Iddata = this.CustomerPartNameList.filter(arr => arr.isActive === true && arr.partCategoryId === Id);
        for (let index = 0; index < Iddata.length; index++) {
          var obj = { 'id': Iddata[index].wordpressId, 'id1': Iddata[index].partCategoryId };
          this.NewArray1.push(obj);

        }
        const apiWordpressProduct = ab.dataList[0].apiWordpressProduct.filter(x => x.type == 'Crimp' && x.isChecked == true);

        if (apiWordpressProduct.length > 0) {
          apiWordpressProduct.forEach(e => {
            this.WordpressCategoryCrimpToolsList.filter(f => f.id == e.categoryId)[0].IsChecked = true;
            this.CrimpNewArray.push(e.categoryId);
            this.GetdataLoadCrimp(e.categoryId, apiWordpressProduct, id1, ab);
          });
        }
        else {
          this.GetdataLoadCrimp(Id, apiWordpressProduct, id1, ab);
        }

        this.loader = false;
      }
    )
  }
  Getdata2(Id) {
    this.loader = true;
    this.partService.GetAllWordpressProduct(this.CompanyValue).subscribe(
      data => {
        this.data = data;
        this.data.dataList.forEach(e => {
          this.WordpressAllProductList.push(e);
        });
        this.CustomerPartNameList = this.WordpressAllProductList;

        this.loader = false;
      }
    )
  }
  Getdata1(Id, rowIndex, name) {
    this.loader = true;
    this.partService.GetAllCustomerPartNameByCompanyId(this.CompanyValue, Id).subscribe(
      (data: any) => {
        switch (data.statusCode) {
          case 200:
            this.data = data;
            this.data.dataList.forEach(e => {
              this.CustomerPartNameList.push(e);
            });
            this.loader = false;
            break;
          case 404:
            $('#wrong_mssg').show();
            this.loader = false;
            break;
          case 400:
            this.noproduct = "No Company Part Name Found in '" + name + "' Part Category";
            $('#noproduct').show();
            const index: number = this.NewArray.indexOf(Id);
            this.NewArray.splice(index, 1);
            this.loader = false;
            this.WordpressCategoryList[rowIndex].IsChecked = false;

            break;
          default:
            $('#wrong_mssg').show();
            this.loader = false;
        }
      }
    )
  }
  mssgitem() {
    $('#wrong_mssg').hide();
  }
  noprod() {
    $('#noproduct').hide();
  }


  GetdataLoadCrimp(Id, data1, id1, data: any) {
    this.loader = true;
    const ac = data;
    this.partService.GetAllCustomerPartNameSelected(this.CompanyValue, Id, id1, 'ApiWordpressProduct').subscribe(
      data => {
        this.data = data;
        this.data.dataList.forEach(e => {
          this.CustomerPartNameCrimpList.push(e);
        });
        const Iddata = this.CustomerPartNameCrimpList.filter(arr => arr.isActive === true && arr.partCategoryId === Id);
        for (let index = 0; index < Iddata.length; index++) {
          var obj = { 'id': Iddata[index].wordpressId, 'id1': Iddata[index].partCategoryId };
          this.CrimpNewArray1.push(obj);

        }
        const apiWordpressRelProduct = ac.dataList[0].apiWordpressProduct.filter(x => x.type == 'Related' && x.isChecked == true);

        apiWordpressRelProduct.forEach(e => {
          this.WordpressCategoryRelatedItemList.filter(f => f.id == e.categoryId)[0].IsChecked = true;
          this.RelatedNewArray.push(e.categoryId);
          this.GetdataLoadRelated(e.categoryId, apiWordpressRelProduct, id1);
        });
        this.loader = false;
      }
    )
  }
  GetdataLoadRelated(Id, data1, id1) {
    this.loader = true;
    this.partService.GetAllCustomerPartNameSelected(this.CompanyValue, Id, id1, 'ApiWordpressRelatedProduct').subscribe(
      data => {
        this.data = data;
        this.data.dataList.forEach(e => {
          this.CustomerPartNameRelatedList.push(e);
        });
        const Iddata = this.CustomerPartNameRelatedList.filter(arr => arr.isActive === true && arr.partCategoryId === Id);
        for (let index = 0; index < Iddata.length; index++) {
          var obj = { 'id': Iddata[index].wordpressId, 'id1': Iddata[index].partCategoryId };
          this.RelatedNewArray1.push(obj);

        }

        this.loader = false;
      }
    )
  }
  Getdata1Crimp(Id, rowIndex, name) {
    this.loader = true;
    this.partService.GetAllCustomerPartNameByCompanyId(this.CompanyValue, Id).subscribe(
      (data: any) => {
        switch (data.statusCode) {
          case 200:
            this.data = data;
            this.data.dataList.forEach(e => {
              this.CustomerPartNameCrimpList.push(e);
            });
            this.loader = false;
            break;
          case 404:
            $('#wrong_mssg').show();
            this.loader = false;
            break;
          case 400:
            this.noproduct = "No Company Part Name Found in '" + name + "' Part Category";
            $('#noproduct').show();
            const index: number = this.CrimpNewArray.indexOf(Id);
            this.CrimpNewArray.splice(index, 1);
            this.loader = false;
            this.WordpressCategoryCrimpToolsList[rowIndex].IsChecked = false;

            break;
          default:
            $('#wrong_mssg').show();
            this.loader = false;
        }
      }
    )
  }
  GetdataRelatedItem(Id, rowIndex, name) {
    this.loader = true;
    this.partService.GetAllCustomerPartNameByCompanyId(this.CompanyValue, Id).subscribe(
      (data: any) => {
        switch (data.statusCode) {
          case 200:
            this.data = data;
            this.data.dataList.forEach(e => {
              this.CustomerPartNameRelatedList.push(e);
            });
            this.loader = false;
            break;
          case 404:
            $('#wrong_mssg').show();
            this.loader = false;
            break;
          case 400:
            this.noproduct = "No Company Part Name Found in '" + name + "' Part Category";
            $('#noproduct').show();
            const index: number = this.RelatedNewArray.indexOf(Id);
            this.RelatedNewArray.splice(index, 1);
            this.loader = false;
            this.WordpressCategoryRelatedItemList[rowIndex].IsChecked = false;

            break;
          default:
            $('#wrong_mssg').show();
            this.loader = false;
        }
      }
    )
  }
  chngIsInv(event, rowIndex, id, name) {
    if (event == true) {

      if (this.NewArray.length <= 3) {
        this.NewArray.push(id);
        this.WordpressCategoryList[rowIndex].IsChecked = true;
        this.Getdata1(id, rowIndex, name);
      }
      else {
        //this.toastr.error('Only 4 items Could be Selected in Mating Connectors');
        $('#add_Items').show();
        this.MainProp = "Only 4 part category could be selected in Mating Connectors";
        return false;
      }
    }
    else {
      const index: number = this.NewArray.indexOf(id);
      this.NewArray.splice(index, 1);

      if (this.CheckId == 0) {
        this.CustomerPartNameList = this.CustomerPartNameList.filter(item => !(item.partCategoryId === id));
      }
      else {
        const list = this.CustomerPartNameList.filter(item => (item.partCategoryId === id));
        this.MatingDisableList = list.filter(x => x.isActive == true);
        this.CustomerPartNameList = this.CustomerPartNameList.filter(item => !(item.partCategoryId === id));
      }
      this.NewArray1 = this.NewArray1.filter(item => !(item.id1 === id));
      this.WordpressCategoryList[rowIndex].IsChecked = false;
      this.CustomeList = [];
    }
  }

  chngIsInvCrimp(event, rowIndex, id, name) {
    if (event == true) {
      if (this.CrimpNewArray.length <= 3) {
        this.CrimpNewArray.push(id);
        this.WordpressCategoryCrimpToolsList[rowIndex].IsChecked = true;
        this.Getdata1Crimp(id, rowIndex, name);
      }
      else {
        $('#add_Items').show();
        this.MainProp = "Only 4 part category could be selected in Crimp Tools";
        return false;
      }
    }
    else {
      const index: number = this.CrimpNewArray.indexOf(id);
      this.CrimpNewArray.splice(index, 1);

      if (this.CheckId == 0) {
        this.CustomerPartNameCrimpList = this.CustomerPartNameCrimpList.filter(item => !(item.partCategoryId === id));
      }
      else {
        const list = this.CustomerPartNameCrimpList.filter(item => (item.partCategoryId === id));
        this.CrimpDisableList = list.filter(x => x.isActive == true);
        this.CustomerPartNameCrimpList = this.CustomerPartNameCrimpList.filter(item => !(item.partCategoryId === id));
      }

      this.CrimpNewArray1 = this.CrimpNewArray1.filter(item => !(item.id1 === id));
      this.WordpressCategoryCrimpToolsList[rowIndex].IsChecked = false;
    }
  }
  chngIsInvRelated(event, rowIndex, id, name) {
    if (event == true) {
      if (this.RelatedNewArray.length <= 3) {
        this.RelatedNewArray.push(id);
        this.WordpressCategoryRelatedItemList[rowIndex].IsChecked = true;
        this.GetdataRelatedItem(id, rowIndex, name);
      }
      else {
        $('#add_Items').show();
        this.MainProp = "Only 4 part category could be selected in Related Items";
        return false;
      }
    }
    else {
      const index: number = this.RelatedNewArray.indexOf(id);
      this.RelatedNewArray.splice(index, 1);
      if (this.CheckId == 0) {
        this.CustomerPartNameRelatedList = this.CustomerPartNameRelatedList.filter(item => !(item.partCategoryId === id));
      }
      else {
        const list = this.CustomerPartNameRelatedList.filter(item => (item.partCategoryId === id));
        this.RelatedDisableList = list.filter(x => x.isActive == true);
        this.CustomerPartNameRelatedList = this.CustomerPartNameRelatedList.filter(item => !(item.partCategoryId === id));
      }

      this.RelatedNewArray1 = this.RelatedNewArray1.filter(item => !(item.id1 === id));
      this.WordpressCategoryRelatedItemList[rowIndex].IsChecked = false;
    }
  }
  chngIsInv1(event, rowIndex, id, id1) {
    if (event == true) {

      if (this.NewArray1.length <= 3) {
        var obj = { 'id': id, 'id1': id1 };
        this.NewArray1.push(obj);
        this.CustomerPartNameList[rowIndex].IsChecked = true;

      }
      else {
        $("#add_Items").show();
        this.MainProp = "Only 4 customer part name could be selected in Mating Connectors";
        return false;
      }
    }
    else {
      const index: number = this.NewArray1.indexOf(id);
      this.NewArray1.splice(index, 1);
      if (this.CheckId == 0) {
        this.CustomerPartNameList[rowIndex].IsChecked = false;
      }
      else {
        this.CustomerPartNameList[rowIndex].IsChecked = false;
        this.CustomerPartNameList[rowIndex].isActive = false;
      }

    }
  }

  chngIsInv1Crimp(event, rowIndex, id, id1) {
    if (event == true) {
      if (this.CrimpNewArray1.length <= 3) {
        var obj = { 'id': id, 'id1': id1 };
        this.CrimpNewArray1.push(obj);
        this.CustomerPartNameCrimpList[rowIndex].IsChecked = true;
      }
      else {
        $("#add_Items").show();
        this.MainProp = "Only 4 customer part name could be selected in Crimp Tools";
        return false;
      }
    }
    else {
      const index: number = this.CrimpNewArray1.indexOf(id);
      this.CrimpNewArray1.splice(index, 1);
      if (this.CheckId == 0) {
        this.CustomerPartNameCrimpList[rowIndex].IsChecked = false;
      }
      else {
        this.CustomerPartNameCrimpList[rowIndex].IsChecked = false;
        this.CustomerPartNameCrimpList[rowIndex].isActive = false;
      }

    }
  }

  chngIsInv1Related(event, rowIndex, id, id1) {
    if (event == true) {
      if (this.RelatedNewArray1.length <= 3) {
        var obj = { 'id': id, 'id1': id1 };
        this.RelatedNewArray1.push(obj);
        this.CustomerPartNameRelatedList[rowIndex].IsChecked = true;

      }
      else {
        $("#add_Items").show();
        this.MainProp = "Only 4 customer part name could be selected in Related Items";
        return false;
      }
    }
    else {
      const index: number = this.RelatedNewArray1.indexOf(id);
      this.RelatedNewArray1.splice(index, 1);
      if (this.CheckId == 0) {
        this.CustomerPartNameRelatedList[rowIndex].IsChecked = false;
      }
      else {
        this.CustomerPartNameRelatedList[rowIndex].isActive = false;
        this.CustomerPartNameRelatedList[rowIndex].IsChecked = false;
      }

    }
  }

  closepopModal() {
    $("#CheckCategoryModal").hide();
  }

  ResetPopModelData1() {
    this.NewPartialArray = [];
  }

  ResetPopModelData() {
    this.WordpressCategoryList = [];
    this.WordpressCategoryCrimpToolsList = [];
    this.WordpressCategoryRelatedItemList = [];
    this.NewArray2 = [];
    this.NewArray = [];
    this.NewArray1 = [];
    this.CrimpNewArray = [];
    this.CrimpNewArray1 = [];
    this.RelatedNewArray = [];
    this.RelatedNewArray1 = [];
    this.CustomerPartNameList = [];
    this.CustomerPartNameCrimpList = [];
    this.CustomerPartNameRelatedList = [];
    this.shortDescription = '';
    this.InfoName = "More Information";
    this.MatingName = "Mating Connectors";
    this.CrimpName = "Crimp Tools";
    this.RelatedName = "Related Items";
  }

  changeDimentions(e) {
    this.DimentionTypeL = Number(e.target.value);
    this.DimentionTypeW = Number(e.target.value);
    this.DimentionTypeH = Number(e.target.value);

  }
  changeMargin(event: any) {
    
    if (event.target.checked == true) {
      this.IsMarginDisabled = false;
      this.IsPricePart = true;
      var calcMargin = 1 + Number(this.MarkupMargin);
      this.cost = Number.isNaN(Number(this.VendorPartCost * calcMargin)) ? 0 : Number(this.VendorPartCost * calcMargin).toFixed(5);
      if (Number.isNaN(this.cost) || this.cost == "NaN") {
        this.cost = 0;
        this.cost = this.cost.toFixed(5);
      }
    }
    else {
      this.IsMarginDisabled = true;
      this.IsPricePart = false;
      this.cost = this.SubTotalPartCost.toFixed(5);
    }
    this.formatCurrencyMargin();
  }

  UpdatePrice() {
    if (this.MarkupCheck) {
      var calcMargin = 1 + Number(this.MarkupMargin);
      this.cost = Number(this.VendorPartCost * calcMargin).toFixed(5);
      if (this.cost == "NaN") {
        this.cost = 0;
      }
    }
    this.formatCurrencymargin();
  }
  keyPressNumbers(event) {
    var character = String.fromCharCode(event.keyCode);
    if (this.MarkupMargin == undefined) {
      this.MarkupMargin = '';
    }
    var newValue = this.MarkupMargin + character;
    if (newValue != ".") {
      if (isNaN(Number(newValue)) || this.hasDecimalPlace(newValue, 4)) {
        event.preventDefault();
        return false;
      }
    }
  }
  hasDecimalPlace(value, x) {
    var pointIndex = value.indexOf('.');
    return pointIndex >= 0 && pointIndex < value.length - x;
  }

  openHistoryModal() {
    this.PartHistoryList = [];
    this.loader = true;
    this.partService.GetPartHistoryDetails(this.ManualPartId).subscribe(
      (data: any) => {
        data.dataList.forEach(e => {
          this.PartHistoryList.push(e);
        });
        this.loader = false;
        if (this.PartHistoryList.length == 0) {
          this.CheckHistoryList = false;
        }
        else {
          this.CheckHistoryList = true;
        }
        $('#history').show();
      });

  }

  historyclose() {
    $('#history').hide();
  }


  WordpressCategoryLoad(id) {
    if (id == undefined) {
      id = 0;
    }
    this.partService.GetAllWordpressCategory(id).subscribe(
      (data: any) => {
        if (id > 0) {
          switch (data.statusCode) {
            case 200:

              this.data = data;
              this.WordpressCategoryPartialList = this.data.dataList;
              this.WordpressCategoryList = this.data.dataList.map(x => Object.assign({}, x));
              this.WordpressCategoryCrimpToolsList = this.data.dataList.map(x => Object.assign({}, x));
              this.WordpressCategoryRelatedItemList = this.data.dataList.map(x => Object.assign({}, x));
          }
        }
        else {
          this.WordpressCategoryPartialList = [];
          this.WordpressCategoryList = [];
          this.WordpressCategoryCrimpToolsList = [];
          this.WordpressCategoryRelatedItemList = [];
        }

      });

  }
  NultiCategoryModel() {
    $('#NultiCategoryModel').hide();
  }
  changeActive(event: any) {
    let count = 0;
    const catList = this.CategoryArr.filter(x => x.isActive == true);
    for (let i = 0; i < catList.length; i++) {
      var str = String(catList[i].partcategoryid);
      var includeStr = "-";
      var wid;
      if (str.includes(includeStr)) {
        var spilted = catList[i].partcategoryid.split('-');
        wid = spilted[1];
        var pid = spilted[0];
      }
      else {
        wid = catList[i].partWordpressid;
        var pid = catList[i].partcategoryid;
      }
      if (wid != 0 && wid != null) {
        count = count + 1;
      }

    }
    if (count > 0) {
      if (event.target.checked == false && this.NonInventory == false) {
        this.CheckCategory = true;
        this.CheckCategoryDisable = false;
      }
      else {
        this.CheckCategory = false;
        this.CheckCategoryDisable = true;
      }
    }
    else {
      this.CheckCategory = false;
      this.CheckCategoryDisable = false;
    }
  }
  openVendorModal() {
    if (this.name == "" || this.name == undefined) {
      this.partNameProperty = "Please Enter List Part Name";
      $('#PartNameModel').show();
    }
    else {
      if (this.partNo == "" || this.partNo == undefined) {
        this.partNameProperty = "Please Enter List Part Number";
        $('#PartNameModel').show();
      }
      else {
        if (this.selectedVendorValue == "" || this.selectedVendorValue == undefined) {
          this.partNameProperty = "Please Select Primary Vendor";
          $('#PartNameModel').show();
        }
        else {
          this.PartName1 = this.name;
          this.PartNumber1 = this.partNo;
          var spilted = this.selectedVendorValue.split("-");
          const vendorValue = spilted[0];
          this.vendorlist2 = this.vendorlist1.filter(x => x.id != vendorValue);
          if (this.CategoryArr.length > 0) {
            this.partmodelVendorValue = '';
            this.PartMarkupMargin = this.MarkupMargin;
            this.PVendorCost = this.VendorPartCost;
            this.PPartPrice = this.cost;
            this.PartMarkupCheck = this.MarkupCheck;
            this.PartMarkupMargin = this.MarkupMargin;
            this.PVendorNumber = this.VendorPartNumber;
            this.PVendorPartName = this.VendorPartName;
            this.IsPartSave = true;
            this.IsPartUpdate = false;
            this.partvalidation = false;
            $('#vendorModel').show();
          }
          else {
            this.partNameProperty = "Please Select Part Category";
            $('#PartNameModel').show();
          }
        }

      }

    }

  }
  PartNameModel() {
    $('#PartNameModel').hide();
  }

  partChangeMargin(event: any) {
    if (event.target.checked == true) {
      this.IsPartMarginDisabled = false;
      this.IsPPricePart = true;
      var calcMargin = 1 + Number(this.PartMarkupMargin);
      this.PPartPrice = Number.isNaN(Number(this.PVendorCost * calcMargin)) ? 0 : Number(this.PVendorCost * calcMargin).toFixed(5);
      if (Number.isNaN(this.PPartPrice) || this.PPartPrice == "NaN") {
        this.PPartPrice = 0;
      }
    }
    else {
      this.IsPartMarginDisabled = true;
      this.IsPPricePart = false;
      this.PPartPrice = Number('');
    }
  }
  AddVendorPart() {
    this.partvalidation = true;
    if (this.partForm.controls.partmodelVendorValue.status === this.constants.Invalid ||
      this.partForm.controls.PVendorCost.status === this.constants.Invalid ||
      this.partForm.controls.PPartPrice.status === this.constants.Invalid || this.partForm.controls.PVendorNumber.status === this.constants.Invalid ||
      this.partForm.controls.PPartPrice.status === this.constants.Invalid ||
      this.CompanyValue === 0
    ) {
      return;
    }
    if (this.PartMarkupCheck == true) {
      if (this.partForm.controls.PartMarkupMargin.status === this.constants.Invalid) {
        return;
      }

    }
    else {
      this.partForm.controls.PartMarkupMargin.status === "VALID";
    }

    let objs = {};
    var data = this.partmodelVendorValue.split("-");

    objs['vendorId'] = Number(data[1]);
    objs['vendorName'] = String(data[0]);
    objs['Discount'] = String(data[2]);
    const vid = Number(objs['vendorId']);
    const AlreadyExist = this.AddvendorList.find(x => x.vendorId == vid && x.IsActive == true);
    if (AlreadyExist) {
      this.AlreadyVendorName = String(data[0]) + " Already Added in the Additional Vendors for Parts";
      $('#AlreadyVendor').show();
    }
    else {
      objs["id"] = Number(this.i) + 1;
      this.i = objs["id"];
      objs['vendorPartCost'] = Number(this.PVendorCost).toFixed(5);
      objs['cost'] = Number(this.PPartPrice).toFixed(5);
      objs['markupCheck'] = this.PartMarkupCheck;
      objs['markupMargin'] = this.PartMarkupMargin;
      objs['companyId'] = Number(this.CompanyValue);
      objs['vendorPartName'] = this.PVendorPartName;
      objs['vendorPartNumber'] = this.PVendorNumber;
      objs['ModifiedBy'] = this.userId;
      objs['CreatedBy'] = this.userId;
      objs["CreatedDate"] = this.now = new Date();
      objs["ModifiedDate"] = this.now = new Date();
      objs["IsActive"] = true;
      this.AddvendorList.push(objs);
      this.rerender("one");
      // this.AddvendorList = this.AddvendorList.find(x=>x.IsActive == true);
      this.resetData();
      var obj = {
        "Id": vid,
        "name": objs['vendorName'],
        "type": "2"
      }
      this.partVendorsList.push(obj);

      this.SaveModel = "Save Successfully";
      const counting = this.AddvendorList.filter(x => x.IsActive == true);
      if (counting.length > 0) {
        this.DeActiveCouting = false;
        this.ActiveCouting = true;
      }
      else {
        this.DeActiveCouting = true;
        this.ActiveCouting = false;
      }
      $('#SaveModel').show();
    }



  }
  SavePopModel() {
    $('#SaveModel').hide();
  }
  AlreadyVendor() {
    $('#AlreadyVendor').hide();
  }
  resetData() {
    this.partvalidation = false;
    this.PartMarkupMargin = this.MarkupMargin;
    this.PVendorCost = Number(this.VendorPartCost).toFixed(5);
    this.PPartPrice = this.cost;
    this.PartMarkupCheck = this.MarkupCheck;
    this.PartMarkupMargin = this.MarkupMargin;
    this.PVendorNumber = this.VendorPartNumber;
    this.PVendorPartName = this.VendorPartName;
    this.partmodelVendorValue = "";
    this.IsPartUpdate = false;
    this.IsPartSave = true;
  }
  vendorclose() {
    $('#vendorModel').hide();
  }

  Getmultivendordropdownlist(companyId, editrow, customid, Alist) {
    if (companyId == undefined) {
      companyId = 0;
    }
    this.partService.Getmultivendordropdownlist(companyId).subscribe(
      data => {
        this.data = data;
        if (companyId > 0) {
          this.vendorlist1 = this.data.dataList;
          let vendorList = [];
          if (this.data.dataList) {
            this.data.dataList.forEach((item, index) => {
              let obj = {
                partcategoryid: item.id,
                partcategorytext: item.vendorName
              }
              vendorList.push(obj);

            });
            customid = customid == 0 ? undefined : customid;
            if (editrow != undefined && customid != undefined && Alist != undefined) {

              if (editrow == true) {
                $('#vendorModel').show();
                this.loader = true;
                data = Alist.find(x => x.id == customid && x.IsActive == true);
                this.loader = false;
                this.partmodelVendorValue = data["vendorName"] + "-" + data["vendorId"] + "-" + data["Discount"];
                var spilted = this.selectedVendorValue.split("-");
                const vendorValue = spilted[0];
                this.vendorlist2 = this.vendorlist1.filter(x => x.id != vendorValue);
                this.PVendorCost = data["vendorPartCost"];
                this.PPartPrice = data["cost"];
                this.PartMarkupCheck = data["markupCheck"];
                this.PartMarkupMargin = data["markupMargin"];
                this.PVendorPartName = data['vendorPartName'];
                this.PVendorNumber = data['vendorPartNumber'];
                this.EditpartId = data["id"];
                this.IsPartUpdate = true;
                this.IsPartSave = false;
                const counting = Alist.filter(x => x.IsActive == true);
                if (counting.length > 0) {
                  this.DeActiveCouting = false;
                  this.ActiveCouting = true;
                }
                else {
                  this.DeActiveCouting = true;
                  this.ActiveCouting = false;
                }
              }
            }
            //  this.dropdownList = vendorList;
          }

        }
        else {
          this.partmodelVendorValue = '';
        }
        this.GetpartGategoryDropdownList(companyId);

      }
    )
  }
  VendorPartEdit(item: any) {
    this.loader = true;
    var data = {};
    data = this.AddvendorList.find(x => x.id == item.id && x.IsActive == true);
    this.loader = false;
    this.partmodelVendorValue = data["vendorName"] + "-" + data["vendorId"] + "-" + data["Discount"];
    this.PVendorCost = Number(data["vendorPartCost"]).toFixed(5);
    this.PPartPrice = Number(data["cost"]).toFixed(5);
    this.PartMarkupCheck = data["markupCheck"];
    this.PartMarkupMargin = data["markupMargin"];
    this.PVendorPartName = data['vendorPartName'];
    this.PVendorNumber = data['vendorPartNumber'];
    this.EditpartId = data["id"];
    this.IsPartUpdate = true;
    this.IsPartSave = false;
    const counting = this.AddvendorList.filter(x => x.IsActive == true);
    if (counting.length > 0) {
      this.DeActiveCouting = false;
      this.ActiveCouting = true;
    }
    else {
      this.DeActiveCouting = true;
      this.ActiveCouting = false;
    }
  }
  UpdateVendorPart() {
    this.partvalidation = true;
    if (this.partForm.controls.partmodelVendorValue.status === this.constants.Invalid ||
      this.partForm.controls.PVendorCost.status === this.constants.Invalid ||
      this.partForm.controls.PPartPrice.status === this.constants.Invalid ||
      this.partForm.controls.PVendorNumber.status === this.constants.Invalid ||
      this.partForm.controls.PPartPrice.status === this.constants.Invalid ||
      this.CompanyValue === 0
    ) {
      return;
    }
    var data = {};
    data = this.AddvendorList.find(x => x.id == this.EditpartId && x.IsActive == true);
    var data1 = this.partmodelVendorValue.split("-");
    const AlreadyExist = this.AddvendorList.find(x => x.vendorId == Number(data1[1]) && x.IsActive == true && x.id != this.EditpartId);
    if (AlreadyExist) {
      this.AlreadyVendorName = String(data1[0]) + " Already Added in the Additional Vendors for Parts";
      $('#AlreadyVendor').show();
    }
    else {
      var checklist = this.partVendorsList.filter(x => x.type == "2" && x.Id == Number(data['vendorId']));
      if (checklist.length > 0) {
        var updateInvList = this.InvList.filter(x => x.InvVendor == checklist[0].Id);
        if (updateInvList.length > 0) {
          updateInvList.forEach(element => {
            element.InvVendor = Number(data1[1]);
          });
        }
        checklist[0].Id = Number(data1[1]);
        checklist[0].name = String(data1[0]);
        checklist[0].type = "2";
      }
      else {
        var obj = {
          "Id": Number(data1[1]),
          "name": String(data1[0]),
          "type": "2"
        }
        this.partVendorsList.push(obj);
      }

      data['vendorId'] = Number(data1[1]);
      data['vendorName'] = String(data1[0]);
      data['Discount'] = String(data1[2]);
      data['vendorPartCost'] = Number(this.PVendorCost);
      data['cost'] = Number(this.PPartPrice);
      data['markupCheck'] = this.PartMarkupCheck;
      data['markupMargin'] = this.PartMarkupMargin;
      data['vendorPartName'] = this.PVendorPartName;
      data['vendorPartNumber'] = this.PVendorNumber;
      data['companyId'] = Number(this.CompanyValue);
      data['ModifiedBy'] = this.userId;
      data['CreatedBy'] = this.userId;
      data["CreatedDate"] = data["CreatedDate"];
      data["ModifiedDate"] = data["ModifiedDate"];
      data["IsActive"] = true;
      this.SaveModel = "Update Successfully";
      $('#SaveModel').show();
      this.resetData();
      const counting = this.AddvendorList.filter(x => x.IsActive == true);
      if (counting.length > 0) {
        this.DeActiveCouting = false;
        this.ActiveCouting = true;
      }
      else {
        this.DeActiveCouting = true;
        this.ActiveCouting = false;
      }
    }


  }

  deletePartVendorList(id: any) {
    var data = {};
    data = this.AddvendorList.find(x => x.id == id && x.IsActive == true);
    data['vendorId'] = data["vendorId"];
    data['vendorName'] = data["vendorName"];
    data['Discount'] = data["Discount"];
    data['vendorPartCost'] = data["vendorPartCost"];
    data['cost'] = data["cost"];
    data['markupCheck'] = data["markupCheck"];
    data['markupMargin'] = data['markupMargin'];
    data['vendorPartName'] = data['vendorPartName'];
    data['vendorPartNumber'] = data['vendorPartNumber'];
    data['companyId'] = data['companyId'];
    data['ModifiedBy'] = data['ModifiedBy'];
    data['CreatedBy'] = data['CreatedBy'];
    data["CreatedDate"] = data["CreatedDate"];
    data["ModifiedDate"] = data["ModifiedDate"];
    data["IsActive"] = false;
    // this.AddvendorList = this.AddvendorList.filter(x=>x.id !== id);
    $('#deleteVendorlist').hide();
    this.SaveModel = "Delete Successfully";
    const counting = this.AddvendorList.filter(x => x.IsActive == true);
    if (counting.length > 0) {
      this.DeActiveCouting = false;
      this.ActiveCouting = true;
    }
    else {
      this.DeActiveCouting = true;
      this.ActiveCouting = false;
    }
    $('#SaveModel').show();
    this.partVendorsList = this.partVendorsList.filter(x => x.Id != Number(data['vendorId']));
  }
  Getvendordropdownlists(companyId, vendorId) {
    this.partService.Getvendordropdownlist(companyId).subscribe(
      data => {
        this.data = data;
        if (companyId > 0) {

          let vendorList = [];
          if (this.data.dataList) {
            this.data.dataList.forEach((item, index) => {
              if (item.id == vendorId) {
                let obj = {
                  id: item.id,
                  vendorName: item.vendorName,
                  color: item.discount != 0 ? 'red' : 'black',
                  setvalue: false,
                  discount: item.discount
                }
                vendorList.push(obj);
              }
              else {
                let obj = {
                  id: item.id,
                  vendorName: item.vendorName,
                  color: item.discount != 0 ? 'red' : 'black',
                  setvalue: item.discount == 0 ? false : true,
                  discount: item.discount
                }
                vendorList.push(obj);


              }


            });
            this.dropdownList = vendorList;
            this.vendorlist = vendorList;
          }

        }
        else {
          this.selectedVendorValue = '';
        }
        this.GetpartGategoryDropdownList(companyId);
        this.GetCustomCategory(companyId);
      }
    )
  }
  deletevendorPart(item) {
    $('#deleteVendorlist').show();
    this.deleteDetailVendor = 'Are you sure, you want to delete?';
    this.PartdeletedItem = item.id;
  }
  VendorPartCurrency() {
    var input_val = this.PVendorCost;
    var original_len = input_val.length;
    var calcMargin = 1 + Number(this.PartMarkupMargin);
    if (this.PartMarkupCheck) {
      this.PPartPrice = Number(this.PVendorCost * calcMargin).toFixed(5);
      if (this.PPartPrice == "NaN") {
        this.PPartPrice = 0;
      }
    }
    if (input_val != "") {
      if (input_val.indexOf(".") >= 0) {
        var decimal_pos = input_val.indexOf(".");
        var l = input_val.substring(0, decimal_pos);
        var r = input_val.substring(decimal_pos, decimal_pos + 6);
        var right_side = input_val.substring(decimal_pos, 2);
        this.PVendorCost = l + "" + r;
      } else {
        this.PVendorCost = this.PVendorCost + ".00000";
      }
    }
  }

  onItemSelect(item: any) {
    if (this.CategoryArr.length > 0) {
      const insertedData = this.CategoryArr.filter(x => x.partcategoryid == item.partcategoryid && x.IsActive == false);
      if (insertedData.length > 0) {
        insertedData[0].IsActive = true;
      }
      else {
        item.isActive = true;
        this.CategoryArr.push(item);
      }
    }
    else {
      item.isActive = true;
      this.CategoryArr.push(item);
    }
    const catList = this.CategoryArr.filter(x => x.isActive == true);
    let count = 0;
    for (let i = 0; i < catList.length; i++) {
      var str = String(catList[i].partcategoryid);
      var includeStr = "-";
      var wid;
      if (str.includes(includeStr)) {
        var spilted = catList[i].partcategoryid.split('-');
        wid = spilted[1];
        var pid = spilted[0];
      }
      else {
        wid = catList[i].partWordpressid;
        var pid = catList[i].partcategoryid;
      }
      if (wid != 0 && wid != null) {
        count = count + 1;
      }

    }

    if (count > 0) {
      if (this.NotActive == false && this.NonInventory == false) {
        this.CheckCategory = true;
        this.CheckCategoryDisable = false;
      }
      else {
        this.CheckCategory = false;
        this.CheckCategoryDisable = true;
      }
    }
    else {
      this.CheckCategory = false;
      this.CheckCategoryDisable = false;
    }
    const validCatList = this.CategoryArr.filter(x => x.isActive == true);
    if (validCatList.length == 1) {
      var category = validCatList[0].partcategoryid;
      var str = String(category);
      var includeStr = "-";
      var pids;
      if (str.includes(includeStr)) {
        var spilted1 = validCatList[0].partcategoryid.split('-');
        pids = Number(spilted1[0]);
      }
      else {
        pids = Number(validCatList[0].partcategoryid);
      }
      this.sharedService.GetMarkup(this.CompanyValue, pids).subscribe(
        (data: any) => {
          if (data.dataList[0].markupMargin == null) {
            this.MarkupMargin = 0;
          }
          else {
            this.MarkupMargin = data.dataList[0].markupMargin;
          }

          if (this.MarkupCheck) {
            var calcMargin = 1 + Number(this.MarkupMargin);
            this.cost = Number(this.VendorPartCost * calcMargin).toFixed(5);
            if (this.cost == "NaN") {
              this.cost = 0;
            }
          }


        });
    }
    else {
      this.MarkupMargin = 0;
      if (this.MarkupCheck) {
        var calcMargin = 1 + Number(this.MarkupMargin);
        this.cost = Number(this.VendorPartCost * calcMargin).toFixed(5);
        if (this.cost == "NaN") {
          this.cost = 0;
        }
      }

    }


  }

  onItemDeSelect(item: any) {
    if (this.CategoryArr.length > 0) {
      this.CategoryArr.forEach((itemRemove, index) => {
        var str = String(item.partcategoryid);
        var includeStr = "-";
        var pids;
        var pids1;
        var str1 = String(itemRemove.partcategoryid);
        if (str1.includes(includeStr)) {
          var spilted1 = itemRemove.partcategoryid.split('-');
          pids1 = Number(spilted1[0]);
        }
        else {
          pids1 = itemRemove.partcategoryid;
        }
        if (str.includes(includeStr)) {
          var spilted = item.partcategoryid.split('-');
          pids = Number(spilted[0]);
        }
        else {
          pids = item.partcategoryid;
        }
        if (pids === pids1) {
          itemRemove.isActive = false;
        }
      });
      let count = 0;
      const catList = this.CategoryArr.filter(x => x.isActive == true);
      for (let i = 0; i < catList.length; i++) {
        var str = String(catList[i].partcategoryid);
        var includeStr = "-";
        var wid;
        if (str.includes(includeStr)) {
          var spilted = catList[i].partcategoryid.split('-');
          wid = spilted[1];
          var pid = spilted[0];
        }
        else {
          wid = catList[i].partWordpressid;
          var pid = catList[i].partcategoryid;
        }
        if (wid != 0 && wid != null) {
          count = count + 1;
        }

      }
      if (count > 0) {
        if (this.NotActive == false && this.NonInventory == false) {
          this.CheckCategory = true;
          this.CheckCategoryDisable = false;
        }
        else {
          this.CheckCategory = false;
          this.CheckCategoryDisable = true;
        }
      }
      else {
        this.CheckCategory = false;
        this.CheckCategoryDisable = false;
      }

      const validCatList = this.CategoryArr.filter(x => x.isActive == true);
      if (validCatList.length == 1) {
        var category = validCatList[0].partcategoryid;
        var str = String(category);
        var includeStr = "-";
        var pids;
        if (str.includes(includeStr)) {
          var spilted1 = validCatList[0].partcategoryid.split('-');
          pids = Number(spilted1[0]);
        }
        else {
          pids = Number(validCatList[0].partcategoryid);
        }
        this.sharedService.GetMarkup(this.CompanyValue, pids).subscribe(
          (data: any) => {
            if (data.dataList[0].markupMargin == null) {
              this.MarkupMargin = 0;
            }
            else {
              this.MarkupMargin = data.dataList[0].markupMargin;
            }

            if (this.MarkupCheck) {
              var calcMargin = 1 + Number(this.MarkupMargin);
              this.cost = Number(this.VendorPartCost * calcMargin).toFixed(5);
              if (this.cost == "NaN") {
                this.cost = 0;
              }
            }


          });
      }
      else {
        this.MarkupMargin = 0;
        if (this.MarkupCheck) {
          var calcMargin = 1 + Number(this.MarkupMargin);
          this.cost = Number(this.VendorPartCost * calcMargin).toFixed(5);
          if (this.cost == "NaN") {
            this.cost = 0;
          }
        }
      }

    }
  }

  onDeSelectAll(items: any) {
    if (this.CategoryArr.length > 0) {
      this.CategoryArr.forEach((itemInner, index) => {
        itemInner.isActive = false;
        this.CheckCategory = false;
        this.CheckCategoryDisable = false;
      });
      this.MarkupMargin = 0;
    }
  }
  onSelectAll(items: any) {
    let oldCategoryArr = items;
    this.CategoryArr = [];
    items.forEach((itemInner, index) => {
      if (oldCategoryArr.length > 0) {
        itemInner.Id = oldCategoryArr[index].Id;
      }
      itemInner.isActive = true;
      this.CategoryArr.push(itemInner);
    });
    let count = 0;
    const catList = this.CategoryArr.filter(x => x.isActive == true);
    for (let i = 0; i < catList.length; i++) {
      var str = String(catList[i].partcategoryid);
      var includeStr = "-";
      var wid;
      if (str.includes(includeStr)) {
        var spilted = catList[i].partcategoryid.split('-');
        wid = spilted[1];
        var pid = spilted[0];
      }
      else {
        wid = catList[i].partWordpressid;
        var pid = catList[i].partcategoryid;
      }
      if (wid != 0 && wid != null) {
        count = count + 1;
      }

    }

    if (count > 0) {
      if (this.NotActive == false && this.NonInventory == false) {
        this.CheckCategory = true;
        this.CheckCategoryDisable = false;
      }
      else {
        this.CheckCategory = false;
        this.CheckCategoryDisable = true;
      }
    }
    else {
      this.CheckCategory = false;
      this.CheckCategoryDisable = false;
    }
    this.MarkupMargin = 0;
    if (this.MarkupCheck) {
      var calcMargin = 1 + Number(this.MarkupMargin);
      this.cost = Number(this.VendorPartCost * calcMargin).toFixed(5);
      if (this.cost == "NaN") {
        this.cost = 0;
      }
    }
  }
  DisablePrice() {
    if (this.MarkupCheck == true) {
      const trueValue = this.MarkupMargin;
      if (trueValue == undefined) {
        $('#DisableModel').show();
      }

    }

  }

  DisableModel() {
    $('#DisableModel').hide();
  }

  msg404() {
    $("#msgModel404").hide();
  }
  changeNonInventory(event: any) {
    const invListing = this.InvList.filter(x => x.InvType != 6 && x.InvType != 7);
    if (event.target.checked == true) {
      if (this.id != undefined && !Number.isNaN(this.id)) {
        if (invListing.length == 0) {
          this.NonInventory = true;
        }
        else {
          $('#InventoryExist').show();
          this.NonInventory = false;
          event.target.checked = false;
        }
      }
      else {
        if (invListing.length == 0) {
          this.NonInventory = true;
        }
        else {
          $('#InventoryExist').show();
          this.NonInventory = false;
          event.target.checked = false;

        }
      }
      let count = 0;
      const catList = this.CategoryArr.filter(x => x.isActive == true);
      for (let i = 0; i < catList.length; i++) {
        var str = String(catList[i].partcategoryid);
        var includeStr = "-";
        var wid;
        if (str.includes(includeStr)) {
          var spilted = catList[i].partcategoryid.split('-');
          wid = spilted[1];
          var pid = spilted[0];
        }
        else {
          wid = catList[i].partWordpressid;
          var pid = catList[i].partcategoryid;
        }
        if (wid != 0 && wid != null) {
          count = count + 1;
        }

      }
      if (count > 0) {
        if (event.target.checked == false && this.NotActive == false) {
          this.CheckCategory = true;
          this.CheckCategoryDisable = false;
        }
        else {
          this.CheckCategory = false;
          this.CheckCategoryDisable = true;
        }
      }
      else {
        this.CheckCategory = false;
        this.CheckCategoryDisable = false;
      }


    }
    else {
      if (this.id != undefined && !Number.isNaN(this.id)) {
        if (this.SaveInvList.length > 0) {
          this.InvList = this.SaveInvList;
          this.TotalAllType = this.SaveTotalAllType;
          this.TotalTypeInventory = this.SaveTotalTypeInventory;
          this.TotalInvLog = this.SaveTotalInvLog;
          //  this.logList = this.SavelogList;
          this.isTotalInvQty = true;
        }

        if (this.InvList.length > 0) {
          this.isInvFind = true;
          this.viewLog = false;
        }

      }
      this.NonInventory = false;
      let count = 0;
      const catList = this.CategoryArr.filter(x => x.isActive == true);
      for (let i = 0; i < catList.length; i++) {
        var str = String(catList[i].partcategoryid);
        var includeStr = "-";
        var wid;
        if (str.includes(includeStr)) {
          var spilted = catList[i].partcategoryid.split('-');
          wid = spilted[1];
          var pid = spilted[0];
        }
        else {
          wid = catList[i].partWordpressid;
          var pid = catList[i].partcategoryid;
        }
        if (wid != 0 && wid != null) {
          count = count + 1;
        }

      }
      if (count > 0) {
        if (event.target.checked == false && this.NotActive == false) {
          this.CheckCategory = true;
          this.CheckCategoryDisable = false;
        }
        else {
          this.CheckCategory = false;
          this.CheckCategoryDisable = true;
        }
      }
      else {
        this.CheckCategory = false;
        this.CheckCategoryDisable = false;
      }


    }
  }


  InventoryExist() {
    $('#InventoryExist').hide();
  }

  RemoveInventory() {
    if (this.id != undefined && !Number.isNaN(this.id)) {
      this.SaveInvList = this.InvList;
      this.SaveTotalAllType = this.TotalAllType;
      this.SaveTotalTypeInventory = this.TotalTypeInventory;
      this.SaveTotalInvLog = this.TotalInvLog;
      const invListing = this.InvList.filter(x => x.InvType == 6 || x.InvType == 7);
      this.InvList = invListing;
      if (invListing.length == 0) {
        this.viewLog = false;
        this.TotalAllType = 0;
        this.TotalTypeInventory = 0;
        this.TotalInvLog = 0;
        this.isTotalInvQty = false;
        this.isInvFind = false;
      }
      else {
        this.TotalAllType = 0;
        this.TotalTypeInventory = 0;
        this.TotalInvLog = 0;
        this.viewLog = false;
        this.isTotalInvQty = true;
        this.isInvFind = true;
        invListing.forEach(element => {

          this.TotalInvLog = this.TotalInvLog + element.InvQty;
          this.TotalAllType = this.TotalAllType + element.InvQty;
        });
      }
      $('#InventoryExist').hide();
      $('#InventoryRemove').show();
      this.NonInventory = true;

    }
    else {
      const invListing = this.InvList.filter(x => x.InvType != 6 || x.InvType != 7);
      this.InvList = invListing;
      if (invListing.length == 0) {
        this.viewLog = false;
        this.isInvFind = false;
        this.TotalAllType = 0;
        this.TotalTypeInventory = 0;
        this.TotalInvLog = 0;
        this.isTotalInvQty = false;
        this.isInvFind = false;
      }
      $('#InventoryExist').hide();
      $('#InventoryRemove').show();
      this.NonInventory = true;
    }

    let count = 0;
    const catList = this.CategoryArr.filter(x => x.isActive == true);
    for (let i = 0; i < catList.length; i++) {
      var str = String(catList[i].partcategoryid);
      var includeStr = "-";
      var wid;
      if (str.includes(includeStr)) {
        var spilted = catList[i].partcategoryid.split('-');
        wid = spilted[1];
        var pid = spilted[0];
      }
      else {
        wid = catList[i].partWordpressid;
        var pid = catList[i].partcategoryid;
      }
      if (wid != 0 && wid != null) {
        count = count + 1;
      }

    }
    if (count > 0) {
      if (this.NotActive == false) {
        if (this.NonInventory == true) {
          this.CheckCategory = false;
          this.CheckCategoryDisable = true;
        }
        else {
          this.CheckCategory = true;
          this.CheckCategoryDisable = false;
        }

      }
      else {
        this.CheckCategory = false;
        this.CheckCategoryDisable = true;
      }
    }
    else {
      this.CheckCategory = false;
      this.CheckCategoryDisable = false;
    }
  }
  InventoryRemove() {
    $('#InventoryRemove').hide();
  }

  GetVendorNameById(vId) {
    const vData = this.partVendorsList.filter(x => x.Id == vId);
    if (vData.length > 0) {
      return vData[0].name;
    }
    else {
      return '';
    }
  }

  GetPartVendors(cid, partid) {
    this.loader = true;
    this.sharedService.GetPartVendors(cid, partid).subscribe(
      data => {
        this.loader = false;
        this.data = data;
        this.partVendorsList = [];
        if (Number(cid) > 0) {
          const vendorDatas = this.data.dataList;
          vendorDatas.forEach(element => {
            var obj = {
              Id: element.id,
              name: element.name,
              type: element.defaultUI == null ? "2" : "1"

            }
            this.partVendorsList.push(obj);
          });

        }
        else {
          this.partVendorsList = null;
        }
      }
    )
  }

  InvProductOrderDropdownList1(cid: any, partId: any) {
    // Pass 1 For Product Order
    this.partService.PartInvOrderDropdownList1(1, cid, partId).subscribe(
      data => {
        this.data = data;
        this.InvProductOrderDDL = this.data.dataList;
        this.mInvProductOrderDDL = this.data.dataList;
      }
    );
  }
  InvPartOrderDropdownList1(cid: any, partId: any) {
    // Pass 2 For Part Order
    this.partService.PartInvOrderDropdownList1(2, cid, partId).subscribe(
      data => {
        this.data = data;
        this.InvPartOrderDDL = this.data.dataList;
        this.mInvPartOrderDDL = this.data.dataList;
      }
    );
  }
  PartItemExit(){
    $('#addindividualpart').hide();
  }
  OpenCustomPart(){
    if(this.CompanyValue != 0){
      this.ListShow = false;
      $('#addindividualpart').show();
    }
    else{
      $('#commomModel').show();
    }
   
  }
  commomModel(){
    $('#commomModel').hide();
  }

  SearchByCategory(){
    this.ListShow = true;
    let categoryId = this.CustomCategory == "" ? 0 : parseInt(this.CustomCategory);
    let partnamenumber1 = this.PartName_search == undefined ? "" : this.PartName_search == null ? "" : this.PartName_search;
    if (this.CompanyValue != null && this.CompanyValue != undefined && this.CompanyValue != "" && this.CompanyValue != 0) {
      this.getAllPartsDropdownList(this.CompanyValue, categoryId, partnamenumber1);
    }
    else {

    }
  }
  SearchByCategoryAll(){
    this.PartName_search = '';
    this.ListShow = true;
    let categoryId = this.CustomCategory == "" ? 0 : parseInt(this.CustomCategory);
    let partnamenumber1 = '';
    if (this.CompanyValue != null && this.CompanyValue != undefined && this.CompanyValue != "" && this.CompanyValue != 0) {
      this.getAllPartsDropdownList(this.CompanyValue, categoryId, partnamenumber1);
    }
    else {

    }
  }
  CategoryByPartDetails(event) {
    this.miscellenouseType = $("#customCategory option:selected").text().trim();
    this.CategoryId = parseInt(event);
    if (this.CategoryId > 0) {
      this.AddCategoryPart(this.CategoryId);
    }
    else {
      this.ddlPartList = [];
    }
  }

  AddCategoryPart(categoryId) {
    if (categoryId != 0 && categoryId != null && categoryId != undefined) {
      this.loader = true;
      this.CustompartCategoryId = categoryId;
      this.partService.getAllPartByCategoryId(categoryId, this.CompanyValue).subscribe(
        data => {
          this.loader = false;
          this.data = data;
          let ddlData = this.data.dataList.filter(x => x.isCustompart == false);
          switch (this.data.statusCode) {
            case 200:
              this.ddlPartList = ddlData;
              this.PartCategoryList = ddlData;
              break;
            case 400:
              this.ddlPartList = [];
              break;
            default:
              $("#wrong_msg").show();
            // this.toastr.error(this.constants.SomethingWentWrong);
          }
        });
    }
  }

  getAllPartsDropdownList(companyId, categoryId, partnamenumber1) {
    this.loader = true;
    $('#datatable2').DataTable().destroy();
    this.dtTrigger1.next('');
    this.dtOptions1 = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      ordering: false,
      //processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        this.partService.getAllCategoryParts(companyId, categoryId, partnamenumber1 ? partnamenumber1 : dataTablesParameters.search.value, dataTablesParameters.start, dataTablesParameters.length)
          .subscribe(resp => {
            this.loader = false;
            let totalRows = null;
            this.data = resp;
            if (this.data.dataList.length > 0) {
              this.ddlPartList1 = this.data.dataList;
              totalRows = [...new Set(this.ddlPartList1.map(item => item.totalRows))];
            }
            else {
              this.ddlPartList1 = null;
              totalRows = 0;
            }

            callback({
              recordsTotal: totalRows,
              recordsFiltered: totalRows,
              data: []
            });
          });

      }
    };
    this.rerender("two");
  }
  rerender(type): void {
    if (type == 'one') {
      this.dtElements.first.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next('');
      });
    }
    else if (type == 'two') {
      this.dtElements.last.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger1.next('');
      });
    }
  }

  PartdetailsModalPopup(partid, partCategoryId) {
    if (partid != null || partid != "" || undefined != "") {
      this.OnCustomPartChangeEvent(partid);
      this.changePart(partid, partCategoryId);
      $("#addpartModalpopup").show();
      $("#addindividualpart").hide();
    }
    else {

    }
  }

  changePart(partitd, categoryId) {

    let partId = partitd;
    this.selectedPartValue = partId;
    if (partId == "") {
      return;
    }
    this.loader = true;
    //let customerID=localStorage.getItem("customerID");
    this.partService.getAllPartDetailsByPartId1(partId,0).subscribe(
      data => {
        this.data = data;
        if (this.data.dataList != null && this.data.dataList != undefined) {
          this.partObjectModels.partNo = this.data.dataList[0].partNo;
          this.partObjectModels.name = this.data.dataList[0].name;
          this.partObjectModels.vendorPartName = this.data.dataList[0].vendorPartName;
          this.partObjectModels.vendorPartNumber = this.data.dataList[0].vendorPartNumber;
          this.partObjectModels.id = this.data.dataList[0].partid;
          this.partObjectModels.cost = this.data.dataList[0].cost; //parseFloat(this.data.dataList[0].cost).toFixed(5);
          this.partObjectModels.hours = this.data.dataList[0].hours;
          this.partObjectModels.vendorName = this.data.dataList[0].vendorName;
          this.partObjectModels.isCustomPart = this.data.dataList[0].isCustompart;
          this.partObjectModels.vendorPartCoat = this.data.dataList[0].vendorPartCost;
          this.partObjectModels.VendorId = this.data.dataList[0].vendorId;
          this.partObjectModels.categoryId = categoryId;

          let partList = [];
          let partDetailss = {};
          for (let i = 0; i < this.data.dataList.length; i++) {
            partDetailss = {
              answerId: 0,
              typeName: this.data.dataList[i].typeName,
              questionName: this.data.dataList[i].questionTitle,
              questionId: this.data.dataList[i].questionid,
              typeKey: this.data.dataList[i].typeKey,
              answerName: this.data.dataList[i].answer
            };
            partList.push(partDetailss);
            this.partObjectModels.partQuestions = partList;
            this.loader = false;
          }
        }
      });
  }

  ClosePartModalPopup() {
    this.cValidation = false;
    this.CustompartVendor = "";
    this.vendorsName = '';
    this.vendorId = '';
    $("#addindividualpart").show();
    $("#addpartModalpopup").hide();

  }

  getLocationLevel(id) {
    this.sharedService.getLocationLevel(id, '').subscribe(
      data => {
        this.data = data;
        if (this.data.dataList) {
          this.locationNameList = this.data.dataList.filter(x=>x.locationTypeId == 1);
        }
        else {
          this.locationNameList = '';
        }
      });
  }
  changeLocation(event) {
    var value=event.target.value;
    var value2 = event.target.options[event.target.options.selectedIndex].text;
    this.selectedLocationName = value2;
    this.getPartLocationLevel(parseInt(value),value2);
  }
  
  getPartLocationLevel(id,locationName) {
    this.sharedService.getPartLocationLevel(id,locationName, this.CompanyValue).subscribe(
      data => {
        this.data = data;
        if (this.data.dataList) {
          this.locationLevelList = this.data.dataList;
        }
        else {
          this.locationLevelList = '';
        }
      });
  }
  addRow() {
    this.dynamicArray.push({ 'Qty': '', 'DiscountPrice': '', 'cost': '', 'id': '0', 'isActive': true, 'CreateDate': '', 'CreatedBy': '','isReadOnly': false,'IsDReadonly': false  });
  }
  deleteRow(index) {
    this.dynamicArray[index].isActive = false;
    // this.dynamicArray.splice(index, 1);
  }
  ///Ambrish
  priceChanged(id, vals) 
  {
 
    if (this.cost != undefined) {
      // this.dynamicArray[id].DiscountPrice = vals; 
      this.dynamicCustomerDiscount[id].cost = String(vals);
      this.dynamicCustomerDiscount[id].id = id;
      if (this.dynamicCustomerDiscount[id].Qty != "") {
        if (Number(this.dynamicCustomerDiscount[id].cost) != (Number(this.dynamicCustomerDiscount[id].cost))) 
        {
          this.dynamicCustomerDiscount[id].DiscountPrice = '';

        }
        else {
          this.dynamicCustomerDiscount[id].cost = String(vals);
          this.dynamicCustomerDiscount[id].DiscountPrice = '';


        }

      }
      else {
        this.DiscountModel = "Please Enter Qty";
        $('#DiscountModel').show();
      }

    }
    else {
      this.DiscountModel = "Please Enter Vendor Part Cost";
      this.dynamicCustomerDiscount[id].DiscountPrice = '';
      $('#DiscountModel').show();
    }

  }


  costcalc(id, vals) {
    
    if (this.VendorPartCost != undefined) {
      this.dynamicArray[id].DiscountPrice = vals;
      if (this.dynamicArray[id].Qty != "") {
        const val = this.dynamicArray[id];
        if (this.dynamicArray[id].DiscountPrice != "") {
          if (this.hasDecimalPlace(val.DiscountPrice, 1)) {
            const value = (parseFloat(val.DiscountPrice) * this.VendorPartCost).toFixed(2);
            const stringvDiscperPart = (parseFloat(this.VendorPartCost) - parseFloat(value)).toFixed(2);               
            this.dynamicArray[id].cost = stringvDiscperPart;
            this.dynamicArray[id].isReadOnly = false;
            this.dynamicArray[id].IsDReadonly = false;
          }
          else {
            const disVlaue = parseFloat(val.DiscountPrice) / 100;
            const value = (disVlaue * this.VendorPartCost).toFixed(2);
            const stringvDiscperPart = (parseFloat(this.VendorPartCost) - parseFloat(value)).toFixed(2);         
            this.dynamicArray[id].cost = stringvDiscperPart;
            this.dynamicArray[id].isReadOnly = false;
            this.dynamicArray[id].IsDReadonly = false;
          }
        }
        else {
          this.dynamicArray[id].cost = '';
        }

      }

      else {
        this.DiscountModel = "Please Enter Qty";
        $('#DiscountModel').show();
      }

    }
    else {
      this.DiscountModel = "Please Enter Vendor Part Cost";
      $('#DiscountModel').show();
    }

  }

  CloseDiscountModel() {
    $('#DiscountModel').hide();
  }
 
  costcalc1(id, val) {
    
    if (this.VendorPartCost != undefined) {
      this.dynamicArray[id].Qty = val;
     
      if (this.dynamicArray[id].Qty != "") {
        if(this.dynamicArray[id].cost != "" ){
          const val = this.dynamicArray[id];
          if (this.hasDecimalPlace(val.DiscountPrice, 1)) { 
            this.dynamicArray[id].isReadOnly = true;
            this.dynamicArray[id].IsDReadonly = false;
         
          }

          else {
            this.dynamicArray[id].isReadOnly = true;
            this.dynamicArray[id].IsDReadonly = false;
           
          }
        }
        else{
          this.dynamicArray[id].cost = '';
        }

      }
      else {
        this.DiscountModel = "Please Enter Qty";
        this.dynamicArray = [];
        this.dynamicArray.push({ 'Qty': '', 'DiscountPrice': '', 'cost': '', 'id': '0', 'isActive': true,'isReadOnly': false, 'CreateDate': '', 'CreatedBy': '' , 'IsDReadonly': false});
        this.dynamicArray[id].DiscountPrice = '';
        $('#DiscountModel').show();
      }

    }
    else {
      this.DiscountModel = "Please Enter Vendor Part Cost";
      this.dynamicArray = [];
      this.dynamicArray.push({ 'Qty': '', 'DiscountPrice': '', 'cost': '', 'id': '0', 'isActive': true, 'isReadOnly': false,'CreateDate': '', 'CreatedBy': '', 'IsDReadonly': false });
      this.dynamicArray[id].DiscountPrice = '';
      $('#DiscountModel').show();
    }
  }


  addCustomerDiscount() {
    this.dynamicCustomerDiscount.push({ 'Qty': '', 'DiscountPrice': '', 'cost': '', 'id': '0', 'isActive': true, 'CreateDate': '', 'CreatedBy': '','isReadOnly': false,'IsDReadonly': false  });
  }
  deleteCustomerDiscount(index) {
    this.dynamicCustomerDiscount[index].isActive = false;
    // this.dynamicCustomerDiscount.splice(index, 1);
  }

  CustomerCalc(id, vals) {
    if (this.cost != undefined && this.cost != 0) {
      this.dynamicCustomerDiscount[id].DiscountPrice = vals;
      if (this.dynamicCustomerDiscount[id].Qty != "") {
        const val = this.dynamicCustomerDiscount[id];
        if (this.hasDecimalPlace(val.DiscountPrice, 1)) 
        {

          const value = (parseFloat(val.DiscountPrice) * this.cost).toFixed(2);
          const stringvalue1 = ((parseFloat(this.cost) -parseFloat(value))).toFixed(2);                   
          this.dynamicCustomerDiscount[id].cost = stringvalue1;
          this.dynamicCustomerDiscount[id].isReadOnly = true;
          this.dynamicCustomerDiscount[id].IsDReadonly = false;
        }
        else {
          const disVlaue = parseFloat(val.DiscountPrice) / 100;
          const value = (disVlaue * this.cost).toFixed(2);
          const stringvalue1 = ((parseFloat(this.cost)-parseFloat(value))).toFixed(2);
          this.dynamicCustomerDiscount[id].cost = stringvalue1;
          this.dynamicCustomerDiscount[id].isReadOnly = true;
          this.dynamicCustomerDiscount[id].IsDReadonly = false;
        }

      }
      else {
        this.DiscountModel = "Please Enter Qty";
        this.dynamicCustomerDiscount[id].DiscountPrice = "";
        this.dynamicCustomerDiscount = [];
        this.dynamicCustomerDiscount.push({ 'Qty': '', 'DiscountPrice': '', 'cost': '', 'id': '', 'isActive': true, 'CreateDate': '', 'CreatedBy': '','isReadOnly': false,'IsDReadonly': false });
        $('#DiscountModel').show();
      }

    }
    else {
      this.DiscountModel = "Please Enter List Part Price";
      this.dynamicCustomerDiscount[id].DiscountPrice = "";
      this.dynamicCustomerDiscount = [];
      this.dynamicCustomerDiscount.push({ 'Qty': '', 'DiscountPrice': '', 'cost': '', 'id': '', 'isActive': true, 'CreateDate': '', 'CreatedBy': '','isReadOnly': false,'IsDReadonly': false });
      $('#DiscountModel').show();
    }

  }

  CustomerCalc1(id, val) {
    
    this.dynamicCustomerDiscount[id].Qty = val;
    
    if (this.cost != undefined) {
      this.dynamicCustomerDiscount[id].Qty = val;
     
      if (this.dynamicCustomerDiscount[id].Qty != "") {
        if(this.dynamicCustomerDiscount[id].cost != "" ){
          const val = this.dynamicCustomerDiscount[id];
          if (this.hasDecimalPlace(val.DiscountPrice, 1)) {
            this.dynamicCustomerDiscount[id].isReadOnly = true;
            this.dynamicCustomerDiscount[id].IsDReadonly = false;
         
          }

          else {
            this.dynamicCustomerDiscount[id].isReadOnly = true;
            this.dynamicCustomerDiscount[id].IsDReadonly = false;
           
          }
        }
        else{
          this.dynamicCustomerDiscount[id].cost = '';
        }

      }
      else {
        this.DiscountModel = "Please Enter Qty";
        this.dynamicCustomerDiscount = [];
        this.dynamicCustomerDiscount.push({ 'Qty': '', 'DiscountPrice': '', 'cost': '', 'id': '0', 'isActive': true,'isReadOnly': false, 'CreateDate': '', 'CreatedBy': '' , 'IsDReadonly': false});
        this.dynamicCustomerDiscount[id].DiscountPrice = '';
        $('#DiscountModel').show();
      }

    }
    else {
      this.DiscountModel = "Please Enter List Part Price";
      this.dynamicCustomerDiscount = [];
      this.dynamicCustomerDiscount.push({ 'Qty': '', 'DiscountPrice': '', 'cost': '', 'id': '0', 'isActive': true, 'isReadOnly': false,'CreateDate': '', 'CreatedBy': '', 'IsDReadonly': false });
      this.dynamicCustomerDiscount[id].DiscountPrice = '';
      $('#DiscountModel').show();
    }
  }
  SortString(name){
    var newstr = name.substring(0,24);
    var newStr1 = newstr + "....";
    return newStr1;
 
  }

  //ankit implemenation
  ErrorModal(title:string, message:string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }

}
