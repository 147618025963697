<section class="content-section-1" id="new-layout" style="background-color:#f4f3f7;" *ngIf="showPartList">
    <div class="loading" *ngIf="loader">Loading&#8230;</div>
    <div class="cstmbreadcum paddingmng-right">
        <div class="page_title_cstms">
            <ul class="link_current">
                <li>
                    <a routerLink="/adminDashboard">Home</a>
                </li>
                <li>
                    <span>Part List</span>
                </li>
            </ul>
        </div>
    </div>
    <div class="container-fluid">
        <div class="row mb-3 inventory-row">
            <div class="col-lg-11 col-12">
                <div class="row">
                    <div class="col-md-3 col-6 first-card">
                        <mat-card class="example-card p-0">
                            <mat-card-content>
                                <div class="col-lg-12 main-card">
                                    <span>On Hand</span>
                                </div>
                                <div class="row">
                                    <div class="col-xl-6 col-lg-12 col-12 d-flex flex-column">
                                        <div class="count-title">5700</div>
                                        <div class="count-subtitle"><span>Total Quantity</span></div>
                                    </div>
                                    <div class="col-xl-6 col-lg-12 col-12 d-flex flex-column">
                                        <div class="count-title">$557,000</div>
                                        <div class="count-subtitle"><span>Total Cost</span></div>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>

                    <div class="col-md-3 col-6 mb-3 second-card">
                        <mat-card class="example-card p-0">
                            <mat-card-content>
                                <div class="col-lg-12 main-card">
                                    <span>Available</span>
                                </div>
                                <div class="row">
                                    <div class="col-xl-6 col-lg-12 col-12 d-flex flex-column">
                                        <div class="count-title">5700</div>
                                        <div class="count-subtitle"><span>Total Quantity</span></div>
                                    </div>
                                    <div class="col-xl-6 col-lg-12 col-12 d-flex flex-column">
                                        <div class="count-title">$557,000</div>
                                        <div class="count-subtitle"><span>Total Cost</span></div>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>

                    <div class="col-md-3 col-6 mb-3 third-card">
                        <mat-card class="example-card p-0">
                            <mat-card-content>
                                <div class="col-lg-12 main-card">
                                    <span>Committed</span>
                                </div>
                                <div class="row">
                                    <div class="col-xl-6 col-lg-12 col-12 d-flex flex-column">
                                        <div class="count-title">5700</div>
                                        <div class="count-subtitle"><span>Total Quantity</span></div>
                                    </div>
                                    <div class="col-xl-6 col-lg-12 col-12 d-flex flex-column">
                                        <div class="count-title">$557,000</div>
                                        <div class="count-subtitle"><span>Total Cost</span></div>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>

                    <div class="col-md-3 col-6 mb-3 fourth-card">
                        <mat-card class="example-card p-0">
                            <mat-card-content>
                                <div class="col-lg-12 main-card">
                                    <span>Back Ordered</span>
                                </div>
                                <div class="row">
                                    <div class="col-xl-6 col-lg-12 col-12 d-flex flex-column">
                                        <div class="count-title">5700</div>
                                        <div class="count-subtitle"><span>Total Quantity</span></div>
                                    </div>
                                    <div class="col-xl-6 col-lg-12 col-12 d-flex flex-column">
                                        <div class="count-title">$557,000</div>
                                        <div class="count-subtitle"><span>Total Cost</span></div>
                                    </div>
                                </div>
                            </mat-card-content>
                        </mat-card>
                    </div>
                </div>
            </div>

        </div>
    </div>
    <div class="row mb-3">
        <div class="col-lg-11 col-12">
            <div class="row align-items-center">
                <div class="col-lg-3 col-md-6">
                </div>
                <div class="col-lg-6 col-md-8 desktop-order-1">
                    <div class="search_filter-alltype">
                        <mat-select class="customInput">
                            <mat-option value='All' selected>All</mat-option>
                        </mat-select>
                        <input type="text" class="searchalltype" placeholder="Search by Part Number or Description">
                        <button class="btnsearch_alltype" type="button">
                            <mat-icon>search</mat-icon>
                        </button>
                    </div>
                </div>
                <div class="col-lg-3">
                    <button mat-raised class="quick-bg d-flex align-items-center quick-ord-btn fs-15 text-light order_1"
                        (click)="newPart()">
                        <mat-icon class="mr-1 text-light">add</mat-icon>
                        <span class="text-light quick-order-text">Add New Part</span>
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div class="col-md-12">
        <table mat-table [dataSource]="dataSource">
            <ng-container matColumnDef="item">
                <th mat-header-cell *matHeaderCellDef> Item </th>
                <td mat-cell *matCellDef="let row" data-label="Menu">{{row.item}}</td>
            </ng-container>
            <ng-container matColumnDef="partNumber">
                <th mat-header-cell *matHeaderCellDef> Part Number </th>
                <td mat-cell *matCellDef="let row"> {{row.partNumber}}
                </td>
            </ng-container>

            <ng-container matColumnDef="partDescription">
                <th mat-header-cell *matHeaderCellDef> Part Description </th>
                <td mat-cell *matCellDef="let row"> {{row.partDescription}}</td>
            </ng-container>

            <ng-container matColumnDef="uom">
                <th mat-header-cell *matHeaderCellDef> UOM </th>
                <td mat-cell *matCellDef="let row"> {{row.uom}} </td>
            </ng-container>

            <ng-container matColumnDef="invType">
                <th mat-header-cell *matHeaderCellDef> Inventory Type </th>
                <td mat-cell *matCellDef="let row"> {{row.invType}} </td>
            </ng-container>

            <ng-container matColumnDef="onHand">
                <th mat-header-cell *matHeaderCellDef>On Hand</th>
                <td mat-cell *matCellDef="let row"> {{row.onHand}}</td>
            </ng-container>

            <ng-container matColumnDef="available">
                <th mat-header-cell *matHeaderCellDef> Available </th>
                <td mat-cell *matCellDef="let row"> {{row.available}} </td>
            </ng-container>
            <ng-container matColumnDef="committed">
                <th mat-header-cell *matHeaderCellDef> Committed </th>
                <td mat-cell *matCellDef="let row"> {{row.committed}} </td>
            </ng-container>
            <ng-container matColumnDef="backOrder">
                <th mat-header-cell *matHeaderCellDef>Back Ordered </th>
                <td mat-cell *matCellDef="let row"> {{row.backOrder}} </td>
            </ng-container>
            <ng-container matColumnDef="unitCost">
                <th mat-header-cell *matHeaderCellDef> Unit Cost </th>
                <td mat-cell *matCellDef="let row"> {{row.unitCost}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>

            <tr class="mat-row" *matNoDataRow>
                <td class="mat-cell" colspan="10" class="error text-center" style="height: 47px">
                    No data found !
                </td>
            </tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;let i= index;"></tr>
        </table>
    </div>
</section>


<!--Section to create new Part-->
<section class="content-section-1" id="new-layout" style="background-color:#f4f3f7;" *ngIf="!showPartList">
    <div class="loading" *ngIf="loader">Loading&#8230;</div>
    <div class="row" style="margin-top: 67px;">
        <div class="col-12">
            <!-- <form [formGroup]="partForm"> -->
            <div class="cstmbreadcum paddingmng-right">
                <div class="page_title_cstms">
                    <ul class="link_current">
                        <li>
                            <a>Home</a>
                        </li>
                        <li>
                            <span>Part Details</span>
                        </li>
                    </ul>
                </div>
                <div class="top-button mt-12 col-md-3">
                    <button class="cancel-button pBtn" (click)="Cancel()">
                        <span>Cancel</span>
                    </button>
                    <button class="save-button pBtn submit-from" type="submit">
                        <span>Save</span>
                    </button>
                </div>
            </div>
            <div class="container-fluid mt-4">
                <mat-card style="width: 88%; margin-top: 5px;">
                    <mat-card-header>
                        <h2>Part Information</h2>
                    </mat-card-header>
                    <mat-card-content>
                        <div class="col-md-12 row mt-2">
                            <!-- Part Information-->
                            <div class="col-md-4">
                                <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                                    <mat-label>Part Number</mat-label>
                                    <input matInput type="text" placeholder="Part Number">
                                </mat-form-field>
                            </div>
                            <div class="col-md-2">
                                <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                                    <mat-label>Inventory Type</mat-label>
                                    <mat-select class="select-arrow-change-icon">
                                        <mat-option></mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-md-2">
                                <!-- <ng-multiselect-dropdown placeholder="Select" [settings]="dropdownSettings"
                                    [(ngModel)]="selectedItems" [ngClass]="" (onSelect)="onItemSelect($event)"
                                    (onDeSelect)="onItemDeSelect($event)" (onSelectAll)="onSelectAll($event)"
                                    (onDeSelectAll)="onDeSelectAll($event)">
                                </ng-multiselect-dropdown> -->
                                <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                                    <mat-label>Tracking Type</mat-label>
                                    <mat-select class="select-arrow-change-icon">
                                        <mat-option></mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4">
                                <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                                    <mat-label>Status</mat-label>
                                    <mat-select class="select-arrow-change-icon">
                                        <mat-option></mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="col-md-12 row mt-2">
                            <div class="col-md-8">
                                <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                                    <mat-label>Part Description</mat-label>
                                    <input matInput type="text" placeholder="Part Description">
                                </mat-form-field>
                            </div>
                            <div class="col-md-4">
                                <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                                    <mat-label>Purpose</mat-label>
                                    <mat-select class="select-arrow-change-icon">
                                        <mat-option></mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>

                        <div class="col-md-12 row mt-2">
                            <div class="col-md-4">
                                <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                                    <mat-label>Part Category</mat-label>
                                    <mat-select class="select-arrow-change-icon">
                                        <mat-option></mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-md-3">
                                <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                                    <mat-label>Assembly ID</mat-label>
                                    <input matInput type="text" placeholder="Id">
                                </mat-form-field>
                            </div>
                            <div class="col-md-1">
                                <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                                    <mat-label>UOM</mat-label>
                                    <mat-select class="select-arrow-change-icon">
                                        <mat-option></mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-md-4">
                                <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                                    <mat-label>Bussiness Channels</mat-label>
                                    <mat-select class="select-arrow-change-icon">
                                        <mat-option></mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>

            <div class="container-fluid mt-4">
                <mat-card style="width: 88%; margin-top: 5px;">
                    <mat-card-header>
                        <h2>Vendor Information</h2>
                    </mat-card-header>
                    <mat-card-content>
                        <div class="row">
                            <div class="col-7">
                                <div class="row mt-2">
                                    <!-- Vendor Information-->
                                    <div class="col-8">
                                        <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                                            <mat-label>Vendor Name</mat-label>
                                            <mat-select class="select-arrow-change-icon">
                                                <mat-option></mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-4">
                                        <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                                            <mat-label>Vendor List Price</mat-label>
                                            <mat-select class="select-arrow-change-icon">
                                                <mat-option></mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="row mt-2">
                                    <div class="col-8">
                                        <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                                            <mat-label>Vendor Part Number/SKU</mat-label>
                                            <input matInput type="text" placeholder="Vendor Part Number">
                                        </mat-form-field>
                                    </div>
                                    <div class="col-4">
                                        <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                                            <mat-label>Unit Cost</mat-label>
                                            <input matInput type="text" placeholder="Unit Cost">
                                        </mat-form-field>
                                    </div>
                                </div>

                                <div class="row mt-2">
                                    <div class="col-12">
                                        <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                                            <mat-label>Vendor Part Description</mat-label>
                                            <mat-select class="select-arrow-change-icon">
                                                <mat-option></mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="col-5">
                                <!-- Volume Discount Pricing -->
                                <!-- <form> -->
                                <div class="card">
                                    <div class="row row-padding top-heading">
                                        <div class="col-2">
                                            <span class="table-heading">Min Qty</span>
                                        </div>
                                        <div class="col-2">
                                            <span class="table-heading">Max Qty</span>
                                        </div>
                                        <div class="col-2">
                                            <span class="table-heading">Discount Type</span>
                                        </div>
                                        <div class="col-2">
                                            <span class="table-heading">Discount Value</span>
                                        </div>
                                        <div class="col-2">
                                            <span class="table-heading">Unit Cost</span>
                                        </div>
                                        <div class="col-1">

                                        </div>
                                    </div>
                                    <div>
                                        <div class="row additional-border-row">
                                            <div class="col-2 p-0">
                                                <span>
                                                    <mat-form-field style="margin-left: 15px;">
                                                        <input matInput type="text">
                                                    </mat-form-field>
                                                </span>
                                            </div>
                                            <div class="col-2 p-0">
                                                <span>
                                                    <mat-form-field>
                                                        <input matInput type="text">
                                                    </mat-form-field>
                                                </span>
                                            </div>
                                            <div class="col-2 p-0">
                                                <mat-form-field class="icon-change-input">
                                                    <mat-select placeholder="Discount %">
                                                        <mat-option>
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <div class="col-2 p-0">
                                                <span>
                                                    <mat-form-field>
                                                        <input matInput type="text">
                                                    </mat-form-field>
                                                </span>
                                            </div>
                                            <div class="col-2 p-0">
                                                <span>
                                                    <mat-form-field style="width: 145px;">
                                                        <input matInput type="text">
                                                    </mat-form-field>
                                                </span>
                                            </div>
                                            <div class="col-1 m-auto p-0">
                                                <mat-icon style="margin-left: 30px">
                                                    <mat-icon>cancel</mat-icon>
                                                </mat-icon>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-start mt-5">
                                        <button type="button" class="add-row-button">+ Add Row</button>
                                    </div>
                                </div>
                                <!-- </form> -->
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>

            <!--Pricing Information-->
            <div class="container-fluid mt-4">
                <mat-card style="width: 88%; margin-top: 5px;">
                    <mat-card-header>
                        <h2>Pricing Information</h2>
                        <mat-form-field style="margin-left: 270px;" appearance="outline">
                            <mat-label>List Price</mat-label>
                            <input matInput type="text">
                        </mat-form-field>
                    </mat-card-header>
                    <mat-card-content>
                        <div class="row mt-3">
                            <div class="col-6">
                                <!-- Volume  Pricing  Discounts-->
                                <!-- <form> -->
                                <div class="card">
                                    <div class="row row-padding top-heading">
                                        <div class="col-2">
                                            <span class="table-heading">Min Qty</span>
                                        </div>
                                        <div class="col-2">
                                            <span class="table-heading">Max Qty</span>
                                        </div>
                                        <div class="col-2">
                                            <span class="table-heading">Discount Type</span>
                                        </div>
                                        <div class="col-2">
                                            <span class="table-heading">Discount Value</span>
                                        </div>
                                        <div class="col-2">
                                            <span class="table-heading">Price</span>
                                        </div>
                                        <div class="col-1">

                                        </div>
                                    </div>
                                    <div>
                                        <div class="row additional-border-row">
                                            <div class="col-2 p-0">
                                                <span>
                                                    <mat-form-field style="margin-left: 15px;">
                                                        <input matInput type="text">
                                                    </mat-form-field>
                                                </span>
                                            </div>
                                            <div class="col-2 p-0">
                                                <span>
                                                    <mat-form-field>
                                                        <input matInput type="text">
                                                    </mat-form-field>
                                                </span>
                                            </div>
                                            <div class="col-2 p-0">
                                                <mat-form-field class="icon-change-input">
                                                    <mat-select placeholder="Discount %">
                                                        <mat-option>
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <div class="col-2 p-0">
                                                <span>
                                                    <mat-form-field>
                                                        <input matInput type="text">
                                                    </mat-form-field>
                                                </span>
                                            </div>
                                            <div class="col-2 p-0">
                                                <span>
                                                    <mat-form-field style="width: 155px;">
                                                        <input matInput type="text">
                                                    </mat-form-field>
                                                </span>
                                            </div>
                                            <div class="col-1 m-auto p-0">
                                                <mat-icon style="margin-left: 30px">
                                                    <mat-icon>cancel</mat-icon>
                                                </mat-icon>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-start mt-5">
                                        <button type="button" class="add-row-button">+ Add Row</button>
                                    </div>
                                </div>
                                <!-- </form> -->
                            </div>
                            <div class="col-6">
                                <!-- Role Based Pricing -->
                                <!-- <form> -->
                                <div class="card">
                                    <div class="row row-padding top-heading">
                                        <div class="col-2">
                                            <span class="table-heading">Discount Type</span>
                                        </div>
                                        <div class="col-2">
                                            <span class="table-heading">Discount Value</span>
                                        </div>
                                        <div class="col-2">
                                            <span class="table-heading">Customer Type</span>
                                        </div>
                                        <div class="col-2">
                                            <span class="table-heading">Customer Value</span>
                                        </div>
                                        <div class="col-2">
                                            <span class="table-heading">Price</span>
                                        </div>
                                        <div class="col-1">

                                        </div>
                                    </div>
                                    <div>
                                        <div class="row additional-border-row">
                                            <div class="col-2 p-0">
                                                <span>
                                                    <mat-form-field class="icon-change-input"
                                                        style="margin-left: 15px;">
                                                        <mat-select placeholder="Percent Discount">
                                                            <mat-option>
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </span>
                                            </div>
                                            <div class="col-2 p-0">
                                                <span>
                                                    <mat-form-field>
                                                        <input matInput type="text">
                                                    </mat-form-field>
                                                </span>
                                            </div>
                                            <div class="col-2 p-0">
                                                <mat-form-field class="icon-change-input">
                                                    <mat-select placeholder="User Role">
                                                        <mat-option>
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <div class="col-2 p-0">
                                                <span>
                                                    <mat-form-field class="icon-change-input">
                                                        <mat-select placeholder="Guest">
                                                            <mat-option>
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </span>
                                            </div>
                                            <div class="col-2 p-0">
                                                <span>
                                                    <mat-form-field style="width: 155px;">
                                                        <input matInput type="text">
                                                    </mat-form-field>
                                                </span>
                                            </div>
                                            <div class="col-1 m-auto p-0">
                                                <mat-icon style="margin-left: 30px">
                                                    <mat-icon>cancel</mat-icon>
                                                </mat-icon>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-start mt-5">
                                        <button type="button" class="add-row-button">+ Add Row</button>
                                    </div>
                                </div>
                                <!-- </form> -->
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>

            <!--Part Specifications-->
            <div class="container-fluid mt-4">
                <mat-card style="width: 88%; margin-top: 5px;">
                    <mat-card-header>
                        <h2>Part Specifications</h2>
                    </mat-card-header>
                    <mat-card-content>
                        <div class="row mt-3">
                            <div class="col-6">
                                <!-- Dimensions and Weight-->
                                <!-- <form> -->
                                <div class="card">
                                    <div class="row row-padding top-heading">
                                        <div class="col-2">
                                            <span class="table-heading">Name</span>
                                        </div>
                                        <div class="col-5">
                                            <span class="table-heading">Value</span>
                                        </div>
                                        <div class="col-2">
                                            <span class="table-heading">UOM</span>
                                        </div>
                                    </div>
                                    <div>
                                        <div class="row additional-border-row">
                                            <div class="col-2 p-0">
                                                <span>
                                                    <mat-form-field style="margin-left: 15px;">
                                                        <input matInput type="text">
                                                    </mat-form-field>
                                                </span>
                                            </div>
                                            <div class="col-5 p-0">
                                                <span>
                                                    <mat-form-field>
                                                        <input matInput type="text">
                                                    </mat-form-field>
                                                </span>
                                            </div>
                                            <div class="col-2 p-0">
                                                <mat-form-field class="icon-change-input">
                                                    <mat-select placeholder="In">
                                                        <mat-option>
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- </form> -->
                            </div>
                            <div class="col-6">
                                <!-- Additional Specifications -->
                                <!-- <form> -->
                                <div class="card">
                                    <div class="row row-padding top-heading">
                                        <div class="col-4">
                                            <span class="table-heading">Name</span>
                                        </div>
                                        <div class="col-4">
                                            <span class="table-heading">Value</span>
                                        </div>
                                        <div class="col-1">

                                        </div>
                                    </div>
                                    <div>
                                        <div class="row additional-border-row">
                                            <div class="col-4 p-0">
                                                <span>
                                                    <mat-form-field style="margin-left: 15px;">
                                                        <input matInput type="text" placeholder="Enter Name Here">
                                                    </mat-form-field>
                                                </span>
                                            </div>
                                            <div class="col-6 p-0">
                                                <mat-form-field class="icon-change-input">
                                                    <input matInput type="text" placeholder="Enter Value Here">
                                                </mat-form-field>
                                            </div>
                                            <div class="col-1 m-auto p-0">
                                                <mat-icon style="margin-left: 30px">
                                                    <mat-icon>cancel</mat-icon>
                                                </mat-icon>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text-start mt-5">
                                        <button type="button" class="add-row-button">+ Add Row</button>
                                    </div>
                                </div>
                                <!-- </form> -->
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>


            <!--Sales Question-->
            <div class="container-fluid mt-4">
                <mat-card style="width: 88%; margin-top: 5px;">
                    <mat-card-header>
                        <h2>Sales Questions</h2>
                    </mat-card-header>
                    <mat-card-content>
                        <div class="row mt-3">
                            <div class="col-12">
                                <!-- <form> -->
                                <div class="card">
                                    <div class="row row-padding top-heading">
                                        <div class="col-6">
                                            <span class="table-heading">Question</span>
                                        </div>
                                        <div class="col-2">
                                            <span class="table-heading">Answer Type</span>
                                        </div>
                                        <div class="col-2">
                                            <span class="table-heading">Required</span>
                                        </div>
                                        <div class="col-1">
                                        </div>
                                    </div>
                                    <div>
                                        <div class="row additional-border-row">
                                            <div class="col-6 p-0">
                                                <span>
                                                    <mat-form-field style="margin-left: 15px;width: 520px !important"
                                                        appearance="outline">
                                                        <input matInput type="text">
                                                    </mat-form-field>
                                                </span>
                                            </div>
                                            <div class="col-2 p-0">
                                                <span>
                                                    <mat-form-field appearance="outline">
                                                        <mat-select placeholder="In">
                                                            <mat-option>
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </span>
                                            </div>
                                            <div class="col-2 p-0">
                                                <mat-form-field class="icon-change-input" appearance="outline">
                                                    <mat-select placeholder="In">
                                                        <mat-option>
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </div>
                                            <div class="col-1 m-auto p-0">
                                                <mat-icon style="margin-left: 30px">
                                                    <mat-icon>cancel</mat-icon>
                                                </mat-icon>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- </form> -->
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>

            <!--Ecommerce Information-->
            <div class="container-fluid mt-4">
                <mat-card style="width: 88%; margin-top: 5px;">
                    <mat-card-header>
                        <h2>eCommerce Information</h2>
                    </mat-card-header>
                    <mat-card-content>
                        <!-- <form> -->
                        <div class="card">
                            <div class="col-12 row">
                                <div class="col-5">
                                    <mat-form-field appearance="outline" style="width: 520px !important">
                                        <mat-label>Product Name</mat-label>
                                        <input matInput type="text">
                                    </mat-form-field>
                                </div>
                                <div class="col-3">
                                    <mat-form-field appearance="outline" style="width: 200px !important">
                                        <mat-label>eCommerce Status</mat-label>
                                        <input matInput type="text">
                                    </mat-form-field>
                                </div>
                                <div class="col-2">
                                    <mat-form-field appearance="outline">
                                        <mat-label>Sync With WordPress</mat-label>
                                        <input matInput type="text">
                                    </mat-form-field>
                                </div>
                                <div class="col-2">
                                    <mat-form-field appearance="outline">
                                        <mat-label>WordPress ID</mat-label>
                                        <input matInput type="text">
                                    </mat-form-field>
                                </div>
                            </div>
                            <div class="col-12">
                                <div class="row">
                                    <div class="col-6 mt-3">
                                        <label class="customLabel">Short
                                            Description</label><sup>§</sup>
                                        <editor apiKey="{{GetAPIKey()}}" id="shortDescription"
                                            [(ngModel)]="ShortDescription" name="ShortDescription"
                                            (onChange)="getStrDesc($event)" [init]="{   
                                              height: 250,
                                              width: 650,
                                              menubar: false,
                                              plugins:'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                                              toolbar:
                                                'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat'
                                            }"></editor>
                                    </div>
                                    <div class="col-6 mt-3">
                                        <label class="customLabel">Detail
                                            Description</label><sup>§</sup>
                                        <editor apiKey="{{GetAPIKey()}}" [(ngModel)]="description" name="description"
                                            (onChange)="getDtlDesc($event)" id="detailDescription" [init]="{
                                          height: 250,
                                          width: 650,
                                          menubar: false,
                                          plugins:'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                                              toolbar:
                                                'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat'
                                        }"></editor>
                                    </div>
                                </div>
                            </div>
                            <!-- </form> -->
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>

            <!--Images & Related Products-->
            <div class="container-fluid mt-4">
                <mat-card style="width: 88%; margin-top: 5px;">
                    <mat-card-header>
                        <h2>Images & Related Products</h2>
                    </mat-card-header>
                    <mat-card-content>
                        <div class="row mt-3">
                            <div class="col-6">
                                <!-- <form> -->
                                <div class="card">
                                    <div class="row row-padding top-heading">
                                        <div class="col-6">
                                            <span class="table-heading">Image</span>
                                        </div>
                                        <div class="col-2">
                                            <span class="table-heading">File Type</span>
                                        </div>
                                        <div class="col-2">
                                            <span class="table-heading">File Name</span>
                                        </div>
                                        <div class="col-1">
                                        </div>
                                    </div>
                                    <div>
                                        <div class="row additional-border-row">
                                            <div class="col-6 p-0">
                                                <span>
                                                    <mat-form-field style="margin-left: 15px;width: 520px !important">
                                                        <input matInput type="text">
                                                    </mat-form-field>
                                                </span>
                                            </div>
                                            <div class="col-2 p-0">
                                                <span>
                                                    <mat-form-field>
                                                        <mat-select>
                                                            <mat-option>
                                                            </mat-option>
                                                        </mat-select>
                                                    </mat-form-field>
                                                </span>
                                            </div>
                                            <div class="col-2 p-0">
                                                <mat-form-field class="icon-change-input">
                                                    <input matInput type="text">
                                                </mat-form-field>
                                            </div>
                                            <div class="col-1 m-auto p-0">
                                                <mat-icon style="margin-left: 30px">
                                                    <mat-icon>cancel</mat-icon>
                                                </mat-icon>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row align-items-center" appFileUpload
                                    (filesChangeEmiter)="onFileChange($event.target.files)">
                                    <div class="col-xl-9 col-lg-5 col-md-5 col-8 drop-file-col">
                                        <div class="dropzone">
                                            <div class="text-wrapper">
                                                <div class="centered">
                                                    <input type="file" accept=".pdf"
                                                        class="choose-file-hidden upload_icon_custom"
                                                        (change)="onFileChange($event.target.files)"
                                                        (click)="$event.target.value=null">
                                                    <label for="file" class="drop-file-text">Add
                                                        attachment here !</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-3 col-lg-5 col-md-5 col-6 text-start select-file-column">
                                        <button class="save-button" style="width: 119px;">
                                            <input type="file" accept=".pdf"
                                                class="choose-file-hidden upload_icon_custom"
                                                (change)="onFileChange($event.target.files)"
                                                (click)="$event.target.value=null">
                                            <span>Select File</span>
                                        </button>
                                    </div>
                                </div>
                                <!-- </form> -->
                            </div>

                            <div class="col-6">
                                <!-- <form> -->
                                <div class="card">
                                    <div class="row row-padding top-heading">
                                        <div class="col-3">
                                            <span class="table-heading">Part Number</span>
                                        </div>
                                        <div class="col-3">
                                            <span class="table-heading">Part Name</span>
                                        </div>
                                        <div class="col-3">
                                            <span class="table-heading">WP ID</span>
                                        </div>
                                        <div class="col-1">
                                        </div>
                                    </div>
                                    <div>
                                        <div class="row additional-border-row">
                                            <div class="col-4 p-0">
                                                <span>
                                                    <mat-form-field style="margin-left: 15px;">
                                                        <input matInput type="text">
                                                    </mat-form-field>
                                                </span>
                                            </div>
                                            <div class="col-3 p-0">
                                                <span>
                                                    <mat-form-field>
                                                        <input matInput type="text">
                                                    </mat-form-field>
                                                </span>
                                            </div>
                                            <div class="col-3 p-0">
                                                <mat-form-field class="icon-change-input">
                                                    <input matInput type="text">
                                                </mat-form-field>
                                            </div>
                                            <div class="col-1 m-auto p-0">
                                                <mat-icon style="margin-left: 30px">
                                                    <mat-icon>cancel</mat-icon>
                                                </mat-icon>
                                            </div>
                                            <div class="text-start mt-5">
                                                <button type="button" class="add-row-button" style="margin-left: 17px !important;">+ Add Row</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <!-- </form> -->
                            </div>
                        </div>
                    </mat-card-content>
                </mat-card>
            </div>
        </div>
    </div>
</section>