import { Compiler, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { ConstantsService } from 'src/app/constants.service';
import { SharedService } from 'src/app/services/shared.service';
import { AuthService } from '../auth.service';
import { Observable, Subject, timer } from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  @ViewChild('closeBtn', { static: false }) closeBtn: ElementRef;
  @Input() name: string;
  @Output() isLogin = new EventEmitter<string>();
  message: string;
  validation: boolean;
  errorMessage: string;
  public userinput: any = {};
  form: FormGroup;
  model: any = {};
  submitted = false;
  EmailNotFound = false;
  PasswordNotFound = false;
  UserNotFound = false;
  userName: any;
  userId: any;
  APiKey: string;
  data: any;
  userList: any;
  rolePermissionsList: any;
  roleId: any;
  loader: boolean = false;
  emailDetails: string;
  UserDoseNotExist = false;
  private timeout: any;
  private warningTimer: any;
  timeoutDuration: any; 
  warningDuration: any;  
  private activitySubject = new Subject<void>();
  logoutDuration: any;
  logoutwarningDuration: any;
  currentRoute;
  constructor(public authService: AuthService,
    public sharedService: SharedService,
    private constants: ConstantsService,
    private _compiler: Compiler,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private elementRef: ElementRef,
    public router: Router) {
      this.APiKey = this.constants.APiKey;
      this.setMessage();
      localStorage.setItem('isLogin', 'false');
      this.form = new FormGroup({
        emailId : new FormControl(''),
      });
  }
  setMessage() {
    this.message = 'Logged ' + (this.authService.isLoggedIn ? 'in' : 'out');
  }
  TokenValues(token) {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace('-', '+').replace('_', '/');
    return JSON.parse(window.atob(base64));
  }

  logout() {
    this.authService.logout();
    this.setMessage();
    this.router.navigate(['']);
  }

  public onSubmit(): void {
    this.currentRoute = this.router.url;
    this._compiler.clearCache();
    this.submitted = true;
    localStorage.clear();
    const obj = {};
    obj['UserName'] = this.model.userName;
    obj['Password'] = this.model.password;
    this.loader = true;
    this.authService.authentication(this.APiKey, obj)
      .subscribe(
        data => {
          switch (data.statusCode) {
            case 200: {
              
              const tokenDetail = this.TokenValues(data.data.token);
              localStorage.setItem('UniqueName', tokenDetail.unique_name);
              localStorage.setItem('AuthHeader', data.data.token);
              this.userName = localStorage.setItem('AuthHeader', data.data.token);
              localStorage.setItem('username', data.data.username);
              localStorage.setItem('useremail', data.data.userEmail);
              localStorage.setItem('companyId', data.data.companyId == null ? 0 : data.data.companyId);
              this.userId = localStorage.setItem('userId', data.data.id);
              this.getAllUsers();
              localStorage.setItem('isLogin', 'true');
              this.startTimer();
              break;
            }
            case 400: {

              if (data.errorMessage === 'Username or password is incorrect') {
                this.EmailNotFound = true;
                this.PasswordNotFound = false;
                this.UserNotFound = false;
              }
              if (data.errorMessage === 'InvalidEmail.') {
                this.EmailNotFound = true;
                this.PasswordNotFound = false;
                this.UserNotFound = false;
              }
              if (data.errorMessage === 'InvalidPassword.') {
                this.PasswordNotFound = true;
                this.EmailNotFound = false;
                this.UserNotFound = false;
              }
              if (data.message === 'InvalidUser.') {
                this.PasswordNotFound = false;
                this.EmailNotFound = false;
                this.UserNotFound = true;
              }
              return;
            }
            default: {
              return;
            }
          }
          this.loader = false;
        }
        , (err) => {
          this.PasswordNotFound = true;
          this.EmailNotFound = true;
          this.UserNotFound = true;
          if (err.status === 403) {
            this.router.navigate(['login']);
          }
          if (err.status === 401) {
            localStorage.clear();
            this.router.navigate(['login']);
          }
          this.loader = false;
        }
      );
  }
 

  getAllUsers() {
    this.userId = localStorage.getItem(this.constants.userId);
    this.sharedService.getAllUsers().subscribe(
      data => {
        this.data = data;
        this.userList = this.data.dataList.filter(x => x.userId == this.userId);;
        if (this.userList.length == 1) {
          this.roleId = this.userList[0].roleId;
          this.getAllRolePermissions();
        } else {
          this.roleId = '034eef3b-cf41-4a73-9a95-45543fe997ef';
          this.getAllRolePermissions();
        }
      });
  }
 

  getAllRolePermissions() {
    this.sharedService.GetAllRolePermissionByUserId(this.roleId).subscribe(
      data => {
        this.data = data;
        if (this.data.dataList != undefined && this.data.dataList != null) {
          this.rolePermissionsList = this.data.dataList;
          localStorage.setItem('rolePermissionsList', JSON.stringify(this.rolePermissionsList));
          const redirectUrl = '/admin';
          const navigationExtras: NavigationExtras = {
            queryParamsHandling: 'preserve',
            preserveFragment: true
          };
          this.router.navigate([redirectUrl], navigationExtras);
        }
      });
  }

  opemPasswordPopUp(content) {
    this.validation = false;
    this.form.patchValue({emailId :''});
    this.modalService.open(content, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
    this.emailDetails = 'You can reset your password here.';
  }

  sendEmail() {
    this.validation = true;
    this.loader=true;
    if (this.form.value.emailId == '' ||
      this.form.value.emailId == undefined ||
      this.form.controls.emailId.status === this.constants.Invalid
    ) {
      this.loader=false;
      return  
    }
    this.sharedService.SendEmails(this.form.value.emailId).subscribe(
      data => {
        switch (data.statusCode) {
          case 200:
            this.toastr.success(this.constants.sendEmailMessage);
            this.validation = false;
            this.loader=false;
            break;
          case 400:
            if (data.errorMessage === 'User does not Exist') {
              this.UserDoseNotExist = true;
              this.loader=false;
            } else {
              this.toastr.error(this.constants.UserDoseNotExist);
              this.loader=false;
            }
            break;
          default:
            $("#wrong_msg").show();
            this.loader=false;
            // this.toastr.error(this.constants.SomethingWentWrong);
        }
    $('#close-btn').trigger("click")
      });
      this.loader=false;
     
  }

  ngOnInit(): void {
    
  }
 closeModal(){
 
 }
 
 RemovedEmailMessage(event) {

  this.UserDoseNotExist = false;
}

  msgitem() {
    $("#wrong_msg").hide();
  }


  startTimer() {
    const token = localStorage.getItem('AuthHeader');
    if (token && !this.isTokenExpired(token)) {
      const tokenPayload = this.TokenValues(token);
      const expirationDate = new Date(0);
      expirationDate.setUTCSeconds(tokenPayload.exp);
      const remainingTime = expirationDate.getTime() - new Date().getTime();
      if (remainingTime > 0) {
        this.timeout = setTimeout(() => {
          this.stopTimer();
          this.logout();
        }, remainingTime);
      } else {
        this.stopTimer();
        this.logout();
      }
    } else {
      this.stopTimer();
      this.logout();
    }
  }
 
  
  stopTimer() {
    clearTimeout(this.timeout);
    clearTimeout(this.warningTimer);
  }
  
  resetTimer() {
    clearTimeout(this.timeout);
    clearTimeout(this.warningTimer);
    this.startTimer();
  }

  private isTokenExpired(token: string): boolean {
    const tokenPayload = this.TokenValues(token);
    if (tokenPayload && tokenPayload.exp) {
      const expirationDate = new Date(0);
      expirationDate.setUTCSeconds(tokenPayload.exp);
      return expirationDate.valueOf() < new Date().valueOf();
    }
    return true; 
  }
}

