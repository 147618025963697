<section class="content-section pt-2">
    <div class="loading" *ngIf="loader">Loading&#8230;</div>

    <div col-sm-12>
        <div class="row">
            <div class="col-6 mb-2 d-flex align-items-baseline text-xs-left">
                <div class="page-title">
                    <div class="bread-crumb">
                        <a [routerLink]="['/adminDashboard/']">Home</a>
                        <span class="mx-1">-</span>
                        <span class="active">Questions</span>
                        <div *ngIf="permissionMessage!=''" class='btn-danger text-center'>{{permissionMessage}} </div>
                    </div>
                </div>
            </div>
            <div class="col-6 mb-2 text-right align-items-baseline">
                <span class="downloadicon" (click)="exportToWord()"> <i class="fa fa-file-word-o"></i>   </span>
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="white-box1">
                <div class="card-desc1">
                    <form [formGroup]="formSearch">
                        <div class="row detailSection">
                            <div class="col-md-12 container">
                                <div class="row mt-3">
                                    <div class="col-lg-2 col-md-6">
                                        <div class="d-flex align-items-center">
                                            <label class="customLabel mr-1" for="Type">Type</label>
                                            <select class="customInput" (change)="ChangeTCreateUpdate($event)"
                                                [(ngModel)]="TCreateUpdate" id="TCreateUpdate"
                                                formControlName="TCreateUpdate">
                                                <option value="1">Create</option>
                                                <option value="0">Update</option>
                                            </select>
                                        </div>
                                    </div>

                                    <!-- <div class="col-lg-3 col-md-6 mt-md-0 mt-1" [style.display]="SearchControl==true ? 'block' : 'none'">
                                        <div class="d-flex align-items-center required borderNone" [ngClass]="((value_search==0) && (SearchValidation)) ? 'validation_border': ''">
                                            <label class="customLabel mr-1" for="Type">Company</label>
                                            <ngx-dropdown-treeview-select [config]="config_search" class="dropdown-company customInput-dropdown"
                                                [items]="userGroupObjects_search" [(value)]="value_search"
                                                (valueChange)="onValueChange_search($event)">
                                            </ngx-dropdown-treeview-select>
                                        </div>
                                    </div> -->
                                    <div class="col-lg-5 col-md-12 mt-lg-0 mt-1" [style.display]="SearchControl==true ? 'block' : 'none'">

                                        <div class="d-flex align-items-center">
                                            <label class="customLabel mr-1 ws-nobreak" for="Vendor Name">Part Question</label>
                                            <input type="text" placeholder="Search Part Question"
                                                Class="customInput"
                                                [(ngModel)]="ProductSearch" formControlName="ProductSearch"
                                                autocomplete="off" required [ngClass]="((!ProductSearch && SearchType==false) && (SearchValidation)) ? 'validation_border': ''">
                                                <!-- <div *ngIf="((!ProductSearch && SearchType==false) && (SearchValidation))"
                                                class="text-danger input-error err-fontSize"
                                                style="">
                                                Enter Question Name.</div> -->
                                            <i class="fas fa-search cpointer ml-3 mr-4 cpointer"
                                                (click)="searchQuestion()"></i>
                                            <button class="btn blue-bg round-btn m-0" (click)="AllQuestion()">All
                                                Question</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="white-box1" *ngIf="rolePermissionsList && rolePermissionsList[0].isView" [style.display]="ListShow==true ? 'block' : 'none'">
                <div class="white-box-title1">
                    <div class="customHeading mt-3 mb-2">
                        Part Question List
                        <!-- <span  id="expand-collapse">
                            <i class="fa fa-angle-down cpointer" aria-hidden="true" (click)="ListDivShowHide()"></i>
                        </span> -->
                        <i class="fa fa-angle-{{ListAngle}} cpointer ml-2" aria-hidden="true"(click)="ListDivShowHide()"></i>
                    </div>
                </div>
                <div class="card-desc1 table-responsive" [style.display]="ListDiv==true ? 'block' : 'none'">
                <table id="datatable" class="display table clearfix" datatable [dtOptions]="dtOptions"
                    [dtTrigger]="dtTrigger">
                    <thead>
                        <tr>
                            <th style="display:none">Id</th>
                            <th>Company Name</th>
                            <th>Part Questions</th>
                            <th>Answer Type</th>
                            <th data-orderable="false">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let data of allQuestionList">
                            <td style="display:none">{{data.id}}</td>
                            <td>{{data.companyName}}</td>
                            <td>{{data.questionTitle}}</td>
                            <td>{{data.typeName}}</td>
                            <td>
                                <a *ngIf="rolePermissionsList && rolePermissionsList[0].isUpdate"
                                    (click)="ToggleEdit(data)" class="green-text"><i class="fas fa-edit"></i></a>
                                <a *ngIf="rolePermissionsList && rolePermissionsList[0].isDelete"
                                    (click)="deletePopUp(data,content)" class="red-text"><i
                                        class="fas fa-trash-alt"></i></a>
                            </td>
                        </tr>

                    </tbody>
                </table>
                </div>


            </div>
            <div class="white-box1">
                <div class="white-box-title1 mb-0">
                    <div class="customHeading mt-3">
                        Add Questions
                    </div>
                </div>                
                <div class="card-desc1">
                    <form [formGroup]="form">
                        <div class="row mt-2">
                            <!-- <div class="col-lg-4 col-md-12">
                                <div class="row required">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Company</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10 borderNone" [ngClass]="((value==0) && (validation)) ? 'validation_border': ''">
                                        <ngx-dropdown-treeview-select class="dropdown-company customInput-dropdown" [config]="config" required
                                            [items]="userGroupObjects" [(value)]="value"
                                            (valueChange)="onValueChange($event)">
                                        </ngx-dropdown-treeview-select>
                                    </div>
                                </div>
                            </div> -->
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                                <div class="row required">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Question</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input class="customInput" placeholder="Enter Question" [(ngModel)]="questions"
                                            formControlName="questions" autocomplete="off" required minlength="3"
                                            maxlength="250" required [ngClass]="((!form.controls.questions.valid) && (validation)) ? 'validation_border': ''">
                                        <!-- <div *ngIf="((!form.controls.questions.valid) && (validation))"
                                            class="text-danger input-error">
                                            Enter Question.</div> -->
                                        <div *ngIf="(recordAlreadyExist)" class="text-danger input-error">
                                            Question Already Exist.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                                <div class="row required">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel ws-nobreak">Answer Type</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <select class="customInput" formControlName="answerType" required
                                            [(ngModel)]="selectedAnswerType"
                                            (ngModelChange)="changeAnswerType(selectedAnswerType)" [ngClass]="((!form.controls.answerType.valid) && (validation)) ? 'validation_border': ''">
                                            <option value=''>Select Answer Type</option>
                                            <option *ngFor="let item of answerTypeData" [value]="item.id">{{item.typeName}}
                                        </select>
                                        <!-- <div *ngIf="((!form.controls.answerType.valid) && (validation))"
                                            class="text-danger input-error">
                                            Select Answer Type.</div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="submit-from text-right mt-1">
                            <button *ngIf="rolePermissionsList && rolePermissionsList[0].isInsert"
                                class="btn blue-bg round-btn" (click)="AddQuestions()"><i class="fas fa-save"
                                    aria-hidden="true"></i> {{buttonValue}}
                            </button>
                            <button class="btn darkred-bg round-btn" *ngIf="clearbutton==true"  (click)="ClearModel()"><i class="fas fa-redo"
                                    aria-hidden="true"></i>
                                Clear </button>
                                <button class="btn darkred-bg round-btn" *ngIf="clearbutton==false" (click)="PartQuestionBack()"><i class="fas fa"
                                    aria-hidden="true"></i>
                                Exit </button>
                        </div>
                    </form>
                </div>

            </div>


           
        </div>
    </div>

</section>
<ng-template #content let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg alert_heading sweet-heading"></h4>
    <button class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body">
        <div class="thank-you-pop">
            <img src="./assets/image/popup-alert.png" alt="">
            <h1>Message</h1>
            <p class="modal-para m-1">{{deleteDetail}}</p>
        </div>
    </div>
    <div class="modal-footer sweet-footer text-center p-1">
        <button type="button" class="btn round-btn green-bg m-1"
            (click)="deleteQuestions(deletedItem); c('Close click')">Yes</button>
        <button type="button" class="btn round-btn red-bg m-1" (click)="c('Close click')">No</button>
    </div>
</ng-template>
<!-- export word -->

<div class="row" id="contentWord" #contentWord>
    <style>
        .mainword-tbl {
            font-family: 'Open Sans', sans-serif;
        }

        .word-tbl-style {
            border-collapse: collapse;
        }

        .word-tbl-style tr td {
            border: 1px solid #000000 !important;
            text-align: left;
            font-weight: bold;
            padding-left: 10px;
            padding-right: 10px;
        }

        .no-border-tbl tr td {
            border: none !important;
        }
    </style>

    <table width="100%" cellpadding="0" cellspacing="0" class="mainword-tbl" style="border: none; margin: 0px;">
        <tr>
            <td>
                <table width="100%" align="center" style="text-align: center; border: none; margin: 0px;">
                    <tr >
                        <td ><img  style="height: 200px;" src="this.profileLogo"   id="hrdlogoImage2"></td>
                    </tr>
                
                </table>
            </td>
        </tr>
       
        <tr>
            <td>
                <table border="1" bordercolor="black" style="border-collapse: collapse;  margin: 0px;"
                    cellpadding="0" cellspacing="0" width="100%" class="word-tbl-style">
                    <tr>
                        <td style="padding: 0px 5px 0px 5px;">Id</td>
                        <td style="padding: 0px 5px 0px 5px;">Part Questions:</td>
                        <td style="padding: 0px 5px 0px 5px;">Question Type:</td>
                    </tr>

                        <!-- <tr style="background: #dadada;">
                            <td>&nbsp;</td>
                            <td style="padding: 0px 5px 0px 5px;"> {{category.categoryName}}</td>
                            <td>&nbsp;</td>
                        </tr> -->
                        <ng-container *ngFor="let parts of allQuestionList;let innerIndex=index;">
                            <tr>
                                <td style="padding: 0px 5px 0px 5px;">{{parts.id}}</td>
                                <td style="padding: 0px 5px 0px 5px;">{{parts.questionTitle}}
                                </td>
                                <td style="padding: 0px 5px 0px 5px;">{{parts.typeName}}
                                </td>
                            </tr>
                        </ng-container>
                    
                  
                </table>
            </td>
        </tr>
       
    </table>
</div>
<!-- end export word -->
<div class="modal" id="add_success">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="addsuccess()" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Added Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="addsuccess()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="add_update">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="updateitem()" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Update Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="updateitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="delete_success">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="deleteitem()" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Deleted Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="deleteitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="wrong_msg">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="msgitem()" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>Something went wrong</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="msgitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>