import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { ConstantsService } from 'src/app/constants.service';
import { SharedService } from 'src/app/services/shared.service';
// import { TreeviewConfig, TreeviewItem } from 'ngx-treeview';
// import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown-angular7';
import { GlobalChangeService } from '../services/global-change.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-workflows',
  templateUrl: './workflows.component.html',
  styleUrls: ['./workflows.component.css']
})

export class WorkflowsComponent implements AfterViewInit, OnDestroy, OnInit {
  workflowDataList: any;
  workflowTaskCategoryList: any;
  workflowTaskCategoryList1: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  data: any;
  validation: boolean;
  name: any;
  id: any;
  userId: any;
  deletedItem: string;
  deleteDetail: string;
  loader: boolean = false;
  form: FormGroup;
  workflow: string;
  userRoleList: any;
  buttonValue: any;
  settingDetailCSS: any;
  recordAlreadyExist = false;
  settingLogoImage: any;
  clearbutton: boolean = true;
  rolePermissionsList: any;
  permissionMessage: any;
  TCreateUpdate: any = 1;
  ProductSearch: any = "";
  SearchValidation: any;
  SearchControl: boolean = false;
  ListShow: boolean = false;
  ListDiv: boolean = false;
  formSearch: FormGroup;
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  // config = TreeviewConfig.create({
  //   hasAllCheckBox: true,
  //   hasFilter: true,
  //   hasCollapseExpand: true,
  //   decoupleChildFromParent: false,
  //   maxHeight: 400
  // });
  // config1 = TreeviewConfig.create({
  //   hasAllCheckBox: true,
  //   hasFilter: true,
  //   hasCollapseExpand: true,
  //   decoupleChildFromParent: false,
  //   maxHeight: 400
  // });
  userGroupObjects = new Array();
  userGroupObjects1 = new Array();
  companyList: any;
  value = 0;
  value1 = 0;
  companyLoginId: any;
  userSearch: any;
  ListAngle: any;
  SearchType: any;
  value_search: number;
  userGroupObjects_search = new Array();
  companyList_search: any;
  // items_search: TreeviewItem[];
  createdDateEdit: any;
  now: Date;
  RowEdit: any;
  normalShow : any;
  //Task Category Properties
  dropdownList = [];
  selectedItems = [];
  TaskCategoryArr = [];
  dropdownSettings = {};

  /* Message Modal Properties */
  messageModalImage: any;
  messageModalHeading: any;
  messageModalDescription: any;
  @ViewChild('messageModal', { static: false }) private messageModal;


  constructor(
    private route: ActivatedRoute,
    public sharedService: SharedService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private constants: ConstantsService,
    private router: Router,
    private globalChangeService: GlobalChangeService
  ) { }

  ngOnInit(): void {
    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);
    }
    else {
      this.userId = localStorage.getItem(this.constants.userId);
      this.buttonValue = this.constants.Save;
      this.dtOptions = {
        pagingType: this.constants.full_numbers,
        pageLength: 10,
      };

      
    }

    this.form = new FormGroup({
      'workflow': new FormControl(),
      'selectedItems': new FormControl()

    });
    this.formSearch = new FormGroup({
      'TCreateUpdate': new FormControl(),
      'userSearch': new FormControl(),
    });
    this.rolePermissionsList = JSON.parse(localStorage.getItem('rolePermissionsList'));
    this.rolePermissionsList = this.rolePermissionsList.filter(x => x.pageId == 1023);
    this.permissionMessage = '';
    if (this.rolePermissionsList != null && this.rolePermissionsList != undefined) {
      if (!this.rolePermissionsList[0].isView) {
        this.permissionMessage += 'view';
      } if (!this.rolePermissionsList[0].isInsert) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'add';
      } if (!this.rolePermissionsList[0].isUpdate) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'edit';
      } if (!this.rolePermissionsList[0].isDelete) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'delete';
      }
    }
    if (this.permissionMessage != '') {
      this.permissionMessage = 'You do not have access to ' + this.permissionMessage + '. Please contact to administrator!';
    }

    //Task Category Properties

    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: false
    };

    this.companyLoginId = localStorage.getItem(this.constants.companyId);
    // this.getAllCompany(this.companyLoginId);
    // this.getAllCompany_search(this.companyLoginId);
    // this.getAllTaskCategory(this.companyLoginId, '');

    //ankit implementaion
    // get company by default set value
    var company = this.globalChangeService.getGlobalCompany();
    if(company>0){
      this.value = company;
      this.value_search = company;
      this.getAllTaskCategory(company, '');
    }
    else{
      this.ErrorModal('Error', 'Please select company.');
    }

    //on change function 
    this.globalChangeService.dropdownChange$.subscribe((data)=>{
      this.onValueChange(data);
      this.onValueChange_search(data);
    })
  }


  ngAfterViewInit() {
    this.settingDetailCSS = localStorage.getItem("settingDetailCSS");
    this.settingLogoImage = localStorage.getItem("settingImageLogo");
    this.SetSettingCSS(this.settingDetailCSS, this.settingLogoImage);
    this.dtTrigger.next('');
  }

  onItemSelect(item: any) {
    if (this.TaskCategoryArr.length > 0) {
      const insertedData = this.TaskCategoryArr.filter(x => x.item_id == item.item_id && x.IsActive == false);
      if (insertedData.length > 0) {
        insertedData[0].IsActive = true;
      }
      else{
        item.IsActive = true;
        this.TaskCategoryArr.push(item);
      }
    }
    else {
      item.IsActive = true;
      this.TaskCategoryArr.push(item);
    }
  }

  onItemDeSelect(item: any) {
    if (this.TaskCategoryArr.length > 0) {
      this.TaskCategoryArr.forEach((itemRemove, index) => {
        if (item.item_id === itemRemove.item_id) {
          itemRemove.IsActive = false;
        }
      });
    }
  }

  onSelectAll(items: any) {
    let oldTaskCategoryArr = this.TaskCategoryArr;
    this.TaskCategoryArr = [];
    items.forEach((itemInner, index) => {
      if (oldTaskCategoryArr.length > 0) {
        itemInner.Id = oldTaskCategoryArr[index].Id;
      }
      itemInner.IsActive = true;
      this.TaskCategoryArr.push(itemInner);
    });
  }

  onDeSelectAll(items: any) {
    if (this.TaskCategoryArr.length > 0) {
      this.TaskCategoryArr.forEach((itemInner, index) => {
        itemInner.IsActive = false;
      });
    }
  }

  getAllTaskCategory(companyId, SearchItem) {
    this.sharedService.getAllTaskCategory(companyId, SearchItem).subscribe(
      data => {
        this.data = data;
        let taskCatList = [];
        if (this.data.dataList) {
          this.data.dataList.forEach((item, index) => {
            let obj = {
              item_id: item.id,
              item_text: item.name
            }
            taskCatList.push(obj);
          });
          this.dropdownList = taskCatList;
        }
      });
  }

  getAllWorkflows(companyId, SearchItem) {
    this.sharedService.getAllWorkflows(companyId, SearchItem).subscribe(
      data => {
        this.loader = true;
        this.data = data;
        this.workflowDataList = this.data.dataList;
        this.GetTaskByWorkFlow(companyId);
    
      });
  }

  GetTaskByWorkFlow(companyId) {
    this.sharedService.GetTaskByWorkFlow(companyId).subscribe(
      data => {
       
        this.data = data;
        this.workflowTaskCategoryList = this.data.dataList;
        let list = [];
        for (var a = 0; a < this.workflowDataList.length; a++) {
          let customObj = {};
          customObj['id'] = this.workflowDataList[a].id;
          customObj['name'] = this.workflowDataList[a].name;
          customObj['companyName'] = this.workflowDataList[a].companyName;
          let taskcategorylist = [];
          if(this.workflowTaskCategoryList != undefined){
            let taskcategoryList=   this.workflowTaskCategoryList.filter(s => s.id == this.workflowDataList[a].id);
            for (var b = 0; b < taskcategoryList.length; b++) {
              let taskcategory = {};
              taskcategory['category'] = taskcategoryList[b].taskDescription;
              taskcategorylist.push(taskcategory['category']);
            }
            taskcategoryList.forEach(element => {
            });
            customObj['taskCategory'] = taskcategorylist.join(',');;
          }
          list.push(customObj);
        }
        this.workflowTaskCategoryList1 = list;
        this.loader = false;
        this.rerender();
        this.ListShow = true;
        this.ListDiv = true;
        this.ListAngle = this.constants.UpArrow;
      });
  }
  addNewWorkflow() {
    this.validation = true;
    this.clearbutton = true;
    if (this.form.controls.workflow.status === this.constants.Invalid || this.value == 0
    ) {
      return;
    }

    if (this.TaskCategoryArr.length == 0) {
      this.modalError(this.constants.SelectConfigTrigger);
      return;
    }

    this.loader = true;

    const obj = {};
    obj['name'] = this.workflow;
    obj['CompanyId'] = this.value;
    obj['CreatedBy'] = this.userId;

    let TaskCategoryArr1 = this.TaskCategoryArr;
    this.TaskCategoryArr = [];
    for (var j = 0; j < TaskCategoryArr1.length; j++) {
      let items = {};
      items["Id"] = TaskCategoryArr1[j].Id;
      items['item_id'] = TaskCategoryArr1[j].item_id;
      items['item_text'] = TaskCategoryArr1[j].item_text;
      items['IsActive'] = TaskCategoryArr1[j].IsActive;
      items['CreatedBy'] = this.userId;
      items["CreatedDate"] = this.now = new Date();
      items["CompanyId"] = Number(this.value);
      this.TaskCategoryArr.push(items);
    }
    obj['WorkflowTaskCategory'] = this.TaskCategoryArr;

    if (this.id != undefined && this.id != null && this.id != '') {
      obj['Id'] = this.id;
      obj['ModifiedBy'] = this.userId;
      obj['CreatedDate'] = this.createdDateEdit;
      this.sharedService.updateWorkflow(obj)
        .subscribe(
          data => {
            switch (data.statusCode) {
              case 200:
                this.loader = false;
                this.buttonValue = this.constants.Save;
                this.TCreateUpdate = 1;
                this.SearchControl = false;
                this.ListShow = false;
                this.ListDiv = false;
                this.validation = false;
                this.id = '';
                this.resetWorkflow();
                this.AllWorkflows();
                this.modalSuccess(this.constants.RecordUpdateSuccessfully);
                break;
              case 400:
                this.loader = false;
                this.modalError(this.constants.SomethingWentWrong);
                break;

              default:
                this.loader = false;
                this.modalError(this.constants.SomethingWentWrong);
            }
          }
        );
    } else {
      this.sharedService.addWorkflow(obj)
        .subscribe(
          data => {
            switch (data.statusCode) {
              case 200:
                this.loader = false;
                this.recordAlreadyExist = false;
                this.validation = false;
                this.resetWorkflow();
                this.AllWorkflows();
                this.modalSuccess(this.constants.RecordAddedSuccessfully);
                break;
              case 400:
                this.loader = false;
                this.modalError(this.constants.SomethingWentWrong);
                break;
              case 208:
                this.loader = false;
                this.modalError(this.constants.RecordAlreadyExist);
                break;
              default:
                this.loader = false;
                this.modalError(this.constants.SomethingWentWrong);
            }
          }
        );
    }
  }

  deletePopup(item, content) {
    this.deletedItem = '';
    this.modalService.open(content, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
    this.deleteDetail = this.constants.DeletePopup;
    this.deletedItem = item.id;
  }


  deleteWorkflow(item: any) {
    this.sharedService.deleteWorkflow(item).subscribe(
      data => {

        this.data = data;
        this.loader = true;
        switch (data.statusCode) {
          case 200:
            this.loader = false;
            this.getAllWorkflows(this.value_search, this.userSearch == "" ? "" : this.userSearch);
            this.resetWorkflow();
            this.rerender();
            this.modalSuccess(this.constants.RecordDeletedSuccessfully);
            break;
          case 400:
            this.loader = false;
            this.modalError(this.constants.SomethingWentWrong);
            break;
          default:
            this.loader = false;
            this.modalError(this.constants.SomethingWentWrong);
        }
      });
  }


  toggleEdit(item: any) {
    this.TaskCategoryArr = [];
    this.selectedItems = [];
    this.ListDiv = false;
    this.ListAngle = this.constants.DownArrow;
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.buttonValue = this.constants.Update;
    this.clearbutton = false;
    this.sharedService.GetWorkflow(item.id).subscribe(
      (data: any) => {
        this.id = data.data.id;
        this.value = data.data.companyId;
        this.workflow = data.data.name;
        this.createdDateEdit = data.data.createdDate == null ? '' : data.data.createdDate == undefined ? '' : data.data.createdDate;
        if (data.data.jsonData) {
          this.TaskCategoryArr = JSON.parse(data.data.jsonData);
          this.selectedItems = this.TaskCategoryArr.filter(x => x.IsActive == true);
        }
      });
  }


  SetSettingCSS(settingCSS, logoImagePath) {
    if (settingCSS != null) {
      let headerCss = document.getElementsByClassName(this.constants.CssHealerClass) as HTMLCollectionOf<HTMLElement>;
      if (headerCss.length != 0) {
        headerCss[0].style.backgroundColor = settingCSS.split(',')[0];
      }
      let saveButtonCss = document.getElementsByClassName(this.constants.CssSaveBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (saveButtonCss.length != 0) {
        saveButtonCss[0].style.backgroundColor = settingCSS.split(',')[1];
      }
      let cencelButtonCss = document.getElementsByClassName(this.constants.CssCancelBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (cencelButtonCss.length != 0) {
        cencelButtonCss[0].style.backgroundColor = settingCSS.split(',')[2];
      }
    }

    if (logoImagePath != null && logoImagePath != "") {
      (document.getElementById('hrdlogoImage') as HTMLImageElement).src = logoImagePath;
    }

  }

  WorkflowBack() {
    this.clearbutton = true;
    this.id = null;
    this.buttonValue = this.constants.Save;
    this.resetWorkflow();
  }

  // getAllCompany(companyId) {
  //   this.sharedService.GetDropdownCompanyHierarchyModel(companyId).subscribe(
  //     data => {
  //       this.data = data;
  //       if (this.data.dataList != null && this.data.dataList != undefined) {
  //         this.companyList = this.data.dataList;
  //         this.companyList.forEach((x) => {
  //           this.userGroupObjects.push(new TreeviewItem(x, false));

  //         });
  //       }
  //       this.value = Number(companyId);
  //     });
  // }

  onValueChange(value: any): void {
    this.value = value;
  }

  ChangeTCreateUpdate(event) {
    this.TCreateUpdate = event.target.value;
    if (this.TCreateUpdate == "1") {
      this.SearchControl = false;
      this.ListShow = false;
      this.ListDiv = false;
      this.clearbutton = true;
      this.buttonValue = this.constants.Save;
      this.resetWorkflow();
    }
    else if (this.TCreateUpdate == "0") {
      this.SearchControl = true;
      this.userSearch = "";
    }
  }

  ListDivShowHide() {
    if (this.ListDiv == true) {
      this.ListDiv = false;
      this.ListAngle = this.constants.DownArrow;
    }
    else if (this.ListDiv == false) {
      this.ListDiv = true;
      this.ListAngle = this.constants.UpArrow;
    }
  }

  searchWorkflow() {
    this.SearchType = false;
    if (!this.userSearch || this.value_search == 0) {
      this.SearchValidation = true;
      return;
    }

    this.getAllWorkflows(this.value_search, this.userSearch);
  }

  AllWorkflows() {

    this.SearchType = true;
    if (this.value_search == 0) {
      this.SearchValidation = true;
      return;
    }

    this.getAllWorkflows(this.value_search, '');
  }

  // getAllCompany_search(companyId) {
  //   this.sharedService.GetDropdownCompanyHierarchyModel(this.companyLoginId).subscribe(
  //     data => {
  //       this.data = data;
  //       this.items_search = this.data.dataList;
  //       this.companyList_search = this.data.dataList;
  //       this.companyList_search.forEach((x) => {
  //         this.userGroupObjects_search.push(new TreeviewItem(x, false));
  //       });
  //       this.value_search = Number(companyId);
  //     });
  // }

  onValueChange_search(value: any): void {
    this.value_search = value;

  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next('');
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  resetWorkflow() {
    this.validation = false;
    this.buttonValue = this.constants.Save;
    this.id = null;
    this.workflow = '';
    this.TaskCategoryArr = [];
    this.selectedItems = [];
    if (this.companyLoginId > 0) {
      this.value = Number(this.companyLoginId)
    }
    else {
      this.value = 0;
    }
  }

  modalSuccess(msg) {
    this.messageModalImage = this.constants.SuccessPng;
    this.messageModalDescription = msg;
    this.modalService.dismissAll();
    this.modalService.open(this.messageModal, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
  }

  modalError(msg) {
    this.messageModalImage = this.constants.ClosePng;
    this.messageModalDescription = msg;
    this.modalService.dismissAll();
    this.modalService.open(this.messageModal, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
  }

  
  //ankit implemenation
  ErrorModal(title:string, message:string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }
}

