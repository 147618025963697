import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
// import { TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { Subject } from 'rxjs';
import { ConstantsService } from 'src/app/constants.service';
import { SharedService } from '../services/shared.service';
import Swal from 'sweetalert2';
import { GlobalChangeService } from '../services/global-change.service';
import { CompanyComponent } from '../admin/company/company.component';
import { CompanyService } from '../services/company/company.service';

@Component({
  selector: 'app-skill-level-category',
  templateUrl: './skill-level-category.component.html',
  styleUrls: ['./skill-level-category.component.css']
})

export class SkillLevelCategoryComponent implements AfterViewInit, OnDestroy, OnInit {

  loader: boolean = false;
  rolePermissionsList: any;
  permissionMessage: any;
  form: FormGroup;
  formSearch: FormGroup;
  dtOptions: DataTables.Settings = {};
  userId: any;
  buttonValue: any;
  TCreateUpdate: any = 1;
  TSkillLevel: any = 0;
  companyLoginId: any;
  data: any;
  userGroupObjects = new Array();
  companyList: any;
  value = 0;
  skillLevel = 0;
  userGroupObjects_search = new Array();
  companyList_search: any;
  // items_search: TreeviewItem[];
  value_search: number;
  SearchControl: boolean = false;
  ListShow: boolean = false;
  ListDiv: boolean = false;
  clearbutton: boolean = true;
  userSearch: any;
  id: any;
  skilllevelcategory: any;
  skilllevelcategorydesc: any;
  SearchValidation: any;
  SearchType: any;
  ListAngle: any;
  dtTrigger: Subject<any> = new Subject<any>();
  deletedItem: string;
  deleteDetail: string;
  recordAlreadyExist = false;
  skillAlreadyExist = false;
  nameAlreadyExist = false;
  validation: boolean;
  createdDateEdit: any;
  skillLevelCategoryDataList: any;
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  settingDetailCSS: any;
  settingLogoImage: any;
  /* Message Modal Properties */
  messageModalImage: any;
  messageModalHeading: any;
  messageModalDescription: any;
  userRole = '';
  userRoleList: any;
  selectedRoleValue = '';

  @ViewChild('messageModal', { static: false }) private messageModal;
  items_search: any;

  // config = TreeviewConfig.create({
  //   hasAllCheckBox: true,
  //   hasFilter: true,
  //   hasCollapseExpand: true,
  //   decoupleChildFromParent: false,
  //   maxHeight: 400
  // });

  constructor(
    private constants: ConstantsService,
    private router: Router,
    public sharedService: SharedService,
    private modalService: NgbModal,
    private globalChangeService: GlobalChangeService,
    private companyService: CompanyService
  ) { }

  ngOnInit(): void {
    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);
    } else {
      this.userId = localStorage.getItem(this.constants.userId);
      this.buttonValue = this.constants.Save;
      this.dtOptions = {
        pagingType: this.constants.full_numbers,
        pageLength: 10,
      };

      
    }
    this.form = new FormGroup({
      'skilllevelcategory': new FormControl(),
      'skilllevelcategorydesc': new FormControl(),
      'TSkillLevel': new FormControl(),
      'roleId': new FormControl()
    });
    this.formSearch = new FormGroup({
      'TCreateUpdate': new FormControl(),
      'userSearch': new FormControl(),
    });
    this.rolePermissionsList = JSON.parse(localStorage.getItem('rolePermissionsList'));
    this.rolePermissionsList = this.rolePermissionsList.filter(x => x.pageId == 1026);
    this.permissionMessage = '';
    if (this.rolePermissionsList != null && this.rolePermissionsList != undefined) {
      if (!this.rolePermissionsList[0].isView) {
        this.permissionMessage += 'view';
      } if (!this.rolePermissionsList[0].isInsert) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'add';
      } if (!this.rolePermissionsList[0].isUpdate) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'edit';
      } if (!this.rolePermissionsList[0].isDelete) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'delete';
      }
    }
    if (this.permissionMessage != '') {
      this.permissionMessage = 'You do not have access to ' + this.permissionMessage + '. Please contact to administrator!';
    }
    this.companyLoginId = localStorage.getItem(this.constants.companyId);
    // this.getAllCompany(this.companyLoginId);
    // this.getAllCompany_search(this.companyLoginId);
    // this.getAllUsersRole(this.companyLoginId);

    //ankit implementaion
      // get company by default set value
      var company = this.globalChangeService.getGlobalCompany();
      if(company>0){
        this.value = company;
        this.value_search = company;

        this.getAllCompany(company);
        this.getAllCompany_search(company);

        if (this.value > 0) {
          this.getAllUsersRole(this.value);
        }
        else {
          this.selectedRoleValue = '';
        }
      }
      else{
        this.ErrorModal('Error', 'Please select company.');
      }

      //on change function 
      this.globalChangeService.dropdownChange$.subscribe((data)=>{
        this.onValueChange(data);
        this.onValueChange_search(data);
      })
  }

  ngAfterViewInit(): void {
    this.settingDetailCSS = localStorage.getItem("settingDetailCSS");
    this.settingLogoImage = localStorage.getItem("settingImageLogo");
    this.SetSettingCSS(this.settingDetailCSS, this.settingLogoImage);
    this.dtTrigger.next('');
  }

  SetSettingCSS(settingCSS, logoImagePath) {
    if (settingCSS != null) {
      let headerCss = document.getElementsByClassName(this.constants.CssHealerClass) as HTMLCollectionOf<HTMLElement>;
      if (headerCss.length != 0) {
        headerCss[0].style.backgroundColor = settingCSS.split(',')[0];
      }
      let saveButtonCss = document.getElementsByClassName(this.constants.CssSaveBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (saveButtonCss.length != 0) {
        saveButtonCss[0].style.backgroundColor = settingCSS.split(',')[1];
      }
      let cencelButtonCss = document.getElementsByClassName(this.constants.CssCancelBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (cencelButtonCss.length != 0) {
        cencelButtonCss[0].style.backgroundColor = settingCSS.split(',')[2];
      }
    }

    if (logoImagePath != null && logoImagePath != "") {
      (document.getElementById('hrdlogoImage') as HTMLImageElement).src = logoImagePath;
    }

  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  getAllCompany(companyId) {
    this.companyService.GetDropdownCompanyHierarchyModel(companyId).subscribe(
      data => {
        this.data = data;
        if (this.data.dataList != null && this.data.dataList != undefined) {
          this.companyList = this.data.dataList;
          // this.companyList.forEach((x) => {
          //   this.userGroupObjects.push(new TreeviewItem(x, false));

          // });
        }
        this.value = Number(companyId);
      });
  }

  getAllCompany_search(companyId) {
    this.companyService.GetDropdownCompanyHierarchyModel(this.companyLoginId).subscribe(
      data => {
        this.data = data;
        this.items_search = this.data.dataList;
        this.companyList_search = this.data.dataList;
        // this.companyList_search.forEach((x) => {
        //   this.userGroupObjects_search.push(new TreeviewItem(x, false));
        // });
        this.value_search = Number(companyId);

      });
  }

  ChangeTCreateUpdate(event) {
    this.TCreateUpdate = event.target.value;
    if (this.TCreateUpdate == "1") {
      this.SearchControl = false;
      this.ListShow = false;
      this.ListDiv = false;
      this.clearbutton = true;
      this.buttonValue = this.constants.Save;
      this.resetSkillLevelCategory();

    }
    else if (this.TCreateUpdate == "0") {
      this.SearchControl = true;
      this.userSearch = "";

    }
  }

  ChangeSkillLevel(event) {
    this.TSkillLevel = event.target.value;;
    // if (this.TSkillLevel) {
    //   var data = this.skillLevelCategoryDataList.filter(x => x.roleId == this.selectedRoleValue && x.skillLevel == Number(this.TSkillLevel));
    //   if (data.length != 0) {
    //     this.skillAlreadyExist = true;
    //     this.nameAlreadyExist = false;
    //   }
    //   else{
    //     this.skillAlreadyExist = false;
    //     this.nameAlreadyExist = false;
    //   }
    // }
  }

  resetSkillLevelCategory() {
    this.id = '';
    this.skilllevelcategory = '';
    this.selectedRoleValue = '';
    this.skilllevelcategorydesc = '';
    this.TSkillLevel = 0;
    if (this.companyLoginId > 0) {
      this.value = Number(this.companyLoginId)
    }
    else {
      this.value = 0;

    }

  }

  onValueChange_search(value: any): void {
    this.value_search = value;
  }

  searchSkillLevelCategory() {
    this.SearchType = false;
    if (!this.userSearch || this.value_search == 0) {
      this.SearchValidation = true;
      return;
    }
    this.getAllSkillLevelCategory(this.value_search, this.userSearch == "" ? "" : this.userSearch);
  }

  AllSkillLevelCategory() {

    this.SearchType = true;
    if (this.value_search == 0) {
      this.SearchValidation = true;
      return;
    }

    this.getAllSkillLevelCategory(this.value_search, '');
  }

  getAllSkillLevelCategory(companyId, SearchItem) {
    this.sharedService.getAllSkillLevelCategory(companyId, SearchItem == undefined || SearchItem == "" ? SearchItem = '' : SearchItem).subscribe(
      data => {
        this.loader = true;
        this.data = data;
        this.skillLevelCategoryDataList = this.data.dataList;
        this.loader = false;
        this.rerender();
        this.ListShow = true;
        this.ListDiv = true;
        this.ListAngle = this.constants.UpArrow;
      });
  }

  ListDivShowHide() {
    if (this.ListDiv == true) {
      this.ListDiv = false;
      this.ListAngle = this.constants.DownArrow;
    }
    else if (this.ListDiv == false) {
      this.ListDiv = true;
      this.ListAngle = this.constants.UpArrow;
    }
  }

  deletePopup(item, content) {
    this.deletedItem = '';
    this.modalService.open(content, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
    this.deleteDetail = this.constants.DeletePopup;
    this.deletedItem = item.id;
  }

  onValueChange(value: any): void {
    this.value = value;
    if (this.value > 0) {
      this.getAllUsersRole(this.value);
    }
    else {
      this.selectedRoleValue = '';
    }
  }

  modalSuccess(msg) {
    this.messageModalImage = this.constants.SuccessPng;
    this.messageModalDescription = msg;
    this.modalService.dismissAll();
    this.modalService.open(this.messageModal, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
  }

  modalError(msg) {
    this.messageModalImage = this.constants.ClosePng;
    this.messageModalDescription = msg;
    this.modalService.dismissAll();
    this.modalService.open(this.messageModal, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
  }

  addNewSkillLevelCategory() {
    this.validation = true;
    this.clearbutton = true;
    if (this.form.controls.skilllevelcategory.status === this.constants.Invalid || this.form.controls.skilllevelcategorydesc.status === this.constants.Invalid || this.value == 0 || this.TSkillLevel == 0 || this.form.controls.roleId.status == this.constants.Invalid
    ) {
      return;
    }

    this.loader = true;
    const obj = {};
    obj['Name'] = this.skilllevelcategory;
    obj['skillLevelDescription'] = this.skilllevelcategorydesc;
    obj['skillLevel'] = Number(this.TSkillLevel);
    obj['CompanyId'] = this.value;
    obj['CreatedBy'] = this.userId;
    obj['roleId'] = this.selectedRoleValue;
    if (this.id != undefined && this.id != null && this.id != '') {
      obj['Id'] = this.id;
      obj['ModifiedBy'] = this.userId;
      obj['CreatedDate'] = this.createdDateEdit;
      this.sharedService.updateSkillLevelCategory(obj)
        .subscribe(
          data => {
            switch (data.statusCode) {
              case 200:
                this.loader = false;
                this.buttonValue = this.constants.Save;
                this.skilllevelcategory = '';
                this.skilllevelcategorydesc = '';
                this.TCreateUpdate = 1;
                this.skillLevel = 0;
                this.selectedRoleValue = '';
                this.SearchControl = false;
                this.ListShow = false;
                this.ListDiv = false;
                this.validation = false;
                this.id = '';
                this.nameAlreadyExist = false;
                  this.skillAlreadyExist = false;
                this.resetSkillLevelCategory();
                this.AllSkillLevelCategory();
                this.modalSuccess(this.constants.RecordUpdateSuccessfully);
                break;
              case 400:
                this.loader = false;
                this.modalError(this.constants.SomethingWentWrong);
                break;
              case 208:
                if (data.data == "Name") {
                  this.loader = false;
                  this.nameAlreadyExist = true;
                  this.skillAlreadyExist = false;
                }
                else if (data.data == "Skill") {
                  this.loader = false;
                  this.skillAlreadyExist = true;
                  this.nameAlreadyExist = false;
                }
                else {
                  this.nameAlreadyExist = false;
                  this.skillAlreadyExist = false;
                  this.modalError(this.constants.RecordAlreadyExist);
                }
                break;
              default:
                this.loader = false;
                this.modalError(this.constants.SomethingWentWrong);
            }
          }
        );
    } else {
      this.sharedService.addSkillLevelCategory(obj)
        .subscribe(
          data => {
            switch (data.statusCode) {
              case 200:
                this.loader = false;
                this.recordAlreadyExist = false;
                this.validation = false;
                this.skillAlreadyExist = false;
                this.nameAlreadyExist = false;
                this.resetSkillLevelCategory();
                this.AllSkillLevelCategory();
                this.modalSuccess(this.constants.RecordAddedSuccessfully);
                break;
              case 400:
                this.loader = false;
                this.recordAlreadyExist = true;
                this.skillAlreadyExist = false;
                this.nameAlreadyExist = false;
                this.modalError(this.constants.SomethingWentWrong);
                break;
              case 208:
                if (data.data == "Name") {
                  this.loader = false;
                  this.nameAlreadyExist = true;
                  this.skillAlreadyExist = false;
                }
                else if (data.data == "Skill") {
                  this.loader = false;
                  this.skillAlreadyExist = true;
                  this.nameAlreadyExist = false;
                }
                else {
                  this.nameAlreadyExist = false;
                  this.skillAlreadyExist = false;
                  this.modalError(this.constants.RecordAlreadyExist);
                }
                break;
              default:
                this.loader = false;
                this.recordAlreadyExist = true;
                this.nameAlreadyExist = false;
                this.skillAlreadyExist = false;
                this.modalError(this.constants.SomethingWentWrong);
            }
          }
        );
    }

  }

  SkillLevelCategoryBack() {
    this.clearbutton = true;
    this.id = null;
    this.buttonValue = this.constants.Save;
    this.resetSkillLevelCategory();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next('');
    });
  }

  toggleEdit(item: any) {
    this.ListDiv = false;
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.buttonValue = this.constants.Update;
    this.skilllevelcategory = item.name;
    this.id = item.id;
    this.TSkillLevel = item.skillLevel;
    if (item.roleId == null || item.roleId == undefined) {
      this.selectedRoleValue = '';
    }
    else {
      this.selectedRoleValue = item.roleId;
    }
    this.skilllevelcategorydesc = item.skillLevelDescription;
    this.clearbutton = false;
    this.value = item.companyId;
    this.createdDateEdit = item.createdDate == null ? '' : item.createdDate == undefined ? '' : item.createdDate;
    this.getAllUsersRole(item.companyId);
  }

  deleteSkillLevelCategory(item: any) {
    this.sharedService.deleteSkillLevelCategory(item).subscribe(
      data => {

        this.data = data;
        this.loader = true;
        switch (data.statusCode) {
          case 200:
            this.loader = false;
            this.getAllSkillLevelCategory(this.value_search, this.userSearch == "" ? "" : this.userSearch);
            this.rerender();
            this.modalSuccess(this.constants.RecordDeletedSuccessfully);
            break;
          case 208:
            this.loader = false;
            this.modalError("Skill Level not deleted that is used by a user role.");
            break;
          case 400:
            this.loader = false;
            this.modalError(this.constants.SomethingWentWrong);
            break;
          default:
            this.loader = false;
            this.modalError(this.constants.SomethingWentWrong);
        }
      });
  }

  getAllUsersRole(companyId) {
    this.sharedService.getAllUserRolesByUserId(companyId, '').subscribe(
      data => {
        this.data = data;
        if (companyId > 0) {
          this.userRoleList = this.data.dataList;
        }
        else {
          this.selectedRoleValue = '';
        }
      });
  }

  changeUserRole(event) {
    this.selectedRoleValue = event;
  }

   //ankit implemenation
   ErrorModal(title:string, message:string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }
}
