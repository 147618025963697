<section class="content-section">
  <div class="white-box">

    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl">
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
        <button mat-icon-button disabled></button>
        <div class="node-inner">
        {{node.name}}
        </div>
      </mat-tree-node>
    
      <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
       
        <button mat-icon-button matTreeNodeToggle
                [attr.aria-label]="'Toggle ' + node.name">
          <mat-icon class="mat-icon-rtl-mirror">        
            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>
        <div class="node-inner">
          <div class="company-logo d-inline-block">
            <img _ngcontent-mow-c45="" src="/assets/image/company-logo.jpg"> 
          </div>        
          <div class="d-inline-block company-info-main">
            {{node.name}}
            <p class="company-info">1509 Johnson Ave - 26330-1016</p>
          </div>
      </div>
       
      </mat-tree-node>
    </mat-tree>
  </div>
<!-- <app-tabletree></app-tabletree> -->
</section>
