import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { CompanyComponent } from './company/company.component';
import { UsersComponent } from './users/users.component';
import { VendorsComponent } from './vendors/vendors.component';
import { ProductsComponent } from './products/products.component';
import { PartsComponent } from './parts/parts.component';
import { OrdersComponent } from './orders/orders.component';
import { PartsQuestionsComponent } from './parts-questions/parts-questions.component';
import { CompanyListComponent } from './company-list/company-list.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { OrderPartdetailComponent } from './order-partdetail/order-partdetail.component';
import { CategoryComponent } from './category/category.component';
import { CustomPartComponent } from './custom-part/custom-part.component';
import { PurchaseOrderComponent } from './purchase-order/purchase-order.component';
import { ReceiveOrderComponent } from './receive-order/receive-order.component';
import { ShippingComponent } from './shipping/shipping.component';
import { InventoryComponent } from './inventory/inventory.component';
import { DashboardlistComponent } from './dashboardlist/dashboardlist.component';
import { ProductionJobsComponent } from './production-jobs/production-jobs.component';
import { MyProductionTaskComponent } from './my-production-task/my-production-task.component';
import { TeamManagementComponent } from './team-management/team-management.component';
import { SearchableFieldsComponent } from './searchable-fields/searchable-fields.component';
import { ReportComponent } from './report/report.component';
import { VehiclelistComponent } from './vehiclelist/vehiclelist.component';
import { PicklistComponent } from './picklist/picklist.component';
import { MenuComponent } from './menu/menu.component';
import { PurchaseorderreportComponent } from './purchaseorderreport/purchaseorderreport.component';
import { ShipmentComponent } from './shipment/shipment.component';
import { PickListsComponent } from './pick-lists/pick-lists.component';
import { FormGuardService } from '../FormGuard/form-guard.service';
import { WarehouseSetupComponent } from './warehouse-setup/warehouse-setup.component';
import { CustomersComponent } from './customers/customers.component';
import { HelcimComponent } from './helcim/helcim.component';
import { SalesOrderComponent } from './sales-order/sales-order.component';
import { PartDetailsComponent } from './part-details/part-details.component';
import { ReceivingDetailsComponent } from './receiving/receiving-details/receiving-details.component';
import { ReceivingListComponent } from './receiving/receiving-list/receiving-list.component';
import { PurchaseReportComponent } from './purchase-report/purchase-report.component';
import { SalesReportComponent } from './sales-report/sales-report.component';
import { PoListComponent } from './purchaseorder-details/po-list/po-list.component';
import { PoDetailComponent } from './purchaseorder-details/po-detail/po-detail.component';
import { PutawayComponent } from './putaway/putaway.component';
import { InventoryListComponent } from './inventory-details/inventory-list/inventory-list.component';
import { InventoryDetailComponent } from './inventory-details/inventory-detail/inventory-detail.component';
import { Shipment1Component } from './shipment1/shipment1.component';
import { ReceivingCongratulationsComponent } from './receiving/receiving-congratulations/receiving-congratulations.component';
import { JSPrintComponent } from './jsprint/jsprint.component';

const adminRoutes: Routes = [
  {
    path: '',
    component: AdminDashboardComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: '',
        canActivateChild: [AuthGuard],
        children: [
          { path: 'company', component: CompanyComponent },
          { path: '', component: AdminDashboardComponent }
        ]
      }
    ]
  }, {
    path: 'users',
    component: UsersComponent,
  // }, {
  //   path: 'print',
  //   component: JSPrintComponent,
  },
  {
    path: 'company',
    component: CompanyComponent,
  },
  {
    path: 'vendors',
    canDeactivate: [FormGuardService],
    component: VendorsComponent,
  }, {
    path: 'vendors/:id',
    component: VendorsComponent,
  }, {
    path: 'products',
    canDeactivate: [FormGuardService],
    component: ProductsComponent,
  }, {
    path: 'parts',
    canDeactivate: [FormGuardService],
    component: PartsComponent,
  }, {
    path: 'parts/:id',
    component: PartsComponent,
  }, {
    path: 'orders',
    canDeactivate: [FormGuardService],
    component: OrdersComponent,
  }, {
    path: 'order/:id',
    component: OrdersComponent,
  },
  {
    path: 'orders/:orderId/:orderTypeId/:type',
    component: OrdersComponent,
  }, {
    path: 'partquestions',
    component: PartsQuestionsComponent,
  }, {
    path: 'companyList',
    component: CompanyListComponent,
  }, {
    path: 'orderView/:id',
    canDeactivate: [FormGuardService],
    component: OrderDetailsComponent,
  },
  {
    path: 'orderView/:id/:type',
    component: OrderDetailsComponent,
  }, {
    path: 'orderpartdetails/:id',
    canDeactivate: [FormGuardService],
    component: OrderPartdetailComponent,
  },
  {
    path: 'orderpartdetails/:id/:type',
    component: OrderPartdetailComponent,
  },
  {
    path: 'category',
    component: CategoryComponent,
  },
  {
    path: 'customPart',
    canDeactivate: [FormGuardService],
    component: CustomPartComponent,
  },
  {
    path: 'customPart/:id',
    component: CustomPartComponent,
  }, {
    path: 'purchasorder',
    canDeactivate: [FormGuardService],
    component: PurchaseOrderComponent,
  },
  {
    path: 'purchasorder/:poNumber/:companyId/:type',
    component: PurchaseOrderComponent,
  },
  {
    path: 'purchasorder/:id',
    component: PurchaseOrderComponent,
  }, {
    path: 'receiveorder',
    component: ReceiveOrderComponent,
  }, {
    path: 'shipping',
    component: ShippingComponent,
    canDeactivate: [FormGuardService],
  }, {
    path: 'shipping/:soNumber/:companyId/:type',
    component: ShippingComponent,
  }, {
    path: 'inventory',
    component: InventoryComponent,
  }, {
    path: 'dashboardlist/:type',
    component: DashboardlistComponent,
  },
  {
    path: 'productionjobs',
    component: ProductionJobsComponent,
  }, {
    path: 'myproductiontask',
    component: MyProductionTaskComponent,
  }, {
    path: 'teammanagement',
    component: TeamManagementComponent,
  }, {
    path: 'searchablefield',
    component: SearchableFieldsComponent,
  }, {
    path: 'report',
    component: ReportComponent,
  }, {
    path: 'vehiclelist',
    component: VehiclelistComponent,
  }, {
    path: 'picklist',
    component: PicklistComponent,
  },
  {
    path: 'picklists',
    component: PickListsComponent,
  }, {
    path: 'menu',
    component: MenuComponent,
  }, {
    path: 'purchaseorderreport',
    component: PurchaseorderreportComponent,
  }, {
    path: 'shipment1',
    component: ShipmentComponent,
    canDeactivate: [FormGuardService],
  }, {
    path: 'WareHouseSetup',
    component: WarehouseSetupComponent
  }, {
    path: 'customers',
    component: CustomersComponent
  }, {
    path: 'poList',
    component: PoListComponent
  }, {
    path: 'poDetails',
    component: PoDetailComponent
  }, {
    path: 'poDetails/:id',
    component: PoDetailComponent
  }, {
    path: 'helcim',
    component: HelcimComponent
  }, {
    path: 'SO',
    component: SalesOrderComponent
  }, {
    path: 'inventory-list',
    component: InventoryListComponent
  },
  , {
    path: 'inventory-detail/:id',
    component: InventoryDetailComponent
  }, {
    path: 'receiving',
    component: ReceivingListComponent
  }, {
    path: 'receiving/:id',
    component: ReceivingDetailsComponent
  }, {
    path: 'receiving-congratulations/:id',
    component: ReceivingCongratulationsComponent
  }, {
    path: 'partDetail',
    component: PartDetailsComponent
  }, {
    path: 'poreport',
    component: PurchaseReportComponent
  }, {
    path: 'soreport',
    component: SalesReportComponent
  }, {
    path: 'putaway',
    component: PutawayComponent
  },{
    path: 'putAway',
    component: PutawayComponent
  }, 
  {
    path: 'shipment',
    component: Shipment1Component
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(adminRoutes),
  ],
  exports: [
    RouterModule
  ]
})
export class AdminRoutingModule { }
