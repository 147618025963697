import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CustomerService } from '../../services/customer/customer.service';
import { GlobalChangeService } from '../../services/global-change.service';
import Swal from 'sweetalert2';


interface Customer {
  value: number;
  text: string;
  isActive?: boolean;
  isEcommerece?: boolean;
  wordpressId?: number;
  children?: Customer[];
}

@Component({
  selector: 'app-customer-dropdown',
  templateUrl: './customer-dropdown.component.html',
  styleUrls: ['./customer-dropdown.component.css']
})
export class CustomerDropdownComponent implements OnInit {
  @Input() _selectedCustomer = 0;
  @Output() _newItemEvent = new EventEmitter<number>();

  //_selectedCustomer: Number;
  customers: Customer[] = [];
  data: any;

  constructor(
    private customerService: CustomerService,
    private globalChangeService:GlobalChangeService
  ) { }

  ngOnInit(): void {
    var company = this.globalChangeService.getGlobalCompany();
    if(company>0){
      this.getParentCustomer(company);
    }
    else{
      this.ErrorModal('Error', 'Please select company.');
    }

    this.globalChangeService.dropdownChange$.subscribe((data)=>{
      this.getParentCustomer(data);
    })
  }

  getParentCustomer(companyId) {
    this.customerService.GetDropdownCustomerHierarchyModel(companyId).subscribe(
      data => {
        this.data = data;
        this.customers = this.data.dataList;
      });
  }

  ErrorModal(title:string, message:string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }

  onParentCustomerChange(value: any) {
    this._newItemEvent.emit(value.value);
  }

}
