<section class="content-section-1 pt-2">
    <div class="loading" *ngIf="loader">Loading&#8230;</div>


    <div col-sm-12>
        <div class="row">
            <div class="col-6 mb-2 d-flex align-items-baseline text-xs-left">
                <div class="page-title">
                    <div class="bread-crumb">
                        <span class="mx-1">-</span>
                        <span class="active">Create Pick List</span>
                        <div *ngIf="permissionMessage!=''" class='btn-danger text-center'>{{permissionMessage}} </div>
                    </div>
                </div>
            </div>
            <div class="col-6 mb-2 text-right align-items-baseline">
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="white-box1">
                <div class="card-desc1">
                    <form [formGroup]="formSearch">
                        <div class="row detailSection">
                            <!-- <div class="col-md-3">
                                <div class="row mt-3">
                                    <div class="col-lg-3 col-md-2 trp">
                                        <label class="customLabel">Company</label>
                                    </div>
                                    <div class="col-lg-9 col-md-10">
                                        <ngx-dropdown-treeview-select [config]="config"
                                            class="dropdown-company customInput-dropdown"
                                            [items]="userGroupObjects_search" [(value)]="value_search"
                                            [ngClass]="((value_search==0) && (SearchValidation)) ? 'validation_border': ''">
                                        </ngx-dropdown-treeview-select>


                                    </div>
                                </div>
                            </div> -->
                            <div class="col-md-3">
                                <div class="row mt-3">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Pick List For</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <select class="customInput pl-1px" required formControlName="pickListFor"
                                            [(ngModel)]="pickListFor"
                                            [ngClass]="((!pickListFor) && (SearchValidation)) ? 'validation_border': ''"
                                            (change)="ChangePick($event.target.value)">
                                            <option value=''>Select Pick List</option>
                                            <option *ngFor="let item of PickListForList" [value]="item.name">
                                                {{item.name}}
                                            </option>
                                        </select>

                                    </div>
                                </div>
                            </div>
                            <div class="col-md-3">
                                <div class="row mt-3">
                                    <div class="col-lg-3 col-md-2 trp">
                                        <label class="customLabel">Order No</label>
                                    </div>
                                    <div class="col-lg-9 col-md-10 d-flex align-items-center">
                                        <input type="text" class="customInput" [(ngModel)]="orderNo"
                                            placeholder="Search Order No" formControlName='orderNo' autocomplete="off"
                                            [ngClass]="((!orderNo) && (SearchValidation)) ? 'validation_border': ''"
                                            (keyup.enter)="searchPickList()">
                                        <i class="fas fa-search cpointer ml-3 mr-4 cpointer"
                                            (click)="searchPickList()"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>


    <div class="white-box1">

        <div class="white-box1" *ngIf="rolePermissionsList && rolePermissionsList[0].isView"
            [style.display]="ListShow==true ? 'block' : 'none'">
            <div class="white-box-title1">
                <div class="customHeading mt-3 mb-2">
                    Pick List
                    <i class="fa fa-angle-{{ListAngle}} cpointer ml-2" aria-hidden="true"
                        (click)="ListDivShowHide()"></i>
                </div>
            </div>
            <div class="card-desc1 divScroll" [style.display]="ListDiv==true ? 'block' : 'none'">
                <table class="display nowrap table clearfix" id="datatable" datatable [dtOptions]="dtOptions"
                    [dtTrigger]="dtTrigger">
                    <thead>
                        <tr>
                            <th>Company Name</th>
                            <th>Order No</th>
                            <th>Status</th>
                            <th>Customer Name</th>
                            <th>Order Date</th>
                            <th>Part/Product</th>

                            <th data-orderable="false">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let data of pickList; let i = index;">
                            <td>{{data.companyName}}</td>
                            <td>{{data.soNo}}</td>
                            <td>{{data.statusId==1?'Open':data.statusId==2?'Sale Quote
                                ':data.statusId==3?'Cancel':data.statusId==5?'Complete':data.statusId==6 ?'Processing':data.statusId==7 ?'Shipped':data.statusId==8 ?'On Hold':data.statusId==9 ?'In Production':'' }}</td>
                            <!-- <td>{{data.statusId == '1' ? 'Open' : ''}}</td> -->
                            <td>{{data.customerName}}</td>
                            <td>{{data.orderDate |date:"MM/dd/yyyy" }}</td>
                            <td>{{data.orderTypeId == 1 ? 'Product' : 'Part'}}</td>
                            <td>
                                <img *ngIf="data.scolor==false" (click)="SelectedPickIcon(i)"
                                    src="/assets/image/icons-red.png" class="image">
                                <img *ngIf="data.scolor==true" (click)="SelectedPickIcon(i)"
                                    src="/assets/image/icons-green.png" class="image">
                            </td>
                        </tr>
                    </tbody>
                    <tbody *ngIf="pickList == null">
                        <tr class="c1">
                            <td colspan="13" class="no-data-available">No Pick List Avilable</td>
                        </tr>
                    </tbody>
                </table>
                <div class="mt-3"></div>
                <button type="button" class="btn blue-bg round-btn m-0" (click)="PickItems()"
                    style="float: right;">Create Pick List</button>
                <!-- <div class="alert alert-success mt-2 custom" role="alert" *ngIf="IsUpdating==true">
                    {{UpdatingMsg}}
                </div> -->
                <div class="mt-1" *ngIf="this.ShowPickList==true">
                    <div class="customHeading mt-3 mb-2">
                        SO - Pick List

                    </div>
                    <table class="display nowrap table clearfix" id="datatable1" datatable [dtOptions]="dtOptions1"
                        [dtTrigger]="dtTrigger1">
                        <thead>
                            <tr>
                                <th>Item #</th>
                                <th>SO#</th>
                                <th>Part/Product</th>
                                <th>Customer Name</th>
                                <th>List Part #</th>
                                <th>Vendor Part #</th>
                                <th>Qty</th>
                                <th>From Location Name</th>
                                <th>From Location</th>
                                <th>To Location Name</th>
                                <th>To Location</th>
                                <th>Misc.</th>
                                <th data-orderable="false">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let data of SpecialPartList; let i = index">
                                <tr [ngClass]="data.isCustomPart==true ? 'Gray' : ''">
                                    <td>{{i + 1}}</td>
                                    <td>{{data.soNo}}</td>
                                    <td>{{data.orderTypeId == 1 ? 'Product' : 'Part'}}</td>
                                    <td>{{data.customerName}}</td>
                                    <td>
                                        <label class="customLabel tooltipWrapss"
                                            style="color: #333;" title={{data.companyPartNumber}}>{{data.companyPartNumber.length <= 25 ? data.companyPartNumber : SortString(data.companyPartNumber)}}
                                            <!-- {{data.companyPartNumber}} -->
                                            <span class="tooltipWrapss tooltip-icon" style="display:inline"><i
                                                    class="fa fa-info-circle"></i></span>
                                            <span class="tooltipss">
                                                List Part Name : {{data.companyPartName}}
                                            </span>
                                        </label>
                                    </td>
                                    <!-- <td>{{data.companyPartName}}</td> -->
                                    <!-- <td>{{data.vendorPartName}}</td> -->
                                    <td>
                                        <label class="customLabel tooltipWrapss"
                                            style="color: #333;"  title={{data.vendorPartNumber}}>
                                            {{data.vendorPartNumber.length <= 25 ? data.vendorPartNumber : SortString(data.vendorPartNumber)}}
                                            <span class="tooltipWrapss tooltip-icon" style="display:inline"><i
                                                    class="fa fa-info-circle"></i></span>
                                            <span class="tooltipss">
                                                Vendor Part Name : {{data.vendorPartName}}
                                            </span>
                                        </label>
                                    </td>
                                    <td>{{data.isCustomPart==false ? data.quantity : ''}}
                                    </td>
                                    <td>{{data.isCustomPart==false ? data.fromLocationName : ''}}</td>
                                    <td>
                                        <label *ngIf="data.isCustomPart==false" class="customLabel tooltipWrapss"
                                            style="color: #333;">{{data.isCustomPart==false ? data.fromLocation : ''}}
                                            <span class="tooltipWrapss tooltip-icon" style="display:inline"><i
                                                    class="fa fa-info-circle"></i></span>
                                            <span class="tooltipss">
                                                {{data.locationLevelDescription}}
                                            </span>
                                        </label>

                                    </td>
                                    <td>
                                        <span *ngIf="data.isCustomPart==false">
                                            {{data.orderTypeId == 2 ? data.toLocationName : data.toLocationName1}}
                                        </span>

                                    </td>
                                    <td>
                                        <label *ngIf="data.isCustomPart==false" class="customLabel tooltipWrapss1"
                                            style="color: #333;">{{ data.orderTypeId == "2" ? data.toLocation :
                                            data.toLocation1 }}
                                            <span class="tooltipWrapss1 tooltip-icon" style="display:inline"><i
                                                    class="fa fa-info-circle"></i></span>
                                            <span class="tooltipss1">
                                                {{data.orderTypeId == 2 ? data.locationDesp : data.locationDesp1}}
                                            </span>
                                        </label>

                                    </td>
                                    <td>
                                        <span *ngIf="data.isCustomPart==false"
                                            (click)="OpenSerialModel(data.partInventoryId, data.companyId, data.partId, data.partTracking)"
                                            style="cursor: pointer;color:#1e9ff2; font-weight: bold;">
                                            {{data.partTracking == "1" ? 'Serial # Required' : data.partTracking == "2"
                                            ? 'Lot # Required' : 'No Tracking' }}
                                        </span>

                                    </td>

                                    <td>
                                        <ng-container *ngIf="data.isCustomPart==false">
                                            <img *ngIf="data.scolor==false"
                                                (click)="OpenSerialModels(data.partInventoryId, data.companyId, data.partId, data.partTracking, 1)"
                                                src="/assets/image/icons-red.png" class="image">

                                            <!-- <img *ngIf="data.scolor==false" (click)="CheckPickList(data.partInventoryId, 1)" src="/assets/image/icons-red.png" class="image"> -->

                                        </ng-container>

                                        <ng-container *ngIf="data.isCustomPart==true">
                                            <img *ngIf="data.scolor==false" (click)="CheckPickList(i)"
                                                src="/assets/image/icons-red.png" class="image"
                                                style="visibility: hidden;">

                                        </ng-container>


                                    </td>

                                </tr>
                                <ng-container *ngFor="let datasub of data.PartList; let inner = index">
                                    <tr>
                                        <td>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{i +1}}.{{inner+1}}</td>
                                        <td>{{datasub.soNo}}</td>
                                        <td>{{datasub.orderTypeId == 1 ? 'Product' : 'Part'}}</td>
                                        <td>{{datasub.customerName}}</td>
                                        <td>
                                            <label class="customLabel tooltipWrapss"
                                                style="color: #333;">{{datasub.companyPartNumber}}
                                                <span class="tooltipWrapss tooltip-icon" style="display:inline"><i
                                                        class="fa fa-info-circle"></i></span>
                                                <span class="tooltipss">
                                                    List Part Name : {{datasub.companyPartName}}
                                                </span>
                                            </label>
                                        </td>
                                        <!-- <td>{{datasub.companyPartName}}</td> -->
                                        <!-- <td>{{datasub.vendorPartName}}</td> -->
                                        <td>
                                            <label class="customLabel tooltipWrapss"
                                                style="color: #333;">{{datasub.vendorPartNumber}}
                                                <span class="tooltipWrapss tooltip-icon" style="display:inline"><i
                                                        class="fa fa-info-circle"></i></span>
                                                <span class="tooltipss">
                                                    Vendor Part Name : {{datasub.vendorPartName}}
                                                </span>
                                            </label>
                                        </td>
                                        <td>{{datasub.quantity}}</td>
                                        <td>{{datasub.fromLocationName}}</td>
                                        <td>
                                            <label class="customLabel tooltipWrapss"
                                                style="color: #333;">{{datasub.fromLocation}}
                                                <span class="tooltipWrapss tooltip-icon" style="display:inline"><i
                                                        class="fa fa-info-circle"></i></span>
                                                <span class="tooltipss">
                                                    {{datasub.locationLevelDescription}}
                                                </span>
                                            </label>

                                        </td>
                                        <td>
                                            {{datasub.orderTypeId == 2 ? datasub.toLocationName :
                                            datasub.toLocationName1}}
                                        </td>
                                        <td>
                                            <label class="customLabel tooltipWrapss1" style="color: #333;">
                                                {{datasub.orderTypeId == 2 ? datasub.toLocation :datasub.toLocation1 }}
                                                <span class="tooltipWrapss1 tooltip-icon" style="display:inline"><i
                                                        class="fa fa-info-circle"></i></span>
                                                <span class="tooltipss1">
                                                    {{datasub.orderTypeId == 2 ? datasub.locationDesp :
                                                    datasub.locationDesp1}}
                                                </span>
                                            </label>

                                        </td>
                                        <td>
                                            <span
                                                (click)="OpenSerialModel(datasub.partInventoryId, datasub.companyId, datasub.partId, datasub.partTracking)"
                                                style="cursor: pointer;color:#1e9ff2; font-weight: bold;">
                                                {{datasub.partTracking == "1" ? 'Serial # Required' :
                                                datasub.partTracking == "2" ? 'Lot # Required' : 'No Tracking' }}
                                            </span>

                                        </td>

                                        <td>

                                            <img *ngIf="datasub.scolor==false"
                                            (click)="OpenSerialModels(datasub.partInventoryId, datasub.companyId, datasub.partId, datasub.partTracking, 2)"
                                                src="/assets/image/icons-red.png" class="image">

                                        </td>
                                    </tr>
                                </ng-container>
                            </ng-container>


                        </tbody>
                        <tbody *ngIf="SpecialPartList.length==0">
                            <tr class="c1">
                                <td colspan="13" class="no-data-available">No Part Avilable</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

        </div>

    </div>
</section>

<div class="modal" id="pickModel" style="z-index:1080">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="ClosepickModel()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>{{PickItem}}</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="ClosepickModel()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="SuccessMsg">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="CloseSuccessMsg()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You</h1>
                    <p style="margin-bottom: 0">Pick Successfully</p>


                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">

                <button type="button" class="btn red-bg round-btn" (click)="CloseSuccessMsg()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="SerialModel">
    <div class="modal-dialog modal-dialog-scrollable" [ngClass]="(this.qtyLabel.length > 15) ? 'heightClass1': 'heightClass'">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading">Update {{openTracking == "1" ? 'Serial #' : 'Lot #'}}</h4>
                <button type="button" class="close btn alert_modal" (click)="CloseSerialModel()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">

                <div class="thank-you-pop">
                    <div class="row mt-1" *ngFor="let data of qtyLabel; let i=index">
                        <div class="col-md-4">
                            <span class="customLabel">Part {{i+1}} :</span>
                        </div>
                        <div class="col-md-7">
                            <input type="text" class="customInput" [disabled]="data.disabled == true"
                                [(ngModel)]="data.serialNo" (blur)="changeValidation(data.id)"
                                [ngClass]="(data.valid == true) ? 'validation_border': ''" />

                        </div>
                        <div class="col-md-4">

                        </div>
                        <div class="col-md-7" style="color:red; 
                            text-align: left;">{{data.message}}</div>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button"
                    (click)="EditDetail(this.openId, this.openTracking, this.openPartId, this.openCompanyId)"
                    class="btn blue-bg round-btn" style="width: 120px;
                padding: 4px 12px;"><i class="fas fa-save" aria-hidden="true"></i>Update</button>
                <button type="button" class="btn green-bg round-btn" (click)="CheckPickList(openId, customVal)"
                    style="width: 100px; padding: 4px 12px;" *ngIf='pickingProp == true'>Pick</button>
                <button type="button" class="btn red-bg round-btn" (click)="CloseSerialModel()" data-dismiss="modal"
                    style="padding: 4px 12px;">Exit</button>

            </div>
        </div>
    </div>
</div>
<div class="modal" id="CheckPick">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="CloseCheckPick()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Confirmation</h1>
                    <p>Are you sure, you want to pick?</p>


                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn green-bg round-btn m-0 mr-1"
                    (click)="SavePickList(SaveIndex);">Yes</button>
                <button type="button" class="btn red-bg round-btn" (click)="CloseCheckPick()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>

    </div>
</div>

<div class="modal" id="Update_Success">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="Update_SuccessClose()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You</h1>
                    <p style="margin-bottom: 0">{{openTracking == "1" ? "Serial #" : "Lot #"}} Update Successfully</p>


                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">

                <button type="button" class="btn red-bg round-btn" (click)="Update_SuccessClose()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="wrong_msg">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="msgitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>Something went wrong</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="msgitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>