<!--Staging -->
<!-- <div class="container" style="margin-top:90px;max-width:1252px"> 
    <iframe title="911Vehicle-TRPT_BI_reports_v6" width="1550" height="750" src="https://app.powerbi.com/reportEmbed?reportId=41f28a2e-6500-4818-ba74-9150f70b941c&autoAuth=true&ctid=116a7504-a959-4b83-871f-7084cf3b4ee9" frameborder="0" allowFullScreen="true"></iframe>
</div> -->

<!--Production -->
<!-- <div class="container" style="margin-top:90px;max-width:1252px"> 
    <iframe title="911Vehicle-TRPT_BI_reports_v6" width="1550" height="750" src="https://app.powerbi.com/reportEmbed?reportId=70275134-5ed7-466d-989c-12b9a5bd3c66&autoAuth=true&ctid=116a7504-a959-4b83-871f-7084cf3b4ee9" frameborder="0" allowFullScreen="true"></iframe>
</div> -->
<link rel="stylesheet" href="https://unpkg.com/ngx-bootstrap/datepicker/bs-datepicker.css">
<section class="r_report content-section">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-2">
                <form [formGroup]="reportFormforSO">
                    <div class="left-img">
                        <!-- <img src="http://localhost:4200/assets/image/logo.jpg" alt=""> -->
                        <h5 class="mt-2">mark@911vehicle.com</h5>
                        <h5>911vehicle.com</h5>
                    </div>
                    <div class="p-2 blue-txt text-center shadow">
                        <p>Total Profit</p>
                        <p style="font-weight: bold">{{(this.totalPartProfit==0?'':this.totalPartProfit)|currency}}</p>
                    </div>
                    <!-- <div class="left-gray-box mt-2 shadow">
                        <div class="form-group">
                            <label for="">Company Name</label>
                            <div class="form-control"
                                [ngClass]="((value_search==0) && (SearchValidation)) ? 'validation_border': ''">
                                <ngx-dropdown-treeview-select [config]="config"
                                    class="dropdown-company customInput-dropdown" [items]="userGroupObjects_search"
                                    [(value)]="value_search" (valueChange)="onValueChange_search($event)">
                                </ngx-dropdown-treeview-select>
                            </div>
                        </div>
                    </div> -->

                    <div class="left-gray-box mt-2 shadow">

                        <div class="mb-0">
                            <div class="form-group dflex">

                                <input class="form-control-input" type="radio" name="allDateRange"
                                    checked="checked" formControlName="allDateRange"
                                    (change)="changeAllDateOrder($event)" id=dateRange1 value="">
                                <label class="form-control-label" for="dateRange1">All Order</label>
                            </div>
                            <div class="form-group dflex mb-0">

                                <input class="form-control-input"  type="radio" name="allDateRange"
                                    formControlName="allDateRange" (change)="changeAllDateOrder($event)" id=dateRange
                                    value="allDateRange">
                                <label class="form-control-label" for="dateRange">Order Date Range</label>
                            </div>
                            <div class="d-flex mt-2 flex-column" *ngIf="IsDateRange==true">
                                <input type="date" class="customInput mr-1 " [(ngModel)]="fromDate"
                                    formControlName="fromDate">
                                <input type="date" class="customInput mt-1" [(ngModel)]="toDate"
                                    formControlName="toDate">
                            </div>
                        </div>
                    </div>

                    <div class="left-gray-box mt-2 shadow">
                        <div class="form-group">
                            <label for="">Order Status</label>
                            <select class="form-control" name='statusId' id="statusvalues" [(ngModel)]="statusIdVal"
                                formControlName="statusIdVal" (change)="statusvalues($event)">
                                <!-- <option value=0>Select Status</option> -->
                                <option *ngFor="let item of ShippingStatus" [value]="item.id">
                                    {{item.name}}
                                </option>
                            </select>
                        </div>
                    </div>

                    <div class="left-gray-box mt-2 shadow">
                        <button class="form-control" (click)="GetSalesOrderReportData()">Search <span> <i
                                    class="fas fa-search cpointer"></i></span> </button>
                    </div>
                    <!-- <div class="left-gray-box mt-2 shadow ">
                    <div class="form-control">  
                        <button class="ml-autoBtn" >PDF</button>
                        <button  class="ml-autoBtn">Print</button> 
                        <button class="ml-autoBtn" >Excel</button> 
                    </div>
                  </div>  -->
                    <!-- <div class="left-gray-box mt-2 shadow">
                        <div class="form-group">
                            <label for="">So Prefix</label>
                            <select class="form-control" id="">
                              <option>ALL</option>
                              <option>2</option>
                              <option>3</option>
                              <option>4</option>
                              <option>5</option>
                            </select>
                          </div>
                     </div> -->
                </form>
            </div>

            <div class="col-md-10 right-blk">
                <div class="row three-map-blk">
                    <div class="col-md-4">
                        <div class="map-box-custom shadow">
                            <h4>Labor Revenue and Part Revenue</h4>
                           
                        </div>

                    </div>
                    <div class="col-md-4">
                        <div class="map-box-custom shadow">
                            <h4>GrossProfit</h4>
                        </div>
                    </div>
                    <div class="col-md-4">
                        <div class="map-box-custom shadow">
                            <h4>Count Of</h4>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <div class="shadow">
                            <div class="table-report">
                                <h2 class="headingNew">Sales Order Report</h2>
                                <table id="datatableSOReport" cellspacing="0" width="100%"
                                    class="display table table-responsive clearfix" datatable [dtOptions]="dtOptions"
                                    [dtTrigger]="dtTrigger">
                                    <!-- <table id="datatableSOReport"  cellspacing="0" width="auto" class="display table table-responsive clearfix"> -->


                                    <thead>
                                        <tr>
                                          <th hidden></th>
                                            <!-- <th (click)="SortTableHeader('id')">SO #<span id="id"
                                                    class="sorting1"></span></th> -->
                                            <!-- <th (click)="SortTableHeader('prefixYear')">SO Prefix<span id="prefixYear"
                                                    class="sorting1"></span></th> -->
                                                    <th (click)="SortTableHeader('prefixYear')">SO#<span id="prefixYear"
                                                        class="sorting1"></span></th>
                                            <th (click)="SortTableHeader('laborPriceExt')">Labor Revenue<span
                                                    id="laborPriceExt" class="sorting1"></span></th>
                                            <th (click)="SortTableHeader('laborCostExt')">Labor Cost<span
                                                    id="laborCostExt" class="sorting1"></span></th>
                                            <th (click)="SortTableHeader('partPriceExt')">Parts Revenue <span
                                                    id="partPriceExt" class="sorting1"></span></th>
                                            <th (click)="SortTableHeader('partCostExt')">Parts Cost<span
                                                    id="partCostExt" class="sorting1"></span></th>
                                            <th (click)="SortTableHeader('laborProfit')">Labor Profit <span
                                                    id="laborProfit" class="sorting1"></span> </th>
                                            <th (click)="SortTableHeader('laborProfitMarkUp')">Labor Margin<span
                                                    id="laborProfitMarkUp" class="sorting1"></span></th>
                                            <th (click)="SortTableHeader('partProfit')">Parts Profit<span
                                                    id="partProfit" class="sorting1"></span></th>
                                            <th (click)="SortTableHeader('partProfitMarkUp')">Parts Margin<span
                                                    id="partProfitMarkUp" class="sorting1"></span></th>
                                            <th>Total Revenue<span id="TotalRevenue" class="sorting1"></span></th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="orderList?.length != 0">
                                        <tr *ngFor="let datalist of orderList">
                                            <td hidden></td>
                                            <!-- <td class="double-border">{{datalist.id}}</td>                                        -->
                                            <td class="double-border">{{datalist.prefixYear}}-{{datalist.id}}</td>
                                            <td class="double-border">{{datalist.laborPriceExt==0?'':datalist.laborPriceExt|currency}} <span
                                                    class="text-right"></span> </td>
                                            <td class="double-border">{{datalist.laborCostExt==0?'':datalist.laborCostExt|currency}} <span
                                                    class="text-right"></span></td>
                                            <td class="double-border">{{datalist.partPriceExt==0?'':datalist.partPriceExt|currency}} <span
                                                    class="text-right"></span></td>
                                            <td class="double-border">{{datalist.partCostExt==0?'':datalist.partCostExt|currency}} <span
                                                    class="text-right"></span></td>
                                            <td class="double-border">{{(datalist.laborProfit==0?'':datalist.laborProfit)|currency}} <span
                                                    class="text-right"></span></td>
                                            <td class="double-border">{{(datalist.laborProfitMarkUp==0?'':datalist.laborProfitMarkUp)|currency}} <span
                                                    class="text-right"></span></td>
                                            <td class="double-border">{{(datalist.partProfit==0?'':datalist.partProfit)|currency}} <span
                                                    class="text-right"></span></td>
                                            <td class="double-border">{{(datalist.partProfitMarkUp==0?'':datalist.partProfitMarkUp)|currency}} <span
                                                    class="text-right"></span></td>
                                            <td class="double-border">{{(datalist.partPriceExt==0?'':datalist.partPriceExt +
                                                datalist.laborPriceExt)|currency}} <span class="text-right"></span>
                                            </td>

                                        </tr>
                                    </tbody>
                                    <tbody>
                                        <tr>
                                            <td><span style="font-weight:bold">Total:</span></td>
                                           
                                            <td class="double-border text-right"><span
                                                    style="font-weight:bold">{{(totalLaborPriceExt==0?'':totalLaborPriceExt)|currency}}</span>
                                            </td>
                                            <td class="double-border text-right"><span
                                                    style="font-weight:bold">{{(totalLaborCostExt==0?'':totalLaborCostExt)|currency}}</span>
                                            </td>
                                            <td class="double-border text-right"><span
                                                    style="font-weight:bold">{{(totalPartPriceExt==0?'':totalPartPriceExt)|currency}}</span>
                                            </td>
                                            <td class="double-border text-right"><span
                                                    style="font-weight:bold">{{(totalPartCostExt==0?'':totalPartCostExt)|currency}}</span>
                                            </td>
                                            <td class="double-border text-right"><span
                                                    style="font-weight:bold">{{(totalLaborProfit==0?'':totalLaborProfit)|currency}}</span>
                                            </td>
                                            <td class="double-border text-right"><span
                                                    style="font-weight:bold">{{(totalLaborProfitMarkUp==0?'':totalLaborProfitMarkUp)|currency}}</span>
                                            </td>
                                            <td class="double-border text-right"><span
                                                    style="font-weight:bold">{{(totalPartProfit==0?'':totalPartProfit)|currency}}</span>
                                            </td>
                                            <td class="double-border text-right"><span
                                                    style="font-weight:bold">{{(totalPartProfitMarkUp==0?'':totalPartProfitMarkUp)|currency}}</span>
                                            </td>
                                            <td class="double-border text-right"><span
                                                    style="font-weight:bold">{{(totalRevenue==0?'':totalRevenue)|currency}}</span>
                                            </td>
                                        </tr>
                                    </tbody>

                                    <tbody *ngIf="orderList==null">
                                        <tr class="c1">
                                            <td colspan="11" class="no-data-available">No data available in table</td>
                                        </tr>
                                    </tbody>

                                </table>
                            </div>
                            <!-- <div class="d-flex align-items-center">
                             <div class="dataTables_info" id="datatable_info" role="status" aria-live="polite">Showing 1 to 10 of 22 entries</div>
                             <nav aria-label="Page navigation" class="ml-auto">
                                <ul class="pagination">
                                  <li class="page-item"><a class="page-link" href="#">Previous</a></li>
                                  <li class="page-item"><a class="page-link" href="#">1</a></li>
                                  <li class="page-item"><a class="page-link" href="#">2</a></li>
                                  <li class="page-item"><a class="page-link" href="#">3</a></li>
                                  <li class="page-item"><a class="page-link" href="#">Next</a></li>
                                </ul>
                              </nav>
                            </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>