import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalChangeService } from '../services/global-change.service';
import { SharedService } from '../services/shared.service';
import { ConstantsService } from '../constants.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import Swal from 'sweetalert2';
import { MatDialog } from '@angular/material/dialog';
import { EmailTemplateComponent } from '../admin/email-template/email-template.component';

@Component({
  selector: 'app-email-config-detail',
  templateUrl: './email-config-detail.component.html',
  styleUrls: ['./email-config-detail.component.css']
})
export class EmailConfigDetailComponent implements OnInit {
  loader: boolean = false;
  userId: any = '';
  companyId: number = 0;
  masterForm: FormGroup;
  hide1: boolean = true;
  hide2: boolean = true;
  triggerList: any = this.constants.TriggerItems;

  constructor(
    private formBuilder: FormBuilder,
    private constants: ConstantsService,
    private sharedService: SharedService,
    private globalChangeService: GlobalChangeService,
    public dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.userId = localStorage.getItem('userId');
    this.createMasterForm();
  }

  ngOnInit(): void {
    const id = this.route.snapshot.params['id'];
    //this.pdfId = id;
    this.companyId = this.globalChangeService.getGlobalCompany();
    if (this.companyId > 0) {
      if (id > 0) {
        this.getbyId(Number(id));
      }
    } else {
      this.popupModal(this.constants.APIError, this.constants.SelectCompany);
    }

    //on change function 
    this.globalChangeService.dropdownChange$.subscribe((data) => {
      this.companyId = data;
      this.router.navigate(['/email-config-list'])
    })
  }

  backToList() {
    this.router.navigate(['/email-config-list'])
  }

  getbyId(id: number) {
    this.sharedService.GetEmailConfig(id).subscribe(
      (data: any) => {
        if (data.data != null) {
          this.masterForm.patchValue(data.data);
          this.masterForm.patchValue({ selectedItems: JSON.parse(data.data.trig).map(x => x.item_id) });
          this.masterForm.patchValue({ EmailConfigTrigger: JSON.parse(data.data.trig) });
        }
      });
  }

  createMasterForm() {
    this.masterForm = this.formBuilder.group({
      id: new FormControl(0),
      companyId: new FormControl(this.companyId),
      name: new FormControl('', Validators.required),
      senderEmail: new FormControl('', [Validators.required, Validators.email]),
      // appPassword: new FormControl('', Validators.required),
      selectedItems: new FormControl([], Validators.required),
      EmailConfigTrigger: new FormControl([]),
      password: new FormControl('', Validators.required),
      smtp: new FormControl('', Validators.required),
      port: new FormControl('', Validators.required),
    });
  }

  Cancel() {

  }

  SendTestEmail() {
    const obj = {
      type: 0,
      docId: 0,
      docNo: 0,
      comId: this.companyId,
      venName: '',
      //files: this.poSavedObj.data.file,
      files: [],
      from: this.masterForm.value.senderEmail,
      to: '',
      password: this.masterForm.value.password,
      //appPassword: this.masterForm.value.appPassword,
      smtp: this.masterForm.value.smtp,
      port: this.masterForm.value.port,
    }
    const dialogRef = this.dialog.open(EmailTemplateComponent, {
      data: obj,
      height: '800px',
      width: '750px'
    })
    dialogRef.afterClosed().subscribe(result => {
      if (result.success) {
        if (result.msg) {
          this.popupModal(this.constants.Success, this.constants.EmailSentMessage);
        } else {
          this.popupModal(this.constants.Success, this.constants.EmailSentMessage);
        }
      } else {
        if (result.msg != '') {
          this.popupModal(this.constants.APIError, result.msg);
        }
      }
    });
  }

  AddTriggerObj() {
    this.masterForm.value.EmailConfigTrigger = [];
    const selected = this.masterForm.value.selectedItems;
    if (selected.length > 0) {
      selected.forEach(element => {
        this.masterForm.value.EmailConfigTrigger.push({
          Id: 0,
          IsActive: true,
          item_id: element,
          item_text: this.triggerList.length > 0 ? this.triggerList.filter(x => x.item_id == element)[0].item_text : '',
          CreatedBy: localStorage.getItem('userId'),
          CompanyId: this.companyId,
          CreatedDate: new Date()
        })
      });
    }
    return this.masterForm.value.EmailConfigTrigger;
  }

  UpdateTriggerObj() {
    const oldSelected = this.masterForm.value.EmailConfigTrigger;
    this.masterForm.value.EmailConfigTrigger = [];
    const selected = this.masterForm.value.selectedItems;
    if (selected.length > 0) {
      selected.forEach(x => {
        if (oldSelected.map(a=> a.item_id).includes(x)) {
          oldSelected.forEach(y => {
            if (y.Id > 0 && x == y.item_id) {
              this.masterForm.value.EmailConfigTrigger.push({
                Id: y.Id,
                IsActive: true,
                item_id: y.item_id,
                item_text: this.triggerList.length > 0 ? this.triggerList.filter(m => m.item_id == y.item_id)[0].item_text : '',
                ModifiedBy: localStorage.getItem('userId'),
                CompanyId: this.companyId,
                ModifiedDate: new Date()
              });
            }
            else {
              this.masterForm.value.EmailConfigTrigger.push({
                Id: y.Id,
                IsActive: false,
                item_id: y.item_id,
                item_text: this.triggerList.length > 0 ? this.triggerList.filter(x => x.item_id == y.item_id)[0].item_text : '',
                ModifiedBy: localStorage.getItem('userId'),
                CompanyId: this.companyId,
                ModifiedDate: new Date()
              });
            }

          });
        } else {
          this.masterForm.value.EmailConfigTrigger.push({
            Id: 0,
            IsActive: true,
            item_id: x,
            item_text: this.triggerList.length > 0 ? this.triggerList.filter(n => n.item_id == x)[0].item_text : '',
            CreatedBy: localStorage.getItem('userId'),
            CompanyId: this.companyId,
            CreatedDate: new Date()
          });
        }

      })
    }
    return this.masterForm.value.EmailConfigTrigger;
  }

  Delete() {
    this.sharedService.deleteEmailConfig(this.masterForm.value.id).subscribe(
      (data: any) => {
        switch (data.statusCode) {
          case 200:
            this.popupModal("Message", this.constants.RecordDeletedSuccessfully);
            this.router.navigate(['/email-config-list'])
            break;
          case 400:
            this.popupModal("Message", this.constants.SomethingWentWrong);
            break;
          default:
            this.popupModal("Message", this.constants.SomethingWentWrong);
        }
      });
  }

  AddUpdate() {
    let obj = this.masterForm.getRawValue();

    if (obj.id > 0) {
      obj.EmailConfigTrigger = this.UpdateTriggerObj();
      this.sharedService.UpdateEmailConfig(obj)
        .subscribe(
          data => {
            switch (data.statusCode) {
              case 200:
                this.popupModal(this.constants.Success, this.constants.RecordUpdateSuccessfully);
                break;
              case 400:
                this.popupModal(this.constants.Error, this.constants.SomethingWentWrong);
                break;
              case 409:
                this.popupModal(this.constants.Error, this.constants.TriggerExist);
                break;
              case 208:
                this.popupModal(this.constants.Error, this.constants.EmailConfigurationExist);
                break;
              default:
                this.popupModal(this.constants.Error, this.constants.SomethingWentWrong);
            }
            this.getbyId(Number(obj.id));
            this.loader = false;
          },
          error => {
            this.loader = false;
            this.popupModal(this.constants.APIError, error);
          }
        );
    } else {
      obj.companyId = this.companyId;
      obj.EmailConfigTrigger = this.AddTriggerObj();
      this.sharedService.AddEmailConfig(obj)
        .subscribe(
          data => {
            switch (data.statusCode) {
              case 200:
                this.router.navigate(['/email-config-detail', Number(data.data)]);
                this.popupModal(this.constants.Success, this.constants.RecordAddedSuccessfully);
                break;
              case 400:
                this.popupModal(this.constants.Error, this.constants.SomethingWentWrong);
                break;
              case 406:
                this.popupModal(this.constants.Error, this.constants.TriggerExist);
                break;
              case 208:
                this.popupModal(this.constants.Error, this.constants.EmailConfigurationExist);
                break;
              default:
                this.popupModal(this.constants.Error, this.constants.SomethingWentWrong);
            }
            this.loader = false;
          },
          error => {
            this.loader = false;
            this.popupModal(this.constants.APIError, error);
          }
        );
    }
  }

  popupModal(title: string, message: string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }
}
