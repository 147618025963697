import { FlatTreeControl } from '@angular/cdk/tree';
import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { SharedService } from '../../services/shared.service';
import { CompanyService } from '../../services/company/company.service';
import { CustomerService } from '../../services/customer/customer.service';
import { ConstantsService } from '../../constants.service';
import { GlobalChangeService } from '../../services/global-change.service';
import Swal from 'sweetalert2';
import { Company, ExampleFlatNode } from 'src/app/CommonInterface';
import { VendorService } from 'src/app/services/vendor/vendor.service';
import { PartService } from 'src/app/services/part/part.service';

@Component({
  selector: 'app-company-details',
  templateUrl: './company-details.component.html',
  styleUrls: ['./company-details.component.css']
})
export class CompanyDetailsComponent implements OnInit {
  size: any;
  width: number;
  height: number;
  imageError: boolean;
  imgError: string;

  constructor(
    public sharedService: SharedService,
    public companyService: CompanyService,
    public customerService: CustomerService,
    private constants: ConstantsService,
    private router: Router,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private globalChangeService: GlobalChangeService,
    private sanitizer: DomSanitizer,
    public vendorService: VendorService,
    private partService: PartService,
  ) {
  }

  loader: boolean = false;
  CompanyForm: FormGroup;
  CompanyWareHouseForm: FormGroup;
  BusinessChaneelsForm: FormGroup;
  CustomerRolesForm: FormGroup;
  companyDetailsList: Company[] = [];
  hierarchyLevelData: any = [];
  countrList: any = [];
  stateData: any = [];
  WareHouseStateData: any = [];
  companyId: any;
  selectedCompanySearch: string = 'all';
  selectedCompanyText: string = 'Search by Company Name and Address';
  rolePermissionsList: any;
  permissionMessage = '';
  DefaultLogoPDF: any;
  IsAllCompany: boolean = false;
  SelectedCompanyValue: any;
  CompanyObj: Company[] = [];
  public files: any[] = [];
  submitted: boolean = false;
  CompanyDetail: any;
  defaultImageLogo: any;
  imageUrl: any;
  filter: string = 'Active';
  FobPoint: any = [];
  shippingTerms: any = [];
  displayedColumn: string[] = ['menu', 'item', 'companyName', 'address', 'parent', 'status', 'lastModified', 'modifiedBy'];
  StatusList = [
    { type: 'Active', value: true },
    { type: 'In-Active', value: false }
  ];
  private _transformer = (node: Company, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      id: node.id,
      companyName: node.companyName,
      addressLine2: node.addressLine2,
      addressLine1: node.addressLine1,
      parentId: node.parentId,
      itemNo: node.itemNo,
      isActive: node.isActive,
      modifiedDate: node.modifiedDate,
      modifiedBy: node.modifiedBy,
      parentCompanyName: node.parentCompanyName,
      level: level,
    };
  };
  treeControl = new FlatTreeControl<ExampleFlatNode>(
    node => node.level,
    node => node.expandable,
  );
  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children,
  );
  companyDataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
  hasChild = (_: number, node: ExampleFlatNode) => node.expandable;
  ngOnInit(): void {
    this.companyId = localStorage.getItem(this.constants.companyId);
    this.defaultImageLogo = this.constants.defaultImageLogo;
    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);
    } else {
      this.rolePermissionsList = JSON.parse(localStorage.getItem('rolePermissionsList'));
      this.rolePermissionsList = this.rolePermissionsList.filter(x => x.pageId == 2);
      this.permissionMessage = '';
      if (this.rolePermissionsList != null && this.rolePermissionsList != undefined) {
        this.rolePermissions()
      }
      if (this.permissionMessage != '') {
        this.permissionMessage = 'You do not have access to ' + this.permissionMessage + '. Please contact to administrator!';
      }
      this.companyId = localStorage.getItem(this.constants.companyId);
      this.DefaultLogoPDF = "/assets/image/PDFFile1.png";
      this.createForm()
      this.getCompanyHierarchyLevel();
      this.GetAllCountryDetails();
      this.getAllState(231);
      this.GetAllCompany(0);
      this.IsAllCompany = false;
      this.getAllShippings();
      this.getFOBPointList();
    }
  }
  // All form Creation
  createForm() {
    this.createCompanyForm();
    this.CreateCompanyWareHouseForm();
    this.CreateBusinessChaneelsForm();
    this.CreateCustomerRolesForm();
  }
  // Form defination start
  createCompanyForm() {
    this.CompanyForm = this.fb.group({
      'id': new FormControl(0),
      'companyName': new FormControl('', Validators.compose([Validators.required, Validators.minLength(1)])),
      'addressLine1': new FormControl(''),
      'addressLine2': new FormControl(),
      'city': new FormControl(''),
      'zipCode': new FormControl('', [Validators.minLength(5), Validators.maxLength(10)]),
      'contactEmail': new FormControl('', [Validators.email, Validators.pattern('^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,4}$')]),
      'phoneNumber': new FormControl('', [Validators.minLength(11)]),
      'countryId': new FormControl(231),
      'stateId': new FormControl(0),
      'isActive': new FormControl(false),
      'companyDomain': new FormControl(''),
      'compContactName': new FormControl(''),
      'compContactNumber': new FormControl('', [Validators.minLength(11)]),
      'businessChannels': new FormControl(''),
      'customerRoles': new FormControl(''),
      'defQuoteDays': new FormControl(0),
      'fobPoint': new FormControl(0),
      'shippingTerm': new FormControl(0),
      'parentId': new FormControl(this.SelectedCompanyValue),
      'CreatedBy': new FormControl(localStorage.getItem('userId'))
    });
  }
  CreateCompanyWareHouseForm() {
    this.CompanyWareHouseForm = this.fb.group({
      'id': new FormControl(0),
      'companyId': new FormControl(0),
      'name': new FormControl(''),
      'address1': new FormControl(''),
      'address2': new FormControl(),
      'city': new FormControl(''),
      'zipCode': new FormControl('', [Validators.minLength(5), Validators.maxLength(10)]),
      'countryId': new FormControl(231),
      'stateId': new FormControl(0),
      'isActive': new FormControl(false),
    });
  }
  CreateBusinessChaneelsForm() {
    this.BusinessChaneelsForm = this.fb.group({
      Channels: this.fb.array([])
    });
  }
  CreateCustomerRolesForm() {
    this.CustomerRolesForm = this.fb.group({
      Roles: this.fb.array([])
    });
  }
  // form Defination End
  get CompanyformError() {
    return this.CompanyForm.controls;
  }
  get CompanyWareHouseFormError() {
    return this.CompanyWareHouseForm.controls;
  }
  get Channels() {
    return (<FormArray>this.BusinessChaneelsForm.get('Channels'));
  }
  get Roles() {
    return (<FormArray>this.CustomerRolesForm.get('Roles'));
  }
  createChannels() {
    return this.fb.group({
      channelName: '',
      status: false
    });
  }
  createRoles() {
    return this.fb.group({
      rolesName: '',
      chanels: '',
      status: false
    });
  }
  AddBusinessChannels() {
    this.Channels.push(this.createChannels());
  }
  RemoveBusinessChannels(index: number) {
    this.Channels.removeAt(index);
  }
  AddCustomerRoles() {
    this.Roles.push(this.createRoles());
  }
  RemoveCustomerRoles(index: number) {
    this.Roles.removeAt(index);
  }
  SearchCompany(value: any) {
    this.selectedCompanySearch = value.value;
    if (this.selectedCompanySearch == 'companyName') {
      this.selectedCompanyText = 'Search by company Name';
    } else if (this.selectedCompanySearch == 'addressLine1') {
      this.selectedCompanyText = 'Search by Address';
    } else {
      this.selectedCompanyText = 'Search by Company Name and Address';
    }
  }
  CompanyFilterByName(filter: any) {
    if (filter.target.value != '') {
      if (this.selectedCompanySearch.trim() == 'companyName') {
        this.companyDataSource.data = this.companyDetailsList.filter(x => x.companyName != null ? x.companyName.trim().toUpperCase().includes(filter.target.value.trim().toUpperCase()) : '')
      } else if (this.selectedCompanySearch.trim() == 'addressLine1') {
        this.companyDataSource.data = this.companyDetailsList.filter(x => x.addressLine1 != null ? x.addressLine1.trim().toUpperCase().includes(filter.target.value.trim().toUpperCase()) : '')
      } else {
        this.companyDataSource.data = this.companyDetailsList.filter(x => (x.companyName != null ? x.addressLine1.trim().toUpperCase().includes(filter.target.value.trim().toUpperCase()) || (x.companyName != null ? x.companyName.trim().toUpperCase().includes(filter.target.value.trim().toUpperCase()) : '') : ''))
      }
    } else {
      this.companyDataSource.data = this.companyDetailsList;
    }
  }
  ChanegeCompanyStatus(value: any) {
    if (value.value) {
      this.setRequired();
    } else {
      this.unsetRequired();
    }
  }
  setRequired() {
    this.CompanyWareHouseFormError.name.setValidators(Validators.compose([Validators.required, Validators.minLength(1)]));
    this.CompanyWareHouseFormError.address1.setValidators(Validators.required);
    this.CompanyWareHouseFormError.city.setValidators(Validators.required);
    this.CompanyWareHouseFormError.zipCode.setValidators([Validators.required, Validators.minLength(5), Validators.maxLength(10)]);
    this.CompanyWareHouseFormError.stateId.setValidators(Validators.required);
    this.CompanyWareHouseFormError.isActive.setValidators(Validators.required);
    this.CompanyWareHouseFormError.name.updateValueAndValidity();
    this.CompanyWareHouseFormError.address1.updateValueAndValidity();
    this.CompanyWareHouseFormError.city.updateValueAndValidity();
    this.CompanyWareHouseFormError.stateId.updateValueAndValidity();
    this.CompanyWareHouseFormError.isActive.updateValueAndValidity();
  }

  unsetRequired() {
    this.CompanyWareHouseFormError.name.setValidators(null);
    this.CompanyWareHouseFormError.address1.setValidators(null);
    this.CompanyWareHouseFormError.city.setValidators(null);
    this.CompanyWareHouseFormError.zipCode.setValidators(null);
    this.CompanyWareHouseFormError.stateId.setValidators(null);
    this.CompanyWareHouseFormError.isActive.setValidators(null);
    this.CompanyWareHouseFormError.name.updateValueAndValidity();
    this.CompanyWareHouseFormError.address1.updateValueAndValidity();
    this.CompanyWareHouseFormError.city.updateValueAndValidity();
    this.CompanyWareHouseFormError.zipCode.updateValueAndValidity();
    this.CompanyWareHouseFormError.stateId.updateValueAndValidity();
    this.CompanyWareHouseFormError.isActive.updateValueAndValidity();
  }
  RemoveMaskValidation(type: string) {
    if (type == 'W') {
      if (this.CompanyWareHouseForm.value.zipCode.length > 4) {
        this.CompanyWareHouseFormError.zipCode.setValidators(null);
        this.CompanyWareHouseFormError.zipCode.updateValueAndValidity();
      } else {
        this.CompanyWareHouseFormError.zipCode.setErrors({ requiredMask: "00000" });
      }
    }
    else if (type == 'C') {
      if (this.CompanyForm.value.zipCode.length > 4) {
        this.CompanyformError.zipCode.setValidators(null);
        this.CompanyformError.zipCode.updateValueAndValidity();
      } else {
        this.CompanyformError.zipCode.setErrors({ requiredMask: "00000" });
      }
    }
  }
  companyByStatus(value: any) {
    this.filter = value.value;
    if (value.value == 'All') {
      this.companyDataSource.data = this.companyDetailsList;
    } else {
      this.companyDataSource.data = this.companyDetailsList.filter(y => y.isActive == value.value);
    }
  }
  getCompanyHierarchyLevel() {
    this.companyService.getCompanyHierarchyLevel()
      .subscribe({
        next: (data: any) => {
          this.hierarchyLevelData = data.dataList;
        },
        error: (err) => {
          this.loader = false;
          this.SwalModal('Exception', err);
        },
      });
  }
  GetAllCountryDetails() {
    this.sharedService.GetCountry()
      .subscribe({
        next: (data: any) => {
          this.countrList = data.dataList;
        },
        error: (err) => {
          this.loader = false;
          this.SwalModal('Exception', err);
        },
      });
  }
  getAllState(countryId) {
    this.sharedService.getAllState(countryId)
      .subscribe({
        next: (data: any) => {
          this.stateData = data.dataList;
          this.WareHouseStateData = data.dataList;
        },
        error: (err) => {
          this.loader = false;
          this.SwalModal('Exception', err);
        },
      });
  }
  getAllWareHouseState(countryId) {
    this.sharedService.getAllState(countryId)
      .subscribe({
        next: (data: any) => {
          this.WareHouseStateData = data.dataList;
        },
        error: (err) => {
          this.loader = false;
          this.SwalModal('Exception', err);
        },
      });
  }
  changeCompanyCountry(event) {
    this.getAllState(event.value);
  }
  changeWareHouseCountry(event) {
    this.getAllWareHouseState(event.value);
  }
  GetAllCompany(compId: number) {
    this.loader = true;
    this.companyId = compId;
    this.IsAllCompany = (this.companyId == '0' || this.companyId == null || this.companyId == undefined) ? false : true;
    this.companyService.GetAllCompany(this.companyId)
      .subscribe({
        next: (data: any) => {
          this.companyDetailsList = data.dataList;
          this.companyDataSource.data = this.companyDetailsList.filter(y => y.isActive == 'Active');
          this.loader = false;
        },
        error: (err) => {
          this.SwalModal('Exception', err);
        },
      });
  }
  GetCompanyById(compId: number) {
    this.loader = true;
    this.companyId = compId;
    this.companyService.GetCompanyById(this.companyId)
      .subscribe({
        next: (data: any) => {
          this.CompanyDetail = data.data;
          if (this.CompanyDetail != undefined) {
            this.IsAllCompany = true;
            this.BindCompanyById()
            this.BindCompanyWareHouseById()
            this.loader = false;
          }
        },
        error: (err) => {
          this.SwalModal('Exception', err);
        },
      });
  }
  companyChangeValue(value) {
    this.SelectedCompanyValue = value;
    this.globalChangeService.emitDropdownChange(this.SelectedCompanyValue);
  }
  Cancel() {
    this.submitted = false;
    if (this.companyId != 0) {
      this.BindCompanyById()
      this.BindCompanyWareHouseById()
    } else {
      this.CompanyForm.reset();
      this.CompanyWareHouseForm.reset();
    }
  }
  BackToList() {
    this.Channels.clear();
    this.Roles.clear();
    this.CompanyWareHouseForm.reset();
    this.CompanyForm.reset();
    this.companyId = 0;
    this.IsAllCompany = false;
    this.imageUrl = undefined;
    this.GetAllCompany(this.companyId);
  }
  AddNewCompany() {
    this.CompanyForm.patchValue({
      id: 0,
      countryId: 231,
      stateId: 0,
      isActive: false,
      customerRoles: this.constants.customerRoles,
      businessChannels: this.constants.businessChannels
    })
    this.CompanyWareHouseForm.patchValue({
      id: 0,
      stateId: 0,
      isActive: false,
      companyId: 0,
      countryId: 231
    })
    this.BindBusinessChannels();
    this.IsAllCompany = true;
    this.SelectedCompanyValue = null;
  }
  SaveUpdCompany(CompnayId: number) {
    this.submitted = true;
    if (this.CompanyForm.invalid || this.CompanyWareHouseForm.invalid) {
      return;
    } else {
      this.loader = true;
      this.IsAllCompany = true;
      this.companyId = CompnayId;
      this.CompanyForm.patchValue({ defQuoteDays: Number(this.CompanyForm.value.defQuoteDays == undefined ? 0 : this.CompanyForm.value.defQuoteDays) })
      this.CompanyForm.value.customerRoles = JSON.stringify(this.CustomerRolesForm.value.Roles);
      this.CompanyForm.value.businessChannels = JSON.stringify(this.BusinessChaneelsForm.value.Channels);
      let companyDetailsVM = {
        "company": this.CompanyForm.value,
        "companyWareHouse": this.CompanyWareHouseForm.value,
      }
      this.companyService.InsUpdCompany(companyDetailsVM)
        .subscribe({
          next: (data: any) => {
            if (data.errorMessage == 'Company Already Exists') {
              this.CompanyAPIErrorMsg();
            } else {
              this.UpdCompLogo();
            }
          },
          error: (err) => {
            this.loader = false;
            this.SwalModal('Exception', 'Something Went Wrong.');
          },
        });
    }
  }
  UpdCompLogo() {
    let file = new FormData();
    if (this.files.length > 0) {
      file.append('file', this.files[0], this.files[0].name);
    }
    this.companyService.UpdCompLogo(file, this.companyId)
      .subscribe({
        next: (data: any) => {
          this.submitted = false;
          if (this.companyId == 0) {
            this.BackToList();
          } else {
            this.IsAllCompany = true;
            this.GetCompanyById(this.companyId);
          }
        },
        error: (err) => {
          this.loader = false;
          this.SwalModal('Exception', 'Something Went Wrong.');
        },
      });
  }
  SwalModal(title: string, message: string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonText: 'Close',
    })
  }
  CompanyAPIErrorMsg() {
    this.loader = false;
    Swal.fire({
      text: 'Company already exist.',
      showCancelButton: true,
      showConfirmButton: false,
      cancelButtonText: 'Close',
    })
  }
  onFileChange(pFileList: any) {
    this.files = Object.keys(pFileList).map(key => pFileList[key]);
    let fileToUpload = this.files[0];
    this.size = fileToUpload.size;
    const reader = new FileReader();
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
      const img = new Image();
      img.src = event.target.result;
      this.getDimensions(img);
    };
    reader.readAsDataURL(fileToUpload);
  }

  async getDimensions(img: HTMLImageElement) {
    await img.onload;
    this.width = img.width;
    this.height = img.height;
    if (this.width <= 250 && this.height <= 100) {
      this.imageError = false;
    } else {
      this.imageError = true;
      this.imgError = this.constants.ImageSizeError
    }
  }

  DeleteFile(i: number) {
    this.files.splice(0, (i + 1));
    this.imageUrl = undefined;
  }
  DefaultDeleteFile(i: number) {
    this.imageUrl = undefined;
  }
  ISNullOrEmpty(value: any): boolean {
    let result = false;
    if (value != undefined && value != null && value != '') {
      result = true
    }
    return result;
  }
  BindCompanyById() {
    if (this.CompanyDetail.company != undefined && this.CompanyDetail.company != null) {
      this.SelectedCompanyValue = this.CompanyDetail.company.parentId == null ? this.CompanyDetail.company.id : this.CompanyDetail.company.parentId;
      if (this.ISNullOrEmpty(this.CompanyDetail.company.logoName)) {
        this.imageUrl = this.sanitizer.bypassSecurityTrustUrl(this.CompanyDetail.company.logoName);
      }
      else {
        this.imageUrl = undefined;
      }
      this.CompanyForm.patchValue({
        id: this.CompanyDetail.company.id,
        companyName: this.CompanyDetail.company.companyName,
        addressLine1: this.CompanyDetail.company.addressLine1,
        addressLine2: this.CompanyDetail.company.addressLine2,
        city: this.CompanyDetail.company.city,
        zipCode: this.CompanyDetail.company.zipCode,
        contactEmail: this.CompanyDetail.company.contactEmail,
        phoneNumber: this.CompanyDetail.company.phoneNumber,
        countryId: Number(this.CompanyDetail.company.countryId),
        stateId: Number(this.CompanyDetail.company.stateId),
        isActive: this.CompanyDetail.company.isActive,
        companyDomain: this.CompanyDetail.company.companyDomain,
        compContactName: this.CompanyDetail.company.compContactName,
        compContactNumber: this.CompanyDetail.company.compContactNumber,
        parentId: this.CompanyDetail.company.parentId,
        defQuoteDays: this.CompanyDetail.company.defQuoteDays,
        businessChannels: !this.ISNullOrEmpty(this.CompanyDetail.company.businessChannels) ? this.constants.businessChannels : this.CompanyDetail.company.businessChannels,
        customerRoles: !this.ISNullOrEmpty(this.CompanyDetail.company.customerRoles) ? this.constants.customerRoles : this.CompanyDetail.company.customerRoles,
        shippingTerm: this.CompanyDetail.company.shippingTerm,
        fobPoint: this.CompanyDetail.company.fobPoint
      })
      this.BindBusinessChannels();
    }
  }
  BindCompanyWareHouseById() {
    if (this.CompanyDetail.companyWareHouse != undefined && this.CompanyDetail.companyWareHouse != null) {
      this.CompanyWareHouseForm.patchValue({
        id: this.CompanyDetail.companyWareHouse.id,
        companyId: this.CompanyDetail.companyWareHouse.companyId,
        name: this.CompanyDetail.companyWareHouse.name,
        address1: this.CompanyDetail.companyWareHouse.address1,
        address2: this.CompanyDetail.companyWareHouse.address2,
        city: this.CompanyDetail.companyWareHouse.city,
        zipCode: this.CompanyDetail.companyWareHouse.zipCode,
        countryId: Number(this.CompanyDetail.companyWareHouse.countryId),
        stateId: Number(this.CompanyDetail.companyWareHouse.stateId),
        isActive: this.CompanyDetail.companyWareHouse.isActive,
      })
    }
  }
  BindBusinessChannels() {
    this.Channels.clear();
    JSON.parse(this.CompanyForm.value.businessChannels).forEach(element => {
      this.Channels.push(new FormGroup({
        channelName: new FormControl(element.channelName),
        status: new FormControl(element.status)
      }))
    });
    this.Roles.clear();
    JSON.parse(this.CompanyForm.value.customerRoles).forEach(element => {
      this.Roles.push(new FormGroup({
        chanels: new FormControl(element.chanels),
        rolesName: new FormControl(element.rolesName),
        status: new FormControl(element.status),
      }))
    });
  }
  getAllShippings() {
    this.vendorService.getAllShippings()
      .subscribe({
        next: (data: any) => {
          this.shippingTerms = data.dataList;
        },
        error: (err) => {
          this.SwalModal('Exception', err);
        },
      });
  }
  getFOBPointList() {
    this.partService.FobPointsDropdownList()
      .subscribe({
        next: (data: any) => {
          this.FobPoint = data.dataList;
        },
        error: (err) => {
          this.SwalModal('Exception', err);
        },
      });
  }
  rolePermissions() {
    if (!this.rolePermissionsList[0].isView) {
      this.permissionMessage += 'view';
    } if (!this.rolePermissionsList[0].isInsert) {
      if (this.permissionMessage != '') {
        this.permissionMessage += ', ';
      }
      this.permissionMessage += 'add';
    } if (!this.rolePermissionsList[0].isUpdate) {
      if (this.permissionMessage != '') {
        this.permissionMessage += ', ';
      }
      this.permissionMessage += 'edit';
    } if (!this.rolePermissionsList[0].isDelete) {
      if (this.permissionMessage != '') {
        this.permissionMessage += ', ';
      }
      this.permissionMessage += 'delete';
    }
  }
  // Role Permission End

  // Image Start
  sanitizeImageUrl(imageUrl: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
  }
  // Image End
}
