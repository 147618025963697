<section class="content-section pt-2">
    <div class="loading" *ngIf="loader">Loading&#8230;</div>
    <div class="page-title mb-4">
        <!-- <h2>Order Detail</h2> -->
        <div class="bread-crumb d-flex justify-content-between align-items-baseline">
            <div class="breadWrap">
                <a [routerLink]="['/adminDashboard/']">Home</a>
                <span class="mx-1">-</span>
                <span class="active">Order</span>
            </div>
            <div class="row">
                <div class="col-12" *ngIf="ExportEnable==true">
                    <select class="customInput" (change)="ExportType($event)" id="exportDrodown">
                        <option value="" selected>Select Export Type </option>
                        <option value="1" *ngIf="rolePermissionsList[0].isExportPdf">Export to PDF</option>
                        <option value="2" *ngIf="rolePermissionsList[0].isExportWord">Export to Word</option>
                    </select>
                </div>
            </div>
        </div>
    </div>

    <div class="white-box1 no_bg1" [style.display]="ListShow1==true ? 'block' : 'none'">
        <div class="white-box-title1">
            <div class="row">
                <div class="col-md-12">
                    <div class="customHeading mt-1 mb-1">
                        Order List
                        <span id="expand-collapse">
                            <i class="fa fa-angle-down cpointer" aria-hidden="true" (click)="ListDivShowHide()"></i>
                        </span>

                    </div>
                </div>

                <div class="col-md-7"></div>

            </div>
        </div>

        <div class="card-desc table-responsive mb-3 position-relative" [style.display]="ListDiv1==true ? 'block' : 'none'">

            <!-- <div class="card-desc table-responsive mb-3 position-relative" [style.display]="ListDiv1 == true"> -->
            <!--  -->
            <div class="detailSection">
                <form [formGroup]="formSearch">
                <div class="d-flex">

                    <!-- mat multiple select -->
                    <mat-form-field class="full-width ml-2" appearance="outline">
                        <mat-label>Status</mat-label>
                        <mat-select placeholder="Status" formControlName="IsActive" (selectionChange)="GetIsActiveStatus($event)">
                            <mat-option value="1">Active</mat-option>
                            <mat-option value="0">Inactive</mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="full-width" appearance="outline">
                        <mat-label>Order Status</mat-label>
                        <mat-select placeholder="Order Status" formControlName="SearchStatusValue" (selectionChange)="GetStatus($event)" multiple>
                            <mat-option value="0">All</mat-option>
                            <ng-container *ngIf="IsActive=='1'">
                                <mat-option *ngFor="let item of activestatuslist" [value]="item.id">{{ item.name }}</mat-option>
                            </ng-container>
                            <ng-container *ngIf="IsActive=='0'">
                                <mat-option *ngFor="let item of inactivestatuslist" [value]="item.id">{{ item.name }}</mat-option>
                            </ng-container>
                        </mat-select>
                    </mat-form-field>
                    <!--  -->
                    <i class="fas fa-search ml-3 mr-4 search-icon"
                        (click)="AllStatus()"></i>

                </div>
                </form>
            </div>

            <table id="datatable1" class="display table table-responsive mt-3 clearfix" datatable [dtOptions]="dtOptions2"
                [dtTrigger]="dtTrigger2">
                <thead>
                    <tr>
                        <th style="display: none;"></th>
                        <th (click)="SortTableHeader('companyName','')">Company<span id="companyName"
                                class="sorting1"></span></th>
                        <th (click)="SortTableHeader('customerPONumber','')">Order No.<span id="customerPONumber"
                                class="sorting1"></span></th>
                        <th (click)="SortTableHeader('isEcommOrder','')">eComm<span id="isEcommOrder"
                                class="sorting1"></span></th>
                        <th (click)="SortTableHeader('statusId','')">Status<span id="statusId" class="sorting1"></span>
                        </th>
                        <th (click)="SortTableHeader('customerFirstName','')">Contact Name<span id="customerFirstName"
                                class="sorting1"></span></th>
                        <th (click)="SortTableHeader('customerName','')">Customer Name<span id="customerName"
                                class="sorting1"></span></th>
                        <th (click)="SortTableHeader('partProductName','')">Product Name<span id="partProductName"
                                class="sorting1"></span></th>
                        <th (click)="SortTableHeader('orderDate','')">Order Date<span id="orderDate"
                                class="sorting1"></span></th>
                        <th (click)="SortTableHeader('orderType','')">Part/Product<span id="orderType"
                                class="sorting1"></span></th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody *ngIf="orderList?.length != 0">
                    <tr *ngFor="let data of orderList">
                        <td style="display: none;"></td>
                        <td>{{data.companyName}}</td>
                        <td>{{data.customerPONumber==null?'-------':data.customerPONumber}}</td>
                        <td><span *ngIf="data.isEcommOrder==true"><i class="fa fa-check text-success"
                                    aria-hidden="true"></i></span></td>
                        <td>{{data.statusId==1?'Open':data.statusId==2?'Sale Quote
                            ':data.statusId==3?'Cancel':data.statusId==5?'Complete':data.statusId==6
                            ?'Processing':data.statusId==7 ?'Shipped':data.statusId==8 ?'On Hold':data.statusId==9 ?'In
                            Production':'' }}</td>
                        <td>{{data.customerFirstName}} {{data.customerLastName}}</td>
                        <td>{{data.customerName}}</td>
                        <td>{{data.partProductName}}</td>
                        <td>{{data.orderDate | date: "MMM-dd-yyyy"}}</td>
                        <td>{{data.orderType}}</td>
                        <td>
                            <a *ngIf="rolePermissionsList && rolePermissionsList[0].isView"
                                (click)="PreviewOrder(data.id,data.orderTypeId,'', data.customerId, data.orderType)"
                                class="green-text"><i class="fas fa-edit"></i></a>
                            <a *ngIf="rolePermissionsList && rolePermissionsList[0].isCopy"
                                (click)="CopyOrder(data.id, data.companyId)" class="green-text"><i
                                    class="fas fa-copy"></i></a>
                            <a *ngIf="rolePermissionsList && rolePermissionsList[0].isDelete"
                                (click)="deletePopupProduct(data.id)" class="red-text"><i
                                    class="fas fa-trash-alt"></i></a>
                        </td>
                    </tr>
                </tbody>
                <tbody *ngIf="orderList==null">
                    <tr class="c1">
                        <td colspan="10" class="no-data-available">No data available in table</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-12">
            <div class="white-box no_bg">
                <div class="white-box-title">
                    <div class="row">
                        <div class="col-sm-2">
                            <p class="customLabel"><b class="light-color-text">Product : </b>{{productName}} </p>
                        </div>
                        <div class="col-sm-3">
                            <p class="customLabel"><b class="light-color-text">Customer Name : </b>{{customerName}}</p>
                        </div>
                        <div class="col-sm-3">
                            <span class="position-relative">
                                <p (click)="multiContactData()" class="customLabel tooltipWrap tooltip-icon d-flex">

                                    <span class="tooltipWrap tooltip-icon mr-1">
                                        <i class="fa fa-info-circle"></i></span>
                                    <span class="tooltips">
                                        Email : {{email ? email :
                                        'N/A'}}<br>
                                        Phone : {{contactPhone1 ? contactPhone1 :
                                        'N/A'}}<br>
                                        Address : {{address1 ? address1 :
                                        'N/A'}}<br>
                                    </span>

                                    <b class="light-color-text">Contact Name : </b><span
                                        class="aLink">{{contactNameSO}}</span>
                                </p>

                            </span>
                        </div>
                        <div class="col-sm-2">
                            <p class="customLabel"><b class="light-color-text">Sales order number :
                                </b>{{displaysaleorder==null?"N/A":displaysaleorder==undefined?"N/A":displaysaleorder==''?"N/A":displaysaleorder}}
                            </p>
                        </div>
                        <div class="col-sm-2">
                            <p class="customLabel"><b class="light-color-text">Status :
                                </b>{{statusname==null?'N/A':statusname}}</p>
                        </div>
                    </div>

                </div>

            </div>
            <div class="white-box no_bg col-md-12 mb-1 pb-0">
                <div class="row">
                    <div class="col-lg-12 p-0">
                        <div class="customHeading">SO Overview</div>

                    </div>
                    <div class="col-md-12  mt-1">
                        <div class="row">

                            <div class="col-md-3">
                                <div class="form-group row mb-0">
                                    <div class="col-lg-5 col-md-2">
                                        <label class="customLabel">Product Description :</label>
                                    </div>
                                    <div class="col-lg-7 col-md-10">
                                        <label class="customLabel"> {{description}}</label>
                                    </div>
                                </div>
                            </div>

                            <div class="col-md-9"></div>
                            <div class="col-lg-3 col-md-6" *ngIf="IsEnableAprrovel==true">
                                <div class="form-group row mb-0">
                                    <div class="col-lg-5 col-md-2">
                                        <label class="customLabel">Order Status :</label>
                                    </div>

                                    <div class="col-lg-7 col-md-10">
                                        <select class="customInput" name='statusId' id="statusvalues"
                                            [(ngModel)]="statusDropdownList" (change)="statusvalues($event)">
                                            <option *ngFor="let item of productStatusList" [value]="item.id">
                                                {{item.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row d-flex align-items-center">
                                    <div class="col-lg-5 col-md-2 trp">
                                        <label class="customLabel">Sales Quote Date :</label>
                                    </div>
                                    <div class="col-lg-7 col-md-10">
                                        <input type="date" class="customInput" [(ngModel)]="SalesQuoteDate"
                                            min="{{PopupinDate}}">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row d-flex align-items-center">
                                    <div class="col-lg-5 col-md-2 trp">
                                        <label class="customLabel">Sales Order Date :</label>
                                    </div>
                                    <div class="col-lg-7 col-md-10">
                                        <input type="date" class="customInput" [(ngModel)]="SalesOrderDate"
                                            min="{{PopupinDate}}">
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row d-flex align-items-center">
                                    <div class="col-lg-5 col-md-2 trp">
                                        <label class="customLabel"># of Product(s)</label>
                                    </div>
                                    <div class="col-lg-7 col-md-10">
                                        <input type="text" class="customInput Cost" style="padding-left: 5px;"
                                            (keypress)="keyPressNumbers($event)" [disabled]="HashProductDisable == true"
                                            [(ngModel)]="HashProduct" maxlength="3" (blur)="mulProduct()">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="col-md-12  mt-1">
                        <div class="row">

                            <div class="col-lg-3 col-md-6"
                                *ngIf="rolePermissionsList && rolePermissionsList[0].isProductionStatus">
                                <div class="form-group row">
                                    <div class="col-lg-5 col-md-2">
                                        <label class="customLabel">Production Status :</label>
                                    </div>

                                    <div class="col-lg-7 col-md-10">
                                        <!-- <select class="form-control1 customInput" name='pStatusId'
                                            [(ngModel)]="pStatusId" (change)="productionStatusvalue($event)">
                                            <option value="">Select Status</option>
                                            <option *ngFor="let item of producitonStatusList" [value]="item.id"
                                                (change)="pstatusvalues($event)">
                                                {{item.name}}
                                            </option>
                                        </select> -->

                                        <select class="form-control1 customInput" name='pStatusId'
                                            [(ngModel)]="pStatusId" (change)="pstatusvalues($event)">
                                            <option value="">Select Status</option>
                                            <option *ngFor="let item of producitonStatusList" [value]="item.id">
                                                {{item.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                            </div>

                            <div class="col-lg-3 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row d-flex align-items-center">
                                    <div class="col-lg-5 col-md-2 trp">
                                        <label class="customLabel">Start Date :</label>
                                    </div>
                                    <div class="col-lg-7 col-md-10">
                                        <input type="date" class="customInput" [(ngModel)]="StartDate"
                                            min="{{PopupinDate}}">
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-3 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row d-flex align-items-center">
                                    <div class="col-lg-5 col-md-2 trp">
                                        <label class="customLabel">Est. Completion Date: </label>
                                    </div>
                                    <div class="col-lg-7 col-md-10">
                                        <input type="date" class="customInput" [(ngModel)]="CompletionDate"
                                            min="{{PopupinDate}}">
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="col-md-12  mt-1">
                        <div class="row">

                            <div class="col-lg-3 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row d-flex align-items-center">
                                    <div class="col-lg-5 col-md-2 trp">
                                        <label class="customLabel">Location Name :</label>
                                    </div>
                                    <div class="col-lg-7 col-md-10">
                                        <select class="customInput pl-1px" required name="LocationTypeId"
                                            [(ngModel)]="SelectedLocationType" (change)="changeLocation($event)">
                                            <option value=''>Select Location Name</option>
                                            <option *ngFor="let item of LocationTypeName" [value]="item.locationTypeId">
                                                {{item.locationName}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row d-flex align-items-center">
                                    <div class="col-lg-5 col-md-2 trp">
                                        <label class="customLabel">Location :</label>
                                    </div>
                                    <div class="col-lg-7 col-md-10">
                                        <select class="customInput pl-1px" required name="LocationLevelId"
                                            [(ngModel)]="SelectedLocationLevel">
                                            <option value=''>Select Location</option>
                                            <option *ngFor="let item of LocationLevels" [value]="item.id">
                                                {{item.levelsName}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div class="col-md-12 mt-1">
                        <div class="row d-flex align-items-center">
                            <div class="col-lg-2 col-md-2">
                                <label class="customLabel">SO Notes :</label>
                            </div>
                            <div class="col-lg-10 col-md-10 mt-lg-0">
                                <textarea rows="3" cols="5" type="text"
                                    class="customInput customerTextarea customerTextarea2" placeholder="Enter SO Notes"
                                    [(ngModel)]="SoNotes" name="SoNotes" autocomplete="off"></textarea>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 p-0">
                        <div class="customHeading"></div>

                    </div>

                    <div class="col-lg-12 p-0">
                        <div class="customHeading mt-1">Product SO - Part Detail</div>

                    </div>
                </div>
            </div>

            <div class="col-md-12  mt-1">
                <div class="row">

                    <div class="col-lg-3 col-md-6" *ngIf="VehicleRequiredStatus==true">
                        <div class="row d-flex align-items-center">
                            <div class="col-lg-5 col-md-2">
                                <label class="customLabel">Manufacturer
                                    <span class="tooltipWrap tooltip-icon" style="display: inline; padding-left: 0;"><i
                                            class="fa fa-info-circle"></i></span>
                                    <span class="tooltips">
                                        {{vehicleDescription}}
                                    </span>
                                </label>
                            </div>
                            <div class="col-lg-7 col-md-10">
                                <select class="customInput form-control1" required name='manufacturerId'
                                    id="manufacturerId" [(ngModel)]="selectedManufacturerValue"
                                    (change)="onManufacturerChange($event)">
                                    <option value=''>Select Manufacturer</option>
                                    <option *ngFor="let item of manufacturerList" [value]="item.id">
                                        {{item.vehicleManufacturer}}
                                    </option>
                                </select>
                            </div>
                        </div>

                    </div>

                    <div class="col-lg-3 col-md-12 mt-lg-0 mt-md-1" *ngIf="VehicleRequiredStatus==true">
                        <div class="row d-flex align-items-center">
                            <div class="col-lg-5 col-md-2">
                                <label class="customLabel">Model
                                    <span class="tooltipWrap tooltip-icon" style="display: inline; padding-left: 0;"><i
                                            class="fa fa-info-circle"></i></span>
                                    <span class="tooltips">
                                        {{modelDescription}}
                                    </span>
                                </label>
                            </div>
                            <div class="col-lg-7 col-md-10">
                                <select class="customInput pl-1px" required name='manufacturerModelId'
                                    id="manufacturerModelId" [(ngModel)]="selectedManufacturerModelValue"
                                    (change)="onModelChange($event)">
                                    <option value=''>Select Model</option>
                                    <option *ngFor="let item of manufacturerModelList" [value]="item.id">
                                        {{item.modelName}}
                                    </option>
                                </select>
                            </div>
                        </div>

                    </div>
                    <div class="col-lg-2 col-md-12 mt-lg-0 mt-md-1" *ngIf="VehicleRequiredStatus==true">
                        <div class="row d-flex align-items-center">
                            <div class="col-lg-4 col-md-2 trp">
                                <label class="customLabel">Year</label>
                            </div>
                            <div class="col-lg-8 col-md-10">
                                <input type="text" class="customInput" required [(ngModel)]="Year">
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-2 col-md-12 mt-lg-0 mt-md-1" *ngIf="VehicleRequiredStatus==true">
                        <div class="row d-flex align-items-center">
                            <div class="col-lg-4 col-md-2 trp">
                                <label class="customLabel">VIN #</label>
                            </div>
                            <div class="col-lg-8 col-md-10">
                                <input type="text" class="customInput" required [(ngModel)]="VIN">
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-12 mt-lg-0 mt-md-1" *ngIf="VehicleRequiredStatus==true">
                        <div class="row d-flex align-items-center">
                            <div class="col-lg-7 col-md-2 trp">
                                <label class="customLabel">Customer Vehicle ID</label>
                            </div>
                            <div class="col-lg-5 col-md-10">
                                <input type="text" class="customInput" required [(ngModel)]="CustomerVehicleID">
                            </div>
                        </div>
                    </div>


                </div>
            </div>

            <div class="col-md-12  mt-1" *ngIf="VehicleRequiredStatus==true">
                <div class="row">
                    <div class="col-lg-3 col-md-12 mt-lg-0">
                        <div class="row d-flex align-items-center">
                            <div class="col-lg-5 col-md-2">
                                <label class="customLabel">Vehicle Status

                                </label>
                            </div>
                            <div class="col-lg-7 col-md-10">
                                <select class="customInput" required name='vehicleStatus' id="vehicleStatus"
                                    [(ngModel)]="vehicleStatus">
                                    <option value=''>Select Vehicle Status</option>
                                    <option *ngFor="let item of VehicleStatusList" [value]="item.id">
                                        {{item.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-12 mt-lg-0" *ngIf="vehicleStatus=='1'">
                        <div class="row d-flex align-items-center">
                            <div class="col-lg-5 col-md-2">
                                <label class="customLabel">Ordered Date :

                                </label>
                            </div>
                            <div class="col-lg-7 col-md-10">
                                <input type="date" class="customInput" [(ngModel)]="OrderedDate" min="{{PopupinDate}}">
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-12 mt-lg-0" *ngIf="vehicleStatus=='2'">
                        <div class="row d-flex align-items-center">
                            <div class="col-lg-5 col-md-2">
                                <label class="customLabel">In Transit Date

                                </label>
                            </div>
                            <div class="col-lg-7 col-md-10">
                                <input type="date" class="customInput" [(ngModel)]="OrderedDate" min="{{PopupinDate}}">
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-12 mt-lg-0" *ngIf="vehicleStatus=='3'">
                        <div class="row d-flex align-items-center">
                            <div class="col-lg-5 col-md-2">
                                <label class="customLabel">On Premise Date

                                </label>
                            </div>
                            <div class="col-lg-7 col-md-10">
                                <input type="date" class="customInput" [(ngModel)]="OrderedDate" min="{{PopupinDate}}">
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div class="col-md-12 mt-1">
                <div class="row">
                    <div class="col-lg-12 col-md-12 mt-lg-0" *ngIf="VehicleRequiredStatus==true">
                        <div class="row d-flex align-items-center">
                            <div class="col-lg-2 col-md-2">
                                <label class="customLabel">Vehicle Notes

                                </label>
                            </div>
                            <div class="col-lg-10 col-md-10">
                                <textarea rows="2" cols="5" type="text"
                                    class="customInput customerTextarea customerTextarea3"
                                    placeholder="Enter Vehicle Notes" [(ngModel)]="VehicleNotes" name="VehicleNotes"
                                    autocomplete="off"></textarea>
                            </div>
                        </div>

                    </div>
                </div>

            </div>
            <div class="col-lg-12 p-0" *ngIf="VehicleRequiredStatus==true">
                <div class="customHeading mt-1"></div>

            </div>

            <div class="col-md-12">
                <div class="row mt-1">

                    <div class="col-lg-3 col-md-6">
                        <div class="row d-flex align-items-center">
                            <div class="col-lg-5 col-md-2">
                                <label class="customLabel">SO View

                                </label>
                            </div>
                            <div class="col-lg-7 col-md-10">
                                <select class="form-control1 customInput" [(ngModel)]="SOView"
                                    (change)="changeView($event.target.value)">
                                    <option value="1">Sales View</option>
                                    <option value="2">Operations View</option>
                                    <option value="3" *ngIf="rolePermissionsList[0].isSOProfitability == true">So
                                        Profitability View</option>
                                </select>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-6" *ngIf="btnShowvalidation && isOrderApproved==false">
                        <select class="customInput prodCategory" [(ngModel)]="SelectedCatagoryName"
                            autocomplete="none|false|no" (ngModelChange)="typeaheadOnSelectCatagory($event)">
                            <option value='' selected>Select category</option>
                            <option *ngFor="let item of catagoryDataList" [value]="item.id">
                                {{item.name}}
                            </option>
                        </select>
                        <div *ngIf="((selectedCategoryValue=='') && (validationCategory))"
                            class="text-danger input-error">
                            Select Category.</div>
                    </div>
                    <div class="col-lg-2 col-md-6" *ngIf="btnShowvalidation && isOrderApproved==true"></div>

                    <div class="col-lg-1 col-md-6 text-left" *ngIf="issaleOrderChecked==false">
                        <div *ngIf="btnShowvalidation && isOrderApproved==false">
                            <button class="btn blue-bg round-btn part-category" (click)="addCategory(content)"
                                data-target="#checkCategoryPart" data-toggle="modal"><i class="fas fa-plus-circle"
                                    aria-hidden="true"></i> Add Category
                            </button>
                        </div>

                    </div>

                    <div class="col-lg-1 col-md-6 text-left" *ngIf="issaleOrderChecked==true">
                        <div *ngIf="btnShowvalidation && isOrderApproved==false">
                            <button class="btn blue-bg round-btn part-category"
                                (click)="addCategoryChangeOrder(content)" data-target="#checkCategoryPart"
                                data-toggle="modal"><i class="fas fa-plus-circle" aria-hidden="true"></i> Add
                                Category
                            </button>
                        </div>

                    </div>

                </div>
            </div>

            <div class="order-detail-main p-2">
                <ng-container *ngIf="SalesOrderView==true">
                    <table class="display table clearfix order-detail product_detail_table mt-0">
                        <thead>
                            <tr>
                                <th></th>
                                <th colspan="4">Parts</th>
                                <th colspan="2">Labor
                                    <b *ngIf="!laborCostEditMode"
                                        (click)="turnLaborToEditOn(laborHrsCost)"><i><small>({{laborHrsCost|currency}}/hr)</small></i></b>
                                    <span *ngIf="laborCostEditMode">
                                        <input type="text" class="customInput width-60 Cost" maxlength="8"
                                            (blur)="turnLaborToEditOff()" [(ngModel)]="laborCostEdit" autofocus>
                                    </span>
                                </th>
                                <th>Extention</th>
                            </tr>
                            <tr>
                                <th class="text-left">&nbsp;Company Part Name</th>
                                <th>Vendor name</th>
                                <th>Qty</th>
                                <th>Each</th>
                                <th>Ext</th>
                                <th>Hrs</th>
                                <th>Ext</th>
                                <th>Total</th>
                            </tr>
                        </thead>
                        <tbody>

                            <ng-container *ngFor="let category of partCategoriesList;let index=index">
                                <tr class="part-category hoverEffect">
                                    <td>
                                        <span (click)="Openpart(category.categoryId)" class="openpart"
                                            id="openpart-{{category.categoryId}}"><i
                                                class="fas fa-plus-circle"></i></span>
                                        {{index+1}}.0 {{category.categoryName}}
                                        <span>
                                            <button class="arrow-updown float-right mr-1 showBtn"
                                                (click)="SwapCategory(partCategoriesList, index, (index-1))"
                                                [disabled]="index==0? true:false"><i
                                                    class="fas fa-arrow-up"></i></button>
                                            <button class="arrow-updown float-right showBtn"
                                                (click)="SwapCategory(partCategoriesList, index, (index+1))"
                                                [disabled]="index==partCategoriesList.length-1? true:false"><i
                                                    class="fas fa-arrow-down"></i></button>
                                            <!-- <input type="button" value="↑"   class=" arrowbutton ml-1"  (click)="SwapCategory(partCategoriesList, index, (index-1))" [disabled]="index==0? true:false" />
                                        <input type="button" value="↓" class=" arrowbutton ml-1" (click)="SwapCategory(partCategoriesList, index, (index+1))"  [disabled]="index==partCategoriesList.length-1? true:false" /> -->

                                        </span>
                                        <span *ngIf="btnShowvalidation && isOrderApproved==false">
                                            <button data-toggle="modal" data-target="#addPart"
                                                (click)="AddCategoryPart(category.categoryId)"
                                                class="showBtn btn btn-sm green-bg sq-btn p-addPart float-right mr-1"><span
                                                    class="fas fa-plus"></span> Part
                                            </button>
                                            <button data-toggle="modal" data-target="#addPart"
                                                (click)="AddNewRowForCategoryPart(partCategoriesList, category.categoryId,index)"
                                                class="showBtn btn btn-sm orange-bg sq-btn p-addPart float-right mr-1"><span
                                                    class="fas fa-plus"></span> FF
                                            </button>
                                            <span class="showBtn float-right" *ngIf="btnShowvalidation"
                                                (click)="deletecategorypopup(category.categoryId,contentcategory)"><i
                                                    class="fas fa-trash-alt order-del"></i></span>
                                        </span>
                                    </td>
                                    <td colspan="7" class="text-right" *ngIf="partCategoriesList1 != undefined">
                                       <span *ngIf="partCategoriesList1.length > 0"> {{category.totalextensiondata |currency}}</span></td>
                                </tr>
                                <ng-container *ngFor="let parts of category.partList;let innerIndex=index;">
                                    <tr class="hoverEffect openpart-{{category.categoryId}}-form table-partname">
                                        <td>
                                            <div class="d-flex align-items-center position-relative">
                                                <span class="partdetail" (click)="OpenQuestion(parts.partId, 'open')"
                                                    id="partname-{{parts.partId}}"> <i class="fas fa-plus"></i>
                                                </span>
                                                <span class="PartName tooltipWrap"> {{index+1}}.{{innerIndex+1}}
                                                    {{parts.partName}} <span class="partDetails"
                                                        *ngIf="parts.costold==0" style="font-size: 11px !important;">
                                                        (CS)</span></span>
                                                <span class="partDetails" *ngIf="parts.costold==0"
                                                    style="color: white;font-size: 11px !important;">
                                                    (CS)</span>
                                                <span class="showBtn" *ngIf="isOrderApproved==false">
                                                    <span *ngIf="btnShowvalidation && btnOkShow==false"
                                                        style="display:flex; align-items: center;">
                                                        <span class="tooltipWrap tooltip-icon"
                                                            style="display:inline;"><i class="fa fa-info-circle"
                                                                style="font-size: 12px !important;"></i></span>
                                                        <span class="tooltipsnew">
                                                            <span *ngIf="parts.isCustomPart==false else other_content"
                                                                style="color: white">

                                                                "Vendor Part Name" :
                                                                {{parts.vendorPartName==null?'N/A':parts.vendorPartName==undefined?'N/A':parts.vendorPartName==''?'N/A':parts.vendorPartName}}
                                                                and "Vendor Part #" :
                                                                {{parts.vendorPartNumber==null?'N/A':parts.vendorPartNumber==undefined?'N/A':parts.vendorPartNumber==''?'N/A':parts.vendorPartNumber}}
                                                            </span>
                                                            <ng-template #other_content>
                                                                <span *ngIf="parts.isCustomPart==true">
                                                                    <span *ngFor="let parts1 of parts.customPartList;"
                                                                        style="color: white">
                                                                        "Vendor Part Name" :
                                                                        {{parts1.vendorPartName==null?'N/A':parts1.vendorPartName==undefined?'N/A':parts1.vendorPartName==''?'N/A':parts1.vendorPartName}}
                                                                        and "Vendor Part #" :
                                                                        {{parts1.vendorPartNumber==null?'N/A':parts1.vendorPartNumber==undefined?'N/A':parts1.vendorPartNumber==''?'N/A':parts1.vendorPartNumber}}

                                                                        <br />
                                                                    </span>
                                                                </span>
                                                            </ng-template>
                                                        </span>

                                                        <a class="showBtn btn btn-sm sq-btn mr-1 text-white makecs"
                                                            (click)="makeCS(category.categoryId,parts.partId)"
                                                            [ngClass]="parts.cs == true ? 'redc' : 'bluec'">CS</a>
                                                        <a *ngIf="btnShowvalidation"
                                                            (click)="deletePopup(category.categoryId,parts.partId,contentname)"><i
                                                                class="fas fa-trash-alt order-del"></i></a>
                                                        <a><span class="fas fa-edit partEdit"
                                                                (click)="EditPartRow(category.categoryId,parts.partId,'editRow',parts.quantity)"></span></a>
                                                    </span>
                                                    <span
                                                        *ngIf="category.categoryId==EditRowCateId && parts.partId==EditRowPartId && btnOkShow==true"
                                                        style="display:flex;">
                                                        <span><button data-toggle="modal"
                                                                (click)="UpdatePartRow(category.categoryId,parts.partId)"
                                                                class="showBtn btn btn-sm green-bg sq-btn ml-1 p-addPart"
                                                                style="width: 35px !important;">Ok
                                                            </button></span>
                                                        <span><button data-toggle="modal"
                                                                (click)="CancleUpdatePartRow(category.categoryId,parts.partId)"
                                                                class="showBtn btn darkred-bg  sq-btn ml-1 p-addPart"
                                                                style="width: 55px !important;">Cancel
                                                            </button></span>
                                                    </span>
                                                </span>
                                                <span style="display: flex;">
                                                    <button class="arrow-updown showBtn float-right"
                                                        (click)="SwapPartItem(category.partList, innerIndex, (innerIndex-1))"
                                                        [disabled]="innerIndex==0? true:false"><span
                                                            class="fas fa-arrow-up"></span></button>
                                                    <button class="arrow-updown showBtn float-right"
                                                        (click)="SwapPartItem(category.partList, innerIndex, (innerIndex+1))"
                                                        [disabled]="innerIndex==category.partList.length-1? true:false"><span
                                                            class="fas fa-arrow-down"></span></button>
                                                    <!-- <input type="button" value="↑"   class=" arrowbutton ml-1"  (click)="SwapPartItem(category.partList, innerIndex, (innerIndex-1))" [disabled]="innerIndex==0? true:false" />
                                                <input type="button" value="↓" class=" arrowbutton ml-1" (click)="SwapPartItem(category.partList, innerIndex, (innerIndex+1))"  [disabled]="innerIndex==category.partList.length-1? true:false" /> -->
                                                </span>
                                            </div>
                                        </td>
                                        <td class="text-center">
                                            {{parts.vendorName}}
                                        </td>
                                        <td class="text-center">
                                            <span
                                                *ngIf="normalShow==true || RowEdit==false">{{parts.quantityOld}}</span>
                                            <span
                                                *ngIf="category.categoryId==EditRowCateId && parts.partId==EditRowPartId && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                <input class="form-control odr-qty NumberOnly " type="text"
                                                    [(ngModel)]="UpdateQty" maxlength="5" min="1" max="99"
                                                    (blur)="CalculationOnkeypress(category.categoryId,parts.partId)" />
                                            </span>
                                        </td>

                                        <td>
                                            <span *ngIf="normalShow==true">{{parts.costold | currency}}</span>
                                            <!-- <span *ngIf="normalShow==true">${{parts.costold}}</span> -->
                                            <span
                                                *ngIf="category.categoryId==EditRowCateId && parts.partId==EditRowPartId && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                <input class="form-control inputShow Cost" type="text"
                                                    [(ngModel)]="UpdateEachPartCost"
                                                    (blur)="formatCurrency($event.target.value)" maxlength="10"
                                                    (blur)="CalculationOnkeypressPrice(category.categoryId,parts.partId)" />
                                            </span>
                                        </td>
                                        <td>{{parts.costold*parts.quantityOld | currency}}
                                            <br />
                                            <span class="onEditCurrTextProduct"
                                                *ngIf="parts.partId==EditRowPartId && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                {{parts.cost*parts.quantity | currency}}
                                            </span>
                                        </td>
                                        <td>
                                            <span
                                                *ngIf="normalShow==true || CurrentEditRow=='Edit'">{{parts.hourold|number:
                                                '1.2-2'}}</span>
                                            <span
                                                *ngIf="category.categoryId==EditRowCateId && parts.partId==EditRowPartId && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                <input class="form-control odr-qty Cost" type="text"
                                                    [(ngModel)]="UpdateLaborHrs"
                                                    (blur)="formatHours($event.target.value)" maxlength="6"
                                                    (blur)="CalculationOnkeypress(category.categoryId,parts.partId)" />
                                            </span>
                                        </td>

                                        <td>{{parts.hourold*laborHrsCost*parts.quantityOld | currency}}
                                            <br />
                                            <span class="onEditCurrTextProduct"
                                                *ngIf="parts.partId==EditRowPartId && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                {{parts.hours*laborHrsCost*parts.quantity | currency}}
                                            </span>
                                        </td>
                                        <td>
                                            {{(parts.costold*parts.quantityOld)+(parts.hourold*laborHrsCost*parts.quantityOld)
                                            |currency}}
                                            <br />
                                            <span class="onEditCurrTextProduct"
                                                *ngIf="parts.partId==EditRowPartId && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                {{(parts.cost*parts.quantity)+(parts.hours*laborHrsCost*parts.quantity)
                                                |currency}}
                                            </span>
                                        
                                        </td>

                                    </tr>

                            

                                    <tr class="table-partdetail partname-{{parts.partId}}-detail">
                                        <td colspan="8">
                                            <!-- <div class="qus-title"></div> -->
                                            <div *ngFor="let qaItem of parts.questionAnsList;">
                                                <div><b style="font-size: 13px;">Questions
                                                        <span class="hoverEffect qus mt-2" *ngIf="qaItem.questionId==0"
                                                            style="margin-left: 9px;font-weight: bold;color: #333;">N/A</span>
                                                    </b>
                                                </div>
                                                <hr style="margin-top: 2px;margin-bottom: -5px;"
                                                    *ngIf="qaItem.questionId!=0">

                                                <!-- akm -->
                                                <div class="hoverEffect qus mt-2 d-flex" *ngIf="qaItem.questionId!=0">
                                                    <span style="font-weight: bold;color: #333;">
                                                        {{qaItem.questionName}} </span> :
                                                    {{qaItem.answerName==null ?'Not Answered':qaItem.answerName}}
                                                    <a class="showBtn green-text" *ngIf="isOrderApproved==false"
                                                        (click)="EditQuestionAnswr(qaItem.questionId,parts.partId,qaItem.answerName,'')">
                                                        <i class="fas fa-edit"></i></a>
                                                    <div
                                                        *ngIf="parts.partId==selectedPartId && qaItem.questionId==selectedQuestionId && isQA==true">
                                                        <div class="col-sm-12" *ngIf="qaItem.typeKey =='Text'">
                                                            <input type="text" [(ngModel)]="answerValue"
                                                                value="qaItem.answerName" class="form-control"
                                                                placeholder="Type Answer" maxlength="50"
                                                                (blur)="UpdateQuestionAnswer(qaItem.questionId,parts.partId,qaItem.answerName,'')">
                                                        </div>
                                                    </div>
                                                </div>


                                                <div
                                                    *ngIf="parts.partId==selectedPartId && qaItem.questionId==selectedQuestionId">
                                                    <div class="col-sm-12">
                                                        <select *ngIf="qaItem.typeKey =='CustomerName'"
                                                            class="form-control newsmallddl"
                                                            [(ngModel)]="qaItem.answerName">
                                                            <option value='null' selected>Select Customer</option>
                                                            <option *ngFor="let item of customerData">
                                                                {{item.customerName}}
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div class="col-sm-12" *ngIf="qaItem.typeKey =='YesNo'">
                                                        <select class="form-control newsmallddl"
                                                            [(ngModel)]="qaItem.answerName">
                                                            <option value="" selected>Select Yes/No</option>
                                                            <option value="Yes">Yes</option>
                                                            <option value="No">No</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-sm-12" *ngIf="qaItem.typeKey =='VendorName'">
                                                        <select class="form-control newsmallddl"
                                                            [(ngModel)]="qaItem.answerName">
                                                            <option value='null' selected>Select Vendor</option>
                                                            <option *ngFor="let item of vendorList">
                                                                {{item.vendorName}}
                                                            </option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>

                                            <hr style="margin-top: 2px;margin-bottom: -5px;">

                                            <div class="hoverEffect qus mt-2 d-flex">
                                                <span style="font-weight: bold; white-space:nowrap; color: #333;">
                                                    Notes :
                                                    <span class="hoverEffect qus mt-1"
                                                        *ngIf="parts.isNotes==false">{{parts.notes}}</span>
                                                </span>

                                                <div *ngIf="parts.isNotes==true" class="w-100">
                                                    <div class="col-lg-12">
                                                        <input type="text" [(ngModel)]="parts.notes"
                                                            value="qaItem.notes" class="form-control notes"
                                                            placeholder="Type Notes"
                                                            (blur)="SaveNotes(category.categoryId, parts.partId)">
                                                    </div>
                                                </div>
                                                <a class="showBtn green-text" *ngIf="parts.isEdit==true"
                                                    (click)="visibleNotes(category.categoryId, parts.partId)"><i
                                                        class="fas fa-edit"></i></a>
                                                <a class="showBtn green-text" *ngIf="parts.isEdit==false"
                                                    (click)="SaveNotes(category.categoryId, parts.partId)"><i
                                                        style="color: #0fa506; margin-top: 3px;"
                                                        class="fas fa-save"></i></a>
                                            </div>

                                        </td>
                                    </tr>

                                </ng-container>
                                <!-- // Show data in the table -->  
                            <ng-container *ngFor="let nestcategory of freeFormPartData;let index=index">
                                <tr *ngIf = "category.categoryId == nestcategory.partCategoryId"
                                    class="orange openpart-{{category.categoryId}}-form table-partname ">
                                    <!-- <form [formGroup]="formAddFF"> -->
                                    <td class="hoverEffect">
                                        <div class="d-flex align-items-center position-relative">
                                            <div class="col-lg-12 row">
                                                <div class="col-md-7">
                                                    <span *ngIf="!nestcategory.enabled"
                                                        class="openpart">{{nestcategory.partName}}</span>
                                                    <input *ngIf="nestcategory.enabled" type="text" class="form-control"
                                                        style="width: 89%;" name="ffCompanyPartNameEdit"
                                                        [(ngModel)]='ffCompanyPartNameEdit' placeholder="Company Part Name"
                                                        maxlength="50">
                                                </div>
                                                <div class="col-sm-5 ">
                                                    <a class="fw-bold mx-1" *ngIf="nestcategory.enabled"
                                                        (click)="FFCancelBtnClicked(nestcategory);"><i class="fa fa-times"
                                                            style="color: red;" aria-hidden="true"></i>
                                                    </a>
                                                    <a class="green-text mx-1" *ngIf="nestcategory.enabled"
                                                        (click)="FFUpdateBtnClicked(nestcategory,category.categoryId);"><i class="fas fa-save"
                                                            aria-hidden="true"></i>
                                                    </a>
                                                
                                                    <a class="mx-1 float-right" *ngIf="!nestcategory.enabled"
                                                        (click)="FFDeleteBtnClicked(nestcategory);"><i  style="color: red;" class="fas fa-trash"
                                                            aria-hidden="true"></i>
                                                    </a>
                                                    <a *ngIf="!nestcategory.enabled" class="float-right"><span class="fas fa-edit " style="color: black;"
                                                        (click)="enableFFEditMethod(nestcategory);"></span></a>

                                                    <!-- <a  *ngIf="!nestcategory.enabled" class="float-right"
                                                        (click)="enableFFEditMethod(nestcategory);"><i style="color: black;"
                                                            class="fas fa-edit"></i>
                                                    </a> -->
                                                        
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="col-sm-12">
                                            <span *ngIf="!nestcategory.enabled" class="openpart">{{nestcategory.vendorName}}</span>
                                            <input *ngIf="nestcategory.enabled" type="text" class="form-control"
                                                style="width: 89%;" name="ffVendorName" [(ngModel)]='ffVendorNameEdit'
                                                placeholder="Company Part Name" maxlength="50">
                                        </div>
                                    </td>
                                    <td>
                                        <div class="col-sm-12">
                                            <span *ngIf="!nestcategory.enabled" class="openpart">{{nestcategory.quantity}}</span>
                                            <input *ngIf="nestcategory.enabled" (ngModelChange)="ffQtyValuechange($event)" type="text" class="form-control"
                                                name="ffQtyEdit" [(ngModel)]='ffQtyEdit' placeholder="Qty" maxlength="50">
                                        </div>
                                    </td>
                                    <td>
                                        <span *ngIf="!nestcategory.enabled" class="openpart">{{nestcategory.each|currency}}</span>
                                        <input *ngIf="nestcategory.enabled" (ngModelChange)="ffEachValuechange($event)" type="text" class="form-control" name="ffEachEdit"
                                            [(ngModel)]='ffEachEdit' placeholder="Each" maxlength="50">
                                    </td>
                                    <td>
                                        <div class="col-sm-12">
                                            <span *ngIf="!nestcategory.enabled" class="openpart">{{nestcategory.quantity*nestcategory.each|currency}}</span>
                                            <input *ngIf="nestcategory.enabled" type="text" class="form-control" name="ffExtEdit"
                                            [(ngModel)]='ffExtEdit'  maxlength="50">
                                        </div>
                                    </td>
                                    <td>
                                        <div class="col-sm-12">
                                            <span *ngIf="!nestcategory.enabled" class="openpart">{{nestcategory.hrs|number:
                                                '1.2-2'}}</span>
                                            <input *ngIf="nestcategory.enabled" (ngModelChange)="ffHrsValuechange($event)" type="text" class="form-control"
                                                name="ffHrsEdit" [(ngModel)]='ffHrsEdit' placeholder="Hrs" maxlength="50">
                                        </div>
                                    </td>
                                    <td>
                                        <div class="col-sm-12">
                                            <span *ngIf="!nestcategory.enabled"  class="openpart">{{nestcategory.hrs*laborHrsCost*nestcategory.quantity
                                                |currency}}</span>
                                                <input *ngIf="nestcategory.enabled" type="text" class="form-control" name="ffExt2Edit"
                                                    [(ngModel)]='ffExt2Edit'  maxlength="50">
                                        </div>
                                    </td>
                                    <td>
                                        <div class="col-sm-12 rightText">
                                            <span *ngIf="!nestcategory.enabled" class="openpart">{{((nestcategory.quantity*nestcategory.each) +
                                                (nestcategory.hrs*nestcategory.quantity*laborHrsCost))|currency}}</span>
                                            <input *ngIf="nestcategory.enabled" type="text" class="form-control" name="ffTotalEdit"
                                                [(ngModel)]='ffTotalEdit'  maxlength="50">
                                        </div>
                                    </td>
                                    <!-- </form> -->

                                </tr>
                            </ng-container >
                            
                        <!-- // end data row -->
                                        
                                    <!-- // new row after condition -->
                            <tr *ngIf="isAddNewRowForCategory && index==selectFFIndex" class="hoverEffect ">
                                <!-- <form [formGroup]="formAddFF"> -->
                                <td>
                                    <div class="d-flex align-items-center position-relative">
                                        <span (click)="deleteNewRowForCategoryPart()" class="openpart"><i
                                                class="fa fa-times"></i></span>
                                        <a class="green-text mx-1" (click)="SaveNewRowDataForFF();"><i
                                                class="fas fa-save" aria-hidden="true"></i>
                                        </a>
                                        <div class="col-sm-12">
                                            <input type="text" class="form-control" style="width: 89%;"
                                                name="ffCompanyPartName" [(ngModel)]='ffCompanyPartName'
                                                placeholder="Company Part Name" maxlength="50">
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="col-sm-12">
                                        <input type="text" class="form-control" name="ffVendorName"
                                            [(ngModel)]='ffVendorName' placeholder="Vendor Name" maxlength="50">
                                    </div>
                                </td>
                                <td>
                                    <div class="col-sm-12">
                                        <input type="text" class="form-control" (ngModelChange)="ffNewQtyValuechange($event)" name="ffQty" [(ngModel)]='ffQty'
                                            placeholder="Qty" maxlength="50">
                                    </div>
                                </td>
                                <td>
                                    <div class="col-sm-12">
                                        <input type="text" class="form-control" (ngModelChange)="ffNewEachValuechange($event)" name="ffEach" [(ngModel)]='ffEach'
                                            placeholder="Each" maxlength="50">
                                    </div>
                                </td>
                                <td>
                                    <div class="col-sm-12">
                                        <input type="text" class="form-control" name="ffExt"
                                            [(ngModel)]='ffExt' placeholder="Ext" maxlength="50">
                                    </div>
                                </td>
                                <td>
                                    <div class="col-sm-12">
                                        <input type="text" (ngModelChange)="ffNewHrsValuechange($event)" class="form-control" name="ffHrs" [(ngModel)]='ffHrs'
                                            placeholder="Hrs" maxlength="50">
                                    </div>
                                </td>
                                <td>
                                    <div class="col-sm-12">
                                        <input type="text"  class="form-control" name="ffExt2"
                                            [(ngModel)]='ffExt2' placeholder="Ext" maxlength="50">
                                    </div>
                                </td>
                                <td>
                                    <div class="col-sm-12">
                                        <input type="text"  class="form-control" name="ffTotal"
                                            [(ngModel)]='ffTotal' placeholder="Total" maxlength="50">
                                    </div>
                                </td>
                                <!-- </form> -->

                            </tr>
                            <!-- new row ends after condition -->

                            </ng-container>
                            <tr *ngIf="partCategoriesList!=undefined && partCategoriesList?.length==0">
                                <td>Part Item is not Available</td>
                            </tr>
                            <!-- // Show data in the table -->  
                            <!-- <tr *ngFor="let category of freeFormPartData;let index=index"
                                class="orange">
                            
                                <td class="hoverEffect">
                                    <div class="d-flex align-items-center position-relative">
                                        <div class="col-lg-12 row">
                                            <div class="col-md-7">
                                                <span *ngIf="!category.enabled"
                                                    class="openpart">{{category.partName}}</span>
                                                <input *ngIf="category.enabled" type="text" class="form-control"
                                                    style="width: 89%;" name="ffCompanyPartNameEdit"
                                                    [(ngModel)]='ffCompanyPartNameEdit' placeholder="Company Part Name"
                                                    maxlength="50">
                                            </div>
                                            <div class="col-sm-5 ">
                                                <a class="fw-bold mx-1" *ngIf="category.enabled"
                                                    (click)="FFCancelBtnClicked(category);"><i class="fa fa-times"
                                                        style="color: red;" aria-hidden="true"></i>
                                                </a>
                                                <a class="green-text mx-1" *ngIf="category.enabled"
                                                    (click)="FFUpdateBtnClicked(category);"><i class="fas fa-save"
                                                        aria-hidden="true"></i>
                                                </a>

                                                <button class="arrow-updown float-right mr-1 showBtn"
                                                    (click)="SwapPartItem(category, index, (index-1))"
                                                    [disabled]="index==0? true:false "><i
                                                        class="fas fa-arrow-up"></i></button>
                                                <button class="arrow-updown float-right showBtn"
                                                    (click)="SwapPartItem(category, index, (index+1))"
                                                    [disabled]="index==freeFormPartData.length-1? true:false"><i
                                                        class="fas fa-arrow-down"></i></button>
                                                <a class="mx-1 float-right" *ngIf="!category.enabled"
                                                    (click)="FFDeleteBtnClicked(category);"><i  style="color: red;" class="fas fa-trash"
                                                        aria-hidden="true"></i>
                                                </a>
                                                <a  *ngIf="!category.enabled" class="float-right"
                                                    (click)="enableFFEditMethod(category);"><i style="color: black;"
                                                        class="fas fa-edit"></i>
                                                    </a>
                                                    
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <div class="col-sm-12">
                                        <span *ngIf="!category.enabled" class="openpart">{{category.vendorName}}</span>
                                        <input *ngIf="category.enabled" type="text" class="form-control"
                                            style="width: 89%;" name="ffVendorName" [(ngModel)]='ffVendorNameEdit'
                                            placeholder="Company Part Name" maxlength="50">
                                    </div>
                                </td>
                                <td>
                                    <div class="col-sm-12">
                                        <span *ngIf="!category.enabled" class="openpart">{{category.quantity}}</span>
                                        <input *ngIf="category.enabled" (ngModelChange)="ffQtyValuechange($event)" type="text" class="form-control"
                                            name="ffQtyEdit" [(ngModel)]='ffQtyEdit' placeholder="Qty" maxlength="50">
                                    </div>
                                </td>
                                <td>
                                    <span *ngIf="!category.enabled" class="openpart">{{category.each|currency}}</span>
                                    <input *ngIf="category.enabled" (ngModelChange)="ffEachValuechange($event)" type="text" class="form-control" name="ffEachEdit"
                                        [(ngModel)]='ffEachEdit' placeholder="Each" maxlength="50">
                                </td>
                                <td>
                                    <div class="col-sm-12">
                                        <span *ngIf="!category.enabled" class="openpart">{{category.quantity*category.each|currency}}</span>
                                        <input *ngIf="category.enabled" type="text" class="form-control" name="ffExtEdit"
                                        [(ngModel)]='ffExtEdit'  maxlength="50">
                                    </div>
                                </td>
                                <td>
                                    <div class="col-sm-12">
                                        <span *ngIf="!category.enabled" class="openpart">{{category.hrs|number:
                                            '1.2-2'}}</span>
                                        <input *ngIf="category.enabled" (ngModelChange)="ffHrsValuechange($event)" type="text" class="form-control"
                                            name="ffHrsEdit" [(ngModel)]='ffHrsEdit' placeholder="Hrs" maxlength="50">
                                    </div>
                                </td>
                                <td>
                                    <div class="col-sm-12">
                                        <span *ngIf="!category.enabled"  class="openpart">{{category.hrs*laborHrsCost*category.quantity
                                            |currency}}</span>
                                            <input *ngIf="category.enabled" type="text" class="form-control" name="ffExt2Edit"
                                                [(ngModel)]='ffExt2Edit'  maxlength="50">
                                    </div>
                                </td>
                                <td>
                                    <div class="col-sm-12 rightText">
                                        <span *ngIf="!category.enabled" class="openpart">{{((category.quantity*category.each) +
                                            (category.hrs*category.quantity*laborHrsCost))|currency}}</span>
                                        <input *ngIf="category.enabled" type="text" class="form-control" name="ffTotalEdit"
                                            [(ngModel)]='ffTotalEdit'  maxlength="50">
                                    </div>
                                </td>
                                

                            </tr> -->
                            <!-- // end data row -->

                            
                        </tbody>
                        <!-- *ngIf="subSection>0" -->
                        <tfoot class="footOrder tfootnew" [style.display]="subSection>0 ? '' : 'none'">
                            <tr>
                                <td class="tr-bg-clr removeBorder"></td>
                                <td class="double-border border_left" colspan="3" align="left">
                                    SubTotal
                                </td>
                                
                                <!-- <td class="double-border">{{(PartExtensionTotal + ffPartTotalExt)  |currency}}</td> -->
                                <td class="double-border">{{(PartExtensionTotalShow + ffPartTotalExt)  |currency}}</td>
                                <td class="double-border">{{HoursExtensionTotal1==undefined || HoursExtensionTotal1==0?"0.00":
                                    HoursExtensionTotal1 | number:'1.2' }}</td>
                                <td class="double-border">{{(PartLaborExtesionTotalShow + ffPartTotalLabExt) |currency}}</td>
                                <!-- <td class="double-border">{{(LaborExtesionTotal + ffPartTotalLabExt) |currency}}</td> -->
                                <!-- <td class="double-border">{{GrandTotalAmount|currency}}</td> -->
                                <td class="double-border">{{PartSubTotalShow|currency}}</td>
                                
                            </tr>
                            <tr>
                                <td class="tr-bg-clr-none setBorder"></td>
                                <td colspan="5" align="left" class="border_left">
                                    Discount
                                    <span class="discount">
                                        <label class="mr-0">
                                            % <input class="p_relative" type="radio" value="Percentage" name="Discount"
                                                [disabled]="isOrderApproved"
                                                (change)="OnDiscountChange($event.target.value)" id="discountdata">
                                        </label>
                                        <label class="ml-2">
                                            $ <input class="p_relative" type="radio" value="Doller" name="Discount"
                                                (change)="OnDiscountChange($event.target.value)"
                                                [disabled]="isOrderApproved" id="discountdata">
                                        </label>
                                    </span>
                                    <span>
                                        <label class="ml-2">
                                            Parts &nbsp;<input class="p_relative custom_checkbox" type="checkbox"
                                                name="Part" [disabled]="!enableCheckBox||isOrderApproved"
                                                [(ngModel)]='PartCheckBox' (change)="CalculationSubtotal()">
                                        </label>

                                        <label class="ml-2">
                                            Labor &nbsp;<input class="p_relative custom_checkbox" type="checkbox"
                                                name="Labor" [disabled]="!PartCheckBox||isOrderApproved"
                                                [(ngModel)]='LaborCheckBox' (change)="CalculationSubtotal()">
                                        </label>
                                    </span>
                                </td>
                                <td>
                                    <span *ngIf="discountRadioBox=='Doller'" class="pr-1">$</span> <input type="text"
                                        (keyup)="CalculationSubtotal()" [disabled]="!enableCheckBox||isOrderApproved"
                                        (keypress)="formatCurrencyDiscount()"
                                        (blur)="formatCurrencyDiscountBlur($event.target.value)"
                                        [(ngModel)]="discountamount" class="width-100_1 Cost input_cmn fix_ht"
                                        id="dollor" maxlength="8" autocomplete="off" />
                                    <span *ngIf="discountRadioBox=='Percentage'" class="pl-1">%</span>
                                </td>
                                <td style="color: #f00;">({{discount|currency}})</td>
                            </tr>
                            <tr>
                                <td class="tr-bg-clr-none setBorder"></td>
                                <td colspan="3" align="left" class="border_left">
                                    SubTotal with Discounts for {{this.HashProduct}} Products
                                </td>
                                <td>{{(partExtSubTotalWithDiscount + ffPartTotalExt)|currency}}</td>
                                <td></td>
                                <!-- <td>{{laborExtSubTotalWithDiscount|currency}}</td> -->
                                <td>{{(PartLaborExtesionTotalShow + ffPartTotalLabExt)|currency}}</td>
                                <!-- <td>{{SubTotalWithDisnt|currency}}</td> -->
                                <td>{{(PartLaborExtesionTotalShow + ffPartTotalLabExt) +(PartExtensionTotalShow + ffPartTotalExt) |currency}}</td>
                                
                            </tr>
                            <tr>
                                <td class="tr-bg-clr-none setBorder"></td>

                                <td colspan="6" align="left" class="border_left">
                                    <select [(ngModel)]='TaxType' name='TaxType' (change)="SaleTaxType()"
                                        [disabled]="isOrderApproved">
                                        <option value="1" selected>Sales Tax</option>
                                        <option value="2">Reseller</option>
                                        <option value="3">Out of State</option>
                                    </select>
                                    <span *ngIf="SaleTaxDiv">
                                        <input type="text" class="input_cmn fix_ht Cost"
                                            (keypress)="formatCurrencySalesTaxKeyPress()" maxlength="5"
                                            (keyup)="CalculationSalesTax()" (blur)="SaleTaxBlur($event.target.value)"
                                            [(ngModel)]="SaleTaxAmount" id="dollor" [disabled]="isOrderApproved"
                                            autocomplete="off" />
                                        <span class="dollar ml-1">%</span>
                                        <label class="ml-2">
                                            Parts &nbsp;<input class="p_relative custom_checkbox" type="checkbox"
                                                name="IsTaxPart" [(ngModel)]='IsTaxPart'
                                                (change)="CalculationSalesTax()" [disabled]="isOrderApproved">
                                        </label>
                                        <label class="ml-2">
                                            Labor &nbsp;<input class="p_relative custom_checkbox" type="checkbox"
                                                name="IsTaxLabor" [disabled]="!IsTaxPart||isOrderApproved"
                                                [(ngModel)]='IsTaxLabor' (change)="CalculationSalesTax()">
                                        </label>
                                    </span>
                                    <label class="ml-2" *ngIf="Resellertextbox">
                                        Reseller No. <input type="text" class="width-150" [disabled]="isOrderApproved"
                                            [(ngModel)]='ResellerNo' maxlength="10">
                                    </label>
                                </td>
                                <td>{{TaxTotalAmount|currency}}</td>
                            </tr>
                            <tr>
                                <td class="tr-bg-clr-none bb-1"></td>
                                <td colspan="4" align="left" class="border_left">
                                    Total
                                </td>
                                <td colspan="2"></td>
                                <!-- <td colspan="">{{finalAmount|currency}}</td> -->
                                <td colspan="">{{(TaxTotalAmount) + (PartLaborExtesionTotalShow + ffPartTotalLabExt) +(PartExtensionTotalShow + ffPartTotalExt)|currency}}</td>
                            </tr>
                        </tfoot>
                    </table>
                </ng-container>
                <ng-container *ngIf="OperationView==true">
                    <table class="display table clearfix order-detail product_detail_table mt-0"
                        style="overflow: hidden">
                        <thead>
                            <tr>
                                <th class="text-left">&nbsp;Company Part Name</th>
                                <th>Vendor name</th>
                                <th>Total Qty</th>
                                <th>Avlb In Inventory</th>
                                <th>Inv. Assigned to SO</th>
                                <th>Fulfilled fm PO</th>
                                <th>PO Expected Ship Date</th>
                                <th>Qty still needed to Fulfill</th>
                                <th class="text-center">Ready for Production</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let category of partCategoriesList;let index=index">
                                <tr class="part-category hoverEffect">
                                    <td>
                                        <span (click)="Openpart(category.categoryId)" class="openpart"
                                            id="openpart-{{category.categoryId}}"><i
                                                class="fas fa-plus-circle"></i></span>
                                        {{index+1}}.0 {{category.categoryName}}
                                    </td>
                                    <td colspan="8" class="text-right">&nbsp;</td>
                                </tr>
                                <ng-container *ngFor="let parts of category.partList;let innerIndex=index;">
                                    <tr class="hoverEffect openpart-{{category.categoryId}}-form table-partname">
                                        <td>
                                            <div class="d-flex align-items-center position-relative">
                                                <span class="PartName tooltipWrap"
                                                    [ngClass]="{'minusplustxt' : parts.partcustom==false}"> <span
                                                        class="partdetailA" id="partnameA-{{parts.partId}}"
                                                        (click)="OpenQuestion1(parts.partId, 'open')"
                                                        *ngIf="parts.partcustom==true"> <i
                                                            class="fas fa-plus plustxt"></i>
                                                    </span> {{index+1}}.{{innerIndex+1}}
                                                    {{parts.partName}} <span class="partDetails"
                                                        *ngIf="parts.costold==0">
                                                        (CS)</span></span>
                                                <span class="tooltips">

                                                    {{parts.partName}}
                                                    <span class="partDetails" *ngIf="parts.costold==0"
                                                        style="color: white;">
                                                        (CS)</span>
                                                </span>
                                            </div>
                                        </td>
                                        <td class="text-center">
                                            {{parts.vendorName}}
                                        </td>
                                        <td class="text-center">
                                            {{parts.opqty}}
                                        </td>
                                        <td class="text-center">
                                            <span *ngIf="parts.partcustom==false else other_content">
                                                {{parts.opavlbInventory}}
                                            </span>
                                            <ng-template #other_content>0</ng-template>

                                        </td>
                                        <td class="text-center">
                                            <span *ngIf="parts.partcustom==false else other_content">
                                                {{parts.opfromInventory}}
                                            </span>
                                            <ng-template #other_content></ng-template>

                                        </td>

                                        <td class="text-center" *ngIf="parts.partcustom==false">
                                            <span>{{parts.opfromPO}}</span><i class="fa fa-info-circle ml-2"
                                                ngbTooltip="{{parts.oppoDetails}}" style="font-size: 10px !important"
                                                *ngIf="parts.opfromPO!=0"></i>
                                        </td>
                                        <td *ngIf="parts.partcustom==true">
                                        </td>
                                        <td class="text-center">
                                            <span *ngIf="parts.partcustom==false;else other_content">
                                                {{parts.opshipdate}}
                                            </span>
                                            <ng-template #other_content></ng-template>
                                        </td>

                                        <td class="text-center" *ngIf="parts.partcustom==false"
                                            [ngClass]="parts.opqtyToFulfill==0 ? 'tdbg_green' : (parts.opqty > parts.opqtyToFulfill) ? 'tdbg_yellow' : (parts.opqty == parts.opqtyToFulfill) ? 'tdbg_red' : ''">
                                            {{parts.opqtyToFulfill}}
                                        </td>
                                        <td class="text-center" *ngIf="parts.partcustom==true"
                                            style="background-color: #ddd; border: 1px solid #eee;">
                                            0
                                        </td>
                                        <td class="text-center" *ngIf="parts.partcustom==false">
                                            <ng-container
                                                *ngIf="((parts.opfromPO==parts.opfromPORec) && parts.opqtyToFulfill==0) else cross">
                                                <i class="fa fa-check text-success" aria-hidden="true"></i>
                                            </ng-container>
                                            <ng-template #cross>
                                                <i class="fa fa-times text-danger" aria-hidden="true"></i>
                                            </ng-template>
                                        </td>
                                        <td *ngIf="parts.partcustom==true">

                                        </td>
                                    </tr>

                                    <ng-container *ngFor="let partss of parts.newlist; let innerIndex1=index;">
                                        <tr class="hoverEffect table-partdetail partnameA-{{parts.partId}}-detailA">

                                            <td>
                                                <span></span>

                                                <span class="ml-5">
                                                    {{index + 1}}.{{innerIndex + 1}}.{{innerIndex1+1}}
                                                    {{partss.partName}}
                                                </span>

                                            </td>
                                            <td class="text-center">
                                                <span>
                                                    {{partss.vendorName}}
                                                </span>

                                            </td>
                                            <td class="text-center">
                                                {{partss.qty}}
                                            </td>
                                            <td class="text-center">
                                                {{partss.avlbInventory}}
                                            </td>
                                            <td class="text-center">
                                                {{partss.fromInventory}}

                                            </td>
                                            <td class="text-center">
                                                {{partss.fromPO}}
                                                <i class="fa fa-info-circle ml-2" ngbTooltip="{{partss.poDetails}}"
                                                    style="font-size: 10px !important" *ngIf="partss.fromPO!=0"></i>

                                            </td>

                                            <td class="text-center">
                                                {{partss.shipdate}}
                                            </td>
                                            <td class="text-center"
                                                [ngClass]="partss.qtyToFulfill==0 ? 'tdbg_green' : (partss.qty > partss.qtyToFulfill) ? 'tdbg_yellow' : (partss.qty == partss.qtyToFulfill) ? 'tdbg_red' : ''">
                                                {{partss.qtyToFulfill}}
                                            </td>
                                            <td class="text-center">
                                                <ng-container
                                                    *ngIf="((partss.fromPO==partss.fromPORec) && partss.qtyToFulfill==0) else cross">
                                                    <i class="fa fa-check text-success" aria-hidden="true"></i>
                                                </ng-container>
                                                <ng-template #cross>
                                                    <i class="fa fa-times text-danger" aria-hidden="true"></i>
                                                </ng-template>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </ng-container>
                            </ng-container>
                            <tr *ngIf="partCategoriesList!=undefined && partCategoriesList?.length==0">
                                <td>Part Item is not Available</td>
                            </tr>
                            <!-- // add new row -->
                            <tr *ngFor="let category of freeFormPartData;let index=index" class="orange">
                                <!-- <form [formGroup]="formAddFF"> -->
                                <td class="hoverEffect">
                                    <div class="d-flex align-items-center position-relative">
                                        <div class="col-lg-12 row">
                                            <div class="col-md-9">
                                                <span 
                                                    class="openpart">{{category.partName}}</span>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-center">
                                    <div class="col-sm-12">
                                        <span  class="openpart">{{category.vendorName}}</span>
                                    </div>
                                </td>
                                <td class="text-center">
                                    <div class="col-sm-12">
                                        <span  class="openpart">{{category.quantity}}</span>
                                    </div>
                                </td>
                                <td class="text-center">
                                    <span  class="openpart">{{0}}</span>
                                </td>
                                <td class="text-center">
                                    <div class="col-sm-12">
                                        <span  class="openpart">{{0}}</span>
                                    </div>
                                </td>
                                <td class="text-center">
                                    <div class="col-sm-12">
                                        <span class="openpart">{{0}}</span>
                                       
                                    </div>
                                </td>
                                <td class="text-center"> 
                                    <div class="col-sm-12">
                                        <span   class="openpart">N/A</span>
                                    </div>
                                </td>
                                <td class="text-center">
                                    <div class="col-sm-12 rightText">
                                        <span  class="openpart">{{0}}</span>
                                        
                                    </div>
                                </td>
                                <td class="text-center">
                                    <div class="col-sm-12  ">
                                        <i class="fa fa-times text-danger " aria-hidden="true"></i>
                                    </div>
                                </td>
                                <!-- </form> -->

                            </tr>
                            <!-- // end  -->
                        </tbody>
                    </table>
                </ng-container>
                <ng-container *ngIf="SoProfitabilityView==true">
                    <table class="display table clearfix order-detail product_detail_table mt-0">
                        <thead>
                            <tr>
                                <!-- <th></th> -->
                                <th></th>
                                <th colspan="5">Parts</th>
                                <th colspan="7">Labor
                                    <i>({{laborLoadedCost|currency}} / {{laborHrsCost|currency}})</i>
                                </th>
                            </tr>
                            <tr>
                                <th class="text-left">&nbsp;Company Part Name</th>
                                <th>Qty</th>
                                <th>Part Cost</th>
                                <th>Part Cost Ext</th>
                                <th>Part Price</th>
                                <th>Part Price Ext</th>
                                <th>Labor Hrs</th>
                                <th>Labor Hrs Ext</th>
                                <th>Labor Cost Ext</th>
                                <th>Labor Price Ext</th>
                                <th>Total Cost Ext</th>
                                <th>Total Price Ext</th>
                            </tr>
                        </thead>
                        <tbody>

                            <ng-container *ngFor="let category of partCategoriesList;let index=index">
                                <tr class="part-category hoverEffect">
                                    <td>
                                        <span (click)="Openpart(category.categoryId)" class="openpart"
                                            id="openpart-{{category.categoryId}}">
                                            <i class="fas fa-plus-circle"></i>
                                            {{index+1}}.0 {{category.categoryName}}
                                        </span>
                                    </td>
                                </tr>
                                <ng-container *ngFor="let parts of category.partList;let innerIndex=index;">
                                    <tr class="hoverEffect openpart-{{category.categoryId}}-form table-partname">
                                        <td>
                                            <div class="d-flex align-items-center position-relative">
                                                <span class="partdetail" id="partname-{{parts.partId}}">
                                                </span>
                                                <span class="PartName tooltipWrap minusplustxt">
                                                    {{index+1}}.{{innerIndex+1}}
                                                    {{parts.partName}} <span class="partDetails"
                                                        *ngIf="parts.costold==0">
                                                        (CS)</span></span>
                                                <span class="tooltips">{{parts.partName}} <span class="partDetails"
                                                        *ngIf="parts.costold==0" style="color: white;">
                                                        (CS)</span></span>

                                            </div>
                                        </td>
                                        <td class="text-center">
                                            {{parts.quantityOld * this.HashProduct}}
                                        </td>
                                        <td class="text-right">
                                            {{parts.vendorPartCost| currency}}
                                        </td>
                                        <td class="text-right">
                                            {{parts.vendorPartCost*(parts.quantityOld * this.HashProduct) | currency}}
                                        </td>
                                        <td class="text-right">
                                            {{parts.costold| currency}}
                                        </td>
                                        <td class="text-right">
                                            {{parts.costold*(parts.quantityOld * this.HashProduct) | currency}}
                                        </td>
                                        <td class="text-center">
                                            {{parts.hourold |currency}}
                                        </td>
                                        <td class="text-center">
                                            {{(parts.quantity * this.HashProduct) * parts.hourold |currency}}
                                        </td>
                                        <td class="text-right">
                                            {{((parts.quantity * this.HashProduct) * parts.hourold) *
                                            laborLoadedCost|currency}}
                                        </td>
                                        <td class="text-right">
                                            {{((parts.quantity * this.HashProduct) * parts.hourold) *
                                            laborHrsCost|currency}}
                                        </td>
                                        <td class="text-right">
                                            {{(parts.vendorPartCost * (parts.quantityOld * this.HashProduct)) +
                                            ((parts.quantity * this.HashProduct) *
                                            parts.hourold * laborLoadedCost)|currency}}
                                        </td>
                                        <td class="text-right">
                                            {{(parts.costold * (parts.quantityOld * this.HashProduct)) +
                                            ((parts.quantity * this.HashProduct) * parts.hourold *
                                            laborHrsCost)|currency}}
                                        </td>
                                    </tr>


                                    <tr class="table-partdetail partname-{{parts.partId}}-detail">
                                        <td colspan="8">
                                            <div class="qus-title">Questions</div>
                                            <div class="d-flex" *ngFor="let qaItem of parts.questionAnsList;">
                                                <div class="hoverEffect qus mt-2" *ngIf="qaItem.questionId!=0"><b>
                                                        {{qaItem.questionName}} </b> :
                                                    {{qaItem.answerName==null ?'Not Answered':qaItem.answerName}}
                                                    <a class="showBtn green-text" *ngIf="isOrderApproved==false"
                                                        (click)="EditQuestionAnswr(qaItem.questionId,parts.partId)"><i
                                                            class="fas fa-edit"></i></a>
                                                </div>
                                                <div class="hoverEffect qus mt-2" *ngIf="qaItem.questionId==0">
                                                    <b>N/A</b>
                                                </div>
                                                <div
                                                    *ngIf="parts.partId==selectedPartId && qaItem.questionId==selectedQuestionId">
                                                    <div class="col-sm-12">
                                                        <select *ngIf="qaItem.typeKey =='CustomerName'"
                                                            class="form-control newsmallddl"
                                                            [(ngModel)]="qaItem.answerName">
                                                            <option value='null' selected>Select Customer</option>
                                                            <option *ngFor="let item of customerData">
                                                                {{item.customerName}}
                                                            </option>
                                                        </select>
                                                    </div>
                                                    <div class="col-sm-12" *ngIf="qaItem.typeKey =='YesNo'">
                                                        <select class="form-control newsmallddl"
                                                            [(ngModel)]="qaItem.answerName">
                                                            <option value="" selected>Select Yes/No</option>
                                                            <option value="Yes">Yes</option>
                                                            <option value="No">No</option>
                                                        </select>
                                                    </div>
                                                    <div class="col-sm-12" *ngIf="qaItem.typeKey =='VendorName'">
                                                        <select class="form-control newsmallddl"
                                                            [(ngModel)]="qaItem.answerName">
                                                            <option value='null' selected>Select Vendor</option>
                                                            <option *ngFor="let item of vendorList">
                                                                {{item.vendorName}}
                                                            </option>
                                                        </select>
                                                    </div>

                                                    <div class="col-sm-12" *ngIf="qaItem.typeKey =='Text'">
                                                        <input type="text" [(ngModel)]="qaItem.answerName"
                                                            class="form-control" placeholder="Type Answer"
                                                            maxlength="50">
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>

                                </ng-container>

                            </ng-container>
                            <tr *ngIf="partCategoriesList!=undefined && partCategoriesList?.length==0">
                                <td>Part Item is not Available</td>
                            </tr>
                        </tbody>
                        <!-- *ngIf="subSection>0" -->
                        <tfoot class="footOrder tfootnew" [style.display]="subSection>0 ? '' : 'none'">
                            <tr>
                                <td class="tr-bg-clr-none removeBorder"></td>
                                <td class="double-border border_left" align="left">
                                    SubTotal
                                </td>
                                <td class="double-border text-right">
                                    {{totalCost|currency}}
                                </td>
                                <td class="double-border text-right">
                                    {{totalCostExt|currency}}
                                </td>
                                <td class="double-border text-right">
                                    {{totalPrice|currency}}
                                </td>
                                <td class="double-border text-right">
                                    {{totalPriceExt|currency}}
                                </td>
                                <td class="double-border text-center">
                                    {{totalLabor==null || totalLabor==0?"0.00":
                                    totalLabor }}
                                </td>
                                <td class="double-border text-center">
                                    {{laborHrsExt | currency}}
                                </td>
                                <td class="double-border text-right">
                                    {{laborCostExt|currency}}
                                </td>
                                <td class="double-border text-right">
                                    {{laborPriceExt|currency}}
                                </td>
                                <td class="double-border text-right">
                                    {{soTotalCostExt|currency}}
                                </td>
                                <td class="double-border text-right">
                                    {{soTotalPriceExt|currency}}
                                </td>
                            </tr>
                            <tr>
                                <td class="tr-bg-clr-none bb-1"></td>
                                <td colspan="4" align="left" class="border_left">
                                    Total
                                </td>
                                <td colspan="3"></td>
                                <td colspan="3"></td>
                                <td>
                                    {{finalAmount|currency}}
                                </td>
                            </tr>
                            <tr>
                                <td class="tr-bg-clr-none bb-1"></td>
                                <td colspan="4" align="left" class="border_left">
                                    Total Labor Cost
                                </td>
                                <td colspan="3"></td>
                                <td colspan="3"></td>
                                <td>
                                    {{totalLaborCostExt|currency}}
                                </td>
                            </tr>
                            <tr>
                                <td class="tr-bg-clr-none bb-1"></td>
                                <td colspan="4" align="left" class="border_left">
                                    Total Part Cost
                                </td>
                                <td colspan="3"></td>
                                <td colspan="3"></td>
                                <td>
                                    {{totalCostExt|currency}}
                                </td>
                            </tr>
                            <tr>
                                <td class="tr-bg-clr-none bb-1"></td>
                                <td colspan="4" align="left" class="border_left">
                                    Gross Profit
                                </td>
                                <td colspan="3"></td>
                                <td colspan="3"></td>
                                <td>
                                    {{(finalAmount-(totalLaborCostExt+totalCostExt))|currency}}
                                </td>

                            </tr>
                            <tr>
                                <td class="tr-bg-clr-none bb-1"></td>
                                <td colspan="4" align="left" class="border_left">
                                    Part Profit / Markup %
                                </td>
                                <td colspan="3"></td>
                                <td colspan="3"></td>
                                <td>
                                    {{(totalPriceExt-totalCostExt)|currency}}&nbsp;/&nbsp;{{((totalPriceExt-totalCostExt)/totalCostExt
                                    | number : '1.2-2')}}%
                                </td>
                            </tr>
                            <tr>
                                <td class="tr-bg-clr-none bb-1"></td>
                                <td colspan="4" align="left" class="border_left" style="color: black">
                                    Labor Profit / Markup %
                                </td>
                                <td colspan="3"></td>
                                <td colspan="3"></td>
                                <td style="color: black">
                                    {{(totalLaborPriceExt-totalLaborCostExt)|currency}}&nbsp;/&nbsp;{{((totalLaborPriceExt-totalLaborCostExt)/totalLaborCostExt
                                    | number : '1.2-2')}}%
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                </ng-container>
                <div class="white-box no_bg discountSetup col-md-12 pr-0" *ngIf="btnArea==true">
                    <div>
                        <div class="submit-from text-right">

                            <span *ngIf="issaleOrderChecked==false">
                                <button
                                    *ngIf="rolePermissionsList && rolePermissionsList[0].isInsert && btnShowvalidation && btnUpdateOrderValidation==false"
                                    class="btn blue-bg round-btn" (click)="SaveOrder(content)" [disabled]="isPartval"><i
                                        class="fas fa-save" aria-hidden="true"></i> {{saveasDraft}}
                                </button>
                            </span>
                            <span *ngIf="issaleOrderChecked==false">
                                <button
                                    *ngIf="rolePermissionsList && rolePermissionsList[0].isInsert && btnUpdateOrderValidation && IsModalPopup!=true"
                                    class="btn blue-bg round-btn" (click)="UpdateOrder(content,updateOrderId,false)"><i
                                        class="fas fa-save" aria-hidden="true"></i> {{saveasDraft}}
                                </button>
                            </span>
                            <span *ngIf="issaleOrderChecked==false">
                                <button
                                    *ngIf="rolePermissionsList && rolePermissionsList[0].isInsert && isOrderApproved==false && isOrderConfirmType=='product'"
                                    (click)="SaveOrder(true)" class="btn blue-bg round-btn" [disabled]="isPartval"><i
                                        class="fas fa-save" aria-hidden="true"></i> {{saveasOrder}}
                                </button>
                            </span>
                            <span *ngIf="issaleOrderChecked==false">
                                <button
                                    *ngIf="rolePermissionsList && rolePermissionsList[0].isInsert && isOrderApproved==false && isOrderConfirmType=='order'"
                                    (click)="UpdateOrder('',updateOrderId,true)" class="btn blue-bg round-btn"><i
                                        class="fas fa-save" aria-hidden="true"></i> {{saveasOrder}}
                                </button>
                            </span>
                            <span *ngIf="issaleOrderChecked==true">
                                <button *ngIf="rolePermissionsList && rolePermissionsList[0].isInsert"
                                    (click)="ChangeOrder(true)" class="btn blue-bg round-btn" [disabled]="isPartval"><i
                                        class="fas fa-save" aria-hidden="true"></i> Change Order
                                </button>

                            </span>
                            <span *ngIf="issaleOrderChecked==false">
                                <button class="btn darkred-bg round-btn " (click)="DiscrdProduct(discardItem)">
                                    <i class="fas fa-sign-out-alt"></i>Exit </button>
                            </span>

                            <span *ngIf="issaleOrderChecked==true">
                                <button class="btn darkred-bg round-btn " (click)="ChangeOrderExit()">
                                    <i class="fas fa-sign-out-alt"></i>Exit </button>
                            </span>


                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-12">
        <div class="row">
            <div class="col-lg-12 text-left col-md-7 pl-0">
                <span *ngIf="IsEnableAprrovel==true">
                    <button class="btn blue-bg round-btn part-category" (click)="ViewLogDataShow()"><i
                            class="fas fa-down-circle" aria-hidden="true"></i>View Log <i
                            class="ml-1 fas fa-angle-{{ListAngle}}" aria-hidden="true"></i>
                    </button>
                </span>
                <span *ngIf="IsShow==true">
                    <button class="btn blue-bg round-btn part-category" (click)="ViewHistoryLog()"><i
                            class="fas fa-down-circle" aria-hidden="true"></i>SO History Log <i
                            class="ml-1 fas fa-angle-{{ListAngle}}" aria-hidden="true"></i>
                    </button>
                </span>

            </div>

            <div class="col-lg-8 col-md-12 pl-0">
                <div style="display: none;" id="divlog">
                    <table class="table table-bordered mb-0 mt-0 LogContent">
                        <tr style="background-color: #dee2e6;">
                            <td style="width: 16%;"><b>Date time</b></td>
                            <td style="width: 16%;"><b>User name</b></td>
                            <td style="width: 10%;"><b>Action</b></td>
                            <td style="width: 53%;"><b>Reason</b></td>
                            <td style="width: 5%;"><b>View</b></td>
                        </tr>
                        <tr *ngFor="let logitem of SaleOrderLog">
                            <td class="customLabel">{{logitem.createdDate|date: "MMM-dd-yyyy hh:mm:ss a"}}</td>
                            <td class="customLabel">{{logitem.userName}} </td>
                            <td class="customLabel">{{logitem.action}}</td>
                            <td *ngIf="logitem.reason!=null" class="customLabel">{{logitem.reason}}</td>
                            <td *ngIf="logitem.reason==null" class="customLabel">SO-{{logitem.saleOrderNo}}</td>
                            <td class="customLabel" (click)="LogPdfOpen(logitem.attachedPOPDF)"><span
                                    *ngIf="logitem.action=='Mail sent'"><i class="fa fa-paperclip"
                                        style="cursor:pointer" aria-hidden="true" data-toggle="modal"></i></span><span
                                    *ngIf="logitem.action!='Mail sent'">N/A</span></td>
                        </tr>

                    </table>


                </div>
            </div>
            <div class="col-lg-8 col-md-12 pl-0" *ngIf="showHistoryLog==true">
                <div>
                    <table class="table table-bordered mb-0 mt-0 LogContent">
                        <tr style="background-color: #dee2e6;">
                            <td style="width: 16%;"><b>Date time</b></td>
                            <td style="width: 16%;"><b>User name</b></td>
                            <td style="width: 10%;"><b>Action</b></td>
                            <td style="width: 10%;"><b>Status Type</b></td>
                            <!-- <td style="width: 10%;"><b>Reason Type</b></td> -->
                            <td style="width: 53%;"><b>Reason</b></td>
                            <td style="width: 5%;"><b>View</b></td>
                        </tr>
                        <tr *ngFor="let logitem of SaleHistoryLog">
                            <td class="customLabel">{{logitem.createdDate|date: "MMM-dd-yyyy hh:mm:ss a"}}</td>
                            <td class="customLabel">{{logitem.userName}} </td>
                            <td class="customLabel">{{logitem.status}}</td>
                            <td class="customLabel" *ngIf="logitem.status =='Order'">
                                {{logitem.statusId==1?'Open':logitem.statusId==2?'Sale Quote
                                ':logitem.statusId==3?'Cancelled':logitem.statusId==5?'Complete':logitem.statusId==6
                                ?'Processing':logitem.statusId==7 ?'Shipped':logitem.statusId==8 ?'On
                                Hold':logitem.statusId==9 ?'In Production':'' }}</td>
                            <!-- <td class="customLabel">{{logitem.statusType}}</td> -->
                            <td class="customLabel" *ngIf="logitem.status =='Production'">{{logitem.statusId==7?'Not
                                Started':logitem.statusId==3?'Started
                                ':logitem.statusId==4?'On Hold':logitem.statusId==5?'Complete':logitem.statusId==6
                                ?'Cancelled' : '' }}</td>
                            <td class="customLabel">{{logitem.reasonCode}}</td>
                            <td class="customLabel" (click)="LogPdfOpen(logitem.attachedPOPDF)"><span
                                    *ngIf="logitem.action=='Mail sent'"><i class="fa fa-paperclip"
                                        style="cursor:pointer" aria-hidden="true" data-toggle="modal"></i></span><span
                                    *ngIf="logitem.action!='Mail sent'">N/A</span></td>
                        </tr>
                    </table>


                </div>
            </div>
        </div>
    </div>
    <div class="modal" id="addpartModalpopup">
        <div class="modal-dialog  modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
                <h5 class="modal-title info-popup-title p-1" id="exampleModalLabel1">
                    <div class="text-over-ellipse pl-2 text-white">{{partObjectModel.name}}</div>
                </h5>
                <button class="close btn alert_modal" aria-label="Close" (click)="ClosePartModalPopup()">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="row">
                                <div class="col-md-6">

                                    <label class="customLabel">Company Part #</label>
                                </div>
                                <div class="col-md-6 f_size_10 customLabel">
                                    {{partObjectModel.partNo}}


                                </div>
                            </div>
                        </div>
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-4">
                                    <label class="customLabel">Company Part Name</label>
                                </div>
                                <div class="col-md-8 f_size_10 text-over-ellipse customLabel ">
                                    {{partObjectModel.name}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-md-4">
                            <div class="row">
                                <div class="col-md-6">
                                    <label class="customLabel">Vendor Part #</label>
                                </div>
                                <div class="col-md-6 f_size_10 customLabel">
                                    {{partObjectModel.vendorPartNumber}}
                                    <span style="display: none;">{{partObjectModel.vendorName}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-4">
                                    <label class="customLabel">Vendor Part Name</label>
                                </div>
                                <div class="col-md-8 f_size_10 text-over-ellipse customLabel">
                                    {{partObjectModel.vendorPartName}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-md-2">
                            <div class="d-flex align-items-center">
                                <label class="customLabel mr-2">Quantity</label>
                                <input type="text" [(ngModel)]="partObjectModel.qty" class="customInput NumberOnly"
                                    placeholder="Qty" id="quantity" maxlength="5" min="1" max="99">
                                <div *ngIf="((quantity=='') && (validationPart))" class="text-danger input-error">
                                    Enter Quantity.</div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-md-12">
                            <div class="customHeading">
                                Part Questions
                            </div>
                        </div>
                        <div class="col-md-12 mt-2">
                            <div class="row pb-2" *ngFor="let item of partObjectModel.partQuestions;let i=index">
                                <div class="col-md-8">
                                    <label class="customLabel">{{item.questionName}}</label>
                                </div>

                                <div class="col-md-4" *ngIf="item.typeKey =='Text'">
                                    <input type="text" [(ngModel)]="item.answerName" class="customInput"
                                        placeholder="Type Answer" maxlength="50">
                                </div>
                                <div class="col-md-4" *ngIf="item.typeKey =='VendorName'">
                                    <select class="form-control newsmallddl" [(ngModel)]="item.answerName">
                                        <option value='null'>Select Vendor</option>
                                        <option *ngFor="let item of vendorList">{{item.vendorName}}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-4" *ngIf="item.typeKey =='CustomerName'">
                                    <select class="form-control newsmallddl " [(ngModel)]="item.answerName">
                                        <option value='null' selected>Select Customer</option>
                                        <option *ngFor="let item of customerData">
                                            {{item.customerName}}
                                        </option>

                                    </select>
                                </div>
                                <div class="col-sm-4" *ngIf="item.typeKey =='YesNo'">
                                    <select class="form-control newsmallddl" [(ngModel)]="item.answerName">
                                        <option value='null' selected>Select Yes/No</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="submit-from text-right">
                        <button class="btn blue-bg round-btn" (click)="addPart(partObjectModel)"><i class="fas fa-save"
                                aria-hidden="true"></i> Add Part
                        </button>
                        <button class="btn darkred-bg round-btn" (click)="ClosePartModalPopup()"><i
                                aria-hidden="true"></i> Exit
                        </button>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <!-- export pdf -->
    <div style="display: none;">
        <div class="row" id="content" style="width: 800px !important;" #content>
            <style type="text/css" media="only print and (max-width: 1920px)">
                * {
                    box-sizing: border-box;
                }

                #content .table td,
                #content1 .table th {
                    padding: 4px !important;
                    font-weight: 700 !important;
                    height: 23px;
                }

                #content {
                    font-family: Arial, Helvetica, sans-serif;
                }

                #content {
                    max-width: 800px;
                    margin: auto;
                    padding: 20px 10px 10px;
                }

                .container .row>div>div {
                    font-size: 0.7rem;

                }

                #content .vdetails,
                #content .cdetails,
                #content .sidedetails {
                    display: flex;
                }

                #content .vdetails p,
                #content .cdetails p,
                #content .vdetails label,
                #content .cdetails label {
                    white-space: nowrap;
                    text-align: left !important;
                }

                #content .vdetails p,
                #content .cdetails p,
                #content .sidedetails p {
                    font-weight: Bold !important;
                    margin-left: 5px !important;
                    margin-bottom: 0 !important;
                    text-align: left !important;
                }

                #content table.order-detail tr th:nth-child(2),
                table.order-detail tr td:nth-child(2) {
                    text-align: left;
                }

                #content .qus {
                    font-size: 0.8rem;
                }

                #content .order-detail {
                    font-size: 0.8rem;
                }

                #content .order-detail tfoot td:first-child {
                    border: none;
                }

                #content .order-detail-main table {
                    border-collapse: collapse;
                    border: 1px solid #000;
                    font-size: 0.6rem;
                }

                #content .order-detail-main table tr td,
                #content .order-detail-main table tr th {
                    font-size: 12px;
                    font-weight: bold;
                }

                #content .order-detail-main tr,
                #content .order-detail-main td {
                    border: none !important;
                }

                #content .order-detail-main table thead tr th,
                #content .order-detail-main table tbody tr td,
                #content .order-detail-main table tfoot tr td {
                    padding: 2px 0.5rem;
                }

                #content .order-detail-main table thead tr th {
                    background-color: transparent;
                    background-color: transparent;
                    border-right: 1px solid #000 !important;
                    border-bottom: 1px solid #000 !important;
                    border-left: none;
                    border-top: none;
                }

                #content .order-detail-main table tr td {
                    border-bottom: 1px solid #000 !important;
                }

                #content .order-detail-main table tr td:first-child {
                    border-right: 1px solid #000 !important;
                }

                #content .order-detail-main table tr th:last-child {
                    text-align: right;
                }

                #content .order-detail-main table tr td:last-child {
                    border-left: 1px solid #000 !important;
                    text-align: right;
                }

                #content .order-detail-main table tfoot tr td {
                    /* font-size: 0.6rem; */
                    font-weight: bold;
                }

                .part-category td {
                    background: #dadada !important;

                }

                #content .paddingLeft {
                    padding-left: 30% !important;
                }

                #content .paddingLeft label {
                    margin-bottom: 0;
                }

                .subCate td:nth-child(2) {
                    padding-left: 20px !important;
                }

                #content .cdetails,
                #content .vdetails {
                    display: block;
                }

                #content .cdetails label {
                    margin-bottom: 0.2rem;
                }

                #content .vdetails label {
                    margin-bottom: 0.2rem;
                }

                #content .cdetails p,
                #content .vdetails p {
                    display: inline-block;
                }

                #content .order-detail-main .order-detail {
                    margin-top: 5px;
                }

                .pdfFont p,
                .pdfFont label,
                .pdfFont b,
                .pdfFont strong,
                .pdfFont span,
                .pdfFont table tr th,
                .pdfFont table tr td {
                    font-family: Arial, Helvetica, sans-serif;
                }

                .cdetails label,
                .cdetails p,
                .vdetails p {
                    font-size: 11px;
                }

                @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap');
            </style>
            <div class="container pdfFont">
                <div class="row">
                    <div class="col-md-12 text-center">
                        <p *ngIf="this.profileLogo">
                            <img src="this.profileLogo" class="hrdLogoSize" id="hrdlogoImage1">
                        </p>
                        <p
                            style="margin-bottom: -8px; font-size: 0.7rem; margin-top: 0.5rem; font-weight: bold; font-family: Arial, Helvetica, sans-serif;">
                            {{companyDetails.companyName}}</p>
                        <p style="margin-bottom: 0.5rem; font-size: 0.7rem; margin-top: 0.5rem; font-weight: bold; font-family: Arial, Helvetica, sans-serif;"
                            [innerHtml]="this.sharedService.FormatAddress(companyDetails.addressLine1,
                companyDetails.addressLine2, companyDetails.city, '',
                companyDetails.zipCode)">
                            {{companyDetails.phoneNumber}}
                        </p>
                    </div>
                </div>
                <div class="row mt-3">
                    <div class="col-md-5 text-left">
                        <div>
                            <div class="vdetails">
                                <p style="font-family: Arial, Helvetica, sans-serif;">{{customerDetails.customerName}}
                                </p>
                            </div>
                            <div class="vdetails">
                                <p style="white-space: break-spaces; font-family: Arial, Helvetica, sans-serif;"
                                    [innerHtml]="this.sharedService.FormatAddress(customerDetails.addressLine1,
                                customerDetails.addressLine2, customerDetails.city, customerDetails.statename,
                                customerDetails.zipCode)">
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-3"></div>
                    <div class="col-md-4">
                        <div style="padding: 10px; border: 1px solid #000;">
                            <div class="cdetails d-flex justify-content-start align-items-center">
                                <label
                                    style="font-family: Arial, Helvetica, sans-serif; margin-bottom: 0;text-align: left; font-weight: bold;">Contact:</label>
                                <p
                                    style="font-family: Arial, Helvetica, sans-serif; text-align: left; white-space: nowrap; font-weight: normal !important; margin-bottom: 0 !important;">
                                    {{customerDetails.firstName}}{{customerDetails.lastName}}</p>
                            </div>
                            <div class="cdetails d-flex justify-content-start">
                                <label
                                    style="font-family: Arial, Helvetica, sans-serif; margin-bottom: 0; text-align: left; font-weight: bold;">Phone:</label>
                                <p
                                    style="font-family: Arial, Helvetica, sans-serif; white-space:nowrap; text-align: left; font-weight: normal !important; margin-bottom: 0 !important;">
                                    {{customerDetails.phone}}</p>
                            </div>
                            <div class="cdetails d-flex justify-content-start">
                                <label
                                    style="font-family: Arial, Helvetica, sans-serif; margin-bottom: 0;text-align: left; font-weight: bold;">Email:</label>
                                <p
                                    style="font-family: Arial, Helvetica, sans-serif; white-space: pre-wrap;text-align: left; font-weight: normal !important; margin-bottom: 0 !important;">
                                    {{customerDetails.email}}</p>
                            </div>
                            <div class="cdetails d-flex justify-content-start">
                                <label
                                    style="font-family: Arial, Helvetica, sans-serif; margin-bottom: 0;text-align: left; font-weight: bold;">
                                    {{statusDropdownList==2? 'Sales Quote #': 'Sale Order #'}} :
                                </label>
                                <!-- <p style="font-family: 'Open Sans', sans-serif;width: 70px;white-space: pre-wrap;text-align: left;">{{orderNumber==''||orderNumber==null||orderNumber==undefined?'Pending':orderNumber}}</p> -->
                                <p
                                    style="font-family: Arial, Helvetica, sans-serif; white-space: pre-wrap;text-align: left; font-weight: 300 !important; margin-bottom: 0 !important;">
                                    {{orderNumber==''||orderNumber==null||orderNumber==undefined?'Pending':orderNumber}}
                                </p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <div class="col-sm-12 pdfFont">

                <div class="order-detail-main">
                    <table class="display table clearfix order-detail">
                        <thead>
                            <tr>
                                <th style="font-size: 0.7rem !important; font-family: Arial, Helvetica, sans-serif;"
                                    width="5%">Qty</th>
                                <th width="75%"
                                    style="font-size: 0.7rem !important; font-family: Arial, Helvetica, sans-serif;">
                                    Breakdown with labor:</th>
                                <th width="20%"
                                    style="font-size: 0.7rem !important; font-family: Arial, Helvetica, sans-serif;">
                                    Extension Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let category of partCategoriesList;let index=index">
                                <tr class="part-category">
                                    <td></td>
                                    <td
                                        style="font-size: 0.7rem !important; font-family: Arial, Helvetica, sans-serif;">
                                        <span (click)="Openpart(category.categoryId)" class="openpart"
                                            id="openpart-{{category.categoryId}}"><i class="fas fa-plus-circle"
                                                style="display: none;"></i></span>
                                        {{category.categoryName}}
                                    </td>
                                    <td></td>
                                </tr>
                                <ng-container *ngFor="let parts of category.partList;let innerIndex=index;">
                                    <tr class="subCate">
                                        <td
                                            style="font-size: 0.7rem !important; text-align: center; font-family: Arial, Helvetica, sans-serif;">
                                            {{parts.quantity}}
                                        </td>
                                        <td
                                            style="font-size: 0.7rem !important; font-family: Arial, Helvetica, sans-serif;">
                                            <span class="partdetail" (click)="OpenQuestion(parts.partId, 'open')"
                                                id="partname-{{parts.partId}}"> <i class="fas fa-plus"
                                                    style="display: none;"></i>
                                            </span>{{parts.partName}}<span *ngIf="parts.cost==0"> (CS)</span>
                                        </td>
                                        <td
                                            style="font-size: 0.7rem !important; font-family: Arial, Helvetica, sans-serif;">
                                            {{(parts.cost*parts.quantity)+(parts.hours*laborHrsCost*parts.quantity)
                                            |currency}}</td>
                                    </tr>
                                </ng-container>
                            </ng-container>
                            <tr>
                                <td style="padding: .8rem;"></td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <td style="padding: .8rem;"></td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                        <tfoot>
                            <tr>
                                <td colspan=""></td>
                                <td style="font-size: 0.7rem !important;" class="discount paddingLeft" colspan="">
                                    <label style="padding-left: 400px; font-size: 0.7rem !important;">
                                        <b
                                            style="font-size: 0.7rem !important; font-family: Arial, Helvetica, sans-serif;">
                                            SUBTOTALS </b>
                                    </label>
                                </td>
                                <td style="font-size: 0.7rem !important; font-family: Arial, Helvetica, sans-serif;"
                                    class="discount">
                                    {{GrandTotalAmount|currency}}
                                </td>
                            </tr>
                            <!-- <tr> -->
                            <tr *ngIf="discount!=0">
                                <td colspan=""></td>
                                <td style="font-size: 0.7rem !important;" class="discount paddingLeft" colspan="">
                                    <label style="padding-left: 400px; ">
                                        <b
                                            style="font-size: 0.7rem !important; font-family: Arial, Helvetica, sans-serif;">
                                            DISCOUNT</b>
                                    </label>
                                </td>
                                <td style="font-size: 0.7rem !important;" class="discount">
                                    <span
                                        style="color: #f00; font-family: Arial, Helvetica, sans-serif;">{{discount|currency}}</span>
                                </td>
                            </tr>
                            <tr>
                                <td style="font-size: 0.7rem !important;" colspan=""></td>
                                <td class="discount paddingLeft" colspan="">
                                    <label style="padding-left: 400px;">
                                        <b
                                            style="font-size: 0.7rem !important; font-family: Arial, Helvetica, sans-serif;">
                                            SUBTOTAL DISCOUNT</b>
                                    </label>
                                </td>
                                <td style="font-size: 0.7rem !important; font-family: Arial, Helvetica, sans-serif;"
                                    class="discount">
                                    {{SubTotalWithDisnt|currency}}
                                </td>
                            </tr>
                            <tr>
                                <td colspan=""></td>
                                <td style="font-size: 0.7rem !important;" class="discount paddingLeft" colspan="">
                                    <label style="padding-left: 400px;">
                                        <b
                                            style="font-size: 0.7rem !important; font-family: Arial, Helvetica, sans-serif;">
                                            SALES TAX({{SaleTaxAmount}}%) </b>
                                    </label>
                                </td>
                                <td style="font-size: 0.7rem !important; font-family: Arial, Helvetica, sans-serif;"
                                    class="discount">
                                    {{TaxTotalAmount|currency}}
                                </td>
                            </tr>
                            <tr>
                                <td style="font-family: Arial, Helvetica, sans-serif;"></td>
                                <td style="font-family: Arial, Helvetica, sans-serif;" class="discount paddingLeft">
                                    <label style="padding-left: 400px;">
                                        <b
                                            style="color: rgb(9, 95, 23); font-size: 0.7rem !important; font-family: Arial, Helvetica, sans-serif;">TOTAL</b>
                                    </label>

                                </td>
                                <td style="font-size: 0.7rem !important;" class="discount"
                                    style="font-family: Arial, Helvetica, sans-serif; text-align: right; font-weight: bold;color: rgb(9, 95, 23); font-size: 0.7rem !important;">
                                    {{finalAmount|currency}}
                                </td>
                            </tr>
                        </tfoot>
                    </table>
                    <p style="font-family: Arial, Helvetica, sans-serif; font-size: 11px;">{{(companyDetails.notes ==
                        "null")
                        ? ""
                        :companyDetails.notes}}</p>

                </div>

                <div>
                    <div class="submit-from text-right">
                    </div>
                </div>

            </div>

        </div>
    </div>
    <!-- end export pdf -->

    <!-- export word -->

    <div class="row" id="contentWord" #contentWord>
        <style>
            .mainword-tbl {
                font-family: 'Open Sans', sans-serif;
            }

            .word-tbl-style {
                border-collapse: collapse;
            }

            .word-tbl-style tr td {
                border: 1px solid #000000 !important;
                text-align: left;
                font-weight: bold;
                padding-left: 10px;
                padding-right: 10px;
            }

            .no-border-tbl tr td {
                border: none !important;
            }
        </style>

        <table width="100%" cellpadding="0" cellspacing="0" class="mainword-tbl" style="border: none; margin: 0px;">
            <tr>
                <td>
                    <table width="100%" align="center" style="text-align: center; border: none; margin: 0px;">
                        <tr *ngIf="this.profileLogo">
                            <td><img style="height: 200px;" src="this.profileLogo" id="hrdlogoImage2"></td>
                        </tr>
                        <tr>
                            <td style="text-align: center;">
                                <table width="100%" cellpadding="0" cellspacing="0" align="center"
                                    style=" margin: 0px; text-align: center; border: none;">
                                    <tr>
                                        {{companyDetails.companyName}}
                                    </tr>
                                    <tr>
                                        <td [innerHtml]="this.sharedService.FormatAddress(companyDetails.addressLine1,
                                        companyDetails.addressLine2, companyDetails.city, '',
                                        companyDetails.zipCode)">
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>{{companyDetails.phoneNumber}}</td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
            <tr>
                <td>
                    <table width="100%" style="border: none; margin: 0px;" cellpadding="0" cellspacing="0">
                        <tr>
                            <td style="vertical-align: top; font-size: 12px;" width="50%">
                                <table width="100%" cellpadding="0" cellspacing="0"
                                    style="border: none; margin: 0px; text-align: left;">
                                    <tr>
                                        <td style="font-weight: bold;">{{customerDetails.customerName}}</td>
                                    </tr>
                                    <tr>
                                        <td style="font-weight: bold;" [innerHtml]="this.sharedService.FormatAddress(customerDetails.addressLine1,
                                        customerDetails.addressLine2, customerDetails.city, customerDetails.statename,
                                        customerDetails.zipCode)">
                                        </td>
                                    </tr>
                                </table>
                            </td>
                            <td align="right"
                                style="text-align: right; vertical-align: top; font-size: 12px;border: 1px solid #000;padding: 10px;"
                                width="30%">
                                <table width="100%" cellpadding="0" cellspacing="0"
                                    style="text-align: right; margin: 0px; border: none">
                                    <tr>
                                        <td style="text-align: left;width: 40%;">Contact:</td>
                                        <td style="text-align: left;"><b
                                                style="min-width: 30px;display: inline-block;">{{customerDetails.firstName}}{{customerDetails.lastName}}</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="text-align: left;width: 40%;">Phone:</td>
                                        <td style="text-align: left;"><b
                                                style="min-width: 30px;display: inline-block;">{{customerDetails.phone}}</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="text-align: left;width: 40%;">Email:</td>
                                        <td style="text-align: left;"><b
                                                style="min-width: 30px;display: inline-block;">{{customerDetails.email}}</b>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td style="text-align: left;width: 40%;">
                                            {{statusDropdownList==2? 'Sales Quote #': 'Sale Order #'}} :
                                        </td>
                                        <td style="text-align: left;"><b
                                                style="min-width: 30px;display: inline-block;">{{orderNumber==''||orderNumber==null||orderNumber==undefined?'Pending':orderNumber}}</b>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                        </tr>
                    </table>
                </td>
            </tr>
            <tr>
                <td style='padding-top: 5px;'>
                    <table border="1" bordercolor="black" style="border-collapse: collapse;  margin: 0px;"
                        cellpadding="0" cellspacing="0" width="100%" class="word-tbl-style">
                        <tr>
                            <td style="padding: 0px 5px 0px 5px;">Qty</td>
                            <td style="padding: 0px 5px 0px 5px;">Breakdown with labor:</td>
                            <td style="text-align: right; padding: 0px 5px 0px 5px;">Extension Total</td>
                        </tr>
                        <ng-container *ngFor="let category of partCategoriesList;let index=index">

                            <tr style="background: #dadada;">
                                <td style="padding: 0px 5px 0px 5px;">&nbsp;</td>
                                <td style="padding: 0px 5px 0px 5px;"> {{category.categoryName}}</td>
                                <td>&nbsp;</td>
                            </tr>
                            <ng-container *ngFor="let parts of category.partList;let innerIndex=index;">
                                <tr>
                                    <td style="padding: 0px 5px 0px 5px;">{{parts.quantity}}</td>
                                    <td style="padding: 0px 5px 0px 5px;">{{parts.partName}}
                                    </td>
                                    <td style="text-align: right; padding: 0px 5px 0px 5px;">
                                        {{(parts.cost*parts.quantity)+(parts.hours*laborHrsCost*parts.quantity)
                                        |currency}}</td>
                                </tr>
                            </ng-container>
                        </ng-container>
                        <!-- <tr>
                            <td style="padding: 0px 5px 0px 5px;">1</td>
                            <td style="padding: 0px 5px 0px 5px;">Air Horn with Compressor and Tank with Foot Switch
                            </td>
                            <td style="text-align: right; padding: 0px 5px 0px 5px;">$124.00</td>
                        </tr> -->
                        <!-- <tr>
                            <td style="padding: 0px 5px 0px 5px;">1</td>
                            <td style="padding: 0px 5px 0px 5px;">Siren speakers mounted behind grill </td>
                            <td style="text-align: right; padding: 0px 5px 0px 5px;">$1,202.24</td>
                        </tr> -->
                        <tr>
                            <td style="padding: 0px 5px 0px 5px;">&nbsp;</td>
                            <td style="padding: 0px 5px 0px 5px;">&nbsp;</td>
                            <td style="text-align: right; padding: 0px 5px 0px 5px;">&nbsp;</td>
                        </tr>
                        <tr>
                            <td style="padding: 0px 5px 0px 5px;">&nbsp;</td>
                            <td style="padding: 0px 5px 0px 5px;">&nbsp;</td>
                            <td style="text-align: right; padding: 0px 5px 0px 5px;">&nbsp;</td>
                        </tr>
                        <tr>
                            <td style="padding: 0px 5px 0px 5px;"></td>
                            <td>
                                <table width="100%" cellpadding="0" cellspacing="0" class="no-border-tbl"
                                    style=" margin: 0px;">
                                    <tr>
                                        <td width="50%"></td>
                                        <td width="50%" style="padding:0px 5px 0px 5px;">SUBTOTALS</td>
                                    </tr>
                                </table>
                            </td>
                            <td style="text-align: right; padding: 0px 5px 0px 5px;"> {{GrandTotalAmount|currency}}</td>
                        </tr>
                        <tr>
                            <td style="padding: 0px 5px 0px 5px;">&nbsp;</td>
                            <td>
                                <table width="100%" cellpadding="0" cellspacing="0" class="no-border-tbl"
                                    style=" margin: 0px;">
                                    <tr>
                                        <td width="50%"></td>
                                        <td width="50%" style="padding: 0px 5px 0px 5px;">DISCOUNT</td>
                                    </tr>
                                </table>
                            </td>
                            <td style="text-align: right; color: #f00; padding: 0px 5px 0px 5px;">{{discount|currency}}
                            </td>
                        </tr>
                        <tr>
                            <td style="padding: 0px 5px 0px 5px;">&nbsp;</td>
                            <td>
                                <table width="100%" cellpadding="0" cellspacing="0" class="no-border-tbl"
                                    style=" margin: 0px;">
                                    <tr>
                                        <td width="50%"></td>
                                        <td width="50%" style="padding: 0px 5px 0px 5px;">SUBTOTAL DISCOUNT</td>
                                    </tr>
                                </table>
                            </td>
                            <td style="text-align: right; padding: 0px 5px 0px 5px;"> {{SubTotalWithDisnt|currency}}
                            </td>
                        </tr>
                        <tr>
                            <td style="padding: 0px 5px 0px 5px;">&nbsp;</td>
                            <td>
                                <table width="100%" cellpadding="0" cellspacing="0" class="no-border-tbl"
                                    style=" margin: 0px;">
                                    <tr>
                                        <td width="50%"></td>
                                        <td width="50%" style="padding: 0px 5px 0px 5px;">SALES TAX({{SaleTaxAmount}}%)
                                        </td>
                                    </tr>
                                </table>
                            </td>
                            <td style="text-align: right; padding: 0px 5px 0px 5px;"> {{TaxTotalAmount|currency}}</td>
                        </tr>
                        <tr>
                            <td style="padding: 0px 5px 0px 5px;">&nbsp;</td>
                            <td>
                                <table width="100%" cellpadding="0" cellspacing="0" class="no-border-tbl"
                                    style=" margin: 0px;">
                                    <tr>
                                        <td width="50%"></td>
                                        <td width="50%" style="color: #095f17; padding: 0px 5px 0px 5px;">TOTAL</td>
                                    </tr>
                                </table>
                            </td>
                            <td style="text-align: right; color: #095f17; padding: 0px 5px 0px 5px;">
                                {{finalAmount|currency}}</td>
                        </tr>
                    </table>
                </td>
            </tr>
            <tr>
                <td style="font-size: 12px; padding-top:20px; text-align: left;">{{(companyDetails.notes == "null") ? ""
                    :companyDetails.notes}}</td>
            </tr>
        </table>
    </div>
    <!-- end export word -->

    <!-- end export word -->





    <div class="modal" id="addpartModal1">
        <div class="modal-dialog  modal-dialog-centered modal-lg centModel" role="document">
            <div class="modal-content">
                <h5 class="modal-title info-popup-title p-1 pl-2" id="exampleModalLabel1">Add Part -
                    {{CategoryshowonPopup}}</h5>
                <button class="close btn alert_modal" aria-label="Close" (click)="PartItemExit()">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="modal-body">
                    <div class="row mb-1">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-4 col-12">
                                    <div class="d-flex align-items-center">
                                        <label class="customLabel mr-2" for="Vendor">Vendor</label>
                                        <select class="customInput newsmallddl" [(ngModel)]="selectedVendorValue">
                                            <option value='' selected>All</option>
                                            <option *ngFor="let item of NewVendorList" [value]="item.id">
                                                {{item.vendorName}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-12 mt-lg-0 mt-1">

                                    <div class="d-flex align-items-center">
                                        <select class="customInput pl-1px"
                                            (change)="ChangefieldToSearch($event.target.value)"
                                            [(ngModel)]="fieldToSearch" id="fieldToSearch">
                                            <option value='1'>Search Keywords</option>
                                            <option value='2'>List or Vendor Part Name</option>
                                            <option value='3'>List or Vendor Part #</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-3 col-11 pr-md-2 mt-md-0 mt-1">
                                    <div class="d-flex align-items-center">
                                        <input type="text" class="customInput"
                                            placeholder="{{fieldToSearchPlaceHolder}}" [(ngModel)]="PartName_search"
                                            autocomplete="off" (keyup.enter)="SearchByvendorList()">
                                    </div>
                                </div>
                                <div class="col-md-1 col-1 text-left or_width"
                                    style="max-width: 30px; padding:0; margin-top: 3px;">
                                    <i class="fas fa-search cpointer" (click)="SearchByvendorList()"></i>
                                </div>
                                <div class="col-md-1 col-1 text-left or_width">
                                    <button type="button" class="btn blue-bg all-custom round-btn m-0 centbutton"
                                        (click)="SearchByvendorListAll()">All Parts</button>
                                </div>
                                <!-- <div class="col-md-5 col-11 pr-md-2 mt-md-0 mt-1">
                                    <div class="d-flex align-items-center">
                                        <label class="customLabel ws-nobreak pr-1">Part Name/Number</label>
                                        <input type="text" class="customInput" [(ngModel)]="PartName_search" />

                                    </div>
                                </div>
                                <div class="col-md-1 col-1 text-left or_width"
                                    style="max-width: 30px; padding:0; margin-top: 3px;">
                                    <i class="fas fa-search cpointer ml-2" (click)="SearchByvendorList()"></i>
                                </div> -->
                            </div>
                        </div>



                    </div>
                    <hr>
                    <div class="white-box1" [style.display]="ListShow==true ? 'block' : 'none'">
                        <div class="white-box-title1">
                            <div class="customHeading">
                                Part List
                            </div>
                        </div>
                        <!------------------------------------- company list table ------------------------------>
                        <div class="card-desc1 mt-1 table-responsive autoWidth">
                            <table id="datatable" class="display table clearfix" class="row-border hover table-cursor"
                                datatable [dtOptions]="dtOptions1" [dtTrigger]="dtTrigger1">
                                <thead>
                                    <tr>
                                        <th class="coloumn-hide"></th>
                                        <th (click)="SortTableHeader('companyName1','parts')">Company</th>
                                        <th (click)="SortTableHeader('name','parts')">List Part Name<span id="name"
                                                class="sorting2"></span></th>
                                        <th (click)="SortTableHeader('partNo','parts')">List Part#<span id="partNo"
                                                class="sorting2"></span></th>
                                        <th (click)="SortTableHeader('vendorName','parts')">Vendor Name<span
                                                id="vendorName" class="sorting2"></span></th>
                                        <!-- <th (click)="SortTableHeader('partCategoryName','parts')">Category<span id="partCategoryName" class="sorting2"></span></th> -->
                                        <th (click)="SortTableHeader('vendorPartName','')">Vendor Part Name<span
                                                id="vendorName" class="sorting1"></span></th>
                                        <th (click)="SortTableHeader('vendorPartNumber','')">Vendor Part#<span
                                                id="vendorName" class="sorting1"></span></th>
                                        <th (click)="SortTableHeader('cost','parts')">Part Price<span id="cost"
                                                class="sorting2"></span></th>
                                        <th class="width-75" (click)="SortTableHeader('hours','parts')">Labor Hrs<span
                                                id="hours" class="sorting2"></span></th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="ddlPartList?.length != 0">
                                    <tr *ngFor="let data of ddlPartList" (click)="PartdetailsModalPopup(data.id);">
                                        <td class="coloumn-hide">{{data.id}}</td>
                                        <td><a>{{data.companyName}}</a></td>
                                        <td class="text-over-ellipse-td">
                                            <span class="tooltip-text"><a>{{data.name}}</a></span>
                                            <span class="tooltip-td">{{data.name}}</span>
                                        </td>
                                        <td><a>{{data.partNo}}</a></td>
                                        <td><a>{{data.vendorName}}</a></td>
                                        <!-- <td><a>{{data.partCategoryName}}</a></td> -->
                                        <td><a>{{data.vendorPartName}}</a></td>
                                        <td><a>{{data.vendorPartNumber}}</a></td>
                                        <td><a>{{data.cost|currency}}</a></td>
                                        <td><a>{{data.hours|number: '1.2-2'}}</a></td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="ddlPartList==null">
                                    <tr class="c1">
                                        <td colspan="8" class="no-data-available">No data available in table</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                    </div>

                    <div class="submit-from text-right">
                        <!-- <button class="btn blue-bg round-btn" (click)="addPart(partObjectModel)"><i class="fas fa-save"
                                aria-hidden="true"></i> Add Part
                        </button> -->
                        <button class="btn darkred-bg round-btn" (click)="PartItemExit();"><i aria-hidden="true"></i>
                            Exit </button>

                    </div>
                </div>

            </div>
        </div>
    </div>


    <div class="modal" id="SaleorderNumber">
        <div class="modal-dialog">
            <div class="modal-content">

                <div class="modal-header info-popup-header p-2">
                    <button type="button" class="close" (click)="SaleorderNumber()" data-dismiss="modal"
                        aria-label=""><span>×</span></button>
                </div>
                <div class="modal-body">
                    <div class="thank-you-pop">
                        <img src="./assets/image/Green-Round-Tick.png" alt="">
                        <h1>Thank you!</h1>
                        <p>Your Sale Order Has Been Saved</p>

                        <h3 class="cupon-pop" style="font-size:12px;">Your Sale Order Number:
                            <span>{{SOGenerateNumber}}</span>
                        </h3>
                        <p class="mb-0">
                            <button class="btn blue-bg round-btn" (click)="DownloadPdf()">Export PDF&nbsp;&nbsp;<i
                                    class="fa fa-file-pdf-o text-white" aria-hidden="true"></i></button>
                            <button class="btn blue-bg round-btn" (click)="PopSendMail()">Send Email&nbsp;&nbsp;<i
                                    class="fa fa-file-pdf-o text-white" aria-hidden="true"></i></button>
                            <!-- <button class="btn blue-bg round-btn" (click)="Stay(this.SendMailSoId)" style="padding: 3px 12px;width: 100px;">Stay&nbsp;&nbsp;</button> -->

                        </p>
                    </div>
                    <div class="modal-footer sweet-footer p-1">
                        <button type="button" class="btn blue-bg round-btn" (click)="Stay(this.SendMailSoId)"
                            data-dismiss="modal">Stay</button>
                        <button type="button" class="btn red-bg round-btn" (click)="SaleorderNumber()"
                            data-dismiss="modal">Exit</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- -- -->

    <ng-template #content let-c="close" let-d="dismiss">
        <h4 class="modal-title darkred-bg alert_heading sweet-heading"></h4>
        <button class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-body p-1">
            <div class="thank-you-pop">
                <img src="./assets/image/popup-alert.png" alt="">
                <h1>Alert</h1>
                <p class="mt-1">{{confirmationMsg}}</p>
            </div>
        </div>
        <div class="modal-footer sweet-footer text-center p-1">
            <button *ngIf="confirmationAletType=='ConfirmCategory'" type="button" class="btn green-bg round-btn"
                (click)="c('Close click')">Ok</button>
            <button
                *ngIf="rolePermissionsList && rolePermissionsList[0].isInsert && confirmationAletType=='ConfirmOrderSave'"
                type="button" class="btn green-bg round-btn" (click)="SaveOrder(); c('Close click')">Yes</button>
            <button
                *ngIf="rolePermissionsList && rolePermissionsList[0].isInsert && confirmationAletType=='ConfirmOrderUpdate'"
                type="button" class="btn green-bg round-btn m-0 "
                (click)="UpdateOrder(content,updateOrderId); c('Close click')">Yes</button>
            <button type="button" class="btn red-bg round-btn" (click)="c('Close click')">No</button>

        </div>
    </ng-template>

</section>
<ng-template #contentname let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg alert_heading sweet-heading"></h4>
    <button class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>

    <div class="modal-body p-1">
        <div class="thank-you-pop">
            <img src="./assets/image/popup-alert.png" alt="">
            <h1>Confirmation</h1>
            <p class="mt-1">{{deleteDetail}}</p>
        </div>
    </div>
    <div class="modal-footer sweet-footer text-center p-1">
        <button type="button" class="btn green-bg round-btn m-1" (click)="DeletePart(); c('Close click')">Yes</button>
        <button type="button" class="btn red-bg round-btn m-1" (click)="c('Close click')">No</button>
    </div>
</ng-template>
<ng-template #contentcategory let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg alert_heading sweet-heading"></h4>
    <button class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>

    <div class="modal-body p-1">
        <div class="thank-you-pop">
            <img src="./assets/image/popup-alert.png" alt="">
            <h1>Confirmation</h1>
            <p class="mt-1">{{deleteDetail}}</p>
        </div>
    </div>
    <div class="modal-footer sweet-footer text-center p-1">
        <button type="button" class="btn green-bg round-btn m-1"
            (click)="RemoveCategory(); c('Close click')">Yes</button>
        <button type="button" class="btn red-bg round-btn m-1" (click)="c('Close click')">No</button>
    </div>
</ng-template>

<ng-template #statuscontent let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg alert_heading sweet-heading"></h4>
    <button class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body p-1">
        <div class="thank-you-pop">
            <img src="./assets/image/popup-alert.png" alt="">
            <h1>Confirmation</h1>
            <p class="mt-1">{{deleteDetail}}</p>
        </div>
    </div>
    <div class="modal-footer sweet-footer text-center p-1">
        <button type="button" class="btn green-bg round-btn m-1" (click)="UpdateStatus(); c('Close click')">Yes</button>
        <button type="button" class="btn red-bg round-btn m-1" (click)="c('Close click')">No</button>
    </div>
</ng-template>

<div class="modal" id="myModal">
    <div class="modal-dialog">
        <div class="modal-content p-0">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="hidemodalpopup()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="">
                    <h1>Alert</h1>
                    <p>You have not added any part in order, so please add at least one part.</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="hidemodalpopup()"
                    data-dismiss="modal">Close</button>
            </div>

        </div>
    </div>
</div>

<div class="modal" id="noSelectedCategory">
    <div class="modal-dialog">
        <div class="modal-content p-0">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="hideSelectedCategory()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="">
                    <h1>Alert</h1>
                    <p>Please Select Category.</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="hideSelectedCategory()"
                    data-dismiss="modal">Close</button>
            </div>

        </div>
    </div>
</div>



<div class="modal" id="myModal1">
    <div class="modal-dialog">
        <div class="modal-content">
            <h4 class="modal-title info-popup-header darkred-bg alert_heading sweet-heading"></h4>
            <button class="close btn alert_modal" aria-label="Close" (click)="hidemodalpopup1()">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
                <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="">
                    <h1>Alert</h1>
                    <p class="modal-para mb-0">You have Already Added this Part into List.</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer">
                <button type="button" class="btn red-bg round-btn m-0" (click)="hidemodalpopup1()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="CustomPartitem">
    <div class="modal-dialog">
        <div class="modal-content">
            <h4 class="modal-title info-popup-header darkred-bg alert_heading sweet-heading"></h4>
            <button class="close btn alert_modal" aria-label="Close" (click)="CustomPartPopup()">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thanks</h1>
                    <p class="modal-para mb-0">Part : {{partObjectModel.name}} Added</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer">
                <button type="button" class="btn red-bg round-btn m-0" (click)="CustomPartPopup()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="CategoryExist">
    <div class="modal-dialog">
        <div class="modal-content">
            <h4 class="modal-title info-popup-header darkred-bg alert_heading sweet-heading"></h4>
            <button class="close btn alert_modal" aria-label="Close" (click)="CategoryExistClose()">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
                <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="">
                    <h1>Alert</h1>
                    <p class="modal-para mb-0">You have Already Added this Category into List.</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer">
                <button type="button" class="btn red-bg round-btn m-0" (click)="CategoryExistClose()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="add_success">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="addsuccess()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank you!</h1>
                    <p>Record added successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="addsuccess()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="add_successFF">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="addsuccessFF()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank you!</h1>
                    <p>Record added successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="addsuccessFF()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="add_update">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="updateitemClose()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank you!</h1>
                    <p>Record update successfully</p>
                    <p class="mb-0">
                        <button class="btn blue-bg round-btn" (click)="DownloadPdf()">Export PDF&nbsp;&nbsp;<i
                                class="fa fa-file-pdf-o text-white" aria-hidden="true"></i></button>
                        <button class="btn blue-bg round-btn" (click)="PopSendMail()">Send Email&nbsp;&nbsp;<i
                                class="fa fa-file-pdf-o text-white" aria-hidden="true"></i></button>

                    </p>
                </div>
            </div>
            <div class="modal-footer p-1">
                <button type="button" class="btn blue-bg round-btn centbutton" (click)="GoUpdateOrder()"
                    style="width: 104px;">Stay</button>
                <button type="button" class="btn blue-bg round-btn centbutton" *ngIf="this.dashboardShow == true"
                    (click)="GoUDashboard(isApproved)">Dashboard</button>
                <button type="button" class="btn red-bg round-btn centbutton" (click)="updateitemClose()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="delete_success">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="deleteitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank you!</h1>
                    <p>Record deleted successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="deleteitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="configMsgModel">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="configMsgModelClose()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>Please configure the sender email in email configuration.</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="configMsgModelClose()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="wrong_msg">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="msgitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>Something went wrong</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="msgitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<ng-template #discardItem let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg alert_heading sweet-heading"></h4>
    <button class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>

    <div class="modal-body p-1">
        <div class="thank-you-pop">
            <img src="./assets/image/popup-alert.png" alt="">
            <h1>Confirmation</h1>
            <p class="mt-1">{{deleteDetail}}</p>
        </div>
    </div>
    <div class="modal-footer sweet-footer text-center p-1">
        <button type="button" class="btn green-bg round-btn" (click)="gotoDashBoard(); c('Close click')">Yes</button>
        <button type="button" class="btn red-bg round-btn" (click)="c('Close click')">No</button>
    </div>
</ng-template>
<div class="modal" id="ReasonModalPopup">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading">Reason for Update</h4>
                <button type="button" class="close btn alert_modal" (click)="ReasonModalPopup()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="row">
                    <div class="col-md-2">
                        Reason<span style="color: red;">*</span>
                    </div>
                    <div class="col-md-4 mt-1 border-fix">
                        <!-- <input type="textarea " class="customInput" [(ngModel)]="PartName_search" rows="4" cols="50" /> -->
                        <textarea name="w3review" rows="4" cols="50" [(ngModel)]="Reason"
                            [ngClass]="((Reason==null||Reason==undefined||Reason=='') && (IsModalPopup)) ? 'validation_border': ''">

                        </textarea>
                    </div>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn green-bg round-btn" (click)="UpdateOrder('',updateOrderId,true)"
                    data-dismiss="modal">Save</button>
                <button type="button" class="btn red-bg round-btn" (click)="ReasonModalPopup()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<!-- ----for Generate Sale order number--- -->
<div class="modal" id="SaleAlert">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="SaleAlert()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="">
                    <h1>Alert</h1>
                    <p>{{salesorderAlert}}</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="SaleAlert()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="sendMailPopup">
    <div class="modal-dialog" style="max-width: 60%;">
        <div class="modal-content">

            <div class="modal-header info-popup-header p-2">
                <h5 class="modal-title info-popup-title p-1" id="exampleModalLabel1">Send
                    Email</h5>
                <button type="button" class="close" (click)="sendMailPopup()" data-dismiss="modal"
                    aria-label=""><span>×</span></button>
            </div>
            <div class="modal-body">
                <form [formGroup]="formSendEmail">
                    <div class="form-group row mb-0 required">
                        <label for="inputEmail3" class="col-sm-2 col-form-label customLabel">Email Template</label>
                        <div class="col-sm-6 ">
                            <select class="customInput" [(ngModel)]="EmailTemplate" (change)="ChangeTemplate($event)"
                                formControlName='EmailTemplate'
                                [ngClass]="((!EmailTemplate) && (EmailTempValidate)) ? 'validation_border': ''">
                                <option value=''>Select Template</option>
                                <option *ngFor="let tempitem of tempList" [value]="tempitem.id">
                                    {{tempitem.tempName}}
                                </option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row mb-0 required">
                        <label for="inputEmail3" class="col-sm-2 col-form-label customLabel">To</label>
                        <div class="col-sm-6 ">
                            <input class="customInput" formControlName='txtEmailSendTo' type="text"
                                [(ngModel)]="txtEmailSendTo"
                                [ngClass]="((!txtEmailSendTo) && (EmailTempValidate)) ? 'validation_border': ''" />

                        </div>
                    </div>
                    <div class="form-group row mb-0">
                        <label for="inputEmail3" class="col-sm-2 col-form-label customLabel">Cc</label>
                        <div class="col-sm-6">
                            <input class="customInput" formControlName='txtEmailSendCC' type="text"
                                [(ngModel)]="txtEmailSendCC" />
                        </div>
                    </div>
                    <div class="form-group row mb-0">
                        <label for="inputEmail3" class="col-sm-2 col-form-label customLabel">Bcc</label>
                        <div class="col-sm-6">
                            <input class="customInput" formControlName='txtEmailSendBCC' type="text"
                                [(ngModel)]="txtEmailSendBCC" />
                        </div>
                    </div>
                    <div class="form-group row mb-0 required">
                        <label for="inputEmail3" class="col-sm-2 col-form-label customLabel">Subject</label>
                        <div class="col-sm-6">
                            <input class="customInput" formControlName='txtEmailSubject' type="text"
                                [(ngModel)]="txtEmailSubject"
                                [ngClass]="((!txtEmailSubject) && (EmailTempValidate)) ? 'validation_border': ''" />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="inputEmail3" class="col-sm-2 col-form-label customLabel">Body</label>
                        <div class="col-sm-10">
                            <ckeditor [(ngModel)]="templateBody" name="myckeditor" formControlName="templateBody"
                                [config]="ckeConfig" debounce="500">
                            </ckeditor>
                        </div>
                    </div>
                </form>
                <p class="mb-0">
                    <button class="btn blue-bg round-btn float-right" (click)="SendEmailPdf()">Send email</button>
                </p>
            </div>
        </div>
    </div>
</div>


<div class="modal" id="sendMailPopupSuccess">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-header info-popup-header p-2">
                <button type="button" class="close" (click)="sendMailPopupSuccessClose()" data-dismiss="modal"
                    aria-label=""><span>×</span></button>
            </div>
            <div class="modal-body">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank you!</h1>
                    <p>{{EmailSentMessage}}</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="sendMailPopupSuccessClose()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="CategoryNotExist">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-header info-popup-header p-2">
                <button type="button" class="close" (click)="CategoryNotExist()" data-dismiss="modal"
                    aria-label=""><span>×</span></button>
            </div>
            <div class="modal-body">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>Category Not Exist in Part Category!</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="CategoryNotExist()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="ExportOption">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-header info-popup-header p-2">
                <!-- <h5 class="modal-title" id="exampleModalLabel">Send Mail</h5> -->
                <button type="button" class="close" (click)="ExportOption()" data-dismiss="modal"
                    aria-label=""><span>×</span></button>
            </div>
            <div class="modal-body">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank you!</h1>
                    <p>Choose your option</p>

                    <p class="mb-0">
                        <button class="btn blue-bg round-btn" (click)="DownloadPdf()">Export PDF&nbsp;&nbsp;<i
                                class="fa fa-file-pdf-o text-white" aria-hidden="true"></i></button>
                        <button class="btn blue-bg round-btn" (click)="PopSendMail()">Send Email&nbsp;&nbsp;<i
                                class="fa fa-file-pdf-o text-white" aria-hidden="true"></i></button>

                    </p>
                </div>

            </div>
        </div>
    </div>
</div>

<div class="modal" id="DeleteOrderModel">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-header info-popup-header p-2">
                <button type="button" class="close" (click)="DeleteOrderModel()" data-dismiss="modal"
                    aria-label=""><span>×</span></button>
            </div>
            <div class="modal-body">
                <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="">
                    <h1>Confirmation</h1>
                    <p class="mt-1">Are you sure, you want to delete Order?</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn green-bg round-btn m-1"
                    (click)="DeleteOrder(deletedItemPart)">Yes</button>
                <button type="button" class="btn red-bg round-btn" (click)="DeleteOrderModel()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="ExitPopModel">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-header info-popup-header p-2">
                <button type="button" class="close" (click)="ExitPopModel()" data-dismiss="modal"
                    aria-label=""><span>×</span></button>
            </div>
            <div class="modal-body">
                <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="">
                    <h1>Confirmation</h1>
                    <p class="mt-1">Are you sure, you want to exit without saving ?</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn green-bg round-btn m-1" (click)="GoOrderPage()">Yes</button>
                <button type="button" class="btn red-bg round-btn" (click)="ExitPopModel()"
                    data-dismiss="modal">No</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="vehicle_msg">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="vehicleClose()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>{{vehicleProp}}</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="vehicleClose()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="ProdcutionStatus">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="ProdcutionStatusClose()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>Please Select Production Status</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="ProdcutionStatusClose()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="salesorder">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading">Copy Sales Order</h4>
                <button type="button" class="close btn alert_modal" (click)="closeSalesorder()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <div class="row">
                        <div class="col-md-12">
                            <p>{{salesProp}}</p>
                        </div>

                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <input type="text" class="customInput cs" name="CreateOrder" [(ngModel)]="CreateOrder"
                                [ngClass]="(CreateOrder == '' && (copyValidation)) ? 'validation_border': ''"
                                autocomplete="off" />

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-8"></div>
                        <div class="col-md-4">
                            <p style="color:red; font-size: 14px;">{{prop}}</p>
                        </div>
                    </div>


                </div>

            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn green-bg round-btn m-1"
                    (click)="CopyCreateOrder(copyOrderId, copyCompanyId)">Create</button>
                <button type="button" class="btn red-bg round-btn" (click)="closeSalesorder()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="confirmation">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="closeConfirmation()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="">
                    <h1>Confirmation</h1>
                    <p>You would like to create {{CreateOrder}} new Sales Order(s)?</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn green-bg round-btn m-1"
                    (click)="Create(copyOrderId, copyCompanyId)">Yes</button>
                <button type="button" class="btn red-bg round-btn" (click)="closeConfirmation()"
                    data-dismiss="modal">No</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="copy_success">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-header info-popup-header p-2">
                <button type="button" class="close" (click)="closeCopySuccess()" data-dismiss="modal"
                    aria-label=""><span>×</span></button>
            </div>
            <div class="modal-body">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Your Sale Order Has Been Copied</p>
                    <h3 class="cupon-pop" style="font-size:12px;">Your Sale Order Number:
                        <span class="customLabel" *ngFor="let data of copySerialArr; let i = index; let isLast=last;">
                            <ng-container>
                                {{ data.customerPonumber}}{{data.customerPonumber != "" ? isLast
                                ? '' : ', ' : ''}}
                            </ng-container>
                        </span>

                    </h3>
                    <!-- <p class="mb-0">
                        <button class="btn blue-bg round-btn" (click)="DownloadPdf()">Export PDF&nbsp;&nbsp;<i
                            class="fa fa-file-pdf-o text-white" aria-hidden="true"></i></button>
                    <button class="btn blue-bg round-btn" (click)="PopSendMail()">Send Email&nbsp;&nbsp;<i
                            class="fa fa-file-pdf-o text-white" aria-hidden="true"></i></button>

                    </p> -->
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="StayModel">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="closeStayModel()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="">
                    <h1>Confirmation</h1>
                    <p>Do you want to stay here?</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn green-bg round-btn m-1" (click)="stayFun(stayId)">Yes</button>
                <button type="button" class="btn red-bg round-btn" (click)="closeStayModel()"
                    data-dismiss="modal">No</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="StayUpdate">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="closeStayUpdate()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="">
                    <h1>Confirmation</h1>
                    <p>Do you want to stay here?</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn green-bg round-btn m-1" (click)="stayUpdate()">Yes</button>
                <button type="button" class="btn red-bg round-btn" (click)="closeStayUpdate()"
                    data-dismiss="modal">No</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="ReasonCodes">
    <div class="modal-dialog modal-dialog-centered modal-lg centModels">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading">"Reason" for the "{{this.statusType == "3" ? 'Cancelled' :
                    'On Hold' }}" status</h4>
                <button type="button" class="close btn alert_modal" (click)="closeReasonCodes(changeProdStatus)"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp">
                                    <label class="customLabel">Reason Codes</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <select class="customInput pl-1px" required [(ngModel)]="ReasonCodes"
                                        [ngClass]="((!ReasonCodes) && (reasonValidation)) ? 'validation_border': ''">
                                        <option value=''>Select Reason Codes</option>
                                        <option *ngFor="let item of reasonStatusCodeList" [value]="item.id">
                                            {{item.name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mt-1">
                            <div class="row">
                                <div class="col-lg-4 col-md-2 trp">
                                    <label class="customLabel">Reason Description</label>
                                </div>
                                <div class="col-lg-8 col-md-10">
                                    <input type="text" class="customInput" [(ngModel)]="ReasonDescp"
                                        placeholder="Enter Reason Description" name="ReasonDescp" autocomplete="off"
                                        required maxlength="50"
                                        [ngClass]="((!ReasonDescp) && (reasonValidation)) ? 'validation_border': ''">
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn green-bg round-btn m-1"
                    (click)="saveReason(changeProdStatus)">Save</button>
                <button type="button" class="btn red-bg round-btn" (click)="closeReasonCodes(changeProdStatus)"
                    data-dismiss="modal">No</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="add_successs">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="closeSuccesss()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Reason Codes Save Successfully</p>

                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="closeSuccesss()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="multicustomerTable">
    <div class="modal-dialog modal-dialog-centered modal-lg centModel">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading">Multi Contact List</h4>
                <button type="button" class="close btn alert_modal" (click)="closeMultiContactModel()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body">
                <div class="card-desc1 table-responsive y-overflow">
                    <table class="table table-bordered" style="width: 100%;" id="MultiUser">

                        <thead>
                            <tr>
                                <th>Contact First Name</th>
                                <th>Contact Last Name</th>
                                <th>Email</th>
                                <th>Phone Number</th>
                                <th>Contact Type</th>
                                <th>SO Contact</th>
                                <th>IsEcommerce</th>
                                <th data-orderable="false">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor="let data of multiUserList">
                                <tr *ngIf="data.isActive == true">
                                    <td>{{data.firstname }}</td>
                                    <td>{{data.lastname}}</td>
                                    <td>{{data.email}}</td>
                                    <td>{{data.phone}}</td>
                                    <td>{{data.customerTypeVal}}</td>

                                    <td>
                                        <input type="checkbox" value="{{data.issocontact}}"
                                            [(ngModel)]="data.issocontact"
                                            (change)="onCheckChange($event, data.id, data)">

                                    </td>
                                    <!-- <td><span *ngIf="data.issocontact==true"><i class="fa fa-check text-success" aria-hidden="true"></i></span></td> -->
                                    <td><span *ngIf="data.IsEcoomerce==true"><i class="fa fa-check text-success"
                                                aria-hidden="true"></i></span></td>
                                    <td> <a class="green-text"
                                            *ngIf="rolePermissionsList && rolePermissionsList[0].isUpdate">
                                            <i class="fas fa-save" (click)="multiUserEdits(data)"></i></a>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>

                        <tbody *ngIf="multiUserList==null">
                            <tr class="c1">
                                <td colspan="9" class="no-data-available">No data available in table</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="modal-footer sweet-footer p-1">
                <button class="btn round-btn upload_btn add_img mt-lg-1 mb-1" style="padding:5px 10px;"
                    (click)="openCustomerModal();" [disabled]="isGuestType == true">+ Add Contacts

                </button>

                <button class="btn darkred-bg round-btn CustomClass" (click)="closeMultiContactModel()"><i
                        aria-hidden="true"></i>
                    Exit
                </button>

            </div>
        </div>
    </div>
</div>

<div class="modal" id="add_updateMessage" style="z-index: 1080;">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="updateitems()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Update Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="updateitems()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>



<div class="modal" id="customerModel">
    <div class="modal-dialog  modal-dialog-centered modal-lg bigmodals" role="document">
        <div class="modal-content">
            <h4 class="modal-title info-popup-title p-1 text-center">Multiple Contacts for {{customerName}}
            </h4>
            <button type="button" class="close btn alert_modal" aria-label="Close" (click)="userclose()">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
                <!------------------------------------- Multiple Contacts table ------------------------------>
                <div class="card-desc1">
                    <form [formGroup]="userForm">
                        <div class="row mt-2">
                            <div class="col-lg-4 col-md-12">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Contact First Name</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput" [(ngModel)]="userfirstName"
                                            placeholder="Enter First Name" name="userfirstName"
                                            formControlName="userfirstName" autocomplete="off" maxlength="50"
                                            (keypress)="omit_special_char($event)"
                                            [ngClass]="((!userForm.controls.userfirstName.valid) && (uservalidation)) ? 'validation_border': ''">

                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel Character">Contact Last Name</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput" required [(ngModel)]="userlastName"
                                            placeholder="Enter Last Name" name="userlastName"
                                            formControlName="userlastName" autocomplete="none|false|no" maxlength="50"
                                            (keypress)="omit_special_char($event)"
                                            [ngClass]="((!userForm.controls.userlastName.valid) && (uservalidation)) ? 'validation_border': ''">

                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Email</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput EmailValidation" [(ngModel)]="useremail"
                                            placeholder="Enter Email" name="useremail" formControlName="useremail"
                                            autocomplete="off" required
                                            pattern="[0-9a-zA-Z._%+-]+@[0-9a-zA-Z.-]+\.[a-zA-Z]{2,3}$" maxlength="50"
                                            [ngClass]="((!userForm.controls.useremail.valid) && (uservalidation)) ? 'validation_border': ''">

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-1">
                            <div class="col-lg-4 col-md-12">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Phone Number</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput PhoneValidation"
                                            (keypress)="validatePhoneNo($event.target)" [(ngModel)]="userphone"
                                            placeholder="Enter Phone Number (xxx)xxx-xxx" name="userphone" required
                                            formControlName="userphone" autocomplete="disabled" maxlength="14"
                                            pattern="^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$"
                                            [ngClass]="((!userForm.controls.userphone.valid) && (uservalidation)) ? 'validation_border': ''">

                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Extension #</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput" [(ngModel)]="userextention"
                                            placeholder="Enter Extension" name="userextention"
                                            formControlName="userextention" autocomplete="disabled" maxlength="14">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Fax #</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput"
                                            (keypress)="validatePhoneNo($event.target)" [(ngModel)]="userfax"
                                            placeholder="Enter Fax (xxx)xxx-xxx" name="userfax"
                                            formControlName="userfax" autocomplete="disabled" maxlength="14"
                                            pattern="^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$">
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="row mt-1">
                            <div class="col-lg-4 col-md-12">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">

                                        <label class="customLabel">Address Line 1</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput" [(ngModel)]="useraddress"
                                            placeholder="Enter Address Line 1 " maxlength="100" name="useraddress"
                                            formControlName="useraddress" autocomplete="disabled">

                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Address Line 2</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput" [(ngModel)]="useraddress1"
                                            placeholder="Enter Address Line 2 " maxlength="100" name="useraddress1"
                                            formControlName="useraddress1" autocomplete="disabled">

                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Zip</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput NumberOnly" [(ngModel)]="userzip"
                                            placeholder="Enter Zip xxxxx-xxxx" name="userzip" pattern="\d{5}-?(\d{4})?"
                                            (keypress)="validateZipcode($event.target)" formControlName="userzip"
                                            autocomplete="disabled" maxlength="10">
                                        <div *ngIf="((!userForm.controls.userzip.valid && userForm.controls.userzip.value!='' && userForm.controls.userzip.value!=undefined) && (uservalidation))"
                                            class="text-danger input-error">Enter Valid Zip.eg-xxxxx-xxxx</div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="row mt-1">
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">City</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput" [(ngModel)]="usercity"
                                            placeholder="Enter City" name="usercity" formControlName="usercity"
                                            autocomplete="off"
                                            [ngClass]="((!userForm.controls.usercity.valid) && (uservalidation)) ? 'validation_border': ''">

                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Country</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <select class="customInput pl-1px" formControlName='usercountry'
                                            (change)="changeUserCountryContact($event)"
                                            [(ngModel)]="selectedContactCountry">
                                            <option value=''>Select Country</option>
                                            <option *ngFor="let item of countryDataContact" [value]="item.id">
                                                {{item.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">State</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <select class="customInput pl-1px" formControlName="userstate"
                                            [(ngModel)]="selectedUserValue"
                                            [ngClass]="((!userForm.controls.userstate.valid) && (uservalidation)) ? 'validation_border': ''">
                                            <option value=''> Select State</option>
                                            <option *ngFor="let item of stateUserData" [value]="item.id">
                                                {{item.name}}
                                            </option>
                                        </select>

                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="row mt-1">
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Conatct Type</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <select class="customInput pl-1px" required
                                            formControlName="selectedCustomerContactType"
                                            [(ngModel)]="selectedCustomerContactType"
                                            [ngClass]="((!userForm.controls.selectedCustomerContactType.valid) && (uservalidation)) ? 'validation_border': ''">
                                            <option value=''> Select Contact Type</option>
                                            <option *ngFor="let item of CustomerContacttypeId" [value]="item.id">
                                                {{item.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel" for="issocontact">So Contact</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="checkbox" id="issocontact" name="issocontact"
                                            [checked]="issocontact" formControlName="issocontact"
                                            [(ngModel)]="issocontact">

                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="row mt-1">
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-1"
                                *ngIf="isUserEdit==false && isEcoomUser == true">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp required">
                                        <label class="customLabel">Password</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="password" class="customInput" [(ngModel)]="userpassword"
                                            formControlName='userpassword' required autocomplete="off" maxlength="40"
                                            placeholder="Enter Password"
                                            [ngClass]="((userForm.controls.userpassword.value==''||userForm.controls.userpassword.value==undefined) && (uservalidation)) ? 'validation_border': ''">

                                        <div class="text-danger input-error"
                                            *ngIf="userForm.get('userpassword').invalid && (userForm.get('userpassword').touched || userForm.get('userpassword').dirty)">
                                            <div *ngIf="userForm.get('userpassword').errors.minlength">
                                                Password must be at least 8 characters
                                            </div>
                                            <div *ngIf="userForm.get('userpassword').hasError('passwordStrength')">
                                                {{userForm.get('userpassword').errors['passwordStrength']}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-1"
                                *ngIf="isUserEdit==false && isEcoomUser == true">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp required">
                                        <label class="customLabel">Confirm Password</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="password" class="customInput" [(ngModel)]="userpassworConfirm"
                                            formControlName='userpassworConfirm' required autocomplete="new-password"
                                            placeholder="Enter Confirm Password" maxlength="40"
                                            [ngClass]="((userForm.controls.userpassworConfirm.value==''||userForm.controls.userpassworConfirm.value==undefined) && (uservalidation)) ? 'validation_border': ''">
                                        <div class="text-danger input-error">

                                            <div *ngIf="((userpassword != userpassworConfirm) && (userForm.controls.userpassworConfirm.value !='') && (userForm.controls.userpassworConfirm.value != undefined)
                                                && (userForm.controls.userpassworConfirm.value != null))">
                                                Password not match.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel" for="isEcoomUser">Ecommerce User</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="checkbox" id="isEcoomUser" name="isEcoomUser"
                                            [checked]="isEcoomUser" formControlName="isEcoomUser"
                                            [(ngModel)]="isEcoomUser">

                                    </div>
                                </div>
                            </div>



                        </div>

                        <div class="row mt-1">
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1"></div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1"></div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">

                                <button type="button" class="btn darkred-bg round-btn btnCss" (click)="userclose()"
                                    style="float: right">Exit</button>
                                <button type="button" class="btn blue-bg round-btn" (click)="AddMultiUser()"
                                    style="float: right">Save</button>



                            </div>
                        </div>

                        <div class="row mt-2" *ngIf="isUserEdit==true">
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp required">
                                        <label class="customLabel">Password</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="password" class="customInput" [(ngModel)]="changepassword"
                                            formControlName='changepassword' required autocomplete="off" maxlength="40"
                                            placeholder="Enter Password"
                                            [ngClass]="((userForm.controls.changepassword.value==''||userForm.controls.changepassword.value==undefined) && (userPasswordvalidation)) ? 'validation_border': ''">

                                        <div class="text-danger input-error"
                                            *ngIf="userForm.get('changepassword').invalid && (userForm.get('changepassword').touched || userForm.get('changepassword').dirty)">
                                            <div *ngIf="userForm.get('changepassword').errors.minlength">
                                                Password must be at least 8 characters
                                            </div>
                                            <div *ngIf="userForm.get('changepassword').hasError('passwordStrength')">
                                                {{userForm.get('changepassword').errors['passwordStrength']}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-2">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp required">
                                        <label class="customLabel">Confirm Password</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="password" class="customInput" [(ngModel)]="changepassworConfirm"
                                            formControlName='changepassworConfirm' required autocomplete="new-password"
                                            placeholder="Enter Confirm Password" maxlength="40"
                                            [ngClass]="((userForm.controls.changepassworConfirm.value==''||userForm.controls.changepassworConfirm.value==undefined) && (userPasswordvalidation)) ? 'validation_border': ''">
                                        <div class="text-danger input-error">

                                            <div *ngIf="((changepassword != changepassworConfirm) && (userForm.controls.changepassworConfirm.value !='') && (userForm.controls.changepassworConfirm.value != undefined)
                                                && (userForm.controls.changepassworConfirm.value != null))">
                                                Password not match.</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <button type="button" class="btn blue-bg round-btn" (click)="ChangePassword()"
                                        style="float: right">Change Password</button>
                                    <button type="button" class="btn darkred-bg round-btn"
                                        (click)="clearPasswordDetails()"><i class="fas fa-redo" aria-hidden="true"></i>
                                        Clear</button>
                                </div>
                            </div>



                        </div>

                    </form>

                    <hr style="margin-bottom: 0;" />

                </div>
            </div>
        </div>
    </div>
</div>




<div class="modal" id="ReturnModel" style="z-index: 1080">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="CloseReturnModel()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="">
                    <h1>Alert</h1>
                    <p>Only one SO Contact could be selected</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="CloseReturnModel()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>