<section class="content-section pt-2">

    <div class="page-title mb-4">
        
        <div class="bread-crumb">
            <a href="#!">Home</a>
            <span class="mx-1">-</span>
            <span class="active">User Profile</span>
        </div>
        <div class="customHeading mt-3">
            Edit Profile
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="white-box1">

                <div class="card-desc">
                    <form [formGroup]="form">
                        <div class="d-flex align-items-center">
                            <div class="white-box-title border-0 margin-0">
                                <div class="f_size_12">Profile Image</div>
                            </div>

                            <div class="white-box-title border-0 ml-2">
                                <input type="file" class="form-control choose-file-hidden" #Image accept="image/*"
                                    (change)="ChngSettingImage($event.target.files)" id="file">
                                <img [src]="this.imageUrl" class="uplod-logo" id="uplod-img" title="Click to change">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-6">
                                <label>Profile image size should be 50 px (width) x 25 px (height)</label>
                            </div>
                        </div>
                        <div class="color-setting1">
                            <div class="white-box-title1">
                                <div class="customHeading">
                                    Personal Details
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-lg-4 col-md-12">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-2 trp required">
                                            <label class="customLabel">Email Address</label>
                                        </div>
                                        <div class="col-lg-8 col-md-10">
                                            <input type="text" class="customInput Character" disabled
                                            [(ngModel)]="userName" name="userName" formControlName='userName'>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-2 trp required">
                                            <label class="customLabel">Phone Number</label>
                                        </div>
                                        <div class="col-lg-8 col-md-10">
                                            <input type="text" class="customInput PhoneValidation"
                                                (keypress)="validatePhoneNo($event.target)" [(ngModel)]="phoneNumber"
                                                placeholder="Enter Phone No*" name="phone" formControlName="phoneNumber"
                                                autocomplete="off" required maxlength="14" [ngClass]="((!form.controls.phoneNumber.valid) && (validation)) ? 'validation_border': ''">
                                            <!-- <div *ngIf="((!form.controls.phoneNumber.valid) && (validation))"
                                                class="text-danger input-error">
                                                Enter Phone No.</div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-2 trp required">
                                            <label class="customLabel">First Name</label>
                                        </div>
                                        <div class="col-lg-8 col-md-10">
                                            <input type="text" class="customInput Character" [(ngModel)]="firstName"
                                                placeholder="Enter first Name*" name="firstName" formControlName='firstName'
                                                autocomplete="off" required maxlength="50" [ngClass]="((!form.controls.firstName.valid) && (validation)) ? 'validation_border': ''">
                                            <!-- <div *ngIf="((!form.controls.firstName.valid) && (validation))"
                                                class="text-danger input-error">
                                                Enter First Name.</div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-1">
                                <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-2 trp required">
                                            <label class="customLabel">Last Name</label>
                                        </div>
                                        <div class="col-lg-8 col-md-10">
                                            <input type="text" class="customInput Character" [(ngModel)]="lastName"
                                                placeholder="Enter last Name*" name="lastName" formControlName='lastName'
                                                autocomplete="off" required maxlength="50" [ngClass]="((!form.controls.lastName.valid) && (validation)) ? 'validation_border': ''">
                                            <!-- <div *ngIf="((!form.controls.lastName.valid) && (validation))"
                                                class="text-danger input-error">
                                                Enter Last Name.</div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="submit-from text-right">
                            <button class="btn blue-bg round-btn" (click)="editUserProfile()"><i class="fas fa-save"
                                    aria-hidden="true"></i> Update
                            </button>
                            <button class="btn darkred-bg round-btn" (click)="ResetUserProfile()"><i class="fas fa-redo"
                                    aria-hidden="true"></i>
                                Reset </button>
                        </div>
                        <div class="color-setting1">
                            <div class="white-box-title1">
                                <div class="customHeading">
                                    Password Details
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-lg-4 col-md-12">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-2 trp required">
                                            <label class="customLabel">Password</label>
                                        </div>
                                        <div class="col-lg-8 col-md-10">
                                            <input type="password" class="customInput" [(ngModel)]="password"
                                                formControlName='password' required autocomplete="off" maxlength="40"
                                                placeholder="Enter Password*" [ngClass]="((form.controls.password.value==''||form.controls.password.value==undefined) && (validation)) ? 'validation_border': ''">
                                            <!-- <div *ngIf="((form.controls.password.value==''||form.controls.password.value==undefined) && (validation))"
                                                class="text-danger input-error">
                                                Enter password</div> -->
                                            <div class="text-danger input-error"
                                                *ngIf="form.get('password').invalid && (form.get('password').touched || form.get('password').dirty)">
                                                <div *ngIf="form.get('password').errors.minlength">
                                                    Password must be at least 8 characters
                                                </div>
                                                <div *ngIf="form.get('password').hasError('passwordStrength')">
                                                    {{form.get('password').errors['passwordStrength']}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-2 trp required">
                                            <label class="customLabel">Confirm Password</label>
                                        </div>
                                        <div class="col-lg-8 col-md-10">
                                            <input type="password" class="customInput" [(ngModel)]="passworConfirm"
                                                formControlName='passworConfirm' required autocomplete="new-password"
                                                placeholder="Enter Confirm Password*" maxlength="40" [ngClass]="((form.controls.passworConfirm.value==''||form.controls.passworConfirm.value==undefined) && (validation)) ? 'validation_border': ''">
                                            <div class="text-danger input-error">
                                                <!-- <div class="text-danger"
                                                    *ngIf="((form.controls.passworConfirm.value==''||form.controls.passworConfirm.value==undefined) && (validation))">
                                                    Enter Confirm Password</div> -->
                                                <div *ngIf="((password!= passworConfirm) && (form.controls.passworConfirm.valid))">
                                                    Password not match.</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="submit-from text-right">
                            <button class="btn blue-bg round-btn" (click)="changePassword()"><i class="fas fa-save"
                                    aria-hidden="true"></i> Change Password
                            </button>
                            <button class="btn darkred-bg round-btn" (click)="clearPassword()"><i class="fas fa-redo"
                                    aria-hidden="true"></i>
                                Clear </button>
                        </div>
                    </form>
                </div>

            </div>


        </div>
    </div>

</section>

<div class="modal" id="add_success">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="addsuccess()" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Added Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="addsuccess()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="add_update">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="updateitem()" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Update Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="updateitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="delete_success">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="deleteitem()" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Deleted Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="deleteitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="pwd_success">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="updatepwd()" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Password Change Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="updatepwd()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="wrong_msg">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="msgitem()" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>Something went wrong</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="msgitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>