import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ConstantsService } from 'src/app/constants.service';
import { InventoryService } from 'src/app/services/inventory/inventory.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-serial-lot-flyout',
  templateUrl: './serial-lot-flyout.component.html',
  styleUrls: ['./serial-lot-flyout.component.css','../inventory-details/inventory-details.component.css']
})
export class SerialLotFlyoutComponent implements OnInit {
  @Input() seriallotflyoutObj: any ={};
  @Output() closeSerialLotFlyoutEvent = new EventEmitter<any>();

  loader: boolean = false;
  serialLotForm: FormGroup;
  serialNumberArray: FormArray;
  IsScan: boolean = false;
  ScanIndex: number = 0;
  inventoryId: number = 0;
  partId: number =  0;
  balance: number = 0;
  companyId: number =  0;
  partTracking: number =  3;
  partName: string = '';
  trackingObj: any = [];
  SKU: string = '';
  data: Object;

  constructor(
    public inventoryService: InventoryService,
    public fb: FormBuilder,
    private constants: ConstantsService,
  ) { 
    this.serialLotFormCreate();
  }

  ngOnInit(): void {
    if(this.seriallotflyoutObj !== null || this.seriallotflyoutObj !== undefined){
      this.partId =  this.seriallotflyoutObj.partId;
      this.companyId =  this.seriallotflyoutObj.companyId;
      this.partTracking =  this.seriallotflyoutObj.trackingId;
      this.partName =  this.seriallotflyoutObj.partName;
      this.SKU = this.seriallotflyoutObj.SKU;
      this.trackingObj = this.seriallotflyoutObj.trackingObj;
      this.balance = this.seriallotflyoutObj.balance;
      this.inventoryId = this.seriallotflyoutObj.inventoryId;
      if(this.partTracking == 2 || this.partTracking ==1){
        this.GetSerialLotList();
      }
      else{
        this.CloseSerialFlyout();
      }
    }
    else{
      this.CloseSerialFlyout();
    }
  }

  serialLotFormCreate() {
    this.serialLotForm = new FormGroup({
      serialNumberArray: this.fb.array([]),
    });
  }

  initSerial(): FormGroup {
    const index = this.serialNumberArray ? this.serialNumberArray.length : 0;
    return this.fb.group({
      id: new FormControl(0),
      tracking: new FormControl('', this.validateUniq(index)),
      inventoryId: new FormControl(0),
    });
  }

  get SerialNumberGet(): FormArray {
    return this.serialLotForm.get('serialNumberArray') as FormArray
  }

  getTracking(index: number) {
      return this.serialNumberArray ? (this.serialNumberArray.at(index) as FormGroup).get("tracking") : null;
    }

  checkTrackingDuplicacy(index) {
    this.SerialNumberGet.value.forEach((x, i) => {
      if (index != i)
        (x as FormGroup).get('tracking').updateValueAndValidity()
    })
  }

  hasAnyBlank(serialArr: any): boolean {
    const serialArrs = serialArr.filter(x => x.tracking == '');
    if (serialArrs.length > 0) {
      return true;
    }
    return false;
  }

  hasDuplicates(serialArr: any): boolean {
    serialArr = serialArr.filter(x => x.tracking != '');
    const trackingNos: string[] = serialArr.map(element => element.tracking);
    return new Set(trackingNos).size !== trackingNos.length;
  }

  validateUniq(index) {
    return (control: FormControl) => {
      if (control.value) {
        const formArray = control.parent
          ? (control.parent.parent as FormArray)
          : null;
        if (formArray) {
          const attributes = formArray.value.map((x) => x.tracking.toUpperCase());
          return ((attributes.indexOf(control.value.toUpperCase()) >= 0
            && attributes.indexOf(control.value.toUpperCase()) < index))
            ? { duplicateName: true } : null;
        }
      }
    };
  }
  
  removeDuplicates(myArray, Prop) {
    return myArray.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[Prop]).indexOf(obj[Prop]) === pos;
    });
  }

  GetSerialLotList() {
    if (this.inventoryId > 0) {
      this.loader = true;
      this.inventoryService.GetSerialLotList(this.companyId, this.partId, this.inventoryId, this.partTracking).subscribe(
        (data: any) => {
          this.loader = false;
          const list = data.data;
          this.clearFormArray(this.SerialNumberGet);
          if (this.partTracking == 1) {
            for (let x = 0; x < this.balance; x++) {
              if (x < list.length) {
                list.forEach(element => {
                  this.PushSerialNo(element.serialNo, element.id, element.inventoryId);
                });
                x = x + (list.length - 1);
              } else {
                this.PushSerialNo('', 0, 0);
              }
            }
          } else {
            if (list.length > 0) {
              list.forEach(element => {
                this.PushSerialNo(element.serialNo, element.id, element.inventoryId);
              });
            } else {
              this.PushSerialNo('', 0, 0);
            }
          }
        },
        (error: any) => {
          this.loader = false;
        }
      );
    } else {
      this.clearFormArray(this.SerialNumberGet);
      if (this.partTracking == 1) {
        let existDataLength = this.trackingObj.length;
        if(existDataLength>0){
          this.trackingObj.forEach(element => {
            this.PushSerialNo(element.serialNo, element.id, element.inventoryId)
          });
        }
        for (let x = 0; x < this.balance - existDataLength; x++) {
          this.PushSerialNo('', 0, 0);
        }
      } else {
        this.PushSerialNo('', 0, 0);
      }
    }
  }

  serialLotFn(){
    
  }

  GetTrackingType(trackingId: number): string {
    if (trackingId == 1) {
      return this.constants.trackList.serial;
    } else if (trackingId == 2) {
      return this.constants.trackList.lot;
    } else {
      return this.constants.trackList.none;
    }
  }

  AddLotRow() {
    this.PushSerialNo('', 0, 0);
  }

  AddSerialLotList(serialLotList: any): any {
    if (this.inventoryId > 0) {
      serialLotList.forEach((element, i) => {
        let obj = {};
        obj = {
          id: element.id,
          part: this.SKU,
          serialNo: element.tracking,
          inventoryId: Number(this.inventoryId),
          partId: Number(this.partId),
          trackingType: this.partTracking,
          companyId: Number(this.companyId),
          isActive: true,
          // Ordering: i + 1
        }
        this.trackingObj.push(obj);
      });
    } else {
      serialLotList.forEach((element, i) => {
        let obj = {};
        obj = {
          id: element.id,
          part: this.SKU,
          serialNo: element.tracking,
          partId: Number(this.partId),
          trackingType: this.partTracking,
          companyId: Number(this.companyId),
          inventoryId: element.inventoryId,
          isActive: true,
          // Ordering: i + 1
        }
        this.trackingObj.push(obj);
      });
    }
    return this.trackingObj;
  }

  PushSerialNo(data: any, id: number, inventoryId: number) {
    this.SerialNumberGet.push(new FormGroup({
      id: new FormControl(id),
      tracking: new FormControl(data),
      inventoryId: new FormControl(inventoryId)
    }));
  }

  serialLotFormSubmit(serialLotFormVal: any) {
    const serialArr = serialLotFormVal.serialNumberArray.filter(x => x.tracking.length > 0);
    if (serialArr.length > 0) {
      //const isBlank = this.hasAnyBlank(serialArr);
      const isDuplicate = this.hasDuplicates(serialArr);
      //if(!isBlank && !isDuplicate){
      if (!isDuplicate) {
        this.trackingObj = [];
        const requestObj = this.AddSerialLotList(serialArr);
        if (this.inventoryId > 0) {
          if(this.seriallotflyoutObj.page == 'inventory'){
            this.SaveSeralLotObject(requestObj);
          }
        } 
        this.trackingObj = requestObj;
        this.CloseSerialFlyout();
      } else {
      }
    }
  }

  CloseSerialFlyout() {
    const obj ={
      isShow : false,
      trackingObj: this.trackingObj == undefined ? [] : this.trackingObj,
    }
    this.closeSerialLotFlyoutEvent.emit(obj);
  }

  SaveSeralLotObject(serialLotArr: any) {
    this.inventoryService.AddSerialLotList(serialLotArr, this.companyId).subscribe(
      (data) => {
        this.data = data;
        this.CloseSerialFlyout();
      },
      (error) => {
        this.loader = false;
        this.PopupModal(this.constants.APIError, error);
      }
    );
  }

  PopupModal(title: string, message: string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }

  clearFormArray(formArray) {
    while (formArray.length !== 0) {
      formArray.removeAt(0)
    }
  }

  //for scanner
  camerasNotFound(e: any) {
    if (e.message == 'Requested device not found') {
      Swal.fire({
        text: 'Device not found.',
        showCancelButton: true,
        cancelButtonText: 'Close',
        showConfirmButton: false
      }).then((result) => {
        this.IsScan = false;
      });
    } else {
      this.IsScan = false;
    }
  }

  onCodeResult(resultString: string) {
    this.IsScan = false;
    this.SerialNumberGet.value[this.ScanIndex].Tracking = resultString;
    this.serialLotForm.patchValue({ serialNumberArray: this.SerialNumberGet.value })
  }

  AvailableDevice(index: number, id: number) {
    this.IsScan = true;
    this.ScanIndex = index;
  }
}
