import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FulfillmentService {

  baseUrl = environment.baseUrl;
  constructor(private _http: HttpClient) { }

  GetFulfillmentList(orderRequest: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/AssignInventorytoSOs/GetFulfillmentList', orderRequest,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetFulfillmentStatusCount(CompanyId: number) {
    return this._http.get(this.baseUrl + 'api/AssignInventorytoSOs/GetFulfillmentStatusCount?CompanyId=' + CompanyId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetFulfillPartsByOrderId(Id) {
    return this._http.get(this.baseUrl + 'api/AssignInventorytoSOs/GetFulfillPartsByOrderId?Id=' + Id,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  UpdFulfillment(op: any) {
    return this._http.post(this.baseUrl + 'api/AssignInventorytoSOs/UpdFulfillment', op,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  FulFillOrder(op: any, OrderId: number) {
    return this._http.post(this.baseUrl + 'api/AssignInventorytoSOs/UpdateFulFillOrdedr?OrderId=' + OrderId, op,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  //Error Handler Method
  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || "server error.");
  }
}
