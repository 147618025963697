<section class="content-section pt-2">
    <div class="loading" *ngIf="loader">Loading&#8230;</div>
    <div class="page-title mb-2">
        <!-- <h2>Products</h2> -->
        <div class="bread-crumb">
            <a [routerLink]="['/adminDashboard/']">Home</a>
            <span class="mx-1">-</span>
            <span class="active">Products</span>
            <div *ngIf="permissionMessage!=''" class='btn-danger text-center'>{{permissionMessage}} </div>
        </div>
    </div>

    <div class="row">
        <div class="col-sm-12">
            <div class="white-box1 no_bg1">
                <div class="card-desc1">
                    <form [formGroup]="formSearch">
                        <div class="row detailSection">
                            <div class="col-md-12 container">
                                <div class="row mt-3">
                                    <div class="col-lg-2 col-md-6">
                                        <div class="d-flex align-items-center">
                                            <label class="customLabel mr-1" for="Type">Type</label>
                                            <select class="customInput" (change)="ChangeTCreateUpdate($event)"
                                                [(ngModel)]="TCreateUpdate" id="TCreateUpdate"
                                                formControlName="TCreateUpdate">
                                                <option value="1">Create</option>
                                                <option value="0">Update</option>
                                            </select>
                                        </div>
                                    </div>

                                    <!-- <div class="col-lg-3 col-md-6 mt-md-0 mt-1"
                                        [style.display]="SearchControl==true ? 'block' : 'none'">
                                        <div class="d-flex align-items-center required border-none"
                                            [ngClass]="((value_search==0) && (SearchValidation)) ? 'validation_border': ''">
                                            <label class="customLabel mr-1" for="Type">Company</label>
                                            <ngx-dropdown-treeview-select [config]="config_search"
                                                class="dropdown-company customInput-dropdown"
                                                [items]="userGroupObjects_search" [(value)]="value_search"
                                                (valueChange)="onValueChange_search($event)">
                                            </ngx-dropdown-treeview-select>
                                        </div>
                                    </div> -->
                                    <div class="col-lg-5 col-md-12 mt-lg-0 mt-1"
                                        [style.display]="SearchControl==true ? 'block' : 'none'">

                                        <div class="d-flex align-items-center">
                                            <label class="customLabel mr-1 ws-nobreak" for="Vendor Name">Product
                                                Name</label>
                                            <input type="text" placeholder="Search Product Name" Class="customInput"
                                                [(ngModel)]="ProductSearch" formControlName="ProductSearch"
                                                autocomplete="off" required
                                                [ngClass]="((!ProductSearch && SearchType==false) && (SearchValidation)) ? 'validation_border': ''" (keyup.enter)="searchVendor()">
                                            <!-- <div *ngIf="((!ProductSearch && SearchType==false) && (SearchValidation))"
                                                class="text-danger input-error err-fontSize"
                                                style="position: absolute; bottom: -40px; top: 23px; right: 257px;">
                                                Enter Product Name.</div> -->
                                            <i class="fas fa-search cpointer ml-3 mr-4 cpointer"
                                                (click)="searchVendor()"></i>
                                            <button type="button" class="btn blue-bg round-btn m-0" (click)="AllProduct()">All
                                                Products</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="white-box1 ProductsList" *ngIf="rolePermissionsList && rolePermissionsList[0].isView"
                [style.display]="ListShow==true ? 'block' : 'none'">
                <div class="white-box-title customHeading mt-3">
                    Product List
                    <span id="expand-collapse">
                        <i class="fa fa-angle-down cpointer" aria-hidden="true" (click)="ListDivShowHide()"></i>
                    </span>
                </div>
                <div class="card-desc table-responsive" [style.display]="ListDiv==true ? 'block' : 'none'">
                    <table id="datatable" class="display table clearfix" class="row-border hover" datatable
                        [dtOptions]="dtOptions1" [dtTrigger]="dtTrigger1">
                        <thead>
                            <tr>
                                <th class="w-25" (click)="SortTableHeader('name','')">Product Name<span id="name" class="sorting1"></span></th>
                                <th (click)="SortTableHeader('description','')">Description<span id="description" class="sorting1"></span></th>
                                <th data-orderable="false">Action</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="productDetails?.length != 0">
                            <tr *ngFor="let item of productDetails">
                                <td>{{item.name}}</td>
                                <td>{{item.description}}</td>
                                <td>
                                    <a *ngIf="rolePermissionsList && rolePermissionsList[0].isInsert" class="green-text"
                                        (click)="toggleEdit(item.id)"><i class="fas fa-edit"></i></a>
                                        <a *ngIf="rolePermissionsList && rolePermissionsList[0].isCopy" class="green-text"
                                        (click)="CopyProduct(item.id, item.name)"><i class="fas fa-copy"></i></a>
                                    <a *ngIf="rolePermissionsList && rolePermissionsList[0].isDelete" class="red-text"
                                        (click)="deletePopup(item,content)"><i class="fas fa-trash-alt"></i></a>
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="productDetails==null">
                            <tr class="c1">
                                <td colspan="3" class="no-data-available">No data available in table</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="white-box1 no_bg1">
                <div class="customHeading mt-3">
                    Product Details
                </div>
                <div class="card-desc1">
                    <form [formGroup]="form">
                        <div class="row mt-1">
                            <!-- <div class="col-lg-4 col-md-12">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp required">
                                        <label class="customLabel">Company</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10 borderNone"
                                        [ngClass]="((value==0) && (validation)) ? 'validation_border': ''">
                                        <ngx-dropdown-treeview-select class="dropdown-company customInput-dropdown"
                                            [config]="config" required [items]="userGroupObjects" [(value)]="value"
                                            (valueChange)="onValueChange($event)">
                                        </ngx-dropdown-treeview-select>
                                    </div>
                                </div>
                            </div> -->
                            <div class="col-lg-1 col-md-12">

                                <div class="row">
                                    <div class="col-lg-5 col-md-2 trp required">
                                        <label class="customLabel" for="vehicleRequired">Vehicle</label>
                                    </div>
                                    <div class="col-lg-4 col-md-10">
                                        <input type="checkbox" id="vehicleRequired" name="vehicleRequired" [checked]="vehicleRequired"
                                        (change)="vehicleRequiredEvent($event)" formControlName="vehicleRequired"
                                        [(ngModel)]="vehicleRequired" style="top:2px">
                                   
                                    </div>
                                    <div class="col-lg-2"></div>
                                </div>
                           
                            </div>
                            <div class="col-lg-3 col-md-12" *ngIf="isVehicleRequired == true">
                                <div class="row">
                                        
                                    <div class="col-lg-12 col-md-10"> 
                                        <select class="customInput pl-1px" required name='manufacturerId' id="manufacturerId"
                                        [(ngModel)]="selectedManufacturerValue" formControlName='manufacturerId'
                                        (change)="onManufacturerChange($event)"
                                        [ngClass]="((!form.controls.manufacturerId.valid) && (validation)) ? 'validation_border': ''">
                                        <option value=''>Select Manufacturer</option>
                                        <option *ngFor="let item of manufacturerList"
                                            [value]="item.id">
                                            {{item.vehicleManufacturer}}
                                        </option>
                                    </select>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-2 col-md-12" *ngIf="isVehicleRequired == true">
                                <div class="row">
                                       
                                    <div class="col-lg-12 col-md-10"> 
                                        <select class="customInput pl-1px" required name='manufacturerModelId' id="manufacturerModelId"
                                        [(ngModel)]="selectedManufacturerModelValue" formControlName='manufacturerModelId'
                                        [ngClass]="((!form.controls.manufacturerModelId.valid) && (validation)) ? 'validation_border': ''">
                                        <option value=''>Select Model</option>
                                        <option *ngFor="let item of manufacturerModelList"
                                            [value]="item.id">
                                            {{item.modelName}}
                                        </option>
                                    </select>
                                    </div>
                                </div>
                               
                            </div>
                            <div class="col-lg-2 col-md-12" *ngIf="isVehicleRequired == true">
                                <div class="row">
                                    
                                    <div class="col-lg-12 col-md-10"> 
                                        <input type="text" [(ngModel)]="Year" class="customInput Cost"
                                        placeholder="Enter Year (YYYY)" required maxlength="4" minlength="4"
                                        autocomplete="off" formControlName="Year"
                                        [ngClass]="((!form.controls.Year.valid) && (validation)) ? 'validation_border': ''">
                                    </div>
                                    
                                </div>
                            </div>
                         
                      
                        </div>
                        <div class="row mt-1">
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp required">
                                        <label class="customLabel">Product Name</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" [(ngModel)]="productName" class="customInput"
                                            placeholder="Enter Product Name" required maxlength="50" minlength="3"
                                            autocomplete="off" formControlName="productName"
                                            [ngClass]="((!form.controls.productName.valid) && (validation)) ? 'validation_border': ''">
                                        <!-- <div *ngIf="((!form.controls.productName.valid) && (validation))"
                                            class="text-danger input-error err-fontSize">
                                            Enter Product Name.</div> -->
                                        <div *ngIf="((form.controls.productName.valid) && (recordAlreadyExist))"
                                            class="text-danger input-error">
                                            Product Already Exist.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-lg-2 col-md-2 trp">
                                <label class="customLabel padding-r">Description</label>
                            </div>
                            <div class="col-lg-10 col-md-10">
                                <textarea class="customInput customerTextarea customerTextarea2" rows="3" cols="5"
                                    [(ngModel)]="productDescription" autocomplete="off" placeholder="Enter Description"
                                    maxlength="500" formControlName="productDescription"></textarea>
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-lg-4 col-md-12">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Product View</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <select class="customInput" (change)="ChangeView($event.target.value)"
                                            [(ngModel)]="dropdownView" id="dropdownView" formControlName="dropdownView">
                                            <option value="1">Sales Order View</option>
                                            <option value="2">Production View</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                    <div class="customHeading mt-3">
                        Product Part Details
                    </div>
                    <div class="add-partcategory1 mb-2">
                        <div class="row mt-1">
                            <div class="col-md-4">
                                <div class="form-group form_group_cmn">
                                    <select class="customInput prodCategory" [(ngModel)]="SelectedCatagoryName"
                                        autocomplete="none|false|no"
                                        (ngModelChange)="typeaheadOnSelectCatagory($event)">
                                        <option value='' selected>Select category</option>
                                        <option *ngFor="let item of catagoryDataList" [value]="item.id">{{item.name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-2 text-right">
                                <button type="button" class="btn w-100 green-bg sq-btn mb-3" (click)="addCategory(contentCat)" style="height:27px !important;"
                                    data-target="#checkCategoryPart" data-toggle="modal"><i class="fas fa-plus-circle"
                                        aria-hidden="true"></i> Add Category
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="order-detail-main">

                        <table class="display table clearfix order-detail swapcategory"
                            [style.display]="dropdownView==1 ? '' : 'none'">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th colspan="4">Parts</th>
                                    <th colspan="2">Labor(<b><i><small>{{laborHrsCost|currency}}/hr</small></i></b>)
                                    </th>
                                    <th>Extention</th>
                                </tr>
                                <tr>
                                    <th></th>
                                    <th>Vendor name</th>
                                    <th>Qty</th>
                                    <th>Each</th>
                                    <th>Ext</th>
                                    <th>Hrs</th>
                                    <th>Ext</th>
                                    <th>Total</th>
                                </tr>
                            </thead>
                            <tbody>

                                <ng-container *ngFor="let category of partCategoriesList;let index=index">
                                    <tr class="part-category hoverEffect" id="catIds">
                                        <td>
                                            <span (click)="Openpart(category.categoryId)" class="openpart"
                                                id="openpart-{{category.categoryId}}"><i
                                                    class="fas fa-plus-circle"></i></span>

                                            {{index+1}}.0 {{category.categoryName}}
                                            <span class="d-inline-flex align-items-center">

                                                <button type="button" data-toggle="modal" data-target="#addPart"
                                                    (click)="AddCategoryPart(category.categoryId)"
                                                    class="showBtn btn btn-sm green-bg sq-btn p-addPart"><span
                                                        class="fas fa-plus"></span> Part
                                                </button>

                                                <span class="showBtn"
                                                    (click)="deletecategorypopup(category.categoryId,contentcategory)"><i
                                                        class="fas fa-trash-alt order-del"></i>

                                                </span>
                                                <span>
                                                    <button type="button" class="showBtn arrow-updown"
                                                        (click)="SwapCategory(partCategoriesList, index, (index-1))"
                                                        [disabled]="index==0? true:false"><i
                                                            class="fas fa-arrow-up"></i></button>
                                                    <button type="button" class="showBtn arrow-updown"
                                                        (click)="SwapCategory(partCategoriesList, index, (index+1))"
                                                        [disabled]="index==partCategoriesList.length-1? true:false"><i
                                                            class="fas fa-arrow-down"></i></button>
                                                    <!-- <input type="button" value="↑"   class=" arrowbutton ml-1"  (click)="SwapCategory(partCategoriesList, index, (index-1))" [disabled]="index==0? true:false" />
                                                        <input type="button" value="↓" class=" arrowbutton ml-1" (click)="SwapCategory(partCategoriesList, index, (index+1))"  [disabled]="index==partCategoriesList.length-1? true:false" /> -->

                                                </span>


                                            </span>
                                        </td>
                                        <td colspan="7">{{category.totalextensiondata|currency}}</td>
                                    </tr>
                                    <ng-container *ngFor="let parts of category.partList;let innerIndex=index;">
                                        <tr class="hoverEffect  openpart-{{category.categoryId}}-form table-partname">
                                            <td>
                                                <div class="d-flex align-items-center position-relative">
                                                    <span class="partdetail" (click)="OpenQuestion(parts.partId, 'open')"
                                                        id="partname-{{parts.partId}}"> <i class="fas fa-plus"></i>
                                                    </span>
                                                    <span class="PartName tooltipWrap">{{index+1}}.{{innerIndex+1}}
                                                        {{parts.partName}}</span>
                                                    <span class="tooltips">{{parts.partName}}</span>
                                                    <span class="showBtn">
                                                        <span *ngIf="btnShowvalidation && btnOkShow==false">
                                                            <a
                                                                (click)="deletePopup1(category.categoryId,parts.partId,contentname)"><i
                                                                    class="fas fa-trash-alt order-del"></i></a>
                                                            <a><span class="fas fa-edit partEdit"
                                                                    (click)="EditPartRow(category.categoryId,parts.partId,'editRow',parts.quantity)"></span></a>
                                                        </span>

                                                        <span
                                                            *ngIf="category.categoryId==EditRowCateId && parts.partId==EditRowPartId && btnOkShow==true">
                                                            <span><button type="button" data-toggle="modal"
                                                                    (click)="UpdatePartRow(category.categoryId,parts.partId)"
                                                                    class="showBtn btn btn-sm green-bg sq-btn ml-1 p-addPart"
                                                                    style="width: 35px !important;">Ok
                                                                </button></span>
                                                            <span><button type="button" data-toggle="modal"
                                                                    (click)="CancleUpdatePartRow(category.categoryId,parts.partId)"
                                                                    class="showBtn btn darkred-bg  sq-btn ml-1 p-addPart"
                                                                    style="width: 55px !important;">Cancel
                                                                </button></span>
                                                        </span>
                                                    </span>

                                                    <span>
                                                        <button type="button" class="showBtn arrow-updown"
                                                            (click)="SwapPartItem(category.partList, innerIndex, (innerIndex-1))"
                                                            [disabled]="innerIndex==0? true:false"><span
                                                                class="fas fa-arrow-up"></span></button>
                                                        <button type="button" class="showBtn arrow-updown"
                                                            (click)="SwapPartItem(category.partList, innerIndex, (innerIndex+1))"
                                                            [disabled]="innerIndex==category.partList.length-1? true:false"><span
                                                                class="fas fa-arrow-down"></span></button>
                                                        <!-- <input type="button" value="↑"   class=" arrowbutton ml-1"  (click)="SwapPartItem(category.partList, innerIndex, (innerIndex-1))" [disabled]="innerIndex==0? true:false" />
                                                    <input type="button" value="↓" class=" arrowbutton ml-1" (click)="SwapPartItem(category.partList, innerIndex, (innerIndex+1))"  [disabled]="innerIndex==category.partList.length-1? true:false" /> -->
                                                    </span>

                                                </div>
                                            </td>
                                            <td class="text-center">
                                                <!-- {{category.vendorName}}   -->
                                                {{parts.vendorName}}
                                            </td>
                                            <td class="text-center">
                                                <span
                                                    *ngIf="normalShow==true || RowEdit==false">{{parts.quantityOld}}</span>
                                                <span
                                                    *ngIf="category.categoryId==EditRowCateId && parts.partId==EditRowPartId && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                    <input class="form-control odr-qty NumberOnly " type="text"
                                                        [(ngModel)]="UpdateQty" maxlength="5" min="1" max="99"
                                                        (change)="CalculationOnkeypress(category.categoryId,parts.partId)" />
                                                </span>
                                            </td>


                                            <td>
                                                <span *ngIf="normalShow==true">${{parts.costold}}</span>
                                                <span
                                                    *ngIf="category.categoryId==EditRowCateId && parts.partId==EditRowPartId && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                    <input class="form-control inputShow Cost" type="text"
                                                        [(ngModel)]="UpdateEachPartCost"
                                                        (blur)="formatCurrency($event.target.value)" maxlength="10"
                                                        (change)="CalculationOnkeypress(category.categoryId,parts.partId)" />
                                                </span>
                                            </td>

                                            <td>{{parts.costold*parts.quantityOld | currency}}
                                                <br />
                                                <span class="onEditCurrTextProduct"
                                                    *ngIf="parts.partId==EditRowPartId && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                    {{parts.cost*parts.quantity | currency}}
                                                </span>
                                            </td>

                                            <td>
                                                <span
                                                    *ngIf="normalShow==true || CurrentEditRow=='Edit'">{{parts.hourold|number:
                                                    '1.2-2'}}</span>
                                                <span
                                                    *ngIf="category.categoryId==EditRowCateId && parts.partId==EditRowPartId && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                    <input class="form-control odr-qty Cost" type="text"
                                                        [(ngModel)]="UpdateLaborHrs"
                                                        (blur)="formatHours($event.target.value)" maxlength="6"
                                                        (change)="CalculationOnkeypress(category.categoryId,parts.partId)" />
                                                </span>
                                            </td>
                                            <td>{{parts.hourold*laborHrsCost*parts.quantityOld | currency}}
                                                <br />
                                                <span class="onEditCurrTextProduct"
                                                    *ngIf="parts.partId==EditRowPartId && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                    {{parts.hours*laborHrsCost*parts.quantity | currency}}
                                                </span>
                                            </td>
                                            <td>
                                                {{(parts.costold*parts.quantityOld)+(parts.hourold*laborHrsCost*parts.quantityOld)
                                                |currency}}
                                                <br />
                                                <span class="onEditCurrTextProduct"
                                                    *ngIf="parts.partId==EditRowPartId && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                    {{(parts.cost*parts.quantity)+(parts.hours*laborHrsCost*parts.quantity)
                                                    |currency}}
                                                </span>
                                            </td>
                                        </tr>
                                        <!-- <tr class="table-partdetail partname-{{parts.partId}}-detail">
                                            <td colspan="7">
                                                <div class="qus-title">Questions</div>
                                                <div class="d-flex" *ngFor="let qaItem of parts.questionAnsList;">
                                                    <div class="hoverEffect qus" *ngIf="qaItem.questionId!=0"><b>
                                                            {{qaItem.questionName}}
                                                        </b> :
                                                        {{qaItem.answerName==null ?'Not
                                                        Answered':qaItem.answerName}}
                                                        <a class="showBtn green-text" *ngIf="isOrderApproved==false"
                                                            (click)="EditQuestionAnswr(qaItem.questionId,parts.partId)"><i
                                                                class="fas fa-edit"></i></a>
                                                    </div>
                                                    <div class="hoverEffect qus" *ngIf="qaItem.questionId==0">
                                                        <b>N/A</b>
                                                    </div>

                                                    <div
                                                        *ngIf="parts.partId==selectedPartId && qaItem.questionId==selectedQuestionId">
                                                        <div class="col-sm-12">
                                                            <select *ngIf="qaItem.typeKey =='CustomerName'"
                                                                class="form-control" [(ngModel)]="qaItem.answerName">
                                                                <option value='0' selected>Select Customer</option>
                                                                <option *ngFor="let item of customerData">
                                                                    {{item.customerName}}

                                                                </option>
                                                            </select>
                                                        </div>
                                                        <div class="col-sm-12" *ngIf="qaItem.typeKey =='YesNo'">
                                                            <select class="form-control"
                                                                [(ngModel)]="qaItem.answerName">
                                                                <option value="" selected>Select Yes/No</option>
                                                                <option value="Yes">Yes</option>
                                                                <option value="No">No</option>
                                                            </select>
                                                        </div>
                                                        <div class="col-sm-12" *ngIf="qaItem.typeKey =='VendorName'">
                                                            <select class="form-control"
                                                                [(ngModel)]="qaItem.answerName">
                                                                <option value='' selected>Select Vendor</option>
                                                                <option *ngFor="let item of vendorList">
                                                                    {{item.vendorName}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <div class="col-sm-12" *ngIf="qaItem.typeKey =='Text'">
                                                            <input type="text" [(ngModel)]="qaItem.answerName"
                                                                class="form-control" placeholder="Type Answer"
                                                                maxlength="50">
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr> -->

                                        <tr class="table-partdetail partname-{{parts.partId}}-detail">
                                            <td colspan="8">
                                                <!-- <div class="qus-title"></div> -->
    
                                                <div *ngFor="let qaItem of parts.questionAnsList;">
                                                    <div><b style="font-size: 13px;">Questions
                                                            <span class="hoverEffect qus mt-2" *ngIf="qaItem.questionId==0"
                                                                style="margin-left: 9px;font-weight: bold;color: #333;">N/A</span>

                                                        </b>
                                                    </div>
                                                    <hr style="margin-top: 2px;margin-bottom: -5px;"
                                                        *ngIf="qaItem.questionId!=0">
    
                                                    <!-- akm -->
                                                    <div class="hoverEffect qus mt-2 d-flex" *ngIf="qaItem.questionId!=0">
                                                        <span style="font-weight: bold;color: #333;">
                                                            {{qaItem.questionName}} </span> :
                                                        {{qaItem.answerName==null ?'Not Answered':qaItem.answerName}}
                                                        <a class="showBtn green-text" *ngIf="isOrderApproved==false"
                                                            (click)="EditQuestionAnswr(qaItem.questionId,parts.partId,qaItem.answerName,'')">
                                                            <i class="fas fa-edit"></i></a>
                                                        <div
                                                            *ngIf="parts.partId==selectedPartId && qaItem.questionId==selectedQuestionId && isQA==true">
                                                            <div class="col-sm-12" *ngIf="qaItem.typeKey =='Text'">
    
                                                                <input type="text" [(ngModel)]="answerValue"
                                                                    value="qaItem.answerName" class="form-control"
                                                                    placeholder="Type Answer" maxlength="50"
                                                                    (blur)="UpdateQuestionAnswer(qaItem.questionId,parts.partId,qaItem.answerName,'')">
                                                            </div>
                                                        </div>
                                                    </div>
    
    
                                                    <div
                                                        *ngIf="parts.partId==selectedPartId && qaItem.questionId==selectedQuestionId">
                                                        <div class="col-sm-12">
                                                            <select *ngIf="qaItem.typeKey =='CustomerName'"
                                                                class="form-control newsmallddl"
                                                                [(ngModel)]="qaItem.answerName">
                                                                <option value='null' selected>Select Customer</option>
                                                                <option *ngFor="let item of customerData">
                                                                    {{item.customerName}}
                                                                </option>
                                                            </select>
                                                        </div>
                                                        <div class="col-sm-12" *ngIf="qaItem.typeKey =='YesNo'">
                                                            <select class="form-control newsmallddl"
                                                                [(ngModel)]="qaItem.answerName">
                                                                <option value="" selected>Select Yes/No</option>
                                                                <option value="Yes">Yes</option>
                                                                <option value="No">No</option>
                                                            </select>
                                                        </div>
                                                        <div class="col-sm-12" *ngIf="qaItem.typeKey =='VendorName'">
                                                            <select class="form-control newsmallddl"
                                                                [(ngModel)]="qaItem.answerName">
                                                                <option value='null' selected>Select Vendor</option>
                                                                <option *ngFor="let item of vendorList">
                                                                    {{item.vendorName}}
                                                                </option>
                                                            </select>
                                                        </div>
    
    
                                                    </div>
                                                </div>
    
                                                <hr style="margin-top: 2px;margin-bottom: -5px;">
    
                                                <div class="hoverEffect qus mt-2 d-flex">
                                                    <span style="font-weight: bold; white-space:nowrap; color: #333;">
                                                        Notes :
                                                        <span class="hoverEffect qus mt-1"
                                                            *ngIf="parts.isNotes==false">{{parts.notes}}</span>
                                                    </span>
    
                                                    <div *ngIf="parts.isNotes==true" class="w-100">
                                                        <div class="col-lg-12">
                                                            <input type="text" [(ngModel)]="parts.notes"
                                                                value="qaItem.notes" class="form-control notes"
                                                                placeholder="Type Notes"
                                                                (blur)="SaveNotes(category.categoryId, parts.partId)">
                                                        </div>
                                                    </div>
                                                    <a class="showBtn green-text" *ngIf="parts.isEdit==true"
                                                        (click)="visibleNotes(category.categoryId, parts.partId)"><i
                                                            class="fas fa-edit"></i></a>
                                                    <a class="showBtn green-text" *ngIf="parts.isEdit==false"
                                                        (click)="SaveNotes(category.categoryId, parts.partId)"><i
                                                            style="color: #0fa506; margin-top: 3px;"
                                                            class="fas fa-save"></i></a>
                                                </div>
    
                                            </td>
                                        </tr>
                                    </ng-container>
                                </ng-container>

                                <tr *ngIf="partCategoriesList!=undefined && partCategoriesList?.length==0">
                                    <td colspan="8" class="text-center">Record not found </td>
                                </tr>
                            </tbody>
                            <!-- *ngIf="subSection>0" -->
                            <tfoot class="footOrder setFont" [style.display]="subSection>0 ? '' : 'none'">
                                <!-- <tfoot class="footOrder setFont">    -->

                                <tr>

                                    <td class="tr-bg-clr removeBorder"></td>
                                    <td class="double-border border_left" colspan="3" align="left">
                                        Sub Total
                                    </td>
                                    <td class="double-border">{{PartExtensionTotal|currency}}</td>
                                    <td class="double-border">{{HoursExtensionTotal==null ||
                                        HoursExtensionTotal==0?"0.00": HoursExtensionTotal | number:'1.2' }}</td>
                                    <td class="double-border">{{LaborExtesionTotal|currency}}</td>
                                    <td class="double-border">{{GrandTotalAmount|currency}}</td>
                                </tr>
                                <tr>

                                    <td class="tr-bg-clr removeBorder"></td>
                                    <td colspan="5" align="left" class="border_left">
                                        Discount
                                        <span class="discount align-with">
                                            <label style="margin-right: 0px;">
                                                % <input class="p_relative" type="radio" value="Percentage"
                                                    name="Discount" [disabled]="isOrderApproved"
                                                    (change)="OnDiscountChange($event.target.value)">
                                            </label>
                                            <label class="ml-2">
                                                $ <input class="p_relative" type="radio" value="Doller" name="Discount"
                                                    (change)="OnDiscountChange($event.target.value)"
                                                    [disabled]="isOrderApproved">
                                            </label>
                                        </span>
                                        <span>
                                            <label class="ml-2">
                                                Parts &nbsp;<input class="p_relative custom_checkbox" type="checkbox"
                                                    name="Part" [disabled]="!enableCheckBox||isOrderApproved"
                                                    [(ngModel)]='PartCheckBox' (change)="CalculationSubtotal()">
                                            </label>

                                            <label class="ml-2">
                                                Labor &nbsp;<input class="p_relative custom_checkbox" type="checkbox"
                                                    name="Labor" [disabled]="!PartCheckBox||isOrderApproved"
                                                    [(ngModel)]='LaborCheckBox' (change)="CalculationSubtotal()">
                                            </label>
                                        </span>

                                    </td>
                                    <td>

                                        <span *ngIf="discountRadioBox=='Doller'" class="pr-1">$</span><input type="text"
                                            (keyup)="CalculationSubtotal()"
                                            [disabled]="!enableCheckBox||isOrderApproved"
                                            (keypress)="formatCurrencyDiscount()"
                                            (blur)="formatCurrencyDiscountBlur($event.target.value)"
                                            [(ngModel)]="discountamount" class="width-100_1 Cost " id="dollor"
                                            maxlength="8" autocomplete="off" />
                                        <span *ngIf="discountRadioBox=='Percentage'" class="pl-1">%</span>
                                    </td>
                                    <td style="color: #f00;">({{discount|currency}})</td>
                                </tr>
                                <tr>

                                    <td class="tr-bg-clr-none removeBorder"></td>
                                    <td colspan="3" align="left" class="border_left">
                                        SubTotal with Discounts
                                    </td>
                                    <td>{{partExtSubTotalWithDiscount|currency}}</td>
                                    <td></td>
                                    <td>{{laborExtSubTotalWithDiscount|currency}}</td>
                                    <td>{{SubTotalWithDisnt|currency}}</td>
                                </tr>
                                <tr>

                                    <td class="tr-bg-clr-none removeBorder"></td>
                                    <td colspan="6" align="left" class="border_left">
                                        <select [(ngModel)]='TaxType' name='TaxType' (change)="SaleTaxType()"
                                            [disabled]="isOrderApproved">
                                            <option value="1" selected>Sales Tax</option>
                                            <option value="2">Reseller</option>
                                            <option value="3">Out of State</option>
                                        </select>
                                        <span *ngIf="SaleTaxDiv">
                                            <input type="text" class="Cost"
                                                (keypress)="formatCurrencySalesTaxKeyPress()"
                                                (blur)="SaleTaxBlur($event.target.value)" maxlength="5"
                                                (keyup)="CalculationSalesTax()" [(ngModel)]="SaleTaxAmount" id="dollor1"
                                                [disabled]="isOrderApproved" autocomplete="off" />
                                            <span class="dollar ml-1">%</span>
                                            <label class="ml-2">
                                                Parts &nbsp;<input class="p_relative custom_checkbox" type="checkbox"
                                                    name="IsTaxPart" [(ngModel)]='IsTaxPart'
                                                    (change)="CalculationSalesTax()" [disabled]="isOrderApproved">
                                            </label>
                                            <label class="ml-2">
                                                Labor &nbsp;<input class="p_relative custom_checkbox" type="checkbox"
                                                    name="IsTaxLabor" [disabled]="!IsTaxPart||isOrderApproved"
                                                    [(ngModel)]='IsTaxLabor' (change)="CalculationSalesTax()">
                                            </label>
                                        </span>
                                        <label class="ml-2" *ngIf="Resellertextbox">
                                            Reseller No. <input type="text" class="width-150"
                                                [disabled]="isOrderApproved" [(ngModel)]='ResellerNo' maxlength="10">
                                        </label>
                                    </td>
                                    <td>{{TaxTotalAmount|currency}}</td>
                                </tr>
                                <tr>

                                    <td class="tr-bg-clr-none bb-1"></td>
                                    <td colspan="3" align="left" class="border_left">
                                        Total
                                    </td>
                                    <td colspan="3"></td>
                                    <td colspan="1">{{finalAmount|currency}}</td>
                                </tr>
                            </tfoot>

                        </table>

                        <table class="display table clearfix order-detail swapcategory"
                            [style.display]="dropdownView==2 ? '' : 'none'">
                            <thead>
                                <tr>
                                    <th style="width: 30%;">Task By Workflow</th>
                                    <th style="width: 50%;">Task Description</th>
                                    <th style="width: 5%;">Task Hrs</th>
                                    <th style="width: 5%;"></th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let workflow of partWorkflowList;let index=index">
                                    <tr class="part-category hoverEffect" id="catIds">
                                        <td colspan="5">
                                            <span (click)="OpenpartWorkflow(workflow.workflowId)" class="openpart"
                                                id="openpartWorkflow-{{workflow.workflowId}}"><i
                                                    class="fas fa-plus-circle"></i></span>
                                            {{index+1}}.0 {{workflow.workflowName}}
                                        </td>
                                    </tr>
                                    <ng-container *ngFor="let parts of workflow.workflowPart;let innerIndex=index;">
                                        <tr
                                            class="hoverEffect openpartWorkflow-{{workflow.workflowId}}-form table-partname">
                                            <td colspan="5">
                                                <div class="d-flex align-items-center position-relative">
                                                    <span class="partdetail" (click)="OpenTask(parts.partId)"
                                                        id="tasklist-{{parts.partId}}">
                                                        <i class="fas fa-plus"></i>
                                                    </span>
                                                    &nbsp;{{index+1}}.{{innerIndex+1}}&nbsp;{{parts.partName}}&nbsp;&nbsp;
                                                    <span class="d-inline-flex align-items-center ml-2">
                                                        <button type="button" class="showBtn btn btn-sm green-bg sq-btn p-addPart"
                                                            (click)="OpenAddTaskModal(parts.partId,workflow.workflowId, workflow.workflowName, parts.partName)"><span
                                                                class="fas fa-plus"></span> Task
                                                        </button>
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                        <ng-container *ngFor="let tasks of parts.taskList; let taskIndex=index;">
                                            <tr
                                                class="table-partdetailWorkflow tasklist-{{parts.partId}}-detail {{tasks.hideClass}}">
                                                <td>
                                                    <span class="ml-5">{{tasks.taskName}}</span>
                                                    <span class="ml-2">
                                                        <button type="button" class="arrow-updown"
                                                            (click)="SwapTaskItem(parts.taskList, taskIndex, (taskIndex-1))"
                                                            [disabled]="taskIndex==0? true : false">
                                                            <span class="fas fa-arrow-up"></span>
                                                        </button>
                                                        <button type="button" class="arrow-updown"
                                                            (click)="SwapTaskItem(parts.taskList, taskIndex, (taskIndex+1))"
                                                            [disabled]="taskIndex==parts.taskList.length-1 ? true : false">
                                                            <span class="fas fa-arrow-down"></span>
                                                        </button>
                                                    </span>
                                                </td>
                                                <td>
                                                    {{tasks.taskDescription}}
                                                </td>
                                                <td>
                                                    {{tasks.taskHour}}
                                                </td>
                                                <td>
                                                    <!-- {{tasks.partId}}{{tasks.task}}{{tasks.workflowId}} -->
                                                    <span *ngIf="btnShowvalidation && btnOkShow==false">
                                                        <a><i class="fas fa-trash-alt order-del"
                                                                style="font-size: 9px !important;"
                                                                (click)="deleteTask(taskIndex, tasks.partId)"></i></a>
                                                        <a><span class="fas fa-edit partEdit"
                                                                (click)="fillTaskDetails(tasks, 'edit')"></span></a>
                                                    </span>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                            </tbody>
                        </table>

                        <div class="col-md-12 pr-0">
                            <div>
                                <div class="submit-from text-right">
                                    <button type="button"
                                        *ngIf="rolePermissionsList && rolePermissionsList[0].isInsert && isActiveAdd"
                                        data-toggle="modal" data-target="#ConfirmOrderPlace"
                                        class="btn blue-bg round-btn" (click)="addProduct()" [disabled]="isPartval"><i
                                            class="fas fa-save" aria-hidden="true"></i>
                                        Save Product
                                    </button>

                                    <button type="button"
                                        *ngIf="rolePermissionsList && rolePermissionsList[0].isUpdate && !isActiveAdd"
                                        class="btn blue-bg round-btn" (click)="editProduct()"><i class="fas fa-save"
                                            aria-hidden="true"></i> Update Product
                                    </button>
                                    <!-- <button class="btn darkred-bg round-btn" *ngIf="clearbutton==true" (click)="clearProductDetails()"><i
                                            class="fas fa-redo" aria-hidden="true"></i>
                                        Cancel 
                                    </button> -->
                                    <button type="button" class="btn darkred-bg round-btn" (click)="DiscrdProduct(discardItem)"><i
                                            class="fas fa" aria-hidden="true" class="fas fa-sign-out-alt"></i>
                                        Exit </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    <div class="modal" id="addpartModal1">
        <div class="modal-dialog  modal-dialog-centered modal-lg centModel" role="document">
            <div class="modal-content">
                <h5 class="modal-title info-popup-title p-1 pl-2" id="exampleModalLabel1">Add Part -
                    {{CategoryshowonPopup}}</h5>
                <button type="button" class="close btn alert_modal" aria-label="Close" (click)="PartItemExit()">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="modal-body">
                    <div class="row mb-1">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-4 col-12">
                                    <div class="d-flex align-items-center">
                                        <label class="customLabel mr-2" for="Vendor">Vendor</label>
                                        <select class="customInput newsmallddl" [(ngModel)]="selectedVendorValue">
                                            <option value='' selected>All</option>
                                            <option *ngFor="let item of vendorPartListData" [value]="item.id">
                                                {{item.vendorName}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-3 col-md-12 mt-lg-0 mt-1">

                                    <div class="d-flex align-items-center">
                                        <select class="customInput pl-1px"
                                            (change)="ChangefieldToSearch($event.target.value)"
                                            [(ngModel)]="fieldToSearch" id="fieldToSearch">
                                            <option value='1'>Search Keywords</option>
                                            <option value='2'>List or Vendor Part Name</option>
                                            <option value='3'>List or Vendor Part #</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-md-3 col-11 pr-md-2 mt-md-0 mt-1">
                                    <div class="d-flex align-items-center">
                                        <input type="text" class="customInput" placeholder="{{fieldToSearchPlaceHolder}}"
                                            [(ngModel)]="PartName_search" autocomplete="off"
                                            (keyup.enter)="SearchByvendorList()">
                                    </div>
                                </div>
                                <div class="col-md-1 col-1 text-left or_width"
                                    style="max-width: 30px; padding:0; margin-top: 3px;">
                                    <i class="fas fa-search cpointer" (click)="SearchByvendorList()"></i>
                                </div>
                                <div class="col-md-1 col-1 text-left or_width">
                                    <button type="button" class="btn blue-bg all-custom round-btn m-0 centbutton"
                                        (click)="SearchByvendorListAll()">All Parts</button>
                                </div>
                                <!-- <div class="col-md-5 col-11 pr-md-0 mt-md-0 mt-1">
                                    <div class="d-flex align-items-center">
                                        <label class="customLabel ws-nobreak pr-1">Part Name/Number</label>
                                        <input type="text" class="customInput" [(ngModel)]="PartName_search" />
                                    </div>
                                </div>
                                <div class="col-md-1 col-1 text-left or_width"
                                    style="max-width: 30px; padding:0;margin-top: 3px;">
                                    <i class="fas fa-search cpointer ml-2" (click)="SearchByvendorList()"></i>
                                </div> -->
                            </div>
                        </div>



                    </div>
                    <hr>
                    <div class="white-box1" [style.display]="partListShow==true ? 'block' : 'none'">
                        <div class="white-box-title1">
                            <div class="customHeading">
                                Part List
                            </div>
                        </div>
                        <!------------------------------------- company list table ------------------------------>
                        <div class="card-desc1 mt-1">
                            <div class="table-responsive autoWidth">
                                <table id="datatable1" class="display table clearfix"
                                    class="row-border hover table-cursor" datatable [dtOptions]="dtOptions2"
                                    [dtTrigger]="dtTrigger2">
                                    <thead>
                                        <tr>
                                            <th class="coloumn-hide"></th>
                                            <th (click)="SortTableHeader('name1','parts')">List Part Name<span id="name1" class="sorting2"></span></th>
                                            <th (click)="SortTableHeader('partNo','parts')">List Part#<span id="partNo" class="sorting2"></span></th>
                                            <th (click)="SortTableHeader('vendorName','parts')">Vendor Name<span id="vendorName" class="sorting2"></span></th>
                                            <!-- <th>Category</th> -->
                                            <th (click)="SortTableHeader('vendorPartName','')">Vendor Part Name<span
                                                id="vendorName" class="sorting1"></span></th>
                                        <th (click)="SortTableHeader('vendorPartNumber','')">Vendor Part#<span id="vendorName" class="sorting1"></span></th>
                                            <th (click)="SortTableHeader('cost','parts')">Part Price<span id="cost" class="sorting2"></span></th>
                                            <th class="width-75" (click)="SortTableHeader('hours','parts')">Labor Hrs<span id="hours" class="sorting2"></span></th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="ddlPartList?.length != 0">
                                        <tr *ngFor="let data of ddlPartList" (click)="PartdetailsModalPopup(data.id);">
                                            <td class="coloumn-hide">{{data.id}}</td>
                                            <td class="text-over-ellipse-td">
                                                <span class="tooltip-text"><a>{{data.name}}</a></span>
                                                <span class="tooltip-td">{{data.name}}</span>
                                            </td>
                                            <td><a>{{data.partNo}}</a></td>
                                            <td><a>{{data.vendorName}}</a></td>
                                            <!-- <td><a>{{data.partCategoryName}}</a></td> -->
                                            <td><a>{{data.vendorPartName}}</a></td>
                                            <td><a>{{data.vendorPartNumber}}</a></td>
                                            <td><a>{{data.cost|currency}}</a></td>
                                            <td><a>{{data.hours|number: '1.2-2'}}</a></td>
                                        </tr>
                                    </tbody>
                                    <tbody *ngIf="ddlPartList==null">
                                        <tr class="c1">
                                            <td colspan="7" class="no-data-available">No data available in table</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>

                    <div class="submit-from text-right">
                        <!-- <button class="btn blue-bg round-btn" (click)="addPart(partObjectModel)"><i class="fas fa-save"
                                aria-hidden="true"></i> Add Part
                        </button> -->
                        <button type="button" class="btn darkred-bg round-btn" (click)="PartItemExit();"><i aria-hidden="true"></i>
                            Exit </button>

                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="modal" id="addpartModalpopup">
        <div class="modal-dialog  modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
                <h5 class="modal-title info-popup-title p-1" id="exampleModalLabel1">
                    <div class="text-over-ellipse pl-2 text-white">{{partObjectModel.name}}</div>
                </h5>
                <button type="button" class="close btn alert_modal" aria-label="Close" (click)="ClosePartModalPopup()">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="modal-body">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="row">
                                <div class="col-md-6">

                                    <label class="customLabel">Company Part #</label>
                                </div>
                                <div class="col-md-6 f_size_10 customLabel ">
                                    {{partObjectModel.partNo}}


                                </div>
                            </div>
                        </div>
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-4">
                                    <label class="customLabel">Company Part Name</label>
                                </div>
                                <div class="col-md-8 f_size_10 text-over-ellipse customLabel ">
                                    {{partObjectModel.name}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-md-4">
                            <div class="row">
                                <div class="col-md-6">
                                    <label class="customLabel">Vendor Part #</label>
                                </div>
                                <div class="col-md-6 f_size_10 customLabel ">
                                    {{partObjectModel.vendorPartNumber}}
                                    <span style="display: none;">{{partObjectModel.vendorName}}</span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-8">
                            <div class="row">
                                <div class="col-md-4">
                                    <label class="customLabel">Vendor Part Name</label>
                                </div>
                                <div class="col-md-8 f_size_10 text-over-ellipse customLabel ">
                                    {{partObjectModel.vendorPartName}}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-4">
                        <div class="col-md-2">
                            <div class="d-flex align-items-center">
                                <label class="customLabel mr-2">Quantity</label>
                                <input type="text" [(ngModel)]="partObjectModel.qty" class="customInput NumberOnly"
                                    placeholder="Qty" id="quantity" maxlength="5" min="1" max="99">
                                <div *ngIf="((quantity=='') && (validationPart))" class="text-danger input-error">
                                    Enter Quantity.</div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="d-flex align-items-center trp">
                                <label class="customLabel mr-2">Workflow</label>
                                <select class="customInput pl-1px" [(ngModel)]="partObjectModel.workflowId">
                                    <option value=''>Select Workflow</option>
                                    <option *ngFor="let item of partObjectModel.WorkflowList" [value]="item.id">
                                        {{item.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                        <!-- <div class="col-md-3">
                            <div class="d-flex align-items-center trp required">
                                <label class="customLabel mr-2">Workflow</label>
                                <select class="customInput pl-1px" [(ngModel)]="partObjectModel.workflowId"
                                    [ngClass]="((!partObjectModel.workflowId) && (validationPart)) ? 'validation_border': ''">
                                    <option value=''>Select Workflow</option>
                                    <option *ngFor="let item of partObjectModel.WorkflowList" [value]="item.id">
                                        {{item.name}}
                                    </option>
                                </select>
                            </div>
                        </div> -->
                    </div>
                    <div class="row mt-2">
                        <div class="col-md-12">
                            <div class="customHeading">
                                Part Questions
                            </div>
                        </div>
                        <div class="col-md-12 mt-2">
                            <div class="row pb-2" *ngFor="let item of partObjectModel.partQuestions;let i=index">
                                <div class="col-md-8">
                                    <label class="customLabel">{{item.questionName}}</label>
                                </div>

                                <div class="col-md-4" *ngIf="item.typeKey =='Text'">
                                    <input type="text" [(ngModel)]="item.answerName" class="customInput"
                                        placeholder="Type Answer" maxlength="50">
                                </div>
                                <div class="col-md-4" *ngIf="item.typeKey =='VendorName'">
                                    <select class="form-control newsmallddl" [(ngModel)]="item.answerName">
                                        <option value='null'>Select Vendor</option>
                                        <option *ngFor="let item of vendorList">{{item.vendorName}}
                                        </option>
                                    </select>
                                </div>
                                <div class="col-md-4" *ngIf="item.typeKey =='CustomerName'">
                                    <select class="form-control newsmallddl " [(ngModel)]="item.answerName">
                                        <option value='null' selected>Select Customer</option>
                                        <option *ngFor="let item of customerData">
                                            {{item.customerName}}
                                        </option>

                                    </select>
                                </div>
                                <div class="col-sm-4" *ngIf="item.typeKey =='YesNo'">
                                    <select class="form-control newsmallddl" [(ngModel)]="item.answerName">
                                        <option value='null' selected>Select Yes/No</option>
                                        <option value="Yes">Yes</option>
                                        <option value="No">No</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="submit-from text-right">
                        <button type="button" class="btn blue-bg round-btn" (click)="addPart(partObjectModel)"><i class="fas fa-save"
                                aria-hidden="true"></i> Add Part
                        </button>
                        <button type="button" class="btn darkred-bg round-btn " (click)="ClosePartModalPopup()"><i
                                aria-hidden="true"></i> Exit
                        </button>
                    </div>
                </div>

            </div>
        </div>
    </div>
    <div class="modal" id="addpartInWorkflow">
        <div class="modal-dialog  modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
                <h5 class="modal-title info-popup-title p-1 pl-2" id="exampleModalLabel1">Add Task for Workflow
                    {{addTaskPopupWorkflowName}} -
                    Part {{addTaskPopupPartName}}</h5>
                <button type="button" class="close btn alert_modal" aria-label="Close" (click)="CloseAddTask()">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="modal-body">
                    <div class="row mb-3">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-5 col-11 pr-md-2 mt-md-0 mt-1">
                                    <div class="d-flex align-items-center">
                                        <label class="customLabel ws-nobreak pr-1">Task Name</label>
                                        <input type="text" class="customInput" [(ngModel)]="partObjectModel.TaskName_search"/>
                                    </div>
                                </div>
                                <div class="col-md-1 col-1 text-left or_width"
                                    style="max-width: 30px; padding:0; margin-top: 3px;">
                                    <i class="fas fa-search cpointer" (click)="SearchByTaskName()"></i>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mb-1">
                        <div class="col-md-12">
                            <table id="datatable2" class="display table clearfix" class="row-border hover" datatable
                                [dtOptions]="dtOptions3" [dtTrigger]="dtTrigger3">
                                <thead>
                                    <tr>
                                        <th>Task Name</th>
                                        <th>Task Description</th>
                                        <th>Task Hrs</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="TaskLists?.length != 0">
                                    <tr *ngFor="let data of TaskLists" (click)="fillTaskDetails(data, 'add');"
                                        style="cursor: pointer;">
                                        <td>{{data.taskName}}</td>
                                        <td>{{data.notes}}</td>
                                        <td>{{data.taskHours}}</td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="TaskLists==null">
                                    <tr class="c1">
                                        <td colspan="3" class="no-data-available">No data available in table</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div class="submit-from text-right">
                        <button type="button" class="btn darkred-bg round-btn" (click)="CloseAddTask()"><i aria-hidden="true"></i>
                            Exit </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal" id="addpartInWorkflowForm">
        <div class="modal-dialog  modal-dialog-centered modal-lg" role="document">
            <div class="modal-content" style="min-height: 222px;">
                <h5 class="modal-title info-popup-title p-1 pl-2" id="exampleModalLabel1">Task Name -
                    {{partObjectModel.taskName}}</h5>
                <button type="button" class="close btn alert_modal" aria-label="Close" (click)="CloseAddTask()">
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="modal-body">
                    <div class="row mb-1">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-lg-6 col-md-12 mt-lg-0 mt-1">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-2 trp required">
                                            <label class="customLabel">Task Name</label>
                                        </div>
                                        <div class="col-lg-8 col-md-10">
                                            <input type="text" class="customInput" [disabled]="true"
                                                [(ngModel)]="partObjectModel.taskName"
                                                [ngClass]="((!partObjectModel.taskName) && (validationTask)) ? 'validation_border': ''" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-6 col-md-12 mt-lg-0 mt-1">
                                    <div class="row">
                                        <div class="col-lg-3 col-md-2 trp required">
                                            <label class="customLabel">Task Hrs</label>
                                        </div>
                                        <div class="col-lg-4 col-md-10">
                                            <input type="text" class="customInput"
                                                [(ngModel)]="partObjectModel.taskHour"
                                                [ngClass]="((!partObjectModel.taskHour) && (validationTask)) ? 'validation_border': ''" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-lg-12 col-md-12 mt-lg-0 mt-1">
                                    <div class="row">
                                        <div class="col-lg-2 col-md-2">
                                            <label class="customLabel">Task Description</label>
                                        </div>
                                        <div class="col-lg-10 col-md-10">
                                            <textarea class="customInput customerTextarea ml-0" rows="3" cols="5"
                                                autocomplete="off" placeholder="Enter Description" maxlength="500"
                                                [(ngModel)]="partObjectModel.taskDescription"></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="submit-from text-right">
                        <button type="button" class="btn blue-bg round-btn" (click)="AddPartTask()">{{addTaskButton}}</button>
                        <button type="button" class="btn darkred-bg round-btn" (click)="CloseAddTaskForm()"><i aria-hidden="true"></i>
                            Back </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>





<ng-template #content let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg alert_heading sweet-heading"></h4>
    <button type="button" class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body p-1">
        <div class="thank-you-pop">
            <img src="./assets/image/popup-alert.png" alt="">
            <h1>Confirmation</h1>
            <p class="mt-1">{{deleteDetail}}</p>
        </div>
    </div>
    <div class="modal-footer sweet-footer text-center p-1">
        <button type="button" class="btn green-bg round-btn"
            (click)="deleteProduct(deletedItem,deleteType); c('Close click')">Yes</button>
        <button type="button" class="btn red-bg round-btn" (click)="c('Close click')">No</button>
    </div>
</ng-template>
<ng-template #contentcategory let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg alert_heading sweet-heading"></h4>
    <button type="button" class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>

    <div class="modal-body p-1">
        <div class="thank-you-pop">
            <img src="./assets/image/popup-alert.png" alt="">
            <h1>Confirmation</h1>
            <p class="mt-1">{{deleteDetail}}</p>
        </div>
    </div>
    <div class="modal-footer sweet-footer text-center p-1">
        <button type="button" class="btn green-bg round-btn" (click)="RemoveCategory(); c('Close click')">Yes</button>
        <button type="button" class="btn red-bg round-btn" (click)="c('Close click')">No</button>
    </div>
</ng-template>
<ng-template #contentname let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg alert_heading sweet-heading"></h4>
    <button type="button" class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>

    <div class="modal-body p-1">
        <div class="thank-you-pop">
            <img src="./assets/image/popup-alert.png" alt="">
            <h1>Confirmation</h1>
            <p class="mt-1">{{deleteDetail}}</p>
        </div>
    </div>
    <div class="modal-footer sweet-footer text-center p-1">
        <button type="button" class="btn green-bg round-btn" (click)="DeletePart(); c('Close click')">Yes</button>
        <button type="button" class="btn red-bg round-btn" (click)="c('Close click')">No</button>
    </div>
</ng-template>
<div class="modal" id="myModal">
    <div class="modal-dialog">
        <div class="modal-content">

            <div class="modal-header1 darkred-bg1">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="hidemodalpopup()" data-dismiss="modal">
                    <span>&times;</span>
                </button>
            </div>

            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="">
                    <h1>Alert</h1>
                    <p>You have not added any part category, so please add at least one part category.</p>
                </div>

                <div class="modal-footer sweet-footer p-1">
                    <button type="button" class="btn red-bg round-btn m-0" (click)="hidemodalpopup()"
                        data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="CategoryExist">
    <div class="modal-dialog">
        <div class="modal-content">
            <h4 class="modal-title info-popup-header darkred-bg alert_heading sweet-heading"></h4>
            <button type="button" class="close btn alert_modal" aria-label="Close" (click)="CategoryExistClose()">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
                <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="">
                    <h1>Alert</h1>
                    <p class="modal-para mb-0">You have Already Added this Category into List.</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer">
                <button type="button" class="btn red-bg round-btn m-0" (click)="CategoryExistClose()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<ng-template #contentCat let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg alert_heading sweet-heading"></h4>
    <button type="button" class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body p-1">
        <div class="thank-you-pop">
            <img src="./assets/image/popup-alert.png" alt="">
            <h1>Alert</h1>
            <p class="mt-1">{{confirmationMsg}}</p>
        </div>
    </div>
    <div class="modal-footer sweet-footer text-center p-1">
        <button type="button" class="btn red-bg round-btn m-0" (click)="c('Close click')">Ok</button>
    </div>
</ng-template>
<div class="modal" id="myModalAlreadyExits">
    <div class="modal-dialog">
        <div class="modal-content">
            <h4 class="modal-title info-popup-header darkred-bg alert_heading sweet-heading"></h4>
            <button type="button" class="close btn alert_modal" aria-label="Close" (click)="hidemodalpopup1()">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
                <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="">
                    <h1>Alert</h1>
                    <p class="modal-para mb-0">You have Already Added this Part into List.</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer">
                <button type="button" class="btn red-bg round-btn m-0" (click)="hidemodalpopup1()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>


<div class="modal" id="add_success">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="addsuccess()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Added Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="addsuccess()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="add_update">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="updateitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Update Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="updateitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="delete_success">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="deleteitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Deleted Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="deleteitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="wrong_msg">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="msgitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>Something went wrong</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="msgitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="CustomPartitem">
    <div class="modal-dialog">
        <div class="modal-content">
            <h4 class="modal-title info-popup-header darkred-bg alert_heading sweet-heading"></h4>
            <button type="button" class="close btn alert_modal" aria-label="Close" (click)="CustomPartPopup()">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thanks</h1>
                    <p class="modal-para mb-0">Part : {{partObjectModel.name}} Added</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer">
                <button type="button" class="btn red-bg round-btn m-0" (click)="CustomPartPopup()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<ng-template #discardItem let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg alert_heading sweet-heading"></h4>
    <button type="button" class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>

    <div class="modal-body p-1">
        <div class="thank-you-pop">
            <img src="./assets/image/popup-alert.png" alt="">
            <h1>Confirmation</h1>
            <p class="mt-1">{{deleteDetail}}</p>
        </div>
    </div>
    <div class="modal-footer sweet-footer text-center p-1">
        <button type="button" class="btn green-bg round-btn" (click)="gotoDashBoard(); c('Close click')">Yes</button>
        <button type="button" class="btn red-bg round-btn" (click)="c('Close click')">No</button>
    </div>
</ng-template>
<div class="modal" id="taskMessage">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="closeTaskMessage()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <p>{{taskMessage}}</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="closeTaskMessage()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="CopyProduct">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading">Copy Product to new Product</h4>
                <button type="button" class="close btn alert_modal" (click)="closeCopyProduct()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                  
                    <p>Copy Product "{{copyProductName}}" to new Product 
                    <input type="text" class="customInput"  [(ngModel)]="newProduct"  autocomplete="off"
                    [ngClass]="(newProduct == '' && (copyValidation)) ? 'validation_border': ''" />    
                    </p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn green-bg round-btn" (click)="CreateCopyProduct(copyProductId)"
                >Create Product</button>
                <button type="button" class="btn red-bg round-btn" (click)="closeCopyProduct()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="CreatingProduct">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading">Copy Product to new Product</h4>
                <button type="button" class="close btn alert_modal"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                  
                    <p>Creating Product...</p>
                    
                </div>
            </div>
          
        </div>
    </div>
</div>

<div class="modal" id="create_success">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="deleteitems()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Product Copy Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="deleteitems()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="ProductCopyExist">
    <div class="modal-dialog">
        <div class="modal-content">
            <h4 class="modal-title info-popup-header darkred-bg alert_heading sweet-heading"></h4>
            <button type="button" class="close btn alert_modal" aria-label="Close" (click)="ProductCopyExistClose()">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
                <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="">
                    <h1>Alert</h1>
                    <p class="modal-para mb-0">{{ProductExist}}</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer">
                <button type="button" class="btn red-bg round-btn m-0" (click)="ProductCopyExistClose()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>