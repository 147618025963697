import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ConstantsService } from 'src/app/constants.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { identity, Subject } from 'rxjs';
import { SharedService } from 'src/app/services/shared.service';
import { PartQuestions, ProductModel, ProductParts } from './productParts';
// import { TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
import { QueryList } from '@angular/core';
import { ViewChildren } from '@angular/core';
import { ConfirmationDialogService } from 'src/app/ConfirmationDialog/confirmation-dialog.service'
import { GlobalChangeService } from 'src/app/services/global-change.service';
import Swal from 'sweetalert2';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { VendorService } from 'src/app/services/vendor/vendor.service';
import { CustomerService } from 'src/app/services/customer/customer.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { PartService } from 'src/app/services/part/part.service';


@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.css']
})
export class ProductsComponent implements AfterViewInit, OnDestroy, OnInit {
  isVehicleRequired: boolean = false;
  settingDetailCSS: any;
  settingLogoImage: any; productName: any; Year: any;
  productDescription: any; data: any;
  catagoryData: any;
  SelectedCatagoryName = '';
  manufacturerId: any;
  manufacturerModelId: any;
  ProductView = 1;
  SalesOrderView: boolean = true;
  ProductionView: boolean = false;
  btnArea: boolean = true;
  IsEnableAprrovel: boolean = false;
  SelectedCatagoryId: any;
  userId: string;
  selectTedCategoryArray = [];
  selectTedPartArray = [];
  productDetails: any;
   customerDiscountList: any;
  deletedItem: string;
  deleteDetail: string;
  partObjectModel = new ProductParts();
  ProductDetailsModel = new ProductModel();
  partQuestions = new PartQuestions();
  partList = [];
  subSection: any = 0;
  selectedPartValue = '';
  categoryIndex: any;
  // config = TreeviewConfig.create({
  //   hasAllCheckBox: true,
  //   hasFilter: true,
  //   hasCollapseExpand: true,
  //   decoupleChildFromParent: false,
  //   maxHeight: 400
  // });
  value = 0;
  userGroupObjects = new Array();
  companyList: any;
  categoryPartDetails = [];
  ProductPartModels = [];
  public now: Date = new Date();
  //@ViewChild('closeBtn') closeBtn: ElementRef;
  questionAnswersDetails: any;
  validation = false;
  copyValidation = false;
  catagoryDataList: any;
  catagoryDataList1: any;
  productDetailsById: any;
  getPartId: any;
  isActiveAdd = true;
  isActiveAddPart = true;
  form: FormGroup;
  formSearch: FormGroup;
  companyId: string;
  customerData: any;
  vendorList: any;
  productId = 0;
  categoryPartValidation = false;
  validationPart = false;
  validationTask = false;
  PartQuestionAnswersDetails = [];
  companyLoginId: string;
  rolePermissionsList: any;
  userList: any;
  roleId: any;
  loader = false;
  getPartDetailsByCategoryId: any;
  recordAlreadyExist = false;
  type: any;
  partIndex: any;
  productPartId: any;
  isPartUpdate = true;
  permissionMessage = '';
  laborHrsCost: any = 0;
  partCategoriesList = [];
  partWorkflowList = [];
  isDiscountDoller: boolean;
  isDiscountPercentage: boolean;
  PartExtensionTotal: any;
  LaborExtesionTotal: any;
  HoursExtensionTotal: number;
  GrandTotalAmount: any = 0;
  PartGrandTotal: any = 0;
  PartCheckBox: any;
  discountamount: any;
  LaborCheckBox: any;
  discount: any = 0;
  subtotalwithdiscount: any = 0;
  SaleTaxAmount: any;
  saletaxdiscount: any = 0;
  SubdiscountExtension: any = 0;
  PartLaborExtension: any = 0;
  Partxtension: any = 0;
  LaborExtesion: any = 0;
  partExtSubTotalWithDiscount: any = 0;
  laborExtSubTotalWithDiscount: any = 0;
  SubTotalWithDisnt: any = 0;
  IsTaxPart: any;
  IsTaxLabor: any;
  TaxTotalAmount: any = 0;
  orderNumber: any;
  TaxType: any = 1;
  TaxName: any;
  TaxRateAmount: any;
  ResellerNo: any;
  IsOrderApproved: any;
  LaborExtensionTotal: any;
  LaborHourTotal: any;
  PartExtenionTotal: any;
  CustomerPonumber: any;
  Resellertextbox: boolean = false;
  SaleTaxDiv: boolean = true;
  enableCheckBox: boolean = false;
  costomerPONumber: any;
  isOrderApproved: boolean = false;
  confirmationAletType: string = "";
  confirmationMsg: string = "NA";
  isOrderConfirmType: string = "";
  idPart: string;
  categoryDeleteId: any;
  categorydeletecategoryid: any;
  grandTotal: any = 0;
  finalAmount: any = 0;
  orderDetails: any;
  btnShowvalidation: boolean = false;
  btnUpdateOrderValidation: boolean = false;
  discountRadioBox: any;
  discountRate: any = 0;
  DiscountedAmount: any = 0;
  selectedCategoryValue = '';
  LocalDdlPartList = [];
  ddlPartList: any;
  partCategoryId: any = 0;
  quantity: any;
  normalShow: boolean = true;
  RowEdit: boolean = false;
  CurrentEditRow: string;
  EditRowCateId: any = 0;
  EditRowPartId: any = 0;
  EditRowTxtName: any = 0;
  btnOkShow: boolean = false;
  btnEditDeleteShow: boolean = true;
  UpdateQty: any = 0;
  UpdateEachPartCost: any = 0;
  UpdateLaborHrs: any = 0;
  selectedQuestionId: any = 0;
  selectedPartId: any = 0;
  orderPartListArray = [];
  orderTaskListArray = [];
  PartsDetails = [];
  TotalExtension: any;
  clearbutton: boolean = true;
  TCreateUpdate: any = 1;
  ProductSearch: any = "";
  SearchValidation: any;
  SearchControl: boolean = false;
  ListShow: boolean = false;
  ListDiv: boolean = false;
  FilterData: any;
  categorydisabled = false;
  dropdownView: any = 1;
  vehicleRequired: boolean = false;
  isBackgroudImage: boolean = false;
  open: boolean = false;
  // config_search = TreeviewConfig.create({
  //   hasAllCheckBox: true,
  //   hasFilter: true,
  //   hasCollapseExpand: true,
  //   decoupleChildFromParent: false,
  //   maxHeight: 400
  // });
  userGroupObjects_search = new Array();
  value_search = 0;
  // items_search: TreeviewItem[];
  companyList_search: any;
  SearchType: any;
  vendorPartListData: any;
  selectedVendorValue: any = '';
  selectedManufacturerValue: any = '';
  selectedManufacturerModelValue: any = ''
  PartName_search: any = '';
  validationsearch: boolean = false;
  datalistList: any;
  CategoryshowonPopup: any;
  PartListRecord: any;
  partListShow = false;
  PartListDiv = true;
  isCatval: boolean = true;
  isPartval: boolean = true;
  maxminvalue: any = '';
  displayindex: any = '';
  indexing: any = 0;
  ordering: any = 0;
  addTaskPopupWorkflowName: any;
  addTaskPopupPartName: any;
  addTaskButton: any;
  partIdAddTask: number;
  workflowIdAddTask: number;
  TaskLists = [];
  TaskListArray = [];
  taskMessage: any;
  isSaveOrUpdate: boolean = false;
  manufacturerList = [];
  manufacturerModelList = [];
  fieldToSearch: any = '3';
  fieldToSearchPlaceHolder = 'Enter List or Vendor Part #';
  @ViewChildren(DataTableDirective)
  dtElements: QueryList<DataTableDirective>;
  dtOptions1: DataTables.Settings = {};
  dtTrigger1: Subject<any> = new Subject<any>();
  dtOptions2: DataTables.Settings = {};
  dtTrigger2: Subject<any> = new Subject<any>();
  dtOptions3: DataTables.Settings = {};
  dtTrigger3: Subject<any> = new Subject<any>();
  closeBtn: ElementRef;
  workFlowCategoriesList: any;
  copyProductName: any;
  copyProductId: any;
  SelectedCopyProduct: any;
  newProduct: string;
  ProductExist: string;
  copyId: any;
  answerUpdatedList = [];
  answerValue: any;
  notesValue: any;
  isQA: boolean = false;
  isNotes: boolean = false;
  items_search: any;
  // dataSource = new MatTableDataSource();

  // @ViewChild(MatSort) sort: MatSort;

  constructor(
    public sharedService: SharedService,
    public vendorService: VendorService,
    public customerService: CustomerService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private constants: ConstantsService,
    private router: Router,
    public confirmation: ConfirmationDialogService,
    public globalChangeService: GlobalChangeService,
    private companyService: CompanyService,
    private partService: PartService
    ) { }

  ngOnInit(): void {
    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);
    } else {
      this.rolePermissionsList = JSON.parse(localStorage.getItem('rolePermissionsList'));
      this.rolePermissionsList = this.rolePermissionsList.filter(x => x.pageId == 7);
      this.permissionMessage = '';
      if (this.rolePermissionsList != null && this.rolePermissionsList != undefined) {
        if (!this.rolePermissionsList[0].isView) {
          this.permissionMessage += 'view';
        } if (!this.rolePermissionsList[0].isInsert) {
          if (this.permissionMessage != '') {
            this.permissionMessage += ', ';
          }
          this.permissionMessage += 'add';
        } if (!this.rolePermissionsList[0].isUpdate) {
          if (this.permissionMessage != '') {
            this.permissionMessage += ', ';
          }
          this.permissionMessage += 'edit';
        } if (!this.rolePermissionsList[0].isDelete) {
          if (this.permissionMessage != '') {
            this.permissionMessage += ', ';
          }
          this.permissionMessage += 'delete';
        }
      }
      if (this.permissionMessage != '') {
        this.permissionMessage = 'You do not have access to ' + this.permissionMessage + '. Please contact to administrator!';
      }

      this.companyLoginId = localStorage.getItem(this.constants.companyId);

      this.isActiveAdd = true;
      this.isActiveAddPart = true;
      this.btnShowvalidation = true;
      this.userId = localStorage.getItem(this.constants.userId);
      this.getAllCatagory(this.companyLoginId)

      //ankit commented
      // this.getAllCompany(this.companyLoginId);
      // this.getAllCompany_search(this.companyLoginId);
      this.GetvendorpartModal(this.companyLoginId);
      this.getAllWorkflowSteps(this.companyLoginId);
      this.getManufacturerList(this.companyLoginId);

      //ankit implementaion
      // get company by default set value
      var company = this.globalChangeService.getGlobalCompany();
      if(company>0){
        this.value = company;
        this.value_search = company;
        this.companyLoginId = company.toString();
        this.getAllCatagory(company);
        this.getAllCustomer(company);
        this.GetvendorpartModal(company);
        this.getManufacturerList(company);
      }
      else{
        this.ErrorModal('Error', 'Please select company.');
      }

      //on change function 
      this.globalChangeService.dropdownChange$.subscribe((data)=>{
        this.onValueChange(data);
        this.onValueChange_search(data);
      })
    }
    this.dtOptions1 = {
      pagingType: this.constants.full_numbers,
      pageLength: 10,
      ordering: false,
      //serverSide:true,
      scrollX: true
    };

    this.formSearch = new FormGroup({
      'TCreateUpdate': new FormControl(),
      'ProductSearch': new FormControl(),
    })
    this.form = new FormGroup({
      'productName': new FormControl(),
      'categoryType': new FormControl(),
      'productDescription': new FormControl(),
      'dropdownView': new FormControl(),
      'vehicleRequired': new FormControl(),
      'manufacturerId': new FormControl(new FormControl('', Validators.compose([Validators.required, Validators.minLength(1)])),),
      'manufacturerModelId': new FormControl(new FormControl('', Validators.compose([Validators.required, Validators.minLength(1)])),),
      'Year': new FormControl(),
    });
  }

  getManufacturerList(cid) {
    this.sharedService.GetManufacturerList(cid).subscribe(
      data => {
        this.data = data;
        if (this.data.dataList != null && this.data.dataList != undefined) {
          if (cid > 0) {
            this.manufacturerList = this.data.dataList;
          }
          else {
            this.selectedManufacturerValue = '';
          }
        }
      }
    )
  }

  async canDeactivate(): Promise<Promise<boolean> | boolean> {

    if (!this.form.pristine && !this.isSaveOrUpdate) {
      const confirmResult = await this.confirmation.confirm('Confirmation', 'Are you sure you want to leave this page ?', 'Cancel', 'OK', 'lg');
      if (confirmResult === true) {
        return true;
      } else {
        return false;
      }
    }
    else {
      return true;
    }
  }

  ChangeView(value) {
    if (value == 1) {
      this.dropdownView = 1;
    }
    else if (value == 2) {
      this.dropdownView = 2;
      this.partWorkflowList = [];
      this.partCategoriesList.forEach(cateLst => {
        cateLst.partList.forEach(partLst => {
          this.partWorkflowList.push(partLst);
        });
      });

      let partWorkflowList = this.partWorkflowList;
      this.partWorkflowList = [];
      const cateWorkflowSteps = [...new Set(partWorkflowList.map(item => item.workflowId))];

      cateWorkflowSteps.forEach(cateLst => {
        let obj = {
          workflowId: cateLst,
          workflowName: this.partObjectModel.WorkflowList.find(x => x.id == cateLst).name,
          workflowPart: partWorkflowList.filter(x => x.workflowId == cateLst)
        }
        this.partWorkflowList.push(obj);
      });
    }
  }

  getAllWorkflowSteps(companyId) {
    this.sharedService.getAllWorkflows(companyId, '').subscribe(
      data => {
        this.data = data;
        if (companyId > 0) {
          this.partObjectModel.WorkflowList = this.data.dataList;
        }
        else {
          this.partObjectModel.WorkflowList = '';
        }
      });
  }

  getAllCustomer(companyId) {
    this.customerService.GetAllCustomer(companyId, '').subscribe(
      data => {
        this.data = data;
        if (this.data.dataList != null && this.data.dataList != undefined) {
          if (companyId > 0) {
            this.customerData = this.data.dataList;
          }
          else {

          }
        }
        this.GetAllVendor(companyId);
      }
    )
  }

  GetAllVendor(companyId) {
    this.vendorService.GetAllVendor(companyId, '').subscribe(
      data => {
        this.data = data;
        if (this.data.dataList != null && this.data.dataList != undefined) {
          if (companyId > 0)
            this.vendorList = this.data.dataList;
        }
        else {

        }
        this.GetCompanyDetails(this.companyLoginId)
      }
    )
  }


  getAllCompany(companyId) {
    this.companyService.GetDropdownCompanyHierarchyModel(companyId).subscribe(
      data => {
        this.data = data;
        if (this.data.dataList != null && this.data.dataList != undefined) {
          this.companyList = this.data.dataList;
          // this.companyList.forEach((x) => {
          //   this.userGroupObjects.push(new TreeviewItem(x, false));

          // });
        }
        this.value = Number(companyId);
        this.getAllCustomer(this.companyLoginId);
      });
  }


  SortTableHeader(item: any, type: any) {

    if (type == 'parts') {
      if (this.ddlPartList != null) {
        var ret = new Array;
        for (var i = this.ddlPartList.length - 1; i >= 0; i--) {
          ret.push(this.ddlPartList[i]);
        }

        this.ddlPartList = ret;
        var Keys = Object.keys(this.ddlPartList[0]);

        if (Keys.includes(item)) {
          if (this.isBackgroudImage) {
            this.isBackgroudImage = false;
            var element = document.getElementById(item);
            if (element.className === 'sorting2') {
              element.classList.replace('sorting2', 'sorting2_asc');
            }
            else if (element.className === 'sorting2_asc') {
              element.classList.replace('sorting2_asc', 'sorting2_desc');
            }
            else {
              element.classList.replace('sorting2_desc', 'sorting2_asc');
            }
          }
          else {
            this.isBackgroudImage = true;
            var element = document.getElementById(item);
            if (element.className === 'sorting2') {
              element.classList.replace('sorting2', 'sorting2_asc');
            }
            else if (element.className === 'sorting2_asc') {
              element.classList.replace('sorting2_asc', 'sorting2_desc');
            }
            else {
              element.classList.replace('sorting2_desc', 'sorting2_asc');
            }
          }
        }

        if (item == 'name1') {
          if (this.isBackgroudImage) {
            this.isBackgroudImage = false;
            var element = document.getElementById(item);
            if (element.className === 'sorting2') {
              element.classList.replace('sorting2', 'sorting2_asc');
            }
            else if (element.className === 'sorting2_asc') {
              element.classList.replace('sorting2_asc', 'sorting2_desc');
            }
            else {
              element.classList.replace('sorting2_desc', 'sorting2_asc');
            }
          }
          else {
            this.isBackgroudImage = true;
            var element = document.getElementById(item);
            if (element.className === 'sorting2') {
              element.classList.replace('sorting2', 'sorting2_asc');
            }
            else if (element.className === 'sorting2_asc') {
              element.classList.replace('sorting2_asc', 'sorting2_desc');
            }
            else {
              element.classList.replace('sorting2_desc', 'sorting2_asc');
            }
          }
        }

        const result = Keys.filter(key => key != item);

        if (result) {
          result.forEach(data => {
            var element = document.getElementById(data);
            if (element != null) {
              if (element.className === 'sorting2') {
                element.classList.replace('sorting2', 'sorting2');
              }
              else if (element.className === 'sorting2_asc') {
                element.classList.replace('sorting2_asc', 'sorting2');
              }
              else {
                element.classList.replace('sorting2_desc', 'sorting2');
              }
            }
          });
        }
      }
    }
    else {
      if (this.productDetails != null) {
        var ret = new Array;
        for (var i = this.productDetails.length - 1; i >= 0; i--) {
          ret.push(this.productDetails[i]);
        }

        this.productDetails = ret;
        var Keys = Object.keys(this.productDetails[0]);

        if (Keys.includes(item)) {
          if (this.isBackgroudImage) {
            this.isBackgroudImage = false;
            var element = document.getElementById(item);
            if (element.className === 'sorting1') {
              element.classList.replace('sorting1', 'sorting_asc');
            }
            else if (element.className === 'sorting_asc') {
              element.classList.replace('sorting_asc', 'sorting_desc');
            }
            else {
              element.classList.replace('sorting_desc', 'sorting_asc');
            }
          }
          else {
            this.isBackgroudImage = true;
            var element = document.getElementById(item);
            if (element.className === 'sorting1') {
              element.classList.replace('sorting1', 'sorting_asc');
            }
            else if (element.className === 'sorting_asc') {
              element.classList.replace('sorting_asc', 'sorting_desc');
            }
            else {
              element.classList.replace('sorting_desc', 'sorting_asc');
            }
          }
        }

        const result = Keys.filter(key => key != item);

        if (result) {
          result.forEach(data => {
            var element = document.getElementById(data);
            if (element != null) {
              if (element.className === 'sorting1') {
                element.classList.replace('sorting1', 'sorting1');
              }
              else if (element.className === 'sorting_asc') {
                element.classList.replace('sorting_asc', 'sorting1');
              }
              else {
                element.classList.replace('sorting_desc', 'sorting1');
              }
            }
          });
        }
      }
    }

  }

  getAllProducts(companyId, product) {
    this.loader = true;
    $('#datatable').DataTable().destroy();
    this.dtTrigger1.next('');
    this.dtOptions1 = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      ordering: false,
      //processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        // dataTablesParameters.minNumber = dataTablesParameters.start + 1;
        // dataTablesParameters.maxNumber = dataTablesParameters.start + dataTablesParameters.length;
        this.sharedService.getAllProducts(companyId, product ? product : dataTablesParameters.search.value, dataTablesParameters.start, dataTablesParameters.length)
          .subscribe(resp => {
            this.loader = false;
            let totalRows = null;
            this.data = resp;
            if (this.data.dataList.length > 0) {
              this.productDetails = this.data.dataList;
              totalRows = [...new Set(this.productDetails.map(item => item.totalRows))];
            }
            else {
              this.productDetails = null;
              totalRows = 0;
            }

            callback({
              recordsTotal: totalRows,
              recordsFiltered: totalRows,
              data: []
            });
          });
      }
    };
    this.rerender('one');
  }

  onValueChange(value: any): void {
    this.value = value;
    this.companyLoginId = value;
    this.getAllCatagory(value);
    this.getAllCustomer(value);
    this.GetvendorpartModal(value);
    this.getManufacturerList(this.value);
  }

  SetSettingCSS(settingCSS, logoImagePath) {
    if (settingCSS != null) {
      let headerCss = document.getElementsByClassName(this.constants.CssHealerClass) as HTMLCollectionOf<HTMLElement>;
      if (headerCss.length != 0) {
        headerCss[0].style.backgroundColor = settingCSS.split(',')[0];
      }
      let saveButtonCss = document.getElementsByClassName(this.constants.CssSaveBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (saveButtonCss.length != 0) {
        saveButtonCss[0].style.backgroundColor = settingCSS.split(',')[1];
        $(".blue-bg").css("background", settingCSS.split(',')[1]);
        $(".green-bg").css("background", settingCSS.split(',')[1]);

      }
      let cencelButtonCss = document.getElementsByClassName(this.constants.CssCancelBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (cencelButtonCss.length != 0) {
        cencelButtonCss[0].style.backgroundColor = settingCSS.split(',')[2];
        $(".darkred-bg").css("background", settingCSS.split(',')[2]);
      }
    }
    if (logoImagePath != null && logoImagePath != "") {
      (document.getElementById('hrdlogoImage') as HTMLImageElement).src = logoImagePath;
    }
  }

  getAllPartsByCategoryId(SelectedCatagoryId) {
    this.partService.getAllPartByCategoryId(SelectedCatagoryId, this.companyLoginId).subscribe(
      data => {
        this.data = data;
        if (this.data.dataList != null && this.data.dataList != undefined) {
          this.partList = this.data.dataList;
          if (this.type != 'editPart') {
            this.openPartListByCategory();
          }
        }
      });
  }

  getAllCatagory(compaanyId) {
    this.sharedService.getAllCatogary(compaanyId, '').subscribe(
      data => {
        this.data = data;
        this.catagoryData = this.data.dataList;
        if (compaanyId > 0) {
          this.catagoryDataList = this.data.dataList.filter(x => x.id != this.constants.MisceType);
        }
        else {
          this.SelectedCatagoryName = '';
        }

      });
  }


  catagoryInputValue() {
    this.SelectedCatagoryName;
  }

  removeCategory(item, index) {

    let abc = {
      id: Number(item.categoryId),
      name: item.categoryName
    }

    this.catagoryDataList = this.catagoryDataList.sort((n1, n2) => {
      if (n1.id > n2.id) {
        return 1;
      }
      if (n1.id < n2.id) {
        return -1;
      }
      return 0;
    });
    this.selectTedCategoryArray.splice(index, 1);
    this.ProductPartModels = this.ProductPartModels.filter(x => x.PartCategoryId != item.categoryId);
    this.SelectedCatagoryName = '';
  }

  updatePart(item, categoryId, categoryindex) {
    this.selectTedCategoryArray[categoryindex].partList.splice(this.partIndex, 1);
    for (var i = this.ProductPartModels.length - 1; i >= 0; i--) {
      if (this.ProductPartModels[i].PartCategoryId == categoryId && this.ProductPartModels[i].PartId == this.getPartId) {
        this.ProductPartModels.splice(i, 1);
      }
    }
  }

  removePart(categoryIndex, index, partId, categoryId) {
    let abc = {
      id: Number(partId),
      name: this.selectTedCategoryArray[categoryIndex].partList[index].partName
    }
    this.partList.push(abc);
    this.categoryPartValidation = false;
    this.selectTedCategoryArray[categoryIndex].partList.splice(index, 1);
    this.categoryPartDetails.splice(index, 1);
    this.selectTedPartArray.splice(index, 1);
    this.SelectedCatagoryName = '';
    for (var i = this.ProductPartModels.length - 1; i >= 0; i--) {
      if (this.ProductPartModels[i].PartCategoryId == categoryId && this.ProductPartModels[i].PartId == partId) {
        this.ProductPartModels.splice(i, 1);
      }
    }
  }

  clearPartDetails() {
    this.selectedPartValue = '';
    this.partObjectModel.cost = '';
    this.partObjectModel.hours = '';
    this.partObjectModel.qty = 1;
    this.partObjectModel.partQuestions = [];
    this.isActiveAddPart = true;
    this.ddlPartList = [];
    this.PartName_search = '';
    this.selectedVendorValue = '';
    this.validationsearch = false;
    //this.ddlPartList = [];
  }

  clearProductDetails() {
    this.recordAlreadyExist = false;
    this.isDiscountPercentage = false;
    this.isDiscountDoller = false;
    this.PartExtensionTotal = null;
    this.HoursExtensionTotal = 0;
    this.LaborExtesionTotal = null;
    this.GrandTotalAmount = 0;
    this.isDiscountPercentage = false;
    this.DiscountedAmount = 0
    this.discountRate = 0;
    this.PartCheckBox = false;
    this.LaborCheckBox = false;
    this.discount = 0;
    this.partExtSubTotalWithDiscount = 0;
    this.laborExtSubTotalWithDiscount = 0;
    this.TaxTotalAmount = 0;
    this.SubTotalWithDisnt = 0;
    this.discountamount = '';
    this.SaleTaxAmount = '';
    this.TaxType == "1"
    this.finalAmount = 0;
    this.GrandTotalAmount = 0;
    this.grandTotal = 0;
    this.categoryPartValidation = false;
    this.catagoryDataList = this.catagoryData;
    this.partCategoriesList = [];
    this.partWorkflowList = [];
    this.dropdownView = 1;
    this.PartCheckBox = false;
    this.discountamount = '';
    this.isPartUpdate = true;
    this.validation = false;
    this.productName = '';
    this.IsTaxPart = false;
    this.IsTaxLabor = false;
    this.productDescription = '';
    this.subSection = 0;
    this.isVehicleRequired = false;
    this.vehicleRequired = false;
    this.selectedManufacturerValue = '';
    this.selectedManufacturerModelValue = '';
    this.Year = '';
    this.SelectedCatagoryName = '';
    if (Number(this.companyLoginId) > 0) {
      this.value = Number(this.companyLoginId);
    }
    else {
      this.value = 0;
    }

    this.selectTedCategoryArray = [];
    this.isActiveAdd = true;
    this.ProductPartModels = [];
    this.isPartval = true;

  }

  hidemodalpopup() {
    $("#myModal").hide();
  }

  addProduct() {
    this.clearbutton = true;
    this.validation = true;
    if (this.form.controls.productName.status === this.constants.Invalid ||
      this.value == 0
    ) {
      return;
    }
    if (this.isVehicleRequired == true) {
      if (this.form.controls.manufacturerId.status === this.constants.Invalid ||
        this.form.controls.manufacturerModelId.status === this.constants.Invalid ||
        this.form.controls.Year.status === this.constants.Invalid
      ) {
        return;
      }
    }
    if (this.partCategoriesList.length == 0) {
      $("#myModal").show();
      return;
    }

    if (this.partCategoriesList.length != 0) {
      for (let i = 0; i < this.partCategoriesList.length; i++) {
        if (this.partCategoriesList[i].categoryId.length != 0) {
          if (this.partCategoriesList[i].partList == undefined) {
            this.categoryPartValidation = true;
            $("#myModal").show();
            return;
          }
        }
      }
    }

    this.recordAlreadyExist = false;
    let obj = {};
    obj['Product'] = {
      'Name': this.productName,
      'Description': this.productDescription,
      'IsActive': true,
      'CreatedDate': this.now = new Date(),
      'ModifiedDate': this.now = new Date(),
      'CreatedBy': this.userId,
      'ModifiedBy': this.userId,
      'CompanyId': this.value,
      'LaborCost': Number(this.laborHrsCost),
      'discountRate': parseFloat(this.discountamount),
      'discountAmout': parseFloat(this.discount),
      'grandTotal': this.grandTotal,
      'finalAmount': this.finalAmount,
      'IsDiscountPart': this.PartCheckBox == undefined ? false : this.PartCheckBox,
      'IsDiscountLabor': this.LaborCheckBox == undefined ? false : this.LaborCheckBox,
      'PartExtSubTotalWithDiscount': this.partExtSubTotalWithDiscount,
      'LaborExtSubTotalWithDiscount': this.laborExtSubTotalWithDiscount,
      'SubTotalWithDisnt': this.SubTotalWithDisnt,
      'TaxType': parseInt(this.TaxType),
      'TaxName': "SaleTaxName",
      'ResellerNo': this.ResellerNo,
      'IsTaxPart': this.IsTaxPart == undefined ? false : this.IsTaxPart,
      'IsTaxLabor': this.IsTaxLabor == undefined ? false : this.IsTaxLabor,
      'TaxRateAmount': this.SaleTaxAmount == undefined ? 0 : parseFloat(this.SaleTaxAmount),
      'LaborExtensionTotal': this.LaborExtesionTotal,
      'LaborHourTotal': Number(this.HoursExtensionTotal),
      'PartExtenionTotal': this.PartExtensionTotal,
      'TaxTotalAmount': this.TaxTotalAmount,
      'isDiscountPercentage': this.discountRadioBox == "Percentage" ? true : false,
      'isDiscountDoller': this.discountRadioBox == "Doller" ? true : false,
      'isVehicleRequired': this.isVehicleRequired,
      'manufacturerId': this.isVehicleRequired == false ? 0 : Number(this.selectedManufacturerValue),
      'modelId': this.isVehicleRequired == false ? 0 : Number(this.selectedManufacturerModelValue),
      'modelYear': this.isVehicleRequired == false ? 0 : Number(this.Year),
    }

    let dataCategory = this.partCategoriesList
    for (var i = 0; i < dataCategory.length; i++) {
      let catId = Number(dataCategory[i].categoryId);
      let dataPart = dataCategory[i].partList;
      if (dataPart != undefined) {
        for (var j = 0; j < dataPart.length; j++) {
          this.orderTaskListArray = [];
          this.orderPartListArray = [];
          let partData = dataPart[j].questionAnsList;

          let tasklistData = dataPart[j].taskList ? dataPart[j].taskList.filter(x => x.partId == dataPart[j].partId) : '';

          if (partData != undefined) {

            if (tasklistData) {
              for (var t = 0; t < tasklistData.length; t++) {
                let partTaskObj = {};
                partTaskObj["Id"] = Number(tasklistData[t].id);
                partTaskObj["PartId"] = Number(tasklistData[t].partId);
                partTaskObj["TaskModuleId"] = Number(tasklistData[t].task);
                partTaskObj["TaskName"] = tasklistData[t].taskName;
                partTaskObj["TaskHour"] = tasklistData[t].taskHour;
                partTaskObj["TaskDescription"] = tasklistData[t].taskDescription;
                partTaskObj["CompanyId"] = Number(this.value);
                partTaskObj["WorkflowId"] = Number(tasklistData[t].workflowId);
                partTaskObj["IsActive"] = tasklistData[t].isActive;
                partTaskObj["CreatedBy"] = this.userId;
                partTaskObj["CreatedDate"] = this.now = new Date();
                partTaskObj["Ordering"] = t;
                this.orderTaskListArray.push(partTaskObj);
              }
            }

            for (var z = 0; z < partData.length; z++) {
              this.orderPartListArray = [];
              let partQuestObj = {};
              partQuestObj["QuestionId"] = partData[z].questionId == 0 ? null : partData[z].questionId;
              partQuestObj["Answer"] = partData[z].answerName;
              partQuestObj["IsActive"] = true;
              partQuestObj["CreatedBy"] = this.userId;
              partQuestObj["CreatedDate"] = this.now = new Date();
              this.orderPartListArray.push(partQuestObj);
              let partObj = {};
              partObj["Id"] = this.productId;
              partObj["ProductId"] = catId;
              partObj["PartId"] = parseInt(dataPart[j].partId);
              partObj["CompanyId"] = this.value;
              partObj["PartCategoryId"] = catId;
              partObj["QuestionAnswerId"] = 0;
              partObj["Quantity"] = parseInt(dataPart[j].quantity);
              partObj["Cost"] = parseFloat(dataPart[j].cost);
              partObj["partName"] = dataPart[j].partName;
              partObj["Hours"] = (dataPart[j].hours).toString();
              partObj["IsCustomProduct"] = true;
              partObj["IsActive"] = true;
              partObj["CreatedDate"] = this.now = new Date();
              partObj["CreatedBy"] = this.userId;
              partObj["ModifiedDate"] = this.now = new Date();
              partObj["ModifiedBy"] = this.userId;
              partObj["QuestionAnswer"] = this.orderPartListArray[0];
              partObj["ProductPartTasks"] = this.orderTaskListArray;
              partObj["Indexing"] = i;
              partObj["Ordering"] = j;
              partObj["WorkflowId"] = parseInt(dataPart[j].workflowId);
              partObj["Notes"] = dataPart[j].notes;
              this.PartsDetails.push(partObj);
            }
          }
        }
      }
    }

    obj['ProductPartModels'] = this.PartsDetails;
    this.loader = true;
    this.sharedService.addProduct(obj).subscribe(
      data => {
        switch (data.statusCode) {
          case 200:
            $("#add_success").show();
            this.PartsDetails = [];
            this.isSaveOrUpdate = true;
            this.TCreateUpdate = 1;
            this.SearchControl = false;
            this.ListShow = false;
            this.ListDiv = false;
            this.clearProductDetails();
            break;
          case 400:
            if (data.errorMessage === 'Record Already Exist') {
              this.recordAlreadyExist = true;
            } else {
              $("#wrong_msg").show();

            }
            break;
          case 500:
            $("#wrong_msg").show();

            break;
          default:
            $("#wrong_msg").show();

        }
        (error) => {
          $("#wrong_msg").show();
        }
        this.loader = false;

      });
  }

  editProduct() {
    this.validation = true;
    this.clearbutton = true;
    if (this.form.controls.productName.status === this.constants.Invalid ||
      this.value == 0
    ) {
      return;
    }
    if (this.isVehicleRequired == true) {
      if (this.form.controls.manufacturerId.status === this.constants.Invalid ||
        this.form.controls.manufacturerModelId.status === this.constants.Invalid ||
        this.form.controls.Year.status === this.constants.Invalid
      ) {
        return;
      }
    }
    if (this.partCategoriesList.length != 0) {
      for (let i = 0; i < this.partCategoriesList.length; i++) {
        if (this.partCategoriesList[i].categoryId.length != 0) {
          if (this.partCategoriesList[i].partList == undefined) {
            this.categoryPartValidation = true;
            $("#myModal").show();
            return;
          }
        }
      }
    }
    this.categoryPartValidation = false;
    let obj = {}
    obj['Product'] = {
      'CompanyId': this.value,
      'Id': this.productId,
      'Name': this.productName,
      'Description': this.productDescription,
      'IsActive': true,
      'CreatedDate': this.now = new Date(),
      'ModifiedDate': this.now = new Date(),
      'CreatedBy': this.userId,
      'ModifiedBy': this.userId,
      'LaborCost': Number(this.laborHrsCost),
      'discountRate': parseFloat(this.discountamount),
      'discountAmout': parseFloat(this.discount),
      'grandTotal': this.grandTotal,
      'finalAmount': this.finalAmount,
      'IsDiscountPart': this.PartCheckBox == undefined ? false : this.PartCheckBox,
      'IsDiscountLabor': this.LaborCheckBox == undefined ? false : this.LaborCheckBox,
      'PartExtSubTotalWithDiscount': this.partExtSubTotalWithDiscount,
      'LaborExtSubTotalWithDiscount': this.laborExtSubTotalWithDiscount,
      'SubTotalWithDisnt': this.SubTotalWithDisnt,
      'TaxType': parseInt(this.TaxType),
      'TaxName': "SaleTaxName",
      'ResellerNo': this.ResellerNo,
      'IsTaxPart': this.IsTaxPart == undefined ? false : this.IsTaxPart,
      'IsTaxLabor': this.IsTaxLabor == undefined ? false : this.IsTaxLabor,
      'TaxRateAmount': this.SaleTaxAmount == undefined ? 0 : parseFloat(this.SaleTaxAmount),
      'LaborExtensionTotal': this.LaborExtesionTotal,
      'LaborHourTotal': Number(this.HoursExtensionTotal),
      'PartExtenionTotal': this.PartExtensionTotal,
      'TaxTotalAmount': this.TaxTotalAmount,
      'isDiscountPercentage': this.discountRadioBox == "Percentage" ? true : false,
      'isDiscountDoller': this.discountRadioBox == "Doller" ? true : false,
      'isVehicleRequired': this.isVehicleRequired,
      'manufacturerId': this.isVehicleRequired == false ? 0 : Number(this.selectedManufacturerValue),
      'modelId': this.isVehicleRequired == false ? 0 : Number(this.selectedManufacturerModelValue),
      'modelYear': this.isVehicleRequired == false ? 0 : Number(this.Year),
      'copyId' : this.copyId
    }
    let dataCategory = this.partCategoriesList
    for (var i = 0; i < dataCategory.length; i++) {
      let catId = Number(dataCategory[i].categoryId);
      let dataPart = dataCategory[i].partList;
      if (dataPart != undefined) {
        for (var j = 0; j < dataPart.length; j++) {

          this.orderTaskListArray = [];
          this.orderPartListArray = [];

          let partData = dataPart[j].questionAnsList;
          let tasklistData = dataPart[j].taskList ? dataPart[j].taskList.filter(x => x.partId == dataPart[j].partId) : '';

          if (partData != undefined) {
            if (tasklistData) {
              for (var t = 0; t < tasklistData.length; t++) {
                let partTaskObj = {};
                partTaskObj["Id"] = Number(tasklistData[t].id);
                partTaskObj["PartId"] = Number(tasklistData[t].partId);
                partTaskObj["TaskModuleId"] = Number(tasklistData[t].task);
                partTaskObj["TaskName"] = tasklistData[t].taskName;
                partTaskObj["TaskHour"] = tasklistData[t].taskHour;
                partTaskObj["TaskDescription"] = tasklistData[t].taskDescription;
                partTaskObj["CompanyId"] = Number(this.value);
                partTaskObj["WorkflowId"] = Number(tasklistData[t].workflowId);
                partTaskObj["IsActive"] = tasklistData[t].isActive;
                partTaskObj["CreatedBy"] = this.userId;
                partTaskObj["CreatedDate"] = this.now = new Date();
                partTaskObj["Ordering"] = t;
                this.orderTaskListArray.push(partTaskObj);
              }
            }

            for (var z = 0; z < partData.length; z++) {
              this.orderPartListArray = [];
              let partQuestObj = {};
              let productPartId = 0;
              partQuestObj["QuestionId"] = partData[z].questionId == 0 ? null : partData[z].questionId;
              partQuestObj["Answer"] = partData[z].answerName;
              partQuestObj["IsActive"] = true;
              partQuestObj["CreatedBy"] = this.userId;
              partQuestObj["CreatedDate"] = this.now = new Date();
              productPartId = partData[z].productPartId;
              this.orderPartListArray.push(partQuestObj);
              let partObj = {};
              partObj["Id"] = productPartId;
              partObj["ProductId"] = this.productId;
              partObj["PartId"] = parseInt(dataPart[j].partId);
              partObj["CompanyId"] = this.value;
              partObj["PartCategoryId"] = catId;
              partObj["QuestionAnswerId"] = partData[z].answerId;
              partObj["Quantity"] = parseInt(dataPart[j].quantity);
              partObj["Cost"] = parseFloat(dataPart[j].cost);
              partObj["partName"] = dataPart[j].partName;
              partObj["Hours"] = (dataPart[j].hours).toString();
              partObj["IsCustomProduct"] = true;
              partObj["IsActive"] = true;
              partObj["CreatedBy"] = this.userId;
              partObj["CreatedDate"] = this.now = new Date();
              partObj["ModifiedBy"] = this.userId;
              partObj["ModifiedDate"] = this.now = new Date();
              partObj["QuestionAnswer"] = this.orderPartListArray[0];
              partObj["ProductPartTasks"] = this.orderTaskListArray;
              partObj["Indexing"] = dataCategory[i].indexing;
              partObj["Ordering"] = dataPart[j].ordering;
              partObj["WorkflowId"] = parseInt(dataPart[j].workflowId);
              partObj["Notes"] = dataPart[j].notes;
              this.PartsDetails.push(partObj);
            }
          }
        }
      }
    }
    obj['ProductPartModels'] = this.PartsDetails;
    this.loader = true;
    this.sharedService.editProduct(obj).subscribe(
      data => {
        switch (data.statusCode) {
          case 200:
            if(data.data == "1"){
              $("#add_update").show();
              this.PartsDetails = [];
              this.isSaveOrUpdate = true;
              this.clearProductDetails();
              this.TCreateUpdate = 1;
              this.SearchControl = false;
              this.ListShow = false;
              this.ListDiv = false;
              this.isActiveAdd = true;
              this.productId = 0;
            }
            else{
              this.recordAlreadyExist = true;
            }
          
            break;
          case 400:
            $("#wrong_msg").show();
            break;
          case 500:
            $("#wrong_msg").show();
            break;
          default:
            $("#wrong_msg").show();
        }
        this.loader = false;
      });
  }

  toggleEdit(id: any) {

    this.ListDiv = false;
    this.productId = id;
    this.isPartUpdate = false;
    this.clearbutton = false;
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.isActiveAdd = false;
    this.sharedService.GetProductByProductId(id).subscribe(
      data => {
        this.loader = false;
        this.data = data;
        switch (this.data.statusCode) {
          case 200:
            this.FilterData = this.data;
            this.isOrderConfirmType = "order";
            this.btnShowvalidation = true;
            this.btnUpdateOrderValidation = true;
            this.value = this.data.data.companyId;
            this.GetvendorpartModal(this.data.data.companyId);
            this.companyLoginId = this.data.data.companyId;
            this.getAllCatagory(this.companyLoginId);
            this.productDescription = this.data.data.description;
            this.productName = this.data.data.productName;
            this.discountRate = this.data.data.discountRate;
            this.DiscountedAmount = this.data.data.discountAmout;
            this.getManufacturerList(this.companyLoginId);
            this.vehicleRequired = this.data.data.isVehicleRequired;
            this.data.data.isVehicleRequired == true ? this.isVehicleRequired = true : this.isVehicleRequired = false
            this.selectedManufacturerValue = this.data.data.manufacturerId == 0 ? '' : this.data.data.manufacturerId;

            this.Year = this.data.data.modelYear;
            if (this.data.data.isDiscountDoller == true) {
              this.discountRadioBox = "Doller";
            }
            if (this.data.data.isDiscountPercentage == true) {
              this.discountRadioBox = "Percentage";
            }
            this.partCategoriesList = this.data.data.partCategories;
            if (this.data.data.isDiscountDoller) {
              $("INPUT[name=Discount]").val(['Doller']);
            }
            if (this.data.data.isDiscountPercentage) {
              $("INPUT[name=Discount]").val(['Percentage']);
            }
            this.isOrderApproved = this.data.data.isOrderApproved == null ? false : this.data.data.isOrderApproved;
            this.PartExtensionTotal = this.data.data.partExtenionTotal;
            this.HoursExtensionTotal = this.data.data.laborHourTotal;
            this.LaborExtesionTotal = this.data.data.laborExtensionTotal;
            this.GrandTotalAmount = this.data.data.grandTotal;
            this.isDiscountPercentage = this.data.data.isDiscountPercentage;
            this.DiscountedAmount = this.data.data.discountAmout
            this.discountRate = this.data.data.discountRate;
            this.PartCheckBox = this.data.data.isDiscountPart;
            this.LaborCheckBox = this.data.data.isDiscountLabor;
            this.discount = this.data.data.discountAmout;
            this.partExtSubTotalWithDiscount = this.data.data.partExtSubTotalWithDiscount;
            this.laborExtSubTotalWithDiscount = this.data.data.laborExtSubTotalWithDiscount;
            this.TaxTotalAmount = this.data.data.taxTotalAmount;
            this.SubTotalWithDisnt = this.data.data.subTotalWithDisnt;
            this.discountamount = this.data.data.discountRate == 0 ? '' : this.data.data.discountRate;
            this.SaleTaxAmount = this.data.data.taxRateAmount == 0 ? '' : this.data.data.taxRateAmount;
            this.TaxType = this.data.data.taxType;
            this.copyId = this.data.data.copyId;
            this.enableCheckBox = true;
            this.orderNumber = this.data.data.orderNumber;
            this.laborHrsCost = this.data.data.laborCost;
            if (this.TaxType == "2") {
              this.ResellerNo = this.data.data.resellerNo;
              this.TaxTotalAmount = this.data.data.taxTotalAmount;
              this.Resellertextbox = true;
              this.SaleTaxDiv = false;
            }
            else if (this.data.data.taxType == "3") {
              this.TaxTotalAmount = this.data.data.taxTotalAmount;
              this.Resellertextbox = false;
              this.SaleTaxDiv = false;
            }
            else if (this.data.data.taxType == "1") {
              this.TaxTotalAmount = this.data.data.taxTotalAmount;
              this.SaleTaxAmount = this.data.data.taxRateAmount == 0 ? '' : this.data.data.taxRateAmount;
              this.IsTaxPart = this.data.data.isTaxPart;
              this.IsTaxLabor = this.data.data.isTaxLabor;
              this.SaleTaxDiv = true;
              this.Resellertextbox = false;
            }
            this.finalAmount = this.data.data.finalAmount;
            this.GrandTotalAmount = this.data.data.grandTotal;
            this.grandTotal = this.data.data.grandTotal;
            if (this.data.data.partCategories != null && this.data.data.partCategories.length > 0) {
              this.data.data.partCategories.forEach(cateLst => {

                this.TotalExtension = 0;
                cateLst.indexing = cateLst.indexing;

                cateLst.partList.forEach(partLst => {
                  let getTaskList = [];
                  partLst.quantityOld = partLst.quantity;
                  partLst.costold = parseFloat(partLst.cost).toFixed(5);
                  partLst.hourold = partLst.hours;
                  this.TotalExtension += (partLst.cost * partLst.quantity) + (partLst.hours * this.laborHrsCost * partLst.quantity);
                  partLst.vendorName = partLst.vendorName;
                  partLst.ordering = partLst.ordering;
                  partLst.notes = partLst.notes;
                  partLst.isEdit = true;
                  partLst.isNotes = false;
                  partLst.productPartTasks.forEach(element => {
                    let obj = {
                      id: element.id,
                      partId: partLst.partId,
                      workflowId: element.workflowId,
                      task: element.taskModuleId,
                      taskName: element.taskName,
                      taskHour: element.taskHour,
                      taskDescription: element.taskDescription,
                      isActive: element.isActive,
                      hideClass: 'table-partname'
                    }
                    getTaskList.push(obj);
                    this.TaskListArray.push(obj);
                  });
                  if (getTaskList.length > 0) {
                    partLst.taskList = getTaskList;
                  }
                });
                cateLst.totalextensiondata = this.TotalExtension;
                this.subSection = cateLst.partList.length;
              });
            }
            this.CalculationSubtotal();
            this.CalculationSalesTax();
            if (this.data.data.manufacturerId != 0 && this.data.data.manufacturerId != undefined) {
              this.CallModelService(this.data.data.manufacturerId);
              this.selectedManufacturerModelValue = this.data.data.modelId == 0 ? '' : this.data.data.modelId;
            }

            break;
          case 400:
            $("#wrong_msg").show();
            break;
          default:
            $("#wrong_msg").show();
        }
      }
    )
    this.isPartUpdate = true;
  }

  getAllProductDetails() {
    this.ProductPartModels = [];
    if (this.productDetailsById != null && this.productDetailsById != undefined) {
      this.value = this.value == 0 ? this.productDetailsById.companyId : this.value;
      this.productId = this.productDetailsById.productId;
      this.productName = this.productDetailsById.productName;
      this.productDescription = this.productDetailsById.description;
      this.selectTedCategoryArray = this.productDetailsById.partCategories;
      if (this.selectTedCategoryArray != undefined && this.selectTedCategoryArray != null) {
        for (let k = 0; k < this.selectTedCategoryArray.length; k++) {
          var catagoryDataListitem = this.catagoryDataList.filter(x => x.id != this.selectTedCategoryArray[k].categoryId);
          if (this.selectTedCategoryArray[k].partList != 'undefined') {
            for (let j = 0; j < this.selectTedCategoryArray[k].partList.length; j++) {
              if (this.selectTedCategoryArray[k].partList[j].questionAnsList != 'undefined') {
                for (let i = 0; i < this.selectTedCategoryArray[k].partList[j].questionAnsList.length; i++) {
                  this.productPartId = this.selectTedCategoryArray[k].partList[j].questionAnsList[i].productPartId,
                    this.questionAnswersDetails = [];
                  var QuestionsAnsObj = {
                    QuestionId: this.selectTedCategoryArray[k].partList[j].questionAnsList[i].questionId,
                    Answer: this.selectTedCategoryArray[k].partList[j].questionAnsList[i].answerName,
                    CreatedBy: this.userId,
                    IsActive: true,
                    CreatedDate: this.now = new Date(),
                  }
                  this.questionAnswersDetails.push(QuestionsAnsObj);
                  let productPartsObjModel = {
                    Id: this.selectTedCategoryArray[k].partList[j].questionAnsList[i].productPartId,
                    ProductId: this.productDetailsById.productId,
                    PartId: this.selectTedCategoryArray[k].partList[j].partId,
                    CompanyId: this.value,
                    PartCategoryId: this.selectTedCategoryArray[k].categoryId,
                    QuestionAnswer: QuestionsAnsObj,
                    QuestionAnswerId: this.selectTedCategoryArray[k].partList[j].questionAnsList[i].answerId,
                    Quantity: this.selectTedCategoryArray[k].partList[j].quantity,
                    IsCustomProduct: true,
                    IsActive: true,
                    CreatedBy: this.userId,
                    CreatedDate: this.now = new Date(),
                    ModifiedDate: this.now = new Date(),
                    ModifiedBy: this.userId,
                  }
                  this.ProductPartModels.push(productPartsObjModel);
                }
              }
            }
          }
          this.catagoryDataList = catagoryDataListitem;
        }

      }
    }
  }

  toggleEditPartDetails(item: any, data, index, partIndex) {
    this.partIndex = partIndex;
    this.selectedPartValue = item.partId;
    this.isActiveAddPart = false;
    this.getPartId = item.partId;
    this.openPart(data, index, 'editPart');
    this.selectedPartValue = item.partId;
  }

  openPart(item, categoryIndex, type) {
    this.type = type;
    if (item.categoryId) {
      this.SelectedCatagoryId = item.categoryId;
      this.getAllPartsByCategoryId(this.SelectedCatagoryId);
    }
    this.categoryIndex = categoryIndex;
  }

  OpenAddTaskModal(partId, workflowIdAddTask, workflowName, partName) {
    this.partObjectModel.TaskName_search = '';
    this.partIdAddTask = partId;
    this.workflowIdAddTask = workflowIdAddTask;
    this.addTaskPopupWorkflowName = workflowName;
    this.addTaskPopupPartName = partName;

    this.loader = true;
    $('#datatable2').DataTable().destroy();
    this.dtTrigger3.next('');
    this.dtOptions3 = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      // processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        let search2 = '';
        if (this.partObjectModel.TaskName_search && !dataTablesParameters.search.value) {
          search2 = this.partObjectModel.TaskName_search;
        }
        else if (this.partObjectModel.TaskName_search && dataTablesParameters.search.value) {
          search2 = dataTablesParameters.search.value;
        }
        else {
          search2 = dataTablesParameters.search.value;
        }
        this.sharedService.getAllTaskByWorkflow(this.value, workflowIdAddTask, search2, dataTablesParameters.start, dataTablesParameters.length)
          .subscribe(resp => {
            this.loader = false;
            let totalRows = null;
            this.data = resp;
            if (this.data.dataList.length > 0) {
              this.TaskLists = this.data.dataList;
              totalRows = [...new Set(this.TaskLists.map(item => item.totalRows))];
            }
            else {
              this.TaskLists = null;
              totalRows = 0;
            }

            callback({
              recordsTotal: totalRows,
              recordsFiltered: totalRows,
              data: []
            });
          });

      }
    };
    this.rerender('three');
    $('#addpartInWorkflow').show();
  }

  SearchByTaskName() {
    this.OpenAddTaskModal(this.partIdAddTask, this.workflowIdAddTask, this.addTaskPopupWorkflowName, this.addTaskPopupPartName);
  }

  fillTaskDetails(data, action) {
    if (action == 'add') {
      this.addTaskButton = 'Add Task';
      this.partObjectModel.task = data.id;
      this.partObjectModel.taskName = data.taskName;
      this.partObjectModel.taskHour = data.taskHours;
      this.partObjectModel.taskDescription = data.notes;
    }
    else if (action == 'edit') {
      this.addTaskButton = 'Update Task';
      this.partIdAddTask = data.partId;
      this.workflowIdAddTask = data.workflowId;
      this.partObjectModel.task = data.task;
      this.partObjectModel.taskName = data.taskName;
      this.partObjectModel.taskHour = data.taskHour;
      this.partObjectModel.taskDescription = data.taskDescription;
    }

    $('#addpartInWorkflowForm').show();
  }

  AddPartTask() {

    let addorupdate = '';

    let obj = {
      id: 0,
      partId: this.partIdAddTask,
      workflowId: this.workflowIdAddTask,
      task: this.partObjectModel.task,
      taskName: this.partObjectModel.taskName,
      taskHour: this.partObjectModel.taskHour,
      taskDescription: this.partObjectModel.taskDescription,
      isActive: true,
      hideClass: ''
    }

    if (this.TaskListArray.length > 0) {
      const chkExist = this.TaskListArray.filter(x => x.partId == this.partIdAddTask && x.workflowId == this.workflowIdAddTask && x.task == this.partObjectModel.task);
      if (chkExist.length > 0) {
        if (this.addTaskButton == 'Add Task') {
          this.taskMessage = "Task " + this.partObjectModel.taskName + " already exist.";
          $('#addpartInWorkflowForm').hide();
          $('#taskMessage').show();
          return;
        }
        else {
          chkExist[0].taskHour = obj.taskHour;
          chkExist[0].taskDescription = obj.taskDescription;
          addorupdate = 'update';
        }
      }
      else {
        this.TaskListArray.push(obj);
        addorupdate = 'add';
      }
    }
    else {
      this.TaskListArray.push(obj);
      addorupdate = 'add';
    }

    if (this.partCategoriesList != null && this.partCategoriesList.length > 0) {
      this.partCategoriesList.forEach(cateLst => {
        cateLst.partList.forEach(partLst => {
          if (partLst.partId == this.partIdAddTask && partLst.workflowId == this.workflowIdAddTask) {
            partLst.taskList = this.TaskListArray.filter(x => x.partId == partLst.partId && x.workflowId == partLst.workflowId);
          }
        });
      });
    }

    if (addorupdate == 'add') {
      this.taskMessage = "Task " + this.partObjectModel.taskName + " added.";
    }
    else if (addorupdate == 'update') {
      this.taskMessage = "Task " + this.partObjectModel.taskName + " updated.";
    }
    $('#addpartInWorkflowForm').hide();
    $('#taskMessage').show();
  }

  deleteTask(taskIndex, partId) {
    this.partCategoriesList.forEach(cateLst => {
      const partData = cateLst.partList.filter(x => x.partId == partId);
      if (partData.length > 0) {
        const virtualTask = partData[0].taskList[taskIndex];
        if (virtualTask) {
          const DataTaskListArray = this.TaskListArray.filter(x => x.task == partData[0].taskList[taskIndex].task && x.partId == partData[0].taskList[taskIndex].partId && x.workflowId == partData[0].taskList[taskIndex].workflowId);
          if (DataTaskListArray.length > 0) {
            let index = this.TaskListArray.findIndex(d => d.id === DataTaskListArray[0].id);
            this.TaskListArray.splice(index, 1);
          }
          this.taskMessage = "Task " + partData[0].taskList[taskIndex].taskName + " deleted.";
          partData[0].taskList.splice(taskIndex, 1);
          $('#taskMessage').show();
        }
      }
    });
  }

  CloseAddTask() {
    $('#addpartInWorkflow').hide();
  }

  CloseAddTaskForm() {
    this.partObjectModel.task = 0;
    this.partObjectModel.taskName = "";
    this.partObjectModel.taskHour = "";
    this.partObjectModel.taskDescription = "";
    $('#addpartInWorkflowForm').hide();
  }

  openPartListByCategory() {
    if (this.partList.length > 0 && this.selectTedCategoryArray[this.categoryIndex].partList.length != 0) {
      for (let i = 0; i < this.selectTedCategoryArray[this.categoryIndex].partList.length; i++) {
        this.partList = this.partList.filter(x => x.id != this.selectTedCategoryArray[this.categoryIndex].partList[i].partId)
      }
    }
  }

  deletePopup(item, content) {
    this.deletedItem = '';
    this.modalService.open(content, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
    this.deleteDetail = 'Do you want to delete ' + item.name + '?';
    this.deletedItem = item.id;
  }

  deleteProduct(id) {
    this.sharedService.deleteProduct(id).subscribe(
      data => {
        switch (data.statusCode) {
          case 200:
            $("#delete_success").show();
            this.getAllProducts(this.value_search, this.ProductSearch == "" ? "" : this.ProductSearch);
            break;
          case 400:
            $("#wrong_msg").show();

            break;
          case 500:
            $("#wrong_msg").show();

            break;
          default:
            $("#wrong_msg").show();

        }
      });
  }

  closeModal() {
    this.validationPart = false;
    this.clearPartDetails();
    this.closeBtn.nativeElement.click();

  }

  ngAfterViewInit(): void {
    this.dtTrigger1.next('');
    this.dtTrigger2.next('');
    this.dtTrigger3.next('');
    this.settingDetailCSS = localStorage.getItem("settingDetailCSS");
    this.settingLogoImage = localStorage.getItem("settingImageLogo");
    this.SetSettingCSS(this.settingDetailCSS, this.settingLogoImage);
  }

  CalculationSubtotal() {


    var placeType = "Product";
    var dolloramount = this.discountamount == "" ? 0 : this.discountamount;
    this.discount = dolloramount;
    if (this.PartCheckBox && this.LaborCheckBox == undefined || this.LaborCheckBox == false) {
      if (this.discountRadioBox == "Percentage") {
        this.DiscountedAmount = (this.PartExtensionTotal * dolloramount) / 100;
        this.discount = this.DiscountedAmount;
        this.partExtSubTotalWithDiscount = this.PartExtensionTotal - this.DiscountedAmount;
        this.laborExtSubTotalWithDiscount = this.LaborExtesionTotal;
        this.SubTotalWithDisnt = this.GrandTotalAmount - this.DiscountedAmount;
        this.finalAmount = this.SubTotalWithDisnt;
      }
      if (this.discountRadioBox == "Doller") {
        this.partExtSubTotalWithDiscount = this.PartExtensionTotal - this.discount;
        this.laborExtSubTotalWithDiscount = this.LaborExtesionTotal;
        this.SubTotalWithDisnt = this.GrandTotalAmount - this.discount;
        this.finalAmount = this.SubTotalWithDisnt;
      }
      this.CalculationSalesTax();
    }
    if (this.PartCheckBox && this.LaborCheckBox) {

      if (this.discountRadioBox == "Percentage") {
        this.DiscountedAmount = (this.grandTotal * dolloramount) / 100;
        this.discount = this.DiscountedAmount;
        var divby2 = this.DiscountedAmount / 2;
        var roundoff = parseFloat("" + divby2).toFixed(2);
        this.partExtSubTotalWithDiscount = this.PartExtensionTotal - parseFloat(roundoff);
        this.laborExtSubTotalWithDiscount = this.LaborExtesionTotal - (this.DiscountedAmount - parseFloat(roundoff));
        this.SubTotalWithDisnt = this.GrandTotalAmount - this.discount;
        this.finalAmount = this.SubTotalWithDisnt;
      }
      if (this.discountRadioBox == "Doller") {

        var divby2 = this.discount / 2;
        var roundoff = parseFloat("" + divby2).toFixed(2);
        this.partExtSubTotalWithDiscount = this.PartExtensionTotal - parseFloat(roundoff);
        this.laborExtSubTotalWithDiscount = this.LaborExtesionTotal - (this.discount - parseFloat(roundoff));
        this.SubTotalWithDisnt = this.GrandTotalAmount - this.discount;
        this.finalAmount = this.SubTotalWithDisnt;
      }
      this.CalculationSalesTax();
    }
    if ((this.PartCheckBox == false || this.PartCheckBox == undefined)) {
      this.discount = 0;
      this.partExtSubTotalWithDiscount = this.PartExtensionTotal;
      this.laborExtSubTotalWithDiscount = this.LaborExtesionTotal;
      this.SubTotalWithDisnt = this.GrandTotalAmount;
      this.finalAmount = this.SubTotalWithDisnt;
      this.LaborCheckBox = false;
    }
    else {
    }

  }


  CalculationSalesTax() {
    var salestaxamount = this.SaleTaxAmount;
    this.TaxType;
    if (this.IsTaxPart && this.IsTaxLabor == undefined || this.IsTaxLabor == false) {

      if (this.TaxType == "2") {
        this.TaxTotalAmount = 0;
        this.finalAmount = this.SubTotalWithDisnt;
      }

      else if (this.TaxType == "3") {
        this.TaxTotalAmount = 0;
        this.finalAmount = this.SubTotalWithDisnt + this.TaxTotalAmount;
      }

      else {
        this.TaxTotalAmount = this.partExtSubTotalWithDiscount * salestaxamount / 100;
        this.finalAmount = this.SubTotalWithDisnt + this.TaxTotalAmount;
      }
    }
    if (this.IsTaxPart && this.IsTaxLabor) {
      if (this.TaxType == "2") {
        this.TaxTotalAmount = 0;
        this.finalAmount = this.SubTotalWithDisnt + this.TaxTotalAmount;
      }

      else if (this.TaxType == "3") {
        this.TaxTotalAmount = 0;
        this.finalAmount = this.SubTotalWithDisnt + this.TaxTotalAmount;
      }

      else {
        this.TaxTotalAmount = this.SubTotalWithDisnt * salestaxamount / 100;
        this.finalAmount = this.SubTotalWithDisnt + this.TaxTotalAmount;
      }
    }
    if ((this.IsTaxPart == false || this.IsTaxPart == undefined)) {
      this.IsTaxLabor = false;
      this.TaxTotalAmount = 0;
      this.finalAmount = this.SubTotalWithDisnt + this.TaxTotalAmount;
    }

  }
  SaleTaxType() {
    var taxtype = this.TaxType;
    if (taxtype == "2") {
      this.Resellertextbox = true;
      this.SaleTaxDiv = false;
      this.IsTaxPart = false;
      this.IsTaxLabor = false;
      this.SaleTaxAmount = localStorage.getItem("SaleTax");;
      this.CalculationSalesTax();
    }

    else if (taxtype == "3") {
      this.Resellertextbox = false;
      this.SaleTaxDiv = false;
      this.ResellerNo = null;
      this.IsTaxPart = false;
      this.IsTaxLabor = false;
      this.SaleTaxAmount = "";
      this.CalculationSalesTax();
    }

    else {
      this.SaleTaxDiv = true;
      this.Resellertextbox = false;
      this.ResellerNo = null;
      this.CalculationSalesTax();
    }
  }

  OnDiscountChange(value) {
    if (value) {
      this.discountRadioBox = value;
      this.enableCheckBox = true;
      this.discountamount = '';
    }
    this.CalculationSubtotal();
    this.CalculationSalesTax();
    if (this.discountRadioBox == "Percentage") {
      this.maxminvalue = 5
    }
    else if ((this.discountRadioBox == "Doller")) {
      this.maxminvalue = 8
    }
    else {

    }

  }

  formatCurrencySubtotal(event) {

    let txtValue = event;
    var input_val = parseFloat(txtValue).toFixed(2);
    if (input_val != "") {
      if (input_val.indexOf(".") >= 0) {
        var decimal_pos = input_val.indexOf(".");
        var l = input_val.substring(0, decimal_pos);
        var r = input_val.substring(decimal_pos, decimal_pos + 3);
        this.discountamount = l + "" + r;
        return l + "" + r;;
      } else {
        this.discountamount = this.discountamount + ".00";
      }
    }
    else {
      this.discountamount = "0.00";
    }
  }



  addCategory(contentCat) 
  {

    if (this.selectedCategoryValue == '' || this.selectedCategoryValue == undefined || this.selectedCategoryValue == null
      || this.SelectedCatagoryId == '' || this.SelectedCatagoryId == undefined || this.SelectedCatagoryId == null) {
      $("#myModal").show();
      return;
    }

    let partAlreadyExists = this.partCategoriesList.filter(x => x.categoryId == this.SelectedCatagoryId);
    if (partAlreadyExists.length > 0) {
      $("#CategoryExist").show();
      return;
    }
    if (this.partCategoriesList.length > 0) {
      this.isCatval = false;
    }

    if (this.SelectedCatagoryId != 0 && this.SelectedCatagoryId != null && this.SelectedCatagoryId != undefined) {
      this.loader = true;
      this.partService.getAllPartByCategoryId(this.SelectedCatagoryId, this.companyLoginId).subscribe(
        data => {
          this.loader = false;
          this.data = data;
          let ddlData = this.data.dataList;

          if (ddlData != null && ddlData != undefined && ddlData.length > 0) {
            if (this.SelectedCatagoryId) {

              let obj = {
                categoryId: Number(this.SelectedCatagoryId),
                categoryName: $(".prodCategory option:selected").text(),
                totalextensiondata: '',

              }
              this.partCategoriesList.push(obj);
              this.partCategoriesList.forEach(cateLst => {

              });
              this.SelectedCatagoryName = '';
              this.SelectedCatagoryId = '';
              this.selectedCategoryValue = '';
            }

          }
          else {

            this.modalService.open(contentCat, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
            this.confirmationAletType = 'ConfirmCategory';
            this.confirmationMsg = "Part not available in this category.";
            this.SelectedCatagoryName = '';
            this.SelectedCatagoryId = '';
            this.selectedCategoryValue = '';
          }
        });

    }

  }
  typeaheadOnSelectCatagory(event) {
    this.SelectedCatagoryId = event;
    this.selectedCategoryValue = event;
  }

  Openpart(id) {
    var service = id;
    var service_id = '.openpart-' + service + '-form';
    $(service_id).toggle();
    $("#openpart-" + id).find('i').toggleClass('fa-plus-circle fa-minus-circle');
    $(service_id).next('.table-partdetail').hide();
    $(service_id).find('i').addClass('fa-plus').removeClass('fa-minus');
  }

  OpenpartWorkflow(id) {
    var service = id;
    var service_id = '.openpartWorkflow-' + service + '-form';
    $(service_id).toggle();
    $("#openpartWorkflow-" + id).find('i').toggleClass('fa-plus-circle fa-minus-circle');
    $(service_id).nextAll('.table-partdetailWorkflow').hide();
    $(service_id).find('i').addClass('fa-plus').removeClass('fa-minus');
  }

  OpenQuestion(id, text) {
    var service = id;
    var service_id = '.partname-' + service + '-detail';
    $(service_id).toggle();
    $("#partname-" + id).find('i').toggleClass('fa-plus fa-minus');
    if (text == 'open') {
      this.open = !this.open;
    }
  }

  OpenTask(id) {
    var service = id;
    var service_id = '.tasklist-' + service + '-detail';
    $(service_id).toggle();
    $("#tasklist-" + id).find('i').toggleClass('fa-plus fa-minus')
  }


  changePart(partid) {
    let partId = partid;
    this.selectedPartValue = partId;
    this.loader = true;
   // let customerID=(this.pdfCustomerId == undefined ? 0 : this.pdfCustomerId)
    //let customerID=(this.custome == undefined ? 0 : this.pdfCustomerId)
   // this.sharedService.getAllPartDetailsByPartId(partId).subscribe(
    this.partService.getAllPartDetailsByPartId1(partId,0).subscribe(
      data => {
       
        this.data = data;
        if (this.data.dataList.length > 0 && this.data.dataList != null && this.data.dataList != undefined) {
          this.partObjectModel.id = this.data.dataList[0].partid;
          this.partObjectModel.cost = parseFloat(this.data.dataList[0].cost).toFixed(5);
          this.partObjectModel.hours = this.data.dataList[0].hours;
          this.partObjectModel.hours = this.data.dataList[0].hours;
          this.partObjectModel.partNo = this.data.dataList[0].partNo;
          this.partObjectModel.name = this.data.dataList[0].name;
          this.partObjectModel.vendorPartName = this.data.dataList[0].vendorPartName;
          this.partObjectModel.vendorPartNumber = this.data.dataList[0].vendorPartNumber;
          this.partObjectModel.vendorName = this.data.dataList[0].vendorName;
          this.partObjectModel.customerDiscount = this.data.dataList[0].customerDiscount;
          let partList = [];
          let partDetailss = {};
          for (let i = 0; i < this.data.dataList.length; i++) {
            partDetailss = {
              answerId: 0,
              typeName: this.data.dataList[i].typeName,
              questionName: this.data.dataList[i].questionTitle,
              questionId: this.data.dataList[i].questionid,
              typeKey: this.data.dataList[i].typeKey,
              answerName: this.data.dataList[i].answer
            };
            partList.push(partDetailss);
            this.partObjectModel.partQuestions = partList;
            this.loader = false;
          }
        }
      });
  }

  addPart(item)
   {
    
    this.quantity = $("#quantity").val();
    var index = this.partCategoriesList.findIndex(d => d.categoryId == this.partCategoryId);
    let partAlreadyExists1 = this.partCategoriesList[index].partList == undefined ? [] : this.partCategoriesList[index].partList.filter(x => x.partId == this.selectedPartValue);
    if (partAlreadyExists1.length > 0) {
      $("#myModalAlreadyExits").show();
      return;
    }

    this.validationPart = true;
    if (this.selectedPartValue == '' ||
      this.selectedPartValue == undefined ||
      this.selectedPartValue == null ||
      this.quantity == "" ||
      this.quantity == 0) {
      return;
    }


    let obj = {
      quantity: item.qty == "" ? 1 : item.qty,
      cost: item.cost,
      hours: item.hours,
      quantityOld: item.qty == "" ? 1 : item.qty,
      costold: item.cost,
      hourold: item.hours,
      questionAnsList: item.partQuestions,
      partId: item.id,
      partName: this.ddlPartList.find(x => x.id == this.selectedPartValue).name,
      vendorName: item.vendorName,
      workflowId: Number(item.workflowId),
      notes: '',
      isEdit: true,
      isNotes: false,

    }
    let partCategoryId = this.partCategoryId;
    let cateItem = this.partCategoriesList.filter(item => item.categoryId == partCategoryId);
    if (cateItem.length > 0 && cateItem != null) {
      if (cateItem[0].partList == undefined) {
        cateItem[0].partList = [];
      }
      cateItem[0].partList.push(obj);
      this.subSection = cateItem[0].partList.length;
      $("#CustomPartitem").show();
      $("#addpartModalpopup").hide();
    }

    this.grandTotal = 0;
    this.finalAmount = 0;
    this.HoursExtensionTotal = 0;
    this.PartExtensionTotal = 0;
    this.GrandTotalAmount = 0;
    this.LaborExtesionTotal = 0;


    if (this.partCategoriesList != null && this.partCategoriesList.length > 0) {
      this.partCategoriesList.forEach(cateLst => {
        this.TotalExtension = 0;
        let partindexing = 1;
        if (cateLst.partList != undefined) {
          cateLst.partList.forEach(partLst => {
            let cost = partLst.cost;
            let horse = partLst.hours;
            this.grandTotal += (cost * partLst.quantity) + (horse * this.laborHrsCost * partLst.quantity);
            this.finalAmount += (cost * partLst.quantity) + (horse * this.laborHrsCost * partLst.quantity);
            this.PartExtensionTotal += partLst.cost * partLst.quantity;
            this.HoursExtensionTotal += Number(Number(partLst.hours).toFixed(2));
            this.LaborExtesionTotal += partLst.hours * this.laborHrsCost * partLst.quantity;
            this.GrandTotalAmount = this.grandTotal;
            this.TotalExtension += (cost * partLst.quantity) + (horse * this.laborHrsCost * partLst.quantity);
          });
          cateLst.totalextensiondata = this.TotalExtension;
        }
      });
      this.CalculationSubtotal();
      this.CalculationSalesTax();
    }
    if (this.subSection > 0) {
      this.isPartval = false;
    }
    this.partObjectModel.qty = 1;
    // this.ClosePartPopup();
  }

  deletecategorypopup(categoryId, contentcategory) {
    this.deletedItem = '';
    this.modalService.open(contentcategory, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
    this.deleteDetail = 'Are you sure, you want to delete Category?';
    this.categorydeletecategoryid = categoryId;
  }

  RemoveCategory() {

    var id = this.categorydeletecategoryid;
    this.grandTotal = 0;
    this.finalAmount = 0;
    const cateItem = this.partCategoriesList.filter(item => item.categoryId == id);
    const index = this.partCategoriesList.findIndex(item => item.categoryId == id);
    if (index > -1) {
      this.partCategoriesList.splice(index, 1);
      this.grandTotal = 0;
      this.finalAmount = 0;
      this.HoursExtensionTotal = 0;
      this.PartExtensionTotal = 0;
      this.GrandTotalAmount = 0;
      this.LaborExtesionTotal = 0;
      if (this.partCategoriesList != null && this.partCategoriesList.length > 0) {
        this.partCategoriesList.forEach(cateLst => {
          cateLst.indexing = cateLst.indexing--;


          if (cateLst != undefined) {
            if (cateLst.partList != undefined) {
              cateLst.partList.forEach(partLst => {
                let cost = partLst.cost;
                let horse = partLst.hours;
                this.grandTotal += (cost * partLst.quantity) + (horse * this.laborHrsCost);
                this.finalAmount += (cost * partLst.quantity) + (horse * this.laborHrsCost);
                this.PartExtensionTotal += partLst.cost * partLst.quantity;
                this.HoursExtensionTotal += partLst.hours * 1;
                this.LaborExtesionTotal += partLst.hours * this.laborHrsCost;
                this.GrandTotalAmount = this.grandTotal;
              });
            }
            else {

            }
          }
        });

      }
      this.subSection = this.partCategoriesList.length;
      this.CalculationSubtotal();
      this.CalculationSalesTax();
      if (this.subSection > 0) {
        this.isCatval = false;
      }
      else {
        this.isCatval = false;
      }
      if (this.partCategoriesList != null && this.partCategoriesList != undefined && this.partCategoriesList.length <= 0) {
        this.PartCheckBox = false;
        this.LaborCheckBox = false;
        this.IsTaxPart = false;
        this.IsTaxLabor = false;
        this.SaleTaxAmount = '';
        this.discountamount = '';
        this.ResellerNo = '';
      }

    }


  }
  EditPartRow(categoryId, partId, rowText) {
    if (this.open == false) {
      this.OpenQuestion(partId, '');
    }
    this.open = false;
    this.normalShow = true;
    this.RowEdit = true;
    if (categoryId != 0 && partId != 0 && rowText == "editRow") {
      this.CurrentEditRow = "Edit";
      this.RowEdit = false;
      this.EditRowCateId = categoryId;
      this.EditRowPartId = partId;
      this.EditRowTxtName = "editRow";
      this.btnOkShow = true;
      this.btnEditDeleteShow = false;
      let itemCat = this.partCategoriesList.filter(item => item.categoryId == categoryId);
      if (itemCat.length > 0 && itemCat != null && itemCat[0].partList.length > 0) {
        this.TotalExtension = 0;
        const partData = itemCat[0].partList.filter(item => item.partId == partId);
        if (partData.length > 0 && partData != null) {
          this.UpdateQty = partData[0].quantity;
          this.UpdateEachPartCost = partData[0].cost;
          this.UpdateLaborHrs = partData[0].hours;
          this.UpdateQty = partData[0].quantityOld;
          this.UpdateEachPartCost = partData[0].costold;
          this.UpdateLaborHrs = partData[0].hourold;
          partData[0].quantity = partData[0].quantityOld;
          partData[0].cost = partData[0].costold;
          partData[0].hours = partData[0].hourold;
          this.UpdateEachPartCost = parseFloat(partData[0].cost).toFixed(5);
          this.UpdateLaborHrs = parseFloat(partData[0].hours).toFixed(2);
        }
      }
    }
  }

  UpdatePartRow(categoryId, partId) {
    this.OpenQuestion(partId, '');
    let itemCat = this.partCategoriesList.filter(item => item.categoryId == categoryId);
    if (itemCat.length > 0 && itemCat != null && itemCat[0].partList.length > 0) {
      const partData = itemCat[0].partList.filter(item => item.partId == partId);
      if (partData.length > 0 && partData != null) {
        partData[0].quantityOld = this.UpdateQty;
        partData[0].costold = this.UpdateEachPartCost;
        partData[0].hourold = this.UpdateLaborHrs;
      }
    }

    this.normalShow = true;
    this.RowEdit = true;
    this.CurrentEditRow = "";
    this.EditRowTxtName = "";
    this.btnEditDeleteShow = true;
    this.btnOkShow = false;
    this.grandTotal = 0;
    this.finalAmount = 0;
    this.HoursExtensionTotal = 0;
    this.PartExtensionTotal = 0;
    this.GrandTotalAmount = 0;
    this.LaborExtesionTotal = 0;
    if (this.partCategoriesList != null && this.partCategoriesList.length > 0) {
      this.partCategoriesList.forEach(cateLst => {
        this.TotalExtension = 0;
        if (cateLst != undefined) {
          cateLst.partList.forEach(partLst => {
            let cost = partLst.cost;
            let horse = partLst.hours;
            this.grandTotal += (cost * partLst.quantity) + (horse * this.laborHrsCost * partLst.quantity);
            this.finalAmount += (cost * partLst.quantity) + (horse * this.laborHrsCost * partLst.quantity);
            this.PartExtensionTotal += partLst.cost * partLst.quantity;
            this.HoursExtensionTotal = Number(this.HoursExtensionTotal) + parseFloat(horse);
            this.HoursExtensionTotal = Number(parseFloat('' + this.HoursExtensionTotal).toFixed(2))
            this.LaborExtesionTotal += partLst.hours * this.laborHrsCost * partLst.quantity;
            this.GrandTotalAmount = this.grandTotal;
            this.TotalExtension += (cost * partLst.quantity) + (horse * this.laborHrsCost * partLst.quantity);
          });
          cateLst.totalextensiondata = this.TotalExtension;
        }
      });
      this.CalculationSubtotal();
      this.CalculationSalesTax();
    }

  }

  CancleUpdatePartRow(categoryId, partId,) {
    this.OpenQuestion(partId, '');
    this.btnOkShow = false;
    this.btnEditDeleteShow = true;
    this.EditRowCateId = 0;
    this.EditRowPartId = 0;
    this.EditRowTxtName = '';
    this.CurrentEditRow = '';
    let itemCat = this.partCategoriesList.filter(item => item.categoryId == categoryId);
    if (itemCat.length > 0 && itemCat != null && itemCat[0].partList.length > 0) {
      const partData = itemCat[0].partList.filter(item => item.partId == partId);
      if (partData.length > 0 && partData != null) {
        partData[0].quantityOld = partData[0].quantityOld;
        partData[0].costold = partData[0].costold;
        partData[0].hourold = partData[0].hourold;
      }
    }
    this.normalShow = true;
    this.RowEdit = true;
    this.CurrentEditRow = "";
    this.EditRowTxtName = "";
    this.btnEditDeleteShow = true;
    this.btnOkShow = false;
    this.grandTotal = 0;
    this.finalAmount = 0;
    this.HoursExtensionTotal = 0;
    this.PartExtensionTotal = 0;
    this.GrandTotalAmount = 0;
    this.LaborExtesionTotal = 0;

    if (this.partCategoriesList != null && this.partCategoriesList.length > 0) {
      this.partCategoriesList.forEach(cateLst => {
        if (cateLst != undefined) {
          cateLst.partList.forEach(partLst => {
            let cost = partLst.costold;
            let horse = partLst.hourold;
            this.grandTotal += (cost * partLst.quantityOld) + (horse * this.laborHrsCost * partLst.quantityOld);
            this.finalAmount += (cost * partLst.quantityOld) + (horse * this.laborHrsCost * partLst.quantityOld);
            this.PartExtensionTotal += partLst.costold * partLst.quantityOld;
            this.HoursExtensionTotal = Number(this.HoursExtensionTotal) + parseFloat(horse);
            this.HoursExtensionTotal = Number(parseFloat('' + this.HoursExtensionTotal).toFixed(2))
            this.LaborExtesionTotal += partLst.hourold * this.laborHrsCost * partLst.quantityOld;
            this.GrandTotalAmount = this.grandTotal;
          });
        }
      });

      this.CalculationSubtotal();
      this.CalculationSalesTax();
    }
  }
  DeletePart() {
    var idPart = parseInt(this.idPart);
    var idCategory = this.categoryDeleteId;
    if (idCategory != 0 && idPart != 0) {
      let itemCat = this.partCategoriesList.filter(item => item.categoryId == idCategory);
      if (itemCat.length > 0 && itemCat != null && itemCat[0].partList.length > 0) {
        const index = itemCat[0].partList.findIndex(item => item.partId == idPart);
        if (index > -1) {
          itemCat[0].partList.splice(index, 1);
          this.grandTotal = 0;
          this.finalAmount = 0;
          this.HoursExtensionTotal = 0;
          this.PartExtensionTotal = 0;
          this.GrandTotalAmount = 0;
          this.LaborExtesionTotal = 0;
          if (this.partCategoriesList != null && this.partCategoriesList.length > 0) {
            this.partCategoriesList.forEach(cateLst => {
              this.TotalExtension = 0;

              if (cateLst != undefined) {
                cateLst.partList.forEach(partLst => {
                  let cost = partLst.cost;
                  let horse = partLst.hours;
                  this.grandTotal += (cost * partLst.quantity) + (horse * this.laborHrsCost * partLst.quantity);
                  this.finalAmount += (cost * partLst.quantity) + (horse * this.laborHrsCost * partLst.quantity);
                  this.PartExtensionTotal += partLst.cost * partLst.quantity;
                  this.HoursExtensionTotal += parseFloat(partLst.hours);
                  this.LaborExtesionTotal += partLst.hours * this.laborHrsCost * partLst.quantity;
                  this.GrandTotalAmount = this.grandTotal;
                  this.TotalExtension += (partLst.cost * partLst.quantity) + (partLst.hours * this.laborHrsCost * partLst.quantity);

                });
                cateLst.totalextensiondata = this.TotalExtension;
                this.subSection = cateLst.partList.length;
              }
            });
            this.CalculationSubtotal();
            this.CalculationSalesTax();
            if (this.subSection > 0) {
              this.isPartval = false;
            }

            else {
              this.isPartval = true;
            }
            if (this.partCategoriesList[0].partList != null && this.partCategoriesList[0].partList != undefined && this.partCategoriesList[0].partList.length <= 0) {
              this.PartCheckBox = false;
              this.LaborCheckBox = false;
              this.IsTaxPart = false;
              this.IsTaxLabor = false;
              this.SaleTaxAmount = '';
              this.discountamount = '';
              this.ResellerNo = '';
            }

          }
        }
      }
    }
  }
  EditQuestionAnswr(selectedQuestionId, selectedPartId, answerValue, notesValue) {

    if (selectedQuestionId != 0 && selectedPartId != 0) {
      this.answerUpdatedList = [];

      this.partCategoriesList.forEach(prtCat => {
        if (prtCat.partList != null) {
          prtCat.partList.forEach(partLst => {
            if (partLst.questionAnsList != null) {
              partLst.questionAnsList.forEach(quesAnsList => {
                let partQuestObj = {};
                partQuestObj["answerId"] = parseInt(quesAnsList.answerId == undefined ? 0 : quesAnsList.answerId);
                partQuestObj["questionName"] = quesAnsList.questionName;
                partQuestObj["notes"] = quesAnsList.notes;
                partQuestObj["productPartId"] = quesAnsList.productPartId;
                partQuestObj["answerName"] = quesAnsList.answerName;
                partQuestObj["Id"] = quesAnsList.questionId;
                this.answerUpdatedList.push(partQuestObj);
              })
            }
          });
        }
      });

      this.selectedQuestionId = selectedQuestionId,
        this.selectedPartId = selectedPartId;
      if (answerValue != "" && answerValue != null) {
        this.answerValue = answerValue;
        this.isQA = true;
      }
      else if (notesValue != "" && notesValue != null) {
        this.notesValue = notesValue;
        this.isNotes = true;
      }


    }
  }
  UpdateQuestionAnswer(questionId, partId, answer, notes, event: any) {
    var data = this.answerUpdatedList.filter(x => x.Id == questionId);
    if (answer != "") {
      data[0].answerName = this.answerValue;
    }
    else if (notes == 'insertNotes') {

      if (this.answerUpdatedList.length > 0) {
        var data = this.answerUpdatedList.filter(x => x.Id == questionId);
        data[0].notes = event.target.value;
      }
      else {
        this.answerUpdatedList = [];
        this.partCategoriesList.forEach(prtCat => {
          if (prtCat.partList != null) {
            prtCat.partList.forEach(partLst => {
              if (partLst.questionAnsList != null) {

                partLst.questionAnsList.forEach(quesAnsList => {


                  let partQuestObj = {};
                  partQuestObj["answerId"] = parseInt(quesAnsList.answerId == undefined ? 0 : quesAnsList.answerId);
                  partQuestObj["questionName"] = quesAnsList.questionName;
                  partQuestObj["notes"] = quesAnsList.notes;
                  partQuestObj["productPartId"] = quesAnsList.productPartId;
                  partQuestObj["answerName"] = quesAnsList.answerName;
                  partQuestObj["Id"] = quesAnsList.questionId;
                  this.answerUpdatedList.push(partQuestObj);
                })
              }
            });
          }
        });

        var data = this.answerUpdatedList.filter(x => x.Id == questionId);
        data[0].notes = event.target.value;
      }


    }
    else {
      data[0].notes = this.notesValue;
      //data[0].answerName = this.answerValue;
    }
  }

  UpdateQuestionAnswer1(questionId, partId, answer, notes, event: any) {


    var data = this.answerUpdatedList.filter(x => x.Id == questionId);
    if (answer != "") {
      data[0].answerName = this.answerValue;
    }
    else if (notes == 'insertNotes') {

      if (this.answerUpdatedList.length > 0) {
        var data = this.answerUpdatedList.filter(x => x.Id == questionId);
        data[0].notes = event.target.value;
      }
      else {
        this.answerUpdatedList = [];
        this.partCategoriesList.forEach(prtCat => {
          if (prtCat.partList != null) {
            prtCat.partList.forEach(partLst => {
              if (partLst.questionAnsList != null) {

                partLst.questionAnsList.forEach(quesAnsList => {


                  let partQuestObj = {};
                  partQuestObj["answerId"] = parseInt(quesAnsList.answerId == undefined ? 0 : quesAnsList.answerId);
                  partQuestObj["questionName"] = quesAnsList.questionName;
                  partQuestObj["notes"] = quesAnsList.notes;
                  partQuestObj["productPartId"] = quesAnsList.productPartId;
                  partQuestObj["answerName"] = quesAnsList.answerName;
                  partQuestObj["Id"] = quesAnsList.questionId;
                  this.answerUpdatedList.push(partQuestObj);
                })
              }
            });
          }
        });

        var data = this.answerUpdatedList.filter(x => x.Id == questionId);
        data[0].notes = event.target.value;
      }


    }
    else {
      data[0].notes = this.notesValue;
      //data[0].answerName = this.answerValue;
    }
  }

  formatCurrencySalesTaxKeyPress() {
    var saletaxfield = this.SaleTaxAmount;
    if (saletaxfield == "" || saletaxfield == undefined || saletaxfield == null) {
      return;
    }
    var decimal_pos = saletaxfield.indexOf(".");
    if (saletaxfield.length >= 2 && decimal_pos == 1) {
      let maskingSaletax = '';
      maskingSaletax = saletaxfield.substring(0, 2) + "." + saletaxfield.substring(3, 4);
      this.SaleTaxAmount = maskingSaletax;
    }
    if (saletaxfield.length >= 1 && decimal_pos == 1) {
      let maskingSaletax = '';
      maskingSaletax = saletaxfield.substring(0, 1) + "." + saletaxfield.substring(2, 3);
      this.SaleTaxAmount = maskingSaletax;
    }
    if (saletaxfield.length >= 3 && decimal_pos == -1) {
      let maskingSaletax = '';
      maskingSaletax = saletaxfield.substring(0, 2) + "." + saletaxfield.substring(3, 4);
      this.SaleTaxAmount = maskingSaletax;
    }
    else {

    }
  }

  formatCurrencyDiscount() {
    var discountfield = this.discountamount;
    if (this.discountRadioBox == "Percentage") {
      if (discountfield == "" || discountfield == undefined || discountfield == null) {
        return;
      }
      var decimal_pos = discountfield.indexOf(".");
      if (discountfield.length >= 2 && decimal_pos == 1) {
        let maskingSaletax = '';
        maskingSaletax = discountfield.substring(0, 2) + "." + discountfield.substring(3, 4);
        this.discountamount = maskingSaletax;
      }
      if (discountfield.length >= 1 && decimal_pos == 1) {
        let maskingSaletax = '';
        maskingSaletax = discountfield.substring(0, 1) + "." + discountfield.substring(2, 3);
        this.discountamount = maskingSaletax;
      }
      if (discountfield.length >= 3 && decimal_pos == -1) {
        let maskingSaletax = '';
        maskingSaletax = discountfield.substring(0, 2) + "." + discountfield.substring(3, 4);
        this.discountamount = maskingSaletax;
      }
      else {

      }
    }
    if (this.discountRadioBox == "Doller") {
      if (discountfield == "" || discountfield == undefined || discountfield == null) {
        return;
      }
      var decimal_pos1 = discountfield.indexOf(".");
      if (discountfield.length >= 5 && decimal_pos1 == 1) {
        let maskingSaletax = '';
        maskingSaletax = discountfield.substring(0, 5) + "." + discountfield.substring(6, 7);
        this.discountamount = maskingSaletax;
      }
      if (decimal_pos1 == 1) {

        this.discountamount = this.discountamount;
      }
      if (discountfield.length >= 6 && decimal_pos1 == -1) {
        let maskingSaletax = '';
        maskingSaletax = discountfield.substring(0, 5) + "." + discountfield.substring(6, 7);
        this.discountamount = maskingSaletax;
      }
    }
  }

  formatCurrencySalesTaxForBlur() {

    let txtValue = this.SaleTaxAmount;
    var input_val = parseFloat(txtValue).toFixed(2);
    if (input_val != "") {
      if (input_val.indexOf(".") >= 0) {
        var decimal_pos = input_val.indexOf(".");
        var l = input_val.substring(0, decimal_pos);
        var r = input_val.substring(decimal_pos, decimal_pos + 3);
        this.SaleTaxAmount = l + "" + r;
        return l + "" + r;;
      } else {
        this.SaleTaxAmount = this.SaleTaxAmount + ".00";
      }
    }
    else {
      this.SaleTaxAmount = "0.00";
    }
  }
  formatCurrency(event) {
    let txtValue = event;
    var input_val = txtValue;
    if (input_val != "") {
      if (input_val.indexOf(".") >= 0) {
        var decimal_pos = input_val.indexOf(".");
        var l = input_val.substring(0, decimal_pos);
        var r = input_val.substring(decimal_pos, decimal_pos + 6);
        this.UpdateEachPartCost = l + "" + r;
        return l + "" + r;;
      } else {
        this.UpdateEachPartCost = this.UpdateEachPartCost + ".00000";
      }
    }
    else {
      this.UpdateEachPartCost = "0.00000";
    }
  }
  formatHours(event) {
    let txtValue = event;
    var input_val = txtValue;
    if (input_val != "") {
      if (input_val.indexOf(".") >= 0) {
        var decimal_pos = input_val.indexOf(".");
        var l = input_val.substring(0, decimal_pos);
        var r = input_val.substring(decimal_pos, decimal_pos + 3);
        this.UpdateLaborHrs = l + "" + r;
        return l + "" + r;;
      } else {
        this.UpdateLaborHrs = this.UpdateLaborHrs + ".00";
      }
    }
    else {
      this.UpdateLaborHrs = "0.00";
    }
  }
  deletePopup1(categoryid, partid, contentname) {
    this.deletedItem = '';
    this.modalService.open(contentname, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
    this.deleteDetail = 'Are you sure, you want to delete Part Item?';
    this.idPart = partid;
    this.categoryDeleteId = categoryid;
  }

  CalculationOnkeypress(categoryId, partId)
   {
    
    let rowText = "editRow";
    let itemCat = this.partCategoriesList.filter(item => item.categoryId == categoryId);
    if (itemCat.length > 0 && itemCat != null && itemCat[0].partList.length > 0) {
      const partData = itemCat[0].partList.filter(item => item.partId == partId);
      if (partData.length > 0 && partData != null) {
        partData[0].quantity = this.UpdateQty;
        partData[0].cost = this.UpdateEachPartCost;
        partData[0].hours = this.UpdateLaborHrs;
      }
    }
    this.normalShow = true;
    this.RowEdit = true;
    this.CurrentEditRow = "";
    this.EditRowTxtName = "";
    this.btnEditDeleteShow = true;
    this.btnOkShow = false;
    this.grandTotal = 0;
    this.finalAmount = 0;
    this.HoursExtensionTotal = 0;
    this.PartExtensionTotal = 0;
    this.GrandTotalAmount = 0;
    this.LaborExtesionTotal = 0;

    if (this.partCategoriesList != null && this.partCategoriesList.length > 0) {
      this.partCategoriesList.forEach(cateLst => {
        if (cateLst != undefined) {
          cateLst.partList.forEach(partLst => {
            let cost = partLst.cost;
            let horse = partLst.hours;
            this.grandTotal += (cost * partLst.quantity) + (horse * this.laborHrsCost * partLst.quantity);
            this.finalAmount += (cost * partLst.quantity) + (horse * this.laborHrsCost * partLst.quantity);
            this.PartExtensionTotal += partLst.cost * partLst.quantity;
            this.HoursExtensionTotal = Number(this.HoursExtensionTotal) + parseFloat(horse);
            this.HoursExtensionTotal = Number(parseFloat('' + this.HoursExtensionTotal).toFixed(2))
            this.LaborExtesionTotal += partLst.hours * this.laborHrsCost * partLst.quantity;
            this.GrandTotalAmount = this.grandTotal;
          });
        }
      });
      this.CalculationSubtotal();
      this.CalculationSalesTax();
      
      this.EditPartRowOnKeypress(categoryId, partId, rowText)
    }
  }


  EditPartRowOnKeypress(categoryId, partId, rowText) {
    this.normalShow = true;
    this.RowEdit = true;
    if (categoryId != 0 && partId != 0 && rowText == "editRow") {
      this.CurrentEditRow = "Edit";
      this.RowEdit = false;
      this.EditRowCateId = categoryId;
      this.EditRowPartId = partId;
      this.EditRowTxtName = "editRow";
      this.btnOkShow = true;
      this.btnEditDeleteShow = false;

      let itemCat = this.partCategoriesList.filter(item => item.categoryId == categoryId);
      if (itemCat.length > 0 && itemCat != null && itemCat[0].partList.length > 0) {
        const partData = itemCat[0].partList.filter(item => item.partId == partId);
        if (partData.length > 0 && partData != null) {
          this.UpdateQty = partData[0].quantity;
          this.UpdateEachPartCost = partData[0].cost;
          this.UpdateLaborHrs = partData[0].hours;
        }
      }
    }
  }
  rerender(type): void {
    if (type == 'one') {
      this.dtElements.toArray()[0].dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger1.next('');
      });
    }
    else if (type == 'two') {
      this.dtElements.toArray()[1].dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger2.next('');
      });
    }
    else if (type == 'three') {
      this.dtElements.toArray()[2].dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger3.next('');
      });
    }
  }

  GetCompanyDetails(companyId) {
    this.companyService.GetAllCompanyById(companyId).subscribe(
      data => {
        this.data = data;
        if (this.data.data != undefined || this.data.data != null) {
          this.laborHrsCost = this.data.data.laborCost;
          this.SaleTaxAmount = this.data.data.saleTax;
        }

      }
    )
  }

  ProductBack() {
    this.clearbutton = true;
    this.productId = 0;
    this.ProductPartModels = null;
    this.isActiveAdd = true;
    this.subSection = 0;
    this.clearProductDetails();
  }
  Exit() {
    this.clearbutton = true;
    this.productId = 0;
    this.ProductPartModels = null;
    this.isActiveAdd = true;
    this.subSection = 0;
    this.isPartval = true;
    this.TCreateUpdate = 1;
    this.ListDiv = false;
    this.ListShow = false;
    this.clearProductDetails();
  }

  ChangeTCreateUpdate(event) {
    this.TCreateUpdate = event.target.value;
    if (this.TCreateUpdate == "1") {
      this.SearchControl = false;
      this.ListShow = false;
      this.ListDiv = false;
      this.clearbutton = true;
      this.clearProductDetails();
    }
    else if (this.TCreateUpdate == "0") {
      this.SearchControl = true;
      this.ProductSearch = "";
      this.SearchValidation = false;
    }
  }

  ListDivShowHide() {
    
    if (this.ListDiv == true) {
      this.ListDiv = false;
    }
    else if (this.ListDiv == false) {
      this.ListDiv = true;
    }
  }

  searchVendor() {
    this.SearchType = false;
    if (!this.ProductSearch || this.value_search == 0) {
      this.SearchValidation = true;
      return;
    }
    this.ListShow = true;
    this.ListDiv = true;
    this.getAllProducts(this.value_search, this.ProductSearch);
  }

  AllProduct() {
    this.recordAlreadyExist = false;
    this.SearchType = true;
    this.ProductSearch = '';
    if (this.value_search == 0) {
      this.SearchValidation = true;
      return;
    }
    this.SearchValidation = false;
    this.ListShow = true;
    this.ListDiv = true;
    this.getAllProducts(this.value_search, this.ProductSearch);
  }
  getAllCompany_search(companyId) {
    this.companyService.GetDropdownCompanyHierarchyModel(this.companyLoginId).subscribe(
      data => {
        this.data = data;
        this.items_search = this.data.dataList;
        this.companyList_search = this.data.dataList;
        // this.companyList_search.forEach((x) => {
        //   this.userGroupObjects_search.push(new TreeviewItem(x, false));
        // });
        this.value_search = Number(companyId);

      });
  }


  onValueChange_search(value: any): void {
    this.value_search = value;
  }


  getAllproductParts(companyId, vendorId, partnamenumber, cateoryId) {

    this.loader = true;

    $('#datatable1').DataTable().destroy();
    this.dtTrigger2.next('');
    this.dtOptions2 = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      ordering: false,
      //processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        // dataTablesParameters.minNumber = dataTablesParameters.start + 1;
        // dataTablesParameters.maxNumber = dataTablesParameters.start + dataTablesParameters.length;
        this.sharedService.getAllproductParts(companyId, vendorId, partnamenumber ? partnamenumber : dataTablesParameters.search.value, cateoryId, dataTablesParameters.start, dataTablesParameters.length)
          .subscribe(resp => {
            this.loader = false;
            let totalRows = null;
            this.data = resp;
            if (this.data.dataList.length > 0) {
              this.ddlPartList = this.data.dataList;
              totalRows = [...new Set(this.ddlPartList.map(item => item.totalRows))];
            }
            else {
              this.ddlPartList = null;
              totalRows = 0;
            }

            callback({
              recordsTotal: totalRows,
              recordsFiltered: totalRows,
              data: []
            });
          });

      }
    };
    this.rerender('two');

  }

  GetvendorpartModal(companyId) {
    this.partService.Getvendordropdownlist(companyId).subscribe(
      data => {
        this.data = data;
        if (companyId > 0) {
          this.vendorPartListData = this.data.dataList;
        }
        else {
        }
      }
    )
  }
  hidemodalpopup1() {
    $("#myModalAlreadyExits").hide();
  }


  AddCategoryPart(categoryId) {
    if (categoryId != null || categoryId != undefined || categoryId != "") {
      this.partCategoryId = categoryId;
      this.CategoryshowonPopup = this.catagoryDataList.find(x => x.id == categoryId).name
      $("#addpartModal1").show();
    }
    else {

    }
  }

  PartdetailsModalPopup(partid) {
    if (partid != null || partid != "" || undefined != "") {
      this.changePart(partid);
      this.validationPart = false;
      $("#addpartModalpopup").show();
      $("#addpartModal1").hide();
    }
    else {

    }

  }

  ClosePartModalPopup() {
    $("#addpartModal1").show();
    $("#addpartModalpopup").hide();
  }

  PartItemExit() {
    $("#addpartModal1").hide();
    this.PartName_search = '';
    this.partListShow = false;
    this.selectedVendorValue = "";
  }


  CustomPartPopup() {
    $("#addpartModal1").show();
    $("#CustomPartitem").hide();
  }


  SearchByvendorList() {
    this.partListShow = true;
    let vendorId = this.selectedVendorValue == "" ? 0 : this.selectedVendorValue;
    let partnamenumber1 = this.PartName_search == undefined ? "" : this.PartName_search == null ? "" : this.PartName_search;
    if (this.companyLoginId != null || this.companyLoginId != undefined || this.companyLoginId != "") {
      this.getAllproductParts(this.companyLoginId, vendorId, partnamenumber1, this.partCategoryId);
    }
    else {

    }
  }
  SearchByvendorListAll() {
    this.PartName_search = '';
    this.partListShow = true;
    let vendorId = this.selectedVendorValue == "" ? 0 : this.selectedVendorValue;
    let partnamenumber1 = '';
    if (this.companyLoginId != null || this.companyLoginId != undefined || this.companyLoginId != "") {
      this.getAllproductParts(this.companyLoginId, vendorId, partnamenumber1, this.partCategoryId);
    }
    else {

    }
  }


  ngOnDestroy(): void {
    this.dtTrigger1.unsubscribe();
    this.dtTrigger2.unsubscribe();
    this.dtTrigger3.unsubscribe();
  }
  ngAfterContentChecked(): void {

    var partdisplay = $('.openpart-' + this.partCategoryId + '-form').attr('style');
    if (partdisplay != undefined) {
      $('.openpart-' + this.partCategoryId + '-form').attr('style', partdisplay)
    }
    else {
      var cat = $('#openpart-' + this.partCategoryId + ' i').attr('class');
      if (cat == 'fas fa-minus-circle') {
        $('.openpart-' + this.partCategoryId + '-form').attr('style', 'display: table-row;')
      }
      else {
        $('.openpart-' + this.partCategoryId + '-form').attr('style', 'display: none;')
      }
    }



  }
  CategoryExistClose() {
    $("#CategoryExist").hide();
  }
  addsuccess() {
    $("#add_success").hide();
  }
  updateitem() {
    $("#add_update").hide();
  }
  deleteitem() {
    $("#delete_success").hide();
  }
  msgitem() {
    $("#wrong_msg").hide();
  }

  formatCurrencyDiscountBlur(event) {
    let txtValue = event;
    var input_val = parseFloat(txtValue).toFixed(2);
    if (input_val != "") {
      if (input_val.indexOf(".") >= 0) {
        var decimal_pos = input_val.indexOf(".");
        var l = input_val.substring(0, decimal_pos);
        var r = input_val.substring(decimal_pos, decimal_pos + 3);
        this.discountamount = l + "" + r;
        return l + "" + r;;
      } else {
        this.discountamount = this.discountamount == "" ? "0.00" : this.discountamount == undefined ? "0.00" : this.discountamount == null ? "0.00" : this.discountamount + ".00";
      }
    }
    else {
      this.discountamount = "0.00";
    }
  }

  SaleTaxBlur(event) {
    let txtValue = event;
    var input_val = parseFloat(txtValue).toFixed(2);
    if (input_val != "") {
      if (input_val.indexOf(".") >= 0) {
        var decimal_pos = input_val.indexOf(".");
        var l = input_val.substring(0, decimal_pos);
        var r = input_val.substring(decimal_pos, decimal_pos + 3);
        this.SaleTaxAmount = l + "" + r;
        return l + "" + r;;
      } else {
        this.SaleTaxAmount = this.SaleTaxAmount == "" ? "0.00" : this.SaleTaxAmount == undefined ? "0.00" : this.SaleTaxAmount == null ? "0.00" : this.SaleTaxAmount + ".00";
      }
    }
    else {
      this.SaleTaxAmount = "0.00";
    }
  }


  DiscrdProduct(discardItem) {

    this.deletedItem = '';
    if (this.isPartval == false && this.productId == 0 || this.RowEdit == true) {
      this.modalService.open(discardItem, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
      this.deleteDetail = this.constants.ExitMessage;
    }
    else {
      this.clearbutton = true;
      this.productId = 0;
      this.ProductPartModels = null;
      this.isActiveAdd = true;
      this.subSection = 0;
      this.Exit();
    }


  }
  gotoDashBoard() {
    this.Exit();
  }


  SwapCategory(catearray, index_A, index_B) {

    let temp = catearray[index_A];
    catearray[index_A] = catearray[index_B];
    catearray[index_A].indexing = index_A;
    catearray[index_B] = temp;
    catearray[index_B].indexing = index_B;
  }

  SwapPartItem(partitem, index_A, index_B) {
    let temp = partitem[index_A];
    partitem[index_A] = partitem[index_B];
    partitem[index_A].ordering = index_A;
    partitem[index_B] = temp;
    partitem[index_B].ordering = index_B;
  }

  SwapTaskItem(taskitem, index_A, index_B) {
    let temp = taskitem[index_A];
    taskitem[index_A] = taskitem[index_B];
    taskitem[index_A].ordering = index_A;
    taskitem[index_B] = temp;
    taskitem[index_B].ordering = index_B;
  }

  closeTaskMessage() {
    $('#taskMessage').hide();
  }

  vehicleRequiredEvent(event: any) {
    if (event.target.checked == true) {
      this.isVehicleRequired = true;
    }
    else {
      this.isVehicleRequired = false;
    }

  }

  CallModelService(manufactrerModel: any) {
    this.sharedService.getVehicleList(manufactrerModel).subscribe(
      data => {
        this.data = data;
        if (this.data.statusCode == 200) {
          if (this.data.dataList != null && this.data.dataList != undefined) {
            if (manufactrerModel > 0) {
              this.manufacturerModelList = this.data.dataList;
            }
            else {
              this.selectedManufacturerModelValue = '';
            }
          }
        }
        else {
          this.selectedManufacturerModelValue = '';
          this.manufacturerModelList = [];
        }

      }
    )
  }

  onManufacturerChange(e): void {
    var manufactrerModel = e.target.value;
    this.CallModelService(manufactrerModel);
  }
  ChangefieldToSearch(value) {
    this.fieldToSearchPlaceHolder = value == '1' ? 'Enter Search Keywords' : value == '2' ? 'Enter List or Vendor Part Name' : value == '3' ? 'Enter List or Vendor Part #' : '';
  }

  CopyProduct(id, name) {
    this.copyValidation = false;
    this.copyProductName = name;
    this.copyProductId = id;
    $('#CopyProduct').show();
  }
  closeCopyProduct() {
    $('#CopyProduct').hide();
  }
  CreateCopyProduct(id) {
    if (this.newProduct == "") {
      this.copyValidation = true;
      return;
    }
    $('#CreatingProduct').show();
    this.sharedService.GetCopyProduct(id, this.newProduct.trim()).subscribe(
      data => {
        this.data = data;
        if (this.data.statusCode == 200) {
          if (this.data.dataList != null && this.data.dataList != undefined) {
            if (this.data.data == "1") {
              $('#CreatingProduct').hide();
              this.newProduct = "";
              $('#create_success').show();
              this.getAllProducts(this.value_search, this.ProductSearch);
              this.copyValidation = false;
            }
            else if (this.data.data == "2") {
              $('#CreatingProduct').hide();
              $('#CopyProduct').show();
              $('#ProductCopyExist').show();
              this.ProductExist = "Product name already exist! Product name should be unique";
              this.newProduct = "";
              this.copyValidation = false;
            }
            else {
              $('#CreatingProduct').hide();
              $('#wrong_msg').show();
              this.ProductExist = "";
              this.newProduct = "";
              this.copyValidation = false;
            }
          }
        }
        else {
          $('#CreatingProduct').hide();
          $('#wrong_msg').show();
          this.ProductExist = "";
          this.newProduct = "";
        }

      }
    )
    $('#CopyProduct').hide();

  }
  CloseCreatingProduct() {
    $('#CreatingProduct').hide();
  }
  deleteitems() {
    $('#create_success').hide();
  }
  ProductCopyExistClose() {
    $('#ProductCopyExist').hide();
  }
 
  visibleNotes(cid, partid) {
    let cateItem = this.partCategoriesList.filter(item => item.categoryId == cid);
    if (cateItem.length > 0 && cateItem != null) {
      const partData = cateItem[0].partList.filter(x => x.partId == partid);
      if (partData.length > 0) {
        partData[0].isNotes = true;
        partData[0].isEdit = false;
      }
    }
  }
  SaveNotes(cid, partid) {
    const partData = this.partList.filter(x => x.partId == partid);
    let cateItem = this.partCategoriesList.filter(item => item.categoryId == cid);
    if (cateItem.length > 0 && cateItem != null) {
      const partData = cateItem[0].partList.filter(x => x.partId == partid);
      if (partData.length > 0) {
        partData[0].isNotes = false;
        partData[0].isEdit = true;
      }
    }
  }

  //ankit implemenation
  ErrorModal(title:string, message:string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }

}





