import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
//import { Subject } from 'rxjs/internal/Subject';
import { Subject } from 'rxjs';
import { ConstantsService } from 'src/app/constants.service';
import { SharedService } from 'src/app/services/shared.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import { TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { DataTableDirective } from 'angular-datatables';
import { ConfirmationDialogService } from 'src/app/ConfirmationDialog/confirmation-dialog.service'
import { DatePipe } from '@angular/common';
import { GlobalChangeService } from 'src/app/services/global-change.service';
import Swal from 'sweetalert2';
import { CustomerService } from 'src/app/services/customer/customer.service';
import { CompanyService } from 'src/app/services/company/company.service';


@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements AfterViewInit, OnDestroy, OnInit {
  copyValidation: boolean = false;
  selectedOrderList = 1;
  dtOptions: DataTables.Settings = {};
  settingDetailCSS: any;
  statusId: any;
  settingLogoImage: any;
  selectedProductValue = '';
  selectedCustomerValue = '';
  data: any;
  productList: any;
  customerList: any;
  orderList: any;
  orderListOpenCancel: any;
  firstName: any;
  dtTrigger: Subject<any> = new Subject<any>();
  form: FormGroup;
  validation: boolean = false;
  productId: any;
  customerId: any;
  PartialShipment: any;
  CustomerSupplied: any;
  userId: string;
  companyId: any;
  modelDetail: string;
  loader: boolean = false;
  customerName: string;
  productName: string;
  sessionOrderDetials: any = null;
  rolePermissionsList: any;
  deletedItem: string;
  deleteDetail: string;
  partList: any;
  // items: TreeviewItem[];
  companyList: any;
  userGroupObjects = new Array();
  partId: any;
  TCreateUpdate: any = 1;
  ProductSearch: any = "";
  SearchValidation: any;
  SearchControl: boolean = false;
  ListShow: boolean = false;
  ListDiv: boolean = false;
  formSearch: FormGroup;
  SearchType: any;
  PopupinDate: any;
  productDisabled: boolean = true;
  isDisabled: boolean = true;
  copySerialArr = [];
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  // config = TreeviewConfig.create({
  //   hasAllCheckBox: true,
  //   hasFilter: true,
  //   hasCollapseExpand: true,
  //   decoupleChildFromParent: false,
  //   maxHeight: 400

  // });
  selectedOrderTypeValue = '';
  value: any;
  permissionMessage: string;
  // statuslist: any;
  activestatuslist: any;
  inactivestatuslist: any;
  StatusData: '';
  // config_search = TreeviewConfig.create({
  //   hasAllCheckBox: true,
  //   hasFilter: true,
  //   hasCollapseExpand: true,
  //   decoupleChildFromParent: false,
  //   maxHeight: 400
  // });
  userGroupObjects_search = new Array();
  value_search = 0;
  // items_search: TreeviewItem[];
  companyList_search: any;
  sessionExitDetials: any = null;
  BackData: any;
  StatusModel: any;
  //StatusValue: any = '0';
  StatusValue: any = [0];
  IsActive: any = '1';
  SalesNumber: any;
  SPrefix: any;
  getSoList: any;
  GetSaleOrderId: any = '';
  isSaleOrder: boolean = false;
  IsChangeOrder: boolean = true;
  GetChangeValue: any;
  Changevalidation: boolean = false;
  orderDatatype: any;
  ChangeOrdrProductId: any;
  ChangeOrdrProductName: any;
  descriptiondata: any;
  isBackgroudImage: boolean = false;
  isSaveChanges: boolean = false;
  SalesQuoteDate: any;
  SalesOrderDate: any;
  isVehicleRequired: boolean;
  manufacturerId: any;
  manufacturerModelId: any;
  manufacturerList = [];
  manufacturerModelList = [];
  selectedManufacturerValue: any = '';
  selectedManufacturerModelValue: any = ''
  Year: any;
  salesProp: string;
  CreateOrder: any = '';
  prop: string = "";
  copyOrderId: any;
  copyCompanyId: any;
  items: any;
  items_search: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private constants: ConstantsService,
    public sharedService: SharedService,
    private customerService: CustomerService,
    private modalService: NgbModal,
    public confirmation: ConfirmationDialogService, 
    public datepipe: DatePipe,
    private globalChangeService: GlobalChangeService,
    private companyService: CompanyService
  ) {
  }

  ngOnInit(): void {
    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);
    }
    //this.sharedService.changeMessage('', this.companyId);
    if (this.route.snapshot.params['orderId'] && this.route.snapshot.params['orderTypeId'] && this.route.snapshot.params['type']) {
      this.PreviewOrder(this.route.snapshot.params['orderId'], this.route.snapshot.params['orderTypeId'], this.route.snapshot.params['type']);
    }
    if (this.route.snapshot.params['id']) {
      var orderid = this.route.snapshot.params['id'];
      this.sharedService.GetOrderDetails(orderid, '').subscribe(
        (data: any) => {

          const data1 = data;
          this.EditOrder(orderid, data.data.orderTypeId, '', data.data.companyId);
        });
    }
    this.rolePermissionsList = JSON.parse(localStorage.getItem('rolePermissionsList'));
    this.rolePermissionsList = this.rolePermissionsList.filter(x => x.pageId == 12);
    this.permissionMessage = '';
    if (this.rolePermissionsList != null && this.rolePermissionsList != undefined) {
      if (!this.rolePermissionsList[0].isView) {
        this.permissionMessage += 'view';
      } if (!this.rolePermissionsList[0].isInsert) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'add';
      } if (!this.rolePermissionsList[0].isUpdate) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'edit';
      } if (!this.rolePermissionsList[0].isDelete) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'delete';
      }
    }
    if (this.permissionMessage != '') {
      this.permissionMessage = 'You do not have access to ' + this.permissionMessage + '. Please contact to administrator!';
    }
    this.userId = localStorage.getItem(this.constants.userId);
    this.companyId = localStorage.getItem("companyId");
    this.settingDetailCSS = localStorage.getItem("settingDetailCSS");
    this.settingLogoImage = localStorage.getItem("settingImageLogo");
    this.SetSettingCSS(this.settingDetailCSS, this.settingLogoImage);

    this.dtOptions = {
      pagingType: this.constants.full_numbers,
      pageLength: 10,
      ordering: false
    };

    this.form = new FormGroup({
      'firstName': new FormControl(),
      'lastName': new FormControl(),
      'productId': new FormControl('', Validators.compose([Validators.required, Validators.minLength(1)])),
      'customerId': new FormControl('', Validators.compose([Validators.required, Validators.minLength(1)])),
      'GetSaleOrderId': new FormControl(),
      'PartialShipment': new FormControl(),
      'CustomerSupplied': new FormControl(),
      'SalesQuoteDate': new FormControl(),
      'SalesOrderDate': new FormControl(),
      'manufacturerId': new FormControl(new FormControl('', Validators.compose([Validators.required, Validators.minLength(1)])),),
      'manufacturerModelId': new FormControl(new FormControl('', Validators.compose([Validators.required, Validators.minLength(1)])),),
      'Year': new FormControl(),
    });

    this.formSearch = new FormGroup({
      'TCreateUpdate': new FormControl(),
      'ProductSearch': new FormControl(),
      'StatusData': new FormControl(),
      'StatusValue': new FormControl(['0']),
      'IsActive': new FormControl('1'),
      // 'StatusValue': new FormControl(),
      'isSaleOrder': new FormControl(),
    });

    //ankit commented
    //this.getAllCompany(this.companyId);
    //this.getAllProducts(this.companyId);
    //this.getManufacturerList(this.companyId);
    var ordertype = Number($("#ordertype").val());
    //this.getAllCompany_search(this.companyId);
    //this.GetallSaleOrderNo(this.companyId, 0);
    this.SalesQuoteDate = this.datepipe.transform((new Date), 'yyyy-MM-dd');
    this.BackData = this.sharedService.getBackDetails() == null ? "" : this.sharedService.getBackDetails();
    if (this.BackData.BackStatus == true && this.BackData.BackdataSaleOrder == "") {
      this.ProductSearch = "";
      this.SearchControl = true;
      this.StatusData = this.BackData.BackdataStatus;
      this.TCreateUpdate = this.BackData.BackdataType;
      this.StatusValue = this.BackData.BackdataStatus;
      this.ListShow = true;
      this.ListDiv = true;
      this.GetAllOrder(this.BackData.BackdatacompanyId, this.BackData.BackdataStatus);
    }
    if (this.BackData.BackStatus == true && this.BackData.BackdataSaleOrder != "") {
      this.ProductSearch = this.BackData.BackdataSaleOrder;
      this.SearchControl = true;
      this.StatusModel = this.BackData.BackdataStatus;
      this.TCreateUpdate = this.BackData.BackdataType;
      this.ListShow = true;
      this.ListDiv = true;
      this.GetAllOrderBYSO(this.BackData.BackdatacompanyId, this.ProductSearch);
    }
    else {
    }

    //ankit implementaion
      // get company by default set value
      
      var company = this.globalChangeService.getGlobalCompany();
      if(company>0){
        this.value = company;
        this.value_search = company;

        this.getAllProducts(this.value);
        this.GetallSaleOrderNo(this.value, 0);
        this.getManufacturerList(this.value);
        this.GetStatusdropdownlist();
      }
      else{
        this.ErrorModal('Error', 'Please select company.');
      }

      //on change function 
      this.globalChangeService.dropdownChange$.subscribe((data)=>{
        this.onValueChange(data);
        this.onValueChange_search(data);
        this.GetStatusdropdownlist();
      })
  }

  async canDeactivate(): Promise<Promise<boolean> | boolean> {
    if (!this.form.pristine && !this.isSaveChanges) {//this.form.touched && !this.isSaveChanges
      const confirmResult = await this.confirmation.confirm('Confirmation', 'Are you sure you want to leave this page ?', 'Cancel', 'OK', 'lg');
      if (confirmResult === true) {
        return true;
      } else {
        return false;
      }
    }
    else {
      return true;
    }
  }

  SortTableHeader(item: any) {
    if (this.orderList != null) {
      var ret = new Array;
      for (var i = this.orderList.length - 1; i >= 0; i--) {
        ret.push(this.orderList[i]);
      }

      this.orderList = ret;
      var Keys = Object.keys(this.orderList[0]);

      if (Keys.includes(item)) {
        if (this.isBackgroudImage) {
          this.isBackgroudImage = false;
          var element = document.getElementById(item);
          if (element.className === 'sorting1') {
            element.classList.replace('sorting1', 'sorting_asc');
          }
          else if (element.className === 'sorting_asc') {
            element.classList.replace('sorting_asc', 'sorting_desc');
          }
          else {
            element.classList.replace('sorting_desc', 'sorting_asc');
          }
        }
        else {
          this.isBackgroudImage = true;
          var element = document.getElementById(item);
          if (element.className === 'sorting1') {
            element.classList.replace('sorting1', 'sorting_asc');
          }
          else if (element.className === 'sorting_asc') {
            element.classList.replace('sorting_asc', 'sorting_desc');
          }
          else {
            element.classList.replace('sorting_desc', 'sorting_asc');
          }
        }
      }

      const result = Keys.filter(key => key != item);

      if (result) {
        result.forEach(data => {
          var element = document.getElementById(data);
          if (element != null) {
            if (element.className === 'sorting1') {
              element.classList.replace('sorting1', 'sorting1');
            }
            else if (element.className === 'sorting_asc') {
              element.classList.replace('sorting_asc', 'sorting1');
            }
            else {
              element.classList.replace('sorting_desc', 'sorting1');
            }
          }
        });
      }
    }
  }

  getAllProducts(companyId) {
    this.sharedService.getAllProducts(companyId, '', 0, 0).subscribe(
      data => {
        this.data = data;
        if (this.data.dataList != null && this.data.dataList != undefined) {
          if (companyId > 0) {
            this.productList = this.data.dataList;
          }
          else {
            this.selectedProductValue = '';
          }
        }
      })
    this.GetAllCustomer(companyId);

  }

  getAllCompany(companyId) {
    this.companyService.GetDropdownCompanyHierarchyModel(this.companyId).subscribe(
      data => {
        this.data = data;
        if (this.data.dataList != null && this.data.dataList != undefined) {
          this.items = this.data.dataList;
          this.companyList = this.data.dataList;
          // this.companyList.forEach((x) => {
          //   this.userGroupObjects.push(new TreeviewItem(x, false));
          // });
        }
        this.value = Number(companyId);
      });
    this.GetStatusdropdownlist();
  }

  changetype1(event) {
    if (event.target.value == 2) {
      $('#productdropdown').hide();
      this.productDisabled = false;
      $("#orderpart").show();
      $("#reviewbtn").hide();
      this.PartialShipment = this.PartialShipment;
      this.isDisabled = false;
      this.isVehicleRequired = false;
    } else {
      $('#productdropdown').show();
      this.productDisabled = true;
      $("#orderpart").hide();
      $("#reviewbtn").show();
      this.PartialShipment = '';
      this.isDisabled = true;
    }

  }

  GetAllCustomer(companyId) {
    this.customerService.GetAllCustomer(companyId, '').subscribe(
      data => {

        this.data = data;
        if (companyId > 0) {
          // this.customerList =  this.data.dataList.sort((one, two) => (one.customerName < two.customerName ? -1 : 1));
          this.customerList =  this.data.dataList;
        }
        else {
          this.selectedCustomerValue = '';
        }
      }
    )
  }

  GetAllOrder(companyId, statusId) {
    if (companyId == undefined) { companyId = 0; }

    this.loader = true;

    $('#datatable').DataTable().destroy();
    this.dtTrigger.next('');
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      ordering: false,
      //processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        const orderRequest = {
          companyId: companyId,
          isActive: Number(this.IsActive),
          statusIds: this.StatusValue.map(str => parseInt(str, 10)),
          offsetNo: dataTablesParameters.start,
          pageSize: dataTablesParameters.length,
          searchTerm: dataTablesParameters.search.value
        }
        //this.sharedService.GetAllOrder(companyId, statusId, dataTablesParameters.start, dataTablesParameters.length, dataTablesParameters.search.value)
        this.sharedService.GetAllOrder(orderRequest)
          .subscribe(resp => {
            
            this.loader = false;
            let totalRows = null;
            this.data = resp;
            if (this.data.dataList.length > 0) {
              this.orderList = this.data.dataList;
              totalRows = [...new Set(this.orderList.map(item => item.totalRows))];
            }
            else {
              this.orderList = null;
              totalRows = 0;
            }

            callback({
              recordsTotal: totalRows,
              recordsFiltered: totalRows,
              data: []
            });
          });

      }
    };
    this.rerender();
  }

  GetAllOrderBYSO(companyId, searchTerm) {

    this.loader = true;

    $('#datatable').DataTable().destroy();
    this.dtTrigger.next('');
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
      serverSide: true,
      ordering: false,
      //processing: true,
      ajax: (dataTablesParameters: any, callback) => {
        let searchTerm2 = "";
        if (dataTablesParameters.search.value) {
          searchTerm2 = dataTablesParameters.search.value;
        }
        else {
          searchTerm2 = searchTerm;
        }

        this.sharedService.GetAllOrderBYSO(companyId, searchTerm2, dataTablesParameters.start, dataTablesParameters.length)
          .subscribe(resp => {
            this.loader = false;
            let totalRows = null;
            this.data = resp;
            
            if (this.data.dataList.length > 0)
             {
              this.orderList = this.data.dataList;
              totalRows = [...new Set(this.orderList.map(item => item.totalRows))];
            }
            else {
              this.orderList = null;
              totalRows = 0;
            }

            callback({
              recordsTotal: totalRows,
              recordsFiltered: totalRows,
              data: []
            });
          });

      }
    };
    this.rerender();

  }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next('');
    });
  }

  changeDDLProduct(event) {
    const spiltValue = event.split("-");
    this.productId = spiltValue[0];
    const vehicleRequired = spiltValue[1];
    if (vehicleRequired == "true") {
      this.isVehicleRequired = true;
      this.loader = true;
      this.sharedService.GetVehicleDetail(this.productId).subscribe(
        data => {
          this.loader = false;
          this.selectedManufacturerValue = data['dataList'][0].manufacturerId;
          this.CallModelService(this.selectedManufacturerValue);
          this.selectedManufacturerModelValue = data['dataList'][0].modelId;
          this.Year = data['dataList'][0].modelYear;
        });
    }
    else {
      this.isVehicleRequired = false;
    }
    this.productName = event.text;
  }

  changeDDLCustomer(event) {
    this.customerId = event.target.value;
    this.customerName = event.text;
    this.companyId = this.value;
    this.GetallSaleOrderNo(this.companyId, this.customerId);
    this.GetShipment(this.companyId, this.customerId);
  }

  GetShipment(companyId, customerId) {
    customerId = customerId == undefined ? 0 : customerId == "" ? 0 : customerId;
    this.customerService.GetCustomerShipment(companyId, customerId).subscribe(
      data => {
        this.data = data;
        this.PartialShipment = this.data.dataList[0].partialShipment;
      }
    )
  }
  ClearOrder() {
    this.selectedCustomerValue = '';
    this.selectedProductValue = '';
  }

  PreviewDetails() {
    this.isSaveChanges = true;
    this.validation = true;
    if (this.form.controls.customerId.status === this.constants.Invalid ||
      this.form.controls.productId.status === this.constants.Invalid ||
      this.value == 0 ||
      this.value == undefined
      || this.form.controls.SalesQuoteDate.status === this.constants.Invalid
      || this.form.controls.manufacturerId.status === this.constants.Invalid
      || this.form.controls.manufacturerModelId.status === this.constants.Invalid
      || this.form.controls.Year.status === this.constants.Invalid
    ) {
      return;
    }
    let customerId = Number(this.customerId);
    var customermail = this.customerList.filter(x => x.id == customerId);
    let productId = Number(this.productId)
    if (customerId != 0 && customerId != null) {
      const orderDetials = {
        customerId: customerId,
        productId: productId,
        orderId: 0,
        companyId: this.value,
        customerName: $(".custName option:selected").text(),
        productName: $(".prodName option:selected").text(),
        Type: "product",
        ContactEmail: customermail[0].cEmail,
        isSaleOrder: this.isSaleOrder,
        ChangeSaleOrdervalue: this.GetChangeValue,
        manufacture: this.selectedManufacturerValue,
        model: this.selectedManufacturerModelValue,
        year: String(this.Year),
        orderDate: this.SalesOrderDate,
        quoteDate: this.SalesQuoteDate,
        Partial: this.PartialShipment,
        Supplied: this.CustomerSupplied,
      }
      this.sessionOrderDetials = localStorage.setItem("orderDetials", JSON.stringify(orderDetials));

      this.sharedService.setOrderDetails(orderDetials);
      this.router.navigate(['/orderView/', productId]);
    }
  }


  PreviewChangeOrder() {

    let customerId = Number(this.customerId);
    var customermail = this.customerList.filter(x => x.id == customerId);
    let productId = Number(this.productId)
    if (customerId != 0 && customerId != null) {
      const orderDetials = {
        customerId: customerId,
        productId: productId,
        orderId: 0,
        companyId: this.value,
        customerName: $(".custName option:selected").text(),
        productName: this.ChangeOrdrProductName,
        Type: "product",
        ContactEmail: customermail[0].cEmail,
        isSaleOrder: this.isSaleOrder,
        ChangeSaleOrdervalue: this.GetChangeValue,
        ChangeOrderProductId: this.ChangeOrdrProductId,
        Descrtiptiondata: this.descriptiondata
        // ChangeOrdrProductName:this.ChangeOrdrProductName
      }
      this.sessionOrderDetials = localStorage.setItem("orderDetials", JSON.stringify(orderDetials));
      this.sharedService.setOrderDetails(orderDetials);
      this.router.navigate(['/orderView/', 18])

    }
  }

  PreviewOrder(id, partid, type) {
    this.isSaveChanges = true;
    let orderId = Number(id);
    let productId = Number(this.productId);
    let customerId = Number(this.customerId);
    if (type == 'part_so_open') { this.value = this.companyId }
    if (partid == 2) {
      if (orderId != 0 && orderId != null) {
        const orderDetials = {
          customerId: customerId,
          companyId: this.value,
          orderId: id,
          customerName: null,
          productName: null,
          Type: "order",
          isSaleOrder: this.isSaleOrder
        }
        this.sessionOrderDetials = localStorage.setItem("orderDetials", JSON.stringify(orderDetials));
        sessionStorage.setItem('Qmessage','')
        this.sharedService.setOrderDetails(orderDetials);
        if (type) {
          this.router.navigate(['/orderpartdetails/' + orderId + '/' + type]);
        }
        else {
          this.router.navigate(['/orderpartdetails/', orderId]);
        }
      }
    }
    else {

      if (orderId != 0 && orderId != null) {
        const orderDetials = {
          customerId: customerId,
          companyId: this.value,
          productId: 0,
          orderId: id,
          customerName: null,
          productName: null,
          Type: "order",
          isSaleOrder: this.isSaleOrder
        }

        this.sessionOrderDetials = localStorage.setItem("orderDetials", JSON.stringify(orderDetials));
        this.sharedService.setOrderDetails(orderDetials);
        if (type) {
          this.router.navigate(['/orderView/' + orderId + '/' + type]);
        }
        else {
          this.router.navigate(['/orderView/', orderId]);
        }
      }
    }
    if (this.value_search == 0) {
      const ExitDetailsData = {
        EXitcompanyId: this.value,
        EXitType: this.TCreateUpdate,
        ExitSaleOrder: this.ProductSearch,
        ExitStatus: this.StatusValue,
      }
      this.sessionExitDetials = localStorage.setItem("exitdetails", JSON.stringify(ExitDetailsData));
      this.sharedService.setExitDetails(ExitDetailsData);
    }
    else {
      const ExitDetailsData = {
        EXitcompanyId: this.value_search,
        EXitType: this.TCreateUpdate,
        ExitSaleOrder: this.ProductSearch,
        ExitStatus: this.StatusValue,
      }
      this.sessionExitDetials = localStorage.setItem("exitdetails", JSON.stringify(ExitDetailsData));
      this.sharedService.setExitDetails(ExitDetailsData);
    }
  }

  EditOrder(id, partid, type, companyId) {
    let orderId = Number(id);
    let productId = Number(this.productId);
    let customerId = Number(this.customerId);
    if (partid == 2) {
      if (orderId != 0 && orderId != null) {
        const orderDetials = {
          customerId: customerId,
          companyId: companyId,
          orderId: id,
          customerName: null,
          productName: null,
          Type: "order",
          isSaleOrder: this.isSaleOrder
        }
        this.sessionOrderDetials = localStorage.setItem("orderDetials", JSON.stringify(orderDetials));
        sessionStorage.setItem('Qmessage','')
        this.sharedService.setOrderDetails(orderDetials);
        if (type) {
          this.router.navigate(['/orderpartdetails/' + orderId + '/' + type]);
        }
        else {
          this.router.navigate(['/orderpartdetails/', orderId]);
        }
      }
    }
    else {

      if (orderId != 0 && orderId != null) {
        const orderDetials = {
          customerId: customerId,
          companyId: companyId,
          productId: 0,
          orderId: id,
          customerName: null,
          productName: null,
          Type: "order",
          isSaleOrder: this.isSaleOrder
        }

        this.sessionOrderDetials = localStorage.setItem("orderDetials", JSON.stringify(orderDetials));
        this.sharedService.setOrderDetails(orderDetials);
        if (type) {
          this.router.navigate(['/orderView/' + orderId + '/' + type]);
        }
        else {
          this.router.navigate(['/orderView/', orderId]);
        }
      }
    }
    const ExitDetailsData = {
      EXitcompanyId: companyId,
      EXitType: this.TCreateUpdate,
      ExitSaleOrder: this.ProductSearch,
      ExitStatus: this.StatusValue,
    }
    this.sessionExitDetials = localStorage.setItem("exitdetails", JSON.stringify(ExitDetailsData));
    this.sharedService.setExitDetails(ExitDetailsData);

  }



  ConfirmSave(content) {
    this.validation = true;
    if (this.form.controls.customerId.status === this.constants.Invalid ||
      this.form.controls.productId.status === this.constants.Invalid
    ) {
      return;
    }
    this.modalService.open(content, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
    this.modelDetail = 'Do you want to Place the Order.';
  }

  deletePopup(item, content) {
    this.deletedItem = '';
    this.modalService.open(content, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
    this.deleteDetail = 'Are you sure, you want to delete Order?';
    this.deletedItem = item;
  }

  DeleteOrder(item) {
    let statusIds = this.StatusValue;
    this.loader = true;
    this.sharedService.DeleteOrder(item.id).subscribe(
      data => {
        this.data = data;
        switch (data.statusCode) {
          case 200:
            $("#delete_success").show();
            this.GetAllOrder(this.value_search, statusIds);
            break;
          case 400:
            $("#wrong_msg").show();
            break;
          default:
            $("#wrong_msg").show();

        }
        this.loader = false;
      });
  }

  GetStatusdropdownlist() {
    this.loader = true;
    this.sharedService.GetStatusdropdownlist().subscribe(
      data => {
        this.data = data;
        // this.statuslist = this.data.dataList;
        this.activestatuslist = this.data.dataList.filter(x=>x.id!=5 && x.id!=17 && x.id!=3);
        this.inactivestatuslist = this.data.dataList.filter(x=>x.id==5 || x.id==17 || x.id==3);
        this.loader = false;
      }
    )
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  SetSettingCSS(settingCSS, logoImagePath) {
    if (settingCSS != null) {
      let headerCss = document.getElementsByClassName(this.constants.CssHealerClass) as HTMLCollectionOf<HTMLElement>;
      if (headerCss.length != 0) {
        headerCss[0].style.backgroundColor = settingCSS.split(',')[0];
      }
      let saveButtonCss = document.getElementsByClassName(this.constants.CssSaveBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (saveButtonCss.length != 0) {
        saveButtonCss[0].style.backgroundColor = settingCSS.split(',')[1];
        $(".blue-bg").css("background", settingCSS.split(',')[1]);

      }
      let cencelButtonCss = document.getElementsByClassName(this.constants.CssCancelBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (cencelButtonCss.length != 0) {
        cencelButtonCss[0].style.backgroundColor = settingCSS.split(',')[2];
        $(".darkred-bg").css("background", settingCSS.split(',')[2]);
      }
    }

    if (logoImagePath != null && logoImagePath != "") {
      (document.getElementById('hrdlogoImage') as HTMLImageElement).src = logoImagePath;
    }
  }

  onValueChange(value: any): void {
    this.value = value;
    this.getAllProducts(this.value);
    this.GetallSaleOrderNo(this.value, 0);
    this.getManufacturerList(this.value);

  }

  GetallSaleOrderNo(companyId, customerId) {

    customerId = customerId == undefined ? 0 : customerId == "" ? 0 : customerId;
    // ordertype=ordertype==undefined?0:ordertype==""?0:ordertype;
    this.sharedService.GetAllSaleorderNumber(companyId, customerId).subscribe(
      data => {
        this.data = data;
        this.getSoList = this.data.dataList;
      }
    )
  }

  // GetStatus($event) {
  //   this.StatusValue = $event.target.value;
  // }

  GetStatus($event) {
    this.StatusValue = $event.value;
  }

  GetIsActiveStatus($event) {
    this.IsActive = $event.value;
  }

  PreviewPartDetails() {

    this.isSaveChanges = true;
    this.validation = true;
    if (this.form.controls.customerId.status === this.constants.Invalid
      || this.value === undefined
      || this.value == 0
      || this.form.controls.SalesQuoteDate.status === this.constants.Invalid) {
      return;
    }

    let customerId = Number(this.customerId);
    var customermail = this.customerList.filter(x => x.id == customerId);
    if (customerId != 0 && customerId != null) {
      const orderDetials = {
        customerId: customerId,
        orderId: 0,
        customerName: $(".custName option:selected").text(),
        companyId: this.value,
        Type: "part",
        OrderTypeId: 2,
        ContactEmail: customermail[0].cEmail,
        isSaleOrder: this.isSaleOrder,
        ChangeSaleOrdervalue: this.GetChangeValue,
        orderDate: this.SalesOrderDate,
        quoteDate: this.SalesQuoteDate, 
        Partial: this.PartialShipment,
      }
      this.sessionOrderDetials = localStorage.setItem("orderDetials", JSON.stringify(orderDetials));
      sessionStorage.setItem('Qmessage','')
      this.sharedService.setOrderDetails(orderDetials);
      this.router.navigate(['/orderpartdetails/', 12]);
    }
  }



  ChangeTCreateUpdate(event) {
    this.TCreateUpdate = event.target.value;
    if (this.TCreateUpdate == "1") {
      this.SearchControl = false;
      this.ListShow = false;
      this.ListDiv = false;
      // this.IsChangeOrder = true;
      $("#productdropdown").show();
      this.ClearOrder();

    }
    else if (this.TCreateUpdate == "0") {
      this.SearchControl = true;
      this.ProductSearch = "";
      //this.IsChangeOrder = false;
    }
  }
  searchorder() {
    
    this.SearchType = false;
    if (!this.ProductSearch || this.value_search == 0) {
      this.SearchValidation = true;
      return;
    }
    this.ListShow = true;
    this.ListDiv = true;
    if(this.value_search>0){
      this.GetAllOrderBYSO(this.value_search, this.ProductSearch);
    }else{
      this.ErrorModal('Error', 'Please select company.');
    }
  }
  AllStatus() {
    this.ProductSearch = '';
    if (this.value_search == 0) {
      this.SearchValidation = true;
      return;
    }
    let Statesval = this.StatusValue;
    this.SearchValidation = false;
    this.ListShow = true;
    this.ListDiv = true;
    this.SearchType = true;
    if(this.value_search>0){
      this.GetAllOrder(this.value_search, Statesval);
    }else{
      this.ErrorModal('Error', 'Please select company.');
    }
  }

  ListDivShowHide() {

    if (this.ListDiv == true) {
      this.ListDiv = false;
    }
    else if (this.ListDiv == false) {
      this.ListDiv = true;
    }
  }

  getAllCompany_search(companyId) {
    this.companyService.GetDropdownCompanyHierarchyModel(this.companyId).subscribe(
      data => {
        this.data = data;
        this.items_search = this.data.dataList;
        this.companyList_search = this.data.dataList;
        // this.companyList_search.forEach((x) => {
        //   this.userGroupObjects_search.push(new TreeviewItem(x, false));
        // });
        this.value_search = Number(companyId);
      });
  }
  onValueChange_search(value: any): void {
    this.value_search = value;
  }
  ngAfterViewInit(): void {
    this.dtTrigger.next('');
  }

  addsuccess() {
    $("#add_success").hide();
  }
  updateitem() {
    $("#add_update").hide();
  }
  deleteitem() {
    $("#delete_success").hide();
  }
  msgitem() {
    $("#wrong_msg").hide();
  }

  ChangeSaleOrder(event: any) {
    this.isSaleOrder = event.currentTarget.checked;
    $("#orderpart").hide();
    $("#reviewbtn").hide();
    this.isVehicleRequired = false;
    this.productDisabled = true;

  }


  ChangeorderData(event) {
    this.GetChangeValue = event.target.value;
  }

  GetSaleItemId() {

    let saleorderNo = this.GetChangeValue;
    this.validation = true;
    if (this.form.controls.customerId.status === this.constants.Invalid ||
      this.value == 0 ||
      this.value == undefined ||
      this.GetSaleOrderId == null || this.GetSaleOrderId == undefined || this.GetSaleOrderId == ""
    ) {
      return;
    }
    if (saleorderNo != null) {
      let ordertype = this.getSoList.find(x => x.customerPONumber == saleorderNo).orderTypeId;
      if (ordertype == 1) {
        this.ChangeOrdrProductId = this.getSoList.find(x => x.customerPONumber == saleorderNo).productId;
        this.ChangeOrdrProductName = this.productList.find(x => x.id == this.ChangeOrdrProductId).name;

        this.descriptiondata = this.productList.find(x => x.id == this.ChangeOrdrProductId).description;

        this.PreviewChangeOrder();

      }
      else if (ordertype == 2) {

        this.PreviewPartDetails();
      }

    }
    else {

    }
  }
  getManufacturerList(cid) {
    this.sharedService.GetManufacturerList(cid).subscribe(
      data => {
        this.data = data;
        if (this.data.dataList != null && this.data.dataList != undefined) {
          if (cid > 0) {
            this.manufacturerList = this.data.dataList;
          }
          else {
            this.selectedManufacturerValue = '';
          }
        }
      }
    )
  }

  onManufacturerChange(e): void {
    var manufactrerModel = e.target.value;
    this.CallModelService(manufactrerModel);
  }
  CallModelService(manufactrerModel: any) {
    this.sharedService.getVehicleList(manufactrerModel).subscribe(
      data => {
        this.data = data;
        if (this.data.statusCode == 200) {
          if (this.data.dataList != null && this.data.dataList != undefined) {
            if (manufactrerModel > 0) {
              this.manufacturerModelList = this.data.dataList;
            }
            else {
              this.selectedManufacturerModelValue = '';
            }
          }
        }
        else {
          this.selectedManufacturerModelValue = '';
          this.manufacturerModelList = [];
        }

      }
    )
  }
  CopyOrder(id: any, cid: any) {
    this.copyValidation = false;
    this.prop = "";
    this.salesProp = `How many Sales Orders would you like to create?`;
    this.copyOrderId = id;
    this.copyCompanyId = cid;
    $('#salesorder').show();
  }
  closeSalesorder() {
    $('#salesorder').hide();
  }
  CopyCreateOrder(id, cid) {
    if (this.CreateOrder == "" || this.CreateOrder == null || this.CreateOrder == undefined) {
      this.copyValidation = true;
      return;
    }
    else {
      if (this.CreateOrder == "0") {
        this.prop = "You can not enter 0";
      } else {
        this.prop = "";
        this.copyValidation = false;
        this.copyOrderId = id;
        this.copyCompanyId = cid;
        $('#confirmation').show();
      }
    }
  }
  Create(id: any, cid: any) {
    this.loader = true;
    this.sharedService.GetCopySalesOrder(id, this.CreateOrder, cid).subscribe(
      data => {
        this.data = data;
        this.loader = false;
        if (this.data.statusCode == 200) {
          if (this.data.dataList != null && this.data.dataList != undefined) {
            this.copySerialArr = [];
            this.copySerialArr = this.data.dataList;
            $('#confirmation').hide();
            $('#salesorder').hide();
            this.CreateOrder = "";
            this.copyOrderId = "";
            $('#copy_success').show();
            let Statesval = this.StatusValue;
            this.GetAllOrder(this.value_search, Statesval);
            this.copyValidation = false;
          }
        }
        else {
          $('#confirmation').hide();
          $('#wrong_msg').show();
          this.CreateOrder = "";
        }

      }
    )
  }
  closeConfirmation() {
    $('#confirmation').hide();
  }
  closeCopySuccess() {
    $('#copy_success').hide();
  }

  //ankit implemenation
  ErrorModal(title:string, message:string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }
}


