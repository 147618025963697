<section class="content-section pt-2 ws-nobreak1">
    <div class="loading" *ngIf="loader">Loading&#8230;</div>

    <div col-sm-12>
        <div class="row">
            <div class="col-6 mb-2 d-flex align-items-baseline text-xs-left">
                <div class="page-title">
                    <div class="bread-crumb">
                        <a [routerLink]="['/adminDashboard/']">Home</a>
                        <span class="mx-1">-</span>
                        <span class="active">POs to create SOs</span>
                        <div *ngIf="permissionMessage!=''" class='btn-danger text-center'>{{permissionMessage}} </div>
                    </div>
                </div>
            </div>
            <div class="col-6 mb-2 text-right align-items-baseline">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="white-box1">
                <div class="card-desc1">
                    <form [formGroup]="formSearch">
                        <div class="row mt-2">
                            <!-- <div class="col-lg-3 col-md-12 mt-lg-0 mt-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp required">
                                        <label class="customLabel">Company</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10 border-none"
                                        [ngClass]="((value_search==0) && (SearchValidation)) ? 'validation_border': ''">
                                        <ngx-dropdown-treeview-select class="dropdown-company customInput-dropdown"
                                            [items]="userGroupObjects_search" [(value)]="value_search"
                                            (valueChange)="onValueChange_search($event)">
                                        </ngx-dropdown-treeview-select>
                                    </div>
                                </div>
                            </div> -->

                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-1" style="display: none;">
                                <div class="row">
                                    <div class="col-lg-3 col-md-2 trp required">
                                        <label class="customLabel">Vendor</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <select [(ngModel)]="vendorSearch" class="customInput"
                                            formControlName='vendorSearchDDL'
                                            [ngClass]="((!vendorSearch) && (SearchValidation)) ? 'validation_border': ''">
                                            <option value='0'>All</option>
                                            <option *ngFor="let item of vendorSearchListDDL" [value]="item.id">
                                                {{item.vendorName}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                                <div class="row">
                                    <div class="col-lg-3 col-md-2 trp">
                                        <label class="customLabel">Part Name</label>
                                    </div>
                                    <div class="col-lg-9 col-md-10">
                                        <input type="text" placeholder="Search Part Name" class="customInput"
                                             formControlName="partSearch" autocomplete="off">
                                    </div>
                                </div>
                            </div>  -->

                            <div class="col-lg-1 col-md-6 mt-lg-0 mt-1">
                                <div class="row">
                                    <div class="col-lg-12 col-md-2 trp">
                                        <i class="fas fa-search cpointer" (click)="searchInv()"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <hr>

            <div class="white-box1" *ngIf="rolePermissionsList && rolePermissionsList[0].isView"
                [style.display]="ListShow==true ? 'block' : 'none'">
                <div class="white-box-title1">
                    <div class="customHeading">
                        POs to create SOs<i class="fa fa-angle-{{ListAngle}} cpointer ml-2" aria-hidden="true"
                            (click)="ListDivShowHide()"></i>
                    </div>
                </div>
                <div class="card-desc1 mt-1" [style.display]="ListDiv==true ? 'block' : 'none'">
                    <form [formGroup]="form">
                        <div class="col-sm-12 p-0">
                            <table class="row-border hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                                <thead>
                                    <tr>
                                        <th class="coloumn-hide"></th>
                                        <th style="font-weight:bold; ">SO#</th>
                                        <th style="font-weight:bold; "> Vendor Name</th>
                                        <th style="font-weight:bold; ">Vendor Part Name</th>
                                        <th style="font-weight:bold; ">Customer Part Name</th>
                                        <th style="font-weight:bold; ">Qty in Inventory</th>
                                        <th style="font-weight:bold; ">Qty on SO</th>
                                        <th style="font-weight:bold; ">Qty to Fulfill</th>
                                        <th style="font-weight:bold; ">Qty to Order</th>
                                        <th style="font-weight:bold; ">Est Cost Per Part</th>
                                        <th style="font-weight:bold; ">Total Cost</th>
                                        <th style="font-weight:bold; ">Check to Create</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let data of InvList; let i = index">
                                        <td class="coloumn-hide">{{data.id}}</td>
                                        <td>{{data.customerPONumber}}</td>
                                        <td>
                                            <select class="customInput"
                                                (change)="ChangeddlVendor($event.target.value, i)">
                                                <option value="">Select</option>
                                                <option *ngFor="let item of vendorListDDL" [value]="item.id" [selected]="item.id==data.vendorId">
                                                    {{item.vendorName}}
                                                </option>
                                            </select>
                                        </td>
                                        <td>
                                            <div class="d-flex justify-content-center">
                                                <div class="position-relative fix-width-tooltip">
                                                    <span class="tooltipWrap">
                                                        {{!data.vendorPartName ?
                                                        "N/A" : data.vendorPartName}}</span>
                                                    <span class="tooltips">{{!data.vendorPartName ? "N/A" :
                                                        data.vendorPartName}}</span>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <div class="d-flex justify-content-center">
                                                <div class="position-relative fix-width-tooltip">
                                                    <span class="tooltipWrap">
                                                        {{!data.name ?
                                                        "N/A" : data.name}}</span>
                                                    <span class="tooltips">{{!data.name ? "N/A" :
                                                        data.name}}</span>
                                                </div>
                                            </div>
                                        </td>

                                        <td>{{data.invQty}}</td>
                                        <td>{{data.quantity}}</td>
                                        <td>{{data.qtyToFulFill}}</td>

                                        <td>
                                            <ng-container *ngIf="data.rowEdit==false">
                                                <input type="text" class="form-control" [value]="data.assignQty"
                                                    (click)="openEditAssQty(i)" readonly>
                                            </ng-container>
                                            <ng-container *ngIf="data.rowEdit==true">
                                                <input type="text" class="form-control" [(ngModel)]="UpdatedAssignQty"
                                                    formControlName='UpdatedAssignQty' (blur)="quantityBlur(i)">
                                            </ng-container>
                                        </td>

                                        <td>{{data.vendorPartCost|currency}}</td>
                                        <td>{{data.vendorPartCost * data.assignQty |currency}}</td>

                                        <td style="text-align:center !important;">
                                            <input class="p_relative custom_checkbox" formControlName='chkInv'
                                                type="checkbox" (click)="chngIsInv($event.target.checked, data.id)"
                                                [checked]='data.isChecked'>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <div class="submit-from text-right">
                            <button class="btn blue-bg round-btn" (click)="SaveConfirm(confirmSave)" [disabled]="disableButton==false">Create POs</button>
                        </div>
                    </form>
                </div>
            </div>

        </div>
    </div>

</section>

<ng-template #confirmSave let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg sweet-heading"></h4>
    <button class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body">
        <div class="thank-you-pop">
            <img src="./assets/image/popup-alert.png" alt="">
            <h1>Confirmation</h1>
            <p class="mb-0">Are you sure you want to save?</p>
        </div>
    </div>
    <div class="modal-footer sweet-footer text-center">
        <button type="button" class="btn green-bg round-btn m-0 mr-1"
            (click)="SaveAssignInv(); c('Close click')">Yes</button>
        <button type="button" class="btn red-bg round-btn m-0" (click)="c('Close click')">No</button>
    </div>
</ng-template>

<div class="modal" id="add_update">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="updateitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Update Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="updateitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>