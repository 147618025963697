import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ConstantsService } from '../constants.service';
import { SharedService } from 'src/app/services/shared.service';
import { GlobalChangeService } from '../services/global-change.service';
import { CompanyService } from '../services/company/company.service';

interface Company {
  value: number;
  text: string;
  isActive?: boolean;
  children?: Company[];
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {
  rolePermissionsList: any;
  permissionMessage: any;
  id = 24;
  isSideBarActive: boolean = false;
  arrow: boolean = false;
  isSideBarActiveCompany = false;
  isSideBarActiveCustomPart = false;
  isSideBarActivePO = false;
  isSideBarActiveSR = false;
  isSideBarActiveOrder = false;
  isSideBarActiveShipment = false;
  isSideBarActiveOM = false;
  isSideBarActiveInventory= false;
  arrowCompany = false;
  isSideBarActiveRMA = false;
  isSideBarActiveReport = false;
  arrowRMA = false;
  arrowReport = false;
  arrowCustomPart = false;
  arrowPO = false;
  arrowSR = false;
  arrowShipment = false;
  arrowOM = false;
  companyId: any;
  settingImageLogo: any;
  @Input() subMenuState;
  isSideBarActiveUtility = false;
  isSideBarDashboard = false;
  isSideBarCategories = false;
  isSideBarLocationDetails = false;
  isSideBarEmail = false;
  isSideBarProduction = false;
  isSideBarActivePurchase = false;
  isSideBarApiManagement = false;
  arrowUtility = false;
  arrowdashboard = false;
  arrowCategories = false;
  arrowLocationDetails = false;
  arrowApiManagement = false;
  arrowEmail = false;
  arrowProduction = false;
  arrowPurchase = false;
  ChkPO = true;
  ChKPO1: boolean = false;
  sideBar: any;
  userId: any;
  data: any;
  arrowInventory= false;
  isSideBarActiveInventoryReport: boolean=false;
  arrowInventoryReport: boolean=false;
  CompanyObj: Company[] = [];
  SelectedCompanyValue: any;
 

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private companyService: CompanyService,
    private constants: ConstantsService,
    public sharedService: SharedService,
    private globalChangeService: GlobalChangeService,
  ) {
    if(Number(localStorage.getItem('companyId'))==0){
      this.SelectedCompanyValue = Number(localStorage.getItem('GlobalSelectedCompanyId'));
    }else{
      localStorage.setItem('GlobalSelectedCompanyId',localStorage.getItem('companyId'));
      this.SelectedCompanyValue = Number(localStorage.getItem('GlobalSelectedCompanyId'));
    }
   }

  opened: boolean;
  showMenu = true;

  toggleMenu() {
    this.showMenu = !this.showMenu;
  }

  ngOnChanges() {
    this.showMenu = this.subMenuState;
  }

  ngOnInit(): void {
    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);
    }
    this.settingImageLogo = localStorage.getItem('settingImageLogo');
    if (this.settingImageLogo != null) {
      this.settingImageLogo = localStorage.getItem('settingImageLogo');
    }
    else {
      this.settingImageLogo = "/assets/image/logo.jpg";
    }

    this.rolePermissionsList = JSON.parse(localStorage.getItem('rolePermissionsList'));
    if (this.rolePermissionsList) {
      this.rolePermissionsList = this.rolePermissionsList.filter(x => x.pageId == 11);
    }

    this.permissionMessage = '';
    if (this.rolePermissionsList != null && this.rolePermissionsList != undefined) {
      if (!this.rolePermissionsList[0].isView) {
        this.permissionMessage += 'view';
      } if (!this.rolePermissionsList[0].isInsert) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'add';
      } if (!this.rolePermissionsList[0].isUpdate) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'edit';
      } if (!this.rolePermissionsList[0].isDelete) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'delete';
      }
    }
    if (this.permissionMessage != '') {
      this.permissionMessage = 'You do not have access to ' + this.permissionMessage + '. Please contact to administrator!';
    }
    else {
      this.companyId = localStorage.getItem(this.constants.companyId);
      this.getSetting();
    }

    this.getAllCompany(localStorage.getItem('companyId'));
  
    if(Number(localStorage.getItem('companyId'))>0){
      this.SelectedCompanyValue = Number(localStorage.getItem('companyId'));
    }
  
  }

  getSetting() {

    this.userId = localStorage.getItem(this.constants.userId);
    this.companyId = localStorage.getItem(this.constants.companyId);
    this.companyService.GetSettingDetails(this.userId).subscribe(
      data => {
        if (data == null || data == undefined) {
          this.ChKPO1 = false;
        }
        else {
          this.data = data;
          if (this.data.data.statusCode == 403) {
            this.ChKPO1 = false;
          }
          else {
            if (this.companyId > 0) {
              this.ChKPO1 = this.data.data.isPo;
            }
            else {
              this.ChKPO1 = true;
            }
          }
        }
      });
  }

  togglesideBar() {
    this.isSideBarActive = !this.isSideBarActive;
    this.arrow = !this.arrow;

  }

  togglesideBarCompany() {
    this.isSideBarActiveCompany = !this.isSideBarActiveCompany;
    this.arrowCompany = !this.arrowCompany;
  }

  togglesideBarCustompart() {
    this.isSideBarActiveCustomPart = !this.isSideBarActiveCustomPart;
    this.arrowCustomPart = !this.arrowCustomPart;
  }

  togglesideBarPurchseOrder() {

    this.isSideBarActivePO = !this.isSideBarActivePO;
    this.arrowPO = !this.arrowPO;
  }

  toggleSippingReceiving(){
    this.isSideBarActiveSR = !this.isSideBarActiveSR;
    this.arrowSR = !this.arrowSR;
  }
  
  togglesideBarOrder() {
    
    this.isSideBarActiveOrder = !this.isSideBarActiveOrder;
    this.arrowPO = !this.arrowPO;
  }
  togglesideBarShipment() {
    
    this.isSideBarActiveShipment = !this.isSideBarActiveShipment;
    this.arrowShipment = !this.arrowShipment;
  }

  togglesideBarOperationsManagement() {
    this.isSideBarActiveOM = !this.isSideBarActiveOM;
    this.arrowOM = !this.arrowOM;
  }

  togglesideBarInventory() {
    this.isSideBarActiveInventory = !this.isSideBarActiveInventory;
    this.arrowInventory = !this.arrowInventory;
  }

  togglesideBarRMA() {
    this.isSideBarActiveRMA = !this.isSideBarActiveRMA;
    this.arrowRMA = !this.arrowRMA;
  }
  togglesideBarReport()
  {
    this.isSideBarActiveReport = !this.isSideBarActiveReport;
    this.arrowReport = !this.arrowReport;
  }
  togglesideBarInventoryReport()
  {
    this.isSideBarActiveInventoryReport = !this.isSideBarActiveInventoryReport;
    this.arrowInventoryReport = !this.arrowInventoryReport;
  }

  togglesideBarUtility() {
    this.ChkPO = this.sharedService.getSideBar();
    this.isSideBarActiveUtility = !this.isSideBarActiveUtility;
    this.arrowUtility = !this.arrowUtility;
  }

  togglesidedashBoard() {
    this.isSideBarDashboard = !this.isSideBarDashboard;
    this.arrowdashboard = !this.arrowdashboard;
  }

  togglesideCategories() {
    this.isSideBarCategories = !this.isSideBarCategories;
    this.arrowCategories = !this.arrowCategories;
  }

  togglesideLocationDetails() {
    this.isSideBarLocationDetails = !this.isSideBarLocationDetails;
    this.arrowLocationDetails = !this.arrowLocationDetails;
  }

  togglesideApiManagement() {
    this.isSideBarApiManagement = !this.isSideBarApiManagement;
    this.arrowApiManagement = !this.arrowApiManagement;
  }

  togglesideEmail() {
    this.isSideBarEmail = !this.isSideBarEmail;
    this.arrowEmail = !this.arrowEmail;
  }

  togglesideProductSetUp() {
    this.isSideBarProduction = !this.isSideBarProduction;
    this.arrowProduction = !this.arrowProduction;
  }

  mbview() {
    $("body").removeClass("menu_collapsed");
      this.sharedService.purchaseLoadedComponent.next(0);
       this.sharedService.IndividualpartLoadedComponent.next(0);
      this.sharedService.custompartLoadedComponent.next(0);
      this.sharedService.VendorLoadedComponent.next(0);
      this.sharedService.CustomerLoadedComponent.next(0);
      this.sharedService.isChangeValue.next(true);
  }

  //ankit implementation start
  getAllCompany(companyId) {
    if (companyId == undefined) { companyId = 0; }
    this.companyService.GetDropdownCompanyHierarchyModel(this.companyId).subscribe(
      data => {
        this.data = data;
        this.CompanyObj = this.data.dataList;
      });
  }

  companyChangeValue(value){
    this.SelectedCompanyValue = value;
    //user global company change service
    this.globalChangeService.emitDropdownChange(this.SelectedCompanyValue);
  }
  tog() {
    $('body').toggleClass("menu_collapsed");
  }
}


