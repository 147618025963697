<section *ngIf="!IsScan" class="content-section-1 purchaseOrder " id="new-layout" style="background-color:#f4f3f7;" [readonly]="masterTrackingNo != '' && masterTrackingNo != undefined && masterTrackingNo != null">
    <div class="loading" *ngIf="loader">Loading&#8230;</div>
    <!-- -----------------top bredcumps---------------- -->
    <div class="cstmbreadcum paddingmng-right">
        <div class="page_title_cstms">
            <a routerLink="/shipment" class="backbtns" *ngIf="showHeader" (click)="showList()" readonly="">
                <mat-icon>keyboard_arrow_left</mat-icon>
                <span>Back to List</span>
            </a>
            <ul class="link_current">
                <li>
                    <a routerLink="/adminDashboard">Home</a>
                </li>
                <li>
                    <span>{{detailOfShipping ? 'Shipping':'Shipping List'}}</span>
                </li>
            </ul>
        </div>
        <div *ngIf="permissionMessage!=''" class='btn-danger'>{{permissionMessage}} </div>
        <div class="top-button" *ngIf="showHeader">
            <button class="cancel-button" (click)="Cancel()">
                <span>Cancel</span>
            </button>
            <button class="save-button" (click)="SaveUpdateShippedCartoon(false)">
                <span>Save</span>
            </button>

        </div>
    </div>
    <div class="container-fluid" style="margin-top: 70px;">
        <div class="row">
            <div class=" col-xl-11 col-lg-12 col-md-12 col-12 change-column-width">
                <div class="white-box1 cust-label">
                    <div class="card-desc1">
                        <form [formGroup]="formSearch">
                            <div class="row detailSection">
                                <div class="col-md-12 mt-3">
                                    <div class="row justify-content-between align-items-center ipad-search"
                                        *ngIf="hideShow">
                                        <div class="col-xl-6 col-lg-12 col-md-12 col-12 order-change-filter"
                                            [style.display]="RecOrderListShow==true ? 'block' : 'none'">
                                            <div class="cstm-radiosearch">
                                                <span class="cstmtlt_ss">Status Filter :</span>
                                                <div class="filter_cstm_radio" (change)="onItemChange($event)">
                                                    <input type="radio" name="status_filter" id="allopen" checked
                                                        *ngIf="isPO">
                                                    <label for="allopen" *ngIf="isPO">
                                                        <mat-icon>check</mat-icon>
                                                        <span>Ready To Ship</span>
                                                    </label>
                                                    <input type="radio" name="status_filter" id="all_open" checked
                                                        *ngIf="!isPO">
                                                    <label for="all_open" *ngIf="!isPO">
                                                        <mat-icon>check</mat-icon>
                                                        <span>Open</span>
                                                    </label>
                                                    <input type="radio" name="status_filter" id="shipped_radio">
                                                    <label for="shipped_radio">
                                                        <mat-icon>check</mat-icon>
                                                        <span>Shipped</span>
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-xl-6 col-lg-12 col-md-12 col-12"
                                            [style.display]="RecOrderListShow==true ? 'block' : 'none'">
                                            <div class="search_filter-alltype">
                                                <select name="" id="" [(ngModel)]="selectedCustomerSearch"
                                                    class="customInput" formControlName='CustomerSearchDDL'
                                                    [ngClass]="((!selectedCustomerSearch) && (SearchDDLValidation)) ? 'validation_border': ''">
                                                    <option value='0'>All</option>
                                                    <option *ngFor="let item of customerList" [value]="item.id">
                                                        {{item.customerName}}
                                                    </option>
                                                </select>
                                                <input type="text" class="searchalltype" (keyup)="applyFilter($event)"
                                                    #input placeholder="Search by Customer Name or Sales Order number">
                                                <button class="btnsearch_alltype" type="button">
                                                    <mat-icon (click)="SearchByDropDown()">search</mat-icon>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div id="main_check" *ngIf="(!isPO || isPO == undefined) && radioTarget == 'shipped_radio'">
                    <mat-checkbox (change)="$event ? masterToggle() : null"
                        [checked]="selection.hasValue()"
                        [indeterminate]="selection.hasValue() && !isAllSelected()">
                    </mat-checkbox>
                    <span class="btn btn-link" *ngIf="!detailOfShipping" (click)="MarkCompleted()"> Mark as Complete</span>
                </div>
                <div class="white-box1 mt-12" [style.display]="RecOrderListShow==true ? 'block' : 'none'">
                    <div class="card-desc example-container cstm_scrollbar_theme company-table"
                        [style.display]="ListDiv==true ? 'block' : 'none'">
                        <table mat-table matSort [dataSource]="salesOrderDetail"
                            class="mat-elevation-z8 bxshadow-none mb-0 text-left bg-header">
                            <ng-container matColumnDef="menu">
                                <th mat-header-cell *matHeaderCellDef> Menu </th>
                                <td mat-cell *matCellDef="let element" data-label="Menu"> {{element.menu}}
                                    <a class="green-text"><mat-icon class=icon_color>more_vert</mat-icon></a>
                                </td>
                            </ng-container>

                            <ng-container matColumnDef="company">
                                    <th mat-header-cell *matHeaderCellDef>
                                        {{((isPO == false || isPO == undefined)  && (radioTarget == 'all_open' || radioTarget == 'allopen')) ? 'Company' : ''}}
                                    </th>
                                    <td mat-cell *matCellDef="let element">
                                        <span *ngIf="(!isPO || isPO == undefined) && radioTarget == 'shipped_radio'">
                                            <mat-checkbox (click)="$event.stopPropagation()"
                                                (change)="$event ? selection.toggle(element) : null"
                                                [checked]="selection.isSelected(element)">
                                            </mat-checkbox>
                                        </span>
                                        <span *ngIf="((isPO == false || isPO == undefined)  && radioTarget == 'all_open')">
                                            {{element.companyName}}
                                        </span>
                                    </td>
                            </ng-container>

                            <ng-container matColumnDef="orderDate">
                                <th mat-header-cell *matHeaderCellDef> Order Date </th>
                                <td mat-cell *matCellDef="let element" data-label="Order Date">
                                    {{element.orderDate | date: "MMM-dd-yyyy"}} </td>
                            </ng-container>

                            <ng-container matColumnDef="orderNumber">
                                <th mat-header-cell *matHeaderCellDef> Order Number </th>
                                <td mat-cell *matCellDef="let element" data-label="Order Number">
                                    {{element.customerPONumber}} </td>
                            </ng-container>

                            <ng-container matColumnDef="customerName">
                                <th mat-header-cell *matHeaderCellDef> Customer Name </th>
                                <td mat-cell *matCellDef="let element" data-label="Customer Name">
                                    {{element.customerName}} </td>
                            </ng-container>

                            <ng-container matColumnDef="contactName">
                                <th mat-header-cell *matHeaderCellDef> Contact Name </th>
                                <td mat-cell *matCellDef="let element" data-label="Contact Name">
                                    {{element.customerFirstName}} {{element.customerLastName}}</td>
                            </ng-container>
                            <ng-container matColumnDef="status">
                                <th mat-header-cell *matHeaderCellDef> Status </th>
                                <td mat-cell *matCellDef="let element" data-label="Status">
                                    {{GetStatusText(element.statusId)}} </td>
                            </ng-container>
                            <ng-container matColumnDef="carrier">
                                <th mat-header-cell *matHeaderCellDef> Carrier </th>
                                <td mat-cell *matCellDef="let element" data-label="Carrier">
                                    {{element.carrier}} </td>
                            </ng-container>
                            <ng-container matColumnDef="shipAs">
                                <th mat-header-cell *matHeaderCellDef> Ship As </th>
                                <td mat-cell *matCellDef="let element" data-label="Ship As">
                                    {{element.shipAs}} </td>
                            </ng-container>
                            <div>
                                <tr mat-header-row *matHeaderRowDef="displayedColumn; sticky: true"></tr>
                                <tr mat-row *matRowDef="let row; columns: displayedColumn;"
                                    (click)="SearchBySOList(row.customerPONumber,row.id )"></tr>
                            </div>
                            <tr class="mat-row" *matNoDataRow>
                                <td class="mat-cell" colspan="10" class="error text-center">
                                    No data found !
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <!--This below given div is for material form -->
        <div class="row" *ngIf="detailOfShipping">
            <div class="col-xl-11 col-lg-12 col-md-12 col-12 for-mobile-padding change-column-width">
                <form [formGroup]="form" class="w-100">
                    <div class=" row-2">
                        <div class="column-1 margin-rg-15">
                            <div class="card p-0 ship-to-card">
                                <mat-card class="example-card p-0 h-100 card_cst_one">
                                    <mat-card-header class="mat-header-bg">
                                        <mat-card-title class="mt-card-title">Ship To</mat-card-title>
                                    </mat-card-header>
                                    <mat-card-content class="card_cstm-mngsd">
                                        <ul class="cstmdt_cards">
                                            <li>{{customerName}}</li>
                                            <li [innerHtml]="this.sharedService.FormatAddress(customerAdd1,
                                customerAdd2, customerCity, customerState,
                                customerZip)"></li>
                                            <li>{{customerCountry}} {{customerPhone}}</li>
                                        </ul>
                                    </mat-card-content>
                                </mat-card>
                            </div>
                        </div>
                        <!-- ----------------------------------------------------- -->
                        <div class="column-2 border-ltb margin-lg-15 column-padding-1">
                            <div class="card card-ltb">
                                <mat-form-field class="forShipping m-0 mt-12 p-0 w-100" appearance="outline">
                                    <mat-label>Order Status</mat-label>
                                    <mat-select [(ngModel)]='ShippingStatusModel' name='ShippingStatusModel'
                                        formControlName='ShippingStatusModel' id='ShippingStatusModel'
                                        class="select-arrow-change-icon">
                                        <mat-option *ngFor="let item of ShippingStatus" [value]="item.id">
                                            {{item.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field class="forShipping m-0 p-0 w-100" appearance="outline"
                                    *ngIf="SalesOrderNo != undefined">
                                    <mat-label>Order Number</mat-label>
                                    <input matInput value="{{SalesOrderNo}}" disabled>
                                </mat-form-field>
                                <mat-form-field class=" p-0 m-0 w-100 change-icon-datepicker" appearance="outline"
                                    style="margin: 0em 1.5em;">
                                    <mat-label>Shipped Date</mat-label>
                                    <input matInput [matDatepicker]="picker" placeholder="Shipped Date"
                                        [(ngModel)]="ShippedDate" [ngModelOptions]="{standalone: true}" id="paymentDate"
                                        readonly>
                                    <mat-datepicker-toggle matIconSuffix [for]="picker" class="down-chevron-date">
                                        <mat-icon matDatepickerToggleIcon
                                            class="date-picker-down-chevron">keyboard_arrow_down</mat-icon>
                                    </mat-datepicker-toggle>
                                    <mat-datepicker #picker></mat-datepicker>
                                </mat-form-field>
                            </div>
                        </div>
                        <div class="column-2  mt-23 border-tb margin-rg-15 column-padding-2" *ngIf="BillTo!= undefined">
                            <div class="card card-tb">
                                <mat-form-field class="forShipping m-0 w-100" appearance="outline">
                                    <mat-label>Quoted Shipping</mat-label>
                                    <input matInput value="{{BillTo.shippingType}}" disabled>
                                </mat-form-field>

                                <mat-form-field class="forShipping m-0 p-0 w-100" appearance="outline">
                                    <mat-label>Quoted Shipping Weight</mat-label>
                                    <input matInput value="{{BillTo.quotedShippingWeight | number:'1.2-2'}} lbs"
                                        disabled>
                                </mat-form-field>
                                <mat-form-field class="forShipping  m-0 p-0 w-100" appearance="outline">
                                    <mat-label>Quoted Shipping Packages</mat-label>
                                    <input matInput value="{{BillTo.quotedShippingPackages}}" disabled>
                                </mat-form-field>

                            </div>
                        </div>
                        <div class="column-2 mt-23 border-rtb margin-lg-15 column-padding-3" *ngIf="BillTo!= undefined">
                            <div class="card card-rtb">
                                <mat-form-field class="forShipping parent_toggledate m-0 p-0 w-100"
                                    appearance="outline">
                                    <mat-label>Actual Shipping</mat-label>
                                    <mat-select [(ngModel)]='carrierModel' name='carrierModel' id='carrierModel'
                                        formControlName='carrierModel' class="select-arrow-change-icon">
                                        <mat-option value="{{BillTo.shippingType}}"
                                            selected>{{BillTo.shippingType==null? "Select Carrier" :
                                            BillTo.shippingType}}</mat-option>
                                        <mat-option aria-hidden="true" *ngFor="let item of carrierData"
                                            [value]="item.name">
                                            {{item.name}}
                                        </mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-form-field class="forShipping p-0 m-0 w-100" appearance="outline">
                                    <mat-label>Actual Shipping Weight</mat-label>
                                    <input matInput value="{{ActualShippingWeight | number:'1.2-2'}} lbs" disabled>
                                </mat-form-field>
                                <mat-form-field class="forShipping  p-0 m-0 w-100" appearance="outline">
                                    <mat-label>Actual Shipping Packages</mat-label>
                                    <input matInput value="{{form.value.ShippingPackage.length}}" disabled>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                    <div class="d-flex flex-wrap mt-12 Warehouse-row" *ngIf="BillTo!= undefined">
                        <div class="col-lg-6 col-md-12 col-sm-12 padding-left-20">
                            <mat-form-field class="forShipping m-0 p-0 w-100" appearance="outline">
                                <mat-label>Notes for Warehouse</mat-label>
                                <input matInput value="{{BillTo.orderShipWareHouseNotes}}" disabled>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-6 col-md-12 col-sm-12 padding-right-20">
                            <mat-form-field class="forShipping m-0 p-0 w-100" appearance="outline">
                                <mat-label>Notes for Customer</mat-label>
                                <input matInput value="{{BillTo.orderShipCustomerNotes}}" disabled>
                            </mat-form-field>
                        </div>
                    </div>





                    <!-- ------------------------ Packaging information Section start here----------------- -->
                    <div class="row mt-3">
                        <div class="col-lg-12">
                            <mat-card class="example-card cstmcard_shipings border-back">
                                <mat-card-header class="w-100 packaging-mat-header-text">
                                    <mat-card-title>
                                        <div class="row">
                                            <div class="col-xl-8 col-lg-6 col-md-6 col-12 ">
                                               <div class="card border-0 d-flex flex-row p-0">
                                               <div class="heading">
                                                <span class="textNowrap mt-card-title info-packg">
                                                    Packaging Information
                                                </span>
                                               </div>
                                               <div class="button-section"> <button class="btn btnaddpackage_cstm">
                                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                                    viewBox="0 0 18 18" fill="none">
                                                    <path
                                                        d="M15 9.75H9.75V15H8.25V9.75H3V8.25H8.25V3H9.75V8.25H15V9.75Z"
                                                        fill="#005FAE" />
                                                </svg>
                                                <span (click)="AddPackage()">Add Package</span>
                                            </button>
                                        </div>
                                               </div>
                                            </div>
                                            <div class="col-xl-4 col-lg-6 col-md-6 col-12 d-flex align-items-center "
                                            *ngIf="BillTo != undefined">
                                            <mat-checkbox class="cstmscheckspc fs-14 mat-checkbox-change" disabled
                                                [checked]="BillTo.orderShipCollectAcctNo != null && BillTo.orderShipCollectAcctNo != '' && BillTo.orderShipCollectAcctNo != undefined">Collect
                                                Shipping</mat-checkbox>
                                            <h6 class="ups-heading">
                                                {{BillTo.orderShipCollectAcct}} <span
                                                    *ngIf="BillTo.orderShipCollectAcct != undefined && BillTo.orderShipCollectAcct != '' && BillTo.orderShipCollectAcct != null">#</span>
                                                <span>{{BillTo.orderShipCollectAcctNo}}</span>
                                            </h6>
                                            <mat-icon (click)="SelectPrinter()"
                                                class="print-icon-image">print</mat-icon>
                                        </div>
                                        </div>
                                       
                                    </mat-card-title>
                                </mat-card-header>
                                <span class="customLabel text-danger">{{cartonError}}</span>
                                <mat-card-content>
                                    <div class="row" id="printPackingSlip">
                                        <div class="col-12 border-overflow mt-12">
                                            <div formArrayName="ShippingPackage" class="border-package"
                                                *ngFor="let project of form.get('ShippingPackage')['controls']; let i = index;">
                                                <mat-card-subtitle class="mt-2 card-subtitle">Package {{i +
                                                    1}}</mat-card-subtitle>
                                                <div class="mt-2"
                                                    *ngIf="form.value.ShippingPackage.length>0">
                                                    <div class="row">
                                                        <div class="col-12 d-flex flex-wrap gap-10">
                                                            <span class="more-vert-span packaging-drop-icon">
                                                                <button [formGroupName]="i" mat-icon-button
                                                                    [matMenuTriggerFor]="menu"
                                                                    #menuTrigger="matMenuTrigger"
                                                                    (mouseover)="menuTrigger.openMenu()">
                                                                    <mat-icon class="icon_fix">more_vert</mat-icon>
                                                                </button>
                                                                <mat-menu #menu="matMenu" [overlapTrigger]="false">
                                                                    <span (mouseleave)="menuTrigger.closeMenu()">
                                                                        <button mat-menu-item
                                                                            (click)="EditCarton(i,'editRow')">
                                                                            <span>Edit Package</span>
                                                                        </button>
                                                                        <span
                                                                            *ngIf="form.value.ShippingPackage[i].label_downloadURL != null"
                                                                            class="reprint-button-dropdown">
                                                                            <button mat-menu-item
                                                                                (click)="ReprintPackageSlip(form.value.ShippingPackage[i].label_downloadURL,i)">
                                                                                RePrint Shipping Label
                                                                            </button>
                                                                        </span>
                                                                        <span
                                                                            *ngIf="form.value.ShippingPackage[i].label_downloadURL == null">
                                                                            <button disabled mat-menu-item>
                                                                                RePrint Shipping Label
                                                                            </button>
                                                                        </span>
                                                                        <span
                                                                            *ngIf="form.value.ShippingPackage.length >=2">
                                                                            <button mat-menu-item
                                                                                (click)="removePackage(i)">
                                                                                Delete Package
                                                                            </button>
                                                                        </span>
                                                                        <span
                                                                            *ngIf="form.value.ShippingPackage.length < 2">
                                                                            <button disabled mat-menu-item>
                                                                                Delete Package
                                                                            </button>
                                                                        </span>
                                                                    </span>
                                                                </mat-menu>
                                                            </span>
                                                            <div class="first-input-width">
                                                                <mat-form-field
                                                                class="w-100"
                                                                [formGroupName]="i" appearance="outline">
                                                                <mat-label>Box Sizes</mat-label>
                                                                <mat-select formControlName="boxSizes"
                                                                    (selectionChange)="onValueChange($event,i)"
                                                                    class="select-arrow-change-icon">
                                                                    <mat-option *ngFor="let size of boxSizing;"
                                                                        [value]="size.id">
                                                                        {{size.name}}
                                                                    </mat-option>
                                                                </mat-select>
                                                            </mat-form-field>
                                                            </div>
                                                           <div class="second-input-width">
                                                            <mat-form-field [formGroupName]="i"
                                                            class="w-100 "
                                                            appearance="outline">
                                                            <mat-label>Length</mat-label>
                                                            <input matInput type="text" class="NumberOnly"
                                                                formControlName="length1"
                                                                [readonly]="form.value.ShippingPackage[i].isEditable">
                                                            <mat-select formControlName="length2"
                                                                [disabled]="form.value.ShippingPackage[i].isEditable"
                                                                class="select-arrow-change-icon">
                                                                <mat-option *ngFor="let item of LenghtUnit"
                                                                    [value]="item.id">
                                                                    {{item.name}}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                           </div>
                                                            <div class="second-input-width">
                                                                <mat-form-field [formGroupName]="i"
                                                                class="w-100"
                                                                appearance="outline">
                                                                <mat-label>Width</mat-label>
                                                                <input matInput type="text" class="NumberOnly"
                                                                    formControlName='width1'
                                                                    [readonly]="form.value.ShippingPackage[i].isEditable">
                                                                <mat-select formControlName='width2'
                                                                    [disabled]="form.value.ShippingPackage[i].isEditable"
                                                                    class="select-arrow-change-icon">
                                                                    <mat-option *ngFor="let item of WidthUnit"
                                                                        [value]="item.id">
                                                                        {{item.name}}
                                                                    </mat-option>
                                                                </mat-select>
                                                            </mat-form-field>
                                                            </div>
                                                           <div class="second-input-width">
                                                            <mat-form-field [formGroupName]="i"
                                                            class="w-100"
                                                            appearance="outline">
                                                            <mat-label>Height</mat-label>
                                                            <input matInput type="text" class="NumberOnly border-0"
                                                                formControlName='height1'
                                                                [readonly]="form.value.ShippingPackage[i].isEditable">
                                                            <mat-select formControlName='height2'
                                                                [disabled]="form.value.ShippingPackage[i].isEditable"
                                                                class="select-arrow-change-icon">
                                                                <mat-option *ngFor="let item of HeightUnit"
                                                                    [value]="item.id">
                                                                    {{item.name}}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                           </div>
                                                           <div class="second-input-width actual-weight-order">
                                                            <mat-form-field [formGroupName]="i"
                                                            class="w-100"
                                                            appearance="outline">
                                                            <mat-label>Actual Weight</mat-label>
                                                            <input matInput type="numeric"
                                                                (input)="CalculateVariance($event.target,i)"
                                                                formControlName='weight1'>
                                                            <mat-select formControlName='weight2'
                                                                class="select-arrow-change-icon">
                                                                <mat-option *ngFor="let item of WeightUnit"
                                                                    [value]="item.id">
                                                                    {{item.name}}
                                                                </mat-option>
                                                            </mat-select>
                                                        </mat-form-field>
                                                           </div>
                                                        
                                                      <div class="second-input-width order-change">
                                                        <mat-form-field [formGroupName]="i"
                                                        class="w-100"
                                                        appearance="outline">
                                                        <mat-label>Qty in Package</mat-label>
                                                        <input matInput formControlName="qtyPackage" type="text"
                                                            readonly>
                                                    </mat-form-field>
                                                      </div>
                                                            <div class="part-weight-section">
                                                                <div [formGroupName]="i"  class="text-center">
                                                                    <span class=" fs-16 d-block">{{BindWeightForPackagingInformation(form.value.ShippingPackage[i].id) | number:'1.2-2'}} lbs</span>
                                                                    <span class="font-12px order_2">Part Weight   </span>
                                                                </div>
                                                            </div>
                                                            <div class="variance-section">
                                                                <div [formGroupName]="i"
                                                                class="">
                                                                <!-- <span> <span class="fs-16">
                                                                        <input matInput type="text"
                                                                            class="NumberOnly border-0" readonly
                                                                            [style.color]="form.value.ShippingPackage[i].variance >= 0 ? '#212529' : 'red'"
                                                                            formControlName='variance'>
                                                                        Variance</span> <br>
                                                                </span> -->
                                                                <div class="percentage">
                                                                    <input matInput type="text"
                                                                    class="NumberOnly border-0 w-100" readonly
                                                                    [style.color]="form.value.ShippingPackage[i].variance >= 0 ? '#212529' : 'red'"
                                                                    formControlName='variance'>
                                                                </div>
                                                                <div>
                                                                    <span class="fs-14">Variance</span>
                                                                </div>
                                                            </div>
                                                            </div>
                                                          <div class="tracing-section-width">
                                                            <mat-form-field [formGroupName]="i"
                                                            class="w-100"
                                                            appearance="outline">
                                                            <mat-label>Tracking Number</mat-label>
                                                            <input matInput
                                                                placeholder="Tracking Number weight-input" readonly
                                                                formControlName="tracking">
                                                        </mat-form-field>
                                                          </div>
                                                        </div>
                                                       
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row mt-12 justify-content-end">
                                        <div class="col-5 d-none"></div>
                                        <div class="col-xl-7 col-12">
                                            <div class="print-btn-flex">
                                                <div class="inner-wrapper inner-wrapper2 ">
                                                    <mat-form-field class="cst_master total_dp-none master-tracking-input"
                                                        appearance="outline">
                                                        <mat-label>Master Tracking Number</mat-label>
                                                        <input matInput placeholder="Master Tracking Number" readonly
                                                            value="{{masterTrackingNo}}">
                                                    </mat-form-field>
                                                    <div class="total cstmone_dt_width total_dp-none  order__1">
                                                        <span> <span class="font-16px d-block">{{totalLBS | number:'1.2-2'
                                                                }}
                                                                lbs</span> <span class="font-12px">Total Part
                                                                Weight</span></span>
                                                    </div>
                                                    <div class="total cstmone_dt_width total_dp-none order__1">
                                                        <span> <span class="font-16px"
                                                                [style.color]="totalVariance >= 0 ? '#212529' : 'red'">
                                                                {{totalVariance | number:'1.2-2' }}
                                                            </span> <br><span class="font-12px">Total Variance
                                                            </span></span>
                                                    </div>
                                                    <div class="example-button total larger ">
                                                        <mat-menu #print="matMenu" [overlapTrigger]="false">
                                                            <span (mouseleave)="menuTrigger.closeMenu()">
                                                                <button mat-menu-item (click)="PrintAll()">
                                                                    Print All
                                                                </button>
                                                                <button mat-menu-item
                                                                    (click)="GetEqualShippedSerialLot(defaultShippingLabelsPrinter,false)">
                                                                    <span>Print
                                                                        Shipping
                                                                        Labels</span>
                                                                </button>
                                                                <button mat-menu-item
                                                                    (click)="getCompanyDetailsByCompanyId()"
                                                                    [disabled]="masterTrackingNo == '' || masterTrackingNo == undefined || masterTrackingNo == null">
                                                                    <span>Print Packing
                                                                        Slip</span>
                                                                </button>
                                                                <button mat-menu-item
                                                                    [disabled]="masterTrackingNo == '' || masterTrackingNo == undefined || masterTrackingNo == null"
                                                                    (click)="CancelShipment()">
                                                                    Cancel Shipment
                                                                </button>
                                                            </span>
                                                        </mat-menu>
                                                        <button class="common-button cstm_chng_btns "
                                                            [matMenuTriggerFor]="print" #menuTrigger="matMenuTrigger"
                                                            (mouseover)="menuTrigger.openMenu()">Print</button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </mat-card-content>
                            </mat-card>
                        </div>
                    </div>
                    <!-- ------------------------ Packaging information Section start here----------------- -->




                    <div class="packaging-input-layout mt-4">
                        <div class="shipping-section">
                            <ul class="heading-section">
                                <li class="menu">Menu</li>
                                <li class="item">Item</li>
                                <li class="part-n">Part Number</li>
                                <li class="desc">Description</li>
                                <li class="uom">UOM</li>
                                <li class="ready">Ready</li>
                                <li class="shiped">Shipped</li>
                                <li class="package">Package</li>
                                <li class="weight">Weight</li>
                                <li class="size">Size</li>
                                <li class="tracking">Tracking</li>
                            </ul>
                            <div class="shipping-row">
                                <div
                                    *ngIf="SpecialPartList != undefined && SpecialPartList.length>0 && form.value.rowItems.length>0">
                                    <div class="shipping-box" *ngFor="let element of SpecialPartList;let i = index;">
                                        <ul class="value-section">
                                            <li class="menu"><i class="fa fa-ellipsis-v" aria-hidden="true"></i></li>
                                            <li class="item" data-label="item">{{i + 1}}</li>
                                            <li class="part-n">
                                                <span>
                                                    {{element.vendorPartNumber=="" ||
                                                    element.vendorPartNumber==null ?
                                                    "N/A" :
                                                    element.vendorPartNumber}}
                                                </span>
                                            </li>
                                            <li class="desc"> {{element.partName}}
                                                <span *ngIf="BindTrackingForParent(i) !=''">
                                                    <span>S/N: {{BindTrackingForParent(i)}}</span>
                                                </span>
                                                <a (click)="expandedElement = expandedElement === element ? null : element;ExpandMore(i,element)"
                                                    class="add-icon-tab">
                                                    <mat-icon>{{expandedElement === element ? 'expand_more' :
                                                        'keyboard_arrow_right'}}</mat-icon>
                                                </a>
                                                <a (click)="expandedElement = expandedElement === element ? null : element;ExpandMore(i,element)"
                                                    class="add-row-tab">+Add Row</a>
                                            </li>
                                            <li class="uom" data-label="UOM">{{element.uomName}}</li>
                                            <li class="ready" data-label="Ready"> {{element.quantity}} </li>
                                            <li class="shiped">
                                                <mat-form-field class=" p-0 w-100" appearance="outline">
                                                    <!-- <mat-label>Shipped</mat-label> -->
                                                    <input matInput class="setRow  shippedcstmwid" type="number"
                                                        [value]="form.value.rowItems[i].shipped"
                                                        (keyup)="ChangeShip($event.target,i,element)">
                                                </mat-form-field>
                                            </li>
                                            <li class="package">
                                                <mat-form-field class="p-0 w-100" appearance="outline">
                                                    <mat-select class="setRow  pckgsctmwid select-arrow-change-icon"
                                                        [(value)]="form.value.rowItems[i].package"
                                                        (selectionChange)="changePackage($event,i)">
                                                        <mat-option
                                                            *ngFor="let pac of form.value.ShippingPackage; let pacIndex = index"
                                                            [value]="pac.id">
                                                            {{pacIndex+1}}
                                                        </mat-option>
                                                    </mat-select>
                                                </mat-form-field>
                                            </li>
                                            <li class="weight" data-label="Weight">
                                                {{BindWeightForParent(i,element)}}</li>
                                            <li class="size" data-label="Size">{{element.size=="" ||
                                                element.size==null ?
                                                "N/A" :
                                                element.size}}</li>
                                            <li class="tracking" data-label="Tracking">
                                                <span *ngIf="element.partTracking != 'None'"
                                                    (click)="getSidenav(i,0,true,element)" class="positive">
                                                    {{element.partTracking}}
                                                </span>
                                                <span
                                                    *ngIf="IsFlyoutErro && CheckEqualTracking(this.form.value.rowItems[i].Id) == 0 && element.partTracking != 'None'"
                                                    style=" color: red;">
                                                    Incomplete
                                                </span>
                                                <span *ngIf="element.partTracking == 'None'" class="negative">
                                                    {{element.partTracking}}
                                                </span>
                                            </li>
                                        </ul>
                                        <!-- Bottom Work -->
                                        <div formArrayName="rowItems"
                                            *ngFor="let item of rowItems.controls; let ind = index;">
                                            <div>
                                                <div [formGroupName]="ind">
                                                    <div formArrayName="SubItemList">
                                                        <div *ngFor="let subList of getRowItemSubList(ind).controls; let sub = index;"
                                                            [formGroupName]="sub">
                                                            <ul class="value-section added-row"
                                                                *ngIf="i == ind && i== IsShowRow">
                                                                <li class="menu">
                                                                    <span>
                                                                        <button mat-icon-button
                                                                            [matMenuTriggerFor]="Childmenu"
                                                                            #ChildmenuTrigger="matMenuTrigger"
                                                                            (mouseover)="ChildmenuTrigger.openMenu()">
                                                                            <mat-icon
                                                                                class="icon_fix">more_vert</mat-icon>
                                                                        </button>
                                                                        <mat-menu #Childmenu="matMenu">
                                                                            <span
                                                                                (mouseleave)="ChildmenuTrigger.closeMenu()">
                                                                                <span>
                                                                                    <button mat-menu-item
                                                                                        (click)="removeChildPart(ind,sub)">
                                                                                        Delete Package
                                                                                    </button>
                                                                                </span>
                                                                            </span>
                                                                        </mat-menu>
                                                                    </span>
                                                                </li>
                                                                <li class="item"> {{ind+1}}.{{sub+1}}</li>
                                                                <li class="part-n">

                                                                </li>
                                                                <li class="desc" *ngIf="BindTracking(ind,sub) ==''">

                                                                </li>
                                                                <li class="desc" *ngIf="BindTracking(ind,sub) !=''">
                                                                    S/N: {{BindTracking(ind,sub)}}
                                                                </li>
                                                                <li class="add-row"
                                                                    *ngIf="getRowItemSubList(ind).length == sub+1 && form.value.rowItems[ind].SubItemList[sub].ready >=0">
                                                                    <a (click)="addItem(ind,element)">+ Add
                                                                        Row</a>
                                                                </li>

                                                                <li class="remaining" data-label=""
                                                                    [style.color]="form.value.rowItems[ind].SubItemList[sub].ready >=0 ? '#212529' : 'red'">
                                                                    Remaining
                                                                </li>
                                                                <li class="ready"
                                                                    [style.color]="form.value.rowItems[ind].SubItemList[sub].ready >=0 ? '#212529' : 'red'">
                                                                    {{form.value.rowItems[ind].SubItemList[sub].ready}}
                                                                </li>
                                                                <li class="shiped">
                                                                    <mat-form-field class=" p-0 w-100"
                                                                        appearance="outline">
                                                                        <mat-label>Shipped</mat-label>
                                                                        <input matInput class="setRow  shippedcstmwid"
                                                                            type="number" formControlName="shipped"
                                                                            (input)="CalculateWeight($event.target,ind,sub,element)">
                                                                    </mat-form-field>
                                                                </li>
                                                                <li class="package">
                                                                    <mat-form-field class="p-0 w-100"
                                                                        appearance="outline">
                                                                        <mat-label>Package</mat-label>
                                                                        <mat-select
                                                                            class="setRow  pckgsctmwid select-arrow-change-icon"
                                                                            formControlName="package"
                                                                            (selectionChange)="changeChildPackage($event,ind,sub)">
                                                                            <mat-option
                                                                                *ngFor="let pac of form.value.ShippingPackage; let k = index"
                                                                                [value]="pac.id">
                                                                                {{k+1}}
                                                                            </mat-option>
                                                                        </mat-select>
                                                                    </mat-form-field>
                                                                </li>
                                                                <li class="weight">
                                                                    <input readonly formControlName="weight" type="text"
                                                                        placeholder="lbs"
                                                                        class="inputs-bg weight-input">
                                                                </li>
                                                                <li class="size">
                                                                    <input class="setRow sizewdthcstw"
                                                                        formControlName="size" type="text" readonly
                                                                        class="inputs-bg">
                                                                </li>
                                                                <li class="tracking" data-label="Tracking">
                                                                    <span *ngIf="element.partTracking != 'None'"
                                                                        (click)="getSidenav(ind,sub,false,element)"
                                                                        class="positive">
                                                                        {{element.partTracking}}
                                                                    </span>
                                                                    <span
                                                                        *ngIf="IsFlyoutErro && CheckEqualTracking(this.form.value.rowItems[ind].SubItemList[sub].Id) == 0 && element.partTracking != 'None'"
                                                                        style="color: red;">
                                                                        Incomplete
                                                                    </span>
                                                                    <span *ngIf="element.partTracking == 'None'"
                                                                        class="negative">
                                                                        {{element.partTracking}}
                                                                    </span>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- -------------------------------------------------------------------------New HTML Structure End--------------------------------------------------------------------------------------- -->
                    </div>

                    <div class="overcstfixedsidebar active" *ngIf="showNavbar"></div>
                    <div class="sidebar_trackingnum active" *ngIf="showNavbar">
                        <div class="titlecstmsidebar">
                            <h3>P/N: {{_sideNavList.customerPartNumber}}</h3>
                            <p class="discr">
                                {{_sideNavList.partName}}
                            </p>
                            <p class="cstmsimdesc">
                                Click the Scan icon to scan the barcodes or QR codes for quicker entry. Click on the
                                field to manually enter the number.
                            </p>
                        </div>
                        <div class="cstmbxdsis">
                            <h5 class="tltcstf">
                                <span *ngIf="FlayOutPackageNo !=0">
                                    Package {{FlayOutPackageNo}}
                                </span>
                                <span style="color: red;" *ngIf="FlayOutPackageNo ==0">
                                    Package not selected.
                                </span>
                            </h5>
                            <div class="scroll_cstmbx-era" [formGroup]="SerialLotForm">
                                <div class="tltbarcstmlg">
                                    <div class="itlemwidth labelcstmhebx">
                                        Menu
                                    </div>
                                    <div class="itlemwidth labelcstmhebx">
                                        Item
                                    </div>
                                    <div class="liceswidthaddeda labelcstmhebx">
                                        Serial Number
                                    </div>
                                    <div class="scanwdtcstadda labelcstmhebx">
                                        Scan
                                    </div>
                                </div>

                                <div class="container scrollwidthcstmbcd" formArrayName="SerialNumber"
                                    *ngIf="SerialLotForm.value.SerialNumber.length > 0">
                                    <div class="row rowcstmfhjs"
                                        *ngFor="let item of SerialNumber.controls; let i = index;" [formGroupName]="i">
                                        <div class="col-md-2 rowcstmfhjs">
                                            <button mat-icon-button [matMenuTriggerFor]="F"
                                                #FlyMenuTrigger="matMenuTrigger" (click)="FlyMenuTrigger.openMenu()">
                                                <mat-icon class="icon_fix">more_vert</mat-icon>
                                            </button>
                                            <mat-menu #F="matMenu">
                                                <span (mouseleave)="FlyMenuTrigger.closeMenu()">
                                                    <button mat-menu-item (click)="removeFlyOut(i)">
                                                        Delete
                                                    </button>
                                                </span>
                                            </mat-menu>
                                        </div>
                                        <div class="col-md-1 rowcstmfhjs">
                                            <div class="labeldhfbs itlemwidth">{{i+1}}</div>
                                        </div>
                                        <div class="col-md-6 ">
                                            <input class="licensecstnfjscs liceswidthaddeda"
                                                placeholder="Scan or Manually Enter" formControlName="Tracking"
                                                (input)="checkTrackingDuplicacy(i)">
                                            <span class="licensecstnfjscs liceswidthaddeda DuplicateError error"
                                                *ngIf="getTracking(i)?.errors?.duplicateName">Duplicate field.</span>
                                        </div>
                                        <div class="col-md-3 cscanwlda scanwdtcstadda" (click)="AvailableDevice(i)">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64"
                                                viewBox="0 0 64 64" fill="none">
                                                <g filter="url(#filter0_d_1448_76537)">
                                                    <rect x="2" y="2" width="56" height="56" rx="4" fill="#EEEDF1" />
                                                    <path
                                                        d="M41.1108 10H38.8884C37.555 10 36.666 10.8889 36.666 12.2223C36.666 13.5556 37.555 14.4445 38.8884 14.4445H41.1108C43.5554 14.4445 45.5555 16.4446 45.5555 18.889V21.3335C45.5555 22.6669 46.4444 23.5558 47.7779 23.5558C49.1113 23.5558 50.0002 22.6669 50.0002 21.3335V18.889C50.0002 14.0001 46 10 41.1108 10Z"
                                                        fill="#43474E" />
                                                    <path
                                                        d="M47.7779 36.666C46.4444 36.666 45.5555 37.5549 45.5555 38.8883V41.1105C45.5555 43.555 43.5554 45.5551 41.1108 45.5551H38.8884C37.555 45.5551 36.666 46.444 36.666 47.7773C36.666 49.1107 37.555 49.9996 38.8884 49.9996H41.1108C46 49.9996 50.0002 45.9995 50.0002 41.1105V38.8883C50.0002 37.5549 49.1113 36.666 47.7779 36.666Z"
                                                        fill="#43474E" />
                                                    <path
                                                        d="M21.1118 45.5551H18.8895C16.4449 45.5551 14.4447 43.555 14.4447 41.1105V38.8883C14.4447 37.5549 13.5558 36.666 12.2224 36.666C10.8889 36.666 10 37.5549 10 38.8883V41.1105C10 45.9995 14.0003 49.9996 18.8895 49.9996H21.1118C22.4453 49.9996 23.3342 49.1107 23.3342 47.7773C23.3342 46.444 22.4453 45.5551 21.1118 45.5551Z"
                                                        fill="#43474E" />
                                                    <path
                                                        d="M12.2224 23.3336C13.5558 23.3336 14.4447 22.4447 14.4447 21.1113V18.889C14.4447 16.4446 16.4449 14.4445 18.8895 14.4445H21.1118C22.4453 14.4445 23.3342 13.5556 23.3342 12.2223C23.3342 10.8889 22.4453 10 21.1118 10H18.8895C14.0003 10 10 14.0001 10 18.889V21.1113C10 22.2224 10.8889 23.3336 12.2224 23.3336Z"
                                                        fill="#43474E" />
                                                    <path
                                                        d="M29.9983 21.1105C29.9983 18.6661 27.9981 16.666 25.5535 16.666H21.1088C18.6642 16.666 16.6641 18.6661 16.6641 21.1105V25.5551C16.6641 27.9995 18.6642 29.9996 21.1088 29.9996H25.5535C27.9981 29.9996 29.9983 27.9995 29.9983 25.5551V21.1105ZM21.1088 25.5551V21.1105H25.5535V25.5551H21.1088Z"
                                                        fill="#43474E" />
                                                    <path
                                                        d="M43.3342 38.889V34.4445C43.3342 32 41.3341 30 38.8895 30H34.4447C32.0001 30 30 32 30 34.4445V38.889C30 41.3335 32.0001 43.3336 34.4447 43.3336H38.8895C41.3341 43.3336 43.3342 41.3335 43.3342 38.889ZM34.4447 34.4445H38.8895V38.889H34.4447V34.4445Z"
                                                        fill="#43474E" />
                                                    <path
                                                        d="M34.4431 27.7773H36.6654C37.9989 27.7773 38.8878 26.8884 38.8878 25.5551V21.1105H41.1102C42.4436 21.1105 43.3325 20.2216 43.3325 18.8883C43.3325 17.5549 42.4436 16.666 41.1102 16.666H36.6654C35.332 16.666 34.4431 17.5549 34.4431 18.8883V23.3328C33.1097 23.3328 32.2207 24.2217 32.2207 25.5551C32.2207 26.8884 33.1097 27.7773 34.4431 27.7773Z"
                                                        fill="#43474E" />
                                                    <path
                                                        d="M25.5535 32.2227H18.8864C17.553 32.2227 16.6641 33.1116 16.6641 34.4449C16.6641 35.7783 17.553 36.6672 18.8864 36.6672H23.3312V41.1117C23.3312 42.4451 24.2201 43.334 25.5535 43.334C26.887 43.334 27.7759 42.4451 27.7759 41.1117V34.4449C27.7759 33.1116 26.887 32.2227 25.5535 32.2227Z"
                                                        fill="#43474E" />
                                                </g>
                                                <defs>
                                                    <filter id="filter0_d_1448_76537" x="0" y="0" width="64" height="64"
                                                        filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                        <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                        <feColorMatrix in="SourceAlpha" type="matrix"
                                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                                            result="hardAlpha" />
                                                        <feOffset dx="2" dy="2" />
                                                        <feGaussianBlur stdDeviation="2" />
                                                        <feComposite in2="hardAlpha" operator="out" />
                                                        <feColorMatrix type="matrix"
                                                            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.2 0" />
                                                        <feBlend mode="normal" in2="BackgroundImageFix"
                                                            result="effect1_dropShadow_1448_76537" />
                                                        <feBlend mode="normal" in="SourceGraphic"
                                                            in2="effect1_dropShadow_1448_76537" result="shape" />
                                                    </filter>
                                                </defs>
                                            </svg>
                                        </div>

                                    </div>
                                    <div class="row btncstmLot-widthd" *ngIf="IsLotBtnShow">
                                        <button type="button"
                                            style="background-color: transparent;color: #005fae;font-weight:500;"
                                            (click)="AddTracking()">+ Add Lot Number</button>
                                    </div>
                                </div>
                                <div class="footerbtnswrps">
                                    <button [disabled]="!SerialLotForm.valid || _IsBtnFlyOutSave || FlayOutPackageNo==0"
                                        type="button" class="saveclosebnt" (click)="saveSerialLot()">Save and
                                        Close</button>
                                    <button type="button" class="onlyclosebnt" (click)="closeNavbar()">Cancel</button>
                                </div>

                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
<!-- Scanner work -->
<div class="zxing-scanner" *ngIf="IsScan && qrResultString == ''">
    <zxing-scanner [autofocusEnabled]="autofocusEnabled" (camerasNotFound)="camerasNotFound($event)"
        (scanSuccess)="onCodeResult($event)"></zxing-scanner>
</div>
<ng-container *ngIf="hasDevices === undefined">
</ng-container>
<!-- Scanner work -->
<!-- BarCode work -->
<div *ngIf="IsBarCodeScan">
    <barcode-scanner-livestream #barCodescanner (valueChanges)="onBarCodeValueChange($event)">
    </barcode-scanner-livestream>
</div>
<!-- --------------modal popup for printer-------- -->
<div class="modal" id="SelectPrinter">
    <div class="modal-dialog">
        <div class="modal-content" *ngIf="PrinterList != null && PrinterList != undefined">
            <div class="modal-body p-4">
                <h2>Printer Settings</h2>
                <p class="subhcstm">Please select the default printers for printing Shipping Labels and Packing Lists.
                </p>
                <mat-form-field class="outline mb-3" appearance="outline">
                    <mat-label>Shipping Labels Default Printer</mat-label>
                    <mat-select [(ngModel)]="ShippingLabelsPrinter"
                        (selectionChange)="changeShippingLabelsPrinter($event)" class="select-arrow-change-icon">
                        <mat-option *ngFor="let pl of PrinterList" [value]="pl">
                            {{pl}}
                        </mat-option>
                    </mat-select>
                </mat-form-field><br />
                <mat-form-field class="outline" appearance="outline">
                    <mat-label>Packing List Default Printer</mat-label>
                    <mat-select [(ngModel)]="PackingListPrinter" (selectionChange)="changePackingSlipPrinter($event)"
                        class="select-arrow-change-icon">
                        <mat-option *ngFor="let pl of PrinterList" [value]="pl">
                            {{pl}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="modal-footer sweet-footer px-4">
                <button type="button" class="btn round-btn" (click)="CloseSelectPrinter()"
                    data-dismiss="modal">Cancel</button>
                <button type="button" class="btn btnCustm round-btn" (click)="SaveSelectPrinter()"
                    data-dismiss="modal">Save
                    Setting</button>
            </div>
        </div>
    </div>
</div>
<!-- --------------modal popup for printer-------- -->
<div id="BarCodeGen" hidden>
    <svg #barcode id="barcode"></svg>
</div>