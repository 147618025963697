export class ProductModel 
{
  ProductName: number;
  ProductDescription: number;
  category: Category[];
}
export class Category {
  id: number;
  productParts: ProductParts[];
  displayIndex: string;
  Indexing: number;
  Ordering: number;
}
export class ProductParts 
{
  id: number;
  qty: number = 1;
  cost: string;
  hours: string;
  partQuestions: PartQuestions[];
  customerDiscount:CustomerDiscount[];
  partNo: string;
  name: string;
  vendorPartName: string;
  vendorPartNumber: string;
  vendorName: string;
  WorkflowList: any;
  workflowId = '';
  task: number;
  taskName: string;
  taskHour: string;
  taskDescription: string;
  TaskName_search: string;
  vendorId : number;
}
export class PartQuestions {
  answerId: number;
  questionTitle: string;
  questionid: number;
  typeKey: string;
  typeName: string;
  answer: any;
  productPartId: number;

}
export class CustomerDiscount 
{
  Id: number;
  PartId: number;
  Qty: number;
  Discount:string;
  CustomerCost:string;
  isActive:boolean
}
