import { Component, OnInit, ChangeDetectorRef, AfterViewInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { Subject } from 'rxjs';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstantsService } from 'src/app/constants.service';
import { SharedService } from 'src/app/services/shared.service';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
// import { DownlineTreeviewItem, DropdownTreeviewComponent, TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { DatePipe } from '@angular/common';
import { GlobalChangeService } from 'src/app/services/global-change.service';
import Swal from 'sweetalert2';
import { VendorService } from 'src/app/services/vendor/vendor.service';
import { CustomerService } from 'src/app/services/customer/customer.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { PartService } from 'src/app/services/part/part.service';
declare var $: any

@Component({
  selector: 'app-receive-order',
  templateUrl: './receive-order.component.html',
  styleUrls: ['./receive-order.component.css']
})
export class ReceiveOrderComponent implements AfterViewInit, OnDestroy, OnInit {
  srNo: number;
  customArrayIndex: number = 1;
  [x: string]: any;
  recordAlreadyExist = false;
  settingDetailCSS: any;
  settingLogoImage: any;
  data: any = {};
  imageUrl: any = "/assets/image/add-part.png";
  datalist: any;
  vendorlist: any;
  categorylist: any;
  Customlist: any;
  PartQuestions = [0];
  questionlist: any;
  questionlistNew: any;
  tempQuestionlist: [];
  userId: string;
  value: number;
  id: any;
  vendorId: any;
  name: any;
  description: any;
  cost: any;
  hours: any;
  imagePath: any
  selectedVendorValue = '';
  laborCharge: any;
  search: any;
  GetStatusData: any = "1";
  customPartImagePath: any
  notes: any;
  partCategoryId: any;
  companyId: any;
  productId: any;
  isActive: any;
  createdDate: any;
  modifiedDate: any;
  createdBy: string;
  modifiedBy: string;
  partNo: any
  vendorName: any;
  partCategoryName: any;
  partname: any;
  form: FormGroup;
  formSearch: FormGroup;
  questionId: any;
  selectedPartCategoryIdValue = '';
  CustomCategory = '';
  @ViewChild('closeBtn') closeBtn: ElementRef;
  buttonValue: string;
  partid: number;
  validation: boolean = false;
  validation1: boolean = false;
  validation2: boolean = false;
  SearchPOValidation: boolean = false;
  SearchDDLValidation: boolean = false;
  loader: boolean = false;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  partIds: any;
  qnsId: any;
  deleteDetail: string;
  disabled = true;
  questionidZero: any;
  fileToUpload: File = null;
  deletedItem: string;
  PartImage: any;
  clearbutton: boolean = true;
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  rolePermissionsList: any;
  permissionMessage: string;
  btnShowvalidation: boolean = false;
  btnOkShow: boolean = false;
  SelectedLocation = '';
  SelectedPartLocation = '';
  MistypeValue: any;
  companyList: any;
  companyList1: any;
  userGroupObjects = new Array();
  CompanyValue: any;
  VendorPartCost: any;
  CategoryId: any;
  ddlPartList: any;
  PartCategoryList: any;
  partList = [];
  partListWithoutCustomPart = [];
  partListCustomPart = [];
  partitemId: any;
  POTypeList: any;
  PartId: any;
  custompartId: any;
  selectedPartValue: any = '';
  selectedPart: '';
  SelectedBuyer = '';
  PartsDetails = [];
  RcvdPartsDetails = [];
  now: Date;
  validationcustom: boolean = false;
  deleteCustomPart: any;
  SubTotalVendorCost: any;
  SubTotalPartCost: any;
  SubTotalLaborHours: any;
  iteminc: any = 1;
  //: any;
  qty: any = 1;
  normalShow = true;
  UpdateQty: any = 0;
  UpdateEachPartCost: any = 0;
  UpdateLaborHrs: any = 0;
  UpdateVendorPartCost: any = 0;
  UpdatePartName: any;
  UpdatePartNumber: any;
  unitofmeasurement: any;
  UnitofMeasureId: any;
  SelectedUnitMeasure = '';
  Shipcustomer: boolean = false;
  Shipcompany: boolean = false;
  DateScheduled: any;
  termsData: any;
  FobPoint: any;
  selectedtermsValue: '';
  selectedFobPointValue: any = '';
  shippingData: any;
  selectedshippingValue = '';
  POstatusList: any;
  selectedstatusvalue: any = '1';
  vendordata: any;
  selectedvaluetype: any = '';
  selectedvaluetype1: any = '';
  IsServices: boolean = false;
  IsPartType: boolean = false;
  IsProductType: boolean = false;
  selectedPartOrdertype = '';
  PartOrderDropdown: any;
  ProductOrderDropdown: any;
  buyerId: '';
  PaymentTerms = '';
  PartOrderId: any;
  typeId: '';
  SelectedvaluetypeOfPos: any = "0";
  shiptoradiobutton: any;
  productOrderId: any;
  selectedProductOrdervaluetype = '';
  selectedPartOrder: any = '';
  Updatedateschedule: any;
  UpdatecustumerPartNumber: any;
  UpdateDescription: any;
  UpdatevendorpartNumber: any;
  Updateservice: any;
  UpdatevendorParCost: any;
  TypeStatusEdit: boolean = false;
  IsServicesEdit = false;
  IsProductTypeEdit = false;
  IsPartTypeEdit = false;
  PoSearchTxt: any;
  selectedVendorSearch = '0';
  selectedStatusSearch = '0';
  RecOrderListShow = false;
  CompanyError = false;
  userGroupObjects1 = new Array();
  CompanyValue1: any;
  UpdateClear: boolean = false;
  DSMinDate: any;
  //Status Popup Property
  formPopup: FormGroup;
  PopupValidation: boolean = false;
  PopupItemId: any;
  popVendorPart: any;
  popVendorPartName: any;
  popCustPart: any;
  popCustPartName: any;
  popDesc: any;
  popOrdered: any;
  poCustomId: any;
  TxtItemDateReceived: any = new Date();
  TxtItemReceived: any;
  HidTxtItemReceived: any = 0;
  ItemStatusList = [];
  LocalItemStatusList = [];
  TotalReceivedQty: any;
  PopupRecordId: any;
  PoNum: any;
  totalOrderQty: number = 0;
  totalRecQty: number = 0;
  discountProp: number = 0;
  @ViewChild('completeModel', { static: false }) private completeModel;
  PopupErrorMsg: any;
  PopupMaxDate: any;
  PopupRowNo: any;
  SpecialPartList = [];
  ListDiv: boolean = false;
  ListAngle: any;
  isPartval: boolean = true;
  carrier = '';
  internalNotes: any;
  carrierData: any;
  isBackgroudImage: boolean = false;
  qtyLabel = [];
  oldqtyLabel = [];
  inventoryQty: any;
  Cid: any;
  uniquePartId: any;
  locationNameList: any;
  locationLevelList = [];
  isEdit: boolean = false;
  serialPartId: any;
  constructor(
    public sharedService: SharedService,
    private cdr: ChangeDetectorRef,
    private vendorService: VendorService,
    private customerService: CustomerService,
    private constants: ConstantsService,
    private modalService: NgbModal,
    private router: Router,
    public datepipe: DatePipe,
    private globalChangeService: GlobalChangeService,
    private companyService: CompanyService,
    private partService: PartService
  ) { }
  ngOnInit(): void {
    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);
    } else {
      this.rolePermissionsList = JSON.parse(localStorage.getItem('rolePermissionsList'));
      this.rolePermissionsList = this.rolePermissionsList.filter(x => x.pageId == 1015);
      this.permissionMessage = '';
      if (this.rolePermissionsList != null && this.rolePermissionsList != undefined) {
        if (!this.rolePermissionsList[0].isView) {
          this.permissionMessage += 'view';
        } if (!this.rolePermissionsList[0].isInsert) {
          if (this.permissionMessage != '') {
            this.permissionMessage += ', ';
          }
          this.permissionMessage += 'add';
        } if (!this.rolePermissionsList[0].isUpdate) {
          if (this.permissionMessage != '') {
            this.permissionMessage += ', ';
          }
          this.permissionMessage += 'edit';
        } if (!this.rolePermissionsList[0].isDelete) {
          if (this.permissionMessage != '') {
            this.permissionMessage += ', ';
          }
          this.permissionMessage += 'delete';
        }
      }
      if (this.permissionMessage != '') {
        this.permissionMessage = 'You do not have access to ' + this.permissionMessage + '. Please contact to administrator!';
      }
      this.companyId = localStorage.getItem(this.constants.companyId);
      this.MiscategortyTypeValue = this.constants.MisceType;
      this.userId = localStorage.getItem(this.constants.userId);
      this.buttonValue = this.constants.Save;
      this.SelectedBuyer = this.userId;
      this.typeOfPo = "1";
      this.UnitofMeasurementDropdownList();
      this.getAllCarrier();
      this.getAllTerms();
      var company = this.globalChangeService.getGlobalCompany();
      if (company > 0) {
        this.CompanyValue1 = company;
        this.companyId = company;

        this.Getvendordropdownlist(company);
        this.GetAllCustomer(company);
        this.GetBuyerDropdownList(company);
        this.getAllCompany(company);
        this.getLocationLevel(company);
        this.getAllCompanySuper(company);
        this.PartOrderDropdown = null;
        this.ProductOrderDropdown = null;
        this.selectedCustomerValue = '';
        this.customerDetails = "";
        this.CompanyValue = '';
        this.CompanyDetails = "";
      }
      else {
        this.ErrorModal('Error', 'Please select company.');
      }

      //on change function 
      this.globalChangeService.dropdownChange$.subscribe((data) => {
        this.companyChangeValueMain(data);
        this.getAllCompanySuper(data);
      })

    }
    let today = new Date();
    this.DSMinDate = this.datepipe.transform(today, 'yyyy-MM-dd');
    this.customerDetails = "";
    this.vendordata = "";
    this.CompanyDetails = "";
    this.form = new FormGroup({
      'typeofPoId': new FormControl(),
      'vendorId': new FormControl(),
      'ship': new FormControl(),
      // 'shipcompanyId':new FormControl(),
      'qty': new FormControl(),
      'UnitofMeasureId': new FormControl(),
      'customerId': new FormControl(),
      'companyId': new FormControl(),
      'PO': new FormControl(),
      'DateScheduled': new FormControl(),
      'Status': new FormControl(),
      'buyerId': new FormControl(),
      'PaymentTerms': new FormControl(),
      'FOBPoint': new FormControl(),
      'ShippingTerms': new FormControl(),
      'Notes': new FormControl(),
      'type': new FormControl(),
      'paymenttermId': new FormControl(),
      'CustomerSO': new FormControl(),
      'Service': new FormControl(),
      'ProductOrder': new FormControl(),
      'partOrder': new FormControl(),
      "custompartId": new FormControl(),
      "UpdateQty": new FormControl(),
      "Updatedateschedule": new FormControl(),
      "UpdatecustumerPartNumber": new FormControl(),
      "UpdateDescription": new FormControl(),
      "UpdatevendorpartNumber": new FormControl(),
      "Updateservice": new FormControl(),
      "UpdatevendorParCost": new FormControl(),
      'carrier': new FormControl(),
      'internalNotes': new FormControl(),
      'LocationsId': new FormControl('', Validators.required),
      'partLocation': new FormControl('', Validators.required),
    });

    this.formSearch = new FormGroup({
      "PoSearchTxt": new FormControl('', Validators.required),
      "VendorSearchDDL": new FormControl('', Validators.required),
      "StatusSearchDDL": new FormControl('', Validators.required),
    });

    this.formPopup = new FormGroup({
      "ItemDateReceived": new FormControl('', Validators.required),
      "ItemReceived": new FormControl('', Validators.required)
    });

  }

  SortTableHeader(item: any) {

    if (this.datalist != null) {
      var ret = new Array;
      for (var i = this.datalist.length - 1; i >= 0; i--) {
        ret.push(this.datalist[i]);
      }

      this.datalist = ret;
      var Keys = Object.keys(this.datalist[0]);

      if (Keys.includes(item)) {
        if (this.isBackgroudImage) {
          this.isBackgroudImage = false;
          var element = document.getElementById(item);
          if (element.className === 'sorting1') {
            element.classList.replace('sorting1', 'sorting_asc');
          }
          else if (element.className === 'sorting_asc') {
            element.classList.replace('sorting_asc', 'sorting_desc');
          }
          else {
            element.classList.replace('sorting_desc', 'sorting_asc');
          }
        }
        else {
          this.isBackgroudImage = true;
          var element = document.getElementById(item);
          if (element.className === 'sorting1') {
            element.classList.replace('sorting1', 'sorting_asc');
          }
          else if (element.className === 'sorting_asc') {
            element.classList.replace('sorting_asc', 'sorting_desc');
          }
          else {
            element.classList.replace('sorting_desc', 'sorting_asc');
          }
        }
      }

      const result = Keys.filter(key => key != item);

      if (result) {
        result.forEach(data => {
          var element = document.getElementById(data);
          if (element != null) {
            if (element.className === 'sorting1') {
              element.classList.replace('sorting1', 'sorting1');
            }
            else if (element.className === 'sorting_asc') {
              element.classList.replace('sorting_asc', 'sorting1');
            }
            else {
              element.classList.replace('sorting_desc', 'sorting1');
            }
          }
        });
      }
    }
  }

  ListDivShowHide() {
    if (this.ListDiv == true) {
      this.ListDiv = false;
      this.ListAngle = "down";
    }
    else if (this.ListDiv == false) {
      this.ListDiv = true;
      this.ListAngle = "up";
    }
  }

  SetSettingCSS(settingCSS, logoImagePath) {
    if (settingCSS != null) {
      let headerCss = document.getElementsByClassName(this.constants.CssHealerClass) as HTMLCollectionOf<HTMLElement>;
      if (headerCss.length != 0) {
        headerCss[0].style.backgroundColor = settingCSS.split(',')[0];
      }
      let saveButtonCss = document.getElementsByClassName(this.constants.CssSaveBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (saveButtonCss.length != 0) {
        saveButtonCss[0].style.backgroundColor = settingCSS.split(',')[1];
        $(".blue-bg").css("background", settingCSS.split(',')[1]);
      }
      let cencelButtonCss = document.getElementsByClassName(this.constants.CssCancelBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (cencelButtonCss.length != 0) {
        cencelButtonCss[0].style.backgroundColor = settingCSS.split(',')[2];
        $(".darkred-bg").css("background", settingCSS.split(',')[2]);
      }
    }
    if (logoImagePath != null && logoImagePath != "") {
      (document.getElementById('hrdlogoImage') as HTMLImageElement).src = logoImagePath;
    }
  }

  deleteCustomPartPopup(item, contentPart) {

    this.modalService.open(contentPart, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
    this.deleteDetail = this.constants.DeletePopup;
    this.deleteCustomPart = item;
  }

  getAllCarrier() {
    this.vendorService.getAllCarrier().subscribe(
      data => {
        this.data = data;
        this.carrierData = this.data.dataList;
      });
  }

  Getvendordropdownlist(companyId) {
    this.partService.Getvendordropdownlist(companyId).subscribe(
      data => {

        this.data = data;
        if (companyId > 0) {
          this.vendorlist = this.data.dataList;
        }
        else {
          this.selectedVendorSearch = '0';
        }
      }
    )
  }



  ChngSettingImage(files: FileList) {
    this.fileToUpload = files.item(0);
    var reader = new FileReader()
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);
  }

  resetvendorData() {
    //salman khan
    this.typeOfPo = '1';
    this.selectedstatusvalue = '1';
    this.selectedVendorValue = '';
    this.CompanyValue = 0;
    this.selectedCustomerValue = '';
    this.selectedCustomerValue = '';
    //this.PO = '';
    this.DateScheduled = '';
    this.PaymentTerms = '';
    this.selectedFobPointValue = '';
    this.selectedshippingValue = '';
    this.carrier = '';
    this.Note = '';
    this.qty = 1;
    this.selectedPartValue = '';
    this.selectedvaluetype1 = '';
    this.partList = [];
    this.SubTotalVendorCost = '';
    this.vendordata = '';
    this.CompanyDetails = '';
    this.customerDetails = '';
    this.validation = false;
    this.validation1 = false;
    this.validation2 = false;
    this.EditPOPrefixNumber = "N/A";
    this.PartsDetails = [];
    this.RcvdPartsDetails = [];
    this.SelectedLocation = '';
    this.SelectedPartLocation = '';
    $('input[name="ship"]').prop('checked', false);
    this.internalNotes = '';
    this.discountProp = 0;
    this.partListCustomPart = [];
    this.partListWithoutCustomPart = [];
    this.SpecialPartList = [];
  }

  getListBack() {
    this.selectedVendorSearch = '0';
    this.selectedStatusSearch = '0';
    this.SearchByDropDown();
  }

  GetStatusText(statusId) {
    return this.POstatusList.find(s => s.id == statusId).name;
  }

  SearchByPONumber() {
    if (!this.PoSearchTxt || this.companyId == 0) {
      this.SearchPOValidation = true;
      this.SearchDDLValidation = false;
      return;
    }
    else {
      this.selectedVendorSearch = '0';
      this.selectedStatusSearch = '0';
      this.RecOrderListShow = false;
      this.toggleEditByPONumber(this.PoSearchTxt);
    }

  }

  SearchByPOList(PoNumber) {
    this.toggleEditByPONumber(PoNumber);
  }

  searchByPO() {
    this.SearchType = false;
    if (!this.PoSearchTxt || this.SearchCompany_Value == 0) {
      this.SearchPOValidation = true;
      return;
    }
    this.ListShow = true;
    this.ListDiv = true;
    this.SearchByDropDown();
  }

  SearchByDropDown() {
    if (this.companyId == 0 || (!this.selectedStatusSearch && !this.selectedVendorSearch && !this.PoSearchTxt)) {
      this.SearchPOValidation = false;
      this.SearchDDLValidation = true;
      return;
    }
    else {
      this.SearchDDLValidation = false;
      let vendorId = this.selectedVendorSearch == '' ? '0' : this.selectedVendorSearch;
      let statusId = this.selectedStatusSearch == '' ? '0' : this.selectedStatusSearch;


      this.loader = true;
      if (this.PoSearchTxt) {
        vendorId = "0";
        statusId = "0";
      }
      else {
        this.PoSearchTxt = "";
      }
      this.RecOrderListShow = true;
      this.resetvendorData();
      document.body.scrollTop = document.documentElement.scrollTop = 0;

      $('#datatable').DataTable().destroy();
      this.dtTrigger.next('');
      this.dtOptions = {
        pagingType: 'full_numbers',
        pageLength: 10,
        serverSide: true,
        ordering: false,
        //processing: true,
        ajax: (dataTablesParameters: any, callback) => {
          this.sharedService.getReceiveOrderSearch(this.companyId, 2, vendorId, dataTablesParameters.start, dataTablesParameters.length, this.PoSearchTxt)
            .subscribe(resp => {
              this.RecOrderListShow = true;
              this.ListDiv = true;
              this.ListAngle = this.constants.UpArrow;
              this.loader = false;
              let totalRows = null;
              this.data = resp;

              if (this.data.dataList.length > 0) {
                this.datalist = this.data.dataList;
                totalRows = [...new Set(this.datalist.map(item => item.totalRows))];
              }
              else {
                this.datalist = null;
                totalRows = 0;
              }

              callback({
                recordsTotal: totalRows,
                recordsFiltered: totalRows,
                data: []
              });
            });
        }
      };
      this.rerender();

    }

  }

  ShowPurchaseOrderList() {
    $("#Hideshow").show();
  }

  toggleEditByPONumber(PoNumber: any) {
    this.ListDiv = false;
    this.ListAngle = "down";
    this.PoNum = PoNumber;
    this.buttonValue = this.constants.Update;
    this.clearbutton = false;
    this.partListWithoutCustomPart = [];
    this.partListCustomPart = [];
    this.SpecialPartList = [];
    this.srNo = 1;
    this.customArrayIndex = 0;
    this.sharedService.GetPODetailsByPONumber(PoNumber, this.companyId).subscribe(
      (data: any) => {
        if (data.data == null) {
          this.resetvendorData();
          this.UpdateClear = false;
          $("#no_record").show();
        }
        else {
          if (Number(data.data.statusId) == 1) {
            this.resetvendorData();
            this.UpdateClear = false;
            $("#no_record").show();
            return;
          }
          this.UpdateClear = true;
          this.LocalItemStatusList = [];
          this.id = data.data.id;
          this.selectedVendorValue = data.data.vendorId;
          this.vendorId = data.data.vendorId;
          this.DateScheduled = this.datepipe.transform(data.data.dateSchedule, 'yyyy-MM-dd');
          this.CompanyValue1 = data.data.companyId;
          this.companyId = data.data.companyId;
          this.SelectedBuyer = data.data.buyerId;
          this.PO = data.data.po;
          this.EditPOPrefixNumber = data.data.purchaseOrderPrefix;
          this.SubTotalVendorCost = data.data.totalAmount;
          this.selectedstatusvalue = data.data.statusId;
          this.PaymentTerms = !data.data.paymentTermsId ? "" : data.data.paymentTermsId;
          this.selectedFobPointValue = !data.data.fobPointId ? "" : data.data.fobPointId;
          this.selectedshippingValue = !data.data.shippingId ? "" : data.data.shippingId;
          this.Note = data.data.notes;
          this.carrier = !data.data.carrier ? '' : data.data.carrier;
          this.internalNotes = data.data.internalNotes;
          this.SelectedLocation = data.data.locationName == undefined ? '' : data.data.locationName;
          this.getPartLocationLevel(3, data.data.lname);
          this.SelectedPartLocation = data.data.location == undefined ? '' : data.data.location;
          this.GetAllCustomer(data.data.companyId);
          this.getAllCompany(data.data.companyId);
          this.GetBuyerDropdownList(data.data.companyId);
          this.EditPOVendorDetail(this.selectedVendorValue);
          if (data.data.isShipCustomer == true) {
            this.Shipcustomer = true;
            this.Shipcompany = false;
            this.selectedCustomerValue = data.data.customerId;
            this.POGetAllCustomerByCustomerId(this.selectedCustomerValue);
            this.PartOrderDrodownList(this.CompanyValue1, this.selectedCustomerValue, 0);
            this.ProductOrderDrodownList(this.CompanyValue1, this.selectedCustomerValue, 0);
            this.shiptoradiobutton = "customer";
            $("INPUT[name=ship]").val(['customer']);
          }
          if (data.data.isShipCompany == true) {
            this.Shipcompany = true;
            this.Shipcustomer = false;
            this.CompanyValue = data.data.companyLocationId;
            this.GetCompanyDetails(this.CompanyValue);
            this.PartOrderDrodownList(0, 0, this.CompanyValue);
            this.ProductOrderDrodownList(0, 0, this.CompanyValue);
            this.shiptoradiobutton = "companyLocation";
            $("INPUT[name=ship]").val(['companyLocation']);
          }

          if (data.data.purchaseOrderPart.length > 0) {
            this.btnShowvalidation = true;
            this.partList = data.data.purchaseOrderPart;
            this.partList.forEach(partLst => {
              partLst.Id = partLst.id;
              partLst.partId = partLst.partId;
              partLst.item = partLst.itemNumber;
              partLst.dateschedule = this.datepipe.transform(partLst.dateSchedule, 'MMM-dd-yyyy');
              partLst.qty = partLst.qty;
              partLst.qtyToFulFill = partLst.qtyToFulFill;
              partLst.recQty = !partLst.recQty ? 0 : partLst.recQty;
              partLst.service = !partLst.service ? "" : partLst.service;
              partLst.custumerPartName = partLst.custPartName;
              partLst.custumerPartNumber = partLst.customerPartNumber;
              partLst.vendorPartName = partLst.vendPartName;
              partLst.vendorpartNumber = partLst.vendorPartNumber;
              partLst.description = partLst.description;
              partLst.unitOfMeasurementName = !partLst.uomtype ? "" : this.unitofmeasurement.find(x => x.id == partLst.uomtype).name;
              partLst.partOrderId = !partLst.partOrderId ? "" : partLst.partOrderId;
              partLst.productOrderId = !partLst.productOrderId ? "" : partLst.productOrderId;
              partLst.Type = !this.POTypeList ? "" : this.POTypeList.find(x => x.id == partLst.typeId).name;
              partLst.vendorParCost = (!partLst.unitCost ? 0 : partLst.unitCost).toFixed(5);;
              partLst.lastRcvdDate = partLst.lastRcvdDate;
              partLst.BaseUnit = partLst.baseUnitCost;
              partLst.VendorName = partLst.vendorName;
              partLst.vendorId = partLst.vendorId;
              partLst.Discount = partLst.discount;
              partLst.isEcommOrder = partLst.isEcommOrder;
              partLst.virtual = partLst.virtual;
              partLst.custompartId = partLst.custompartId;
              partLst.customArrayIndex = this.customArrayIndex;
              partLst.isCustompart = partLst.isCustompart;
              partLst.tracking = partLst.tracking;
              this.customArrayIndex += 1;


              if (Number(partLst.recQty) > 0) {
                if (Number(partLst.recQty) == Number(partLst.qty)) {
                  partLst.bcolor = 'forestgreen';
                }
                else {
                  partLst.bcolor = 'yellow';
                }
              }
              else {
                if (!partLst.dateSchedule) {
                  let today = new Date();
                  let dateString = partLst.dateSchedule;
                  let sdate = new Date(dateString);

                  if (today.getDay() == sdate.getDay() && today.getMonth() == sdate.getMonth() && today.getFullYear() == sdate.getFullYear()) {
                    partLst.bcolor = 'silver';
                  }
                  else {
                    if (today.getTime() > sdate.getTime()) {
                      partLst.bcolor = 'firebrick';
                    }
                    else {
                      partLst.bcolor = 'silver';
                    }
                  }
                }
                else {
                  partLst.bcolor = 'firebrick';
                }
              }
            });

            this.partListWithoutCustomPart = this.partList.filter(x => (x.virtual == false || x.virtual == null) && x.customPartId == null);
            this.partListWithoutCustomPart.forEach(partLst => {
              partLst.srNo = this.srNo;
              this.srNo += 1;
            });

            this.partListCustomPart = this.partList.filter(x => x.virtual == true);
            this.partListCustomPart.forEach(partLst => {
              if (this.partList.filter(x => x.customPartId == partLst.partId).length > 0) {
                partLst.srNo = this.srNo;
                if (partLst.partOrderId != "") {
                  partLst.PartList = this.partList.filter(x => x.customPartId == partLst.partId && x.partOrderId == partLst.partOrderId);

                }
                else {
                  partLst.PartList = this.partList.filter(x => x.customPartId == partLst.partId && x.productOrderId == partLst.productOrderId);

                }
                this.srNo += 1;
              }
            });
            this.SpecialPartList.push(...this.partListWithoutCustomPart, ...this.partListCustomPart);
          }
          this.SpecialPartList = this.SpecialPartList.sort((a, b) => (a.id > b.id ? 1 : -1))
          this.vendorService.GetVendorById(this.vendorId).subscribe(
            (data: any) => {
              this.loader = false;
              if (data.data.discount != 0) {
                this.discountProp = data.data.discount * 100;
              }
              else {
                this.discountProp = 0;
              }
            });
        }
      });
  }

  toggleSub() {
    this.partList.forEach(partLst => {
      if (this.PartOrderDropdown) {
        const getData = this.PartOrderDropdown.filter(x => x.id == partLst.partOrderId);
        if (getData.length > 0) {
          // partLst.partOrder = !partLst.partOrderId ? "" : this.PartOrderDropdown.find(x => x.id == partLst.partOrderId).name;
          partLst.partOrder = getData[0].name;
        }
        else {
          partLst.partOrder = "";
        }
      }
      if (this.ProductOrderDropdown) {
        const getData = this.ProductOrderDropdown.filter(x => x.id == partLst.productOrderId);
        if (getData.length > 0) {
          // partLst.productOrder = !partLst.productOrderId ? "" : this.ProductOrderDropdown.find(x => x.id == partLst.productOrderId).name;
          partLst.productOrder = getData[0].name;
        }
        else {
          partLst.productOrder = "";
        }
      }
    });
  }

  UpdateConfirmPO() {
    if (this.SelectedLocation == '' || this.SelectedPartLocation == ''
    ) {
      this.validation = true;
      return;
    }
    else {
      this.validation = false;
    }
    this.totalOrderQty = 0;
    this.totalRecQty = 0;

    const PartList = this.partList.filter(x => x.isCustompart == false);
    PartList.forEach(partLst => {
      this.totalOrderQty += partLst.qty;
      this.totalRecQty += partLst.recQty;
    });


    if (this.selectedstatusvalue != "4") {
      if (this.totalOrderQty == this.totalRecQty) {
        this.modalService.dismissAll();
        this.modalService.open(this.completeModel, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
      }
      else {
        this.UpdatePurchaseOrder();
      }
    }
    else {
      this.UpdatePurchaseOrder();
    }
  }

  UpdateCompletePO() {
    this.selectedstatusvalue = "4";
    this.UpdatePurchaseOrder();
  }

  UpdatePurchaseOrder() {
    $("#Hideshow").show();
    this.validation = true;
    this.validation1 = false;

    if (this.companyId == 0) {
      this.SearchPOValidation = true;
      return;
    }

    if (this.selectedVendorValue == '' ||
      this.SelectedBuyer == '' ||
      this.selectedstatusvalue == '' || this.SelectedLocation == '' || this.SelectedPartLocation == ''
    ) {
      return;
    }
    if (this.partList.length == 0) {
      $("#myModal").show();
      return;
    }
    let obj = {};
    obj['TypeofPo'] = parseInt(this.typeOfPo);
    obj['vendorId'] = parseInt(this.selectedVendorValue);
    if (this.shiptoradiobutton == undefined) {
      obj["IsShipCustomer"] = false;
      obj["IsShipCompany"] = false;
    }
    if (this.shiptoradiobutton == "customer") {
      obj["IsShipCustomer"] = true;
      obj["IsShipCompany"] = false;
    }
    if (this.shiptoradiobutton == "companyLocation") {
      obj["IsShipCustomer"] = false;
      obj["IsShipCompany"] = true;
    }
    obj['CompanyLocationId'] = this.CompanyValue == 0 ? null : this.CompanyValue == null ? null : Number(this.CompanyValue);
    obj['CompanyId'] = parseInt(this.companyId);
    obj['customerId'] = this.selectedCustomerValue == "" ? null : Number(this.selectedCustomerValue);
    obj['BuyerId'] = this.SelectedBuyer;
    obj['PrefixYear'] = this.Prefixyear;
    obj['PurchaseOrderPrefix'] = this.EditPOPrefixNumber;
    obj['Po'] = this.PO;
    obj['DateSchedule'] = this.DateScheduled == "" ? null : this.DateScheduled;
    obj['StatusId'] = parseInt(this.selectedstatusvalue);
    obj['PaymentTermsId'] = parseInt(this.PaymentTerms);
    obj['FobPointId'] = parseInt(this.selectedFobPointValue);
    obj['ShippingId'] = parseInt(this.selectedshippingValue);
    obj['Carrier'] = Number(this.carrier);
    obj['Notes'] = this.Note;
    obj['InternalNotes'] = this.internalNotes;
    obj['LoggedEmail'] = localStorage.getItem(this.constants.userEmail) == "" ? '' : localStorage.getItem(this.constants.userEmail);
    obj['LoggedName'] = localStorage.getItem(this.constants.userName) == "" ? '' : localStorage.getItem(this.constants.userName);
    obj['TotalAmount'] = this.SubTotalVendorCost;
    obj["Isactive"] = true;
    obj["CreatedBy"] = this.userId;
    obj["CreatedDate"] = this.now = new Date();
    obj["LocationName"] = this.SelectedLocation;
    obj["Location"] = this.SelectedPartLocation;
    obj["Lname"] = this.selectedLocationName
    obj["CreatedBy"] = this.userId;
    let dataPart = this.partList;
    for (var j = 0; j < dataPart.length; j++) {
      let partObj = {};
      partObj["Id"] = dataPart[j].id;
      partObj["PartId"] = dataPart[j].partId;
      partObj["ItemNumber"] = dataPart[j].item;
      partObj["DateSchedule"] = dataPart[j].dateschedule == undefined ? null : this.datepipe.transform(dataPart[j].dateschedule, 'yyyy-MM-dd');
      partObj["Qty"] = dataPart[j].qty == undefined ? null : dataPart[j].qty;
      partObj["QtyToFulFill"] = dataPart[j].qtyToFulFill == undefined ? null : dataPart[j].qtyToFulFill;
      partObj["CustPartName"] = dataPart[j].custumerPartName;
      partObj["CustomerPartNumber"] = dataPart[j].custumerPartNumber == undefined ? null : dataPart[j].custumerPartNumber;
      partObj["VendPartName"] = dataPart[j].vendorPartName;
      partObj["VendorPartNumber"] = dataPart[j].vendorpartNumber == undefined ? null : dataPart[j].vendorpartNumber;
      partObj["Description"] = dataPart[j].description == undefined ? null : dataPart[j].description;
      partObj["UOMType"] = dataPart[j].uomtype == undefined ? null : dataPart[j].uomtype == "" ? null : parseInt(dataPart[j].uomtype);
      partObj["TypeId"] = dataPart[j].typeId == undefined ? null : dataPart[j].typeId == "" ? null : parseInt(dataPart[j].typeId);
      partObj["PartOrderId"] = dataPart[j].partOrderId == undefined ? null : dataPart[j].partOrderId == "" ? null : parseInt(dataPart[j].partOrderId);
      partObj["ProductOrderId"] = dataPart[j].productOrderId == undefined ? null : dataPart[j].productOrderId == "" ? null : parseInt(dataPart[j].productOrderId);
      partObj["UnitCost"] = parseFloat(dataPart[j].vendorParCost);
      partObj["SubTotal"] = dataPart[j].subtotal;
      partObj["TotalAmount"] = dataPart[j].totalamout;
      partObj["Service"] = dataPart[j].service;
      partObj["vendorId"] = dataPart[j].vendorId;
      partObj["discount"] = this.discountProp;
      partObj["baseUnitCost"] = parseFloat(dataPart[j].BaseUnit);
      partObj["isEcommOrder"] = dataPart[j].isEcommOrder;
      partObj["Isactive"] = true;
      partObj["CreatedBy"] = this.userId;
      partObj["CreatedDate"] = this.now = new Date();
      if (dataPart[j].isCustompart == true) {
        partObj["recQty"] = dataPart[j].qty;
      }
      else {
        partObj["recQty"] = dataPart[j].recQty == undefined ? 0 : parseInt(dataPart[j].recQty);
      }
      partObj["LastRcvdDate"] = dataPart[j].lastRcvdDate ? dataPart[j].lastRcvdDate : null;
      partObj["Virtual"] = dataPart[j].virtual;
      partObj["CustomPartId"] = dataPart[j].customPartId;
      partObj["isCustompart"] = dataPart[j].isCustompart;
      partObj["tracking"] = dataPart[j].tracking;
      this.PartsDetails.push(partObj);
    }
    obj["PurchaseOrderPart"] = this.PartsDetails;
    const newArr = [];
    let rcvdPart = this.LocalItemStatusList;
    for (var k = 0; k < rcvdPart.length; k++) {
      let rcvdPartObj = {};
      rcvdPartObj["Id"] = rcvdPart[k].id;
      rcvdPartObj["PoId"] = rcvdPart[k].poId;
      rcvdPartObj["CompanyId"] = rcvdPart[k].companyId;
      rcvdPartObj["ItemId"] = rcvdPart[k].itemId;
      rcvdPartObj["DateReceived"] = this.datepipe.transform(rcvdPart[k].dateReceived, 'yyyy-MM-dd');
      rcvdPartObj["QtyReceived"] = rcvdPart[k].qtyReceived;
      rcvdPartObj["IsActive"] = rcvdPart[k].isActive;
      rcvdPartObj["CreatedBy"] = rcvdPart[k].createdBy;
      rcvdPartObj["CreatedDate"] = rcvdPart[k].createdDate;
      rcvdPartObj["ModifiedBy"] = rcvdPart[k].modifiedBy;
      rcvdPartObj["Tracking"] = String(rcvdPart[k].invTracking);
      const arr = [];
      rcvdPartObj["ModifiedDate"] = rcvdPart[k].modifiedDate;
      if (rcvdPart[k].receiveSerials != undefined) {
        rcvdPart[k].receiveSerials.forEach(element => {
          let serObj = {};
          serObj["Id"] = element.SerialId;
          serObj["Part"] = element.part;
          serObj["SerialNo"] = element.serialNo;
          serObj["PartId"] = element.partId;
          serObj["PartInventoryId"] = parseInt(element.partInventoryId);
          serObj["CompanyId"] = this.CompanyValue1;
          serObj["IsActive"] = element.isActive;
          serObj["Ordering"] = element.ordering;
          serObj["type"] = element.type;
          if (element.serialNo != undefined) {
            arr.push(element.serialNo);
            newArr.push(serObj);
          }

        });
      }

      rcvdPartObj["Serials"] = String(arr);

      const list = [];
      if (rcvdPart[k].receiveSerials != undefined) {
        rcvdPart[k].receiveSerials.forEach((element, Index) => {
          let serObj = {};
          serObj["Id"] = element.SerialId;
          serObj["Part"] = element.part;
          serObj["SerialNo"] = element.serialNo;
          serObj["PartId"] = element.partId;
          serObj["IsSale"] = true;
          serObj["type"] = element.type;
          serObj["CompanyId"] = this.CompanyValue1;
          serObj["IsActive"] = element.isActive;
          serObj["Ordering"] = element.ordering;
          if (element.serialNo != undefined) {
            list.push(serObj);
          }
        });
      }

      rcvdPartObj["ReceiveSerials"] = list;
      this.RcvdPartsDetails.push(rcvdPartObj);
    }
    obj["POItemStatusModel"] = this.RcvdPartsDetails;
    obj["PartSerialNumber"] = newArr;

    obj['Id'] = this.id;
    obj['discount'] = this.discountProp;
    obj['ModifiedBy'] = this.userId;
    obj['CreatedBy'] = this.userId;
    obj['CreatedDate'] = this.now = new Date();
    obj['ModifiedDate'] = this.now = new Date();
    this.loader = true;
    this.sharedService.UpdatePurchaseOrder(obj)
      .subscribe(
        data => {
          switch (data.statusCode) {
            case 200:
              this.modalService.dismissAll();
              $("#add_update").show();
              this.validation = false;
              // this.clearbutton = true;
              this.resetvendorData();
              this.getListBack();
              this.id = null;
              break;
            case 400:
              $("#wrong_msg").show();
              break;
            default:
              $("#wrong_msg").show();
          }
          this.loader = false;
          this.validation = false;
          this.PoSearchTxt = '';
        },
        error =>{
          this.loader = false;
        },
        () =>{
          this.loader = false;
        }
      );
  }

  MiscePartCost() {
    var input_val = this.misccost;
    var original_len = input_val.length;
    if (input_val != "") {
      if (input_val.indexOf(".") >= 0) {
        var decimal_pos = input_val.indexOf(".");
        var l = input_val.substring(0, decimal_pos);
        var r = input_val.substring(decimal_pos, decimal_pos + 3);
        var right_side = input_val.substring(decimal_pos, 2);
        this.misccost = l + "" + r;
      } else {
        this.misccost = this.misccost + ".00";
      }
    }
  }

  getAllCompany(companyId) {
    this.companyList = '';
    // this.userGroupObjects = [];
    this.companyService.GetDropdownCompanyHierarchyModel(this.companyId).subscribe(
      data => {
        this.data = data;
        if (companyId > 0) {
          this.items = this.data.dataList;
          this.companyList = this.data.dataList;
          this.userGroupObjects = [];
          // this.companyList.forEach((x) => {
          //   this.userGroupObjects.push(new TreeviewItem(x, false));
          // });
        }
        else {

        }

      });
  }

  PartBack() {
    this.clearbutton = true;
    this.id = null;
    this.clearbutton = true;
    this.UpdateClear = false;
    this.buttonValue = this.constants.Save;
    this.resetvendorData();
  }


  changebuyer(event) {
    this.SelectedBuyer = event.target.value;
    //(this.SelectedBuyer);
  }

  changrPruductOrder(event) {
    if (event.target.value) {
      this.loader = true;
      this.productOrderId = parseInt(event.target.value);
      this.selectedPartValue = '';
      this.sharedService.getallpartbySO(this.productOrderId, this.vendorId).subscribe(
        data => {
          this.loader = false;
          this.data = data;
          let ddlData = this.data.dataList;
          switch (this.data.statusCode) {
            case 200:
              this.ddlPartList = ddlData;
              this.PartCategoryList = ddlData;
              break;
            case 400:
              this.ddlPartList = [];
              break;
            default:
              $("#wrong_msg").show();
            // this.toastr.error(this.constants.SomethingWentWrong);
          }
        });
    }
    else {
      this.productOrderId = '';
      this.ddlPartList = [];
    }

  }

  changePartorder(event) {
    if (event.target.value) {
      this.loader = true;
      this.selectedPartOrder = event.target.value;
      this.selectedProductOrdervaluetype = '';
      this.sharedService.getallpartbySO(this.selectedPartOrder, this.vendorId).subscribe(
        data => {
          this.loader = false;
          this.data = data;
          let ddlData = this.data.dataList;
          switch (this.data.statusCode) {
            case 200:
              this.ddlPartList = ddlData;
              this.PartCategoryList = ddlData;
              break;
            case 400:
              this.ddlPartList = [];
              break;
            default:
              $("#wrong_msg").show();
            // this.toastr.error(this.constants.SomethingWentWrong);
          }
        });
    }
    else {
      this.selectedPartOrder = '';
      this.ddlPartList = [];
    }
  }

  DeletePart() {
    if (this.deleteCustomPart > -1) {
      this.partList.splice(this.deleteCustomPart, 1);
      this.SubTotalVendorCost = 0;
      if (this.partList.length > 0) {
        this.partList.forEach(partLst => {
          this.SubTotalVendorCost += Number(partLst.vendorParCost * partLst.quantity);
        });
      }
    }
  }

  EditPartRow(arrIndex, rowText) {
    this.normalShow = true;
    this.RowEdit = true;
    if (rowText == "editRow") {
      this.CurrentEditRow = "Edit";
      this.RowEdit = false;
      this.EditRowIndex = arrIndex;
      this.EditRowTxtName = "editRow";
      this.btnOkShow = true;
      this.editrowdata = false;
      this.btnEditDeleteShow = false;

      this.UpdateQty = this.partList[arrIndex].qty;
      this.Updateservice = this.partList[arrIndex].service;
      this.UpdatevendorParCost = this.partList[arrIndex].vendorParCost;
      this.selectedPartOrder = !this.partList[arrIndex].partOrderId ? "" : this.partList[arrIndex].partOrderId;
      this.selectedProductOrdervaluetype = !this.partList[arrIndex].productOrderId ? "" : this.partList[arrIndex].productOrderId;
      this.selectedvaluetype = !this.partList[arrIndex].typeId ? "" : this.partList[arrIndex].typeId;
      this.EditTypeStatus(this.partList[arrIndex].typeId);
    }
  }

  EditTypeStatus(typeId) {
    if (typeId == 5) {
      this.IsServicesEdit = true;
      this.IsProductTypeEdit = false;
      this.IsPartTypeEdit = false;
    }

    else if (typeId == 6) {
      this.IsServicesEdit = false;
      this.IsProductTypeEdit = false;
      this.IsPartTypeEdit = true;
    }
    else if (typeId == 7) {
      this.IsServicesEdit = false;
      this.IsProductTypeEdit = true;
      this.IsPartTypeEdit = false;
    }

    else {
      this.IsServicesEdit = false;
      this.IsProductTypeEdit = false;
      this.IsPartTypeEdit = false;
    }
  }

  UpdatePartRow(arrIndex) {
    this.editrowdata = true;

    this.partList[arrIndex].qty = Number(this.UpdateQty);
    this.partList[arrIndex].vendorParCost = this.UpdatevendorParCost == "" ? 0 : this.UpdatevendorParCost;
    this.partList[arrIndex].typeId = this.selectedvaluetype;
    this.partList[arrIndex].Type = !this.selectedvaluetype ? '' : this.POTypeList.find(x => x.id == Number(this.selectedvaluetype)).name;
    this.partList[arrIndex].partOrder = !this.selectedPartOrder ? "" : this.selectedvaluetype != "6" ? "" : this.PartOrderDropdown.find(x => x.id == this.selectedPartOrder).name;
    this.partList[arrIndex].productOrder = !this.selectedProductOrdervaluetype ? "" : this.selectedvaluetype != "7" ? "" : this.ProductOrderDropdown.find(x => x.id == this.selectedProductOrdervaluetype).name;

    if (this.selectedvaluetype == "5") {
      this.partList[arrIndex].service = this.selectedvaluetype == "5" ? this.Updateservice : "";
      this.partList[arrIndex].partOrderId = null;
      this.partList[arrIndex].productOrderId = null;
    }
    else if (this.selectedvaluetype == "6") {
      this.partList[arrIndex].partOrderId = !this.selectedPartOrder ? null : Number(this.selectedPartOrder);
      this.partList[arrIndex].productOrderId = null;
      this.partList[arrIndex].service = "";
    }

    else if (this.selectedvaluetype == "7") {
      this.partList[arrIndex].productOrderId = !this.selectedProductOrdervaluetype ? null : Number(this.selectedProductOrdervaluetype);
      this.partList[arrIndex].partOrderId = null;
      this.partList[arrIndex].service = "";
    }
    else {
      this.partList[arrIndex].productOrderId = null;
      this.partList[arrIndex].partOrderId = null;
      this.partList[arrIndex].service = "";
    }

    this.normalShow = true;
    this.RowEdit = true;
    this.CurrentEditRow = "";
    this.EditRowTxtName = "";
    this.btnEditDeleteShow = true;
    this.btnOkShow = false;
    this.VendorPartCost = 0;
    this.SubTotalVendorCost = 0;
    if (this.partList.length > 0) {
      this.partList.forEach(partLst => {
        this.SubTotalVendorCost += Number(partLst.vendorParCost * partLst.qty);
      });
    }
  }

  CancleUpdatePartRow() {
    this.btnOkShow = false;
    this.btnEditDeleteShow = true;
    this.EditRowPartId = 0;
    this.editrowdata = true;
    this.normalShow = true;
    this.RowEdit = true;
    this.CurrentEditRow = "";
    this.EditRowTxtName = "";
  }

  UnitofMeasurementDropdownList() {

    this.partService.UnitofMeasurement().subscribe(
      data => {

        this.data = data;
        this.unitofmeasurement = this.data.dataList;
        //this.Getquestiondropdownlist();
      }
    )
  }

  GetAllCustomer(companyid) {
    this.customerService.GetAllCustomer(this.companyId, '').subscribe(
      data => {
        this.data = data;
        if (companyid > 0) {
          this.customerList = this.data.dataList;
        }
        else {
          this.selectedCustomerValue = ''
        }
      }
    )
  }

  ShipType(value) {

    this.shiptoradiobutton = value;
    this.PartOrderDropdown = null;
    this.ProductOrderDropdown = null;

    if (value == "customer") {
      this.Shipcustomer = true;
      this.Shipcompany = false;
      this.selectedCustomerValue = '';
      this.customerDetails = "";
      // this.GetAllCustomer();
    }
    else {
      this.Shipcustomer = false;
      this.Shipcompany = true;
      this.CompanyDetails = "";
      this.CompanyValue = '';
      //this.getAllCompany(this.companyId);

    }
  }

  getAllTerms() {
    this.vendorService.getAllTerms().subscribe(
      data => {
        this.data = data;
        this.termsData = this.data.dataList
        this.FobPointDropdownList();
      });
  }

  FobPointDropdownList() {

    this.partService.FobPointsDropdownList().subscribe(
      data => {
        this.data = data;
        this.FobPoint = this.data.dataList;
        this.getAllShippings();
      }
    )
  }

  getAllShippings() {
    this.vendorService.getAllShippings().subscribe(
      data => {
        this.data = data;
        this.shippingData = this.data.dataList;
        this.POStatusDropdownList();
      });
  }

  POTypeDropdownList() {

    this.sharedService.GetPOTypeDetail().subscribe(
      data => {

        this.data = data;
        this.POTypeList = this.data.dataList;
      }
    )
  }

  POStatusDropdownList() {

    this.sharedService.GetPOStatusDropdownList().subscribe(
      data => {
        this.data = data;
        this.POstatusList = this.data.dataList;
        this.POTypeDropdownList();
      }
    )
  }

  ChangeVendorDropdow(event) {
    if (event.target.value != "") {
      this.vendorId = event.target.value;
      this.POVendorDetail(this.vendorId);
      this.vendorService.GetVendorById(this.vendorId).subscribe(
        (data: any) => {
          this.loader = false;
          if (data.data.discount != 0) {
            this.discountProp = data.data.discount * 100;
          }
          else {
            this.discountProp = 0;
          }
          if (this.partList.length > 0) {
            for (let i = 0; i < this.partList.length; i++) {
              this.partList[i].vendorId = this.partList[i].vendorId;
              this.partList[i].vendorName = this.partList[i].vendorName;
              this.partList[i].Discount = this.discountProp;
              this.partList[i].partId = this.partList[i].partId;
              this.partList[i].item = this.partList[i].item;
              this.partList[i].dateorder = this.partList[i].dateorder;
              this.partList[i].dateschedule = this.partList[i].dateschedule;
              this.partList[i].qty = this.partList[i].qty;
              this.partList[i].custumerPartName = this.partList[i].custumerPartName;
              this.partList[i].custumerPartNumber = this.partList[i].custumerPartNumber;
              this.partList[i].vendorPartName = this.partList[i].vendorPartName;
              this.partList[i].vendorpartNumber = this.partList[i].vendorpartNumber;
              this.partList[i].description = this.partList[i].description;
              this.partList[i].typeId = this.partList[i].typeId;
              this.partList[i].uomtype = this.partList[i].uomtype;
              this.partList[i].unitOfMeasurementName = this.partList[i].unitOfMeasurementName;
              this.partList[i].Type = this.partList[i].Type;
              this.partList[i].CustomerSO = this.partList[i].CustomerSO;
              this.partList[i].service = this.partList[i].service;
              this.partList[i].partOrderId = this.partList[i].partOrderId;
              this.partList[i].productOrderId = this.partList[i].productOrderId;
              this.partList[i].partOrder = this.partList[i].partOrder;
              this.partList[i].productOrder = this.partList[i].productOrder;
              this.partList[i].productOrder = this.partList[i].productOrder;
              const disvalue = (Number(this.discountProp) / 100);
              this.partList[i].vendorParCost = (this.partList[i].BaseUnit - (this.partList[i].BaseUnit * disvalue)).toFixed(5)
              this.partList[i].subtotal = this.partList[i].vendorParCost * this.partList[i].qty;
              this.partList[i].BaseUnit = this.partList[i].BaseUnit;

            }
          }
          if (this.partList.length > 0) {
            this.SubTotalVendorCost = 0;
            this.partList.forEach(partLst => {
              this.SubTotalVendorCost += partLst.vendorParCost * partLst.qty;
            });
          }
        });
    }
    else {
      this.vendorId = '';
      this.discountProp = 0;
      this.vendordata = "";
      this.ddlPartList = [];
    }
  }

  POVendorDetail(vendorId) {

    this.sharedService.POVendorDetailVendor(vendorId).subscribe(
      (data: any) => {
        this.data = data;
        if (this.data.data != null && this.data.data != undefined) {
          this.vendordata = this.data.data;
          this.PaymentTerms = this.vendordata.termsId == null ? '' : this.vendordata.termsId;
          this.selectedFobPointValue = this.vendordata.fobPointId == null ? '' : this.vendordata.fobPointId;
          this.selectedshippingValue = this.vendordata.shippingId == null ? '' : this.vendordata.shippingId;
        }
      });
  }

  EditPOVendorDetail(vendorId) {

    this.sharedService.POVendorDetailVendor(vendorId).subscribe(
      (data: any) => {
        this.data = data;
        if (this.data.data != null && this.data.data != undefined) {
          this.vendordata = this.data.data;
        }
      });
  }

  changeDDLCustomer(event) {
    if (event.target.value != "") {
      var customerId = event.target.value;
      this.POGetAllCustomerByCustomerId(customerId);
      this.PartOrderDrodownList(this.CompanyValue1, customerId, 0);
      this.ProductOrderDrodownList(this.CompanyValue1, customerId, 0);
    }
    else {
      this.customerDetails = "";
      this.PartOrderDropdown = null;
      this.ProductOrderDropdown = null;
    }
  }

  ///Customer Details
  POGetAllCustomerByCustomerId(customerId) {
    this.loader = true;
    this.sharedService.POGetAllCustomerByCustomerId(customerId).subscribe(
      data => {

        this.data = data;
        if (this.data.data != null && this.data.data != undefined) {
          this.customerDetails = this.data.data;
          this.loader = false;
        }
      });
  }

  GetStatus($event) {
    this.statusId = $event.target.value;
    //this.GetAllPurhaseOrder(this.companyId,this.statusId)
  }

  companyChangeValue(value: any): void {
    this.CompanyValue = value;
    this.GetCompanyDetails(this.CompanyValue);
    this.PartOrderDrodownList(0, 0, this.CompanyValue);
    this.ProductOrderDrodownList(0, 0, this.CompanyValue);
  }
  ///CompanyDetails

  GetCompanyDetails(companyId) {
    this.loader = true;
    this.sharedService.GetCompanyDetail(companyId).subscribe(
      data => {
        this.data = data;
        if (this.data.data != null && this.data.data != undefined) {
          this.CompanyDetails = this.data.data;
          this.loader = false;
        }
      });
  }


  ChangePOtypeEdit(event) {
    this.type = parseInt(event.target.value);
    this.EditTypeStatus(this.type);
  }

  GetBuyerDropdownList(companyId) {
    this.sharedService.POBuyerDropdow(this.companyId).subscribe(
      data => {
        this.data = data;
        if (companyId > 0) {
          this.buyerList = this.data.dataList;
        }
        else {
          this.SelectedBuyer = '';
        }
      }
    )
  }
  //for Part Drodown List
  PartOrderDrodownList(companyId, custId, companyLoc) {
    this.sharedService.PartOrderDropdown(companyId, custId, companyLoc).subscribe(
      data => {
        this.data = data;
        if (this.data.dataList != null && this.data.dataList != undefined) {
          this.PartOrderDropdown = this.data.dataList;
        }
        else {
          this.PartOrderDropdown = '';
        }
      });
  }

  ProductOrderDrodownList(companyId, custId, companyLoc) {

    this.sharedService.ProductOrderDropdown(companyId, custId, companyLoc).subscribe(
      data => {
        this.data = data;
        if (this.data.dataList != null && this.data.dataList != undefined) {
          this.ProductOrderDropdown = this.data.dataList;
        }
        else {
          this.ProductOrderDropdown = '';
        }
      });
  }

  changeShipping(event) {
    this.selectedshippingValue = event;
  }

  hidemodalpopup() {
    $("#myModal").hide();
  }

  hidemodalpopup1() {
    $("#myModal1").hide();
  }

  getAllCompanySuper(companyId) {
    this.companyService.GetDropdownCompanyHierarchyModel(this.companyId).subscribe(
      data => {
        this.data = data;
        this.items1 = this.data.dataList;
        this.companyList1 = this.data.dataList;
        // this.companyList1.forEach((x) => {
        //   this.userGroupObjects1.push(new TreeviewItem(x, false));
        // });
        this.CompanyValue1 = Number(companyId);
        // this.getAllState();

      });
  }

  companyChangeValueMain(value: any): void {
    this.CompanyValue1 = value;
    this.companyId = this.CompanyValue1;
    this.Getvendordropdownlist(value);
    this.GetAllCustomer(value);
    this.GetBuyerDropdownList(value);
    this.getAllCompany(value);
    this.getLocationLevel(value);
    this.PartOrderDropdown = null;
    this.ProductOrderDropdown = null;
    this.selectedCustomerValue = '';
    this.customerDetails = "";
    this.CompanyValue = '';
    this.CompanyDetails = "";
  }

  ShowItemModel(itemModel, id, tracking) {
    this.PopupItemId = '';
    this.popCustPart = '';
    this.popCustPartName = '';
    this.popVendorPart = '';
    this.popVendorPartName = '';
    this.popDesc = '';
    this.popOrdered = '';
    const GridItemData = this.partList.filter(item => item.id == id);
    if (GridItemData.length > 0) {
      let today = new Date();
      this.PopupMaxDate = this.datepipe.transform(today, 'yyyy-MM-dd');
      this.PopupItemId = id;
      this.popCustPart = GridItemData[0].custumerPartNumber;
      this.popCustPartName = GridItemData[0].custumerPartName;
      this.popVendorPart = GridItemData[0].vendorpartNumber;
      this.popVendorPartName = GridItemData[0].vendorPartName;
      this.popDesc = GridItemData[0].description;
      this.popOrdered = GridItemData[0].qty;
      this.poCustomId = GridItemData[0].customPartId;
      this.uniquePartId = GridItemData[0].partId,
      this.uniqueOrderId = GridItemData[0].partOrderId != "" ? GridItemData[0].partOrderId : GridItemData[0].productOrderId;
      this.ItemTracking = tracking;
      this.ClearPopup();
      this.GetPoStatusList();
      this.modalService.open(itemModel, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
    }
  }

  SavePopup(Cid: any, partid: any, tracking: any) {
    if (this.formPopup.controls.ItemDateReceived.status == this.constants.Invalid ||
      this.formPopup.controls.ItemReceived.status == this.constants.Invalid) {
      this.PopupValidation = true;
      return;
    }
    else {
      let newQty = 0;
      const Oid = this.uniqueOrderId;
      if (tracking != "3" && tracking != "0") {
        if (this.isEdit == false) {
          if (parseInt(this.popOrdered) >= ((parseInt(this.TotalReceivedQty) - parseInt(this.HidTxtItemReceived)) + parseInt(this.TxtItemReceived))) {
            this.PopupErrorMsg = ""; {
              this.qtyLabel = [];
              const recQty = parseInt(this.TxtItemReceived);
              this.inventoryQty = parseInt(this.TxtItemReceived);
              const Oid = this.uniqueOrderId;
              for (var i = 1; i <= recQty; i++) {
                var obj = {
                  id: i, part: this.popCustPartName + ' ' + i,
                  serialNo: '', valid: false, partId: this.uniquePartId, partInventoryId: Oid, isActive: true,
                  ordering: i
                }
                this.qtyLabel.push(obj);
                this.Cid = Cid;
                this.serialPartId = partid;
                this.generalTracking = tracking;
                $('#SerialModel').show();
              }
            }
          }
          else {
            this.PopupErrorMsg = this.constants.QtyExceed;
          }

        }
        else {
          this.qtyLabel = [];
          if (this.oldQty == parseInt(this.TxtItemReceived)) {

            this.qtyLabel = this.oldqtyLabel;
            this.qtyLabel.forEach(element => {
              element.partInventoryId = Oid;
            });
          }
          else {
            newQty = parseInt(this.TxtItemReceived) - this.oldQty;
            this.qtyLabel = this.oldqtyLabel;
          }
          if (parseInt(this.popOrdered) >= ((parseInt(this.TotalReceivedQty) - parseInt(this.HidTxtItemReceived)) + parseInt(this.TxtItemReceived))) {
            this.PopupErrorMsg = ""; {
              const recQty = newQty;
              let I: number = parseInt(this.oldQty);
              for (var i = 1; i <= recQty; i++) {
                var obj = {
                  id: I + i, part: this.popCustPartName + ' ' + I + i,
                  serialNo: '', valid: false, partId: this.uniquePartId, partInventoryId: Oid, isActive: true,
                  ordering: I + i

                }
                this.qtyLabel.push(obj);
                this.Cid = Cid;
              }
            }
            this.serialPartId = partid;
            $('#SerialModel').show();
          }
          else {
            this.PopupErrorMsg = this.constants.QtyExceed;
          }
        }
      }
      else {
        this.SavePopupNew(Cid, tracking);
      }

    }
  }
  SavePopupNew(Cid: any, tracking: any) {
    if (this.formPopup.controls.ItemDateReceived.status == this.constants.Invalid ||
      this.formPopup.controls.ItemReceived.status == this.constants.Invalid) {
      this.PopupValidation = true;
      return;
    }
    else {

      if (parseInt(this.popOrdered) >= ((parseInt(this.TotalReceivedQty) - parseInt(this.HidTxtItemReceived)) + parseInt(this.TxtItemReceived))) {
        this.PopupErrorMsg = "";
        if (this.PopupRecordId == null) {
          let obj = {
            rowNo: this.PopupRowNo,
            poId: parseInt(this.id),
            companyId: parseInt(this.companyId),
            itemId: parseInt(this.PopupItemId),
            dateReceived: this.TxtItemDateReceived,
            qtyReceived: parseInt(this.TxtItemReceived),
            isActive: true,
            createdBy: this.userId,
            createdDate: this.now = new Date(),
            CustomId: parseInt(Cid),
            invTracking: tracking
          }
          this.LocalItemStatusList.push(obj);
          this.PopupRowNo += 1;
        }
        else {
          const RecData = this.LocalItemStatusList.filter(x => x.rowNo === this.PopupRecordId && x.poId === this.id && x.companyId === Number(this.companyId) && x.itemId === this.PopupItemId);
          if (RecData.length > 0 && RecData != null) {
            RecData[0].dateReceived = this.TxtItemDateReceived;
            RecData[0].qtyReceived = parseInt(this.TxtItemReceived);
            RecData[0].invTracking = tracking;
            RecData[0].CustomId = parseInt(Cid);
            RecData[0].modifiedBy = this.userId;
            RecData[0].modifiedDate = this.now = new Date();
          }
        }
        this.GetPoStatusList();
        this.ClearPopup();
      }
      else {
        this.PopupErrorMsg = this.constants.QtyExceed;
      }
    }
  }
  CloseSerialModel() {
    this.ClearPopup();
    $('#SerialModel').hide();
  }
  changeValidation(id) {
    const filterElement = this.qtyLabel.filter(x => x.id == id);
    if (filterElement[0].number == '') {
      filterElement[0].valid = true;
      filterElement[0].message = '';
    }
    else {
      filterElement[0].valid = false;
      filterElement[0].message = '';
    }
  }
  SaveDetail(partid: any, tracking: any) {
    let count = 0; const sameSerial = []; let messageCount = 0; let unique;
    const filterElement = this.qtyLabel;
    if (filterElement[0].number == '') {
      filterElement[0].message = '';
    }
    else {
      filterElement[0].message = '';
    }
    this.qtyLabel.forEach(element => {
      if (element.serialNo == '') {
        element.valid = true;
        count++;
      }
      else {
        element.valid = false;
      }
    });
    if (count == 0) {
      let serial = '';
      this.qtyLabel.forEach(partLst => {
        if (this.isEdit == false) {
          sameSerial.push(partLst.serialNo);
          serial += partLst.serialNo.trim() + ':' + '' + ',';
        }
        else {
          var sid = partLst.SerialId == undefined ? 0 : partLst.SerialId;
          serial += partLst.serialNo.trim() + ':' + sid + ',';
        }

      });
      let findDuplicates = arr => arr.filter((item, index) => arr.indexOf(item) != index);
      const duplicate = [...new Set(findDuplicates(sameSerial))];
      if (duplicate.length == 0) {
        if (serial) {
          serial = serial.slice(0, -1);
        }
        this.loader = true;
        this.sharedService.GetSerialExists(serial, partid, "1").subscribe(
          data => {
            this.data = data;
            const list = this.data.dataList;
            this.loader = false;
            if (list.length > 0) {
              list.forEach(element => {
                const getSerial = this.qtyLabel.filter(x => x.serialNo == element.serialNo);
                getSerial[0].message = tracking == 1 ? 'Serial No already exists for this part' : 'Lot # already exists for this part';

                messageCount = messageCount + 1;
              });
            }
            if (messageCount == 0) {
              const getSerial = this.qtyLabel;
              getSerial.forEach(element => {
                element.type = tracking == 1 ? 'Serial' : 'Lot';
              });
              $('#SerialModel').hide();
              this.ReceivedItem(this.Cid, tracking);
            }
            else {
            }
          });
      }
      else {
        if (duplicate.length > 0) {
          duplicate.forEach(element => {
            const getSerial = this.qtyLabel.filter(x => x.serialNo == element);
            getSerial.forEach(element => {
              element.message = tracking == 1 ? 'Serial No already exists for this part' : 'Lot # already exists for this part';
            });

          });
        }
      }


    }
    else {
      if (this.qtyLabel.length > 15) {
        $('#GeneralModel').show();
        var s = tracking == 1 ? " Serial #" : " Lot#";
        this.SerialComment = "Please Add " + count + s;
      }
      return;
    }

  }
  CloseGeneralModel() {
    $('#GeneralModel').hide();
  }
  ReceivedItem(Cid: any, tracking: any) {
    if (parseInt(this.popOrdered) >= ((parseInt(this.TotalReceivedQty) - parseInt(this.HidTxtItemReceived)) + parseInt(this.TxtItemReceived))) {
      this.PopupErrorMsg = "";
      if (this.PopupRecordId == null) {
        let obj = {
          rowNo: this.PopupRowNo,
          poId: parseInt(this.id),
          companyId: parseInt(this.companyId),
          itemId: parseInt(this.PopupItemId),
          dateReceived: this.TxtItemDateReceived,
          qtyReceived: parseInt(this.TxtItemReceived),
          isActive: true,
          createdBy: this.userId,
          createdDate: this.now = new Date(),
          CustomId: parseInt(Cid),
          Serials: this.qtyLabel,
          receiveSerials: this.qtyLabel,
          invTracking: tracking
        }
        this.LocalItemStatusList.push(obj);

        this.PopupRowNo += 1;
      }
      else {
        const RecData = this.LocalItemStatusList.filter(x => x.rowNo === this.PopupRecordId && x.poId === this.id && x.companyId === Number(this.companyId) && x.itemId === this.PopupItemId);
        if (RecData.length > 0 && RecData != null) {
          RecData[0].dateReceived = this.TxtItemDateReceived;
          RecData[0].qtyReceived = parseInt(this.TxtItemReceived);
          RecData[0].CustomId = parseInt(Cid);
          RecData[0].modifiedBy = this.userId;
          RecData[0].modifiedDate = this.now = new Date();
          RecData[0].Serials = this.qtyLabel,
            RecData[0].receiveSerials = this.qtyLabel,
            RecData[0].invTracking = tracking
        }
      }
      this.GetPoStatusList();
      this.ClearPopup();
    }
    else {
      this.PopupErrorMsg = this.constants.QtyExceed;
    }
  }

  GetPoStatusList() {
    this.ItemStatusList = [];
    this.TotalReceivedQty = 0;

    const ChkExist = this.LocalItemStatusList.filter(x => x.poId === this.id && x.companyId === Number(this.companyId) && x.itemId === this.PopupItemId);
    if (ChkExist.length > 0) {
      ChkExist.forEach(ChkExist => {
        if (ChkExist.isActive == true) {
          this.ItemStatusList.push(ChkExist);
          this.TotalReceivedQty += ChkExist.qtyReceived;
        }
      });
      this.colorChange();
    }
    else {
      this.loader = true;
      this.sharedService.GetPoStatusList(this.id, this.companyId, this.PopupItemId).subscribe(
        data => {
          this.data = data;
          const value = this.data.dataList;
          value.forEach(element => {
            if (element.Serials != null) {
              element.Serials = element.serials.split(',');
            }
            element.receiveSerials = element.receiveSerials;
            element.receiveSerials.forEach(element1 => {
              element1.partInventoryId = this.uniqueOrderId;
              element1.SerialId = element1.id
            });
            element.isSale = true;

          });
          this.PopupRowNo = 1;
          this.ItemStatusList = this.data.dataList;
          if (this.data.dataList != null && this.data.dataList != undefined) {
            this.ItemStatusList = this.data.dataList;
            if (this.ItemStatusList.length > 0) {
              this.ItemStatusList.forEach(ItemStatusList => {
                ItemStatusList.rowNo = this.PopupRowNo;
                this.LocalItemStatusList.push(ItemStatusList);
                this.TotalReceivedQty += ItemStatusList.qtyReceived;
                this.PopupRowNo += 1;
              });
            }
            this.colorChange();
            this.loader = false;
          }
        }
      )
    }
  }

  editItemStatus(rowno: any) {
    const ItemData = this.LocalItemStatusList.filter(x => x.rowNo === rowno && x.poId === this.id && x.companyId === Number(this.companyId) && x.itemId === this.PopupItemId);
    if (ItemData.length > 0) {
      this.PopupRecordId = rowno;
      this.TxtItemDateReceived = this.datepipe.transform(ItemData[0].dateReceived, 'yyyy-MM-dd');
      this.TxtItemReceived = ItemData[0].qtyReceived;
      this.HidTxtItemReceived = ItemData[0].qtyReceived;
      const Serial = ItemData[0].receiveSerials;
      this.qtyLabel = [];
      this.oldqtyLabel = [];
      for (var i = 0; i < Serial.length; i++) {
        var obj = {
          id: i + 1, part: Serial[i].part,
          serialNo: Serial[i].serialNo, valid: false, partId: Serial[i].partId, partInventoryId: Serial[i].partInventoryId,
          SerialId: Serial[i].id, isActive: true, ordering: Serial[i].ordering
        }
        this.qtyLabel.push(obj);
      }
      this.oldqtyLabel = this.qtyLabel;
      this.isEdit = true;
      this.oldQty = ItemData[0].qtyReceived;
    }
  }


  deleteItemStatusConfirm(item, content) {

    this.modalService.open(content, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
    this.deleteDetail = this.constants.DeletePopup;
    this.deletedItem = item;
  }

  deleteItemStatus(item: any) {
    let serial = [];
    const ItemData = this.LocalItemStatusList.filter(x => x.rowNo === item && x.poId === this.id && x.companyId === Number(this.companyId) && x.itemId === this.PopupItemId);
    if (ItemData.length > 0) {
      if (ItemData[0].id == undefined) {
        for (let index = 0; index < this.LocalItemStatusList.length; index++) {
          this.LocalItemStatusList[index].receiveSerials.forEach(element => {
            serial.push(element.serialNo);
          });

          if (item == this.LocalItemStatusList[index].rowNo) {
            this.LocalItemStatusList.splice(index, 1);
          }
        }
      }
      else {

        ItemData[0].isActive = false;
        ItemData[0].receiveSerials.forEach(element => {
          element.isActive = false;
          serial.push(element.serialNo);
        });
      }

      this.GetPoStatusList();
    }


  }

  colorChange() {
    const ChkGridItem = this.partList.filter(x => x.Id === this.PopupItemId);
    if (ChkGridItem.length > 0) {
      ChkGridItem[0].recQty = this.TotalReceivedQty;
      if (Number(ChkGridItem[0].recQty) > 0) {
        if (Number(ChkGridItem[0].recQty) == Number(ChkGridItem[0].qty)) {
          ChkGridItem[0].bcolor = 'forestgreen';
        }
        else {
          ChkGridItem[0].bcolor = 'yellow';
        }
      }
      else {
        if (!ChkGridItem[0].dateSchedule) {
          let today = new Date();
          let dateString = ChkGridItem[0].dateSchedule;
          let sdate = new Date(dateString);

          if (today.getDay() == sdate.getDay() && today.getMonth() == sdate.getMonth() && today.getFullYear() == sdate.getFullYear()) {
            ChkGridItem[0].bcolor = 'silver';
          }
          else {
            if (today.getTime() > sdate.getTime()) {
              ChkGridItem[0].bcolor = 'firebrick';
            }
            else {
              ChkGridItem[0].bcolor = 'silver';
            }
          }
        }
        else {
          ChkGridItem[0].bcolor = 'firebrick';
        }
      }
    }
  }

  ClearPopup() {
    this.HidTxtItemReceived = 0;
    this.TxtItemDateReceived = this.datepipe.transform(new Date(), 'yyyy-MM-dd');
    this.TxtItemReceived = '';
    this.PopupRecordId = null;
    this.qtyErrorMsg = '';
    this.PopupErrorMsg = "";
    this.PopupValidation = false;
    this.isEdit = false;
    this.oldqtyLabel = [];
  }

  addsuccess() {
    $("#add_success").hide();
  }
  updateitem() {
    $("#add_update").hide();
  }
  deleteitem() {
    $("#delete_success").hide();
  }
  msgitem() {
    $("#wrong_msg").hide();
  }
  norecorditem() {
    $("#no_record").hide();
  }

  noPart() {
    $("#modalNoPart").hide();
  }

  DiscrdProduct(discardItem) {

    this.deletedItem = '';
    // && this.LocalId==0
    if (this.isPartval == false || this.RowEdit == true) {
      this.modalService.open(discardItem, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
      this.deleteDetail = this.constants.ExitMessage;
    }
    else {

      this.productId = 0;
      this.Exit();
    }

  }
  gotoDashBoard() {
    this.Exit();
  }

  Exit() {
    this.clearbutton = true;
    this.IsToggleEdit = false;
    this.id = null;
    this.buttonValue = this.constants.Save;
    this.ispa = true;
    this.typeOfPo = 1;
    this.RecOrderListShow = false;
    this.ListDiv = false;
    this.resetvendorData();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next('');
    });
  }



  ngAfterViewInit(): void {
    this.cdr.detectChanges();
    //this.dtTrigger.next('');
    this.settingDetailCSS = localStorage.getItem("settingDetailCSS");
    this.settingLogoImage = localStorage.getItem("settingImageLogo");
    this.SetSettingCSS(this.settingDetailCSS, this.settingLogoImage);
  }

  ngAfterViewChecked() {
    //your code to update the model
    this.toggleSub();
    this.cdr.detectChanges();

  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  getLocationLevel(id) {
    this.sharedService.getLocationLevel(id, '').subscribe(
      data => {
        this.data = data;
        if (this.data.dataList) {
          this.locationNameList = this.data.dataList.filter(x => x.locationTypeId == 3);
          this.SelectedLocation = this.locationNameList.length > 0 ? this.locationNameList[0].id : '';
          this.getPartLocationLevel(3, this.locationNameList[0].locationName);
        }
        else {
          this.locationNameList = '';
        }
      });
  }
  changeLocation(event) {
    var value = event.target.value;
    var value2 = event.target.options[event.target.options.selectedIndex].text;
    this.selectedLocationName = value2;
    this.getPartLocationLevel(3, value2);
  }

  getPartLocationLevel(id, locationName) {
    this.sharedService.getPartLocationLevel(id, locationName, this.CompanyValue1).subscribe(
      data => {
        this.data = data;
        if (this.data.dataList) {
          this.locationLevelList = this.data.dataList;
          this.SelectedPartLocation = this.locationLevelList.length > 0 ? this.locationLevelList[0].levelsName : '';
        }
        else {
          this.locationLevelList = [];
        }
      });
  }

  //ankit implemenation
  ErrorModal(title: string, message: string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }

}
