import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AdminRoutingModule } from './admin-routing.module';
import { AdminComponent } from './admin/admin.component';
import { RouterModule } from '@angular/router';
import { AdminDashboardComponent } from './admin-dashboard/admin-dashboard.component';
import { CompanyComponent } from './company/company.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { UsersComponent } from './users/users.component';
import { VendorsComponent } from './vendors/vendors.component';
import { ProductsComponent } from './products/products.component';
import { PartsComponent } from './parts/parts.component';
import { OrdersComponent } from './orders/orders.component';
import { PartsQuestionsComponent } from './parts-questions/parts-questions.component';
import { CompanyListComponent } from './company-list/company-list.component';
import { ViewCompanyComponent } from './view-company/view-company.component';
import { OrderDetailsComponent } from './order-details/order-details.component';
import { ToastrModule } from 'ngx-toastr';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { OrderPartdetailComponent } from './order-partdetail/order-partdetail.component';
import { CategoryComponent } from './category/category.component';
import { CustomPartComponent } from './custom-part/custom-part.component';
import { PurchaseOrderComponent } from './purchase-order/purchase-order.component';
import { ReceiveOrderComponent } from './receive-order/receive-order.component';
import { ShippingComponent } from './shipping/shipping.component';
import { InventoryComponent } from './inventory/inventory.component';
import { DashboardlistComponent } from './dashboardlist/dashboardlist.component';
import { CKEditorModule } from 'ckeditor4-angular';
import { ProductionJobsComponent } from './production-jobs/production-jobs.component';
import { MyProductionTaskComponent } from './my-production-task/my-production-task.component';
import { TeamManagementComponent } from './team-management/team-management.component';
import { SearchableFieldsComponent } from './searchable-fields/searchable-fields.component';
import { ReportComponent } from './report/report.component';
import { VehiclelistComponent } from './vehiclelist/vehiclelist.component';
import { PicklistComponent } from './picklist/picklist.component';
import { MenuComponent } from './menu/menu.component';
import { PurchaseorderreportComponent } from './purchaseorderreport/purchaseorderreport.component';
import { MaterialModule } from '../material/material.module';
import { EditorModule } from '@tinymce/tinymce-angular';
import { ShipmentComponent } from './shipment/shipment.component';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { NgImageSliderModule } from 'ng-image-slider';
import { BarcodeScannerLivestreamModule } from 'ngx-barcode-scanner';
import { CustomerDropdownComponent } from './customer-dropdown/customer-dropdown.component';
import { CustomersComponent } from './customers/customers.component';
import { DataTablesModule } from 'angular-datatables';
import { WarehouseSetupComponent } from './warehouse-setup/warehouse-setup.component';
import { NgxMaskDirective, NgxMaskPipe } from 'ngx-mask';
import { PickListsComponent } from './pick-lists/pick-lists.component';
import { CompanyDetailsComponent } from './company-details/company-details.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { HelcimComponent } from './helcim/helcim.component';
import { OrderPartFlyOutComponent } from './order-part-fly-out/order-part-fly-out.component';
import { Category } from './products/productParts';
import { CategoryLayoutComponent } from './category-layout/category-layout.component';
import { SalesOrderComponent } from './sales-order/sales-order.component';
import { EmailTemplateComponent } from './email-template/email-template.component';
import { PartDetailsComponent } from './part-details/part-details.component';
import { ReceivingDetailsComponent } from './receiving/receiving-details/receiving-details.component';
import { ReceivingListComponent } from './receiving/receiving-list/receiving-list.component';
import { PurchaseReportComponent } from './purchase-report/purchase-report.component';
import { SalesReportComponent } from './sales-report/sales-report.component';
import { PoListComponent } from './purchaseorder-details/po-list/po-list.component';
import { PoDetailComponent } from './purchaseorder-details/po-detail/po-detail.component';
import { PutawayComponent } from './putaway/putaway.component';
import { ReplenishmentComponent } from './putaway/replenishment/replenishment.component';
import { PoHistoryComponent } from './purchaseorder-details/po-history/po-history.component';
import { InventoryListComponent } from './inventory-details/inventory-list/inventory-list.component';
import { InventoryDetailComponent } from './inventory-details/inventory-detail/inventory-detail.component';
import { VendorCardComponent } from './vendor-card/vendor-card.component';
import { InventoryFlyoutComponent } from './inventory-flyout/inventory-flyout.component';
import { SerialLotFlyoutComponent } from './serial-lot-flyout/serial-lot-flyout.component';
import { Shipment1Component } from './shipment1/shipment1.component';
import { PoShiptoComponent } from './po-shipto/po-shipto.component';
import { PoOrderdetialsCardComponent } from './po-orderdetials-card/po-orderdetials-card.component';
import { ReceivingCongratulationsComponent } from './receiving/receiving-congratulations/receiving-congratulations.component';
import { ToUppercaseDirective } from '../Directives/Uppercase.directive';
import { NumbersOnlyDirective } from '../Directives/numbers-only.directive';
import { JSPrintComponent } from './jsprint/jsprint.component';


@NgModule({
  declarations: [
    AdminComponent,
    AdminDashboardComponent,
    CompanyComponent,
    UsersComponent,
    VendorsComponent,
    CustomersComponent,
    ProductsComponent,
    PartsComponent,
    OrdersComponent,
    PartsQuestionsComponent,
    CompanyListComponent,
    ViewCompanyComponent,
    OrderDetailsComponent,
    OrderPartdetailComponent,
    CategoryComponent,
    CustomPartComponent,
    PurchaseOrderComponent,
    ReceiveOrderComponent,
    ShippingComponent,
    InventoryComponent,
    DashboardlistComponent,
    ProductionJobsComponent,
    MyProductionTaskComponent,
    TeamManagementComponent,
    SearchableFieldsComponent,
    ReportComponent,
    VehiclelistComponent,
    PicklistComponent,
    MenuComponent,
    PurchaseorderreportComponent,
    ShipmentComponent,
    CustomerDropdownComponent,
    PickListsComponent,
    CustomerDropdownComponent,
    CompanyDetailsComponent,
    WarehouseSetupComponent,
    SalesOrderComponent,
    HelcimComponent,
    OrderPartFlyOutComponent,
    CategoryLayoutComponent,
    EmailTemplateComponent,
    PartDetailsComponent,
    ReceivingDetailsComponent,
    ReceivingListComponent,
    PurchaseReportComponent,
    SalesReportComponent,
    PoListComponent,
    PoDetailComponent,
    PutawayComponent,
    ReplenishmentComponent,
    PoHistoryComponent,
    InventoryListComponent,
    InventoryDetailComponent,
    VendorCardComponent,
    InventoryFlyoutComponent,
    SerialLotFlyoutComponent,
    Shipment1Component,
    PoShiptoComponent,
    PoOrderdetialsCardComponent,
    ReceivingCongratulationsComponent,
    ToUppercaseDirective,
    NumbersOnlyDirective,
    JSPrintComponent
],

  imports: [
    CommonModule,
    AdminRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    DataTablesModule,
    CKEditorModule,
    NgbModule,
    NgMultiSelectDropDownModule.forRoot(),
    RouterModule,
    ToastrModule.forRoot(),
    TypeaheadModule.forRoot(),
    ZXingScannerModule,
    MaterialModule,
    EditorModule,
    NgImageSliderModule,
    BarcodeScannerLivestreamModule,
    NgxMaskDirective,
    NgxMaskPipe 
  ],
  providers: [
    CurrencyPipe
  ]

})
export class AdminModule { }
