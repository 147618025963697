import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { ConstantsService } from 'src/app/constants.service';
import { SharedService } from 'src/app/services/shared.service';
// import { TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { GlobalChangeService } from 'src/app/services/global-change.service';
import Swal from 'sweetalert2';
import { CompanyService } from 'src/app/services/company/company.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  IsEcommerce: boolean = false;
  IsEcommerceShow: boolean = false;
  IsDisable: boolean = false;
  menuDataList: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  data: any;
  validation: boolean;
  name: any;
  id: any;
  userId: any;
  deletedItem: string;
  deleteDetail: string;
  loader: boolean = false;
  form: FormGroup;
  menu: string;

  userRoleList: any;
  buttonValue: any;
  settingDetailCSS: any;
  recordAlreadyExist = false;
  recordAlreadywordpresssiteExist = false;
  settingLogoImage: any;
  clearbutton: boolean = true;
  rolePermissionsList: any;
  permissionMessage: any;
  TCreateUpdate: any = 1;
  ProductSearch: any = "";
  SearchValidation: any;
  SearchControl: boolean = false;
  ListShow: boolean = false;
  ListDiv: boolean = false;
  formSearch: FormGroup;
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  // config = TreeviewConfig.create({
  //   hasAllCheckBox: true,
  //   hasFilter: true,
  //   hasCollapseExpand: true,
  //   decoupleChildFromParent: false,
  //   maxHeight: 400
  // });
  // config1 = TreeviewConfig.create({
  //   hasAllCheckBox: true,
  //   hasFilter: true,
  //   hasCollapseExpand: true,
  //   decoupleChildFromParent: false,
  //   maxHeight: 400
  // });
  userGroupObjects = new Array();
  userGroupObjects1 = new Array();
  companyList: any;
  value = 0;
  value1 = 0;
  companyLoginId: any;
  userSearch: any;
  ListAngle: any;
  SearchType: any;
  value_search: number;
  userGroupObjects_search = new Array();
  EcommerceObjects = new Array();
  companyList_search: any;
  // items_search: TreeviewItem[];
  createdDateEdit: any;
  wordpressId: any;
  ecommerceDataList: any;
  ecommerceObjects: any = [];
  eCommerce: boolean = false;
  ecommerceList: any = [];
  items_search: any;
  constructor(
    private route: ActivatedRoute,
    public sharedService: SharedService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private constants: ConstantsService,
    private router: Router,
    private globalChangeService: GlobalChangeService,
    private companyService: CompanyService
  ) { }

  ngOnInit(): void {
    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);
    } else {
      this.userId = localStorage.getItem(this.constants.userId);
      this.buttonValue = this.constants.Save;
      this.dtOptions = {
        pagingType: this.constants.full_numbers,
        pageLength: 10,
        ordering:true
      };

    }
    this.form = new FormGroup({
      'menu': new FormControl(),
      'eCommerce': new FormControl()
    });
    this.formSearch = new FormGroup({
      'TCreateUpdate': new FormControl(),
      'userSearch': new FormControl(),
    });
    this.rolePermissionsList = JSON.parse(localStorage.getItem('rolePermissionsList'));
    this.rolePermissionsList = this.rolePermissionsList.filter(x => x.pageId == 1043);
    this.permissionMessage = '';
    if (this.rolePermissionsList != null && this.rolePermissionsList != undefined) {
      if (!this.rolePermissionsList[0].isView) {
        this.permissionMessage += 'view';
      } if (!this.rolePermissionsList[0].isInsert) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'add';
      } if (!this.rolePermissionsList[0].isUpdate) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'edit';
      } if (!this.rolePermissionsList[0].isDelete) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'delete';
      }
    }
    if (this.permissionMessage != '') {
      this.permissionMessage = 'You do not have access to ' + this.permissionMessage + '. Please contact to administrator!';
    }
    this.companyLoginId = localStorage.getItem(this.constants.companyId);
    // this.getAllCompany(this.companyLoginId);
    // this.getAllCompany_search(this.companyLoginId);

    //ankit implementaion
      // get company by default set value
      var company = this.globalChangeService.getGlobalCompany();
      if(company>0){
        this.value = company;
        this.value_search = company;
      }
      else{
        this.ErrorModal('Error', 'Please select company.');
      }

      //on change function 
      this.globalChangeService.dropdownChange$.subscribe((data)=>{
        this.getAllCompany(data);
        this.getAllCompany_search(data);
      })

  }

  ngAfterViewInit() {
    this.settingDetailCSS = localStorage.getItem("settingDetailCSS");
    this.settingLogoImage = localStorage.getItem("settingImageLogo");
    this.SetSettingCSS(this.settingDetailCSS, this.settingLogoImage);
    this.dtTrigger.next('');
  }

  getAllMenu(companyId, SearchItem) {
    this.loader = true;
    if(companyId == undefined){companyId =0;}
    this.sharedService.getAllMenus(companyId, SearchItem).subscribe(
      data => {

        this.loader = false;
        this.data = data;
        this.menuDataList = this.data.dataList;
        this.rerender();
      });
  }

  addNewMenu() {
    this.validation = true;

    this.clearbutton = true;
    if (this.IsEcommerce == true) {
      if (this.form.controls.MarkupMargin.status === this.constants.Invalid) {
        return;
      }
    }
    if (this.form.controls.menu.status === this.constants.Invalid || this.value == 0) {
      return;
    }

    this.loader = true;
    const obj = {};
    obj['name'] = this.menu;
    obj['CompanyId'] = this.value;
    obj['CreatedBy'] = this.userId;
    if (this.id != undefined && this.id != null && this.id != '') {
      obj['Id'] = this.id;
      obj['ModifiedBy'] = this.userId;
      obj['CreatedDate'] = this.createdDateEdit;
      obj['WordpressId'] = !this.wordpressId ? 0 : this.wordpressId;
      this.sharedService.updateMenus(obj)
        .subscribe(
          data => {
            switch (data.statusCode) {
              case 200:
                this.loader = false;
                this.buttonValue = this.constants.Save;
                $("#add_update").show();
                this.menu = '';
                // this.TCreateUpdate = 1;
                // this.SearchControl = false;
               // this.ListDiv = true;
                this.validation = false;
                this.id = '';
                this.eCommerce = false;
                this.resetCategory();
                this.getAllMenu(this.value_search, this.userSearch);
                break;
              case 400:
                this.loader = false;
                if (data.errorMessage === 'Record Already Exist') {
                  this.recordAlreadyExist = true;
                  this.recordAlreadywordpresssiteExist = false;
                } else {
                  this.recordAlreadywordpresssiteExist = true;
                  this.recordAlreadyExist = false;
                }
                break;
              case 401:
                this.loader = false;
                this.ecommerceObjects = [];
                $("#wrong_msg1").show()
                this.resetCategory();

                break;
              case 500:
                this.loader = false;
                this.ecommerceObjects = [];
                $("#wrong_msg2").show()
                this.resetCategory();

                break;
              case 404:
                this.loader = false;
                this.ecommerceObjects = [];
                $("#wrong_msg1").show()
                this.resetCategory();

                break;
              default:
                this.loader = false;
                $("#wrong_msg").show()
            }
          }
        );
    } else {
      this.sharedService.addMenus(obj)
        .subscribe(
          data => {
            switch (data.statusCode) {
              case 200:
                this.loader = false;
                this.recordAlreadyExist = false;
                this.recordAlreadywordpresssiteExist = false;
                $("#add_success").show();
                this.validation = false;
                this.resetCategory();
                this.getAllMenu(this.value_search, this.userSearch);
                break;
              case 400:
                this.loader = false;
                if (data.errorMessage === 'Record Already Exist') {
                  this.recordAlreadyExist = true;
                  this.recordAlreadywordpresssiteExist = false;
                } else {
                  this.recordAlreadywordpresssiteExist = true;
                  this.recordAlreadyExist = false;
                }
                break;
              case 500:
                this.loader = false;
                this.ecommerceObjects = [];
                $("#wrong_msg2").show()
                this.resetCategory();

                break;
              case 401:
                this.loader = false;
                this.ecommerceObjects = [];
                $("#wrong_msg1").show()
                this.resetCategory();

                break;
              case 404:
                this.loader = false;
                this.ecommerceObjects = [];
                $("#wrong_msg1").show()
                this.resetCategory();

                break;
              case 0:
                this.loader = false;
                this.recordAlreadyExist = true;
                break;
              default:
                $("#wrong_msg").show()
            }
          }
        );
    }
  }

  deletePopup(item, content) {
    this.deletedItem = '';
    this.modalService.open(content, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
    this.deleteDetail = this.constants.DeletePopup;
    this.deletedItem = item.id;
  }

  deleteUserMenu(item: any) {
    this.loader = true;
    this.sharedService.deleteMenu(item).subscribe(
      data => {

        this.data = data;

        switch (data.statusCode) {
          case 200:
            $("#delete_success").show();
            // this.toastr.success(this.constants.RecordDeletedSuccessfully);
            this.getAllMenu(this.value_search, this.userSearch == "" ? "" : this.userSearch);
            this.rerender();
            this.loader = false;
            break;

          case 400:
            this.loader = false;
            $("#wrong_msg").show();
            // this.toastr.error(this.constants.SomethingWentWrong);

            break;
          case 401:
            this.loader = false;
            $("#wrong_msg").show()


            break;
          case 404:
            this.loader = false;
            $("#wrong_msg").show()


            break;
          case 500:
            this.loader = false;
            $("#wrong_msg").show();
            // this.toastr.error(this.constants.SomethingWentWrong);
            break;
          default:
            this.loader = false;
            $("#wrong_msg").show();
          // this.toastr.error(this.constants.SomethingWentWrong);

        }
      });
  }

  toggleEdit(item: any) {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.ListAngle = this.constants.DownArrow;
    this.ListDiv = false;
    this.buttonValue = this.constants.Update;
    this.clearbutton = false;
    this.value = item.companyId;
    this.id = item.id;
    this.menu = item.name;
    this.recordAlreadyExist = false;
    this.wordpressId = item.wordpressId;
    this.getEcoomerceCategory(item.companyId);
    this.createdDateEdit = item.createdDate == null ? '' : item.createdDate == undefined ? '' : item.createdDate;
  }

  SetSettingCSS(settingCSS, logoImagePath) {
    if (settingCSS != null) {
      let headerCss = document.getElementsByClassName(this.constants.CssHealerClass) as HTMLCollectionOf<HTMLElement>;
      if (headerCss.length != 0) {
        headerCss[0].style.backgroundColor = settingCSS.split(',')[0];
      }
      let saveButtonCss = document.getElementsByClassName(this.constants.CssSaveBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (saveButtonCss.length != 0) {
        saveButtonCss[0].style.backgroundColor = settingCSS.split(',')[1];
      }
      let cencelButtonCss = document.getElementsByClassName(this.constants.CssCancelBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (cencelButtonCss.length != 0) {
        cencelButtonCss[0].style.backgroundColor = settingCSS.split(',')[2];
      }
    }

    if (logoImagePath != null && logoImagePath != "") {
      (document.getElementById('hrdlogoImage') as HTMLImageElement).src = logoImagePath;
    }

  }

  CategoryBack() {
    this.clearbutton = true;
    this.id = null;
    this.buttonValue = this.constants.Save;
    this.resetCategory();
  }

  getAllCompany(companyId) {
    this.companyService.GetDropdownCompanyHierarchyModel(companyId).subscribe(
      data => {
        this.data = data;
        if (this.data.dataList != null && this.data.dataList != undefined) {
          this.companyList = this.data.dataList;
          // this.companyList.forEach((x) => {
          //   this.userGroupObjects.push(new TreeviewItem(x, false));

          // });
        }
        this.value = Number(companyId);
    
        //this.getAllCustomer(this.companyLoginId);
      });
  }

  onValueChange(value: any): void {
    this.value = value == undefined ? 0 : value;
    this.getEcoomerceCategory(value == undefined ? 0 : value);
  }

 
  getEcoomerceCategory(companyId) {

    this.sharedService.GetEcommerceCategory(companyId).subscribe(
      data => {
        this.data = data;
        this.ecommerceList = [];
        if (this.data.statusCode != "400") {
          if (this.data.dataList != null && this.data.dataList != undefined) {
            this.ecommerceList = this.data.dataList;
          
          }
          this.value = Number(companyId);
        }
        else {
          this.ecommerceList = [];
        }
      });
  }


  ChangeTCreateUpdate(event) {
    this.TCreateUpdate = event.target.value;
    if (this.TCreateUpdate == "1") {
      this.SearchControl = false;
      this.ListShow = false;
      this.ListDiv = false;
      this.clearbutton = true;
      this.buttonValue = this.constants.Save;
      this.resetCategory();
    }
    else if (this.TCreateUpdate == "0") {
      this.SearchControl = true;
      this.userSearch = "";
    }
  }

  ListDivShowHide() {
    if (this.ListDiv == true) {
      this.ListDiv = false;
      this.ListAngle = this.constants.DownArrow;
    }
    else if (this.ListDiv == false) {
      this.ListDiv = true;
      this.ListAngle = this.constants.UpArrow;
    }
  }

  searchUser() {
    this.SearchType = false;
    if (!this.userSearch || this.value_search == 0) {
      this.SearchValidation = true;
      return;
    }
    this.getAllMenu(this.value_search, this.userSearch);
  }

  AllUser() {

    this.SearchType = true;
    if (this.value_search == 0) {
      this.SearchValidation = true;
      return;
    }
    this.getAllMenu(this.value_search, this.userSearch);
    this.ListShow = true;
    this.ListDiv = true;
    this.ListAngle = this.constants.UpArrow;
  }

  getAllCompany_search(companyId) {
    this.companyService.GetDropdownCompanyHierarchyModel(this.companyLoginId).subscribe(
      data => {
        this.data = data;
        this.items_search = this.data.dataList;
        this.companyList_search = this.data.dataList;
        // this.companyList_search.forEach((x) => {
        //   this.userGroupObjects_search.push(new TreeviewItem(x, false));
        // });
        this.value_search = Number(companyId);
      });
  }

  onValueChange_search(value: any): void {
    this.value_search = value;
  }

  addsuccess() {
    $("#add_success").hide();
  }
  updateitem() {
    $("#add_update").hide();
  }
  deleteitem() {
    $("#delete_success").hide();
  }
  msgitem() {
    $("#wrong_msg").hide();
  }
  msgitem1() {
    $("#wrong_msg1").hide();
  }
  msgitem2() {
    $("#wrong_msg2").hide();
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next('');
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  resetCategory() {
    this.validation = false;
    if (this.companyLoginId > 0) {
      this.value = Number(this.companyLoginId)
    }
    else {
      this.value = 0;
    }

    this.id = null;
    this.menu = '';
    this.eCommerce = false;
    this.IsEcommerceShow = false;
    this.IsDisable = false;
    this.IsEcommerce = false;
  }

  getAllEcommerceDetails(companyId, SearchItem): void {
    this.sharedService.GetAllEcommerceDetails(companyId, SearchItem).subscribe(
      data => {
        this.loader = true;
        this.data = data;
        this.ecommerceDataList = this.data.dataList;
        this.loader = false;
        this.rerender();
        // this.ListShow = true;
        // this.ListDiv = true;
        // this.ListAngle = "up";

      });
  }

  checkValue(event: any) {
    if (event.target.checked == true) {
      this.IsEcommerce = true;
    }
    else {
      this.IsEcommerce = false;
    }
  }

  checkValue1(event: any) {
    if (event.target.checked == true) {
      this.IsEcommerceShow = true;
      this.IsEcommerce = true;
      this.eCommerce = true;
      this.IsDisable = true;
    }
    else {
      this.IsEcommerceShow = false;
      this.IsEcommerce = false;
      this.eCommerce = false;

    }
  }
 
  // keyPressNumbers(event) {
  //   var character = String.fromCharCode(event.keyCode);
  //   if (isNaN(Number(newValue)) || this.hasDecimalPlace(newValue, 4)) {
  //     event.preventDefault();
  //     return false;
  //   }
  // } 


  hasDecimalPlace(value, x) {
    var pointIndex = value.indexOf('.');
    return  pointIndex >= 0 && pointIndex < value.length - x;
  }

  //ankit implemenation
  ErrorModal(title:string, message:string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }
}
