import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { ConstantsService } from 'src/app/constants.service';
import { SharedService } from 'src/app/services/shared.service';
import { asBlob } from 'html-docx-js-typescript';
import { saveAs } from 'file-saver';
// import { TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { GlobalChangeService } from 'src/app/services/global-change.service';
import Swal from 'sweetalert2';
import { CompanyService } from 'src/app/services/company/company.service';
@Component({
  selector: 'app-parts-questions',
  templateUrl: './parts-questions.component.html',
  styleUrls: ['./parts-questions.component.css']
})
export class PartsQuestionsComponent implements AfterViewInit, OnDestroy, OnInit {
  @ViewChild('contentWord', { static: false }) contentWord: ElementRef;
  data: any;
  allQuestionList: any;
  buttonValue: string;
  validation = false;
  id: string;
  form: any;
  questions: string;
  selectedAnswerType = '';
  userId: any;
  deletedItem: string;
  deleteDetail: string;
  settingDetailCSS: any;
  settingLogoImage: any;
  createdBy: any;
  createdDate: any;
  answerTypeData: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  rolePermissionsList: any;
  userList: any;
  roleId: any;
  loader = false;
  recordAlreadyExist = false;
  clearbutton: boolean = true;
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  permissionMessage = '';
  companyList: any;
  companyList_search: any;
  userGroupObjects = new Array();
  userGroupObjects_search = new Array();
  value = 0;
  value_search=0;
  // items: TreeviewItem[];
  // items_search: TreeviewItem[];
  companyLoginId: any;
  TCreateUpdate: any = 1;
  ProductSearch: any="";
  SearchValidation: any;
  SearchControl: boolean = false;
  ListShow: boolean = false;
  ListDiv: boolean = false;
  ListAngle: any;
  formSearch: FormGroup;
  // config = TreeviewConfig.create({
  //   hasAllCheckBox: true,
  //   hasFilter: true,
  //   hasCollapseExpand: true,
  //   decoupleChildFromParent: false,
  //   maxHeight: 400
  // });
  // config_search = TreeviewConfig.create({
  //   hasAllCheckBox: true,
  //   hasFilter: true,
  //   hasCollapseExpand: true,
  //   decoupleChildFromParent: false,
  //   maxHeight: 400
  // });
  SearchType: any;
  items_search: any;

  
  constructor(
    public sharedService: SharedService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private constants: ConstantsService,
    private router: Router,
    private globalChangeService: GlobalChangeService,
    private companyService: CompanyService
  ) { }

  ngOnInit(): void {
    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);
    } else {
      this.rolePermissionsList = JSON.parse(localStorage.getItem('rolePermissionsList'));
      this.rolePermissionsList = this.rolePermissionsList.filter(x => x.pageId == 9);
      this.permissionMessage = '';
      if (this.rolePermissionsList != null && this.rolePermissionsList != undefined) {
        if (!this.rolePermissionsList[0].isView) {
          this.permissionMessage += 'view';
        } if (!this.rolePermissionsList[0].isInsert) {
          if (this.permissionMessage != '') {
            this.permissionMessage += ', ';
          }
          this.permissionMessage += 'add';
        } if (!this.rolePermissionsList[0].isUpdate) {
          if (this.permissionMessage != '') {
            this.permissionMessage += ', ';
          }
          this.permissionMessage += 'edit';
        } if (!this.rolePermissionsList[0].isDelete) {
          if (this.permissionMessage != '') {
            this.permissionMessage += ', ';
          }
          this.permissionMessage += 'delete';
        }
      }

      if (this.permissionMessage != '') {
        this.permissionMessage = 'You do not have access to ' + this.permissionMessage + '. Please contact to administrator!';
      }
      this.buttonValue = this.constants.Save;
      this.userId = localStorage.getItem(this.constants.userId);
      this.companyLoginId = localStorage.getItem(this.constants.companyId);
     // this.getAllQuestions(this.companyLoginId,this.ProductSearch);
      this.getAllAnswerType();
      // this.getAllCompany_search(this.companyLoginId);
      // this.getAllCompany(this.companyLoginId);
      

      this.dtOptions = {
        pagingType: this.constants.full_numbers,
        pageLength: 10,
        order: [0, 'desc'],
      };

      //ankit implementaion
      // get company by default set value
      var company = this.globalChangeService.getGlobalCompany();
      if(company>0){
        this.value = company;
        this.value_search = company;
      }
      else{
        this.ErrorModal('Error', 'Please select company.');
      }

      //on change function 
      this.globalChangeService.dropdownChange$.subscribe((data)=>{
        this.onValueChange(data);
        this.onValueChange_search(data);
      })
    }
   
       
    this.form = new FormGroup({
      'questions': new FormControl(),
      'answerType': new FormControl(),
    });
    this.formSearch = new FormGroup({
      'TCreateUpdate': new FormControl(),
      'ProductSearch': new FormControl(),
      
    })

  }

  changeAnswerType(event) {
    this.selectedAnswerType = event;
  }

  /* Function to get the all Questions */
  getAllQuestions(companySearch, searchTerm) {
    this.loader = true;
    this.sharedService.getAllQuestions(companySearch,searchTerm).subscribe(
      data => {
        this.data = data;
        if (this.data.dataList != null && this.data.dataList != undefined) {
          this.allQuestionList = this.data.dataList;
        }
        this.ListAngle="up";
        this.loader = false;
        this.rerender();
      });
  }

  /* Function to get the all answer type */
  getAllAnswerType() {
    this.sharedService.getAllAnswerType().subscribe(
      data => {
        this.data = data;
        if (this.data.dataList != null && this.data.dataList != undefined) {
          this.answerTypeData = this.data.dataList;
        }
      });
  }

  /* Function to Add/Update the Questions */
  AddQuestions() {
    this.validation = true;
    this.clearbutton = true;
    if (this.form.controls.questions.status === this.constants.Invalid ||
      this.form.controls.answerType.status === this.constants.Invalid||this.value==0
    ) {
      return;
    }
    const obj = {};
    obj['QuestionTitle'] = this.questions;
    obj['answerTypeId'] = Number(this.selectedAnswerType);
    obj['CompanyId'] = this.value;
    
    this.recordAlreadyExist = false;

    if (this.id != undefined && this.id != null && this.id != '') {
      obj['modifiedBy'] = this.userId;
      obj['CreatedBy'] = this.userId;
      obj['CreatedDate'] = this.createdDate;
      obj['id'] = this.id;
      this.sharedService.updateQuestions(obj).subscribe(
        data => {
          switch (data.statusCode) {
            case 200:
              this.buttonValue = this.constants.Save;
              $("#add_update").show();
              // this.toastr.success(this.constants.RecordUpdateSuccessfully);
              this.validation = false;
              this.TCreateUpdate = 1;
              this.SearchControl = false;
              this.ListShow = false;
              this.ListDiv = false;
              this.ClearModel();
              break;
            case 400:
              $("#wrong_msg").show();
              // this.toastr.error(this.constants.SomethingWentWrong);
              break;

            default:
              $("#wrong_msg").show();
              // this.toastr.error(this.constants.SomethingWentWrong);
          }
        });
    } else {
      obj['createdBy'] = this.userId;
      this.sharedService.addQuestions(obj).subscribe(
        data => {
          switch (data.statusCode) {
            case 200:
              this.buttonValue = this.constants.Save;
              $("#add_success").show();
              // this.toastr.success(this.constants.RecordAddedSuccessfully);
              this.validation = false;
              this.TCreateUpdate = 1;
              this.SearchControl = false;
              this.ListShow = false;
              this.ListDiv = false;
              this.ClearModel();

              break;
            case 400:
              if (data.errorMessage === 'Record Already Exist') {
                this.recordAlreadyExist = true;
              } else {
                $("#wrong_msg").show();
                // this.toastr.error(this.constants.SomethingWentWrong);
              }
              break;

            default:
              $("#wrong_msg").show();
              // this.toastr.error(this.constants.SomethingWentWrong);
          }
        });
    }
  }

  ClearModel() {
    this.questions = '';
    this.selectedAnswerType = '';
    this.id = '';
    this.recordAlreadyExist = false;
    this.buttonValue = this.constants.Save;
    if (this.companyLoginId > 0) {
      this.value = Number(this.companyLoginId)
    }
    else {
      this.value = 0;
    }
  }

  ToggleEdit(item: any) {
    this.ListDiv = false;
    this.ListAngle="down";
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.buttonValue = this.constants.Update;
    this.questions = item.questionTitle;
    this.selectedAnswerType = item.answerTypeId;
    this.id = item.id;
    this.createdBy = item.createdBy;
    this.createdDate = item.createdDate==null?'':item.createdDate==undefined?'':item.createdDate;
    this.clearbutton = false;
    this.value=item.companyId;
  }

  /* Function to open the pop up for deleting a User  */
  deletePopUp(item, content) {
    this.deletedItem = '';
    this.modalService.open(content, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
    this.deleteDetail = 'Do you want to delete ' + item.questionTitle + '?';
    this.deletedItem = item;
  }

  // Apply css into header,save button,cancel button.
  SetSettingCSS(settingCSS, logoImagePath) {
    if (settingCSS != null) {
      let headerCss = document.getElementsByClassName(this.constants.CssHealerClass) as HTMLCollectionOf<HTMLElement>;
      if (headerCss.length != 0) {
        headerCss[0].style.backgroundColor = settingCSS.split(',')[0];
      }
      let saveButtonCss = document.getElementsByClassName(this.constants.CssSaveBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (saveButtonCss.length != 0) {
        saveButtonCss[0].style.backgroundColor = settingCSS.split(',')[1];
      }
      let cencelButtonCss = document.getElementsByClassName(this.constants.CssCancelBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (cencelButtonCss.length != 0) {
        cencelButtonCss[0].style.backgroundColor = settingCSS.split(',')[2];
      }
    }
    if (logoImagePath != null && logoImagePath != "") {
      (document.getElementById('hrdlogoImage') as HTMLImageElement).src = logoImagePath;
    }
  }

  /* Function to deleting a User  */
  deleteQuestions(item:any) {
    
    this.sharedService.deleteQuestions(item.id).subscribe(
      data => {
        this.data = data;
        switch (data.statusCode) {
          case 200:
            $("#delete_success").show();
            // this.toastr.success(this.constants.RecordDeletedSuccessfully);
            this.getAllQuestions(this.value_search, this.ProductSearch == "" ? "" : this.ProductSearch);
            this.rerender();
            break;
          case 400:
            $("#wrong_msg").show();
            // this.toastr.error(this.constants.SomethingWentWrong);
            break;
          default:
            $("#wrong_msg").show();
            // this.toastr.error(this.constants.SomethingWentWrong);
        }
      });
  }

  PartQuestionBack() {
    this.clearbutton = true;
    this.id = null;
    this.buttonValue = this.constants.Save;
    this.ClearModel();
  }
  ChangeTCreateUpdate(event) {
    this.TCreateUpdate = event.target.value;
    if (this.TCreateUpdate == "1") {
      this.SearchControl = false;
      this.ListShow = false;
      this.ListDiv = false;
      this.SearchValidation=false;
      //this.value_search=0;
      this.ClearModel();
      this.clearbutton=true;
      this.buttonValue = this.constants.Save;
    }
    else if (this.TCreateUpdate == "0") {
      this.SearchControl = true;
      this.ProductSearch = "";
    }
  }
  
  ListDivShowHide() {
    if (this.ListDiv == true) {
      this.ListDiv = false;
      this.ListAngle="down";

    }
    else if (this.ListDiv == false) {
      this.ListDiv = true;
      this.ListAngle="up";
    }
  }

  searchQuestion() {
    this.SearchType = false;
   
    if (!this.ProductSearch|| this.value_search == 0) {
      this.SearchValidation = true;
      return;
    }
    this.ListShow=true;
    this.ListDiv=true;
    this.getAllQuestions(this.value_search, this.ProductSearch);
    
  }

  AllQuestion(){
    this.SearchType = true;
    if (this.value_search == 0) {
      this.SearchValidation = true;
      return;
    }
    this.ProductSearch='';
    this.SearchValidation = false;
    this.ListShow=true;
    this.ListDiv=true;
    this.getAllQuestions(this.value_search, this.ProductSearch);
  }
  getAllCompany_search(companyId) {
    this.companyService.GetDropdownCompanyHierarchyModel(this.companyLoginId).subscribe(
      data => {
        this.data = data;
        this.items_search = this.data.dataList;
        this.companyList_search = this.data.dataList;
        // this.companyList_search.forEach((x) => {
        //   this.userGroupObjects_search.push(new TreeviewItem(x, false));
        // });
        this.value_search = Number(companyId);
        //this.getAllState();
      });
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next('');
    });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next('');
    this.settingDetailCSS = localStorage.getItem("settingDetailCSS");
    this.settingLogoImage = localStorage.getItem("settingImageLogo");
    this.SetSettingCSS(this.settingDetailCSS, this.settingLogoImage);
    $("#contentWord").hide();

  }
  getAllCompany(companyId) {
    this.companyService.GetDropdownCompanyHierarchyModel(companyId).subscribe(
      data => {
        this.data = data;
        if (this.data.dataList != null && this.data.dataList != undefined) {
        this.companyList = this.data.dataList;
        // this.companyList.forEach((x) => {
        //   this.userGroupObjects.push(new TreeviewItem(x, false));

        // });
      }
        this.value = Number(companyId);
        //this.getAllCustomer(this.companyLoginId);
      });
  }
  onValueChange_search(value: any): void {
    this.value_search = value;
  }
  

 
  onValueChange(value: any): void {
    this.value = value;
  
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  exportToWord() {
  this.loader=true;
    $("#contentWord").addClass('contentdisplay');
    const htmlContent = this.contentWord.nativeElement;
    // const htmlString = $("#test").html();
    const htmlString = htmlContent.innerHTML;
    asBlob(htmlString, { orientation: 'landscape', margins: { top: 720 } }).then(data => {
      saveAs(data,'save' + '.docx')
    })
    $("#contentWord").removeClass('contentdisplay');
    this.loader=false;
    

  }
  addsuccess() {
    $("#add_success").hide();
  }
  updateitem() {
    $("#add_update").hide();
  }
  deleteitem() {
    $("#delete_success").hide();
  }
  msgitem() {
    $("#wrong_msg").hide();
  }

  
  //ankit implemenation
  ErrorModal(title:string, message:string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }
}
