import { Component, OnInit, QueryList, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { ConstantsService } from 'src/app/constants.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { GlobalChangeService } from 'src/app/services/global-change.service';
import { SharedService } from 'src/app/services/shared.service';
import { CurrencyPipe } from '@angular/common';
import Swal from 'sweetalert2';
import { PartService } from 'src/app/services/part/part.service';
import { PurchaseOrderService } from 'src/app/services/purchaseOrder/purchase-order.service';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-purchase-report',
  templateUrl: './purchase-report.component.html',
  styleUrls: ['./purchase-report.component.css']
})
export class PurchaseReportComponent implements OnInit {
  data: any;
  companyList: any;
  userGroupObjects: any;
  value: number;
  contactTypeDataList: any;
  // dtOptions: DataTables.Settings = {};
  dtOptions: any = {};
  dtTrigger: Subject<any> = new Subject<any>();
  validation: boolean;
  name: any;
  id: any;
  userId: any;
  deletedItem: string;
  deleteDetail: string;
  loader: boolean = false;
  form: FormGroup;
  contactType: string;
  userRoleList: any;
  buttonValue: any;
  settingDetailCSS: any;
  recordAlreadyExist = false;
  settingLogoImage: any;
  clearbutton: boolean = true;
  rolePermissionsList: any;
  permissionMessage: any;
  TCreateUpdate: any = 1;
  ProductSearch: any = "";
  SearchValidation: any;
  SearchControl: boolean = false;
  ListShow: boolean = false;
  ListDiv: boolean = false;
  formSearch: FormGroup;
  reportFormforSO: FormGroup;
  value1 = 0;
  companyLoginId: any;
  userSearch: any;
  ListAngle: any;
  SearchType: any;
  value_search: number;
  userGroupObjects_search = new Array();
  companyList_search: any;
  createdDateEdit: any;
  messageModalImage: any;
  messageModalHeading: any;
  messageModalDescription: any;
  ShippingStatus: any;
  IsStatuschage: boolean = false;
  statusDropdownList: any;
  statusIdVal: any = 0;
  vendorVal: any = 0;
  purchageorderList: any;
  dtElements: QueryList<DataTableDirective>;
  dtTrigger1: Subject<any> = new Subject<any>();
  dtTrigger2: Subject<any> = new Subject<any>();
  ddlPartList: any;
  isBackgroudImage: any;
  companyIdVal: any;
  fromDate: any;
  toDate: any;
  buttons: any[];
  totalAmountAll: any = 0;
  RecOrderListShow = false;
  loginUserName: any;
  loginFirstName: any;
  customerDetails: any;
  datepipe: any;
  companyDetails: any;
  revisionNumbers: any;
  orderNumber: any;
  partList: any;
  CurrencyPipe: any;
  laborHrsCost: any;
  MorderList: any = [];
  IsDateRange: boolean = false;
  vendorlist: any;
  POTypeList: any;
  POstatusList: any;
  totalrows: any;
  items_search: any;
  displayedColumns: string[] = ['po', 'poprefix', 'status', 'vendor', 'orderDate', 'shipDate', 'total']
  dataSource: MatTableDataSource<any>;

  constructor(
    private route: ActivatedRoute,
    public sharedService: SharedService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private constants: ConstantsService,
    private router: Router,
    private globalChangeService: GlobalChangeService,
    private companyService: CompanyService,
    private partService: PartService,
    private poService: PurchaseOrderService
  ) { }


  ngOnInit(): void {

    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);
    } else {
      this.userId = localStorage.getItem(this.constants.userId);
      this.buttonValue = this.constants.Save;

      //ankit implementaion
      // get company by default set value
      var company = this.globalChangeService.getGlobalCompany();
      if (company > 0) {
        this.value = company;
        this.value_search = company;

        this.Getvendordropdownlist(this.value_search);
      }
      else {
        this.ErrorModal('Error', 'Please select company.');
      }

      //on change function 
      this.globalChangeService.dropdownChange$.subscribe((data) => {
        this.onValueChange(data);
        this.onValueChange_search(data);
      })
    }

    this.reportFormforSO = new FormGroup
      ({
        'statusIdVal': new FormControl(),
        'vendorVal': new FormControl(),
        'fromDate': new FormControl(),
        'toDate': new FormControl(),
        'allDateRange': new FormControl('', Validators.required)

      });

    this.rolePermissionsList = JSON.parse(localStorage.getItem('rolePermissionsList'));
    this.rolePermissionsList = this.rolePermissionsList.filter(x => x.pageId == 1044);
    this.permissionMessage = '';
    if (this.rolePermissionsList != null && this.rolePermissionsList != undefined) {
      if (!this.rolePermissionsList[0].isView) {
        this.permissionMessage += 'view';
      } if (!this.rolePermissionsList[0].isInsert) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'add';
      } if (!this.rolePermissionsList[0].isUpdate) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'edit';
      } if (!this.rolePermissionsList[0].isDelete) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'delete';
      }
    }
    if (this.permissionMessage != '') {
      this.permissionMessage = 'You do not have access to ' + this.permissionMessage + '. Please contact to administrator!';
    }
    this.companyLoginId = localStorage.getItem(this.constants.companyId);
    this.getAllCompany_search(this.companyLoginId);
    this.GetShippingStatus();
    this.POStatusDropdownList();

  }

  changeAllDateOrder(e) {

    let valueChange = e.target.value;
    if (valueChange == "allDateRange") {
      this.IsDateRange = true;
    }
    else {
      this.IsDateRange = false;
    }
  }

  get range() {
    return this.form.controls;
  }

  getAllCompany_search(companyId) {
    this.companyService.GetDropdownCompanyHierarchyModel(this.companyLoginId).subscribe(
      data => {

        this.data = data;
        this.items_search = this.data.dataList;
        this.companyList_search = this.data.dataList;
        this.value_search = Number(companyId);
      });
  }

  onValueChange(value: any): void {
    this.value = value;

  }
  onValueChange_search(value: any): void {
    this.value_search = value;
    this.Getvendordropdownlist(this.value_search);
  }

  statusvalues(event) {
    this.statusDropdownList = event.target.value;
    this.IsStatuschage = true;
  }
  GetShippingStatus() {
    this.sharedService.GetShippingStatusdropdownlist().subscribe(
      data => {
        this.data = data;
        this.ShippingStatus = this.data.dataList;

      }
    )
  }


  Getvendordropdownlist(companyId) {

    if (companyId == undefined) { companyId = 0; }
    this.partService.Getvendordropdownlist(companyId).subscribe(

      data => {
        this.data = data;
        if (companyId > 0) {
          this.vendorlist = this.data.dataList;
        }

      }
    )
  }

  POStatusDropdownList() {
    
    this.poService.GetPOStatus().subscribe(
      (data: any) => {
        this.POstatusList = data;
      }
    )
  }

  vendorvalues(e)
  {
    //this.statusDropdownList = event.target.value;
    //this.IsStatuschage = true;
  }

  GetPurchaseOrderReportData() {
    this.companyIdVal = this.value_search;
    if (this.companyIdVal == undefined) { this.companyIdVal = 0; }
    if (this.fromDate == undefined) { this.fromDate = '' }
    if (this.toDate == undefined) { this.toDate = '' }
    this.loader = true;
    this.poService.GetPOReport(this.companyIdVal, Number(this.statusIdVal), Number(this.vendorVal), '0', '50', this.fromDate, this.toDate)
      .subscribe(resp => {
        this.loader = false;
        this.data = resp;
        this.purchageorderList = this.data.dataList;
        if(this.purchageorderList.length > 0){
          this.totalAmountAll = this.purchageorderList.reduce((acc, current) => acc + current.totalAmount, 0);
        }
        this.totalrows = this.purchageorderList.length;
        this.dataSource = new MatTableDataSource(this.purchageorderList);
      });
  }

  //ankit implemenation
  ErrorModal(title: string, message: string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }

}
