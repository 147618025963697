
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PurchaseOrder } from 'src/app/shared/purchaseOrder';
import { MatTableDataSource } from '@angular/material/table';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PurchaseOrderService } from 'src/app/services/purchaseOrder/purchase-order.service';
import { GlobalChangeService } from 'src/app/services/global-change.service';
import Swal from 'sweetalert2';
import { ConstantsService } from 'src/app/constants.service';
import { PartService } from 'src/app/services/part/part.service';
import { VendorService } from 'src/app/services/vendor/vendor.service';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'app-po-list',
  templateUrl: './po-list.component.html',
  styleUrls: ['./po-list.component.css'],
})

export class PoListComponent implements OnInit {
  loader: boolean = false;
  FilterData: { id: number; value: string; }[];
  DateFilterData: { id: number; value: string; }[];
  dataSource: MatTableDataSource<PurchaseOrder>;
  dataS: any = [];
  displayedColumns: string[] = ["menu", "issuedDate", "poNumber", "vendorName", "contactName", "status", "EstArrDate", "payterms", "fobpoint", "shippingTerms"];
  formSearch: FormGroup;
  companyId: number;
  StatusValue: any[] = [];
  IsActive: any = '1';
  vendors: any = [];
  selectedVendorSearch: string;
  maxFromDate: any;
  minToDate: any;
  shippingData: any = [];
  FobPoint: any = [];
  termsData: any = [];
  statuslist: any = [];
  start: number = 0;
  vendorPartNumbers: any;
  defaultStatus: any[] = [];
  isShowDefaultSelected: boolean = true;
  StatusVendorValue: any[] = [];

  constructor(
    private router: Router,
    private POService: PurchaseOrderService,
    private globalChangeService: GlobalChangeService,
    private constants: ConstantsService,
    private partService: PartService,
    private vendorService: VendorService,
    public datepipe: DatePipe,
  ) {
    this.FilterData = this.constants.Filter;
    this.DateFilterData = this.constants.CustomDate;
    this.defaultStatus = this.constants.statusIDsPO;

    this.getAllShippings();
    this.createSerchForm();
    this.POStatusList();
    this.getAllTerms();
    this.getFOBPointList();
  }

  ngOnInit(): void {
    this.companyId = this.globalChangeService.getGlobalCompany();
    if (this.companyId > 0) {
      this.dataS = [];
      this.isShowDefaultSelected = true;
      this.getAllPOList(false,this.defaultStatus);
      this.Getvendordropdownlist(this.companyId);
    } else {
      this.PopupModal(this.constants.APIError, this.constants.SelectCompany);
    }
    //on change function 
    this.globalChangeService.dropdownChange$.subscribe((data) => {
      this.companyId = data;
      this.dataS = [];
      this.isShowDefaultSelected = true;
      this.getAllPOList(false,this.defaultStatus);
      this.Getvendordropdownlist(this.companyId);
    })
  }
  createSerchForm() {
    const today = new Date();
    this.formSearch = new FormGroup({
      "selected": new FormControl('All'),
      "searchText": new FormControl(''),
      "filterType": new FormControl(1),
      "status": new FormControl(this.defaultStatus),
      "vendor": new FormControl(''),
      "vendorValue": new FormControl(''),
      "timeperiod": new FormControl(1),
      "prevdate": new FormControl(new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000)),
      "curdate": new FormControl(today)
    });
  }

  getAllPOList(isUpdate,selectedStatusIds) {
    if(this.isShowDefaultSelected === true){
      selectedStatusIds = this.defaultStatus;
    }else{
      selectedStatusIds = this.StatusValue;
    }
    const obj = {
      companyId: this.companyId,
      statusIds: selectedStatusIds,
      vendorIds: [0],
      vendorPartNumber: '',
      FromDate: '',
      ToDate: '',
      StartIndex: isUpdate ? (this.start+25) : this.start,
      EndIndex: 25,
      FilterType: 1,
      searchText: this.formSearch.value.searchText == undefined ? '' : this.formSearch.value.searchText,
    }
    this.POService.GetAllPurchaseOrderList(obj).subscribe({
      next: (data: any) => {
        this.loader = false;
        this.dataS = this.dataS.concat(data.dataList);
        this.dataS = this.removeDuplicates(this.dataS, 'id');
        this.dataSource = new MatTableDataSource(this.dataS);       
        if (isUpdate) {
          this.updateIndex();
        }
      },
      error: error => {
        this.PopupModal(this.constants.APIError, error);
      },
      complete: () => {
      }
    });
  }

  removeDuplicates(myArray, Prop) {
    return myArray.filter((obj, pos, arr) => {
      return arr.map(mapObj => mapObj[Prop]).indexOf(obj[Prop]) === pos;
    });
  }

  updateIndex() {
    this.start = this.start + 25;
  }

  onScroll(e){
    const tableViewHeight = e.target.offsetHeight // viewport: ~500px
    const tableScrollHeight = e.target.scrollHeight // length of all table
    const scrollLocation = e.target.scrollTop; // how far user scrolled
    // If the user has scrolled within 200px of the bottom, add more data
    const buffer = 200;
    const limit = tableScrollHeight - tableViewHeight - buffer;
    if (scrollLocation > limit) {
      //this.getAllPOList(true,this.defaultStatus);
      this.getFilterStatusData();
    }
  }

  Getvendordropdownlist(companyId) {
    if (companyId == undefined) { companyId = 0; }
    this.partService.Getvendordropdownlist(this.companyId).subscribe(
      (data: any) => {
        if (companyId > 0) {
          this.vendors = data.dataList;
        }
        else {
          this.selectedVendorSearch = '0';
        }
      }
    )
  }

  createNewOrder() {
    if(this.companyId>0){
      this.router.navigate(['/poDetails'])
    }else{
      this.PopupModal(this.constants.Alert, this.constants.SelectCompany);
    }
  }

  onInput(event) {
    
    this.vendorPartNumbers = event.target.value;
  }

  getDataForFilter(orderRequest) {
    this.POService.GetAllPurchaseOrderList(orderRequest).subscribe((data: any) => {
      this.dataSource = new MatTableDataSource(data.dataList);
    })
    this.updateIndex();
  }

  onSearch(){
    this.start = 0;
    this.dataS = [];
    this.getAllPOList(false,'');
  }

  getFilterStatusData() {
    this.isShowDefaultSelected = false;
    const value = this.formSearch.value.filterType;
    const orderRequest = {
      companyId: this.companyId,
      statusIds: this.formSearch.value.status,
      vendorIds: [0],
      vendorPartNumber: '',
      fromDate: '',
      toDate: '',
      startIndex: 0,
      // endIndex: 5000,
      endIndex: this.start != 0 ? this.start : 0,
      filterType: value,
      searchText: ''
    }
    switch (value) {
      case 1:
        orderRequest.vendorIds = [0];
        if (orderRequest.statusIds != null || orderRequest.statusIds != undefined) {
          this.getDataForFilter(orderRequest);
        }
        break;
      case 2:
        orderRequest.statusIds = [0];
        orderRequest.vendorIds = this.StatusVendorValue.map(str => parseInt(str, 10));
        if (orderRequest.vendorIds != null || orderRequest.vendorIds != undefined) {
          this.getDataForFilter(orderRequest);
        }
        break;
      case 3:
        orderRequest.vendorPartNumber = this.vendorPartNumbers;
        this.getDataForFilter(orderRequest);
        break;
      case 4:
        orderRequest.fromDate = this.datepipe.transform(this.formSearch.value.prevdate, 'MM/dd/yyyy');
        orderRequest.toDate = this.datepipe.transform(this.formSearch.value.curdate, 'MM/dd/yyyy');
        this.getDataForFilter(orderRequest);
        break;
    }
  }

  GetStatus($event) {
    this.isShowDefaultSelected = true;
    this.StatusValue = $event.value;
  }
  
  GetVendor($event){
    this.isShowDefaultSelected = false;
    this.StatusVendorValue = $event.value;
  }

  PopupModal(title: string, message: string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }

  POStatusList() {
    this.POService.GetPOStatus().subscribe(
      (data: any) => {
        this.statuslist = data.filter(x => x.isPurchaseOrder == true).sort((a, b) => a.poOrdering - b.poOrdering);
      }
    )
  }

  GetDateValue() {
    
    const today = new Date();
    const timeperiod = this.formSearch.value.timeperiod;
    switch (timeperiod) {
      case 1:
        this.formSearch.patchValue({
          curdate: today,
          prevdate: new Date(today.getTime() - 30 * 24 * 60 * 60 * 1000)
        })
        break;
      case 2:
        this.formSearch.patchValue({
          curdate: today,
          prevdate: new Date(today.getTime() - 2 * 30 * 24 * 60 * 60 * 1000)
        })
        break;
      case 3:
        this.formSearch.patchValue({
          curdate: today,
          prevdate: new Date(today.getTime() - 3 * 30 * 24 * 60 * 60 * 1000)
        })
        break;
      case 4:
        this.formSearch.patchValue({
          curdate: today,
          prevdate: new Date(today.getFullYear(), 0, 1)
        })
        break;
      default:
        this.formSearch.patchValue({
          curdate: '',
          prevdate: ''
        })
        break;
    }
  }

  getAllShippings() {
    this.vendorService.getAllShippings().subscribe(
      (data: any) => {
        this.shippingData = data.dataList;
      });
  }

  GetShippingTermKey(id: any) {
    if (this.shippingData.length > 0 && id > 0) {
      const list = this.shippingData.filter(x => x.id == Number(id));
      if (list.length > 0) {
        return list[0].name;
      }
    }
    return '';
  }

  getFOBPointList() {
    this.partService.FobPointsDropdownList().subscribe(
      (data: any) => {
        this.FobPoint = data.dataList;
      }
    )
  }

  GetFOBKey(id: any) {
    if (this.FobPoint.length > 0 && id > 0) {
      const list = this.FobPoint.filter(x => x.id == Number(id));
      if (list.length > 0) {
        return list[0].name;
      }
    }
    return '';
  }

  getAllTerms() {
    this.vendorService.getAllTerms().subscribe(
      (data: any) => {
        this.termsData = data.dataList;
      });
  }

  GetTermKey(id: any) {
    if (this.termsData.length > 0 && id > 0) {
      const list = this.termsData.filter(x => x.id == Number(id));
      if (list.length > 0) {
        return list[0].name;
      }
    }
    return '';
  }

  GetStatusKey(id: any) {
    if (this.statuslist.length > 0 && id > 0) {
      const list = this.statuslist.filter(x => x.statusId == Number(id));
      if (list.length > 0) {
        return list[0].statusName;
      }
    }
    return '';
  }

  GetPOById(row: any) {
    this.router.navigate(['/poDetails', row.id])
  }
}

