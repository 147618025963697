import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ConstantsService } from 'src/app/constants.service';
import { SharedService } from 'src/app/services/shared.service';
// import { TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GlobalChangeService } from 'src/app/services/global-change.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-user-permissions',
  templateUrl: './user-permissions.component.html',
  styleUrls: ['./user-permissions.component.css']
})
export class UserPermissionsComponent implements OnInit {
  data: any;
  userRoleList: any;
  selectedRoleValue = '';
  rolePermissionsList: any;
  userId: string;
  roleId;
  settingDetailCSS: any;
  settingLogoImage: any;
  companyLoginId: any;
  // items: TreeviewItem[];
  companyList: any;
  CopyfromRoleList: any;
  userGroupObjects = new Array();
  value = 0;
  selectedcopyrole: '';
  searchitem: any = '';
  Selectitem: any = '';
  IsPermissionExist: boolean = false;
  confirmationMsg: any;
  permissionId: any;
  notpermissionId: any;
  // config = TreeviewConfig.create({
  //   hasAllCheckBox: true,
  //   hasFilter: true,
  //   hasCollapseExpand: true,
  //   decoupleChildFromParent: false,
  //   maxHeight: 400
  // });
  rolelist: any;
  roleDetails = [];
  perArray: any;
  nopermissionrole: any;
  loader: boolean = false;
  //modalService: any;
  constructor(
    public sharedService: SharedService,
    private constants: ConstantsService,
    private router: Router,
    private modalService: NgbModal,
    private globalChangeService: GlobalChangeService
  ) { }

  ngOnInit(): void {
    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);

    } else {
      this.userId = localStorage.getItem(this.constants.userId);
      this.settingDetailCSS = localStorage.getItem("settingDetailCSS");
      this.settingLogoImage = localStorage.getItem("settingImageLogo");
      this.SetSettingCSS(this.settingDetailCSS, this.settingLogoImage);
      this.companyLoginId = localStorage.getItem(this.constants.companyId);
      
      // this.getAllCompany(this.companyLoginId);
      // if (this.companyLoginId > 0) {
      //   this.getAllUserRolesByUserId(this.companyLoginId, '');
      // }
      // else {
      //   this.selectedRoleValue = '';
      // }

      //ankit implementaion
      // get company by default set value
      let company = this.globalChangeService.getGlobalCompany();
      if(company>0){
        this.value = company;
        if (this.value > 0) {
          this.getAllUserRolesByUserId(this.value, this.searchitem);
        }
        else {
          this.selectedRoleValue = '';
        }
      }
      else{
        this.ErrorModal('Error', 'Please select company.');
      }

      //on change function 
      this.globalChangeService.dropdownChange$.subscribe((data)=>{
        this.onValueChange(data);
      })
    }
  }
  getAllUsersRole() {
    this.sharedService.getAllUserRoles().subscribe(
      data => {
        this.data = data;
        this.userRoleList = this.data.dataList;
      });
  }
  getAllUserRolesByUserId(companyId, searchitem) {

    this.sharedService.getAllUserRolesByUserId(companyId, '').subscribe(
      data => {
        this.data = data;
        this.userRoleList = this.data.dataList;
      });
  }
  getCopyFromRole(companyId, roleId) {
    this.sharedService.getAllRoleForCopy(companyId, roleId).subscribe(
      data => {
        this.data = data;
        this.CopyfromRoleList = this.data.dataList;
        if (this.CopyfromRoleList.length > 0) {
          this.IsPermissionExist = true;
          this.selectedcopyrole = '';
        }
        else {
          this.IsPermissionExist = false;
        }
      });
  }

  changeUserRole(event) {
    if (event != "") {
      this.selectedRoleValue = event;
      this.nopermissionrole = event;
      this.roleId = event;
      this.getAllRolePermissions(this.roleId);
      this.getCopyFromRole(this.value, this.roleId);
    }
    else {
      this.IsPermissionExist = false;
      this.rolePermissionsList = [];
      this.perArray = [];
    }
  }

  CopyRoleChange(event) {
    if (event != "") {
      this.permissionId = event;
      this.notpermissionId = this.roleId;
      this.roleId = this.permissionId;
      this.selectedcopyrole = this.permissionId;
      this.getAllRolePermissions(this.roleId);
    }
    else {
      this.IsPermissionExist = true;
      this.selectedcopyrole = '';
      //this.rolePermissionsList=[];
    }
    //this.getCopyFromRole(this.value,this.roleId);

  }


  // onRolePermissionChange(event, pageId, permissionName) {
  //   if (event) {
  //     let obj = {}
  //     obj['isChecked'] = event.target.checked;
  //     obj['pageId'] = pageId;
  //     obj['permissionName'] = permissionName;
  //     obj['roleId'] = this.roleId;
  //     this.sharedService.SaveRolePermission(obj).subscribe(
  //       data => {
  //         $("#add_update").show();
  //         // this.toastr.success(this.constants.RecordUpdateSuccessfully);
  //         this.getAllRolePermissions();
  //       });
  //   }
  // }
  getAllRolePermissions(roleId) {
    this.sharedService.GetAllRolePermissionByUserId(roleId).subscribe(
      data => {
        this.data = data;
        this.rolePermissionsList = this.data.dataList;
        this.perArray = this.rolePermissionsList.length;
        
      });
  }


  GetAllCopyPermission(nonpermission, permission) {
    this.sharedService.GetRoleCopyfromPermission(nonpermission, permission).subscribe(
      data => {
        this.data = data;
        this.rolePermissionsList = this.data.dataList;
        this.IsPermissionExist = false;
      });
  }

  // getAllCompany(companyId) {
  //   this.sharedService.GetDropdownCompanyHierarchyModel(companyId).subscribe(
  //     data => {
  //       this.data = data;
  //       if (this.data.dataList != null && this.data.dataList != undefined) {
  //         this.companyList = this.data.dataList;
  //         this.companyList.forEach((x) => {
  //           this.userGroupObjects.push(new TreeviewItem(x, false));
  //         });
  //       }
  //       this.value = Number(companyId);
  //       //this.getAllCustomer(this.companyLoginId);
  //     });
  // }

  onValueChange(value: any): void {
    this.value = value;
    if (this.value > 0) {
      this.getAllUserRolesByUserId(this.value, this.searchitem);
    }
    else {
      this.selectedRoleValue = '';
    }
  }
  SetSettingCSS(settingCSS, logoImagePath) {
    if (settingCSS != null) {
      let headerCss = document.getElementsByClassName(this.constants.CssHealerClass) as HTMLCollectionOf<HTMLElement>;
      if (headerCss.length != 0) {
        headerCss[0].style.backgroundColor = settingCSS.split(',')[0];
      }
      let saveButtonCss = document.getElementsByClassName(this.constants.CssSaveBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (saveButtonCss.length != 0) {
        saveButtonCss[0].style.backgroundColor = settingCSS.split(',')[1];
      }
      let cencelButtonCss = document.getElementsByClassName(this.constants.CssCancelBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (cencelButtonCss.length != 0) {
        cencelButtonCss[0].style.backgroundColor = settingCSS.split(',')[2];
      }
    }

    if (logoImagePath != null && logoImagePath != "") {
      (document.getElementById('hrdlogoImage') as HTMLImageElement).src = logoImagePath;
    }



  }
  ApplyPermission() {
    let rolepart = this.rolePermissionsList;
    let obj = {};
    for (var j = 0; j < rolepart.length; j++) {
      let roleObj = {};
      roleObj["pageId"] = rolepart[j].pageId;
      roleObj["pageName"] = rolepart[j].pageName;
      roleObj["isView"] = rolepart[j].isView;
      roleObj["isUpdate"] = rolepart[j].isUpdate;
      roleObj["isServices"] = rolepart[j].isServices;
      roleObj["isPurchaseOrderNumber"] = rolepart[j].isPurchaseOrderNumber;
      roleObj["IsProductionStatus"] = rolepart[j].isProductionStatus;
      roleObj["IsLaborRateChange"] = rolepart[j].isLaborRateChange;
      roleObj["IsSOProfitability"] = rolepart[j].isSOProfitability;
      roleObj["IsUpdateReasonPopUp"] = rolepart[j].isUpdateReasonPopUp;
      roleObj["isInsert"] = rolepart[j].isInsert;
      roleObj["isExportWord"] = rolepart[j].isExportWord;
      roleObj["isExportPdf"] = rolepart[j].isExportPdf;
      roleObj["isDelete"] = rolepart[j].isDelete;
      roleObj["isActive"] = rolepart[j].isActive;
      roleObj["roleId"] = this.nopermissionrole;
      this.roleDetails.push(roleObj);
    }
    obj["RolePermissionModel"] = this.roleDetails;
    this.sharedService.SaveRolePermission(obj).subscribe(
      data => {
        $("#add_update").show();
        this.roleDetails = [];
        this.IsPermissionExist = false;
        this.getAllRolePermissions(this.nopermissionrole);
      });

  }

  Apply(content) {
    this.modalService.open(content, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
    this.confirmationMsg = 'Do you want to copy from role?';

  }
  addsuccess() {
    $("#add_success").hide();
  }
  updateitem() {
    $("#add_update").hide();
  }
  deleteitem() {
    $("#delete_success").hide();
  }

  AllSelectPermission(event) {
    let ischeck = event.target.checked;
    this.rolelist = this.rolePermissionsList;
    if (this.rolelist.length > 0) {
      this.rolelist.forEach(roleitem => {
        roleitem.isView = ischeck;
        roleitem.isInsert = ischeck;
        roleitem.isUpdate = ischeck;
        roleitem.isDelete = ischeck;
        roleitem.isExportPdf = ischeck;
        roleitem.isExportWord = ischeck;
        roleitem.isPurchaseOrderNumber = ischeck;
        roleitem.isProductionStatus = ischeck;
        roleitem.isLaborRateChange = ischeck;
        roleitem.isSOProfitability = ischeck;
        roleitem.isServices = ischeck;
      });
      this.rolePermissionsList = this.rolelist;
    }
    else {

    }
  }


  updateRole() {
    let rolepart = this.rolePermissionsList;
    let obj = {};
    for (var j = 0; j < rolepart.length; j++) {
      let roleObj = {};
      roleObj["pageId"] = rolepart[j].pageId;
      roleObj["pageName"] = rolepart[j].pageName;
      roleObj["isView"] = rolepart[j].isView;
      roleObj["isUpdate"] = rolepart[j].isUpdate;
      roleObj["isServices"] = rolepart[j].isServices;
      roleObj["isPurchaseOrderNumber"] = rolepart[j].isPurchaseOrderNumber;
      roleObj["IsProductionStatus"] = rolepart[j].isProductionStatus;
      roleObj["IsLaborRateChange"] = rolepart[j].isLaborRateChange;
      roleObj["IsSOProfitability"] = rolepart[j].isSOProfitability;
      roleObj["isInsert"] = rolepart[j].isInsert;
      roleObj["isExportWord"] = rolepart[j].isExportWord;
      roleObj["isExportPdf"] = rolepart[j].isExportPdf;
      roleObj["IsUpdateReasonPopUp"] = rolepart[j].isUpdateReasonPopUp;
      roleObj["isDelete"] = rolepart[j].isDelete;
      roleObj["isActive"] = rolepart[j].isActive;
      roleObj["roleId"] = this.roleId;
      roleObj["isCopy"] =  rolepart[j].isCopy;
      this.roleDetails.push(roleObj);
    }
    obj["RolePermissionModel"] = this.roleDetails;
    this.loader = true;
    this.sharedService.SaveRolePermission(obj).subscribe(
      data => {
        $("#add_update").show();
        this.roleDetails = [];
        this.IsPermissionExist = false;
        this.loader = false;
        this.getAllRolePermissions(this.roleId);
      });
  }

  //ankit implemenation
  ErrorModal(title:string, message:string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }
}


