<mat-card class="mat-card-custom p-0 h-100" [formGroup]="formGrp">
    <mat-card-header class="align-items-center gap-3 change-mat-header">
        <div class="main-heading">Ship to</div>
        <div class="form-fields-custom">
            <mat-form-field [ngClass]="isShipTo == true ? 'height-change' : 'height-change height-change-danger'" appearance="outline">
                <mat-select class="select-arrow-change-icon" (selectionChange)="changeShipto($event.value)"
                    formControlName="selectedShipTo" placeholder="Select Ship to">
                    <mat-option *ngFor="let item of ShipTo" [value]="item.id+'-'+item.type">
                        {{item.name}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
    </mat-card-header>
    <div formGroupName="ship">
        <mat-card-content>
            <div class="address">
                <ng-container *ngIf="formGrp.value.shipToType === 'drop_ship';">
                    <div class="form-fields-custom dropship-contain">
                        <mat-form-field appearance="outline" class="height-change">
                            <input matInput placeholder="Search Customers" [matAutocomplete]="auto"
                                (input)="onInputCustomer($event.target.value)" class="m-0">
                            <mat-autocomplete #auto="matAutocomplete">
                                <mat-option *ngFor="let item of filteredData"
                                    [value]="item.customerName" (click)="changeDDLCustomer(item.id)">
                                    {{item.customerName}}
                                </mat-option>
                            </mat-autocomplete>
                            <span class="material-icons">
                                search
                            </span>
                        </mat-form-field>
                    </div>
                </ng-container>
                <span>{{formGrp.value.ship.contactName}}</span>
                <span>{{formGrp.value.ship.name}}</span>
                <ng-container *ngIf="formGrp.value.shipToType !== 'will_call' && formGrp.value.shipToType !== '' && formGrp.value.vendorId>0;">
                    <input matInput type="text" formControlName="attention" placeholder="Attn: Receiving"
                        autocomplete="off" class="w-100" /><br />
                </ng-container>

                <span>{{formGrp.value.ship.add1}} {{formGrp.value.ship.add2}}</span>
                <span>{{formGrp.value.ship.city}} {{GetStateName(formGrp.value.ship.state)}} {{formGrp.value.ship.zip}}</span>
            </div>
        </mat-card-content>
    </div>
</mat-card>