import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
// import { TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { SharedService } from '../services/shared.service';
import { ConstantsService } from 'src/app/constants.service';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { GlobalChangeService } from '../services/global-change.service';
import Swal from 'sweetalert2';
import { CompanyService } from '../services/company/company.service';
import { error } from 'console';

@Component({
  selector: 'app-location-levels',
  templateUrl: './location-levels.component.html',
  styleUrls: ['./location-levels.component.css']
})
export class LocationLevelsComponent implements OnInit {

  loader: boolean = false;
  permissionMessage: any;
  rolePermissionsList: any;
  userId: string;
  value: number;
  data: any;
  userGroupObjects = new Array();
  companyLoginId: any;
  validation: boolean;
  form: FormGroup;

  isShowLocationLevelList: boolean = false;

  selectedLocationValue: any = '';
  locationTypeList: any;
  totalLocations: any = 0;
  locationName: any;

  deletedItem: string;
  deleteDetail: string;

  searchValue: any;
  //location levels details to add
  nameOfLevel1: any = '';
  numbersOfLocations1: any;
  startingLettersNumbers1: any = '';
  specialCharacter1: any = '';
  level1Id: any = 0;

  nameOfLevel2: any = '';
  numbersOfLocations2: any;
  startingLettersNumbers2: any = '';
  specialCharacter2: any = '';
  level2Id: any = 0;

  nameOfLevel3: any = '';
  numbersOfLocations3: any;
  startingLettersNumbers3: any = '';
  specialCharacter3: any = '';
  level3Id: any = 0;

  nameOfLevel4: any = '';
  numbersOfLocations4: any;
  startingLettersNumbers4: any = '';
  specialCharacter4: any = '';
  level4Id: any = 0;

  clearbutton: boolean = true;

  router: any;
  buttonValue: string;
  id: any;
  createdDateEdit: any;
  createdByEdit: any;

  messageModalImage: any;
  messageModalHeading: any;
  messageModalDescription: any;
  @ViewChild('messageModal', { static: false }) private messageModal;
  ListShow: boolean = false;
  ListDiv: boolean = false;
  ListAngle: any;
  isEdit: boolean = false;
  locationDetailsList: any;
  locationLevelsList: any;
  columnSort: any = '';
  sortType: any = 'asc';
  @ViewChildren(DataTableDirective)
  dtElement: QueryList<DataTableDirective>;
  dtOptions: DataTables.Settings = {};
  dtOptions1: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  dtTrigger1: Subject<any> = new Subject<any>();
  TCreateUpdate: any = 1;
  SearchControl: boolean = false;
  userSearch: any;
  formSearch: FormGroup;
  SearchType: any;
  SearchValidation: any;
  locationDesc: any;
  css_startingLettersNumbers2: boolean = false;
  css_numbersOfLocations2: boolean = false;
  css_specialCharacter2: boolean = false;
  css_startingLettersNumbers4: boolean = false;
  css_numbersOfLocations4: boolean = false;
  css_specialCharacter3: boolean = false;
  css_startingLettersNumbers3: boolean = false;
  css_numbersOfLocations3: boolean = false;
  level1List = [];
  locationTypes: any = []
  constructor(
    public sharedService: SharedService,
    private constants: ConstantsService,
    private modalService: NgbModal,
    private globalChangeService: GlobalChangeService
  ) 
  { 
    this.getLocationTypeList();
  }
    

  ngOnInit(): void {
    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);
    } else {
      this.userId = localStorage.getItem(this.constants.userId);
      this.buttonValue = this.constants.Create;
    }

    this.formSearch = new FormGroup({
      'TCreateUpdate': new FormControl(),
      'userSearch': new FormControl(),
    });

    this.dtOptions = {
      pagingType: this.constants.full_numbers,
      pageLength: 10,
      ordering: true,
    };

    this.dtOptions1 = {
      pagingType: this.constants.full_numbers,
      pageLength: 10,
      ordering: true,
    };


    this.form = new FormGroup({
      'selectedLocationValue': new FormControl(),
      'locationName': new FormControl(),
      'locationDesc': new FormControl(),
      'nameOfLevel1': new FormControl(),
      'numbersOfLocations1': new FormControl(),
      'startingLettersNumbers1': new FormControl(),
      'specialCharacter1': new FormControl(),

      'nameOfLevel2': new FormControl(),
      'numbersOfLocations2': new FormControl(),
      'startingLettersNumbers2': new FormControl(),
      'specialCharacter2': new FormControl(),

      'nameOfLevel3': new FormControl(),
      'numbersOfLocations3': new FormControl(),
      'startingLettersNumbers3': new FormControl(),
      'specialCharacter3': new FormControl(),

      'nameOfLevel4': new FormControl(),
      'numbersOfLocations4': new FormControl(),
      'startingLettersNumbers4': new FormControl(),
      'specialCharacter4': new FormControl(),
    });

    this.rolePermissionsList = JSON.parse(localStorage.getItem('rolePermissionsList'));
    this.rolePermissionsList = this.rolePermissionsList.filter(x => x.pageId == 1026);
    this.permissionMessage = '';
    if (this.rolePermissionsList != null && this.rolePermissionsList != undefined) {
      if (!this.rolePermissionsList[0].isView) {
        this.permissionMessage += 'view';
      } if (!this.rolePermissionsList[0].isInsert) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'add';
      } if (!this.rolePermissionsList[0].isUpdate) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'edit';
      } if (!this.rolePermissionsList[0].isDelete) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'delete';
      }
    }
    if (this.permissionMessage != '') {
      this.permissionMessage = 'You do not have access to ' + this.permissionMessage + '. Please contact to administrator!';
    }
    //ankit implementaion
    var company = this.globalChangeService.getGlobalCompany();
    if (company > 0) {
      
      this.value = company;
      this.searchValue = company;
      this.getAllLocationType(company);
    }
    else {
      this.ErrorModal('Error', 'Please select company.');
    }

    //on change function 
    this.globalChangeService.dropdownChange$.subscribe((data) => {
      this.searchValue = data;
      this.value = data;
      this.onValueChange(data);
      this.getAllLocationType(company);
    })
  }

  getLocationTypeList(){
    this.sharedService.GetMasterLocationTypeList().subscribe(
      data => {
        this.data = data;
        if(this.data.data.length>0){
          this.locationTypes = this.data.data;
        }
      });
  }

  onValueChange(value: any): void {
    this.value = value;
    if (this.value > 0) {
      this.getAllLocationType(this.value);
    }
    else {
      this.selectedLocationValue = '';
    }
  }

  changeLocationType(event) {
    this.selectedLocationValue = event;
  }

  getAllLocationType(companyId) {
    this.sharedService.getLocationTypeList(companyId, '').subscribe(
      data => {
        this.data = data;
        if (companyId > 0) {
          this.locationTypeList = this.data.dataList;
        }
        else {
          this.selectedLocationValue = '';
        }
      });
  }

  ChangeTCreateUpdate(event) {

    this.TCreateUpdate = event.target.value;
    if (this.TCreateUpdate == "1") {
      this.SearchControl = false;
      this.ListShow = false;
      this.ListDiv = false;
      this.buttonValue = this.constants.Save;
      this.resetLocationLevel();
      this.searchValue = this.value;
    }
    else if (this.TCreateUpdate == "0") {
      this.SearchControl = true;
      this.userSearch = "";
      this.searchValue = this.value;
    }
  }

  addLocationLevelDetails() {
    this.validation = true;
    this.clearbutton = true;

    if ((this.nameOfLevel2 != "" && this.numbersOfLocations2 != "") && this.startingLettersNumbers2 == "") {
      this.css_startingLettersNumbers2 = true;
      this.css_numbersOfLocations2 = false;
      // this.css_specialCharacter2 = false;
      this.css_startingLettersNumbers4 = false;
      this.css_numbersOfLocations4 = false;
      // this.css_specialCharacter3 = false;
      this.css_startingLettersNumbers3 = false;
      this.css_numbersOfLocations3 = false;
      return
    }
    else if ((this.nameOfLevel2 != "" && this.startingLettersNumbers2 != "") && this.numbersOfLocations2 == "") {
      this.css_numbersOfLocations2 = true;
      this.css_startingLettersNumbers2 = false;
      // this.css_specialCharacter2 = false;
      this.css_startingLettersNumbers4 = false;
      this.css_numbersOfLocations4 = false;
      // this.css_specialCharacter3 = false;
      this.css_startingLettersNumbers3 = false;
      this.css_numbersOfLocations3 = false;
      return
    }
    else if ((this.nameOfLevel3 != "" && this.numbersOfLocations3 != "") && this.startingLettersNumbers3 == "") {
      this.css_startingLettersNumbers3 = true;
      this.css_numbersOfLocations2 = false;
      this.css_startingLettersNumbers2 = false;
      // this.css_specialCharacter2 = false;
      this.css_startingLettersNumbers4 = false;
      this.css_numbersOfLocations4 = false;
      // this.css_specialCharacter3 = false;
      this.css_numbersOfLocations3 = false;
      return
    }
    else if ((this.nameOfLevel3 != "" && this.startingLettersNumbers3 != "") && this.numbersOfLocations3 == "") {
      this.css_numbersOfLocations3 = true;
      this.css_startingLettersNumbers3 = false;
      this.css_numbersOfLocations2 = false;
      this.css_startingLettersNumbers2 = false;
      // this.css_specialCharacter2 = false;
      this.css_startingLettersNumbers4 = false;
      this.css_numbersOfLocations4 = false;
      // this.css_specialCharacter3 = false;
      return
    }
    else if ((this.nameOfLevel4 != "" && this.numbersOfLocations4 != "") && this.startingLettersNumbers4 == "") {
      this.css_startingLettersNumbers4 = true;
      this.css_numbersOfLocations3 = false;
      this.css_startingLettersNumbers3 = false;
      this.css_numbersOfLocations2 = false;
      this.css_startingLettersNumbers2 = false;
      // this.css_specialCharacter2 = false;
      this.css_numbersOfLocations4 = false;
      // this.css_specialCharacter3 = false;
      return
    }
    else if ((this.nameOfLevel4 != "" && this.startingLettersNumbers4 != "") && this.numbersOfLocations4 == "") {
      this.css_numbersOfLocations4 = true;
      this.css_startingLettersNumbers4 = false;
      this.css_numbersOfLocations3 = false;
      this.css_startingLettersNumbers3 = false;
      this.css_numbersOfLocations2 = false;
      this.css_startingLettersNumbers2 = false;
      // this.css_specialCharacter2 = false;
      // this.css_specialCharacter3 = false;
      return
    }
    // else if ((this.numbersOfLocations3 != "" && this.startingLettersNumbers3 != "") && this.specialCharacter2 == "") {
    //   // this.css_specialCharacter2 = true;
    //   this.css_numbersOfLocations4 = false;
    //   this.css_startingLettersNumbers4 = false;
    //   this.css_numbersOfLocations3 = false;
    //   this.css_startingLettersNumbers3 = false;
    //   this.css_numbersOfLocations2 = false;
    //   this.css_startingLettersNumbers2 = false;
    //   // this.css_specialCharacter3 = false;
    //   return
    // }
    // else if ((this.numbersOfLocations4 != "" && this.startingLettersNumbers4 != "") && this.specialCharacter3 == "") {
    //   // this.css_specialCharacter3 = true;
    //   // this.css_specialCharacter2 = false;
    //   this.css_numbersOfLocations4 = false;
    //   this.css_startingLettersNumbers4 = false;
    //   this.css_numbersOfLocations3 = false;
    //   this.css_startingLettersNumbers3 = false;
    //   this.css_numbersOfLocations2 = false;
    //   this.css_startingLettersNumbers2 = false;
    //   return
    // }
    else {
      this.css_startingLettersNumbers2 = false;
      this.css_numbersOfLocations2 = false;
      // this.css_specialCharacter2 = false;
      this.css_startingLettersNumbers4 = false;
      this.css_numbersOfLocations4 = false;
      // this.css_specialCharacter3 = false;
      this.css_startingLettersNumbers3 = false;
      this.css_numbersOfLocations3 = false;
    }

    // if (this.value == 0 || this.form.controls.selectedLocationValue.status == this.constants.Invalid || this.form.controls.locationName.status == this.constants.Invalid
    //   || this.form.controls.specialCharacter1.status == this.constants.Invalid
    //   || this.form.controls.startingLettersNumbers1.status == this.constants.Invalid
    //   || this.form.controls.nameOfLevel1.status == this.constants.Invalid
    //   || this.form.controls.numbersOfLocations1.status == this.constants.Invalid
    // )
    if (this.value == 0
      || this.form.controls.selectedLocationValue.status == this.constants.Invalid 
      || this.form.controls.locationName.status == this.constants.Invalid
      || this.form.controls.startingLettersNumbers1.status == this.constants.Invalid
      || this.form.controls.nameOfLevel1.status == this.constants.Invalid
      || this.form.controls.numbersOfLocations1.status == this.constants.Invalid
    ) {
      return;
    }

    // if(this.nameOfLevel2!=null && this.startingLettersNumbers2!=0)
    // {
    //   this.form.controls.startingLettersNumbers2.invalid
    // }

    this.loader = true;

    const objLevel1 = {};
    objLevel1['NameOfLevels'] = this.nameOfLevel1;
    objLevel1['NumbersOfLocations'] = Number(this.numbersOfLocations1 ? this.numbersOfLocations1 : 0);
    objLevel1['StartingLettersNumbers'] = this.startingLettersNumbers1;
    // objLevel1['SpecialCharacter'] = this.specialCharacter1;
    objLevel1['SpecialCharacter'] = '-';
    objLevel1['CompanyId'] = this.value;
    objLevel1['IsActive'] = true;

    const objLevel2 = {};
    objLevel2['NameOfLevels'] = this.nameOfLevel2;
    objLevel2['NumbersOfLocations'] = Number(this.numbersOfLocations2 ? this.numbersOfLocations2 : 0);
    objLevel2['StartingLettersNumbers'] = this.startingLettersNumbers2;
    // objLevel2['SpecialCharacter'] = this.specialCharacter2;
    objLevel2['SpecialCharacter'] = '-';
    objLevel2['CompanyId'] = this.value;
    objLevel2['IsActive'] = true;

    const objLevel3 = {};
    objLevel3['NameOfLevels'] = this.nameOfLevel3;
    objLevel3['NumbersOfLocations'] = Number(this.numbersOfLocations3 ? this.numbersOfLocations3 : 0);
    objLevel3['StartingLettersNumbers'] = this.startingLettersNumbers3;
    // objLevel3['SpecialCharacter'] = this.specialCharacter3;
    objLevel3['SpecialCharacter'] = '-';
    objLevel3['CompanyId'] = this.value;
    objLevel3['IsActive'] = true;

    const objLevel4 = {};
    objLevel4['NameOfLevels'] = this.nameOfLevel4;
    objLevel4['NumbersOfLocations'] = Number(this.numbersOfLocations4 ? this.numbersOfLocations4 : 0);
    objLevel4['StartingLettersNumbers'] = this.startingLettersNumbers4;
    // objLevel4['SpecialCharacter'] = this.specialCharacter4;
    objLevel4['SpecialCharacter'] = '-';
    objLevel4['CompanyId'] = this.value;
    objLevel4['IsActive'] = true;

    const obj = {};
    obj['CompanyId'] = this.value;
    obj['LocationTypeId'] = Number(this.selectedLocationValue);
    obj['LocationName'] = this.locationName;
    obj['LocationDescription'] = this.locationDesc;
    obj['IsActive'] = true;
    obj['LocationLevel1'] = objLevel1;
    obj['LocationLevel2'] = objLevel2;
    obj['LocationLevel3'] = objLevel3;
    obj['LocationLevel4'] = objLevel4;



    if (this.id != undefined && this.id != null && this.id != '') {

      objLevel1['ModifiedBy'] = this.userId;
      objLevel1['ModifiedDate'] = new Date();
      objLevel1['CreatedBy'] = this.createdByEdit;
      objLevel1['CreatedDate'] = this.createdDateEdit;
      objLevel1['Id'] = this.level1Id;
      objLevel1['LocationLevelsMasterId'] = this.id;

      objLevel2['ModifiedBy'] = this.userId;
      objLevel2['CreatedBy'] = this.createdByEdit;
      objLevel2['CreatedDate'] = this.createdDateEdit;
      objLevel2['ModifiedDate'] = new Date();
      objLevel2['Id'] = this.level2Id;
      objLevel2['LocationLevelsMasterId'] = this.id;

      objLevel3['ModifiedBy'] = this.userId;
      objLevel3['CreatedBy'] = this.createdByEdit;
      objLevel3['CreatedDate'] = this.createdDateEdit;
      objLevel3['ModifiedDate'] = new Date();
      objLevel3['Id'] = this.level3Id;
      objLevel3['LocationLevelsMasterId'] = this.id;

      objLevel4['ModifiedBy'] = this.userId;
      objLevel4['CreatedBy'] = this.createdByEdit;
      objLevel4['CreatedDate'] = this.createdDateEdit;
      objLevel4['ModifiedDate'] = new Date();
      objLevel4['Id'] = this.level4Id;
      objLevel4['LocationLevelsMasterId'] = this.id;

      obj['ModifiedBy'] = this.userId;
      obj['ModifiedDate'] = new Date();
      obj['CreatedBy'] = this.createdByEdit;
      obj['CreatedDate'] = this.createdDateEdit;
      obj['Id'] = this.id;

      this.sharedService.updateLocationLevelDetails(obj)
        .subscribe(
          data => {
            switch (data.statusCode) {
              case 200:
                this.loader = false;
                this.validation = false;
                this.TCreateUpdate = 1;
                this.SearchControl = false;
                this.ListShow = false;
                this.ListDiv = false;
                this.resetLocationLevel();
                this.getLocationDetails(this.value, '');
                this.modalSuccess(this.constants.RecordUpdateSuccessfully);
                break;
              case 400:
                this.loader = false;
                // this.recordAlreadyExist = true;
                this.modalError(this.constants.SomethingWentWrong);
                break;
              case 208:
                this.loader = false;
                // this.recordAlreadyExist = true;
                this.modalError(data.successMessage);
                break;
              default:
                this.loader = false;
                // this.recordAlreadyExist = true;
                this.modalError(this.constants.SomethingWentWrong);
            }
          },
          error =>{
            this.loader = false;
          }
        );
    }
    else {

      objLevel1['CreatedBy'] = this.userId;
      objLevel1['CreatedDate'] = new Date();

      objLevel2['CreatedBy'] = this.userId;
      objLevel2['CreatedDate'] = new Date();

      objLevel3['CreatedBy'] = this.userId;
      objLevel3['CreatedDate'] = new Date();

      objLevel4['CreatedBy'] = this.userId;
      objLevel4['CreatedDate'] = new Date();

      obj['CreatedBy'] = this.userId;
      obj['CreatedDate'] = new Date();

      this.sharedService.addLocationLevelDetails(obj)
        .subscribe(
          data => {
            switch (data.statusCode) {
              case 200:
                this.loader = false;
                this.validation = false;
                this.TCreateUpdate = 1;
                this.SearchControl = false;
                this.ListShow = false;
                this.ListDiv = false;
                this.resetLocationLevel();
                this.getLocationDetails(this.value, '');
                this.modalSuccess(this.constants.RecordAddedSuccessfully);
                break;
              case 400:
                this.loader = false;
                // this.recordAlreadyExist = true;
                this.modalError(this.constants.SomethingWentWrong);
                break;
              case 208:
                this.loader = false;
                // this.recordAlreadyExist = true;
                this.modalError(data.errorMessage);
                break;
              default:
                this.loader = false;
                // this.recordAlreadyExist = true;
                this.modalError(this.constants.SomethingWentWrong);
            }
          },
          error =>{
            this.loader = false;
          }
        );
    }
  }

  modalSuccess(msg) {
    this.messageModalImage = this.constants.SuccessPng;
    this.messageModalDescription = msg;
    this.modalService.dismissAll();
    this.modalService.open(this.messageModal, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
  }

  modalError(msg) {
    this.messageModalImage = this.constants.ClosePng;
    this.messageModalDescription = msg;
    this.modalService.dismissAll();
    this.modalService.open(this.messageModal, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
  }

  ListDivShowHide() {
    if (this.ListDiv == true) {
      this.ListDiv = false;
      this.ListAngle = this.constants.DownArrow;
    }
    else if (this.ListDiv == false) {
      this.ListDiv = true;
      this.ListAngle = this.constants.UpArrow;
    }
  }

  onSearchValueChange(value: any): void {
    this.searchValue = value;
  }

  AllLocationLevelList() {

    this.SearchType = true;
    if (this.searchValue == 0) {
      this.SearchValidation = true;
      return;
    }
    this.getLocationDetails(this.searchValue, '');
  }

  searchLocationDetailsList() {

    this.SearchType = false;
    if (!this.userSearch || this.searchValue == 0) {
      this.SearchValidation = true;
      return;
    }
    this.getLocationDetails(this.searchValue, this.userSearch == "" ? "" : this.userSearch);
  }

  getLocationDetails(companyId, searchText) {
    $('#datatable').DataTable().destroy();
    this.dtTrigger.next('');

    this.sharedService.getLocationDetails(companyId, (searchText == undefined || searchText == "" || searchText == null) ? searchText = '' : searchText).subscribe(
      data => {
        this.loader = true;
        this.data = data;
        this.locationDetailsList = this.data.dataList;
        this.loader = false;
        this.rerender('one');
        this.ListShow = true;
        this.ListDiv = true;
        this.ListAngle = this.constants.UpArrow;
      });
  }


  getLocationLevelList(companyId, locationTypeId, locationName) {
    $('#datatable1').DataTable().destroy();
    this.dtTrigger1.next('');
    //this.locationLevelsList = [];
    this.sharedService.getLocationLevelList(companyId, locationTypeId, locationName).subscribe(
      data => {
        this.loader = true;
        this.data = data;
        this.locationLevelsList = this.data.dataList;
        this.totalLocations = this.data.dataList.length;
        this.loader = false;
        this.rerender('two');
      });
  }

  rerender(type): void {
    if (type == 'one') {
      this.dtElement.first.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next('');
      });
    }
    else {
      this.dtElement.last.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger1.next('');
      });
    }
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
    this.dtTrigger1.unsubscribe();
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next('');
    this.dtTrigger1.next('');
  }

  resetLocationLevel() {
    this.id = '';
    this.level1Id = '';
    this.level2Id = '';
    this.level3Id = '';
    this.level4Id = '';
    this.numbersOfLocations1 = '';
    this.numbersOfLocations2 = '';
    this.numbersOfLocations3 = '';
    this.numbersOfLocations4 = '';
    this.nameOfLevel1 = '';
    this.nameOfLevel2 = '';
    this.nameOfLevel3 = '';
    this.nameOfLevel4 = '';
    this.specialCharacter1 = '';
    this.specialCharacter2 = '';
    this.specialCharacter3 = '';
    this.specialCharacter4 = '';
    this.startingLettersNumbers1 = '';
    this.startingLettersNumbers2 = '';
    this.startingLettersNumbers3 = '';
    this.startingLettersNumbers4 = '';
    this.selectedLocationValue = '';
    this.locationDesc = '';
    this.locationName = '';
    this.isShowLocationLevelList = false;
    this.totalLocations = 0;
    this.locationLevelsList = [];

    var table = $('#datatable1').DataTable();

    table.clear()
  }

  toggleEdit(item: any) {
    this.ListDiv = false;
    this.isEdit = true;
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.buttonValue = this.constants.Update;
    this.selectedLocationValue = item.locationTypeId;
    this.id = item.id;
    this.level1Id = item.level1Id;
    this.level2Id = item.level2Id;
    this.level3Id = item.level3Id;
    this.level4Id = item.level4Id;
    this.nameOfLevel1 = item.nameOfLevels1;
    this.numbersOfLocations1 = item.numbersOfLocations1 == 0 ? "" : item.numbersOfLocations1;
    this.startingLettersNumbers1 = item.startingLettersNumbers1;
    this.specialCharacter1 = item.specialCharacter1;
    this.nameOfLevel2 = item.nameOfLevels2;
    this.numbersOfLocations2 = item.numbersOfLocations2 == 0 ? "" : item.numbersOfLocations2;
    this.startingLettersNumbers2 = item.startingLettersNumbers2;
    this.specialCharacter2 = item.specialCharacter2;
    this.nameOfLevel3 = item.nameOfLevels3;
    this.numbersOfLocations3 = item.numbersOfLocations3 == 0 ? "" : item.numbersOfLocations3;
    this.startingLettersNumbers3 = item.startingLettersNumbers3;
    this.specialCharacter3 = item.specialCharacter3;
    this.nameOfLevel4 = item.nameOfLevels4;
    this.numbersOfLocations4 = item.numbersOfLocations4 == 0 ? "" : item.numbersOfLocations4;
    this.startingLettersNumbers4 = item.startingLettersNumbers4;
    this.specialCharacter4 = item.specialCharacter4;
    this.locationName = item.locationName;
    this.locationDesc = item.locationLevelDesc;
    this.clearbutton = false;
    this.value = item.companyId;
    this.getLocationLevelList(this.value, this.selectedLocationValue, this.locationName);
    this.createdDateEdit = item.createdDate == null ? '' : item.createdDate == undefined ? '' : item.createdDate;
    this.createdByEdit = item.createdBy == null ? '' : item.createdBy == undefined ? '' : item.createdBy;
  }

  deleteLocationLevelDetails(item: any) {

    this.sharedService.deleteLocationLevelDetails(item).subscribe(
      data => {

        this.data = data;
        this.loader = true;
        switch (data.statusCode) {
          case 200:
            this.loader = false;
            this.getLocationDetails(this.searchValue, '');
            this.rerender('one');
            this.modalSuccess(this.constants.RecordDeletedSuccessfully);
            break;
          case 208:
            this.loader = false;
            this.modalError("Location Type not deleted, It is used in Location Level.");
            break;
          case 400:
            this.loader = false;
            this.modalError(this.constants.SomethingWentWrong);
            break;
          default:
            this.loader = false;
            this.modalError(this.constants.SomethingWentWrong);
        }
      });
  }

  deletePopup(item, content) {
    this.deletedItem = '';
    this.modalService.open(content, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
    this.deleteDetail = this.constants.DeletePopup;
    this.deletedItem = item.id;
  }

  showLocationLevelList() {
    if (this.isShowLocationLevelList) {
      this.isShowLocationLevelList = false;
    }
    else {
      this.isShowLocationLevelList = true;
      if (this.selectedLocationValue > 0 && this.locationName != '') {
        this.getLocationLevelList(this.value, this.selectedLocationValue, this.locationName);
      }
      else {
        this.getLocationLevelList(this.searchValue, 0, '');
      }
    }
  }


  //ankit implemenation
  ErrorModal(title: string, message: string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }
}
