<section class="content-section pt-2">
    <div class="loading" *ngIf="loader">Loading&#8230;</div>
    <div col-sm-12>
        <div class="row">
            <div class="col-6 mb-2 d-flex align-items-baseline text-xs-left">
                <div class="page-title">
                    <div class="bread-crumb">
                        <a [routerLink]="['/adminDashboard/']">Home</a>
                        <span class="mx-1">-</span>
                        <span class="active"><a [routerLink]="['/companyList/']">Company List</a></span>
                        <div *ngIf="permissionMessage!=''" class='btn-danger text-center'>{{permissionMessage}} </div>
                    </div>
                </div>
            </div>
            <div class="col-6 mb-2 text-right align-items-baseline">
                
            </div>
        </div>
    </div>
    
    
    <div class="row">
        <div class="col-md-12">
            <div class="white-box1">
                <div class="customHeading mt-3">
                    Company Details
                </div>
                <div class="card-desc1" *ngIf="rolePermissionsList && rolePermissionsList[0].isInsert">
                    <form [formGroup]="form">
                        <div class="row mt-2">
                            <div class="col-lg-4 col-md-12">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp required">
                                        <label class="customLabel">Company</label> 
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput" [(ngModel)]="companyName"
                                            placeholder="Enter Company Name" name="comapanyName"
                                            formControlName="companyName" autocomplete="off" minlength="3" maxlength="50" [ngClass]="((!form.controls.companyName.valid) && (validation)) ? 'validation_border': ''">
                                        <!-- <div *ngIf="((!form.controls.companyName.valid) && (validation))"
                                            class="text-danger input-error">
                                            Enter Company Name.</div> -->
                                        <div *ngIf="((recordAlreadyExist) && (form.controls.companyName.valid))" class="text-danger input-error">
                                            Record Already Exist.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp required">
                                        <label class="customLabel">Email</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput EmailValidation" [(ngModel)]="contactEmail"
                                            placeholder="Enter Email" name="contactEmail" formControlName="contactEmail"
                                            autocomplete="off" required pattern="[0-9a-zA-Z._%+-]+@[0-9a-zA-Z.-]+\.[a-zA-Z]{2,3}$" [ngClass]="((!form.controls.contactEmail.valid) && (validation)) ? 'validation_border': ''">
                                        <!-- <div *ngIf="((!form.controls.contactEmail.valid) && (validation))"
                                            class="text-danger input-error">
                                            Enter Valid Email.</div> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp required">
                                        <label class="customLabel">Phone</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput PhoneValidation "
                                            (keypress)="validatePhoneNo($event.target)" [(ngModel)]="phoneNumber"
                                            placeholder="Enter Phone Number (xxx) xxx-xxxx" name="phoneNumber"
                                            formControlName="phoneNumber" autocomplete="off" required maxlength="14"
                                            pattern="^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$" [ngClass]="((form.controls.phoneNumber.value=='' || form.controls.phoneNumber.value==undefined) && (validation)) ? 'validation_border': ''">
                                    
                                            <!-- <div *ngIf="((form.controls.phoneNumber.value=='' || form.controls.phoneNumber.value==undefined) && (validation))"
                                            class="text-danger input-error">
                                            Enter Phone Number.eg- (xxx) xxx-xxxx</div> -->
                                        <div *ngIf="((!form.controls.phoneNumber.valid && form.controls.phoneNumber.value!='' && form.controls.phoneNumber.value!=undefined) && (validation))"
                                            class="text-danger input-error">Enter Valid Phone Number.eg- (xxx) xxx-xxxx</div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-lg-4 col-md-12">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp required">
                                        <label class="customLabel">Address Line 1</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput" [(ngModel)]="addresssLine1"
                                            placeholder="Enter Address Line 1" name="addresssLine1"
                                            formControlName="addressLine1" autocomplete="off" required minlength="3"
                                            maxlength="100" required [ngClass]="((!form.controls.addressLine1.valid) && (validation)) ? 'validation_border': ''">
                                        <!-- <div *ngIf="((!form.controls.addressLine1.valid) && (validation))"
                                            class="text-danger input-error">
                                            Enter Address Line 1.</div> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Address Line 2</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput" [(ngModel)]="addresssLine2"
                                            placeholder="Enter Address Line 2" name="addresssLine2"
                                            formControlName="addressLine2" autocomplete="off" maxlength="100">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp required">
                                        <label class="customLabel">Zip</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <div *ngIf="(countrySelected==231)">
                                            <input type="text" class="customInput NumberOnly" [(ngModel)]="zipCode"
                                            required placeholder="Enter Zip xxxxx-xxxx" name="zipCode"
                                            formControlName="zipCode" autocomplete="off" maxlength="10"
                                            pattern="\d{5}-?(\d{4})?"
                                            (keypress)="validateZipcode($event.target,'')" [ngClass]="((form.controls.zipCode.value=='' || form.controls.zipCode.value==undefined) && (validation)) ? 'validation_border': ''">
                                            <!-- <div *ngIf="((form.controls.zipCode.value=='' || form.controls.zipCode.value==undefined) && (validation))"
                                                class="text-danger input-error">
                                                Enter Zip.eg-xxxxx-xxxx</div> -->
                                            <div *ngIf="((!form.controls.zipCode.valid && form.controls.zipCode.value!='' && form.controls.zipCode.value!=undefined) && (validation))"
                                                class="text-danger input-error">Enter Valid Zip.eg-xxxxx-xxxx</div>
                                        </div>
                                        <div *ngIf="(countrySelected!=231)">
                                            <input type="text" class="customInput" [(ngModel)]="zipCode"
                                            placeholder="Enter Zip xxxxx-xxxx" name="zipCode"
                                            formControlName="zipCode" autocomplete="disabled"
                                            maxlength="10"/>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-1">
                            <div class="col-lg-4 col-md-12">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp required">
                                        <label class="customLabel">City</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput" [(ngModel)]="city"
                                            placeholder="Enter City" name="city" formControlName="cityName"
                                            autocomplete="off" required minlength="3" maxlength="50" [ngClass]="((!form.controls.cityName.valid) && (validation)) ? 'validation_border': ''">
                                        <!-- <div *ngIf="((!form.controls.cityName.valid) && (validation))"
                                            class="text-danger input-error">
                                            Enter City.</div> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp required">
                                        <label class="customLabel">Country</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <select class="customInput pl-1px" formControlName='countrySelected'
                                            (change)="changeCountry($event)"
                                            [(ngModel)]="countrySelected">
                                            <option value=''>Select Country</option>
                                            <option *ngFor="let item of countrList" [value]="item.id">
                                                {{item.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp required">
                                        <label class="customLabel">State</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <select class="customInput pl-1px" formControlName="stateName" required
                                            [(ngModel)]="selectedStateValue" (ngModelChange)="changeState($event)" [ngClass]="((selectedStateValue=='') && (validation)) ? 'validation_border': ''">
                                            <option value=''> Select State</option>
                                            <option *ngFor="let item of stateData" [value]="item.id">{{item.name}}
                                            </option>
                                        </select>
                                        <!-- <div *ngIf="((selectedStateValue=='') && (validation))"
                                            class="text-danger input-error">
                                            Select State.</div> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                          <!--Tax Information-->
      <div class="white-box1 no_bg1">
        <div class="customHeading mt-3">
            Tax Information
        </div>
        <div class="card-desc1">

            <div class="row mt-2">

                <div class="col-lg-8 col-md-12">
                    <div class="row">
                        <div class="col-lg-2 col-md-2 trp">
                            <label class="customLabel"></label>
                        </div>
                        <div class="col-lg-10 col-md-12">
                            <input type="radio" [checked]='true' value="taxable" id="taxable" name="taxable"
                                [ngModel]="taxable" formControlName="taxable" (change)="changeTaxable($event)">
                            <label for="taxable" class="customLabel">Taxable</label>
                            <input type="radio" value="nontaxable" id="nontaxable" name="taxable"
                                [ngModel]="taxable" formControlName="taxable" (change)="changeTaxable($event)">
                            <label for="nontaxable" class="customLabel">Non-Taxable</label>

                            <div class="taxids" style="display: inline-flex;">
                                <label class="customLabel labelSec">Tax ID #</label>
                                <input type="text" class="customInput ml-2 textwidth Cost"
                                    style="padding-left:4px;" [(ngModel)]="taxId" name="taxId"
                                    formControlName="taxId" autocomplete="off"
                                    (keypress)="setOnlyNumber($event)" maxlength="50">
                            </div>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <div class="col-lg-2 col-md-12 trp">
                            <label class="customLabel">Sales Tax Certificate</label>
                        </div>
                        <div class="col-lg-4 col-md-10">
                            <div class="cstyle">
                                <input type="file" class=" customInput" #Image accept="
                                application/pdf" (change)="ChngSettingImage($event.target.files)" id="file"
                                    [disabled]="IsNonTaxable==false">
                            </div>

                        </div>
                        <div class="col-lg-4 col-md-2 addimageandpdf mt-4 mt-md-0">
                            <button type="button"
                                class="btn round-btn upload_btn add_img ml-0 mt-1 mt-lg-0 btns"
                                (click)="AddImage();">+ Add image</button>
                            <div class="pdffield" *ngFor="let image of MultipleImage;let i = index;">
                                <div class="particular_img tax">
                                    <img [src]="DefaultLogoPDF" class="pdfimage"
                                        (click)="viewImage(image.imageUrl)">

                                    <button type="button" class="close"
                                        (click)="DeleteImageModalpopup(i,contentImage)" aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    <span class="imgtypes">
                                        {{image.imageName}}
                                    </span>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
    <!--Tax Information-->
                        <div class="submit-from text-right mt-2">

                            <button class="btn blue-bg round-btn" (click)="addNewCompamy()"><i class="fas fa-save"></i>
                                {{buttonValue}} </button>
                            <button class="btn darkred-bg round-btn" *ngIf="clearbutton==true" (click)="resetCompanyData()"><i
                                    class="fas fa-redo"></i> Clear  </button>
                            <button *ngIf="this.companyDetails!= undefined" class="btn blue-bg round-btn "
                                (click)="openCompanyHierarchy()"><i class="fas fa-save"></i> Add Hierarchy
                            </button>
                        </div>
                       
                        <div *ngIf="isAddHierarchy">
                            <div class="whitebox-subtitle mb-2 mt-2 pt-4 bt-1">
                                <div class="row">
                                    <div class="col-md-12">
                                        <div class="customHeading mt-2">
                                            Add Company Hierarchy
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row mt-2">
                                <div class="col-lg-4 col-md-12">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-2 trp required">
                                            <label class="customLabel">Company Level</label>
                                        </div>
                                        <div class="col-lg-8 col-md-10">
                                            <select class="customInput pl-1px" [(ngModel)]="selectedhierarchyValue"
                                                (ngModelChange)="changeHierarchy($event)"
                                                formControlName='selectedhierarchyValue' required autocomplete="off" [ngClass]="((!form.controls.selectedhierarchyValue.valid) && (validation)) ? 'validation_border': ''">
                                                <option value="">Select Company Level</option>
                                                <option *ngFor="let item of hierarchyLevelData;let indx=index"
                                                    [value]="item.id">
                                                    {{item.name + '--Level --' }}{{item.ordering}}

                                                </option>
                                            </select>
                                            <!-- <div *ngIf="((!form.controls.selectedhierarchyValue.valid) && (validation))"
                                                class="text-danger input-error">
                                                Select Company level!.</div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-2 trp required">
                                            <label class="customLabel">Hierarchy Name</label>
                                        </div>
                                        <div class="col-lg-8 col-md-10">
                                            <input type="text" class="customInput" [(ngModel)]="hierarchyName"
                                                placeholder="Enter Name" name="hierarchyName"
                                                formControlName='hierarchyName' required autocomplete="off" minlength="3"
                                                maxlength="50" [ngClass]="((!form.controls.hierarchyName.valid) && (validation)) ? 'validation_border': ''">
                                            <!-- <div *ngIf="((!form.controls.hierarchyName.valid) && (validation))"
                                                class="text-danger input-error">
                                                Enter Hierarchy Name.</div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-2 trp required">
                                            <label class="customLabel">Email</label>
                                        </div>
                                        <div class="col-lg-8 col-md-10">
                                            <input type="text" class="customInput EmailValidation"
                                                [(ngModel)]="hierarchyEmail" placeholder="Enter Email"
                                                name="hierarchyEmail" formControlName='hierarchyEmail' required
                                                autocomplete="off" pattern="[0-9a-zA-Z._%+-]+@[0-9a-zA-Z.-]+\.[a-zA-Z]{2,3}$"
                                                maxlength="60" [ngClass]="((!form.controls.hierarchyEmail.valid) && (validation)) ? 'validation_border': ''">
                                            <!-- <div *ngIf="((!form.controls.hierarchyEmail.valid) && (validation))"
                                                class="text-danger input-error">
                                                Please Enter Valid Email.</div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-1">
                                <div class="col-lg-4 col-md-12">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-2 trp required">
                                            <label class="customLabel">Phone</label>
                                        </div>
                                        <div class="col-lg-8 col-md-10">
                                            <input type="text" class="customInput PhoneValidation"
                                                (keypress)="validatePhoneNo($event.target)"
                                                [(ngModel)]="hierarchyPhoneNumber"
                                                placeholder="Enter Phone Number (xxx) xxx-xxxx" name="hierarchyPhoneNumber"
                                                formControlName='hierarchyPhoneNumber' required autocomplete="off"
                                                maxlength="14" pattern="^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$" [ngClass]="((form.controls.hierarchyPhoneNumber.value=='' || form.controls.hierarchyPhoneNumber.value ==undefined) && (validation)) ? 'validation_border': ''">
                                    
                                            <!-- <div *ngIf="((form.controls.hierarchyPhoneNumber.value=='' || form.controls.hierarchyPhoneNumber.value ==undefined) && (validation))"
                                                class="text-danger input-error">
                                                Enter Phone Number.eg-(xxx) xxx-xxxx</div> -->
                                            <div *ngIf="((!form.controls.hierarchyPhoneNumber.valid && form.controls.hierarchyPhoneNumber.value!='' && form.controls.hierarchyPhoneNumber.value!=undefined) && (validation))"
                                                class="text-danger input-error">Enter valid Phone Number.eg-(xxx) xxx-xxxx
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-2 trp required">
                                            <label class="customLabel">Address Line 1</label>
                                        </div>
                                        <div class="col-lg-8 col-md-10">
                                            <input type="text" class="customInput" [(ngModel)]="hierarchyAddresssLine1"
                                                placeholder="Enter Address Line 1" name="hierarchyaddresssLine1"
                                                formControlName='hierarchyAddresssLine1' required autocomplete="off"
                                                maxlength="100" minlength="3" [ngClass]="((!form.controls.hierarchyAddresssLine1.valid) && (validation)) ? 'validation_border': ''">
                                            <!-- <div *ngIf="((!form.controls.hierarchyAddresssLine1.valid) && (validation))"
                                                class="text-danger input-error">
                                                Enter Addresss Line1.</div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-2 trp">
                                            <label class="customLabel">Address Line 2</label>
                                        </div>
                                        <div class="col-lg-8 col-md-10">
                                            <input type="text" class="customInput" [(ngModel)]="hierarchyAddresssLine2"
                                                placeholder="Enter Address Line 2" name="hierarchyaddresssLine2"
                                                formControlName='hierarchyAddresssLine2' required autocomplete="off"
                                                maxlength="100">
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-1">
                                <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-2 trp required">
                                            <label class="customLabel">Zip</label>
                                        </div>
                                        <div class="col-lg-8 col-md-10">
                                            <input type="text" class="customInput NumberOnly"
                                                [(ngModel)]="hierarchyZipCode" placeholder="Enter Zip xxxxx-xxxx"
                                                name="hierarchyzipCode" formControlName='hierarchyZipCode' required
                                                autocomplete="off" maxlength="10" pattern="\d{5}-?(\d{4})?"
                                                (keypress)="validateZipcode($event.target,'hierarchy')" [ngClass]="((form.controls.hierarchyZipCode.value=='' || form.controls.hierarchyZipCode.value ==undefined) && (validation)) ? 'validation_border': ''">
                                            <!-- <div *ngIf="((form.controls.hierarchyZipCode.value=='' || form.controls.hierarchyZipCode.value ==undefined) && (validation))"
                                                class="text-danger input-error">
                                                Enter Zip.eg-xxxxx-xxxx</div> -->
                                            <div *ngIf="((!form.controls.hierarchyZipCode.valid && form.controls.hierarchyZipCode.value!='' && form.controls.hierarchyZipCode.value!=undefined) && (validation))"
                                                class="text-danger input-error">Enter Valid Zip.eg-xxxxx-xxxx</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-2 trp required">
                                            <label class="customLabel">City</label>
                                        </div>
                                        <div class="col-lg-8 col-md-10">
                                            <input type="text" class="customInput" [(ngModel)]="hierarchyCity"
                                                required placeholder="Enter City" name="hierarchycity"
                                                formControlName='hierarchyCity' autocomplete="off" maxlength="50"
                                                minlength="3" [ngClass]="((!form.controls.hierarchyCity.valid) && (validation)) ? 'validation_border': ''">
                                            <!-- <div *ngIf="((!form.controls.hierarchyCity.valid) && (validation))"
                                                class="text-danger input-error">
                                                Enter City.</div> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-2 trp required">
                                            <label class="customLabel">Country</label>
                                        </div>
                                        <div class="col-lg-8 col-md-10">
                                            <select class="customInput pl-1px" formControlName='countrySelected'
                                                (change)="changeCountry($event)"
                                                [(ngModel)]="countrySelected">
                                                <option value=''>Select Country</option>
                                                <option *ngFor="let item of countrList" [value]="item.id">
                                                    {{item.name}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-1">
                                <div class="col-lg-4 col-md-12">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-2 trp required">
                                            <label class="customLabel">State</label>
                                        </div>
                                        <div class="col-lg-8 col-md-10">
                                            <select class="customInput pl-1px" [(ngModel)]="selectedHierarchyStateValue"
                                                (ngModelChange)="changeHierarchyState($event)"
                                                formControlName='hierarchyState' required autocomplete="off" [ngClass]="((!form.controls.hierarchyState.valid) && (validation)) ? 'validation_border': ''">
                                                <option value=''> Select State</option>
                                                <option *ngFor="let item of stateData" [value]="item.id">
                                                    {{item.name}}
                                                </option>
                                            </select>
                                            <!-- <div *ngIf="((!form.controls.hierarchyState.valid) && (validation))"
                                                class="text-danger input-error">
                                                Select State.</div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                            <div class="row mt-1">
                                <div class="col-md-12 text-right mt-2 mb-2">
                                    <button type="button" class="btn blue-bg round-btn"
                                        (click)="addNewCompanyHierarchy()"><i class="fas fa-save"></i> Save
                                        Hierarchy </button>
                                </div>
                            </div>
                        </div>
                        <div class="herirchylavel1"
                            *ngFor="let hierarchy of companyHierarchyDetails; let listIndex = index">
                            <div class="grey-sec-title">
                                <div class="row align-items-center">
                                    <div class="col-sm-6">
                                        <h5 id="openpartCompany-{{hierarchy.id}}" class="dropdowncompanyher">
                                            <i class="fas fa-plus-circle green-text openpart"  
                                            (click)="OpenpartCompany(hierarchy.id)" id="openpart"   >
                                            </i>
                                            {{hierarchy.companyName}}
                                        </h5>
                                    </div>
                                    <div class="col-sm-6 text-right">
                                        <button class="no-btn green-text" data-toggle="modal"
                                            *ngIf="rolePermissionsList && rolePermissionsList[0].isInsert"
                                            data-target="#addhierarchy" (click)="callHierarchy(hierarchy)">Add</button>
                                        <button class="no-btn blue-text" data-toggle="modal" data-target="#addhierarchy"
                                            *ngIf="rolePermissionsList && rolePermissionsList[0].isView"
                                            (click)="viewHierarchy(hierarchy)">
                                            <i class="fas fa-eye" aria-hidden="true"></i>
                                        </button>
                                        <button class="no-btn green-text" data-toggle="modal"
                                            *ngIf="rolePermissionsList && rolePermissionsList[0].isUpdate"
                                            data-target="#addhierarchy" (click)="openEditHierarchy(hierarchy)">
                                            <i class="fas fa-edit" aria-hidden="true"></i>
                                        </button>
                                        <button class="no-btn"
                                            *ngIf="rolePermissionsList && rolePermissionsList[0].isDelete"
                                            (click)="deletePopup(hierarchy,content,'parent')">
                                            <i class="fas fa-trash-alt red-text"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <div *ngFor="let item of hierarchyListOfCompany; let listIndex1 = index">
                                <div class="grey-sec-desc" *ngIf="hierarchy.id == item.parentId" >
                                    
                                    <div class=" openpartCompany-{{hierarchy.id}}-herirchylavel2 pl-4"  style="display: none;" >

                                        <div class="grey-sec-title">
                                            <div class="row align-items-center">
                                                <div class="col-sm-6">
                                                   
                                                        <h5 id="openpart-{{item.id}}">
                                                            <i class="fas fa-minus-circle green-text" aria-hidden="true"
                                                            (click)="Openpart(item.id)">
                                                            </i>
                                                          
                                                            {{item.companyName}}
                                                        </h5>
                                                </div>
                                                <div class="col-sm-6 text-right">
                                                    <button class="no-btn green-text" data-toggle="modal"
                                                        *ngIf="rolePermissionsList && rolePermissionsList[0].isInsert"
                                                        data-target="#addhierarchy"
                                                        [disabled]="item.companyHierarchyId == '4'"
                                                        (click)="callHierarchy(item)">Add</button>
                                                    <button class="no-btn blue-text" data-toggle="modal"
                                                        *ngIf="rolePermissionsList && rolePermissionsList[0].isView"
                                                        data-target="#addhierarchy" (click)="viewHierarchy(item)">
                                                        <i class="fas fa-eye" aria-hidden="true"></i>
                                                    </button>
                                                    <button class="no-btn green-text" data-toggle="modal"
                                                        data-target="#addhierarchy"
                                                        *ngIf="rolePermissionsList && rolePermissionsList[0].isUpdate"
                                                        (click)="openEditHierarchy(item)">
                                                        <i class="fas fa-edit" aria-hidden="true"></i>
                                                    </button>
                                                    <button class="no-btn"
                                                        *ngIf="rolePermissionsList && rolePermissionsList[0].isDelete"
                                                        (click)="deletePopup(item,content,'child',hierarchy)">
                                                        <i class="fas fa-trash-alt red-text"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                        <div *ngFor="let items of hierarchyListOfCompany">
                                            <div class="herirchylavel3 pl-4" *ngIf="item.id == items.parentId">
                                            <div class=" openpart-{{item.id}}-herirchylavel3 pl-4" >
                                                
                                                <div class="grey-sec-title">
                                                    <div class="row align-items-center">
                                                        <div class="col-sm-6">
                                                            <h5><i class="fas fa-plus-circle green-text"
                                                                    aria-hidden="true"></i> {{items.companyName}}</h5>
                                                        </div>
                                                        <div class="col-sm-6 text-right">
                                                            <button class="no-btn green-text" data-toggle="modal"
                                                                *ngIf="rolePermissionsList && rolePermissionsList[0].isInsert"
                                                                data-target="#addhierarchy"
                                                                [disabled]="items.companyHierarchyId == '4'"
                                                                (click)="callHierarchy(items)">Add</button>
                                                            <button class="no-btn blue-text" data-toggle="modal"
                                                                *ngIf="rolePermissionsList && rolePermissionsList[0].isView"
                                                                data-target="#addhierarchy"
                                                                (click)="viewHierarchy(items)">
                                                                <i class="fas fa-eye" aria-hidden="true"></i>
                                                            </button>
                                                            <button class="no-btn green-text" data-toggle="modal"
                                                                data-target="#addhierarchy"
                                                                *ngIf="rolePermissionsList && rolePermissionsList[0].isUpdate"
                                                                (click)="openEditHierarchy(items)">
                                                                <i class="fas fa-edit" aria-hidden="true"></i>
                                                            </button>

                                                            <button class="no-btn"
                                                                *ngIf="rolePermissionsList && rolePermissionsList[0].isDelete"
                                                                (click)="deletePopup(items,content,'child',hierarchy)">
                                                                <i class="fas fa-trash-alt red-text"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                                <div *ngFor="let itemss of hierarchyListOfCompany">
                                                    <div class="herirchylavel3 pl-4"
                                                        *ngIf="items.id == itemss.parentId">
                                                        <div class="grey-sec-title">
                                                            <div class="row align-items-center">
                                                                <div class="col-sm-6">
                                                                    <h5><i class="fas fa-plus-circle green-text"
                                                                            aria-hidden="true" ></i>
                                                                        {{itemss.companyName}}</h5>
                                                                </div>
                                                                <div class="col-sm-6 text-right">
                                                                    <button class="no-btn green-text"
                                                                        *ngIf="rolePermissionsList && rolePermissionsList[0].isInsert"
                                                                        [disabled]="itemss.companyHierarchyId == '4'"
                                                                        data-toggle="modal" data-target="#addhierarchy"
                                                                        (click)="callHierarchy(itemss)">Add</button>

                                                                    <button class="no-btn blue-text" data-toggle="modal"
                                                                        *ngIf="rolePermissionsList && rolePermissionsList[0].isView"
                                                                        data-target="#addhierarchy"
                                                                        (click)="viewHierarchy(itemss)">
                                                                        <i class="fas fa-eye" aria-hidden="true"></i>
                                                                    </button>
                                                                    <button class="no-btn green-text"
                                                                        *ngIf="rolePermissionsList && rolePermissionsList[0].isUpdate"
                                                                        data-toggle="modal" data-target="#addhierarchy"
                                                                        (click)="openEditHierarchy(itemss)">
                                                                        <i class="fas fa-edit" aria-hidden="true"></i>
                                                                    </button>
                                                                    <button class="no-btn"
                                                                        *ngIf="rolePermissionsList && rolePermissionsList[0].isDelete"
                                                                        (click)="deletePopup(itemss,content,'child',hierarchy)">
                                                                        <i class="fas fa-trash-alt red-text"></i>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

</section>




<div class="modal fade" id="addhierarchy" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
    aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content border-none">
            <div class="modal-header info-popup-header p-2">
                <h5 class="modal-title info-popup-title" id="exampleModalLabel">{{buttonStatus}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" #closeBtn
                    (click)="closeModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <form [formGroup]="form">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-4 required">
                                    <label class="customLabel">Company level</label>
                                </div>
                                <div class="col-md-8">
                                    <select class="customInput newsmallddl pl-1px" [(ngModel)]="selectedhierarchyValue"
                                        (ngModelChange)="changeHierarchy($event)" formControlName='selectedhierarchyValue'
                                        required autocomplete="off" [ngClass]="((!form.controls.selectedhierarchyValue.valid) && (validation)) ? 'validation_border': ''">
                                        <option value="">Select Company Level</option>
                                        <option *ngFor="let item of hierarchyLevelDataList; let indx = index "
                                            [value]="item.id">
                                        
                                            {{item.name + '--Level --' }}{{item.ordering}}
                                        </option>
                                    </select>
                                    <!-- <div *ngIf="((!form.controls.selectedhierarchyValue.valid) && (validation))"
                                        class="text-danger input-error">
                                        Select Company Level.</div> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-4 required">
                                    <label class="customLabel">Hierarchy Name</label>
                                </div>
                                <div class="col-md-8">
                                    <input type="text" class="customInput" [(ngModel)]="hierarchyName" autocomplete="off"
                                        placeholder="Enter Name" name="hierarchyName" formControlName='hierarchyName'
                                        required maxlength="50" minlength="3" [ngClass]="((!form.controls.hierarchyName.valid) && (validation)) ? 'validation_border': ''">
                                    <!-- <div *ngIf="((!form.controls.hierarchyName.valid) && (validation))"
                                        class="text-danger input-error">
                                        Enter Hierarchy Name.</div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-4 required">
                                    <label class="customLabel">Email</label>
                                </div>
                                <div class="col-md-8">
                                    <input type="text" class="customInput EmailValidation"
                                        pattern="[0-9a-zA-Z._%+-]+@[0-9a-zA-Z.-]+\.[a-zA-Z]{2,3}$" [(ngModel)]="hierarchyEmail"
                                        placeholder="Enter Email" name="hierarchyEmail" formControlName='hierarchyEmail'
                                        required autocomplete="off" maxlength="60" [ngClass]="((!form.controls.hierarchyEmail.valid) && (validation)) ? 'validation_border': ''">
                                    <!-- <div *ngIf="((!form.controls.hierarchyEmail.valid) && (validation))"
                                        class="text-danger input-error">
                                        Enter Valid Email.</div> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-4 required">
                                    <label class="customLabel">Phone</label>
                                </div>
                                <div class="col-md-8">
                                    <input type="text" class="customInput PhoneValidation"
                                        (keypress)="validatePhoneNo($event.target)" [(ngModel)]="hierarchyPhoneNumber"
                                        placeholder="Enter Phone Number (xxx) xxx-xxxx" name="hierarchyPhoneNumber"
                                        formControlName='hierarchyPhoneNumber' required autocomplete="off" maxlength="14"
                                        pattern="^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$" [ngClass]="((form.controls.hierarchyPhoneNumber.value=='' || form.controls.hierarchyPhoneNumber.value ==undefined) && (validation)) ? 'validation_border': ''">
                                
                                    <!-- <div *ngIf="((form.controls.hierarchyPhoneNumber.value=='' || form.controls.hierarchyPhoneNumber.value ==undefined) && (validation))"
                                        class="text-danger input-error">
                                        Enter Phone Number.eg-(xxx) xxx-xxxx</div> -->
                                    <div *ngIf="((!form.controls.hierarchyPhoneNumber.valid && form.controls.hierarchyPhoneNumber.value!='' && form.controls.hierarchyPhoneNumber.value!=undefined) && (validation))"
                                        class="text-danger input-error">Enter valid Phone Number.eg-(xxx) xxx-xxxx</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-4 required">
                                    <label class="customLabel">Address Line 1</label>
                                </div>
                                <div class="col-md-8">
                                    <input type="text" class="customInput" [(ngModel)]="hierarchyAddresssLine1"
                                        placeholder="Enter Address Line 1" name="hierarchyaddresssLine1"
                                        formControlName='hierarchyAddresssLine1' required autocomplete="off" maxlength="100"
                                        minlength="3" [ngClass]="((!form.controls.hierarchyAddresssLine1.valid) && (validation)) ? 'validation_border': ''">
                                    <!-- <div *ngIf="((!form.controls.hierarchyAddresssLine1.valid) && (validation))"
                                        class="text-danger input-error">
                                        Enter Addresss Line 1.</div> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-4 required">
                                    <label class="customLabel">Address Line 2</label>
                                </div>
                                <div class="col-md-8">
                                    <input type="text" class="customInput" [(ngModel)]="hierarchyAddresssLine2"
                                        placeholder="Enter Address Line 2" name="hierarchyaddresssLine2"
                                        formControlName='hierarchyAddresssLine2' required autocomplete="off"
                                        maxlength="100">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-4 required">
                                    <label class="customLabel">Zip</label>
                                </div>
                                <div class="col-md-8">
                                    <input type="text" class="customInput NumberOnly"
                                            [(ngModel)]="hierarchyZipCode" placeholder="Enter Zip xxxxx-xxxx"
                                            name="hierarchyzipCode" formControlName='hierarchyZipCode' required
                                            autocomplete="off" maxlength="10" pattern="\d{5}-?(\d{4})?"
                                            (keypress)="validateZipcode($event.target,'hierarchy')" [ngClass]="((form.controls.hierarchyZipCode.value=='' || form.controls.hierarchyZipCode.value ==undefined) && (validation)) ? 'validation_border': ''">
                                        <!-- <div *ngIf="((form.controls.hierarchyZipCode.value=='' || form.controls.hierarchyZipCode.value ==undefined) && (validation))"
                                            class="text-danger input-error">
                                            Enter Zip.eg-xxxxx-xxxx</div> -->
                                        <div *ngIf="((!form.controls.hierarchyZipCode.valid && form.controls.hierarchyZipCode.value!='' && form.controls.hierarchyZipCode.value!=undefined) && (validation))"
                                            class="text-danger input-error">Enter Valid Zip.eg-xxxxx-xxxx</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-4 required">
                                    <label class="customLabel">City</label>
                                </div>
                                <div class="col-md-8">
                                    <input type="text" class="customInput" [(ngModel)]="hierarchyCity" required
                                            placeholder="Enter City" name="hierarchycity"
                                            formControlName='hierarchyCity' autocomplete="off" maxlength="50"
                                            minlength="3" [ngClass]="((!form.controls.hierarchyCity.valid) && (validation)) ? 'validation_border': ''">
                                        <!-- <div *ngIf="((!form.controls.hierarchyCity.valid) && (validation))"
                                            class="text-danger input-error">
                                            Enter City.</div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-4 required">
                                    <label class="customLabel">Country</label>
                                </div>
                                <div class="col-md-8">
                                    <select class="customInput newsmallddl pl-1px" formControlName='countrySelected'
                                        (change)="changeCountry($event)"
                                        [(ngModel)]="countrySelected">
                                        <option value=''>Select Country</option>
                                        <option *ngFor="let item of countrList" [value]="item.id">
                                            {{item.name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-4 required">
                                    <label class="customLabel">State</label>
                                </div>
                                <div class="col-md-8">
                                    <select class="customInput newsmallddl pl-1px" [(ngModel)]="selectedHierarchyStateValue"
                                        (ngModelChange)="changeHierarchyState($event)"
                                        formControlName='hierarchyState' required autocomplete="off" [ngClass]="((!form.controls.hierarchyState.valid) && (validation)) ? 'validation_border': ''">
                                        <option value=''> Select State</option>
                                        <option *ngFor="let item of stateData" [value]="item.id">
                                            {{item.name}}
                                        </option>
                                    </select>
                                    <!-- <div *ngIf="((!form.controls.hierarchyState.valid) && (validation))"
                                        class="text-danger input-error">
                                        Select State.</div> -->
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="submit-from text-right mt-2">
                       
                        <button class="btn blue-bg round-btn" *ngIf="isShownButton"
                            (click)="addNewCompanyHierarchy()"><i class="fas fa-save" aria-hidden="true"></i>
                            {{buttonValue}}
                        </button>
                        <button class="btn darkred-bg round-btn" (click)="resetCompanyData()"><i class="fas fa-redo"
                                aria-hidden="true"></i> Clear
                        </button>
                    </div>
                </form>

            </div>
        </div>
    </div>
</div>

<ng-template #content let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg alert_heading sweet-heading"></h4>
    <button class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body">
        <div class="thank-you-pop">
            <img src="./assets/image/popup-alert.png" alt="">
            <h1>Message</h1>
            <p class="mt-1">{{deleteDetail}}</p>
        </div>
    </div>
    <div class="modal-footer sweet-footer text-center p-1">
        <button type="button" class="btn round-btn green-bg m-1" *ngIf="(isDeletedChild)"
            (click)="deleteCompanyHierarchy(deletedItem,deleteType); c('Close click')">Yes</button>
        <button type="button" class="btn round-btn red-bg m-1" (click)="c('Close click')">No</button>
    </div>
</ng-template>
<div class="modal" id="add_success">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="addsuccess()" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Added Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="addsuccess()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="add_update">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="updateitem()" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Update Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="updateitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="delete_success">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="deleteitem()" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Deleted Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="deleteitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="wrong_msg">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="msgitem()" data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>Something went wrong</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="msgitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<ng-template #contentImage let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg sweet-heading"></h4>
    <button type="button" class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body">
        <div class="thank-you-pop">
            <img src="./assets/image/popup-alert.png" alt="">
            <h1>Confirmation</h1>
            <p class="mb-0">{{deleteDetail}}</p>
        </div>
    </div>
    <div class="modal-footer sweet-footer text-center">
        <button type="button" class="btn green-bg round-btn m-0 mr-1"
            (click)="DeleteImage(); c('Close click')">Yes</button>
        <button type="button" class="btn red-bg round-btn m-0" (click)="c('Close click')">No</button>
    </div>
</ng-template>