export class Shippingmulti {
    rate_options: RateOptions
    shipment: Shipment
}
export class Dimensions {
    unit: String
    length: Number
    width: Number
    height: Number
}

export class Weight {
    value: Number
    unit: String
}

export class Shipment {
    validate_address: String
    ship_date: String
    ship_to: ShipTo
    ship_from: ShipFrom
    confirmation: String
    customs: Customs
    packages: Package[]
}

export class Customs {
    non_delivery: String
    contents: String
    customs_items: CustomsItem[]
}

export class CustomsItem{
    description: String
    quantity: Number
    value: Value
    country_of_origin: String
    harmonized_tariff_code: String
}

export class Value{
    amount: Number
    currency: String
}

export class ShipTo {
    name: String
    phone: String
    company_name: String
    address_line1: String
    city_locality: String
    state_province: String
    postal_code: String
    country_code: String
    address_residential_indicator: String
}
export class ShipFrom {
    name: String
    phone: String
    company_name: String
    address_line1: String
    city_locality: String
    state_province: String
    postal_code: String
    country_code: String
    address_residential_indicator: String
}
export class Package {
    package_code: String
    weight: Weight
    dimensions: Dimensions
}

export class RateOptions {
    carrier_ids: String[]
    preferred_currency: string
}

// export class Shippingmulti {
//     rate_options: RateOptions
//     shipment: Shipment
// }
// export class Weight {
//     value: Number
//     unit: String
// }

// export class Shipment {
//     validate_address: string
//     ship_to: ShipTo
//     ship_from: ShipFrom
//     packages: Package[]
// }

// export class ShipTo {
//     name: String
//     phone: String
//     company_name: String
//     address_line1: String
//     city_locality: String
//     state_province: String
//     postal_code: String
//     country_code: String
//     address_residential_indicator: String
// }
// export class ShipFrom {
//     name: String
//     phone: String
//     company_name: String
//     address_line1: String
//     city_locality: String
//     state_province: String
//     postal_code: String
//     country_code: String
//     address_residential_indicator: String
// }
// export class Package {
//     package_code: String
//     weight: Weight
// }

// export class RateOptions {
//     carrier_ids: String[]
// }
