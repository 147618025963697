<section class="content-section pt-2">
    <div class="loading" *ngIf="loader">Loading&#8230;</div>

    <div col-sm-12>
        <div class="row">
            <div class="col-6 mb-2 d-flex align-items-baseline text-xs-left">
                <div class="page-title">
                    <div class="bread-crumb">
                        <a [routerLink]="['/adminDashboard/']">Home</a>
                        <span class="mx-1">-</span>
                        <span class="active">Role Management</span>
                        <div *ngIf="permissionMessage!=''" class='btn-danger text-center'>{{permissionMessage}} </div>
                    </div>
                </div>
            </div>
            <div class="col-6 mb-2 text-right align-items-baseline">

            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">

            <div class="white-box1">
                <div class="card-desc1">
                    <form [formGroup]="formSearch">
                        <div class="row detailSection">
                            <div class="col-md-12 container">
                                <div class="row mt-3">
                                    <div class="col-lg-2 col-md-6">
                                        <div class="d-flex d-flex align-items-center">
                                            <label class="customLabel mr-1" for="Type">Type</label>
                                            <select class="customInput pl-1px" (change)="ChangeTCreateUpdate($event)"
                                                [(ngModel)]="TCreateUpdate" id="TCreateUpdate"
                                                formControlName="TCreateUpdate">
                                                <option value="1">Create</option>
                                                <option value="0">Update</option>
                                            </select>
                                        </div>
                                    </div>
                                    <!-- <div class="col-lg-3 col-md-6 mt-md-0 mt-1"
                                        [style.display]="SearchControl==true ? 'block' : 'none'">
                                        <div class="d-flex align-items-center required border-none"
                                            [ngClass]="((value_search==0) && (SearchValidation)) ? 'validation_border': ''">
                                            <label class="customLabel mr-1" for="Type">Company</label>
                                            <ngx-dropdown-treeview-select [config]="config_search"
                                                class="dropdown-company customInput-dropdown"
                                                [items]="userGroupObjects_search" [(value)]="value_search"
                                                (valueChange)="onValueChange_search($event)">
                                            </ngx-dropdown-treeview-select>
                                        </div>
                                    </div> -->
                                    <div class="col-lg-5 col-md-12 mt-lg-0 mt-1 mb-1"
                                        [style.display]="SearchControl==true ? 'block' : 'none'">

                                        <div class="d-flex align-items-center">
                                            <label class="customLabel mr-1 ws-nobreak" for="Vendor Name">Role
                                                Name</label>
                                            <input type="text" placeholder="Search Role Name" Class="customInput"
                                                [(ngModel)]="vendorSearch" formControlName="vendorSearch"
                                                autocomplete="off" required
                                                [ngClass]="((!vendorSearch && SearchType==false) && (SearchValidation)) ? 'validation_border': ''" (keyup.enter)="searchVendor()">
                                            <!-- <div *ngIf="((!vendorSearch && SearchType==false) && (SearchValidation))"
                                                class="text-danger input-error err-fontSize header_error"
                                                style="">
                                                Enter Role Name.</div> -->
                                            <i class="fas fa-search cpointer ml-3 mr-4 cpointer"
                                                (click)="searchVendor()"></i>
                                            <button type="button" class="btn blue-bg round-btn m-0" (click)="AllVendor()">All
                                                Role</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div class="white-box1" [style.display]="ListShow==true ? 'block' : 'none'">
                <div class="white-box-title1">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="customHeading mt-3">
                                Role List
                                <span id="expand-collapse">
                                    <i class="fa fa-angle-down cpointer" aria-hidden="true"
                                        (click)="ListDivShowHide()"></i>
                                </span>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="card-desc1 mt-2" [style.display]="ListDiv==true ? 'block' : 'none'">
                    <table class="row-border hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                        <thead>
                            <tr>
                                <th>Company</th>
                                <th>User Role</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of userRoleList1">
                                <td>{{data.companyName}}</td>
                                <td>{{data.name}}</td>
                                <td>
                                    <a *ngIf="rolePermissionsList && rolePermissionsList[0].isUpdate" class="green-text"
                                        (click)="toggleEdit(data)"><i class="fas fa-edit"></i></a>
                                    <a *ngIf="rolePermissionsList && rolePermissionsList[0]?.isDelete"
                                        class="red-text"><i class="fas fa-trash-alt"
                                            (click)="deletePopup(data,content)"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>




            </div>
            <div class="white-box1">
                <div class="customHeading mt-3">
                    Add Role
                </div>
                <div class="card-desc1">
                    <form [formGroup]="form">
                        <div class="row mt-2">
                            <!-- <div class="col-lg-3 col-md-12">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp required">
                                        <label class="customLabel">Company</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10 borderNone"
                                        [ngClass]="((value==0) && (validation)) ? 'validation_border': ''">
                                        <ngx-dropdown-treeview-select class="dropdown-company customInput-dropdown"
                                            [config]="config" required [items]="userGroupObjects" [(value)]="value"
                                            (valueChange)="onValueChange($event)">
                                        </ngx-dropdown-treeview-select>
                                    </div>
                                </div>
                            </div> -->
                            <div class="col-lg-3 col-md-12 mt-lg-0 mt-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp required">
                                        <label class="customLabel">Role Name</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput" [(ngModel)]="roles"
                                            placeholder="Enter Role" name="roles" formControlName="roles"
                                            autocomplete="off" required maxlength="30"
                                            [ngClass]="((!form.controls.roles.valid) && (validation)) ? 'validation_border': ''">
                                        <!-- <div *ngIf="((!form.controls.roles.valid) && (validation))"
                                            class="text-danger input-error">
                                            Enter Role!.</div> -->
                                        <div *ngIf="((recordAlreadyExist) && (validation))"
                                            class="text-danger input-error">
                                            Record Already Exist.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-12">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Template</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10 borderNone"
                                        [ngClass]="((value==0) && (validation)) ? 'validation_border': ''">
                                        <select class="customInput pl-1px" [(ngModel)]="Template"
                                            autocomplete="none|false|no" formControlName='Template'>
                                            <option value=''>Select Template</option>
                                            <option *ngFor="let item of AllTemplate" [value]="item.id">{{item.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-12 mt-lg-0 mt-1">
                                <div class="row">
                                    <div class="col-lg-5 col-md-10">
                                        <label class="customLabel"><input class="p_relative custom_checkbox"
                                                type="checkbox" formControlName='chkIsSkill' [(ngModel)]='chkIsSkill'
                                                (click)="chngIsSkill($event)" [checked]='chkIsSkill' />&nbsp;Is Skill
                                            Required</label>
                                    </div>
                                    <div class="col-lg-5 col-md-2 trp">
                                        <label class="customLabel"><input class="p_relative custom_checkbox"
                                                type="checkbox" formControlName='chkIsProduction'
                                                [(ngModel)]='chkIsProduction' (click)="chngIsProduction($event)"
                                                [checked]='chkIsProduction' />&nbsp;&nbsp;&nbsp;Production Role</label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="submit-from text-right mt-1 p-0">
                            <button type="button" class="btn blue-bg round-btn"
                                *ngIf="rolePermissionsList && rolePermissionsList[0].isInsert"
                                (click)="addNewRoles()"><i class="fas fa-save"></i> {{buttonValue}}
                            </button>
                            <button type="button" class="btn darkred-bg round-btn" (click)="resetRole()" *ngIf="clearbutton==true"><i
                                    class="fas fa-redo"></i> Clear </button>
                            <button type="button" class="btn darkred-bg round-btn" (click)="RoleBack()" *ngIf="clearbutton==false"><i
                                    class="fas fa" aria-hidden="true"></i>
                                Exit </button>
                        </div>

                    </form>
                </div>
            </div>

        </div>
    </div>

</section>
<ng-template #content let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg sweet-heading"></h4>
    <button type="button" class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body">
        <div class="thank-you-pop">
            <img src="./assets/image/popup-alert.png" alt="">
            <h1>Message</h1>
            <p class="mt-1">{{deleteDetail}}</p>
        </div>
    </div>
    <div class="modal-footer sweet-footer text-center" *ngIf="!isRolePopUp">
        <button type="button" class="btn roundBtn green-bg"
            (click)="deleteUserRoles(deletedItem); c('Close click')">Yes</button>
        <button type="button" class="btn roundBtn red-bg" (click)="c('Close click')">No</button>
    </div>
    <div class="modal-footer sweet-footer text-center" *ngIf="isRolePopUp">
        <button type="button" class="btn roundBtn green-bg" (click)="c('Close click')">Ok</button>
    </div>
</ng-template>

<div class="modal" id="add_success">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="addsuccess()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Added Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="addsuccess()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="add_update">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="updateitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Update Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="updateitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="delete_success">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="deleteitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Deleted Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="deleteitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="wrong_msg">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="msgitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>Something went wrong</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="msgitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>