import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs/internal/Subject';
import { FormControl, FormGroup, Validators } from '@angular/forms';
// import { DownlineTreeviewItem, DropdownTreeviewComponent, TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstantsService } from 'src/app/constants.service';
import { SharedService } from 'src/app/services/shared.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { PasswordStrengthValidator } from '../PasswordStrengthValidator';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.css']
})
export class UserManagementComponent implements OnInit {
  form: FormGroup;
  imageUrl: any = this.constants.uploadlogo;
  userId: any;
  fileToUpload: File = null;
  validation: boolean = false;
  data: any;
  id: any;
  userName: string;
  firstName: string;
  lastName: string;
  phoneNumber: string
  email: string;
  settingDetailCSS: any;
  settingLogoImage: any;
  idHeaderColor: any;
  idSaveButtonColor: any;
  idCancelButtonColor: any;
  profileLogo: any;
  loader = false;
  password: string;
  passworConfirm: string
  constructor(public sharedService: SharedService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private constants: ConstantsService,
    private _sanitizer: DomSanitizer,
    private router: Router) { }

  ngOnInit(): void {
    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);
     
    } else {
      this.userId = localStorage.getItem(this.constants.userId);
      this.GetProfileDetail(this.userId);

      this.form = new FormGroup({
        'firstName': new FormControl(),
        'lastName': new FormControl(),
        'phoneNumber': new FormControl(),
        'email': new FormControl(),
        'userName': new FormControl(),
        'password': new FormControl(null, Validators.compose([
          Validators.required, Validators.minLength(8), PasswordStrengthValidator])),
        'passworConfirm': new FormControl(),
      });
    }
  }


  
  ChngSettingImage(files: FileList) {
    this.fileToUpload = files.item(0);
    var reader = new FileReader()
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);

  }

  public isValidFlg: boolean = true;
  validatePhoneNo(field) {
    var phoneNumDigits = field.value.replace(/\D/g, '');
    this.isValidFlg = (phoneNumDigits.length == 0 || phoneNumDigits.length == 10);
    var formattedNumber = phoneNumDigits;
    if (phoneNumDigits.length >= 6)
      formattedNumber = '(' + phoneNumDigits.substring(0, 3) + ') ' + phoneNumDigits.substring(3, 6) + '-' + phoneNumDigits.substring(6, 10);
    else if (phoneNumDigits.length >= 3)
      formattedNumber = '(' + phoneNumDigits.substring(0, 3) + ') ' + phoneNumDigits.substring(3);
    field.value = formattedNumber;
  }

 
  editUserProfile() {
    this.validation = true;
    if (this.form.controls.firstName.status === this.constants.Invalid ||
      this.form.controls.lastName.status === this.constants.Invalid ||
      this.form.controls.email.status === this.constants.Invalid ||
      this.form.controls.phoneNumber.status === this.constants.Invalid
    ) {
      return;
    }
    const obj = {};
    obj['Id'] = this.id;
    obj['FirstName'] = this.firstName;
    obj['LastName'] = this.lastName;
    obj['PhoneNumber'] = this.phoneNumber;
    obj['Email'] = this.firstName;
    if (this.imageUrl != '/assets/image/uploadlogo.png' && this.fileToUpload != null) {
      obj['ProfileImage'] = this.imageUrl;
    }

    this.sharedService.UpdateProfile(obj)
      .subscribe(
        data => {
          this.data = data;
          switch (this.data.statusCode) {
            case 200:
              if (this.fileToUpload != null) {
                (document.getElementById('profileLogo') as HTMLImageElement).src = this.imageUrl;
              }
              this.fileToUpload = null;
              $("#add_success").show();
              // this.toastr.success(this.constants.RecordAddedSuccessfully);
              break;
            case 400:
              $("#wrong_msg").show();
              // this.toastr.error(this.constants.SomethingWentWrong);
              break;
            default:
              $("#wrong_msg").show();
              // this.toastr.error(this.constants.SomethingWentWrong);
          }
        }
      );

  }

  
  GetProfileDetail(userId) {
    this.loader = true;
    this.sharedService.GetEditUserDetail(userId).subscribe(
      data => {
        this.data = data;
        this.loader = false;
        this.id = this.data.data.userId;
        this.userName = this.data.data.email;
        this.firstName = this.data.data.firstName;
        this.lastName = this.data.data.lastName;
        this.phoneNumber = this.data.data.phoneNumber;
        this.email = this.data.data.email;
        if (this.data.data.profileImage != null && this.data.data.profileImage != "") {
          this.imageUrl = this._sanitizer.bypassSecurityTrustUrl(this.data.data.profileImage);
        }

      });
  }

 
  ResetUserProfile() {

    this.ngOnInit();
  }

  
  SetSettingCSS(settingCSS, logoImagePath) {
    if (settingCSS != null && settingCSS != "null" && settingCSS != "") {
      let headerCss = document.getElementsByClassName(this.constants.CssHealerClass) as HTMLCollectionOf<HTMLElement>;
      if (headerCss.length != 0) {
        headerCss[0].style.backgroundColor = settingCSS.split(',')[0];
      }
      let saveButtonCss = document.getElementsByClassName(this.constants.CssSaveBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (saveButtonCss.length != 0) {
        saveButtonCss[0].style.backgroundColor = settingCSS.split(',')[1];
      }
      let cencelButtonCss = document.getElementsByClassName(this.constants.CssCancelBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (cencelButtonCss.length != 0) {
        cencelButtonCss[0].style.backgroundColor = settingCSS.split(',')[2];
      }
    }

   
    if (logoImagePath != null && logoImagePath != "") {
      (document.getElementById('hrdlogoImage') as HTMLImageElement).src = logoImagePath;
    }
  }

  ngAfterViewInit(): void {  
    this.settingDetailCSS = localStorage.getItem("settingDetailCSS");
    this.settingLogoImage = localStorage.getItem("settingImageLogo");
    this.SetSettingCSS(this.settingDetailCSS, this.settingLogoImage);
  }
  
  changePassword() {
    this.validation = true;
    if (
      this.form.controls.passworConfirm.status === this.constants.Invalid ||
      this.form.controls.password.status === this.constants.Invalid ||
      this.password != this.passworConfirm
    ) {
      return;
    }
    const obj = {};
    obj['UserId'] = this.id;
    obj['password'] = this.password;
    this.sharedService.changePassword(obj)
      .subscribe(
        data => {
          this.data = data;
          switch (this.data.statusCode) {
            case 200:
              $("#pwd_success").show();
              // this.toastr.success(this.constants.PasswordchangeSuccessfully);
              break;
            case 400:
              $("#wrong_msg").show();
              // this.toastr.error(this.constants.SomethingWentWrong);
              break;
            default:
              $("#wrong_msg").show();
              // this.toastr.error(this.constants.SomethingWentWrong);
          }
        }
      );
  }
  clearPassword() {
    this.passworConfirm = '';
    this.password = '';
  }

  addsuccess() {
    $("#add_success").hide();
  }
  updateitem() {
    $("#add_update").hide();
  }
  deleteitem() {
    $("#delete_success").hide();
  }
  updatepwd() {
    $("#pwd_success").hide();
  }
  msgitem() {
    $("#wrong_msg").hide();
  }
  
}
