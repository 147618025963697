import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TitleCasePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class PutAwayService {
  baseUrl = environment.baseUrl;
  orderDetails: any = null;
  ExitDetails: any = null;
  backdata: any = null;
  sideBar: any;
  Receive: any;
  shipping: any;
  invetory: any;
  vendor: any;
  company: any;

  constructor(
    private _http: HttpClient,
    public titlecasePipe: TitleCasePipe,
  ) { }

  GetAllPartList(data:any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/PickList/GetNewPickList' , data , 
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetPoInvData(data: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/PickList/GetPutAwayParts', data,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetPutAwayPartDetail(data): Observable<any> {
    return this._http.post(this.baseUrl + 'api/PickList/GetPutAwayPartDetail', data,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetDefaultPartData(partId:any): Observable<any> {
    return this._http.get(this.baseUrl + 'api/PutAway/GetReplenishmentbyPartId?partId='+ partId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetSourceLocationByPartId(partId : number,companyId: number): Observable<any>{
    return this._http.get(this.baseUrl + 'api/PickList/GetSourceLocationList?partId=' + partId + '&companyId=' + companyId, 
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetLocationCapacityByLocId(data:any): Observable<any>{
    return this._http.post(this.baseUrl + 'api/Inventory/GetLocationCapacityData', data,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  CreateReplenishment(object: any): Observable<any>{
    return this._http.post(this.baseUrl + 'api/PutAway/AddReplenishment', object,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || "server error.");
  }

}
