<section class="content-section pt-2 ws-nobreak1">
    <div class="loading" *ngIf="loader">Loading&#8230;</div>

    <div col-sm-12>
        <div class="row">
            <div class="col-6 mb-2 d-flex align-items-baseline text-xs-left">
                <div class="page-title">
                    <div class="bread-crumb">
                        <a [routerLink]="['/adminDashboard/']">Home</a>
                        <span class="mx-1">-</span>
                        <span class="active">Fulfill Parts for SOs</span>
                        <div *ngIf="permissionMessage!=''" class='btn-danger text-center'>{{permissionMessage}} </div>
                    </div>
                </div>
            </div>
            <div class="col-6 mb-2 text-right align-items-baseline">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="white-box1">
                <div class="card-desc1">
                    <form [formGroup]="formSearch">
                        <div class="row mt-2">
                            <!-- <div class="col-lg-3 col-md-12 mt-lg-0 mt-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp required">
                                        <label class="customLabel">Company</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10 border-none"
                                        [ngClass]="((value_search==0) && (SearchValidation)) ? 'validation_border': ''">
                                        <ngx-dropdown-treeview-select class="dropdown-company customInput-dropdown"
                                            [items]="userGroupObjects_search" [(value)]="value_search"
                                            (valueChange)="onValueChange_search($event)">
                                        </ngx-dropdown-treeview-select>
                                    </div>
                                </div>
                            </div> -->

                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-1" style="display: none;">
                                <div class="row">
                                    <div class="col-lg-4 col-md-2 trp required">
                                        <label class="customLabel">Vendor</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <select [(ngModel)]="vendorSearch" class="customInput"
                                            formControlName='vendorSearchDDL'
                                            [ngClass]="((!vendorSearch) && (SearchValidation)) ? 'validation_border': ''">
                                            <option value='0'>All</option>
                                            <option *ngFor="let item of vendorListDDL" [value]="item.id">
                                                {{item.vendorName}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                            <!-- <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                                <div class="row">
                                    <div class="col-lg-3 col-md-2 trp">
                                        <label class="customLabel">Part Name</label>
                                    </div>
                                    <div class="col-lg-9 col-md-10">
                                        <input type="text" placeholder="Search Part Name" class="customInput"
                                             formControlName="partSearch" autocomplete="off">
                                    </div>
                                </div>
                            </div>  -->

                            <!-- <div class="col-lg-1 col-md-6 mt-lg-0 mt-1">
                                <div class="row">
                                    <div class="col-lg-12 col-md-2 trp">
                                        <i class="fas fa-search cpointer" (click)="searchInv()"></i>
                                    </div>
                                </div>
                            </div> -->
                        </div>
                    </form>
                </div>
            </div>

            

            <div class="white-box1 no_bg1" [style.display]="ListShow==true ? 'block' : 'none'">
                <div class="white-box-title1">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="customHeading mb-1">
                                Fulfill Parts for SOs
                                <i class="fa fa-angle-{{ListAngle}} cpointer ml-2" aria-hidden="true"
                                    (click)="ListDivShowHide()"></i>
                            </div>
                        </div>

                        <div class="col-md-7"></div>

                    </div>
                </div>

                <div class="card-desc table-responsive" [style.display]="ListDiv==true ? 'block' : 'none'">

                    <table id="datatable" class="display table table-responsive clearfix" datatable
                        [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                        <thead>
                            <tr>
                                <th style="display: none;"></th>
                                <th>Company</th>
                                <th>Order No.</th>
                                <th>Status</th>
                                <th>Contact Name</th>
                                <th>Customer Name</th>
                                <th>Product Name</th>
                                <th>Order Date</th>
                                <th>Part/Product</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of orderList">
                                <td style="display: none;"></td>
                                <td>{{data.companyName}}</td>
                                <td>{{data.customerPONumber==null?'-------':data.customerPONumber}}</td>
                                <td>{{data.statusId==1?'Open':data.statusId==2?'Sale Quote
                                    ':data.statusId==3?'Cancel':data.statusId==4?'Close':''}}</td>
                                <td>{{data.customerFirstName}} {{data.customerLastName}}</td>
                                <td>{{data.customerName}}</td>
                                <td>{{data.partProductName}}</td>
                                <td>{{data.orderDate | date: "MMM-dd-yyyy"}}</td>
                                <td>{{data.orderType}}</td>
                                <td>
                                    <a *ngIf="rolePermissionsList && rolePermissionsList[0].isView"
                                        (click)="showPartList(data.id, data.orderType)" class="green-text"><i
                                            class="fas fa-edit"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                </div>
            </div>

            <div class="white-box1" *ngIf="rolePermissionsList && rolePermissionsList[0].isView"
                [style.display]="ListShow2==true ? 'block' : 'none'">
                <div class="card-desc1 mt-1">
                    <form [formGroup]="form">
                        <div class="col-sm-12 p-0">
                            <table class="row-border hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger"
                                id="datatable1">
                                <thead>
                                    <tr>
                                        <th class="coloumn-hide"></th>
                                        <th style="font-weight:bold; ">Item #</th>
                                        <th style="font-weight:bold; ">Inv Type</th>
                                        <th style="font-weight:bold; ">Document</th>
                                        <th style="font-weight:bold; ">Vendor Name</th>
                                        <th style="font-weight:bold; ">Vendor Part Name</th>
                                        <th style="font-weight:bold; ">Company Part Name</th>
                                        <th style="font-weight:bold; ">Ordered</th>
                                    <!-- <th style="font-weight:bold; ">{{QtySo}}</th> -->
                                        <th style="font-weight:bold; ">Needed</th>
                                        <!--Added acc to document for PO-->
                                        <th style="font-weight:bold; ">Shipped</th>
                                        <th style="font-weight:bold; ">On Hand</th>
                                        <th style="font-weight:bold; ">Inventory</th>
                                        <th style="font-weight:bold; ">New PO</th>
                                     <!--Added acc to document for PO-->
                                    <th style="font-weight:bold; ">Qty fm BO</th>
                                        <th style="font-weight:bold; ">Avlb. in BO</th>
                                        <th style="font-weight:bold; ">Check to Assign</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let datasub of InvListWithoutCustomPart; let i = index">
                                        <tr>
                                            <td class="coloumn-hide">{{datasub.id}}</td>
                                            <td>{{datasub.srNo}}</td>
                                            <td>InvType</td>
                                            <td>{{datasub.customerPONumber}}</td>
                                            <td>{{datasub.vendorName}}</td>
                                            <td>
                                                <div class="d-flex justify-content-center">
                                                    <div class="position-relative fix-width-tooltip">
                                                        <span class="tooltipWrap">
                                                            {{!datasub.vendorPartName ?
                                                            "N/A" : datasub.vendorPartName}}</span>
                                                        <span class="tooltips">{{!datasub.vendorPartName ? "N/A" :
                                                            datasub.vendorPartName}}</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="d-flex justify-content-center">
                                                    <div class="position-relative fix-width-tooltip">
                                                        <span class="tooltipWrap">
                                                            {{!datasub.name ?
                                                            "N/A" : datasub.name}}</span>
                                                        <span class="tooltips">{{!datasub.name ? "N/A" :
                                                            datasub.name}}</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{{datasub.quantity}}</td>
                                            <td>{{datasub.qtyToFulFill}}</td>
                                            <td>Shipped</td>
                                            <td>{{datasub.invQty}}</td>
                                            <td>
                                                <ng-container *ngIf="datasub.rowEdit==false">
                                                    <input type="text" class="form-control" [value]="datasub.assignQty"
                                                        (click)="openEditAssQty(datasub.opId)" readonly>
                                                </ng-container>
                                                <ng-container *ngIf="datasub.rowEdit==true">
                                                    <input type="text" class="form-control"
                                                        [(ngModel)]="UpdatedAssignQty"
                                                        formControlName='UpdatedAssignQty'
                                                        (blur)="quantityBlur(datasub.opId)">
                                                </ng-container>
                                            </td>
                                            <td>
                                                <input type="text" class="form-control" [value]="datasub.poQty"
                                                    readonly>
                                            </td>
                                            <td>Qty BO</td>
                                            <td>BO</td>
                                            <td style="text-align:center !important;">
                                                <input class="p_relative custom_checkbox" formControlName='chkInv'
                                                    type="checkbox"
                                                    (click)="chngIsInv($event.target.checked, datasub.opId)"
                                                    [checked]='datasub.isChecked'>
                                            </td>
                                        </tr>
                                    </ng-container>
                                    <ng-container *ngFor="let data of InvListCustomPart; let i = index">
                                        <tr style="background-color: #f5f5f5 !important;" *ngIf="data.visible==true">
                                            <td class="coloumn-hide">{{data.id}}</td>
                                            <td>{{data.srNo}}</td>
                                            <td>{{data.customerPONumber}}</td>
                                            <td>{{data.vendorName}}</td>
                                            <td>
                                                <div class="d-flex justify-content-center">
                                                    <div class="position-relative fix-width-tooltip">
                                                        <span class="tooltipWrap">
                                                            {{!data.vendorPartName ?
                                                            "N/A" : data.vendorPartName}}</span>
                                                        <span class="tooltips">{{!data.vendorPartName ? "N/A" :
                                                            data.vendorPartName}}</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>
                                                <div class="d-flex justify-content-center">
                                                    <div class="position-relative fix-width-tooltip">
                                                        <span class="tooltipWrap">
                                                            {{!data.name ?
                                                            "N/A" : data.name}}</span>
                                                        <span class="tooltips">{{!data.name ? "N/A" :
                                                            data.name}}</span>
                                                    </div>
                                                </div>
                                            </td>
                                            <td>{{data.quantity}}</td>
                                            <td>
                                                <span *ngIf="data.isCustompart==false else other_content">
                                                    {{data.qtyToFulFill}}
                                                </span>
                                                <ng-template #other_content>0</ng-template>
                                            </td>
                                            <td>
                                                <span *ngIf="data.isCustompart==false else other_content">
                                                    {{data.invQty}}
                                                </span>
                                                <ng-template #other_content>0</ng-template>
                                           
                                            </td>

                                            <td>
                                                <input type="text" class="form-control" [value]="0" readonly>
                                            </td>

                                            <td>
                                                <input type="text" class="form-control" [value]="0" readonly>
                                            </td>

                                            <td style="text-align:center !important;">
                                                <input class="p_relative custom_checkbox" formControlName='chkInv'
                                                    type="checkbox" [attr.disabled]="true">
                                            </td>
                                        </tr>
                                        <ng-container *ngFor="let datasub of data.PartList; let innerIndex = index">
                                            <tr>
                                                <td class="coloumn-hide">{{datasub.id}}</td>
                                                <td>&nbsp;&nbsp;&nbsp;{{data.srNo}}.{{innerIndex+1}}</td>
                                                <td>InvType</td>
                                                <td>{{datasub.customerPONumber}}</td>
                                                <td>{{datasub.vendorName}}</td>
                                                <td>
                                                    <div class="d-flex justify-content-center">
                                                        <div class="position-relative fix-width-tooltip">
                                                            <span class="tooltipWrap">
                                                                {{!datasub.vendorPartName ?
                                                                "N/A" : datasub.vendorPartName}}</span>
                                                            <span class="tooltips">{{!datasub.vendorPartName ? "N/A" :
                                                                datasub.vendorPartName}}</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>
                                                    <div class="d-flex justify-content-center">
                                                        <div class="position-relative fix-width-tooltip">
                                                            <span class="tooltipWrap">
                                                                {{!datasub.name ?
                                                                "N/A" : datasub.name}}</span>
                                                            <span class="tooltips">{{!datasub.name ? "N/A" :
                                                                datasub.name}}</span>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>{{datasub.quantity}}</td>
                                                <td>{{datasub.qtyToFulFill}}</td>
                                                <td>Shipped</td>
                                                <td>{{datasub.invQty}}</td>

                                                <td>
                                                    <ng-container *ngIf="datasub.rowEdit==false">
                                                        <input type="text" class="form-control"
                                                            [value]="datasub.assignQty"
                                                            (click)="openEditAssQty(datasub.opId)" readonly>
                                                    </ng-container>
                                                    <ng-container *ngIf="datasub.rowEdit==true">
                                                        <input type="text" class="form-control"
                                                            [(ngModel)]="UpdatedAssignQty"
                                                            formControlName='UpdatedAssignQty'
                                                            (blur)="quantityBlur(datasub.opId)">
                                                    </ng-container>
                                                </td>

                                                <td>
                                                    <input type="text" class="form-control" [value]="datasub.poQty"
                                                        readonly>
                                                </td>
                                                <td>Qty BO</td>
                                                <td>BO</td>
                                                <td style="text-align:center !important;">
                                                    <input class="p_relative custom_checkbox" formControlName='chkInv'
                                                        type="checkbox"
                                                        (click)="chngIsInv($event.target.checked, datasub.opId)"
                                                        [checked]='datasub.isChecked'>
                                                </td>
                                            </tr>
                                        </ng-container>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>

                        <div class="alert alert-success mt-2" role="alert" *ngIf="IsUpdating==true">
                            {{UpdatingMsg}}
                        </div>

                        <div class="alert alert-danger fade show mt-2" role="alert" *ngIf="avlbInv==true">
                            You don't have available inventory to assign in this part.
                            <button type="button" class="close" (click)="closenoti()">
                                <strong aria-hidden="true" class="text-danger">&times;</strong>
                            </button>
                        </div>
                        <div class="submit-from text-right">
                            <button class="btn blue-bg round-btn" (click)="SaveConfirm(confirmSave)">Fulfill SO</button>
                            <button class="btn darkred-bg round-btn" (click)="Exit()"><i
                                    class="fas fa-sign-out-alt"></i>Exit</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

</section>

<ng-template #confirmSave let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg sweet-heading"></h4>
    <button class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body">
        <div class="thank-you-pop">
            <img src="./assets/image/popup-alert.png" alt="">
            <h1>Confirmation</h1>
            <p class="mb-0">Are you sure you want to save?</p>
        </div>
    </div>
    <div class="modal-footer sweet-footer text-center">
        <button type="button" class="btn green-bg round-btn m-0 mr-1"
            (click)="SaveAssignInv(); c('Close click')">Yes</button>
        <button type="button" class="btn red-bg round-btn m-0" (click)="c('Close click')">No</button>
    </div>
</ng-template>

<div class="modal" id="add_update">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="updateitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Update Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="updateitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="emptyInv">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="emptyInv()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="">
                    <h1>Alert!</h1>
                    <p>There is no Items to fulfill SO</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="emptyInv()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="emptyInv1">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="emptyInv1()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="">
                    <h1>Alert!</h1>
                   
                        <span *ngFor="let a of countArray;" style="font-size: 17px;">
                             {{ a.name }} <span style="font-size: 17px;">has available qty in Inventory  </span> {{a.qty}} <br />
                        </span>
                       
                     
                      <span style="font-size: 16px; color: red">Note : Can not be assigned more than available qty. Please Add more qty in Inventory or Adjust qty with PO.</span>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="emptyInv1()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>