<section class="content-section pt-2">
    <div class="loading" *ngIf="loader">Loading&#8230;</div>


    <div col-sm-12>
        <div class="row">
            <div class="col-6 mb-2 d-flex align-items-baseline text-xs-left">
                <div class="page-title">
                    <div class="bread-crumb">
                        <a [routerLink]="['/adminDashboard/']">Home</a>
                        <span class="mx-1">-</span>
                        <span class="active">Vehicle List</span>
                        <div *ngIf="permissionMessage!=''" class='btn-danger text-center'>{{permissionMessage}} </div>
                    </div>
                </div>
            </div>
            <div class="col-6 mb-2 text-right align-items-baseline">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="white-box1">
                <div class="card-desc1">
                    <form [formGroup]="formSearch">
                        <div class="row detailSection">
                            <div class="col-md-12 container">
                                <div class="row mt-3">
                                    <div class="col-lg-2 col-md-6">
                                        <div class="d-flex align-items-center">
                                            <label class="customLabel mr-1" for="Type">Type</label>
                                            <select class="customInput" (change)="ChangeTCreateUpdate($event)"
                                                [(ngModel)]="TCreateUpdate" id="TCreateUpdate"
                                                formControlName="TCreateUpdate">
                                                <option value="1">Create</option>
                                                <option value="0">Update</option>
                                            </select>
                                        </div>
                                    </div>

                                    <!-- <div class="col-lg-3 col-md-6 mt-md-0 mt-1"
                                        [style.display]="SearchControl==true ? 'block' : 'none'">
                                        <div class="d-flex align-items-center required borderNone"
                                            [ngClass]="((value_search==0) && (SearchValidation)) ? 'validation_border': ''">
                                            <label class="mr-1 customLabel" for="Company">Company</label>
                                            <ngx-dropdown-treeview-select [config]="config"
                                                class="dropdown-company customInput-dropdown"
                                                [items]="userGroupObjects_search" [(value)]="value_search"
                                                (valueChange)="onValueChange_search($event)">
                                            </ngx-dropdown-treeview-select>
                                         
                                        </div>
                                    </div> -->
                                    <div class="col-lg-5 col-md-12 mt-lg-0 mt-1"
                                        [style.display]="SearchControl==true ? 'block' : 'none'">

                                        <div class="d-flex align-items-center">
                                            <label class="customLabel mr-1 ws-nobreak" for="Vehicle">Vehicle 
                                                Name</label>
                                            <input type="text" placeholder="Search Vehicle Name"
                                                class="customInput" [(ngModel)]="userSearch"
                                                formControlName="userSearch" autocomplete="off" required
                                                [ngClass]="((!userSearch && SearchType==false) && (SearchValidation)) ? 'validation_border': ''">
                                     
                                            <i class="fas fa-search cpointer ml-3 mr-4 cpointer"
                                                (click)="searchVehicle()"></i>
                                            <button class="btn blue-bg round-btn m-0" (click)="AllVehicle()">All
                                                Vehicle</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            
         <div class="white-box1">
            <div class="customHeading mt-3">
                Add Vehicle
            </div>
                 <div class="white-box1" *ngIf="rolePermissionsList && rolePermissionsList[0].isView"
         [style.display]="ListShow==true ? 'block' : 'none'">
         <div class="white-box-title1">
             <div class="customHeading mt-3 mb-2">
                 Vehicle List
                 <i class="fa fa-angle-{{ListAngle}} cpointer ml-2" aria-hidden="true"
                     (click)="ListDivShowHide()"></i>
             </div>
         </div>
         <div class="card-desc1" [style.display]="ListDiv==true ? 'block' : 'none'">
             <table class="row-border hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                 <thead>
                     <tr>
                         <th>Company Name</th>
                         <th>Vehicle Manufacturer</th>
                         <th data-orderable="false">Action</th>
                     </tr>
                 </thead>
                 <tbody>
                     <tr *ngFor="let data of vehicleDataList">
                         <td>{{data.companyName}}</td>
                         <td>{{data.vehicleManufacturer}}</td>
                         <td>
                             <a class="green-text" *ngIf="rolePermissionsList && rolePermissionsList[0].isUpdate"
                                 (click)="toggleEdit(data)"><i class="fas fa-edit"></i></a>
                             <a class="red-text"
                                 *ngIf="rolePermissionsList && rolePermissionsList[0].isDelete"><i
                                     class="fas fa-trash-alt" (click)="deletePopup(data,content)"></i></a>
                         </td>
                     </tr>
                 </tbody>
             </table>
         </div>
         </div>
            <div class="card-desc1">
                <form [formGroup]="form">
                    <div class="row mt-2">
                        <!-- <div class="col-lg-4 col-md-12">
                            <div class="row required">
                                <div class="col-lg-3 col-md-2 trp">
                                    <label class="customLabel">Company</label>
                                </div>
                                <div class="col-lg-8 col-md-10 borderNone"
                                    [ngClass]="((value==0) && (validation)) ? 'validation_border': ''">
                                    <ngx-dropdown-treeview-select class="dropdown-company customInput-dropdown"
                                        [config]="config" required [items]="userGroupObjects" [(value)]="value"
                                        (valueChange)="onValueChange($event)">
                                    </ngx-dropdown-treeview-select>
                                </div>
                            </div>
                        </div> -->
                        <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                            <div class="row required">
                                <div class="col-lg-4 col-md-2 trp">
                                    <label class="customLabel">Vehicle Manufacturer</label>
                                </div>
                                <div class="col-lg-7 col-md-10">
                                    <input type="text" class="customInput" [(ngModel)]="VehicleManufacture"
                                        placeholder="Enter Vehicle Manufacture" name="category" formControlName="VehicleManufacture"
                                        autocomplete="off" required maxlength="15"
                                        [ngClass]="((!form.controls.VehicleManufacture.valid) && (validation)) ? 'validation_border': ''">
                                
                                    <div *ngIf="((form.controls.VehicleManufacture.valid) && (recordAlreadyExist))"
                                        class="text-danger input-error">
                                        Vehicle Manufacture Already Exist.</div>
                                    
                                </div>
                            </div>
                        </div>
              
                    </div>
                    <div class="row  mt-md-1">
                        
                        <div class="col-lg-12 col-md-12 mt-lg-0">
                            <div class="row required">
                                <div class="col-lg-1 col-md-2 trp">
                                    <label class="customLabel">Models</label>
                                </div>
                                <div class="col-lg-7 col-md-10">
                                    <div class="row">
                                    
                                        <div class="col-lg-12">
                                            <table formArrayName="models" style="border: none;margin:0"> 
                                                <tr>   
                                                    <th colspan="3"><button type="button" (click)="addModel()" class="btn blue-bg round-btn btnModel" style="float: left">Add Model</button></th>  
                                                </tr>  
                                              
                                                <tr *ngFor="let model of models().controls let i=index" [formGroupName]="i">  
                                                    <td>  
                                                        <label class="customNewLabel textlabel" style="float:left">Model {{i+1}} </label>
                                                         
                                                        <input type="text"  placeholder="Enter Model Name" formControlName="name" class="customInput textbox">  
                                                    </td>  
                                                    <td> 
                                                        <label class="customNewLabel textlabel" style="float:left">Description {{i+1}} </label> 
                                                  
                                                        <input type="text"  required placeholder="Enter Model Description" formControlName="description" class="customInput textbox">  
                                                    </td>  
                                                    <td>  
                                                        <button (click)="removeQuantity(i)" class="btn darkred-bg round-btn rbtnstyle">Remove</button>  
                                                    </td>  
                                                  </tr> 
                                              </table>
                                        </div>
                                   
                                    
                                    </div>
                                
                                 
                                </div>
                            </div>
                        </div>
                    </div>

                   

                  
                    
                 
                    <div class="submit-from text-right mt-1 p-0">
                        <button class="btn blue-bg round-btn"
                            *ngIf="rolePermissionsList && rolePermissionsList[0].isInsert"
                            (click)="addNewVehicle()"><i class="fas fa-save"></i> {{buttonValue}}
                        </button>
                        <button class="btn darkred-bg round-btn" (click)="resetCategory()"
                            *ngIf="clearbutton==true"><i class="fas fa-redo"></i> Clear </button>
                        <button class="btn darkred-bg round-btn" (click)="VehicleBack()"
                            *ngIf="clearbutton==false"><i class="fas fa" aria-hidden="true"></i>
                            Exit </button>
                    </div>
                </form>
            </div>
        </div>
         </div>

    

    </div>
    </section>

    <div class="modal" id="add_success">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header1 darkred-bg sweet-heading p-0">
                    <h4 class="modal-title sweet-heading"></h4>
                    <button type="button" class="close btn alert_modal" (click)="addsuccess()"
                        data-dismiss="modal">&times;</button>
                </div>
                <div class="modal-body p-1">
                    <div class="thank-you-pop">
                        <img src="./assets/image/Green-Round-Tick.png" alt="">
                        <h1>Thank You!</h1>
                        <p>Record Added Successfully</p>
                    </div>
                </div>
                <div class="modal-footer sweet-footer p-1">
                    <button type="button" class="btn red-bg round-btn" (click)="addsuccess()"
                        data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal" id="add_update">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header1 darkred-bg sweet-heading p-0">
                    <h4 class="modal-title sweet-heading"></h4>
                    <button type="button" class="close btn alert_modal" (click)="updateitem()"
                        data-dismiss="modal">&times;</button>
                </div>
                <div class="modal-body p-1">
                    <div class="thank-you-pop">
                        <img src="./assets/image/Green-Round-Tick.png" alt="">
                        <h1>Thank You!</h1>
                        <p>Record Update Successfully</p>
                    </div>
                </div>
                <div class="modal-footer sweet-footer p-1">
                    <button type="button" class="btn red-bg round-btn" (click)="updateitem()"
                        data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
    <div class="modal" id="delete_success">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header1 darkred-bg sweet-heading p-0">
                    <h4 class="modal-title sweet-heading"></h4>
                    <button type="button" class="close btn alert_modal" (click)="deleteitem()"
                        data-dismiss="modal">&times;</button>
                </div>
                <div class="modal-body p-1">
                    <div class="thank-you-pop">
                        <img src="./assets/image/Green-Round-Tick.png" alt="">
                        <h1>Thank You!</h1>
                        <p>Record Deleted Successfully</p>
                    </div>
                </div>
                <div class="modal-footer sweet-footer p-1">
                    <button type="button" class="btn red-bg round-btn" (click)="deleteitem()"
                        data-dismiss="modal">Close</button>
                </div>
            </div>
        </div>
    </div>
<ng-template #content let-c="close" let-d="dismiss">
        <h4 class="modal-title darkred-bg alert_heading sweet-heading"></h4>
        <button class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
            <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-body p-0">
            <div class="thank-you-pop">
                <img src="./assets/image/popup-alert.png" alt="">
                <h1>Confirmation</h1>
                <p class="mt-1 modal-para">{{deleteDetail}}</p>
            </div>
        </div>
        <div class="modal-footer sweet-footer text-center p-1">
            <button type="button" class="btn green-bg round-btn m-1"
                (click)="deleteVehicleList(deletedItem); c('Close click')">Yes</button>
            <button type="button" class="btn red-bg round-btn m-1" (click)="c('Close click')">No</button>
        </div>
</ng-template>

<div class="modal" id="wrong_msg">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="msgitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>Something went wrong</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="msgitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="add_msg">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="add_msg()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>Please Add atleast One Model</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="add_msg()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>