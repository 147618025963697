import { Component, OnInit, ChangeDetectorRef, AfterViewInit, ViewChild, OnDestroy, ElementRef, ViewChildren, QueryList } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { FormControl, FormGroup, } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstantsService } from 'src/app/constants.service';
import { SharedService } from 'src/app/services/shared.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
// import { TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { DatePipe } from '@angular/common';
import { GlobalChangeService } from '../services/global-change.service';
import Swal from 'sweetalert2';
import { CompanyService } from '../services/company/company.service';
import { PartService } from '../services/part/part.service';
declare var $: any

@Component({
  selector: 'app-assign-inventory-sos',
  templateUrl: './assign-inventory-sos.component.html',
  styleUrls: ['./assign-inventory-sos.component.css']
})
export class AssignInventorySOsComponent implements AfterViewInit, OnDestroy, OnInit {

  srNo: number;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();

  @ViewChildren(DataTableDirective)
  dtElements: QueryList<DataTableDirective>;
  rolePermissionsList: any;
  permissionMessage: string;
  userGroupObjects_search = new Array();
  value_search: number;
  loader: boolean = false;
  ListShow: boolean = false;
  ListDiv: boolean = false;
  ListShow2: boolean = false;
  ListDiv2: boolean = false;
  ListAngle: any;

  form: FormGroup;
  formSearch: FormGroup;
  companyId: string;
  data: any = {};
  // items_search: TreeviewItem[];
  companyList_search: any;

  userId: string;
  buttonValue: string;
  SearchValidation: boolean;
  searchType = '1';
  searchText: any;
  partId: number;
  partSearch = '';
  totalQty: number = 0;
  InvList = [];
  InvListCustomPart = [];
  InvListWithoutCustomPart = [];
  InvDetails = [];
  datalist: any;
  vendorSearch = '0';
  vendorListDDL: any;
  userQuestionUpdate = new Subject<string>();
  search = ''
  @ViewChild('input') input: ElementRef;
  now: Date;
  CustomerPONumber: any;
  VendorName: any;
  vendorName: any;
  VendorPartName: any;
  UpdateVendorPartName: any;
  UpdateCustomerPONumber: any;
  UpdateName: any;
  UpdateInvQty: any;
  UpdateQuantity: any;
  UpdateVendorName: any;
  UpdateInvServNo: any;
  UpdatedAssignQty: any;
  Quantity: any;
  Id: any;
  InvValidationUpdate: boolean;
  chkIsInv: boolean = false;
  InvValidation: boolean;
  isPartval: boolean = true;
  InvType: string;
  InvQty: string;
  UpdateInvType: string;
  InvSno: any;
  partListDDL: any;
  settingDetailCSS: any;
  settingLogoImage: any;
  avlbInv: boolean = false;
  IsUpdating: boolean = false;
  UpdatingMsg: any;
  orderList: any;

  // config = TreeviewConfig.create({
  //   hasAllCheckBox: true,
  //   hasFilter: true,
  //   hasCollapseExpand: true,
  //   decoupleChildFromParent: false,
  //   maxHeight: 400

  // });
  // items: TreeviewItem[];
  countArray = [];
  QtySo: string;
  items_search: any;

  constructor(public sharedService: SharedService,
    private cdr: ChangeDetectorRef,
    private toastr: ToastrService,
    private _sanitizer: DomSanitizer,
    private constants: ConstantsService,
    private modalService: NgbModal,
    private router: Router,
    public datepipe: DatePipe,
    private globalChangeService: GlobalChangeService,
    private companyService: CompanyService,
    private partService: PartService
  ) {
  }

  ngOnInit(): void {
    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);
    } else {
      this.rolePermissionsList = JSON.parse(localStorage.getItem('rolePermissionsList'));
      this.rolePermissionsList = this.rolePermissionsList.filter(x => x.pageId == 1029);
      this.permissionMessage = '';
      if (this.rolePermissionsList != null && this.rolePermissionsList != undefined) {
        if (!this.rolePermissionsList[0].isView) {
          this.permissionMessage += 'view';
        } if (!this.rolePermissionsList[0].isInsert) {
          if (this.permissionMessage != '') {
            this.permissionMessage += ', ';
          }
          this.permissionMessage += 'add';
        } if (!this.rolePermissionsList[0].isUpdate) {
          if (this.permissionMessage != '') {
            this.permissionMessage += ', ';
          }
          this.permissionMessage += 'edit';
        } if (!this.rolePermissionsList[0].isDelete) {
          if (this.permissionMessage != '') {
            this.permissionMessage += ', ';
          }
          this.permissionMessage += 'delete';
        }
      }
      if (this.permissionMessage != '') {
        this.permissionMessage = 'You do not have access to ' + this.permissionMessage + '. Please contact to administrator!';
      }
      this.companyId = localStorage.getItem(this.constants.companyId);
      this.userId = localStorage.getItem(this.constants.userId);
      this.buttonValue = this.constants.Save;
      // this.getAllCompany_search(this.companyId);
      // this.Getvendordropdownlist(this.companyId);

      this.dtOptions = {
        pagingType: this.constants.full_numbers,
        pageLength: 10,
        destroy: true,
        scrollX: false
      };

      //ankit implementaion
      // get company by default set value
      var company = this.globalChangeService.getGlobalCompany();
      if(company>0){
        this.value_search = company;
        this.Getvendordropdownlist(this.value_search);
        this.searchInv();
      }
      else{
        this.ErrorModal('Error', 'Please select company.');
      }

      //on change function 
      this.globalChangeService.dropdownChange$.subscribe((data)=>{
        this.value_search = data;
        this.onValueChange_search(data);
        this.searchInv();
      })
    }

    this.formSearch = new FormGroup({
      'vendorSearchDDL': new FormControl(),
    });

    this.form = new FormGroup({
      'UpdatedAssignQty': new FormControl(),
      'chkInv': new FormControl(true),
    });
  }

  SetSettingCSS(settingCSS, logoImagePath) {
    if (settingCSS != null) {
      let headerCss = document.getElementsByClassName(this.constants.CssHealerClass) as HTMLCollectionOf<HTMLElement>;
      if (headerCss.length != 0) {
        headerCss[0].style.backgroundColor = settingCSS.split(',')[0];
      }
      let saveButtonCss = document.getElementsByClassName(this.constants.CssSaveBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (saveButtonCss.length != 0) {
        saveButtonCss[0].style.backgroundColor = settingCSS.split(',')[1];
        $(".blue-bg").css("background", settingCSS.split(',')[1]);
      }
      let cencelButtonCss = document.getElementsByClassName(this.constants.CssCancelBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (cencelButtonCss.length != 0) {
        cencelButtonCss[0].style.backgroundColor = settingCSS.split(',')[2];
        $(".darkred-bg").css("background", settingCSS.split(',')[2]);
      }
    }
    if (logoImagePath != null && logoImagePath != "") {
      (document.getElementById('hrdlogoImage') as HTMLImageElement).src = logoImagePath;
    }
  }

  closenoti() {
    this.avlbInv = false;
  }

  getAllCompany_search(companyId) {
    this.companyService.GetDropdownCompanyHierarchyModel(this.companyId).subscribe(
      data => {
        this.data = data;
        this.items_search = this.data.dataList;
        this.companyList_search = this.data.dataList;
        // this.companyList_search.forEach((x) => {
        //   this.userGroupObjects_search.push(new TreeviewItem(x, false));
        // });
        this.value_search = Number(companyId);
      });
  }

  onValueChange_search(value: any): void {
    this.value_search = value;
    this.Getvendordropdownlist(this.value_search);
  }

  openEditAssQty(opId) {

    this.InvList.forEach(InvList => {
      InvList.rowEdit = false;
    });

    const invdata = this.InvList.filter(x => x.opId == opId);
    if (invdata.length > 0) {
      invdata[0].rowEdit = true;
      this.UpdatedAssignQty = invdata[0].assignQty ? invdata[0].assignQty : 0;
    }
  }

  quantityBlur(opId): void {
    const invdata = this.InvList.filter(x => x.opId == opId);
    if (invdata.length > 0) {
      let InvQty = Number(invdata[0].invQty);
      let QtyToFulFill = Number(invdata[0].qtyToFulFill);
      let alreadyAssigned = Number(invdata[0].assignQty);

      if (InvQty == 0) {
        this.avlbInv = true;
        invdata[0].assignQty = 0;
        invdata[0].rowEdit = false;
        return;
      }

      this.avlbInv = false;
      if (InvQty > QtyToFulFill) {
        invdata[0].assignQty = Number(this.UpdatedAssignQty) > QtyToFulFill ? QtyToFulFill : Number(this.UpdatedAssignQty);
      }
      else if (InvQty < QtyToFulFill) {
        invdata[0].assignQty = Number(this.UpdatedAssignQty) > InvQty ? InvQty : Number(this.UpdatedAssignQty);
      }

      invdata[0].poQty = Number(invdata[0].qtyToFulFill) - Number(invdata[0].assignQty);

      invdata[0].rowEdit = false;
    }
  }

  searchInv() {
    this.loader = true;
    this.ListShow = true;
    this.ListShow2 = false;
    this.ListDiv = true;
    this.ListAngle = this.constants.UpArrow;
    this.sharedService.GetFulfillPartSOList(this.value_search).subscribe(
      data => {
        this.loader = false;
        this.data = data;
        if (this.data.dataList != null && this.data.dataList != undefined) {
          this.orderList = this.data.dataList;
        }
        this.rerender();
      }
    )
  }

   getCommon(arr1, arr2) {
    var common = [];   
    common= arr1.filter(x => !arr2.includes(x));  
    return common;                     
  }
  showPartList(orderId, type) {
    this.QtySo = type == "Part" ? "Qty on SO" : "Total Qty on SO";
    this.IsUpdating = false;
    this.loader = true;
    this.srNo = 1;
    this.ListDiv = false;
    this.ListShow2 = true;
    this.ListAngle = this.constants.DownArrow;
    this.sharedService.GetFulfillPartsList(orderId, type).subscribe(
      data => {
        this.loader = false;
        this.data = data;
        this.InvList = [];
        if (this.data.dataList != null && this.data.dataList != undefined) {
          if (this.data.dataList.length > 0) {
            var restInv = 0;
            for (var item = 0; item < this.data.dataList.length; item++) {
              if ((this.data.dataList[item].qtyToFulFill == 0 && this.data.dataList[item].customPartId) || (this.data.dataList[item].qtyToFulFill == 0 && !this.data.dataList[item].virtual && !this.data.dataList[item].customPartId)) {
                continue;
              }
              let obj = {
                id: this.data.dataList[item].id,
                opId: this.data.dataList[item].opId,
                orderTypeId: this.data.dataList[item].orderTypeId,
                customerPONumber: this.data.dataList[item].customerPONumber,
                vendorId: this.data.dataList[item].vendorId,
                vendorName: this.data.dataList[item].vendorName,
                partId: this.data.dataList[item].partId,
                name: this.data.dataList[item].name,
                partNo: this.data.dataList[item].partNo,
                vendorPartName: this.data.dataList[item].vendorPartName,
                vendorPartNumber: this.data.dataList[item].vendorPartNumber,
                vendorPartCost: this.data.dataList[item].vendorPartCost,
                description: this.data.dataList[item].description,
                unitofMeasureId: this.data.dataList[item].unitofMeasureId,
                createdBy: this.data.dataList[item].createdBy,
                createdDate: this.data.dataList[item].createdDate,
                isChecked: true,
                rowEdit: false,
                quantity: this.data.dataList[item].quantity,
                qtyToFulFill: this.data.dataList[item].qtyToFulFill,
                invQty: this.data.dataList[item].invQty,
                assignQty: this.data.dataList[item].isCustompart == false ? (Number(this.data.dataList[item].invQty) < Number(this.data.dataList[item].qtyToFulFill) ? Number(this.data.dataList[item].invQty) : Number(this.data.dataList[item].qtyToFulFill)) : this.data.dataList[item].quantity,
                poQty: this.data.dataList[item].isCustompart == false ? (Number(this.data.dataList[item].invQty) < Number(this.data.dataList[item].qtyToFulFill) ? (Number(this.data.dataList[item].qtyToFulFill) - Number(this.data.dataList[item].invQty)) : 0) : this.data.dataList[item].quantity,
                virtual: this.data.dataList[item].virtual,
                customPartId: this.data.dataList[item].customPartId,
                isCustompart: this.data.dataList[item].isCustompart,
                location: this.data.dataList[item].location,
                locationName: this.data.dataList[item].locationName,
                 SerialGroup : this.data.dataList[item].serialGroup != null ? this.data.dataList[item].serialGroup.split(',') : '',
                // SerialGroupSelected : this.data.dataList[item].serialGroup != null ? this.data.dataList[item].serialGroup.split(',').slice(0, this.data.dataList[item].isCustompart == false ? (Number(this.data.dataList[item].invQty) < Number(this.data.dataList[item].qtyToFulFill) ? Number(this.data.dataList[item].invQty) : Number(this.data.dataList[item].qtyToFulFill)) : this.data.dataList[item].quantity) : '',
                 SerialGroup1 : this.data.dataList[item].serialGroup1 != null ? this.data.dataList[item].serialGroup1.split(',') : '',
                // SerialGroupSelected1 : this.data.dataList[item].serialGroup1 != null ? this.data.dataList[item].serialGroup1.split(',').slice(0, this.data.dataList[item].isCustompart == false ? (Number(this.data.dataList[item].invQty) < Number(this.data.dataList[item].qtyToFulFill) ? Number(this.data.dataList[item].invQty) : Number(this.data.dataList[item].qtyToFulFill)) : this.data.dataList[item].quantity) : '',
                tracking: this.data.dataList[item].tracking,
              }
              this.InvList.push(obj);
            }
            let AdArray = this.InvList.filter(x=>x.isCustompart == false);
            var result = [];
            AdArray.reduce(function (res, value) {
              if (!res[value.partId]) {
                res[value.partId] = { Id: value.partId, invQty: 0, name: value.name, count :0 };
                result.push(res[value.partId])
              }
              res[value.partId].invQty = value.invQty;
              res[value.partId].count = AdArray.filter(x=>x.isCustompart == false && x.partId == value.partId).length;
              return res;
            }, {});      
            let count = 0;
            const countarray1 = [];
            result.forEach(element => {
             
              this.InvList.forEach(element1 => {
                if(element.Id == element1.partId){
                  if(element.count > 1)
                  {
                    
                    if(element.invQty > element1.qtyToFulFill)
                    {
                      let AssignQty = element1.qtyToFulFill;
                      element1.assignQty = AssignQty;
                      element1.invQty = element1.qtyToFulFill;
                      element.invQty = element.invQty - element1.assignQty;
                    }
                    else if((element.invQty < element1.qtyToFulFill)){
                      element1.assignQty = element.invQty;
                      element1.invQty = element.invQty;
                      element1.poQty =   element1.qtyToFulFill - element.invQty;
                      element.invQty = 0;
                    }
                    else{
                      element1.assignQty = element.invQty;
                      element1.invQty = element.invQty;
                      element1.poQty =  0;
                      element.invQty = 0;
                    }

                    if(element1.isCustompart == false){
                      element1.SerialGroup = element1.SerialGroup;
                      element1.SerialGroupSelected = element1.SerialGroup.slice(0, element1.isCustompart == false ? (Number(element1.invQty) < Number(element1.qtyToFulFill) ? Number(element1.invQty) : Number(element1.qtyToFulFill)) : element1.quantity);
                      if(element1.SerialGroup != ""){
                        element1.SerialGroup = this.getCommon(element1.SerialGroup, element1.SerialGroupSelected);
                        var cArray = this.InvList.filter(x=>x.partId == element.Id);
                        if(cArray.length > 0){
                          cArray.forEach(elements => {
                            elements.SerialGroup = element1.SerialGroup;
                          });
                        }
                      }
                      element1.SerialGroup1 = element1.SerialGroup1;
                      element1.SerialGroupSelected1 = element1.SerialGroup1.slice(0, element1.isCustompart == false ? (Number(element1.invQty) < Number(element1.qtyToFulFill) ? Number(element1.invQty) : Number(element1.qtyToFulFill)) : element1.quantity);
                      if(element1.SerialGroup1 != ""){
                        element1.SerialGroup1 = this.getCommon(element1.SerialGroup1, element1.SerialGroupSelected1);
                      }
                      var cArray = this.InvList.filter(x=>x.partId == element.Id);
                      if(cArray.length > 0){
                        cArray.forEach(elements => {
                          elements.SerialGroup1 = element1.SerialGroup1;
                        });
                      }
                   
                    }
                  }
                  else{
                      
                    if(element.invQty > element1.qtyToFulFill)
                    {
                      let AssignQty = element1.qtyToFulFill;
                      element1.assignQty = element1.qtyToFulFill;

                    }
                    else if((element.invQty < element1.qtyToFulFill)){
                      element1.assignQty = element.invQty;
                      // element1.invQty = element1.qtyToFulFill;
                      element1.poQty = element1.qtyToFulFill - element.invQty;
                    }
                    else{
                      element1.assignQty = element.invQty;
                    }
                      
                    if(element1.isCustompart == false){
                      element1.SerialGroup = element1.SerialGroup;
                      element1.SerialGroupSelected = element1.SerialGroup.slice(0, element1.isCustompart == false ? (Number(element1.invQty) < Number(element1.qtyToFulFill) ? Number(element1.invQty) : Number(element1.qtyToFulFill)) : element1.quantity);
                      if(element1.SerialGroup != ""){
                        element1.SerialGroup = this.getCommon(element1.SerialGroup, element1.SerialGroupSelected);
                        var cArray = this.InvList.filter(x=>x.partId == element.Id);
                        if(cArray.length > 0){
                          cArray.forEach(elements => {
                            elements.SerialGroup = element1.SerialGroup;
                          });
                        }
                      }
                      element1.SerialGroup1 = element1.SerialGroup1;
                      element1.SerialGroupSelected1 = element1.SerialGroup1.slice(0, element1.isCustompart == false ? (Number(element1.invQty) < Number(element1.qtyToFulFill) ? Number(element1.invQty) : Number(element1.qtyToFulFill)) : element1.quantity);
                      if(element1.SerialGroup1 != ""){
                        element1.SerialGroup1 = this.getCommon(element1.SerialGroup1, element1.SerialGroupSelected1);
                      }
                      var cArray = this.InvList.filter(x=>x.partId == element.Id);
                      if(cArray.length > 0){
                        cArray.forEach(elements => {
                          elements.SerialGroup1 = element1.SerialGroup1;
                        });
                      }
                   
                    }
                  }
                  

                }
              });
        
            });
            this.InvListWithoutCustomPart = this.InvList.filter(x => (x.virtual == false || x.virtual == null) && x.customPartId == null);
            this.InvListWithoutCustomPart.forEach(partLst => {
              partLst.srNo = this.srNo;
              this.srNo += 1;
            });

            this.InvListCustomPart = this.InvList.filter(x => x.virtual == true);
            this.InvListCustomPart.forEach(partLst => {
              if (this.InvList.filter(x => x.customPartId == partLst.partId).length > 0) {
                partLst.srNo = this.srNo;
                partLst.isChecked = true;
                partLst.PartList = this.InvList.filter(x => x.customPartId == partLst.partId);
                partLst.visible = true;
                this.srNo += 1;
              }
            });
          
          }
      
        }
        this.rerender();
      }
    )

  }

  chngIsInv(event, opId) {
    const invdata = this.InvList.filter(x => x.opId == opId);
    if (invdata.length > 0) {
      if (event == true) {
        invdata[0].isChecked = true;
      }
      else {
        invdata[0].isChecked = false;
      }
    }
  }

  SaveConfirm(confirmSave) {
    if (this.InvList.length > 0) {
      this.modalService.open(confirmSave, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
    }
    else {
      $('#emptyInv').show();
    }
  }

  emptyInv() {
    $('#emptyInv').hide();
  }

  SaveAssignInv() {
    let invArr1 = this.InvList.filter(x => x.isChecked == true && x.isCustompart == false);
   
    let obj = {};
    obj['PartId'] = 0;
    obj['CustomerSo'] = 'abc';
    obj['OrderedQty'] = 0;
    obj['ShipQty'] = 0;
    obj['PartOrderId'] = 0;
    obj['ProductOrderId'] = 0;
    obj['CustomerRma'] = 'fgh';
    obj['ServiceNo'] = 'ab10';
    obj['PartLocation'] = 'US';
    obj['Reason'] = 'ABC'
    let invArr = this.InvList.filter(x => x.isChecked == true && x.isCustompart == false);
    for (var j = 0; j < invArr.length; j++) {

      let invObj = {};
      invObj["CompanyId"] = Number(this.value_search);
      invObj["Id"] = invArr[j].id;
      invObj["OpId"] = invArr[j].opId;
      invObj["OrderTypeId"] = invArr[j].orderTypeId;
      invObj["CustomerPONumber"] = invArr[j].customerPONumber;
      invObj["VendorId"] = invArr[j].vendorId;
      invObj["VendorName"] = invArr[j].vendorName;
      invObj["PartId"] = invArr[j].partId;
      invObj["Name"] = invArr[j].name;
      invObj["PartNo"] = invArr[j].partNo;
      invObj["VendorPartName"] = invArr[j].vendorPartName;
      invObj["VendorPartNumber"] = invArr[j].vendorPartNumber;
      invObj["InvQty"] = invArr[j].invQty;
      invObj["Quantity"] = invArr[j].quantity;
      invObj["QtyToFulFill"] = invArr[j].qtyToFulFill;
      invObj["AssignQty"] = invArr[j].assignQty;
      invObj["VendorPartCost"] = Number(invArr[j].vendorPartCost);
      invObj["Description"] = invArr[j].description;
      invObj["UnitofMeasureId"] = invArr[j].unitofMeasureId;
      invObj["CreatedBy"] = this.userId;
      invObj["BuyerId"] = this.userId;
      invObj["LoggedEmail"] = localStorage.getItem(this.constants.userEmail) == "" ? '' : localStorage.getItem(this.constants.userEmail);
      invObj["LoggedName"] = localStorage.getItem(this.constants.userName) == "" ? '' : localStorage.getItem(this.constants.userName);
      invObj["CustomPartId"] = invArr[j].customPartId;
      invObj["SelectedSerial"] = String(invArr[j].SerialGroupSelected);
      invObj["SelectedSerial1"] = String(invArr[j].SerialGroupSelected1);
      invObj["Location"] = String(invArr[j].location);
      invObj["LocationName"] = String(invArr[j].locationName);
      invObj["tracking"] = parseInt(invArr[j].tracking);
      
      this.InvDetails.push(invObj);
    }

    obj["AssignInventorytoSOs"] = this.InvDetails;

    this.IsUpdating = true;
    this.loader = true;
    this.UpdatingMsg = "Updating Inventory...";

    
    // this.sharedService.UpdateAssignInventorySO(obj)
    //   .subscribe(
    //     data => {
    //       this.IsUpdating = false;
    //       switch (data.statusCode) {
    //         case 200:
    //           this.UpdatePOsToCreateSOs();
    //           break;
    //         case 400:
    //           $("#wrong_msg").show();
    //           break;
    //         default:
    //           $("#wrong_msg").show();
    //       }
    //     }
    //   );

  }
  emptyInv1() {
    $('#emptyInv1').hide();
  }

  UpdatePOsToCreateSOs() {
    let obj = {};
    obj['PartId'] = 0;
    obj['CustomerSo'] = 'abc';
    obj['OrderedQty'] = 0;
    obj['ShipQty'] = 0;
    obj['PartOrderId'] = 0;
    obj['ProductOrderId'] = 0;
    obj['CustomerRma'] = 'fgh';
    obj['ServiceNo'] = 'ab10';
    obj['PartLocation'] = 'US';
    obj['Reason'] = 'ABC';

    this.InvDetails = [];

    let invArr = this.InvList;

    for (var j = 0; j < invArr.length; j++) {
      if ((invArr[j].virtual == false || invArr[j].virtual == null) && invArr[j].isChecked == false) {
        continue;
      }

      if (invArr[j].virtual == true) {
        const getCustomParts = this.InvList.filter(x => x.customPartId == invArr[j].partId && x.isChecked == true);
        if (getCustomParts.length == 0) {
          continue;
        }
      }

      let invObj = {};
      invObj["CompanyId"] = Number(this.value_search);
      invObj["Id"] = invArr[j].id;
      invObj["OpId"] = invArr[j].opId;
      invObj["OrderTypeId"] = invArr[j].orderTypeId;
      invObj["CustomerPONumber"] = invArr[j].customerPONumber;
      invObj["VendorId"] = invArr[j].vendorId;
      invObj["VendorName"] = invArr[j].vendorName;
      invObj["PartId"] = invArr[j].partId;
      invObj["Name"] = invArr[j].name;
      invObj["PartNo"] = invArr[j].partNo;
      invObj["VendorPartName"] = invArr[j].vendorPartName;
      invObj["VendorPartNumber"] = invArr[j].vendorPartNumber;
      invObj["InvQty"] = invArr[j].invQty;
      invObj["Quantity"] = invArr[j].quantity;
      invObj["QtyToFulFill"] = invArr[j].qtyToFulFill;
      invObj["AssignQty"] = invArr[j].poQty;

      invObj["VendorPartCost"] = Number(invArr[j].vendorPartCost);
      invObj["Description"] = invArr[j].description;
      invObj["UnitofMeasureId"] = invArr[j].unitofMeasureId;
      invObj["CreatedBy"] = this.userId;
      invObj["BuyerId"] = this.userId;
      invObj["LoggedEmail"] = localStorage.getItem(this.constants.userEmail) == "" ? '' : localStorage.getItem(this.constants.userEmail);
      invObj["LoggedName"] = localStorage.getItem(this.constants.userName) == "" ? '' : localStorage.getItem(this.constants.userName);
      invObj["Virtual"] = invArr[j].virtual;
      invObj["CustomPartId"] = invArr[j].customPartId;
      invObj["isCustompart"] = invArr[j].isCustompart;
      invObj["tracking"] = parseInt(invArr[j].tracking);
      this.InvDetails.push(invObj);
    }
    obj["POsToCreateSOsList"] = this.InvDetails;

    this.IsUpdating = true;
    this.UpdatingMsg = "Creating POs...";
    this.sharedService.UpdatePOsToCreateSOs(obj)
      .subscribe(
        data => {
          this.IsUpdating = false;
          this.loader = false;
          switch (data.statusCode) {
            case 200:
              this.buttonValue = this.constants.Save;
              this.resetvendorData();
              this.searchInv();
              this.rerender();
              $("#add_update").show();
              break;
            case 400:
              $("#wrong_msg").show();
              break;
            default:
              $("#wrong_msg").show();
          }
        }
      );
  }

  

  Getvendordropdownlist(companyId) {
    this.loader = true;
    if (companyId == undefined) { companyId = 0; }
    this.partService.Getvendordropdownlist(companyId).subscribe(
      data => {
        this.loader = false;
        this.data = data;
        if (companyId > 0) {
          this.vendorListDDL = this.data.dataList;
        }
        else {
          this.vendorSearch = '0';
        }
      }
    )
  }

  resetvendorData() {
    this.InvValidation = false;
    this.InvQty = '';
    this.InvType = '';
    this.InvList = [];
    this.InvDetails = [];
  }

  updateitem() {
    $("#add_update").hide();
  }

  ListDivShowHide() {
    if (this.ListDiv == true) {
      this.ListDiv = false;
      this.ListAngle = this.constants.DownArrow;
    }
    else if (this.ListDiv == false) {
      this.ListDiv = true;
      this.ListAngle = this.constants.UpArrow;
    }
  }

  Exit() {
    this.ListShow2 = false;
    this.ListShow = true;
    this.ListDiv = true;
    this.ListAngle = this.constants.UpArrow;
  }

  rerender(): void {
    this.dtElements.forEach((dtElement: DataTableDirective) => {
      dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
        dtInstance.destroy();
        this.dtTrigger.next('');
      });
    });
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
    this.dtTrigger.next('');
    this.settingDetailCSS = localStorage.getItem("settingDetailCSS");
    this.settingLogoImage = localStorage.getItem("settingImageLogo");
    this.SetSettingCSS(this.settingDetailCSS, this.settingLogoImage);
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  
  //ankit implemenation
  ErrorModal(title:string, message:string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }
}




