<section class="content-section pt-2">
    <div class="loading" *ngIf="loader">Loading&#8230;</div>


    <div col-sm-12>
        <div class="row">
            <div class="col-6 mb-2 d-flex align-items-baseline text-xs-left">
                <div class="page-title">
                    <div class="bread-crumb">
                        <a [routerLink]="['/adminDashboard/']">Home</a>
                        <span class="mx-1">-</span>
                        <span class="active">Production Jobs</span>
                        <div *ngIf="permissionMessage!=''" class='btn-danger text-center'>{{permissionMessage}} </div>
                    </div>
                </div>
            </div>
            <div class="col-6 mb-2 text-right align-items-baseline">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="white-box1">
                <div class="card-desc1">
                    <form [formGroup]="formSearch">
                        <div class="row detailSection">
                            <div class="col-md-12">
                                <div class="row">
                                    <!-- <div class="col-lg-2 col-md-6">
                                        <div class="d-flex align-items-center borderNone"
                                            [ngClass]="((value==0) && (SearchPOValidation || SearchDDLValidation)) ? 'validation_border': ''">
                                            <label class="customLabel mr-1" for="company">Company<span
                                                    style="color:red">*</span></label>
                                            <ngx-dropdown-treeview-select [config]="config"
                                                class="dropdown-company customInput-dropdown" [items]="userGroupObjects"
                                                [(value)]="value" (valueChange)="onValueChange($event)">
                                            </ngx-dropdown-treeview-select>
                                        </div>
                                    </div> -->
                                    <div class="col-lg-3 col-md-6 mt-md-0 mt-1 d-flex align-items-center">
                                        <label class="customLabel ws-nobreak mr-1" for="SO#">Sales Order #</label>
                                        <input type="text" placeholder="Enter Sales Order #" class="customInput POSO"
                                            [(ngModel)]="SoSearchTxt" formControlName="SoSearchTxt" autocomplete="off"
                                            required maxlength="12"
                                            [ngClass]="((!SoSearchTxt) && (SearchPOValidation)) ? 'validation_border': ''">
                                        <i class="fas fa-search cpointer ml-2" (click)="SearchBySONumber()"></i>
                                    </div>
                                    <div class="col-lg-3 col-md-6 mt-lg-0 mt-1 d-flex align-items-center">
                                        <label class="ortext mr-4">OR</label>
                                        <label class="customLabel mr-1" for="Production Status"
                                            style="width: 200px;">Production Status</label>
                                        <select [(ngModel)]="pStatusIdSearch" class="customInput"
                                            formControlName='pStatusIdSearch'
                                            [ngClass]="((!pStatusIdSearch) && (SearchDDLValidation)) ? 'validation_border': ''">
                                            <option value="">Select</option>
                                            <option *ngFor="let item of producitonStatusListSearch" [value]="item.id">
                                                {{item.name}}
                                            </option>
                                        </select>
                                        <i class="fas fa-search cpointer ml-2" (click)="SearchByPStatus()"></i>
                                    </div>
                                    <div class="col-lg-2 col-md-6 mt-lg-0 mt-1 d-flex align-items-center">
                                        <button class="btn blue-bg round-btn m-0" (click)="SearchAll()">Search
                                            All</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="white-box1" *ngIf="rolePermissionsList && rolePermissionsList[0].isView"
                [style.display]="ListShow==true ? 'block' : 'none'">
                <div class="white-box-title1">
                    <div class="customHeading mt-3 mb-2">
                        Production Jobs
                        <i class="fa fa-angle-{{ListAngle}} cpointer ml-2" aria-hidden="true"
                            (click)="ListDivShowHide()"></i>
                    </div>
                </div>
                <div class="card-desc1" [style.display]="ListDiv==true ? 'block' : 'none'">
                    <table class="row-border hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                        <thead>
                            <tr>
                                <th>Sales Order#</th>
                                <th>Est. Delivery Date</th>
                                <th>Customer</th>
                                <th>Status</th>
                                <th># of Alerts</th>
                                <th>Total Labor Hours</th>
                                <th>Labor Hours Completed</th>
                                <th>Labor Hrs. Remaining</th>
                                <th data-orderable="false">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>1234</td>
                                <td>Feb-8-2022</td>
                                <td>Adamson</td>
                                <td>Open</td>
                                <td>6</td>
                                <td>8</td>
                                <td>6</td>
                                <td>4</td>
                                <td>
                                    <a class="green-text"
                                        *ngIf="rolePermissionsList && rolePermissionsList[0].isUpdate"><i
                                            class="fas fa-edit" (click)="edit();"></i></a>
                                    <!-- <a class="red-text"
                                        *ngIf="rolePermissionsList && rolePermissionsList[0].isDelete"><i
                                            class="fas fa-trash-alt"></i></a> -->
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="white-box1" *ngIf="IsMainContent==true">
                <!-- <div class="customHeading mt-3">
                    Add Workflow
                </div> -->
                <div class="card-desc1">
                    <form [formGroup]="form">
                        <div class="row mt-2">
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                                <div class="row required">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Estimate Delivery Date</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="date" class="customInput" [(ngModel)]="estimateDeliveryDate"
                                            name="workflow" formControlName="estimateDeliveryDate" autocomplete="off"
                                            required maxlength="50"
                                            [ngClass]="((!form.controls.estimateDeliveryDate.valid) && (validation)) ? 'validation_border': ''">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                                <div class="row required">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Production Status</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <select [(ngModel)]="pStatusId" class="customInput" formControlName='pStatusId'
                                            [ngClass]="((!form.controls.pStatusId.valid) && (validation)) ? 'validation_border': ''">
                                            <option value="">Select</option>
                                            <option *ngFor="let item of producitonStatusList" [value]="item.id">
                                                {{item.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-2">
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                                <div class="row required">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Production Job Name</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput" [(ngModel)]="productionJobName"
                                            name="productionJobName" formControlName="productionJobName" placeholder="Enter Production Job Name">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                                <div class="row required">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Job Number</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput" [(ngModel)]="productionJobName"
                                            name="productionJobName" formControlName="productionJobName" placeholder="Enter Job Number">
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="row  mt-md-1">
                            <div class="col-md-12">
                                <div class="row">
                                    <div class="col-lg-2 col-md-4 trp">
                                        <label class="customLabel padding-r">Notes</label>
                                    </div>
                                    <div class="col-lg-10 col-md-8">
                                        <input type="text" class="customInput customDescription-parts"
                                            placeholder="Enter Notes" [(ngModel)]="notes" name="notes"
                                            formControlName='notes' autocomplete="off">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-2 mb-2">
                            <div class="col-md-12">
                                <table class="display table-bordered table clearfix order-detail mb-0 mt-2">
                                    <tr>
                                        <th style="font-weight:bold; width: 6%;">Task by Workflow</th>
                                        <th style="font-weight:bold; width: 6%;">Task Discription</th>
                                        <th style="font-weight:bold; width: 6%;">Notes</th>
                                        <th style="font-weight:bold; width: 6%;">Labor Hrs</th>
                                        <th style="font-weight:bold; width: 6%;">Skill Role</th>
                                        <th style="font-weight:bold; width: 6%;">Skill Level</th>
                                        <th style="font-weight:bold; width: 6%;">Technician</th>
                                        <th style="font-weight:bold; width: 6%;">Start Date</th>
                                        <th style="font-weight:bold; width: 6%;">End Date</th>
                                        <th style="font-weight:bold; width: 6%;">Alerts</th>
                                        <!-- <th style="font-weight:bold; width: 6%;">Item</th>
                                        <th style="font-weight:bold; width: 6%;">Vendor</th>
                                        <th style="font-weight:bold; width: 10%;">Part Qty</th>
                                        <th style="font-weight:bold; width: 10%;">Labor Hours</th>
                                        <th style="font-weight:bold; width: 15%;">Workflow Steps</th>
                                        <th style="font-weight:bold; width: 15%;">Skill Role</th>
                                        <th style="font-weight:bold; width: 12%;"> Skill Level</th>
                                        <th style="font-weight:bold; width: 10%;">Team Technician</th>
                                        <th style="font-weight:bold; width: 5%;">Start Date</th>
                                        <th style="font-weight:bold; width: 5%;">End Date</th>
                                        <th style="font-weight:bold; width: 5%;">Alerts</th> -->
                                    </tr>
                                    <tr>
                                        <td>WorkflowSteps</td>
                                        <td>Test</td>
                                        <td>Test</td>
                                        <td>2</td>
                                        <td>Admin</td>
                                        <td>3</td>
                                        <td>Tech 1</td>
                                        <td>Feb-8-2022</td>
                                        <td>Feb-10-2022</td>
                                        <td>4</td>
                                         
                                        <!-- <td>Item 1</td>
                                        <td>Adam</td>
                                        <td>5</td>
                                        <td>8</td>
                                        <td>1</td>
                                        <td>Admin</td>
                                        <td>4</td>
                                        <td>Tech 1</td>
                                        <td>Feb-8-2022</td>
                                        <td>Feb-10-2022</td>
                                        <td>3</td> -->
                                    </tr>
                                </table>
                            </div>
                        </div>
                        <div class="row mt-3">
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                                <div class="row required">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Workflow Steps</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <select [(ngModel)]="WorkflowSteps" class="customInput"
                                            formControlName='WorkflowSteps'
                                            [ngClass]="((!form.controls.WorkflowSteps.valid) && (validation)) ? 'validation_border': ''">
                                            <option value="">Select</option>
                                            <!-- <option *ngFor="let item of producitonStatusList" [value]="item.id">
                                                {{item.name}}
                                            </option> -->
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                                <div class="row required">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Skill Role</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <select [(ngModel)]="skillRole" class="customInput" formControlName='skillRole'
                                            [ngClass]="((!form.controls.skillRole.valid) && (validation)) ? 'validation_border': ''">
                                            <option value="">Select</option>
                                            <!-- <option *ngFor="let item of producitonStatusList" [value]="item.id">
                                                {{item.name}}
                                            </option> -->
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="submit-from text-right mt-1 p-0">
                            <button class="btn blue-bg round-btn"
                                *ngIf="rolePermissionsList && rolePermissionsList[0].isInsert"
                                (click)="addNewWorkflow()"><i class="fas fa-save"></i> {{buttonValue}}
                            </button>
                            <button class="btn darkred-bg round-btn" (click)="resetWorkflow()"
                                *ngIf="clearbutton==true"><i class="fas fa-redo"></i> Clear </button>
                            <button class="btn darkred-bg round-btn" (click)="WorkflowBack()"
                                *ngIf="clearbutton==false"><i class="fas fa" aria-hidden="true"></i>
                                Exit </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

</section>
<ng-template #content let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg alert_heading sweet-heading"></h4>
    <button class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body p-0">
        <div class="thank-you-pop">
            <img src="./assets/image/popup-alert.png" alt="">
            <h1>Confirmation</h1>
            <p class="mt-1 modal-para">{{deleteDetail}}</p>
        </div>
    </div>
    <div class="modal-footer sweet-footer text-center p-1">
        <button type="button" class="btn green-bg round-btn m-1"
            (click)="deleteWorkflow(deletedItem); c('Close click')">Yes</button>
        <button type="button" class="btn red-bg round-btn m-1" (click)="c('Close click')">No</button>
    </div>
</ng-template>


<ng-template #messageModal let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg alert_heading sweet-heading"></h4>
    <button class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body">
        <div class="thank-you-pop">
            <img src="{{messageModalImage}}" alt="{{messageModalHeading}}">
            <!-- <h1>{{messageModalHeading}}</h1> -->
            <p class="modal-para m-1">{{messageModalDescription}}</p>
        </div>
    </div>
    <div class="modal-footer sweet-footer text-center p-1">
        <button type="button" class="btn red-bg round-btn" (click)="c('Close click')"
            data-dismiss="modal">Close</button>
    </div>
</ng-template>