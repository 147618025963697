import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ConstantsService } from 'src/app/constants.service';
import { PartService } from 'src/app/services/part/part.service';
declare var $: any
declare var tinymce: any;
export interface partList {
  item: number,
  partNumber: number,
  partDescription: string,
  uom: string,
  invType: string,
  onHand: number,
  available: number,
  committed: number,
  backOrder: number,
  unitCost: string
}



const ELEMENT_DATA: partList[] = [
  { item: 1, partNumber: 1234156, partDescription: 'My Part Description', uom: 'ea.', invType: 'Part', onHand: 30, available: 5, committed: 25, backOrder: 100, unitCost: '0.12' },
  { item: 1, partNumber: 123658, partDescription: 'My Part Description', uom: 'ea.', invType: 'Part', onHand: 30, available: 5, committed: 25, backOrder: 100, unitCost: '0.12' },
]

@Component({
  selector: 'app-part-details',
  templateUrl: './part-details.component.html',
  styleUrls: ['./part-details.component.css']
})
export class PartDetailsComponent implements OnInit {
  loader: boolean = false;
  displayedColumns: string[] = ['item', 'partNumber', 'partDescription', 'uom', 'invType', 'onHand', 'available', 'committed', 'backOrder', 'unitCost'];
  dataSource = ELEMENT_DATA;
  showPartDetail: boolean = false;
  showPartList: boolean = false;
  partForm: FormGroup;
  
  //Trigger
  dropdownList = [];
  selectedItems = [];
  EmailConfigTrigger = [];
  dropdownSettings = {};
  shortDesc: any;
  ShortDescription: any;
  description: string;

  public files: any[] = [];
  imageUrl: any;

  constructor(
    private constants: ConstantsService,
    private partService: PartService
  ) { }

  ngOnInit(): void {
    this.loader = false;
    this.showPartList = true;
    
  }

  newPart(){
    this.showPartDetail = true;
    this.showPartList = false;
  }

  Cancel(){ }

  onItemSelect(item: any) {
    if (this.EmailConfigTrigger.length > 0) {
      const insertedData = this.EmailConfigTrigger.filter(x => x.item_id == item.item_id && x.IsActive == false);
      if (insertedData.length > 0) {
        insertedData[0].IsActive = true;
      }
      else {
        item.IsActive = true;
        this.EmailConfigTrigger.push(item);
      }
    }
    else {
      item.IsActive = true;
      this.EmailConfigTrigger.push(item);
    }
  }

  onItemDeSelect(item: any) {
    if (this.EmailConfigTrigger.length > 0) {
      this.EmailConfigTrigger.forEach((itemRemove, index) => {
        if (item.item_id === itemRemove.item_id) {
          itemRemove.IsActive = false;
        }
      });
    }
  }

  onSelectAll(items: any) {
    let oldEmailTriggerList = this.EmailConfigTrigger;
    this.EmailConfigTrigger = [];
    items.forEach((itemInner, index) => {
      if (oldEmailTriggerList.length > 0) {
        itemInner.Id = oldEmailTriggerList[index].Id;
      }
      itemInner.IsActive = true;
      this.EmailConfigTrigger.push(itemInner);
    });
  }

  onDeSelectAll(items: any) {
    if (this.EmailConfigTrigger.length > 0) {
      this.EmailConfigTrigger.forEach((itemInner, index) => {
        itemInner.IsActive = false;
      });
    }
  }

  GetAPIKey() {
    return this.constants.EditorKeyTinyMCE
  }

  getStrDesc(event: any) {
    const shortDescrip = tinymce.get('shortDescription').getContent();
    let arr = [];
    if (shortDescrip != null && shortDescrip.includes("<img")) {
      const imgData = shortDescrip.split("<p>");
      for (let i = 0; i < imgData.length; i++) {
        if (imgData[i].includes("<img") && imgData[i] != null && imgData[i] != undefined)
          arr.push(imgData[i])
      }
      this.partService.callImageBlobApi(arr).subscribe((data: any) => {
        if (data != null) {
          let count = 0;
          for (let i = 0; i < imgData.length; i++) {
            if (imgData[i].includes("<img") && imgData[i].includes(".blob.")) {
              imgData[i] = data[count];
              count++;
            } else if (imgData[i].includes("<img") && imgData[i].includes(".blob.") == false) {
              imgData[i] = "<img src='" + data[count] + "'></p>";
              count++;
            }
          }
          this.shortDesc = imgData.join('<p>');
        }
      })
    } else {
      this.ShortDescription = shortDescrip;
    }
  }

  getDtlDesc(event: any) {
    const detaildesc = tinymce.get('detailDescription').getContent();
    let arr1 = [];
    let imgData1 = [];
    if (detaildesc != null && detaildesc.includes("<img")) {
      imgData1 = detaildesc.split("<p>")
      for (let i = 0; i < imgData1.length; i++) {
        if (imgData1[i].includes("<img") && imgData1[i] != null && imgData1[i] != undefined)
          arr1.push(imgData1[i])
      }
      this.partService.callImageBlobApi(arr1).subscribe((data: any) => {
        if (data != null) {
          let count1 = 0;
          for (let i = 0; i < imgData1.length; i++) {
            if (imgData1[i].includes("<img") && imgData1[i].includes(".blob.")) {
              imgData1[i] = data[count1];
              count1++;
            } else if (imgData1[i].includes("<img") && imgData1[i].includes(".blob.") == false) {
              imgData1[i] = "<img src='" + data[count1] + "'></p>";
              count1++;
            }
          }
          this.description = imgData1.join('<p>');
        }
      })
    } else {
      this.description = detaildesc;
    }
  }


  onFileChange(pFileList: any) {
    this.files = Object.keys(pFileList).map(key => pFileList[key]);
    let fileToUpload = this.files[0];
    var reader = new FileReader()
    reader.onload = (event: any) => {
      this.imageUrl = event.target.result;
    }
    reader.readAsDataURL(fileToUpload);
  }
}


