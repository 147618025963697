import { Component, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ConstantsService } from 'src/app/constants.service';
import { SharedService } from 'src/app/services/shared.service';
import { runInThisContext } from 'vm';

@Component({
  selector: 'app-dashboardlist',
  templateUrl: './dashboardlist.component.html',
  styleUrls: ['./dashboardlist.component.css']
})
export class DashboardlistComponent implements OnInit {

  companyId: any;
  tableList: any;
  listHeading: any;
  isPoList: boolean = false;
  isSoList: boolean = false;

  totalPartCost: number = 0;
  totalPartPrice: number = 0;
  totalPartPriceExt: number = 0;
  totalLaborHrs: number = 0;
  totalLaborExt: number = 0;
  totalPartDisc: number = 0;
  totalLaborDisc: number = 0;
  totalPartSaleTax: number = 0;
  totalLaborSaleTax: number = 0;
  gTotal: number = 0;
  poGTotal: number = 0;
  partsMargin: number = 0;
  discParts: number = 0;
  discLabor: number = 0;

  loader: boolean = false;
  rolePermissionsList: any;
  permissionMessage = '';
  data: any;
  settingDetailCSS: any;
  settingLogoImage: any;

  /*Datatable Property*/
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;


  constructor(private router: Router,
    private route: ActivatedRoute,
    public sharedService: SharedService,
    private constants: ConstantsService) { }

  ngOnInit(): void {
    //Permission Section

    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);
    }
    this.rolePermissionsList = JSON.parse(localStorage.getItem('rolePermissionsList'));
    this.rolePermissionsList = this.rolePermissionsList.filter(x => x.pageId == 1022);
    this.permissionMessage = '';
    if (this.rolePermissionsList != null && this.rolePermissionsList != undefined) {
      if (!this.rolePermissionsList[0].isView) {
        this.permissionMessage += 'view';
      } if (!this.rolePermissionsList[0].isInsert) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'add';
      } if (!this.rolePermissionsList[0].isUpdate) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'edit';
      } if (!this.rolePermissionsList[0].isDelete) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'delete';
      }
    }
    if (this.permissionMessage != '') {
      this.permissionMessage = 'You do not have access to ' + this.permissionMessage + '. Please contact to administrator!';
    }
    //End
    this.companyId = sessionStorage.getItem("CompanyId");

    this.dtOptions = {
      pagingType: this.constants.full_numbers,
      pageLength: 10,
      ordering: false
    };

    if (this.route.snapshot.params['type']) {
      const GetType = this.constants.WidgetQuery.filter(x => x.id == this.route.snapshot.params['type']);
      this.listHeading = GetType[0].name;
      sessionStorage.setItem('breadcrumd', this.listHeading);
      switch (GetType[0].type) {
        case 'po':
          this.GetOpDashboardList(this.companyId, this.constants.IssuedPO, GetType[0].id);
          break;
        case 'so':
          this.GetCooDashboardList(this.companyId, GetType[0].id);
          break
      }
    }
  }

  GetOpDashboardList(companyId, statusId, type) {
    this.isPoList = true;
    this.isSoList = false;
    this.tableList = null;
    this.loader = true;
    this.sharedService.GetOpDashboardList(companyId, statusId, type).subscribe(
      data => {
        this.data = data;
        this.tableList = this.data.dataList;
        if (this.tableList) {
          this.tableList.forEach((item, index) => {
            this.poGTotal += Number(item.poTotal);
          });
        }
        this.loader = false;
        this.rerender();
      }
    )
  }

  GetCooDashboardList(companyId, type) {
    this.isSoList = true;
    this.isPoList = false;
    this.tableList = null;
    this.loader = true;

    this.sharedService.GetCooDashboardList(companyId, type).subscribe(
      data => {
        this.data = data;
        this.tableList = this.data.dataList;  
        if (this.tableList) {
          this.tableList.forEach((item, index) => {
            this.gTotal += Number(item.totalAmount);
          });
        }

        this.partsMargin = Number(this.totalPartCost) / Number(this.totalPartPriceExt);
        this.discParts = Number(this.totalPartDisc) / Number(this.totalPartPriceExt);
        this.discLabor = Number(this.totalLaborDisc) / Number(this.totalLaborExt);

        this.loader = false;
        this.rerender();
      }
    )
  }

  redirect(poNumber) {
    this.router.navigate(['/purchasorder/' + poNumber + '/' + this.companyId + '/' + this.route.snapshot.params['type'] + '/'], { skipLocationChange: true });
  }

  redirectPartSo(soNumber, orderTypeId) {
    this.router.navigate(['/orders/' + soNumber + '/' + orderTypeId + '/' + this.route.snapshot.params['type'] + '/']);
  }
  
  SetSettingCSS(settingCSS, logoImagePath) {
    if (settingCSS != null) {
      let headerCss = document.getElementsByClassName(this.constants.CssHealerClass) as HTMLCollectionOf<HTMLElement>;
      if (headerCss.length != 0) {
        headerCss[0].style.backgroundColor = settingCSS.split(',')[0];
      }
      let saveButtonCss = document.getElementsByClassName(this.constants.CssSaveBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (saveButtonCss.length != 0) {
        saveButtonCss[0].style.backgroundColor = settingCSS.split(',')[1];
        $(".blue-bg").css("background", settingCSS.split(',')[1]);
      }
      let cencelButtonCss = document.getElementsByClassName(this.constants.CssCancelBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (cencelButtonCss.length != 0) {
        cencelButtonCss[0].style.backgroundColor = settingCSS.split(',')[2];
        $(".darkred-bg").css("background", settingCSS.split(',')[2]);
      }
    }

    if (logoImagePath != null && logoImagePath != "") {
      (document.getElementById('hrdlogoImage') as HTMLImageElement).src = logoImagePath;
    }
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next('');
    this.settingDetailCSS = localStorage.getItem("settingDetailCSS");
    this.settingLogoImage = localStorage.getItem("settingImageLogo");
    this.SetSettingCSS(this.settingDetailCSS, this.settingLogoImage);
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next('');
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  back() {
    this.router.navigate(['/adminDashboard/']);
  }

}
