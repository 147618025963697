<section class="content-section">
  <div class="loading" *ngIf="loader">Loading&#8230;</div>
  <div class="page-title mb-4">
    <!-- <h2>Order Detail</h2> -->
    <div class="bread-crumb d-flex justify-content-between align-items-baseline">
      <div class="breadWrap">
        <a [routerLink]="['/adminDashboard/']">Home</a>
        <span class="mx-1">-</span>
        <span class="active">Order</span>
      </div>

      <div class="row">
        <div class="col-12" *ngIf="ExportEnable == true">
          <select class="customInput" (change)="ExportType($event)" id="exportDrodown">
            <option value="" selected>Select Export Type</option>
            <option value="1" *ngIf="rolePermissionsList[0].isExportPdf">
              Export to PDF
            </option>
            <option value="2" *ngIf="rolePermissionsList[0].isExportWord">
              Export to Word
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="white-box1 no_bg1" [style.display]="ListShow1 == true ? 'block' : 'none'">
    <div class="white-box-title1">
      <div class="row">
        <!-- <div class="col-md-12">
          <div class="row mt-1 m-auto">
            <div class="col-lg-3 col-md-6 pl-0">
              <div class="row mb-1">
                <div class="col-sm-4 trp required">
                  <label class="customLabel">Company</label>
                </div>
  
                <div class="col-sm-8">
                  <ngx-dropdown-treeview-select class="dropdown-company customInput-dropdown" id="dropdownfocus"
                    [config]="config" [items]="userGroupObjects" [(value)]="value"
                    [ngClass]="((value==0) && (uservalidation)) ? 'validation_borders': ''"
                    (valueChange)="onValueChangeCompany($event)">
                  </ngx-dropdown-treeview-select>
                </div>
              </div>
            </div>
          </div>
        </div> -->

        <div class="col-md-12">
          <div class="customHeading mt-1 mb-1">
            Order List
            <span id="expand-collapse">
              <i class="fa fa-angle-down cpointer" aria-hidden="true" (click)="ListDivShowHide()"></i>
            </span>
          </div>
        </div>

      </div>
    </div>

    <div class="card-desc table-responsive mb-3" [style.display]="ListDiv1 == true ? 'block' : 'none'">
      <table id="datatable1" class="display table table-responsive clearfix" datatable [dtOptions]="dtOptions2"
        [dtTrigger]="dtTrigger2">
        <thead>
          <tr>
            <th hidden></th>
            <th style="display: contents !important"></th>
            <th (click)="SortTableHeader('companyName1', 'orderlist')">
              Company<span id="companyName1" class="sorting2"></span>
            </th>
            <th (click)="SortTableHeader('customerPONumber', 'orderlist')">
              Order No.<span id="customerPONumber" class="sorting2"></span>
            </th>
            <th (click)="SortTableHeader('isEcommOrder', 'orderlist')">
              eComm<span id="isEcommOrder" class="sorting2"></span>
            </th>
            <th (click)="SortTableHeader('statusId', 'orderlist')">
              Status<span id="statusId" class="sorting2"></span>
            </th>
            <th (click)="SortTableHeader('customerFirstName', 'orderlist')">
              Contact Name<span id="customerFirstName" class="sorting2"></span>
            </th>
            <th (click)="SortTableHeader('customerName', 'orderlist')">
              Customer Name<span id="customerName" class="sorting2"></span>
            </th>
            <th (click)="SortTableHeader('partProductName', 'orderlist')">
              Product Name<span id="partProductName" class="sorting2"></span>
            </th>
            <th (click)="SortTableHeader('orderDate', 'orderlist')">
              Order Date<span id="orderDate" class="sorting2"></span>
            </th>
            <th (click)="SortTableHeader('orderType', 'orderlist')">
              Part/Product<span id="orderType" class="sorting2"></span>
            </th>
            <th (click)="SortTableHeader('partialship', 'orderlist')">
              Partial Shipment<span id="partialship" class="sorting2"></span>
            </th>

            <th>Action</th>
          </tr>
        </thead>
        <tbody *ngIf="orderList?.length != 0">
          <tr *ngFor="let data of orderList">
            <td style="display: contents !important"></td>
            <td>{{ data.companyName }}</td>
            <td>
              {{
              data.customerPONumber == null
              ? "-------"
              : data.customerPONumber
              }}
            </td>
            <td>
              <span *ngIf="data.isEcommOrder == true">{{
                data.eOrderNo == null ? "" : data.eOrderNo
                }}</span>
            </td>
            <!-- <span  [(ngModel)]="data.discount"  id="customerAddDiscount"></span> -->
            <!-- <td><span *ngIf="data.isEcommOrder==true"><i class="fa fa-check text-success" aria-hidden="true"></i></span></td> -->
            <td>
              {{
              data.statusId == 1
              ? "Open"
              : data.statusId == 2
              ? "Sale Quote"
              : data.statusId == 3
              ? "Cancel"
              : data.statusId == 5
              ? "Complete"
              : data.statusId == 6
              ? "Processing"
              : data.statusId == 7
              ? "Shipped"
              : data.statusId == 8
              ? "On Hold"
              : data.statusId == 9
              ? "In Production"
              : ""
              }}
            </td>
            <td>{{ data.customerFirstName }} {{ data.customerLastName }}</td>
            <td>{{ data.customerName }}</td>
            <td>{{ data.partProductName }}</td>
            <td>{{ data.orderDate | date : "MMM-dd-yyyy" }}</td>
            <td>{{ data.orderType }}</td>
            <td>
              <span *ngIf="data.partialShipment == true" class="ml-2"><i class="fa fa-check text-success"
                  aria-hidden="true"></i></span>
            </td>

            <td>
              <a *ngIf="rolePermissionsList && rolePermissionsList[0].isView" (click)="
                  PreviewOrder(
                    data.id,
                    data.orderTypeId,
                    '',
                    data.customerId,
                    data.orderType
                  )
                " class="green-text"><i class="fas fa-edit"></i></a>
              <a *ngIf="rolePermissionsList && rolePermissionsList[0].isCopy"
                (click)="CopyOrder(data.id, data.companyId)" class="green-text"><i class="fas fa-copy"></i></a>
              <a *ngIf="rolePermissionsList && rolePermissionsList[0].isDelete" (click)="deletePopupPart(data.id)"
                class="red-text"><i class="fas fa-trash-alt"></i></a>
            </td>
          </tr>
        </tbody>
        <tbody *ngIf="orderList == null">
          <tr class="c1">
            <td colspan="10" class="no-data-available">
              No data available in table
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="row">
    <div class="col-sm-12">
      <div class="white-box1">
        <div class="white-box-title">
          <div class="row">
            <div class="col-sm-2">
              <p class="customLabel">
                <b class="light-color-text">Product : </b>Custom Parts
              </p>
            </div>
            <div class="col-sm-2">
              <p class="customLabel">
                <b class="light-color-text">Customer Name : </b>{{ customerName }}
              </p>
            </div>

            <div class="col-sm-2">
              <span class="position-relative">
                <p (click)="multiContactData()" class="customLabel tooltipWrap tooltip-icon d-flex">
                  <span class="tooltipWrap tooltip-icon mr-1">
                    <i class="fa fa-info-circle"></i></span>
                  <span class="tooltips">
                    Email : {{ email ? email : "N/A" }}<br />
                    Phone : {{ contactPhone1 ? contactPhone1 : "N/A" }}<br />
                    Address : {{ address1 ? address1 : "N/A" }}<br />
                  </span>

                  <b class="light-color-text">Contact Name : </b><span class="aLink" style="cursor: pointer">{{
                    contactNameSO
                    }}</span>
                </p>
              </span>
            </div>

            <div class="col-sm-2">
              <p class="customLabel">
                <b class="light-color-text">Sales order number : </b>{{
                displaysaleorder == null
                ? "N/A"
                : displaysaleorder == undefined
                ? "N/A"
                : displaysaleorder == ""
                ? "N/A"
                : displaysaleorder
                }}
              </p>
            </div>

            <div class="col-sm-2">
              <p class="customLabel">
                <b class="light-color-text">Status : </b>{{ statusname == null ? "N/A" : statusname }}
              </p>
            </div>
            <div class="col-sm-2">
              <p class="customLabel">
                <b class="light-color-text" for="partialProp">Partial Shipment :
                </b>
                <input [(ngModel)]="partialProp" type="checkbox" [checked]="partialProp" id="partialProp" />
              </p>
            </div>
            <div class="col-sm-4 d-flex" *ngIf="isEcoomUser == true">
              <p class="customLabel">
                <b class="light-color-text">Payment Method :</b>
                {{ paymentStatus }}
              </p>
              <!-- <img src="./assets/image/card-visa.png" alt="card-visa" class="wimage" /> -->

              <!-- <span class="spinimage">****</span>&nbsp;<span class="customLabel">
                {{ CreditNumber }}</span> -->
            </div>
          </div>
        </div>
      </div>

      <div class="white-box1 col-md-12 mb-1 pb-0 mt-3">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12">
                <div class="row">
                  <div class="col-lg-3 col-md-6 pl-0" *ngIf="IsEnableAprrovel == true">
                    <div class="form-group row mb-1">
                      <div class="col-sm-4">
                        <label class="customLabel">Order Status</label>
                      </div>
                      <div class="col-sm-8">
                        <select class="form-control1 customInput" name="statusId" id="statusvalues"
                          [(ngModel)]="statusDropdownList" (change)="statusvalues($event)">
                          <option *ngFor="let item of statuslist" [value]="item.id">
                            {{ item.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-6 pl-0">
                    <div class="form-group row mb-0">
                      <div class="col-sm-4">
                        <label class="customLabel">Draft Date</label>
                      </div>
                      <div class="col-sm-8">
                        <input type="date" class="customInput" [(ngModel)]="SalesDraftDate" min="{{ PopupinDate }}" />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-6 pl-0">
                    <div class="form-group row mb-0">
                      <div class="col-lg-4 col-md-2 trp">
                        <label class="customLabel sidestatus">Quote Date</label>
                      </div>
                      <div class="col-lg-8 col-md-10">
                        <input type="date" class="customInput" [(ngModel)]="SalesQuoteDate" min="{{ PopupinDate }}" />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-6 pl-0">
                    <div class="form-group row mb-0">
                      <div class="col-lg-4 col-md-2 trp">
                        <label class="customLabel"> Order Date</label>
                      </div>
                      <div class="col-lg-8 col-md-10">
                        <input type="date" class="customInput" [(ngModel)]="SalesOrderDate" min="{{ PopupinDate }}" />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-6 pl-0">
                    <div class="form-group row mb-0">
                      <div class="col-lg-4 col-md-2 trp">
                        <label class="customLabel"> Net Terms</label>
                      </div>
                      <div class="col-lg-8 col-md-10">
                        <select class="customInput" #NetTermsId [(ngModel)]="NetTerms" [disabled]="netfadeOut"
                          (change)="onNetTermsChange(NetTermsId.value)">
                          <option value="">Net Terms</option>
                          <option *ngFor="let item of NetTermsList" [value]="item.name">
                            {{ item.name }}
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-6 pl-0">
                    <div class="form-group row mb-0">
                      <div class="col-lg-4 col-md-2 trp">
                        <label class="customLabel" for="PO">Purchase Order</label>
                      </div>
                      <div class="col-lg-8 col-md-10">
                        <input [(ngModel)]="PurchaseOrder" type="text" class="customInput" id="PurchaseOrder"
                          placeholder="Enter Purchase Order" />
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-md-6 pl-0">
                    <div class="form-group row mb-0">
                      <div class="col-lg-4 col-md-2 trp">
                        <label class="customLabel" for="nonTaxable">Taxable</label>
                      </div>
                      <div class="col-lg-8 col-md-10">
                        <input [(ngModel)]="nonTaxable" type="checkbox" [disabled]="taxfadeOut" id="nonTaxable"
                          (change)="changeTaxable($event)" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-12 p-0 mt-2">
            <div class="customHeading mt-1">Customer Information:
              <label class="customLabel mx-2">New Customer</label>
              <input [checked]="controlCustomerState"
                class="p_relative custom_checkbox ml-2 ng-untouched ng-pristine ng-valid" type="checkbox"
                ng-reflect-model="false" (click)="getCustomerState($event)" />
            </div>
          </div>
          <div class="col-md-12">
            <div class="row" *ngIf="controlCustomerState === false">
              <div class="col-lg-3 col-md-6 pl-0">
                <div class="form-group row mb-1">
                  <div class="col-sm-4 required">
                    <label class="customLabel">Customer</label>
                  </div>

                  <div class="col-sm-8 ng-untouched ng-pristine ng-valid">
                    <app-customer-dropdown [_selectedCustomer]="selectedCustomerValue"
                      (_newItemEvent)="existingUser($event)"></app-customer-dropdown>
                    <!-- <select class="customInput custName" name="customerId" id="customerId"
                      [ngClass]="((!selectedCustomerValue) && (uservalidation)) ? 'validation_border': ''"
                      [(ngModel)]="selectedCustomerValue" (change)="existingUser($event)" [disabled]="orderId>0">
                      <option value="">Select Customer</option>
                      <option *ngFor="let item of customerData" [value]="item.id">
                        {{ item.customerName }}
                      </option>
                    </select> -->
                  </div>
                </div>
              </div>
            </div>
          </div>


          <form [formGroup]="customerForm">
            <div class="col-md-12" *ngIf="controlCustomerState === true">
              <div class="row mt-1">
                <div class="col-lg-12">
                  <div class="row">
                    <div class="col-lg-3 pl-0">
                      <div class="form-group row mb-0">
                        <div class="col-sm-4">
                          <label for="bname" class="customLabel required">Business Name</label>
                        </div>
                        <div class="col-sm-8">
                          <input type="text" class="customInput" [(ngModel)]="businessName" placeholder="Business Name"
                            id="bname" name="bname" formControlName="businessName" autocomplete="off" maxlength="50"
                            (keypress)="omit_special_char($event)">


                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 pl-0">
                      <div class="form-group row mb-0">
                        <div class="col-sm-3 required">
                          <label for="fname" class="customLabel">First Name</label>
                        </div>

                        <div class="col-sm-8">

                          <input type="text" class="customInput" [(ngModel)]="firstNameCustomer"
                            placeholder="Enter First Name" name="userfirstName" formControlName="firstNameCustomer"
                            autocomplete="off" maxlength="50" (keypress)="omit_special_char($event)" [ngClass]="
                            ((!firstNameCustomer) &&
                            (uservalidation))
                              ? 'validation_border'
                              : ''
                          " />

                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 pl-0">
                      <div class="form-group row mb-0">
                        <div class="col-sm-3 required">
                          <label for="lname" class="customLabel">Last Name</label>
                        </div>
                        <div class="col-sm-8">
                          <input type="text" class="customInput" [(ngModel)]="lastNameCustomer"
                            placeholder="Enter Last Name" id="lname" name="lname" formControlName="lastNameCustomer"
                            autocomplete="off" maxlength="50" (keypress)="omit_special_char($event)" [ngClass]="
                           ((!lastNameCustomer) &&
                            (uservalidation))
                              ? 'validation_border'
                              : ''
                          " />
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 pl-0">
                      <div class="form-group row mb-0">
                        <div class="col-sm-2 trp required">
                          <label for="email" class="customLabel">Email</label>
                        </div>
                        <div class="col-sm-10">
                          <input type="text" class="customInput EmailValidation"
                            (keypress)="RemovedEmailMessage($event.target)" [(ngModel)]="customerEmail" required
                            placeholder="Enter Email" name="Email" formControlName="customerEmail" autocomplete="off"
                            pattern="[0-9a-zA-Z._%+-]+@[0-9a-zA-Z.-]+\.[a-zA-Z]{2,3}$" maxlength="50" [ngClass]="
                           ((!customerForm.controls.customerEmail.valid) && (uservalidation))
                              ? 'validation_border'
                              : ''
                          " />
                          <div *ngIf="(customerForm.controls.customerEmail.pattern)" class="text-danger input-error">
                            Enter Valid Email.
                          </div>
                          <div *ngIf="((customerForm.controls.customerEmail.valid) && (recordAlreadyExist))"
                            class="text-danger input-error">
                            Email Already Exist.
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-3 pl-0 mt-1">
                      <div class="form-group row mb-0">
                        <div class="col-sm-4 required">
                          <label for="phone" class="customLabel">Phone</label>
                        </div>
                        <div class="col-sm-8">
                          <input type="text" class="customInput PhoneValidation"
                            (keypress)="validatePhoneNo($event.target)" [(ngModel)]="contactPhone"
                            placeholder="(xxx)xxx-xxx" name="contactPhone" formControlName="contactPhone"
                            autocomplete="disabled" maxlength="14"
                            pattern="^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$" [ngClass]="
                            ((!contactPhone) && (uservalidation))
                              ? 'validation_border'
                              : ''
                          " />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div class="col-lg-12 p-0 mt-2 mb-1">
              <div class="customHeading mt-1">Ship To Address:
                <select class="customInput custName" style="width: 235px; margin-left: -4px;" name="ShippingTypeDrp"
                  id="ShippingTypeDrp" [(ngModel)]="ShippingTypeDrp" formControlName="ShippingTypeDrp"
                  (change)="CallShipDip($event.target.value)">
                  <option value="">Select ShippingType</option>
                  <option *ngFor="let item of ShipTypeDList" [value]="item.id" [disabled]="controlCustomerState">
                    {{ item.name }}
                  </option>
                </select>
              </div>
            </div>

            <div class="col-md-12">
              <div class="row">
                <div class="col-md-6">
                  <div class="row mt-1">
                    <div class="col-md-6 pl-0">
                      <div class="form-group row mb-1">
                        <div class="col-sm-4">
                          <label class="customLabel">Customer Name</label>
                        </div>
                        <div class="col-sm-8">
                          <input type="text" class="customInput" placeholder="Enter Customer Name"
                            formControlName="ShipCustomerName" [readonly]="ShippingTypeDrp==2" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 pl-0">
                      <div class="form-group row mb-0">
                        <div class="col-sm-3">
                          <label class="customLabel">City</label>
                        </div>
                        <div class="col-sm-9">
                          <input type="text" class="customInput" placeholder="City" [(ngModel)]="ShipCity"
                            autocomplete="off" formControlName="ShipCity" (change)="onChangeShippingAddress()" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 pl-0">
                      <div class="form-group row mb-1">
                        <div class="col-sm-4">
                          <label class="customLabel">Attention</label>
                        </div>
                        <div class="col-sm-8">
                          <input type="text" class="customInput" placeholder="Enter Attention"
                            formControlName="ShipAttention" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 pl-0">
                      <div class="form-group row mb-0">
                        <div class="col-sm-3 required">
                          <label class="customLabel">State</label>
                        </div>
                        <div class="col-sm-9">
                          <div class="row">
                            <div class="col-sm-6">
                              <select class="customInput pl-1px" [(ngModel)]="ShipState"
                                (change)="changeStateContact($event);onChangeShippingAddress();"
                                formControlName="ShipState" [ngClass]="
                              ((!ShipState) && (uservalidation))
                                ? 'validation_border'
                                : ''
                              ">
                                <option value="">Select State</option>
                                <option *ngFor="let item of stateData" [value]="item.id">
                                  {{item.name}}
                                </option>
                              </select>
                            </div>
                            <div class="col-sm-6">
                              <div class="form-group row mb-0">
                                <div class="col-sm-3 required">
                                  <label class="customLabel">Zip</label>
                                </div>

                                <div class="col-sm-9">
                                  <div *ngIf="(ShipCountry==231)">
                                    <input type="text" class="customInput NumberOnly" [(ngModel)]="ShipZip"
                                      placeholder="Enter Zip xxxxx-xxxx" name="ShipZip" pattern="\d{5}-?(\d{4})?"
                                      (keypress)="validateZipcode($event.target)" formControlName="ShipZip"
                                      autocomplete="disabled" maxlength="10"
                                      [ngClass]="((!ShipZip) && (uservalidation))? 'validation_border': ''"
                                      (change)="onChangeShippingAddress()" />
                                    <div *ngIf="
                                          !customerForm.controls.ShipZip.valid &&
                                          customerForm.controls.ShipZip.value != '' &&
                                          customerForm.controls.ShipZip.value != undefined &&
                                          uservalidation
                                        " class="text-danger input-error">
                                      Enter Valid Zip.eg-xxxxx-xxxx
                                    </div>
                                  </div>
                                  <div *ngIf="(ShipCountry!='231')">
                                    <input type="text" class="customInput" [(ngModel)]="ShipZip"
                                      placeholder="Enter Zip xxxxx-xxxx" name="ShipZip" formControlName="ShipZip"
                                      autocomplete="disabled" maxlength="10" (change)="onChangeShippingAddress()" />
                                  </div>

                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="col-md-6 pl-0">
                      <div class="form-group row mb-1">
                        <div class="col-sm-4 required">
                          <label class="customLabel">Address 1</label>
                        </div>

                        <div class="col-sm-8">
                          <input type="text" class="customInput" placeholder="Enter Address Line 1 " maxlength="100"
                            [(ngModel)]="ShipAddress1" name="ShipAddress1" autocomplete="off" autocomplete="disabled"
                            formControlName="ShipAddress1"
                            [ngClass]="((!ShipAddress1) && (uservalidation))? 'validation_border': ''"
                            (change)="onChangeShippingAddress()" />
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 pl-0">
                      <div class="form-group row mb-0">
                        <div class="col-sm-3">
                          <label class="customLabel">Country</label>
                        </div>
                        <div class="col-sm-9">
                          <select class="customInput pl-1px" (change)="changeCountryContact($event)"
                            [(ngModel)]="ShipCountry" formControlName="ShipCountry" [ngClass]="
                            ((!ShipCountry) && (uservalidation))
                              ? 'validation_border'
                              : ''
                          ">
                            <option value="">Select Country</option>
                            <option *ngFor="let item of countryDataContact" [value]="item.id">
                              {{item.name}}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6 pl-0">
                      <div class="form-group row mb-0">
                        <div class="col-sm-4">
                          <label class="customLabel">Address 2</label>
                        </div>

                        <div class="col-sm-8">
                          <input type="text" class="customInput" placeholder="Enter Address Line 2"
                            [(ngModel)]="ShipAddress2" autocomplete="off" formControlName="ShipAddress2" />
                        </div>
                      </div>
                    </div>

                    <div class="col-sm-6 pl-0 text-right">
                      <button [ngClass]="togglevalidAddBtn==true?'btn grey-bg':'btn darkred-bg round-btn'"
                        (click)="onValidateAddress()" [disabled]="togglevalidAddBtn">Validate Address</button>
                    </div>




                  </div>
                </div>
                <div class="col-md-6">
                  <div class="row mt-1">
                    <div class="col-md-12 pl-0">
                      <div class="form-group row mb-0">
                        <!-- <div class="col-sm-6">
                          <input formControlName="IsCollectAmount" [(ngModel)]="IsCollectAmount"
                          class="custom_checkbox mr-2 mt-auto mb-auto" type="checkbox" ng-reflect-model="false"/>
                          <label class="customLabel">Collect Account</label>
                        </div> -->
                        <div class="col-sm-3">
                          <label class="customLabel">Collect Shipping Account</label>
                        </div>
                        <div class="col-sm-4">
                          <select class="customInput" formControlName="CollectAccount" [(ngModel)]="CollectAccount"
                            (change)="onCollectAcctChange()">
                            <option value="">None</option>
                            <option *ngFor="let item of CollectAccountList" [value]="item.value">
                              {{ item.name }} - {{ item.value }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 pl-0 mt-1">
                      <div class="form-group row mb-0">
                        <div class="col-sm-3">
                          <label class="customLabel">Shipping Type</label>
                        </div>
                        <div class="col-sm-4">
                          <select class="customInput" [(ngModel)]="ShippingType" (change)="onValueChange()"
                            [ngModelOptions]="{standalone: true}">
                            <option value="">Select Shipping Type</option>
                            <option *ngFor="let item of ShippingTypeList" [value]="item.id">
                              {{ item.name }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-12 pl-0 mt-1">
                      <div class="form-group row mb-1">
                        <div class="col-sm-3">
                          <label class="customLabel">Notes for Customer</label>
                        </div>
                        <div class="col-sm-9">
                          <input type="text" class="customInput" placeholder="Notes for Customer"
                            formControlName="ShipCustomerNotes" />
                        </div>
                      </div>
                    </div>

                    <div class="col-md-12 pl-0">
                      <div class="form-group row mb-1">
                        <div class="col-sm-3">
                          <label class="customLabel">Notes for Warehouse</label>
                        </div>
                        <div class="col-sm-9">
                          <input type="text" class="customInput" placeholder="Notes for Warehouse"
                            formControlName="ShipWareHouseNotes" />
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>


            <!-- <div class="col-md-12">
              <div class="row mt-1">
                <div class="col-lg-3 col-md-6 pl-0">
                  <div class="form-group row mb-1">
                    <div class="col-sm-4 required">
                      <label class="customLabel">Address 1</label>
                    </div>

                    <div class="col-sm-8">
                      <input type="text" class="customInput" placeholder="Enter Address Line 1 " maxlength="100"
                        [(ngModel)]="ShipAddress1" name="ShipAddress1" autocomplete="off" autocomplete="disabled"
                        formControlName="ShipAddress1" [ngClass]="((!ShipAddress1) && (uservalidation))? 'validation_border': ''" (change)="onChangeShippingAddress()"/>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 pl-0">
                  <div class="form-group row mb-0">
                    <div class="col-sm-4">
                      <label class="customLabel">Address 2</label>
                    </div>

                    <div class="col-sm-8">
                      <input type="text" class="customInput" placeholder="Enter Address Line 2"
                        [(ngModel)]="ShipAddress2" autocomplete="off" formControlName="ShipAddress2"/>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 pl-0">
                  <div class="form-group row mb-0">
                    <div class="col-sm-4 required">
                      <label class="customLabel">Zip</label>
                    </div>

                    <div class="col-sm-8">
                      <div *ngIf="(ShipCountry==231)">
                        <input type="text" class="customInput NumberOnly" [(ngModel)]="ShipZip"
                        placeholder="Enter Zip xxxxx-xxxx" name="ShipZip" pattern="\d{5}-?(\d{4})?"
                        (keypress)="validateZipcode($event.target)" formControlName="ShipZip" autocomplete="disabled"
                        maxlength="10" [ngClass]="((!ShipZip) && (uservalidation))? 'validation_border': ''" (change)="onChangeShippingAddress()"/>
                        <div *ngIf="
                              !customerForm.controls.ShipZip.valid &&
                              customerForm.controls.ShipZip.value != '' &&
                              customerForm.controls.ShipZip.value != undefined &&
                              uservalidation
                            " class="text-danger input-error">
                          Enter Valid Zip.eg-xxxxx-xxxx
                        </div>
                      </div>
                      <div *ngIf="(ShipCountry!='231')">
                        <input type="text" class="customInput" [(ngModel)]="ShipZip"
                        placeholder="Enter Zip xxxxx-xxxx" name="ShipZip"
                        formControlName="ShipZip" autocomplete="disabled"
                        maxlength="10" (change)="onChangeShippingAddress()"/>
                      </div>

                    </div>
                  </div>
                </div>

                <div class="col-lg-3 col-md-6 pl-0">
                  <div class="form-group row mb-0">
                    <div class="col-sm-4">
                      <label class="customLabel">City</label>
                    </div>

                    <div class="col-sm-8">
                      <input type="text" class="customInput" placeholder="City" [(ngModel)]="ShipCity"
                        autocomplete="off" formControlName="ShipCity" (change)="onChangeShippingAddress()"/>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 pl-0">
                  <div class="form-group row mb-0">
                    <div class="col-sm-4 required">
                      <label class="customLabel">State</label>
                    </div>
                    <div class="col-lg-8 col-md-10">
                      <select class="customInput pl-1px" [(ngModel)]="ShipState" (change)="changeStateContact($event);onChangeShippingAddress();"
                        formControlName="ShipState" [ngClass]="
                        ((!ShipState) && (uservalidation))
                          ? 'validation_border'
                          : ''
                      ">
                        <option value="">Select State</option>
                        <option *ngFor="let item of stateData" [value]="item.id">
                          {{item.name}}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6 pl-0">
                  <div class="form-group row mb-0">
                    <div class="col-sm-4">
                      <label class="customLabel">Country</label>
                    </div>
                    <div class="col-sm-8">
                      <select class="customInput pl-1px" (change)="changeCountryContact($event)"
                        [(ngModel)]="ShipCountry" formControlName="ShipCountry" [ngClass]="
                        ((!ShipCountry) && (uservalidation))
                          ? 'validation_border'
                          : ''
                      ">
                        <option value="">Select Country</option>
                        <option *ngFor="let item of countryDataContact" [value]="item.id">
                          {{item.name}}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                

                <div class="col-lg-3 col-md-6 pl-0">
                  <div class="form-group row mb-0">
                    <div class="col-sm-4">
                      <label class="customLabel">Shipping Type</label>
                    </div>
                    <div class="col-sm-8">
                      <select class="customInput" [(ngModel)]="ShippingType" (change)="onValueChange()"
                        [ngModelOptions]="{standalone: true}">
                        <option value="">Select Shipping Type</option>
                        <option *ngFor="let item of ShippingTypeList" [value]="item.id">
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>

                <div class="col-lg-3 col-md-6 pl-0">
                  <div class="form-group row mb-0">
                    <div class="col-sm-6">
                      <input formControlName="IsCollectAmount" [(ngModel)]="IsCollectAmount"
                      class="custom_checkbox mr-2 mt-auto mb-auto" type="checkbox" ng-reflect-model="false"/>
                      <label class="customLabel">Collect Account</label>
                    </div>
                    <div class="col-sm-6 text-right">
                      <button [ngClass]="togglevalidAddBtn==true?'btn grey-bg':'btn darkred-bg round-btn'"
                       (click)="onValidateAddress()" [disabled]="togglevalidAddBtn">Validate Address</button>
                    </div>
                  </div>
                </div>
              </div>
            </div> -->

          </form>

          <div class="col-lg-3 col-md-6 pl-0" *ngIf="collection == true">
            <div class="form-group row mb-0">
              <div class="col-sm-4">
                <label class="customLabel">Collect Account</label>
              </div>

              <div class="col-sm-8">
                <input type="text" class="customInput" placeholder="Collect Account" [(ngModel)]="CollectAmount"
                  autocomplete="off" />
              </div>
            </div>
          </div>
          <div class="col-lg-12 p-0 mt-2">
            <div class="customHeading mt-1">
              Part SO - Part Detail
              <span class="text-success" *ngIf="EditIsEcommOrder == true">(eCommerce)</span>
            </div>
          </div>
        </div>
      </div>

      <div class="modal" id="addpartModal1">
        <div class="modal-dialog  modal-dialog-centered modal-lg centModel" role="document">
          <div class="modal-content">
            <h5 class="modal-title info-popup-title p-1" id="exampleModalLabel1">Add Part</h5>
            <button class="close btn alert_modal" aria-label="Close" (click)="PartItemExit()">
              <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
              <div class="row mb-1">
                <div class="col-md-12">
                  <div class="row">
                    <div class="col-md-4 col-12">
                      <div class="d-flex align-items-center">
                        <label class="customLabel mr-2" for="Vendor">Vendor</label>
                        <select class="customInput newsmallddl" [(ngModel)]="selectedVendorValue">
                          <option value='' selected>All</option>
                          <option *ngFor="let item of vendorPartListData" [value]="item.id">
                            {{item.vendorName}}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="col-lg-3 col-md-12 mt-lg-0 mt-1">

                      <div class="d-flex align-items-center">
                        <select class="customInput pl-1px" (change)="ChangefieldToSearch($event.target.value)"
                          [(ngModel)]="fieldToSearch" id="fieldToSearch">
                          <option value='1'>Search Keywords</option>
                          <option value='2'>List or Vendor Part Name</option>
                          <option value='3'>List or Vendor Part #</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-md-3 col-11 pr-md-2 mt-md-0 mt-1">
                      <div class="d-flex align-items-center">
                        <input type="text" class="customInput" placeholder="{{fieldToSearchPlaceHolder}}"
                          [(ngModel)]="PartName_search" autocomplete="off" (keyup.enter)="SearchByvendorList()">
                      </div>
                    </div>
                    <div class="col-md-1 col-1 text-left or_width" style="max-width: 30px; padding:0; margin-top: 3px;">
                      <i class="fas fa-search cpointer" (click)="SearchByvendorList()"></i>
                    </div>
                    <div class="col-md-1 col-1 text-left or_width">
                      <button type="button" class="btn blue-bg all-custom round-btn m-0 centbutton"
                        (click)="SearchByvendorListAll()">All Parts</button>
                    </div>

                  </div>
                </div>

              </div>
              <hr>
              <div class="white-box1" [style.display]="ListShow==true ? 'block' : 'none'">
                <div class="white-box-title1">
                  <div class="customHeading">
                    Part List
                  </div>
                </div>
                <!------------------------------------- company list table ------------------------------>
                <div class="card-desc1 mt-1 table-responsive autoWidth">
                  <table id="datatable" class="display table clearfix" class="row-border hover table-cursor" datatable
                    [dtOptions]="dtOptions1" [dtTrigger]="dtTrigger1">
                    <thead>
                      <tr>
                        <th hidden></th>
                        <th style="display: contents !important;"></th>
                        <th (click)="SortTableHeader('companyName','')">Company</th>
                        <th (click)="SortTableHeader('name','')">List Part Name<span id="name" class="sorting1"></span>
                        </th>
                        <th (click)="SortTableHeader('partNo','')">List Part#<span id="partNo" class="sorting1"></span>
                        </th>
                        <th (click)="SortTableHeader('vendorName','')">Vendor Name<span id="vendorName"
                            class="sorting1"></span></th>
                        <th (click)="SortTableHeader('vendorPartName','')">Vendor Part Name<span id="vendorName"
                            class="sorting1"></span></th>
                        <th (click)="SortTableHeader('vendorPartNumber','')">Vendor Part
                          #<span id="vendorName" class="sorting1"></span></th>
                        <!-- <th>Category</th> -->
                        <th (click)="SortTableHeader('cost','')">Part Price<span id="cost" class="sorting1"></span></th>
                        <th class="width-75" (click)="SortTableHeader('hours','')">Labor
                          Hrs<span id="hours" class="sorting1"></span></th>
                      </tr>
                    </thead>
                    <tbody class="customLabel" *ngIf="ddlPartList?.length != 0">
                      <tr *ngFor="let data of ddlPartList" (click)="PartdetailsModalPopup(data.id);">
                        <td style="display: none;">{{data.id}}</td>
                        <td><a>{{data.companyName}}</a></td>
                        <td class="text-over-ellipse-td">
                          <span class="tooltip-text"><a>{{data.name}}</a></span>
                          <span class="tooltip-td">{{data.name}}</span>
                        </td>
                        <td><a>{{data.partNo}}</a></td>
                        <td><a>{{data.vendorName}}</a></td>
                        <td><a>{{data.vendorPartName}}</a></td>
                        <td><a>{{data.vendorPartNumber}}</a></td>
                        <!-- <td><a>{{data.partCategoryName}}</a></td> -->
                        <td><a>{{data.cost|currency}}</a></td>
                        <td><a>{{data.hours|number: '1.2-2'}}</a></td>

                      </tr>
                    </tbody>
                    <tbody *ngIf="ddlPartList==null">
                      <tr class="c1">
                        <td colspan="8" class="no-data-available">No data available in table
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>

              </div>

              <div class="submit-from text-right">
                <!-- <button class="btn blue-bg round-btn" (click)="addPart(partObjectModel)"><i class="fas fa-save"
                                aria-hidden="true"></i> Add Part
                        </button> -->
                <button class="btn darkred-bg round-btn" (click)="PartItemExit();"><i aria-hidden="true"></i>
                  Exit </button>

              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal" id="HelcimModal">
        <div class="modal-dialog  modal-dialog-centered modal-lg centModel" role="document">
          <div class="modal-content">
            <h5 class="modal-title info-popup-title p-1" id="exampleModalLabel1">Helcim</h5>
            <button class="close btn alert_modal" aria-label="Close" (click)="onHelcimClose()">
              <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
              <!-- <app-helcim></app-helcim> -->
              <!--for helcium UI we have -->

              <div class="container p-0">
                <div class="cardHelcim px-4">
                  <p class="h8 py-3">Payment Details</p>
                  <form name="helcimForm" id="helcimForm">
                    <div class="row gx-3">
                      <div>
                        <div id="helcimResults"></div>

                        <!-- <input type="hidden" id="token" value="b806c4db5336fcd9f209e1"> -->
                        <input type="hidden" id="token" value="04532a54d6f9b9b8436a54">
                        <input type="hidden" id="language" value="en">
                        <!-- <input type="hidden" id="test" value="1"> -->
                      </div>
                      <div class="col-12">
                        <div class="d-flex flex-column">
                          <input id="amount" class="form-controlH mb-3" type="text" placeholder="Amount"
                            [disabled]="true">
                          <!-- <input type="hidden" id="amountHash" value="c0fbd50bf08ced29ea91930296107be8590294009919f92aa9f0e52eafc14bfc"> -->
                        </div>

                      </div>
                      <div class="col-12">
                        <div class="d-flex flex-column">
                          <input id="cardHolderName" class="form-controlH mb-3" type="text" placeholder="Name">
                        </div>
                      </div>
                      <div class="col-8">
                        <div class="d-flex flex-column">
                          <input id="cardHolderAddress" class="form-controlH mb-3" type="text"
                            placeholder="Street Address">
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="d-flex flex-column">
                          <input id="cardHolderPostalCode" class="form-controlH mb-3" type="text"
                            placeholder="Postal Code">
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="d-flex flex-column">
                          <input id="cardNumber" class="form-controlH mb-3" type="text" placeholder="1234 5678 435678"
                            maxlength="18">
                        </div>
                      </div>
                      <div class="col-3">
                        <div class="d-flex flex-column">
                          <input id="cardExpiryMonth" class="form-controlH mb-3" type="text" maxlength="2"
                            placeholder="MM">
                        </div>
                      </div>
                      <div class="col-3">
                        <div class="d-flex flex-column">
                          <input id="cardExpiryYear" class="form-controlH mb-3" type="text" maxlength="2"
                            placeholder="YY">
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="d-flex flex-column">
                          <input id="cardCVV" class="form-controlH mb-3 pt-2 " type="text" maxlength="4"
                            placeholder="123">
                        </div>
                      </div>
                      <div class="col-12">
                        <!-- <div class="btn btn-primary mb-3" onclick="javascript:helcimProcess();">
                      <span class="text-center">Process Payment</span>
                      
                  </div> -->
                        <div class="btn btn-primaryHel mb-3" (click)="PayNow(content, 'fromHelcimPay')">
                          <span class="text-center">Process Payment</span>

                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <!--helcium End-->
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <div class="row mt-1">
          <div class="col-lg-3 col-md-6 pl-0">
            <div class="row mb-1">
              <div class="col-sm-4">
                <label class="customLabel">SO View</label>
              </div>

              <div class="col-sm-8">
                <select class="form-control1 customInput" [(ngModel)]="SOView"
                  (change)="changeView($event.target.value)">
                  <option value="1">Sales View</option>
                  <option value="2">Operations View</option>
                  <option value="3" *ngIf="rolePermissionsList[0].isSOProfitability == true">
                    SO Profitability View
                  </option>
                </select>
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-6 pl-0">
            <div class="row mb-1">
              <div class="col-sm-12">
                <button class="btn blue-bg round-btn part-category m-0" (click)="AddModalPart()">
                  <i class="fas fa-plus-circle" aria-hidden="true"></i> Add Part
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>

      <div class="order-detail-main">
        <ng-container *ngIf="SalesOrderView == true">
          <table class="display table clearfix order-detail">
            <thead>
              <tr>
                <!-- <th></th> -->
                <th></th>
                <th colspan="4">Parts</th>
                <th colspan="2">
                  Labor
                  <b *ngIf="!laborCostEditMode" (click)="turnLaborToEditOn(laborHrsCost)"><i><small>({{ laborHrsCost |
                        currency }}/hr)</small></i></b>
                  <span *ngIf="laborCostEditMode">
                    <input type="text" class="customInput width-60 Cost" maxlength="8" (blur)="turnLaborToEditOff()"
                      [(ngModel)]="laborCostEdit" autofocus />
                  </span>
                </th>
                <th>Extention</th>
              </tr>
              <tr>
                <th class="text-left">&nbsp;Company Part Name</th>
                <th class="text-left">Vendor name</th>

                <th>Qty</th>
                <th>Each</th>
                <th>Ext</th>
                <th>Hrs</th>
                <th>Ext</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <ng-container>
                <ng-container *ngFor="let parts of partList; let innerIndex = index">
                  <tr class="hoverEffect OrderPartsDetails">
                    <td>
                      <div class="d-flex align-items-center position-relative">
                        <span class="partdetail" id="partname-{{ parts.partId }}"
                          (click)="OpenQuestion(parts.partId, 'open')">
                          <i class="fas fa-plus"></i>
                        </span>
                        <span class="partDetails tooltipWrap">{{ parts.partName }}
                          <span class="partDetails" *ngIf="parts.costold == 0" style="font-size: 11px !important">
                            (CS)</span></span>
                        <span class="tooltips" *ngIf="parts.costold == 0"
                          style="color: white; font-size: 11px !important">
                          (CS)</span>
                        <span class="showBtn" *ngIf="isOrderApproved == false">
                          <span *ngIf="btnShowvalidation && btnOkShow == false">
                            <span class="tooltipWrap tooltip-icon" style="display: inline"><i class="fa fa-info-circle"
                                style="font-size: 12px !important"></i></span>

                            <span class="tooltipsnew">
                              <span *ngIf="
                                  parts.isCustomPart == false;
                                  else other_content
                                " style="color: white">
                                "Vendor Part Name" :
                                {{
                                parts.vendorPartName == null
                                ? "N/A"
                                : parts.vendorPartName == undefined
                                ? "N/A"
                                : parts.vendorPartName == ""
                                ? "N/A"
                                : parts.vendorPartName
                                }}
                                and "Vendor Part #" :
                                {{
                                parts.vendorPartNumber == null
                                ? "N/A"
                                : parts.vendorPartNumber == undefined
                                ? "N/A"
                                : parts.vendorPartNumber == ""
                                ? "N/A"
                                : parts.vendorPartNumber
                                }}
                                <br />
                                "Width:" {{ parts.width }}
                                {{ calcaulte(parts.ptype) }} and "Height:"
                                {{ parts.height }}
                                {{ calcaulte(parts.ptype) }} and "Length:"
                                {{ parts.length }}
                                {{ calcaulte(parts.ptype) }} and "Weight:"
                                {{ parts.weight }} {{ wcalcaulte(parts.dtype) }}
                              </span>
                              <ng-template #other_content>
                                <span *ngIf="parts.isCustomPart == true">
                                  <span *ngFor="let parts1 of parts.customPartList" style="color: white">
                                    "Vendor Part Name" :
                                    {{
                                    parts1.vendorPartName == null
                                    ? "N/A"
                                    : parts1.vendorPartName == undefined
                                    ? "N/A"
                                    : parts1.vendorPartName == ""
                                    ? "N/A"
                                    : parts1.vendorPartName
                                    }}
                                    and "Vendor Part #" :
                                    {{
                                    parts1.vendorPartNumber == null
                                    ? "N/A"
                                    : parts1.vendorPartNumber == undefined
                                    ? "N/A"
                                    : parts1.vendorPartNumber == ""
                                    ? "N/A"
                                    : parts1.vendorPartNumber
                                    }}

                                    "Width:" {{ parts1.width }}
                                    {{ calcaulte(parts1.ptype) }} and "Height:"
                                    {{ parts1.height }}
                                    {{ calcaulte(parts1.ptype) }} and "Length:"
                                    {{ parts1.length }}
                                    {{ calcaulte(parts1.ptype) }} and "Weight:"
                                    {{ parts1.weight }}
                                    {{ wcalcaulte(parts1.dtype) }}
                                    <br />
                                  </span>
                                </span>
                              </ng-template>
                            </span>

                            <a class="showBtn btn btn-sm sq-btn mr-1 text-white" (click)="makeCS(parts.partId)"
                              [ngClass]="parts.cs == true ? 'redc' : 'bluec'">CS</a>
                            <a (click)="deletePopup(parts.partId, content)"><i
                                class="fas fa-trash-alt order-del"></i></a>
                            <a><span class="fas fa-edit partEdit" (click)="
                                  EditPartRow(
                                    parts.partId,
                                    'editRow',
                                    parts.quantity
                                  )
                                "></span></a>
                          </span>
                          <span *ngIf="
                              parts.partId == EditRowPartId && btnOkShow == true
                            " style="display: flex">
                            <span><button data-toggle="modal" (click)="UpdatePartRow(parts.partId)"
                                class="showBtn btn btn-sm green-bg sq-btn ml-1 p-addPart"
                                style="width: 35px !important">
                                Ok
                              </button></span>
                            <span><button data-toggle="modal" (click)="CancleUpdatePartRow(parts.partId)"
                                class="showBtn btn darkred-bg sq-btn ml-1 p-addPart mr-1"
                                style="width: 55px !important">
                                Cancel
                              </button></span>
                          </span>
                        </span>
                        <span style="display: flex">
                          <button class="arrow-updown showBtn" (click)="
                              SwapPartItem(partList, innerIndex, innerIndex - 1)
                            " [disabled]="innerIndex == 0 ? true : false">
                            <i class="fas fa-arrow-up"></i>
                          </button>
                          <button class="arrow-updown showBtn" (click)="
                              SwapPartItem(partList, innerIndex, innerIndex + 1)
                            " [disabled]="
                              innerIndex == partList.length - 1 ? true : false
                            ">
                            <i class="fas fa-arrow-down"></i>
                          </button>
                          <!-- <input type="button" value="↑"   class=" arrowbutton ml-1"  (click)="SwapPartItem(partList, innerIndex, (innerIndex-1))" [disabled]="innerIndex==0? true:false" />
                                                    <input type="button" value="↓" class=" arrowbutton ml-1" (click)="SwapPartItem(partList, innerIndex, (innerIndex+1))"  [disabled]="innerIndex==partList.length-1? true:false" /> -->
                        </span>
                      </div>
                    </td>
                    <td class="text-left">
                      {{ parts.vendorName }}

                      <span *ngIf="OEMType == true" class="oem">{{
                        parts.oemDiscount
                        }}</span>
                    </td>
                    <td class="text-center">
                      <span *ngIf="normalShow == true || RowEdit == false">{{
                        parts.quantityOld
                        }}</span>
                      <span hidden="hidden">
                        {{ parts.originalPartPrice }}</span>
                      <span *ngIf="
                          parts.partId == EditRowPartId &&
                          EditRowTxtName == 'editRow' &&
                          CurrentEditRow == 'Edit'
                        ">
                        <input class="form-control odr-qty NumberOnly" maxlength="5" type="text" [(ngModel)]="UpdateQty"
                          (blur)="CalculationOnkeypress(parts.partId)" />
                      </span>
                    </td>
                    <td>
                      <span *ngIf="normalShow == true">{{
                        parts.costold | currency
                        }}</span>
                      <span hidden="hidden">
                        {{ parts.originalPartPrice }}</span>

                      <span *ngIf="
                          parts.partId == EditRowPartId &&
                          EditRowTxtName == 'editRow' &&
                          CurrentEditRow == 'Edit'
                        ">
                        <input class="form-control inputShow Cost" type="text" [(ngModel)]="UpdateEachPartCost"
                          maxlength="10" (blur)="formatCurrency($event.target.value)"
                          (blur)="CalculationOnkeypressPrice(parts.partId)" />
                      </span>
                    </td>
                    <td>
                      {{ parts.costold * parts.quantityOld | currency }}

                      <span class="onEditCurrText" *ngIf="
                          parts.partId == EditRowPartId &&
                          EditRowTxtName == 'editRow' &&
                          CurrentEditRow == 'Edit'
                        ">
                        {{ parts.cost * parts.quantity | currency }}
                      </span>
                    </td>
                    <td>
                      <span *ngIf="normalShow == true || CurrentEditRow == 'Edit'">{{ parts.hourold | number : "1.2-2"
                        }}</span>
                      <span *ngIf="
                          parts.partId == EditRowPartId &&
                          EditRowTxtName == 'editRow' &&
                          CurrentEditRow == 'Edit'
                        ">
                        <input class="form-control odr-qty Cost" type="text" [(ngModel)]="UpdateLaborHrs" maxlength="6"
                          (blur)="formatHours($event.target.value)" (blur)="CalculationOnkeypress(parts.partId)" />
                      </span>
                    </td>
                    <td>
                      {{
                      parts.hourold * laborHrsCost * parts.quantityOld
                      | currency
                      }}
                      <span class="onEditCurrText" *ngIf="
                          parts.partId == EditRowPartId &&
                          EditRowTxtName == 'editRow' &&
                          CurrentEditRow == 'Edit'
                        ">
                        {{
                        parts.hours * laborHrsCost * parts.quantity | currency
                        }}
                      </span>
                    </td>
                    <td>
                      {{
                      parts.costold * parts.quantityOld +
                      parts.hourold * laborHrsCost * parts.quantityOld
                      | currency
                      }}
                      <span class="onEditCurrText" *ngIf="
                          parts.partId == EditRowPartId &&
                          EditRowTxtName == 'editRow' &&
                          CurrentEditRow == 'Edit'
                        ">
                        {{
                        parts.cost * parts.quantity +
                        parts.hours * laborHrsCost * parts.quantity
                        | currency
                        }}
                      </span>
                    </td>
                  </tr>
                  <tr class="table-partdetail partname-{{ parts.partId }}-detail">
                    <td colspan="8" style="text-align: left">
                      <!-- <div class="qus-title">Questions</div> -->

                      <div *ngFor="let qaItem of parts.questionAnsList">
                        <div>
                          <b style="font-size: 13px">Questions<span class="hoverEffect qus mt-2"
                              *ngIf="qaItem.questionId == 0" style="
                                margin-left: 9px;
                                font-weight: bold;
                                color: #333;
                              ">N/A</span></b>
                        </div>
                        <hr style="margin-top: 6px; margin-bottom: -4px" *ngIf="qaItem.questionId != 0" />
                        <!-- akm -->
                        <div class="hoverEffect qus mt-2 d-flex" *ngIf="qaItem.questionId != 0">
                          <span style="font-weight: bold; color: #333">
                            {{ qaItem.questionName }}
                          </span>
                          :
                          {{
                          qaItem.answerName == null
                          ? "Not Answered"
                          : qaItem.answerName
                          }}
                          <a class="showBtn green-text" *ngIf="isOrderApproved == false" (click)="
                              EditQuestionAnswr(
                                qaItem.questionId,
                                parts.partId,
                                qaItem.answerName,
                                ''
                              )
                            "><i class="fas fa-edit"></i></a>
                          <div *ngIf="
                              parts.partId == selectedPartId &&
                              qaItem.questionId == selectedQuestionId &&
                              isQA == true
                            ">
                            <div class="col-sm-12" *ngIf="qaItem.typeKey == 'Text'">
                              <input type="text" [(ngModel)]="answerValue" value="qaItem.answerName"
                                class="form-control" placeholder="Type Answer" maxlength="50" (blur)="
                                  UpdateQuestionAnswer(
                                    qaItem.questionId,
                                    parts.partId,
                                    qaItem.answerName,
                                    ''
                                  )
                                " />
                            </div>
                          </div>
                        </div>
                        <div *ngIf="
                            parts.partId == selectedPartId &&
                            qaItem.questionId == selectedQuestionId
                          ">
                          <div class="col-sm-12">
                            <select *ngIf="qaItem.typeKey == 'CustomerName'" class="form-control newsmallddl"
                              [(ngModel)]="qaItem.answerName">
                              <option value="null" selected>
                                Select Customer
                              </option>
                              <option *ngFor="let item of customerData">
                                {{ item.customerName }}
                              </option>
                            </select>
                          </div>

                          <div class="col-sm-12" *ngIf="qaItem.typeKey == 'YesNo'">
                            <select class="form-control newsmallddl" [(ngModel)]="qaItem.answerName">
                              <option value="" selected>Select Yes/No</option>
                              <option value="Yes">Yes</option>
                              <option value="No">No</option>
                            </select>
                          </div>

                          <div class="col-sm-12" *ngIf="qaItem.typeKey == 'VendorName'">
                            <select class="form-control newsmallddl" [(ngModel)]="qaItem.answerName">
                              <option value="null" selected>
                                Select Vendor
                              </option>
                              <option *ngFor="let item of vendorList">
                                {{ item.vendorName }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>

                      <hr style="margin-top: 6px; margin-bottom: 1px" />
                      <div class="hoverEffect qus mt-2 d-flex">
                        <span style="
                            font-weight: bold;
                            white-space: nowrap;
                            color: #333;
                          ">
                          Notes :
                          <span class="hoverEffect qus mt-1" *ngIf="parts.isNotes == false">{{ parts.notes }}</span>
                        </span>

                        <div *ngIf="parts.isNotes == true" class="w-100">
                          <div class="col-lg-12">
                            <input type="text" [(ngModel)]="parts.notes" value="qaItem.notes" class="form-control notes"
                              placeholder="Type Notes" (blur)="SaveNotes(parts.partId)" />
                          </div>
                        </div>
                        <a class="showBtn green-text" *ngIf="parts.isEdit == true"
                          (click)="visibleNotes(parts.partId)"><i class="fas fa-edit"></i></a>
                        <a class="showBtn green-text" *ngIf="parts.isEdit == false" (click)="SaveNotes(parts.partId)"><i
                            style="color: #0fa506; margin-top: 3px" class="fas fa-save"></i></a>
                      </div>
                    </td>
                  </tr>
                </ng-container>
                <tr *ngIf="partList.length == 0">
                  <td class="customLabel">Part Item is not Available</td>
                </tr>
              </ng-container>
            </tbody>
            <tfoot class="footOrder setFont" [style.display]="partList.length > 0 ? '' : 'none'">
              <tr>
                <td class="tr-bg-clr-none removeBorder"></td>
                <td class="double-border border_left" colspan="3" align="left">
                  SubTotal
                </td>
                <td class="double-border">
                  {{ PartExtensionTotal | currency }}
                </td>
                <td class="double-border">
                  {{
                  HoursExtensionTotal == null || HoursExtensionTotal == 0
                  ? "0.00"
                  : (HoursExtensionTotal | number : "1.2")
                  }}
                </td>
                <td class="double-border">
                  {{ LaborExtesionTotal | currency }}
                </td>
                <td class="double-border">{{ GrandTotalAmount | currency }}</td>
              </tr>
              <tr>
                <td class="tr-bg-clr-none removeBorder"></td>
                <td class="double-border border_left" colspan="6" align="left">
                  Shipping : <span style="color:#0fa506">{{serviceType}}</span> &nbsp;&nbsp; <span
                    *ngIf="showMultiPackage">Multiple Packages( <span
                      style="color:#0fa506; font-weight: bold;">{{packageCount}}</span>)</span><br />

                  <span *ngIf="ShippingType == '3' && ShippingType == '4'">
                    <span *ngFor="let shipType of shiprateListnew">
                      <input class="mr-2 PoRec" for="services" [ngModel]="rate_id" name="service" type="radio"
                        value="{{ shipType.rate_id}}" (change)="getshipValue(shipType.rate_id)" />
                      <span id="services">{{ shipType.service_type }} (
                        {{ shipType.carrier_delivery_days }} Days Delivery )
                      </span>
                      <span style="color: #0fa506; font-weight: 400">{{ shipType.package_type}}
                      </span>
                      <span style="color: #f00; font-weight: 600">{{ shipType.amount | currency }}
                      </span>
                      <br />
                    </span>
                  </span>
                  <span *ngIf="ShippingType == '1'">
                    <span *ngFor="let shipType of shiprateListnew">
                      <input class="mr-2 PoRec" for="services" [ngModel]="rate_id" name="service" type="radio"
                        value="{{ shipType.rate_id}}" (change)="getshipValueShip(shipType.rate_id)" />
                      <span id="services">{{ shipType.service_type }} (
                        {{ shipType.carrier_delivery_days }} Days Delivery )
                      </span>
                      <span style="color: #0fa506; font-weight: 400">{{ shipType.package_type}}
                      </span>
                      <span style="color: #f00; font-weight: 600">{{ shipType.amount | currency }}
                      </span>
                      <br />
                    </span>
                  </span>
                  <span *ngIf="ShippingType == '2'">
                    <span *ngFor="let shipType of shiprateListnew">
                      <input class="mr-2 PoRec" for="services" [ngModel]="serviceType" name="service" type="radio"
                        value="{{ shipType.service_type}}" (change)="getshipValueShip(shipType.rate_id)" />
                      <span id="services">{{ shipType.service_type }} (
                        {{ shipType.carrier_delivery_days }} )
                      </span>
                      <span style="color: #0fa506; font-weight: 400">{{ shipType.package_type}}
                      </span>
                      <span style="color: #f00; font-weight: 600">{{ shipType.amount | currency }}
                      </span>
                      <br />
                    </span>
                  </span>

                  <span *ngIf="ShippingType == '7'">
                    <span *ngFor="let shipType of shiprateListnew">
                      <input class="mr-2 PoRec" for="services" [ngModel]="serviceType" name="service" type="radio"
                        value="{{ shipType.service_type}}" (change)="getshipValueShip(shipType.rate_id)" />
                      <span id="services">{{ shipType.service_type }} (
                        {{ shipType.carrier_delivery_days }} )
                      </span>
                      <span style="color: #0fa506; font-weight: 400">{{ shipType.package_type}}
                      </span>
                      <span style="color: #f00; font-weight: 600">{{ shipType.amount | currency }}
                      </span>
                      <br />
                    </span>
                  </span>

                  &nbsp;&nbsp;<span style="cursor: pointer" (click)="showView()" *ngIf="this.more"><i
                      class="fas fa-plus-circle"></i>Show More</span>
                  &nbsp;&nbsp;<span style="cursor: pointer" (click)="showLess()" *ngIf="this.less"><i
                      class="fas fa-minus-circle"></i>Show Less</span>
                </td>
                <td class="double-border">
                  {{ shippingAmount | currency }}
                </td>
              </tr>
              <tr>
                <td class="tr-bg-clr-none removeBorder"></td>
                <td colspan="5" align="left" class="border_left">
                  Discount
                  <span class="discount align-with">
                    <label style="margin-right: 0px">
                      %
                      <input class="p_relative" type="radio" value="Percentage" [disabled]="isOrderApproved"
                        name="Discount" (change)="OnDiscountChange($event.target.value)" />
                    </label>

                    <label class="ml-2">
                      $
                      <input class="p_relative" type="radio" value="Doller" name="Discount"
                        (change)="OnDiscountChange($event.target.value)" [disabled]="isOrderApproved" />
                    </label>
                  </span>

                  <span>
                    <label class="ml-2">
                      Parts &nbsp;<input class="p_relative custom_checkbox" type="checkbox" name="Part"
                        [disabled]="!enableCheckBox || isOrderApproved" name="Part" [(ngModel)]="PartCheckBox"
                        (change)="CalculationSubtotal()" />
                    </label>

                    <label class="ml-2">
                      Labor &nbsp;<input class="p_relative custom_checkbox" type="checkbox" name="Labor"
                        [disabled]="!PartCheckBox || isOrderApproved" name="Labor" [(ngModel)]="LaborCheckBox"
                        (change)="CalculationSubtotal()" />
                    </label>
                  </span>
                </td>
                <td>
                  <span *ngIf="discountRadioBox == 'Doller'" class="pr-1">$</span><input type="text"
                    (keyup)="CalculationSubtotal()" [disabled]="!enableCheckBox || isOrderApproved"
                    (keypress)="formatCurrencyDiscount()" [(ngModel)]="discountamount"
                    (blur)="formatCurrencyDiscountBlur($event.target.value)" class="width-100_1 Cost" id="dollor"
                    [disabled]="isOrderApproved" maxlength="8" autocomplete="off" />
                  <span *ngIf="discountRadioBox == 'Percentage'" class="pl-1">%</span>
                </td>

                <td style="color: #f00">({{ discount | currency }})</td>
              </tr>
              <tr>
                <td class="tr-bg-clr-none removeBorder"></td>
                <td colspan="3" align="left" class="border_left">
                  SubTotal with Discounts
                </td>
                <td>{{ partExtSubTotalWithDiscount | currency }}</td>
                <td></td>
                <td>{{ laborExtSubTotalWithDiscount | currency }}</td>
                <td>{{ SubTotalWithDisnt + shippingAmount | currency }}</td>
                <!-- <td>{{SubTotalWithDisnt |currency}}</td> -->
              </tr>
              <tr>
                <td class="tr-bg-clr-none removeBorder"></td>

                <td colspan="6" align="left" class="border_left">
                  <select class="salestax_custom" [(ngModel)]="TaxType" nam="TaxType"
                    (change)="SaleTaxType($event.target.value)" [value]="1" [disabled]="isOrderApproved">
                    <option value="1">Sales Tax</option>
                    <option value="2">Reseller</option>
                    <option value="3">Out of State</option>
                  </select>
                  <span *ngIf="SaleTaxDiv">
                    <!-- <input type="text" (keyup)="CalculationSalesTax()" (keypress)="formatCurrencySalesTaxKeyPress()"
                      (blur)="SaleTaxBlur($event.target.value)" [(ngModel)]="SaleTaxAmountRate" class="Cost" id="dollor"
                      [disabled]="isOrderApproved" maxlength="5" autocomplete="off" /> -->
                    <input type="text" (keyup)="CalculationSalesTax()" (keypress)="formatCurrencySalesTaxKeyPress()"
                      (blur)="SaleTaxBlur($event.target.value)" [(ngModel)]="SaleTaxAmountRate" class="Cost" id="dollor"
                      maxlength="5" autocomplete="off" disabled />
                    <span class="dollar">%</span>
                    <label class="ml-2">
                      Parts &nbsp;<input class="p_relative custom_checkbox" type="checkbox" name="IsTaxPart"
                        [(ngModel)]="IsTaxPart" (change)="CalculationSalesTax()" [disabled]="isOrderApproved" />
                    </label>
                    <label class="ml-2">
                      Labor &nbsp;<input class="p_relative custom_checkbox" type="checkbox" name="IsTaxLabor"
                        [disabled]="!IsTaxPart || isOrderApproved" [(ngModel)]="IsTaxLabor"
                        (change)="CalculationSalesTax()" />
                    </label>
                    <label class="ml-2">
                      Shipping &nbsp;<input class="p_relative custom_checkbox" type="checkbox" name="IsShipping" />
                    </label>
                  </span>
                  <label class="ml-2" *ngIf="Resellertextbox">
                    Reseller No.
                    <input type="text" maxlength="10" class="width-150" [(ngModel)]="ResellerNo" disabled />
                  </label>
                </td>
                <!-- <td>{{TaxTotalAmount  | currency}}</td> -->
                <td>{{ TaxjarTaxAmount | currency}}</td>
              </tr>
              <tr>
                <td class="tr-bg-clr-none bb-1"></td>
                <td colspan="5" align="left" class="border_left text-success">
                  Approved<span class="ml-5">{{ ApprovedamountMoney | currency }}</span>
                </td>
                <td align="left" class="border_left text-primary">
                  Total
                </td>
                <td colspan="3">
                  <span class="ml-5">{{ (finalAmount + shippingAmount + TaxjarTaxAmount) |currency }}</span>
                </td>
              </tr>
              <tr>
                <td class="tr-bg-clr-none bb-1"></td>
                <td colspan="5" align="left" class="border_left">
                </td>
                <td align="left" class="border_left text-success">
                  Paid
                </td>
                <td colspan="3">
                  <span class="ml-5" *ngIf="paymentType!='Helcim'">{{ CompletedamountMoney | currency }}</span>
                  <span class="ml-5" *ngIf="paymentType=='Helcim' && transactionStatus=='COMPLETED'">{{ (finalAmount + shippingAmount + TaxjarTaxAmount) | currency }}</span>
                </td>
              </tr>
              <tr>
                <td class="tr-bg-clr-none bb-1"></td>
                <td colspan="5" align="left" class="border_left">
                </td>
                <td align="left" class="border_left text-danger">
                  Balance
                </td>
                <td colspan="3">
                  <span class="ml-5" *ngIf="paymentType != 'Helcim'">{{ finalAmount + shippingAmount + TaxjarTaxAmount -
                    CompletedamountMoney |currency
                    }}</span>
                  <span class="ml-5" *ngIf="paymentType == 'Helcim' && transactionStatus=='COMPLETED'">{{ balanceAmount |currency
                    }}</span>
                </td>
              </tr>
            </tfoot>
          </table>
          <div>
            <p *ngIf="!togglevalidAddBtn" class="text-danger h6">* Address has changed calculations and total may be
              incorrect, please “Validate Address”.</p>
          </div>
        </ng-container>

        <!--  create payment design start -->
        <div class="col-lg-12">
          <div class="row p-0 mt-2">
            <div class="col-md-12 p-0">
              <div class="customHeading mt-1">Payment Options</div>
            </div>



            <div class="col-md-12">
              <div class="row mt-1">
                <div class="col-lg-3 col-md-6 pl-0">
                  <div class="row mb-1">
                    <div class="col-sm-4">
                      <label class="customLabel"><b class="light-color-text">Payment Type:</b></label>
                    </div>

                    <div class="col-sm-8">
                      <select name="PaymentMode" #PaymentModeId id="PaymentMode" [(ngModel)]="PayModeModel"
                        class="pl-1px customInput PaymentMode" (change)="onChangePayMode($event)">
                        <option value="">-- Select --</option>
                        <option value="NetTerms" [hidden]="isNetTerms">Net Terms</option>
                        <option value="CreditCard">Credit Card</option>
                        <option value="Helcim">Helcim Pay</option>
                        <option value="PayPal">PayPal</option>
                        <option value="Check">Check</option>
                        <option value="ACH">ACH</option>
                        <option value="Wire">Wire</option>
                        <option value="Cash">Cash</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-lg-9 col-md-6 pl-0">
                  <div class="row mb-1">
                    <div class="col-sm-3 d-flex">
                      <!-- <img src="{{ '/assets/image/' + Payment_Network + '.png' }}" alt="{{ Payment_Network }}"
                      class="PayNetworkImg pl-2" [style.display]="isPayNetworkImg == true ? 'block' : 'none'" /> -->
                      <button class="btn blue-bg round-btn text-white" (click)="onPayClick()"
                        [disabled]="isPay">
                        <i aria-hidden="true" class="fas fa-credit-card"></i> Pay
                      </button>
                    
                      <button class="btn blue-bg round-btn text-white">
                        <i aria-hidden="true" class="fas fa-dollar"
                          (click)="onCollectClick()">Collect Payment</i>
                      </button>
                      <!-- <button class="btn blue-bg round-btn text-white"
                        *ngIf="transactionStatus=='APPROVED' && ((finalAmount+shippingAmount+TaxjarTaxAmount)-CompletedamountMoney<=0)">
                        <i aria-hidden="true" class="fas fa-dollar" *ngIf="SelectedPayementMode != 'Helcim'"
                          (click)="onCollectClick()">Collect Payment</i>
                        <i aria-hidden="true" class="fas fa-dollar" *ngIf="SelectedPayementMode == 'Helcim'"
                          (click)="onCollectClickForHelcim()"> Collect Payment</i>
                      </button> -->
                    </div>
                    <div class="col-sm-3">
                      <div class="mr-5 f_size_10">
                        <b class="light-color-text">Payment Status: </b>&nbsp;
                        <label for="PaymentStatusValue" [class]="classPayStatustxt">{{ paymentStatusCard }}</label>
                      </div>
                    </div>
                    <div class="col-sm-2">
                      <div class="mr-5 f_size_10">
                        <b class="light-color-text">Card: </b>&nbsp;
                        <label for="PaymentNetwork">{{ Payment_Network }}</label>
                      </div>
                    </div>
                    <div class="col-sm-2">
                      <div class="mr-5 f_size_10">
                        <b class="light-color-text">Payment Error: </b>&nbsp;
                        <label for="PaymentErrorValue">{{ paymentResults_error_code }}</label>
                      </div>
                    </div>
                    <div class="col-sm-2">
                      <div class="mr-5 f_size_10">
                        <button class="btn blue-bg round-btn text-white" (click)="openModalPayment()">
                          <i aria-hidden="true" class="fas fa-info-circle"></i> Pay Log
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
          <div class="row d-flex justify-content-left p-4">
            <div class="col-lg-8" [style.display]="isDisplay == true ? 'block' : 'none'">
              <div class="card shadow">
                <div class="bg-light">
                  <span class="p-4"><i aria-hidden="true" class="fas fa-money"></i> Payment
                    Method</span>
                </div>
                <div class="p-4">
                  <div class="p-2">
                    <input class="form-check-input" type="radio" name="CreditRadios" id="CreditRadios" value="Credit"
                      checked />&nbsp; <span>Credit Card</span><span><img src="/assets/image/payment-Options.png"
                        alt="paymentOptions" class="w-25" /></span>
                  </div>
                  <div>
                    <div>
                      <small><span class="text-muted">Pay securely using your Credit Card.</span></small>
                    </div>
                    <div class="">
                      <form id="payment-form">
                        <div id="card-container"></div>
                        <div class="float-right">
                          <button id="card-button" type="button" class="btn blue-bg round-btn" (click)="PayNow(content, 'fromCardPay')"
                            [disabled]="isDisabled">
                            Pay Now
                          </button>
                          <button id="card-button" type="button" class="btn darkred-bg round-btn"
                            (click)="ExitPayment()">
                            Exit
                          </button>
                        </div>
                      </form>
                      <div id="payment-status-container"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal" id="PaymentLog">
            <div class="modal-dialog modal-dialog-centered modal-lg centModel" role="document">
              <div class="modal-content">
                <h5 class="modal-title info-popup-title p-1" id="exampleModalLabel1">
                  Payment Log for SO: {{ displaysaleorder }}
                </h5>
                <button class="close btn alert_modal" aria-label="Close" (click)="PaymentLogExit()">
                  <span aria-hidden="true">&times;</span>
                </button>
                <div class="modal-body">
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Date</th>
                        <th scope="col">Amount</th>
                        <th scope="col">Status</th>
                        <th scope="col">TransId</th>
                        <th scope="col">Last 4 of CC</th>
                        <th scope="col">Exp Date</th>
                        <th scope="col">Card</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of PaymentLog; let sno = index">
                        <th scope="row">{{ sno + 1 }}</th>
                        <td>{{ item.createdDate }}</td>
                        <td>
                          {{ item.amountMoney / 100 + " " + item.currency }}
                        </td>
                        <td [ngClass]="item.status == 'FAILED' ? 'text-danger' : 'text-success'">{{ item.status }}</td>
                        <td>{{ item.transactionId }}</td>
                        <td>{{ item.ccLast4 }}</td>
                        <td>{{ item.ccExpDate }}</td>
                        <td>{{ item.cardBrand }}</td>
                      </tr>
                    </tbody>
                  </table>
                  <div class="row mt-2 p-3">
                    <div class="">
                      <strong>Balance:&nbsp;</strong>
                    </div>
                    <div class="">
                      <strong>{{ (finalAmount + shippingAmount + TaxjarTaxAmount) - (CompletedamountMoney) |
                        currency}}</strong>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="modal" id="AmountAlert">
            <div class="modal-dialog">
              <div class="modal-content">
                <h4 class="modal-title info-popup-header darkred-bg alert_heading sweet-heading"></h4>
                <button class="close btn alert_modal" aria-label="Close" (click)="AmountAlertClose()">
                  <span aria-hidden="true">&times;</span>
                </button>
                <div class="modal-body">
                  <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="" />
                    <!-- <h1>Alert</h1> -->
                    <p class="modal-para mb-0">{{ AmountAlertMeassage }}</p>
                  </div>
                </div>
                <div class="modal-footer sweet-footer">
                  <button type="button" class="btn red-bg round-btn m-0" (click)="AmountAlertClose()"
                    data-dismiss="modal">
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div class="modal" id="AmountAlert1">
            <div class="modal-dialog">
              <div class="modal-content">
                <h4 class="modal-title info-popup-header darkred-bg alert_heading sweet-heading"></h4>
                <button class="close btn alert_modal" aria-label="Close" (click)="AmountAlertClose1()">
                  <span aria-hidden="true">&times;</span>
                </button>
                <div class="modal-body">
                  <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="" />
                    <!-- <h1>Alert</h1> -->
                    <label class="my-3 text-danger" *ngIf="isAmountToCollect">
                      Amount to Collect should not be greater than or equal to the <strong>"Balance Amount"</strong> and
                      <strong>"Mininum Balance Amount"</strong> should be $1.
                    </label>
                    <p class="h4 my-3 text-dark">
                      Amount to Collect: $&nbsp;<input type="text" class="width-150" [(ngModel)]="RequestPayVal"
                        (change)="onRequestPayValChange()" />
                    </p>
                    <div>
                      <button class="btn blue-bg round-btn text-white" (click)="onRequestPayClick()"
                        [disabled]="isAmountToCollect">
                        <i aria-hidden="true" class="fas fa-dollar"></i> Request Payment
                      </button>
                    </div>
                  </div>
                </div>
                <div class="modal-footer sweet-footer">
                  <button type="button" class="btn red-bg round-btn m-0" (click)="AmountAlertClose1()"
                    data-dismiss="modal">
                    Close
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!--  create payment design end -->



        <ng-container *ngIf="OperationView == true">
          <table class="display table clearfix order-detail">
            <thead>
              <tr>
                <th class="text-left">&nbsp;Company Part Name</th>
                <th>Vendor name</th>
                <th>Qty</th>
                <th>Stock</th>
                <th>Inv. Assigned to SO</th>
                <th>Back Ordered</th>
                <th>PO Expected Ship Date</th>
                <th>Qty still needed to Fulfill</th>
                <th class="text-center">Ready to Ship

                </th>
                <th class="text-center">Qty Shipped</th>
              </tr>

            </thead>
            <tbody>
              <ng-container>
                <ng-container *ngFor="
                    let parts of OperationViewList;
                    let innerIndex = index
                  ">
                  <tr class="hoverEffect OrderPartsDetails">
                    <td>
                      <span class="partdetailA" id="partnameA-{{ parts.partId }}" *ngIf="parts.partcustom == true"
                        (click)="OpenQuestion1(parts.partId)">
                        <i class="fas fa-plus plustxt"></i>
                      </span>

                      <span class="ml-1">{{ parts.partName }}</span>
                    </td>
                    <td class="text-center">
                      {{ parts.vendorName }}
                    </td>
                    <td class="text-center">
                      {{ parts.qty }}
                    </td>
                    <td class="text-center">
                      <span *ngIf="parts.partcustom == false; else other_content">
                        {{ parts.avlbInventory }}
                      </span>
                      <ng-template #other_content>0</ng-template>
                    </td>
                    <td class="text-center">
                      <span *ngIf="parts.partcustom == false; else other_content">
                        {{ parts.fromInventory }}
                      </span>
                      <ng-template #other_content>0</ng-template>
                    </td>
                    <td class="text-center" *ngIf="parts.partcustom == false">
                      <span>{{ parts.fromPO - parts.fromPORec }}</span><i class="fa fa-info-circle ml-2"
                        ngbTooltip="{{ parts.poDetails }}" style="font-size: 10px !important"
                        *ngIf="parts.fromPO != 0"></i>
                    </td>
                    <td *ngIf="parts.partcustom == true">
                      <span></span>
                    </td>
                    <td class="text-center">
                      <span *ngIf="parts.partcustom == false; else other_content">
                        {{ parts.shipdate }}
                      </span>
                      <ng-template #other_content></ng-template>
                    </td>
                    <td class="text-center" *ngIf="parts.partcustom == false" [ngClass]="
                        parts.qtyToFulfill == 0
                          ? 'tdbg_green'
                          : parts.qty > parts.qtyToFulfill
                          ? 'tdbg_yellow'
                          : parts.qty == parts.qtyToFulfill
                          ? 'tdbg_red'
                          : ''
                      ">
                      {{ parts.qtyToFulfill }}
                    </td>
                    <td class="text-center" *ngIf="parts.partcustom == true"
                      style="background-color: #ddd; border: 1px solid #eee">
                      0
                    </td>
                    <td class="text-center" *ngIf="parts.partcustom == false">
                      <ng-container *ngIf="
                          parts.fromPO == parts.fromPORec &&
                            parts.qtyToFulfill == 0;
                            else cross
                        ">
                        <span class="text-dangers"><i class="fa fa-check text-success" aria-hidden="true"></i></span>

                      </ng-container>

                      <ng-template #cross>
                        <i class="fa fa-times text-danger" *ngIf="this.partialProp== false" aria-hidden="true"></i>
                        <i class="fa fa-check text-success" aria-hidden="true"
                          *ngIf="partialProp== true && parts.fromInventory != 0"></i>
                        <i class="fa fa-times text-danger" aria-hidden="true"
                          *ngIf="partialProp== true && parts.fromInventory == 0"></i>

                      </ng-template>
                    </td>
                    <td *ngIf="parts.partcustom == true"></td>
                    <td class="text-center">

                      <span (click)="showShipDetails(parts.itemId)" *ngIf="
                        (parts.shipqty > 0 && parts.shipqty != parts.qty) && partialProp== true"
                        style="color:orange;cursor: pointer;">Partial({{parts.shipqty}})</span>


                      <span (click)="showShipDetails(parts.itemId)" *ngIf="
                        (parts.shipqty == parts.qty)
                           " style="color:green; cursor: pointer;">Complete({{parts.shipqty}})</span>


                    </td>
                  </tr>

                  <ng-container *ngFor="let partss of parts.newlist; let innerIndex = index">
                    <tr class="hoverEffect table-partdetail partnameA-{{
                        parts.partId
                      }}-detailA">
                      <td>
                        <span></span>

                        <span style="margin-left: 40px">
                          {{ partss.partName }}
                        </span>
                      </td>
                      <td class="text-center">
                        <span>
                          {{ partss.vendorName }}
                        </span>
                      </td>
                      <td class="text-center">
                        {{ partss.qty }}
                      </td>
                      <td class="text-center">
                        {{ partss.avlbInventory }}
                      </td>
                      <td class="text-center">
                        {{ partss.fromInventory }}
                      </td>
                      <td class="text-center">
                        {{ partss.fromPO - partss.fromPORec
                        }}<i class="fa fa-info-circle ml-2" ngbTooltip="{{ partss.poDetails }}"
                          style="font-size: 10px !important" *ngIf="partss.fromPO != 0"></i>
                      </td>
                      <td class="text-center">
                        {{ partss.shipdate }}
                      </td>
                      <td class="text-center" [ngClass]="
                          partss.qtyToFulfill == 0
                            ? 'tdbg_green'
                            : partss.qty > partss.qtyToFulfill
                            ? 'tdbg_yellow'
                            : partss.qty == partss.qtyToFulfill
                            ? 'tdbg_red'
                            : ''
                        ">
                        {{ partss.qtyToFulfill }}
                      </td>
                      <td class="text-center">
                        <ng-container *ngIf="
                        partss.fromPO == partss.fromPORec &&
                        partss.qtyToFulfill == 0;
                              else cross
                          ">
                          <span class="text-dangers"><i class="fa fa-check text-success" aria-hidden="true"></i></span>

                        </ng-container>

                        <ng-template #cross>
                          <i class="fa fa-times text-danger" *ngIf="this.partialProp== false" aria-hidden="true"></i>
                          <i class="fa fa-check text-success" aria-hidden="true"
                            *ngIf="partialProp== true && partss.fromInventory != 0"></i>
                          <i class="fa fa-times text-danger" aria-hidden="true"
                            *ngIf="partialProp== true && partss.fromInventory == 0"></i>

                        </ng-template>
                      </td>
                      <td class="text-center">
                        <span (click)="showShipDetails()" *ngIf="
                       (partss.shipqty > 0 && partss.shipqty != partss.qty)"
                          style="color:orange;cursor: pointer;">Partial({{partss.shipqty}})</span>

                        <span (click)="showShipDetails()" *ngIf="
                       (partss.shipqty == partss.qty)
                          " style="color:green; cursor: pointer;">Complete({{partss.shipqty}})</span>
                      </td>

                    </tr>
                  </ng-container>
                </ng-container>
              </ng-container>
            </tbody>
          </table>
        </ng-container>

        <ng-container *ngIf="SoProfitabilityView == true">
          <table class="display table clearfix order-detail">
            <thead>
              <tr>
                <!-- <th></th> -->
                <th></th>
                <th colspan="5">Parts</th>
                <th colspan="7">
                  Labor
                  <i>({{ laborLoadedCost | currency }} /
                    {{ laborHrsCost | currency }})</i>
                </th>
              </tr>
              <tr>
                <th class="text-left">&nbsp;Company Part Name</th>
                <th>Qty</th>
                <th>Part Cost</th>
                <th>Part Cost Ext</th>
                <th>Part Price</th>
                <th>Part Price Ext</th>
                <th>Labor Hrs</th>
                <th>Labor Hrs Ext</th>
                <th>Labor Cost Ext</th>
                <th>Labor Price Ext</th>
                <th>Total Cost Ext</th>
                <th>Total Price Ext</th>
              </tr>
            </thead>
            <tbody>
              <ng-container>
                <ng-container *ngFor="let parts of partList; let innerIndex = index">
                  <tr class="hoverEffect OrderPartsDetails">
                    <td>
                      <div class="d-flex align-items-center position-relative">
                        <span class="partDetails">&nbsp;{{ parts.partName }}
                          <span class="partDetails" *ngIf="parts.costold == 0">
                            (CS)</span></span>
                      </div>
                    </td>
                    <td class="text-center">
                      {{ parts.quantityOld }}
                    </td>
                    <td>
                      {{ parts.vendorPartCost | currency }}
                    </td>
                    <td>
                      {{ parts.vendorPartCost * parts.quantityOld | currency }}
                    </td>
                    <td>
                      {{ parts.costold | currency }}
                    </td>
                    <td>
                      {{ parts.costold * parts.quantityOld | currency }}
                    </td>
                    <td>
                      {{ parts.hourold | currency }}
                    </td>
                    <td>
                      {{ parts.quantity * parts.hourold | currency }}
                    </td>
                    <td>
                      {{
                      parts.quantity * parts.hourold * laborLoadedCost
                      | currency
                      }}
                    </td>
                    <td>
                      {{
                      parts.quantity * parts.hourold * laborHrsCost | currency
                      }}
                    </td>
                    <td>
                      {{
                      parts.vendorPartCost * parts.quantityOld +
                      parts.quantity * parts.hourold * laborLoadedCost
                      | currency
                      }}
                    </td>
                    <td>
                      {{
                      parts.costold * parts.quantityOld +
                      parts.quantity * parts.hourold * laborHrsCost
                      | currency
                      }}
                    </td>
                  </tr>
                </ng-container>
                <tr *ngIf="partList.length == 0">
                  <td class="customLabel">Part Item is not Available</td>
                </tr>
              </ng-container>
            </tbody>
            <tfoot class="footOrder setFont" [style.display]="partList.length > 0 ? '' : 'none'">
              <tr>
                <td class="tr-bg-clr-none removeBorder"></td>
                <td class="double-border border_left" align="left">SubTotal</td>
                <td class="double-border">
                  {{ totalCost | currency }}
                </td>
                <td class="double-border">
                  {{ totalCostExt | currency }}
                </td>
                <td class="double-border">
                  {{ totalPrice | currency }}
                </td>
                <td class="double-border">
                  {{ totalPriceExt | currency }}
                </td>
                <td class="double-border">
                  {{
                  totalLabor == null || totalLabor == 0 ? "0.00" : totalLabor
                  }}
                </td>
                <td class="double-border">
                  {{ laborHrsExt | currency }}
                </td>
                <td class="double-border">
                  {{ laborCostExt | currency }}
                </td>
                <td class="double-border">
                  {{ laborPriceExt | currency }}
                </td>
                <td class="double-border">
                  {{ soTotalCostExt | currency }}
                </td>
                <td class="double-border">
                  {{ soTotalPriceExt | currency }}
                </td>
              </tr>

              <tr>
                <td class="tr-bg-clr-none bb-1"></td>
                <td colspan="4" align="left" class="border_left">Total</td>
                <td colspan="3"></td>
                <td colspan="3"></td>

                <td>
                  {{ finalAmount | currency }}
                </td>
              </tr>
              <tr>
                <td class="tr-bg-clr-none bb-1"></td>
                <td colspan="4" align="left" class="border_left">
                  Total Labor Cost
                </td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td>
                  {{ totalLaborCostExt | currency }}
                </td>
              </tr>
              <tr>
                <td class="tr-bg-clr-none bb-1"></td>
                <td colspan="4" align="left" class="border_left">
                  Total Part Cost
                </td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td>
                  {{ totalCostExt | currency }}
                </td>
              </tr>
              <tr>
                <td class="tr-bg-clr-none bb-1"></td>
                <td colspan="4" align="left" class="border_left">
                  Gross Profit
                </td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td>
                  {{
                  finalAmount - (totalLaborCostExt + totalCostExt) | currency
                  }}
                </td>
              </tr>
              <tr>
                <td class="tr-bg-clr-none bb-1"></td>
                <td colspan="4" align="left" class="border_left">
                  Part Profit / Markup %
                </td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td>
                  {{ totalPriceExt - totalCostExt | currency }}&nbsp;/&nbsp;{{
                  (totalPriceExt - totalCostExt) / totalCostExt
                  | number : "1.2-2"
                  }}%
                </td>
              </tr>
              <tr>
                <td class="tr-bg-clr-none bb-1"></td>
                <td colspan="4" align="left" class="border_left" style="color: black">
                  Labor Profit / Markup %
                </td>
                <td colspan="3"></td>
                <td colspan="3"></td>
                <td style="color: black">
                  {{
                  totalLaborPriceExt - totalLaborCostExt | currency
                  }}&nbsp;/&nbsp;{{
                  (totalLaborPriceExt - totalLaborCostExt) / totalLaborCostExt
                  | number : "1.2-2"
                  }}%
                </td>
              </tr>
            </tfoot>
          </table>
        </ng-container>

        <div class="white-box discountSetup col-md-12" *ngIf="btnArea == true">
          <div>
            <div class="submit-from text-right">
              <!-- my comment -->
              <span *ngIf="issaleOrderChecked == false">
                <button
                  *ngIf="rolePermissionsList && rolePermissionsList[0].isInsert && isOrderApproved == false && partList.length > 0 && isOrderConfirmType == 'order'"
                  (click)="UpdateOrder(values, updateOrderId, true, '2')" class="btn blue-bg round-btn"
                  [disabled]="!togglevalidAddBtn">
                  <i class="fas fa-save" aria-hidden="true"></i>Print
                </button>
              </span>

              <span *ngIf="issaleOrderChecked == false">
                <button class="btn blue-bg round-btn" (click)="ManuallyCalculate()">
                  <i class="fas fa-calculator" aria-hidden="true"></i>
                  Manually Calculate
                </button>
              </span>
              <span *ngIf="issaleOrderChecked == false">
                <button *ngIf="
                    rolePermissionsList &&
                    rolePermissionsList[0].isInsert &&
                    isOrderApproved == false &&
                    partList.length == 0
                  " data-toggle="modal" (click)="PartListExistence()" class="btn blue-bg round-btn" [disabled]="true">
                  <i class="fas fa-save" aria-hidden="true"></i>
                  {{ saveasOrder }}
                </button>
              </span>
              <span *ngIf="issaleOrderChecked == false">
                <button *ngIf="
                    rolePermissionsList &&
                    rolePermissionsList[0].isInsert &&
                    isOrderApproved == false &&
                    partList.length > 0 &&
                    isOrderConfirmType == 'part' && 
                    fromWhere!='fromHelcimPay'" (click)="SaveOrder(content,'1', 'fromsaveorder')"
                  class="btn blue-bg round-btn" [disabled]="!togglevalidAddBtn">
                  <i class="fas fa-save" aria-hidden="true"></i>{{ saveasOrder }}
                </button>
              </span>

              <span *ngIf="issaleOrderChecked == false">
                <button *ngIf="
                    rolePermissionsList &&
                    rolePermissionsList[0].isInsert &&
                    isOrderApproved == false &&
                    partList.length > 0 &&
                    isOrderConfirmType == 'part' && 
                    fromWhere=='fromHelcimPay'" (click)="SaveOrder(content,'1', 'fromHelcimPaySave')"
                  class="btn blue-bg round-btn" [disabled]="!togglevalidAddBtn">
                  <i class="fas fa-save" aria-hidden="true"></i>{{ saveasOrder }}
                </button>
              </span>

              <span *ngIf="issaleOrderChecked == false">
                <button *ngIf="
                    rolePermissionsList &&
                    rolePermissionsList[0].isInsert &&
                    isOrderApproved == false &&
                    partList.length > 0 &&
                    isOrderConfirmType == 'order'" (click)="UpdateOrder(values, updateOrderId, true, '2')"
                  class="btn blue-bg round-btn" [disabled]="!togglevalidAddBtn">
                  <i class="fas fa-save" aria-hidden="true"></i>
                  {{ saveasOrder }}
                </button>
              </span>

              <span *ngIf="issaleOrderChecked == true">
                <button *ngIf="
                    rolePermissionsList &&
                    rolePermissionsList[0].isInsert &&
                    partList.length > 0
                  " (click)="ChangeOrder(true)" class="btn blue-bg round-btn">
                  <i class="fas fa-save" aria-hidden="true"></i> Change Order
                </button>
              </span>

              <span *ngIf="issaleOrderChecked == false">
                <button class="btn darkred-bg round-btn" (click)="DiscrdProduct(discardItem)">
                  <i class="fas fa-sign-out-alt"></i>Exit
                </button>
              </span>
              <span *ngIf="issaleOrderChecked == true">
                <button class="btn darkred-bg round-btn" (click)="ChangeOrderExit()">
                  <i class="fas fa-sign-out-alt"></i>Exit
                </button>
              </span>
            </div>
          </div>
        </div>


      </div>
    </div>
  </div>
  <div class="col-md-8">
    <div class="row">
      <div class="col-lg-12 text-left col-md-7 pl-0">
        <span *ngIf="IsEnableAprrovel == true">
          <button class="btn blue-bg round-btn part-category" (click)="ViewLogDataShow()">
            <i class="fas fa-down-circle" aria-hidden="true"></i>View Log
            <i class="ml-1 fas fa-angle-{{ ListAngle }}" aria-hidden="true"></i>
          </button>
        </span>
        <span *ngIf="IsShow == true">
          <button class="btn blue-bg round-btn part-category" (click)="ViewHistoryLog()">
            <i class="fas fa-down-circle" aria-hidden="true"></i>SO History Log
            <i class="ml-1 fas fa-angle-{{ ListAngle }}" aria-hidden="true"></i>
          </button>
        </span>
      </div>
      <div class="col-lg-12 col-md-12 pl-0" style="display: none" id="divlog">
        <div>
          <table class="table table-bordered mb-0 mt-0 LogContent">
            <tr style="background-color: #dee2e6">
              <td style="width: 16%"><b>Date time</b></td>
              <td style="width: 16%"><b>User name</b></td>
              <td style="width: 20%"><b>Action</b></td>
              <td style="width: 43%"><b>Reason</b></td>
              <td style="width: 5%"><b>View</b></td>
            </tr>
            <tr *ngFor="let logitem of SaleOrderLog">
              <td class="customLabel">
                {{ logitem.createdDate | date : "MMM-dd-yyyy hh:mm:ss a" }}
              </td>
              <td class="customLabel">{{ logitem.userName }}</td>
              <td class="customLabel">{{ logitem.action }}</td>
              <td *ngIf="logitem.reason != null" class="customLabel">
                {{ logitem.reason }}
              </td>
              <td *ngIf="logitem.reason == null" class="customLabel">
                SO-{{ logitem.saleOrderNo }}
              </td>
              <td class="customLabel" (click)="LogPdfOpen(logitem.attachedPOPDF)">
                <span *ngIf="logitem.action == 'Mail sent'"><i class="fa fa-paperclip" style="cursor: pointer"
                    aria-hidden="true" data-toggle="modal"></i></span><span
                  *ngIf="logitem.action != 'Mail sent'">N/A</span>
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div class="col-lg-12 col-md-12 pl-0" *ngIf="showHistoryLog == true">
        <div>
          <table class="table table-bordered mb-0 mt-0 LogContent">
            <tr style="background-color: #dee2e6">
              <td style="width: 16%"><b>Date time</b></td>
              <td style="width: 16%"><b>User name</b></td>
              <td style="width: 10%"><b>Action</b></td>
              <td style="width: 10%"><b>Status Type</b></td>
              <!-- <td style="width: 10%;"><b>Reason Type</b></td> -->
              <td style="width: 53%"><b>Reason</b></td>
              <td style="width: 5%"><b>View</b></td>
            </tr>
            <tr *ngFor="let logitem of SaleHistoryLog">
              <td class="customLabel">
                {{ logitem.createdDate | date : "MMM-dd-yyyy hh:mm:ss a" }}
              </td>
              <td class="customLabel">{{ logitem.userName }}</td>
              <td class="customLabel">{{ logitem.status }}</td>
              <td class="customLabel">
                {{
                logitem.statusId == 1
                ? "Open"
                : logitem.statusId == 2
                ? "Sale Quote
                "
                : logitem.statusId == 3
                ? "Cancel"
                : logitem.statusId == 5
                ? "Complete"
                : logitem.statusId == 6
                ? "Processing"
                : logitem.statusId == 7
                ? "Shipped"
                : logitem.statusId == 8
                ? "On Hold"
                : logitem.statusId == 9
                ? "In Production"
                : ""
                }}
              </td>
              <!-- <td class="customLabel">{{logitem.statusType}}</td> -->
              <td class="customLabel">{{ logitem.reasonCode }}</td>
              <td class="customLabel" (click)="LogPdfOpen(logitem.attachedPOPDF)">
                <span *ngIf="logitem.action == 'Mail sent'"><i class="fa fa-paperclip" style="cursor: pointer"
                    aria-hidden="true" data-toggle="modal"></i></span><span
                  *ngIf="logitem.action != 'Mail sent'">N/A</span>
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
  </div>

  <div class="modal" id="addpartModalpopup">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
      <div class="modal-content">
        <h5 class="modal-title info-popup-title p-1" id="exampleModalLabel1">
          <div class="text-over-ellipse pl-2 text-white">
            {{ partObjectModel.name }}
          </div>
        </h5>
        <button class="close btn alert_modal" aria-label="Close" (click)="ClosePartModalPopup()">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-4">
              <div class="row">
                <div class="col-md-6">
                  <label class="customLabel">Company Part #</label>
                </div>
                <div class="col-md-6 f_size_10 customLabel">
                  {{ partObjectModel.partNo }}
                </div>
              </div>
            </div>
            <div class="col-md-8">
              <div class="row">
                <div class="col-md-4">
                  <label class="customLabel">Company Part Name</label>
                </div>
                <div class="col-md-8 f_size_10 text-over-ellipse customLabel">
                  {{ partObjectModel.name }}
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-1">
            <div class="col-md-4">
              <div class="row">
                <div class="col-md-6">
                  <label class="customLabel">Vendor Part #</label>
                </div>
                <div class="col-md-6 f_size_10 customLabel">
                  {{ partObjectModel.vendorPartNumber }}
                  <span style="display: none">{{
                    partObjectModel.vendorName
                    }}</span>
                </div>
              </div>
            </div>
            <div class="col-md-8">
              <div class="row">
                <div class="col-md-4">
                  <label class="customLabel">Vendor Part Name</label>
                </div>
                <div class="col-md-8 f_size_10 text-over-ellipse customLabel">
                  {{ partObjectModel.vendorPartName }}
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-4">
            <div class="col-md-2">
              <div class="d-flex align-items-center">
                <label class="customLabel mr-2">Quantity</label>
                <input type="text" [(ngModel)]="partObjectModel.qty" class="customInput NumberOnly" placeholder="Qty"
                  id="quantity" maxlength="5" min="1" max="99" />
                <div *ngIf="quantity == '' && validationPart" class="text-danger input-error">
                  Enter Quantity.
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-md-12">
              <div class="customHeading">Part Questions</div>
            </div>
            <div class="col-md-12 mt-2">
              <div class="row pb-2" *ngFor="
                  let item of partObjectModel.partQuestions;
                  let i = index
                ">
                <div class="col-md-8">
                  <label class="customLabel">{{ item.questionName }}</label>
                </div>

                <div class="col-md-4" *ngIf="item.typeKey == 'Text'">
                  <input type="text" [(ngModel)]="item.answerName" class="customInput" placeholder="Type Answer"
                    maxlength="50" />
                </div>
                <div class="col-md-4" *ngIf="item.typeKey == 'VendorName'">
                  <select class="form-control newsmallddl" [(ngModel)]="item.answerName">
                    <option value="null">Select Vendor</option>
                    <option *ngFor="let item of vendorList">
                      {{ item.vendorName }}
                    </option>
                  </select>
                </div>
                <div class="col-md-4" *ngIf="item.typeKey == 'CustomerName'">
                  <select class="form-control newsmallddl" [(ngModel)]="item.answerName">
                    <option value="null" selected>Select Customer</option>
                    <option *ngFor="let item of customerData">
                      {{ item.customerName }}
                    </option>
                  </select>
                </div>
                <div class="col-sm-4" *ngIf="item.typeKey == 'YesNo'">
                  <select class="form-control newsmallddl" [(ngModel)]="item.answerName">
                    <option value="null" selected>Select Yes/No</option>
                    <option value="Yes">Yes</option>
                    <option value="No">No</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="submit-from text-right">
            <button class="btn blue-bg round-btn" (click)="addPart(partObjectModel)">
              <i class="fas fa-save" aria-hidden="true"></i> Add Part
            </button>
            <button class="btn darkred-bg round-btn" (click)="ClosePartModalPopup()">
              <i aria-hidden="true"></i> Exit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal" id="engineModel" style="z-index: 1080">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header1 darkred-bg sweet-heading p-0">
          <h4 class="modal-title sweet-heading"></h4>
          <button type="button" class="close btn alert_modal" (click)="CloseEngineModel()" data-dismiss="modal">
            &times;
          </button>
        </div>
        <div class="modal-body p-1">
          <div class="thank-you-pop">
            <img src="./assets/image/popup-alert.png" alt="" />
            <h1>Alert</h1>
            <p>USPS does not support mutipackages, Please choose another package</p>
          </div>
        </div>
        <div class="modal-footer sweet-footer p-1">
          <button type="button" class="btn red-bg round-btn" (click)="CloseEngineModel()" data-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="modal" id="validAddress">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body p-1">
          <div class="row p-3">
            <div class="col-lg-12 d-flex justify-content-end">
              <button class="btn" (click)="closevalidaddress()">X</button>
            </div>
            <div class="col-lg-12">
              <p class="h5">
                We are unable to verify your address. Please, update the suggested address.
              </p>
            </div>
            <div class="col-lg-12">
              <div class="row">
                <div class="col-md-6 p-3">
                  <div class="mb-3">
                    <h5 class="text-primary">You Entered</h5>
                  </div>
                  <div>
                    <ul class="list-unstyled">
                      <li>{{ShipAddress1}}</li>
                      <li>{{ShipCity}}</li>
                      <li>{{ShipZip}}</li>
                      <li>{{ShipSAbrev}}</li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-6 p-3">
                  <div class="mb-3">
                    <h5 class="text-primary">Suggested Address</h5>
                  </div>
                  <div>
                    <ul class="list-unstyled">
                      <li>{{address_line1}}</li>
                      <li>{{city_locality}}</li>
                      <li>{{postal_code}}</li>
                      <li>{{state_province}}</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12">
              <button (click)="autofillAddress()" type="button" class="h6 text-light bg-danger w-100 p-2 rounded">USE
                SUGGESTED</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal" id="ManuallyCalModal">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body p-1">
          <form [formGroup]="formManualCal">
            <div class="row p-3">
              <div class="col-lg-12">
                <p class="h5 text-danger text-center">
                  <!-- The following Calculations Failed. -->
                  Override Shipping and Taxes.
                </p>
              </div>
              <div class="col-lg-12">
                <div class="row">
                  <div class="col-md-6 p-3">
                    <div class="mb-1 required">
                      <span class="customLabel">Carrier:</span>
                    </div>
                    <div>
                      <select name="" id="" formControlName="ManualServiceType"
                        class="form-control1 customInput ng-pristine ng-valid ng-touched"
                        [(ngModel)]="ManualServiceType"
                        [ngClass]="!ManualServiceType && ManualValid ? 'validation_border' : ''">
                        <option value="">Select</option>
                        <option value="Fedex Next Day">Fedex Next Day</option>
                        <option value="Fedex 2nd Day">Fedex 2nd Day</option>
                        <option value="Fedex 3rd Day">Fedex 3rd Day</option>
                        <option value="Fedex Ground">Fedex Ground</option>
                        <option value="UPS Next Day">UPS Next Day</option>
                        <option value="UPS 2nd Day">UPS 2nd Day</option>
                        <option value="UPS 3rd Day">UPS 3rd Day</option>
                        <option value="UPS Ground">UPS Ground</option>
                        <option value="USPS">USPS</option>
                        <option value="Delivery">Delivery</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-6 px-3 py-1">
                    <div class="mb-1 required">
                      <span class="customLabel">Amount:</span>
                    </div>
                    <div class="input-group">
                      <span class="mt-auto mb-auto mr-3" id="basic-addon1">$</span>
                      <input type="number" formControlName="ManualAmount" class="form-control"
                        [(ngModel)]="ManualAmount" placeholder="Amount" aria-label="Username"
                        aria-describedby="basic-addon1"
                        [ngClass]="!ManualAmount && ManualValid ? 'validation_border' : ''">
                    </div>
                  </div>
                  <div class="col-md-6 px-3 py-1">
                    <div class="mb-1 required">
                      <span class="customLabel">Tax:</span>
                    </div>
                    <div class="input-group">
                      <input type="number" formControlName="ManualTax" class="form-control" [(ngModel)]="ManualTax"
                        placeholder="Tax" aria-label="Username" aria-describedby="basic-addon1"
                        [ngClass]="!ManualTax && ManualValid ? 'validation_border' : ''">
                      <span class="mt-auto mb-auto ml-3" id="basic-addon1">%</span>
                    </div>
                  </div>
                  <div class="col-md-6 px-3 py-1">
                    <div class="mb-1">
                      <span class="customLabel">&nbsp;</span>
                    </div>
                    <div class="input-group">
                      <input [checked]="chkValidateAddress" class="custom_checkbox mr-2 mt-auto mb-auto" type="checkbox"
                        ng-reflect-model="false" (click)="ChkValidateAddress($event)" />
                      <span class="" id="basic-addon1">Turn Validate Address Off</span>
                    </div>
                  </div>
                  <div class="col-md-6 px-3 pt-1 pb-3">
                    <div class="mb-1 required">
                      <span class="customLabel">Reason Code:</span>
                    </div>
                    <div>
                      <select name="" id="" formControlName="ManualReasonCode"
                        class="form-control1 customInput ng-pristine ng-valid ng-touched" [(ngModel)]="ManualReasonCode"
                        [ngClass]="!ManualReasonCode && ManualValid ? 'validation_border' : ''">
                        <option value="">Select</option>
                        <option value="Address Not Found">Address Not Found</option>
                        <option value="Calculations Incorrect">Calculations Incorrect</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <button (click)="UpdateManullyCal(content, updateOrderId)" type="button"
                  class="h6 text-light bg-primary w-100 p-2 rounded">Update</button>
              </div>
              <div class="col-lg-6">
                <button type="button" class="h6 text-light bg-danger w-100 p-2 rounded"
                  (click)="ManuallyCalModalClose()">Exit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>

  <!--
Modal for invalid shipping address
-->

  <div class="modal" id="invalidAddress">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header1 darkred-bg sweet-heading p-0">
          <h4 class="modal-title sweet-heading"></h4>
          <button type="button" class="close btn alert_modal" (click)="invalidAddress()"
            data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body p-1">
          <div class="thank-you-pop">
            <img src="./assets/image/close.png" alt="">
            <h1>Alert</h1>
            <p><strong>Ship To Address</strong> is Invalid.</p>
            <p>Please enter valid shipping address.</p>
          </div>
        </div>
        <div class="modal-footer sweet-footer p-1">
          <button type="button" class="btn red-bg round-btn" (click)="invalidAddress()"
            data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>

  <!--
  Modal for invalid Customer Form
-->
  <div class="modal" id="invalidForm">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header1 darkred-bg sweet-heading p-0">
          <h4 class="modal-title sweet-heading"></h4>
          <button type="button" class="close btn alert_modal" (click)="invalidForm()"
            data-dismiss="modal">&times;</button>
        </div>
        <div class="modal-body p-1">
          <div class="thank-you-pop">
            <img src="./assets/image/close.png" alt="">
            <h1>Alert</h1>
            <p>Please Enter the Required Field!</p>
          </div>
        </div>
        <div class="modal-footer sweet-footer p-1">
          <button type="button" class="btn red-bg round-btn" (click)="invalidForm()" data-dismiss="modal">Close</button>
        </div>
      </div>
    </div>
  </div>

  <!-- Generate Sale order number   -->
  <div class="modal" id="SaleorderNumber">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header info-popup-header p-2">
          <button type="button" class="close" (click)="SaleorderNumber()" data-dismiss="modal" aria-label="">
            <span>×</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="thank-you-pop">
            <img src="./assets/image/Green-Round-Tick.png" alt="" />
            <h1>Thank You!</h1>
            <p>Your Sale Order Has Been Saved</p>
            <h3 class="cupon-pop" style="font-size: 12px">
              Your Sale Order Number:
              <span>{{ SOGenerateNumber }}</span>
            </h3>
            <div class="row">
              <div class="col-md-4">
                <select class="pl-1px customInput PaymentMode" (change)="onChangePrint($event.target)">
                  <option value="" disabled>--Selection List--</option>
                  <option value="SalesOrder">Sales Order</option>
                  <option value="Invoice">Invoice</option>
                  <option value="PackingSlip">Packing Slip</option>
                </select>
              </div>
              <p class="mb-0">
                <button class="btn blue-bg round-btn" (click)="DownloadPdf()">
                  Export PDF&nbsp;&nbsp;<i class="fa fa-file-pdf-o text-white" aria-hidden="true"></i>
                </button>
                <button class="btn blue-bg round-btn" (click)="PopSendMail()">
                  Send Email&nbsp;&nbsp;<i class="fa fa-file-pdf-o text-white" aria-hidden="true"></i>
                </button>
                <!-- <button class="btn blue-bg round-btn" (click)="Stay(this.SendMailSoId)" style="padding: 3px 12px;width: 100px;">Stay&nbsp;&nbsp;</button> -->
              </p>
            </div>
          </div>
          <div class="modal-footer sweet-footer p-1">
            <button type="button" class="btn blue-bg round-btn" (click)="Stay(this.SendMailSoId)" data-dismiss="modal">
              Stay
            </button>
            <button type="button" class="btn red-bg round-btn" (click)="SaleorderNumber()" data-dismiss="modal">
              Exit
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- -- -->
  <ng-template #contentname let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg sweet-heading"></h4>
    <button class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body p-1">
      <div class="thank-you-pop">
        <img src="./assets/image/popup-alert.png" alt="" />
        <h1>Alert</h1>
        <p class="mt-1">{{ confirmationMsg }}</p>
      </div>
    </div>
    <div class="modal-footer sweet-footer text-center p-1">
      <button *ngIf="confirmationAletType == 'ConfirmCategory'" type="button" class="btn roundBtn red-bg"
        (click)="c('Close click')">
        No
      </button>
      <button *ngIf="
          rolePermissionsList &&
          rolePermissionsList[0].isInsert &&
          confirmationAletType == 'ConfirmOrderSave'
        " type="button" class="btn green-bg round-btn" (click)="SaveOrder(); c('Close click')">
        Yes
      </button>
      <button *ngIf="
          rolePermissionsList &&
          rolePermissionsList[0].isInsert &&
          confirmationAletType == 'ConfirmOrderUpdate'
        " type="button" class="btn green-bg round-btn"
        (click)="UpdateOrder(contentname, updateOrderId); c('Close click')">
        Yes
      </button>
      <button type="button" class="btn red-bg round-btn" (click)="c('Close click')">
        No
      </button>
    </div>
  </ng-template>
</section>

<ng-template #content let-c="close" let-d="dismiss">
  <h4 class="modal-title darkred-bg alert_heading sweet-heading"></h4>
  <button class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>

  <div class="modal-body p-1">
    <div class="thank-you-pop">
      <img src="./assets/image/popup-alert.png" alt="" />
      <h1>Confirmation</h1>
      <p class="mt-1">{{ deleteDetail }}</p>
    </div>
  </div>
  <div class="modal-footer sweet-footer text-center p-1">
    <button type="button" class="btn green-bg round-btn m-1" (click)="DeletePart(); c('Close click')">
      Yes
    </button>
    <button type="button" class="btn red-bg round-btn m-1" (click)="c('Close click')">
      No
    </button>
  </div>
</ng-template>

<ng-template #statuscontent let-c="close" let-d="dismiss">
  <h4 class="modal-title darkred-bg alert_heading sweet-heading"></h4>
  <button class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
  <div class="modal-body p-1">
    <div class="thank-you-pop">
      <img src="./assets/image/popup-alert.png" alt="" />
      <h1>Confirmation</h1>
      <p class="mt-1">{{ deleteDetail }}</p>
    </div>
  </div>
  <div class="modal-footer sweet-footer text-center p-1">
    <button type="button" class="btn green-bg round-btn m-1" (click)="UpdateStatus(); c('Close click')">
      Yes
    </button>
    <button type="button" class="btn red-bg round-btn m-1" (click)="c('Close click')">
      No
    </button>
  </div>
</ng-template>

<div class="modal" id="myModal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header1 darkred-bg sweet-heading p-0">
        <h4 class="modal-title sweet-heading"></h4>
        <button type="button" class="close btn alert_modal" (click)="hidemodalpopup()" data-dismiss="modal">
          &times;
        </button>
      </div>
      <div class="modal-body p-1">
        <div class="thank-you-pop">
          <img src="./assets/image/popup-alert.png" alt="" />
          <h1>Alert</h1>
          <p>
            You have not added any part in order, so please add at least one
            part.
          </p>
        </div>
      </div>
      <div class="modal-footer sweet-footer p-1">
        <button type="button" class="btn red-bg round-btn" (click)="hidemodalpopup()" data-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>

<!-- pdf Export -->
<div style="display: none">
  <div class="row" id="content1" style="width: 800px !important" #content1>
    <style type="text/css" media="only print and (max-width: 1920px)">
      @page {
        size: A4;
      }

      #content1 .table td,
      #content1 .table th {
        padding: 4px !important;
        font-weight: 700 !important;
        height: 23px;
      }

      #content1 td {
        font-size: 0.7rem;
      }

      #content1 table th {
        font-size: 0.75rem !important;
      }

      * {
        box-sizing: border-box;
      }

      #content1 {
        font-family: Arial, Helvetica, sans-serif;
      }

      #content1 {
        max-width: 800px;
        margin: auto;
        padding: 20px 10px 10px;
      }

      .container .row>div>div {
        font-size: 0.7rem;
      }

      #content1 .vdetails,
      #content1 .cdetails,
      #content1 .sidedetails {
        display: flex;
      }

      #content1 .vdetails p,
      #content1 .cdetails p,
      #content1 .vdetails label,
      #content1 .cdetails label {
        white-space: nowrap;
        text-align: left !important;
      }

      #content1 .vdetails p,
      #content1 .cdetails p,
      #content1 .sidedetails p {
        font-weight: Bold !important;
        margin-left: 5px !important;
        margin-bottom: 0 !important;
        text-align: left !important;
      }

      #content1 table.order-detail tr th:nth-child(2),
      table.order-detail tr td:nth-child(2) {
        text-align: left;
      }

      #content1 .qus {
        font-size: 0.8rem;
      }

      #content1 .order-detail {
        font-size: 0.8rem;
      }

      #content1 .order-detail tfoot td:first-child {
        border: none;
      }

      #content1 .order-detail-main table {
        border-collapse: collapse;
        border: 1px solid #000;
        font-size: 0.6rem;
      }

      #content1 .order-detail-main tr,
      #content1 .order-detail-main td {
        border: none !important;
      }

      #content1 .order-detail-main table thead tr th,
      #content1 .order-detail-main table tbody tr td,
      #content1 .order-detail-main table tfoot tr td {
        padding: 2px 0.5rem;
      }

      #content1 .order-detail-main table thead tr th {
        background-color: transparent;
        background-color: transparent;
        border-right: 1px solid #000 !important;
        border-bottom: 1px solid #000 !important;
        border-left: none;
        border-top: none;
      }

      #content1 .order-detail-main table tfoot tr td {
        font-weight: bold;
      }

      #content1 .cdetails {
        justify-content: flex-start;
        align-items: center;
      }

      .order-detail-main table tr th,
      .order-detail-main table tr td {
        font-size: 0.6rem;
        font-weight: bold;
      }

      tr.hoverEffect.OrderPartsDetails td:first-child {
        width: 400px;
      }

      #content1 .order-detail-main table tr td {
        border-bottom: 1px solid #000 !important;
      }

      #content1 .order-detail-main table tr td:first-child {
        border-right: 1px solid #000 !important;
      }

      #content1 .order-detail-main table tr th:last-child {
        text-align: right;
      }

      #content1 .order-detail-main table tr td:last-child {
        border-left: 1px solid #000 !important;
        text-align: right;
      }

      #content1 tr td.paddingLeft {
        padding-left: 30% !important;
      }

      .paddingLeft label {
        margin-bottom: 0;
      }

      .order-detail-main .order-detail {
        margin-top: 5px;
      }

      .cdetails label,
      .cdetails p,
      .vdetails p {
        font-size: 11px;
      }

      @import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&display=swap");
    </style>
    <div class="container pdfFont">
      <div class="row">
        <div class="col-md-12 text-center">
          <p *ngIf="this.profileLogo">
            <img src="this.profileLogo" class="hrdLogoSize" id="hrdlogoImage1" />
          </p>
          <p style="
              margin-bottom: -8px;
              font-size: 0.7rem;
              margin-top: 0.5rem;
              font-weight: bold;
              font-family: Arial, Helvetica, sans-serif;
            ">
            {{ companyDetails.companyName }}
          </p>
          <p style="
              margin-bottom: 0.5rem;
              font-size: 0.7rem;
              margin-top: 0.5rem;
              font-weight: bold;
              font-family: Arial, Helvetica, sans-serif;
            " [innerHtml]="
              this.sharedService.FormatAddress(
                companyDetails.addressLine1,
                companyDetails.addressLine2,
                companyDetails.city,
                '',
                companyDetails.zipCode
              )
            ">
            {{ companyDetails.phoneNumber }}
          </p>
        </div>
      </div>
      <div class="row">
        <div class="col-md-5 text-left">
          <div>
            <div class="vdetails">
              <p style="
                  font-family: Arial, Helvetica, sans-serif;
                  text-align: left;
                ">
                {{ customerDetails.customerName }}
              </p>
            </div>
            <div class="vdetails">
              <p style="
                  white-space: break-spaces;
                  font-family: Arial, Helvetica, sans-serif;
                " [innerHtml]="
                  this.sharedService.FormatAddress(
                    customerDetails.addressLine1,
                    customerDetails.addressLine2,
                    customerDetails.city,
                    customerDetails.statename,
                    customerDetails.zipCode
                  )
                "></p>
            </div>
          </div>
        </div>
        <div class="col-md-3"></div>
        <div class="col-md-4">
          <div style="padding: 10px; border: 1px solid #000">
            <div class="cdetails" style="text-align: left">
              <label style="
                  word-spacing: 0 !important;
                  font-family: Arial, Helvetica, sans-serif;
                  margin-bottom: 0;
                  text-align: left;
                  font-weight: bold;
                ">Contact:</label>
              <p style="
                  font-family: Arial, Helvetica, sans-serif;
                  text-align: left;
                  margin: 0px;
                  padding: 0px;
                  white-space: nowrap;
                  font-weight: normal !important;
                  margin-bottom: 0 !important;
                  width: 70px;
                ">
                {{ customerDetails.firstName }}{{ customerDetails.lastName }}
              </p>
            </div>
            <div class="cdetails">
              <label style="
                  font-family: Arial, Helvetica, sans-serif;
                  margin-bottom: 0;
                  text-align: left;
                  font-weight: bold;
                ">Phone:</label>
              <p style="
                  font-family: Arial, Helvetica, sans-serif;
                  white-space: nowrap;
                  font-weight: normal !important;
                  margin-bottom: 0 !important;
                  width: 70px;
                ">
                {{ customerDetails.phone }}
              </p>
            </div>

            <div class="cdetails">
              <label style="
                  font-family: Arial, Helvetica, sans-serif;
                  margin-bottom: 0;
                  text-align: left;
                  font-weight: bold;
                ">
                Email:</label>
              <p style="
                  font-family: Arial, Helvetica, sans-serif;
                  white-space: nowrap;
                  font-weight: normal !important;
                  margin-bottom: 0 !important;
                  width: 70px;
                ">
                {{ customerDetails.email }}
              </p>
            </div>
            <div class="cdetails">
              <label style="
                  font-family: Arial, Helvetica, sans-serif;
                  margin-bottom: 0;
                  text-align: left;
                  font-weight: bold;
                ">
                {{ statusDropdownList == 2 ? "Sales Quote #" : "Sale Order #" }}
                :
              </label>
              <p style="
                  font-family: Arial, Helvetica, sans-serif;
                  white-space: nowrap;
                  font-weight: normal !important;
                  margin-bottom: 0 !important;
                  width: 70px;
                ">
                {{
                orderNumber == "" ||
                orderNumber == null ||
                orderNumber == undefined
                ? "Pending"
                : orderNumber
                }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-12 pdfFont">
      <div class="order-detail-main">
        <table class="display table clearfix order-detail">
          <thead>
            <tr>
              <th width="5%" style="
                  font-family: Arial, Helvetica, sans-serif;
                  font-size: 0.7rem !important;
                ">
                Qty
              </th>
              <th width="75%" style="
                  font-family: Arial, Helvetica, sans-serif;
                  font-size: 0.7rem !important;
                ">
                Breakdown with labor:
              </th>
              <th width="20%" style="
                  font-family: Arial, Helvetica, sans-serif;
                  font-size: 0.7rem !important;
                ">
                Extension Total
              </th>
            </tr>
          </thead>
          <tbody>
            <ng-container>
              <ng-container *ngFor="let parts of partList">
                <tr>
                  <td style="
                      font-family: Arial, Helvetica, sans-serif;
                      font-size: 0.7rem !important;
                      text-align: center;
                    ">
                    {{ parts.quantity }}
                  </td>
                  <td style="
                      font-family: Arial, Helvetica, sans-serif;
                      white-space: pre-wrap;
                      font-size: 0.7rem !important;
                    ">
                    <span class="partdetail" (click)="OpenQuestion(parts.partId, 'open')"
                      id="partname-{{ parts.partId }}">
                      <i class="fas fa-plus" style="display: none"></i> </span>{{ parts.partName
                    }}<span *ngIf="parts.cost == 0"> (CS)</span>
                  </td>

                  <td style="
                      font-family: Arial, Helvetica, sans-serif;
                      font-size: 0.7rem !important;
                    ">
                    {{
                    parts.cost * parts.quantity +
                    parts.hours * laborHrsCost * parts.quantity | currency
                    }}
                  </td>
                </tr>
              </ng-container>
            </ng-container>
            <tr>
              <td style="padding: 0.8rem"></td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <td style="padding: 0.8rem"></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td style="font-family: Arial, Helvetica, sans-serif"></td>
              <td style="
                  font-family: Arial, Helvetica, sans-serif;
                  padding-left: 300px;
                  font-size: 0.7rem !important;
                " class="discount paddingLeft">
                <label style="padding-left: 200px">
                  <b style="font-size: 0.7rem !important"> SUBTOTALS </b>
                </label>
              </td>
              <td style="
                  font-family: Arial, Helvetica, sans-serif;
                  font-size: 0.7rem !important;
                " class="discount">
                {{ GrandTotalAmount | currency }}
              </td>
            </tr>

            <tr *ngIf="discount != 0">
              <td style="font-family: Arial, Helvetica, sans-serif"></td>
              <td style="
                  font-family: Arial, Helvetica, sans-serif;
                  padding-left: 300px;
                  font-size: 0.7rem !important;
                " class="discount paddingLeft">
                <label style="padding-left: 200px">
                  <b> DISCOUNT</b>
                </label>
              </td>
              <td style="
                  font-family: Arial, Helvetica, sans-serif;
                  font-size: 0.7rem !important;
                " class="discount">
                <span style="color: #f00">{{ discount | currency }}</span>
              </td>
            </tr>
            <tr>
              <td style="font-family: Arial, Helvetica, sans-serif"></td>
              <td style="
                  font-family: Arial, Helvetica, sans-serif;
                  padding-left: 300px;
                  font-size: 0.7rem !important;
                " class="discount paddingLeft">
                <label style="padding-left: 200px">
                  <b style="
                      font-size: 0.7rem !important;
                      font-family: Arial, Helvetica, sans-serif;
                    ">
                    SUBTOTAL DISCOUNT</b>
                </label>
              </td>
              <td style="
                  font-family: Arial, Helvetica, sans-serif;
                  font-size: 0.7rem !important;
                " class="discount">
                {{ SubTotalWithDisnt | currency }}
              </td>
            </tr>
            <tr>
              <td style="font-family: Arial, Helvetica, sans-serif"></td>
              <td style="
                  font-family: Arial, Helvetica, sans-serif;
                  padding-left: 300px;
                  font-size: 0.7rem !important;
                " class="discount paddingLeft">
                <label style="padding-left: 200px">
                  <b style="
                      font-size: 0.7rem !important;
                      font-family: Arial, Helvetica, sans-serif;
                    ">
                    SALES TAX ({{ SaleTaxAmount }}%)
                  </b>
                </label>
              </td>
              <td style="
                  font-family: Arial, Helvetica, sans-serif;
                  font-size: 0.7rem !important;
                " class="discount">
                {{ TaxTotalAmount | currency }}
              </td>
            </tr>
            <tr>
              <td style="font-family: Arial, Helvetica, sans-serif"></td>
              <td style="
                  font-family: Arial, Helvetica, sans-serif;
                  padding-left: 400px;
                  font-size: 0.7rem !important;
                " class="discount paddingLeft">
                <label style="padding-left: 200px">
                  <b style="
                      color: rgb(9, 95, 23);
                      font-size: 12px;
                      font-size: 0.7rem !important;
                    ">TOTAL</b>
                </label>
              </td>
              <td style="
                  font-family: Arial, Helvetica, sans-serif;
                  text-align: right;
                  font-size: 0.7rem !important;
                ">
                <b style="color: rgb(9, 95, 23); font-size: 12px">
                  {{ finalAmount | currency }}
                </b>
              </td>
            </tr>
          </tfoot>
        </table>
        <p style="font-family: Arial, Helvetica, sans-serif; font-size: 11px">
          {{ companyDetails.notes == "null" ? "" : companyDetails.notes }}
        </p>
      </div>

      <div>
        <div class="submit-from text-right"></div>
      </div>
    </div>
  </div>
</div>

<!-- End pdf Export -->
<!-- word export -->
<!-- word export -->
<div class="row" id="contentWordPart" #contentWordPart>
  <style>
    .mainword-tbl {
      font-family: "Open Sans", sans-serif;
    }

    .word-tbl-style {
      border-collapse: collapse;
    }

    .word-tbl-style tr td {
      border: 1px solid #000000 !important;
      text-align: left;
      font-weight: bold;
      padding-left: 10px;
      padding-right: 10px;
    }

    .no-border-tbl tr td {
      border: none !important;
    }
  </style>

  <table width="100%" cellpadding="0" cellspacing="0" class="mainword-tbl" style="border: none; margin: 0px">
    <tr>
      <td>
        <table width="100%" align="center" style="text-align: center; border: none; margin: 0px">
          <tr *ngIf="this.profileLogo">
            <td>
              <img src="this.profileLogo" class="hrdLogoSize" id="hrdlogoImage2" />
            </td>
          </tr>
          <tr>
            <td style="text-align: center">
              <table width="100%" cellpadding="0" cellspacing="0" align="center"
                style="margin: 0px; text-align: center; border: none">
                <tr>
                  {{
                  companyDetails.companyName
                  }}
                </tr>
                <tr>
                  <td [innerHtml]="
                      this.sharedService.FormatAddress(
                        companyDetails.addressLine1,
                        companyDetails.addressLine2,
                        companyDetails.city,
                        '',
                        companyDetails.zipCode
                      )
                    "></td>
                </tr>
                <tr>
                  <td>{{ companyDetails.phoneNumber }}</td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </td>
    </tr>
    <tr>
      <td>
        <table width="100%" style="border: none; margin: 0px" cellpadding="0" cellspacing="0">
          <tr>
            <td style="vertical-align: top; font-size: 12px" width="50%">
              <table width="100%" cellpadding="0" cellspacing="0" style="border: none; margin: 0px; text-align: left">
                <tr>
                  <td style="font-weight: bold">
                    {{ customerDetails.customerName }}
                  </td>
                </tr>
                <tr>
                  <td style="font-weight: bold" [innerHtml]="
                      this.sharedService.FormatAddress(
                        customerDetails.addressLine1,
                        customerDetails.addressLine2,
                        customerDetails.city,
                        customerDetails.statename,
                        customerDetails.zipCode
                      )
                    "></td>
                </tr>
              </table>
            </td>
            <td align="right" style="
                text-align: right;
                vertical-align: top;
                font-size: 12px;
                border: 1px solid #000;
                padding: 10px;
              " width="30%">
              <table width="100%" cellpadding="0" cellspacing="0" style="text-align: right; margin: 0px; border: none">
                <tr>
                  <td style="text-align: left; width: 40%">Contact:</td>
                  <td style="text-align: left">
                    <b style="min-width: 30px; display: inline-block">{{ customerDetails.firstName
                      }}{{ customerDetails.lastName }}</b>
                  </td>
                </tr>
                <tr>
                  <td style="text-align: left; width: 40%">Phone:</td>
                  <td style="text-align: left">
                    <b style="min-width: 30px; display: inline-block">{{
                      customerDetails.phone
                      }}</b>
                  </td>
                </tr>
                <tr>
                  <td style="text-align: left; width: 40%">Email:</td>
                  <td style="text-align: left">
                    <b style="min-width: 30px; display: inline-block">{{
                      customerDetails.email
                      }}</b>
                  </td>
                </tr>
                <tr>
                  <td style="text-align: left; width: 40%">
                    {{
                    statusDropdownList == 2 ? "Sales Quote #" : "Sale Order #"
                    }}
                    :
                  </td>
                  <td style="text-align: left">
                    <b style="min-width: 30px; display: inline-block">{{
                      orderNumber == "" ||
                      orderNumber == null ||
                      orderNumber == undefined
                      ? "Pending"
                      : orderNumber
                      }}</b>
                  </td>
                </tr>
              </table>
            </td>
          </tr>
        </table>
      </td>
    </tr>
    <tr>
      <td style="padding-top: 5px">
        <table border="1" bordercolor="black" style="border-collapse: collapse; margin: 0px; margin-top: 5px"
          cellpadding="0" cellspacing="0" width="100%" class="word-tbl-style">
          <tr>
            <td style="padding: 0px 5px 0px 5px">Qty</td>
            <td style="padding: 0px 5px 0px 5px">Breakdown with labor:</td>
            <td style="text-align: right; padding: 0px 5px 0px 5px">
              Extension Total
            </td>
          </tr>
          <!-- <tr style="background: #dadada;">
                        <td>&nbsp;</td>
                        <td style="padding: 0px 5px 0px 5px;">Base Electrical Systems</td>
                        <td>&nbsp;</td>
                    </tr> -->
          <tr *ngFor="let parts of partList">
            <td style="padding: 0px 5px 0px 5px">{{ parts.quantity }}</td>
            <td style="padding: 0px 5px 0px 5px">{{ parts.partName }}</td>
            <td style="text-align: right; padding: 0px 5px 0px 5px">
              {{
              parts.cost * parts.quantity +
              parts.hours * laborHrsCost * parts.quantity | currency
              }}
            </td>
          </tr>
          <!-- <tr>
                        <td style="padding: 0px 5px 0px 5px;">1</td>
                        <td style="padding: 0px 5px 0px 5px;">Siren speakers mounted behind grill                </td>
                        <td style="text-align: right; padding: 0px 5px 0px 5px;">$1,202.24</td>
                    </tr> -->
          <tr>
            <td style="padding: 0px 5px 0px 5px">&nbsp;</td>
            <td style="padding: 0px 5px 0px 5px">&nbsp;</td>
            <td style="text-align: right; padding: 0px 5px 0px 5px">&nbsp;</td>
          </tr>
          <tr>
            <td style="padding: 0px 5px 0px 5px">&nbsp;</td>
            <td style="padding: 0px 5px 0px 5px">&nbsp;</td>
            <td style="text-align: right; padding: 0px 5px 0px 5px">&nbsp;</td>
          </tr>
          <tr>
            <td style="padding: 0px 5px 0px 5px">&nbsp;</td>
            <td>
              <table width="100%" cellpadding="0" cellspacing="0" class="no-border-tbl" style="margin: 0px">
                <tr>
                  <td style="padding: 0px 5px 0px 5px">&nbsp;</td>
                  <td style="padding: 0px 5px 0px 5px">SUBTOTALS</td>
                </tr>
              </table>
            </td>
            <td style="text-align: right; padding: 0px 5px 0px 5px">
              {{ GrandTotalAmount | currency }}
            </td>
          </tr>
          <tr>
            <td style="padding: 0px 5px 0px 5px">&nbsp;</td>
            <td>
              <table width="100%" cellpadding="0" cellspacing="0" class="no-border-tbl" style="margin: 0px">
                <tr>
                  <td style="padding: 0px 5px 0px 5px">&nbsp;</td>
                  <td style="padding: 0px 5px 0px 5px">DISCOUNT</td>
                </tr>
              </table>
            </td>
            <td style="text-align: right; color: #f00; padding: 0px 5px 0px 5px">
              {{ discount | currency }}
            </td>
          </tr>
          <tr>
            <td style="padding: 0px 5px 0px 5px">&nbsp;</td>
            <td>
              <table width="100%" cellpadding="0" cellspacing="0" class="no-border-tbl" style="margin: 0px">
                <tr>
                  <td style="padding: 0px 5px 0px 5px">&nbsp;</td>
                  <td style="padding: 0px 5px 0px 5px">SUBTOTAL DISCOUNT</td>
                </tr>
              </table>
            </td>
            <td style="text-align: right; padding: 0px 5px 0px 5px">
              {{ SubTotalWithDisnt + shippingAmount | currency }}
            </td>
          </tr>
          <tr>
            <td style="padding: 0px 5px 0px 5px">&nbsp;</td>
            <td>
              <table width="100%" cellpadding="0" cellspacing="0" class="no-border-tbl" style="margin: 0px">
                <tr>
                  <td style="padding: 0px 5px 0px 5px">&nbsp;</td>
                  <td style="padding: 0px 5px 0px 5px">
                    SALES TAX ({{ SaleTaxAmount }}%)
                  </td>
                </tr>
              </table>
            </td>
            <td style="text-align: right; padding: 0px 5px 0px 5px">
              {{ TaxTotalAmount | currency }}
            </td>
          </tr>
          <tr>
            <td style="padding: 0px 5px 0px 5px">&nbsp;</td>
            <td>
              <table width="100%" cellpadding="0" cellspacing="0" class="no-border-tbl" style="margin: 0px">
                <tr>
                  <td style="padding: 0px 5px 0px 5px">&nbsp;</td>
                  <td style="color: #095f17; padding: 0px 5px 0px 5px">
                    TOTAL
                  </td>
                </tr>
              </table>
            </td>
            <td style="
                text-align: right;
                color: #095f17;
                padding: 0px 5px 0px 5px;
              ">
              {{ finalAmount | currency }}
            </td>
          </tr>
        </table>
      </td>
    </tr>
    <tr>
      <td style="font-size: 12px; padding-top: 20px; text-align: left">
        {{ companyDetails.notes == "null" ? "" : companyDetails.notes }}
      </td>
    </tr>
  </table>
</div>
<!-- End Word -->
<!-- End Word -->
<div class="modal" id="myModal1">
  <div class="modal-dialog">
    <div class="modal-content">
      <h4 class="modal-title info-popup-header darkred-bg alert_heading sweet-heading"></h4>
      <button class="close btn alert_modal" aria-label="Close" (click)="hidemodalpopup1()">
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="modal-body">
        <div class="thank-you-pop">
          <img src="./assets/image/popup-alert.png" alt="" />
          <h1>Alert</h1>
          <p class="modal-para mb-0">
            You have Already Added this Part into List.
          </p>
        </div>
      </div>
      <div class="modal-footer sweet-footer">
        <button type="button" class="btn red-bg round-btn m-0" (click)="hidemodalpopup1()" data-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="CustomPartitem">
  <div class="modal-dialog">
    <div class="modal-content">
      <h4 class="modal-title info-popup-header darkred-bg alert_heading sweet-heading"></h4>
      <button class="close btn alert_modal" aria-label="Close" (click)="CustomPartPopup()">
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="modal-body">
        <div class="thank-you-pop">
          <img src="./assets/image/Green-Round-Tick.png" alt="" />
          <h1>Thanks</h1>
          <p class="modal-para mb-0">Part : {{ partObjectModel.name }} Added</p>
        </div>
      </div>
      <div class="modal-footer sweet-footer">
        <button type="button" class="btn red-bg round-btn m-0" (click)="CustomPartPopup()" data-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal" id="ShowShipping">
  <div class="modal-dialog">
    <div class="modal-content">
      <h4 class="modal-title info-popup-header darkred-bg alert_heading sweet-heading"></h4>
      <button class="close btn alert_modal" aria-label="Close" (click)="CloseShippingModel()">
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="modal-body">
        <div class="thank-you-pop">
          <img src="./assets/image/Green-Round-Tick.png" alt="" />
          <h1>Thanks</h1>
          <p class="modal-para mb-0">Recaluculating Shipping</p>
        </div>
      </div>
      <div class="modal-footer sweet-footer">
        <button type="button" class="btn red-bg round-btn m-0" (click)="CloseShippingModel()" data-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal" id="add_success">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header1 darkred-bg sweet-heading p-0">
        <h4 class="modal-title sweet-heading"></h4>
        <button type="button" class="close btn alert_modal" (click)="addsuccess()" data-dismiss="modal">
          &times;
        </button>
      </div>
      <div class="modal-body p-1">
        <div class="thank-you-pop">
          <img src="./assets/image/Green-Round-Tick.png" alt="" />
          <h1>Thank You!</h1>
          <p>Record Added Successfully</p>
        </div>
      </div>
      <div class="modal-footer sweet-footer p-1">
        <button type="button" class="btn red-bg round-btn" (click)="addsuccess()" data-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal" id="add_update">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header1 darkred-bg sweet-heading p-0">
        <h4 class="modal-title sweet-heading"></h4>
        <button type="button" class="close btn alert_modal" (click)="updateitemClose()" data-dismiss="modal">
          &times;
        </button>
      </div>
      <div class="modal-body p-1">
        <div class="thank-you-pop">
          <img src="./assets/image/Green-Round-Tick.png" alt="" />
          <h1>Thank You!</h1>
          <p>Record Updated Successfully</p>
          <div cls="col-md-12 mb-0" *ngIf="isOrderApproved == true">
            <div class="row">
              <div class="col-md-4">
                <select class="pl-1px customInput PaymentMode" (change)="onChangePrint($event.target)">
                  <option value="" disabled>--Selection List--</option>
                  <option value="SalesOrder">Sales Order</option>
                  <option value="Invoice">Invoice</option>
                  <option value="PackingSlip">Packing Slip</option>
                </select>
              </div>
              <div class="col-md-4">
                <button class="btn blue-bg round-btn" (click)="DownloadPdf()">
                  Export PDF&nbsp;&nbsp;<i class="fa fa-file-pdf-o text-white" aria-hidden="true"></i>
                </button>
              </div>
              <div class="col-md-4">
                <button class="btn blue-bg round-btn" (click)="PopSendMail()">
                  Send Email&nbsp;&nbsp;<i class="fa fa-file-pdf-o text-white" aria-hidden="true"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer p-1">
        <button type="button" class="btn blue-bg round-btn centbutton" (click)="GoUpdateOrder()" style="width: 104px">
          Stay
        </button>
        <button type="button" class="btn blue-bg round-btn centbutton" *ngIf="this.dashboardShow == true"
          (click)="GoUDashboard(isApproved)">
          Dashboard
        </button>
        <button type="button" class="btn red-bg round-btn centbutton" (click)="updateitemClose()" data-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal" id="delete_success">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header1 darkred-bg sweet-heading p-0">
        <h4 class="modal-title sweet-heading"></h4>
        <button type="button" class="close btn alert_modal" (click)="deleteitem()" data-dismiss="modal">
          &times;
        </button>
      </div>
      <div class="modal-body p-1">
        <div class="thank-you-pop">
          <img src="./assets/image/Green-Round-Tick.png" alt="" />
          <h1>Thank You!</h1>
          <p>Record Deleted Successfully</p>
        </div>
      </div>
      <div class="modal-footer sweet-footer p-1">
        <button type="button" class="btn red-bg round-btn" (click)="deleteitem()" data-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal" id="configMsgModel">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header1 darkred-bg sweet-heading p-0">
        <h4 class="modal-title sweet-heading"></h4>
        <button type="button" class="close btn alert_modal" (click)="configMsgModelClose()" data-dismiss="modal">
          &times;
        </button>
      </div>
      <div class="modal-body p-1">
        <div class="thank-you-pop">
          <img src="./assets/image/close.png" alt="" />
          <h1>Alert</h1>
          <p>Please configure the sender email in email configuration.</p>
        </div>
      </div>
      <div class="modal-footer sweet-footer p-1">
        <button type="button" class="btn red-bg round-btn" (click)="configMsgModelClose()" data-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal" id="wrong_msg">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header1 darkred-bg sweet-heading p-0">
        <h4 class="modal-title sweet-heading"></h4>
        <button type="button" class="close btn alert_modal" (click)="msgitem()" data-dismiss="modal">
          &times;
        </button>
      </div>
      <div class="modal-body p-1">
        <div class="thank-you-pop">
          <img src="./assets/image/close.png" alt="" />
          <h1>Alert</h1>
          <p>Something went wrong</p>
        </div>
      </div>
      <div class="modal-footer sweet-footer p-1">
        <button type="button" class="btn red-bg round-btn" (click)="msgitem()" data-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>

<!-- <div class="modal" id="NewCustomerSuccess">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header1 darkred-bg sweet-heading p-0">
        <h4 class="modal-title sweet-heading"></h4>
        <button
          type="button"
          class="close btn alert_modal"
          (click)="NewCustomerSuccess()"
          data-dismiss="modal"
        >
          &times;
        </button>
      </div>
      <div class="modal-body p-1">
        <div class="thank-you-pop">
          <img src="./assets/image/Green-Round-Tick.png" alt="" />
          <h1>Thank You!</h1>
          <p>Record Added In Draft Successfully</p>
        </div>
      </div>
      <div class="modal-footer sweet-footer p-1">
        <button
          type="button"
          class="btn red-bg round-btn"
          (click)="NewCustomerSuccess()"
          data-dismiss="modal"
        >
          Close
        </button>
      </div>
    </div>
  </div>
</div> -->


<ng-template #discardItem let-c="close" let-d="dismiss">
  <h4 class="modal-title darkred-bg alert_heading sweet-heading"></h4>
  <button class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>

  <div class="modal-body p-1">
    <div class="thank-you-pop">
      <img src="./assets/image/popup-alert.png" alt="" />
      <h1>Confirmation</h1>
      <p class="mt-1">{{ deleteDetail }}</p>
    </div>
  </div>
  <div class="modal-footer sweet-footer text-center p-1">
    <button type="button" class="btn green-bg round-btn" (click)="gotoDashBoard(); c('Close click')">
      Yes
    </button>
    <button type="button" class="btn red-bg round-btn" (click)="c('Close click')">
      No
    </button>
  </div>
</ng-template>
<div class="modal" id="ReasonModalPopup">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header1 darkred-bg sweet-heading p-0">
        <h4 class="modal-title sweet-heading">Reason for Update</h4>
        <button type="button" class="close btn alert_modal" (click)="ReasonModalPopup()" data-dismiss="modal">
          &times;
        </button>
      </div>
      <div class="modal-body p-1">
        <div class="row">
          <div class="col-md-2">Reason <span style="color: red">*</span></div>
          <div class="col-md-4 mt-1">
            <!-- <input type="textarea " class="customInput" [(ngModel)]="PartName_search" rows="4" cols="50" /> -->
            <textarea name="w3review" rows="4" cols="50" [(ngModel)]="Reason" style="margin-top: 15px" [ngClass]="
                (Reason == null || Reason == undefined || Reason == '') &&
                IsModalPopup
                  ? 'validation_border'
                  : ''
              ">
            </textarea>
          </div>
        </div>
      </div>
      <div class="modal-footer sweet-footer p-1">
        <button type="button" class="btn green-bg round-btn" (click)="UpdateOrder('', updateOrderId, true, '2')"
          data-dismiss="modal">
          Save
        </button>
        <button type="button" class="btn red-bg round-btn" (click)="ReasonModalPopup()" data-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
<!-- ----for Generate Sale order number--- -->
<div class="modal" id="SaleAlert">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header1 darkred-bg sweet-heading p-0">
        <h4 class="modal-title sweet-heading"></h4>
        <button type="button" class="close btn alert_modal" (click)="SaleAlert()" data-dismiss="modal">
          &times;
        </button>
      </div>
      <div class="modal-body p-1">
        <div class="thank-you-pop">
          <img src="./assets/image/popup-alert.png" alt="" />
          <h1>Alert</h1>
          <p>{{ salesorderAlert }}</p>
        </div>
      </div>
      <div class="modal-footer sweet-footer p-1">
        <button type="button" class="btn red-bg round-btn" (click)="SaleAlert()" data-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal" id="sendMailPopup" style="z-index: 1080">
  <div class="modal-dialog" style="max-width: 60%">
    <div class="modal-content">
      <div class="modal-header info-popup-header p-2">
        <h5 class="modal-title info-popup-title p-1" id="exampleModalLabel1">
          Send Email
        </h5>
        <button type="button" class="close" (click)="sendMailPopup()" data-dismiss="modal" aria-label="">
          <span>×</span>
        </button>
      </div>
      <div class="modal-body">
        <form [formGroup]="formSendEmail">
          <div class="form-group row mb-0 required">
            <label for="inputEmail3" class="col-sm-2 col-form-label customLabel">Email Template</label>
            <div class="col-sm-6">
              <select class="customInput" [(ngModel)]="EmailTemplate" (change)="ChangeTemplate($event)"
                formControlName="EmailTemplate" [ngClass]="
                  !EmailTemplate && EmailTempValidate ? 'validation_border' : ''
                ">
                <option value="">Select Template</option>
                <option *ngFor="let tempitem of tempList" [value]="tempitem.id">
                  {{ tempitem.tempName }}
                </option>
              </select>
            </div>
          </div>
          <div class="form-group row mb-0 required">
            <label for="inputEmail3" class="col-sm-2 col-form-label customLabel">To</label>
            <div class="col-sm-6">
              <input class="customInput" formControlName="txtEmailSendTo" type="text" [(ngModel)]="txtEmailSendTo"
                [ngClass]="
                  !txtEmailSendTo && EmailTempValidate
                    ? 'validation_border'
                    : ''
                " />
            </div>
          </div>
          <div class="form-group row mb-0">
            <label for="inputEmail3" class="col-sm-2 col-form-label customLabel">CC</label>
            <div class="col-sm-6">
              <input class="customInput" formControlName="txtEmailSendCC" type="text" [(ngModel)]="txtEmailSendCC" />
            </div>
          </div>
          <div class="form-group row mb-0">
            <label for="inputEmail3" class="col-sm-2 col-form-label customLabel">Bcc</label>
            <div class="col-sm-6">
              <input class="customInput" formControlName="txtEmailSendBCC" type="text" [(ngModel)]="txtEmailSendBCC" />
            </div>
          </div>
          <div class="form-group row mb-0 required">
            <label for="inputEmail3" class="col-sm-2 col-form-label customLabel">Subject</label>
            <div class="col-sm-6">
              <input class="customInput" formControlName="txtEmailSubject" type="text" [(ngModel)]="txtEmailSubject"
                [ngClass]="
                  !txtEmailSubject && EmailTempValidate
                    ? 'validation_border'
                    : ''
                " />
            </div>
          </div>
          <div class="form-group row">
            <label for="inputEmail3" class="col-sm-2 col-form-label customLabel">Body</label>
            <div class="col-sm-10">
              <ckeditor [(ngModel)]="templateBody" name="myckeditor" formControlName="templateBody" [config]="ckeConfig"
                debounce="500">
              </ckeditor>
            </div>
          </div>
        </form>
        <p class="mb-0">
          <button class="btn blue-bg round-btn float-right" (click)="SendEmailPdf()">
            Send email
          </button>
        </p>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="sendMailPopupSuccess">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header info-popup-header p-2">
        <button type="button" class="close" (click)="sendMailPopupSuccessClose()" data-dismiss="modal" aria-label="">
          <span>×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="thank-you-pop">
          <img src="./assets/image/Green-Round-Tick.png" alt="" />
          <h1>Thank You!</h1>
          <p>{{ EmailSentMessage }}</p>
        </div>
      </div>
      <div class="modal-footer sweet-footer p-1">
        <button type="button" class="btn red-bg round-btn" (click)="sendMailPopupSuccessClose()" data-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="ExportOption">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header info-popup-header p-2">
        <!-- <h5 class="modal-title" id="exampleModalLabel">Send Mail</h5> -->
        <button type="button" class="close" (click)="ExportOption()" data-dismiss="modal" aria-label="">
          <span>×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="thank-you-pop">
          <img src="./assets/image/Green-Round-Tick.png" alt="" />
          <h1>Thank You!</h1>
          <p>Choose your option</p>

          <div class="row">
            <div class="col-md-4">
              <select class="pl-1px customInput PaymentMode" (change)="onChangePrint($event.target)">
                <option value="" disabled>--Selection List--</option>
                <option value="SalesOrder">Sales Order</option>
                <option value="Invoice">Invoice</option>
                <option value="PackingSlip">Packing Slip</option>
              </select>
            </div>
            <p class="mb-0">
              <button class="btn blue-bg round-btn" (click)="DownloadPdf()">
                Export PDF&nbsp;&nbsp;<i class="fa fa-file-pdf-o text-white" aria-hidden="true"></i>
              </button>
              <button class="btn blue-bg round-btn" (click)="PopSendMail()">
                Send Email&nbsp;&nbsp;<i class="fa fa-file-pdf-o text-white" aria-hidden="true"></i>
              </button>
              <!-- <button class="btn blue-bg round-btn" (click)="Stay(this.SendMailSoId)" style="padding: 3px 12px;width: 100px;">Stay&nbsp;&nbsp;</button> -->
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="DeleteOrderModel">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header info-popup-header p-2">
        <button type="button" class="close" (click)="DeleteOrderModel()" data-dismiss="modal" aria-label="">
          <span>×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="thank-you-pop">
          <img src="./assets/image/popup-alert.png" alt="" />
          <h1>Confirmation</h1>
          <p class="mt-1">Are you sure, you want to delete Order?</p>
        </div>
      </div>
      <div class="modal-footer sweet-footer p-1">
        <button type="button" class="btn green-bg round-btn m-1" (click)="DeleteOrder(deletedItemPart)">
          Yes
        </button>
        <button type="button" class="btn red-bg round-btn" (click)="DeleteOrderModel()" data-dismiss="modal">
          No
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="ExitPopModel">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header info-popup-header p-2">
        <button type="button" class="close" (click)="ExitPopModel()" data-dismiss="modal" aria-label="">
          <span>×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="thank-you-pop">
          <img src="./assets/image/popup-alert.png" alt="" />
          <h1>Confirmation</h1>
          <p class="mt-1">Are you sure, you want to exit without saving ?</p>
        </div>
      </div>
      <div class="modal-footer sweet-footer p-1">
        <button type="button" class="btn green-bg round-btn m-1" (click)="GoOrderPage()">
          Yes
        </button>
        <button type="button" class="btn red-bg round-btn" (click)="ExitPopModel()" data-dismiss="modal">
          No
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="salesorder">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header1 darkred-bg sweet-heading p-0">
        <h4 class="modal-title sweet-heading">Copy Sales Order</h4>
        <button type="button" class="close btn alert_modal" (click)="closeSalesorder()" data-dismiss="modal">
          &times;
        </button>
      </div>
      <div class="modal-body p-1">
        <div class="thank-you-pop">
          <div class="row">
            <div class="col-md-12">
              <p>{{ salesProp }}</p>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12">
              <input type="text" class="customInput cs" name="CreateOrder" [(ngModel)]="CreateOrder" [ngClass]="
                  CreateOrder == '' && copyValidation ? 'validation_border' : ''
                " autocomplete="off" />
            </div>
          </div>
          <div class="row">
            <div class="col-md-8"></div>
            <div class="col-md-4">
              <p style="color: red; font-size: 14px">{{ prop }}</p>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer sweet-footer p-1">
        <button type="button" class="btn green-bg round-btn m-1" (click)="CopyCreateOrder(copyOrderId, copyCompanyId)">
          Create
        </button>
        <button type="button" class="btn red-bg round-btn" (click)="closeSalesorder()" data-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="confirmation">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header1 darkred-bg sweet-heading p-0">
        <h4 class="modal-title sweet-heading"></h4>
        <button type="button" class="close btn alert_modal" (click)="closeConfirmation()" data-dismiss="modal">
          &times;
        </button>
      </div>
      <div class="modal-body p-1">
        <div class="thank-you-pop">
          <img src="./assets/image/popup-alert.png" alt="" />
          <h1>Confirmation</h1>
          <p>You would like to create {{ CreateOrder }} new Sales Order(s)?</p>
        </div>
      </div>
      <div class="modal-footer sweet-footer p-1">
        <button type="button" class="btn green-bg round-btn m-1" (click)="Create(copyOrderId, copyCompanyId)">
          Yes
        </button>
        <button type="button" class="btn red-bg round-btn" (click)="closeConfirmation()" data-dismiss="modal">
          No
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal" id="SelectCompany">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header1 darkred-bg sweet-heading p-0">
        <h4 class="modal-title sweet-heading"></h4>
        <button type="button" class="close btn alert_modal" (click)="SelectCompany()" data-dismiss="modal">
          &times;
        </button>
      </div>
      <div class="modal-body p-1">
        <div class="thank-you-pop">
          <img src="./assets/image/popup-alert.png" alt="" />
          <h1>Alert</h1>
          <p>{{Smessgae}}</p>
        </div>
      </div>
      <div class="modal-footer sweet-footer p-1">

        <button type="button" class="btn red-bg round-btn" (click)="SelectCompany()" data-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="copy_success">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header info-popup-header p-2">
        <button type="button" class="close" (click)="closeCopySuccess()" data-dismiss="modal" aria-label="">
          <span>×</span>
        </button>
      </div>
      <div class="modal-body">
        <div class="thank-you-pop">
          <img src="./assets/image/Green-Round-Tick.png" alt="" />
          <h1>Thank You!</h1>
          <p>Your Sale Order Has Been Copied</p>
          <h3 class="cupon-pop" style="font-size: 12px">
            Your Sale Order Number:
            <span class="customLabel" *ngFor="
                let data of copySerialArr;
                let i = index;
                let isLast = last
              ">
              <ng-container>
                {{ data.customerPonumber
                }}{{ data.customerPonumber != "" ? (isLast ? "" : ", ") : "" }}
              </ng-container>
            </span>
          </h3>
          <!-- <p class="mb-0">
                        <button class="btn blue-bg round-btn" (click)="DownloadPdf()">Export PDF&nbsp;&nbsp;<i
                            class="fa fa-file-pdf-o text-white" aria-hidden="true"></i></button>
                    <button class="btn blue-bg round-btn" (click)="PopSendMail()">Send Email&nbsp;&nbsp;<i
                            class="fa fa-file-pdf-o text-white" aria-hidden="true"></i></button>

                    </p> -->
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="StayModel">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header1 darkred-bg sweet-heading p-0">
        <h4 class="modal-title sweet-heading"></h4>
        <button type="button" class="close btn alert_modal" (click)="closeStayModel()" data-dismiss="modal">
          &times;
        </button>
      </div>
      <div class="modal-body p-1">
        <div class="thank-you-pop">
          <img src="./assets/image/popup-alert.png" alt="" />
          <h1>Confirmation</h1>
          <p>Do you want to stay here?</p>
        </div>
      </div>
      <div class="modal-footer sweet-footer p-1">
        <button type="button" class="btn green-bg round-btn m-1" (click)="stayFun(stayId)">
          Yes
        </button>
        <button type="button" class="btn red-bg round-btn" (click)="closeStayModel()" data-dismiss="modal">
          No
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal" id="StayUpdate">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header1 darkred-bg sweet-heading p-0">
        <h4 class="modal-title sweet-heading"></h4>
        <button type="button" class="close btn alert_modal" (click)="closeStayUpdate()" data-dismiss="modal">
          &times;
        </button>
      </div>
      <div class="modal-body p-1">
        <div class="thank-you-pop">
          <img src="./assets/image/popup-alert.png" alt="" />
          <h1>Confirmation</h1>
          <p>Do you want to stay here?</p>
        </div>
      </div>
      <div class="modal-footer sweet-footer p-1">
        <button type="button" class="btn green-bg round-btn m-1" (click)="stayUpdate()">
          Yes
        </button>
        <button type="button" class="btn red-bg round-btn" (click)="closeStayUpdate()" data-dismiss="modal">
          No
        </button>
      </div>
    </div>
  </div>
</div>
<div class="modal" id="ReasonCodes">
  <div class="modal-dialog modal-dialog-centered modal-lg centModels">
    <div class="modal-content">
      <div class="modal-header1 darkred-bg sweet-heading p-0">
        <h4 class="modal-title sweet-heading">
          "Reason" for the "{{
          statusDropdownList == "3" ? "Cancelled" : "On Hold"
          }}" status
        </h4>
        <button type="button" class="close btn alert_modal" (click)="closeReasonCodes()" data-dismiss="modal">
          &times;
        </button>
      </div>
      <div class="modal-body p-1">
        <div class="thank-you-pop">
          <div class="row">
            <div class="col-md-12">
              <div class="row">
                <div class="col-lg-4 col-md-2 trp">
                  <label class="customLabel">Reason Codes</label>
                </div>
                <div class="col-lg-8 col-md-10">
                  <select class="customInput pl-1px" required [(ngModel)]="ReasonCodes" [ngClass]="
                      !ReasonCodes && reasonValidation
                        ? 'validation_border'
                        : ''
                    ">
                    <option value="">Select Reason Codes</option>
                    <option *ngFor="let item of reasonStatusCodeList" [value]="item.id">
                      {{ item.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>
            <div class="col-md-12 mt-1">
              <div class="row">
                <div class="col-lg-4 col-md-2 trp">
                  <label class="customLabel">Reason Description</label>
                </div>
                <div class="col-lg-8 col-md-10">
                  <input type="text" class="customInput" [(ngModel)]="ReasonDescp"
                    placeholder="Enter Reason Description" name="ReasonDescp" autocomplete="off" required maxlength="50"
                    [ngClass]="
                      !ReasonDescp && reasonValidation
                        ? 'validation_border'
                        : ''
                    " />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer sweet-footer p-1">
        <button type="button" class="btn green-bg round-btn m-1" (click)="saveReason()">
          Save
        </button>
        <button type="button" class="btn red-bg round-btn" (click)="closeReasonCodes()" data-dismiss="modal">
          No
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="add_successs">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header1 darkred-bg sweet-heading p-0">
        <h4 class="modal-title sweet-heading"></h4>
        <button type="button" class="close btn alert_modal" (click)="closeSuccesss()" data-dismiss="modal">
          &times;
        </button>
      </div>
      <div class="modal-body p-1">
        <div class="thank-you-pop">
          <img src="./assets/image/Green-Round-Tick.png" alt="" />
          <h1>Thank You!</h1>
          <p>Reason Codes Save Successfully</p>
        </div>
      </div>
      <div class="modal-footer sweet-footer p-1">
        <button type="button" class="btn red-bg round-btn" (click)="closeSuccesss()" data-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="add_updateMessage" style="z-index: 1080">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header1 darkred-bg sweet-heading p-0">
        <h4 class="modal-title sweet-heading"></h4>
        <button type="button" class="close btn alert_modal" (click)="updateitems()" data-dismiss="modal">
          &times;
        </button>
      </div>
      <div class="modal-body p-1">
        <div class="thank-you-pop">
          <img src="./assets/image/Green-Round-Tick.png" alt="" />
          <h1>Thank You!</h1>
          <p>Record Updated Successfully</p>
        </div>
      </div>
      <div class="modal-footer sweet-footer p-1">
        <button type="button" class="btn red-bg round-btn" (click)="updateitems()" data-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="ShipLog">
  <div class="modal-dialog modal-dialog-centered modal-lg centModel" role="document">
    <div class="modal-content">
      <h5 class="modal-title info-popup-title p-1" id="exampleModalLabel1">
        Shipping Status
      </h5>
      <button class="close btn alert_modal" aria-label="Close" (click)="ShipLogExit()">
        <span aria-hidden="true">&times;</span>
      </button>
      <div class="modal-body">
        <table class="table m-0">
          <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">Ship Date</th>
              <th scope="col">Qty</th>
              <th scope="col">Carrier</th>
              <th scope="col">Carrier Packing #</th>

            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of shipingOperationList; let sno = index">
              <th scope="row">{{ sno + 1 }}</th>
              <td>
                {{ item.dateShipped | date : "MMM-dd-yyyy" }}
              </td>
              <td>{{ item.qtyShipped }}</td>
              <td>{{ item.carrierName }}</td>
              <td> {{ item.shippingTracking == null ? 'N/A' : 'Package' + item.shippingTracking }}</td>

            </tr>
          </tbody>
        </table>

      </div>
    </div>
  </div>
</div>

<div class="modal" id="SaveModel" style="z-index: 1080">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header1 darkred-bg sweet-heading p-0">
        <h4 class="modal-title sweet-heading"></h4>
        <button type="button" class="close btn alert_modal" (click)="SavePopModel()" data-dismiss="modal">
          &times;
        </button>
      </div>
      <div class="modal-body p-1">
        <div class="thank-you-pop">
          <img src="./assets/image/Green-Round-Tick.png" alt="" />
          <h1>Thank You!</h1>
          <p>{{ SaveModel }}</p>
        </div>
      </div>
      <div class="modal-footer sweet-footer p-1">
        <button type="button" class="btn red-bg round-btn" (click)="SavePopModel()" data-dismiss="modal">
          Close
        </button>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="multicustomerTable">
  <div class="modal-dialog modal-dialog-centered modal-lg centModel" role="document">
    <div class="modal-content">
      <div class="modal-header1 darkred-bg sweet-heading p-0">
        <h4 class="modal-title sweet-heading">Multi Contact List</h4>
        <button type="button" class="close btn alert_modal" (click)="closeMultiContactModel()" data-dismiss="modal">
          &times;
        </button>
      </div>

      <div class="card-desc1 mt-1 table-responsive y-overflow">
        <table class="table table-bordered" style="width: 100%" id="MultiUser">
          <thead>
            <tr>
              <th>Contact First Name</th>
              <th>Contact Last Name</th>
              <th>Email</th>
              <th>Phone Number</th>
              <th>Contact Type</th>
              <th>SO Contact</th>
              <th>IsEcommerce</th>
              <th data-orderable="false">Action</th>
            </tr>
          </thead>
          <tbody>
            <ng-container *ngFor="let data of multiUserList">
              <tr *ngIf="data.isActive == true">
                <td>{{ data.firstname }}</td>
                <td>{{ data.lastname }}</td>
                <td>{{ data.email }}</td>
                <td>{{ data.phone }}</td>
                <td>{{ data.customerTypeVal }}</td>

                <td>
                  <input type="checkbox" value="{{ data.issocontact }}" [(ngModel)]="data.issocontact"
                    (change)="onCheckChange($event, data.id, data)" />
                </td>
                <!-- <td><span *ngIf="data.issocontact==true"><i class="fa fa-check text-success" aria-hidden="true"></i></span></td> -->
                <td>
                  <span *ngIf="data.IsEcoomerce == true"><i class="fa fa-check text-success"
                      aria-hidden="true"></i></span>
                </td>
                <td>
                  <a class="green-text" *ngIf="
                      rolePermissionsList && rolePermissionsList[0].isUpdate
                    ">
                    <i class="fas fa-save" (click)="multiUserEdits(data)"></i></a>
                </td>
              </tr>
            </ng-container>
          </tbody>

          <tbody *ngIf="multiUserList == null">
            <tr class="c1">
              <td colspan="9" class="no-data-available">
                No data available in table
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="modal-footer sweet-footer p-1">
        <button class="btn round-btn upload_btn add_img mt-lg-1 mb-1" (click)="openCustomerModal()"
          [disabled]="isGuestType == true" style="padding: 5px 10px">
          + Add Contacts
        </button>
        <!-- <button class="btn round-btn upload_btn add_img mt-lg-0 mb-2" (click)="addCustomer();"
            >Update

        </button> -->
        <button class="btn darkred-bg round-btn CustomClass" (click)="closeMultiContactModel()">
          <i aria-hidden="true"></i> Exit
        </button>
      </div>
    </div>
  </div>

  <div class="modal" id="customerModel" style="z-index: 999">
    <div class="modal-dialog modal-dialog-centered modal-lg bigmodals" role="document">
      <div class="modal-content">
        <h4 class="modal-title info-popup-title p-1 text-center">
          Multiple Contacts for {{ customerName }}
        </h4>
        <button type="button" class="close btn alert_modal" aria-label="Close" (click)="userclose()">
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-body">
          <!------------------------------------- Multiple Contacts table ------------------------------>
          <div class="card-desc1">
            <form [formGroup]="userForm">
              <div class="row mt-2">
                <div class="col-lg-4 col-md-12">
                  <div class="row">
                    <div class="col-lg-4 col-md-2 trp">
                      <label class="customLabel">Contact First Name</label>
                    </div>
                    <div class="col-lg-8 col-md-10">
                      <input type="text" class="customInput" [(ngModel)]="userfirstName" placeholder="Enter First Name"
                        name="userfirstName" formControlName="userfirstName" autocomplete="off" maxlength="50"
                        (keypress)="omit_special_char($event)" [ngClass]="
                          !userForm.controls.userfirstName.valid &&
                          uservalidation
                            ? 'validation_border'
                            : ''
                        " />
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                  <div class="row">
                    <div class="col-lg-4 col-md-2 trp">
                      <label class="customLabel Character">Contact Last Name</label>
                    </div>
                    <div class="col-lg-8 col-md-10">
                      <input type="text" class="customInput" required [(ngModel)]="userlastName"
                        placeholder="Enter Last Name" name="userlastName" formControlName="userlastName"
                        autocomplete="none|false|no" maxlength="50" (keypress)="omit_special_char($event)" [ngClass]="
                          !userForm.controls.userlastName.valid &&
                          uservalidation
                            ? 'validation_border'
                            : ''
                        " />
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                  <div class="row">
                    <div class="col-lg-4 col-md-2 trp">
                      <label class="customLabel">Email</label>
                    </div>
                    <div class="col-lg-8 col-md-10">
                      <input type="text" class="customInput EmailValidation" [(ngModel)]="useremail"
                        placeholder="Enter Email" name="useremail" formControlName="useremail" autocomplete="off"
                        required pattern="[0-9a-zA-Z._%+-]+@[0-9a-zA-Z.-]+\.[a-zA-Z]{2,3}$" maxlength="50" [ngClass]="
                          !userForm.controls.useremail.valid && uservalidation
                            ? 'validation_border'
                            : ''
                        " />
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-1">
                <div class="col-lg-4 col-md-12">
                  <div class="row">
                    <div class="col-lg-4 col-md-2 trp">
                      <label class="customLabel">Phone Number</label>
                    </div>
                    <div class="col-lg-8 col-md-10">
                      <input type="text" class="customInput PhoneValidation" (keypress)="validatePhoneNo($event.target)"
                        [(ngModel)]="userphone" placeholder="Enter Phone Number (xxx)xxx-xxx" name="userphone" required
                        formControlName="userphone" autocomplete="disabled" maxlength="14"
                        pattern="^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$" [ngClass]="
                          !userForm.controls.userphone.valid && uservalidation
                            ? 'validation_border'
                            : ''
                        " />
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                  <div class="row">
                    <div class="col-lg-4 col-md-2 trp">
                      <label class="customLabel">Extension #</label>
                    </div>
                    <div class="col-lg-8 col-md-10">
                      <input type="text" class="customInput" [(ngModel)]="userextention" placeholder="Enter Extension"
                        name="userextention" formControlName="userextention" autocomplete="disabled" maxlength="14" />
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                  <div class="row">
                    <div class="col-lg-4 col-md-2 trp">
                      <label class="customLabel">Fax #</label>
                    </div>
                    <div class="col-lg-8 col-md-10">
                      <input type="text" class="customInput" (keypress)="validatePhoneNo($event.target)"
                        [(ngModel)]="userfax" placeholder="Enter Fax (xxx)xxx-xxx" name="userfax"
                        formControlName="userfax" autocomplete="disabled" maxlength="14"
                        pattern="^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-1">
                <div class="col-lg-4 col-md-12">
                  <div class="row">
                    <div class="col-lg-4 col-md-2 trp">
                      <label class="customLabel">Address Line 1</label>
                    </div>
                    <div class="col-lg-8 col-md-10">
                      <input type="text" class="customInput" [(ngModel)]="useraddress"
                        placeholder="Enter Address Line 1 " maxlength="100" name="useraddress"
                        formControlName="useraddress" autocomplete="disabled" />
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12">
                  <div class="row">
                    <div class="col-lg-4 col-md-2 trp">
                      <label class="customLabel">Address Line 2</label>
                    </div>
                    <div class="col-lg-8 col-md-10">
                      <input type="text" class="customInput" [(ngModel)]="useraddress1"
                        placeholder="Enter Address Line 2 " maxlength="100" name="useraddress1"
                        formControlName="useraddress1" autocomplete="disabled" />
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                  <div class="row">
                    <div class="col-lg-4 col-md-2 trp">
                      <label class="customLabel">Zip</label>
                    </div>
                    <div class="col-lg-8 col-md-10">
                      <div *ngIf="(selectedContactCountry=='231')">
                        <input type="text" class="customInput NumberOnly" [(ngModel)]="userzip"
                          placeholder="Enter Zip xxxxx-xxxx" name="userzip" pattern="\d{5}-?(\d{4})?"
                          (keypress)="validateZipcode($event.target)" formControlName="userzip" autocomplete="disabled"
                          maxlength="10" />
                        <div *ngIf="
                            !userForm.controls.userzip.valid &&
                            userForm.controls.userzip.value != '' &&
                            userForm.controls.userzip.value != undefined &&
                            uservalidation
                          " class="text-danger input-error">
                          Enter Valid Zip.eg-xxxxx-xxxx
                        </div>
                      </div>
                      <div *ngIf="(selectedContactCountry!='231')">
                        <input type="text" class="customInput" [(ngModel)]="userzip" placeholder="Enter Zip xxxxx-xxxx"
                          name="userzip" formControlName="userzip" autocomplete="disabled" maxlength="10" />
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-1">
                <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                  <div class="row">
                    <div class="col-lg-4 col-md-2 trp">
                      <label class="customLabel">City</label>
                    </div>
                    <div class="col-lg-8 col-md-10">
                      <input type="text" class="customInput" [(ngModel)]="usercity" placeholder="Enter City"
                        name="usercity" formControlName="usercity" autocomplete="off" [ngClass]="
                          !userForm.controls.usercity.valid && uservalidation
                            ? 'validation_border'
                            : ''
                        " />

                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                  <div class="row">
                    <div class="col-lg-4 col-md-2 trp">
                      <label class="customLabel">Country</label>
                    </div>
                    <div class="col-lg-8 col-md-10">
                      <select class="customInput pl-1px" formControlName="usercountry"
                        (change)="changeUserCountryContact($event)" [(ngModel)]="selectedContactCountry">
                        <option value="">Select Country</option>
                        <option *ngFor="let item of countryDataContact" [value]="item.id">
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                  <div class="row">
                    <div class="col-lg-4 col-md-2 trp">
                      <label class="customLabel">State</label>
                    </div>
                    <div class="col-lg-8 col-md-10">
                      <select class="customInput pl-1px" formControlName="userstate" [(ngModel)]="selectedUserValue"
                        [ngClass]="
                          !userForm.controls.userstate.valid && uservalidation
                            ? 'validation_border'
                            : ''
                        ">
                        <option value=''>Select State</option>
                        <option *ngFor="let item of stateUserData" [value]="item.id">
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-1">
                <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                  <div class="row">
                    <div class="col-lg-4 col-md-2 trp">
                      <label class="customLabel">Conatct Type</label>
                    </div>
                    <div class="col-lg-8 col-md-10">
                      <select class="customInput pl-1px" required formControlName="selectedCustomerContactType"
                        [(ngModel)]="selectedCustomerContactType" [ngClass]="
                          !userForm.controls.selectedCustomerContactType
                            .valid && uservalidation
                            ? 'validation_border'
                            : ''
                        ">
                        <option value="">Select Contact Type</option>
                        <option *ngFor="let item of CustomerContacttypeId" [value]="item.id">
                          {{ item.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                  <div class="row">
                    <div class="col-lg-4 col-md-2 trp">
                      <label class="customLabel" for="issocontact">So Contact</label>
                    </div>
                    <div class="col-lg-8 col-md-10">
                      <input type="checkbox" id="issocontact" name="issocontact" [checked]="issocontact"
                        formControlName="issocontact" [(ngModel)]="issocontact" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-1">
                <div class="col-lg-4 col-md-12 mt-lg-0 mt-1" *ngIf="isUserEdit == false && isEcoomUser == true">
                  <div class="row">
                    <div class="col-lg-4 col-md-2 trp required">
                      <label class="customLabel">Password</label>
                    </div>
                    <div class="col-lg-8 col-md-10">
                      <input type="password" class="customInput" [(ngModel)]="userpassword"
                        formControlName="userpassword" required autocomplete="off" maxlength="40"
                        placeholder="Enter Password" [ngClass]="
                          (userForm.controls.userpassword.value == '' ||
                            userForm.controls.userpassword.value ==
                              undefined) &&
                          uservalidation
                            ? 'validation_border'
                            : ''
                        " />

                      <div class="text-danger input-error" *ngIf="
                          userForm.get('userpassword').invalid &&
                          (userForm.get('userpassword').touched ||
                            userForm.get('userpassword').dirty)
                        ">
                        <div *ngIf="userForm.get('userpassword').errors.minlength">
                          Password must be at least 8 characters
                        </div>
                        <div *ngIf="
                            userForm
                              .get('userpassword')
                              .hasError('passwordStrength')
                          ">
                          {{
                          userForm.get("userpassword").errors[
                          "passwordStrength"
                          ]
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12 mt-lg-0 mt-1" *ngIf="isUserEdit == false && isEcoomUser == true">
                  <div class="row">
                    <div class="col-lg-4 col-md-2 trp required">
                      <label class="customLabel">Confirm Password</label>
                    </div>
                    <div class="col-lg-8 col-md-10">
                      <input type="password" class="customInput" [(ngModel)]="userpassworConfirm"
                        formControlName="userpassworConfirm" required autocomplete="new-password"
                        placeholder="Enter Confirm Password" maxlength="40" [ngClass]="
                          (userForm.controls.userpassworConfirm.value == '' ||
                            userForm.controls.userpassworConfirm.value ==
                              undefined) &&
                          uservalidation
                            ? 'validation_border'
                            : ''
                        " />
                      <div class="text-danger input-error">
                        <div *ngIf="
                            userpassword != userpassworConfirm &&
                            userForm.controls.userpassworConfirm.value != '' &&
                            userForm.controls.userpassworConfirm.value !=
                              undefined &&
                            userForm.controls.userpassworConfirm.value != null
                          ">
                          Password not match.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                  <div class="row">
                    <div class="col-lg-4 col-md-2 trp">
                      <label class="customLabel" for="isEcoomUser">Ecommerce User</label>
                    </div>
                    <div class="col-lg-8 col-md-10">
                      <input type="checkbox" id="isEcoomUser" name="isEcoomUser" [checked]="isEcoomUser"
                        formControlName="isEcoomUser" [(ngModel)]="isEcoomUser" />
                    </div>
                  </div>
                </div>
              </div>

              <div class="row mt-1">
                <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1"></div>
                <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1"></div>
                <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                  <button type="button" class="btn darkred-bg round-btn btnCss" (click)="userclose()"
                    style="float: right">
                    Exit
                  </button>
                  <button type="button" class="btn blue-bg round-btn btnCss" (click)="AddMultiUser()"
                    style="float: right">
                    Save
                  </button>
                </div>
              </div>

              <div class="row mt-2" *ngIf="isUserEdit == true">
                <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                  <div class="row">
                    <div class="col-lg-4 col-md-2 trp required">
                      <label class="customLabel">Password</label>
                    </div>
                    <div class="col-lg-8 col-md-10">
                      <input type="password" class="customInput" [(ngModel)]="changepassword"
                        formControlName="changepassword" required autocomplete="off" maxlength="40"
                        placeholder="Enter Password" [ngClass]="
                          (userForm.controls.changepassword.value == '' ||
                            userForm.controls.changepassword.value ==
                              undefined) &&
                          userPasswordvalidation
                            ? 'validation_border'
                            : ''
                        " />

                      <div class="text-danger input-error" *ngIf="
                          userForm.get('changepassword').invalid &&
                          (userForm.get('changepassword').touched ||
                            userForm.get('changepassword').dirty)
                        ">
                        <div *ngIf="
                            userForm.get('changepassword').errors.minlength
                          ">
                          Password must be at least 8 characters
                        </div>
                        <div *ngIf="
                            userForm
                              .get('changepassword')
                              .hasError('passwordStrength')
                          ">
                          {{
                          userForm.get("changepassword").errors[
                          "passwordStrength"
                          ]
                          }}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12 mt-lg-0 mt-2">
                  <div class="row">
                    <div class="col-lg-4 col-md-2 trp required">
                      <label class="customLabel">Confirm Password</label>
                    </div>
                    <div class="col-lg-8 col-md-10">
                      <input type="password" class="customInput" [(ngModel)]="changepassworConfirm"
                        formControlName="changepassworConfirm" required autocomplete="new-password"
                        placeholder="Enter Confirm Password" maxlength="40" [ngClass]="
                          (userForm.controls.changepassworConfirm.value == '' ||
                            userForm.controls.changepassworConfirm.value ==
                              undefined) &&
                          userPasswordvalidation
                            ? 'validation_border'
                            : ''
                        " />
                      <div class="text-danger input-error">
                        <div *ngIf="
                            changepassword != changepassworConfirm &&
                            userForm.controls.changepassworConfirm.value !=
                              '' &&
                            userForm.controls.changepassworConfirm.value !=
                              undefined &&
                            userForm.controls.changepassworConfirm.value != null
                          ">
                          Password not match.
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                  <div class="row">
                    <button type="button" class="btn blue-bg round-btn" (click)="ChangePassword()" style="float: right">
                      Change Password
                    </button>
                    <button type="button" class="btn darkred-bg round-btn" (click)="clearPasswordDetails()">
                      <i class="fas fa-redo" aria-hidden="true"></i> Clear
                    </button>
                  </div>
                </div>
              </div>
            </form>

            <hr style="margin-bottom: 0" />
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="modal" id="ReturnModel" style="z-index: 1080">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header1 darkred-bg sweet-heading p-0">
          <h4 class="modal-title sweet-heading"></h4>
          <button type="button" class="close btn alert_modal" (click)="CloseReturnModel()" data-dismiss="modal">
            &times;
          </button>
        </div>
        <div class="modal-body p-1">
          <div class="thank-you-pop">
            <img src="./assets/image/popup-alert.png" alt="" />
            <h1>Alert</h1>
            <p>Only one SO Contact could be selected</p>
          </div>
        </div>
        <div class="modal-footer sweet-footer p-1">
          <button type="button" class="btn red-bg round-btn" (click)="CloseReturnModel()" data-dismiss="modal">
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
  <div>
  </div>
  <!-- <div class="modal" id="invalidField">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="invalidFieldClose()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>Please Fill all the Required fields</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="invalidFieldClose()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
  </div>
</div>  -->


