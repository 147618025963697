<!-- Serial Flyout Start-->
<div class="Over-Manual-flyout" *ngIf="isSerialFlyout"></div>
<div class="serial-flyout" *ngIf="isSerialFlyout">
    <app-serial-lot-flyout [seriallotflyoutObj]="seriallotflyoutObj"
        (closeSerialLotFlyoutEvent)="closeSerialLotFlyout($event)"></app-serial-lot-flyout>
</div>
<!-- Serial Flyout End -->

<div class="titlecstmsidebar">
    <h2>{{transactionTypeLabel}}</h2>
    <h4>Complete the form below to create a new transaction record.</h4>
</div>

<div class="cstmbxdsis">
    <div class="col-12 p-0" *ngIf="transactionTypeVal == 0">
        <div class="flex-between">
            <div class="row">
                <div class="col-lg-4 col-md-6 mb-3">
                    <mat-form-field appearance="outline" class="w-100">
                        <mat-label>Action</mat-label>
                        <mat-select placeholder="Transaction Type" [(ngModel)]="transactionTypeVal"
                            (selectionChange)="changetransactionType($event.value)">
                            <mat-option *ngFor="let item of InvActionList"
                                [value]="item.actionId">{{item.actionName}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <div class="footer-btn">
                    <button type="button" (click)="CloseNewTransaction()" class="save-btn btn update-btn">
                        Cancel</button>
                </div>
            </div>
        </div>
    </div>

    <!-- Manual Entry Start -->
    <div class="Manual-flyout active" *ngIf="transactionTypeVal == 1">
        <div class="col-12 p-0">
            <form [formGroup]="inventoryManualForm" (ngSubmit)="inventoryManualFormSubmit(inventoryManualForm.value)">
                <div class="flex-between">
                    <div>
                        <div class="row p-0">
                            <div class="col-lg-4 col-md-6 mb-3">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Action</mat-label>
                                    <mat-select placeholder="Action" formControlName="action"
                                        (selectionChange)="changetransactionType($event.value)">
                                        <mat-option *ngFor="let item of InvActionList"
                                            [value]="item.actionId">{{item.actionName}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-4 col-md-6 mb-3">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Description</mat-label>
                                    <input matInput formControlName="description" type="text" placeholder="Description">
                                </mat-form-field>
                            </div>
                            <div class="col-lg-4 col-md-6 mb-3">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Batch Number</mat-label>
                                    <input matInput formControlName="batchNumber" type="text"
                                        placeholder="This will be auto generated">
                                </mat-form-field>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-lg-4 col-md-6 mb-3">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Document Type</mat-label>
                                    <mat-select formControlName="docType" placeholder="Document Type">
                                        <mat-option *ngFor="let item of InvDocTypeList"
                                            [value]="item.docTypeId">{{item.docType}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-4 col-md-6 mb-3">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Document Number</mat-label>
                                    <input formControlName="docNumber" matInput type="text">
                                </mat-form-field>
                            </div>
                            <div class="col-lg-4 col-md-6 mb-3">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Tracking</mat-label>
                                    <mat-select formControlName="manualTracking" placeholder="Tracking">
                                        <mat-option *ngFor="let item of PartTrackingList"
                                            [value]="item.id">{{item.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                                <mat-hint *ngIf="infoTracking == 1 && inventoryManualForm.value.balance>0">
                                    <a type="button"
                                        (click)="GetSerialLotFlyout(0, infoTracking, inventoryManualForm.value.balance)"
                                        class="lot-heading">Serial #</a>
                                </mat-hint>
                                <mat-hint *ngIf="infoTracking == 2 && inventoryManualForm.value.balance>0">
                                    <a type="button"
                                        (click)="GetSerialLotFlyout(0, infoTracking, inventoryManualForm.value.balance)"
                                        class="lot-heading">Lot #</a>
                                </mat-hint>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-2 col-6 mb-3">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Balance</mat-label>
                                    <input matInput appNumberOnly formControlName="balance" type="text"
                                        (change)="onBalanceChange()">
                                </mat-form-field>
                                <div
                                    *ngIf="inventoryManualForm.get('balance')?.invalid && inventoryManualForm.get('balance')?.touched">
                                    <div *ngIf="inventoryManualForm.get('balance')?.errors?.isNumber">
                                        <p class="error-msg">{{ validationMessages['isNumber'] }}</p>
                                    </div>
                                    <div *ngIf="inventoryManualForm.get('balance')?.errors?.greaterThanZero">
                                        <p class="error-msg">{{ validationMessages['greaterThanZero'] }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-2 col-6 mb-3">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Unit Cost</mat-label>
                                    <input matInput formControlName="unitcost" type="text" (change)="onBalanceChange()">
                                </mat-form-field>
                                <div
                                    *ngIf="inventoryManualForm.get('unitcost')?.invalid && inventoryManualForm.get('unitcost')?.touched">
                                    <div *ngIf="inventoryManualForm.get('unitcost')?.errors?.isNumber">
                                        <p class="error-msg">{{ validationMessages['isNumber'] }}</p>
                                    </div>
                                    <div *ngIf="inventoryManualForm.get('unitcost')?.errors?.greaterThanZero">
                                        <p class="error-msg">{{ validationMessages['greaterThanZero'] }}</p>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 mb-3">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Total Value</mat-label>
                                    <input matInput formControlName="totalvalue" type="text">
                                </mat-form-field>
                            </div>
                            <div class="col-lg-4 col-md-6 mb-3">

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 col-md-6 mb-3">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Business Channel</mat-label>
                                    <mat-select formControlName="businessChannel" placeholder="Business Channel">
                                        <mat-option *ngFor="let item of businessChannelList"
                                            [value]="item.channelName">{{item.channelName}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-4 col-md-6 mb-3">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Status</mat-label>
                                    <mat-select formControlName="status" placeholder="Status">
                                        <mat-option *ngFor="let item of InvStatusList"
                                            [value]="item.statusId">{{item.statusValue}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-4 col-md-6 mb-3">

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 flyout-card">
                                <span>Physical Location</span>
                            </div>
                            <div class="col-lg-4 col-md-6 mb-3">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label for="Warehouse-id">Warehouse</mat-label>
                                    <mat-select id="Warehouse-id" formControlName="warehouse" placeholder="Warehouse">
                                        <mat-option *ngFor="let item of CompanyWareHouseList"
                                            [value]="item.id">{{item.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-4 col-md-6 mb-3">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label for="Location-id">Location Name</mat-label>
                                    <mat-select id="Location-id" formControlName="LocName" placeholder="Location Name"
                                        (selectionChange)="locNameChange($event.value, 1)">
                                        <mat-option *ngFor="let item of InvLocationList"
                                            [value]="item.id">{{item.locationName}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-4 col-md-6 mb-3">

                            </div>
                        </div>
                        <div class="row">
                            <div class="col-12 flyout-card">
                                <span>Location Address</span>
                            </div>
                            <div class="col-lg-2 col-6 mb-3">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label for="level1">{{seletedDefaultLocation == undefined ? '' :
                                        seletedDefaultLocation.nameOfLevels1}}</mat-label>
                                    <mat-select id="level1" formControlName="level1" placeholder="Aisle">
                                        <mat-option *ngFor="let item of level1List" [value]="item">{{item}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-2 col-6 mb-3">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label for="level2">{{seletedDefaultLocation == undefined ? '' :
                                        seletedDefaultLocation.nameOfLevels2}}</mat-label>
                                    <mat-select id="level2" formControlName="level2" placeholder="Section">
                                        <mat-option *ngFor="let item of level2List" [value]="item">{{item}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-2 col-6 mb-3">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label for="level3">{{seletedDefaultLocation == undefined ? '' :
                                        seletedDefaultLocation.nameOfLevels3}}</mat-label>
                                    <mat-select id="level3" formControlName="level3" placeholder="Shelf">
                                        <mat-option *ngFor="let item of level3List" [value]="item">{{item}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-2 col-6 mb-3">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label for="level4">{{seletedDefaultLocation == undefined ? '' :
                                        seletedDefaultLocation.nameOfLevels4}}</mat-label>
                                    <mat-select id="level4" formControlName="level4" placeholder="Bin">
                                        <mat-option *ngFor="let item of level4List" [value]="item">{{item}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-4 col-md-6">

                            </div>
                        </div>
                    </div>
                    <div>
                        <div class="row">
                            <div class="col-12">
                                <div class="footer-btn">
                                    <button type="submit"
                                        [ngClass]="inventoryManualForm.invalid == true ? 'disabled' : 'saveclosebnt'"
                                        [disabled]="inventoryManualForm.invalid" class="save-btn btn update-btn">
                                        Save and Close</button>
                                    <button type="button" (click)="CloseNewTransaction()"
                                        class="save-btn btn update-btn">
                                        Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <!-- Manual Entry End -->

    <!-- Move Start -->
    <div class="scroll_cstmbx-era" *ngIf="transactionTypeVal == 4">
        <div class="col-12 p-0">
            <form [formGroup]="inventoryMoveForm" (ngSubmit)="inventoryMoveFormSubmit(inventoryMoveForm.getRawValue())">
                <div class="flex-between">

                    <div class="row">
                        <div class="col-lg-4 col-md-6 mb-3">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Action</mat-label>
                                <mat-select formControlName="action" placeholder="Action"
                                    (selectionChange)="changetransactionType($event.value)">
                                    <mat-option *ngFor="let item of InvActionList"
                                        [value]="item.actionId">{{item.actionName}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 col-md-6 mb-3">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Batch Number</mat-label>
                                <mat-select formControlName="batchNumber" placeholder="Batch Number"
                                    (selectionChange)="onMoveBatchChange($event)">
                                    <mat-option *ngFor="let item of BatchList"
                                        [value]="item">{{GetBatch(item)}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 col-md-6">

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-md-6 mb-3">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Move Type</mat-label>
                                <mat-select formControlName="moveType" placeholder="Move Type"
                                    (selectionChange)="moveFormValidation($event.value)">
                                    <mat-option *ngFor="let item of invmoveTypeList"
                                        [value]="item.reasonId">{{item.reasonCode}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 col-md-6 mb-3">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Description</mat-label>
                                <input matInput formControlName="description" type="text" placeholder="Description">
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 col-md-6">
                        </div>
                    </div>

                    <ng-container *ngIf="inventoryMoveForm.value.moveType == 10">
                        <div class="row">
                            <div class="col-12 flyout-card">
                                <span>Move from Location</span>
                            </div>
                            <div class="col-lg-4 col-md-6 mb-3">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Source</mat-label>
                                    <mat-select formControlName="fromSource" placeholder="Source i.e. 'Warehouse, Location, Location Levels'"
                                        (selectionChange)="fromSourceChange($event.value)">
                                        <mat-option *ngFor="let item of moveInvetoryList"
                                            [value]="item">{{GetWareHouseValue(item.warehouseID) +', '+
                                            GetLocationValue(item.locationName) +', '+ item.location +', Available: '+
                                            item.balance}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-4 col-md-6 mb-3">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Move Quantity</mat-label>
                                    <input matInput formControlName="moveLocQty" type="text"
                                        placeholder="Move Quantity">
                                </mat-form-field>
                                <div
                                    *ngIf="inventoryMoveForm.get('moveLocQty')?.invalid && inventoryMoveForm.get('moveLocQty')?.touched">
                                    <div *ngIf="inventoryMoveForm.get('moveLocQty')?.errors?.isNumber">
                                        <p class="error-msg">{{ validationMessages['isNumber'] }}</p>
                                    </div>
                                    <div *ngIf="inventoryMoveForm.get('moveLocQty')?.errors?.greaterThanZero">
                                        <p class="error-msg">{{ validationMessages['greaterThanZero'] }}</p>
                                    </div>
                                </div>
                                <div *ngIf="inventoryMoveForm.errors?.lessThanAvl">
                                    <p class="error-msg">{{ validationMessages['lessThanAvl']}}</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                            </div>
                        </div>
                        <!-- <div class="row">
                            <div class="col-12 flyout-card">
                                <span>Move from Location</span>
                            </div>
                            <div class="col-lg-4 col-md-6 mb-3">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Available Quantity</mat-label>
                                    <input matInput formControlName="avlLocQty" type="text"
                                        placeholder="Available Quantity">
                                </mat-form-field>
                            </div>
                            <div class="col-lg-4 col-md-6 mb-3">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Move Quantity</mat-label>
                                    <input matInput formControlName="moveLocQty" type="text"
                                        placeholder="Move Quantity">
                                </mat-form-field>
                                <div
                                    *ngIf="inventoryMoveForm.get('moveLocQty')?.invalid && inventoryMoveForm.get('moveLocQty')?.touched">
                                    <div *ngIf="inventoryMoveForm.get('moveLocQty')?.errors?.isNumber">
                                        <p class="error-msg">{{ validationMessages['isNumber'] }}</p>
                                    </div>
                                    <div *ngIf="inventoryMoveForm.get('moveLocQty')?.errors?.greaterThanZero">
                                        <p class="error-msg">{{ validationMessages['greaterThanZero'] }}</p>
                                    </div>
                                </div>
                                <div *ngIf="inventoryMoveForm.errors?.lessThanAvl">
                                    <p class="error-msg">{{ validationMessages['lessThanAvl']}}</p>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-4 col-md-6 mb-3">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Warehouse</mat-label>
                                    <mat-select formControlName="fromWarehouse" placeholder="Warehouse">
                                        <mat-option *ngFor="let item of CompanyWareHouseList"
                                            [value]="item.id">{{item.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-4 col-md-6 mb-3">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Location Name</mat-label>
                                    <mat-select formControlName="fromLocName" placeholder="Location Name"
                                        (selectionChange)="locNameChange($event.value, 2)">
                                        <mat-option *ngFor="let item of InvLocationList"
                                            [value]="item.id">{{item.locationName}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-4 col-md-6">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-2 col-md-6 mb-3">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>{{moveFromLocation == undefined ? '' :
                                        moveFromLocation.nameOfLevels1}}</mat-label>
                                    <mat-select formControlName="fromlevel1" placeholder="Aisle">
                                        <mat-option *ngFor="let item of moveFormlevel1List"
                                            [value]="item">{{item}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-2 col-md-6 mb-3">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>{{moveFromLocation == undefined ? '' :
                                        moveFromLocation.nameOfLevels2}}</mat-label>
                                    <mat-select formControlName="fromlevel2" placeholder="Section">
                                        <mat-option *ngFor="let item of moveFormlevel2List"
                                            [value]="item">{{item}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-2 col-md-6 mb-3">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>{{moveFromLocation == undefined ? '' :
                                        moveFromLocation.nameOfLevels3}}</mat-label>
                                    <mat-select formControlName="fromlevel3" placeholder="Shelf">
                                        <mat-option *ngFor="let item of moveFormlevel3List"
                                            [value]="item">{{item}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-2 col-md-6 mb-3">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>{{moveFromLocation == undefined ? '' :
                                        moveFromLocation.nameOfLevels4}}</mat-label>
                                    <mat-select formControlName="fromlevel4" placeholder="Bin">
                                        <mat-option *ngFor="let item of moveFormlevel4List"
                                            [value]="item">{{item}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-4 col-md-6">

                            </div>
                        </div> -->

                        <div class="row">
                            <div class="col-12 flyout-card">
                                <span>Move to Location</span>
                            </div>
                            <div class="col-lg-4 col-md-6 mb-3">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Warehouse</mat-label>
                                    <mat-select formControlName="toWarehouse" placeholder="Warehouse">
                                        <mat-option *ngFor="let item of CompanyWareHouseList"
                                            [value]="item.id">{{item.name}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-4 col-md-6 mb-3">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Location Name</mat-label>
                                    <mat-select formControlName="toLocName" placeholder="Location Name"
                                        (selectionChange)="locNameChange($event.value, 3)">
                                        <mat-option *ngFor="let item of InvLocationList"
                                            [value]="item.id">{{item.locationName}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-4 col-md-6">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-lg-2 col-md-6 mb-3">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>{{moveToLocation.nameOfLevels1}}</mat-label>
                                    <mat-select formControlName="tolevel1" placeholder="Aisle">
                                        <mat-option *ngFor="let item of moveTolevel1List"
                                            [value]="item">{{item}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-2 col-md-6 mb-3">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>{{moveToLocation.nameOfLevels2}}</mat-label>
                                    <mat-select formControlName="tolevel2" placeholder="Section">
                                        <mat-option *ngFor="let item of moveTolevel2List"
                                            [value]="item">{{item}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-2 col-md-6 mb-3">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>{{moveToLocation.nameOfLevels3}}</mat-label>
                                    <mat-select formControlName="tolevel3" placeholder="Shelf">
                                        <mat-option *ngFor="let item of moveTolevel3List"
                                            [value]="item">{{item}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-2 col-md-6 mb-3">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>{{moveToLocation.nameOfLevels4}}</mat-label>
                                    <mat-select formControlName="tolevel4" placeholder="Bin">
                                        <mat-option *ngFor="let item of moveTolevel4List"
                                            [value]="item">{{item}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="col-lg-4 col-md-6">

                            </div>
                        </div>
                    </ng-container>

                    <ng-container *ngIf="inventoryMoveForm.value.moveType == 11">
                        <div class="row">
                            <div class="col-lg-4 col-md-6 mb-3">
                                <div class="row">
                                    <div class="col-12 flyout-card">
                                        <span>From Business Channel</span>
                                    </div>
                                    <!-- <div class="col-12 mb-3">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Availability Quantity</mat-label>
                                            <input matInput formControlName="avlChannelQty" type="text"
                                                placeholder="Availability Quantity">
                                        </mat-form-field>
                                    </div> -->
                                    <div class="col-12 mb-3">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Source</mat-label>
                                            <mat-select formControlName="fromChannelSource" placeholder="Source i.e. 'Warehouse, Location, Location Levels'"
                                                (selectionChange)="fromSourceChange($event.value)">
                                                <mat-option *ngFor="let item of moveInvetoryList"
                                                    [value]="item">{{GetWareHouseValue(item.warehouseID) +', '+
                                                    GetLocationValue(item.locationName) +', '+ item.location +', Available: '+
                                                    item.balance}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                    <div class="col-12 mb-3">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Business Channel</mat-label>
                                            <mat-select formControlName="frombusinessChannel"
                                                placeholder="Business Channel">
                                                <mat-option *ngFor="let item of businessChannelList"
                                                    [value]="item.channelName">{{item.channelName}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>

                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6 mb-3">
                                <div class="row">
                                    <div class="col-12 flyout-card">
                                        <span>To Business Channel</span>
                                    </div>
                                    <div class="col-12 mb-3">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Move Quantity</mat-label>
                                            <input matInput formControlName="moveChannelQty" type="text"
                                                placeholder="Move Quantity">
                                        </mat-form-field>
                                        <div
                                            *ngIf="inventoryMoveForm.get('moveLocQty')?.invalid && inventoryMoveForm.get('moveLocQty')?.touched">
                                            <div *ngIf="inventoryMoveForm.get('moveLocQty')?.errors?.isNumber">
                                                <p class="error-msg">{{ validationMessages['isNumber'] }}</p>
                                            </div>
                                            <div *ngIf="inventoryMoveForm.get('moveLocQty')?.errors?.greaterThanZero">
                                                <p class="error-msg">{{ validationMessages['greaterThanZero'] }}</p>
                                            </div>
                                        </div>
                                        <div *ngIf="inventoryMoveForm.errors?.lessThanAvl">
                                            <p class="error-msg">{{ validationMessages['lessThanAvl']}}</p>
                                        </div>
                                    </div>
                                    <div class="col-12 mb-3">
                                        <mat-form-field appearance="outline" class="w-100">
                                            <mat-label>Business Channel</mat-label>
                                            <mat-select formControlName="tobusinessChannel"
                                                placeholder="Business Channel">
                                                <mat-option *ngFor="let item of businessChannelList"
                                                    [value]="item.channelName">{{item.channelName}}</mat-option>
                                            </mat-select>
                                        </mat-form-field>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-6">
                            </div>
                        </div>
                    </ng-container>


                    <div>
                        <div class="row">
                            <div class="col-12">
                                <div class="footer-btn">
                                    <button type="submit"
                                        [ngClass]="inventoryMoveForm.invalid == true ? 'disabled' : 'saveclosebnt'"
                                        [disabled]="inventoryMoveForm.invalid" class="save-btn btn update-btn">
                                        Save and Close</button>
                                    <button type="button" (click)="CloseNewTransaction()"
                                        class="save-btn btn update-btn">
                                        Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>

    </div>
    <!-- Move End -->

    <!-- Inventory Gain Start -->
    <div class="scroll_cstmbx-era" *ngIf="transactionTypeVal == 9">
        <div class="col-12 p-0">
            <form [formGroup]="inventoryGainForm" (ngSubmit)="inventoryGainFormSubmit(inventoryGainForm.getRawValue())">
                <div class="flex-between">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 mb-3">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Action</mat-label>
                                <mat-select formControlName="action" placeholder="Action"
                                    (selectionChange)="changetransactionType($event.value)">
                                    <mat-option *ngFor="let item of InvActionList"
                                        [value]="item.actionId">{{item.actionName}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 col-md-6 mb-3">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Batch Number</mat-label>
                                <mat-select formControlName="batchNumber" placeholder="Batch Number"
                                    (selectionChange)="onGainBatchChange($event)">
                                    <mat-option *ngFor="let item of BatchList"
                                        [value]="item">{{GetBatch(item)}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 col-md-6">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-md-6 mb-3">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Reason</mat-label>
                                <mat-select formControlName="reason" placeholder="Reason"
                                    (selectionChange)="onGainReasonChange($event)">
                                    <mat-option [value]="0">Select One</mat-option>
                                    <mat-option *ngFor="let item of invgainReasonList"
                                        [value]="item.reasonId">{{item.reasonCode}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 col-md-6 mb-3">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Description</mat-label>
                                <input matInput formControlName="description" type="text" placeholder="Description">
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 col-md-6">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 flyout-card">
                            <span>Current Balance</span>
                        </div>
                        <div class="col-lg-2 col-md-6 mb-3">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Balance</mat-label>
                                <input matInput formControlName="currentBalance" type="text">
                            </mat-form-field>
                        </div>
                        <div class="col-lg-2 col-md-6 mb-3">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Unit Cost</mat-label>
                                <input matInput formControlName="currentUnitcost" type="text">
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 col-md-6 mb-3">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Total Value</mat-label>
                                <input matInput formControlName="currentTotal" type="text"
                                    value="{{inventoryGainForm.getRawValue().currentBalance * inventoryGainForm.getRawValue().currentUnitcost | number : '1.5-5'}}">
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 col-md-6">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 flyout-card">
                            <span>Adjustments</span>
                        </div>
                        <div class="col-lg-2 col-md-6 mb-3">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>New Balance</mat-label>
                                <input matInput formControlName="adjBalance" type="text"
                                    (input)="adjGainBalanceChange()">
                            </mat-form-field>
                        </div>
                        <div class="col-lg-2 col-md-6 mb-3">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Unit Cost</mat-label>
                                <input matInput formControlName="adjUnitCost" type="text"
                                    (change)="adjGainBalanceChange()">
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 col-md-6 mb-3">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Total Value</mat-label>
                                <input matInput formControlName="adjTotal" type="text"
                                    value="{{inventoryGainForm.getRawValue().adjBalance * inventoryGainForm.getRawValue().adjUnitCost | number : '1.5-5'}}">
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 col-md-6">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 flyout-card">
                            <span>Inventory Gain</span>
                        </div>
                        <div class="col-lg-2 col-md-6 mb-3">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Balance</mat-label>
                                <input matInput formControlName="invGainBalance" type="text"
                                    value="{{inventoryGainForm.getRawValue().adjBalance - inventoryGainForm.getRawValue().currentBalance}}">
                            </mat-form-field>
                        </div>
                        <div class="col-lg-2 col-md-6 mb-3">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Unit Cost</mat-label>
                                <input matInput formControlName="invGainUnitCost" type="text"
                                    value="{{inventoryGainForm.getRawValue().adjUnitCost - inventoryGainForm.getRawValue().currentUnitcost | number : '1.5-5'}}">
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 col-md-6 mb-3">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Total Value of Loss</mat-label>
                                <input matInput formControlName="invGainTotal" type="text">
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 col-md-6">
                        </div>
                    </div>

                    <div>
                        <div class="row">
                            <div class="col-12">
                                <div class="footer-btn">
                                    <button type="submit"
                                        [ngClass]="this.inventoryGainForm.invalid == true ? 'disabled' : 'saveclosebnt'"
                                        [disabled]="this.inventoryGainForm.invalid" class="save-btn btn update-btn">
                                        Save and Close</button>
                                    <button type="button" (click)="CloseNewTransaction()"
                                        class="save-btn btn update-btn">
                                        Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <!-- Inventory Gain End -->

    <!-- Inventory Loss Start -->
    <div class="scroll_cstmbx-era" *ngIf="transactionTypeVal == 10">
        <div class="col-12 p-0">
            <form [formGroup]="inventoryLossForm" (ngSubmit)="inventoryLossFormSubmit(inventoryLossForm.getRawValue())">
                <div class="flex-between">
                    <div class="row">
                        <div class="col-lg-4 col-md-6 mb-3">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Action</mat-label>
                                <mat-select formControlName="action" placeholder="Action"
                                    (selectionChange)="changetransactionType($event.value)">
                                    <mat-option *ngFor="let item of InvActionList"
                                        [value]="item.actionId">{{item.actionName}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 col-md-6 mb-3">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Batch Number</mat-label>
                                <mat-select formControlName="batchNumber" placeholder="Batch Number"
                                    (selectionChange)="onLossBatchChange($event)">
                                    <mat-option [value]="0">Select One</mat-option>
                                    <mat-option *ngFor="let item of BatchList"
                                        [value]="item">{{GetBatch(item)}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 col-md-6">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-md-6 mb-3">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Reason</mat-label>
                                <mat-select formControlName="reason" placeholder="Reason"
                                    (selectionChange)="onLossReasonChange($event)">
                                    <mat-option [value]="0">Select One</mat-option>
                                    <mat-option *ngFor="let item of invlossReasonList"
                                        [value]="item.reasonId">{{item.reasonCode}}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 col-md-6 mb-3">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Description</mat-label>
                                <input matInput formControlName="description" type="text" placeholder="Description">
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 col-md-6">

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 flyout-card">
                            <span>Current Value</span>
                        </div>
                        <div class="col-lg-2 col-md-6 mb-3">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Balance</mat-label>
                                <input matInput formControlName="currentBalance" type="text">
                            </mat-form-field>
                        </div>
                        <div class="col-lg-2 col-md-6 mb-3">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Unit Cost</mat-label>
                                <input matInput formControlName="currentUnitcost" type="text">
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 col-md-6 mb-3">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Total Value</mat-label>
                                <input matInput formControlName="currentTotal" type="text"
                                    value="{{inventoryLossForm.getRawValue().currentBalance * inventoryLossForm.getRawValue().currentUnitcost | number : '1.5-5'}}">
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 col-md-6 ">

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 flyout-card">
                            <span>Adjustments</span>
                        </div>
                        <div class="col-lg-2 col-md-6 mb-3">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>New Balance</mat-label>
                                <input matInput formControlName="adjBalance" type="text"
                                    (input)="adjLossBalanceChange()">
                            </mat-form-field>

                        </div>
                        <div class="col-lg-2 col-md-6 mb-3">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Unit Cost</mat-label>
                                <input matInput formControlName="adjUnitCost" type="text"
                                    (input)="adjLossBalanceChange()">
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 col-md-6 mb-3">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Total Value</mat-label>
                                <input matInput formControlName="adjTotal" type="text"
                                    value="{{inventoryLossForm.getRawValue().adjBalance * inventoryLossForm.getRawValue().adjUnitCost | number : '1.5-5'}}">
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 col-md-6">

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 flyout-card">
                            <span>Inventory Loss</span>
                        </div>
                        <div class="col-lg-2 col-md-6 mb-3">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Balance</mat-label>
                                <input matInput formControlName="invLossBalance" type="text"
                                    value="{{inventoryLossForm.getRawValue().currentBalance - inventoryLossForm.getRawValue().adjBalance}}">
                            </mat-form-field>
                        </div>
                        <div class="col-lg-2 col-md-6 mb-3">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Unit Cost</mat-label>
                                <input matInput formControlName="invLossUnitCost" type="text"
                                    value="{{inventoryLossForm.getRawValue().currentUnitcost - inventoryLossForm.getRawValue().adjUnitCost | number : '1.5-5'}}">
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 col-md-6 mb-3">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Total Value of Loss</mat-label>
                                <input matInput formControlName="invLossTotal" type="text">
                            </mat-form-field>
                        </div>
                        <div class="col-lg-4 col-md-6">

                        </div>
                    </div>

                    <div>
                        <div class="row">
                            <div class="col-12">
                                <div class="footer-btn">
                                    <button type="submit"
                                        [ngClass]="this.inventoryLossForm.invalid == true ? 'disabled' : 'saveclosebnt'"
                                        [disabled]="this.inventoryLossForm.invalid" class="save-btn btn update-btn">
                                        Save and Close</button>
                                    <button type="button" (click)="CloseNewTransaction()"
                                        class="save-btn btn update-btn">
                                        Cancel</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <!-- Inventory Loss End -->

</div>