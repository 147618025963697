export class ShippingRate {
    carrier_ids: string[]
    from_country_code: string
    from_postal_code: string
    from_city_locality: string
    from_state_province: string
    to_country_code: string
    to_postal_code: string
    to_city_locality: string
    to_state_province: string
    weight: Weight
    dimensions: Dimensions
    confirmation: string
    address_residential_indicator: string
  }
  export class Weight {
    value: number
    unit: string
  }
  export class Dimensions {
    unit: string
    length: number
    width: number
    height: number
  }
  