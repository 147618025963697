import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, catchError, throwError } from 'rxjs';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TitleCasePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class PurchaseOrderService {
  baseUrl = environment.baseUrl;
  orderDetails: any = null;
  ExitDetails: any = null;
  backdata: any = null;
  sideBar: any;
  Receive: any;
  shipping: any;
  invetory: any;
  vendor: any;
  company: any;

  constructor(
    private _http: HttpClient,
    public titlecasePipe: TitleCasePipe,
  ) { }

  GetAllPurchaseOrderList(orderRequest:any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/PurchaseOrder/GetAllPurchaseOrderList', orderRequest,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetAllPOList(companyId) {
    return this._http.get(this.baseUrl + 'api/PurchaseOrder/GetAllPOList?companyId=' + companyId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetPOStatus(){
    return this._http.get(this.baseUrl + 'api/Order/GetAllPOStatus',
    { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
    .pipe(catchError(this.errorHandler));
  }

  getAllVendorPartPO(id, companyId, offsetNo, pageSize, searchTerm) {
    return this._http.get(this.baseUrl + 'api/PurchaseOrder/GetPartDrowListBindVendor?vendorId=' + id + '&companyId=' + companyId + '&offsetNo=' + offsetNo + '&pageSize=' + pageSize + '&searchTerm=' + searchTerm,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  getAllPartPOByVendor(id, companyId) {
    return this._http.get(this.baseUrl + 'api/PurchaseOrder/GetPartDropListByVendor?vendorId=' + id + '&companyId=' + companyId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetPOPartListByVendor(reqObj) {
    return this._http.post(this.baseUrl + 'api/PurchaseOrder/GetPOPartListByVendor', reqObj,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  // getAllPartPOByVendorTable(id, companyId,PartIds:string) {
  //   return this._http.get(this.baseUrl + 'api/PurchaseOrder/GetPartDropListByVendorTable?vendorId=' + id + '&companyId=' + companyId +'&PartIds=' + PartIds,
  //     { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
  //     .pipe(catchError(this.errorHandler));
  // }

  AddUpdatePO(data: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/PurchaseOrder/AddUpdatePO', data,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  UpdateReceiving(data: any, isReceived: boolean): Observable<any> {
    return this._http.post(this.baseUrl + 'api/PurchaseOrder/UpdateReceiving?isReceived=' + isReceived, data,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  POFilesUpload(files: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/PurchaseOrder/POFilesUpload', files,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GeneratePurchaseOrderNo(PoNo, companyId) {
    return this._http.get(this.baseUrl + 'api/PurchaseOrder/GetGeneratePONew?PONo=' + PoNo + '&companyId=' + companyId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetPOLog(purchaseId: any) {
    return this._http.get(this.baseUrl + 'api/PurchaseOrder/GetPOLogDetail?purchaseorderId=' + purchaseId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  //For Email Template

  AddEmailTemplate(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/EmailTemp/AddTemplate', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  UpdateEmailTemplate(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/EmailTemp/UpdateTemplate', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetAllEmailTemplate(companyId: any, tempName: any, tempType: any) {
    return this._http.get(this.baseUrl + 'api/EmailTemp/GetAllTemplate?companyId=' + companyId + '&tempName=' + tempName + '&tempType=' + tempType, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  deleteEmailTemplate(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/EmailTemp/DeleteTemplate?tempId=' + item, {},
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetEmailTemplate(id: any) {
    return this._http.get(this.baseUrl + 'api/EmailTemp/GetTemplate?tId=' + id, { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetPObyId(companyId:number ,id: number){
    return this._http.get(this.baseUrl + 'api/PurchaseOrder/GetPObyId?Id=' + id + '&companyId=' + companyId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetAllState() {
    return this._http.get(this.baseUrl + 'api/Order/GetStateList',
      { headers: new HttpHeaders().set('Authorization', 'Bearer' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetPOReport(companyId, statusId, vendorId, offsetNo, pageSize, fromDate, toDate) {
      return this._http.get(this.baseUrl + 'api/Report/GetPOReport?companyId=' + companyId + '&statusId=' + statusId + '&vendorId=' + vendorId + '&offsetNo=' + offsetNo + '&pageSize=' + pageSize + '&fromDate=' + fromDate + '&toDate=' + toDate,
        { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
        .pipe(catchError(this.errorHandler));
}

  GetAllPOReportData(companyId, statusId, vendorId, fromDate, toDate) {
    return this._http.get(this.baseUrl + 'api/Report/GetPOReportAllData?companyId=' + companyId + '&statusId=' + statusId + '&vendorId=' + vendorId + '&fromDate=' + fromDate + '&toDate=' + toDate,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || "server error.");
  }
}
