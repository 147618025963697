import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { ConstantsService } from 'src/app/constants.service';
import { SharedService } from 'src/app/services/shared.service';
// import { TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { GlobalChangeService } from 'src/app/services/global-change.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-role-management',
  templateUrl: './role-management.component.html',
  styleUrls: ['./role-management.component.css']
})
export class RoleManagementComponent implements AfterViewInit, OnDestroy, OnInit {
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  data: any;
  validation: boolean;
  name: any;
  id: any;
  userId: any;
  deletedItem: string;
  deleteDetail: string;
  form: FormGroup;
  roles: string;
  userRoleList: any;
  loader: boolean = false;
  buttonValue: any;
  rolePermissionsList: any;
  userRoleList1: any;
  userList: any;
  roleId: any;
  settingDetailCSS: any;
  recordAlreadyExist = false;
  settingLogoImage: any;
  permissionMessage = '';
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  assinedUserRoleContent: any;
  clearbutton: boolean = true;
  isRolePopUp = false;
  userGroupObjects = new Array();
  userGroupObjects_search = new Array();
  companyList: any;
  companyList_search: any;
  value = 0;
  value_search = 0;
  companyLoginId: any;
  TCreateUpdate: any = 1;
  vendorSearch: any = '';
  SearchValidation: any;
  SearchControl: boolean = false;
  ListShow: boolean = false;
  ListDiv: boolean = false;
  formSearch: FormGroup;
  // items: TreeviewItem[];
  // items_search: TreeviewItem[];
  // config = TreeviewConfig.create({
  //   hasAllCheckBox: true,
  //   hasFilter: true,
  //   hasCollapseExpand: true,
  //   decoupleChildFromParent: false,
  //   maxHeight: 400
  // });
  // config_search = TreeviewConfig.create({
  //   hasAllCheckBox: true,
  //   hasFilter: true,
  //   hasCollapseExpand: true,
  //   decoupleChildFromParent: false,
  //   maxHeight: 400
  // });
  SearchType: any;
  AllTemplate: any;
  Template: any = '';
  chkIsProduction: boolean = false;
  chkIsSkill: boolean = false;


  constructor(
    public sharedService: SharedService,
    private modalService: NgbModal,
    private constants: ConstantsService,
    private router: Router,
    private globalChangeService: GlobalChangeService,
  ) { }

  ngOnInit(): void {
    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);

    } else {
      this.userId = localStorage.getItem(this.constants.userId);
      this.settingDetailCSS = localStorage.getItem("settingDetailCSS");
      this.settingLogoImage = localStorage.getItem("settingImageLogo");
      this.SetSettingCSS(this.settingDetailCSS, this.settingLogoImage);
      this.rolePermissionsList = JSON.parse(localStorage.getItem('rolePermissionsList'));
      this.rolePermissionsList = this.rolePermissionsList.filter(x => x.pageId == 4);
      this.permissionMessage = '';
      if (this.rolePermissionsList != null && this.rolePermissionsList != undefined) {
        if (!this.rolePermissionsList[0].isView) {
          this.permissionMessage += 'view';
        } if (!this.rolePermissionsList[0].isInsert) {
          if (this.permissionMessage != '') {
            this.permissionMessage += ', ';
          }
          this.permissionMessage += 'add';
        } if (!this.rolePermissionsList[0].isUpdate) {
          if (this.permissionMessage != '') {
            this.permissionMessage += ', ';
          }
          this.permissionMessage += 'edit';
        } if (!this.rolePermissionsList[0].isDelete) {
          if (this.permissionMessage != '') {
            this.permissionMessage += ', ';
          }
          this.permissionMessage += 'delete';
        }
      }
      if (this.permissionMessage != '') {
        this.permissionMessage = 'You do not have access to ' + this.permissionMessage + '. Please contact to administrator!';
      }
      this.companyLoginId = localStorage.getItem(this.constants.companyId);

      this.getAllUsers();

      //ankit commented
      // this.getAllCompany_search(this.companyLoginId);
      // this.getAllCompany(this.companyLoginId);
      // this.getAllTemplate(this.companyLoginId);
      
      this.buttonValue = this.constants.Save;
      this.dtOptions = {
        pagingType: this.constants.full_numbers,
        pageLength: 10,
        order:[1, 'asc']
      };

      // this.getAllUserRoles();
      //this.getAllUserRolesByUserId();

      //ankit implementaion
      // get company by default set value
      let company = this.globalChangeService.getGlobalCompany();
      if(company>0){
        this.value = company;
        this.value_search = company;
      }
      else{
        this.ErrorModal('Error', 'Please select company.');
      }

      //on change function 
      this.globalChangeService.dropdownChange$.subscribe((data)=>{
        this.onValueChange(data);
        this.onValueChange_search(data);
      })
    }
    this.form = new FormGroup({
      'roles': new FormControl(),
      'Template': new FormControl(),
      'chkIsSkill': new FormControl(),
      'chkIsProduction': new FormControl()

    });
    this.formSearch = new FormGroup({
      'TCreateUpdate': new FormControl(),
      'vendorSearch': new FormControl(),
      'SearchValidation': new FormControl()
    });

    
  }


  ngAfterViewInit(): void {

    // Set css into header,save button,cancel button into session.
    this.settingDetailCSS = localStorage.getItem("settingDetailCSS");
    this.settingLogoImage = localStorage.getItem("settingImageLogo");

    this.SetSettingCSS(this.settingDetailCSS, this.settingLogoImage);
    this.dtTrigger.next('');

  }

  chngIsSkill(event) {
    if (event.target.checked == true) {
      this.chkIsSkill = true;
    }
    else {
      this.chkIsSkill = false;
    }
  }

  chngIsProduction(event) {
    if (event.target.checked == true) {
      this.chkIsProduction = true;
    }
    else {
      this.chkIsProduction = false;
    }
  }

  /* Function to get the all User  */
  getAllUsers() {
    this.sharedService.getAllUsers().subscribe(
      data => {
        this.loader = true;
        this.data = data;
        this.userList = this.data.dataList.filter(x => x.userId == this.userId);

        if (this.userList.length == 1) {
          this.roleId = this.userList[0].roleId;
          this.getAllRolePermissions();
        } else {
          this.roleId = '034eef3b-cf41-4a73-9a95-45543fe997ef';
          this.getAllRolePermissions();
        }
        this.loader = false;
        //this.dtTrigger.next('');
      });
  }
  /* Function to get the all roles permissions*/
  getAllRolePermissions() {

    this.sharedService.GetAllRolePermissionByUserId(this.roleId).subscribe(
      data => {
        this.data = data;
        if (this.data.dataList != undefined && this.data.dataList != null) {
          this.rolePermissionsList = this.data.dataList.filter(x => x.pageName == 'Role');
        }
      });
  }
  /* Function to get the all User roles by id*/
  getAllUserRolesByUserId(companySearch, searchTerm) {
    if(companySearch>0){
      this.sharedService.getAllUserRolesByUserId(companySearch, searchTerm).subscribe(
        data => {
  
          this.data = data;
          this.userRoleList1 = this.data.dataList;
          // this.dtTrigger.next();
          this.rerender();
        });
    }else{
      this.ErrorModal('Error', 'Please select company.')
    }
  }
  /* Function to get the all User roles */
  getAllUserRoles() {
    this.sharedService.getAllUserRoles().subscribe(
      data => {
        this.data = data;
        this.userRoleList = this.data.dataList;
        // this.dtTrigger.next('');
        this.rerender();

      });
  }

  /* Function to add/update the User roles */
  addNewRoles() {

    this.validation = true;
    this.clearbutton = true;
    if (this.form.controls.roles.status === this.constants.Invalid || this.value == 0
    ) {
      return;
    }

    const obj = {};
    obj['name'] = this.roles;
    obj['CompanyId'] = this.value;
    obj['TemplateId'] = Number(this.Template);
    obj['IsSkillRequired'] = this.chkIsSkill;
    obj['IsProduction'] = this.chkIsProduction;
    if (this.id != undefined && this.id != null && this.id != '') {
      obj['Id'] = this.id;
      // obj['ModifiedBy'] = this.userId;
      this.sharedService.updateUserroles(obj)
        .subscribe(
          data => {
            switch (data.statusCode) {
              case 200:
                this.buttonValue = this.constants.Save;
                $("#add_update").show();
                // this.toastr.success(this.constants.RecordUpdateSuccessfully);

                this.validation = false;
                this.TCreateUpdate = 1;
                this.SearchControl = false;
                this.ListShow = false;
                this.ListDiv = false;
                this.resetRole();
                // this.getAllUserRolesByUserId();
                // this.dtTrigger.unsubscribe();
                // this.dtTrigger.next('');
                //this.rerender();

                this.id = '';
                break;
              case 400:
                $("#wrong_msg").show();
                // this.toastr.error(this.constants.SomethingWentWrong);
                break;

              default:
                $("#wrong_msg").show();
              // this.toastr.error(this.constants.SomethingWentWrong);
            }
          }
        );
    } else {
      //obj['CreatedBy'] = this.userId;
      this.sharedService.addNewUserRoles(obj)
        .subscribe(
          data => {
            this.loader = true;
            switch (data.statusCode) {
              case 200:
                this.recordAlreadyExist = false;
                $("#add_success").show();
                // this.toastr.success(this.constants.RecordAddedSuccessfully);
                this.resetRole();
                this.validation = false;
                this.TCreateUpdate = 1;
                this.SearchControl = false;
                this.ListShow = false;
                this.ListDiv = false;
                //this.getAllUserRolesByUserId();
                // this.dtTrigger.unsubscribe();
                // this.dtTrigger.next('');
                //this.rerender();

                break;
              case 400:
                $("#wrong_msg").show();
                // this.toastr.error(this.constants.SomethingWentWrong);
                break;
              case 0:
                this.recordAlreadyExist = true;

                // if(data.errorMessage ==='Record Already Exist'){
                //   this.recordAlreadyExist= true;
                // }else{
                //   this.toastr.error(this.constants.SomethingWentWrong);
                // }
                // this.toastr.error(this.constants.SomethingWentWrong);
                break;
              default:
                $("#wrong_msg").show();
              // this.toastr.error(this.constants.SomethingWentWrong);
            }
            this.loader = false;
          }
        );
    }
  }
  /* open pop up for delete items  */
  deletePopup(item, content) {
    this.isRolePopUp = false;
    this.assinedUserRoleContent = content;
    this.deletedItem = '';
    this.modalService.open(content, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
    this.deleteDetail = 'Do you want to delete ' + item.name + '?';
    this.deletedItem = item;
  }
  /* open pop up for delete items  */
  displayAssignedRolePopUp() {
    this.isRolePopUp = true;
    this.deletedItem = '';
    this.modalService.open(this.assinedUserRoleContent, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
    this.deleteDetail = 'Role assigned to another user';
  }

  /* Function to delete the User roles */
  deleteUserRoles(item: any) {
    this.sharedService.deleteUserRoles(item).subscribe(
      data => {
        this.data = data;
        switch (data.statusCode) {
          case 200:
            $("#delete_success").show();
            // this.toastr.success(this.constants.RecordDeletedSuccessfully);
            this.getAllUserRolesByUserId(this.value_search, this.vendorSearch == "" ? "" : this.vendorSearch);
            this.rerender();

            break;
          case 400:
            if (data.errorMessage === 'Record Already Exist') {
              this.displayAssignedRolePopUp();
            } else {
              $("#wrong_msg").show();
            }
            break;
          default:
            $("#wrong_msg").show();
        }
      });
  }

  toggleEdit(item: any) {
    this.ListDiv = false;
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.buttonValue = this.constants.Update;
    this.id = item.id;
    this.value = item.companyId;
    this.roles = item.name;
    this.chkIsSkill = Boolean(item.isSkillRequired) == true ? true : false;
    this.chkIsProduction = Boolean(item.isProduction) == true ? true : false;
    this.clearbutton = false;
    this.getAllTemplate(this.value);
    this.Template = item.templateId;
  }

  RoleBack() {
    this.clearbutton = true;
    this.id = null;
    this.buttonValue = this.constants.Save;
    this.resetRole();
  }

  // Apply css into header,save button,cancel button.
  SetSettingCSS(settingCSS, logoImagePath) {
    if (settingCSS != null) {
      let headerCss = document.getElementsByClassName(this.constants.CssHealerClass) as HTMLCollectionOf<HTMLElement>;
      if (headerCss.length != 0) {
        headerCss[0].style.backgroundColor = settingCSS.split(',')[0];
      }
      let saveButtonCss = document.getElementsByClassName(this.constants.CssSaveBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (saveButtonCss.length != 0) {
        saveButtonCss[0].style.backgroundColor = settingCSS.split(',')[1];
        $(".blue-bg").css("background", settingCSS.split(',')[1]);
      }
      let cencelButtonCss = document.getElementsByClassName(this.constants.CssCancelBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (cencelButtonCss.length != 0) {
        cencelButtonCss[0].style.backgroundColor = settingCSS.split(',')[2];
        $(".darkred-bg").css("background", settingCSS.split(',')[2]);
      }
    }

    // set image into header section logo.
    if (logoImagePath != null && logoImagePath != "") {
      // set image into header section logo.
      (document.getElementById('hrdlogoImage') as HTMLImageElement).src = logoImagePath;
    }

  }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next('');
    });
  }

  // ngAfterViewInit(): void {
  //   this.dtTrigger.next('');
  // }
  ChangeTCreateUpdate(event) {
    this.TCreateUpdate = event.target.value;
    if (this.TCreateUpdate == "1") {
      this.SearchControl = false;
      this.ListShow = false;
      this.ListDiv = false;
      this.SearchValidation = false;
      // this.value_search=0;
      this.resetRole();
    }
    else if (this.TCreateUpdate == "0") {
      this.SearchControl = true;
      this.vendorSearch = "";
      // this.value_search=0;
    }
  }
  searchVendor() {
    this.SearchType = false;
    if (!this.vendorSearch || this.value_search == 0) {
      this.SearchValidation = true;
      return;
    }
    this.ListShow = true;
    this.ListDiv = true;
    this.SearchValidation = false;
    //this.value_search=0;
    this.getAllUserRolesByUserId(this.value_search, this.vendorSearch);
  }

  AllVendor() {
    this.vendorSearch = '';
    this.SearchType = true;
    if (this.value_search == 0) {
      this.SearchValidation = true;
      return;
    }
    this.ListShow = true;
    this.ListDiv = true;
    this.getAllUserRolesByUserId(this.value_search, this.vendorSearch);
  }
  ListDivShowHide() {
    if (this.ListDiv == true) {
      this.ListDiv = false;
    }
    else if (this.ListDiv == false) {
      this.ListDiv = true;
    }
  }
  // getAllCompany_search(companyId) {
  //   this.sharedService.GetDropdownCompanyHierarchyModel(this.companyLoginId).subscribe(
  //     data => {
  //       this.data = data;
  //       this.items_search = this.data.dataList;
  //       this.companyList_search = this.data.dataList;
  //       this.companyList_search.forEach((x) => {
  //         this.userGroupObjects_search.push(new TreeviewItem(x, false));
  //       });
  //       this.value_search = Number(companyId);
  //     });
  // }

  // getAllCompany(companyId) {
  //   this.sharedService.GetDropdownCompanyHierarchyModel(companyId).subscribe(
  //     data => {
  //       this.data = data;
  //       if (this.data.dataList != null && this.data.dataList != undefined) {
  //         this.companyList = this.data.dataList;
  //         this.companyList.forEach((x) => {
  //           this.userGroupObjects.push(new TreeviewItem(x, false));

  //         });
  //       }
  //       this.value = Number(companyId);
  //       //this.getAllCustomer(this.companyLoginId);
  //     });
  // }
  onValueChange(value: any): void {
    this.value = value;
    this.getAllTemplate(this.value);
    //this.GetCompanyDetails(this.value);
  }

  onValueChange_search(value: any): void {
    this.value_search = value;
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  resetRole() {

    this.roles = '';
    this.chkIsSkill = false;
    this.chkIsProduction = false;
    if (this.companyLoginId > 0) {
      this.value = Number(this.companyLoginId);
    }
    else {
      this.value = 0;
    }
  }
  // resetRole() {
  //   this.roles = '';
  //   this.getAllUserRolesByUserId(this.value_search,this.vendorSearch);
  // }

  addsuccess() {
    $("#add_success").hide();
  }
  updateitem() {
    $("#add_update").hide();
  }
  deleteitem() {
    $("#delete_success").hide();
  }
  msgitem() {
    $("#wrong_msg").hide();
  }

  ///Template create with role specific under the role module.
  getAllTemplate(companyId) {
    this.loader = true;
    this.sharedService.GetTemplatedropdownlist(Number(companyId)).subscribe(
      data => {
        this.data = data;
        if (companyId > 0) {
          this.AllTemplate = this.data.dataList;
        }
        else {
          this.AllTemplate = '';
        }
        this.loader = false;
      });
  }

  //ankit implemenation
  ErrorModal(title:string, message:string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }

}
