<section class="content-section pt-2">
		
    <div class="page-title mb-4">
        <h2>User management</h2>
        <div class="bread-crumb">
            <a href="#!">Home</a>
            <span class="mx-1">-</span>
            <span class="active">Users</span>
        </div>
    </div>
    
    <div class="row">
        <div class="col-sm-12">
            <div class="white-box">
                <div class="white-box-title">
                    <h5>User Details</h5>
                </div>
                <div class="card-desc">
                    <form>
                        <div class="row">
                            <div class="col-sm-6">
                                <div class="dropdown dropdown-tree" id="example"></div>
                            </div>
                            <div class="col-sm-6">
                                <select class="form-control">
                                    <option>Select User Role</option>
                                    <option>Vice President</option>
                                    <option>Manager</option>
                                    <option>Supervisor</option>
                                    <option>Sales Executive</option>
                                    <option>Technician</option>
                                </select>
                            </div>
                            <div class="col-sm-12">
                                <div class="row">
                                    <div class="col-sm-4">
                                        <input type="text" class="form-control" placeholder="Enter First Name">
                                    </div>
                                    <div class="col-sm-4">
                                        <input type="text" class="form-control" placeholder="Enter Middle Name">
                                    </div>
                                    <div class="col-sm-4">
                                        <input type="text" class="form-control" placeholder="Enter Last Name">
                                    </div>
                                </div>
                            </div>
                            <div class="col-sm-12">
                                <div class="row">
                                    <div class="col-sm-4">
                                        <input type="text" class="form-control" placeholder="Enter Phone No.">
                                    </div>
                                    <div class="col-sm-4">
                                        <input type="email" class="form-control" placeholder="Enter Email">
                                    </div>
                                    <div class="col-sm-4">
                                        <input type="password" class="form-control" placeholder="Enter Password">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="submit-from text-right">
                            <button class="btn blue-bg round-btn"><i class="fas fa-save" aria-hidden="true"></i> Save </button>
                            <button class="btn darkred-bg round-btn"><i class="fas fa-redo" aria-hidden="true"></i> Clear </button>
                        </div>
                    </form>
                </div>
            
            </div>
            
            
            <div class="white-box">
                <div class="white-box-title">
                    <h5>User List</h5>
                </div>
              
                
                <table id="datatable" class="display table clearfix">
                        <thead>
                            <tr>
                                <th>User Name</th>
                                <th>User Role</th>
                                <th>Company Name</th>
                                <th>Phone No</th>
                                <th>Email</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>									
                                <td>John Doe</td>
                                <td>Manager</td>
                                <td>Vehical Company</td>
                                <td>9067453234</td>
                                <td>user@email.com</td>
                                <td>
                                    <a href="#" class="blue-text"><i class="fas fa-eye"></i></a>
                                    <a href="#" class="green-text"><i class="fas fa-edit"></i></a>
                                    <a href="#" class="red-text"><i class="fas fa-trash-alt"></i></a>
                                </td>
                            </tr>
                            <tr>									
                                <td>John Doe</td>
                                <td>Manager</td>
                                <td>Vehical Company</td>
                                <td>9067453234</td>
                                <td>user@email.com</td>
                                <td>
                                    <a href="#" class="blue-text"><i class="fas fa-eye"></i></a>
                                    <a href="#" class="green-text"><i class="fas fa-edit"></i></a>
                                    <a href="#" class="red-text"><i class="fas fa-trash-alt"></i></a>
                                </td>
                            </tr>
                            <tr>									
                                <td>John Doe</td>
                                <td>Manager</td>
                                <td>Vehical Company</td>
                                <td>9067453234</td>
                                <td>user@email.com</td>
                                <td>
                                    <a href="#" class="blue-text"><i class="fas fa-eye"></i></a>
                                    <a href="#" class="green-text"><i class="fas fa-edit"></i></a>
                                    <a href="#" class="red-text"><i class="fas fa-trash-alt"></i></a>
                                </td>
                            </tr>
                            <tr>									
                                <td>John Doe</td>
                                <td>Manager</td>
                                <td>Vehical Company</td>
                                <td>9067453234</td>
                                <td>user@email.com</td>
                                <td>
                                    <a href="#" class="blue-text"><i class="fas fa-eye"></i></a>
                                    <a href="#" class="green-text"><i class="fas fa-edit"></i></a>
                                    <a href="#" class="red-text"><i class="fas fa-trash-alt"></i></a>
                                </td>
                            </tr>
                            <tr>									
                                <td>John Doe</td>
                                <td>Manager</td>
                                <td>Vehical Company</td>
                                <td>9067453234</td>
                                <td>user@email.com</td>
                                <td>
                                    <a href="#" class="blue-text"><i class="fas fa-eye"></i></a>
                                    <a href="#" class="green-text"><i class="fas fa-edit"></i></a>
                                    <a href="#" class="red-text"><i class="fas fa-trash-alt"></i></a>
                                </td>
                            </tr>
                            <tr>									
                                <td>John Doe</td>
                                <td>Manager</td>
                                <td>Vehical Company</td>
                                <td>9067453234</td>
                                <td>user@email.com</td>
                                <td>
                                    <a href="#" class="blue-text"><i class="fas fa-eye"></i></a>
                                    <a href="#" class="green-text"><i class="fas fa-edit"></i></a>
                                    <a href="#" class="red-text"><i class="fas fa-trash-alt"></i></a>
                                </td>
                            </tr>
                            
                        </tbody>
                    </table>
                    

            </div>
        </div>
    </div>

</section>