<div class="container-fluid">
  <div class="loading" *ngIf="loader">Loading&#8230;</div>
  <div class="row">
    <!-- <div class="col-sm-7 p-0">
      <div class="login-left">
        <img src="/assets/image/login-bg.jpg">
      </div>
    </div> -->
    <div class="login-body">
      <div class="login-content shadow">
        <!-- <img src="/assets/image/logo1.jpg"> -->
        <h2 class="m-tb-40 text-center">Login</h2>
        <form name="form" (ngSubmit)="f.form.valid && onSubmit()" #f="ngForm" novalidate>
          <div class="input-group mb-3" [ngClass]="{ 'was-validated': f.submitted && userName.invalid }">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Email</mat-label>
              <input matInput type="email" name="userName" [(ngModel)]="model.userName" #userName="ngModel"
              placeholder="Enter email*" [ngClass]="{ 'is-invalid': f.submitted && userName.invalid }" required
              userName/>
            </mat-form-field>
            <!-- <input type="email" class="form-control mb-2" name="userName" [(ngModel)]="model.userName" #userName="ngModel"
              placeholder="Enter email*" [ngClass]="{ 'is-invalid': f.submitted && userName.invalid }" required
              userName /> -->
            <div *ngIf="f.submitted && userName.invalid" class="invalid-feedback">
              <div *ngIf="userName.errors.required">User Name is required</div>
            </div>
            <div class="messagecolor text-left" *ngIf="(EmailNotFound) && !userName.invalid">Username or password is incorrect.
            </div>
          </div>
          <div class="input-group mb-3" [ngClass]="{ 'was-validated': f.submitted && password.invalid }">
            <mat-form-field appearance="outline" class="w-100">
              <mat-label>Password</mat-label>
              <input matInput type="password" placeholder="Enter Password" name="Enter password"
              [(ngModel)]="model.password" #password="ngModel" placeholder="Password*" autocomplete="on"
              [ngClass]="{ 'is-invalid': f.submitted && password.invalid }" required password/>
            </mat-form-field>
            <!-- <input type="password" placeholder="Enter Password" class="form-control" name="Enter password"
              [(ngModel)]="model.password" #password="ngModel" placeholder="Password*" autocomplete="on"
              [ngClass]="{ 'is-invalid': f.submitted && password.invalid }" required password /> -->
            <div *ngIf="f.submitted && password.invalid" class="invalid-feedback">
              <div *ngIf="password.errors.required">Password is required</div>
            </div>
           
          </div>
          <div class="row">
            <div class="col-7">
              <div class="form-check">
                <input type="checkbox" class="form-check-input" id="exampleCheck1">
                <label class="form-check-label f_size_12" for="exampleCheck1">Keep me logged in</label>
              </div>
            </div>
            <div class="col-5 text-right f_size_12">
              <a (click)="opemPasswordPopUp(content)">Forgot Password </a>
             
            </div>
          </div>
          <button type="submit()" class="btn red-sq-btn m-tb-40">Login</button>
        </form>
      </div>
    </div>
  </div>
</div>

<ng-template #content let-c="close" let-d="dismiss" id='forgasswordotP' >
  <div class="modal-header blue-bg">
    <h5 class="text-white font-weight-normal ">Forgot Password </h5>
    <button class="close btn" aria-label="Close" (click)="d('Cross click')" #closeBtn (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form [formGroup]="form">

      <p class="mt-1">{{emailDetails}}</p>
      <div class="form-group required">
        <input type="text" class="form-control1 customInput EmailValidation" placeholder="Enter Email*" required
          autocomplete="off" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$" maxlength="50" formControlName="emailId" (keypress)="RemovedEmailMessage($event.target)">
        <div *ngIf="((form.controls.emailId.value=='' || form.controls.emailId.value == undefined) && (validation))"
          class="text-danger input-error">
          Enter Email.
        </div>
        <div
          *ngIf="((!form.controls.emailId.valid && form.controls.emailId.value!='' && form.controls.emailId.value!=undefined)&&(validation))"
          class="text-danger input-error">
          Enter valid Email.</div>
          <div *ngIf="((UserDoseNotExist) && (validation))" class="text-danger input-error">
            User does not Exist.</div>
          
      
      </div>
    </form>

  </div>

  <div class="modal-footer text-center">
    <button type="button" class="btn roundBtn green-bg" (click)="sendEmail(deletedItem);">Send
      Email</button>
    <button type="button" class="btn roundBtn red-bg" id="close-btn" (click)="c('Close click')">Close</button>
  </div>

</ng-template>

<div class="modal" id="wrong_msg">
  <div class="modal-dialog">
      <div class="modal-content">
          <div class="modal-header1 darkred-bg sweet-heading p-0">
              <h4 class="modal-title sweet-heading"></h4>
              <button type="button" class="close btn alert_modal" (click)="msgitem()" data-dismiss="modal">&times;</button>
          </div>
          <div class="modal-body p-1">
              <div class="thank-you-pop">
                  <img src="./assets/image/close.png" alt="">
                  <h1>Alert</h1>
                  <p>Something went wrong</p>
              </div>
          </div>
          <div class="modal-footer sweet-footer p-1">
              <button type="button" class="btn red-bg round-btn" (click)="msgitem()"
                  data-dismiss="modal">Close</button>
          </div>
      </div>
  </div>
</div>