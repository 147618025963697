import { DatePipe } from '@angular/common';
import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { ConstantsService } from 'src/app/constants.service';
import { GlobalChangeService } from 'src/app/services/global-change.service';
import { PurchaseOrderService } from 'src/app/services/purchaseOrder/purchase-order.service';
import { PurchaseOrder } from 'src/app/shared/purchaseOrder';
import Swal from 'sweetalert2';
import { SharedService } from '../services/shared.service';

@Component({
  selector: 'app-email-config-list',
  templateUrl: './email-config-list.component.html',
  styleUrls: ['./email-config-list.component.css']
})
export class EmailConfigListComponent {
  loader: boolean = false;
  companyId: number = 0;
  dataSource: any;
  displayedColumns: string[] = ["menu", "name", "sender", "smtp", "port"];
  displayedColumns1: string[] = ["name", "sender", "smtp", "port"];
  selected: string = 'All';
  searchText: string = '';
  userId: string = '';

  constructor(
    private sharedService: SharedService,
    private constants: ConstantsService,
    private globalChangeService: GlobalChangeService,
    private router: Router
  ){
  }

  ngOnInit(): void{
    this.companyId = this.globalChangeService.getGlobalCompany();
    if (this.companyId > 0) {
      this.getList();
    } else {
      this.PopupModal(this.constants.APIError, this.constants.SelectCompany);
    }
    //on change function 
    this.globalChangeService.dropdownChange$.subscribe((data) => {
      this.companyId = data;
      this.getList();
    })
    this.userId = localStorage.getItem('userId');
  }

  getList() {
    this.sharedService.GetAllEmailConfig(this.companyId, this.searchText).subscribe(
      (data: any) => {
        this.dataSource = data.dataList;
      }
    )
  }

  onSearch(){
    this.getList();
  }

  getById(row: any){
    this.router.navigate(['/email-config-detail', row.id])
  }

  createNewOrder() {
    if(this.companyId>0){
      this.router.navigate(['/email-config-detail'])
    }else{
      this.PopupModal(this.constants.Alert, this.constants.SelectCompany);
    }
  }

  PopupModal(title: string, message: string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }
  
}

