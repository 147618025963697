import { Component, OnInit, ChangeDetectorRef, AfterViewInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ConstantsService } from 'src/app/constants.service';
import { SharedService } from 'src/app/services/shared.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { DataTableDirective } from 'angular-datatables';
// import { DownlineTreeviewItem, DropdownTreeviewComponent, TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { DatePipe } from '@angular/common';
import { GlobalChangeService } from 'src/app/services/global-change.service';
import Swal from 'sweetalert2';
import { PartService } from 'src/app/services/part/part.service';
import { CompanyService } from 'src/app/services/company/company.service';

declare var $: any


@Component({
  selector: 'app-my-production-task',
  templateUrl: './my-production-task.component.html',
  styleUrls: ['./my-production-task.component.css']
})
export class MyProductionTaskComponent implements  OnInit {
  recordAlreadyExist = false;
  settingDetailCSS: any;
  settingLogoImage: any;
  data: any = {};
  imageUrl: any = "/assets/image/add-part.png";
  datalist: any;
  vendorlist: any;
  categorylist: any;
  PartQuestions = [0];
  questionlist: any;
  questionlistNew: any;
  tempQuestionlist: [];
  userId: string;
  value: number;
  id: any;
  vendorId: any;
  name: any;
  description: any;
  cost: any;
  hours: any;
  imagePath: any
  selectedVendorValue = '';
  laborCharge: any;
  search: any;
  customPartId: any;
  customPartImagePath: any
  notes: any;
  partCategoryId: any;
  companyId: any;
  productId: any;
  isActive: any;
  createdDate: any;
  modifiedDate: any;
  createdBy: string;
  modifiedBy: string;
  partNo: any
  vendorName: any;
  partCategoryName: any;
  partname: any;
  form: FormGroup;
  questionId: any;
  selectedPartCategoryIdValue = '';
  SelectedUnitMeasure = '';
  buttonValue: string;
  partid: number;
  validation: boolean = false;
  loader: boolean = false;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  partIds: any;
  qnsId: any;
  deleteDetail: string;
  disabled = true;
  questionidZero: any;
  fileToUpload: File = null;
  deletedItem: string;
  PartImage: any;
  deletedItemImage: any;
  clearbutton: boolean = true;
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  rolePermissionsList: any;
  permissionMessage: string;
  unitofmeasurement: any;
  formSearch: FormGroup;
  // config = TreeviewConfig.create({
  //   hasAllCheckBox: true,
  //   hasFilter: true,
  //   hasCollapseExpand: true,
  //   decoupleChildFromParent: false,
  //   maxHeight: 400

  // });
  // items: TreeviewItem[];
  companyList: any;
  userGroupObjects = new Array();
  CompanyValue: any;
  VendorPartCost: any;
  CustomerNumber: any;
  VendorPartName: any;
  VendorPartNumber: any;
  UnitofMeasureId: any;

  InvValidation: boolean = false;
  InvValidationUpdate: boolean = false;
  InvList = [];
  InvDetails = [];
  normalShow = true;
  InvSno = 0;
  InvSnoEdit = 100000;
  InvQty: any;
  InvDate: any;
  InvCost: any;
  InvType = '';
  InvPartOrder = '';
  InvProductOrder = '';
  InvType2: any;
  InvTypeList: any;
  InvCustNo: any;
  InvServNo: any;
  InvCustRMA: any;
  UpdateInvQty: any;
  UpdateInvDate: any;
  UpdateInvCost: any;
  UpdateInvType = '';
  UpdateInvPartOrder = '';
  UpdateInvProductOrder = '';
  UpdateInvServNo: any;
  UpdateInvCustNo: any;
  UpdateInvCustRMA: any;
  DelRowNo: any;
  DelInvId: any;
  RowEdit: any;
  CurrentEditRow: any;
  EditRowTxtName: any;
  EditInvSno: any;
  btnShowvalidation: boolean = false;
  btnOkShow: boolean = false;
  now: Date;
  InvPartOrderDDL: any;
  InvProductOrderDDL: any;
  MinDate: any;
  TCreateUpdate: any = 1;
  userSearch: any;
  SearchValidation: any;
  SearchType: any;
  SearchControl: boolean = false;
  ListShow: boolean = false;
  ListDiv: boolean = false;
  ListAngle: any;
  // formSearch: FormGroup;
  userGroupObjects_search = new Array();
  value_search: number;
  companyList_search: any;
  // items_search: TreeviewItem[];
  FilertingData: any;
  ImageTypelist: any;
  ImageTypeId: any;
  SelectedImageTypeId = '';
  MultipleImage = [];
  PartImageDefault: any;
  PartImageArray = [];
  viewimagesshow: any;
  DefaultLogoPDF: any;
  Documentype: any;
  WordFile: any;
  fileUpload: any;
  PictureValue: any = '';
  skillLevel = '';
  skillLevelList: any;
  minInventory: any;
  @ViewChild('Image') myInputVariable: ElementRef;


  validationImage: boolean = false;
  items: any;
  items_search: any;
  constructor(public sharedService: SharedService,
    private cdr: ChangeDetectorRef,
    private constants: ConstantsService,
    private router: Router,
    public datepipe: DatePipe,
    private globalChangeService: GlobalChangeService,
    private partService: PartService,
    private companyService: CompanyService
  ) { }

  ngOnInit(): void {
    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);
    } else {
      this.rolePermissionsList = JSON.parse(localStorage.getItem('rolePermissionsList'));
      this.rolePermissionsList = this.rolePermissionsList.filter(x => x.pageId == 8);
      this.permissionMessage = '';
      if (this.rolePermissionsList != null && this.rolePermissionsList != undefined) {
        if (!this.rolePermissionsList[0].isView) {
          this.permissionMessage += 'view';
        } if (!this.rolePermissionsList[0].isInsert) {
          if (this.permissionMessage != '') {
            this.permissionMessage += ', ';
          }
          this.permissionMessage += 'add';
        } if (!this.rolePermissionsList[0].isUpdate) {
          if (this.permissionMessage != '') {
            this.permissionMessage += ', ';
          }
          this.permissionMessage += 'edit';
        } if (!this.rolePermissionsList[0].isDelete) {
          if (this.permissionMessage != '') {
            this.permissionMessage += ', ';
          }
          this.permissionMessage += 'delete';
        }
      }
      if (this.permissionMessage != '') {
        this.permissionMessage = 'You do not have access to ' + this.permissionMessage + '. Please contact to administrator!';
      }

      this.companyId = localStorage.getItem(this.constants.companyId);

      this.userId = localStorage.getItem(this.constants.userId);
      this.buttonValue = this.constants.Save;
      this.getSkillLevel(this.companyId,'');
      this.Getvendordropdownlist(this.companyId);
      this.getAllCompany_search(this.companyId);
      this.getAllCompany(this.companyId);
      this.UnitofMeasurementDropdownList();
      this.Getquestiondropdownlist(this.companyId);
      this.InvTypeDropdownList();
      this.InvProductOrderDropdownList(this.companyId);
      this.InvPartOrderDropdownList(this.companyId);
      this.DefaultLogoPDF = "/assets/image/PDFFile.png";
      this.WordFile = "/assets/image/WordFileImage.png";
      this.dtOptions = {
        pagingType: this.constants.full_numbers,
        pageLength: 10,
        order: [0, 'desc'],
        scrollX: true
      };

      //ankit implementaion
      // get company by default set value
      var company = this.globalChangeService.getGlobalCompany();
      if(company>0){
        this.value_search = company;
        this.CompanyValue = company;
        this.Getquestiondropdownlist(this.CompanyValue);
        this.Getvendordropdownlist(this.CompanyValue);
        this.InvPartOrderDropdownList(this.CompanyValue);
        this.InvProductOrderDropdownList(this.CompanyValue);
      }
      else{
        this.ErrorModal('Error', 'Please select company.');
      }

      //on change function 
      this.globalChangeService.dropdownChange$.subscribe((data)=>{
        this.onValueChange_search(data);
        this.companyChangeValue(data);
      })
    }
    let today = new Date();
    this.PartImageDefault = "/assets/image/add-part.png";
    this.MinDate = this.datepipe.transform(today, 'yyyy-MM-dd');

    this.form = new FormGroup({
      'name': new FormControl(),
      'partNo': new FormControl(),
      'description': new FormControl(),
      'hours': new FormControl(),
      'cost': new FormControl(),
      'VendorPartCost': new FormControl(),
      'search': new FormControl(),
      'partId': new FormControl(),
      'VendorPartName': new FormControl(),
      'VendorPartNumber': new FormControl(),
      'UnitofMeasureId': new FormControl(),
      'vendorId': new FormControl('', Validators.compose([Validators.required, Validators.minLength(1)])),
      'partCategoryId': new FormControl('', Validators.compose([Validators.required, Validators.minLength(1)])),
      'questionIds': new FormArray(
        [
          new FormGroup({
            questionId: new FormControl(0),
          }
          )
        ]
      ),
      'InvQty': new FormControl('', Validators.required),
      'InvDate': new FormControl('', Validators.required),
      'InvCost': new FormControl('', Validators.required),
      'InvType': new FormControl('', Validators.required),
      // 'InvCustNo': new FormControl('', Validators.required),
      'InvCustRMA': new FormControl(),
      'InvServNo': new FormControl('', Validators.required),
      'InvPartOrder': new FormControl('', Validators.required),
      'InvProductOrder': new FormControl('', Validators.required),
      'UpdateInvQty': new FormControl('', Validators.required),
      'UpdateInvDate': new FormControl('', Validators.required),
      'UpdateInvCost': new FormControl('', Validators.required),
      'UpdateInvType': new FormControl('', Validators.required),
      // 'UpdateInvCustNo': new FormControl('', Validators.required),
      'UpdateInvServNo': new FormControl('', Validators.required),
      'UpdateInvCustRMA': new FormControl(),
      'UpdateInvPartOrder': new FormControl('', Validators.required),
      'UpdateInvProductOrder': new FormControl('', Validators.required),
      //salman khan for Image type
      'ImageTypeId': new FormControl(),
      //end
      'skillLevel': new FormControl(),
      'minInventory': new FormControl()
    });
    this.formSearch = new FormGroup({
      'TCreateUpdate': new FormControl(),
      'userSearch': new FormControl(),
    });
  }
 
  InvTypeDropdownList() {
    this.sharedService.GetPOTypeDetail().subscribe(
      data => {
        this.data = data;
        this.InvTypeList = this.data.dataList;
      }
    )
  }
  
  ListDivShowHide() {
    if (this.ListDiv == true) {
      this.ListDiv = false;
      this.ListAngle = "down";
    }
    else if (this.ListDiv == false) {
      this.ListDiv = true;
      this.ListAngle = "up";
    }
  }

  SetSettingCSS(settingCSS, logoImagePath) {
    if (settingCSS != null) {
      let headerCss = document.getElementsByClassName(this.constants.CssHealerClass) as HTMLCollectionOf<HTMLElement>;
      if (headerCss.length != 0) {
        headerCss[0].style.backgroundColor = settingCSS.split(',')[0];
      }
      let saveButtonCss = document.getElementsByClassName(this.constants.CssSaveBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (saveButtonCss.length != 0) {
        saveButtonCss[0].style.backgroundColor = settingCSS.split(',')[1];
        $(".blue-bg").css("background", settingCSS.split(',')[1]);
        $(".upload_btn").css("background", settingCSS.split(',')[1]);
      }
      let cencelButtonCss = document.getElementsByClassName(this.constants.CssCancelBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (cencelButtonCss.length != 0) {
        cencelButtonCss[0].style.backgroundColor = settingCSS.split(',')[2];
        $(".darkred-bg").css("background", settingCSS.split(',')[2]);
      }
    }
    if (logoImagePath != null && logoImagePath != "") {
      (document.getElementById('hrdlogoImage') as HTMLImageElement).src = logoImagePath;
    }
  }


  ngAfterViewInit(): void {
    this.cdr.detectChanges();
    this.dtTrigger.next('');
    this.settingDetailCSS = localStorage.getItem("settingDetailCSS");
    this.settingLogoImage = localStorage.getItem("settingImageLogo");
    this.SetSettingCSS(this.settingDetailCSS, this.settingLogoImage);
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

 
  onchangeEvent(event) {
    this.qnsId = '';
    this.qnsId = parseInt(event);
    $('#requiredmessage').html("")
  }

  onEnterVendorPartName(event: any) {
    if (!this.id) {
      this.name = event.target.value;
    }
  }

  onEnterVendorPartNumber(event: any) {
    if (!this.id) {
      this.partNo = event.target.value;
    }
  }

  getSkillLevel(id,roleId) {
    this.sharedService.getSkillLevelUser(id,roleId).subscribe(
      data => {
        this.data = data;
        if (this.data.dataList) {
          this.skillLevelList = this.data.dataList;
        }
        else {
          this.skillLevelList = '';
        }
      });
  }
   
  Getvendordropdownlist(companyId) {
    this.partService.Getvendordropdownlist(companyId).subscribe(
      data => {
        this.data = data;
        if (companyId > 0) {
          this.vendorlist = this.data.dataList;
        }
        else {
          this.selectedVendorValue = '';
        }
        this.GetpartGategoryDropdownList(companyId);

      }
    )
  }

  GetpartGategoryDropdownList(companyId) {
    this.partService.GetpartGategoryDropdownList(companyId, '').subscribe(
      data => {
        this.data = data;
        if (companyId > 0) {
          this.categorylist = this.data.dataList.filter(x => x.id != this.constants.MisceType);
        }
        else {
          this.selectedPartCategoryIdValue = '';
        }
        // this.GetquestiondropdownlistDuplicate(companyId);

      }
    )
  }


  UnitofMeasurementDropdownList() {

    this.partService.UnitofMeasurement().subscribe(
      data => {
        this.data = data;
        this.unitofmeasurement = this.data.dataList;
        this.GetAllImageTypeList();

      }
    )
  }

  GetAllImageTypeList() {

    this.partService.GetAllImageTypeList().subscribe(
      data => {
        this.data = data;
        this.ImageTypelist = this.data.dataList;

      }
    )
  }

  companyChangeValue(value: any): void {
    this.CompanyValue = value;
    this.Getquestiondropdownlist(this.CompanyValue);
    this.Getvendordropdownlist(this.CompanyValue);
    this.InvPartOrderDropdownList(this.CompanyValue);
    this.InvProductOrderDropdownList(this.CompanyValue);
    //this.companyId=this.CompanyValue;
  }

  Getquestiondropdownlist(companyId) {
    this.partService.Getquestiondropdownlist(companyId).subscribe(
      data => {
        this.data = data;
        if (companyId > 0) {
          this.questionlist = this.data.dataList;
        }
        else {

        }
        this.tempQuestionlist = this.data.dataList;
        this.questionlistNew = [...this.data.dataList];
        //this.GetAllPart(this.companyId,'');

      }
    )
  }

  GetquestiondropdownlistDuplicate(companyId) {

    this.partService.Getquestiondropdownlist(companyId).subscribe(
      data => {
        this.data = data;
        if (companyId > 0) {
          this.questionlist = this.data.dataList;
          if (this.FilertingData.data.partQuestions != null && this.FilertingData.data.partQuestions.length > 0) {
            this.FilertingData.data.partQuestions.forEach(cateLst => {

              this.questionlist = this.questionlist.filter(x => x.id != cateLst.questionId);
            });

          }
        }
        else {

        }
        this.tempQuestionlist = this.data.dataList;
        this.questionlistNew = [...this.data.dataList];

      }
    )
  }

  getAllCompany(companyId) {
    this.companyService.GetDropdownCompanyHierarchyModel(this.companyId).subscribe(
      data => {
        this.data = data;
        this.items = this.data.dataList;
        this.companyList = this.data.dataList;
        // this.companyList.forEach((x) => {
        //   this.userGroupObjects.push(new TreeviewItem(x, false));
        // });
        this.CompanyValue = Number(companyId);
        // this.getAllState();

      });
  }


  ChangeTCreateUpdate(event) {
    this.TCreateUpdate = event.target.value;
    if (this.TCreateUpdate == "1") {
      this.SearchControl = false;
      this.ListShow = false;
      this.ListDiv = false;
      this.clearbutton = true;
      this.buttonValue = this.constants.Save;
      this.resetvendorData();
    }
    else if (this.TCreateUpdate == "0") {
      this.SearchControl = true;
      this.userSearch = "";
    }
  }
   
  searchUser() {
    this.SearchType = false;
    if (!this.userSearch || this.value_search == 0) {
      this.SearchValidation = true;
      return;
    }
    this.ListShow = true;
    this.ListDiv = true;
    //this.GetAllPart(this.value_search, this.userSearch);
  }

  // GetAllPart(companyId, searchitem) {
  //   this.loader = true;
  //   this.sharedService.GetAllPart(companyId, searchitem).subscribe(
  //     data => {
  //       this.data = data;
  //       if (this.data.dataList != null && this.data.dataList != undefined) {
  //         this.datalist = this.data.dataList;
  //       }
  //       this.loader = false;
  //       this.ListAngle = "up";
  //       this.rerender();
  //     }
  //   )
  // }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next('');
    });
  }

  AllUser() {
    this.SearchType = true;
    if (this.value_search == 0) {
      this.SearchValidation = true;
      return;
    }
    this.ListShow = true;
    this.ListDiv = true;
    //this.GetAllPart(this.value_search, '');
  }

  resetvendorData() {
    this.recordAlreadyExist = false;
    this.vendorId = '';
    this.name = '';
    this.partNo = '';
    this.description = '';
    this.cost = '';
    this.VendorPartCost = '';
    this.hours = '';
    this.search = '';
    this.partCategoryId = '';
    this.selectedVendorValue = '';
    this.selectedPartCategoryIdValue = '';
    this.imageUrl = '/assets/image/add-part.png';
    this.VendorPartName = '';
    this.VendorPartNumber = '';
    this.SelectedUnitMeasure = '';
    this.minInventory = '';
    this.skillLevel = '';

    if (this.companyId > 0) {
      this.value = this.companyId
    }
    else {
      this.value = 0;
    }
    if (this.questionArray.length > 0) {
      const deletedItem = this.questionArray.controls.splice(1, this.questionArray.length - 1);
      for (let i = 0; i < deletedItem.length; i++) {
        const arrayItem = this.questionlistNew.find(x => x.id === deletedItem[i].value.questionId);
        if (arrayItem) {
          this.questionlist.push(arrayItem);
        }
      }
    }

    this.InvQty = '';
    this.InvDate = '';
    this.InvCost = '';
    this.InvType = '';
    this.InvCustNo = '';
    this.InvServNo = '';
    this.InvCustRMA = '';
    this.InvList = [];
    this.InvDetails = [];
    this.MultipleImage = [];
    this.SelectedImageTypeId = '';
    this.validationImage = false;
    this.PartImageArray = [];
    this.fileUpload = '';

  }

   get questionArray() {
    return this.form.get('questionIds') as FormArray;
  }

  deletePart(item: any) {
    this.loader = true;
    this.partService.DeletePart(item).subscribe(
      data => {
        this.data = data;
        switch (data.statusCode) {
          case 200:
            $("#delete_success").show();
            // this.toastr.success(this.constants.RecordDeletedSuccessfully);
            //this.GetAllPart(this.value_search, this.userSearch == "" ? "" : this.userSearch);
            this.rerender();
            break;
          case 400:
            $("#wrong_msg").show();
            // this.toastr.error(this.constants.SomethingWentWrong);
            break;
          default:
            $("#wrong_msg").show();
          // this.toastr.error(this.constants.SomethingWentWrong);
        }
        this.loader = false;
      });
  }

  getAllCompany_search(companyId) {
    this.companyService.GetDropdownCompanyHierarchyModel(this.companyId).subscribe(
      data => {
        this.data = data;
        this.items_search = this.data.dataList;
        this.companyList_search = this.data.dataList;
        // this.companyList_search.forEach((x) => {
        //   this.userGroupObjects_search.push(new TreeviewItem(x, false));
        // });
        this.value_search = Number(companyId);
        //this.getAllState();
      });
  }

  onValueChange_search(value: any): void {
    this.value_search = value;
    this.InvProductOrderDropdownList(this.value_search);
    this.InvPartOrderDropdownList(this.value_search);
    this.getSkillLevel(this.value_search,'');
  }
 
  InvPartOrderDropdownList(companyId) {
    // Pass 2 For Part Order
    this.partService.PartInvOrderDropdownList(2, companyId).subscribe(
      data => {
        this.data = data;
        if (companyId > 0) {
          this.InvPartOrderDDL = this.data.dataList;
        }
        else {

        }
      }
    );
  }

  InvProductOrderDropdownList(companyId) {
    // Pass 1 For Product Order
    this.partService.PartInvOrderDropdownList(1, companyId).subscribe(
      data => {
        this.data = data;
        if (companyId > 0) {
          this.InvProductOrderDDL = this.data.dataList;
        }
        else {

        }
      }
    );
  }
 
  AddPart() {

    this.validation = true;
    this.recordAlreadyExist = false;
    this.clearbutton = true;
    if (this.form.controls.name.status === this.constants.Invalid ||
      this.form.controls.partNo.status === this.constants.Invalid ||
      this.form.controls.cost.status === this.constants.Invalid ||
      this.form.controls.hours.status === this.constants.Invalid ||
      this.form.controls.vendorId.status === this.constants.Invalid ||
      this.form.controls.partCategoryId.status === this.constants.Invalid ||
      // this.form.controls.CustomerPartName.status === this.constants.Invalid||
      // this.form.controls.CustomerPartNumber.status === this.constants.Invalid||
      this.CompanyValue === 0
      || this.minInventory == null || this.minInventory == undefined || this.minInventory == ""
    ) {
      return;
    }

    let obj = {};
    const Defaultimage = '/assets/image/add-part.png';
    obj['name'] = this.name;
    obj['partNo'] = this.partNo;
    obj['description'] = this.description;
    obj['cost'] = parseFloat(this.cost);
    obj['hours'] = this.hours;
    obj['search'] = this.search;
    obj['partImage'] = this.PartImage;
    obj['companyId'] = Number(this.CompanyValue);
    obj['vendorId'] = parseInt(this.selectedVendorValue);
    obj['partCategoryId'] = parseInt(this.selectedPartCategoryIdValue);
    obj['VendorPartCost'] = parseFloat(this.VendorPartCost);
    obj['IsCustompart'] = false;
    obj['VendorPartName'] = this.VendorPartName;
    obj['VendorPartNumber'] = this.VendorPartNumber;
    obj['UnitofMeasureId'] = parseInt(this.SelectedUnitMeasure);
    obj['MinInventory'] = Number(this.minInventory);
    obj['SkillLevel'] = Number(this.skillLevel);


    let invArr = this.InvList;
    for (var j = 0; j < invArr.length; j++) {
      let invObj = {};
      let LocalServNo = '';
      let LocalPartOrderId = '';
      let LocalProductOrderId = '';
      // let LocalInvDate = invArr[j].InvDate.replace(/(\d{2})-(\d{2})-(\d{4})/, "$2/$1/$3");

      if (invArr[j].InvType == "5") {
        LocalServNo = invArr[j].InvType2;
      }
      else if (invArr[j].InvType == "6") {
        LocalPartOrderId = invArr[j].InvType2;
      }
      else if (invArr[j].InvType == "7") {
        LocalProductOrderId = invArr[j].InvType2;
      }

      invObj["Id"] = invArr[j].id;
      invObj["PartId"] = parseInt(invArr[j].partId);
      invObj["Qty"] = parseInt(invArr[j].InvQty);
      invObj["OrderedQty"] = parseInt(invArr[j].InvQtyOrdered);
      // invObj["Date"] = this.datepipe.transform(LocalInvDate, 'yyyy-MM-dd', 'es-ES');
      invObj["Date"] = invArr[j].InvDate;
      invObj["Cost"] = parseFloat(invArr[j].InvCost);
      invObj["TypeId"] = parseInt(invArr[j].InvType);
      invObj["ServiceNo"] = LocalServNo;
      invObj["PartOrderId"] = parseInt(LocalPartOrderId);
      invObj["ProductOrderId"] = parseInt(LocalProductOrderId);
      // invObj["CustomerSO"] = invArr[j].InvCustNo;
      invObj["CustomerRMA"] = invArr[j].InvCustRMA;
      invObj["CreatedDate"] = this.now = new Date();
      invObj["CreatedBy"] = this.userId;
      invObj["IsActive"] = true;
      invObj["PurchaseOrderId"] = invArr[j].purchaseOrderId;
      invObj["ShipQty"] = invArr[j].shipQty;
      // invObj["RcvdId"] = invArr[j].rcvdId;
      this.InvDetails.push(invObj);
    }
    obj["PartInventory"] = this.InvDetails;
    let dataPart = this.MultipleImage;
    for (var j = 0; j < dataPart.length; j++) {

      let partObj = {};
      partObj["PartType"] = parseInt(dataPart[j].partypeName);
      partObj["DocumentType"] = dataPart[j].documenttype;
      partObj['CreatedBy'] = this.userId;
      partObj['IsActive'] = true;
      partObj["CreatedDate"] = this.now = new Date();
      partObj["PartId"] = this.id;
      if (dataPart[j].imageUrl.changingThisBreaksApplicationSecurity) {
        dataPart[j].imageUrl = dataPart[j].imageUrl.changingThisBreaksApplicationSecurity;
      }
      partObj["ImagePath"] = dataPart[j].imageUrl == Defaultimage ? null : dataPart[j].imageUrl;
      this.PartImageArray.push(partObj);
    }
    obj["PartImageUpload"] = this.PartImageArray;
    const questionIds = [];
    for (let index = 1; index < this.questionArray.controls.length; index++) {
      questionIds.push(this.questionArray.controls[index].value);
    }
    obj['PartQuestions'] = questionIds;
    //salmank 

    //End
    if (this.id != undefined && !Number.isNaN(this.id)) {

      obj['Id'] = this.id;
      obj['ModifiedBy'] = this.userId;
      obj['CreatedBy'] = this.userId;
      this.loader = true;
      this.partService.UpdatePart(obj)
        .subscribe(
          data => {
            switch (data.statusCode) {
              case 200:
                this.buttonValue = this.constants.Save;
                $("#add_update").show();
                // this.toastr.success(this.constants.RecordUpdateSuccessfully);
                this.validation = false;
                this.resetvendorData();
                this.MultipleImage = [];
                this.TCreateUpdate = 1;
                this.SearchControl = false;
                this.ListShow = false;
                this.ListDiv = false;
                this.loader = false;
                // this.GetAllPart(this.companyId);
                // this.rerender();
                this.id = null;
                break;
              case 400:
                $("#wrong_msg").show();
                // this.toastr.error(this.constants.SomethingWentWrong);
                break;
              default:
                $("#wrong_msg").show();
              // this.toastr.error(this.constants.SomethingWentWrong);
            }
            this.loader = false;
          }
        );
    }
    else {

      obj['CreatedBy'] = this.userId;
      this.loader = true;
      this.partService.AddPart(obj)
        .subscribe(
          data => {
            switch (data.statusCode) {
              case 200:
                this.recordAlreadyExist = false;
                $("#add_success").show();
                // this.toastr.success(this.constants.RecordAddedSuccessfully);
                this.validation = false;
                this.MultipleImage = [];
                this.resetvendorData();
                this.TCreateUpdate = 1;
                this.SearchControl = false;
                this.ListShow = false;
                this.ListDiv = false;
                this.loader = false;
                // this.GetAllPart(this.companyId);
                // this.rerender();

                break;
              case 400:
                if (data.errorMessage === 'Record Already Exist') {
                  this.recordAlreadyExist = true;
                } else {
                  $("#wrong_msg").show();
                  // this.toastr.error(this.constants.SomethingWentWrong);
                }
                break;
              default:
                $("#wrong_msg").show();
              // this.toastr.error(this.constants.SomethingWentWrong);
            }
            this.loader = false;
          }


        );
    }
  }

  //ankit implemenation
  ErrorModal(title:string, message:string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }
}



