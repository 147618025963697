import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConstantsService } from 'src/app/constants.service';
import { SharedService } from 'src/app/services/shared.service';
import { DomSanitizer } from "@angular/platform-browser";
import { Subject } from 'rxjs';
import { DataTableDirective } from 'angular-datatables';
import { CompanyService } from 'src/app/services/company/company.service';

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit, AfterViewInit {
  rolePermissionsList: any;
  permissionMessage: string;
  sessionId: any;
  token: any;
  userId: any;
  data: any;
  settingDetailCSS: any;
  settingImageLogo: any;
  settingLaborPerHrsCost: any;
  getAllDashBoardDetail = [];
  companyId: string;
  notes: any;
  PurchaseNumber: any;
  ChkPO: any = false;
  chkReviced: any = false;
  widgetData1 = [];
  widgetData2 = [];
  widgetData3 = [];
  widgetData4 = [];
  widgetData5 = [];
  VUrl: any;
  IsNotTemplateId: boolean = false;
  IsTemplateId: boolean = false;
  loader: boolean = false;

  //Datatables
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  constructor(
    private companyService: CompanyService,
    private route: ActivatedRoute,
    private constants: ConstantsService,
    public sharedService: SharedService,
    private router: Router,
    public _sanitizer: DomSanitizer
  ) { }

  ngOnInit(): void {
    this.dtOptions = {
      pagingType: this.constants.full_numbers,
      pageLength: 10,
      order: [0, 'desc'],
      scrollX: true
    };
    this.getSetting();
  }

  getSetting() {
    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);
    }
    else {

      this.rolePermissionsList = JSON.parse(localStorage.getItem('rolePermissionsList'));
      if (this.rolePermissionsList) {
        this.rolePermissionsList = this.rolePermissionsList.filter(x => x.pageId == 1);
      }

      this.permissionMessage = '';
      if (this.rolePermissionsList != null && this.rolePermissionsList != undefined) {
        if (!this.rolePermissionsList[0].isView) {
          this.permissionMessage += 'view';
        }
      }
      if (this.permissionMessage != '') {
        this.permissionMessage = 'You do not have access to ' + this.permissionMessage + '. Please contact to administrator!';
        return;
      }
      else {
        this.userId = localStorage.getItem(this.constants.userId);
        this.companyId = localStorage.getItem(this.constants.companyId);
        this.companyService.GetSettingDetails(this.userId).subscribe(
          data => {

            this.data = data;
            if (this.data.data != null && this.data.data != undefined) {
              this.settingDetailCSS = localStorage.setItem('settingDetailCSS', this.data.data.color);
              this.settingImageLogo = localStorage.setItem('settingImageLogo', this.data.data.logoName);
              this.settingLaborPerHrsCost = localStorage.setItem(this.constants.laborPerHrsCost, this.data.data.laborCost);
              this.notes = localStorage.setItem("notes", this.data.data.notes);
              localStorage.setItem("PONumber", this.data.data.purchaseOrderNo);
              localStorage.setItem("ChkPO1", this.data.data.isPo);
              localStorage.setItem("Received", this.data.data.isOperational);

              localStorage.setItem("Prefix", this.data.data.prefix);
              //for sale order number
              localStorage.setItem("SalesOrdernumber", this.data.data.salesOrderNo);
              localStorage.setItem("SalesOrderPrefix", this.data.data.salesOrderPrefix);
              localStorage.setItem("SaleTax", this.data.data.saleTax == null ? '' : this.data.data.saleTax);
              //end functionality 
              // localStorage.setItem("chkReviced1", this.data.data.isOperational);
              if (this.data.data.logoName != null && this.data.data.logoName != "") {
                (document.getElementById('hrdlogoImage') as HTMLImageElement).src = this.data.data.logoName;
              }
              if (this.data.data.color != null) {
                let headerCss = document.getElementsByClassName(this.constants.CssHealerClass) as HTMLCollectionOf<HTMLElement>;
                if (headerCss.length != 0) {
                  headerCss[0].style.backgroundColor = this.data.data.color.split(',')[0];
                }
                let saveButtonCss = document.getElementsByClassName(this.constants.CssSaveBtnClass) as HTMLCollectionOf<HTMLElement>;
                if (saveButtonCss.length != 0) {
                  saveButtonCss[0].style.backgroundColor = this.data.data.color.split(',')[1];
                }
                let cencelButtonCss = document.getElementsByClassName(this.constants.CssCancelBtnClass) as HTMLCollectionOf<HTMLElement>;
                if (cencelButtonCss.length != 0) {
                  cencelButtonCss[0].style.backgroundColor = this.data.data.color.split(',')[2];
                }
              }
            }
            this.getTemplateId(this.companyId, this.userId);
          });
      }
    }

  }

  getTemplateId(companyId, userId) {
    companyId = Number(this.companyId);
    userId = this.userId;
    this.sharedService.GetTemplateDetail(companyId, userId).subscribe(
      data => {
        this.data = data;
        if (this.data.data.templateId != null) {
          this.IsNotTemplateId = false;
          this.IsTemplateId = true;
          this.getCustomDashboard(this.data.data.templateId);
        }
        else {
          this.IsTemplateId = false;
          this.IsNotTemplateId = true;
          this.getAllDashBoardDetails();
        }
      });
  }

  getAllDashBoardDetails() {
    this.sharedService.GetDashBoardDetails(this.companyId).subscribe(
      data => {
        this.data = data;
        if (this.data.dataList != null || this.data.dataList != undefined) {
          this.getAllDashBoardDetail = this.data.dataList;
        }
      });
  }

  getCustomDashboard(TempId) {
    this.loader = true;
    
    this.sharedService.getCustomDashboard(TempId, this.companyId, this.constants.IssuedPO).subscribe(
      data => {
        this.loader = false;
        this.data = data;
        if (this.data.dataList != null || this.data.dataList != undefined) {
          if (this.data.dataList.length > 0) {
            for (var item = 0; item < this.data.dataList.length; item++) {

              let obj = {
                id: this.data.dataList[item].id,
                widgetname: this.data.dataList[item].widgetname,
                query_type: this.data.dataList[item].query_type,
                wcount: this.data.dataList[item].widgetCount,
                url: this.data.dataList[item].url,
                newsContent: this._sanitizer.bypassSecurityTrustHtml(this.data.dataList[item].newsContent)
              }

              switch (Number(this.data.dataList[item].widgetType)) {
                case 1:
                  if (this.widgetData1.length <= 3) {
                    this.widgetData1.push(obj);
                  }
                  break;
                case 2:
                  if (this.widgetData2.length <= 3) {
                    this.widgetData2.push(obj);
                  }
                  break;
                case 3:
                  if (this.widgetData3.length < 1) {
                    this.widgetData3.push(obj);
                  }
                  break;
                case 4:
                  if (this.widgetData4.length < 1) {
                    this.widgetData4.push(obj);
                  }
                  break;
                case 5:
                  if (this.widgetData5.length < 1) {
                    this.widgetData5.push(obj);
                  }
                  break;
              }

            }
          }
        }
      });
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next('');
    });
  }

  redirect(url) {
    if (!url || url == '#') {
      return;
    }

    sessionStorage.setItem("CompanyId", this.companyId);
    this.router.navigate(['/' + url + '/']);
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next('');
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

}
