import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-category-layout',
  templateUrl: './category-layout.component.html',
  styleUrls: ['./category-layout.component.css']
})
export class CategoryLayoutComponent implements OnInit {

  loader: boolean = false;
  programs: Program[] = [];
  selectedMulti = [];
  //CategoryIds = [];
  @Input() CompanyId: Number
  @Output() CategoryIds = new EventEmitter<[]>();

  constructor(public sharedService: SharedService,) {

  }
  ngOnInit(): void {
    this.getAllCategories_search()
  }
  getAllCategories_search() {
    this.loader = true;
    this.sharedService.GetDropdownCategoryHierarchyModel(Number(this.CompanyId)).subscribe(
      (data: any) => {
        this.loader = false;
        this.programs = data.dataList;
      });
  }
  onParentCatChange(value: any): void {
    this.CategoryIds.emit(value.value);
  }
}


interface Program {
  value: number;
  text: string;
  isActive?: boolean;
  isEcommerece?: boolean;
  wordpressId?: number;
  children?: Program[];
}