import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
// import { TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { Subject } from 'rxjs';
import { ConstantsService } from 'src/app/constants.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { GlobalChangeService } from 'src/app/services/global-change.service';
import { SharedService } from 'src/app/services/shared.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-vehiclelist',
  templateUrl: './vehiclelist.component.html',
  styleUrls: ['./vehiclelist.component.css']
})
export class VehiclelistComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  loader: boolean = false;
  formSearch: FormGroup;
  userId: any;
  userRoleList: any;
  buttonValue: any;
  rolePermissionsList: any;
  permissionMessage: any;
  companyLoginId: any;
  companyList: any;
  data: any;
  value = 0;
  TCreateUpdate: any = 1;
  userGroupObjects = new Array();
  userGroupObjects_search = new Array();
  // items_search: TreeviewItem[];
  companyList_search: any;
  value_search: number;
  userSearch: any;
  SearchControl: boolean = false;
  ListShow: boolean = false;
  ListDiv: boolean = false;
  clearbutton: boolean = true;
  validation: boolean;
  id: any;
  form: FormGroup;
  // config = TreeviewConfig.create({
  //   hasAllCheckBox: true,
  //   hasFilter: true,
  //   hasCollapseExpand: true,
  //   decoupleChildFromParent: false,
  //   maxHeight: 400
  // });
  VehicleManufacture: any;
  description: any;
  SearchValidation: any;
  SearchType: any;
  ListAngle: any;
  vehicleDataList = [];
  createEditDate: any;
  deletedItem: string;
  deleteDetail: string;
  recordAlreadyExist: boolean;
  ModelList = [];
  now: Date;
  removeModelList = [];
  items_search: any;
  constructor(
    private route: ActivatedRoute,
    public sharedService: SharedService,
    private constants: ConstantsService,
    private router: Router,
    private modalService: NgbModal,
    private fb: FormBuilder,
    private globalChangeService: GlobalChangeService,
    private companyService: CompanyService
  ) { 
     
  }

  ngOnInit(): void {
    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);
    } else {
      this.userId = localStorage.getItem(this.constants.userId);
      this.buttonValue = this.constants.Save;
    }
    this.dtOptions = {
      pagingType: this.constants.full_numbers,
      pageLength: 10,
      ordering:true
    };
    this.form = this.fb.group({
      'VehicleManufacture': new FormControl(),
      models: this.fb.array([], Validators.required) 
    });
    this.formSearch = new FormGroup({
      'TCreateUpdate': new FormControl(),
      'userSearch': new FormControl(),
    });
    this.rolePermissionsList = JSON.parse(localStorage.getItem('rolePermissionsList'));
    this.rolePermissionsList = this.rolePermissionsList.filter(x => x.pageId == 13);
    this.permissionMessage = '';
    if (this.rolePermissionsList != null && this.rolePermissionsList != undefined) {
      if (!this.rolePermissionsList[0].isView) {
        this.permissionMessage += 'view';
      } if (!this.rolePermissionsList[0].isInsert) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'add';
      } if (!this.rolePermissionsList[0].isUpdate) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'edit';
      } if (!this.rolePermissionsList[0].isDelete) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'delete';
      }
    }
    if (this.permissionMessage != '') {
      this.permissionMessage = 'You do not have access to ' + this.permissionMessage + '. Please contact to administrator!';
    }
    this.companyLoginId = localStorage.getItem(this.constants.companyId);
    // this.getAllCompany(this.companyLoginId);
    // this.getAllCompany_search(this.companyLoginId);

    //ankit implementaion
      // get company by default set value
      var company = this.globalChangeService.getGlobalCompany();
      if(company>0){
        this.value = company;
        this.value_search = company;

      }
      else{
        this.ErrorModal('Error', 'Please select company.');
      }

      //on change function 
      this.globalChangeService.dropdownChange$.subscribe((data)=>{
        this.onValueChange(data);
        this.onValueChange_search(data);

      })
  }

  ngAfterViewInit() {
    this.dtTrigger.next('');
  }
  getAllCompany(companyId) {
    this.companyService.GetDropdownCompanyHierarchyModel(companyId).subscribe(
      data => {
        this.data = data;
        if (this.data.dataList != null && this.data.dataList != undefined) {
          this.companyList = this.data.dataList;
          // this.companyList.forEach((x) => {
          //   this.userGroupObjects.push(new TreeviewItem(x, false));

          // });
        }
        this.value = Number(companyId);
      });
  }
  getAllCompany_search(companyId) {
    this.companyService.GetDropdownCompanyHierarchyModel(this.companyLoginId).subscribe(
      data => {
        this.data = data;
        this.items_search = this.data.dataList;
        this.companyList_search = this.data.dataList;
        // this.companyList_search.forEach((x) => {
        //   this.userGroupObjects_search.push(new TreeviewItem(x, false));
        // });
        this.value_search = Number(companyId);
      });
  }
  ChangeTCreateUpdate(event) {
    this.TCreateUpdate = event.target.value;
    if (this.TCreateUpdate == "1") {
      this.SearchControl = false;
      this.ListShow = false;
      this.ListDiv = false;
      this.clearbutton = true;
      this.buttonValue = this.constants.Save;
      // this.resetCategory();
    }
    else if (this.TCreateUpdate == "0") {
      this.SearchControl = true;
      this.userSearch = "";
    }
  }
  onValueChange_search(value: any): void {
    this.value_search = value;
  }
  onValueChange(value: any): void {
    this.value = value == undefined ? 0 : value;
  }
  add_msg(){
    $('#add_msg').hide();
  }
  addNewVehicle() {
    this.validation = true;
    this.clearbutton = true;
    if (this.form.controls.VehicleManufacture.status === this.constants.Invalid || this.value == null
      ) 
    {
      return;
    }
    this.loader = true;
    const modelCount = this.form.value['models'].length;
    const modelCounts = this.form.value['models'];
    if(modelCount == 0){
      $('#add_msg').show();
      this.loader = false;
      return;
    }
    else{
      modelCounts.forEach(element => {
        if(element.name == ""){
          this.loader = false;
          return;
        }
      });
    }
    const obj = {};
    obj['VehicleManufacturer'] = this.VehicleManufacture;
    obj['Description'] = this.description;
    obj['CompanyId'] = this.value;
    obj['CreatedBy'] = this.userId; 
    const newModelList = [];
    for (var j = 0; j < modelCounts.length; j++) {
      let modelObj = {};
      modelObj["ModelName"] = modelCounts[j].name;
      modelObj["ModelDescription"] = modelCounts[j].description;
      modelObj['CreatedBy'] = this.userId;
      modelObj['IsActive'] = true;
      modelObj["CompanyId"] = this.value;
      modelObj["VehicleId"] = this.id;
      modelObj["Id"] = modelCounts[j].id;
      if(modelCounts[j].id != 0){
        modelObj["ModifiedDate"] = this.now = new Date();
        modelObj["CreatedDate"] = modelCounts[j].CreatedDate;
        modelObj['ModifiedBy'] = this.userId;
      }
      else{
        modelObj["CreatedDate"] = this.now = new Date();
        modelObj['CreatedBy'] = this.userId;
      }
      modelObj["isActive"] = modelCounts[j].isActive;
      newModelList.push(modelObj);
    }
    this.removeModelList.forEach(element => {
      let modelObj = {};
      modelObj["ModelName"] = element.name;
      modelObj["ModelDescription"] = element.description;
      modelObj['CreatedBy'] = this.userId;
      modelObj['IsActive'] = element.isActive;
      if(element.id != 0){
        modelObj["ModifiedDate"] = this.now = new Date();
        modelObj["CreatedDate"] = element.CreatedDate;
        modelObj['ModifiedBy'] = this.userId;
      }
      else{
        modelObj["CreatedDate"] = this.now = new Date();
        modelObj['CreatedBy'] = this.userId;
      }
      modelObj["CreatedDate"] = this.now = new Date();
      modelObj["CompanyId"] = this.value;
      modelObj["VehicleId"] = this.id;
      modelObj["Id"] = element.id;
      modelObj["isActive"] = element.isActive;
      newModelList.push(modelObj);
    });
    obj['VehicleList'] = newModelList;
    if (this.id != undefined && this.id != null && this.id != '') {
      obj['id'] = this.id;
      obj['ModifiedBy'] = this.userId;
      obj['CreatedDate'] = this.createEditDate;
      this.sharedService.updateVehicle(obj)
        .subscribe(
          data => {
            switch (data.statusCode) {
              case 200:
                this.loader = false;
                this.buttonValue = this.constants.Save;
                $("#add_update").show();
                this.resetVehicleList();
                break;
              case 400:
                this.loader = false;
                if (data.errorMessage === 'Record Already Exist') {
                  this.recordAlreadyExist = true;
                } else {
                  this.recordAlreadyExist = false;
                }
                break;
              default:
                this.loader = false;
                $("#wrong_msg").show()
            }
          }
        );
    } else {
      this.sharedService.addVehicle(obj)
        .subscribe(
          data => {
            switch (data.statusCode) {
              case 200:
                this.loader = false;
                $("#add_success").show();
                this.validation = false;
                this.resetVehicleList();
                break;
              case 400:
                this.loader = false;
                if (data.errorMessage === 'Record Already Exist') {
                  this.recordAlreadyExist = true;
                } else {
                  this.recordAlreadyExist = false;
                }
                break;
              default:
                $("#wrong_msg").show()
            }
          }
        );
    }
  }

  resetVehicleList(){
    this.validation = false;
   this.VehicleManufacture = "";
   this.description = "";
   if (this.companyLoginId > 0) {
    this.value = Number(this.companyLoginId)
  }
  else {
    this.value = 0;
  }
  this.recordAlreadyExist = false;
  const modelCounts = this.form.value['models'];
  for(let i=0; i < modelCounts.length; i++){
    this.models().clear(); 
  }
  this.removeModelList = [];
  }
  AllVehicle(){
    this.recordAlreadyExist = false;
    this.SearchType = true;
    if (this.value_search == 0) {
      this.SearchValidation = true;
      return;
    }
    this.getAllVehicle(this.value_search, this.userSearch);
    this.ListShow = true;
    this.ListDiv = true;
    this.ListAngle = this.constants.UpArrow;
  }
  getAllVehicle(companyId, SearchItem) {
    this.loader = true;
    if(companyId == undefined){companyId =0;}
    this.sharedService.getAllVehicleWithProduction(companyId, SearchItem).subscribe(
      data => {
        this.loader = false;
        this.data = data;
        this.vehicleDataList = this.data.dataList;
        this.rerender();
      });
  }

  searchVehicle(){

  }
  addsuccess(){
    $('#add_success').hide();
  }
  updateitem(){
    $('#add_update').hide();
  }
  toggleEdit(item: any) {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.ListAngle = this.constants.DownArrow;
    this.ListDiv = false;
    this.buttonValue = this.constants.Update;
    this.clearbutton = false;
    this.value = item.companyId;
    this.id = item.id;
    this.VehicleManufacture = item.vehicleManufacturer;
    this.description = item.description;
    this.createEditDate = item.createdDate;
    this.removeModelList = [];
    const modelCounts = this.models().value;
    for(let i=0; i < modelCounts.length; i++){
      this.models().clear(); 
    }
    this.sharedService.getVehicleList(item.id).subscribe(
      data => {
        this.data = data;
        var obj ={
          name : '',
          description : '', id : 0, isActive : false, CreatedDate : ''
        }
        this.data.dataList.forEach(element => {
          const modelValuesList = this.fb.group({  
            name: element.modelName,  
            description:  element.modelDescription,  
            id : element.id, isActive : element.isActive, CreatedDate : element.createdDate
          })  
          this.models().push(modelValuesList);  
        });
      });
  }
  VehicleBack() {
    this.clearbutton = true;
    this.id = null;
    this.buttonValue = this.constants.Save;
    this.resetVehicleList();
  }
  deletePopup(item, content) {
    this.deletedItem = '';
    this.modalService.open(content, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
    this.deleteDetail = this.constants.DeletePopup;
    this.deletedItem = item.id;
  }

  deleteVehicleList(item: any) {
    this.loader = true;
    this.sharedService.deleteVehicle(item).subscribe(
      data => {
        this.data = data;
        switch (data.statusCode) {
          case 200:
            $("#delete_success").show();
            this.getAllVehicle(this.value_search, this.userSearch == "" ? "" : this.userSearch);
            this.loader = false;
            break;

          case 400:
            this.loader = false;
            $("#wrong_msg").show();
            break;
          case 401:
            this.loader = false;
            $("#wrong_msg").show()
            break;
          case 404:
            this.loader = false;
            $("#wrong_msg").show()
            break;
          case 500:
            this.loader = false;
            $("#wrong_msg").show();
            // this.toastr.error(this.constants.SomethingWentWrong);
            break;
          default:
            this.loader = false;
            $("#wrong_msg").show();
          // this.toastr.error(this.constants.SomethingWentWrong);

        }
      });
  }
  msgitem(){
    $("#wrong_msg").hide()
  }
  deleteitem(){
    $("#delete_success").hide();
  }
  
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next('');
    });
  }
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
  ListDivShowHide() {
    if (this.ListDiv == true) {
      this.ListDiv = false;
      this.ListAngle = this.constants.DownArrow;
    }
    else if (this.ListDiv == false) {
      this.ListDiv = true;
      this.ListAngle = this.constants.UpArrow;
    }
  }

  models() : FormArray {  
    return this.form.get("models") as FormArray  
  }  
     
  newModel(): FormGroup {  
    return this.fb.group({  
      name: '',  
      description: '',  id :0
    })  
  }  
     
  addModel() {  
    this.models().push(this.newModel());  
  }  
     
  removeQuantity(i:number) {  
    const modelCounts = this.models().value;
    this.models().removeAt(i); 
    const modelCounts1 = this.models().value;
    const filteredArray  = modelCounts.filter(value => !modelCounts1.includes(value));
    var obj ={
      name : '',
      description : '', id : 0, isActive : false, CreatedDate : ''
    }
    this.removeModelList = [];
    const list = [];
    filteredArray.forEach(element => {
      const modelValuesList = this.fb.group({  
        name: element.name,  
        description:  element.description,  
        id : element.id, isActive : false, CreatedDate : element.CreatedDate
      })  
      list.push(modelValuesList);  
    });
    this.removeModelList.push(list[0].value);

  }  

  
  //ankit implemenation
  ErrorModal(title:string, message:string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }
}
