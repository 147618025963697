import { Injectable } from '@angular/core';
import { Observable, throwError, of } from 'rxjs';
import { tap, delay, catchError } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  isLoggedIn = false;
  _url: string;
  baseUrl = environment.baseUrl;
  constructor(
    private _http: HttpClient

  ) {

  }
  
  redirectUrl: string;

  login(): Observable<boolean> {
    return of(true).pipe(
      delay(1000),
      tap(val => this.isLoggedIn = true)
    );
  }

  logout(): void {
    this.isLoggedIn = false;
  }
  authentication(APIKey: any, item: any,): Observable<any> {
    return this._http.post(this.baseUrl + 'Auth/token?key=' + APIKey, item
    )
      .pipe(catchError(this.errorHandler));
  }
  ValidPasswordToken(APIKey: any, item: any,): Observable<any> {
    return this._http.post(this.baseUrl + 'Auth/token?key=' + APIKey, item
    )
      .pipe(catchError(this.errorHandler));
  }
  Updatepassword(APIKey: any, item: any, token: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/ResetPassword/UpdatePassword?emailAddress=' + APIKey + '&password=' + item + '&token=' + token, {}
     
    )
      .pipe(catchError(this.errorHandler));
  }
  getAllProduct() {
    return this._http.get(this.baseUrl + 'api/Product/GetAllProduct',
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + sessionStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || "server error.");
  }

}

