<section class="content-section partPage pt-2">
    <div class="loading" *ngIf="loader">Loading&#8230;</div>
    <div class="page-title mb-2">
        <!-- <h2>Parts</h2> -->
        <div class="bread-crumb">
            <a [routerLink]="['/adminDashboard/']">Home</a>
            <span class="mx-1">-</span>
            <span class="active">Custom Parts</span>
            <div *ngIf="permissionMessage!=''" class='btn-danger text-center'>{{permissionMessage}} </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="white-box1">
                <div class="card-desc1">
                    <form [formGroup]="formSearch">
                        <div class="row detailSection">
                            <div class="col-md-12 container">
                                <div class="row mt-3">
                                    <div class="col-lg-2 col-md-6">
                                        <div class="d-flex align-items-center">
                                            <label class="customLabel mr-1" for="Type">Type</label>
                                            <select class="customInput" (change)="ChangeTCreateUpdate($event)"
                                                [(ngModel)]="TCreateUpdate" id="TCreateUpdate"
                                                formControlName="TCreateUpdate">
                                                <option value="1">Create</option>
                                                <option value="0">Update</option>
                                            </select>
                                        </div>
                                    </div>

                                    <!-- <div class="col-lg-3 col-md-6 mt-md-0 mt-1"
                                        [style.display]="SearchControl==true ? 'block' : 'none'">
                                        <div class="d-flex align-items-center required borderNone"
                                            [ngClass]="((value_search==0) && (SearchValidation)) ? 'validation_border': ''">
                                            <label class="customLabel mr-1" for="Type">Company</label>
                                            <ngx-dropdown-treeview-select [config]="config"
                                                class="dropdown-company customInput-dropdown custom_btn"
                                                [items]="userGroupObjects_search" [(value)]="value_search"
                                                (valueChange)="onValueChange_search($event)">
                                            </ngx-dropdown-treeview-select>
                                        </div>
                                    </div> -->
                                    <div class="col-lg-2 col-md-12 mt-lg-0 mt-1"
                                        [style.display]="SearchControl==true ? 'block' : 'none'">

                                        <div class="d-flex align-items-center">
                                            <select class="customInput pl-1px"
                                                (change)="ChangefieldToSearch($event.target.value)"
                                                [(ngModel)]="fieldToSearch" id="fieldToSearch"
                                                formControlName="fieldToSearch">
                                                <option value='1'>Search Keywords</option>
                                                <option value='2'>List or Vendor Part Name</option>
                                                <option value='3'>List or Vendor Part #</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div class="col-lg-3 col-md-12 mt-lg-0 mt-1"
                                        [style.display]="SearchControl==true ? 'block' : 'none'">
                                        <div class="d-flex align-items-center">
                                            <input type="text" placeholder="{{fieldToSearchPlaceHolder}}"
                                                class="customInput" [(ngModel)]="userSearch"
                                                formControlName="userSearch" autocomplete="off" required
                                                [ngClass]="((!userSearch && SearchType==false) && (SearchValidation)) ? 'validation_border': ''"
                                                (keyup.enter)="searchUser()">
                                            <i class="fas fa-search cpointer ml-2 mr-3 cpointer"
                                                (click)="searchUser()"></i>
                                        </div>
                                    </div>
                                    <div class="col-lg-1 col-md-12 mt-lg-0 mt-1"
                                        [style.display]="SearchControl==true ? 'block' : 'none'">
                                        <button type="button" class="btn blue-bg all-custom round-btn m-0"
                                            style="margin-left:-10px !important;" (click)="AllUser()">All
                                            Custom Parts</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="white-box1 partList" *ngIf="rolePermissionsList && rolePermissionsList[0].isView"
                [style.display]="ListShow==true ? 'block' : 'none'">
                <div class="white-box-title1">
                    <div class="customHeading mt-3">
                        Custom Part List<i class="fa fa-angle-{{ListAngle}} cpointer ml-2" aria-hidden="true"
                            (click)="ListDivShowHide()"></i>
                    </div>
                </div>
                <!------------------------------------- company list table ------------------------------>
                <div class="card-desc1 mt-1 divScroll" [style.display]="ListDiv==true ? 'block' : 'none'">
                    <table id="datatable" class="display table clearfix" class="row-border hover" datatable
                        [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                        <thead>
                            <tr>
                                <th hidden></th>
                                <!-- <th style="display: contents !important;"></th> -->
                                <th (click)="SortTableHeader('companyName')">Company<span id="companyName" class="sorting1"></span></th>
                                <th (click)="SortTableHeader('name')">Company Part Name<span id="name" class="sorting1"></span></th>
                                <th (click)="SortTableHeader('partNo')">Company Part#<span id="partNo" class="sorting1"></span></th>
                                <th (click)="SortTableHeader('vendorName')">Vendor Name<span id="vendorName"  class="sorting1"></span></th>
                                <th (click)="SortTableHeader('vendorPartName')">Vendor Part Name<span id="vendorPartName" class="sorting1"></span></th>
                                <th (click)="SortTableHeader('vendorPartNumber')">Vendor Part#<span id="vendorPartNumber" class="sorting1"></span></th>                     
                                <th (click)="SortTableHeader('cost')">Part Price<span id="cost" class="sorting1"></span></th>
                                <th (click)="SortTableHeader('hours')">Labor Hrs<span id="hours"class="sorting1"></span></th>
                                <th>Is Active</th>
                                <th>Non-Inventory</th>
                                <th data-orderable="false">Action</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="datalist?.length != 0">
                            <tr *ngFor="let data of datalist">
                                <td class="coloumn-hide">{{data.id}}</td>
                                <td>{{data.companyName}}</td>
                                <td  title={{data.name}}>{{data.name.length <= 25 ? data.name : SortString(data.name)}} </td>
                                <td>
                                    {{data.partNo}}
                                </td>
                           
                                <td title={{data.vendorName}}>
                                    {{data.vendorName == null ? '' : (data.vendorName.length <= 25 ? data.vendorName : SortString(data.vendorName))}}
                                    <span class="tooltipWrap tooltip-icon" style="display:inline;">
                                        <img src="/assets/image/AdVendor.png" class="picimage red-tooltip"
                                            *ngIf="data.vendorCount > 0" data-bs-toggle="tooltip"
                                            title="This part has Additional Vendors associated with it">
                                    </span>
                                </td>
                                <td title={{data.vendorPartName}}>
                                    {{data.vendorPartName == null ? '' : (data.vendorPartName.length <= 25 ? data.vendorPartName : SortString(data.vendorPartName))}}
                                </td>
                                <td title={{data.vendorPartNumber}}>
                                    {{data.vendorPartNumber == null ? '' :(data.vendorPartNumber.length <= 25 ? data.vendorPartNumber : SortString(data.vendorPartNumber))}}
                                </td>
                                <!-- <td>{{data.partCategoryName}}</td> -->
                                <td>{{data.cost|currency}}</td>
                                <td>{{data.hours|number: '1.2-2'}}</td>
                                <td>{{data.isNotActive == 1 ? 'Not Active' : data.isNotActive == 0 ? 'Active' : ''}}
                                </td>
                                <td>

                                    <span *ngIf="data.nonInventory==true"><i class="fa fa-check text-success"
                                            aria-hidden="true" style="margin-left: 30px;"></i></span>
                                    <!-- {{data.nonInventory == 1 ? 'YES' : 'NO' }} -->
                                </td>
                                <td>
                                    <a class="green-text"
                                        *ngIf="rolePermissionsList && rolePermissionsList[0].isUpdate"><i
                                            class="fas fa-edit" (click)="togglePartEdit(data)"></i></a>
                                    <a class="red-text"
                                        *ngIf="rolePermissionsList && rolePermissionsList[0].isDelete"><i
                                            class="fas fa-trash-alt" (click)="deletePopup(data,content)"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="white-box1">
                <form [formGroup]="form">
                    <div class="customHeading mt-3" style="display: flex">
                        Custom Parts Details
                        <!-- <div class="col-lg-4 col-md-12">
                            <div class="row">
                                <div class="col-lg-4 col-md-3 trp required">
                                    <label class="customLabel">Company</label>
                                </div>
                                <div class="col-lg-8 col-md-9 borderNone"
                                    [ngClass]="((CompanyValue==0) && (validation)) ? 'validation_border': ''">
                                    <ngx-dropdown-treeview-select [config]="config"
                                        class="dropdown-company customInput-dropdown" [items]="userGroupObjects"
                                        [(value)]="CompanyValue" (valueChange)="companyChangeValue($event)">
                                    </ngx-dropdown-treeview-select>
                                </div>
                            </div>
                        </div> -->
                        <span class="mds">
                            <input type="checkbox" id="vehicle1" name="vehicle1" [checked]="NonInventory"
                                (change)="changeNonInventory($event)" formControlName="NonInventory"
                                [(ngModel)]="NonInventory" style="top:2px">
                            <label for="vehicle1" class="customLabel flabel">Non-Inventory</label>

                        </span>
                        <span class="mds">
                            <input type="checkbox" (change)="changeActive($event)" formControlName="NotActive"
                                style="top:2px" [(ngModel)]="NotActive" id="notActive">
                            <label for="notActive" class="customLabel flabel">Not Active</label>

                        </span>
                    </div>

                    <div class="card-desc1">


                        <div class="row mt-2">

                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-3 trp required">
                                        <div>
                                            <label class="customLabel">Primary Vendor </label>
                                            <div class="popover__wrapper1" *ngIf="AdditionalDiscountLabel else others">
                                                <div class="popover__title1">
                                                    <img src="/assets/image/discount.png" class="image-size">
                                                </div>
                                                <div class="popover__content1">

                                                    <p class="popover__message1">
                                                        {{AVendor}} has an additional discount of {{ADiscount}}%. This
                                                        will be reflected on Unit Cost when creating POs
                                                    </p>

                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <ng-template #others>
                                        <div class="col-lg-1 col-md-1"></div>

                                    </ng-template>



                                    <div class="col-lg-8 col-md-9" style="display: flex; align-items: center;">

                                        <select class="customInput pl-1px" name='vendorId' id="VendorC"
                                            [(ngModel)]="selectedVendorValue" formControlName='vendorId'
                                            (change)="onValueChange($event)"
                                            [ngClass]="((!form.controls.vendorId.valid) && (validation)) ? 'validation_border': ''">
                                            <option value=''>Select Vendor</option>
                                            <option *ngFor="let item of vendorlist"
                                                [value]="item.id + '-' +item.discount"
                                                [ngStyle]="{'color': item.color}">
                                                {{item.vendorName + ""}}
                                            </option>

                                        </select>

                                        <div class="popover__wrapper">

                                            <button type="button"
                                                class="btn round-btn upload_btn add_img mt-lg-0 popover__title"
                                                (click)="openVendorModal();">+ Add Vendors

                                            </button>

                                            <div class="popover__content">
                                                <div *ngIf="AddvendorList.length != 0">
                                                    <div *ngIf="ActiveCouting == true">
                                                        <ng-container *ngFor="let data of AddvendorList">
                                                            <p class="popover__message" *ngIf="data.IsActive == true">

                                                                {{data.vendorName}}</p>

                                                        </ng-container>


                                                    </div>
                                                </div>

                                                <div *ngIf="ActiveCouting == false">
                                                    <p class="popover__message">No Additional Vendors found</p>
                                                </div>

                                            </div>


                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-8 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-2 col-md-3 trp required">
                                        <label class="customLabel">Part Category</label>
                                    </div>
                                    <div class="col-lg-6 col-md-9">
                                        <!-- <ng-multiselect-dropdown-angular7 [data]="catdropdownList"
                                            placeholder="Select Part Category" [(ngModel)]="selectedItems"
                                            formControlName="selectedItems" [settings]="dropdownSettings"
                                            (onSelect)="onItemSelect($event)" (onSelectAll)="onSelectAll($event)"
                                            (onDeSelectAll)="onDeSelectAll($event)"
                                            [ngClass]="((!form.controls.selectedItems.valid) && (validation)) ? 'validation_border': ''"
                                            (onDeSelect)="onItemDeSelect($event)">
                                        </ng-multiselect-dropdown-angular7> -->

                                        <ng-multiselect-dropdown
                                        placeholder="Select Part Category" 
                                        [data]="catdropdownList" 
                                        formControlName="selectedItems" 
                                        [(ngModel)]="selectedItems"
                                        [settings]="dropdownSettings"
                                        [ngClass]="((!form.controls.selectedItems.valid) && (validation)) ? 'validation_border': ''"
                                        (onSelect)="onItemSelect($event)" 
                                        (onDeSelect)="onItemDeSelect($event)"
                                        (onSelectAll)="onSelectAll($event)"
                                        (onDeSelectAll)="onDeSelectAll($event)">
                                          </ng-multiselect-dropdown>
    
                                        <!-- <select class="customInput" name='partCategoryId'
                                            formControlName="partCategoryId" [(ngModel)]="selectedPartCategoryIdValue"
                                            formControlName='partCategoryId' (change)="onValueChange1($event)"
                                            [ngClass]="((!form.controls.partCategoryId.valid) && (validation)) ? 'validation_border': ''">
                                            <option value=''>Select Part Category</option>
                                            <option *ngFor="let item of categorylist"
                                                [value]="item.wordpressId + '-' + item.id">
                                                {{item.name}}
                                            </option>
                                        </select> -->

                                        <!-- <div *ngIf="((!form.controls.partCategoryId.valid) && (validation))"
                                            class="text-danger input-error">
                                            Part Category.</div> -->
                                            <!-- <mat-form-field>
                                                <mat-select placeholder="Select Category" formControlName="partCategoryId" [(value)]="selectedPartCategoryIdValue" multiple>
                                                  <mat-option *ngFor="let item of categorylist" [value]="item.wordpressId + '-' + item.id">{{item.name}}</mat-option>
                                                </mat-select>
                                              </mat-form-field> -->
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="row mt-md-1">
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-3 trp">
                                        <label class="customLabel">Vendor Part Name</label>
                                    </div>
                                    <div class="col-lg-8 col-md-9">
                                        <input type="text" class="customInput" [(ngModel)]="VendorPartName"
                                            placeholder="Enter Vendor Part Name" formControlName='VendorPartName'
                                            autocomplete="off" (input)="onEnterVendorPartName($event)">
                                        <!-- <div *ngIf="((!form.controls.name.valid) && (validation))"
                                            class="text-danger input-error">
                                            Enter Part Name.</div> -->
                                        <!-- <div *ngIf="((form.controls.name.valid) && (recordAlreadyExist))"
                                            class="text-danger input-error">
                                            Record Already Exist.</div> -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-8 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-2 col-md-3 trp">
                                        <label class="customLabel">Vendor Part Number</label>
                                    </div>
                                    <div class="col-lg-10 col-md-9">
                                        <input type="text" class="customInput" [(ngModel)]="VendorPartNumber"
                                            placeholder="Enter Vendor Part Number" formControlName='VendorPartNumber'
                                            autocomplete="off" required maxlength="100"
                                            (input)="onEnterVendorPartNumber($event)">
                                        <!-- <div *ngIf="((!form.controls.partNo.valid) && (validation))"
                                        class="text-danger input-error">
                                        Enter  Customer Part Number.</div> -->
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="row mt-md-1">
                            <div class="col-lg-4 col-md-12">
                                <div class="row">
                                    <div class="col-lg-4 col-md-3 trp required">
                                        <label class="customLabel">List Part Name</label>
                                    </div>
                                    <div class="col-lg-8 col-md-9">
                                        <input type="text" class="customInput" [(ngModel)]="name"
                                            placeholder="Enter List Part Name" maxlength="50" formControlName='name'
                                            autocomplete="off" required
                                            [ngClass]="((!form.controls.name.valid) && (validation)) ? 'validation_border': ''">
                                        <!-- <div *ngIf="((!form.controls.name.valid) && (validation))"
                                            class="text-danger input-error">
                                            Enter Part Name.</div> -->
                                        <div *ngIf="((form.controls.name.valid) && (recordAlreadyExist))"
                                            class="text-danger input-error">
                                            List Part Name Already Exist.</div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-8 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-2 col-md-3 trp required">
                                        <label class="customLabel">List Part Number</label>
                                    </div>
                                    <div class="col-lg-10 col-md-9">
                                        <input type="text" class="customInput" [(ngModel)]="partNo"
                                            placeholder="Enter List Part Number" formControlName='partNo'
                                            autocomplete="off" required maxlength="100"
                                            [ngClass]="((!form.controls.partNo.valid) && (validation)) ? 'validation_border': ''">
                                        <!-- <div *ngIf="((!form.controls.partNo.valid) && (validation))"
                                            class="text-danger input-error">
                                            Enter Part Number.</div> -->

                                        <div *ngIf="((form.controls.partNo.valid) && (recordPartNoExist))"
                                            class="text-danger input-error">
                                            Company Part Number Already Exist.</div>
                                    </div>
                                </div>
                            </div>


                        </div>

                        <div class="row mt-md-1">
                            <div class="col-lg-2 col-md-4 trp">
                                <label class="customLabel padding-r">Description</label>
                            </div>
                            <div class="col-lg-10 col-md-8">
                                <input type="text" class="customInput customDescription-parts descp"
                                    placeholder="Enter Description" [(ngModel)]="description" name="description"
                                    maxlength="250" formControlName='description' autocomplete="off">
                            </div>
                        </div>
                        <div class="row mt-md-1">
                            <div class="col-lg-2 col-md-4 trp">
                                <label class="customLabel padding-r">Installation Notes</label>
                            </div>
                            <div class="col-lg-10 col-md-8">
                                <input type="text" class="customInput customDescription-parts"
                                    [(ngModel)]="installationNotes" placeholder="Enter Installation Notes"
                                    formControlName="installationNotes" autocomplete="off" maxlength="250">
                            </div>
                        </div>
                        <div class="row mt-md-1">
                            <div class="col-lg-2 col-md-4 trp">
                                <label class="customLabel padding-r">Search Keywords</label>
                            </div>
                            <div class="col-lg-10 col-md-8">
                                <input type="text" class="customInput customDescription-parts" [(ngModel)]="search"
                                    placeholder="Enter Search Keywords" formControlName="search" autocomplete="off"
                                    maxlength="250">
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="customHeading mt-3">
                                    Add Parts

                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12 mt-2">
                                <button type="button" class="btn blue-bg upload_btn round-btn  m-0"
                                    (click)="OpenCustomPart()"><i class="fas fa-plus-circle" aria-hidden="true"></i>
                                    Add Part
                                </button>
                            </div>
                            <div class="col-md-12">
                                <table class="display table clearfix order-detail mb-0 mt-2">
                                    <thead>
                                        <tr>
                                            <th style="font-weight:bold">Vendor Part Name</th>
                                            <th style="font-weight:bold">Vendor Part#</th>
                                            <th style="font-weight:bold">Vendor Name</th>
                                            <th style="font-weight:bold">Qty</th>
                                            <th style="font-weight:bold">Labor Hours</th>
                                            <th style="font-weight:bold"> Total Labor Hours </th>
                                            <th style="font-weight:bold">Vendor Part Cost</th>
                                            <th style="font-weight:bold"> Total Vendor Part Cost </th>
                                            <th style="font-weight:bold">Part Price</th>
                                            <th style="font-weight:bold"> Total Part Price </th>


                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="hoverEffect customParts"
                                            *ngFor="let parts of partList;let i = index">
                                            <td>
                                                <div class="d-flex align-items-start position-relative">
                                                    <div class="d-flex flex-column">
                                                        <span class="PartName tooltipWrap"
                                                            *ngIf="normalShow==true || RowEdit==false">
                                                            {{parts.vendorPartName==null?'N/A':parts.vendorPartName==undefined?'N/A':parts.vendorPartName==''?'N/A':parts.vendorPartName}}

                                                        </span>
                                                        <span class="tooltips">
                                                            Company Part Name = {{parts.partName}} <br />
                                                            Company Part # = {{parts.partNumber}}
                                                        </span>
                                                        <span class="editFeilds"
                                                            *ngIf="parts.partId==EditRowPartId && EditRowTxtName=='editRow' && CurrentEditRow=='Edit' && RowIsMisc==true">
                                                            <input class="form-control" formControlName='UpdatePartName'
                                                                type="text" [(ngModel)]="UpdatePartName" />
                                                        </span>
                                                    </div>

                                                    <span class="showBtn">
                                                        <span *ngIf="btnShowvalidation && btnOkShow==false">
                                                            <a (click)="deleteCustomPartPopup(i,contentPart)"><i
                                                                    class="fas fa-trash-alt order-del"></i></a>
                                                            <a><span class="fas fa-edit partEdit"
                                                                    (click)="EditPartRow(parts.partId,'editRow')"></span></a>
                                                        </span>
                                                        <span *ngIf="parts.partId==EditRowPartId && btnOkShow==true">
                                                            <span><button type="button" data-toggle="modal"
                                                                    (click)="UpdatePartRow(parts.partId)"
                                                                    class="showBtn btn btn-sm green-bg sq-btn ml-1 p-addPart"
                                                                    style="width: 35px !important;">Ok
                                                                </button></span>
                                                            <span><button type="button" data-toggle="modal"
                                                                    (click)="CancleUpdatePartRow(i)"
                                                                    class="showBtn btn darkred-bg  sq-btn ml-1 p-addPart"
                                                                    style="width: 55px !important;">Cancel
                                                                </button></span>
                                                        </span>
                                                    </span>
                                                </div>
                                            </td>
                                            <td>
                                                <span
                                                    *ngIf="normalShow==true || RowEdit==false">{{parts.vendorPartNumber==null?'N/A':parts.vendorPartNumber==undefined?'N/A':parts.vendorPartNumber==''?'N/A':parts.vendorPartNumber}}</span>
                                                <span
                                                    *ngIf="parts.partId==EditRowPartId && EditRowTxtName=='editRow' && CurrentEditRow=='Edit' && RowIsMisc==true">
                                                    <input class="form-control odr-qty"
                                                        formControlName='UpdateVendorPartNumber' maxlength="20"
                                                        type="text" [(ngModel)]="UpdateVendorPartNumber" />
                                                </span>
                                            </td>
                                            <td>
                                                <span
                                                    *ngIf="normalShow==true || RowEdit==false">{{parts.vendorsName==null?'N/A':parts.vendorsName==undefined?'N/A':parts.vendorsName==''?'N/A':parts.vendorsName}}</span>
                                                <br />
                                                <span
                                                    *ngIf="parts.partId==EditRowPartId && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                    <select class="customInput" name='CustompartVendorId'
                                                        style="width: 120px;" formControlName='UpdateVendorId'
                                                        [(ngModel)]="UpdateVendorId" id="UpdateVendorId"
                                                        (change)="OnCustomEditVendorChangeEvent($event)">
                                                        <option value=''>Select Vendor</option>
                                                        <option *ngFor="let item of ddlVendorList1" [value]="item.id">
                                                            {{item.name}}
                                                        </option>
                                                    </select>

                                                </span>
                                            </td>
                                            <td>
                                                <span
                                                    *ngIf="normalShow==true || RowEdit==false">{{parts.quantity}}</span>
                                                <span
                                                    *ngIf="parts.partId==EditRowPartId && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                    <input class="form-control odr-qty NumberOnly"
                                                        formControlName='UpdateQty' maxlength="5" type="text"
                                                        [(ngModel)]="UpdateQty" />
                                                </span>
                                            </td>

                                            <td>
                                                <span
                                                    *ngIf="normalShow==true || CurrentEditRow=='Edit'">{{parts.hours|number:
                                                    '1.2-2'}}</span>
                                                <span
                                                    *ngIf="parts.partId==EditRowPartId && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                    <input class="form-control odr-qty Cost" type="text"
                                                        [(ngModel)]="UpdateLaborHrs" formControlName='UpdateLaborHrs'
                                                        maxlength="6" />
                                                </span>

                                            </td>
                                            <td>
                                                {{parts.hours*parts.quantity|number: '1.2-2'}}

                                            </td>
                                            <td>
                                                <span *ngIf="normalShow==true">{{parts.vendorParCost| currency}}</span>
                                                <span
                                                    *ngIf="parts.partId==EditRowPartId && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                    <input class="form-control inputShow Cost" type="text"
                                                        [(ngModel)]="UpdateVendorPartCost"
                                                        formControlName="UpdateVendorPartCost" maxlength="10"
                                                        (change)="formatCurrency($event.target.value)" />
                                                </span>
                                            </td>
                                            <td>
                                                {{parts.vendorParCost*parts.quantity | currency}}
                                            </td>
                                            <td>
                                                <span *ngIf="normalShow==true">{{parts.cost| currency}}</span>
                                                <span
                                                    *ngIf="parts.partId==EditRowPartId && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                    <input class="form-control inputShow Cost" type="text"
                                                        [(ngModel)]="UpdateEachPartCost"
                                                        formControlName='UpdateEachPartCost' maxlength="10" />
                                                </span>
                                            </td>
                                            <td>
                                                {{parts.cost*parts.quantity | currency}}
                                            </td>

                                        </tr>
                                        <tr *ngIf="partList.length==0">
                                            <td>Part Item is not Available</td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                           <td class="tr-bg-clr-none"></td> 
                                      
                                            <td></td> 
                                          
                                            <td style="font-weight:bold" class="double-border" colspan="3">
                                                <span style="text-align: right; display: flow-root;"> <b>Total</b></span>
                                            </td>

                                            <td style="font-weight:bold" class="double-border">
                                                <b>{{SubTotalLaborHours|number:
                                                    '1.2-2'}}</b></td>
                                            <td class="double-border"></td>
                                            <td style="font-weight:bold" class="double-border">
                                                <b>{{SubTotalVendorCost|currency}}</b>
                                            </td>
                                            <td style="font-weight:bold" class="double-border"></td>

                                            <td style="font-weight:bold" class="double-border">
                                                <b>{{SubTotalPartCost|currency}}</b>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </table>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="customHeading mt-2">
                                    Cost and Pricing
                                </div>
                            </div>

                        </div>


                        <div class="row mt-md-1">

                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-3 trp">
                                        <label class="customLabel">Vendor Part Cost</label>
                                    </div>
                                    <div class="col-lg-8 col-md-9">
                                        <input type="text" class="customInput" [(ngModel)]="VendorPartCost"
                                            placeholder="$ Enter Vendor Part Cost" formControlName='VendorPartCost'
                                            (blur)="formatVendorCostCurrency()" autocomplete="off" required
                                            maxlength="10">

                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-3 trp required">
                                        <label class="customLabel">List Part Price</label>
                                    </div>
                                    <div class="col-lg-8 col-md-9">
                                        <input type="text" class="customInput Cost" [(ngModel)]="cost"
                                            placeholder="$ Enter Part Price" formControlName='cost'
                                            (change)="formatCurrency()" autocomplete="off" required maxlength="10"
                                            [ngClass]="((!form.controls.cost.valid) && (validation)) ? 'validation_border': ''"
                                            [readonly]="IsPricePart" style="padding-left: 6px;">

                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12">
                                <div class="row">
                                    <div class="col-lg-4 col-md-3 trp required" style="display: flex;">
                                        <label class="customLabel"> Auto Markup<span class="tooltipWrap tooltip-icon"
                                                style="display:inline; padding-left: 7px !important"><i
                                                    class="fa fa-info-circle"></i></span>
                                            <span class="tooltips">
                                                When selected, the Part Price will automatically be
                                                calculated
                                                based on the Vendor Cost and the Markup Margin
                                            </span>
                                        </label>
                                        <input type="checkbox" class="customInput markup" formControlName="MarkupCheck"
                                            [(ngModel)]="MarkupCheck" (change)="changeMargin($event)">
                                    </div>
                                    <div class="col-lg-3 col-md-2 required" style="display: flex;">
                                        <label class="customLabel"> Markup
                                            Margin
                                        </label>
                                    </div>
                                    <div class="col-lg-5 col-md-7">
                                        <div style="display: flex">
                                            <input type="text" class="customInput" [(ngModel)]="MarkupMargin"
                                                placeholder="Enter Markup Margin" name="MarkupMargin"
                                                formControlName="MarkupMargin" autocomplete="off" required
                                                [readonly]="IsMarginDisabled"
                                                [ngClass]="this.MarkupCheck == true ? ((!form.controls.MarkupMargin.valid) && (validation)) ? 'validation_border': '' : ''"
                                                (blur)="UpdatePrice()" (keypress)="keyPressNumbers($event)"
                                                maxlength="9">
                                        </div>


                                    </div>


                                </div>



                            </div>
                        </div>
                        <div class="row mt-md-1">
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-3 trp">
                                        <label class="customLabel" style="font-weight: 500;"> Quantity Discounts
                                            :</label>
                                    </div>
                                    <div class="col-lg-8 col-md-9">
                                        <label class="customLabel"> Vendor Discount</label>
                                        <table class="table-bordered" style="margin-top: 0;">
                                            <tr>
                                                <td>
                                                    <div (click)="addRow()">
                                                        <i class="fas fa-plus-circle green-text"></i>
                                                    </div>

                                                </td>
                                                <td><label class="customLabel">Quantity</label></td>
                                                <td><label class="customLabel">Discount</label></td>
                                                <td><label class="customLabel">Price($)</label></td>
                                            </tr>

                                            <ng-container *ngFor="let dynamic of dynamicArray; let i = index;">
                                                <tr *ngIf="dynamic.isActive == true">
                                                    <td>
                                                        <div (click)="deleteRow(i)">
                                                            <i class="fas fa-minus-circle green-text"></i>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input name="{{dynamic.Qty}}" value="{{dynamic.Qty}}" autocomplete="off" class="customInput Cost" (change)="costcalc1(i, $event.target.value)"  type="text" />
                                                    </td>
                                                    <td>
                                                        <input name="{{dynamic.DiscountPrice}}" autocomplete="off" value="{{dynamic.DiscountPrice}}" (change)="costcalc(i, $event.target.value)" [disabled]="dynamic.IsDReadonly == true"  class="customInput Cost" type="text" />
                                                    </td>
                                                    <td>
                                                        <input name="{{dynamic.cost}}" class="customInput Costs"
                                                            type="text"  [disabled]="dynamic.isReadOnly == true"  value="{{dynamic.cost}}" (input)="priceChanged(i, $event.target.value)" />
                                                        <!-- <label style="width: 70px;"> {{dynamic.cost}} </label> -->
                                                    </td>
                                                </tr>
                                            </ng-container>
                                            <tr>


                                            </tr>

                                        </table>
                                    </div>

                                </div>
                               

                            </div>

                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                 <div class="row">
                                    <div class="col-lg-4 col-md-3 trp"> </div>
                                    <div class="col-lg-8 col-md-9">
                                        <label class="customLabel"> List Price Discount</label>
                                        <table class="table-bordered" style="margin-top: 0;">
                                            <tr>
                                                <td>
                                                    <div (click)="addCustomerDiscount()">
                                                        <i class="fas fa-plus-circle green-text"></i>
                                                    </div>
    
                                                </td>
                                                <td><label class="customLabel">Quantity</label></td>
                                                <td><label class="customLabel">Discount</label></td>
                                                <td><label class="customLabel">Price($)</label></td>
                                            </tr>
    
                                            <ng-container
                                                *ngFor="let dynamic of dynamicCustomerDiscount; let i = index;">
                                                <tr *ngIf="dynamic.isActive == true">
                                                    <td>
                                                        <div (click)="deleteCustomerDiscount(i)">
                                                            <i class="fas fa-minus-circle green-text"></i>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <input name="{{dynamic.Qty}}" autocomplete="off"  value="{{dynamic.Qty}}" class="customInput Cost" (change)="CustomerCalc1(i, $event.target.value)" type="text" />
                                                    </td>
                                                    <td>
                                                        <input name="{{dynamic.DiscountPrice}}"   value="{{dynamic.DiscountPrice}}" autocomplete="off" [disabled]="dynamic.IsDReadonly == true"  (change)="CustomerCalc(i, $event.target.value)" class="customInput Cost" type="text" />
                                                    </td>
                                                    <td>
                                                        <!-- <label style="width: 70px;"> {{dynamic.cost}} </label> -->
                                                        <input name="{{dynamic.cost}}" class="customInput Costs" autocomplete="off" type="text" [disabled]="dynamic.isReadOnly == true"  (change)="priceChanged(i, $event.target.value)"  value="{{dynamic.cost}}" />
                                                    </td>
                                                </tr>
                                            </ng-container>
    
    
                                        </table>
                                    </div>

                                 </div>

                                
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="customHeading mt-3">
                                    Production
                                </div>
                            </div>
                        </div>
                        <div class="row mt-md-1">
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-3 trp">
                                        <label class="customLabel">Production Role</label>
                                    </div>
                                    <div class="col-lg-8 col-md-9">
                                        <select class="customInput pl-1px" [(ngModel)]="userRole"
                                            (ngModelChange)="changeUserRole($event)" autocomplete="none|false|no"
                                            formControlName='userRole'>
                                            <option value=''>Select Role</option>
                                            <option *ngFor="let item of userRoleList" [value]="item.id">{{item.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-3 trp required">
                                        <label class="customLabel">Labor Hours</label>
                                    </div>
                                    <div class="col-lg-8 col-md-9">
                                        <input type="text" class="customInput Cost pl-5px" [(ngModel)]="hours"
                                            placeholder="Enter Labor Hours" formControlName='hours' autocomplete="off"
                                            required maxlength="10"
                                            [ngClass]="((!form.controls.hours.valid) && (validation)) ? 'validation_border': ''">
                                        <!-- <div *ngIf="((!form.controls.hours.valid) && (validation))"
                                            class="text-danger input-error">
                                            Enter hours.</div> -->
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="row mt-md-1">
                            <div class="col-lg-4 col-md-12">
                                <div class="row">
                                    <div class="col-lg-4 col-md-3 trp">
                                        <label class="customLabel">Skill Level</label>
                                    </div>
                                    <div class="col-lg-8 col-md-9">
                                        <select class="customInput pl-1px" formControlName="skillLevel"
                                            [(ngModel)]="skillLevel">
                                            <option value='0'>No Skill Required</option>
                                            <option *ngFor="let item of skillLevelList" [value]="item.id">
                                                {{item.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12">
                                <div class="row">
                                    <div class="col-lg-4 col-md-3 trp">
                                        <label class="customLabel">Default Workflow Steps</label>
                                    </div>
                                    <div class="col-lg-8 col-md-9">
                                        <select class="customInput pl-1px" formControlName="WorkflowSteps"
                                            [(ngModel)]="WorkflowSteps">
                                            <option value=''>Select Workflow Step</option>
                                            <option *ngFor="let item of WorkflowList" [value]="item.id">
                                                {{item.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div class="row hidden-xs">
                            <div class="col-md-12">
                                <div class="customHeading mt-3">
                                    Part Dimensions and Weight
                                </div>
                            </div>
                        </div>
                        <div style="display:flex" class="mt-2">
                            <div style="border:1px solid #eee; width:160px; height: 40px;">
                                <div style="background: #eee;
                          text-align: center;font-size: 12px;
                          border: 1px solid #ddd;"> <label class="customLabel highlight">Length</label>
                                </div>
                                <div style="display: flex">
                                    <input type="text" class="customInput Cost pl-5px" [(ngModel)]="length"
                                        formControlName='length' autocomplete="off" required maxlength="5"
                                        style="height: 22px;     border-radius: 0">

                                    <select class="customInput pl-1px" [(ngModel)]="DimentionTypeL"
                                        formControlName='DimentionTypeL' style="border-radius: 0"
                                        (change)="changeDimentions($event)">

                                        <option *ngFor="let item of DimentionTypeList" [value]="item.id">
                                            {{item.name}}
                                        </option>
                                    </select>


                                </div>
                            </div>

                            <div style="border:1px solid #eee; width:160px; height: 40px;">
                                <div style="background: #eee;
                            text-align: center;font-size: 12px;
                            border: 1px solid #ddd;"> <label class="customLabel highlight">Width</label>
                                </div>
                                <div style="display: flex">
                                    <input type="text" class="customInput Cost pl-5px" [(ngModel)]="width"
                                        formControlName='width' autocomplete="off" required maxlength="5"
                                        style="height: 22px;     border-radius: 0">


                                    <select class="customInput pl-1px" [(ngModel)]="DimentionTypeW"
                                        formControlName='DimentionTypeW' style="border-radius: 0"
                                        (change)="changeDimentions($event)">

                                        <option *ngFor="let item of DimentionTypeList" [value]="item.id">
                                            {{item.name}}
                                        </option>
                                    </select>

                                </div>
                            </div>
                            <div style="border:1px solid #eee; width:160px; height: 40px;">
                                <div style="background: #eee;
                            text-align: center;font-size: 12px;
                            border: 1px solid #ddd;"> <label class="customLabel highlight">Height</label>
                                </div>
                                <div style="display: flex">
                                    <input type="text" class="customInput Cost pl-5px" [(ngModel)]="height"
                                        formControlName='height' autocomplete="off" required maxlength="5"
                                        style="height: 22px;     border-radius: 0">

                                    <select class="customInput pl-1px" [(ngModel)]="DimentionTypeH"
                                        formControlName='DimentionTypeH' style="border-radius: 0"
                                        (change)="changeDimentions($event)">

                                        <option *ngFor="let item of DimentionTypeList" [value]="item.id">
                                            {{item.name}}
                                        </option>
                                    </select>


                                </div>
                            </div>
                            <div style="border:1px solid #eee; width:160px; height: 40px;">
                                <div style="background: #eee;
                            text-align: center;font-size: 12px;
                            border: 1px solid #ddd;"> <label class="customLabel highlight">Weight</label>
                                </div>
                                <div style="display: flex">
                                    <input type="text" class="customInput Cost pl-5px" [(ngModel)]="weight"
                                        formControlName='weight' autocomplete="off" required maxlength="5"
                                        style="height: 22px;     border-radius: 0">


                                    <select class="customInput pl-1px" [(ngModel)]="WeightType"
                                        formControlName='WeightType' style="border-radius: 0">

                                        <option *ngFor="let item of WeightTypeList" [value]="item.id">
                                            {{item.name}}
                                        </option>
                                    </select>

                                </div>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <div class="customHeading mt-3">
                                    Image
                                </div>
                            </div>
                        </div>
                        <div class="row mt-md-1 d-flex align-items-center">

                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-3 trp ">
                                        <label class="customLabel">Image Type</label>
                                    </div>
                                    <div class="col-lg-8 col-md-9">
                                        <select name='ImageTypeId' class="customInput" formControlName="ImageTypeId"
                                            [(ngModel)]="SelectedImageTypeId" id="" class="customInput"
                                            [ngClass]="((SelectedImageTypeId=='') && (validationImage)) ? 'validation_border': ''">
                                            <option value=''>Select Image Type</option>
                                            <option *ngFor="let item of ImageTypelist" [value]="item.id">
                                                {{item.name}}
                                            </option>
                                        </select>
                                        <!-- <div *ngIf="((SelectedImageTypeId=='') && (validationImage))"
                                        class="text-danger input-error">
                                        Select Image Type</div> -->

                                    </div>



                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-3 trp ">
                                        <label class="customLabel">Upload</label>
                                    </div>
                                    <div class="col-lg-8 col-md-9">
                                        <input type="file" class=" customInput" #Image accept="
                                            application/pdf, image/*" (change)="ChngSettingImage($event.target.files)"
                                            id="file"
                                            [ngClass]="((PictureValue=='') && (validationImage)) ? 'validation_border': ''">
                                        <!-- <div *ngIf="((PictureValue=='') && (validationImage))"
                                            class="text-danger input-error">
                                            Choose File</div>  -->
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-1 col-md-12">
                                <button type="button" class="btn round-btn upload_btn add_img ml-0 mt-1 mt-lg-0"
                                    (click)="AddMultiplePartImage();">+ Add image</button>
                            </div>
                        </div>

                        <div class="row mt-4">
                            <div class="col-md-1" *ngFor="let image of MultipleImage;let i = index;">
                                <div class="particular_img">
                                    <img
                                        [src]="image.documenttype=='PDF'?DefaultLogoPDF:image.documenttype=='Word'?WordFile:image.imageUrl">
                                    <div class="view_img" (click)="viewImage(image.imageUrl,image.documenttype)">
                                        <i class="fa fa-eye" aria-hidden="true" data-toggle="modal"></i>
                                    </div>

                                    <button type="button" class="close" (click)="DeleteImageModalpopup(i,contentImage)"
                                        aria-label="Close">
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    <div class="imgtype">
                                        {{image.partypeName=="1"?"Part Image":image.partypeName=="2"?"Insatall
                                        Diagrams":image.partypeName=="3"?"Other Imageindex":""}}{{i+1}}
                                    </div>
                                </div>


                            </div>
                        </div>

                        <div class="part-questions">
                            <div class="customHeading mt-3">
                                Questions
                            </div>
                            <div class="row mt-1">
                                <div class="col-md-4 pr-0">
                                    <div *ngFor="let questionForm of this.questionArray.controls; let i = index"
                                        class="question-detail defaulkt-qus p-0">
                                        <div [formGroup]="questionForm" class="pb-2">
                                            <select *ngIf="i==0" class="customInput"
                                                (ngModelChange)="onchangeEvent($event)" formControlName='questionId'
                                                required>
                                                <option value="0">Select Question</option>
                                                <option *ngFor="let item of questionlist" [value]="item.id">
                                                    {{item.name}}
                                                </option>
                                            </select>
                                            <!-- <div *ngIf="this.questionArray.controls.length==1 && validation"
                                                class="text-danger input-error">
                                                Please Select Question.</div> -->

                                            <select *ngIf="i > 0" class="customInput"
                                                (ngModelChange)="onchangeEvent($event)" formControlName='questionId'
                                                disabled="true" required>
                                                <option value="0">Select Question</option>
                                                <option *ngFor="let item of questionlistNew" [value]="item.id">
                                                    {{item.name}}
                                                </option>
                                            </select>
                                        </div>
                                        <div *ngIf="i==0" class="qus-action  align-with-box add-newquestion"
                                            (click)="AddQuestionDropdownList(questionForm)">
                                            <i class="fas fa-plus-circle green-text"></i>
                                        </div>
                                        <div *ngIf="i>0" class="qus-action  align-with-box add-newquestion"
                                            (click)="RemoveQuestionDropdownList(i,questionForm)">
                                            <i class="fas fa-minus-circle red-text"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <button type="button" *ngIf="CheckCategory" class="btn blue-bg round-btn add-btn-res mt-2"
                            (click)="openModal()">eCommerce Details</button>
                        <button type="button" *ngIf="CheckCategoryDisable"
                            class="btn blue-bg round-btn add-btn-res mt-2" style="opacity:0.6">eCommerce
                            Details</button>
                        <br />
                        <div class="part-questions">
                            <div class="customHeading mt-3">
                                Inventory
                            </div>
                            <div class="row mt-md-1">
                                <div class="col-lg-4 col-md-12">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-3 trp">
                                            <label class="customLabel">Default Location Name</label>
                                        </div>
                                        <div class="col-lg-8 col-md-9">
                                            <!-- <select class="customInput pl-1px" name='UnitofMeasureId'
                                            formControlName="UnitofMeasureId" [(ngModel)]="SelectedUnitMeasure">
                                            <option value=''>Select Unit of Measurement</option>
                                            <option *ngFor="let item of unitofmeasurement" [value]="item.id">
                                                {{item.name}}
                                            </option>
                                        </select> -->
                                            <select class="customInput pl-1px" required name='LocationsId'
                                                formControlName="LocationsId" [(ngModel)]="SelectedLocation"
                                                (change)="changeLocation($event)">
                                                <option value=''>Select Default Location Name</option>
                                                <option *ngFor="let item of locationNameList"
                                                    [value]="item.locationTypeId">
                                                    {{item.locationName}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-12">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-3 trp">
                                            <label class="customLabel">Default Part Location</label>
                                        </div>
                                        <div class="col-lg-8 col-md-9">
                                            <!-- <input type="text" class="customInput" [(ngModel)]="partLocation"
                                            placeholder="Enter Part Location" formControlName='partLocation'
                                            autocomplete="off" maxlength="200"
                                            (keypress)="keyPressAlphaNumericWithCharacters($event)"> -->

                                            <select class="customInput pl-1px" required name='partLocation'
                                                formControlName="partLocation" [(ngModel)]="SelectedPartLocation">
                                                <option value=''>Select Default Part Location</option>
                                                <option *ngFor="let item of locationLevelList" [value]="item.id">
                                                    {{item.levelsName}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-lg-4 col-md-12">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-3 trp">
                                            <label class="customLabel">Part Tracking Details</label>
                                        </div>
                                        <div class="col-lg-8 col-md-9">
                                            <select class="customInput pl-1px" formControlName="partTracking"
                                                [(ngModel)]="partTracking">
                                                <option value=''>Select Part Tracking Details</option>
                                                <option *ngFor="let item of PartTrackingList" [value]="item.id">
                                                    {{item.name}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-md-1">

                                <div class="col-lg-4 col-md-12">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-3 trp">
                                            <label class="customLabel">Part Lead Time (Days)”</label>
                                        </div>
                                        <div class="col-lg-8 col-md-9">
                                            <input type="text" class="customInput NumberOnly" [(ngModel)]="partLeadTime"
                                                placeholder="Enter Part Lead Time" formControlName='partLeadTime'
                                                autocomplete="off" maxlength="10">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-12">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-3 trp">
                                            <label class="customLabel">Reorder Quantity</label>
                                        </div>
                                        <div class="col-lg-8 col-md-9">
                                            <input type="text" class="customInput NumberOnly"
                                                [(ngModel)]="reorderAmount" placeholder="Enter Reorder Quantity"
                                                formControlName='reorderAmount' autocomplete="off" maxlength="10">
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 col-md-12">
                                    <div class="row">
                                        <div class="col-lg-4 col-md-3 trp required">
                                            <label class="customLabel">Minimum Inventory</label>
                                        </div>
                                        <div class="col-lg-8 col-md-9">
                                            <input type="text" class="customInput Cost pl-5px"
                                                placeholder="Enter Minimum Inventory" formControlName="minInventory"
                                                [(ngModel)]="minInventory" autocomplete="off" required maxlength="6"
                                                [ngClass]="((!form.controls.minInventory.valid) && (validation)) ? 'validation_border': ''">
                                            <!-- <div *ngIf="((!form.controls.hours.valid) && (validation))"
                                            class="text-danger input-error">
                                            Enter hours.</div> -->
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row mt-1 d-flex align-items-center">
                                <div class="col-lg-1 col-md-6 pr-lg-0">
                                    <div class="d-flex align-items-center">
                                        <label class="customLabel">Quantity</label>
                                        <input type="text" class="customInput"
                                            [ngClass]="((!form.controls.InvQty.valid) && (InvValidation)) ? 'form-control1 NumberOnly validation_border': 'form-control1 NumberOnly'"
                                            placeholder="Qty" formControlName='InvQty' [(ngModel)]="InvQty"
                                            maxlength="5" min="1" max="99" style="margin-left:6px">
                                        <!-- <div *ngIf="((!form.controls.InvQty.valid) && (InvValidation))"
                                        class="text-danger input-error">
                                        Qty.</div> -->
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-6 mt-lg-0 mt-1">
                                    <div class="d-flex align-items-center">
                                        <label class="customLabel mr-2">Date</label>
                                        <input type="date" class="customInput" min="{{MinDate}}"
                                            [ngClass]="((!form.controls.InvDate.valid) && (InvValidation)) ? 'form-control1 validation_border': 'form-control1'"
                                            placeholder="Date" formControlName='InvDate' [(ngModel)]="InvDate">
                                    </div>
                                </div>
                                <div class="col-lg-1 col-md-6 p-lg-0 mt-lg-0 mt-1">
                                    <div class="d-flex align-items-center">
                                        <label class="customLabel mr-2 ws-nobreak">$ Cost</label>
                                        <input type="text" class="customInput"
                                            [ngClass]="((!form.controls.InvCost.valid) && (InvValidation)) ? 'form-control1 CostQty validation_border': 'form-control1 CostQty'"
                                            placeholder="$ Cost" formControlName='InvCost' [(ngModel)]="InvCost"
                                            maxlength="8">
                                    </div>
                                </div>
                                <div class="col-lg-2 col-md-6 mt-lg-0 mt-1">
                                    <div class="d-flex align-items-center">
                                        <label class="customLabel mr-2">Type</label>
                                        <select class="customInput"
                                            [ngClass]="((!form.controls.InvType.valid) && (InvValidation)) ? 'form-control1 validation_border': 'form-control1'"
                                            formControlName='InvType' [(ngModel)]="InvType">
                                            <option value=''>Select Type</option>
                                            <option *ngFor="let item of InvTypeList" [value]="item.id">
                                                {{item.name}}
                                            </option>
                                        </select>
                                    </div>
                                </div>

                                <ng-container *ngIf='InvType == "5"'>
                                    <div class="d-flex align-items-center">
                                        <label class="customLabel mr-2">Number</label>
                                        <input type="text" class="customInput"
                                            [ngClass]="((!form.controls.InvServNo.valid) && (InvValidation)) ? 'form-control1 validation_border': 'form-control1'"
                                            placeholder="Service #" formControlName='InvServNo' [(ngModel)]="InvServNo">
                                    </div>
                                </ng-container>
                                <ng-container *ngIf='InvType == "6"'>

                                    <div class="d-flex align-items-center">
                                        <label class="customLabel mr-2">Number</label>
                                        <select class="customInput" formControlName='InvPartOrder'
                                            [ngClass]="((!form.controls.InvPartOrder.valid) && (InvValidation)) ? 'validation_border': ''"
                                            [(ngModel)]="InvPartOrder">
                                            <option value=''>Select Part SO</option>
                                            <ng-container *ngFor="let item of InvPartOrderDDL">
                                                <option *ngIf="item.qtyToFulfill!=0" [value]="item.id">
                                                    {{item.name}}
                                                </option>
                                            </ng-container>
                                            <ng-container *ngFor="let item of InvPartOrderDDL">
                                                <option *ngIf="item.qtyToFulfill==0" [value]="item.id"
                                                    [disabled]="true">
                                                    {{item.name}} - Fulfilled
                                                </option>
                                            </ng-container>

                                            <!-- <option *ngFor="let item of InvPartOrderDDL" [value]="item.id">
                                            {{item.name}}
                                        </option> -->
                                        </select>

                                    </div>
                                </ng-container>
                                <ng-container *ngIf='InvType == "7"'>

                                    <div class="d-flex align-items-center">
                                        <label class="customLabel mr-2">Number</label>
                                        <select class="customInput" formControlName='InvProductOrder'
                                            [ngClass]="((!form.controls.InvProductOrder.valid) && (InvValidation)) ? 'validation_border': ''"
                                            [(ngModel)]="InvProductOrder">
                                            <option value=''>Select Product SO</option>
                                            <ng-container *ngFor="let item of InvProductOrderDDL">
                                                <option *ngIf="item.qtyToFulfill!=0" [value]="item.id">
                                                    {{item.name}}
                                                </option>
                                            </ng-container>
                                            <ng-container *ngFor="let item of InvProductOrderDDL">
                                                <option *ngIf="item.qtyToFulfill==0" [value]="item.id"
                                                    [disabled]="true">
                                                    {{item.name}} - Fulfilled
                                                </option>
                                            </ng-container>
                                            <!-- <option *ngFor="let item of InvProductOrderDDL" [value]="item.id">
                                            {{item.name}}
                                        </option> -->
                                        </select>

                                    </div>
                                </ng-container>

                                <div class="col-lg-2 col-md-12 pr-lg-0 mt-lg-0 mt-1">
                                    <div class="d-flex align-items-center">
                                        <label class="customLabel mr-2 ws-nobreak">Vendor<span
                                                style="color:red">*</span></label>
                                        <select class="customInput" formControlName='InvVendor'
                                            [ngClass]="((!form.controls.InvVendor.valid) && (InvValidation)) ? 'validation_border': ''"
                                            [(ngModel)]="InvVendor">
                                            <option value=''>Select Vendor</option>
                                            <option *ngFor="let item of partVendorsList" [value]="item.Id">
                                                {{item.name}}
                                            </option>
                                        </select>

                                    </div>
                                </div>
                                <!-- <div class="col-lg-2 col-md-12 pr-lg-0 mt-lg-0 mt-1">
                                <div class="d-flex align-items-center">
                                    <label class="customLabel mr-2 ws-nobreak">Customer RMA #</label>
                                    <input type="text" class="customInput" placeholder="Customer RMA #"
                                        formControlName='InvCustRMA' [(ngModel)]="InvCustRMA" maxlength="20">
                                </div>
                            </div> -->

                                <div class="col-lg-2 col-md-12 pr-lg-0 mt-lg-0 mt-1">
                                    <div class="d-flex align-items-center">
                                        <label class="customLabel mr-2 ws-nobreak">Location</label>
                                        <input type="text" class="customInput" formControlName='InvPartLocation'
                                            [(ngModel)]="InvPartLocation" maxlength="200">
                                        <button type="button" class="btn blue-bg round-btn add-btn-res"
                                            (click)="AddInv()"><i class="fas fa-plus-circle" aria-hidden="true"></i>
                                            Add
                                        </button>

                                    </div>
                                </div>

                            </div>
                            <table class="display table-bordered table clearfix order-detail mb-0 mt-2">
                                <thead>
                                    <tr>
                                        <th style="font-weight:bold; width: 5%;"></th>
                                        <th style="font-weight:bold; width: 6%;">Avlb. Qty</th>
                                        <th style="font-weight:bold; width: 10%;">Date</th>
                                        <th style="font-weight:bold; width: 10%;">Cost</th>
                                        <th style="font-weight:bold; width: 15%;">Type</th>
                                        <th style="font-weight:bold; width: 15%;">Number</th>
                                        <th style="font-weight:bold; width: 12%;">Vendor </th>
                                        <th style="font-weight:bold; width: 12%;">Location </th>
                                        <th style="font-weight:bold; width: 10%;">Manual/PO </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let InvItem of InvList; let i = index">
                                        <td>
                                            <a ngbTooltip="Move Inventory"><span class="fa fa-exchange partEdit mr-1"
                                                    (click)="MoveInvRow(InvItem)"></span></a>
                                            <a ngbTooltip="Adjust Quantity"
                                                *ngIf="InvItem.InvType!=5 && InvItem.InvType!=6 && InvItem.InvType!=7"><span
                                                    class="fa fa-indent partEdit ml-1"
                                                    (click)="AdjustInvRow(InvItem)"></span></a>
                                        </td>
                                        <td>
                                            <span *ngIf="normalShow==true || RowEdit==false">{{InvItem.InvQty}}</span>
                                            <span
                                                *ngIf="InvItem.InvSno==EditInvSno && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                <input class="customInput"
                                                    [ngClass]="((!form.controls.UpdateInvQty.valid) && (InvValidationUpdate)) ? 'form-control text-center NumberOnly validation_border': 'form-control text-center NumberOnly'"
                                                    formControlName='UpdateInvQty' maxlength="5" type="text"
                                                    [(ngModel)]="UpdateInvQty" min="1" max="99" />
                                            </span>
                                        </td>
                                        <td>
                                            <span *ngIf="normalShow==true || RowEdit==false">{{InvItem.InvDate | date:
                                                'MMM-dd-yyyy'}}</span>
                                            <span
                                                *ngIf="InvItem.InvSno==EditInvSno && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                <input formControlName='UpdateInvDate' class="customInput"
                                                    [ngClass]="((!form.controls.UpdateInvDate.valid) && (InvValidationUpdate)) ? 'form-control text-center validation_border': 'form-control text-center'"
                                                    type="date" min="{{MinDate}}" [(ngModel)]="UpdateInvDate" />
                                            </span>
                                        </td>
                                        <td>
                                            <span *ngIf="normalShow==true || RowEdit==false">${{InvItem.InvCost}}</span>
                                            <span
                                                *ngIf="InvItem.InvSno==EditInvSno && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                <input class="customInput"
                                                    [ngClass]="((!form.controls.UpdateInvCost.valid) && (InvValidationUpdate)) ? 'form-control text-center CostQty validation_border': 'form-control text-center CostQty'"
                                                    formControlName='UpdateInvCost' type="text"
                                                    [(ngModel)]="UpdateInvCost" maxlength="8" />
                                            </span>
                                        </td>
                                        <td>
                                            <span
                                                *ngIf="normalShow==true || RowEdit==false">{{GetInvTypeDDLText(InvItem.InvType)}}</span>
                                            <span
                                                *ngIf="InvItem.InvSno==EditInvSno && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                <select formControlName='UpdateInvType'
                                                    [ngClass]="((!form.controls.UpdateInvType.valid) && (InvValidationUpdate)) ? 'form-control validation_border': 'form-control'"
                                                    [(ngModel)]="UpdateInvType">
                                                    <option value=''>Select Type</option>
                                                    <option *ngFor="let item of InvTypeList" [value]="item.id">
                                                        {{item.name}}
                                                    </option>
                                                </select>
                                            </span>
                                        </td>
                                        <td>
                                            <span
                                                *ngIf="normalShow==true || RowEdit==false">{{GetInvType2DDLText(InvItem.InvType,
                                                InvItem.InvType2)}}</span>
                                            <span
                                                *ngIf="InvItem.InvSno==EditInvSno && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                <ng-container *ngIf='UpdateInvType == "5"'>
                                                    <input
                                                        [ngClass]="((!form.controls.UpdateInvServNo.valid) && (InvValidationUpdate)) ? 'form-control text-center validation_border': 'form-control text-center'"
                                                        formControlName='UpdateInvServNo' type="text"
                                                        [(ngModel)]="UpdateInvServNo" />
                                                </ng-container>
                                                <ng-container *ngIf='UpdateInvType == "6"'>
                                                    <select formControlName='UpdateInvPartOrder'
                                                        [ngClass]="((!form.controls.UpdateInvPartOrder.valid) && (InvValidationUpdate)) ? 'form-control validation_border': 'form-control'"
                                                        [(ngModel)]="UpdateInvPartOrder">
                                                        <option value=''>Select Part SO Order</option>
                                                        <option *ngFor="let item of InvPartOrderDDL" [value]="item.id">
                                                            {{item.name}}
                                                        </option>
                                                    </select>
                                                </ng-container>
                                                <ng-container *ngIf='UpdateInvType == "7"'>
                                                    <select formControlName='UpdateInvProductOrder'
                                                        [ngClass]="((!form.controls.UpdateInvProductOrder.valid) && (InvValidationUpdate)) ? 'form-control validation_border': 'form-control'"
                                                        [(ngModel)]="UpdateInvProductOrder">
                                                        <option value=''>Select Product SO Order</option>
                                                        <option *ngFor="let item of InvProductOrderDDL"
                                                            [value]="item.id">
                                                            {{item.name}}
                                                        </option>
                                                    </select>
                                                </ng-container>
                                            </span>
                                        </td>
                                        <td>
                                            <div *ngIf="normalShow==true || RowEdit==false">
                                                {{GetVendorNameById(InvItem.InvVendor)}}
                                            </div>
                                            <div
                                                *ngIf="InvItem.InvSno==EditInvSno && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                <select class="customInput" formControlName='UpdateInvVendor'
                                                    [ngClass]="((!form.controls.UpdateInvVendor.valid) && (InvValidation)) ? 'validation_border': ''"
                                                    [(ngModel)]="UpdateInvVendor">
                                                    <option value=''>Select Vendor</option>
                                                    <option *ngFor="let item of partVendorsList" [value]="item.Id">
                                                        {{item.name}}
                                                    </option>
                                                </select>
                                            </div>
                                        </td>
                                        <!-- <td>
                                        <span
                                            *ngIf="normalShow==true || RowEdit==false">{{InvItem.InvCustRMA}}</span>
                                        <span
                                            *ngIf="InvItem.InvSno==EditInvSno && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                            <input class="form-control text-center"
                                                formControlName='UpdateInvCustRMA' type="text"
                                                [(ngModel)]="UpdateInvCustRMA" />
                                        </span>
                                    </td> -->
                                        <td>
                                            <div *ngIf="normalShow==true || RowEdit==false">
                                                {{InvItem.InvPartLocation}}
                                            </div>
                                            <div
                                                *ngIf="InvItem.InvSno==EditInvSno && EditRowTxtName=='editRow' && CurrentEditRow=='Edit'">
                                                <input class="customInput text-center"
                                                    formControlName='UpdateInvPartLocation' type="text"
                                                    [(ngModel)]="UpdateInvPartLocation" maxlength="200" />
                                            </div>
                                        </td>
                                        <td>
                                            <span>{{InvItem.purchaseOrderId==null?"Manual":"PO"}}</span>
                                        </td>
                                    </tr>
                                    <tr *ngIf="InvList.length==0">
                                        <td colspan="8" class="text-center">Inventory Item is not Available</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <hr class="mb-1 mt-2">
                        <h3 class="qty_hd" *ngIf="isTotalInvQty==true"><strong>Total Qty : {{TotalAllType}}</strong>
                        </h3>
                        <div class="submit-from text-right">
                            <button type="button" *ngIf="rolePermissionsList && rolePermissionsList[0].isInsert"
                                class="btn blue-bg round-btn" (click)="AddPart()" [disabled]="isPartval"><i
                                    class="fas fa-save" aria-hidden="true"></i>{{buttonValue}}</button>
                            <!-- <button class="btn darkred-bg round-btn" *ngIf="clearbutton==true"
                            (click)="resetvendorData()"><i class="fas fa-redo" aria-hidden="true"></i>
                            Clear </button> -->
                            <button type="button" class="btn darkred-bg round-btn"
                                (click)="DiscrdProduct(discardItem)"><i class="fas fa-sign-out-alt"
                                    aria-hidden="true"></i>
                                Exit </button>
                        </div>

                    </div>
                </form>
            </div>

        </div>
    </div>
    <div class="row mb-4">
        <button type="button" *ngIf="PartHistory" class="btn blue-bg round-btn add-btn-res mt-2"
            (click)="openHistoryModal()" style="width: 145px;margin-left:21px;">Part History Log</button>
    </div>
    <div class="row" [style.display]="isInvFind==true ? 'block' : 'none'" *ngIf="isInvFind">
        <div class="col-md-12 p-lg-0">
            <button type="button" class="btn blue-bg round-btn part-category viewlogbtn" (click)="viewLogFun()"
                style="width: 145px;"><i class="fas fa-down-circle" aria-hidden="true"></i>Inventory Log<i
                    class="ml-1 fas fa-angle-down" aria-hidden="true"></i>
            </button>
        </div>
    </div>
    <div class="inventory-table table-responsive" [style.display]="viewLog==true ? 'block' : 'none'">

        <table class="table table-bordered">
            <thead>
                <tr>
                    <th width="10%" scope="col">Action</th>
                    <th width="10%" scope="col">Date</th>
                    <th width="10%" scope="col">Type</th>
                    <th width="10%" scope="col">Number</th>
                    <th width="10%" scope="col">In Qty</th>
                    <th width="10%" scope="col">Out Qty</th>
                    <th width="15%" scope="col">Location</th>
                    <th width="25%" scope="col">Remark</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let item of logList; let i = index">
                    <td [style.background-color]="item.bcolor">{{item.action}}</td>
                    <td [style.background-color]="item.bcolor">{{item.effectDate}}</td>
                    <td [style.background-color]="item.bcolor">{{item.type}}</td>
                    <td [style.background-color]="item.bcolor">{{item.typeValue}}</td>
                    <td [style.background-color]="item.bcolor">{{item.inQty}}</td>
                    <td [style.background-color]="item.bcolor">{{item.outQty}}</td>
                    <td [style.background-color]="item.bcolor">{{item.location}}</td>
                    <td [style.background-color]="item.bcolor">{{item.remark}}</td>
                </tr>
                <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colspan="2" align="text-center"><b><b>Total Available : {{TotalIn-TotalOut}}</b></b></td>
                    <td></td>
                    <td></td>
                </tr>
            </tbody>
        </table>
    </div>

</section>

<ng-template #itemModel let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg alert_heading">Move Inventory - Move part number {{partNo}} from
        {{displayOldLocation}} to New Location:</h4>
    <button type="button" class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body">
        <form [formGroup]="formPopup">

            <div class="row mt-2">
                <div class="col-lg-3 col-md-12 mt-lg-0 mt-1">
                    <div class="row">
                        <div class="col-lg-4 col-md-2 trp required">
                            <label class="customLabel">Current Qty</label>
                        </div>
                        <div class="col-lg-8 col-md-10">
                            <input type="text" class="customInput NumberOnly" formControlName='mInvQty'
                                [(ngModel)]="mInvQty" maxlength="5" min="1" max="99" disabled>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-12 mt-lg-0 mt-1">
                    <div class="row">
                        <div class="col-lg-4 col-md-2 trp required">
                            <label class="customLabel">Date</label>
                        </div>
                        <div class="col-lg-8 col-md-10">
                            <input type="date" class="customInput" min="{{MinDate}}" placeholder="Date"
                                formControlName='mInvDate' [(ngModel)]="mInvDate"
                                [ngClass]="((!formPopup.controls.mInvDate.valid) && (mInvValidation)) ? 'validation_border': ''"
                                disabled>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-12 mt-lg-0 mt-1">
                    <div class="row">
                        <div class="col-lg-4 col-md-2 trp required">
                            <label class="customLabel">Type</label>
                        </div>
                        <div class="col-lg-8 col-md-10">
                            <select class="customInput" formControlName='mInvType' [(ngModel)]="mInvType"
                                [ngClass]="((!formPopup.controls.mInvType.valid) && (mInvValidation)) ? 'validation_border': ''">
                                <option value=''>Select Type</option>
                                <option *ngFor="let item of mInvTypeList" [value]="item.id">
                                    {{item.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf='mInvType == "5"'>
                    <div class="col-lg-3 col-md-12 mt-lg-0 mt-1">
                        <div class="row">
                            <div class="col-lg-4 col-md-2 trp required">
                                <label class="customLabel">Number</label>
                            </div>
                            <div class="col-lg-8 col-md-10">
                                <input type="text" class="customInput" placeholder="Service #"
                                    formControlName='mInvServNo' [(ngModel)]="mInvServNo"
                                    [ngClass]="((!formPopup.controls.mInvServNo.valid) && (mInvValidation)) ? 'validation_border': ''">
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf='mInvType == "6"'>
                    <div class="col-lg-3 col-md-12 mt-lg-0 mt-1">
                        <div class="row">
                            <div class="col-lg-4 col-md-2 trp required">
                                <label class="customLabel">Number</label>
                            </div>
                            <div class="col-lg-8 col-md-10">
                                <select class="customInput" formControlName='mInvPartOrder' [(ngModel)]="mInvPartOrder"
                                    [ngClass]="((!formPopup.controls.mInvPartOrder.valid) && (mInvValidation)) ? 'validation_border': ''"
                                    (change)="ChangeOrderDropdown($event.target.value)">
                                    <option value=''>Select Part SO Order</option>
                                    <option *ngFor="let item of mInvPartOrderDDL" [value]="item.id">
                                        {{item.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf='mInvType == "7"'>
                    <div class="col-lg-3 col-md-12 mt-lg-0 mt-1">
                        <div class="row">
                            <div class="col-lg-4 col-md-2 trp required">
                                <label class="customLabel">Number</label>
                            </div>
                            <div class="col-lg-8 col-md-10">
                                <select class="customInput" formControlName='mInvProductOrder'
                                    [(ngModel)]="mInvProductOrder"
                                    [ngClass]="((!formPopup.controls.mInvProductOrder.valid) && (mInvValidation)) ? 'validation_border': ''"
                                    (change)="ChangeOrderDropdown($event.target.value)">
                                    <option value=''>Select Product SO Order</option>
                                    <option *ngFor="let item of mInvProductOrderDDL" [value]="item.id">
                                        {{item.name}}
                                    </option>
                                </select>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <div class="row mt-2">
                <div class="col-lg-3 col-md-12 mt-lg-0 mt-1">
                    <div class="row">
                        <div class="col-lg-4 col-md-2 trp required">
                            <label class="customLabel">Qty to Move</label>
                        </div>
                        <div class="col-lg-8 col-md-10">
                            <input type="text" class="customInput NumberOnly" formControlName='mInvQtyToMove'
                                [(ngModel)]="mInvQtyToMove" maxlength="5" min="1" max="99"
                                oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"
                                [ngClass]="((!formPopup.controls.mInvQtyToMove.valid || mInvQtyValidation) && (mInvValidation)) ? 'validation_border': ''">
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-12 mt-lg-0 mt-1">
                    <div class="row">
                        <div class="col-lg-4 col-md-2 trp">
                            <label class="customLabel">Location</label>
                        </div>
                        <div class="col-lg-8 col-md-10">
                            <input type="text" class="customInput" placeholder="Enter Location"
                                formControlName='mInvLocation' [(ngModel)]="mInvLocation">
                        </div>

                    </div>
                </div>
                <div class="col-lg-6 col-md-12 mt-lg-0 mt-1">
                    <div class="row">
                        <div class="col-lg-2 col-md-2 trp">
                            <label class="customLabel">Reason</label>
                        </div>
                        <div class="col-lg-8 col-md-10">
                            <input type="text" class="customInput" placeholder="Enter Reason"
                                formControlName='mInvReason' [(ngModel)]="mInvReason">
                        </div>
                        <div class="col-lg-2 col-md-10">

                        </div>
                    </div>
                </div>
            </div>
            <div class="alert alert-danger fade show mt-3" role="alert" style="padding: 0.35rem 1.25rem;"
                *ngIf="mQtyMoveMsg==true">
                Quantity can't be move more than {{mValidateQtyToFulfill}} for this sales order.
                <button type="button" class="close" (click)="closenoti()">
                    <strong aria-hidden="true" class="text-danger">&times;</strong>
                </button>
            </div>
        </form>
    </div>
    <div class="modal-footer text-center">
        <button type="button" class="btn blue-bg round-btn add-btn-res" (click)="MoveInv()"><i class="fa fa-arrow-right"
                aria-hidden="true"></i>
            Move
        </button>
        <button type="button" class="btn red-bg round-btn m-0" (click)="c('Close click')">Exit</button>
    </div>
</ng-template>

<ng-template #itemModel2 let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg alert_heading">Adjust Quantity - {{name}}</h4>
    <button type="button" class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body">
        <form [formGroup]="formPopupAdjustQty">

            <div class="row mt-2">
                <div class="col-lg-3 col-md-12 mt-lg-0 mt-1">
                    <div class="row">
                        <div class="col-lg-4 col-md-2 trp required">
                            <label class="customLabel">Current Qty</label>
                        </div>
                        <div class="col-lg-8 col-md-10">
                            <input type="text" class="customInput NumberOnly" formControlName='adjInvQty'
                                [(ngModel)]="adjInvQty" maxlength="5" min="1" max="99" disabled>
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-12 mt-lg-0 mt-1">
                    <div class="row">
                        <div class="col-lg-4 col-md-2 trp required">
                            <label class="customLabel">Adjust Qty To</label>
                        </div>
                        <div class="col-lg-8 col-md-10">
                            <input type="text" class="customInput NumberOnly" formControlName='adjInvQtyToAdjust'
                                [(ngModel)]="adjInvQtyToAdjust" maxlength="5" min="1" max="99"
                                [ngClass]="((!formPopupAdjustQty.controls.adjInvQtyToAdjust.valid || (formPopupAdjustQty.controls.adjInvQtyToAdjust.value == formPopupAdjustQty.controls.adjInvQty.value)) && (adjInvValidation)) ? 'validation_border': ''">
                        </div>
                    </div>
                </div>
                <div class="col-lg-3 col-md-12 mt-lg-0 mt-1">
                    <div class="row">
                        <div class="col-lg-4 col-md-2 trp">
                            <label class="customLabel">Reason</label>
                        </div>
                        <div class="col-lg-8 col-md-10">
                            <select class="customInput" [(ngModel)]="adjInvReason" formControlName="adjInvReason">
                                <option value="">Select</option>
                                <option *ngFor="let item of adjInvReasonList" [value]="item.name">
                                    {{item.name}}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div class="modal-footer text-center">
        <button type="button" class="btn blue-bg round-btn add-btn-res ml-0" (click)="AdjustInv()">
            Save
        </button>
        <button type="button" class="btn red-bg round-btn m-0" (click)="c('Close click')">Exit</button>
    </div>
</ng-template>

<ng-template #QtyMoveMsgModel let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg sweet-heading"></h4>
    <button type="button" class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body">
        <div class="thank-you-pop">
            <img src="{{mMsgClass}}" alt="">
            <p class="mb-0">{{mMsg}}</p>
        </div>
    </div>
</ng-template>

<ng-template #content let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg sweet-heading"></h4>
    <button type="button" class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body">
        <div class="thank-you-pop">
            <img src="./assets/image/popup-alert.png" alt="">
            <h1>Confirmation</h1>
            <p class="mb-0">{{deleteDetail}}</p>
        </div>
    </div>
    <div class="modal-footer sweet-footer text-center">
        <button type="button" class="btn green-bg round-btn m-0 mr-1"
            (click)="deletePart(deletedItem); c('Close click')">Yes</button>
        <button type="button" class="btn red-bg round-btn m-0" (click)="c('Close click')">No</button>
    </div>
</ng-template>

<ng-template #contentPart let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg sweet-heading"></h4>
    <button type="button" class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body">
        <div class="thank-you-pop">
            <img src="./assets/image/popup-alert.png" alt="">
            <h1>Confirmation</h1>
            <p class="mb-0">{{deleteDetail}}</p>
        </div>
    </div>
    <div class="modal-footer sweet-footer text-center">
        <button type="button" class="btn green-bg round-btn m-0 mr-1"
            (click)="DeletePart(); c('Close click')">Yes</button>
        <button type="button" class="btn red-bg round-btn m-0" (click)="c('Close click')">No</button>
    </div>
</ng-template>

<ng-template #DelInvModel let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg sweet-heading"></h4>
    <button type="button" class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body">
        <div class="thank-you-pop">
            <img src="./assets/image/popup-alert.png" alt="">
            <h1>Confirmation</h1>
            <p class="mb-0">{{deleteDetail}}</p>
        </div>
    </div>
    <div class="modal-footer sweet-footer text-center">
        <button type="button" class="btn green-bg round-btn m-0 mr-1"
            (click)="DelInvRow(); c('Close click')">Yes</button>
        <button type="button" class="btn red-bg round-btn m-0" (click)="c('Close click')">No</button>
    </div>
</ng-template>




<div class="modal fade" id="addpart" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel">
    <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header info-popup-header">
                <h5 class="modal-title info-popup-title" id="exampleModalLabel">Add Part</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" #closeBtn
                    (click)="MisccloseModal()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <div class="modal-body">
                <form [formGroup]="form">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-4 required">
                                    <label class="customLabel forbold">Part Name</label>
                                </div>
                                <div class="col-md-8">
                                    <input type="text" class="customInput" [(ngModel)]="miscname"
                                        placeholder="Enter Part Name" formControlName='miscname' autocomplete="off"
                                        required
                                        [ngClass]="((!form.controls.miscname.valid) && (validationcustom)) ? 'validation_border': ''">
                                    <!-- <div *ngIf="((!form.controls.miscname.valid) && (validationcustom))"
                                        class="text-danger input-error" style="margin-bottom: 0rem;">
                                        Enter Part Name.</div> -->
                                    <div *ngIf="((form.controls.miscname.valid) && (recordAlreadyExist))"
                                        class="text-danger input-error" style="margin-bottom: 0rem;">
                                        Record Already Exist.</div>
                                </div>
                            </div>

                        </div>

                        <div class="col-md-6 mt-md-0 mt-1">
                            <div class="row">
                                <div class="col-md-4 required">
                                    <label class="customLabel forbold">Part Number</label>
                                </div>
                                <div class="col-md-8">
                                    <input type="text" class="customInput" [(ngModel)]="miscpartNo"
                                        placeholder="Enter Part Number" formControlName='miscpartNo' autocomplete="off"
                                        required maxlength="12">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-4">
                                    <label class="customLabel forbold">Vendor Part Cost</label>
                                </div>
                                <div class="col-md-8">
                                    <div class="part-cost-block">
                                        <span class="dollar">$</span>
                                        <input type="text" class="customInput Cost" [(ngModel)]="miscVendorPartCost"
                                            placeholder="Enter Vendor Part Cost" formControlName='miscVendorPartCost'
                                            autocomplete="off" maxlength="10" (blur)="MisceVendorCost()">
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mt-md-0 mt-1">
                            <div class="row">
                                <div class="col-md-4 required">
                                    <label class="customLabel forbold">Part Price</label>
                                </div>
                                <div class="col-md-8">
                                    <div class="part-cost-block">
                                        <span class="dollar">$</span>
                                        <input type="text" class="customInput Cost" [(ngModel)]="misccost"
                                            placeholder="Enter Part Price" formControlName='misccost' autocomplete="off"
                                            maxlength="10" required (blur)="MiscePartCost()"
                                            [ngClass]="((!form.controls.misccost.valid) && (validationcustom)) ? 'validation_border': ''">
                                    </div>
                                    <!-- <div *ngIf="((!form.controls.misccost.valid) && (validationcustom))"
                                        class="text-danger input-error" style="font-size: 0.7rem; margin-bottom: 0rem;">
                                        Enter Cost.</div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-1">
                        <div class="col-md-6">
                            <div class="row">
                                <div class="col-md-4">
                                    <label class="customLabel forbold">Labor Hours</label>
                                </div>
                                <div class="col-md-8">
                                    <input type="text" class="customInput Cost pl-2" [(ngModel)]="mischours"
                                        placeholder="Enter Labor Hours xx.xx" formControlName='mischours'
                                        autocomplete="off" maxlength="10">
                                    <!-- <div *ngIf="((!form.controls.mischours.valid) && (validationcustom))"
                                    class="text-danger input-error">
                                    Enter hours.</div> -->
                                </div>
                            </div>
                        </div>
                        <div class="col-md-6 mt-md-0 mt-1">
                            <div class="row">
                                <div class="col-md-4">
                                    <label class="customLabel forbold">Quantity</label>
                                </div>
                                <div class="col-md-4">
                                    <input type="text" class="customInput NumberOnly" placeholder="Qty" id="quantity"
                                        formControlName='miscqty' [(ngModel)]="miscqty" maxlength="5" min="1" max="99">

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="submit-from text-right mt-2">
                        <button type="button" class="btn blue-bg round-btn" (click)="AddMiscellaneousPart();"><i
                                class="fas fa-save" aria-hidden="true"></i> Add Part
                        </button>
                        <button type="button" class="btn darkred-bg round-btn"><i class="fas fa-redo"
                                aria-hidden="true"></i> Clear
                        </button>
                    </div>
                </form>
            </div>

        </div>
    </div>
</div>

<div class="modal" id="addindividualpart">
    <div class="modal-dialog  modal-dialog-centered modal-lg centModel" role="document">
        <div class="modal-content">
            <h5 class="modal-title info-popup-title p-1" id="exampleModalLabel1">Add Part</h5>
            <button class="close btn alert_modal" aria-label="Close" (click)="PartItemExit()">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">

                <div class="row mb-1">
                    <div class="col-md-12">
                        <div class="row">
                            <div class="col-md-4 col-12">
                                <div class="d-flex align-items-center">
                                    <label class="customLabel mr-2" for="Vendor">Category</label>
                                    <select class="customInput" name='CustompartCategoryId' [(ngModel)]="CustomCategory"
                                        id="customCategory">
                                        <option value=''>All</option>
                                        <option *ngFor="let item of Customlist" [value]="item.id">
                                            {{item.name}}
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-lg-3 col-md-12 mt-lg-0 mt-1">

                                <div class="d-flex align-items-center">
                                    <select class="customInput pl-1px"
                                        (change)="CChangefieldToSearch($event.target.value)"
                                        [(ngModel)]="cfieldToSearch" id="cfieldToSearch">
                                        <option value='1'>Search Keywords</option>
                                        <option value='2'>List or Vendor Part Name</option>
                                        <option value='3'>List or Vendor Part #</option>
                                    </select>
                                </div>
                            </div>
                            <div class="col-md-3 col-11 pr-md-2 mt-md-0 mt-1">
                                <div class="d-flex align-items-center">
                                    <input type="text" class="customInput" placeholder="{{cfieldToSearchPlaceHolder}}"
                                        [(ngModel)]="PartName_search" autocomplete="off"
                                        (keyup.enter)="SearchByCategory()">
                                </div>
                            </div>
                            <div class="col-md-1 col-1 text-left or_width"
                                style="max-width: 30px; padding:0; margin-top: 3px;">
                                <i class="fas fa-search cpointer" (click)="SearchByCategory()"></i>
                            </div>
                            <div class="col-md-1 col-1 text-left or_width">
                                <button type="button" class="btn blue-bg all-custom round-btn m-0 centbutton"
                                    (click)="SearchByCategoryAll()">All Parts</button>
                            </div>
                        </div>
                    </div>

                </div>

                <hr>
                <div class="white-box1" [style.display]="ListShow==true ? 'block' : 'none'">
                    <div class="white-box-title1">
                        <div class="customHeading">
                            Part List
                        </div>
                    </div>
                    <!------------------------------------- company list table ------------------------------>
                    <div class="card-desc1 mt-1 table-responsive">
                        <table id="datatable2" class="display table table-responsive clearfix" datatable
                            [dtOptions]="dtOptions1" [dtTrigger]="dtTrigger1">
                            <thead>
                                <tr>
                                    <th hidden></th>
                                    <th style="display: contents !important;"></th>
                                    <th>Company</th>
                                    <th>List Part Name</th>
                                    <th>List Part#</th>
                                    <th>Vendor Part Name</th>
                                    <th>Vendor Part Number</th>
                                    <th>Part Price</th>
                                    <th>Labor Hrs</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let data of ddlPartList1" style="cursor: pointer;"
                                    (click)="PartdetailsModalPopup(data.id, data.partCategoryId);">
                                    <td style="display: none;">{{data.id}}</td>
                                    <td>{{data.companyName}}</td>
                                    <td>{{data.name}}</td>
                                    <td>{{data.partNo}}</td>
                                    <td>{{data.vendorPartName}}</td>
                                    <td>{{data.vendorPartNumber}}</td>
                                    <td>{{data.cost | currency }}</td>
                                    <td>{{data.laborCharge}}</td>

                                </tr>
                            </tbody>
                            <tbody *ngIf="ddlPartList1==null">
                                <tr class="c1">
                                    <td colspan="10" class="no-data-available">No data available in table</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                </div>

                <div class="submit-from text-right">
                    <!-- <button class="btn blue-bg round-btn" (click)="addPart(partObjectModel)"><i class="fas fa-save"
                            aria-hidden="true"></i> Add Part
                    </button> -->
                    <button class="btn darkred-bg round-btn" (click)="PartItemExit();"><i aria-hidden="true"></i>
                        Exit </button>

                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="addpartModalpopup">
    <div class="modal-dialog  modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <h5 class="modal-title info-popup-title p-1" id="exampleModalLabel1">
                <div class="text-over-ellipse pl-2 text-white">{{partObjectModels.name}}</div>
            </h5>
            <button class="close btn alert_modal" aria-label="Close" (click)="ClosePartModalPopup()">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
                <div class="row">
                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-md-6">

                                <label class="customLabel">Company Part #</label>
                            </div>
                            <div class="col-md-6 f_size_10 customLabel">
                                {{partObjectModels.partNo}}


                            </div>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-md-4">
                                <label class="customLabel">Company Part Name</label>
                            </div>
                            <div class="col-md-8 f_size_10 text-over-ellipse customLabel">
                                {{partObjectModels.name}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-1">

                    <div class="col-md-4">
                        <div class="row">
                            <div class="col-md-6">
                                <label class="customLabel">Vendor Part #</label>
                            </div>
                            <div class="col-md-6 f_size_10 customLabel">
                                {{partObjectModels.vendorPartNumber}}
                                <span style="display: none;">{{partObjectModels.vendorName}}</span>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-md-4">
                                <label class="customLabel">Vendor Part Name</label>
                            </div>
                            <div class="col-md-8 f_size_10 text-over-ellipse customLabel">
                                {{partObjectModels.vendorPartName}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row mt-4">
                    <div class="col-lg-2 col-md-2">
                        <div class="d-flex align-items-center">
                            <label class="customLabel mr-2">Quantity</label>
                            <input type="text" [(ngModel)]="partObjectModels.qty" class="customInput NumberOnly"
                                placeholder="Qty" id="quantity" maxlength="5" min="1" max="99">
                            <div *ngIf="((quantity=='') && (validationPart))" class="text-danger input-error">
                                Enter Quantity.</div>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-12">
                        <div class="d-flex align-items-center">
                            <label class="customLabel mr-2">Vendor</label>
                            <select class="customInput" name='CustompartVendorId' [(ngModel)]="CustompartVendor"
                                id="CustompartVendor" (change)="OnCustomVendorChangeEvent($event)"
                                [ngClass]="((CustompartVendor=='') && (cValidation)) ? 'validation_border': ''">
                                <option value=''>Select Vendor</option>
                                <option *ngFor="let item of ddlVendorList" [value]="item.id">
                                    {{item.name}}
                                </option>
                            </select>
                        </div>

                    </div>
                </div>
                <div class="row mt-2">
                    <div class="col-md-12">
                        <div class="customHeading">
                            Part Questions
                        </div>
                    </div>
                    <div class="col-md-12 mt-2">
                        <div class="row pb-2" *ngFor="let item of partObjectModels.partQuestions;let i=index">
                            <div class="col-md-8">
                                <label class="customLabel">{{item.questionName}}</label>
                            </div>

                            <div class="col-md-4" *ngIf="item.typeKey =='Text'">
                                <input type="text" [(ngModel)]="item.answerName" class="customInput"
                                    placeholder="Type Answer" maxlength="50">
                            </div>
                            <div class="col-md-4" *ngIf="item.typeKey =='VendorName'">
                                <select class="form-control newsmallddl" [(ngModel)]="item.answerName">
                                    <option value='null'>Select Vendor</option>
                                    <option *ngFor="let item of vendorList">{{item.vendorName}}
                                    </option>
                                </select>
                            </div>
                            <div class="col-md-4" *ngIf="item.typeKey =='CustomerName'">
                                <select class="form-control newsmallddl " [(ngModel)]="item.answerName">
                                    <option value='null' selected>Select Customer</option>
                                    <option *ngFor="let item of customerData">
                                        {{item.customerName}}
                                    </option>

                                </select>
                            </div>
                            <div class="col-sm-4" *ngIf="item.typeKey =='YesNo'">
                                <select class="form-control newsmallddl" [(ngModel)]="item.answerName">
                                    <option value='null' selected>Select Yes/No</option>
                                    <option value="Yes">Yes</option>
                                    <option value="No">No</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="submit-from text-right">
                    <button class="btn blue-bg round-btn" (click)="AddpartDetailsNew(partObjectModels)"><i
                            class="fas fa-save" aria-hidden="true"></i> Add Part
                    </button>
                    <button class="btn darkred-bg round-btn" (click)="ClosePartModalPopup()"><i aria-hidden="true"></i>
                        Exit
                    </button>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal" id="CustomPartitem">
    <div class="modal-dialog">
        <div class="modal-content">
            <h4 class="modal-title info-popup-header darkred-bg alert_heading sweet-heading"></h4>
            <button class="close btn alert_modal" aria-label="Close" (click)="CustomPartPopup()">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thanks</h1>
                    <p class="modal-para mb-0">Part : {{partObjectModels.name}} Added</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer">
                <button type="button" class="btn red-bg round-btn m-0" (click)="CustomPartPopup()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="myModal">
    <div class="modal-dialog">
        <div class="modal-content">
            <h4 class="modal-title info-popup-header darkred-bg alert_heading sweet-heading"></h4>
            <button type="button" class="close btn alert_modal" aria-label="Close" (click)="hidemodalpopup()">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
                <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="">
                    <h1>Message</h1>
                    <p class="modal-para mb-0">You have not added any part item, so please add at least one part item.
                    </p>
                </div>
            </div>
            <div class="modal-footer sweet-footer">
                <button type="button" class="btn red-bg round-btn m-0" (click)="hidemodalpopup()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="myModal1">
    <div class="modal-dialog">
        <div class="modal-content">
            <h4 class="modal-title darkred-bg sweet-heading"></h4>
            <button type="button" class="close btn alert_modal" aria-label="Close" (click)="hidemodalpopup1()">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
                <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="">
                    <h1>Message</h1>
                    <p class="mb-0">Already added this part into list.</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer">
                <button type="button" class="btn red-bg round-btn m-0" (click)="hidemodalpopup1()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="myModal2">
    <div class="modal-dialog">
        <div class="modal-content">
            <h4 class="modal-title darkred-bg sweet-heading"></h4>
            <button type="button" class="close btn alert_modal" aria-label="Close" (click)="hidemodalpopup2()">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
                <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="">
                    <h1>Message</h1>
                    <p class="mb-0">Please Select Vendor</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer">
                <button type="button" class="btn red-bg round-btn m-0" (click)="hidemodalpopup2()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<ng-template #contentImage let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg sweet-heading"></h4>
    <button type="button" class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body">
        <div class="thank-you-pop">
            <img src="./assets/image/popup-alert.png" alt="">
            <h1>Confirmation</h1>
            <p class="mb-0">{{deleteDetail}}</p>
        </div>
    </div>
    <div class="modal-footer sweet-footer text-center">
        <button type="button" class="btn green-bg round-btn m-0 mr-1"
            (click)="DeleteImage(); c('Close click')">Yes</button>
        <button type="button" class="btn red-bg round-btn m-0" (click)="c('Close click')">No</button>
    </div>
</ng-template>
<!-- The Modal -->
<div class="modal" id="myModalImage">
    <div class="modal-dialog">
        <div class="modal-content">
            <!-- Modal Header -->
            <div class="modal-header info-popup-header view-img-modal">
                <button type="button" class="close btn alert_modal" data-dismiss="modal"
                    (click)="HideImage();">&times;</button>
            </div>
            <div class="modal-body">
                <img [src]="viewimagesshow" class="full-doc">
            </div>
        </div>
    </div>
</div>
<div class="modal" id="myModalExist">
    <div class="modal-dialog">
        <div class="modal-content">
            <h4 class="modal-title info-popup-header darkred-bg alert_heading sweet-heading"></h4>
            <button type="button" class="close btn alert_modal" aria-label="Close" (click)="ImageHide()">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
                <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="">
                    <h1>Alert</h1>
                    <p class="modal-para mb-0">You have Already Added this Part into List.</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer">
                <button type="button" class="btn red-bg round-btn m-0" (click)="ImageHide()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="add_success">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="addsuccess()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Added Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="addsuccess()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="add_update">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="updateitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Update Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="updateitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="delete_success">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="deleteitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>Record Deleted Successfully</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="deleteitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="wrong_msg">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="msgitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>Something went wrong</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="msgitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="wrong_mssg" style="z-index: 1080">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="mssgitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>Something went wrong</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="mssgitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<ng-template #discardItem let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg alert_heading sweet-heading"></h4>
    <button type="button" class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>

    <div class="modal-body p-1">
        <div class="thank-you-pop">
            <img src="./assets/image/popup-alert.png" alt="">
            <h1>Confirmation</h1>
            <p class="mt-1">{{deleteDetail}}</p>
        </div>
    </div>
    <div class="modal-footer sweet-footer text-center p-1">
        <button type="button" class="btn green-bg round-btn" (click)="gotoDashBoard(); c('Close click')">Yes</button>
        <button type="button" class="btn red-bg round-btn" (click)="c('Close click')">No</button>
    </div>
</ng-template>

<div class="modal" id="add_Items" style="z-index: 1080">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="crossitem()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="">
                    <p>{{ MainProp}}</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="crossitem()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="noproduct" style="z-index: 1080">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="noprod()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>{{noproduct}}</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="noprod()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="CheckCategoryModal" data-backdrop="static" data-keyboard="false">
    <div class="modal-dialog modal-dialog-scrollable pop">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading">
                    eCommerce Details &nbsp; &nbsp;&nbsp;
                </h4>
                <button type="button" class="close btn alert_modal" (click)="closepopModal()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <span>Show Part on eCommerce Site</span> &nbsp; <input type="checkbox" checked />
                <form [formGroup]="newform">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-12 form-group mt-2">
                                    <span class="customLabel"
                                        style="    font-size: 14px;text-decoration: underline;color: #333;">Field 1
                                        :</span>
                                    <input type="text" class="customInput" [(ngModel)]="InfoName" name="InfoName"
                                        formControlName='InfoName' autocomplete="off" style="padding-left: 7px;"
                                        required
                                        [ngClass]="((!newform.controls.InfoName.valid) && (popvalidation)) ? 'validation_border': ''">

                                    <textarea rows="3" cols="8" class="customInput customerTextarea textareashort"
                                        placeholder="Enter More Information" [(ngModel)]="shortDescription"
                                        name="shortDescription" formControlName='shortDescription' autocomplete="off"
                                        [ngClass]="((!newform.controls.shortDescription.valid) && (popvalidation)) ? 'validation_border': ''"></textarea>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row">
                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-12">
                                    <span class="customLabel"
                                        style="    font-size: 14px;text-decoration: underline;color: #333;">Part
                                        Relationship #1</span>
                                    <input type="text" class="customInput" [(ngModel)]="MatingName" name="MatingName"
                                        formControlName='MatingName' autocomplete="off" style="padding-left: 7px;"
                                        required
                                        [ngClass]="((!newform.controls.MatingName.valid) && (popvalidation)) ? 'validation_border': ''">

                                </div>
                            </div>
                            <div class="row mt-2">
                                <div class="col-md-4">
                                    <label class="customLabel"> Part Category</label>
                                </div>
                                <div class="col-md-7" style="margin-left:18px">

                                    <label class="customLabel"> Company Part Number / Name</label>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12"></div>
                            </div>
                            <div class="row">

                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-4 scrollboxleft">

                                            <div *ngFor="let item of WordpressCategoryList; let i = index">
                                                <input formControlName='chkInv' type="checkbox"
                                                    (click)="chngIsInv($event.target.checked, i, item.id, item.partCategoryName)"
                                                    [checked]="WordpressCategoryList[i].IsChecked">&nbsp;
                                                <label class="customLabel"> {{item.partCategoryName}}</label>
                                            </div>
                                        </div>
                                        <div class="col-md-7 scrollboxright">

                                            <div *ngFor="let item1 of CustomerPartNameList; let i = index">
                                                <input formControlName='chkInv1' type="checkbox"
                                                    (click)="chngIsInv1($event.target.checked, i, item1.wordpressId, item1.partCategoryId)"
                                                    [checked]='item1.isActive'>&nbsp;
                                                <label class="customLabel"> {{item1.partNo}} /
                                                    {{item1.partCategoryName}}
                                                </label>

                                            </div>

                                        </div>
                                    </div>

                                </div>


                            </div>
                        </div>
                    </div>

                    <br />
                    <div class="row">

                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="form-group">
                                        <span class="customLabel"
                                            style="    font-size: 14px;text-decoration: underline;color: #333;">Part
                                            Relationship #2</span>

                                        <input type="text" class="customInput" [(ngModel)]="CrimpName" name="CrimpName"
                                            formControlName='CrimpName' autocomplete="off" style="padding-left: 7px;"
                                            required
                                            [ngClass]="((!newform.controls.CrimpName.valid) && (popvalidation)) ? 'validation_border': ''">
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <label class="customLabel"> Part Category</label>
                                        </div>
                                        <div class="col-md-7" style="margin-left:18px">
                                            <label class="customLabel"> Company Part Number / Name</label>
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-4 scrollboxleft">
                                            <div *ngFor="let crimp of WordpressCategoryCrimpToolsList; let i = index">
                                                <input formControlName='chkInvCrimp' type="checkbox"
                                                    (click)="chngIsInvCrimp($event.target.checked, i, crimp.id, crimp.partCategoryName)"
                                                    [checked]="WordpressCategoryCrimpToolsList[i].IsChecked">&nbsp;
                                                <label class="customLabel"> {{crimp.partCategoryName}}</label>
                                            </div>
                                        </div>

                                        <div class="col-md-7 scrollboxright">
                                            <div *ngFor="let item1 of CustomerPartNameCrimpList; let i = index">
                                                <input formControlName='chkInv1Crimp' type="checkbox"
                                                    (click)="chngIsInv1Crimp($event.target.checked, i, item1.wordpressId, item1.partCategoryId)"
                                                    [checked]='item1.isActive'>&nbsp;
                                                <label class="customLabel"> {{item1.partNo}} /
                                                    {{item1.partCategoryName}} </label>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row mt-1">

                        <div class="col-md-12">
                            <div class="row">
                                <div class="col-md-12 mt-2">
                                    <div class="form-group">
                                        <span class="customLabel"
                                            style="font-size: 14px;text-decoration: underline;color: #333;">Part
                                            Relationship #3</span>
                                        <input type="text" class="customInput" [(ngModel)]="RelatedName"
                                            name="RelatedName" formControlName='RelatedName' required
                                            [ngClass]="((!newform.controls.RelatedName.valid) && (popvalidation)) ? 'validation_border': ''"
                                            autocomplete="off" style="padding-left: 7px;">

                                    </div>

                                </div>

                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-4">
                                            <label class="customLabel"> Part Category</label>
                                        </div>
                                        <div class="col-md-7" style="margin-left:18px">
                                            <label class="customLabel"> Company Part Number / Name</label>
                                        </div>
                                    </div>

                                </div>


                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-4 scrollboxleft">
                                            <div
                                                *ngFor="let relatedItem of WordpressCategoryRelatedItemList; let i = index">
                                                <input formControlName='chkInvRelated' type="checkbox"
                                                    (click)="chngIsInvRelated($event.target.checked, i, relatedItem.id, relatedItem.partCategoryName)"
                                                    [checked]="WordpressCategoryRelatedItemList[i].IsChecked">&nbsp;
                                                <label class="customLabel"> {{relatedItem.partCategoryName}}</label>

                                            </div>
                                        </div>


                                        <div class="col-md-7 scrollboxright">
                                            <div *ngFor="let item1 of CustomerPartNameRelatedList; let i = index">
                                                <input formControlName='chkInv1Related' type="checkbox"
                                                    (click)="chngIsInv1Related($event.target.checked, i, item1.wordpressId, item1.partCategoryId)"
                                                    [checked]='item1.isActive'>&nbsp;
                                                <label class="customLabel"> {{item1.partNo}} /
                                                    {{item1.partCategoryName}} </label>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-md-9"></div>
                        <div class="col-md-3">
                            <button type="button" class="btn blue-bg round-btn" *ngIf="IsSave"
                                (click)="AddPartialPart()" style="width: 82px; float: right;">Save</button>
                            <button type="button" class="btn blue-bg round-btn" *ngIf="IsUpdate"
                                (click)="UpdatePartialPart()" style="width: 82px; float: right;">Update</button>
                        </div>
                    </div>

                </form>
            </div>

        </div>
    </div>
</div>

<div class="modal" id="history">
    <div class="modal-dialog modal-dialog-centered modal-lg bigmodal" role="document">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title info-popup-title p-1 text-center">Part History Log - Part Name -
                    {{this.PartName}}</h4>
                <button type="button" class="close btn alert_modal" (click)="historyclose()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div style="height:282px; overflow:scroll">
                    <table class="table table-bordered" *ngIf="this.CheckHistoryList" style="margin: 0px 0 20px;"
                        id="fixedTable">
                        <thead>
                            <tr>
                                <th>Field</th>
                                <th>Date</th>
                                <th>Price on Parts Details</th>
                                <th>Updated Price</th>
                                <th>SO/PO Old Price</th>
                                <th>Remark</th>
                            </tr>
                        </thead>
                        <tbody class="customLabel">
                            <tr *ngFor="let data of PartHistoryList">
                                <td>{{data.field}}</td>
                                <td>{{data.date | date: 'MM/dd/yyyy'}}</td>
                                <td>${{data.price }}</td>
                                <td>${{data.updatePrice}}</td>
                                <td>
                                    {{data.oldPrice===0?"N/A": "$" + data.oldPrice}}
                                </td>
                                <td>{{data.remark}}</td>
                            </tr>
                        </tbody>
                    </table>

                    <div *ngIf="!this.CheckHistoryList">
                        <p style="font-size: 12px; text-align: center;">No Part History Found</p>
                    </div>
                </div>
                <div class="submit-from text-right">

                    <button type="button" class="btn darkred-bg round-btn" (click)="historyclose();"><i
                            aria-hidden="true"></i>
                        Exit </button>

                </div>
            </div>


        </div>
    </div>
</div>

<div class="modal" id="vendorModel">
    <div class="modal-dialog  modal-dialog-centered modal-lg bigmodals" role="document">
        <div class="modal-content">
            <h4 class="modal-title info-popup-title p-1 text-center">Additional Vendors for Part:
                ({{this.PartName1}}/{{this.PartNumber1}})
            </h4>
            <button type="button" class="close btn alert_modal" aria-label="Close" (click)="vendorclose()">
                <span aria-hidden="true">&times;</span>
            </button>
            <div class="modal-body">
                <!------------------------------------- part history table ------------------------------>
                <div class="card-desc1">
                    <form [formGroup]="partForm">
                        <div class="row mt-2">

                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-3 trp required">
                                        <label class="customLabel">Vendor</label>
                                    </div>
                                    <div class="col-lg-8 col-md-9">

                                        <select class="customInput" [(ngModel)]="partmodelVendorValue" required
                                            formControlName="partmodelVendorValue"
                                            [ngClass]="((!partForm.controls.partmodelVendorValue.valid) && (partvalidation)) ? 'validation_border': ''">
                                            <option value=''>Select Vendor</option>
                                            <option *ngFor="let item of vendorlist2"
                                                [value]="item.vendorName + '-'+ item.id + '-' + item.discount">
                                                {{item.vendorName}}
                                            </option>
                                        </select>


                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-3 trp" style="display: flex;
                                    justify-content: space-between;">
                                        <label class="customLabel customLabelPartInfo">
                                            Auto Markup<span class="tooltipWrap tooltip-icon" style="display:inline"><i
                                                    class="fa fa-info-circle"></i></span>
                                            <span class="tooltips">
                                                When selected, the Part Price will automatically be calculated
                                                based on the Vendor Cost and the Markup Margin
                                            </span>
                                        </label>

                                        <input type="checkbox" formControlName="PartMarkupCheck"
                                            [(ngModel)]="PartMarkupCheck" (change)="partChangeMargin($event)"
                                            style="top: 2px;">
                                    </div>
                                    <div class="col-lg-8 col-md-9">
                                        <div class="row">

                                            <div class="col-lg-10 col-md-9">
                                                <div class="row">
                                                    <div class="col-lg-6 col-md-6 trp">
                                                        <label class="customLabel customLabelPartInfo">
                                                            Markup Margin

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-6 col-md-6">
                                                        <input type="text" class="customInput"
                                                            [(ngModel)]="PartMarkupMargin" placeholder="Markup Margin"
                                                            name="PartMarkupMargin" formControlName="PartMarkupMargin"
                                                            autocomplete="off" [readonly]="IsPartMarginDisabled"
                                                            [ngClass]="this.PartMarkupCheck == true ? ((!partForm.controls.PartMarkupMargin.valid) && (partvalidation)) ? 'validation_border': '': ''"
                                                            (blur)="UpdatePricePart()"
                                                            (keypress)="keyPressPartNumbers($event)" maxlength="9"
                                                            style="width: 134px;">


                                                    </div>
                                                </div>
                                            </div>
                                        </div>




                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-3 trp required">
                                        <label class="customLabel">Vendor Part Name</label>
                                    </div>
                                    <div class="col-lg-8 col-md-9">
                                        <input type="text" class="customInput" [(ngModel)]="PVendorPartName"
                                            placeholder="$ Enter Vendor Part Name" formControlName='PVendorPartName'
                                            autocomplete="off" required style="padding-left: 6px;"
                                            [ngClass]="((!partForm.controls.PVendorPartName.valid) && (partvalidation)) ? 'validation_border': ''">

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="row mt-2">






                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-3 trp required">
                                        <label class="customLabel">Vendor Part Cost</label>
                                    </div>
                                    <div class="col-lg-8 col-md-9">
                                        <input type="text" class="customInput" [(ngModel)]="PVendorCost"
                                            placeholder="$ Enter Vendor Part Cost" formControlName='PVendorCost'
                                            (blur)="VendorPartCurrency()" autocomplete="off" required maxlength="10"
                                            style="padding-left: 6px;"
                                            [ngClass]="((!partForm.controls.PVendorCost.valid) && (partvalidation)) ? 'validation_border': ''">

                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-3 trp required">
                                        <label class="customLabel">Part Price</label>
                                    </div>
                                    <div class="col-lg-8 col-md-9">
                                        <input type="text" class="customInput Cost" [(ngModel)]="PPartPrice"
                                            placeholder="$ Enter Part Price" formControlName='PPartPrice'
                                            (change)="PartformatCurrency()" (click)="DisablePrice()" autocomplete="off"
                                            required maxlength="12"
                                            [ngClass]="((!partForm.controls.PPartPrice.valid) && (partvalidation)) ? 'validation_border': ''"
                                            [readonly]="IsPPricePart" style="padding-left: 6px;">


                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">
                                <div class="row">
                                    <div class="col-lg-4 col-md-3 trp required">
                                        <label class="customLabel">Vendor Part No.</label>
                                    </div>
                                    <div class="col-lg-8 col-md-9">
                                        <input type="text" class="customInput Cost" [(ngModel)]="PVendorNumber"
                                            placeholder="$ Enter Vendor Part Number" formControlName='PVendorNumber'
                                            autocomplete="off" required
                                            [ngClass]="((!partForm.controls.PVendorNumber.valid) && (partvalidation)) ? 'validation_border': ''"
                                            style="padding-left: 6px;">


                                    </div>
                                </div>
                            </div>

                        </div>

                        <div class="row mt-2">
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1"></div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1"></div>
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-md-1">



                                <button type="button" class="btn blue-bg round-btn" *ngIf="IsPartSave"
                                    (click)="AddVendorPart()" style="float: right">Save</button>
                                <button type="button" class="btn blue-bg round-btn" *ngIf="IsPartUpdate"
                                    (click)="UpdateVendorPart()" style="float: right">Update</button>
                                <!-- <button class="btn darkred-bg round-btn" *ngIf="clearbutton==false"
                                (click)="vendorclose()"><i class="fas fa" aria-hidden="true"></i>
                                Exit </button> -->
                            </div>
                        </div>

                    </form>
                    <hr style="margin-bottom: 0;" />
                    <div class="card-desc1 mt-1  table-responsive">
                        <table class="display table clearfix">
                            <thead>
                                <tr>

                                    <th>vendor Name</th>
                                    <th>Vendor Part Name</th>
                                    <th>Vendor Part Number</th>
                                    <th>Vendor Part Cost</th>
                                    <th>Vendor Addt'l Discount</th>
                                    <th>Auto-Markup</th>
                                    <th>MarkUp Margin</th>
                                    <th>Part Price</th>
                                    <th data-orderable="false">Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let data of AddvendorList">
                                    <tr *ngIf="data.IsActive == true">
                                        <td title={{data.vendorName}}>
                                            {{data.vendorName.length <= 25 ? data.vendorName : SortString(data.vendorName)}}
                                        </td>
                                        <td title={{data.vendorPartName}}>
                                            {{data.vendorPartName == null ? '' : (data.vendorPartName.length <= 25 ? data.vendorPartName : SortString(data.vendorPartName))}}
                                        </td>
                                        <td title={{data.vendorPartNumber}}>
                                            {{data.vendorPartNumber == null ? '' : (data.vendorPartNumber.length <= 25 ? data.vendorPartNumber : SortString(data.vendorPartNumber))}}
                                        </td>
                                        <td>{{data.vendorPartCost |number: '1.2-2' | currency}}</td>
                                        <td>{{data.Discount}}</td>
                                        <td>{{data.markupCheck}}</td>
                                        <td>{{data.markupMargin}}</td>
                                        <td>{{data.cost |number: '1.2-2'| currency}}</td>
                                        <td>
                                            <a class="green-text"
                                                *ngIf="rolePermissionsList && rolePermissionsList[0].isUpdate"><i
                                                    class="fas fa-edit" (click)="VendorPartEdit(data)"></i></a>
                                            <a class="red-text"
                                                *ngIf="rolePermissionsList && rolePermissionsList[0].isDelete"><i
                                                    class="fas fa-trash-alt" (click)="deletevendorPart(data)"></i></a>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                            <tbody *ngIf="AddvendorList==null">
                                <tr class="c1">
                                    <td colspan="9" class="no-data-available">No data available in table</td>
                                </tr>
                            </tbody>
                        </table>



                        <div class="submit-from text-right">
                            <!-- <button class="btn blue-bg round-btn" (click)="addPart(partObjectModel)"><i class="fas fa-save"
                                aria-hidden="true"></i> Add Part
                        </button> -->
                            <button type="button" class="btn darkred-bg round-btn" (click)="vendorclose();"><i
                                    aria-hidden="true"></i>
                                Exit </button>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="PartNameModel" style="z-index: 1080">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="PartNameModel()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>{{this.partNameProperty}}</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="PartNameModel()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="AlreadyVendor" style="z-index: 1080">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="AlreadyVendor()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>{{this.AlreadyVendorName}}</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="AlreadyVendor()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="deleteVendorlist" style="z-index: 1080">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="DeleteVendor()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/popup-alert.png" alt="">
                    <h1>Confirmation</h1>
                    <p>{{deleteDetailVendor}}</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn green-bg round-btn m-0 mr-1"
                    (click)="deletePartVendorList(PartdeletedItem);">Yes</button>
                <button type="button" class="btn red-bg round-btn m-0" (click)="DeleteVendor()">No</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="SaveModel" style="z-index: 1080">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="SavePopModel()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You!</h1>
                    <p>{{this.SaveModel}}</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="SavePopModel()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="NultiCategoryModel" style="z-index: 1080">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="NultiCategoryModel()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>Please Select atleast one Part Category</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="NultiCategoryModel()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="DisableModel">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="DisableModel()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>Please Enter MarkUp Margin</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="DisableModel()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="msgModel404">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="msg404()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p style="margin-bottom: 0">Something went wrong</p>
                    <p>May be! WooCommerce API is not working</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="msg404()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="InventoryExist">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="InventoryExist()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p style="margin-bottom: 0">Part's Inventory Already Exist !</p>
                    <p>Do you want to make this part as Non-Inventory? Please Remove the Inventory!</p>

                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn green-bg round-btn" (click)="RemoveInventory()">Yes</button>
                <button type="button" class="btn red-bg round-btn" (click)="InventoryExist()"
                    data-dismiss="modal">No</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="InventoryRemove">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="InventoryRemove()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/Green-Round-Tick.png" alt="">
                    <h1>Thank You</h1>
                    <p style="margin-bottom: 0">Part's Inventory Remove Successfully</p>


                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">

                <button type="button" class="btn red-bg round-btn" (click)="InventoryRemove()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="InvExist">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="InvExist()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p style="margin-bottom: 0">Part is Non-Inventory! You can not add the inventory on this Part </p>

                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">

                <button type="button" class="btn red-bg round-btn" (click)="InvExist()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="AlreadyVendorExist">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="AlreadyVendorExist()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p style="margin-bottom: 0">Primary Vendor '{{vendorText}}' already added to Additional Vendors</p>
                    <p>Please choose another primary vendor</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">

                <button type="button" class="btn red-bg round-btn" (click)="AlreadyVendorExist()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="InvExist1">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="InvExist1()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p style="margin-bottom: 0">{{InvProp}}</p>


                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">

                <button type="button" class="btn red-bg round-btn" (click)="InvExist1()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="notdelete_success">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="notdelete_success()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>{{deleteUnsuccessMsg}}</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="notdelete_success()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>
<div class="modal" id="commomModel">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="commomModel()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>Please Select Company</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="commomModel()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>

<div class="modal" id="DiscountModel">
    <div class="modal-dialog">
        <div class="modal-content">
            <div class="modal-header1 darkred-bg sweet-heading p-0">
                <h4 class="modal-title sweet-heading"></h4>
                <button type="button" class="close btn alert_modal" (click)="CloseDiscountModel()"
                    data-dismiss="modal">&times;</button>
            </div>
            <div class="modal-body p-1">
                <div class="thank-you-pop">
                    <img src="./assets/image/close.png" alt="">
                    <h1>Alert</h1>
                    <p>{{DiscountModel}}</p>
                </div>
            </div>
            <div class="modal-footer sweet-footer p-1">
                <button type="button" class="btn red-bg round-btn" (click)="CloseDiscountModel()"
                    data-dismiss="modal">Close</button>
            </div>
        </div>
    </div>
</div>