import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { ConstantsService } from 'src/app/constants.service';
import { CompanyService } from 'src/app/services/company/company.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-company-list',
  templateUrl: './company-list.component.html',
  styleUrls: ['./company-list.component.css']
})
export class CompanyListComponent implements AfterViewInit, OnDestroy, OnInit {

  companyId: any;
  data: any;
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  companyList: any;
  rolePermissionsList: any;
  settingDetailCSS: any;
  settingLogoImage: any;
  defaultImageLogo: any;
  deletedItem: string;
  loader: boolean = false;
  deleteDetail: string;
  permissionMessage = '';
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;
  constructor(
    public sharedService: SharedService,
    private toastr: ToastrService,
    private modalService: NgbModal,
    private constants: ConstantsService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private companyService: CompanyService
  ) { }

  ngOnInit(): void {
    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);
    } else {
      this.defaultImageLogo = this.constants.defaultImageLogo;
      this.rolePermissionsList = JSON.parse(localStorage.getItem('rolePermissionsList'));
      this.rolePermissionsList = this.rolePermissionsList.filter(x => x.pageId == 2);
      this.permissionMessage = '';
      if (this.rolePermissionsList != null && this.rolePermissionsList != undefined) {
        if (!this.rolePermissionsList[0].isView) {
          this.permissionMessage += 'view';
        } if (!this.rolePermissionsList[0].isInsert) {
          if (this.permissionMessage != '') {
            this.permissionMessage += ', ';
          }
          this.permissionMessage += 'add';
        } if (!this.rolePermissionsList[0].isUpdate) {
          if (this.permissionMessage != '') {
            this.permissionMessage += ', ';
          }
          this.permissionMessage += 'edit';
        } if (!this.rolePermissionsList[0].isDelete) {
          if (this.permissionMessage != '') {
            this.permissionMessage += ', ';
          }
          this.permissionMessage += 'delete';
        }
      }
      if (this.permissionMessage != '') {
        this.permissionMessage = 'You do not have access to ' + this.permissionMessage + '. Please contact to administrator!';
      }
      this.companyId = localStorage.getItem(this.constants.companyId);
      this.settingDetailCSS = localStorage.getItem("settingDetailCSS");
      this.settingLogoImage = localStorage.getItem("settingImageLogo");
      this.SetSettingCSS(this.settingDetailCSS, this.settingLogoImage);
      this.GetAllCompany();
      this.dtOptions = {
        pagingType: this.constants.full_numbers,
        pageLength: 10
      };
    }
  }
  GetAllCompany() {
    this.loader = true;
    this.companyService.GetAllCompany(this.companyId).subscribe(
      data => {
        
        this.data = data;
        if (this.data.dataList != null && this.data.dataList != undefined) {
          //this.companyList = this.data.dataList.filter(x => x.parentId == null);
          this.companyList = this.data.dataList;
        }
        this.loader = false;
        this.rerender();
      }
    )
  }

  SetSettingCSS(settingCSS, logoImagePath) {
    if (settingCSS != null) {
      let headerCss = document.getElementsByClassName('header-main') as HTMLCollectionOf<HTMLElement>;
      if (headerCss.length != 0) {
        headerCss[0].style.backgroundColor = settingCSS.split(',')[0];
      }
      let saveButtonCss = document.getElementsByClassName('btn blue-bg') as HTMLCollectionOf<HTMLElement>;
      if (saveButtonCss.length != 0) {
        saveButtonCss[0].style.backgroundColor = settingCSS.split(',')[1];
      }
      let cencelButtonCss = document.getElementsByClassName('btn darkred-bg') as HTMLCollectionOf<HTMLElement>;
      if (cencelButtonCss.length != 0) {
        cencelButtonCss[0].style.backgroundColor = settingCSS.split(',')[2];
      }
    }
    if (logoImagePath != null && logoImagePath != "") {
      (document.getElementById('hrdlogoImage') as HTMLImageElement).src = logoImagePath;
    }
  }
  sanitizeImageUrl(imageUrl: string): SafeUrl {
    return this.sanitizer.bypassSecurityTrustUrl(imageUrl);
  }

  deletePopUp(item, content) {
    this.deletedItem = '';
    this.modalService.open(content, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
    this.deleteDetail = 'Do you want to delete ' + item.companyName + '?';
    this.deletedItem = item;
  }

  deleteCompany(item: any) {
    let obj = {}
    obj = item;
    obj['companyHierarchyId'] = item.companyHierarchyId == null ? null : String(item.companyHierarchyId);
    this.companyService.deleteCompanyHierarchy(obj).subscribe(
      data => {
        this.loader = true;
        this.data = data;
        switch (data.statusCode) {
          case 200:
            $("#delete_success").show();
            // this.toastr.success(this.constants.RecordDeletedSuccessfully);
            this.GetAllCompany();
            this.rerender();
            break;
          case 400:
            $("#wrong_msg").show();
            // this.toastr.error(this.constants.SomethingWentWrong);
            break;
          default:
            $("#wrong_msg").show();
          // this.toastr.error(this.constants.SomethingWentWrong);
        }
        this.loader = false;
      });
  }
  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next('');
    });
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next('');
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  addsuccess() {
    $("#add_success").hide();
  }
  updateitem() {
    $("#add_update").hide();
  }
  deleteitem() {
    $("#delete_success").hide();
  }
  msgitem() {
    $("#wrong_msg").hide();
  }

}
