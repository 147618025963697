import { Injectable } from '@angular/core';
import { Observable, throwError, of, BehaviorSubject, Subject } from 'rxjs';
import { tap, delay, catchError } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TitleCasePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class HelcimService {
  baseUrl = environment.baseUrl;

  constructor(
    private _http: HttpClient,
    public titlecasePipe: TitleCasePipe,
    private _sanitizer: DomSanitizer,
  ) {
  }


  CreateHelcimCheckoutSession(value: object): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Order/AddHelcimCheckoutSession', value,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  AddHelcimTransaction(value: object, orderId: number, helcimAmount: string): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Order/AddHelcimTransaction?orderId='+orderId+"&helcimAmount="+helcimAmount, value,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  CompleteHelcimPayment(transactionId: any, orderId: number):Observable<any>{
    return this._http.post(this.baseUrl+ 'api/Order/AddCompleteHelcimPayment?transactionId='+transactionId+"&orderId="+orderId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  
  errorHandler(error: HttpErrorResponse) {
    // this.loader = false;
    return throwError(error.message || "server error.");
  }


}

