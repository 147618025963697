import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { CustomerService } from 'src/app/services/customer/customer.service';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-po-shipto',
  templateUrl: './po-shipto.component.html',
  styleUrls: ['./po-shipto.component.css', '../purchaseorder-details/po-detail/po-detail.component.css']
})
export class PoShiptoComponent implements OnInit {
  @Input() formGrp: FormGroup; 
  @Input() companyId: number;
  @Input() isShipTo: boolean = true;
  @Input() ShipTo: any;
  @Input() customerList: any;
  @Output() shiptoEvent = new EventEmitter<any>();
  @Output() customerEvent = new EventEmitter<any>();

  stateList: any = [];
  countryList: any = [];
  filteredData:any[] = [];

  constructor(private sharedService: SharedService, private customerService: CustomerService){
    this.getCountryList();
    this.getStateList();
  }

  ngOnInit(): void {
  }

  getStateList() {
    this.sharedService.getAllState(0).subscribe((data: any) => {
      if (data.dataList.length > 0) {
        this.stateList = data.dataList;
      }
    });
  }

  GetStateName(id: any) {
    if (this.stateList.length > 0 && id > 0) {
      const list = this.stateList.filter(x => x.id == Number(id));
      if (list.length > 0) {
        if(list[0].abbreviation.trim().length>0){
          return list[0].abbreviation;
        }else{
          return list[0].name.trim();
        }
      }
    }
    return '';
  }

  getCountryList() {
    this.sharedService.GetCountry().subscribe((data: any) => {
      if (data.dataList.length > 0) {
        this.countryList = data.dataList;
      }
    });
  }

  GetCountryName(id: any) {
    if (this.countryList.length > 0 && id > 0) {
      const list = this.countryList.filter(x => x.id == Number(id));
      if (list.length > 0) {
        if(list[0].countryCode.trim().length>0){
          return list[0].countryCode.trim();
        }else{
          return list[0].name.trim();
        }
      }
    }
    return '';
  }

  changeShipto(value: string) {
    this.shiptoEvent.emit(value);
  }
  changeDDLCustomer(value: string) {
    this.customerEvent.emit(value);
  }
  
  onInputCustomer(value: string){
    this.filteredData = [];
    if(this.companyId>0 && this.companyId != null && this.companyId != undefined){
      this.customerService.GetAllCustomer(this.companyId, value).subscribe(
        (data: any) => {
            this.filteredData = data.dataList.filter(option =>
              option.customerName.toLowerCase().includes(value.toLowerCase())
            )
        }
      )
    }
  }
}
