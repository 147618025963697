export class PutAway {
    Id:number;
    partId: number;
    wareHouse: number;
    locName: string;
    aisle: string;
    section: string;
    shelf: string;
    bin: string;
    onhand: number;
    moveQty: number;
    totalQty: number;
    putAwayQty: number;
    locCapacity: number;
    transId: string;
    sku: string;
    address: string;
    imagePath: string;
    tracking: any;
    invType: number;
    description: string;
    trackingId: number;
}


export class Replenishment {
    partId: number;
    wareHouseId: number;
    locationId: number;
    locationCapacity: number;
    level1: string;
    level2: string;
    level3: string;
    level4: string;
    onHand: number;
    sku: string;
    imgPath: string;
}