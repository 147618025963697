import { Component } from '@angular/core';

@Component({
  selector: 'app-warehouse-setup',
  templateUrl: './warehouse-setup.component.html',
  styleUrls: ['./warehouse-setup.component.css']
})
export class WarehouseSetupComponent {

}
