<section class="content-section-2">
    <form [formGroup]="masterForm">
        <div class="loading lodingtext" *ngIf="loader"></div>
        <!-- --------------top bredcumps css here --------- -->
        <div class="top-bredcumps">
            <div class="cstmbreadcum paddingmng-right">
                <div class="page_title_cstms">
                    <ul class="link_current">
                        <li>
                            <a type="button" (click)="backToList()" class="backbtns">
                                <mat-icon>keyboard_arrow_left</mat-icon>
                                <span>Back to List</span>
                            </a>
                        </li>
                        <li>
                            <span>Receiving Details</span>
                        </li>
                    </ul>
                </div>
                <div class="top-button d-flex justify-content-between align-items-center gap-3">
                    <button class="cancel-btn update-btn" (click)="Cancel()" [disabled]="masterForm.value.status == 24">
                        Cancel
                    </button>
                    <button class="save-btn update-btn" (click)="AddUpdatePO(true)"
                        [disabled]="masterForm.value.status == 24">
                        Receive Shipment
                    </button>
                    <button class="save-btn update-btn" type="submit" (click)="AddUpdatePO(false)"
                        [disabled]="masterForm.value.status == 24">
                        Save
                    </button>
                </div>
            </div>
        </div>
        <!-- --------------top bredcumps css end here --------- -->
        <!-- maon section start here--------------------- -->
        <div class="content-section-1">
            <div class="container-fluid mt-30">
                <div class="main-section">
                    <div class="row">
                        <div class="col-12">
                            <!-- -------------top cards section------------ -->
                            <div class="row top-section">
                                <!-- for vendor -->
                                <div class="col-xl-4 col-md-6 col-12 mb-3 first-card column-width"
                                    formGroupName="vendor">
                                    <app-vendor-card [companyId]="companyId" [formGrp]="masterForm"></app-vendor-card>
                                </div>
                                <!-- for shipto -->
                                <div class="col-xl-4 col-md-6 col-12 mb-3 second-card column-width">
                                    <app-po-shipto [companyId]="companyId" [formGrp]="masterForm"></app-po-shipto>
                                </div>
                                <div
                                    class="col-xl-4 col-md-12 col-12 mb-3 fourth-card mat-header-change column-width-full">
                                    <app-po-orderdetials-card [formGrp]="masterForm" [companyId]="companyId"
                                        [statuslist]="statuslist"
                                        (historyEvent)="showHistoryFlyOut()"></app-po-orderdetials-card>
                                </div>
                            </div>
                            <!-- ---------------receiving location section----------------------- -->
                            <div class="receiving-location-section mt-4 mb-2">
                                <div class="row">
                                    <div class="col-md-8 col-12 d-flex align-items-center gap-3">
                                        <div class="receiving-heading">
                                            <span class="main-heading">Receiving Location: </span>
                                        </div>
                                        <div class="receiving-number">
                                            <span class="sub-heading">
                                                <a type="button" (click)="openLocationModel()" class="mr-3 d-flex">
                                                    <span>{{
                                                        getWareHouseValue(
                                                        masterForm.get("wareHouse").value
                                                        )
                                                        }}</span>,&nbsp;
                                                    <span>{{
                                                        getLocationValue(
                                                        masterForm.get("locationName").value
                                                        )
                                                        }}</span>,&nbsp;
                                                    <span>{{ masterForm.get("aisle").value }}-{{
                                                        masterForm.get("section").value
                                                        }}-{{ masterForm.get("shelf").value }}-{{
                                                        masterForm.get("bin").value
                                                        }}</span>
                                                </a>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="col-md-4 col-12">
                                        <div class="collapse-section d-flex justify-content-end gap-3">
                                            <a type="button" class="collapse-heading"
                                                (click)="onToggleAll(false)">Collapse All</a>
                                            <a type="button" class="expend-heading" (click)="onToggleAll(true)">Expand
                                                All</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- ------------table section of receiving details ---------- -->
                            <div class="card-desc example-container cstm_scrollbar_theme company-table">
                                <ul class="heading-section">
                                    <li class="menu-section text-center">Menu</li>
                                    <li class="item-section">Item</li>
                                    <li class="w-8">
                                        Vendor <br />
                                        Part Number
                                    </li>
                                    <li class="w-8">
                                        Company<br />
                                        Part Number
                                    </li>
                                    <li class="w-30 text-start">Description</li>
                                    <li class="w-5">UOM</li>
                                    <li class="w-5">Ordered</li>
                                    <li class="w-5">Received</li>
                                    <li class="w-10">Receiving</li>
                                    <li class="w-4 receive-all-btn">
                                        <span *ngIf="masterForm.value.status !== 24">
                                            <a type="button" (click)="onRecieveAll()">Receive All</a>
                                        </span>
                                        <span *ngIf="masterForm.value.status === 24">
                                            Receive All
                                        </span>
                                    </li>
                                    <li class="w-5">Status</li>
                                    <li class="w-5">Tracking</li>
                                </ul>
                                <div formArrayName="summaryItems" class=""
                                    *ngFor="let item of masterForm.get('summaryItems')['controls']; let i = index">
                                    <div [formGroupName]="i">
                                        <ul class="d-flex w-100 list-unstyled py-2 value-section">
                                            <li class="d-flex align-items-center" data-label="Menu">
                                                <button mat-icon-button [matMenuTriggerFor]="menu"
                                                    aria-label="More Options">
                                                    <mat-icon>more_vert</mat-icon>
                                                </button>
                                            </li>
                                            <li class="d-flex item-section" data-label="Item">
                                                <label>{{ i + 1 }}</label>
                                                <span class="collapse-icon"
                                                    *ngIf="masterForm.value.summaryItems[i] .receivedetailList.length > 0">
                                                    <mat-icon
                                                        (click)="masterForm.value.summaryItems[i].isShow = !masterForm.value.summaryItems[i].isShow">
                                                        {{
                                                        masterForm.value.summaryItems[i].isShow ===
                                                        true
                                                        ? "expand_more"
                                                        : "keyboard_arrow_right"
                                                        }}
                                                    </mat-icon>
                                                </span>
                                            </li>
                                            <li class="w-8 text-break" data-label="Vendor Part Number">
                                                {{
                                                masterForm.value.summaryItems[i].vendorPartNumber
                                                }}
                                            </li>
                                            <li class="w-8 text-break" data-label="Company Part Number">
                                                {{ masterForm.value.summaryItems[i].partNo }}
                                            </li>
                                            <li class="w-30 text-start" data-label="Description">
                                                {{
                                                masterForm.value.summaryItems[i].vendorPartName
                                                }}
                                            </li>
                                            <li class="w-5" data-label="UOM">
                                                <small>{{
                                                    masterForm.value.summaryItems[i]
                                                    .unitofMeasureId == undefined ||
                                                    masterForm.value.summaryItems[i]
                                                    .unitofMeasureId == ""
                                                    ? "NA"
                                                    : masterForm.value.summaryItems[i]
                                                    .unitofMeasureId
                                                    }}</small>
                                            </li>
                                            <li class="w-5" data-label="Ordered">
                                                {{ masterForm.value.summaryItems[i].quantity }}
                                            </li>
                                            <li class="w-5" data-label="Received">
                                                {{ masterForm.value.summaryItems[i].received }}
                                            </li>
                                            <li class="w-10" data-label="Receiving">
                                                <div *ngIf="masterForm.value.summaryItems[i].quantity == masterForm.value.summaryItems[i].received"
                                                    class="d">
                                                    <span>-</span>
                                                </div>
                                                <div *ngIf="masterForm.value.summaryItems[i].quantity !=masterForm.value.summaryItems[i].received"
                                                    class="receiving-section quantity-div">
                                                    <mat-form-field class="w-75 text-right" appearance="outline">
                                                        <input matInput formControlName="receiving"
                                                            (input)="onRecieving(i)" placeholder="Receiving" />
                                                    </mat-form-field>
                                                </div>
                                            </li>
                                            <li class="w-5" data-label="Receive All">
                                                <div
                                                    *ngIf="masterForm.value.summaryItems[i].quantity ==masterForm.value.summaryItems[i].received">
                                                    <span>-</span>
                                                </div>
                                                <div
                                                    *ngIf="masterForm.value.summaryItems[i].quantity !=masterForm.value.summaryItems[i].received">
                                                    <mat-checkbox formControlName="isAllRecieved"
                                                        (change)="onIsRecieved(item)"></mat-checkbox>
                                                </div>
                                            </li>
                                            <li class="w-5" data-label="Status">
                                                {{
                                                getPOPartStatusKey(
                                                masterForm.value.summaryItems[i].status
                                                )
                                                }}
                                            </li>
                                            <li class="w-5" data-label="Tracking">
                                                <div
                                                    *ngIf=" masterForm.value.summaryItems[i].tracking ==1 ||masterForm.value.summaryItems[i].tracking ==2;then thenBlock;else elseBlock">
                                                </div>
                                                <ng-template #thenBlock>
                                                    <div
                                                        *ngIf="masterForm.value.summaryItems[i].quantity ==masterForm.value.summaryItems[i].received">
                                                        <span>
                                                            {{
                                                            GetTrackingLabel(
                                                            masterForm.value.summaryItems[i].tracking
                                                            )
                                                            }}
                                                        </span>
                                                    </div>
                                                    <div
                                                        *ngIf="masterForm.value.summaryItems[i].quantity !=masterForm.value.summaryItems[i].received">
                                                        <a type="button" (click)="getSerialLotFlyout(item, i)"
                                                            [style.color]="'#007bffc9'">
                                                            {{
                                                            GetTrackingLabel(
                                                            masterForm.value.summaryItems[i].tracking
                                                            )
                                                            }}
                                                        </a>
                                                    </div>
                                                </ng-template>
                                                <ng-template #elseBlock>
                                                    <span>
                                                        {{
                                                        GetTrackingLabel(
                                                        masterForm.value.summaryItems[i].tracking
                                                        )
                                                        }}
                                                    </span>
                                                </ng-template>
                                            </li>
                                        </ul>
                                        <ng-container *ngIf="masterForm.value.summaryItems[i].isShow">
                                            <ul class="d-flex w-100 list-unstyled py-2 value-section"
                                                *ngFor="let item of masterForm.value.summaryItems[i] .receivedetailList; let j = index">
                                                <li class="empty-li"></li>
                                                <li class="empty-li"></li>
                                                <li class="w-8"></li>
                                                <li class="w-8"></li>
                                                <li class="w-30"></li>
                                                <li class="w-5"></li>
                                                <li class="w-5">
                                                    {{
                                                    GetTrackingLabel(
                                                    masterForm.value.summaryItems[i].tracking
                                                    )
                                                    }}’s:
                                                </li>
                                                <li class="w-5">
                                                    {{ item.serialLot }}
                                                </li>
                                                <li class="w-5">
                                                    {{ item.received }}
                                                </li>
                                                <li class="w-8">
                                                    Received By:
                                                    <span class="receiver-name">{{
                                                        item.receiver
                                                        }}</span>
                                                </li>
                                                <li class="w-5">
                                                    {{
                                                    getPOPartStatusKey(
                                                    masterForm.value.summaryItems[i].status
                                                    )
                                                    }}
                                                </li>
                                                <li class="w-5">
                                                    {{ item.receivedDate | date : "shortDate" }}
                                                </li>
                                            </ul>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- Serial Flyout Start-->
                        <div class="Over-Manual-flyout" *ngIf="isSerialFlyout"></div>
                        <div class="serial-flyout" *ngIf="isSerialFlyout">
                            <app-serial-lot-flyout [seriallotflyoutObj]="seriallotflyoutObj"
                                (closeSerialLotFlyoutEvent)="closeSerialLotFlyout($event)"></app-serial-lot-flyout>
                        </div>
                        <!-- Serial Flyout End -->
                    </div>
                </div>
            </div>
        </div>
        <!-- </ng-container> -->
    </form>

    <!-- Location Modal Start -->
    <div class="modal" id="LocationModal">
        <div class="modal-dialog">
            <div class="modal-content change-backg">
                <!-- Modal Header -->
                <div class="modal-header flex-column">
                    <div class="row">
                        <div class="col-12">
                            <h2>Receiving Location</h2>
                            <span>Please edit the Receiving Location for Shipment.</span>
                        </div>
                    </div>
                </div>
                <div class="modal-body pt-0 select-arrow-change-icon">
                    <div class="row mt-2">
                        <div class="col-6 first-card">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Warehouse</mat-label>
                                <mat-select formControlName="wareHouse" placeholder="Warehouse"
                                    (selectionChange)="onWareHouseChange($event.value)">
                                    <mat-option *ngFor="let item of CompanyWareHouseList" [value]="item.id">{{ item.name
                                        }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-6 fourth-card">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Location Name</mat-label>
                                <mat-select formControlName="locationName" placeholder="Location Name"
                                    (selectionChange)="locNameChange($event.value)">
                                    <mat-option *ngFor="let item of RecLocationList" [value]="item.id">{{
                                        item.locationName }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                    <div class="row mt-2">
                        <div class="col-md-3 col-6 mb-lg-0 mb-3 first-card">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>{{ seletedLocation.nameOfLevels1 }}</mat-label>
                                <mat-select formControlName="aisle" placeholder="Aisle"
                                    (selectionChange)="onloclevelChange(1, $event.value)">
                                    <mat-option *ngFor="let item of level1List" [value]="item">{{
                                        item
                                        }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3 col-6 mb-lg-0 mb-3 second-card">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>{{ seletedLocation.nameOfLevels2 }}</mat-label>
                                <mat-select formControlName="section" placeholder="Section"
                                    (selectionChange)="onloclevelChange(2, $event.value)">
                                    <mat-option *ngFor="let item of level2List" [value]="item">{{
                                        item
                                        }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3 col-6 mb-lg-0 mb-3 second-card third-card">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>{{ seletedLocation.nameOfLevels3 }}</mat-label>
                                <mat-select formControlName="shelf" placeholder="Shelf"
                                    (selectionChange)="onloclevelChange(3, $event.value)">
                                    <mat-option *ngFor="let item of level3List" [value]="item">{{
                                        item
                                        }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                        <div class="col-md-3 col-6 mb-lg-0 mb-3 fourth-card">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>{{ seletedLocation.nameOfLevels4 }}</mat-label>
                                <mat-select formControlName="bin" placeholder="Bin"
                                    (selectionChange)="onloclevelChange(4, $event.value)">
                                    <mat-option *ngFor="let item of level4List" [value]="item">{{
                                        item
                                        }}</mat-option>
                                </mat-select>
                            </mat-form-field>
                        </div>
                    </div>
                </div>
                <!-- Modal footer -->
                <div class="modal-footer border-0">
                    <div class="row mt-2">
                        <button type="button" class="cancel-button" (click)="closeLocationModel()">
                            Cancel
                        </button>
                        <button type="button" class="saveclosebnt" (click)="closeLocationModel()">
                            Save
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Location Modal End -->
</section>

<!-- Scanner work -->
<!-- <div class="zxing-scanner" *ngIf="IsScan">
    <zxing-scanner (camerasNotFound)="camerasNotFound($event)" [autofocusEnabled]="autofocusEnabled"
        (scanSuccess)="onCodeResult($event)"></zxing-scanner>
</div> -->
<!-- Scanner work -->

<!--Selector for PO View History-->
<app-po-history [IsHistoryFlyOut]="IsHistoryFlyOut" [logList]="logList" (HistoryFlyOut)="hideHistoryFlyOut($event)"
    (GeneratPdfEmit)="GeneratePOPdf($event)"></app-po-history>