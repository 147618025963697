<section class="content-section pt-2">

    <div class="loading" *ngIf="loader">Loading&#8230;</div>
    <div col-sm-12 *ngIf="permissionMessage!=''">
        <div class="row">
            <div class="col-6 mb-2 d-flex align-items-baseline text-xs-left">
                <div class="page-title">
                    <div class="bread-crumb">
                        <div class='btn-danger text-center'>{{permissionMessage}} </div>
                    </div>
                </div>
            </div>
            <div class="col-6 mb-2 text-right align-items-baseline">
            </div>
        </div>
    </div>
    <ng-container *ngIf="IsNotTemplateId==true">
        <div class="row">
            <div class="col-sm-4">
                <div class="white-box p-tb-40">
                    <div class="row align-items-center">
                        <div class="col-8">
                            <div class="blue-text lg-text mb-1">
                                {{(this.getAllDashBoardDetail[0]?.orders==0
                                ||
                                this.getAllDashBoardDetail[0]?.orders==null||this.getAllDashBoardDetail[0]?.orders==undefined)
                                ? 0 : this.getAllDashBoardDetail[0]?.orders}}
                            </div>
                            <div>Total Orders</div>
                        </div>
                        <div class="col-4 text-right">
                            <div class="blue-bg card-icon"><i class="fas fa-luggage-cart"></i></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="white-box p-tb-40">
                    <div class="row align-items-center">
                        <div class="col-8">
                            <div class="yellow-text lg-text mb-1">
                                {{(this.getAllDashBoardDetail[0]?.products==0
                                ||
                                this.getAllDashBoardDetail[0]?.products==null||this.getAllDashBoardDetail[0]?.products==undefined)
                                ? 0 : this.getAllDashBoardDetail[0]?.products}}
                            </div>
                            <div>Total Products</div>
                        </div>
                        <div class="col-4 text-right">
                            <div class="yellow-bg card-icon"><i class="fas fa-luggage-cart"></i></div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <div class="white-box p-tb-40">
                    <div class="row align-items-center">
                        <div class="col-8">
                            <div class="red-text lg-text mb-1">{{(this.getAllDashBoardDetail[0]?.customers==0
                                ||
                                this.getAllDashBoardDetail[0]?.customers==null||this.getAllDashBoardDetail[0]?.customers==undefined)
                                ? 0 : this.getAllDashBoardDetail[0]?.customers}}
                            </div>
                            <div>Active Customers</div>
                        </div>
                        <div class="col-4 text-right">
                            <div class="red-bg card-icon"><i class="fas fa-luggage-cart"></i></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-12">
                <div class="white-box">
                    <div class="white-box-title">
                        <h5>Product Sales</h5>
                    </div>
                    <div class="card-desc">
                        <img src="/assets/image/chart.png">
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="IsTemplateId==true">
        <div class="row">
            <div class="col-sm-12 pl-0">
                <div class="pt-3 pl-3 pr-3">
                    <div class="row">
                        <div class="col-sm-9 mb-3">
                            <div class="row">
                                <ng-container *ngIf="widgetData1.length; else noW1">
                                    <div class="col-sm-12 col-md-6 col-lg-3 mb-4" *ngFor="let temp of widgetData1">
                                        <a class="d-box-item" (click)="redirect(temp.url)">
                                            <div class="inner color-white">
                                                <i class="fa fa-area-chart" aria-hidden="true"></i>
                                                <h5 class="flip-box-header">{{temp.widgetname}}</h5>
                                                <h6 class="text-center mb-0 count-value">
                                                    <span class="WidgetAnchor">{{temp.wcount}}</span>
                                                </h6>
                                            </div>
                                        </a>
                                    </div>
                                </ng-container>

                                <ng-template #noW1>
                                    <div class="col-sm-12 col-md-6 col-lg-3 mb-4" *ngFor="let w1 of [1,2,3,4]">
                                        <a class="d-box-item" (click)="redirect(temp.url)">
                                            <div class="inner color-white">
                                                <h5 class="flip-box-header">Space widget type 1</h5>
                                            </div>
                                        </a>
                                    </div>
                                </ng-template>
                            </div>
                        </div>

                        <ng-container *ngIf="widgetData3.length; else noAlerts">
                            <div class="col-sm-3" *ngFor="let alertitem of widgetData3">
                                <h5 class="alert-notification"> <i class="fa fa-bell" aria-hidden="true"></i>
                                    {{alertitem.widgetname}}
                                </h5>
                            </div>
                        </ng-container>

                        <ng-template #noAlerts>
                            <div class="col-sm-3">
                                <h5 class="alert-notification_blank">
                                    No alert
                                </h5>
                            </div>
                        </ng-template>

                    </div>
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="row">
                                <ng-container *ngIf="widgetData2.length; else noTraining">
                                    <div class="col-sm-3" *ngFor="let traing of widgetData2">
                                        <a (click)="redirect(traing.url)">
                                            <div class="producation-order button-training">
                                                <h6 class="text-center mb-0">
                                                    <a (click)="redirect(traing.url)">
                                                        {{traing.widgetname}}&nbsp;({{traing.wcount}})
                                                    </a>
                                                </h6>
                                            </div>
                                        </a>
                                    </div>
                                </ng-container>

                                <ng-template #noTraining>
                                    <div class="col-sm-3" *ngFor="let traing of [1,2,3,4]">
                                        <div class="producation-order button-training_blank">
                                            <h6 class="text-center mb-0">
                                                Space widget type 2
                                            </h6>
                                        </div>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                        <div class="col-sm-12">
                            <div class="row">
                                <ng-container *ngIf="widgetData4.length; else noNews">
                                    <div class="col-sm-5" *ngFor="let new of widgetData4">
                                        <div class="newes-section cust-card" [innerHtml]="new.newsContent">
                                        </div>
                                    </div>
                                </ng-container>

                                <ng-template #noNews>
                                    <div class="col-sm-5">
                                        <div class="newes-section cust-card_blank">
                                            No news
                                        </div>
                                    </div>
                                </ng-template>

                                <ng-container *ngIf="widgetData5.length; else noVideo">
                                    <div class="col-sm-7" *ngFor="let ved of widgetData5">
                                        <div class="video-image-section cust-card"
                                            [style.display]="!ved.url ? 'none' : 'block'">
                                            <iframe [src]='this._sanitizer.bypassSecurityTrustResourceUrl(ved.url)'
                                                style="width:100%;height:100%;" frameborder="0"
                                                allow="autoplay; fullscreen; picture-in-picture"
                                                allowfullscreen></iframe>
                                        </div>
                                    </div>
                                </ng-container>

                                <ng-template #noVideo>
                                    <div class="col-sm-7">
                                        <div class="video-image-section cust-card_blank">
                                            No video
                                        </div>
                                    </div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</section>