<section class="content-section pt-2">
    <div class="loading" *ngIf="loader">Loading&#8230;</div>

    <div col-sm-12>
        <div class="row">
            <div class="col-6 mb-2 d-flex align-items-baseline text-xs-left">
                <div class="page-title">
                    <div class="bread-crumb">
                        <a [routerLink]="['/adminDashboard/']">Home</a>
                        <span class="mx-1">-</span>
                        <span class="active">Customer Contact Type</span>
                        <div *ngIf="permissionMessage!=''" class='btn-danger text-center'>{{permissionMessage}} </div>
                    </div>
                </div>
            </div>
            <div class="col-6 mb-2 text-right align-items-baseline">
            </div>
        </div>
    </div>

    <div class="row">
        <div class="col-md-12">
            <div class="white-box1">
                <div class="card-desc1">
                    <form [formGroup]="formSearch">
                        <div class="row detailSection">
                            <div class="col-md-12 container">
                                <div class="row mt-3">
                                    <div class="col-lg-2 col-md-6">
                                        <div class="d-flex align-items-center">
                                            <label class="customLabel mr-1" for="Type">Type</label>
                                            <select class="customInput" (change)="ChangeTCreateUpdate($event)"
                                                [(ngModel)]="TCreateUpdate" id="TCreateUpdate"
                                                formControlName="TCreateUpdate">
                                                <option value="1">Create</option>
                                                <option value="0">Update</option>
                                            </select>
                                        </div>
                                    </div>

                                    <!-- <div class="col-lg-3 col-md-6 mt-md-0 mt-1"
                                        [style.display]="SearchControl==true ? 'block' : 'none'">
                                        <div class="d-flex align-items-center required borderNone"
                                            [ngClass]="((value_search==0) && (SearchValidation)) ? 'validation_border': ''">
                                            <label class="mr-1 customLabel" for="Company">Company</label>
                                            <ngx-dropdown-treeview-select [config]="config"
                                                class="dropdown-company customInput-dropdown"
                                                [items]="userGroupObjects_search" [(value)]="value_search"
                                                (valueChange)="onValueChange_search($event)">
                                            </ngx-dropdown-treeview-select>
                                        </div>
                                    </div> -->
                                    <div class="col-lg-5 col-md-12 mt-lg-0 mt-1"
                                        [style.display]="SearchControl==true ? 'block' : 'none'">

                                        <div class="d-flex align-items-center">
                                            <label class="customLabel mr-1 ws-nobreak" for="Contact Type">Customer Contact Type
                                               </label>
                                            <input type="text" placeholder="Search Contact Type" class="customInput"
                                                [(ngModel)]="userSearch" formControlName="userSearch" autocomplete="off"
                                                required
                                                [ngClass]="((!userSearch && SearchType==false) && (SearchValidation)) ? 'validation_border': ''">
                                            <i class="fas fa-search cpointer ml-3 mr-4 cpointer"
                                                (click)="searchAlertType()"></i>
                                            <button class="btn blue-bg round-btn m-0" (click)="AllContactType()">All
                                                Contact Type</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div class="white-box1" *ngIf="rolePermissionsList && rolePermissionsList[0].isView"
                [style.display]="ListShow==true ? 'block' : 'none'">
                <div class="white-box-title1">
                    <div class="customHeading mt-3 mb-2">
                        Customer Contact Type List
                        <i class="fa fa-angle-{{ListAngle}} cpointer ml-2" aria-hidden="true"
                            (click)="ListDivShowHide()"></i>
                    </div>
                </div>
                <div class="card-desc1" [style.display]="ListDiv==true ? 'block' : 'none'">
                    <table class="row-border hover" datatable [dtOptions]="dtOptions" [dtTrigger]="dtTrigger">
                        <thead>
                            <tr>
                                <th>Company Name</th>
                                <th>Customer Contact Type</th>
                                <th data-orderable="false">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let data of contactTypeDataList">
                                <td>{{data.companyName}}</td>
                                <td>{{data.name}}</td>
                                <td>
                                    <a class="green-text" *ngIf="rolePermissionsList && rolePermissionsList[0].isUpdate"
                                        (click)="toggleEdit(data)"><i class="fas fa-edit"></i></a>
                                    <a class="red-text"
                                        *ngIf="rolePermissionsList && rolePermissionsList[0].isDelete"><i
                                            class="fas fa-trash-alt" (click)="deletePopup(data,content)"></i></a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="white-box1">
                <div class="customHeading mt-3">
                    Customer Contact Type
                </div>
                <div class="card-desc1">
                    <form [formGroup]="form">
                        <div class="row mt-2">
                            <!-- <div class="col-lg-4 col-md-12">
                                <div class="row required">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Company</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10 borderNone"
                                        [ngClass]="((value==0) && (validation)) ? 'validation_border': ''">
                                        <ngx-dropdown-treeview-select class="dropdown-company customInput-dropdown"
                                            [config]="config" required [items]="userGroupObjects" [(value)]="value"
                                            (valueChange)="onValueChange($event)">
                                        </ngx-dropdown-treeview-select>
                                    </div>
                                </div>
                            </div> -->
                            <div class="col-lg-4 col-md-12 mt-lg-0 mt-1">
                                <div class="row required">
                                    <div class="col-lg-4 col-md-2 trp">
                                        <label class="customLabel">Contact Type Name</label>
                                    </div>
                                    <div class="col-lg-8 col-md-10">
                                        <input type="text" class="customInput" [(ngModel)]="contactType"
                                            placeholder="Enter Contact Type" name="contactType"
                                            formControlName="contactType" autocomplete="off" required maxlength="50"
                                            [ngClass]="((!form.controls.contactType.valid) && (validation)) ? 'validation_border': ''">
                                        <div *ngIf="((form.controls.contactType.valid) && (recordAlreadyExist))"
                                            class="text-danger input-error">
                                            Record Already Exist.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="submit-from text-right mt-1 p-0">
                            <button class="btn blue-bg round-btn"
                                *ngIf="rolePermissionsList && rolePermissionsList[0].isInsert"
                                (click)="addNewCustomerContactType()"><i class="fas fa-save"></i> {{buttonValue}}
                            </button>
                            <button class="btn darkred-bg round-btn" (click)="resetContactType()"
                                *ngIf="clearbutton==true"><i class="fas fa-redo"></i> Clear </button>
                            <button class="btn darkred-bg round-btn" (click)="ContactTypeBack()"
                                *ngIf="clearbutton==false"><i class="fas fa" aria-hidden="true"></i>
                                Exit </button>
                        </div>
                    </form>
                </div>
            </div>



        </div>
    </div>
</section>

<ng-template #content let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg alert_heading sweet-heading"></h4>
    <button class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body p-0">
        <div class="thank-you-pop">
            <img src="./assets/image/popup-alert.png" alt="">
            <h1>Confirmation</h1>
            <p class="mt-1 modal-para">{{deleteDetail}}</p>
        </div>
    </div>
    <div class="modal-footer sweet-footer text-center p-1">
        <button type="button" class="btn green-bg round-btn m-1"
            (click)="deleteCustomerContactType(deletedItem); c('Close click')">Yes</button>
        <button type="button" class="btn red-bg round-btn m-1" (click)="c('Close click')">No</button>
    </div>
</ng-template>

<ng-template #messageModal let-c="close" let-d="dismiss">
    <h4 class="modal-title darkred-bg alert_heading sweet-heading"></h4>
    <button class="close btn alert_modal" aria-label="Close" (click)="d('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
    <div class="modal-body">
        <div class="thank-you-pop">
            <img src="{{messageModalImage}}" alt="{{messageModalHeading}}">
            <!-- <h1>{{messageModalHeading}}</h1> -->
            <p class="modal-para m-1">{{messageModalDescription}}</p>
        </div>
    </div>
    <div class="modal-footer sweet-footer text-center p-1">
        <button type="button" class="btn red-bg round-btn" (click)="c('Close click')"
            data-dismiss="modal">Close</button>
    </div>
</ng-template>

