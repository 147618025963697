import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { ConstantsService } from 'src/app/constants.service';
import { SharedService } from 'src/app/services/shared.service';
// import { TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { DomSanitizer } from "@angular/platform-browser";
import { flush } from '@angular/core/testing';
// import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown-angular7';
import { GlobalChangeService } from '../services/global-change.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-email-configuration',
  templateUrl: './email-configuration.component.html',
  styleUrls: ['./email-configuration.component.css']
})
export class EmailConfigurationComponent implements OnInit {

  /*Search Property*/
  formSearch: FormGroup;
  searchTerm: any;
  TCreateUpdate: any = 1;
  SearchValidation: any;
  SearchControl: boolean = false;
  SearchType: any = false;
  ListShow: boolean = false;
  ListDiv: boolean = false;
  ListAngle: any;
  value_search = 0;
  userGroupObjects_search = new Array();
  companyList_search: any;
  configList: any;

  /* Form Property */
  form: FormGroup;
  validation: boolean = false;
  txtName: any;
  txtEmail: any;
  txtPassword: any;
  txtappPassword: any;
  txtSmtp: any;
  txtPort: any;
  value = 0;
  userGroupObjects = new Array();
  companyList: any;
  mailButton: boolean = false;

  /*Datatable Property*/
  dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective, { static: false })
  dtElement: DataTableDirective;

  /*Others*/
  loader: boolean = false;
  clearbutton: boolean = true;
  rolePermissionsList: any;
  permissionMessage = '';
  id: number;
  userId: string;
  companyLoginId: any;
  data: any;
  buttonValue: string;
  settingDetailCSS: any;
  settingLogoImage: any;
  createdBy: any;
  createdDate: any;
  deletedItem: string;
  deleteDetail: string;
  now: Date;

  //Test Email Property
  testEmailForm: FormGroup;
  EmailTempValidate: boolean = false;
  txtEmailSendTo: any;
  txtEmailSendToCc: any;
  txtEmailSendToBcc: any;
  txtEmailSubject: any;

  //Editer propertise
  name = "ng2-ckeditor";
  ckeConfig: any;
  templateBody: any;
  @ViewChild("myckeditor") ckeditor: any;

  //Trigger
  dropdownList = [];
  selectedItems = [];
  EmailConfigTrigger = [];
  dropdownSettings = {};

  /* Message Modal Properties */
  messageModalImage: any;
  messageModalHeading: any;
  messageModalDescription: any;
  @ViewChild('messageModal', { static: false }) private messageModal;
  @ViewChild('testMailModel', { static: false }) private testMailModel;
  getTempFrom: { item_id: number; item_text: string; }[];


  constructor(public sharedService: SharedService,
    private constants: ConstantsService,
    private router: Router, 
    private modalService: NgbModal,
    private globalChangeService: GlobalChangeService) {

  }

  ngOnInit(): void {

    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);
    }
    this.rolePermissionsList = JSON.parse(localStorage.getItem('rolePermissionsList'));
    this.rolePermissionsList = this.rolePermissionsList.filter(x => x.pageId == 1021);
    this.permissionMessage = '';
    if (this.rolePermissionsList != null && this.rolePermissionsList != undefined) {
      if (!this.rolePermissionsList[0].isView) {
        this.permissionMessage += 'view';
      } if (!this.rolePermissionsList[0].isInsert) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'add';
      } if (!this.rolePermissionsList[0].isUpdate) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'edit';
      } if (!this.rolePermissionsList[0].isDelete) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'delete';
      }
    }
    if (this.permissionMessage != '') {
      this.permissionMessage = 'You do not have access to ' + this.permissionMessage + '. Please contact to administrator!';
    }

    //Editor
    this.ckeConfig = {
      extraPlugins:
        "dialogui,dialog,a11yhelp,about,basicstyles,bidi,blockquote,clipboard," +
        "button,panelbutton,panel,floatpanel,colorbutton,colordialog,menu," +
        "contextmenu,dialogadvtab,div,elementspath,enterkey,entities,popup," +
        "filebrowser,find,fakeobjects,floatingspace,listblock,richcombo," +
        "font,format,forms,horizontalrule,htmlwriter,iframe,indent," +
        "indentblock,indentlist,justify,link,list,liststyle,magicline," +
        "maximize,newpage,pagebreak,pastefromword,pastetext,preview,print," +
        "removeformat,resize,save,menubutton,scayt,selectall,showblocks," +
        "showborders,smiley,sourcearea,specialchar,stylescombo,tab,table," +
        "tabletools,templates,toolbar,undo,wysiwygarea",
      removeButtons: "About",
      removePlugins: 'elementspath'
    };

    //Trigger MultiSelect Dropdown
    this.dropdownList = this.constants.TriggerItems;
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'item_id',
      textField: 'item_text',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      itemsShowLimit: 3,
      allowSearchFilter: false
    };

    this.buttonValue = this.constants.Save;
    this.userId = localStorage.getItem(this.constants.userId);
    this.companyLoginId = localStorage.getItem(this.constants.companyId);
    // this.getAllCompany(this.companyLoginId);
    // this.getAllCompany_search(this.companyLoginId);
    this.form = new FormGroup({
      'txtName': new FormControl(),
      'txtEmail': new FormControl(),
      'txtPassword': new FormControl(),
      'txtappPassword': new FormControl(),
      'txtSmtp': new FormControl(),
      'txtPort': new FormControl(),
      'selectedItems': new FormControl()
    });

    this.formSearch = new FormGroup({
      'TCreateUpdate': new FormControl(),
      'searchTerm': new FormControl(),
    });

    this.testEmailForm = new FormGroup({
      'txtEmailSendTo': new FormControl(),
      'txtEmailSendToCc': new FormControl(),
      'txtEmailSendToBcc': new FormControl(),
      'txtEmailSubject': new FormControl(),
      'templateBody': new FormControl(),
    });

    //ankit implementaion
      // get company by default set value
      var company = this.globalChangeService.getGlobalCompany();
      if(company>0){
        this.value = company;
        this.value_search = company;
      }
      else{
        this.ErrorModal('Error', 'Please select company.');
      }

      //on change function 
      this.globalChangeService.dropdownChange$.subscribe((data)=>{
        this.onValueChange(data);
        this.onValueChange_search(data);
      })

      this.dtOptions = {
        pagingType: this.constants.full_numbers,
        pageLength: 10,
        ordering:true
      };
  }

  onItemSelect(item: any) {
    if (this.EmailConfigTrigger.length > 0) {
      const insertedData = this.EmailConfigTrigger.filter(x => x.item_id == item.item_id && x.IsActive == false);
      if (insertedData.length > 0) {
        insertedData[0].IsActive = true;
      }
      else {
        item.IsActive = true;
        this.EmailConfigTrigger.push(item);
      }
    }
    else {
      item.IsActive = true;
      this.EmailConfigTrigger.push(item);
    }
  }

  onItemDeSelect(item: any) {
    if (this.EmailConfigTrigger.length > 0) {
      this.EmailConfigTrigger.forEach((itemRemove, index) => {
        if (item.item_id === itemRemove.item_id) {
          itemRemove.IsActive = false;
        }
      });
    }
  }

  onSelectAll(items: any) {
    let oldEmailTriggerList = this.EmailConfigTrigger;
    this.EmailConfigTrigger = [];
    items.forEach((itemInner, index) => {
      if (oldEmailTriggerList.length > 0) {
        itemInner.Id = oldEmailTriggerList[index].Id;
      }
      itemInner.IsActive = true;
      this.EmailConfigTrigger.push(itemInner);
    });
  }

  onDeSelectAll(items: any) {
    if (this.EmailConfigTrigger.length > 0) {
      this.EmailConfigTrigger.forEach((itemInner, index) => {
        itemInner.IsActive = false;
      });
    }
  }

  ChangeTCreateUpdate(event) {
    this.TCreateUpdate = event.target.value;
    if (this.TCreateUpdate == "1") {
      this.SearchControl = false;
      this.ListShow = false;
      this.ListDiv = false;
      this.SearchValidation = false;
      this.clearbutton = true;
      this.buttonValue = this.constants.Save;
      this.resetConfigData();
    }
    else if (this.TCreateUpdate == "0") {
      this.SearchControl = true;
      this.searchTerm = "";
    }
  }

  onValueChange_search(value: any): void {
    this.value_search = value;
  }

  onValueChange(value: any): void {
    this.value = value;
  }

  ListDivShowHide() {
    if (this.ListDiv == true) {
      this.ListDiv = false;
      this.ListAngle = this.constants.DownArrow;
    }
    else if (this.ListDiv == false) {
      this.ListDiv = true;
      this.ListAngle = this.constants.UpArrow;
    }
  }

  searchTermF() {
    this.SearchType = false;
    if (!this.searchTerm || this.value_search == 0) {
      this.SearchValidation = true;
      return;
    }
    this.GetAllConfig(this.value_search, this.searchTerm);
  }

  AllConfig() {
    this.SearchType = true;
    if (this.value_search == 0) {
      this.SearchValidation = true;
      return;
    }
    this.GetAllConfig(this.value_search, '');
  }

  GetAllConfig(companySearch, searchTerm) {
    this.loader = true;
    this.sharedService.GetAllEmailConfig(companySearch, searchTerm).subscribe(
      data => {
        this.ListShow = true;
        this.ListDiv = true;
        this.ListAngle = this.constants.UpArrow;
        this.data = data;
        this.configList = this.data.dataList;
        this.loader = false;
        this.rerender();
      }
    )
  }

  toggleEdit(item: any) {
    this.EmailConfigTrigger = [];
    this.selectedItems = [];
    this.mailButton = true;
    this.ListDiv = false;
    this.ListAngle = this.constants.DownArrow;
    document.body.scrollTop = document.documentElement.scrollTop = 0;
    this.buttonValue = this.constants.Update;
    this.clearbutton = false;
    this.sharedService.GetEmailConfig(item.id).subscribe(
      (data: any) => {
        this.id = item.id;
        this.value = data.data.companyId;
        this.txtName = data.data.name;
        this.txtEmail = data.data.senderEmail;
        this.txtPassword = data.data.password;
        this.txtappPassword = data.data.appPassword;
        this.txtSmtp = data.data.smtp;
        this.txtPort = data.data.port;
        if (data.data.trig) {
          this.EmailConfigTrigger = JSON.parse(data.data.trig);
          this.selectedItems = this.EmailConfigTrigger.filter(x => x.IsActive == true);
        }
      });
  }

  addConfig() {
    this.validation = true;
    this.clearbutton = true;
    if (
      this.value === 0 ||
      this.form.controls.txtName.status === this.constants.Invalid ||
      this.form.controls.txtEmail.status === this.constants.Invalid ||
      this.form.controls.txtPassword.status === this.constants.Invalid ||
      this.form.controls.txtappPassword.status === this.constants.Invalid ||
      this.form.controls.txtSmtp.status === this.constants.Invalid ||
      this.form.controls.txtPort.status === this.constants.Invalid
    ) {
      return;
    }

    if (this.EmailConfigTrigger.length == 0) {
      this.modalError(this.constants.SelectConfigTrigger);
      return;
    }

    this.loader = true;

    const obj = {};
    obj['CompanyId'] = Number(this.value);
    obj['Name'] = this.txtName;
    obj['SenderEmail'] = this.txtEmail;
    obj['Password'] = this.txtPassword;
    obj['AppPassword'] = this.txtappPassword;
    obj['Smtp'] = this.txtSmtp;
    obj['Port'] = this.txtPort;
    obj['IsActive'] = true
    let EmailConfigTrigger1 = this.EmailConfigTrigger;
    this.EmailConfigTrigger = [];
    for (var j = 0; j < EmailConfigTrigger1.length; j++) {
      let items = {};
      items["Id"] = EmailConfigTrigger1[j].Id;
      items['item_id'] = EmailConfigTrigger1[j].item_id;
      items['item_text'] = EmailConfigTrigger1[j].item_text;
      items['IsActive'] = EmailConfigTrigger1[j].IsActive;
      items['CreatedBy'] = this.userId;
      items["CreatedDate"] = this.now = new Date();
      items["CompanyId"] = Number(this.value);
      this.EmailConfigTrigger.push(items);
    }
    obj['EmailConfigTrigger'] = this.EmailConfigTrigger;

    if (this.id != undefined && !Number.isNaN(this.id) && this.id != 0 && this.id != null) {

      obj['Id'] = this.id;
      obj['ModifiedBy'] = this.userId;
      obj['ModifiedDate'] = new Date();
      this.sharedService.UpdateEmailConfig(obj)
        .subscribe(
          data => {
            switch (data.statusCode) {
              case 200:
                this.TCreateUpdate = 1;
                this.SearchControl = false;
                this.ListShow = false;
                this.ListDiv = false;
                this.resetConfigData();
                this.rerender();
                this.modalSuccess(this.constants.RecordUpdateSuccessfully);
                break;
              case 400:
                this.modalError(this.constants.SomethingWentWrong);
                break;
              case 409:
                this.modalError(this.getTriggerNameById(data.data) + this.constants.TriggerExist);
                break;
              case 208:
                this.modalError(this.constants.EmailConfigurationExist);
                break;
              default:
                this.modalError(this.constants.SomethingWentWrong);
            }
            this.loader = false;
          }
        );
    }
    else {
      obj['CreatedBy'] = this.userId;
      obj['CreatedDate'] = new Date();
      this.sharedService.AddEmailConfig(obj)
        .subscribe(
          data => {
            switch (data.statusCode) {
              case 200:
                this.TCreateUpdate = 1;
                this.SearchControl = false;
                this.ListShow = false;
                this.ListDiv = false;
                this.resetConfigData();
                this.rerender();
                this.modalSuccess(this.constants.RecordAddedSuccessfully);
                break;
              case 400:
                this.modalError(this.constants.SomethingWentWrong);
                break;
              case 409:
                this.modalError(this.getTriggerNameById(data.data) + this.constants.TriggerExist);
                break;
              case 208:
                this.modalError(this.constants.EmailConfigurationExist);
                break;
              default:
                this.modalError(this.constants.SomethingWentWrong);
            }
            this.loader = false;
          }
        );
    }
  }

  getTriggerNameById(id) {
    const triggerName = this.constants.TriggerItems.filter(x => x.item_id == id);
    if (triggerName.length > 0) {
      return triggerName[0].item_text;
    }
    else {
      return "";
    }
  }

  DeleteConfirm(item, content) {
    this.deletedItem = '';
    this.modalService.open(content, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
    this.deleteDetail = this.constants.DeletePopup;
    this.deletedItem = item;
  }

  DeleteConfig(item: any) {
    this.sharedService.deleteEmailConfig(item.id).subscribe(
      data => {
        this.data = data;
        switch (data.statusCode) {
          case 200:
            this.modalSuccess(this.constants.RecordDeletedSuccessfully);
            this.GetAllConfig(this.value_search, this.searchTerm == "" ? "" : this.searchTerm);
            this.resetConfigData();
            this.rerender();
            break;
          case 400:
            this.modalError(this.constants.SomethingWentWrong);
            break;
          default:
            this.modalError(this.constants.SomethingWentWrong);
        }
      });
  }

  resetConfigData() {
    this.id = null;
    this.buttonValue = this.constants.Save;
    this.txtName = '';
    this.txtEmail = '';
    this.txtPassword = '';
    this.txtappPassword = '';
    this.txtSmtp = '';
    this.txtPort = '';
    this.EmailConfigTrigger = [];
    this.selectedItems = [];
    this.validation = false;
  }

  openModelTestMail() {
    this.EmailTempValidate = false;
    this.txtEmailSendTo = '';
    this.txtEmailSendToCc = '';
    this.txtEmailSendToBcc = '';
    this.txtEmailSubject = '';
    this.templateBody = '';
    this.modalService.dismissAll();
    this.modalService.open(this.testMailModel, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false, size: 'lg' });
  }

  sendMail() {

    this.EmailTempValidate = true;

    if (this.testEmailForm.controls.txtEmailSendTo.status === this.constants.Invalid ||
      this.testEmailForm.controls.txtEmailSubject.status === this.constants.Invalid) {
      return;
    }

    if (!this.templateBody || this.templateBody == '') {
      this.modalError(this.constants.ValidateEditor);
      return;
    }

    this.loader = true;

    const obj = {};
    obj['Smtp'] = this.txtSmtp;
    obj['Name'] = this.txtName;
    obj['SenderEmail'] = this.txtEmail;
    obj['Password'] = this.txtPassword;
    obj['Password'] = this.txtappPassword;
    obj['Port'] = this.txtPort;
    obj['EmailTo'] = this.txtEmailSendTo;
    obj['EmailCC'] = this.txtEmailSendToCc;
    obj['EmailToBcc'] = this.txtEmailSendToBcc;
    obj['Subject'] = this.txtEmailSubject
    obj['Body'] = this.templateBody;

    this.sharedService.SendTestEmail(obj).subscribe(
      data => {
        switch (data.statusCode) {
          case 200:
            this.loader = false;
            this.modalService.dismissAll();
            this.modalSuccess(this.constants.EmailSentMessage);
            break;
          case 400:
            this.loader = false;
            this.modalService.dismissAll();
            this.modalError(this.constants.SomethingWentWrong);
            break;
          default:
            this.loader = false;
            this.modalService.dismissAll();
            this.modalError(this.constants.SomethingWentWrong);
        }
      },
      (err) => {
        this.loader = false;
        this.modalService.dismissAll();
        this.modalError(this.constants.SomethingWentWrong);
      });
  }

  configBack() {
    this.clearbutton = true;
    this.id = null;
    this.buttonValue = this.constants.Save;
    this.TCreateUpdate = 1;
    this.SearchControl = false;
    this.ListShow = false;
    this.ListDiv = false;
    this.mailButton = false;
    this.resetConfigData();
  }

  modalSuccess(msg) {
    this.messageModalImage = this.constants.SuccessPng;
    this.messageModalDescription = msg;
    this.modalService.dismissAll();
    this.modalService.open(this.messageModal, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
  }

  modalError(msg) {
    this.messageModalImage = this.constants.ClosePng;
    this.messageModalDescription = msg;
    this.modalService.open(this.messageModal, { windowClass: 'dark-modal', backdrop: 'static', keyboard: false });
  }

  SetSettingCSS(settingCSS, logoImagePath) {
    if (settingCSS != null) {
      let headerCss = document.getElementsByClassName(this.constants.CssHealerClass) as HTMLCollectionOf<HTMLElement>;
      if (headerCss.length != 0) {
        headerCss[0].style.backgroundColor = settingCSS.split(',')[0];
      }
      let saveButtonCss = document.getElementsByClassName(this.constants.CssSaveBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (saveButtonCss.length != 0) {
        saveButtonCss[0].style.backgroundColor = settingCSS.split(',')[1];
        $(".blue-bg").css("background", settingCSS.split(',')[1]);
      }
      let cencelButtonCss = document.getElementsByClassName(this.constants.CssCancelBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (cencelButtonCss.length != 0) {
        cencelButtonCss[0].style.backgroundColor = settingCSS.split(',')[2];
        $(".darkred-bg").css("background", settingCSS.split(',')[2]);
      }
    }

    if (logoImagePath != null && logoImagePath != "") {
      (document.getElementById('hrdlogoImage') as HTMLImageElement).src = logoImagePath;
    }
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next('');
    this.settingDetailCSS = localStorage.getItem("settingDetailCSS");
    this.settingLogoImage = localStorage.getItem("settingImageLogo");
    this.SetSettingCSS(this.settingDetailCSS, this.settingLogoImage);
  }

  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      dtInstance.destroy();
      this.dtTrigger.next('');
    });
  }

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }

  //ankit implemenation
  ErrorModal(title:string, message:string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }
}
