import { Component, DebugElement, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
// import { DropdownTreeviewComponent, TreeviewConfig, TreeviewItem } from 'ngx-treeview';
import { threadId } from 'worker_threads';
import { ConstantsService } from '../constants.service';
import { SharedService } from '../services/shared.service';
import { GlobalChangeService } from '../services/global-change.service';
import Swal from 'sweetalert2';
import { CompanyService } from '../services/company/company.service';
// import { HeaderComponent } from '../header/header.component';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  data: any;
  // @ViewChild(DropdownTreeviewComponent, { static: false }) dropdownTreeviewComponent: DropdownTreeviewComponent;
  imageUrl: any = "/assets/image/uploadlogo.png";
  SoftwareVersion: any;
  fileToUpload: File = null;
  saveFileForm: any;
  logoInput: any;
  resumeInput: any;
  idHeaderColor: any;
  idSaveButtonColor: any;
  idCancelButtonColor: any;
  defaultColor = "#0000ff";
  headerCss: any = "#d23c45";
  saveButtonCss: any = "#1e9ff2";
  cancelButtonCss: any = "#ce2026";
  colorStyle: any;
  companyId: any;
  settingDetailCSS: any;
  settingLogoImage: any;
  LaborHrsCost: any = 0;
  LaborHrsLoadedCost : any = 0;
  rolePermissionsList: any;
  permissionMessage: string;
  Notes: any;
  validation = false;
  Company: number;
  loader: boolean = false;
  // config = TreeviewConfig.create({
  //   hasAllCheckBox: true,
  //   hasFilter: true,
  //   hasCollapseExpand: true,
  //   decoupleChildFromParent: false,
  //   maxHeight: 400
  // });
  // items: TreeviewItem[];
  companyList: any;
  userGroupObjects = new Array();
  value: number;
  ChkPO: boolean = false;
  chkReviced: boolean = false;
  ChkPO1: any;
  PurchaseOrderMenu: any;
  Prefix: any = '';
  PurchaseNumber: any;
  SPrefix: any = '';
  SalesNumber: any;
  Salestax: any;
  LaborGoalPercentage: any;
  DaysToAddShipDate: any;
  TimeZoneList : any;
  timezone : any = "3";
  items: any;
  constructor(
    private toastr: ToastrService,
    private companyService: CompanyService,
    public sharedService: SharedService,
    private constants: ConstantsService,
    private _sanitizer: DomSanitizer,
    private router: Router,
    private route: ActivatedRoute,
    private globalChangeService: GlobalChangeService,
    private companyServie: CompanyService
  ) {
  }

  ngOnInit(): void {
    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);
    }
    this.rolePermissionsList = JSON.parse(localStorage.getItem('rolePermissionsList'));
    this.rolePermissionsList = this.rolePermissionsList.filter(x => x.pageId == 11);

    this.permissionMessage = '';
    if (this.rolePermissionsList != null && this.rolePermissionsList != undefined) {
      if (!this.rolePermissionsList[0].isView) {
        this.permissionMessage += 'view';
      } if (!this.rolePermissionsList[0].isInsert) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'add';
      } if (!this.rolePermissionsList[0].isUpdate) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'edit';
      } if (!this.rolePermissionsList[0].isDelete) {
        if (this.permissionMessage != '') {
          this.permissionMessage += ', ';
        }
        this.permissionMessage += 'delete';
      }
    }

    if (this.permissionMessage != '') {
      this.permissionMessage = 'You do not have access to ' + this.permissionMessage + '. Please contact to administrator!';
    }
    else {
      this.SoftwareVersion = this.constants.softwareVersion;
      this.companyId = localStorage.getItem('companyId');
      // this.getAllCompany(this.companyId);
      this.idHeaderColor = document.querySelector("#idHeaderColor");
      this.idHeaderColor.value = "#d23c45";
      this.idHeaderColor.addEventListener("input", (event) => {
        let headerCssStyle = document.getElementsByClassName(this.constants.CssHealerClass) as HTMLCollectionOf<HTMLElement>;
        if (headerCssStyle.length != 0) {
          if (this.companyId > 0) {
            headerCssStyle[0].style.backgroundColor = event.target.value;
          }
        }
        this.headerCss = event.target.value;
      }, false)
      this.idHeaderColor.select();
      this.idSaveButtonColor = document.querySelector("#idSaveButtonColor");
      this.idSaveButtonColor.value = "#1e9ff2";
      this.idSaveButtonColor.addEventListener("input", (event) => {
        let saveButtonStyle = document.getElementsByClassName(this.constants.CssSaveBtnClass) as HTMLCollectionOf<HTMLElement>;
        if (saveButtonStyle.length != 0) {
          if (this.companyId > 0) {
            saveButtonStyle[0].style.backgroundColor = event.target.value;
          }
        }
        this.saveButtonCss = event.target.value;
      }, false)
      this.idSaveButtonColor.select();
      this.idCancelButtonColor = document.querySelector("#idCancelButtonColor");
      this.idCancelButtonColor.value = "#ce2026";
      this.idCancelButtonColor.addEventListener("input", (event) => {
        let saveButtonStyle = document.getElementsByClassName(this.constants.CssCancelBtnClass) as HTMLCollectionOf<HTMLElement>;
        if (saveButtonStyle.length != 0) {
          if (this.companyId > 0) {
            saveButtonStyle[0].style.backgroundColor = event.target.value;
          }
        }
        this.cancelButtonCss = event.target.value;
      }, false)
      this.idCancelButtonColor.select();
      if (this.settingDetailCSS != null && this.settingDetailCSS != "null" && this.settingDetailCSS != "") {
        this.headerCss = (this.settingDetailCSS).split(',')[0];
        this.saveButtonCss = (this.settingDetailCSS).split(',')[1];
        this.cancelButtonCss = (this.settingDetailCSS).split(',')[2];
      }

      // this.GetCompanyDetails(this.companyId);
      
      this.TimeZoneList = this.constants.TimeZoneList;
     // this.timezone = '3';
    }

    //ankit implementaion
      // get company by default set value
      var company = this.globalChangeService.getGlobalCompany();
      if(company>0){
        this.value = company;
        this.GetCompanyDetails(this.value);
        this.getAllCompany(company);
      }
      else{
        this.ErrorModal('Error', 'Please select company.');
      }

      //on change function 
      this.globalChangeService.dropdownChange$.subscribe((data)=>{
        this.onValueChange(data);
        this.getAllCompany(data);
      })
  }

  ChngSettingImage(files: FileList) {
    this.fileToUpload = files.item(0);
    var reader = new FileReader()
    reader.onload = (event: any) => {


      this.imageUrl = event.target.result;
    }
    reader.readAsDataURL(this.fileToUpload);

  }


  getAllCompany(companyId) {
    this.companyServie.GetDropdownCompanyHierarchyModel(companyId).subscribe(
      data => {
        this.data = data;
        this.items = this.data.dataList;
        this.companyList = this.data.dataList;
        // this.companyList.forEach((x) => {
        //   this.userGroupObjects.push(new TreeviewItem(x, false));
        // });
        this.Notes = localStorage.getItem("notes");
        this.Notes = this.Notes == 'null' ? '' : this.Notes;
        this.PurchaseNumber = this.PurchaseNumber == null ? '' : this.PurchaseNumber;
        this.value = Number(companyId);
      });
  }

  onValueChange(value: any): void {
    this.value = value;
    this.GetCompanyDetails(this.value);
  }

  GetCompanyDetails(companyId) {
    this.loader = true;
    this.sharedService.GetSettingSuperAdminDetails(companyId).subscribe(
      data => {
        this.data = data;
        if (this.data.data != undefined || this.data.data != null) {
          this.LaborHrsCost = this.data.data.laborCost;
          this.LaborHrsLoadedCost = this.data.data.laborLoadedCost;
          this.Notes = this.data.data.notes == null ? "" : this.data.data.notes;
          this.settingDetailCSS = this.data.data.color;
          this.settingLogoImage = this.data.data.logoName;
          this.ChkPO = this.data.data.isPo;
          this.chkReviced = this.data.data.isOperational;
          this.DaysToAddShipDate = this.data.data.daysToAddShipDate == null ? "" : this.data.data.daysToAddShipDate;
          this.Prefix = this.data.data.prefix == null ? "" : this.data.data.prefix;
          this.PurchaseNumber = this.data.data.purchaseOrderNo == null ? "" : this.data.data.purchaseOrderNo;
          this.SalesNumber = this.data.data.salesOrderNo == null ? "" : this.data.data.salesOrderNo;
          this.SPrefix = this.data.data.salesOrderPrefix == null ? "" : this.data.data.salesOrderPrefix;
          this.Salestax = this.data.data.saleTax == null ? "" : this.data.data.saleTax;
          this.LaborGoalPercentage = this.data.data.laborGoalPercentage == null ? "" : this.data.data.laborGoalPercentage;
          this.timezone = this.data.data.timezone == null ? '' : this.data.data.timezone; 
          let settingCSS = this.settingDetailCSS;
          let logoImagePath = this.settingLogoImage;
          if (settingCSS == null) {
            this.headerCss = '#d23c45';
            this.saveButtonCss = '#1e9ff2';
            this.cancelButtonCss = '#ce2026';
            this.idHeaderColor.value = "#d23c45";
            this.idSaveButtonColor.value = "#1e9ff2";
            this.idCancelButtonColor.value = "#ce2026";
            this.idCancelButtonColor.value = "#ce2026"
          }
          else {
            this.headerCss = (settingCSS).split(',')[0];
            this.saveButtonCss = (settingCSS).split(',')[1];
            this.cancelButtonCss = (settingCSS).split(',')[2];
            this.idHeaderColor.value = settingCSS.split(',')[0];
            this.idSaveButtonColor.value = settingCSS.split(',')[1];
            this.idCancelButtonColor.value = settingCSS.split(',')[2];
          }

          this.idHeaderColor = document.querySelector("#idHeaderColor");
          this.idSaveButtonColor = document.querySelector("#idSaveButtonColor");
          this.idCancelButtonColor = document.querySelector("#idCancelButtonColor");
          if (logoImagePath != null && logoImagePath != "") {
            this.imageUrl = this._sanitizer.bypassSecurityTrustUrl(logoImagePath);
          }
          else {
            this.imageUrl = this._sanitizer.bypassSecurityTrustUrl("/assets/image/uploadlogo.png");
          }
          this.loader = false;
        }

      }
    )
  }


  SettingLogoSubmit() {
    this.validation = true;
    ///this.value=this.companyId
    if (this.companyId > 0) {
      this.value = this.companyId;
    }
    if (this.value == undefined || this.value == 0 || this.PurchaseNumber == null || this.PurchaseNumber == ""
      || this.PurchaseNumber == undefined || this.Salestax == null || this.Salestax == undefined || this.Salestax == "" 
      || this.timezone == undefined || this.timezone == null || this.timezone == ""
      || this.LaborGoalPercentage == null || this.LaborGoalPercentage == undefined || this.LaborGoalPercentage == "") {
      return;
    }
    this.loader = true;
    let ddvVlaue = this.value;
    let compId = this.companyId;
    this.ChkPO == undefined ? false : this.ChkPO;
    this.chkReviced = undefined ? false : this.chkReviced;
    let purchaseOrderNumber = this.PurchaseNumber == null ? "" : this.PurchaseNumber;
    let salesOrderNumber = this.SalesNumber == null ? "" : this.SalesNumber;
    let saletax = this.Salestax == null ? "" : this.Salestax;
    let daysToAddShipDate = this.DaysToAddShipDate == null ? "" : this.DaysToAddShipDate;
    let laborgoalpercentage = this.LaborGoalPercentage == null ? "" : this.LaborGoalPercentage;
    this.Company = ddvVlaue == undefined ? this.companyId : ddvVlaue;
    if (this.Company != null && this.Company != 0) {
      const obj = {};
      this.colorStyle = this.headerCss + "," + this.saveButtonCss + "," + this.cancelButtonCss;
      this.companyId = this.companyId;
      let file = new FormData();
      file.append('file', this.fileToUpload);

      this.companyService.postFile(file, this.Company, String(this.colorStyle), Number(this.LaborHrsCost), Number(this.LaborHrsLoadedCost), this.Notes, this.ChkPO, this.chkReviced, purchaseOrderNumber, this.Prefix, salesOrderNumber, this.SPrefix, saletax, laborgoalpercentage, daysToAddShipDate, this.timezone)
        .subscribe(
          data => {
            switch (data.statusCode) {
              case 200:
                this.LaborHrsCost = this.LaborHrsCost;
                this.LaborHrsLoadedCost = this.LaborHrsLoadedCost;
                if (this.colorStyle != null && this.colorStyle != "null" && this.colorStyle != "") {
                  this.headerCss = (this.colorStyle).split(',')[0];
                  this.saveButtonCss = (this.colorStyle).split(',')[1];
                  this.cancelButtonCss = (this.colorStyle).split(',')[2];
                }
                if (this.companyId > 0) {
                  localStorage.setItem("settingDetailCSS", this.colorStyle);
                  localStorage.setItem("laborPerHrsCost", this.LaborHrsCost);
                  localStorage.setItem("laborPerHrsLoadedCost", this.LaborHrsLoadedCost);
                  localStorage.setItem("notes", this.Notes);
                  localStorage.setItem("PONumber", this.PurchaseNumber);
                  this.Prefix = this.Prefix == undefined ? '' : this.Prefix == null ? '' : this.Prefix;
                  localStorage.setItem("Prefix", this.Prefix);
                  localStorage.setItem("SalesOrderNo", this.SalesNumber);
                  this.SPrefix = this.SPrefix == undefined ? '' : this.SPrefix == null ? '' : this.SPrefix;
                  localStorage.setItem("SalesOrderPrefix", this.SPrefix);
                  localStorage.setItem("SaleTax", this.Salestax);
                  localStorage.setItem("LaborGoalPercentage", this.LaborGoalPercentage);
                  localStorage.setItem("DaysToAddShipDate", this.DaysToAddShipDate);
                }
                $("#add_update").show();
                // this.toastr.success(this.constants.RecordUpdateSuccessfully);
                if (this.companyId == 0) {
                  this.resetvendorData();
                }
                if (data.data != null && data.data != "" && data.data != undefined && this.companyId > 0) {
                  localStorage.setItem('settingImageLogo', data.data);
                  if (compId > 0) {
                    (document.getElementById('hrdlogoImage') as HTMLImageElement).src = data.data;
                  }
                }
                this.loader = false;
                this.validation = false;
                break;
              case 400:
                $("#wrong_msg").show();
                // this.toastr.error(this.constants.SomethingWentWrong);
                break;
              default:
                $("#wrong_msg").show();
              // this.toastr.error(this.constants.SomethingWentWrong);
            }
          });
    }
  }

  ClorClear() {
    this.ngOnInit();
  }

  clorPicker(event) {
    $(this).val(event.srcElement.value);
  }



  public isValidFlg: boolean = true;
  formatCurrency() {
    var input_val = this.LaborHrsCost;
    var original_len = input_val.length;
    if (input_val != "") {
      if (input_val.indexOf(".") >= 0) {
        var decimal_pos = input_val.indexOf(".");
        var l = input_val.substring(0, decimal_pos);
        var r = input_val.substring(decimal_pos, decimal_pos + 3);
        var right_side = input_val.substring(decimal_pos, 2);
        this.LaborHrsCost = l + "" + r;
      } else {
        this.LaborHrsCost = this.LaborHrsCost + ".00";
      }
    }

  }
  formatCurrency1() {
    var input_val = this.LaborHrsLoadedCost;
    var original_len = input_val.length;
    if (input_val != "") {
      if (input_val.indexOf(".") >= 0) {
        var decimal_pos = input_val.indexOf(".");
        var l = input_val.substring(0, decimal_pos);
        var r = input_val.substring(decimal_pos, decimal_pos + 3);
        var right_side = input_val.substring(decimal_pos, 2);
        this.LaborHrsLoadedCost = l + "" + r;
      } else {
        this.LaborHrsLoadedCost = this.LaborHrsLoadedCost + ".00";
      }
    }

  }

  formatValidateCurrency() {
    var input_val = this.LaborHrsCost;
    if (input_val.length > 0) {
      const regex = /d+\.{1}?\d{2}/;
      if (regex.test(input_val)) {
        if (input_val.split('.').length >= 3) {
          var decimal_pos = input_val.indexOf(".");
          var l = input_val.substring(0, decimal_pos);
          var r = input_val.substring(decimal_pos, decimal_pos + 3);
          var right_side = input_val.substring(decimal_pos, 2);
          this.LaborHrsCost = l + "" + r;
        }
      }
      else {
        return false;
      }
    }
  }
  formatValidateCurrency1() {
    var input_val = this.LaborHrsLoadedCost;
    if (input_val.length > 0) {
      const regex = /d+\.{1}?\d{2}/;
      if (regex.test(input_val)) {
        if (input_val.split('.').length >= 3) {
          var decimal_pos = input_val.indexOf(".");
          var l = input_val.substring(0, decimal_pos);
          var r = input_val.substring(decimal_pos, decimal_pos + 3);
          var right_side = input_val.substring(decimal_pos, 2);
          this.LaborHrsLoadedCost = l + "" + r;
        }
      }
      else {
        return false;
      }
    }
  }




  SetSettingCSS(settingCSS, logoImagePath) {
    if (settingCSS != null && settingCSS != "null" && settingCSS != "" && this.companyId > 0) {
      let headerCss = document.getElementsByClassName(this.constants.CssHealerClass) as HTMLCollectionOf<HTMLElement>;
      if (headerCss.length != 0) {
        headerCss[0].style.backgroundColor = settingCSS.split(',')[0];
        this.idHeaderColor = document.querySelector("#idHeaderColor");
        this.idHeaderColor.value = settingCSS.split(',')[0];
        this.headerCss = (settingCSS).split(',')[0];

      }
      let saveButtonCss = document.getElementsByClassName(this.constants.CssSaveBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (saveButtonCss.length != 0) {
        saveButtonCss[0].style.backgroundColor = settingCSS.split(',')[1];
        this.idSaveButtonColor = document.querySelector("#idSaveButtonColor");
        this.idSaveButtonColor.value = settingCSS.split(',')[1];
        this.saveButtonCss = (settingCSS).split(',')[1];

      }
      let cencelButtonCss = document.getElementsByClassName(this.constants.CssCancelBtnClass) as HTMLCollectionOf<HTMLElement>;
      if (cencelButtonCss.length != 0) {
        cencelButtonCss[0].style.backgroundColor = settingCSS.split(',')[2];

        this.idCancelButtonColor = document.querySelector("#idCancelButtonColor");
        this.idCancelButtonColor.value = settingCSS.split(',')[2];
        this.cancelButtonCss = (settingCSS).split(',')[2];

      }
      let laborPerHrsCost = localStorage.getItem(this.constants.laborPerHrsCost);
      this.PurchaseNumber = localStorage.getItem("PONumber");
      this.Prefix = localStorage.getItem("Prefix");
      this.DaysToAddShipDate = localStorage.getItem("DaysToAddShipDate");
      ///For sale order number//
      this.SalesNumber = localStorage.getItem("SalesOrdernumber");
      this.SPrefix = localStorage.getItem("SalesOrderPrefix");
      this.Salestax = localStorage.getItem("SaleTax");
      this.LaborGoalPercentage = localStorage.getItem("LaborGoalPercentage");
      ///
      this.ChkPO = JSON.parse(localStorage.getItem("ChkPO1"));
      this.chkReviced = JSON.parse(localStorage.getItem("Received"));
      if (laborPerHrsCost != null) {
        this.LaborHrsCost = laborPerHrsCost;
      }
    }

    if (logoImagePath != null && logoImagePath != "") {

      this.imageUrl = this._sanitizer.bypassSecurityTrustUrl(logoImagePath);

      (document.getElementById('hrdlogoImage') as HTMLImageElement).src = logoImagePath;
    }

  }
  resetvendorData() {
    this.Notes = '';
    this.LaborHrsCost = '';
    this.LaborHrsLoadedCost = '';
    this.settingLogoImage = '';
    this.settingDetailCSS = '';
    this.value = 0;
    this.imageUrl = '/assets/image/uploadlogo.png';
    this.idHeaderColor.value = "#d23c45";
    this.idSaveButtonColor.value = "#1e9ff2";
    this.idCancelButtonColor.value = "#ce2026";
    this.chkReviced = false;
    this.ChkPO = false;
    this.PurchaseNumber = '';
    this.DaysToAddShipDate = '';
    this.Prefix = '';
    this.SalesNumber = '';
    this.SPrefix = '';
    this.Salestax = '';
    this.LaborGoalPercentage = '';
    this.timezone = '3';
  }

  GetChkVal(event, ChkType) {
    if (event) {
      if (ChkType == 'PO') {
        this.ChkPO = event.target.checked;
        this.sharedService.setSideBar(this.ChkPO);
      }
      else if (ChkType = 'Receive') {
        this.chkReviced = event.target.checked;
        //modified by shail
        this.sharedService.setSideBar(this.chkReviced);

      }
      else if (ChkType = 'Receive' && ChkType == 'PO') {
        this.ChkPO = event.target.checked;
        this.chkReviced = event.target.checked;
        this.sharedService.setSideBar(this.ChkPO);
      }

    }
  }


  ngAfterContentInit(): void {
    this.settingDetailCSS = localStorage.getItem("settingDetailCSS");
    this.settingLogoImage = localStorage.getItem("settingImageLogo");
    this.SetSettingCSS(this.settingDetailCSS, this.settingLogoImage);
  }

  ngAfterViewInit(): void {
    this.settingDetailCSS = localStorage.getItem("settingDetailCSS");
    this.settingLogoImage = localStorage.getItem("settingImageLogo");
    this.SetSettingCSS(this.settingDetailCSS, this.settingLogoImage);
  }
  addsuccess() {
    $("#add_success").hide();
  }
  updateitem() {
    $("#add_update").hide();
  }
  deleteitem() {
    $("#delete_success").hide();
  }
  msgitem() {
    $("#wrong_msg").hide();
  }
  ///For Sale Tax Formation

  SaleTaxkeyPress() {
    var saletaxfield = this.Salestax;
    if (saletaxfield == "" || saletaxfield == undefined || saletaxfield == null) {
      return;
    }
    var decimal_pos = saletaxfield.indexOf(".");
    if (saletaxfield.length >= 2 && decimal_pos == 1) {
      let maskingSaletax = '';
      maskingSaletax = saletaxfield.substring(0, 2) + "." + saletaxfield.substring(3, 4);
      this.Salestax = maskingSaletax;
    }
    if (saletaxfield.length >= 1 && decimal_pos == 1) {
      let maskingSaletax = '';
      maskingSaletax = saletaxfield.substring(0, 1) + "." + saletaxfield.substring(2, 3);
      this.Salestax = maskingSaletax;
    }
    if (saletaxfield.length >= 3 && decimal_pos == -1) {
      let maskingSaletax = '';
      maskingSaletax = saletaxfield.substring(0, 2) + "." + saletaxfield.substring(3, 4);
      this.Salestax = maskingSaletax;
    }
    else {

    }
  }
  //End
  //Sale Tax should be BLur functionality--
  SaleTaxBlur(event) {
    let txtValue = event;
    var input_val = parseFloat(txtValue).toFixed(2);
    if (input_val != "") {
      if (input_val.indexOf(".") >= 0) {
        var decimal_pos = input_val.indexOf(".");
        var l = input_val.substring(0, decimal_pos);
        var r = input_val.substring(decimal_pos, decimal_pos + 3);
        this.Salestax = l + "" + r;
        return l + "" + r;;
      } else {
        this.Salestax = this.Salestax == "" ? "0.00" : this.Salestax == undefined ? "0.00" : this.Salestax == null ? "0.00" : this.Salestax + ".00";
      }
    }
    else {
      this.Salestax = "0.00";
    }
  }

  ////End---

  ///For Labor Goal Formation

  LaborGoalPercentagekeyPress() {

    var laborgoalpercentagefield = this.LaborGoalPercentage;
    if (laborgoalpercentagefield == "" || laborgoalpercentagefield == undefined || laborgoalpercentagefield == null) {
      return;
    }
    var decimal_pos = laborgoalpercentagefield.indexOf(".");
    if (laborgoalpercentagefield.length >= 2 && decimal_pos == 1) {
      let maskingLaborGoalPercentage = '';
      maskingLaborGoalPercentage = laborgoalpercentagefield.substring(0, 2) + "." + laborgoalpercentagefield.substring(3, 4);
      this.LaborGoalPercentage = maskingLaborGoalPercentage;
    }
    if (laborgoalpercentagefield.length >= 1 && decimal_pos == 1) {
      let maskingLaborGoalPercentage = '';
      maskingLaborGoalPercentage = laborgoalpercentagefield.substring(0, 1) + "." + laborgoalpercentagefield.substring(2, 3);
      this.LaborGoalPercentage = maskingLaborGoalPercentage;
    }
    if (laborgoalpercentagefield.length >= 3 && decimal_pos == -1) {
      let maskingLaborGoalPercentage = '';
      maskingLaborGoalPercentage = laborgoalpercentagefield.substring(0, 2) + "." + laborgoalpercentagefield.substring(3, 4);
      this.LaborGoalPercentage = maskingLaborGoalPercentage;
    }
    else {

    }
  }
  //End
  //Labor Goal should be BLur functionality--
  LaborGoalPercentageBlur(event) {

    let txtValue = event;
    var input_val = parseFloat(txtValue).toFixed(2);
    if (input_val != "") {
      if (input_val.indexOf(".") >= 0) {
        var decimal_pos = input_val.indexOf(".");
        var l = input_val.substring(0, decimal_pos);
        var r = input_val.substring(decimal_pos, decimal_pos + 3);
        this.LaborGoalPercentage = l + "" + r;
        return l + "" + r;;
      } else {
        this.LaborGoalPercentage = this.LaborGoalPercentage == "" ? "0.00" : this.LaborGoalPercentage == undefined ? "0.00" : this.LaborGoalPercentage == null ? "0.00" : this.LaborGoalPercentage + ".00";
      }
    }
    else {
      this.LaborGoalPercentage = "0.00";
    }
  }

  
  //ankit implemenation
  ErrorModal(title:string, message:string) {
    Swal.fire({
      title: title,
      text: message,
      showCancelButton: true,
      cancelButtonText: 'Close',
    })
  }


  ////End---

}
