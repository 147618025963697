import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalChangeService {
  private dropdownChangeSubject = new Subject<any>();
  private globalCompany: any;
  dropdownChange$ = this.dropdownChangeSubject.asObservable();


  emitDropdownChange(data: Number){
    localStorage.setItem('GlobalSelectedCompanyId', (data).toString());
    this.globalCompany = data;
    this.dropdownChangeSubject.next(data);
  }

  getGlobalCompany(){
    if(Number(localStorage.getItem('GlobalSelectedCompanyId'))>0){
      return Number(localStorage.getItem('GlobalSelectedCompanyId'));
    }
    return Number(localStorage.getItem('GlobalSelectedCompanyId'));
  }

  constructor() { }
}
