import { Injectable } from '@angular/core';
import { Observable, throwError, of, BehaviorSubject, Subject } from 'rxjs';
import { tap, delay, catchError } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { TitleCasePipe } from '@angular/common';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  baseUrl = environment.baseUrl;

  constructor(private _http: HttpClient) { }

  GetAllCustomer(companyId: any, searchTerm: any) {
    return this._http.get(this.baseUrl + 'api/Customer/GetAllCustomer?companyId=' + companyId + '&searchTerm=' + searchTerm,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetAllCustomerByCustomerId(customerId) {
    return this._http.get(this.baseUrl + 'api/Customer/GetCustomer?customerId=' + customerId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetCustomerHash(companyId) {
    return this._http.get(this.baseUrl + 'api/Customer/GetCustomerHash?companyId=' + companyId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  addCustomer(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Customer/AddCustomer', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetAllCustomerTypeList() {
    return this._http.get(this.baseUrl + 'api/Customer/GetCustomerTypePartDropdown',
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  GetRoles(companyId: number) {
    return this._http.get(this.baseUrl + 'api/Customer/GetRoles?companyId=' + companyId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  UpdateCustomerTypeList(item: object): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Customer/UpdateCustomerTypePartDropdown', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  AddCustomerTypeList(item: object): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Customer/AddCustomerTypePartDropdown', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }
  addShipCustomer(item: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Customer/addShipCustomer', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  updateCustomer(item: object): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Customer/UpdateCustomer', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  deleteCustomer(item: object): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Customer/DeleteCustomer', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }


  GetCustomerContactInfo(customerId) {
    return this._http.get(this.baseUrl + 'api/Customer/GetCustomerContactInfo?customerId=' + customerId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  uploadCustomer(formData: any, pram1: any): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Customer/UploadCustomer?companyId=' + pram1, formData,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetAllCustomerContactType(companyId: any) {
    return this._http.get(this.baseUrl + 'api/Customer/GetCustomerContactTypeList?companyId=' + companyId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetCustomerShipment(companyId: any, customerId: any) {
    return this._http.get(this.baseUrl + 'api/Customer/GetCustomerShipment?companyId=' + companyId + '&customerId=' + customerId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  CheckMultiUserEmail(email, CompanyId, id) {
    return this._http.get(this.baseUrl + 'api/Customer/GetMultiUserEmail?Email=' + email + '&companyId=' + CompanyId + '&uid=' + id,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  updatemultiSOContactCheckBox(item: object): Observable<any> {
    return this._http.patch(this.baseUrl + 'api/Customer/UpdateMultiContactSO', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  updateMultiUserPassword(item: object): Observable<any> {
    return this._http.put(this.baseUrl + 'api/Customer/UpdateMultiUserPassword', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  UpdateMutiContacts(item: object): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Customer/UpdateMutiContacts', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  getCustomerID(emailid: any): Observable<any> {
    return this._http.get(this.baseUrl + 'api/Customer/getCustomerID/?emailid=' + emailid,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  UpdateCustomerAddress(item: object): Observable<any> {
    return this._http.post(this.baseUrl + 'api/Customer/UpdateCustomerAddress', item,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  GetDropdownCustomerHierarchyModel(CompanyId: Number) {
    return this._http.get(this.baseUrl + 'api/Customer/GetCustomerHierarchy?companyId=' + CompanyId,
      { headers: new HttpHeaders().set('Authorization', 'Bearer ' + localStorage.getItem('AuthHeader')) })
      .pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    // this.loader = false;
    return throwError(error.message || "server error.");
  }
}
