import { Component, OnInit } from '@angular/core';
import { SharedService } from 'src/app/services/shared.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})
export class AdminComponent implements OnInit {
  userName:any;
  router: any;
  constructor( public sharedService: SharedService) { 

  }

  ngOnInit(): void {
    if (localStorage.getItem('AuthHeader') === null || localStorage.getItem('AuthHeader') === undefined) {
      this.router.navigate(['']);
    }
    this.userName=localStorage.getItem('username');
    
    // this.GetAllState()
  }
  // GetAllState(){
  //   this.sharedService.getAllState()
  //   .subscribe(
  //     data =>{

  //     }
  //   );

  // }
  LogOut(){   
      localStorage.clear();
     // this.isLogin.emit('login');
      this.router.navigate(['login']);
    
  }

}
